/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { Form, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FaPencilAlt, FaSave, FaTimesCircle } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      edited_use: this.props.user
    };
  }

  nameForKey(key) {
    switch (key) {
      case "display_name":
        return "Display Name";
      case "email":
        return "Email";
      case "role":
        return "Role";
      case "username":
        return "Username";
      case "status":
        return "Status";
      default:
        return key;
    }
  }

  handleChange = event => {
    const field = event.target.id;
    const { edited_user } = this.state;
    edited_user[field] = event.target.value;
    this.setState({ edited_user });
  };

  renderUserProfile() {
    const { user } = this.props;
    const fields = _.map(_.omit(user, ["id"]), (value, key) => {
      const classNames = key === "username" ? "w-100 disabled" : "w-100";
      return (
        <div className="col-12" key={key}>
          <FormGroup controlId={key}>
            <FormLabel>{this.nameForKey(key)}</FormLabel>
            <FormControl
              className={classNames}
              onChange={this.handleChange}
              value={user[key]}
              disabled={key === "username" ? true : !this.state.isEditing}
            />
          </FormGroup>
        </div>
      );
    });
    return <div>{fields}</div>;
  }

  renderActions() {
    return this.state.isEditing ? (
      <div>
        <button className="btn btn-primary btn-gold btn-lg ml-3" onClick={this.saveEdits}>
          <FaSave>Save</FaSave>
        </button>
        <button className="btn btn-primary btn-gold btn-lg ml-3" onClick={this.cancelEdits}>
          <FaTimesCircle>Cancel</FaTimesCircle>
        </button>
      </div>
    ) : (
      <div>
        <button className="btn btn-primary btn-gold btn-lg ml-3" onClick={this.toggleEditing}>
          <FaPencilAlt>Edit</FaPencilAlt>
        </button>
      </div>
    );
  }

  toggleEditing = event => {
    event.preventDefault();
    const isEditing = !this.state.isEditing;
    const edited_user = this.props.user;
    this.setState({ isEditing, edited_user });
  };

  cancelEdits = event => {
    event.preventDefault();
    this.toggleEditing(event);
    this.setState({ edited_user: null });
  };

  saveEdits = async event => {
    event.preventDefault();
    // await this.props.dispatch({
    //   type: FLIGHTS_EDIT_CURRENT,
    //   payload: this.state.editedFlight
    // });
    // this.toggleEditing(event);
  };

  render() {
    return (
      <div className="container">
        <div className="row d-flex mb-3">
          <h1 className="ml-3 mr-auto">User Profile</h1>
          {/* {this.renderActions()} */}
        </div>
        <Form>{this.renderUserProfile()}</Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user
  };
}

export default connect(mapStateToProps)(withRouter(UserProfile));
