export default Object.freeze([
  {
    id: 1,
    name: 'Bamiyan Airport',
    city: 'Bamyan',
    country: 'Afghanistan',
    iata: 'BIN',
    icao: 'OABN',
    latitude: 34.8170013427734,
    longitude: 67.8170013427734,
    altitude: 8367,
    schengen: false
  },
  {
    id: 2,
    name: 'Bost Airport',
    city: 'Lashkar Gah',
    country: 'Afghanistan',
    iata: 'BST',
    icao: 'OABT',
    latitude: 31.559700012207,
    longitude: 64.3649978637695,
    altitude: 2464,
    schengen: false
  },
  {
    id: 3,
    name: 'Chakcharan Airport',
    city: 'Chaghcharan',
    country: 'Afghanistan',
    iata: 'CCN',
    icao: 'OACC',
    latitude: 34.5330009460449,
    longitude: 65.2669982910156,
    altitude: 7383,
    schengen: false
  },
  {
    id: 4,
    name: 'Farah Airport',
    city: 'Farah',
    country: 'Afghanistan',
    iata: 'FAH',
    icao: 'OAFR',
    latitude: 32.367000579834,
    longitude: 62.1829986572266,
    altitude: 3083,
    schengen: false
  },
  {
    id: 5,
    name: 'Fayzabad Airport',
    city: 'Faizabad',
    country: 'Afghanistan',
    iata: 'FBD',
    icao: 'OAFZ',
    latitude: 37.121101,
    longitude: 70.518097,
    altitude: 3872,
    schengen: false
  },
  {
    id: 6,
    name: 'Ghazni Airport',
    city: 'Ghazni',
    country: 'Afghanistan',
    iata: 'GZI',
    icao: 'OAGN',
    latitude: 33.5312004089,
    longitude: 68.412902832,
    altitude: 7150,
    schengen: false
  },
  {
    id: 7,
    name: 'Herat Airport',
    city: 'Herat',
    country: 'Afghanistan',
    iata: 'HEA',
    icao: 'OAHR',
    latitude: 34.2099990844727,
    longitude: 62.2282981872559,
    altitude: 3206,
    schengen: false
  },
  {
    id: 8,
    name: 'Bagram Air Base',
    city: 'Kabul',
    country: 'Afghanistan',
    iata: 'OAI',
    icao: 'OAIX',
    latitude: 34.9460983276,
    longitude: 69.2649993896,
    altitude: 4895,
    schengen: false
  },
  {
    id: 9,
    name: 'Jalalabad Airport',
    city: 'Jalalabad',
    country: 'Afghanistan',
    iata: 'JAA',
    icao: 'OAJL',
    latitude: 34.3997993469238,
    longitude: 70.4985961914062,
    altitude: 1814,
    schengen: false
  },
  {
    id: 10,
    name: 'Kabul International Airport',
    city: 'Kabul',
    country: 'Afghanistan',
    iata: 'KBL',
    icao: 'OAKB',
    latitude: 34.5658988952637,
    longitude: 69.2123031616211,
    altitude: 5877,
    schengen: false
  },
  {
    id: 11,
    name: 'Kandahar Airport',
    city: 'Kandahar',
    country: 'Afghanistan',
    iata: 'KDH',
    icao: 'OAKN',
    latitude: 31.5058002471924,
    longitude: 65.8478012084961,
    altitude: 3337,
    schengen: false
  },
  {
    id: 12,
    name: 'Khost Airport',
    city: 'Khost',
    country: 'Afghanistan',
    iata: 'KHT',
    icao: 'OAKS',
    latitude: 33.3334007263,
    longitude: 69.952003479,
    altitude: 3756,
    schengen: false
  },
  {
    id: 13,
    name: 'Maimana Airport',
    city: 'Maimama',
    country: 'Afghanistan',
    iata: 'MMZ',
    icao: 'OAMN',
    latitude: 35.9308013916016,
    longitude: 64.7609024047852,
    altitude: 2743,
    schengen: false
  },
  {
    id: 14,
    name: 'Mazar I Sharif Airport',
    city: 'Mazar-i-sharif',
    country: 'Afghanistan',
    iata: 'MZR',
    icao: 'OAMS',
    latitude: 36.706901550293,
    longitude: 67.2097015380859,
    altitude: 1284,
    schengen: false
  },
  {
    id: 15,
    name: 'Sharana Airstrip',
    city: 'Sharona',
    country: 'Afghanistan',
    iata: 'OAS',
    icao: 'OASA',
    latitude: 33.12575,
    longitude: 68.838517,
    altitude: 7340,
    schengen: false
  },
  {
    id: 16,
    name: 'Shindand Airport',
    city: 'Shindand',
    country: 'Afghanistan',
    iata: 'OAH',
    icao: 'OASD',
    latitude: 33.3913002014,
    longitude: 62.2610015869,
    altitude: 3773,
    schengen: false
  },
  {
    id: 17,
    name: 'Sheberghan Airport',
    city: 'Sheberghan',
    country: 'Afghanistan',
    iata: '',
    icao: 'OASG',
    latitude: 36.7508010864258,
    longitude: 65.913200378418,
    altitude: 1053,
    schengen: false
  },
  {
    id: 18,
    name: 'Tarin Kowt Airport',
    city: 'Tarin Kowt',
    country: 'Afghanistan',
    iata: 'TII',
    icao: 'OATN',
    latitude: 32.6041984558,
    longitude: 65.8657989502,
    altitude: 4429,
    schengen: false
  },
  {
    id: 19,
    name: 'Konduz Airport',
    city: 'Kunduz',
    country: 'Afghanistan',
    iata: 'UND',
    icao: 'OAUZ',
    latitude: 36.6651000976562,
    longitude: 68.9107971191406,
    altitude: 1457,
    schengen: false
  },
  {
    id: 20,
    name: 'Camp Bastion Airport',
    city: 'Camp Bastion',
    country: 'Afghanistan',
    iata: 'OAZ',
    icao: 'OAZI',
    latitude: 31.8638000488,
    longitude: 64.2246017456,
    altitude: 2943,
    schengen: false
  },
  {
    id: 21,
    name: 'Zaranj Airport',
    city: 'Zaranj',
    country: 'Afghanistan',
    iata: 'ZAJ',
    icao: 'OAZJ',
    latitude: 30.972222,
    longitude: 61.865833,
    altitude: 1572,
    schengen: false
  },
  {
    id: 22,
    name: 'Gjadër Air Base',
    city: 'Gjadër',
    country: 'Albania',
    iata: '',
    icao: 'LAGJ',
    latitude: 41.895199,
    longitude: 19.5987,
    altitude: 23,
    schengen: false
  },
  {
    id: 23,
    name: 'Kukës Airport',
    city: 'Kukës',
    country: 'Albania',
    iata: '',
    icao: 'LAKU',
    latitude: 42.0336990356,
    longitude: 20.4158992767,
    altitude: 1120,
    schengen: false
  },
  {
    id: 24,
    name: 'Kuçovë Air Base',
    city: 'Kuçovë',
    country: 'Albania',
    iata: '',
    icao: 'LAKV',
    latitude: 40.771900177002,
    longitude: 19.9018993377686,
    altitude: 135,
    schengen: false
  },
  {
    id: 25,
    name: 'Tirana International Airport Mother Teresa',
    city: 'Tirana',
    country: 'Albania',
    iata: 'TIA',
    icao: 'LATI',
    latitude: 41.4146995544,
    longitude: 19.7206001282,
    altitude: 126,
    schengen: false
  },
  {
    id: 26,
    name: 'Vlorë Air Base',
    city: 'Vlorë',
    country: 'Albania',
    iata: '',
    icao: 'LAVL',
    latitude: 40.4761009216309,
    longitude: 19.4741992950439,
    altitude: 3,
    schengen: false
  },
  {
    id: 27,
    name: 'Blida Airport',
    city: 'Blida',
    country: 'Algeria',
    iata: 'QLD',
    icao: 'DAAB',
    latitude: 36.5036010742187,
    longitude: 2.81416988372803,
    altitude: 535,
    schengen: false
  },
  {
    id: 28,
    name: 'Bou Saada Airport',
    city: 'Bou Saada',
    country: 'Algeria',
    iata: 'BUJ',
    icao: 'DAAD',
    latitude: 35.3325004577637,
    longitude: 4.20638990402222,
    altitude: 1506,
    schengen: false
  },
  {
    id: 29,
    name: 'Soummam Airport',
    city: 'Bejaja',
    country: 'Algeria',
    iata: 'BJA',
    icao: 'DAAE',
    latitude: 36.7120018005,
    longitude: 5.06992006302,
    altitude: 20,
    schengen: false
  },
  {
    id: 30,
    name: 'Houari Boumediene Airport',
    city: 'Algier',
    country: 'Algeria',
    iata: 'ALG',
    icao: 'DAAG',
    latitude: 36.6910018920898,
    longitude: 3.21540999412537,
    altitude: 82,
    schengen: false
  },
  {
    id: 31,
    name: 'Djanet Inedbirene Airport',
    city: 'Djanet',
    country: 'Algeria',
    iata: 'DJG',
    icao: 'DAAJ',
    latitude: 24.292801,
    longitude: 9.45244,
    altitude: 3176,
    schengen: false
  },
  {
    id: 32,
    name: 'Boufarik Airport',
    city: 'Boufarik',
    country: 'Algeria',
    iata: '',
    icao: 'DAAK',
    latitude: 36.545799,
    longitude: 2.87611,
    altitude: 335,
    schengen: false
  },
  {
    id: 33,
    name: 'Reggane Airport',
    city: 'Reggan',
    country: 'Algeria',
    iata: '',
    icao: 'DAAN',
    latitude: 26.7101,
    longitude: 0.285647,
    altitude: 955,
    schengen: false
  },
  {
    id: 34,
    name: 'Illizi Takhamalt Airport',
    city: 'Illizi',
    country: 'Algeria',
    iata: 'VVZ',
    icao: 'DAAP',
    latitude: 26.7234992981,
    longitude: 8.62265014648,
    altitude: 1778,
    schengen: false
  },
  {
    id: 35,
    name: 'Ain Oussera Airport',
    city: 'Ain Oussera',
    country: 'Algeria',
    iata: '',
    icao: 'DAAQ',
    latitude: 35.5253982543945,
    longitude: 2.8787100315094,
    altitude: 2132,
    schengen: false
  },
  {
    id: 36,
    name: 'Ain Arnat Airport',
    city: 'Setif',
    country: 'Algeria',
    iata: 'QSF',
    icao: 'DAAS',
    latitude: 36.1781005859,
    longitude: 5.32449007034,
    altitude: 3360,
    schengen: false
  },
  {
    id: 37,
    name: 'Aguenar – Hadj Bey Akhamok Airport',
    city: 'Tamanrasset',
    country: 'Algeria',
    iata: 'TMR',
    icao: 'DAAT',
    latitude: 22.8115005493,
    longitude: 5.45107984543,
    altitude: 4518,
    schengen: false
  },
  {
    id: 38,
    name: 'Jijel Ferhat Abbas Airport',
    city: 'Jijel',
    country: 'Algeria',
    iata: 'GJL',
    icao: 'DAAV',
    latitude: 36.7951011658,
    longitude: 5.87361001968,
    altitude: 36,
    schengen: false
  },
  {
    id: 39,
    name: 'Mecheria Airport',
    city: 'Mecheria',
    country: 'Algeria',
    iata: 'MZW',
    icao: 'DAAY',
    latitude: 33.535900116,
    longitude: -0.242353007197,
    altitude: 3855,
    schengen: false
  },
  {
    id: 40,
    name: 'Relizane Airport',
    city: 'Relizane',
    country: 'Algeria',
    iata: '',
    icao: 'DAAZ',
    latitude: 35.752201,
    longitude: 0.626272,
    altitude: 282,
    schengen: false
  },
  {
    id: 41,
    name: 'Rabah Bitat Airport',
    city: 'Annaba',
    country: 'Algeria',
    iata: 'AAE',
    icao: 'DABB',
    latitude: 36.822201,
    longitude: 7.809174,
    altitude: 16,
    schengen: false
  },
  {
    id: 42,
    name: 'Mohamed Boudiaf International Airport',
    city: 'Constantine',
    country: 'Algeria',
    iata: 'CZL',
    icao: 'DABC',
    latitude: 36.2760009765625,
    longitude: 6.62038993835449,
    altitude: 2265,
    schengen: false
  },
  {
    id: 43,
    name: 'Cheikh Larbi Tébessi Airport',
    city: 'Tebessa',
    country: 'Algeria',
    iata: 'TEE',
    icao: 'DABS',
    latitude: 35.4315986633,
    longitude: 8.12071990967,
    altitude: 2661,
    schengen: false
  },
  {
    id: 44,
    name: 'Batna Airport',
    city: 'Batna',
    country: 'Algeria',
    iata: 'BLJ',
    icao: 'DABT',
    latitude: 35.7521018982,
    longitude: 6.3085899353,
    altitude: 2697,
    schengen: false
  },
  {
    id: 45,
    name: "Hassi R'Mel Airport",
    city: 'Tilrempt',
    country: 'Algeria',
    iata: 'HRM',
    icao: 'DAFH',
    latitude: 32.9304008483887,
    longitude: 3.31153988838196,
    altitude: 2540,
    schengen: false
  },
  {
    id: 46,
    name: 'Tsletsi Airport',
    city: 'Djelfa',
    country: 'Algeria',
    iata: 'QDJ',
    icao: 'DAFI',
    latitude: 34.6657,
    longitude: 3.351,
    altitude: 3753,
    schengen: false
  },
  {
    id: 47,
    name: 'Bou Chekif Airport',
    city: 'Tiaret',
    country: 'Algeria',
    iata: 'TID',
    icao: 'DAOB',
    latitude: 35.3410987854,
    longitude: 1.46315002441,
    altitude: 3245,
    schengen: false
  },
  {
    id: 48,
    name: 'Bou Sfer Airport',
    city: 'Bou Sfer',
    country: 'Algeria',
    iata: '',
    icao: 'DAOE',
    latitude: 35.7354011535645,
    longitude: -0.805388987064362,
    altitude: 187,
    schengen: false
  },
  {
    id: 49,
    name: 'Tindouf Airport',
    city: 'Tindouf',
    country: 'Algeria',
    iata: 'TIN',
    icao: 'DAOF',
    latitude: 27.7003993988,
    longitude: -8.1670999527,
    altitude: 1453,
    schengen: false
  },
  {
    id: 50,
    name: 'Ech Cheliff Airport',
    city: 'Ech-cheliff',
    country: 'Algeria',
    iata: 'CFK',
    icao: 'DAOI',
    latitude: 36.2126998901,
    longitude: 1.33176994324,
    altitude: 463,
    schengen: false
  },
  {
    id: 51,
    name: 'Tafaraoui Airport',
    city: 'Oran',
    country: 'Algeria',
    iata: 'TAF',
    icao: 'DAOL',
    latitude: 35.5424003601074,
    longitude: -0.532278001308441,
    altitude: 367,
    schengen: false
  },
  {
    id: 52,
    name: 'Zenata – Messali El Hadj Airport',
    city: 'Tlemcen',
    country: 'Algeria',
    iata: 'TLM',
    icao: 'DAON',
    latitude: 35.0167007446,
    longitude: -1.45000004768,
    altitude: 814,
    schengen: false
  },
  {
    id: 53,
    name: 'Es Senia Airport',
    city: 'Oran',
    country: 'Algeria',
    iata: 'ORN',
    icao: 'DAOO',
    latitude: 35.6239013672,
    longitude: -0.621182978153,
    altitude: 295,
    schengen: false
  },
  {
    id: 54,
    name: 'Béchar Boudghene Ben Ali Lotfi Airport',
    city: 'Béchar',
    country: 'Algeria',
    iata: 'CBH',
    icao: 'DAOR',
    latitude: 31.6457004547119,
    longitude: -2.26986002922058,
    altitude: 2661,
    schengen: false
  },
  {
    id: 55,
    name: 'Sidi Bel Abbes Airport',
    city: 'Sidi Bel Abbes',
    country: 'Algeria',
    iata: 'BFW',
    icao: 'DAOS',
    latitude: 35.1717987061,
    longitude: -0.593275010586,
    altitude: 1614,
    schengen: false
  },
  {
    id: 56,
    name: 'Ghriss Airport',
    city: 'Ghriss',
    country: 'Algeria',
    iata: 'MUW',
    icao: 'DAOV',
    latitude: 35.2076988220215,
    longitude: 0.147141993045807,
    altitude: 1686,
    schengen: false
  },
  {
    id: 57,
    name: 'El Bayadh Airport',
    city: 'El Bayadh',
    country: 'Algeria',
    iata: 'EBH',
    icao: 'DAOY',
    latitude: 33.7216666667,
    longitude: 1.0925,
    altitude: 4493,
    schengen: false
  },
  {
    id: 58,
    name: 'Bordj Badji Mokhtar Airport',
    city: 'Bordj Badji Mokhtar',
    country: 'Algeria',
    iata: 'BMW',
    icao: 'DATM',
    latitude: 21.375,
    longitude: 0.923888981342,
    altitude: 1303,
    schengen: false
  },
  {
    id: 59,
    name: 'Touat Cheikh Sidi Mohamed Belkebir Airport',
    city: 'Adrar',
    country: 'Algeria',
    iata: 'AZR',
    icao: 'DAUA',
    latitude: 27.8376007080078,
    longitude: -0.186414003372192,
    altitude: 919,
    schengen: false
  },
  {
    id: 60,
    name: 'Biskra Airport',
    city: 'Biskra',
    country: 'Algeria',
    iata: 'BSK',
    icao: 'DAUB',
    latitude: 34.7933006287,
    longitude: 5.73823022842,
    altitude: 289,
    schengen: false
  },
  {
    id: 61,
    name: 'El Golea Airport',
    city: 'El Golea',
    country: 'Algeria',
    iata: 'ELG',
    icao: 'DAUE',
    latitude: 30.5713005065918,
    longitude: 2.85959005355835,
    altitude: 1306,
    schengen: false
  },
  {
    id: 62,
    name: 'Noumérat - Moufdi Zakaria Airport',
    city: 'Ghardaia',
    country: 'Algeria',
    iata: 'GHA',
    icao: 'DAUG',
    latitude: 32.3841018676758,
    longitude: 3.79411005973816,
    altitude: 1512,
    schengen: false
  },
  {
    id: 63,
    name: 'Oued Irara Airport',
    city: 'Hassi Messaoud',
    country: 'Algeria',
    iata: 'HME',
    icao: 'DAUH',
    latitude: 31.6730003357,
    longitude: 6.14043998718,
    altitude: 463,
    schengen: false
  },
  {
    id: 64,
    name: 'In Salah Airport',
    city: 'In Salah',
    country: 'Algeria',
    iata: 'INZ',
    icao: 'DAUI',
    latitude: 27.2509994507,
    longitude: 2.51202011108,
    altitude: 896,
    schengen: false
  },
  {
    id: 65,
    name: 'Touggourt Sidi Madhi Airport',
    city: 'Touggourt',
    country: 'Algeria',
    iata: 'TGR',
    icao: 'DAUK',
    latitude: 33.067798614502,
    longitude: 6.0886697769165,
    altitude: 279,
    schengen: false
  },
  {
    id: 66,
    name: 'Laghouat Airport',
    city: 'Laghouat',
    country: 'Algeria',
    iata: 'LOO',
    icao: 'DAUL',
    latitude: 33.7644004822,
    longitude: 2.92833995819,
    altitude: 2510,
    schengen: false
  },
  {
    id: 67,
    name: 'Guemar Airport',
    city: 'Guemar',
    country: 'Algeria',
    iata: 'ELU',
    icao: 'DAUO',
    latitude: 33.5113983154,
    longitude: 6.77679014206,
    altitude: 203,
    schengen: false
  },
  {
    id: 68,
    name: 'Timimoun Airport',
    city: 'Timimoun',
    country: 'Algeria',
    iata: 'TMX',
    icao: 'DAUT',
    latitude: 29.2371006012,
    longitude: 0.276033014059,
    altitude: 1027,
    schengen: false
  },
  {
    id: 69,
    name: 'Ain el Beida Airport',
    city: 'Ouargla',
    country: 'Algeria',
    iata: 'OGX',
    icao: 'DAUU',
    latitude: 31.917200088501,
    longitude: 5.41277980804443,
    altitude: 492,
    schengen: false
  },
  {
    id: 70,
    name: 'In Aménas Airport',
    city: 'Zarzaitine',
    country: 'Algeria',
    iata: 'IAM',
    icao: 'DAUZ',
    latitude: 28.0515,
    longitude: 9.64291,
    altitude: 1847,
    schengen: false
  },
  {
    id: 71,
    name: 'Fitiuta Airport',
    city: "Fiti\\'uta",
    country: 'American Samoa',
    iata: 'FTI',
    icao: 'NSFQ',
    latitude: -14.2172,
    longitude: -169.425003,
    altitude: 110,
    schengen: false
  },
  {
    id: 72,
    name: 'Pago Pago International Airport',
    city: 'Pago Pago',
    country: 'American Samoa',
    iata: 'PPG',
    icao: 'NSTU',
    latitude: -14.3310003281,
    longitude: -170.710006714,
    altitude: 32,
    schengen: false
  },
  {
    id: 73,
    name: 'Mbanza Congo Airport',
    city: "M'banza-congo",
    country: 'Angola',
    iata: 'SSY',
    icao: 'FNBC',
    latitude: -6.26989984512329,
    longitude: 14.2469997406006,
    altitude: 1860,
    schengen: false
  },
  {
    id: 74,
    name: 'Benguela Airport',
    city: 'Benguela',
    country: 'Angola',
    iata: 'BUG',
    icao: 'FNBG',
    latitude: -12.609000206,
    longitude: 13.4036998749,
    altitude: 118,
    schengen: false
  },
  {
    id: 75,
    name: 'Cabinda Airport',
    city: 'Cabinda',
    country: 'Angola',
    iata: 'CAB',
    icao: 'FNCA',
    latitude: -5.59699010848999,
    longitude: 12.1884002685547,
    altitude: 66,
    schengen: false
  },
  {
    id: 76,
    name: 'Catumbela Airport',
    city: 'Catumbela',
    country: 'Angola',
    iata: 'CBT',
    icao: 'FNCT',
    latitude: -12.4792,
    longitude: 13.4869,
    altitude: 23,
    schengen: false
  },
  {
    id: 77,
    name: 'Dundo Airport',
    city: 'Dundo',
    country: 'Angola',
    iata: 'DUE',
    icao: 'FNDU',
    latitude: -7.40088987350464,
    longitude: 20.8185005187988,
    altitude: 2451,
    schengen: false
  },
  {
    id: 78,
    name: 'Ngjiva Pereira Airport',
    city: 'Ondjiva',
    country: 'Angola',
    iata: 'VPE',
    icao: 'FNGI',
    latitude: -17.0435009003,
    longitude: 15.6837997437,
    altitude: 3566,
    schengen: false
  },
  {
    id: 79,
    name: 'Nova Lisboa Airport',
    city: 'Huambo',
    country: 'Angola',
    iata: 'NOV',
    icao: 'FNHU',
    latitude: -12.8088998794556,
    longitude: 15.7604999542236,
    altitude: 5587,
    schengen: false
  },
  {
    id: 80,
    name: 'Kuito Airport',
    city: 'Kuito',
    country: 'Angola',
    iata: 'SVP',
    icao: 'FNKU',
    latitude: -12.404600143433,
    longitude: 16.947399139404,
    altitude: 5618,
    schengen: false
  },
  {
    id: 81,
    name: 'Lobito Airport',
    city: 'Lobito',
    country: 'Angola',
    iata: '',
    icao: 'FNLB',
    latitude: -12.3711996078491,
    longitude: 13.536600112915,
    altitude: 10,
    schengen: false
  },
  {
    id: 82,
    name: 'Lucapa Airport',
    city: 'Lucapa',
    country: 'Angola',
    iata: 'LBZ',
    icao: 'FNLK',
    latitude: -8.44572734833,
    longitude: 20.7320861816,
    altitude: 3029,
    schengen: false
  },
  {
    id: 83,
    name: 'Quatro de Fevereiro Airport',
    city: 'Luanda',
    country: 'Angola',
    iata: 'LAD',
    icao: 'FNLU',
    latitude: -8.85836982727,
    longitude: 13.2312002182,
    altitude: 243,
    schengen: false
  },
  {
    id: 84,
    name: 'Malanje Airport',
    city: 'Malanje',
    country: 'Angola',
    iata: 'MEG',
    icao: 'FNMA',
    latitude: -9.52509021759033,
    longitude: 16.3124008178711,
    altitude: 3868,
    schengen: false
  },
  {
    id: 85,
    name: 'Menongue Airport',
    city: 'Menongue',
    country: 'Angola',
    iata: 'SPP',
    icao: 'FNME',
    latitude: -14.657600402832,
    longitude: 17.7198009490967,
    altitude: 4469,
    schengen: false
  },
  {
    id: 86,
    name: 'Namibe Airport',
    city: 'Mocamedes',
    country: 'Angola',
    iata: 'MSZ',
    icao: 'FNMO',
    latitude: -15.2611999511719,
    longitude: 12.1468000411987,
    altitude: 210,
    schengen: false
  },
  {
    id: 87,
    name: 'Negage Airport',
    city: 'Negage',
    country: 'Angola',
    iata: 'GXG',
    icao: 'FNNG',
    latitude: -7.75450992584229,
    longitude: 15.2876996994019,
    altitude: 4105,
    schengen: false
  },
  {
    id: 88,
    name: 'Porto Amboim Airport',
    city: 'Porto Amboim',
    country: 'Angola',
    iata: 'PBN',
    icao: 'FNPA',
    latitude: -10.7220001220703,
    longitude: 13.7655000686646,
    altitude: 16,
    schengen: false
  },
  {
    id: 89,
    name: 'Saurimo Airport',
    city: 'Saurimo',
    country: 'Angola',
    iata: 'VHC',
    icao: 'FNSA',
    latitude: -9.68906974792481,
    longitude: 20.4319000244141,
    altitude: 3584,
    schengen: false
  },
  {
    id: 90,
    name: 'Soyo Airport',
    city: 'Soyo',
    country: 'Angola',
    iata: 'SZA',
    icao: 'FNSO',
    latitude: -6.14108991622925,
    longitude: 12.3718004226685,
    altitude: 15,
    schengen: false
  },
  {
    id: 91,
    name: 'Sumbe Airport',
    city: 'Sumbe',
    country: 'Angola',
    iata: 'NDD',
    icao: 'FNSU',
    latitude: -11.1679000854492,
    longitude: 13.8474998474121,
    altitude: 36,
    schengen: false
  },
  {
    id: 92,
    name: 'Lubango Airport',
    city: 'Lubango',
    country: 'Angola',
    iata: 'SDD',
    icao: 'FNUB',
    latitude: -14.9246997833252,
    longitude: 13.5749998092651,
    altitude: 5778,
    schengen: false
  },
  {
    id: 93,
    name: 'Luena Airport',
    city: 'Luena',
    country: 'Angola',
    iata: 'LUO',
    icao: 'FNUE',
    latitude: -11.7680997848511,
    longitude: 19.8976993560791,
    altitude: 4360,
    schengen: false
  },
  {
    id: 94,
    name: 'Uige Airport',
    city: 'Uige',
    country: 'Angola',
    iata: 'UGO',
    icao: 'FNUG',
    latitude: -7.60306978225708,
    longitude: 15.0277996063232,
    altitude: 2720,
    schengen: false
  },
  {
    id: 95,
    name: 'Xangongo Airport',
    city: 'Xangongo',
    country: 'Angola',
    iata: 'XGN',
    icao: 'FNXA',
    latitude: -16.7553997039795,
    longitude: 14.9652996063232,
    altitude: 3635,
    schengen: false
  },
  {
    id: 96,
    name: 'Nzagi Airport',
    city: 'Nzagi',
    country: 'Angola',
    iata: 'NZA',
    icao: 'FNZG',
    latitude: -7.71693992615,
    longitude: 21.3582000732,
    altitude: 2431,
    schengen: false
  },
  {
    id: 97,
    name: 'Clayton J Lloyd International Airport',
    city: 'The Valley',
    country: 'Anguilla',
    iata: 'AXA',
    icao: 'TQPF',
    latitude: 18.2048,
    longitude: -63.055099,
    altitude: 127,
    schengen: false
  },
  {
    id: 98,
    name: 'McMurdo Station Ice Runway',
    city: 'Ross Island',
    country: 'Antarctica',
    iata: '',
    icao: 'NZIR',
    latitude: -77.8539962768555,
    longitude: 166.468994140625,
    altitude: 1,
    schengen: false
  },
  {
    id: 99,
    name: 'McMurdo Station Pegasus Field',
    city: 'McMurdo Station',
    country: 'Antarctica',
    iata: '',
    icao: 'NZPG',
    latitude: -77.9634017944336,
    longitude: 166.524993896484,
    altitude: 18,
    schengen: false
  },
  {
    id: 100,
    name: 'South Pole Station Airport',
    city: "Stephen's Island",
    country: 'Antarctica',
    iata: '',
    icao: 'NZSP',
    latitude: -90,
    longitude: 0,
    altitude: 9300,
    schengen: false
  },
  {
    id: 101,
    name: 'Williams Field',
    city: 'Weydon',
    country: 'Antarctica',
    iata: '',
    icao: 'NZWD',
    latitude: -77.8674011230469,
    longitude: 167.057006835938,
    altitude: 68,
    schengen: false
  },
  {
    id: 102,
    name: 'Marambio Base',
    city: 'Marambio Base',
    country: 'Antarctica',
    iata: '',
    icao: 'SAWB',
    latitude: -64.2382965088,
    longitude: -56.6307983398,
    altitude: 760,
    schengen: false
  },
  {
    id: 103,
    name: 'Teniente Rodolfo Marsh Martin Base',
    city: 'Isla Rey Jorge',
    country: 'Antarctica',
    iata: 'TNM',
    icao: 'SCRM',
    latitude: -62.1907997131,
    longitude: -58.9866981506,
    altitude: 147,
    schengen: false
  },
  {
    id: 104,
    name: 'Wilkins Runway',
    city: 'Budd Coast',
    country: 'Antarctica',
    iata: '',
    icao: 'YWKS',
    latitude: -66.690833,
    longitude: 111.523611,
    altitude: 2529,
    schengen: false
  },
  {
    id: 105,
    name: 'V.C. Bird International Airport',
    city: 'Antigua',
    country: 'Antigua and Barbuda',
    iata: 'ANU',
    icao: 'TAPA',
    latitude: 17.1367,
    longitude: -61.792702,
    altitude: 62,
    schengen: false
  },
  {
    id: 106,
    name: 'Codrington Airport',
    city: 'Codrington',
    country: 'Antigua and Barbuda',
    iata: 'BBQ',
    icao: 'TAPH',
    latitude: 17.635799,
    longitude: -61.828602,
    altitude: 15,
    schengen: false
  },
  {
    id: 107,
    name: 'Comodoro Pierrestegui Airport',
    city: 'Concordia',
    country: 'Argentina',
    iata: 'COC',
    icao: 'SAAC',
    latitude: -31.2969,
    longitude: -57.9966,
    altitude: 112,
    schengen: false
  },
  {
    id: 108,
    name: 'Gualeguaychu Airport',
    city: 'Gualeguaychu',
    country: 'Argentina',
    iata: 'GHU',
    icao: 'SAAG',
    latitude: -33.0103,
    longitude: -58.6131,
    altitude: 75,
    schengen: false
  },
  {
    id: 109,
    name: 'Junin Airport',
    city: 'Junin',
    country: 'Argentina',
    iata: 'JNI',
    icao: 'SAAJ',
    latitude: -34.5459,
    longitude: -60.9306,
    altitude: 262,
    schengen: false
  },
  {
    id: 110,
    name: 'General Urquiza Airport',
    city: 'Parana',
    country: 'Argentina',
    iata: 'PRA',
    icao: 'SAAP',
    latitude: -31.7948,
    longitude: -60.4804,
    altitude: 242,
    schengen: false
  },
  {
    id: 111,
    name: 'Islas Malvinas Airport',
    city: 'Rosario',
    country: 'Argentina',
    iata: 'ROS',
    icao: 'SAAR',
    latitude: -32.9036,
    longitude: -60.785,
    altitude: 85,
    schengen: false
  },
  {
    id: 112,
    name: 'Sauce Viejo Airport',
    city: 'Santa Fe',
    country: 'Argentina',
    iata: 'SFN',
    icao: 'SAAV',
    latitude: -31.7117,
    longitude: -60.8117,
    altitude: 55,
    schengen: false
  },
  {
    id: 113,
    name: 'Jorge Newbery Airpark',
    city: 'Buenos Aires',
    country: 'Argentina',
    iata: 'AEP',
    icao: 'SABE',
    latitude: -34.5592,
    longitude: -58.4156,
    altitude: 18,
    schengen: false
  },
  {
    id: 114,
    name: 'Ingeniero Ambrosio Taravella Airport',
    city: 'Cordoba',
    country: 'Argentina',
    iata: 'COR',
    icao: 'SACO',
    latitude: -31.323601,
    longitude: -64.208,
    altitude: 1604,
    schengen: false
  },
  {
    id: 115,
    name: 'Chamical Airport',
    city: 'Gobernador Gordillo',
    country: 'Argentina',
    iata: '',
    icao: 'SACT',
    latitude: -30.34530067,
    longitude: -66.29360198,
    altitude: 1502,
    schengen: false
  },
  {
    id: 116,
    name: 'San Fernando Airport',
    city: 'San Fernando',
    country: 'Argentina',
    iata: 'FDO',
    icao: 'SADF',
    latitude: -34.4532,
    longitude: -58.5896,
    altitude: 10,
    schengen: false
  },
  {
    id: 117,
    name: 'Mariano Moreno Airport',
    city: 'Jose C. Paz',
    country: 'Argentina',
    iata: '',
    icao: 'SADJ',
    latitude: -34.5606,
    longitude: -58.7896,
    altitude: 105,
    schengen: false
  },
  {
    id: 118,
    name: 'La Plata Airport',
    city: 'La Plata',
    country: 'Argentina',
    iata: 'LPG',
    icao: 'SADL',
    latitude: -34.9722,
    longitude: -57.8947,
    altitude: 72,
    schengen: false
  },
  {
    id: 119,
    name: 'Moron Airport',
    city: 'Moron',
    country: 'Argentina',
    iata: '',
    icao: 'SADM',
    latitude: -34.6763,
    longitude: -58.6428,
    altitude: 95,
    schengen: false
  },
  {
    id: 120,
    name: 'El Palomar Airport',
    city: 'El Palomar',
    country: 'Argentina',
    iata: 'EPA',
    icao: 'SADP',
    latitude: -34.6099,
    longitude: -58.6126,
    altitude: 59,
    schengen: false
  },
  {
    id: 121,
    name: 'Ministro Pistarini International Airport',
    city: 'Buenos Aires',
    country: 'Argentina',
    iata: 'EZE',
    icao: 'SAEZ',
    latitude: -34.8222,
    longitude: -58.5358,
    altitude: 67,
    schengen: false
  },
  {
    id: 122,
    name: 'Chos Malal Airport',
    city: 'Chosmadal',
    country: 'Argentina',
    iata: 'HOS',
    icao: 'SAHC',
    latitude: -37.4446983337,
    longitude: -70.2225036621,
    altitude: 2788,
    schengen: false
  },
  {
    id: 123,
    name: 'Dr. Arturo H. Illia Airport',
    city: 'Fuerte Gral Roca',
    country: 'Argentina',
    iata: 'GNR',
    icao: 'SAHR',
    latitude: -39.0007019043,
    longitude: -67.6204986572,
    altitude: 852,
    schengen: false
  },
  {
    id: 124,
    name: 'Rincon De Los Sauces Airport',
    city: 'Rincon de los Sauces',
    country: 'Argentina',
    iata: 'RDS',
    icao: 'SAHS',
    latitude: -37.3905982971,
    longitude: -68.9041976929,
    altitude: 1968,
    schengen: false
  },
  {
    id: 125,
    name: 'Zapala Airport',
    city: 'ZAPALA',
    country: 'Argentina',
    iata: 'APZ',
    icao: 'SAHZ',
    latitude: -38.975498,
    longitude: -70.113602,
    altitude: 3330,
    schengen: false
  },
  {
    id: 126,
    name: 'El Plumerillo Airport',
    city: 'Mendoza',
    country: 'Argentina',
    iata: 'MDZ',
    icao: 'SAME',
    latitude: -32.8316993713,
    longitude: -68.7929000854,
    altitude: 2310,
    schengen: false
  },
  {
    id: 127,
    name: 'Comodoro D.R. Salomón Airport',
    city: 'Malargue',
    country: 'Argentina',
    iata: 'LGS',
    icao: 'SAMM',
    latitude: -35.493598938,
    longitude: -69.5743026733,
    altitude: 4685,
    schengen: false
  },
  {
    id: 128,
    name: 'Suboficial Ay Santiago Germano Airport',
    city: 'San Rafael',
    country: 'Argentina',
    iata: 'AFA',
    icao: 'SAMR',
    latitude: -34.588299,
    longitude: -68.4039,
    altitude: 2470,
    schengen: false
  },
  {
    id: 129,
    name: 'Catamarca Airport',
    city: 'Catamarca',
    country: 'Argentina',
    iata: 'CTC',
    icao: 'SANC',
    latitude: -28.5956001282,
    longitude: -65.751701355,
    altitude: 1522,
    schengen: false
  },
  {
    id: 130,
    name: 'Vicecomodoro Angel D. La Paz Aragonés Airport',
    city: 'Santiago Del Estero',
    country: 'Argentina',
    iata: 'SDE',
    icao: 'SANE',
    latitude: -27.7655563354,
    longitude: -64.3099975586,
    altitude: 656,
    schengen: false
  },
  {
    id: 131,
    name: 'Tinogasta Airport',
    city: 'Tinogasta',
    country: 'Argentina',
    iata: '',
    icao: 'SANI',
    latitude: -28.0377998352,
    longitude: -67.5802993774,
    altitude: 3968,
    schengen: false
  },
  {
    id: 132,
    name: 'Capitan V A Almonacid Airport',
    city: 'La Rioja',
    country: 'Argentina',
    iata: 'IRJ',
    icao: 'SANL',
    latitude: -29.3815994263,
    longitude: -66.7957992554,
    altitude: 1437,
    schengen: false
  },
  {
    id: 133,
    name: 'Chilecito Airport',
    city: 'Chilecito',
    country: 'Argentina',
    iata: '',
    icao: 'SANO',
    latitude: -29.2238998413,
    longitude: -67.4389038086,
    altitude: 3099,
    schengen: false
  },
  {
    id: 134,
    name: 'Termas de Río Hondo international Airport',
    city: 'Rio Hondo',
    country: 'Argentina',
    iata: 'RHD',
    icao: 'SANR',
    latitude: -27.4966,
    longitude: -64.93595,
    altitude: 935,
    schengen: false
  },
  {
    id: 135,
    name: 'Teniente Benjamin Matienzo Airport',
    city: 'Tucuman',
    country: 'Argentina',
    iata: 'TUC',
    icao: 'SANT',
    latitude: -26.8409,
    longitude: -65.104897,
    altitude: 1493,
    schengen: false
  },
  {
    id: 136,
    name: 'Domingo Faustino Sarmiento Airport',
    city: 'San Julian',
    country: 'Argentina',
    iata: 'UAQ',
    icao: 'SANU',
    latitude: -31.571501,
    longitude: -68.418198,
    altitude: 1958,
    schengen: false
  },
  {
    id: 137,
    name: 'Area De Material Airport',
    city: 'Rio Cuarto',
    country: 'Argentina',
    iata: 'RCU',
    icao: 'SAOC',
    latitude: -33.0850982666,
    longitude: -64.2612991333,
    altitude: 1380,
    schengen: false
  },
  {
    id: 138,
    name: 'Villa Dolores Airport',
    city: 'Villa Dolores',
    country: 'Argentina',
    iata: 'VDR',
    icao: 'SAOD',
    latitude: -31.9451999664,
    longitude: -65.1463012695,
    altitude: 1847,
    schengen: false
  },
  {
    id: 139,
    name: 'La Quiaca Airport',
    city: 'Laboulaye',
    country: 'Argentina',
    iata: '',
    icao: 'SAOL',
    latitude: -22.1506004333,
    longitude: -65.5774993896,
    altitude: 11414,
    schengen: false
  },
  {
    id: 140,
    name: 'Marcos Juarez Airport',
    city: 'Marcos Juarez',
    country: 'Argentina',
    iata: '',
    icao: 'SAOM',
    latitude: -32.6836,
    longitude: -62.157801,
    altitude: 360,
    schengen: false
  },
  {
    id: 141,
    name: 'Villa Reynolds Airport',
    city: 'Villa Reynolds',
    country: 'Argentina',
    iata: 'VME',
    icao: 'SAOR',
    latitude: -33.7299003601,
    longitude: -65.3873977661,
    altitude: 1591,
    schengen: false
  },
  {
    id: 142,
    name: 'Brigadier Mayor D Cesar Raul Ojeda Airport',
    city: 'San Luis',
    country: 'Argentina',
    iata: 'LUQ',
    icao: 'SAOU',
    latitude: -33.2732009888,
    longitude: -66.3563995361,
    altitude: 2328,
    schengen: false
  },
  {
    id: 143,
    name: 'Presidente Néstor Kirchner Regionsl Airport',
    city: 'Villa Maria',
    country: 'Argentina',
    iata: '',
    icao: 'SAOV',
    latitude: -32.3201,
    longitude: -63.22663,
    altitude: 670,
    schengen: false
  },
  {
    id: 144,
    name: 'Corrientes Airport',
    city: 'Corrientes',
    country: 'Argentina',
    iata: 'CNQ',
    icao: 'SARC',
    latitude: -27.4455,
    longitude: -58.7619,
    altitude: 202,
    schengen: false
  },
  {
    id: 145,
    name: 'Resistencia International Airport',
    city: 'Resistencia',
    country: 'Argentina',
    iata: 'RES',
    icao: 'SARE',
    latitude: -27.45,
    longitude: -59.0561,
    altitude: 173,
    schengen: false
  },
  {
    id: 146,
    name: 'Formosa Airport',
    city: 'Formosa',
    country: 'Argentina',
    iata: 'FMA',
    icao: 'SARF',
    latitude: -26.2127,
    longitude: -58.2281,
    altitude: 193,
    schengen: false
  },
  {
    id: 147,
    name: 'Cataratas Del Iguazú International Airport',
    city: 'Iguazu Falls',
    country: 'Argentina',
    iata: 'IGR',
    icao: 'SARI',
    latitude: -25.737301,
    longitude: -54.4734,
    altitude: 916,
    schengen: false
  },
  {
    id: 148,
    name: 'Paso De Los Libres Airport',
    city: 'Paso De Los Libres',
    country: 'Argentina',
    iata: 'AOL',
    icao: 'SARL',
    latitude: -29.6894,
    longitude: -57.1521,
    altitude: 230,
    schengen: false
  },
  {
    id: 149,
    name: 'Monte Caseros Airport',
    city: 'Monte Caseros',
    country: 'Argentina',
    iata: 'MCS',
    icao: 'SARM',
    latitude: -30.2719,
    longitude: -57.6402,
    altitude: 170,
    schengen: false
  },
  {
    id: 150,
    name: 'Libertador Gral D Jose De San Martin Airport',
    city: 'Posadas',
    country: 'Argentina',
    iata: 'PSS',
    icao: 'SARP',
    latitude: -27.3858,
    longitude: -55.9707,
    altitude: 430,
    schengen: false
  },
  {
    id: 151,
    name: 'Martin Miguel De Guemes International Airport',
    city: 'Salta',
    country: 'Argentina',
    iata: 'SLA',
    icao: 'SASA',
    latitude: -24.8560009003,
    longitude: -65.4861984253,
    altitude: 4088,
    schengen: false
  },
  {
    id: 152,
    name: 'Gobernador Horacio Guzman International Airport',
    city: 'Jujuy',
    country: 'Argentina',
    iata: 'JUJ',
    icao: 'SASJ',
    latitude: -24.392799,
    longitude: -65.097801,
    altitude: 3019,
    schengen: false
  },
  {
    id: 153,
    name: 'Orán Airport',
    city: 'Oran',
    country: 'Argentina',
    iata: 'ORA',
    icao: 'SASO',
    latitude: -23.1527996063,
    longitude: -64.3292007446,
    altitude: 1171,
    schengen: false
  },
  {
    id: 154,
    name: 'Laboulaye Airport',
    city: 'La Quiaca',
    country: 'Argentina',
    iata: '',
    icao: 'SASQ',
    latitude: -34.1353988647,
    longitude: -63.3623008728,
    altitude: 449,
    schengen: false
  },
  {
    id: 155,
    name: 'General Enrique Mosconi Airport',
    city: 'Tartagal',
    country: 'Argentina',
    iata: 'TTG',
    icao: 'SAST',
    latitude: -22.619600296,
    longitude: -63.7937011719,
    altitude: 1472,
    schengen: false
  },
  {
    id: 156,
    name: 'El Dorado Airport',
    city: 'El Dorado',
    country: 'Argentina',
    iata: 'ELO',
    icao: 'SATD',
    latitude: -26.3974990845,
    longitude: -54.5746994019,
    altitude: 685,
    schengen: false
  },
  {
    id: 157,
    name: 'Goya Airport',
    city: 'Goya',
    country: 'Argentina',
    iata: 'OYA',
    icao: 'SATG',
    latitude: -29.1058,
    longitude: -59.2189,
    altitude: 128,
    schengen: false
  },
  {
    id: 158,
    name: 'Oberá Airport',
    city: 'Obera',
    country: 'Argentina',
    iata: '',
    icao: 'SATO',
    latitude: -27.5181999207,
    longitude: -55.1241989136,
    altitude: 1125,
    schengen: false
  },
  {
    id: 159,
    name: 'Reconquista Airport',
    city: 'Reconquista',
    country: 'Argentina',
    iata: 'RCQ',
    icao: 'SATR',
    latitude: -29.2103,
    longitude: -59.68,
    altitude: 160,
    schengen: false
  },
  {
    id: 160,
    name: 'Curuzu Cuatia Airport',
    city: 'Curuzu Cuatia',
    country: 'Argentina',
    iata: 'UZU',
    icao: 'SATU',
    latitude: -29.7706,
    longitude: -57.9789,
    altitude: 229,
    schengen: false
  },
  {
    id: 161,
    name: 'El Bolson Airport',
    city: 'El Bolson',
    country: 'Argentina',
    iata: 'EHL',
    icao: 'SAVB',
    latitude: -41.9431991577,
    longitude: -71.5323028564,
    altitude: 1141,
    schengen: false
  },
  {
    id: 162,
    name: 'General E. Mosconi Airport',
    city: 'Comodoro Rivadavia',
    country: 'Argentina',
    iata: 'CRD',
    icao: 'SAVC',
    latitude: -45.7853,
    longitude: -67.4655,
    altitude: 189,
    schengen: false
  },
  {
    id: 163,
    name: 'Brigadier Antonio Parodi Airport',
    city: 'Esquel',
    country: 'Argentina',
    iata: 'EQS',
    icao: 'SAVE',
    latitude: -42.908000946,
    longitude: -71.139503479,
    altitude: 2621,
    schengen: false
  },
  {
    id: 164,
    name: 'Las Heras Airport',
    city: 'Las Heras',
    country: 'Argentina',
    iata: 'LHS',
    icao: 'SAVH',
    latitude: -46.5382995605,
    longitude: -68.9653015137,
    altitude: 1082,
    schengen: false
  },
  {
    id: 165,
    name: 'Cabo F.A.A. H. R. Bordón Airport',
    city: 'Ingeniero Jacobacci',
    country: 'Argentina',
    iata: 'IGB',
    icao: 'SAVJ',
    latitude: -41.3208999634,
    longitude: -69.5748977661,
    altitude: 2925,
    schengen: false
  },
  {
    id: 166,
    name: 'Antoine de Saint Exupéry Airport',
    city: 'San Antonio Oeste',
    country: 'Argentina',
    iata: 'OES',
    icao: 'SAVN',
    latitude: -40.7512,
    longitude: -65.0343,
    altitude: 85,
    schengen: false
  },
  {
    id: 167,
    name: 'D. Casimiro Szlapelis Airport',
    city: 'Alto Rio Senguer',
    country: 'Argentina',
    iata: 'ARR',
    icao: 'SAVR',
    latitude: -45.013599,
    longitude: -70.812202,
    altitude: 2286,
    schengen: false
  },
  {
    id: 168,
    name: 'Sierra Grande Airport',
    city: 'Sierra Grande',
    country: 'Argentina',
    iata: 'SGV',
    icao: 'SAVS',
    latitude: -41.5917015076,
    longitude: -65.3394012451,
    altitude: 688,
    schengen: false
  },
  {
    id: 169,
    name: 'Almirante Marco Andres Zar Airport',
    city: 'Trelew',
    country: 'Argentina',
    iata: 'REL',
    icao: 'SAVT',
    latitude: -43.2105,
    longitude: -65.2703,
    altitude: 141,
    schengen: false
  },
  {
    id: 170,
    name: 'Gobernador Castello Airport',
    city: 'Viedma',
    country: 'Argentina',
    iata: 'VDM',
    icao: 'SAVV',
    latitude: -40.8692,
    longitude: -63.0004,
    altitude: 20,
    schengen: false
  },
  {
    id: 171,
    name: 'El Tehuelche Airport',
    city: 'Puerto Madryn',
    country: 'Argentina',
    iata: 'PMY',
    icao: 'SAVY',
    latitude: -42.7592,
    longitude: -65.1027,
    altitude: 427,
    schengen: false
  },
  {
    id: 172,
    name: 'Lago Argentino Airport',
    city: 'El Calafate',
    country: 'Argentina',
    iata: 'ING',
    icao: 'SAWA',
    latitude: -50.336102,
    longitude: -72.248596,
    altitude: 732,
    schengen: false
  },
  {
    id: 173,
    name: 'El Calafate Airport',
    city: 'El Calafate',
    country: 'Argentina',
    iata: 'FTE',
    icao: 'SAWC',
    latitude: -50.2803,
    longitude: -72.053101,
    altitude: 669,
    schengen: false
  },
  {
    id: 174,
    name: 'Puerto Deseado Airport',
    city: 'Puerto Deseado',
    country: 'Argentina',
    iata: 'PUD',
    icao: 'SAWD',
    latitude: -47.7353,
    longitude: -65.9041,
    altitude: 268,
    schengen: false
  },
  {
    id: 175,
    name: 'Hermes Quijada International Airport',
    city: 'Rio Grande',
    country: 'Argentina',
    iata: 'RGA',
    icao: 'SAWE',
    latitude: -53.7777,
    longitude: -67.7494,
    altitude: 65,
    schengen: false
  },
  {
    id: 176,
    name: 'Piloto Civil N. Fernández Airport',
    city: 'Rio Gallegos',
    country: 'Argentina',
    iata: 'RGL',
    icao: 'SAWG',
    latitude: -51.6089,
    longitude: -69.3126,
    altitude: 61,
    schengen: false
  },
  {
    id: 177,
    name: 'Malvinas Argentinas Airport',
    city: 'Ushuaia',
    country: 'Argentina',
    iata: 'USH',
    icao: 'SAWH',
    latitude: -54.8433,
    longitude: -68.2958,
    altitude: 102,
    schengen: false
  },
  {
    id: 178,
    name: 'Capitan D Daniel Vazquez Airport',
    city: 'San Julian',
    country: 'Argentina',
    iata: 'ULA',
    icao: 'SAWJ',
    latitude: -49.3068,
    longitude: -67.8026,
    altitude: 203,
    schengen: false
  },
  {
    id: 179,
    name: 'Tolwin Observatory Airport',
    city: 'Tolhuin',
    country: 'Argentina',
    iata: '',
    icao: 'SAWL',
    latitude: -54.521900177,
    longitude: -67.1989974976,
    altitude: 90,
    schengen: false
  },
  {
    id: 180,
    name: 'Perito Moreno Airport',
    city: 'Perito Moreno',
    country: 'Argentina',
    iata: 'PMQ',
    icao: 'SAWP',
    latitude: -46.537899,
    longitude: -70.978699,
    altitude: 1410,
    schengen: false
  },
  {
    id: 181,
    name: 'Gobernador Gregores Airport',
    city: 'Gobernador Gregores',
    country: 'Argentina',
    iata: 'GGS',
    icao: 'SAWR',
    latitude: -48.7831,
    longitude: -70.150002,
    altitude: 356,
    schengen: false
  },
  {
    id: 182,
    name: 'Jose De San Martin Airport',
    city: 'Jose de San Martin',
    country: 'Argentina',
    iata: 'JSM',
    icao: 'SAWS',
    latitude: -44.0485992432,
    longitude: -70.4589004517,
    altitude: 2407,
    schengen: false
  },
  {
    id: 183,
    name: 'Santa Cruz Airport',
    city: 'Santa Cruz',
    country: 'Argentina',
    iata: 'RZA',
    icao: 'SAWU',
    latitude: -50.0165,
    longitude: -68.5792,
    altitude: 364,
    schengen: false
  },
  {
    id: 184,
    name: 'Comandante Espora Airport',
    city: 'Bahia Blanca',
    country: 'Argentina',
    iata: 'BHI',
    icao: 'SAZB',
    latitude: -38.725,
    longitude: -62.1693,
    altitude: 246,
    schengen: false
  },
  {
    id: 185,
    name: 'Brigadier D.H.E. Ruiz Airport',
    city: 'Colonel Suarez',
    country: 'Argentina',
    iata: 'CSZ',
    icao: 'SAZC',
    latitude: -37.446098,
    longitude: -61.889301,
    altitude: 767,
    schengen: false
  },
  {
    id: 186,
    name: 'Olavarria Airport',
    city: 'Olavarria',
    country: 'Argentina',
    iata: 'OVR',
    icao: 'SAZF',
    latitude: -36.8899993896,
    longitude: -60.2165985107,
    altitude: 551,
    schengen: false
  },
  {
    id: 187,
    name: 'General Pico Airport',
    city: 'General Pico',
    country: 'Argentina',
    iata: 'GPO',
    icao: 'SAZG',
    latitude: -35.6962013245,
    longitude: -63.7583007812,
    altitude: 459,
    schengen: false
  },
  {
    id: 188,
    name: 'Tres Arroyos Airport',
    city: 'Tres Arroyos',
    country: 'Argentina',
    iata: 'OYO',
    icao: 'SAZH',
    latitude: -38.3869,
    longitude: -60.3297,
    altitude: 400,
    schengen: false
  },
  {
    id: 189,
    name: 'Bolivar Airport',
    city: 'Bolivar',
    country: 'Argentina',
    iata: '',
    icao: 'SAZI',
    latitude: -36.1866,
    longitude: -61.0764,
    altitude: 308,
    schengen: false
  },
  {
    id: 190,
    name: 'Santa Teresita Airport',
    city: 'Santa Teresita',
    country: 'Argentina',
    iata: 'SST',
    icao: 'SAZL',
    latitude: -36.5423,
    longitude: -56.7218,
    altitude: 9,
    schengen: false
  },
  {
    id: 191,
    name: 'Ástor Piazzola International Airport',
    city: 'Mar Del Plata',
    country: 'Argentina',
    iata: 'MDQ',
    icao: 'SAZM',
    latitude: -37.9342,
    longitude: -57.5733,
    altitude: 72,
    schengen: false
  },
  {
    id: 192,
    name: 'Presidente Peron Airport',
    city: 'Neuquen',
    country: 'Argentina',
    iata: 'NQN',
    icao: 'SAZN',
    latitude: -38.949001,
    longitude: -68.155701,
    altitude: 895,
    schengen: false
  },
  {
    id: 193,
    name: 'Necochea Airport',
    city: 'Necochea',
    country: 'Argentina',
    iata: 'NEC',
    icao: 'SAZO',
    latitude: -38.4831,
    longitude: -58.8172,
    altitude: 72,
    schengen: false
  },
  {
    id: 194,
    name: 'Comodoro Pedro Zanni Airport',
    city: 'Pehuajo',
    country: 'Argentina',
    iata: 'PEH',
    icao: 'SAZP',
    latitude: -35.8446,
    longitude: -61.8576,
    altitude: 278,
    schengen: false
  },
  {
    id: 195,
    name: 'Santa Rosa Airport',
    city: 'Santa Rosa',
    country: 'Argentina',
    iata: 'RSA',
    icao: 'SAZR',
    latitude: -36.588299,
    longitude: -64.275703,
    altitude: 630,
    schengen: false
  },
  {
    id: 196,
    name: 'San Carlos De Bariloche Airport',
    city: 'San Carlos De Bariloche',
    country: 'Argentina',
    iata: 'BRC',
    icao: 'SAZS',
    latitude: -41.151199,
    longitude: -71.157501,
    altitude: 2774,
    schengen: false
  },
  {
    id: 197,
    name: 'Héroes De Malvinas Airport',
    city: 'Tandil',
    country: 'Argentina',
    iata: 'TDL',
    icao: 'SAZT',
    latitude: -37.2374000549,
    longitude: -59.2279014587,
    altitude: 574,
    schengen: false
  },
  {
    id: 198,
    name: 'Villa Gesell Airport',
    city: 'Villa Gesell',
    country: 'Argentina',
    iata: 'VLG',
    icao: 'SAZV',
    latitude: -37.2354,
    longitude: -57.0292,
    altitude: 32,
    schengen: false
  },
  {
    id: 199,
    name: 'Cutral-Co Airport',
    city: 'Cutralco',
    country: 'Argentina',
    iata: 'CUT',
    icao: 'SAZW',
    latitude: -38.9397010803,
    longitude: -69.2646026611,
    altitude: 2132,
    schengen: false
  },
  {
    id: 200,
    name: 'Aviador C. Campos Airport',
    city: 'San Martin Des Andes',
    country: 'Argentina',
    iata: 'CPC',
    icao: 'SAZY',
    latitude: -40.075401,
    longitude: -71.137299,
    altitude: 2569,
    schengen: false
  },
  {
    id: 201,
    name: 'Stepanavan Airport',
    city: 'Stepanavan',
    country: 'Armenia',
    iata: '',
    icao: 'UDLS',
    latitude: 41.048500061,
    longitude: 44.3372001648,
    altitude: 4836,
    schengen: false
  },
  {
    id: 202,
    name: 'Gyumri Shirak Airport',
    city: 'Gyumri',
    country: 'Armenia',
    iata: 'LWN',
    icao: 'UDSG',
    latitude: 40.7504005432,
    longitude: 43.8592987061,
    altitude: 5000,
    schengen: false
  },
  {
    id: 203,
    name: 'Erebuni Airport',
    city: 'Yerevan',
    country: 'Armenia',
    iata: '',
    icao: 'UDYE',
    latitude: 40.1221008301,
    longitude: 44.4650001526,
    altitude: 2948,
    schengen: false
  },
  {
    id: 204,
    name: 'Zvartnots International Airport',
    city: 'Yerevan',
    country: 'Armenia',
    iata: 'EVN',
    icao: 'UDYZ',
    latitude: 40.1473007202,
    longitude: 44.3959007263,
    altitude: 2838,
    schengen: false
  },
  {
    id: 205,
    name: 'Dzhermuk Airport',
    city: 'Dzhermuk',
    country: 'Armenia',
    iata: '',
    icao: 'UGEJ',
    latitude: 39.8240013123,
    longitude: 45.6739997864,
    altitude: 0,
    schengen: false
  },
  {
    id: 206,
    name: 'Queen Beatrix International Airport',
    city: 'Oranjestad',
    country: 'Aruba',
    iata: 'AUA',
    icao: 'TNCA',
    latitude: 12.5014,
    longitude: -70.015198,
    altitude: 60,
    schengen: false
  },
  {
    id: 207,
    name: 'Oceanside Municipal Airport',
    city: 'Fraser Island',
    country: 'Australia',
    iata: 'OCN',
    icao: 'KOKB',
    latitude: 33.217300415039,
    longitude: -117.35399627686,
    altitude: 28,
    schengen: false
  },
  {
    id: 208,
    name: 'Albany Airport',
    city: 'Albany',
    country: 'Australia',
    iata: 'ALH',
    icao: 'YABA',
    latitude: -34.9432983398437,
    longitude: 117.80899810791,
    altitude: 233,
    schengen: false
  },
  {
    id: 209,
    name: 'RAAF Base Amberley',
    city: 'Amberley',
    country: 'Australia',
    iata: '',
    icao: 'YAMB',
    latitude: -27.6406,
    longitude: 152.712006,
    altitude: 91,
    schengen: false
  },
  {
    id: 210,
    name: 'Ampilatwatja Airport',
    city: 'Ampilatwatja',
    country: 'Australia',
    iata: '',
    icao: 'YAMJ',
    latitude: -21.6550006866455,
    longitude: 135.229995727539,
    altitude: 0,
    schengen: false
  },
  {
    id: 211,
    name: 'Amata Airport',
    city: 'Amata',
    country: 'Australia',
    iata: 'AMT',
    icao: 'YAMT',
    latitude: -26.1082992553711,
    longitude: 131.207000732422,
    altitude: 695,
    schengen: false
  },
  {
    id: 212,
    name: 'Alpha Airport',
    city: 'Alpha',
    country: 'Australia',
    iata: 'ABH',
    icao: 'YAPH',
    latitude: -23.646099,
    longitude: 146.584,
    altitude: 1255,
    schengen: false
  },
  {
    id: 213,
    name: 'Ararat Airport',
    city: '',
    country: 'Australia',
    iata: 'ARY',
    icao: 'YARA',
    latitude: -37.309398651123,
    longitude: 142.988998413086,
    altitude: 1008,
    schengen: false
  },
  {
    id: 214,
    name: 'Argyle Airport',
    city: 'Argyle',
    country: 'Australia',
    iata: 'GYL',
    icao: 'YARG',
    latitude: -16.6369,
    longitude: 128.451004,
    altitude: 522,
    schengen: false
  },
  {
    id: 215,
    name: 'Armidale Airport',
    city: 'Armidale',
    country: 'Australia',
    iata: 'ARM',
    icao: 'YARM',
    latitude: -30.5280990601,
    longitude: 151.617004395,
    altitude: 3556,
    schengen: false
  },
  {
    id: 216,
    name: 'Areyonga Airport',
    city: 'Areyonga',
    country: 'Australia',
    iata: '',
    icao: 'YARN',
    latitude: -24.0666999816895,
    longitude: 132.266998291016,
    altitude: 0,
    schengen: false
  },
  {
    id: 217,
    name: 'Atherton Airport',
    city: 'Atherton',
    country: 'Australia',
    iata: '',
    icao: 'YATN',
    latitude: -17.2616996765,
    longitude: 145.51499939,
    altitude: 2450,
    schengen: false
  },
  {
    id: 218,
    name: 'Aurukun Airport',
    city: 'Aurukun',
    country: 'Australia',
    iata: 'AUU',
    icao: 'YAUR',
    latitude: -13.354067,
    longitude: 141.72065,
    altitude: 31,
    schengen: false
  },
  {
    id: 219,
    name: 'Ayers Rock Connellan Airport',
    city: 'Uluru',
    country: 'Australia',
    iata: 'AYQ',
    icao: 'YAYE',
    latitude: -25.1861,
    longitude: 130.975998,
    altitude: 1626,
    schengen: false
  },
  {
    id: 220,
    name: 'Brisbane Archerfield Airport',
    city: 'Brisbane',
    country: 'Australia',
    iata: 'ACF',
    icao: 'YBAF',
    latitude: -27.5702991486,
    longitude: 153.007995605,
    altitude: 63,
    schengen: false
  },
  {
    id: 221,
    name: 'Northern Peninsula Airport',
    city: 'Amberley',
    country: 'Australia',
    iata: 'ABM',
    icao: 'YBAM',
    latitude: -10.9508,
    longitude: 142.459,
    altitude: 34,
    schengen: false
  },
  {
    id: 222,
    name: 'Barcaldine Airport',
    city: 'Barcaldine',
    country: 'Australia',
    iata: 'BCI',
    icao: 'YBAR',
    latitude: -23.5652999878,
    longitude: 145.307006836,
    altitude: 878,
    schengen: false
  },
  {
    id: 223,
    name: 'Alice Springs Airport',
    city: 'Alice Springs',
    country: 'Australia',
    iata: 'ASP',
    icao: 'YBAS',
    latitude: -23.8066997528076,
    longitude: 133.901992797852,
    altitude: 1789,
    schengen: false
  },
  {
    id: 224,
    name: 'Badu Island Airport',
    city: 'Badu Island',
    country: 'Australia',
    iata: 'BDD',
    icao: 'YBAU',
    latitude: -10.1499996185,
    longitude: 142.1734,
    altitude: 14,
    schengen: false
  },
  {
    id: 225,
    name: 'Brisbane International Airport',
    city: 'Brisbane',
    country: 'Australia',
    iata: 'BNE',
    icao: 'YBBN',
    latitude: -27.3841991424561,
    longitude: 153.117004394531,
    altitude: 13,
    schengen: false
  },
  {
    id: 226,
    name: 'Gold Coast Airport',
    city: 'Coolangatta',
    country: 'Australia',
    iata: 'OOL',
    icao: 'YBCG',
    latitude: -28.1644001007,
    longitude: 153.505004883,
    altitude: 21,
    schengen: false
  },
  {
    id: 227,
    name: 'Blackall Airport',
    city: 'Blackall',
    country: 'Australia',
    iata: 'BKQ',
    icao: 'YBCK',
    latitude: -24.4277992249,
    longitude: 145.429000854,
    altitude: 928,
    schengen: false
  },
  {
    id: 228,
    name: 'Cairns International Airport',
    city: 'Cairns',
    country: 'Australia',
    iata: 'CNS',
    icao: 'YBCS',
    latitude: -16.885799408,
    longitude: 145.755004883,
    altitude: 10,
    schengen: false
  },
  {
    id: 229,
    name: 'Charleville Airport',
    city: 'Charlieville',
    country: 'Australia',
    iata: 'CTL',
    icao: 'YBCV',
    latitude: -26.4132995605,
    longitude: 146.261993408,
    altitude: 1003,
    schengen: false
  },
  {
    id: 230,
    name: 'Bendigo Airport',
    city: 'Bendigo',
    country: 'Australia',
    iata: 'BXG',
    icao: 'YBDG',
    latitude: -36.7393989563,
    longitude: 144.330001831,
    altitude: 705,
    schengen: false
  },
  {
    id: 231,
    name: 'Birdsville Airport',
    city: 'Birdsville',
    country: 'Australia',
    iata: 'BVI',
    icao: 'YBDV',
    latitude: -25.8974990844727,
    longitude: 139.348007202148,
    altitude: 159,
    schengen: false
  },
  {
    id: 232,
    name: 'Beverley Airport',
    city: 'Mine Site',
    country: 'Australia',
    iata: '',
    icao: 'YBEE',
    latitude: -30.1867008209229,
    longitude: 139.557998657227,
    altitude: 116,
    schengen: false
  },
  {
    id: 233,
    name: 'Boolgeeda',
    city: 'Brockman',
    country: 'Australia',
    iata: 'OCM',
    icao: 'YBGD',
    latitude: -22.54,
    longitude: 117.275,
    altitude: 1871,
    schengen: false
  },
  {
    id: 234,
    name: 'Broken Hill Airport',
    city: 'Broken Hill',
    country: 'Australia',
    iata: 'BHQ',
    icao: 'YBHI',
    latitude: -32.0013999939,
    longitude: 141.472000122,
    altitude: 958,
    schengen: false
  },
  {
    id: 235,
    name: 'Hamilton Island Airport',
    city: 'Hamilton Island',
    country: 'Australia',
    iata: 'HTI',
    icao: 'YBHM',
    latitude: -20.3581008911,
    longitude: 148.95199585,
    altitude: 15,
    schengen: false
  },
  {
    id: 236,
    name: 'Bedourie Airport',
    city: 'Bedourie',
    country: 'Australia',
    iata: 'BEU',
    icao: 'YBIE',
    latitude: -24.3460998535156,
    longitude: 139.460006713867,
    altitude: 300,
    schengen: false
  },
  {
    id: 237,
    name: 'Bourke Airport',
    city: 'Bourke',
    country: 'Australia',
    iata: 'BRK',
    icao: 'YBKE',
    latitude: -30.0391998291016,
    longitude: 145.951995849609,
    altitude: 352,
    schengen: false
  },
  {
    id: 238,
    name: 'Burketown Airport',
    city: 'Burketown',
    country: 'Australia',
    iata: 'BUC',
    icao: 'YBKT',
    latitude: -17.7486000061035,
    longitude: 139.533996582031,
    altitude: 21,
    schengen: false
  },
  {
    id: 239,
    name: 'Benalla Airport',
    city: '',
    country: 'Australia',
    iata: 'BLN',
    icao: 'YBLA',
    latitude: -36.5518989562988,
    longitude: 146.00700378418,
    altitude: 569,
    schengen: false
  },
  {
    id: 240,
    name: 'Busselton Regional Airport',
    city: 'Brusselton',
    country: 'Australia',
    iata: 'BQB',
    icao: 'YBLN',
    latitude: -33.6884231567,
    longitude: 115.401596069,
    altitude: 55,
    schengen: false
  },
  {
    id: 241,
    name: 'Ballarat Airport',
    city: 'Ballarat',
    country: 'Australia',
    iata: '',
    icao: 'YBLT',
    latitude: -37.5116996765137,
    longitude: 143.791000366211,
    altitude: 1433,
    schengen: false
  },
  {
    id: 242,
    name: 'Mount Isa Airport',
    city: 'Mount Isa',
    country: 'Australia',
    iata: 'ISA',
    icao: 'YBMA',
    latitude: -20.6639003754,
    longitude: 139.488998413,
    altitude: 1121,
    schengen: false
  },
  {
    id: 243,
    name: 'Sunshine Coast Airport',
    city: 'Maroochydore',
    country: 'Australia',
    iata: 'MCY',
    icao: 'YBMC',
    latitude: -26.6033,
    longitude: 153.091003,
    altitude: 15,
    schengen: false
  },
  {
    id: 244,
    name: 'Mackay Airport',
    city: 'Mackay',
    country: 'Australia',
    iata: 'MKY',
    icao: 'YBMK',
    latitude: -21.1716995239,
    longitude: 149.179992676,
    altitude: 19,
    schengen: false
  },
  {
    id: 245,
    name: 'Ballina Byron Gateway Airport',
    city: 'Ballina Byron Bay',
    country: 'Australia',
    iata: 'BNK',
    icao: 'YBNA',
    latitude: -28.8339004517,
    longitude: 153.56199646,
    altitude: 7,
    schengen: false
  },
  {
    id: 246,
    name: 'Bairnsdale Airport',
    city: 'Bairnsdale',
    country: 'Australia',
    iata: 'BSJ',
    icao: 'YBNS',
    latitude: -37.8875007629395,
    longitude: 147.567993164063,
    altitude: 165,
    schengen: false
  },
  {
    id: 247,
    name: 'Boigu Airport',
    city: 'Boigu',
    country: 'Australia',
    iata: 'GIC',
    icao: 'YBOI',
    latitude: -9.23278045654,
    longitude: 142.218002319,
    altitude: 23,
    schengen: false
  },
  {
    id: 248,
    name: 'Oakey Airport',
    city: 'Oakey',
    country: 'Australia',
    iata: 'OKY',
    icao: 'YBOK',
    latitude: -27.4113998413086,
    longitude: 151.735000610352,
    altitude: 1335,
    schengen: false
  },
  {
    id: 249,
    name: 'Boulia Airport',
    city: 'Boulia',
    country: 'Australia',
    iata: 'BQL',
    icao: 'YBOU',
    latitude: -22.9132995605469,
    longitude: 139.899993896484,
    altitude: 542,
    schengen: false
  },
  {
    id: 250,
    name: 'Brampton Island Airport',
    city: 'Brampton Island',
    country: 'Australia',
    iata: 'BMP',
    icao: 'YBPI',
    latitude: -20.8033008575439,
    longitude: 149.270004272461,
    altitude: 11,
    schengen: false
  },
  {
    id: 251,
    name: 'Proserpine Whitsunday Coast Airport',
    city: 'Prosserpine',
    country: 'Australia',
    iata: 'PPP',
    icao: 'YBPN',
    latitude: -20.4950008392,
    longitude: 148.552001953,
    altitude: 82,
    schengen: false
  },
  {
    id: 252,
    name: 'Rockhampton Airport',
    city: 'Rockhampton',
    country: 'Australia',
    iata: 'ROK',
    icao: 'YBRK',
    latitude: -23.3819007874,
    longitude: 150.475006104,
    altitude: 34,
    schengen: false
  },
  {
    id: 253,
    name: 'Borroloola Airport',
    city: 'Borroloola',
    country: 'Australia',
    iata: 'BOX',
    icao: 'YBRL',
    latitude: -16.0753002166748,
    longitude: 136.302001953125,
    altitude: 55,
    schengen: false
  },
  {
    id: 254,
    name: 'Broome International Airport',
    city: 'Broome',
    country: 'Australia',
    iata: 'BME',
    icao: 'YBRM',
    latitude: -17.9447002410889,
    longitude: 122.232002258301,
    altitude: 56,
    schengen: false
  },
  {
    id: 255,
    name: 'Balranald Airport',
    city: '',
    country: 'Australia',
    iata: 'BZD',
    icao: 'YBRN',
    latitude: -34.6236000061035,
    longitude: 143.578002929688,
    altitude: 210,
    schengen: false
  },
  {
    id: 256,
    name: 'Brewarrina Airport',
    city: '',
    country: 'Australia',
    iata: 'BWQ',
    icao: 'YBRW',
    latitude: -29.9738998413086,
    longitude: 146.817001342773,
    altitude: 414,
    schengen: false
  },
  {
    id: 257,
    name: 'Barimunya Airport',
    city: 'Barimunya',
    country: 'Australia',
    iata: 'BYP',
    icao: 'YBRY',
    latitude: -22.673900604248,
    longitude: 119.16600036621,
    altitude: 2082,
    schengen: false
  },
  {
    id: 258,
    name: 'Bathurst Airport',
    city: 'Bathurst',
    country: 'Australia',
    iata: 'BHS',
    icao: 'YBTH',
    latitude: -33.4094009399,
    longitude: 149.651992798,
    altitude: 2435,
    schengen: false
  },
  {
    id: 259,
    name: 'Bathurst Island Airport',
    city: 'Bathurst Island',
    country: 'Australia',
    iata: 'BRT',
    icao: 'YBTI',
    latitude: -11.7692003250122,
    longitude: 130.619995117188,
    altitude: 67,
    schengen: false
  },
  {
    id: 260,
    name: 'Townsville Airport',
    city: 'Townsville',
    country: 'Australia',
    iata: 'TSV',
    icao: 'YBTL',
    latitude: -19.2525005340576,
    longitude: 146.764999389648,
    altitude: 18,
    schengen: false
  },
  {
    id: 261,
    name: 'Blackwater Airport',
    city: 'Blackwater',
    country: 'Australia',
    iata: 'BLT',
    icao: 'YBTR',
    latitude: -23.603099822998,
    longitude: 148.807006835938,
    altitude: 657,
    schengen: false
  },
  {
    id: 262,
    name: 'Bundaberg Airport',
    city: 'Bundaberg',
    country: 'Australia',
    iata: 'BDB',
    icao: 'YBUD',
    latitude: -24.9039001465,
    longitude: 152.319000244,
    altitude: 107,
    schengen: false
  },
  {
    id: 263,
    name: 'Bunbury Airport',
    city: 'Bunbury',
    country: 'Australia',
    iata: 'BUY',
    icao: 'YBUN',
    latitude: -33.3782997131348,
    longitude: 115.677001953125,
    altitude: 53,
    schengen: false
  },
  {
    id: 264,
    name: 'Bungle Bungle Airport',
    city: 'Bungle Bungle',
    country: 'Australia',
    iata: '',
    icao: 'YBUU',
    latitude: -17.5452995300293,
    longitude: 128.307006835938,
    altitude: 0,
    schengen: false
  },
  {
    id: 265,
    name: 'Bowen Airport',
    city: 'Bowen',
    country: 'Australia',
    iata: 'ZBO',
    icao: 'YBWN',
    latitude: -20.0182991027832,
    longitude: 148.214996337891,
    altitude: 8,
    schengen: false
  },
  {
    id: 266,
    name: 'Weipa Airport',
    city: 'Weipa',
    country: 'Australia',
    iata: 'WEI',
    icao: 'YBWP',
    latitude: -12.6786003113,
    longitude: 141.925003052,
    altitude: 63,
    schengen: false
  },
  {
    id: 267,
    name: 'Toowoomba Wellcamp Airport',
    city: 'Toowoomba',
    country: 'Australia',
    iata: 'WTB',
    icao: 'YBWW',
    latitude: -27.558332,
    longitude: 151.793335,
    altitude: 1509,
    schengen: false
  },
  {
    id: 268,
    name: 'Barrow Island Airport',
    city: 'Barrow Island',
    country: 'Australia',
    iata: 'BWB',
    icao: 'YBWX',
    latitude: -20.8644008636475,
    longitude: 115.40599822998,
    altitude: 26,
    schengen: false
  },
  {
    id: 269,
    name: 'Carnarvon Airport',
    city: 'Carnarvon',
    country: 'Australia',
    iata: 'CVQ',
    icao: 'YCAR',
    latitude: -24.880211,
    longitude: 113.67174,
    altitude: 13,
    schengen: false
  },
  {
    id: 270,
    name: 'Cobar Airport',
    city: 'Cobar',
    country: 'Australia',
    iata: 'CAZ',
    icao: 'YCBA',
    latitude: -31.5382995605469,
    longitude: 145.794006347656,
    altitude: 724,
    schengen: false
  },
  {
    id: 271,
    name: 'Coonabarabran Airport',
    city: 'Coonabarabran',
    country: 'Australia',
    iata: 'COJ',
    icao: 'YCBB',
    latitude: -31.3325004577637,
    longitude: 149.266998291016,
    altitude: 2117,
    schengen: false
  },
  {
    id: 272,
    name: 'Coober Pedy Airport',
    city: 'Coober Pedy',
    country: 'Australia',
    iata: 'CPD',
    icao: 'YCBP',
    latitude: -29.0400009155273,
    longitude: 134.720993041992,
    altitude: 740,
    schengen: false
  },
  {
    id: 273,
    name: 'Chinchilla Airport',
    city: 'Chinchilla',
    country: 'Australia',
    iata: 'CCL',
    icao: 'YCCA',
    latitude: -26.7749996185303,
    longitude: 150.617004394531,
    altitude: 1028,
    schengen: false
  },
  {
    id: 274,
    name: 'Coconut Island Airport',
    city: 'Coconut Island',
    country: 'Australia',
    iata: 'CNC',
    icao: 'YCCT',
    latitude: -10.0500001907349,
    longitude: 143.070007324219,
    altitude: 3,
    schengen: false
  },
  {
    id: 275,
    name: 'Cloncurry Airport',
    city: 'Cloncurry',
    country: 'Australia',
    iata: 'CNJ',
    icao: 'YCCY',
    latitude: -20.6686000824,
    longitude: 140.503997803,
    altitude: 616,
    schengen: false
  },
  {
    id: 276,
    name: 'Caloundra Airport',
    city: 'Caloundra',
    country: 'Australia',
    iata: 'CUD',
    icao: 'YCDR',
    latitude: -26.7999992370605,
    longitude: 153.100006103516,
    altitude: 12,
    schengen: false
  },
  {
    id: 277,
    name: 'Ceduna Airport',
    city: 'Ceduna',
    country: 'Australia',
    iata: 'CED',
    icao: 'YCDU',
    latitude: -32.1305999755859,
    longitude: 133.710006713867,
    altitude: 77,
    schengen: false
  },
  {
    id: 278,
    name: 'Cleve Airport',
    city: '',
    country: 'Australia',
    iata: 'CVC',
    icao: 'YCEE',
    latitude: -33.7097015380859,
    longitude: 136.505004882813,
    altitude: 589,
    schengen: false
  },
  {
    id: 279,
    name: 'RAAF Base Curtin',
    city: 'Derby',
    country: 'Australia',
    iata: 'DCN',
    icao: 'YCIN',
    latitude: -17.5813999176,
    longitude: 123.82800293,
    altitude: 300,
    schengen: false
  },
  {
    id: 280,
    name: 'Croker Island Airport',
    city: 'Croker Island',
    country: 'Australia',
    iata: 'CKI',
    icao: 'YCKI',
    latitude: -11.164999961853,
    longitude: 132.483001708984,
    altitude: 16,
    schengen: false
  },
  {
    id: 281,
    name: 'Cooktown Airport',
    city: 'Cooktown',
    country: 'Australia',
    iata: 'CTN',
    icao: 'YCKN',
    latitude: -15.4447002410889,
    longitude: 145.184005737305,
    altitude: 26,
    schengen: false
  },
  {
    id: 282,
    name: 'Clermont Airport',
    city: 'Clermont',
    country: 'Australia',
    iata: 'CMQ',
    icao: 'YCMT',
    latitude: -22.773099899292,
    longitude: 147.621002197266,
    altitude: 908,
    schengen: false
  },
  {
    id: 283,
    name: 'Cunnamulla Airport',
    city: 'Cunnamulla',
    country: 'Australia',
    iata: 'CMA',
    icao: 'YCMU',
    latitude: -28.0300006866455,
    longitude: 145.621994018555,
    altitude: 630,
    schengen: false
  },
  {
    id: 284,
    name: 'Cessnock Airport',
    city: 'Cessnock',
    country: 'Australia',
    iata: 'CES',
    icao: 'YCNK',
    latitude: -32.787498,
    longitude: 151.341995,
    altitude: 210,
    schengen: false
  },
  {
    id: 285,
    name: 'Coonamble Airport',
    city: 'Coonamble',
    country: 'Australia',
    iata: 'CNB',
    icao: 'YCNM',
    latitude: -30.9832992553711,
    longitude: 148.376007080078,
    altitude: 604,
    schengen: false
  },
  {
    id: 286,
    name: 'Coonawarra Airport',
    city: 'Coonawarra',
    country: 'Australia',
    iata: '',
    icao: 'YCNQ',
    latitude: -37.2832984924316,
    longitude: 140.800003051758,
    altitude: 0,
    schengen: false
  },
  {
    id: 287,
    name: 'Coen Airport',
    city: 'Coen',
    country: 'Australia',
    iata: 'CUQ',
    icao: 'YCOE',
    latitude: -13.761133,
    longitude: 143.113311,
    altitude: 532,
    schengen: false
  },
  {
    id: 288,
    name: 'Cooma Snowy Mountains Airport',
    city: 'Cooma',
    country: 'Australia',
    iata: 'OOM',
    icao: 'YCOM',
    latitude: -36.3005981445,
    longitude: 148.973999023,
    altitude: 3088,
    schengen: false
  },
  {
    id: 289,
    name: 'Cooinda Airport',
    city: 'Cooinda',
    country: 'Australia',
    iata: 'CDA',
    icao: 'YCOO',
    latitude: -12.9033002853394,
    longitude: 132.531997680664,
    altitude: 13,
    schengen: false
  },
  {
    id: 290,
    name: 'Corowa Airport',
    city: '',
    country: 'Australia',
    iata: 'CWW',
    icao: 'YCOR',
    latitude: -35.994701385498,
    longitude: 146.356994628906,
    altitude: 469,
    schengen: false
  },
  {
    id: 291,
    name: 'Coral Bay Airport',
    city: 'Coral Bay',
    country: 'Australia',
    iata: '',
    icao: 'YCOY',
    latitude: -23.1299991607666,
    longitude: 113.777000427246,
    altitude: 5,
    schengen: false
  },
  {
    id: 292,
    name: 'Corryong Airport',
    city: '',
    country: 'Australia',
    iata: 'CYG',
    icao: 'YCRG',
    latitude: -36.1828002929687,
    longitude: 147.888000488281,
    altitude: 963,
    schengen: false
  },
  {
    id: 293,
    name: 'Cootamundra Airport',
    city: '',
    country: 'Australia',
    iata: 'CMD',
    icao: 'YCTM',
    latitude: -34.6239013671875,
    longitude: 148.02799987793,
    altitude: 1110,
    schengen: false
  },
  {
    id: 294,
    name: 'Cunderdin Airport',
    city: 'Cunderdin',
    country: 'Australia',
    iata: '',
    icao: 'YCUN',
    latitude: -31.622200012207,
    longitude: 117.217002868652,
    altitude: 705,
    schengen: false
  },
  {
    id: 295,
    name: 'Coondewanna Airport',
    city: 'Coondewanna',
    country: 'Australia',
    iata: 'CJF',
    icao: 'YCWA',
    latitude: -22.96669960022,
    longitude: 118.81300354004,
    altitude: 2300,
    schengen: false
  },
  {
    id: 296,
    name: 'Dirranbandi Airport',
    city: '',
    country: 'Australia',
    iata: 'DRN',
    icao: 'YDBI',
    latitude: -28.5916996002197,
    longitude: 148.216995239258,
    altitude: 567,
    schengen: false
  },
  {
    id: 297,
    name: 'Derby Airport',
    city: 'Derby',
    country: 'Australia',
    iata: 'DRB',
    icao: 'YDBY',
    latitude: -17.3700008392334,
    longitude: 123.661003112793,
    altitude: 24,
    schengen: false
  },
  {
    id: 298,
    name: 'Dunk Island Airport',
    city: 'Dunk Island',
    country: 'Australia',
    iata: 'DKI',
    icao: 'YDKI',
    latitude: -17.9416999817,
    longitude: 146.13999939,
    altitude: 6,
    schengen: false
  },
  {
    id: 299,
    name: 'Dulkaninna Airport',
    city: 'Dulkaninna',
    country: 'Australia',
    iata: 'DLK',
    icao: 'YDLK',
    latitude: -29.0132999420166,
    longitude: 138.481002807617,
    altitude: 0,
    schengen: false
  },
  {
    id: 300,
    name: 'Deniliquin Airport',
    city: 'Deniliquin',
    country: 'Australia',
    iata: 'DNQ',
    icao: 'YDLQ',
    latitude: -35.5593986511,
    longitude: 144.945999146,
    altitude: 316,
    schengen: false
  },
  {
    id: 301,
    name: 'Doomadgee Airport',
    city: 'Doomadgee',
    country: 'Australia',
    iata: 'DMD',
    icao: 'YDMG',
    latitude: -17.9403,
    longitude: 138.822006,
    altitude: 153,
    schengen: false
  },
  {
    id: 302,
    name: 'Darnley Island Airport',
    city: 'Darnley Island',
    country: 'Australia',
    iata: 'NLF',
    icao: 'YDNI',
    latitude: -9.58333015441895,
    longitude: 143.766998291016,
    altitude: 0,
    schengen: false
  },
  {
    id: 303,
    name: 'Devonport Airport',
    city: 'Devonport',
    country: 'Australia',
    iata: 'DPO',
    icao: 'YDPO',
    latitude: -41.1697006226,
    longitude: 146.429992676,
    altitude: 33,
    schengen: false
  },
  {
    id: 304,
    name: 'Docker River Airport',
    city: 'Docker River',
    country: 'Australia',
    iata: 'DKV',
    icao: 'YDVR',
    latitude: -24.8600006103516,
    longitude: 129.070007324219,
    altitude: 589,
    schengen: false
  },
  {
    id: 305,
    name: 'Dysart Airport',
    city: '',
    country: 'Australia',
    iata: 'DYA',
    icao: 'YDYS',
    latitude: -22.622200012207,
    longitude: 148.363998413086,
    altitude: 670,
    schengen: false
  },
  {
    id: 306,
    name: 'Echuca Airport',
    city: '',
    country: 'Australia',
    iata: 'ECH',
    icao: 'YECH',
    latitude: -36.1571998596191,
    longitude: 144.761993408203,
    altitude: 323,
    schengen: false
  },
  {
    id: 307,
    name: 'Elcho Island Airport',
    city: 'Elcho Island',
    country: 'Australia',
    iata: 'ELC',
    icao: 'YELD',
    latitude: -12.0193996429,
    longitude: 135.570999146,
    altitude: 101,
    schengen: false
  },
  {
    id: 308,
    name: 'Elliott Airport',
    city: 'Elliott',
    country: 'Australia',
    iata: '',
    icao: 'YELL',
    latitude: -17.5274295806885,
    longitude: 133.529891967773,
    altitude: 0,
    schengen: false
  },
  {
    id: 309,
    name: 'Emerald Airport',
    city: 'Emerald',
    country: 'Australia',
    iata: 'EMD',
    icao: 'YEML',
    latitude: -23.5674991608,
    longitude: 148.179000854,
    altitude: 624,
    schengen: false
  },
  {
    id: 310,
    name: 'Erldunda Airport',
    city: 'Erldunda',
    country: 'Australia',
    iata: 'EDD',
    icao: 'YERL',
    latitude: -25.2057991028,
    longitude: 133.253997803,
    altitude: 0,
    schengen: false
  },
  {
    id: 311,
    name: 'Esperance Airport',
    city: 'Esperance',
    country: 'Australia',
    iata: 'EPR',
    icao: 'YESP',
    latitude: -33.684399,
    longitude: 121.822998,
    altitude: 470,
    schengen: false
  },
  {
    id: 312,
    name: 'Everard Park Airport',
    city: 'Mimili',
    country: 'Australia',
    iata: '',
    icao: 'YEVP',
    latitude: -27.0167007446289,
    longitude: 132.716995239258,
    altitude: 0,
    schengen: false
  },
  {
    id: 313,
    name: 'Forbes Airport',
    city: 'Forbes',
    country: 'Australia',
    iata: 'FRB',
    icao: 'YFBS',
    latitude: -33.363602,
    longitude: 147.934998,
    altitude: 760,
    schengen: false
  },
  {
    id: 314,
    name: 'Fortescue - Dave Forrest Aerodrome',
    city: 'Cloudbreak',
    country: 'Australia',
    iata: 'KFE',
    icao: 'YFDF',
    latitude: -22.290754,
    longitude: 119.437143,
    altitude: 1555,
    schengen: false
  },
  {
    id: 315,
    name: 'Flinders Island Airport',
    city: 'Flinders Island',
    country: 'Australia',
    iata: 'FLS',
    icao: 'YFLI',
    latitude: -40.0917015076,
    longitude: 147.992996216,
    altitude: 10,
    schengen: false
  },
  {
    id: 316,
    name: 'Flinders Island Airport',
    city: 'Elliston',
    country: 'Australia',
    iata: '',
    icao: 'YFLS',
    latitude: -33.730989,
    longitude: 134.501,
    altitude: 0,
    schengen: false
  },
  {
    id: 317,
    name: 'Finke Airport',
    city: 'Finke',
    country: 'Australia',
    iata: 'FIK',
    icao: 'YFNE',
    latitude: -25.5946998596,
    longitude: 134.582992554,
    altitude: 0,
    schengen: false
  },
  {
    id: 318,
    name: 'Fregon Airport',
    city: 'Fregon',
    country: 'Australia',
    iata: '',
    icao: 'YFRG',
    latitude: -26.7749996185303,
    longitude: 132.016998291016,
    altitude: 528,
    schengen: false
  },
  {
    id: 319,
    name: 'Forrest Airport',
    city: 'Forrest',
    country: 'Australia',
    iata: 'FOS',
    icao: 'YFRT',
    latitude: -30.8381004333496,
    longitude: 128.115005493164,
    altitude: 511,
    schengen: false
  },
  {
    id: 320,
    name: 'Fitzroy Crossing Airport',
    city: 'Fitzroy Crossing',
    country: 'Australia',
    iata: 'FIZ',
    icao: 'YFTZ',
    latitude: -18.1819000244141,
    longitude: 125.55899810791,
    altitude: 368,
    schengen: false
  },
  {
    id: 321,
    name: 'Gatton Campus Airport',
    city: 'Gatton',
    country: 'Australia',
    iata: '',
    icao: 'YGAT',
    latitude: -27.5604060653,
    longitude: 152.340459824,
    altitude: 0,
    schengen: false
  },
  {
    id: 322,
    name: 'Gayndah Airport',
    city: 'Gayndah',
    country: 'Australia',
    iata: 'GAH',
    icao: 'YGAY',
    latitude: -25.6144008636475,
    longitude: 151.619003295898,
    altitude: 369,
    schengen: false
  },
  {
    id: 323,
    name: 'Gunnedah Airport',
    city: '',
    country: 'Australia',
    iata: 'GUH',
    icao: 'YGDH',
    latitude: -30.9610996246338,
    longitude: 150.251007080078,
    altitude: 863,
    schengen: false
  },
  {
    id: 324,
    name: 'Goondiwindi Airport',
    city: 'Goondiwindi',
    country: 'Australia',
    iata: 'GOO',
    icao: 'YGDI',
    latitude: -28.5214004516602,
    longitude: 150.320007324219,
    altitude: 714,
    schengen: false
  },
  {
    id: 325,
    name: 'Geraldton Airport',
    city: 'Geraldton',
    country: 'Australia',
    iata: 'GET',
    icao: 'YGEL',
    latitude: -28.796101,
    longitude: 114.707001,
    altitude: 121,
    schengen: false
  },
  {
    id: 326,
    name: 'Grafton Airport',
    city: 'Grafton',
    country: 'Australia',
    iata: 'GFN',
    icao: 'YGFN',
    latitude: -29.7593994140625,
    longitude: 153.029998779297,
    altitude: 110,
    schengen: false
  },
  {
    id: 327,
    name: 'Great Keppel Is Airport',
    city: 'Great Keppel Island',
    country: 'Australia',
    iata: 'GKL',
    icao: 'YGKL',
    latitude: -23.1833000183,
    longitude: 150.942001343,
    altitude: 21,
    schengen: false
  },
  {
    id: 328,
    name: 'Gladstone Airport',
    city: 'Gladstone',
    country: 'Australia',
    iata: 'GLT',
    icao: 'YGLA',
    latitude: -23.869699,
    longitude: 151.223007,
    altitude: 64,
    schengen: false
  },
  {
    id: 329,
    name: 'Goulburn Airport',
    city: 'Goulburn',
    country: 'Australia',
    iata: 'GUL',
    icao: 'YGLB',
    latitude: -34.8102989196777,
    longitude: 149.725997924805,
    altitude: 2141,
    schengen: false
  },
  {
    id: 330,
    name: 'Geelong Airport',
    city: 'Geelong',
    country: 'Australia',
    iata: 'GEX',
    icao: 'YGLG',
    latitude: -38.2249984741211,
    longitude: 144.332992553711,
    altitude: 43,
    schengen: false
  },
  {
    id: 331,
    name: 'Glen Innes Airport',
    city: 'Glen Innes',
    country: 'Australia',
    iata: 'GLI',
    icao: 'YGLI',
    latitude: -29.6749992370605,
    longitude: 151.688995361328,
    altitude: 3433,
    schengen: false
  },
  {
    id: 332,
    name: 'Garden Point Airport',
    city: 'Pirlangimpi',
    country: 'Australia',
    iata: 'GPN',
    icao: 'YGPT',
    latitude: -11.4025001525879,
    longitude: 130.421997070313,
    altitude: 90,
    schengen: false
  },
  {
    id: 333,
    name: 'Groote Eylandt Airport',
    city: 'Groote Eylandt',
    country: 'Australia',
    iata: 'GTE',
    icao: 'YGTE',
    latitude: -13.9750003815,
    longitude: 136.460006714,
    altitude: 53,
    schengen: false
  },
  {
    id: 334,
    name: 'Griffith Airport',
    city: 'Griffith',
    country: 'Australia',
    iata: 'GFF',
    icao: 'YGTH',
    latitude: -34.2508010864,
    longitude: 146.067001343,
    altitude: 439,
    schengen: false
  },
  {
    id: 335,
    name: 'Hay Airport',
    city: '',
    country: 'Australia',
    iata: 'HXX',
    icao: 'YHAY',
    latitude: -34.5313987731934,
    longitude: 144.830001831055,
    altitude: 305,
    schengen: false
  },
  {
    id: 336,
    name: 'Hervey Bay Airport',
    city: 'Hervey Bay',
    country: 'Australia',
    iata: 'HVB',
    icao: 'YHBA',
    latitude: -25.3188991547,
    longitude: 152.880004883,
    altitude: 60,
    schengen: false
  },
  {
    id: 337,
    name: 'Horn Island Airport',
    city: 'Horn Island',
    country: 'Australia',
    iata: 'HID',
    icao: 'YHID',
    latitude: -10.586400032,
    longitude: 142.289993286,
    altitude: 43,
    schengen: false
  },
  {
    id: 338,
    name: 'Halls Creek Airport',
    city: 'Halls Creek',
    country: 'Australia',
    iata: 'HCQ',
    icao: 'YHLC',
    latitude: -18.2339000701904,
    longitude: 127.669998168945,
    altitude: 1346,
    schengen: false
  },
  {
    id: 339,
    name: 'Hermannsburg Airport',
    city: 'Hermannsburg',
    country: 'Australia',
    iata: 'HMG',
    icao: 'YHMB',
    latitude: -23.9300003051758,
    longitude: 132.804992675781,
    altitude: 593,
    schengen: false
  },
  {
    id: 340,
    name: 'Hamilton Airport',
    city: 'Hamilton',
    country: 'Australia',
    iata: 'HLT',
    icao: 'YHML',
    latitude: -37.6488990783691,
    longitude: 142.065002441406,
    altitude: 803,
    schengen: false
  },
  {
    id: 341,
    name: 'Hooker Creek Airport',
    city: 'Hooker Creek',
    country: 'Australia',
    iata: 'HOK',
    icao: 'YHOO',
    latitude: -18.3367004395,
    longitude: 130.638000488,
    altitude: 320,
    schengen: false
  },
  {
    id: 342,
    name: 'Mount Hotham Airport',
    city: 'Mount Hotham',
    country: 'Australia',
    iata: 'MHU',
    icao: 'YHOT',
    latitude: -37.0475006104,
    longitude: 147.333999634,
    altitude: 4260,
    schengen: false
  },
  {
    id: 343,
    name: 'Hopetoun Airport',
    city: '',
    country: 'Australia',
    iata: 'HTU',
    icao: 'YHPN',
    latitude: -35.7153015136719,
    longitude: 142.360000610352,
    altitude: 256,
    schengen: false
  },
  {
    id: 344,
    name: 'Horsham Airport',
    city: 'Horsham',
    country: 'Australia',
    iata: 'HSM',
    icao: 'YHSM',
    latitude: -36.6697006225586,
    longitude: 142.173004150391,
    altitude: 445,
    schengen: false
  },
  {
    id: 345,
    name: 'Harts Range Airport',
    city: 'Harts Range',
    country: 'Australia',
    iata: '',
    icao: 'YHTS',
    latitude: -22.9850006103516,
    longitude: 134.917999267578,
    altitude: 0,
    schengen: false
  },
  {
    id: 346,
    name: 'Hughenden Airport',
    city: 'Hughenden',
    country: 'Australia',
    iata: 'HGD',
    icao: 'YHUG',
    latitude: -20.8150005340576,
    longitude: 144.225006103516,
    altitude: 1043,
    schengen: false
  },
  {
    id: 347,
    name: 'Hayman Island Heliport',
    city: 'Hayman Island',
    country: 'Australia',
    iata: '',
    icao: 'YHYN',
    latitude: -20.0599,
    longitude: 148.8834,
    altitude: 8,
    schengen: false
  },
  {
    id: 348,
    name: 'Innisfail Airport',
    city: 'Innisfail',
    country: 'Australia',
    iata: 'IFL',
    icao: 'YIFL',
    latitude: -17.5594005584717,
    longitude: 146.011993408203,
    altitude: 46,
    schengen: false
  },
  {
    id: 349,
    name: 'Inverell Airport',
    city: 'Inverell',
    country: 'Australia',
    iata: 'IVR',
    icao: 'YIVL',
    latitude: -29.888299942,
    longitude: 151.143997192,
    altitude: 2667,
    schengen: false
  },
  {
    id: 350,
    name: 'Ivanhoe Airport',
    city: 'Ivanhoe',
    country: 'Australia',
    iata: '',
    icao: 'YIVO',
    latitude: -32.88330078125,
    longitude: 144.309997558594,
    altitude: 330,
    schengen: false
  },
  {
    id: 351,
    name: 'Jabiru Airport',
    city: 'Jabiru',
    country: 'Australia',
    iata: 'JAB',
    icao: 'YJAB',
    latitude: -12.6583003997803,
    longitude: 132.893005371094,
    altitude: 85,
    schengen: false
  },
  {
    id: 352,
    name: 'Julia Creek Airport',
    city: 'Julia Creek',
    country: 'Australia',
    iata: 'JCK',
    icao: 'YJLC',
    latitude: -20.6683006286621,
    longitude: 141.723007202148,
    altitude: 404,
    schengen: false
  },
  {
    id: 353,
    name: 'Kalbarri Airport',
    city: 'Kalbarri',
    country: 'Australia',
    iata: 'KAX',
    icao: 'YKBR',
    latitude: -27.692813,
    longitude: 114.259169,
    altitude: 157,
    schengen: false
  },
  {
    id: 354,
    name: 'Kings Creek Airport',
    city: 'Petermann',
    country: 'Australia',
    iata: 'KCS',
    icao: 'YKCS',
    latitude: -24.4232997894287,
    longitude: 131.835006713867,
    altitude: 615,
    schengen: false
  },
  {
    id: 355,
    name: 'Kadina Airport',
    city: 'Kadina',
    country: 'Australia',
    iata: '',
    icao: 'YKDI',
    latitude: -33.9766998291016,
    longitude: 137.660003662109,
    altitude: 42,
    schengen: false
  },
  {
    id: 356,
    name: 'Kerang Airport',
    city: '',
    country: 'Australia',
    iata: 'KRA',
    icao: 'YKER',
    latitude: -35.7513999938965,
    longitude: 143.938995361328,
    altitude: 254,
    schengen: false
  },
  {
    id: 357,
    name: 'King Island Airport',
    city: 'King Island',
    country: 'Australia',
    iata: 'KNS',
    icao: 'YKII',
    latitude: -39.877498626709,
    longitude: 143.878005981445,
    altitude: 132,
    schengen: false
  },
  {
    id: 358,
    name: 'Kalkgurung Airport',
    city: 'Kalkgurung',
    country: 'Australia',
    iata: 'KFG',
    icao: 'YKKG',
    latitude: -17.4319000244141,
    longitude: 130.807998657227,
    altitude: 646,
    schengen: false
  },
  {
    id: 359,
    name: 'Karumba Airport',
    city: 'Karumba',
    country: 'Australia',
    iata: 'KRB',
    icao: 'YKMB',
    latitude: -17.4566993713379,
    longitude: 140.830001831055,
    altitude: 5,
    schengen: false
  },
  {
    id: 360,
    name: 'Kempsey Airport',
    city: '',
    country: 'Australia',
    iata: 'KPS',
    icao: 'YKMP',
    latitude: -31.0743999481201,
    longitude: 152.770004272461,
    altitude: 54,
    schengen: false
  },
  {
    id: 361,
    name: 'Kintore Airport',
    city: 'Kintore',
    country: 'Australia',
    iata: '',
    icao: 'YKNT',
    latitude: -23.2649993896484,
    longitude: 129.386993408203,
    altitude: 0,
    schengen: false
  },
  {
    id: 362,
    name: 'Kowanyama Airport',
    city: 'Kowanyama',
    country: 'Australia',
    iata: 'KWM',
    icao: 'YKOW',
    latitude: -15.4856004714966,
    longitude: 141.751007080078,
    altitude: 35,
    schengen: false
  },
  {
    id: 363,
    name: 'Kingaroy Airport',
    city: '',
    country: 'Australia',
    iata: 'KGY',
    icao: 'YKRY',
    latitude: -26.5807991027832,
    longitude: 151.841003417969,
    altitude: 1492,
    schengen: false
  },
  {
    id: 364,
    name: 'Kingscote Airport',
    city: 'Kingscote',
    country: 'Australia',
    iata: 'KGC',
    icao: 'YKSC',
    latitude: -35.7139015197754,
    longitude: 137.52099609375,
    altitude: 24,
    schengen: false
  },
  {
    id: 365,
    name: 'Kubin Airport',
    city: 'Kubin',
    country: 'Australia',
    iata: 'KUG',
    icao: 'YKUB',
    latitude: -10.2250003815,
    longitude: 142.218002319,
    altitude: 15,
    schengen: false
  },
  {
    id: 366,
    name: 'Mount Liebig Airport',
    city: 'Mt Liebig',
    country: 'Australia',
    iata: '',
    icao: 'YLBG',
    latitude: -23.2432994842529,
    longitude: 131.259994506836,
    altitude: 0,
    schengen: false
  },
  {
    id: 367,
    name: 'Leigh Creek Airport',
    city: 'Leigh Creek',
    country: 'Australia',
    iata: 'LGH',
    icao: 'YLEC',
    latitude: -30.5983009338379,
    longitude: 138.425994873047,
    altitude: 856,
    schengen: false
  },
  {
    id: 368,
    name: 'Leonora Airport',
    city: 'Leonora',
    country: 'Australia',
    iata: 'LNO',
    icao: 'YLEO',
    latitude: -28.8780994415283,
    longitude: 121.315002441406,
    altitude: 1217,
    schengen: false
  },
  {
    id: 369,
    name: 'Lake Evella Airport',
    city: 'Lake Evella',
    country: 'Australia',
    iata: 'LEL',
    icao: 'YLEV',
    latitude: -12.4989004135132,
    longitude: 135.805999755859,
    altitude: 256,
    schengen: false
  },
  {
    id: 370,
    name: 'Lord Howe Island Airport',
    city: 'Lord Howe Island',
    country: 'Australia',
    iata: 'LDH',
    icao: 'YLHI',
    latitude: -31.5382995605,
    longitude: 159.07699585,
    altitude: 5,
    schengen: false
  },
  {
    id: 371,
    name: 'Lockhart River Airport',
    city: 'Lockhart River',
    country: 'Australia',
    iata: 'IRG',
    icao: 'YLHR',
    latitude: -12.7869,
    longitude: 143.304993,
    altitude: 77,
    schengen: false
  },
  {
    id: 372,
    name: 'Lilydale Airport',
    city: 'Lilydale',
    country: 'Australia',
    iata: '',
    icao: 'YLIL',
    latitude: -37.6916999816895,
    longitude: 145.367004394531,
    altitude: 76,
    schengen: false
  },
  {
    id: 373,
    name: 'Lismore Airport',
    city: 'Lismore',
    country: 'Australia',
    iata: 'LSY',
    icao: 'YLIS',
    latitude: -28.8302993774,
    longitude: 153.259994507,
    altitude: 35,
    schengen: false
  },
  {
    id: 374,
    name: 'Ballera Airport',
    city: 'Ballera',
    country: 'Australia',
    iata: 'BBL',
    icao: 'YLLE',
    latitude: -27.405633,
    longitude: 141.809458,
    altitude: 385,
    schengen: false
  },
  {
    id: 375,
    name: 'Lightning Ridge Airport',
    city: 'Lightning Ridge',
    country: 'Australia',
    iata: 'LHG',
    icao: 'YLRD',
    latitude: -29.4566993713379,
    longitude: 147.983993530273,
    altitude: 540,
    schengen: false
  },
  {
    id: 376,
    name: 'Longreach Airport',
    city: 'Longreach',
    country: 'Australia',
    iata: 'LRE',
    icao: 'YLRE',
    latitude: -23.4342002869,
    longitude: 144.279998779,
    altitude: 627,
    schengen: false
  },
  {
    id: 377,
    name: 'Leinster Airport',
    city: 'Leinster',
    country: 'Australia',
    iata: 'LER',
    icao: 'YLST',
    latitude: -27.8432998657227,
    longitude: 120.703002929688,
    altitude: 1631,
    schengen: false
  },
  {
    id: 378,
    name: 'Laverton Airport',
    city: 'Laverton',
    country: 'Australia',
    iata: 'LVO',
    icao: 'YLTN',
    latitude: -28.6135997772217,
    longitude: 122.424003601074,
    altitude: 1530,
    schengen: false
  },
  {
    id: 379,
    name: 'Latrobe Valley Airport',
    city: 'Morwell',
    country: 'Australia',
    iata: 'TGN',
    icao: 'YLTV',
    latitude: -38.207199,
    longitude: 146.470001,
    altitude: 180,
    schengen: false
  },
  {
    id: 380,
    name: 'RAAF Williams, Laverton Base',
    city: 'Laverton',
    country: 'Australia',
    iata: '',
    icao: 'YLVT',
    latitude: -37.8636016845703,
    longitude: 144.746002197266,
    altitude: 18,
    schengen: false
  },
  {
    id: 381,
    name: 'Lizard Island Airport',
    city: 'Lizard Island',
    country: 'Australia',
    iata: 'LZR',
    icao: 'YLZI',
    latitude: -14.673273,
    longitude: 145.454571,
    altitude: 70,
    schengen: false
  },
  {
    id: 382,
    name: 'Mabuiag Island Airport',
    city: 'Mabuiag Island',
    country: 'Australia',
    iata: 'UBB',
    icao: 'YMAA',
    latitude: -9.94999980926514,
    longitude: 142.182998657227,
    altitude: 0,
    schengen: false
  },
  {
    id: 383,
    name: 'Avalon Airport',
    city: 'Avalon',
    country: 'Australia',
    iata: 'AVV',
    icao: 'YMAV',
    latitude: -38.039398,
    longitude: 144.468994,
    altitude: 35,
    schengen: false
  },
  {
    id: 384,
    name: 'Albury Airport',
    city: 'Albury',
    country: 'Australia',
    iata: 'ABX',
    icao: 'YMAY',
    latitude: -36.067798614502,
    longitude: 146.957992553711,
    altitude: 539,
    schengen: false
  },
  {
    id: 385,
    name: 'Mareeba Airport',
    city: '',
    country: 'Australia',
    iata: 'MRG',
    icao: 'YMBA',
    latitude: -17.0692005157471,
    longitude: 145.419006347656,
    altitude: 1560,
    schengen: false
  },
  {
    id: 386,
    name: 'Murray Bridge Airport',
    city: 'Murray Bridge',
    country: 'Australia',
    iata: '',
    icao: 'YMBD',
    latitude: -35.0666999816895,
    longitude: 139.227005004883,
    altitude: 55,
    schengen: false
  },
  {
    id: 387,
    name: 'Mallacoota Airport',
    city: 'Mallacoota',
    country: 'Australia',
    iata: 'XMC',
    icao: 'YMCO',
    latitude: -37.5983009338379,
    longitude: 149.720001220703,
    altitude: 31,
    schengen: false
  },
  {
    id: 388,
    name: 'Mudgee Airport',
    city: 'Mudgee',
    country: 'Australia',
    iata: 'DGE',
    icao: 'YMDG',
    latitude: -32.5625,
    longitude: 149.610992432,
    altitude: 1545,
    schengen: false
  },
  {
    id: 389,
    name: 'Mount Davies Airport',
    city: 'Pipalyatjara',
    country: 'Australia',
    iata: '',
    icao: 'YMDV',
    latitude: -26.1667003631592,
    longitude: 129.132995605469,
    altitude: 0,
    schengen: false
  },
  {
    id: 390,
    name: 'Menindee Airport',
    city: 'Menindee',
    country: 'Australia',
    iata: '',
    icao: 'YMED',
    latitude: -32.36669921875,
    longitude: 142.404998779297,
    altitude: 0,
    schengen: false
  },
  {
    id: 391,
    name: 'Meekatharra Airport',
    city: 'Meekatharra',
    country: 'Australia',
    iata: 'MKR',
    icao: 'YMEK',
    latitude: -26.6117000579834,
    longitude: 118.547996520996,
    altitude: 1713,
    schengen: false
  },
  {
    id: 392,
    name: 'Melbourne Essendon Airport',
    city: 'Melbourne',
    country: 'Australia',
    iata: 'MEB',
    icao: 'YMEN',
    latitude: -37.7281,
    longitude: 144.901993,
    altitude: 282,
    schengen: false
  },
  {
    id: 393,
    name: 'Merimbula Airport',
    city: 'Merimbula',
    country: 'Australia',
    iata: 'MIM',
    icao: 'YMER',
    latitude: -36.9085998535,
    longitude: 149.901000977,
    altitude: 7,
    schengen: false
  },
  {
    id: 394,
    name: 'RAAF Base East Sale',
    city: 'East Sale',
    country: 'Australia',
    iata: '',
    icao: 'YMES',
    latitude: -38.0988998413,
    longitude: 147.149002075,
    altitude: 23,
    schengen: false
  },
  {
    id: 395,
    name: 'Milingimbi Airport',
    city: 'Milingimbi',
    country: 'Australia',
    iata: 'MGT',
    icao: 'YMGB',
    latitude: -12.0944004059,
    longitude: 134.893997192,
    altitude: 53,
    schengen: false
  },
  {
    id: 396,
    name: 'Maningrida Airport',
    city: 'Maningrida',
    country: 'Australia',
    iata: 'MNG',
    icao: 'YMGD',
    latitude: -12.0560998917,
    longitude: 134.23399353,
    altitude: 123,
    schengen: false
  },
  {
    id: 397,
    name: 'Hobart International Airport',
    city: 'Hobart',
    country: 'Australia',
    iata: 'HBA',
    icao: 'YMHB',
    latitude: -42.836101532,
    longitude: 147.509994507,
    altitude: 13,
    schengen: false
  },
  {
    id: 398,
    name: 'McArthur River Mine Airport',
    city: 'McArthur River Mine',
    country: 'Australia',
    iata: 'MCV',
    icao: 'YMHU',
    latitude: -16.4424991608,
    longitude: 136.083999634,
    altitude: 131,
    schengen: false
  },
  {
    id: 399,
    name: 'Mildura Airport',
    city: 'Mildura',
    country: 'Australia',
    iata: 'MQL',
    icao: 'YMIA',
    latitude: -34.2291984558,
    longitude: 142.085998535,
    altitude: 167,
    schengen: false
  },
  {
    id: 400,
    name: 'Launceston Airport',
    city: 'Launceston',
    country: 'Australia',
    iata: 'LST',
    icao: 'YMLT',
    latitude: -41.54529953,
    longitude: 147.214004517,
    altitude: 562,
    schengen: false
  },
  {
    id: 401,
    name: 'Melbourne Moorabbin Airport',
    city: 'Melbourne',
    country: 'Australia',
    iata: 'MBW',
    icao: 'YMMB',
    latitude: -37.9757995605469,
    longitude: 145.102005004883,
    altitude: 50,
    schengen: false
  },
  {
    id: 402,
    name: 'Melbourne International Airport',
    city: 'Melbourne',
    country: 'Australia',
    iata: 'MEL',
    icao: 'YMML',
    latitude: -37.673302,
    longitude: 144.843002,
    altitude: 434,
    schengen: false
  },
  {
    id: 403,
    name: 'Mount Allan Airport',
    city: 'Yuelamu',
    country: 'Australia',
    iata: '',
    icao: 'YMNA',
    latitude: -22.2749996185303,
    longitude: 132.216995239258,
    altitude: 0,
    schengen: false
  },
  {
    id: 404,
    name: 'Maitland Airport',
    city: 'Maitland',
    country: 'Australia',
    iata: 'MTL',
    icao: 'YMND',
    latitude: -32.701265,
    longitude: 151.492912,
    altitude: 85,
    schengen: false
  },
  {
    id: 405,
    name: 'Mount Keith Airport',
    city: 'Mount Keith',
    country: 'Australia',
    iata: 'WME',
    icao: 'YMNE',
    latitude: -27.2863998413086,
    longitude: 120.555000305176,
    altitude: 1792,
    schengen: false
  },
  {
    id: 406,
    name: 'Mount Magnet Airport',
    city: 'Mount Magnet',
    country: 'Australia',
    iata: 'MMG',
    icao: 'YMOG',
    latitude: -28.1161003112793,
    longitude: 117.842002868652,
    altitude: 1354,
    schengen: false
  },
  {
    id: 407,
    name: 'Moree Airport',
    city: 'Moree',
    country: 'Australia',
    iata: 'MRZ',
    icao: 'YMOR',
    latitude: -29.4988994598,
    longitude: 149.845001221,
    altitude: 701,
    schengen: false
  },
  {
    id: 408,
    name: 'RAAF Williams, Point Cook Base',
    city: 'Point Cook',
    country: 'Australia',
    iata: '',
    icao: 'YMPC',
    latitude: -37.932201,
    longitude: 144.753006,
    altitude: 14,
    schengen: false
  },
  {
    id: 409,
    name: 'Moranbah Airport',
    city: 'Moranbah',
    country: 'Australia',
    iata: 'MOV',
    icao: 'YMRB',
    latitude: -22.057800293,
    longitude: 148.07699585,
    altitude: 770,
    schengen: false
  },
  {
    id: 410,
    name: 'Mount Garnet Airport',
    city: 'Mount Garnet',
    country: 'Australia',
    iata: '',
    icao: 'YMRT',
    latitude: -17.7000007629395,
    longitude: 145.149993896484,
    altitude: 657,
    schengen: false
  },
  {
    id: 411,
    name: 'Moruya Airport',
    city: 'Moruya',
    country: 'Australia',
    iata: 'MYA',
    icao: 'YMRY',
    latitude: -35.8978004456,
    longitude: 150.143997192,
    altitude: 14,
    schengen: false
  },
  {
    id: 412,
    name: 'Mount Gambier Airport',
    city: 'Mount Gambier',
    country: 'Australia',
    iata: 'MGB',
    icao: 'YMTG',
    latitude: -37.7456016540527,
    longitude: 140.785003662109,
    altitude: 212,
    schengen: false
  },
  {
    id: 413,
    name: 'Mornington Island Airport',
    city: 'Mornington Island',
    country: 'Australia',
    iata: 'ONG',
    icao: 'YMTI',
    latitude: -16.6625003814697,
    longitude: 139.177993774414,
    altitude: 33,
    schengen: false
  },
  {
    id: 414,
    name: 'Murray Island Airport',
    city: 'Murray Island',
    country: 'Australia',
    iata: 'MYI',
    icao: 'YMUI',
    latitude: -9.91666984558,
    longitude: 144.054992676,
    altitude: 300,
    schengen: false
  },
  {
    id: 415,
    name: 'Maryborough Airport',
    city: 'Maryborough',
    country: 'Australia',
    iata: 'MBH',
    icao: 'YMYB',
    latitude: -25.5133,
    longitude: 152.714996,
    altitude: 38,
    schengen: false
  },
  {
    id: 416,
    name: 'Narrandera Airport',
    city: 'Narrandera',
    country: 'Australia',
    iata: 'NRA',
    icao: 'YNAR',
    latitude: -34.7022018433,
    longitude: 146.511993408,
    altitude: 474,
    schengen: false
  },
  {
    id: 417,
    name: 'Narrabri Airport',
    city: 'Narrabri',
    country: 'Australia',
    iata: 'NAA',
    icao: 'YNBR',
    latitude: -30.3192005157,
    longitude: 149.82699585,
    altitude: 788,
    schengen: false
  },
  {
    id: 418,
    name: 'Ngukurr Airport',
    city: '',
    country: 'Australia',
    iata: 'RPM',
    icao: 'YNGU',
    latitude: -14.7228002548218,
    longitude: 134.746994018555,
    altitude: 45,
    schengen: false
  },
  {
    id: 419,
    name: 'Noonkanbah Airport',
    city: 'Noonkanbah',
    country: 'Australia',
    iata: 'NKB',
    icao: 'YNKA',
    latitude: -18.4946994781,
    longitude: 124.851997375,
    altitude: 0,
    schengen: false
  },
  {
    id: 420,
    name: 'Naracoorte Airport',
    city: 'Naracoorte',
    country: 'Australia',
    iata: 'NAC',
    icao: 'YNRC',
    latitude: -36.9852981567383,
    longitude: 140.725006103516,
    altitude: 169,
    schengen: false
  },
  {
    id: 421,
    name: 'Narromine Airport',
    city: '',
    country: 'Australia',
    iata: 'QRM',
    icao: 'YNRM',
    latitude: -32.2146987915039,
    longitude: 148.225006103516,
    altitude: 782,
    schengen: false
  },
  {
    id: 422,
    name: 'Nyrripi Airport',
    city: 'Nyirripi',
    country: 'Australia',
    iata: '',
    icao: 'YNRR',
    latitude: -22.645299911499,
    longitude: 130.565002441406,
    altitude: 0,
    schengen: false
  },
  {
    id: 423,
    name: 'Ravensthorpe Airport',
    city: 'Ravensthorpe',
    country: 'Australia',
    iata: 'RVT',
    icao: 'YNRV',
    latitude: -33.7971992493,
    longitude: 120.208000183,
    altitude: 197,
    schengen: false
  },
  {
    id: 424,
    name: 'Normanton Airport',
    city: 'Normanton',
    country: 'Australia',
    iata: 'NTN',
    icao: 'YNTN',
    latitude: -17.68409,
    longitude: 141.069664,
    altitude: 73,
    schengen: false
  },
  {
    id: 425,
    name: 'Newman Airport',
    city: 'Newman',
    country: 'Australia',
    iata: 'ZNE',
    icao: 'YNWN',
    latitude: -23.4178009033,
    longitude: 119.803001404,
    altitude: 1724,
    schengen: false
  },
  {
    id: 426,
    name: 'Nypari Airport',
    city: 'Angatja Homeland',
    country: 'Australia',
    iata: '',
    icao: 'YNYP',
    latitude: -26.2000007629395,
    longitude: 130.233001708984,
    altitude: 0,
    schengen: false
  },
  {
    id: 427,
    name: 'Olympic Dam Airport',
    city: 'Olympic Dam',
    country: 'Australia',
    iata: 'OLP',
    icao: 'YOLD',
    latitude: -30.4850006104,
    longitude: 136.876998901,
    altitude: 343,
    schengen: false
  },
  {
    id: 428,
    name: 'Onslow Airport',
    city: 'Onslow',
    country: 'Australia',
    iata: 'ONS',
    icao: 'YOLW',
    latitude: -21.668300628662,
    longitude: 115.1129989624,
    altitude: 7,
    schengen: false
  },
  {
    id: 429,
    name: 'Moomba Airport',
    city: 'Moomba',
    country: 'Australia',
    iata: 'MOO',
    icao: 'YOOM',
    latitude: -28.0993995666504,
    longitude: 140.197006225586,
    altitude: 143,
    schengen: false
  },
  {
    id: 430,
    name: 'Orange Airport',
    city: 'Orange',
    country: 'Australia',
    iata: 'OAG',
    icao: 'YORG',
    latitude: -33.3816986084,
    longitude: 149.132995605,
    altitude: 3115,
    schengen: false
  },
  {
    id: 431,
    name: 'Adelaide International Airport',
    city: 'Adelaide',
    country: 'Australia',
    iata: 'ADL',
    icao: 'YPAD',
    latitude: -34.945,
    longitude: 138.531006,
    altitude: 20,
    schengen: false
  },
  {
    id: 432,
    name: 'Port Augusta Airport',
    city: 'Argyle',
    country: 'Australia',
    iata: 'PUG',
    icao: 'YPAG',
    latitude: -32.5069007873535,
    longitude: 137.716995239258,
    altitude: 56,
    schengen: false
  },
  {
    id: 433,
    name: 'Palm Island Airport',
    city: 'Palm Island',
    country: 'Australia',
    iata: 'PMK',
    icao: 'YPAM',
    latitude: -18.7553005218506,
    longitude: 146.580993652344,
    altitude: 28,
    schengen: false
  },
  {
    id: 434,
    name: 'Papunya Airport',
    city: 'Papunya',
    country: 'Australia',
    iata: '',
    icao: 'YPAY',
    latitude: -23.2467002868652,
    longitude: 131.90299987793,
    altitude: 620,
    schengen: false
  },
  {
    id: 435,
    name: 'Paraburdoo Airport',
    city: 'Paraburdoo',
    country: 'Australia',
    iata: 'PBO',
    icao: 'YPBO',
    latitude: -23.1711006165,
    longitude: 117.745002747,
    altitude: 1406,
    schengen: false
  },
  {
    id: 436,
    name: 'Pooncarie Airport',
    city: 'Pooncarie',
    country: 'Australia',
    iata: '',
    icao: 'YPCE',
    latitude: -33.36669921875,
    longitude: 142.587997436523,
    altitude: 50,
    schengen: false
  },
  {
    id: 437,
    name: 'Darwin International Airport',
    city: 'Darwin',
    country: 'Australia',
    iata: 'DRW',
    icao: 'YPDN',
    latitude: -12.4146995544434,
    longitude: 130.876998901367,
    altitude: 103,
    schengen: false
  },
  {
    id: 438,
    name: 'RAAF Base Pearce',
    city: 'Perth',
    country: 'Australia',
    iata: '',
    icao: 'YPEA',
    latitude: -31.6678009033203,
    longitude: 116.014999389648,
    altitude: 149,
    schengen: false
  },
  {
    id: 439,
    name: 'Lake Macquarie Airport',
    city: 'Lake Macquarie',
    country: 'Australia',
    iata: 'BEO',
    icao: 'YPEC',
    latitude: -33.0667,
    longitude: 151.647995,
    altitude: 2,
    schengen: false
  },
  {
    id: 440,
    name: 'RAAF Base Edinburgh',
    city: 'Edinburgh',
    country: 'Australia',
    iata: '',
    icao: 'YPED',
    latitude: -34.7024993896484,
    longitude: 138.621002197266,
    altitude: 67,
    schengen: false
  },
  {
    id: 441,
    name: 'Gove Airport',
    city: 'Gove',
    country: 'Australia',
    iata: 'GOV',
    icao: 'YPGV',
    latitude: -12.2693996429,
    longitude: 136.817993164,
    altitude: 192,
    schengen: false
  },
  {
    id: 442,
    name: 'Phillip Island Airport',
    city: 'Phillip Island',
    country: 'Australia',
    iata: '',
    icao: 'YPID',
    latitude: -38.5233001708984,
    longitude: 145.326995849609,
    altitude: 13,
    schengen: false
  },
  {
    id: 443,
    name: 'Port Pirie Airport',
    city: '',
    country: 'Australia',
    iata: 'PPI',
    icao: 'YPIR',
    latitude: -33.238899230957,
    longitude: 137.994995117188,
    altitude: 40,
    schengen: false
  },
  {
    id: 444,
    name: 'Perth Jandakot Airport',
    city: 'Perth',
    country: 'Australia',
    iata: 'JAD',
    icao: 'YPJT',
    latitude: -32.0974998474121,
    longitude: 115.880996704102,
    altitude: 99,
    schengen: false
  },
  {
    id: 445,
    name: 'Karratha Airport',
    city: 'Karratha',
    country: 'Australia',
    iata: 'KTA',
    icao: 'YPKA',
    latitude: -20.7122001648,
    longitude: 116.773002625,
    altitude: 29,
    schengen: false
  },
  {
    id: 446,
    name: 'Kalgoorlie Boulder Airport',
    city: 'Kalgoorlie',
    country: 'Australia',
    iata: 'KGI',
    icao: 'YPKG',
    latitude: -30.7894001007,
    longitude: 121.461997986,
    altitude: 1203,
    schengen: false
  },
  {
    id: 447,
    name: 'Parkes Airport',
    city: 'Parkes',
    country: 'Australia',
    iata: 'PKE',
    icao: 'YPKS',
    latitude: -33.131401062,
    longitude: 148.238998413,
    altitude: 1069,
    schengen: false
  },
  {
    id: 448,
    name: 'Port Keats Airport',
    city: 'Wadeye',
    country: 'Australia',
    iata: 'PKT',
    icao: 'YPKT',
    latitude: -14.25,
    longitude: 129.529006958008,
    altitude: 91,
    schengen: false
  },
  {
    id: 449,
    name: 'Kununurra Airport',
    city: 'Kununurra',
    country: 'Australia',
    iata: 'KNX',
    icao: 'YPKU',
    latitude: -15.7781000137,
    longitude: 128.707992554,
    altitude: 145,
    schengen: false
  },
  {
    id: 450,
    name: 'Port Lincoln Airport',
    city: 'Port Lincoln',
    country: 'Australia',
    iata: 'PLO',
    icao: 'YPLC',
    latitude: -34.6053009033,
    longitude: 135.880004883,
    altitude: 36,
    schengen: false
  },
  {
    id: 451,
    name: 'Learmonth Airport',
    city: 'Learmonth',
    country: 'Australia',
    iata: 'LEA',
    icao: 'YPLM',
    latitude: -22.2355995178,
    longitude: 114.088996887,
    altitude: 19,
    schengen: false
  },
  {
    id: 452,
    name: 'Prominent Hill Airport',
    city: 'Prominent Hill',
    country: 'Australia',
    iata: 'PXH',
    icao: 'YPMH',
    latitude: -29.716,
    longitude: 135.5244,
    altitude: 745,
    schengen: false
  },
  {
    id: 453,
    name: 'Pormpuraaw Airport',
    city: 'Pormpuraaw',
    country: 'Australia',
    iata: 'EDR',
    icao: 'YPMP',
    latitude: -14.896451,
    longitude: 141.60908,
    altitude: 10,
    schengen: false
  },
  {
    id: 454,
    name: 'Port Macquarie Airport',
    city: 'Port Macquarie',
    country: 'Australia',
    iata: 'PQQ',
    icao: 'YPMQ',
    latitude: -31.4358005524,
    longitude: 152.863006592,
    altitude: 12,
    schengen: false
  },
  {
    id: 455,
    name: 'Portland Airport',
    city: 'Portland',
    country: 'Australia',
    iata: 'PTJ',
    icao: 'YPOD',
    latitude: -38.3180999755859,
    longitude: 141.470993041992,
    altitude: 265,
    schengen: false
  },
  {
    id: 456,
    name: 'Port Hedland International Airport',
    city: 'Port Hedland',
    country: 'Australia',
    iata: 'PHE',
    icao: 'YPPD',
    latitude: -20.3777999878,
    longitude: 118.625999451,
    altitude: 33,
    schengen: false
  },
  {
    id: 457,
    name: 'Adelaide Parafield Airport',
    city: 'Adelaide',
    country: 'Australia',
    iata: '',
    icao: 'YPPF',
    latitude: -34.7933006287,
    longitude: 138.632995605,
    altitude: 57,
    schengen: false
  },
  {
    id: 458,
    name: 'Perth International Airport',
    city: 'Perth',
    country: 'Australia',
    iata: 'PER',
    icao: 'YPPH',
    latitude: -31.940299987793,
    longitude: 115.967002868652,
    altitude: 67,
    schengen: false
  },
  {
    id: 459,
    name: 'Penneshaw Airport',
    city: 'Penneshaw',
    country: 'Australia',
    iata: 'PEA',
    icao: 'YPSH',
    latitude: -35.7558462874,
    longitude: 137.962875366,
    altitude: 0,
    schengen: false
  },
  {
    id: 460,
    name: 'Tindal Airport',
    city: 'Katherine',
    country: 'Australia',
    iata: 'KTR',
    icao: 'YPTN',
    latitude: -14.5211000442505,
    longitude: 132.378005981445,
    altitude: 443,
    schengen: false
  },
  {
    id: 461,
    name: 'Woomera Airfield',
    city: 'Woomera',
    country: 'Australia',
    iata: 'UMR',
    icao: 'YPWR',
    latitude: -31.1441993713379,
    longitude: 136.817001342773,
    altitude: 548,
    schengen: false
  },
  {
    id: 462,
    name: 'Quilpie Airport',
    city: 'Quilpie',
    country: 'Australia',
    iata: 'ULP',
    icao: 'YQLP',
    latitude: -26.6121997833252,
    longitude: 144.253005981445,
    altitude: 655,
    schengen: false
  },
  {
    id: 463,
    name: 'Redcliffe Airport',
    city: 'Rothwell',
    country: 'Australia',
    iata: '',
    icao: 'YRED',
    latitude: -27.2066993713379,
    longitude: 153.067993164063,
    altitude: 2,
    schengen: false
  },
  {
    id: 464,
    name: 'Renmark Airport',
    city: 'Renmark',
    country: 'Australia',
    iata: 'RMK',
    icao: 'YREN',
    latitude: -34.1963996887207,
    longitude: 140.67399597168,
    altitude: 115,
    schengen: false
  },
  {
    id: 465,
    name: 'Richmond Airport',
    city: 'Richmond',
    country: 'Australia',
    iata: 'RCM',
    icao: 'YRMD',
    latitude: -20.7019004821777,
    longitude: 143.115005493164,
    altitude: 676,
    schengen: false
  },
  {
    id: 466,
    name: 'Ramingining Airport',
    city: 'Ramingining',
    country: 'Australia',
    iata: 'RAM',
    icao: 'YRNG',
    latitude: -12.3563995361328,
    longitude: 134.897994995117,
    altitude: 206,
    schengen: false
  },
  {
    id: 467,
    name: 'Roma Airport',
    city: 'Roma',
    country: 'Australia',
    iata: 'RMA',
    icao: 'YROM',
    latitude: -26.5450000763,
    longitude: 148.774993896,
    altitude: 1032,
    schengen: false
  },
  {
    id: 468,
    name: 'Roper Bar Airport',
    city: 'Roper Bar',
    country: 'Australia',
    iata: 'RPB',
    icao: 'YRRB',
    latitude: -14.734814,
    longitude: 134.525485,
    altitude: 92,
    schengen: false
  },
  {
    id: 469,
    name: 'Rottnest Island Airport',
    city: 'Rottnest Island',
    country: 'Australia',
    iata: 'RTS',
    icao: 'YRTI',
    latitude: -32.006699,
    longitude: 115.540001,
    altitude: 12,
    schengen: false
  },
  {
    id: 470,
    name: 'Sydney Bankstown Airport',
    city: 'Sydney',
    country: 'Australia',
    iata: 'BWU',
    icao: 'YSBK',
    latitude: -33.9244003295898,
    longitude: 150.988006591797,
    altitude: 29,
    schengen: false
  },
  {
    id: 471,
    name: 'Canberra International Airport',
    city: 'Canberra',
    country: 'Australia',
    iata: 'CBR',
    icao: 'YSCB',
    latitude: -35.3069000244141,
    longitude: 149.195007324219,
    altitude: 1886,
    schengen: false
  },
  {
    id: 472,
    name: 'Coffs Harbour Airport',
    city: "Coff's Harbour",
    country: 'Australia',
    iata: 'CFS',
    icao: 'YSCH',
    latitude: -30.3206005096,
    longitude: 153.115997314,
    altitude: 18,
    schengen: false
  },
  {
    id: 473,
    name: 'Camden Airport',
    city: 'Camden',
    country: 'Australia',
    iata: 'CDU',
    icao: 'YSCN',
    latitude: -34.0402984619141,
    longitude: 150.686996459961,
    altitude: 230,
    schengen: false
  },
  {
    id: 474,
    name: 'Scone Airport',
    city: 'Scone',
    country: 'Australia',
    iata: 'NSO',
    icao: 'YSCO',
    latitude: -32.0372009277344,
    longitude: 150.832000732422,
    altitude: 745,
    schengen: false
  },
  {
    id: 475,
    name: 'Dubbo City Regional Airport',
    city: 'Dubbo',
    country: 'Australia',
    iata: 'DBO',
    icao: 'YSDU',
    latitude: -32.2167015076,
    longitude: 148.574996948,
    altitude: 935,
    schengen: false
  },
  {
    id: 476,
    name: 'St George Airport',
    city: 'St George',
    country: 'Australia',
    iata: 'SGO',
    icao: 'YSGE',
    latitude: -28.0496997833252,
    longitude: 148.595001220703,
    altitude: 656,
    schengen: false
  },
  {
    id: 477,
    name: 'Shark Bay Airport',
    city: 'Shark Bay',
    country: 'Australia',
    iata: 'MJK',
    icao: 'YSHK',
    latitude: -25.8938999176,
    longitude: 113.577003479,
    altitude: 111,
    schengen: false
  },
  {
    id: 478,
    name: 'Shute Harbour Airport',
    city: 'Shute Harbour',
    country: 'Australia',
    iata: 'JHQ',
    icao: 'YSHR',
    latitude: -20.277221,
    longitude: 148.755556,
    altitude: 12,
    schengen: false
  },
  {
    id: 479,
    name: 'Shepparton Airport',
    city: 'Shepparton',
    country: 'Australia',
    iata: 'SHT',
    icao: 'YSHT',
    latitude: -36.4289016723633,
    longitude: 145.393005371094,
    altitude: 374,
    schengen: false
  },
  {
    id: 480,
    name: 'Saibai Island Airport',
    city: 'Saibai Island',
    country: 'Australia',
    iata: 'SBR',
    icao: 'YSII',
    latitude: -9.37833023071,
    longitude: 142.625,
    altitude: 15,
    schengen: false
  },
  {
    id: 481,
    name: 'Smithton Airport',
    city: '',
    country: 'Australia',
    iata: 'SIO',
    icao: 'YSMI',
    latitude: -40.8349990844727,
    longitude: 145.083999633789,
    altitude: 31,
    schengen: false
  },
  {
    id: 482,
    name: 'Snake Bay Airport',
    city: '',
    country: 'Australia',
    iata: 'SNB',
    icao: 'YSNB',
    latitude: -11.4228000640869,
    longitude: 130.654006958008,
    altitude: 173,
    schengen: false
  },
  {
    id: 483,
    name: 'Nowra Airport',
    city: 'Nowra',
    country: 'Australia',
    iata: 'NOA',
    icao: 'YSNW',
    latitude: -34.9488983154297,
    longitude: 150.537002563477,
    altitude: 400,
    schengen: false
  },
  {
    id: 484,
    name: 'RAAF Base Richmond',
    city: 'Richmond',
    country: 'Australia',
    iata: 'XRH',
    icao: 'YSRI',
    latitude: -33.600601,
    longitude: 150.781006,
    altitude: 67,
    schengen: false
  },
  {
    id: 485,
    name: 'Strahan Airport',
    city: 'Strahan',
    country: 'Australia',
    iata: 'SRN',
    icao: 'YSRN',
    latitude: -42.1549987792969,
    longitude: 145.292007446289,
    altitude: 20,
    schengen: false
  },
  {
    id: 486,
    name: 'Sydney Kingsford Smith International Airport',
    city: 'Sydney',
    country: 'Australia',
    iata: 'SYD',
    icao: 'YSSY',
    latitude: -33.9460983276367,
    longitude: 151.177001953125,
    altitude: 21,
    schengen: false
  },
  {
    id: 487,
    name: 'Tamworth Airport',
    city: 'Tamworth',
    country: 'Australia',
    iata: 'TMW',
    icao: 'YSTW',
    latitude: -31.0839004517,
    longitude: 150.847000122,
    altitude: 1334,
    schengen: false
  },
  {
    id: 488,
    name: 'Wagga Wagga City Airport',
    city: 'Wagga Wagga',
    country: 'Australia',
    iata: 'WGA',
    icao: 'YSWG',
    latitude: -35.1652984619,
    longitude: 147.466003418,
    altitude: 724,
    schengen: false
  },
  {
    id: 489,
    name: 'Swan Hill Airport',
    city: 'Swan Hill',
    country: 'Australia',
    iata: 'SWH',
    icao: 'YSWH',
    latitude: -35.3758010864258,
    longitude: 143.533004760742,
    altitude: 234,
    schengen: false
  },
  {
    id: 490,
    name: 'Stawell Airport',
    city: '',
    country: 'Australia',
    iata: 'SWC',
    icao: 'YSWL',
    latitude: -37.0717010498047,
    longitude: 142.740997314453,
    altitude: 807,
    schengen: false
  },
  {
    id: 491,
    name: 'Taroom Airport',
    city: 'Taroom',
    country: 'Australia',
    iata: 'XTO',
    icao: 'YTAM',
    latitude: -25.801700592041,
    longitude: 149.899993896484,
    altitude: 240,
    schengen: false
  },
  {
    id: 492,
    name: 'Theodore Airport',
    city: 'Theodore',
    country: 'Australia',
    iata: 'TDR',
    icao: 'YTDR',
    latitude: -24.9932994842529,
    longitude: 150.093002319336,
    altitude: 171,
    schengen: false
  },
  {
    id: 493,
    name: 'Telfer Airport',
    city: 'Telfer',
    country: 'Australia',
    iata: 'TEF',
    icao: 'YTEF',
    latitude: -21.7150001525879,
    longitude: 122.228996276855,
    altitude: 970,
    schengen: false
  },
  {
    id: 494,
    name: 'Temora Airport',
    city: 'Temora',
    country: 'Australia',
    iata: 'TEM',
    icao: 'YTEM',
    latitude: -34.4213981628418,
    longitude: 147.511993408203,
    altitude: 921,
    schengen: false
  },
  {
    id: 495,
    name: 'Thargomindah Airport',
    city: 'Thargomindah',
    country: 'Australia',
    iata: 'XTG',
    icao: 'YTGM',
    latitude: -27.986400604248,
    longitude: 143.811004638672,
    altitude: 433,
    schengen: false
  },
  {
    id: 496,
    name: 'The Granites Airport',
    city: 'The Granites',
    country: 'Australia',
    iata: '',
    icao: 'YTGT',
    latitude: -20.5482997894287,
    longitude: 130.34700012207,
    altitude: 0,
    schengen: false
  },
  {
    id: 497,
    name: 'Tibooburra Airport',
    city: '',
    country: 'Australia',
    iata: 'TYB',
    icao: 'YTIB',
    latitude: -29.451099395752,
    longitude: 142.057998657227,
    altitude: 584,
    schengen: false
  },
  {
    id: 498,
    name: 'Ti Tree Airport',
    city: 'Ti Tree',
    country: 'Australia',
    iata: '',
    icao: 'YTIT',
    latitude: -22.1317005157471,
    longitude: 133.419998168945,
    altitude: 0,
    schengen: false
  },
  {
    id: 499,
    name: 'Tilpa Airport',
    city: 'Tilpa',
    country: 'Australia',
    iata: '',
    icao: 'YTLP',
    latitude: -30.9333000183105,
    longitude: 144.417007446289,
    altitude: 0,
    schengen: false
  },
  {
    id: 500,
    name: 'Tumut Airport',
    city: '',
    country: 'Australia',
    iata: 'TUM',
    icao: 'YTMU',
    latitude: -35.2627983093262,
    longitude: 148.240997314453,
    altitude: 878,
    schengen: false
  },
  {
    id: 501,
    name: 'Thangool Airport',
    city: 'Biloela',
    country: 'Australia',
    iata: 'THG',
    icao: 'YTNG',
    latitude: -24.4939002990723,
    longitude: 150.57600402832,
    altitude: 644,
    schengen: false
  },
  {
    id: 502,
    name: 'Tennant Creek Airport',
    city: 'Tennant Creek',
    country: 'Australia',
    iata: 'TCA',
    icao: 'YTNK',
    latitude: -19.6343994140625,
    longitude: 134.182998657227,
    altitude: 1236,
    schengen: false
  },
  {
    id: 503,
    name: 'Taree Airport',
    city: 'Taree',
    country: 'Australia',
    iata: 'TRO',
    icao: 'YTRE',
    latitude: -31.8885993958,
    longitude: 152.514007568,
    altitude: 38,
    schengen: false
  },
  {
    id: 504,
    name: 'Toowoomba Airport',
    city: 'Toowoomba',
    country: 'Australia',
    iata: 'TWB',
    icao: 'YTWB',
    latitude: -27.542801,
    longitude: 151.916,
    altitude: 2086,
    schengen: false
  },
  {
    id: 505,
    name: 'Utopia Airport',
    city: 'Utopia',
    country: 'Australia',
    iata: '',
    icao: 'YUTP',
    latitude: -22.0550003051758,
    longitude: 134.789993286133,
    altitude: 0,
    schengen: false
  },
  {
    id: 506,
    name: 'Victoria River Downs Airport',
    city: 'Victoria River Downs',
    country: 'Australia',
    iata: 'VCD',
    icao: 'YVRD',
    latitude: -16.4021244049072,
    longitude: 131.004974365234,
    altitude: 89,
    schengen: false
  },
  {
    id: 507,
    name: 'Warrabri Airport',
    city: 'Ali-Curung',
    country: 'Australia',
    iata: '',
    icao: 'YWBI',
    latitude: -21,
    longitude: 134.397003173828,
    altitude: 383,
    schengen: false
  },
  {
    id: 508,
    name: 'Warrnambool Airport',
    city: 'Warrnambool',
    country: 'Australia',
    iata: 'WMB',
    icao: 'YWBL',
    latitude: -38.2952995300293,
    longitude: 142.447006225586,
    altitude: 242,
    schengen: false
  },
  {
    id: 509,
    name: 'Warburton Airport',
    city: 'Warburton Community',
    country: 'Australia',
    iata: '',
    icao: 'YWBR',
    latitude: -26.1282997131348,
    longitude: 126.583000183105,
    altitude: 1500,
    schengen: false
  },
  {
    id: 510,
    name: 'Warraber Island Airport',
    city: 'Sue Islet',
    country: 'Australia',
    iata: 'SYU',
    icao: 'YWBS',
    latitude: -10.2082996368408,
    longitude: 142.824996948242,
    altitude: 3,
    schengen: false
  },
  {
    id: 511,
    name: 'Wilcannia Airport',
    city: 'Wilcannia',
    country: 'Australia',
    iata: 'WIO',
    icao: 'YWCA',
    latitude: -31.5263996124268,
    longitude: 143.375,
    altitude: 250,
    schengen: false
  },
  {
    id: 512,
    name: 'Windorah Airport',
    city: 'Windorah',
    country: 'Australia',
    iata: 'WNR',
    icao: 'YWDH',
    latitude: -25.4130992889404,
    longitude: 142.667007446289,
    altitude: 452,
    schengen: false
  },
  {
    id: 513,
    name: 'Wangaratta Airport',
    city: '',
    country: 'Australia',
    iata: 'WGT',
    icao: 'YWGT',
    latitude: -36.4157981872559,
    longitude: 146.307006835938,
    altitude: 504,
    schengen: false
  },
  {
    id: 514,
    name: 'Whyalla Airport',
    city: 'Whyalla',
    country: 'Australia',
    iata: 'WYA',
    icao: 'YWHA',
    latitude: -33.0588989257812,
    longitude: 137.514007568359,
    altitude: 41,
    schengen: false
  },
  {
    id: 515,
    name: 'Whitsunday Island Airport',
    city: 'Airlie Beach',
    country: 'Australia',
    iata: 'WSY',
    icao: 'YWHI',
    latitude: -20.27611,
    longitude: 148.755,
    altitude: 40,
    schengen: false
  },
  {
    id: 516,
    name: 'Warracknabeal Airport',
    city: '',
    country: 'Australia',
    iata: 'WKB',
    icao: 'YWKB',
    latitude: -36.3210983276367,
    longitude: 142.419006347656,
    altitude: 397,
    schengen: false
  },
  {
    id: 517,
    name: 'Walgett Airport',
    city: 'Walgett',
    country: 'Australia',
    iata: 'WGE',
    icao: 'YWLG',
    latitude: -30.0328006744385,
    longitude: 148.126007080078,
    altitude: 439,
    schengen: false
  },
  {
    id: 518,
    name: 'Newcastle Airport',
    city: 'Newcastle',
    country: 'Australia',
    iata: 'NTL',
    icao: 'YWLM',
    latitude: -32.7949981689453,
    longitude: 151.833999633789,
    altitude: 31,
    schengen: false
  },
  {
    id: 519,
    name: 'Wiluna Airport',
    city: 'Wiluna',
    country: 'Australia',
    iata: 'WUN',
    icao: 'YWLU',
    latitude: -26.6291999816895,
    longitude: 120.221000671387,
    altitude: 1649,
    schengen: false
  },
  {
    id: 520,
    name: 'William Creek Airport',
    city: 'William Creek',
    country: 'Australia',
    iata: '',
    icao: 'YWMC',
    latitude: -28.9067001342773,
    longitude: 136.341995239258,
    altitude: 91,
    schengen: false
  },
  {
    id: 521,
    name: 'Wollongong Airport',
    city: 'Wollongong',
    country: 'Australia',
    iata: 'WOL',
    icao: 'YWOL',
    latitude: -34.5611000061035,
    longitude: 150.789001464844,
    altitude: 31,
    schengen: false
  },
  {
    id: 522,
    name: 'Warren Airport',
    city: '',
    country: 'Australia',
    iata: 'QRR',
    icao: 'YWRN',
    latitude: -31.7332992553711,
    longitude: 147.802993774414,
    altitude: 669,
    schengen: false
  },
  {
    id: 523,
    name: 'West Sale Airport',
    city: 'Sale',
    country: 'Australia',
    iata: 'SXE',
    icao: 'YWSL',
    latitude: -38.090827,
    longitude: 146.965335,
    altitude: 72,
    schengen: false
  },
  {
    id: 524,
    name: 'Winton Airport',
    city: 'Winton',
    country: 'Australia',
    iata: 'WIN',
    icao: 'YWTN',
    latitude: -22.3635997772217,
    longitude: 143.085998535156,
    altitude: 638,
    schengen: false
  },
  {
    id: 525,
    name: 'Warnervale Airport',
    city: 'Warnervale Airport',
    country: 'Australia',
    iata: '',
    icao: 'YWVA',
    latitude: -33.240278,
    longitude: 151.429722,
    altitude: 25,
    schengen: false
  },
  {
    id: 526,
    name: 'West Wyalong Airport',
    city: 'West Wyalong',
    country: 'Australia',
    iata: 'WWY',
    icao: 'YWWL',
    latitude: -33.9371986389,
    longitude: 147.190994263,
    altitude: 859,
    schengen: false
  },
  {
    id: 527,
    name: 'Wyndham Airport',
    city: 'Wyndham',
    country: 'Australia',
    iata: 'WYN',
    icao: 'YWYM',
    latitude: -15.5114002227783,
    longitude: 128.15299987793,
    altitude: 14,
    schengen: false
  },
  {
    id: 528,
    name: 'Wynyard Airport',
    city: 'Burnie',
    country: 'Australia',
    iata: 'BWT',
    icao: 'YWYY',
    latitude: -40.9989013671875,
    longitude: 145.731002807617,
    altitude: 62,
    schengen: false
  },
  {
    id: 529,
    name: 'Yorke Island Airport',
    city: 'Yorke Island',
    country: 'Australia',
    iata: 'OKR',
    icao: 'YYKI',
    latitude: -9.752801,
    longitude: 143.405673,
    altitude: 10,
    schengen: false
  },
  {
    id: 530,
    name: 'Yam Island Airport',
    city: 'Yam Island',
    country: 'Australia',
    iata: 'XMY',
    icao: 'YYMI',
    latitude: -9.90110969543457,
    longitude: 142.776000976563,
    altitude: 0,
    schengen: false
  },
  {
    id: 531,
    name: 'Yuendumu Airport',
    city: 'Yuendumu ',
    country: 'Australia',
    iata: 'YUE',
    icao: 'YYND',
    latitude: -22.2541999816895,
    longitude: 131.781997680664,
    altitude: 2205,
    schengen: false
  },
  {
    id: 532,
    name: 'Young Airport',
    city: '',
    country: 'Australia',
    iata: 'NGA',
    icao: 'YYNG',
    latitude: -34.2555999755859,
    longitude: 148.248001098633,
    altitude: 1267,
    schengen: false
  },
  {
    id: 533,
    name: 'Yarram Airport',
    city: 'Yarram',
    country: 'Australia',
    iata: '',
    icao: 'YYRM',
    latitude: -38.5666999816895,
    longitude: 146.755004882813,
    altitude: 15,
    schengen: false
  },
  {
    id: 534,
    name: 'Yalata Mission Airport',
    city: 'Yalata',
    country: 'Australia',
    iata: 'KYI',
    icao: 'YYTA',
    latitude: -31.4706001281738,
    longitude: 131.824996948242,
    altitude: 0,
    schengen: false
  },
  {
    id: 535,
    name: 'Krems Airport',
    city: 'Krems',
    country: 'Austria',
    iata: '',
    icao: 'LOAG',
    latitude: 48.446389,
    longitude: 15.634167,
    altitude: 1017,
    schengen: true
  },
  {
    id: 536,
    name: 'Wiener Neustadt East Airport',
    city: 'Wiener Neustadt Ost',
    country: 'Austria',
    iata: '',
    icao: 'LOAN',
    latitude: 47.8432998657227,
    longitude: 16.2600994110107,
    altitude: 896,
    schengen: true
  },
  {
    id: 537,
    name: 'Stockerau Airport',
    city: 'Stockerau',
    country: 'Austria',
    iata: '',
    icao: 'LOAU',
    latitude: 48.4099998474121,
    longitude: 16.1916999816895,
    altitude: 686,
    schengen: true
  },
  {
    id: 538,
    name: 'Vöslau Airport',
    city: 'Bad Voeslau',
    country: 'Austria',
    iata: '',
    icao: 'LOAV',
    latitude: 47.9650001525879,
    longitude: 16.2600002288818,
    altitude: 765,
    schengen: true
  },
  {
    id: 539,
    name: 'Flugplatz Punitz',
    city: 'Punitz-Guessing',
    country: 'Austria',
    iata: '',
    icao: 'LOGG',
    latitude: 47.14653,
    longitude: 16.316843,
    altitude: 950,
    schengen: true
  },
  {
    id: 540,
    name: 'Niederoeblarn Airport',
    city: 'Niederoeblarn',
    country: 'Austria',
    iata: '',
    icao: 'LOGO',
    latitude: 47.478298,
    longitude: 14.0083,
    altitude: 2142,
    schengen: true
  },
  {
    id: 541,
    name: 'Hohenems-Dornbirn Airport',
    city: 'Hohenems',
    country: 'Austria',
    iata: 'HOH',
    icao: 'LOIH',
    latitude: 47.3849983215,
    longitude: 9.69999980927,
    altitude: 1352,
    schengen: true
  },
  {
    id: 542,
    name: 'St. Johann In Tirol Airport',
    city: 'St. Johann in Tirol',
    country: 'Austria',
    iata: '',
    icao: 'LOIJ',
    latitude: 47.5201,
    longitude: 12.4497,
    altitude: 2198,
    schengen: true
  },
  {
    id: 543,
    name: 'Nötsch Im Gailtal Airport',
    city: 'Notsch Im Gailtal',
    country: 'Austria',
    iata: '',
    icao: 'LOKN',
    latitude: 46.5811004638672,
    longitude: 13.6291999816895,
    altitude: 1801,
    schengen: true
  },
  {
    id: 544,
    name: 'Wels Airport',
    city: 'Wels',
    country: 'Austria',
    iata: '',
    icao: 'LOLW',
    latitude: 48.1833000183106,
    longitude: 14.0409002304077,
    altitude: 1043,
    schengen: true
  },
  {
    id: 545,
    name: 'Mauterndorf Airport',
    city: 'Mauterndorf',
    country: 'Austria',
    iata: '',
    icao: 'LOSM',
    latitude: 47.1324996948242,
    longitude: 13.6969003677368,
    altitude: 3642,
    schengen: true
  },
  {
    id: 546,
    name: 'Graz Airport',
    city: 'Graz',
    country: 'Austria',
    iata: 'GRZ',
    icao: 'LOWG',
    latitude: 46.9911003112793,
    longitude: 15.4395999908447,
    altitude: 1115,
    schengen: true
  },
  {
    id: 547,
    name: 'Innsbruck Airport',
    city: 'Innsbruck',
    country: 'Austria',
    iata: 'INN',
    icao: 'LOWI',
    latitude: 47.260201,
    longitude: 11.344,
    altitude: 1907,
    schengen: true
  },
  {
    id: 548,
    name: 'Klagenfurt Airport',
    city: 'Klagenfurt',
    country: 'Austria',
    iata: 'KLU',
    icao: 'LOWK',
    latitude: 46.642502,
    longitude: 14.3377,
    altitude: 1472,
    schengen: true
  },
  {
    id: 549,
    name: 'Linz Hörsching Airport',
    city: 'Linz',
    country: 'Austria',
    iata: 'LNZ',
    icao: 'LOWL',
    latitude: 48.2332,
    longitude: 14.1875,
    altitude: 980,
    schengen: true
  },
  {
    id: 550,
    name: 'Salzburg Airport',
    city: 'Salzburg',
    country: 'Austria',
    iata: 'SZG',
    icao: 'LOWS',
    latitude: 47.7933006287,
    longitude: 13.0043001175,
    altitude: 1411,
    schengen: true
  },
  {
    id: 551,
    name: 'Vienna International Airport',
    city: 'Vienna',
    country: 'Austria',
    iata: 'VIE',
    icao: 'LOWW',
    latitude: 48.110298156738,
    longitude: 16.569700241089,
    altitude: 600,
    schengen: true
  },
  {
    id: 552,
    name: 'Zell Am See Airport',
    city: 'Zell am See',
    country: 'Austria',
    iata: '',
    icao: 'LOWZ',
    latitude: 47.292222,
    longitude: 12.7875,
    altitude: 2470,
    schengen: true
  },
  {
    id: 553,
    name: 'Brumowski  Air Base',
    city: 'Tulln',
    country: 'Austria',
    iata: '',
    icao: 'LOXT',
    latitude: 48.3167,
    longitude: 16.116699,
    altitude: 574,
    schengen: true
  },
  {
    id: 554,
    name: 'Hinterstoisser Air Base',
    city: 'Zeltweg',
    country: 'Austria',
    iata: '',
    icao: 'LOXZ',
    latitude: 47.202801,
    longitude: 14.7442,
    altitude: 2264,
    schengen: true
  },
  {
    id: 555,
    name: 'Stepanakert Air Base',
    city: 'Stepanakert',
    country: 'Azerbaijan',
    iata: '',
    icao: 'UB13',
    latitude: 39.9014015197754,
    longitude: 46.7869987487793,
    altitude: 2001,
    schengen: false
  },
  {
    id: 556,
    name: 'Heydar Aliyev International Airport',
    city: 'Baku',
    country: 'Azerbaijan',
    iata: 'GYD',
    icao: 'UBBB',
    latitude: 40.4674987792969,
    longitude: 50.0466995239258,
    altitude: 10,
    schengen: false
  },
  {
    id: 557,
    name: 'Ganja Airport',
    city: 'Ganja',
    country: 'Azerbaijan',
    iata: 'KVD',
    icao: 'UBBG',
    latitude: 40.7377014160156,
    longitude: 46.3176002502441,
    altitude: 1083,
    schengen: false
  },
  {
    id: 558,
    name: 'Lankaran International Airport',
    city: 'Lankaran',
    country: 'Azerbaijan',
    iata: 'LLK',
    icao: 'UBBL',
    latitude: 38.7463989258,
    longitude: 48.8180007935,
    altitude: 30,
    schengen: false
  },
  {
    id: 559,
    name: 'Nakhchivan Airport',
    city: 'Nakhchivan',
    country: 'Azerbaijan',
    iata: 'NAJ',
    icao: 'UBBN',
    latitude: 39.1888008117676,
    longitude: 45.4584007263184,
    altitude: 2863,
    schengen: false
  },
  {
    id: 560,
    name: 'Gabala International Airport',
    city: 'Qabala',
    country: 'Azerbaijan',
    iata: 'GBB',
    icao: 'UBBQ',
    latitude: 40.826667,
    longitude: 47.7125,
    altitude: 935,
    schengen: false
  },
  {
    id: 561,
    name: 'Zaqatala International Airport',
    city: 'Zaqatala',
    country: 'Azerbaijan',
    iata: 'ZTU',
    icao: 'UBBY',
    latitude: 41.562222,
    longitude: 46.667221,
    altitude: 1279,
    schengen: false
  },
  {
    id: 562,
    name: 'Clarence A. Bain Airport',
    city: 'Clarence Bain',
    country: 'Bahamas',
    iata: 'MAY',
    icao: 'MYAB',
    latitude: 24.2877006530762,
    longitude: -77.6846008300781,
    altitude: 19,
    schengen: false
  },
  {
    id: 563,
    name: 'Andros Town Airport',
    city: 'Andros Town',
    country: 'Bahamas',
    iata: 'ASD',
    icao: 'MYAF',
    latitude: 24.6979007720947,
    longitude: -77.7956008911133,
    altitude: 5,
    schengen: false
  },
  {
    id: 564,
    name: 'Congo Town Airport',
    city: 'Andros',
    country: 'Bahamas',
    iata: 'COX',
    icao: 'MYAK',
    latitude: 24.158701,
    longitude: -77.589798,
    altitude: 15,
    schengen: false
  },
  {
    id: 565,
    name: 'Leonard M Thompson International Airport',
    city: 'Marsh Harbor',
    country: 'Bahamas',
    iata: 'MHH',
    icao: 'MYAM',
    latitude: 26.5114,
    longitude: -77.083503,
    altitude: 6,
    schengen: false
  },
  {
    id: 566,
    name: 'San Andros Airport',
    city: 'San Andros',
    country: 'Bahamas',
    iata: 'SAQ',
    icao: 'MYAN',
    latitude: 25.0538005828857,
    longitude: -78.0490036010742,
    altitude: 5,
    schengen: false
  },
  {
    id: 567,
    name: 'Spring Point Airport',
    city: 'Spring Point',
    country: 'Bahamas',
    iata: 'AXP',
    icao: 'MYAP',
    latitude: 22.4417991638,
    longitude: -73.9709014893,
    altitude: 11,
    schengen: false
  },
  {
    id: 568,
    name: 'Sandy Point Airport',
    city: 'Sandy Point',
    country: 'Bahamas',
    iata: '',
    icao: 'MYAS',
    latitude: 26.0046005249023,
    longitude: -77.3955001831055,
    altitude: 8,
    schengen: false
  },
  {
    id: 569,
    name: 'Treasure Cay Airport',
    city: 'Treasure Cay',
    country: 'Bahamas',
    iata: 'TCB',
    icao: 'MYAT',
    latitude: 26.745300293,
    longitude: -77.3912963867,
    altitude: 8,
    schengen: false
  },
  {
    id: 570,
    name: 'Abaco I Walker C Airport',
    city: "Walker's Cay",
    country: 'Bahamas',
    iata: 'WKR',
    icao: 'MYAW',
    latitude: 27.2667007446289,
    longitude: -78.3996963500977,
    altitude: 10,
    schengen: false
  },
  {
    id: 571,
    name: 'Chub Cay Airport',
    city: 'Chub Cay',
    country: 'Bahamas',
    iata: 'CCZ',
    icao: 'MYBC',
    latitude: 25.4171009063721,
    longitude: -77.8808975219727,
    altitude: 5,
    schengen: false
  },
  {
    id: 572,
    name: 'Great Harbour Cay Airport',
    city: 'Bullocks Harbour',
    country: 'Bahamas',
    iata: 'GHC',
    icao: 'MYBG',
    latitude: 25.7383,
    longitude: -77.840103,
    altitude: 18,
    schengen: false
  },
  {
    id: 573,
    name: 'South Bimini Airport',
    city: 'Alice Town',
    country: 'Bahamas',
    iata: 'BIM',
    icao: 'MYBS',
    latitude: 25.6998996735,
    longitude: -79.2647018433,
    altitude: 10,
    schengen: false
  },
  {
    id: 574,
    name: "Arthur's Town Airport",
    city: "Arthur's Town",
    country: 'Bahamas',
    iata: 'ATC',
    icao: 'MYCA',
    latitude: 24.6294,
    longitude: -75.673797,
    altitude: 18,
    schengen: false
  },
  {
    id: 575,
    name: 'New Bight Airport',
    city: 'Cat Island',
    country: 'Bahamas',
    iata: 'TBI',
    icao: 'MYCB',
    latitude: 24.3153,
    longitude: -75.452301,
    altitude: 5,
    schengen: false
  },
  {
    id: 576,
    name: 'Colonel Hill Airport',
    city: 'Colonel Hill',
    country: 'Bahamas',
    iata: 'CRI',
    icao: 'MYCI',
    latitude: 22.7455997467,
    longitude: -74.1824035645,
    altitude: 5,
    schengen: false
  },
  {
    id: 577,
    name: 'Black Point Airstrip',
    city: 'Black Point',
    country: 'Bahamas',
    iata: '',
    icao: 'MYEB',
    latitude: 24.0894884488,
    longitude: -76.3979172707,
    altitude: 10,
    schengen: false
  },
  {
    id: 578,
    name: 'Exuma International Airport',
    city: 'Great Exuma',
    country: 'Bahamas',
    iata: 'GGT',
    icao: 'MYEF',
    latitude: 23.5625991821,
    longitude: -75.8779983521,
    altitude: 9,
    schengen: false
  },
  {
    id: 579,
    name: 'George Town Airport',
    city: 'George Town',
    country: 'Bahamas',
    iata: '',
    icao: 'MYEG',
    latitude: 23.4666996002,
    longitude: -75.7817001343,
    altitude: 5,
    schengen: false
  },
  {
    id: 580,
    name: 'North Eleuthera Airport',
    city: 'North Eleuthera',
    country: 'Bahamas',
    iata: 'ELH',
    icao: 'MYEH',
    latitude: 25.474899292,
    longitude: -76.6835021973,
    altitude: 13,
    schengen: false
  },
  {
    id: 581,
    name: "Governor's Harbour Airport",
    city: "Governor's Harbor",
    country: 'Bahamas',
    iata: 'GHB',
    icao: 'MYEM',
    latitude: 25.2847003937,
    longitude: -76.3310012817,
    altitude: 26,
    schengen: false
  },
  {
    id: 582,
    name: 'Normans Cay Airport',
    city: "Norman's Cay",
    country: 'Bahamas',
    iata: 'NMC',
    icao: 'MYEN',
    latitude: 24.5942993164063,
    longitude: -76.820198059082,
    altitude: 8,
    schengen: false
  },
  {
    id: 583,
    name: 'Rock Sound Airport',
    city: 'Rock Sound',
    country: 'Bahamas',
    iata: 'RSD',
    icao: 'MYER',
    latitude: 24.8950787333,
    longitude: -76.1768817902,
    altitude: 10,
    schengen: false
  },
  {
    id: 584,
    name: 'Staniel Cay Airport',
    city: 'Staniel Cay',
    country: 'Bahamas',
    iata: 'TYM',
    icao: 'MYES',
    latitude: 24.1690998077393,
    longitude: -76.4391021728516,
    altitude: 5,
    schengen: false
  },
  {
    id: 585,
    name: 'Grand Bahama International Airport',
    city: 'Freeport',
    country: 'Bahamas',
    iata: 'FPO',
    icao: 'MYGF',
    latitude: 26.5587005615,
    longitude: -78.695602417,
    altitude: 7,
    schengen: false
  },
  {
    id: 586,
    name: 'Inagua Airport',
    city: 'Matthew Town',
    country: 'Bahamas',
    iata: 'IGA',
    icao: 'MYIG',
    latitude: 20.9750003814697,
    longitude: -73.6669006347656,
    altitude: 8,
    schengen: false
  },
  {
    id: 587,
    name: "Deadman's Cay Airport",
    city: "Dead Man's Cay",
    country: 'Bahamas',
    iata: 'LGI',
    icao: 'MYLD',
    latitude: 23.1790008545,
    longitude: -75.0935974121,
    altitude: 9,
    schengen: false
  },
  {
    id: 588,
    name: 'Hard Bargain Airport',
    city: '',
    country: 'Bahamas',
    iata: '',
    icao: 'MYLR',
    latitude: 23.011194,
    longitude: -74.905889,
    altitude: 0,
    schengen: false
  },
  {
    id: 589,
    name: 'Stella Maris Airport',
    city: 'Stella Maris',
    country: 'Bahamas',
    iata: 'SML',
    icao: 'MYLS',
    latitude: 23.582317,
    longitude: -75.268621,
    altitude: 10,
    schengen: false
  },
  {
    id: 590,
    name: 'Mayaguana Airport',
    city: 'Mayaguana',
    country: 'Bahamas',
    iata: 'MYG',
    icao: 'MYMM',
    latitude: 22.379499,
    longitude: -73.013494,
    altitude: 11,
    schengen: false
  },
  {
    id: 591,
    name: 'Lynden Pindling International Airport',
    city: 'Nassau',
    country: 'Bahamas',
    iata: 'NAS',
    icao: 'MYNN',
    latitude: 25.0389995575,
    longitude: -77.4662017822,
    altitude: 16,
    schengen: false
  },
  {
    id: 592,
    name: 'Nassau Paradise Island Airport',
    city: 'Nassau',
    country: 'Bahamas',
    iata: 'PID',
    icao: 'MYPI',
    latitude: 25.0830001831055,
    longitude: -77.3000030517578,
    altitude: 0,
    schengen: false
  },
  {
    id: 593,
    name: 'Duncan Town Airport',
    city: 'Duncan Town',
    country: 'Bahamas',
    iata: 'DCT',
    icao: 'MYRD',
    latitude: 22.1818008422852,
    longitude: -75.7294998168945,
    altitude: 6,
    schengen: false
  },
  {
    id: 594,
    name: 'Rum Cay Airport',
    city: 'Port Nelson',
    country: 'Bahamas',
    iata: 'RCY',
    icao: 'MYRP',
    latitude: 23.6844005584717,
    longitude: -74.8361968994141,
    altitude: 15,
    schengen: false
  },
  {
    id: 595,
    name: 'San Salvador Airport',
    city: 'Cockburn Town',
    country: 'Bahamas',
    iata: 'ZSA',
    icao: 'MYSM',
    latitude: 24.0632991790771,
    longitude: -74.5240020751953,
    altitude: 24,
    schengen: false
  },
  {
    id: 596,
    name: 'Bahrain International Airport',
    city: 'Bahrain',
    country: 'Bahrain',
    iata: 'BAH',
    icao: 'OBBI',
    latitude: 26.2707996368408,
    longitude: 50.6335983276367,
    altitude: 6,
    schengen: false
  },
  {
    id: 597,
    name: 'Sheik Isa Air Base',
    city: 'Bahrain',
    country: 'Bahrain',
    iata: '',
    icao: 'OBBS',
    latitude: 25.918399810791,
    longitude: 50.5905990600586,
    altitude: 136,
    schengen: false
  },
  {
    id: 598,
    name: 'Barisal Airport',
    city: 'Barisal',
    country: 'Bangladesh',
    iata: 'BZL',
    icao: 'VGBR',
    latitude: 22.8010005950928,
    longitude: 90.3012008666992,
    altitude: 23,
    schengen: false
  },
  {
    id: 599,
    name: "Cox's Bazar Airport",
    city: "Cox's Bazar",
    country: 'Bangladesh',
    iata: 'CXB',
    icao: 'VGCB',
    latitude: 21.4521999359131,
    longitude: 91.9638977050781,
    altitude: 12,
    schengen: false
  },
  {
    id: 600,
    name: 'Shah Amanat International Airport',
    city: 'Chittagong',
    country: 'Bangladesh',
    iata: 'CGP',
    icao: 'VGEG',
    latitude: 22.2495994567871,
    longitude: 91.8133010864258,
    altitude: 12,
    schengen: false
  },
  {
    id: 601,
    name: 'Ishurdi Airport',
    city: 'Ishurdi',
    country: 'Bangladesh',
    iata: 'IRD',
    icao: 'VGIS',
    latitude: 24.1525001525879,
    longitude: 89.0494003295899,
    altitude: 45,
    schengen: false
  },
  {
    id: 602,
    name: 'Jessore Airport',
    city: 'Jessore',
    country: 'Bangladesh',
    iata: 'JSR',
    icao: 'VGJR',
    latitude: 23.1837997436523,
    longitude: 89.1607971191406,
    altitude: 20,
    schengen: false
  },
  {
    id: 603,
    name: 'Shah Mokhdum Airport',
    city: 'Rajshahi',
    country: 'Bangladesh',
    iata: 'RJH',
    icao: 'VGRJ',
    latitude: 24.4372005462646,
    longitude: 88.6165008544922,
    altitude: 64,
    schengen: false
  },
  {
    id: 604,
    name: 'Saidpur Airport',
    city: 'Saidpur',
    country: 'Bangladesh',
    iata: 'SPD',
    icao: 'VGSD',
    latitude: 25.7591991424561,
    longitude: 88.9088973999023,
    altitude: 125,
    schengen: false
  },
  {
    id: 605,
    name: 'Osmany International Airport',
    city: 'Sylhet Osmani',
    country: 'Bangladesh',
    iata: 'ZYL',
    icao: 'VGSY',
    latitude: 24.9631996154785,
    longitude: 91.8667984008789,
    altitude: 50,
    schengen: false
  },
  {
    id: 606,
    name: 'Tejgaon Airport',
    city: 'Dhaka',
    country: 'Bangladesh',
    iata: '',
    icao: 'VGTJ',
    latitude: 23.7787990570068,
    longitude: 90.382698059082,
    altitude: 24,
    schengen: false
  },
  {
    id: 607,
    name: 'Dhaka / Hazrat Shahjalal International Airport',
    city: 'Dhaka',
    country: 'Bangladesh',
    iata: 'DAC',
    icao: 'VGZR',
    latitude: 23.843347,
    longitude: 90.397783,
    altitude: 30,
    schengen: false
  },
  {
    id: 608,
    name: 'Sir Grantley Adams International Airport',
    city: 'Bridgetown',
    country: 'Barbados',
    iata: 'BGI',
    icao: 'TBPB',
    latitude: 13.0746002197,
    longitude: -59.4925003052,
    altitude: 169,
    schengen: false
  },
  {
    id: 609,
    name: 'Brest Airport',
    city: 'Brest',
    country: 'Belarus',
    iata: 'BQT',
    icao: 'UMBB',
    latitude: 52.108299,
    longitude: 23.8981,
    altitude: 468,
    schengen: false
  },
  {
    id: 610,
    name: 'Gomel Airport',
    city: 'Gomel',
    country: 'Belarus',
    iata: 'GME',
    icao: 'UMGG',
    latitude: 52.5270004272461,
    longitude: 31.0167007446289,
    altitude: 472,
    schengen: false
  },
  {
    id: 611,
    name: 'Vitebsk Vostochny Airport',
    city: 'Vitebsk',
    country: 'Belarus',
    iata: 'VTB',
    icao: 'UMII',
    latitude: 55.126499176025,
    longitude: 30.349599838257,
    altitude: 682,
    schengen: false
  },
  {
    id: 612,
    name: 'Baranavichi Air Base',
    city: 'Baranavichi',
    country: 'Belarus',
    iata: '',
    icao: 'UMMA',
    latitude: 53.0999984741211,
    longitude: 26.0499992370605,
    altitude: 0,
    schengen: false
  },
  {
    id: 613,
    name: 'Borovaya Airfield',
    city: 'Minsk',
    country: 'Belarus',
    iata: '',
    icao: 'UMMB',
    latitude: 53.9604611,
    longitude: 27.6505961,
    altitude: 0,
    schengen: false
  },
  {
    id: 614,
    name: 'Hrodna Airport',
    city: 'Hrodna',
    country: 'Belarus',
    iata: 'GNA',
    icao: 'UMMG',
    latitude: 53.6020011901856,
    longitude: 24.0538005828857,
    altitude: 443,
    schengen: false
  },
  {
    id: 615,
    name: 'Minsk 1 Airport',
    city: 'Minsk',
    country: 'Belarus',
    iata: 'MHP',
    icao: 'UMMM',
    latitude: 53.8644981384277,
    longitude: 27.5396995544434,
    altitude: 748,
    schengen: false
  },
  {
    id: 616,
    name: 'Minsk National Airport',
    city: 'Minsk',
    country: 'Belarus',
    iata: 'MSQ',
    icao: 'UMMS',
    latitude: 53.882499694824,
    longitude: 28.030700683594,
    altitude: 670,
    schengen: false
  },
  {
    id: 617,
    name: 'Babruisk Air Base',
    city: 'Babruisk',
    country: 'Belarus',
    iata: '',
    icao: 'UMNB',
    latitude: 53.105,
    longitude: 29.205,
    altitude: 502,
    schengen: false
  },
  {
    id: 618,
    name: 'Mogilev Airport',
    city: 'Mogilev',
    country: 'Belarus',
    iata: 'MVQ',
    icao: 'UMOO',
    latitude: 53.9548988342285,
    longitude: 30.095100402832,
    altitude: 637,
    schengen: false
  },
  {
    id: 619,
    name: 'Antwerp International Airport (Deurne)',
    city: 'Antwerp',
    country: 'Belgium',
    iata: 'ANR',
    icao: 'EBAW',
    latitude: 51.1893997192,
    longitude: 4.46027994156,
    altitude: 39,
    schengen: true
  },
  {
    id: 620,
    name: 'Beauvechain Air Base',
    city: 'Beauvechain',
    country: 'Belgium',
    iata: '',
    icao: 'EBBE',
    latitude: 50.7585983276367,
    longitude: 4.76833009719849,
    altitude: 370,
    schengen: true
  },
  {
    id: 621,
    name: 'Kleine Brogel Air Base',
    city: 'Kleine Brogel',
    country: 'Belgium',
    iata: '',
    icao: 'EBBL',
    latitude: 51.168301,
    longitude: 5.47,
    altitude: 200,
    schengen: true
  },
  {
    id: 622,
    name: 'Brussels Airport',
    city: 'Brussels',
    country: 'Belgium',
    iata: 'BRU',
    icao: 'EBBR',
    latitude: 50.9014015198,
    longitude: 4.48443984985,
    altitude: 184,
    schengen: true
  },
  {
    id: 623,
    name: 'Jehonville Air Base',
    city: 'Bertrix',
    country: 'Belgium',
    iata: '',
    icao: 'EBBX',
    latitude: 49.8917007446289,
    longitude: 5.22388982772827,
    altitude: 1514,
    schengen: true
  },
  {
    id: 624,
    name: 'Brussels South Charleroi Airport',
    city: 'Charleroi',
    country: 'Belgium',
    iata: 'CRL',
    icao: 'EBCI',
    latitude: 50.459202,
    longitude: 4.45382,
    altitude: 614,
    schengen: true
  },
  {
    id: 625,
    name: 'Chièvres Air Base',
    city: 'Chievres',
    country: 'Belgium',
    iata: '',
    icao: 'EBCV',
    latitude: 50.5758018493652,
    longitude: 3.83100008964539,
    altitude: 194,
    schengen: true
  },
  {
    id: 626,
    name: 'Koksijde Air Base',
    city: 'Koksijde',
    country: 'Belgium',
    iata: '',
    icao: 'EBFN',
    latitude: 51.0903015136719,
    longitude: 2.65278005599976,
    altitude: 20,
    schengen: true
  },
  {
    id: 627,
    name: 'Florennes Air Base',
    city: 'Florennes',
    country: 'Belgium',
    iata: '',
    icao: 'EBFS',
    latitude: 50.2433013916,
    longitude: 4.64583015442,
    altitude: 935,
    schengen: true
  },
  {
    id: 628,
    name: 'Wevelgem Airport',
    city: 'Kortrijk-vevelgem',
    country: 'Belgium',
    iata: 'KJK',
    icao: 'EBKT',
    latitude: 50.817199707,
    longitude: 3.20472002029,
    altitude: 64,
    schengen: true
  },
  {
    id: 629,
    name: 'Westkapelle heliport',
    city: 'Knokke',
    country: 'Belgium',
    iata: '',
    icao: 'EBKW',
    latitude: 51.32222366333,
    longitude: 3.2930560112,
    altitude: 10,
    schengen: true
  },
  {
    id: 630,
    name: 'Leopoldsburg Airfield',
    city: 'Leopoldsburg',
    country: 'Belgium',
    iata: '',
    icao: 'EBLE',
    latitude: 51.12,
    longitude: 5.3072222,
    altitude: 207,
    schengen: true
  },
  {
    id: 631,
    name: 'Liège Airport',
    city: 'Liege',
    country: 'Belgium',
    iata: 'LGG',
    icao: 'EBLG',
    latitude: 50.6374015808106,
    longitude: 5.44322013854981,
    altitude: 659,
    schengen: true
  },
  {
    id: 632,
    name: 'Suarlée Airport',
    city: 'Namur',
    country: 'Belgium',
    iata: '',
    icao: 'EBNM',
    latitude: 50.487999,
    longitude: 4.76892,
    altitude: 594,
    schengen: true
  },
  {
    id: 633,
    name: 'Ostend-Bruges International Airport',
    city: 'Ostend',
    country: 'Belgium',
    iata: 'OST',
    icao: 'EBOS',
    latitude: 51.1988983154,
    longitude: 2.8622200489,
    altitude: 13,
    schengen: true
  },
  {
    id: 634,
    name: 'Zutendaal Air Base',
    city: 'Zutendaal',
    country: 'Belgium',
    iata: '',
    icao: 'EBSL',
    latitude: 50.9474983215332,
    longitude: 5.59055995941162,
    altitude: 312,
    schengen: true
  },
  {
    id: 635,
    name: 'Spa (la Sauvenière) Airport',
    city: 'Spa',
    country: 'Belgium',
    iata: '',
    icao: 'EBSP',
    latitude: 50.4824981689453,
    longitude: 5.91029977798462,
    altitude: 1581,
    schengen: true
  },
  {
    id: 636,
    name: 'Limburg Regional Airport',
    city: 'Sint-truiden',
    country: 'Belgium',
    iata: '',
    icao: 'EBST',
    latitude: 50.7919006348,
    longitude: 5.20167016983,
    altitude: 246,
    schengen: true
  },
  {
    id: 637,
    name: 'Saint Hubert Air Base',
    city: 'St.-hubert',
    country: 'Belgium',
    iata: '',
    icao: 'EBSU',
    latitude: 50.0344009399414,
    longitude: 5.44081020355225,
    altitude: 1930,
    schengen: true
  },
  {
    id: 638,
    name: 'Goetsenhoven Air Base',
    city: 'GOETSENHOVEN',
    country: 'Belgium',
    iata: '',
    icao: 'EBTN',
    latitude: 50.7817001342773,
    longitude: 4.95777988433838,
    altitude: 246,
    schengen: true
  },
  {
    id: 639,
    name: 'Ursel Air Base',
    city: 'Ursel',
    country: 'Belgium',
    iata: '',
    icao: 'EBUL',
    latitude: 51.1441993713379,
    longitude: 3.47555994987488,
    altitude: 95,
    schengen: true
  },
  {
    id: 640,
    name: 'Weelde Air Base',
    city: 'Weelde',
    country: 'Belgium',
    iata: '',
    icao: 'EBWE',
    latitude: 51.3947982788086,
    longitude: 4.96018981933594,
    altitude: 105,
    schengen: true
  },
  {
    id: 641,
    name: 'Kiewit Airfield Hasselt',
    city: 'Hasselt',
    country: 'Belgium',
    iata: '',
    icao: 'EBZH',
    latitude: 50.970001,
    longitude: 5.37507,
    altitude: 141,
    schengen: true
  },
  {
    id: 642,
    name: 'Zoersel (Oostmalle) Airfield',
    city: 'Zoersel',
    country: 'Belgium',
    iata: 'OBL',
    icao: 'EBZR',
    latitude: 51.264702,
    longitude: 4.75333,
    altitude: 53,
    schengen: true
  },
  {
    id: 643,
    name: 'Philip S. W. Goldson International Airport',
    city: 'Belize City',
    country: 'Belize',
    iata: 'BZE',
    icao: 'MZBZ',
    latitude: 17.5391006469727,
    longitude: -88.3081970214844,
    altitude: 15,
    schengen: false
  },
  {
    id: 644,
    name: 'Birchwood Airport',
    city: 'Belmopan',
    country: 'Belize',
    iata: '',
    icao: 'PABV',
    latitude: 61.41650009,
    longitude: -149.5070038,
    altitude: 83,
    schengen: false
  },
  {
    id: 645,
    name: 'Cadjehoun Airport',
    city: 'Cotonou',
    country: 'Benin',
    iata: 'COO',
    icao: 'DBBB',
    latitude: 6.3572301864624,
    longitude: 2.38435006141663,
    altitude: 19,
    schengen: false
  },
  {
    id: 646,
    name: 'Kandi Airport',
    city: 'Kandi',
    country: 'Benin',
    iata: 'KDC',
    icao: 'DBBK',
    latitude: 11.14479,
    longitude: 2.940381,
    altitude: 951,
    schengen: false
  },
  {
    id: 647,
    name: 'Parakou Airport',
    city: 'Parakou',
    country: 'Benin',
    iata: 'PKO',
    icao: 'DBBP',
    latitude: 9.35768985748291,
    longitude: 2.60967993736267,
    altitude: 1266,
    schengen: false
  },
  {
    id: 648,
    name: 'L.F. Wade International International Airport',
    city: 'Bermuda',
    country: 'Bermuda',
    iata: 'BDA',
    icao: 'TXKF',
    latitude: 32.3639984130859,
    longitude: -64.6787033081055,
    altitude: 12,
    schengen: false
  },
  {
    id: 649,
    name: 'Bathpalathang Airport',
    city: 'Jakar',
    country: 'Bhutan',
    iata: 'BUT',
    icao: 'VQBT',
    latitude: 27.5622,
    longitude: 90.7471,
    altitude: 8485,
    schengen: false
  },
  {
    id: 650,
    name: 'Gelephu Airport',
    city: 'Gelephu',
    country: 'Bhutan',
    iata: 'GLU',
    icao: 'VQGP',
    latitude: 26.88456,
    longitude: 90.46412,
    altitude: 980,
    schengen: false
  },
  {
    id: 651,
    name: 'Paro Airport',
    city: 'Thimphu',
    country: 'Bhutan',
    iata: 'PBH',
    icao: 'VQPR',
    latitude: 27.4032001495,
    longitude: 89.4245986938,
    altitude: 7332,
    schengen: false
  },
  {
    id: 652,
    name: 'Bermejo Airport',
    city: 'Bermejo',
    country: 'Bolivia',
    iata: 'BJO',
    icao: 'SLBJ',
    latitude: -22.7733001709,
    longitude: -64.3128967285,
    altitude: 1249,
    schengen: false
  },
  {
    id: 653,
    name: 'Jorge Wilsterman International Airport',
    city: 'Cochabamba',
    country: 'Bolivia',
    iata: 'CBB',
    icao: 'SLCB',
    latitude: -17.4211006164551,
    longitude: -66.1771011352539,
    altitude: 8360,
    schengen: false
  },
  {
    id: 654,
    name: 'Copacabana Airport',
    city: 'Copacabana',
    country: 'Bolivia',
    iata: '',
    icao: 'SLCC',
    latitude: -16.1910991668701,
    longitude: -69.0955963134766,
    altitude: 12591,
    schengen: false
  },
  {
    id: 655,
    name: 'Chimore Airport',
    city: 'Chapacura',
    country: 'Bolivia',
    iata: 'CCA',
    icao: 'SLCH',
    latitude: -16.976834,
    longitude: -65.145568,
    altitude: 875,
    schengen: false
  },
  {
    id: 656,
    name: 'Capitán Aníbal Arab Airport',
    city: 'Cobija',
    country: 'Bolivia',
    iata: 'CIJ',
    icao: 'SLCO',
    latitude: -11.0403995514,
    longitude: -68.7829971313,
    altitude: 889,
    schengen: false
  },
  {
    id: 657,
    name: 'El Trompillo Airport',
    city: 'Santa Cruz',
    country: 'Bolivia',
    iata: 'SRZ',
    icao: 'SLET',
    latitude: -17.8115997314,
    longitude: -63.1715011597,
    altitude: 1371,
    schengen: false
  },
  {
    id: 658,
    name: 'Capitán de Av. Emilio Beltrán Airport',
    city: 'Guayaramerín',
    country: 'Bolivia',
    iata: 'GYA',
    icao: 'SLGY',
    latitude: -10.820599556,
    longitude: -65.3455963135,
    altitude: 557,
    schengen: false
  },
  {
    id: 659,
    name: 'El Alto International Airport',
    city: 'La Paz',
    country: 'Bolivia',
    iata: 'LPB',
    icao: 'SLLP',
    latitude: -16.5132999420166,
    longitude: -68.1922988891602,
    altitude: 13355,
    schengen: false
  },
  {
    id: 660,
    name: 'Juan Mendoza Airport',
    city: 'Oruro',
    country: 'Bolivia',
    iata: 'ORU',
    icao: 'SLOR',
    latitude: -17.962600708,
    longitude: -67.0762023926,
    altitude: 12152,
    schengen: false
  },
  {
    id: 661,
    name: 'Capitan Nicolas Rojas Airport',
    city: 'Potosi',
    country: 'Bolivia',
    iata: 'POI',
    icao: 'SLPO',
    latitude: -19.5431003571,
    longitude: -65.7237014771,
    altitude: 12913,
    schengen: false
  },
  {
    id: 662,
    name: 'Puerto Rico Airport',
    city: 'Puerto Rico/Manuripi',
    country: 'Bolivia',
    iata: 'PUR',
    icao: 'SLPR',
    latitude: -11.107663,
    longitude: -67.551155,
    altitude: 597,
    schengen: false
  },
  {
    id: 663,
    name: 'Capitán Av. Salvador Ogaya G. airport',
    city: 'Puerto Suarez',
    country: 'Bolivia',
    iata: 'PSZ',
    icao: 'SLPS',
    latitude: -18.975301,
    longitude: -57.820599,
    altitude: 440,
    schengen: false
  },
  {
    id: 664,
    name: 'Capitán Av. Selin Zeitun Lopez Airport',
    city: 'Riberalta',
    country: 'Bolivia',
    iata: 'RIB',
    icao: 'SLRI',
    latitude: -11,
    longitude: -66,
    altitude: 462,
    schengen: false
  },
  {
    id: 665,
    name: 'Reyes Airport',
    city: 'Reyes',
    country: 'Bolivia',
    iata: 'REY',
    icao: 'SLRY',
    latitude: -14.3044,
    longitude: -67.353401,
    altitude: 935,
    schengen: false
  },
  {
    id: 666,
    name: 'Santa Ana Del Yacuma Airport',
    city: 'Santa Ana',
    country: 'Bolivia',
    iata: 'SBL',
    icao: 'SLSA',
    latitude: -13.7622003555,
    longitude: -65.4352035522,
    altitude: 472,
    schengen: false
  },
  {
    id: 667,
    name: 'Capitán Av. German Quiroga G. Airport',
    city: 'San Borja',
    country: 'Bolivia',
    iata: 'SRJ',
    icao: 'SLSB',
    latitude: -14.8591995239258,
    longitude: -66.7375030517578,
    altitude: 633,
    schengen: false
  },
  {
    id: 668,
    name: 'Juana Azurduy De Padilla Airport',
    city: 'Sucre',
    country: 'Bolivia',
    iata: 'SRE',
    icao: 'SLSU',
    latitude: -19.0070991516113,
    longitude: -65.2886962890625,
    altitude: 9540,
    schengen: false
  },
  {
    id: 669,
    name: 'Capitan Oriel Lea Plaza Airport',
    city: 'Tarija',
    country: 'Bolivia',
    iata: 'TJA',
    icao: 'SLTJ',
    latitude: -21.5557003021,
    longitude: -64.7013015747,
    altitude: 6079,
    schengen: false
  },
  {
    id: 670,
    name: 'Teniente Av. Jorge Henrich Arauz Airport',
    city: 'Trinidad',
    country: 'Bolivia',
    iata: 'TDD',
    icao: 'SLTR',
    latitude: -14.8186998367,
    longitude: -64.9179992676,
    altitude: 509,
    schengen: false
  },
  {
    id: 671,
    name: 'Uyuni Airport',
    city: 'Uyuni',
    country: 'Bolivia',
    iata: 'UYU',
    icao: 'SLUY',
    latitude: -20.4463005066,
    longitude: -66.8483963013,
    altitude: 11136,
    schengen: false
  },
  {
    id: 672,
    name: 'Teniente Coronel Rafael Pabón Airport',
    city: 'Villa Montes',
    country: 'Bolivia',
    iata: 'VLM',
    icao: 'SLVM',
    latitude: -21.2551994324,
    longitude: -63.4056015015,
    altitude: 1305,
    schengen: false
  },
  {
    id: 673,
    name: 'Viru Viru International Airport',
    city: 'Santa Cruz',
    country: 'Bolivia',
    iata: 'VVI',
    icao: 'SLVR',
    latitude: -17.6448001861572,
    longitude: -63.1353988647461,
    altitude: 1224,
    schengen: false
  },
  {
    id: 674,
    name: 'Yacuiba Airport',
    city: 'Yacuiba',
    country: 'Bolivia',
    iata: 'BYC',
    icao: 'SLYA',
    latitude: -21.9608993530273,
    longitude: -63.6516990661621,
    altitude: 2112,
    schengen: false
  },
  {
    id: 675,
    name: 'Banja Luka International Airport',
    city: 'Banja Luka',
    country: 'Bosnia and Herzegovina',
    iata: 'BNX',
    icao: 'LQBK',
    latitude: 44.9413986206055,
    longitude: 17.2975006103516,
    altitude: 400,
    schengen: false
  },
  {
    id: 676,
    name: 'Mostar International Airport',
    city: 'Mostar',
    country: 'Bosnia and Herzegovina',
    iata: 'OMO',
    icao: 'LQMO',
    latitude: 43.282901763916,
    longitude: 17.8458995819092,
    altitude: 156,
    schengen: false
  },
  {
    id: 677,
    name: 'Sarajevo International Airport',
    city: 'Sarajevo',
    country: 'Bosnia and Herzegovina',
    iata: 'SJJ',
    icao: 'LQSA',
    latitude: 43.8246002197266,
    longitude: 18.3314990997314,
    altitude: 1708,
    schengen: false
  },
  {
    id: 678,
    name: 'Tuzla International Airport',
    city: 'Dubrave Gornje',
    country: 'Bosnia and Herzegovina',
    iata: 'TZL',
    icao: 'LQTZ',
    latitude: 44.4586982727051,
    longitude: 18.7248001098633,
    altitude: 784,
    schengen: false
  },
  {
    id: 679,
    name: 'Camp Okavango Airport',
    city: 'Okavango Delta',
    country: 'Botswana',
    iata: '',
    icao: 'FBCO',
    latitude: -19.131115,
    longitude: 23.102505,
    altitude: 3158,
    schengen: false
  },
  {
    id: 680,
    name: 'Delta Camp Airport',
    city: 'Delta Camp',
    country: 'Botswana',
    iata: '',
    icao: 'FBDT',
    latitude: -19.5323,
    longitude: 23.0925,
    altitude: 3140,
    schengen: false
  },
  {
    id: 681,
    name: 'Francistown Airport',
    city: 'Francistown',
    country: 'Botswana',
    iata: 'FRW',
    icao: 'FBFT',
    latitude: -21.1595993041992,
    longitude: 27.4745006561279,
    altitude: 3283,
    schengen: false
  },
  {
    id: 682,
    name: 'Ghanzi Airport',
    city: 'Ghanzi',
    country: 'Botswana',
    iata: 'GNZ',
    icao: 'FBGZ',
    latitude: -21.6924991607666,
    longitude: 21.6581001281738,
    altitude: 3730,
    schengen: false
  },
  {
    id: 683,
    name: 'Jwaneng Airport',
    city: 'Jwaneng',
    country: 'Botswana',
    iata: 'JWA',
    icao: 'FBJW',
    latitude: -24.6023006439209,
    longitude: 24.6909999847412,
    altitude: 3900,
    schengen: false
  },
  {
    id: 684,
    name: 'Kasane Airport',
    city: 'Kasane',
    country: 'Botswana',
    iata: 'BBK',
    icao: 'FBKE',
    latitude: -17.8328990936279,
    longitude: 25.1623992919922,
    altitude: 3289,
    schengen: false
  },
  {
    id: 685,
    name: 'Khwai River Lodge Airport',
    city: 'Khwai River',
    country: 'Botswana',
    iata: 'KHW',
    icao: 'FBKR',
    latitude: -19.1499996185303,
    longitude: 23.7830009460449,
    altitude: 3000,
    schengen: false
  },
  {
    id: 686,
    name: 'Maun Airport',
    city: 'Maun',
    country: 'Botswana',
    iata: 'MUB',
    icao: 'FBMN',
    latitude: -19.9726009368896,
    longitude: 23.4311008453369,
    altitude: 3093,
    schengen: false
  },
  {
    id: 687,
    name: 'Orapa Airport',
    city: 'Orapa',
    country: 'Botswana',
    iata: 'ORP',
    icao: 'FBOR',
    latitude: -21.266701,
    longitude: 25.3167,
    altitude: 3100,
    schengen: false
  },
  {
    id: 688,
    name: 'Sir Seretse Khama International Airport',
    city: 'Gaberone',
    country: 'Botswana',
    iata: 'GBE',
    icao: 'FBSK',
    latitude: -24.5552005767822,
    longitude: 25.9181995391846,
    altitude: 3299,
    schengen: false
  },
  {
    id: 689,
    name: 'Sua Pan Airport',
    city: 'Sowa',
    country: 'Botswana',
    iata: 'SXN',
    icao: 'FBSN',
    latitude: -20.5534,
    longitude: 26.115801,
    altitude: 2985,
    schengen: false
  },
  {
    id: 690,
    name: 'Selebi Phikwe Airport',
    city: 'Selebi-phikwe',
    country: 'Botswana',
    iata: 'PKW',
    icao: 'FBSP',
    latitude: -22.0583,
    longitude: 27.8288,
    altitude: 2925,
    schengen: false
  },
  {
    id: 691,
    name: 'Shakawe Airport',
    city: 'Shakawe',
    country: 'Botswana',
    iata: 'SWX',
    icao: 'FBSW',
    latitude: -18.3738994598389,
    longitude: 21.8325996398926,
    altitude: 3379,
    schengen: false
  },
  {
    id: 692,
    name: 'Limpopo Valley Airport',
    city: 'Tuli Lodge',
    country: 'Botswana',
    iata: 'TLD',
    icao: 'FBTL',
    latitude: -22.1891994476,
    longitude: 29.1268997192,
    altitude: 1772,
    schengen: false
  },
  {
    id: 693,
    name: 'Conceição do Araguaia Airport',
    city: 'Conceicao Do Araguaia',
    country: 'Brazil',
    iata: 'CDJ',
    icao: 'SBAA',
    latitude: -8.34834957122803,
    longitude: -49.3014984130859,
    altitude: 653,
    schengen: false
  },
  {
    id: 694,
    name: 'Campo Délio Jardim de Mattos Airport',
    city: 'Rio De Janeiro',
    country: 'Brazil',
    iata: '',
    icao: 'SBAF',
    latitude: -22.875099,
    longitude: -43.384701,
    altitude: 110,
    schengen: false
  },
  {
    id: 695,
    name: 'Amapá Airport',
    city: 'Amapa',
    country: 'Brazil',
    iata: '',
    icao: 'SBAM',
    latitude: 2.07751,
    longitude: -50.8582,
    altitude: 45,
    schengen: false
  },
  {
    id: 696,
    name: 'Base Aérea Airport',
    city: 'Anapolis',
    country: 'Brazil',
    iata: '',
    icao: 'SBAN',
    latitude: -16.2292,
    longitude: -48.964298,
    altitude: 3731,
    schengen: false
  },
  {
    id: 697,
    name: 'Araraquara Airport',
    city: 'Araracuara',
    country: 'Brazil',
    iata: 'AQA',
    icao: 'SBAQ',
    latitude: -21.8120002747,
    longitude: -48.1329994202,
    altitude: 2334,
    schengen: false
  },
  {
    id: 698,
    name: 'Santa Maria Airport',
    city: 'Aracaju',
    country: 'Brazil',
    iata: 'AJU',
    icao: 'SBAR',
    latitude: -10.984000206,
    longitude: -37.0703010559,
    altitude: 23,
    schengen: false
  },
  {
    id: 699,
    name: 'Piloto Osvaldo Marques Dias Airport',
    city: 'Alta Floresta',
    country: 'Brazil',
    iata: 'AFL',
    icao: 'SBAT',
    latitude: -9.8663892746,
    longitude: -56.1049995422,
    altitude: 948,
    schengen: false
  },
  {
    id: 700,
    name: 'Araçatuba Airport',
    city: 'Aracatuba',
    country: 'Brazil',
    iata: 'ARU',
    icao: 'SBAU',
    latitude: -21.1413002014,
    longitude: -50.4247016907,
    altitude: 1361,
    schengen: false
  },
  {
    id: 701,
    name: 'Romeu Zema Airport',
    city: 'Araxa',
    country: 'Brazil',
    iata: 'AAX',
    icao: 'SBAX',
    latitude: -19.563199996948,
    longitude: -46.960399627686,
    altitude: 3276,
    schengen: false
  },
  {
    id: 702,
    name: 'Val de Cans/Júlio Cezar Ribeiro International Airport',
    city: 'Belem',
    country: 'Brazil',
    iata: 'BEL',
    icao: 'SBBE',
    latitude: -1.37925004959,
    longitude: -48.4762992859,
    altitude: 54,
    schengen: false
  },
  {
    id: 703,
    name: 'Comandante Gustavo Kraemer Airport',
    city: 'Bage',
    country: 'Brazil',
    iata: 'BGX',
    icao: 'SBBG',
    latitude: -31.39049911499,
    longitude: -54.112201690674,
    altitude: 600,
    schengen: false
  },
  {
    id: 704,
    name: 'Pampulha - Carlos Drummond de Andrade Airport',
    city: 'Belo Horizonte',
    country: 'Brazil',
    iata: 'PLU',
    icao: 'SBBH',
    latitude: -19.8512001037598,
    longitude: -43.9505996704102,
    altitude: 2589,
    schengen: false
  },
  {
    id: 705,
    name: 'Bacacheri Airport',
    city: 'Curitiba',
    country: 'Brazil',
    iata: 'BFH',
    icao: 'SBBI',
    latitude: -25.4050998688,
    longitude: -49.2319984436,
    altitude: 3057,
    schengen: false
  },
  {
    id: 706,
    name: 'Estadual Arthur Siqueira Airport',
    city: 'Braganca Paulista',
    country: 'Brazil',
    iata: 'BJP',
    icao: 'SBBP',
    latitude: -22.979162,
    longitude: -46.537508,
    altitude: 2887,
    schengen: false
  },
  {
    id: 707,
    name: 'Major Brigadeiro Doorgal Borges Airport',
    city: 'Barbacena',
    country: 'Brazil',
    iata: '',
    icao: 'SBBQ',
    latitude: -21.2672,
    longitude: -43.761101,
    altitude: 3657,
    schengen: false
  },
  {
    id: 708,
    name: 'Presidente Juscelino Kubistschek International Airport',
    city: 'Brasilia',
    country: 'Brazil',
    iata: 'BSB',
    icao: 'SBBR',
    latitude: -15.8691673278809,
    longitude: -47.9208335876465,
    altitude: 3497,
    schengen: false
  },
  {
    id: 709,
    name: 'Chafei Amsei Airport',
    city: 'Barretos',
    country: 'Brazil',
    iata: 'BAT',
    icao: 'SBBT',
    latitude: -20.584499359131,
    longitude: -48.594100952148,
    altitude: 1898,
    schengen: false
  },
  {
    id: 710,
    name: 'Bauru Airport',
    city: 'Bauru',
    country: 'Brazil',
    iata: 'BAU',
    icao: 'SBBU',
    latitude: -22.3449993134,
    longitude: -49.0537986755,
    altitude: 2025,
    schengen: false
  },
  {
    id: 711,
    name: 'Atlas Brasil Cantanhede Airport',
    city: 'Boa Vista',
    country: 'Brazil',
    iata: 'BVB',
    icao: 'SBBV',
    latitude: 2.84138894081,
    longitude: -60.6922225952,
    altitude: 276,
    schengen: false
  },
  {
    id: 712,
    name: 'Barra do Garças Airport',
    city: 'Barra Do Garcas',
    country: 'Brazil',
    iata: 'BPG',
    icao: 'SBBW',
    latitude: -15.8613004684,
    longitude: -52.3889007568,
    altitude: 1147,
    schengen: false
  },
  {
    id: 713,
    name: 'Umberto Modiano Airport',
    city: 'Buzios',
    country: 'Brazil',
    iata: 'BZC',
    icao: 'SBBZ',
    latitude: -22.7709999084473,
    longitude: -41.9628982543945,
    altitude: 10,
    schengen: false
  },
  {
    id: 714,
    name: 'Cascavel Airport',
    city: 'Cascavel',
    country: 'Brazil',
    iata: 'CAC',
    icao: 'SBCA',
    latitude: -25.0002994537,
    longitude: -53.5008010864,
    altitude: 2473,
    schengen: false
  },
  {
    id: 715,
    name: 'Cabo Frio Airport',
    city: 'Cabo Frio',
    country: 'Brazil',
    iata: 'CFB',
    icao: 'SBCB',
    latitude: -22.9216995239,
    longitude: -42.0742988586,
    altitude: 23,
    schengen: false
  },
  {
    id: 716,
    name: 'Cachimbo Airport',
    city: 'Itaituba',
    country: 'Brazil',
    iata: '',
    icao: 'SBCC',
    latitude: -9.33393955231,
    longitude: -54.9654006958,
    altitude: 1762,
    schengen: false
  },
  {
    id: 717,
    name: 'Caçador Airport',
    city: 'Cacador',
    country: 'Brazil',
    iata: 'CFC',
    icao: 'SBCD',
    latitude: -26.78840065,
    longitude: -50.9398002625,
    altitude: 3376,
    schengen: false
  },
  {
    id: 718,
    name: 'Tancredo Neves International Airport',
    city: 'Belo Horizonte',
    country: 'Brazil',
    iata: 'CNF',
    icao: 'SBCF',
    latitude: -19.6244430541992,
    longitude: -43.9719429016113,
    altitude: 2715,
    schengen: false
  },
  {
    id: 719,
    name: 'Campo Grande Airport',
    city: 'Campo Grande',
    country: 'Brazil',
    iata: 'CGR',
    icao: 'SBCG',
    latitude: -20.4687004089,
    longitude: -54.6725006104,
    altitude: 1833,
    schengen: false
  },
  {
    id: 720,
    name: 'Serafin Enoss Bertaso Airport',
    city: 'Chapeco',
    country: 'Brazil',
    iata: 'XAP',
    icao: 'SBCH',
    latitude: -27.134199142456,
    longitude: -52.656600952148,
    altitude: 2146,
    schengen: false
  },
  {
    id: 721,
    name: 'Brig. Lysias Augusto Rodrigues Airport',
    city: 'Carolina',
    country: 'Brazil',
    iata: 'CLN',
    icao: 'SBCI',
    latitude: -7.32043981552124,
    longitude: -47.4586982727051,
    altitude: 565,
    schengen: false
  },
  {
    id: 722,
    name: 'Carajás Airport',
    city: 'Parauapebas',
    country: 'Brazil',
    iata: 'CKS',
    icao: 'SBCJ',
    latitude: -6.11527776718,
    longitude: -50.0013885498,
    altitude: 2064,
    schengen: false
  },
  {
    id: 723,
    name: 'Diomício Freitas Airport',
    city: 'Criciuma',
    country: 'Brazil',
    iata: 'CCM',
    icao: 'SBCM',
    latitude: -28.7244434357,
    longitude: -49.4213905334,
    altitude: 93,
    schengen: false
  },
  {
    id: 724,
    name: 'Nelson Ribeiro Guimarães Airport',
    city: 'Caldas Novas',
    country: 'Brazil',
    iata: 'CLV',
    icao: 'SBCN',
    latitude: -17.725299835205,
    longitude: -48.607498168945,
    altitude: 2247,
    schengen: false
  },
  {
    id: 725,
    name: 'Canoas Air Force Base',
    city: 'Porto Alegre',
    country: 'Brazil',
    iata: '',
    icao: 'SBCO',
    latitude: -29.945928,
    longitude: -51.144413,
    altitude: 26,
    schengen: false
  },
  {
    id: 726,
    name: 'Bartolomeu Lisandro Airport',
    city: 'Campos',
    country: 'Brazil',
    iata: 'CAW',
    icao: 'SBCP',
    latitude: -21.698299408,
    longitude: -41.301700592,
    altitude: 57,
    schengen: false
  },
  {
    id: 727,
    name: 'Corumbá International Airport',
    city: 'Corumba',
    country: 'Brazil',
    iata: 'CMG',
    icao: 'SBCR',
    latitude: -19.0119438171,
    longitude: -57.6713905334,
    altitude: 461,
    schengen: false
  },
  {
    id: 728,
    name: 'Afonso Pena Airport',
    city: 'Curitiba',
    country: 'Brazil',
    iata: 'CWB',
    icao: 'SBCT',
    latitude: -25.5284996033,
    longitude: -49.1758003235,
    altitude: 2988,
    schengen: false
  },
  {
    id: 729,
    name: 'Caravelas Airport',
    city: 'Caravelas',
    country: 'Brazil',
    iata: 'CRQ',
    icao: 'SBCV',
    latitude: -17.652299880981,
    longitude: -39.253101348877,
    altitude: 36,
    schengen: false
  },
  {
    id: 730,
    name: 'Hugo Cantergiani Regional Airport',
    city: 'Caxias Do Sul',
    country: 'Brazil',
    iata: 'CXJ',
    icao: 'SBCX',
    latitude: -29.1970996857,
    longitude: -51.1875,
    altitude: 2472,
    schengen: false
  },
  {
    id: 731,
    name: 'Marechal Rondon Airport',
    city: 'Cuiaba',
    country: 'Brazil',
    iata: 'CGB',
    icao: 'SBCY',
    latitude: -15.6528997421,
    longitude: -56.1166992188,
    altitude: 617,
    schengen: false
  },
  {
    id: 732,
    name: 'Cruzeiro do Sul Airport',
    city: 'Cruzeiro do Sul',
    country: 'Brazil',
    iata: 'CZS',
    icao: 'SBCZ',
    latitude: -7.59990978241,
    longitude: -72.7695007324,
    altitude: 637,
    schengen: false
  },
  {
    id: 733,
    name: 'Presidente Prudente Airport',
    city: 'President Prudente',
    country: 'Brazil',
    iata: 'PPB',
    icao: 'SBDN',
    latitude: -22.1751003265,
    longitude: -51.4245986938,
    altitude: 1477,
    schengen: false
  },
  {
    id: 734,
    name: 'Eduardo Gomes International Airport',
    city: 'Manaus',
    country: 'Brazil',
    iata: 'MAO',
    icao: 'SBEG',
    latitude: -3.03860998153687,
    longitude: -60.0497016906738,
    altitude: 264,
    schengen: false
  },
  {
    id: 735,
    name: 'Jacareacanga Airport',
    city: 'Jacare-acanga',
    country: 'Brazil',
    iata: 'JCR',
    icao: 'SBEK',
    latitude: -6.2331600189209,
    longitude: -57.7769012451172,
    altitude: 323,
    schengen: false
  },
  {
    id: 736,
    name: 'São Pedro da Aldeia Airport',
    city: 'Sao Pedro Da Aldeia',
    country: 'Brazil',
    iata: '',
    icao: 'SBES',
    latitude: -22.8129005432129,
    longitude: -42.0926017761231,
    altitude: 61,
    schengen: false
  },
  {
    id: 737,
    name: 'Cataratas International Airport',
    city: 'Foz Do Iguacu',
    country: 'Brazil',
    iata: 'IGU',
    icao: 'SBFI',
    latitude: -25.6002788543701,
    longitude: -54.4850006103516,
    altitude: 786,
    schengen: false
  },
  {
    id: 738,
    name: 'Hercílio Luz International Airport',
    city: 'Florianopolis',
    country: 'Brazil',
    iata: 'FLN',
    icao: 'SBFL',
    latitude: -27.6702785491943,
    longitude: -48.5525016784668,
    altitude: 16,
    schengen: false
  },
  {
    id: 739,
    name: 'Fernando de Noronha Airport',
    city: 'Fernando Do Noronha',
    country: 'Brazil',
    iata: 'FEN',
    icao: 'SBFN',
    latitude: -3.85493,
    longitude: -32.423302,
    altitude: 193,
    schengen: false
  },
  {
    id: 740,
    name: 'Furnas Airport',
    city: 'Alpinopolis',
    country: 'Brazil',
    iata: '',
    icao: 'SBFU',
    latitude: -20.7028007507324,
    longitude: -46.3353004455566,
    altitude: 2413,
    schengen: false
  },
  {
    id: 741,
    name: 'Pinto Martins International Airport',
    city: 'Fortaleza',
    country: 'Brazil',
    iata: 'FOR',
    icao: 'SBFZ',
    latitude: -3.77627992630005,
    longitude: -38.532600402832,
    altitude: 82,
    schengen: false
  },
  {
    id: 742,
    name: 'Rio Galeão – Tom Jobim International Airport',
    city: 'Rio De Janeiro',
    country: 'Brazil',
    iata: 'GIG',
    icao: 'SBGL',
    latitude: -22.8099994659,
    longitude: -43.2505569458,
    altitude: 28,
    schengen: false
  },
  {
    id: 743,
    name: 'Guajará-Mirim Airport',
    city: 'Guajara-mirim',
    country: 'Brazil',
    iata: 'GJM',
    icao: 'SBGM',
    latitude: -10.7863998413086,
    longitude: -65.284797668457,
    altitude: 478,
    schengen: false
  },
  {
    id: 744,
    name: 'Santa Genoveva Airport',
    city: 'Goiania',
    country: 'Brazil',
    iata: 'GYN',
    icao: 'SBGO',
    latitude: -16.6319999694824,
    longitude: -49.2206993103027,
    altitude: 2450,
    schengen: false
  },
  {
    id: 745,
    name: 'EMBRAER - Unidade Gavião Peixoto Airport',
    city: 'Macae',
    country: 'Brazil',
    iata: '',
    icao: 'SBGP',
    latitude: -21.7737007141113,
    longitude: -48.405101776123,
    altitude: 1998,
    schengen: false
  },
  {
    id: 746,
    name: 'Guarulhos - Governador André Franco Montoro International Airport',
    city: 'Sao Paulo',
    country: 'Brazil',
    iata: 'GRU',
    icao: 'SBGR',
    latitude: -23.4355564117432,
    longitude: -46.4730567932129,
    altitude: 2459,
    schengen: false
  },
  {
    id: 747,
    name: 'Tancredo Thomas de Faria Airport',
    city: 'Guarapuava',
    country: 'Brazil',
    iata: 'GPB',
    icao: 'SBGU',
    latitude: -25.3875007629,
    longitude: -51.520198822,
    altitude: 3494,
    schengen: false
  },
  {
    id: 748,
    name: 'Coronel Altino Machado de Oliveira Airport',
    city: 'Governador Valadares',
    country: 'Brazil',
    iata: 'GVR',
    icao: 'SBGV',
    latitude: -18.89520072937,
    longitude: -41.982200622559,
    altitude: 561,
    schengen: false
  },
  {
    id: 749,
    name: 'Guaratinguetá Airport',
    city: 'Guaratingueta',
    country: 'Brazil',
    iata: 'GUJ',
    icao: 'SBGW',
    latitude: -22.7915992736816,
    longitude: -45.2047996520996,
    altitude: 1761,
    schengen: false
  },
  {
    id: 750,
    name: 'Altamira Airport',
    city: 'Altamira',
    country: 'Brazil',
    iata: 'ATM',
    icao: 'SBHT',
    latitude: -3.2539100646973,
    longitude: -52.254001617432,
    altitude: 369,
    schengen: false
  },
  {
    id: 751,
    name: 'Itacoatiara Airport',
    city: 'Itaituba',
    country: 'Brazil',
    iata: 'ITA',
    icao: 'SBIC',
    latitude: -3.1272599697113,
    longitude: -58.481201171875,
    altitude: 142,
    schengen: false
  },
  {
    id: 752,
    name: 'Itaituba Airport',
    city: 'Itaituba',
    country: 'Brazil',
    iata: 'ITB',
    icao: 'SBIH',
    latitude: -4.2423400878906,
    longitude: -56.000701904297,
    altitude: 110,
    schengen: false
  },
  {
    id: 753,
    name: 'Bahia - Jorge Amado Airport',
    city: 'Ilheus',
    country: 'Brazil',
    iata: 'IOS',
    icao: 'SBIL',
    latitude: -14.815999984741,
    longitude: -39.033199310303,
    altitude: 15,
    schengen: false
  },
  {
    id: 754,
    name: 'Usiminas Airport',
    city: 'Ipatinga',
    country: 'Brazil',
    iata: 'IPN',
    icao: 'SBIP',
    latitude: -19.470699310303,
    longitude: -42.487598419189,
    altitude: 784,
    schengen: false
  },
  {
    id: 755,
    name: 'Francisco Vilela do Amaral Airport',
    city: 'Itumbiara',
    country: 'Brazil',
    iata: 'ITR',
    icao: 'SBIT',
    latitude: -18.4447002411,
    longitude: -49.2134017944,
    altitude: 1630,
    schengen: false
  },
  {
    id: 756,
    name: 'Prefeito Renato Moreira Airport',
    city: 'Imperatriz',
    country: 'Brazil',
    iata: 'IMP',
    icao: 'SBIZ',
    latitude: -5.53129,
    longitude: -47.459999,
    altitude: 432,
    schengen: false
  },
  {
    id: 757,
    name: 'Humberto Ghizzo Bortoluzzi Regional Airport',
    city: 'Jaguaruna',
    country: 'Brazil',
    iata: 'JJG',
    icao: 'SBJA',
    latitude: -28.6753,
    longitude: -49.0596,
    altitude: 120,
    schengen: false
  },
  {
    id: 758,
    name: 'Belém/Brigadeiro Protásio de Oliveira Airport',
    city: 'Belem',
    country: 'Brazil',
    iata: '',
    icao: 'SBJC',
    latitude: -1.4141600132,
    longitude: -48.4607009888,
    altitude: 52,
    schengen: false
  },
  {
    id: 759,
    name: 'Comte. Rolim Adolfo Amaro–Jundiaí State Airport',
    city: 'Jundiai',
    country: 'Brazil',
    iata: '',
    icao: 'SBJD',
    latitude: -23.180369,
    longitude: -46.944408,
    altitude: 2484,
    schengen: false
  },
  {
    id: 760,
    name: 'Francisco de Assis Airport',
    city: 'Juiz De Fora',
    country: 'Brazil',
    iata: 'JDF',
    icao: 'SBJF',
    latitude: -21.7915000915527,
    longitude: -43.3867988586426,
    altitude: 2989,
    schengen: false
  },
  {
    id: 761,
    name: 'Presidente Castro Pinto International Airport',
    city: 'Joao Pessoa',
    country: 'Brazil',
    iata: 'JPA',
    icao: 'SBJP',
    latitude: -7.14583301544,
    longitude: -34.9486122131,
    altitude: 217,
    schengen: false
  },
  {
    id: 762,
    name: 'Jacarepaguá - Roberto Marinho Airport',
    city: 'Rio de Janeiro',
    country: 'Brazil',
    iata: '',
    icao: 'SBJR',
    latitude: -22.987499,
    longitude: -43.369999,
    altitude: 10,
    schengen: false
  },
  {
    id: 763,
    name: 'Orlando Bezerra de Menezes Airport',
    city: 'Juazeiro Do Norte',
    country: 'Brazil',
    iata: 'JDO',
    icao: 'SBJU',
    latitude: -7.21895980835,
    longitude: -39.2700996399,
    altitude: 1392,
    schengen: false
  },
  {
    id: 764,
    name: 'Lauro Carneiro de Loyola Airport',
    city: 'Joinville',
    country: 'Brazil',
    iata: 'JOI',
    icao: 'SBJV',
    latitude: -26.2245006561279,
    longitude: -48.7974014282227,
    altitude: 15,
    schengen: false
  },
  {
    id: 765,
    name: 'Presidente João Suassuna Airport',
    city: 'Campina Grande',
    country: 'Brazil',
    iata: 'CPV',
    icao: 'SBKG',
    latitude: -7.26992,
    longitude: -35.8964,
    altitude: 1646,
    schengen: false
  },
  {
    id: 766,
    name: 'Viracopos International Airport',
    city: 'Campinas',
    country: 'Brazil',
    iata: 'VCP',
    icao: 'SBKP',
    latitude: -23.0074005127,
    longitude: -47.1344985962,
    altitude: 2170,
    schengen: false
  },
  {
    id: 767,
    name: 'Coronel Horácio de Mattos Airport',
    city: 'Lençóis',
    country: 'Brazil',
    iata: 'LEC',
    icao: 'SBLE',
    latitude: -12.4822998047,
    longitude: -41.2770004272,
    altitude: 1676,
    schengen: false
  },
  {
    id: 768,
    name: 'Lages Airport',
    city: 'Lajes',
    country: 'Brazil',
    iata: 'LAJ',
    icao: 'SBLJ',
    latitude: -27.7821006775,
    longitude: -50.28150177,
    altitude: 3065,
    schengen: false
  },
  {
    id: 769,
    name: 'Lins Airport',
    city: 'Lins',
    country: 'Brazil',
    iata: 'LIP',
    icao: 'SBLN',
    latitude: -21.663999557495,
    longitude: -49.730499267578,
    altitude: 1559,
    schengen: false
  },
  {
    id: 770,
    name: 'Governador José Richa Airport',
    city: 'Londrina',
    country: 'Brazil',
    iata: 'LDB',
    icao: 'SBLO',
    latitude: -23.3335990906,
    longitude: -51.1301002502,
    altitude: 1867,
    schengen: false
  },
  {
    id: 771,
    name: 'Bom Jesus da Lapa Airport',
    city: 'Bom Jesus Da Lapa',
    country: 'Brazil',
    iata: 'LAZ',
    icao: 'SBLP',
    latitude: -13.2621002197,
    longitude: -43.4081001282,
    altitude: 1454,
    schengen: false
  },
  {
    id: 772,
    name: 'Lagoa Santa Airport',
    city: 'Lagoa Santa',
    country: 'Brazil',
    iata: '',
    icao: 'SBLS',
    latitude: -19.661600112915,
    longitude: -43.8964004516602,
    altitude: 2795,
    schengen: false
  },
  {
    id: 773,
    name: 'João Correa da Rocha Airport',
    city: 'Maraba',
    country: 'Brazil',
    iata: 'MAB',
    icao: 'SBMA',
    latitude: -5.36858987808,
    longitude: -49.1380004883,
    altitude: 357,
    schengen: false
  },
  {
    id: 774,
    name: 'Minaçu Airport',
    city: 'Minacu',
    country: 'Brazil',
    iata: 'MQH',
    icao: 'SBMC',
    latitude: -13.5491,
    longitude: -48.195301,
    altitude: 1401,
    schengen: false
  },
  {
    id: 775,
    name: 'Monte Dourado Airport',
    city: 'Almeirim',
    country: 'Brazil',
    iata: 'MEU',
    icao: 'SBMD',
    latitude: -0.889839,
    longitude: -52.6022,
    altitude: 677,
    schengen: false
  },
  {
    id: 776,
    name: 'Macaé Airport',
    city: 'Macaé',
    country: 'Brazil',
    iata: 'MEA',
    icao: 'SBME',
    latitude: -22.343000412,
    longitude: -41.7659988403,
    altitude: 8,
    schengen: false
  },
  {
    id: 777,
    name: 'Regional de Maringá - Sílvio Nane Junior Airport',
    city: 'Maringa',
    country: 'Brazil',
    iata: 'MGF',
    icao: 'SBMG',
    latitude: -23.4794445038,
    longitude: -52.01222229,
    altitude: 1788,
    schengen: false
  },
  {
    id: 778,
    name: 'Mário Ribeiro Airport',
    city: 'Montes Claros',
    country: 'Brazil',
    iata: 'MOC',
    icao: 'SBMK',
    latitude: -16.7068996429,
    longitude: -43.818901062,
    altitude: 2191,
    schengen: false
  },
  {
    id: 779,
    name: 'Frank Miloye Milenkowichi–Marília State Airport',
    city: 'Marília',
    country: 'Brazil',
    iata: 'MII',
    icao: 'SBML',
    latitude: -22.1968994141,
    longitude: -49.926399231,
    altitude: 2122,
    schengen: false
  },
  {
    id: 780,
    name: 'Ponta Pelada Airport',
    city: 'Manaus',
    country: 'Brazil',
    iata: 'PLL',
    icao: 'SBMN',
    latitude: -3.14603996276855,
    longitude: -59.9863014221191,
    altitude: 267,
    schengen: false
  },
  {
    id: 781,
    name: 'Zumbi dos Palmares Airport',
    city: 'Maceio',
    country: 'Brazil',
    iata: 'MCZ',
    icao: 'SBMO',
    latitude: -9.51080989837647,
    longitude: -35.7916984558105,
    altitude: 387,
    schengen: false
  },
  {
    id: 782,
    name: 'Alberto Alcolumbre Airport',
    city: 'Macapa',
    country: 'Brazil',
    iata: 'MCP',
    icao: 'SBMQ',
    latitude: 0.0506640002131,
    longitude: -51.0722007751,
    altitude: 56,
    schengen: false
  },
  {
    id: 783,
    name: 'Dix-Sept Rosado Airport',
    city: 'Mocord',
    country: 'Brazil',
    iata: 'MVF',
    icao: 'SBMS',
    latitude: -5.2019200325,
    longitude: -37.3642997742,
    altitude: 76,
    schengen: false
  },
  {
    id: 784,
    name: 'Campo de Marte Airport',
    city: 'Sao Paulo',
    country: 'Brazil',
    iata: '',
    icao: 'SBMT',
    latitude: -23.5090999603,
    longitude: -46.6377983093,
    altitude: 2368,
    schengen: false
  },
  {
    id: 785,
    name: 'Manicoré Airport',
    city: 'Manicore',
    country: 'Brazil',
    iata: 'MNX',
    icao: 'SBMY',
    latitude: -5.8113799095154,
    longitude: -61.278301239014,
    altitude: 174,
    schengen: false
  },
  {
    id: 786,
    name: 'Ministro Victor Konder International Airport',
    city: 'Navegantes',
    country: 'Brazil',
    iata: 'NVT',
    icao: 'SBNF',
    latitude: -26.879999,
    longitude: -48.651402,
    altitude: 18,
    schengen: false
  },
  {
    id: 787,
    name: 'Santo Ângelo Airport',
    city: 'Santo Angelo',
    country: 'Brazil',
    iata: 'GEL',
    icao: 'SBNM',
    latitude: -28.2817,
    longitude: -54.169102,
    altitude: 1056,
    schengen: false
  },
  {
    id: 788,
    name: 'Augusto Severo Airport',
    city: 'Natal',
    country: 'Brazil',
    iata: '',
    icao: 'SBNT',
    latitude: -5.91141986847,
    longitude: -35.2476997375,
    altitude: 169,
    schengen: false
  },
  {
    id: 789,
    name: 'Oiapoque Airport',
    city: 'Oioiapoque',
    country: 'Brazil',
    iata: 'OYK',
    icao: 'SBOI',
    latitude: 3.85548996925354,
    longitude: -51.7969017028809,
    altitude: 63,
    schengen: false
  },
  {
    id: 790,
    name: 'Salgado Filho Airport',
    city: 'Porto Alegre',
    country: 'Brazil',
    iata: 'POA',
    icao: 'SBPA',
    latitude: -29.9944000244141,
    longitude: -51.1713981628418,
    altitude: 11,
    schengen: false
  },
  {
    id: 791,
    name: 'Prefeito Doutor João Silva Filho Airport',
    city: 'Parnaiba',
    country: 'Brazil',
    iata: 'PHB',
    icao: 'SBPB',
    latitude: -2.89374995232,
    longitude: -41.7319984436,
    altitude: 16,
    schengen: false
  },
  {
    id: 792,
    name: 'Poços de Caldas - Embaixador Walther Moreira Salles Airport',
    city: 'Pocos De Caldas',
    country: 'Brazil',
    iata: 'POO',
    icao: 'SBPC',
    latitude: -21.843000411987,
    longitude: -46.567901611328,
    altitude: 4135,
    schengen: false
  },
  {
    id: 793,
    name: 'Lauro Kurtz Airport',
    city: 'Passo Fundo',
    country: 'Brazil',
    iata: 'PFB',
    icao: 'SBPF',
    latitude: -28.243999,
    longitude: -52.326599,
    altitude: 2376,
    schengen: false
  },
  {
    id: 794,
    name: 'Brigadeiro Lysias Rodrigues Airport',
    city: 'Palmas',
    country: 'Brazil',
    iata: 'PMW',
    icao: 'SBPJ',
    latitude: -10.2915000916,
    longitude: -48.3569984436,
    altitude: 774,
    schengen: false
  },
  {
    id: 795,
    name: 'João Simões Lopes Neto International Airport',
    city: 'Pelotas',
    country: 'Brazil',
    iata: 'PET',
    icao: 'SBPK',
    latitude: -31.718399,
    longitude: -52.327702,
    altitude: 59,
    schengen: false
  },
  {
    id: 796,
    name: 'Senador Nilo Coelho Airport',
    city: 'Petrolina',
    country: 'Brazil',
    iata: 'PNZ',
    icao: 'SBPL',
    latitude: -9.36240959167481,
    longitude: -40.5690994262695,
    altitude: 1263,
    schengen: false
  },
  {
    id: 797,
    name: 'Porto Nacional Airport',
    city: 'Porto Nacional',
    country: 'Brazil',
    iata: 'PNB',
    icao: 'SBPN',
    latitude: -10.7194004058838,
    longitude: -48.3997001647949,
    altitude: 870,
    schengen: false
  },
  {
    id: 798,
    name: 'Ponta Porã Airport',
    city: 'Ponta Pora',
    country: 'Brazil',
    iata: 'PMG',
    icao: 'SBPP',
    latitude: -22.5496006011963,
    longitude: -55.7025985717773,
    altitude: 2156,
    schengen: false
  },
  {
    id: 799,
    name: 'Porto Seguro Airport',
    city: 'Porto Seguro',
    country: 'Brazil',
    iata: 'BPS',
    icao: 'SBPS',
    latitude: -16.438601,
    longitude: -39.080898,
    altitude: 168,
    schengen: false
  },
  {
    id: 800,
    name: 'Governador Jorge Teixeira de Oliveira Airport',
    city: 'Porto Velho',
    country: 'Brazil',
    iata: 'PVH',
    icao: 'SBPV',
    latitude: -8.70928955078125,
    longitude: -63.9023017883301,
    altitude: 290,
    schengen: false
  },
  {
    id: 801,
    name: 'Vitória da Conquista Airport',
    city: 'Vitória Da Conquista',
    country: 'Brazil',
    iata: 'VDC',
    icao: 'SBQV',
    latitude: -14.8627996445,
    longitude: -40.8630981445,
    altitude: 3002,
    schengen: false
  },
  {
    id: 802,
    name: 'Plácido de Castro Airport',
    city: 'Rio Branco',
    country: 'Brazil',
    iata: 'RBR',
    icao: 'SBRB',
    latitude: -9.86888885498047,
    longitude: -67.8980560302734,
    altitude: 633,
    schengen: false
  },
  {
    id: 803,
    name: 'Guararapes - Gilberto Freyre International Airport',
    city: 'Recife',
    country: 'Brazil',
    iata: 'REC',
    icao: 'SBRF',
    latitude: -8.12648963928223,
    longitude: -34.9235992431641,
    altitude: 33,
    schengen: false
  },
  {
    id: 804,
    name: 'Santos Dumont Airport',
    city: 'Rio De Janeiro',
    country: 'Brazil',
    iata: 'SDU',
    icao: 'SBRJ',
    latitude: -22.9104995728,
    longitude: -43.1631011963,
    altitude: 11,
    schengen: false
  },
  {
    id: 805,
    name: 'Leite Lopes Airport',
    city: 'Ribeirao Preto',
    country: 'Brazil',
    iata: 'RAO',
    icao: 'SBRP',
    latitude: -21.1363887786865,
    longitude: -47.776668548584,
    altitude: 1806,
    schengen: false
  },
  {
    id: 806,
    name: 'Santa Cruz Air Force Base',
    city: 'Rio De Janeiro',
    country: 'Brazil',
    iata: 'SNZ',
    icao: 'SBSC',
    latitude: -22.9324,
    longitude: -43.719101,
    altitude: 10,
    schengen: false
  },
  {
    id: 807,
    name: 'Governador Aluízio Alves International Airport',
    city: 'Natal',
    country: 'Brazil',
    iata: 'NAT',
    icao: 'SBSG',
    latitude: -5.768056,
    longitude: -35.376111,
    altitude: 272,
    schengen: false
  },
  {
    id: 808,
    name: 'Professor Urbano Ernesto Stumpf Airport',
    city: 'Sao Jose Dos Campos',
    country: 'Brazil',
    iata: 'SJK',
    icao: 'SBSJ',
    latitude: -23.2292003631592,
    longitude: -45.861499786377,
    altitude: 2120,
    schengen: false
  },
  {
    id: 809,
    name: 'Marechal Cunha Machado International Airport',
    city: 'Sao Luis',
    country: 'Brazil',
    iata: 'SLZ',
    icao: 'SBSL',
    latitude: -2.58536005020142,
    longitude: -44.2341003417969,
    altitude: 178,
    schengen: false
  },
  {
    id: 810,
    name: 'Santa Maria Airport',
    city: 'Santa Maria',
    country: 'Brazil',
    iata: 'RIA',
    icao: 'SBSM',
    latitude: -29.711399,
    longitude: -53.688202,
    altitude: 287,
    schengen: false
  },
  {
    id: 811,
    name: 'Maestro Wilson Fonseca Airport',
    city: 'Santarem',
    country: 'Brazil',
    iata: 'STM',
    icao: 'SBSN',
    latitude: -2.42472195625305,
    longitude: -54.785831451416,
    altitude: 198,
    schengen: false
  },
  {
    id: 812,
    name: 'Adolino Bedin Regional Airport',
    city: 'Sorriso',
    country: 'Brazil',
    iata: 'SMT',
    icao: 'SBSO',
    latitude: -12.479177,
    longitude: -55.672341,
    altitude: 1266,
    schengen: false
  },
  {
    id: 813,
    name: 'Congonhas Airport',
    city: 'Sao Paulo',
    country: 'Brazil',
    iata: 'CGH',
    icao: 'SBSP',
    latitude: -23.6261100769043,
    longitude: -46.6563873291016,
    altitude: 2631,
    schengen: false
  },
  {
    id: 814,
    name: 'Prof. Eribelto Manoel Reino State Airport',
    city: 'Sao Jose Do Rio Preto',
    country: 'Brazil',
    iata: 'SJP',
    icao: 'SBSR',
    latitude: -20.8166007996,
    longitude: -49.40650177,
    altitude: 1784,
    schengen: false
  },
  {
    id: 815,
    name: 'Base Aérea de Santos Airport',
    city: 'Santos',
    country: 'Brazil',
    iata: 'SSZ',
    icao: 'SBST',
    latitude: -23.9280567169189,
    longitude: -46.2997207641602,
    altitude: 10,
    schengen: false
  },
  {
    id: 816,
    name: 'Deputado Luiz Eduardo Magalhães International Airport',
    city: 'Salvador',
    country: 'Brazil',
    iata: 'SSA',
    icao: 'SBSV',
    latitude: -12.9086112976,
    longitude: -38.3224983215,
    altitude: 64,
    schengen: false
  },
  {
    id: 817,
    name: 'Base de Aviação de Taubaté Airport',
    city: 'Taubaté',
    country: 'Brazil',
    iata: 'QHP',
    icao: 'SBTA',
    latitude: -23.0401000976562,
    longitude: -45.515998840332,
    altitude: 1908,
    schengen: false
  },
  {
    id: 818,
    name: 'Trombetas Airport',
    city: 'Oriximina',
    country: 'Brazil',
    iata: 'TMT',
    icao: 'SBTB',
    latitude: -1.489599943161,
    longitude: -56.396800994873,
    altitude: 287,
    schengen: false
  },
  {
    id: 819,
    name: 'Hotel Transamérica Airport',
    city: 'Una',
    country: 'Brazil',
    iata: 'UNA',
    icao: 'SBTC',
    latitude: -15.3551998138,
    longitude: -38.9990005493,
    altitude: 20,
    schengen: false
  },
  {
    id: 820,
    name: 'Toledo Airport',
    city: 'Toledo',
    country: 'Brazil',
    iata: 'TOW',
    icao: 'SBTD',
    latitude: -24.6863,
    longitude: -53.697498,
    altitude: 1843,
    schengen: false
  },
  {
    id: 821,
    name: 'Senador Petrônio Portela Airport',
    city: 'Teresina',
    country: 'Brazil',
    iata: 'THE',
    icao: 'SBTE',
    latitude: -5.0599398613,
    longitude: -42.8235015869,
    altitude: 219,
    schengen: false
  },
  {
    id: 822,
    name: 'Tefé Airport',
    city: 'Tefe',
    country: 'Brazil',
    iata: 'TFF',
    icao: 'SBTF',
    latitude: -3.38294005394,
    longitude: -64.7240982056,
    altitude: 188,
    schengen: false
  },
  {
    id: 823,
    name: 'Tarauacá Airport',
    city: 'Tarauaca',
    country: 'Brazil',
    iata: 'TRQ',
    icao: 'SBTK',
    latitude: -8.1552600860596,
    longitude: -70.783302307129,
    altitude: 646,
    schengen: false
  },
  {
    id: 824,
    name: 'Telêmaco Borba Airport',
    city: 'Telemaco Borba',
    country: 'Brazil',
    iata: 'TEC',
    icao: 'SBTL',
    latitude: -24.3178005218506,
    longitude: -50.6515998840332,
    altitude: 2610,
    schengen: false
  },
  {
    id: 825,
    name: 'Tiriós Airport',
    city: 'Obidos Tirios',
    country: 'Brazil',
    iata: '',
    icao: 'SBTS',
    latitude: 2.22347,
    longitude: -55.946098,
    altitude: 1127,
    schengen: false
  },
  {
    id: 826,
    name: 'Tabatinga Airport',
    city: 'Tabatinga',
    country: 'Brazil',
    iata: 'TBT',
    icao: 'SBTT',
    latitude: -4.2556700706482,
    longitude: -69.93579864502,
    altitude: 279,
    schengen: false
  },
  {
    id: 827,
    name: 'Tucuruí Airport',
    city: 'Tucurui',
    country: 'Brazil',
    iata: 'TUR',
    icao: 'SBTU',
    latitude: -3.7860100269318,
    longitude: -49.72029876709,
    altitude: 830,
    schengen: false
  },
  {
    id: 828,
    name: 'São Gabriel da Cachoeira Airport',
    city: 'Sao Gabriel',
    country: 'Brazil',
    iata: 'SJL',
    icao: 'SBUA',
    latitude: -0.14835,
    longitude: -66.9855,
    altitude: 251,
    schengen: false
  },
  {
    id: 829,
    name: 'Paulo Afonso Airport',
    city: 'Paulo Alfonso',
    country: 'Brazil',
    iata: 'PAV',
    icao: 'SBUF',
    latitude: -9.4008798599243,
    longitude: -38.250598907471,
    altitude: 883,
    schengen: false
  },
  {
    id: 830,
    name: 'Rubem Berta Airport',
    city: 'Uruguaiana',
    country: 'Brazil',
    iata: 'URG',
    icao: 'SBUG',
    latitude: -29.7821998596,
    longitude: -57.0382003784,
    altitude: 256,
    schengen: false
  },
  {
    id: 831,
    name: 'Ten. Cel. Aviador César Bombonato Airport',
    city: 'Uberlandia',
    country: 'Brazil',
    iata: 'UDI',
    icao: 'SBUL',
    latitude: -18.883612,
    longitude: -48.225277,
    altitude: 3094,
    schengen: false
  },
  {
    id: 832,
    name: 'Mário de Almeida Franco Airport',
    city: 'Uberaba',
    country: 'Brazil',
    iata: 'UBA',
    icao: 'SBUR',
    latitude: -19.764722824097,
    longitude: -47.966110229492,
    altitude: 2655,
    schengen: false
  },
  {
    id: 833,
    name: 'Major Brigadeiro Trompowsky Airport',
    city: 'Varginha',
    country: 'Brazil',
    iata: 'VAG',
    icao: 'SBVG',
    latitude: -21.5900993347,
    longitude: -45.4733009338,
    altitude: 3025,
    schengen: false
  },
  {
    id: 834,
    name: 'Brigadeiro Camarão Airport',
    city: 'Vilhena',
    country: 'Brazil',
    iata: 'BVH',
    icao: 'SBVH',
    latitude: -12.694399833679,
    longitude: -60.098300933838,
    altitude: 2018,
    schengen: false
  },
  {
    id: 835,
    name: 'Eurico de Aguiar Salles Airport',
    city: 'Vitoria',
    country: 'Brazil',
    iata: 'VIX',
    icao: 'SBVT',
    latitude: -20.258057,
    longitude: -40.286388,
    altitude: 11,
    schengen: false
  },
  {
    id: 836,
    name: 'Iauaretê Airport',
    city: 'Iauarete',
    country: 'Brazil',
    iata: '',
    icao: 'SBYA',
    latitude: 0.6075000166893,
    longitude: -69.18579864502,
    altitude: 345,
    schengen: false
  },
  {
    id: 837,
    name: 'Campo Fontenelle Airport',
    city: 'Piracununga',
    country: 'Brazil',
    iata: 'QPS',
    icao: 'SBYS',
    latitude: -21.9846000671387,
    longitude: -47.3348007202148,
    altitude: 1968,
    schengen: false
  },
  {
    id: 838,
    name: 'CONSELVAN Airport',
    city: 'CONSELVAN',
    country: 'Brazil',
    iata: '',
    icao: 'SCSN',
    latitude: -9.9369444444444,
    longitude: -59.9091666666667,
    altitude: 246,
    schengen: false
  },
  {
    id: 839,
    name: 'Angra dos Reis Airport',
    city: 'Angra dos Reis',
    country: 'Brazil',
    iata: '',
    icao: 'SDAG',
    latitude: -22.9752998352051,
    longitude: -44.3070983886719,
    altitude: 10,
    schengen: false
  },
  {
    id: 840,
    name: 'Americana Airport',
    city: 'Americana',
    country: 'Brazil',
    iata: '',
    icao: 'SDAI',
    latitude: -22.7558002471924,
    longitude: -47.269401550293,
    altitude: 2085,
    schengen: false
  },
  {
    id: 841,
    name: 'Amarais Airport',
    city: 'Campinas',
    country: 'Brazil',
    iata: 'CPQ',
    icao: 'SDAM',
    latitude: -22.8591995239258,
    longitude: -47.1082000732422,
    altitude: 2008,
    schengen: false
  },
  {
    id: 842,
    name: 'Botucatu - Tancredo de Almeida Neves Airport',
    city: 'Botucatu',
    country: 'Brazil',
    iata: 'QCJ',
    icao: 'SDBK',
    latitude: -22.939501,
    longitude: -48.467999,
    altitude: 3012,
    schengen: false
  },
  {
    id: 843,
    name: 'Senadora Eunice Micheles Airport',
    city: 'Sao Paulo de Olivenca',
    country: 'Brazil',
    iata: 'OLC',
    icao: 'SDCG',
    latitude: -3.46792950765,
    longitude: -68.9204120636,
    altitude: 335,
    schengen: false
  },
  {
    id: 844,
    name: 'Sorocaba Airport',
    city: 'Sorocaba',
    country: 'Brazil',
    iata: 'SOD',
    icao: 'SDCO',
    latitude: -23.478001,
    longitude: -47.490002,
    altitude: 2077,
    schengen: false
  },
  {
    id: 845,
    name: 'Morro da Urca Heliport',
    city: 'Rio de Janeiro',
    country: 'Brazil',
    iata: '',
    icao: 'SDHU',
    latitude: -22.9516677856445,
    longitude: -43.1658325195312,
    altitude: 692,
    schengen: false
  },
  {
    id: 846,
    name: 'Maricá Airport',
    city: 'Marica',
    country: 'Brazil',
    iata: '',
    icao: 'SDMC',
    latitude: -22.9195003509521,
    longitude: -42.8308982849121,
    altitude: 13,
    schengen: false
  },
  {
    id: 847,
    name: 'Aeroclube Airport',
    city: 'Nova Iguacu',
    country: 'Brazil',
    iata: 'QNV',
    icao: 'SDNY',
    latitude: -22.7453002929687,
    longitude: -43.4603004455566,
    altitude: 164,
    schengen: false
  },
  {
    id: 848,
    name: 'Ourilândia do Norte Airport',
    city: 'Ourilandia do Norte',
    country: 'Brazil',
    iata: 'OIA',
    icao: 'SDOW',
    latitude: -6.76310014725,
    longitude: -51.0499000549,
    altitude: 901,
    schengen: false
  },
  {
    id: 849,
    name: 'Resende Airport',
    city: 'Resende',
    country: 'Brazil',
    iata: 'REZ',
    icao: 'SDRS',
    latitude: -22.4785003662,
    longitude: -44.4803009033,
    altitude: 1320,
    schengen: false
  },
  {
    id: 850,
    name: 'São Carlos Airport',
    city: 'Sao Carlos',
    country: 'Brazil',
    iata: 'QSC',
    icao: 'SDSC',
    latitude: -21.8754005432129,
    longitude: -47.9037017822266,
    altitude: 2649,
    schengen: false
  },
  {
    id: 851,
    name: 'Saquarema Airport',
    city: 'Saquarema',
    country: 'Brazil',
    iata: '',
    icao: 'SDSK',
    latitude: -22.9297218322754,
    longitude: -42.5069427490234,
    altitude: 26,
    schengen: false
  },
  {
    id: 852,
    name: 'Parati Airport',
    city: 'Paraty',
    country: 'Brazil',
    iata: '',
    icao: 'SDTK',
    latitude: -23.2243995667,
    longitude: -44.7202987671,
    altitude: 10,
    schengen: false
  },
  {
    id: 853,
    name: 'Ubatuba Airport',
    city: 'Ubatuba',
    country: 'Brazil',
    iata: 'UBT',
    icao: 'SDUB',
    latitude: -23.4410991668701,
    longitude: -45.0755996704102,
    altitude: 13,
    schengen: false
  },
  {
    id: 854,
    name: 'Itaperuna Airport',
    city: 'Itaperuna',
    country: 'Brazil',
    iata: 'ITP',
    icao: 'SDUN',
    latitude: -21.2192993164,
    longitude: -41.8759002686,
    altitude: 410,
    schengen: false
  },
  {
    id: 855,
    name: 'Zona da Mata Regional Airport',
    city: 'Juiz de Fora',
    country: 'Brazil',
    iata: 'IZA',
    icao: 'SDZY',
    latitude: -21.5130558014,
    longitude: -43.1730575562,
    altitude: 1348,
    schengen: false
  },
  {
    id: 856,
    name: 'JARU Airport',
    city: 'JARU',
    country: 'Brazil',
    iata: '',
    icao: 'SIDG',
    latitude: -10.423611111111,
    longitude: -62.5175,
    altitude: 550,
    schengen: false
  },
  {
    id: 857,
    name: 'EletroPrimavera Airport',
    city: "PRIMAVERA D'OESTE",
    country: 'Brazil',
    iata: '',
    icao: 'SIEL',
    latitude: -11.9016666667,
    longitude: -61.2288888889,
    altitude: 748,
    schengen: false
  },
  {
    id: 858,
    name: 'Machadinho Airport',
    city: "MACHADINHO D'OESTE",
    country: 'Brazil',
    iata: '',
    icao: 'SIMC',
    latitude: -9.4036111111,
    longitude: -62.00805555556,
    altitude: 450,
    schengen: false
  },
  {
    id: 859,
    name: 'Tenente Lund Pressoto Airport',
    city: 'Franca',
    country: 'Brazil',
    iata: 'FRC',
    icao: 'SIMK',
    latitude: -20.592199,
    longitude: -47.3829,
    altitude: 3292,
    schengen: false
  },
  {
    id: 860,
    name: 'COLNIZA Airport',
    city: 'COLNIZA',
    country: 'Brazil',
    iata: '',
    icao: 'SIXZ',
    latitude: -9.446111111,
    longitude: -59.199166667,
    altitude: 550,
    schengen: false
  },
  {
    id: 861,
    name: 'Inácio Luís do Nascimento Airport',
    city: 'Juara',
    country: 'Brazil',
    iata: 'JUA',
    icao: 'SIZX',
    latitude: -11.2966,
    longitude: -57.5495,
    altitude: 870,
    schengen: false
  },
  {
    id: 862,
    name: 'Bonito Airport',
    city: 'Bointo',
    country: 'Brazil',
    iata: 'BYO',
    icao: 'SJDB',
    latitude: -21.247299,
    longitude: -56.452499,
    altitude: 1180,
    schengen: false
  },
  {
    id: 863,
    name: 'Confresa Airport',
    city: 'Confresa',
    country: 'Brazil',
    iata: 'CFO',
    icao: 'SJHG',
    latitude: -10.6344003677368,
    longitude: -51.5635986328125,
    altitude: 781,
    schengen: false
  },
  {
    id: 864,
    name: 'Novo Progresso Airport',
    city: 'Novo Progresso',
    country: 'Brazil',
    iata: 'NPR',
    icao: 'SJNP',
    latitude: -7.125833,
    longitude: -55.400833,
    altitude: 794,
    schengen: false
  },
  {
    id: 865,
    name: 'Ariquemes Airport',
    city: 'ARIQUEMES',
    country: 'Brazil',
    iata: '',
    icao: 'SJOG',
    latitude: -9.88472175598145,
    longitude: -63.0488891601563,
    altitude: 530,
    schengen: false
  },
  {
    id: 866,
    name: 'Bauru - Arealva Airport',
    city: 'Bauru',
    country: 'Brazil',
    iata: 'JTC',
    icao: 'SJTC',
    latitude: -22.1668591409,
    longitude: -49.0502866745,
    altitude: 1949,
    schengen: false
  },
  {
    id: 867,
    name: 'Fazenda Mequens Airport',
    city: "ALTA FLORESTA D'OESTE",
    country: 'Brazil',
    iata: '',
    icao: 'SJTF',
    latitude: -13.0625,
    longitude: -62.2594444444,
    altitude: 552,
    schengen: false
  },
  {
    id: 868,
    name: 'Arapiraca Airport',
    city: 'Arapiraca',
    country: 'Brazil',
    iata: 'APQ',
    icao: 'SNAL',
    latitude: -9.77536010742188,
    longitude: -36.6291999816895,
    altitude: 886,
    schengen: false
  },
  {
    id: 869,
    name: 'Janaúba Airport',
    city: 'Janauba',
    country: 'Brazil',
    iata: '',
    icao: 'SNAP',
    latitude: -15.732,
    longitude: -43.323102,
    altitude: 1732,
    schengen: false
  },
  {
    id: 870,
    name: 'Cristiano Ferreira Varella Airport',
    city: 'Muriae',
    country: 'Brazil',
    iata: '',
    icao: 'SNBM',
    latitude: -21.1261005401611,
    longitude: -42.394401550293,
    altitude: 886,
    schengen: false
  },
  {
    id: 871,
    name: 'Barreiras Airport',
    city: 'Barreiras',
    country: 'Brazil',
    iata: 'BRA',
    icao: 'SNBR',
    latitude: -12.0789003372192,
    longitude: -45.0089988708496,
    altitude: 2447,
    schengen: false
  },
  {
    id: 872,
    name: 'Lorenzo Airport',
    city: 'Morro de Sao Paulo',
    country: 'Brazil',
    iata: '',
    icao: 'SNCL',
    latitude: -13.3894443511963,
    longitude: -38.9099998474121,
    altitude: 3,
    schengen: false
  },
  {
    id: 873,
    name: 'Centro de Lançamento de Alcântara Airport',
    city: 'Alcantara',
    country: 'Brazil',
    iata: '',
    icao: 'SNCW',
    latitude: -2.37299990653992,
    longitude: -44.3964004516602,
    altitude: 148,
    schengen: false
  },
  {
    id: 874,
    name: 'Redenção Airport',
    city: 'Redencao',
    country: 'Brazil',
    iata: 'RDC',
    icao: 'SNDC',
    latitude: -8.03328990936279,
    longitude: -49.9799003601074,
    altitude: 670,
    schengen: false
  },
  {
    id: 875,
    name: 'Diamantina Airport',
    city: 'Diamantina',
    country: 'Brazil',
    iata: 'DTI',
    icao: 'SNDT',
    latitude: -18.232000351,
    longitude: -43.6503982544,
    altitude: 4446,
    schengen: false
  },
  {
    id: 876,
    name: 'Brigadeiro Cabral Airport',
    city: 'Divinopolis',
    country: 'Brazil',
    iata: 'DIQ',
    icao: 'SNDV',
    latitude: -20.180700302124,
    longitude: -44.870899200439,
    altitude: 2608,
    schengen: false
  },
  {
    id: 877,
    name: 'São Félix do Xingu Airport',
    city: 'Sao Felix do Xingu',
    country: 'Brazil',
    iata: 'SXX',
    icao: 'SNFX',
    latitude: -6.6413,
    longitude: -51.9523,
    altitude: 656,
    schengen: false
  },
  {
    id: 878,
    name: 'Guarapari Airport',
    city: 'Guarapari',
    country: 'Brazil',
    iata: 'GUZ',
    icao: 'SNGA',
    latitude: -20.6464996338,
    longitude: -40.4919013977,
    altitude: 28,
    schengen: false
  },
  {
    id: 879,
    name: 'Guanambi Airport',
    city: 'Guanambi',
    country: 'Brazil',
    iata: 'GNM',
    icao: 'SNGI',
    latitude: -14.2082004547119,
    longitude: -42.7461013793945,
    altitude: 1815,
    schengen: false
  },
  {
    id: 880,
    name: 'Iguatu Airport',
    city: 'Iguatu',
    country: 'Brazil',
    iata: 'QIG',
    icao: 'SNIG',
    latitude: -6.34664011001587,
    longitude: -39.2938003540039,
    altitude: 699,
    schengen: false
  },
  {
    id: 881,
    name: 'Santana do Araguaia Airport',
    city: 'Santana do Araguaia',
    country: 'Brazil',
    iata: 'CMP',
    icao: 'SNKE',
    latitude: -9.31997013092041,
    longitude: -50.328498840332,
    altitude: 597,
    schengen: false
  },
  {
    id: 882,
    name: 'Conselheiro Lafaiete Airport',
    city: 'Conselheiro Lafaiete',
    country: 'Brazil',
    iata: 'QDF',
    icao: 'SNKF',
    latitude: -20.7385997772217,
    longitude: -43.7974014282227,
    altitude: 3478,
    schengen: false
  },
  {
    id: 883,
    name: 'Mucuri Airport',
    city: 'Mucuri',
    country: 'Brazil',
    iata: 'MVS',
    icao: 'SNMU',
    latitude: -18.048900604248,
    longitude: -39.864200592041,
    altitude: 276,
    schengen: false
  },
  {
    id: 884,
    name: 'Sobral Airport',
    city: 'Sobral',
    country: 'Brazil',
    iata: '',
    icao: 'SNOB',
    latitude: -3.67889,
    longitude: -40.336802,
    altitude: 210,
    schengen: false
  },
  {
    id: 885,
    name: 'Oriximiná Airport',
    city: 'Oriximina',
    country: 'Brazil',
    iata: 'ORX',
    icao: 'SNOX',
    latitude: -1.71407997608185,
    longitude: -55.8362007141113,
    altitude: 262,
    schengen: false
  },
  {
    id: 886,
    name: 'Picos Airport',
    city: 'Picos',
    country: 'Brazil',
    iata: 'PCS',
    icao: 'SNPC',
    latitude: -7.06205987930298,
    longitude: -41.5237007141113,
    altitude: 1050,
    schengen: false
  },
  {
    id: 887,
    name: 'Patos de Minas Airport',
    city: 'Patos de Minas',
    country: 'Brazil',
    iata: 'POJ',
    icao: 'SNPD',
    latitude: -18.6728000640869,
    longitude: -46.4911994934082,
    altitude: 2793,
    schengen: false
  },
  {
    id: 888,
    name: 'Cangapara Airport',
    city: 'Floriano',
    country: 'Brazil',
    iata: 'FLB',
    icao: 'SNQG',
    latitude: -6.84638977050781,
    longitude: -43.0773010253906,
    altitude: 689,
    schengen: false
  },
  {
    id: 889,
    name: 'Caruaru Airport',
    city: 'Caruaru',
    country: 'Brazil',
    iata: 'CAU',
    icao: 'SNRU',
    latitude: -8.28238964080811,
    longitude: -36.013500213623,
    altitude: 1891,
    schengen: false
  },
  {
    id: 890,
    name: 'Soure Airport',
    city: 'Soure',
    country: 'Brazil',
    iata: 'SFK',
    icao: 'SNSW',
    latitude: -0.699431002140045,
    longitude: -48.5209999084473,
    altitude: 43,
    schengen: false
  },
  {
    id: 891,
    name: '9 de Maio - Teixeira de Freitas Airport',
    city: 'Teixeira de Freitas',
    country: 'Brazil',
    iata: 'TXF',
    icao: 'SNTF',
    latitude: -17.524499893188,
    longitude: -39.66849899292,
    altitude: 344,
    schengen: false
  },
  {
    id: 892,
    name: 'Juscelino Kubitscheck Airport',
    city: 'Teofilo Otoni',
    country: 'Brazil',
    iata: 'TFL',
    icao: 'SNTO',
    latitude: -17.8922996520996,
    longitude: -41.513599395752,
    altitude: 1572,
    schengen: false
  },
  {
    id: 893,
    name: 'Valença Airport',
    city: 'Valenca',
    country: 'Brazil',
    iata: 'VAL',
    icao: 'SNVB',
    latitude: -13.2965,
    longitude: -38.992401,
    altitude: 21,
    schengen: false
  },
  {
    id: 894,
    name: 'Breves Airport',
    city: 'Breves',
    country: 'Brazil',
    iata: 'BVS',
    icao: 'SNVS',
    latitude: -1.63653004169464,
    longitude: -50.4435997009277,
    altitude: 98,
    schengen: false
  },
  {
    id: 895,
    name: 'Pouso Alegre Airport',
    city: 'Pouso Alegre',
    country: 'Brazil',
    iata: 'PPY',
    icao: 'SNZA',
    latitude: -22.2891998291016,
    longitude: -45.9191017150879,
    altitude: 2904,
    schengen: false
  },
  {
    id: 896,
    name: 'Paracatu Airport',
    city: 'Paracatu',
    country: 'Brazil',
    iata: '',
    icao: 'SNZR',
    latitude: -17.2425994873047,
    longitude: -46.8830986022949,
    altitude: 2359,
    schengen: false
  },
  {
    id: 897,
    name: 'Blumenau Airport',
    city: 'BLUMENAU',
    country: 'Brazil',
    iata: 'BNU',
    icao: 'SSBL',
    latitude: -26.8306007385254,
    longitude: -49.0903015136719,
    altitude: 60,
    schengen: false
  },
  {
    id: 898,
    name: 'Concórdia Airport',
    city: 'Concordia',
    country: 'Brazil',
    iata: 'CCI',
    icao: 'SSCK',
    latitude: -27.1805992126465,
    longitude: -52.0527000427246,
    altitude: 2461,
    schengen: false
  },
  {
    id: 899,
    name: 'Dourados Airport',
    city: 'Dourados',
    country: 'Brazil',
    iata: 'DOU',
    icao: 'SSDO',
    latitude: -22.2019,
    longitude: -54.926601,
    altitude: 1503,
    schengen: false
  },
  {
    id: 900,
    name: 'Erechim Airport',
    city: 'Erechim',
    country: 'Brazil',
    iata: 'ERM',
    icao: 'SSER',
    latitude: -27.6618995666504,
    longitude: -52.2682991027832,
    altitude: 2498,
    schengen: false
  },
  {
    id: 901,
    name: 'Francisco Beltrão Airport',
    city: 'Francisco Beltrao',
    country: 'Brazil',
    iata: 'FBE',
    icao: 'SSFB',
    latitude: -26.0592002868652,
    longitude: -53.0634994506836,
    altitude: 2100,
    schengen: false
  },
  {
    id: 902,
    name: 'Helisul I Heliport',
    city: 'Foz do Iguassu',
    country: 'Brazil',
    iata: '',
    icao: 'SSHH',
    latitude: -25.6041679382324,
    longitude: -54.4936103820801,
    altitude: 732,
    schengen: false
  },
  {
    id: 903,
    name: 'Helisul IV Heliport',
    city: 'Foz Do Iguacu',
    country: 'Brazil',
    iata: '',
    icao: 'SSHS',
    latitude: -25.6130561828613,
    longitude: -54.3980560302734,
    altitude: 676,
    schengen: false
  },
  {
    id: 904,
    name: 'Santa Terezinha Airport',
    city: 'Joacaba',
    country: 'Brazil',
    iata: 'JCB',
    icao: 'SSJA',
    latitude: -27.1714000702,
    longitude: -51.5532989502,
    altitude: 2546,
    schengen: false
  },
  {
    id: 905,
    name: 'Cacoal Airport',
    city: 'Cacoal',
    country: 'Brazil',
    iata: 'OAL',
    icao: 'SSKW',
    latitude: -11.496,
    longitude: -61.4508,
    altitude: 778,
    schengen: false
  },
  {
    id: 906,
    name: 'Mostardas Airport',
    city: 'SANTO ANTONIO DO MATUPI',
    country: 'Brazil',
    iata: '',
    icao: 'SSMT',
    latitude: -7.90861111111,
    longitude: -61.5627777777778,
    altitude: 550,
    schengen: false
  },
  {
    id: 907,
    name: 'São Miguel do Oeste Airport',
    city: 'Sao Miguel do Oeste',
    country: 'Brazil',
    iata: 'SQX',
    icao: 'SSOE',
    latitude: -26.7816009521484,
    longitude: -53.5035018920898,
    altitude: 2180,
    schengen: false
  },
  {
    id: 908,
    name: 'Plínio Alarcom Airport',
    city: 'Tres Lagoas',
    country: 'Brazil',
    iata: 'TJL',
    icao: 'SSTL',
    latitude: -20.754199981689,
    longitude: -51.684200286865,
    altitude: 1050,
    schengen: false
  },
  {
    id: 909,
    name: 'Umuarama Airport',
    city: 'Umuarama',
    country: 'Brazil',
    iata: 'UMU',
    icao: 'SSUM',
    latitude: -23.7987003326416,
    longitude: -53.3138008117676,
    altitude: 1558,
    schengen: false
  },
  {
    id: 910,
    name: 'Fazenda Vaticano Airport',
    city: 'Cruz',
    country: 'Brazil',
    iata: '',
    icao: 'SSVV',
    latitude: -21.294443,
    longitude: -56.11861,
    altitude: 1050,
    schengen: false
  },
  {
    id: 911,
    name: 'Itapiranga Airport',
    city: 'Itapiranga',
    country: 'Brazil',
    iata: '',
    icao: 'SSYT',
    latitude: -27.1424999237061,
    longitude: -53.6857986450195,
    altitude: 1247,
    schengen: false
  },
  {
    id: 912,
    name: 'Santa Rosa Airport',
    city: 'Santa Rosa',
    country: 'Brazil',
    iata: 'SRA',
    icao: 'SSZR',
    latitude: -27.9067,
    longitude: -54.520401,
    altitude: 984,
    schengen: false
  },
  {
    id: 913,
    name: 'Ponta Grossa Airport - Comandante Antonio Amilton Beraldo',
    city: 'Ponta Grossa',
    country: 'Brazil',
    iata: 'PGZ',
    icao: 'SSZW',
    latitude: -25.1847,
    longitude: -50.1441,
    altitude: 2588,
    schengen: false
  },
  {
    id: 914,
    name: 'Barcelos Airport',
    city: 'Barcelos',
    country: 'Brazil',
    iata: 'BAZ',
    icao: 'SWBC',
    latitude: -0.981292,
    longitude: -62.919601,
    altitude: 112,
    schengen: false
  },
  {
    id: 915,
    name: 'Borba Airport',
    city: 'Borba',
    country: 'Brazil',
    iata: 'RBB',
    icao: 'SWBR',
    latitude: -4.4063401222229,
    longitude: -59.6024017333984,
    altitude: 293,
    schengen: false
  },
  {
    id: 916,
    name: 'Carauari Airport',
    city: 'Carauari',
    country: 'Brazil',
    iata: 'CAF',
    icao: 'SWCA',
    latitude: -4.87152004241943,
    longitude: -66.8974990844727,
    altitude: 355,
    schengen: false
  },
  {
    id: 917,
    name: 'Diamantino Airport',
    city: 'Diamantino',
    country: 'Brazil',
    iata: 'DMT',
    icao: 'SWDM',
    latitude: -14.3768997192383,
    longitude: -56.4003982543945,
    altitude: 1476,
    schengen: false
  },
  {
    id: 918,
    name: 'Eirunepé Airport',
    city: 'Eirunepe',
    country: 'Brazil',
    iata: 'ERN',
    icao: 'SWEI',
    latitude: -6.63953018188477,
    longitude: -69.8797988891602,
    altitude: 412,
    schengen: false
  },
  {
    id: 919,
    name: 'Canarana Airport',
    city: 'Canarana',
    country: 'Brazil',
    iata: 'CQA',
    icao: 'SWEK',
    latitude: -13.5744438171387,
    longitude: -52.2705574035645,
    altitude: 1314,
    schengen: false
  },
  {
    id: 920,
    name: 'Fazenda Colen Airport',
    city: 'Lucas do Rio Verde',
    country: 'Brazil',
    iata: '',
    icao: 'SWFE',
    latitude: -13.3144435882568,
    longitude: -56.1127777099609,
    altitude: 1345,
    schengen: false
  },
  {
    id: 921,
    name: 'São Félix do Araguaia Airport',
    city: 'Sao Felix do Araguaia',
    country: 'Brazil',
    iata: 'SXO',
    icao: 'SWFX',
    latitude: -11.632399559021,
    longitude: -50.6896018981934,
    altitude: 650,
    schengen: false
  },
  {
    id: 922,
    name: 'Gurupi Airport',
    city: 'Gurupi',
    country: 'Brazil',
    iata: 'GRP',
    icao: 'SWGI',
    latitude: -11.7396001815796,
    longitude: -49.1321983337402,
    altitude: 1148,
    schengen: false
  },
  {
    id: 923,
    name: 'Araguaína Airport',
    city: 'Araguaina',
    country: 'Brazil',
    iata: 'AUX',
    icao: 'SWGN',
    latitude: -7.22787,
    longitude: -48.240501,
    altitude: 771,
    schengen: false
  },
  {
    id: 924,
    name: 'Olhos D`água Airport',
    city: 'Agua Boa',
    country: 'Brazil',
    iata: '',
    icao: 'SWHP',
    latitude: -14.019444,
    longitude: -52.152222,
    altitude: 1506,
    schengen: false
  },
  {
    id: 925,
    name: 'Humaitá Airport',
    city: 'Humaita',
    country: 'Brazil',
    iata: 'HUW',
    icao: 'SWHT',
    latitude: -7.53212022781,
    longitude: -63.072101593,
    altitude: 230,
    schengen: false
  },
  {
    id: 926,
    name: 'Ji-Paraná Airport',
    city: 'Ji-Paraná',
    country: 'Brazil',
    iata: 'JPR',
    icao: 'SWJI',
    latitude: -10.8708000183,
    longitude: -61.8465003967,
    altitude: 598,
    schengen: false
  },
  {
    id: 927,
    name: 'Juína Airport',
    city: 'Juina',
    country: 'Brazil',
    iata: 'JIA',
    icao: 'SWJN',
    latitude: -11.419444,
    longitude: -58.701668,
    altitude: 1083,
    schengen: false
  },
  {
    id: 928,
    name: 'Juruena Airport',
    city: 'Juruena',
    country: 'Brazil',
    iata: 'JRN',
    icao: 'SWJU',
    latitude: -10.305832862854,
    longitude: -58.489444732666,
    altitude: 525,
    schengen: false
  },
  {
    id: 929,
    name: 'Cáceres Airport',
    city: 'Caceres',
    country: 'Brazil',
    iata: 'CCX',
    icao: 'SWKC',
    latitude: -16.0436000823975,
    longitude: -57.6299018859863,
    altitude: 492,
    schengen: false
  },
  {
    id: 930,
    name: 'Coari Airport',
    city: 'Coari',
    country: 'Brazil',
    iata: 'CIZ',
    icao: 'SWKO',
    latitude: -4.13405990600586,
    longitude: -63.1325988769531,
    altitude: 131,
    schengen: false
  },
  {
    id: 931,
    name: 'Serra da Capivara Airport',
    city: 'Sao Raimundo Nonato',
    country: 'Brazil',
    iata: '',
    icao: 'SWKQ',
    latitude: -9.082778,
    longitude: -42.644444,
    altitude: 1362,
    schengen: false
  },
  {
    id: 932,
    name: 'Lábrea Airport',
    city: 'Labrea',
    country: 'Brazil',
    iata: 'LBR',
    icao: 'SWLB',
    latitude: -7.27896976470947,
    longitude: -64.7695007324219,
    altitude: 190,
    schengen: false
  },
  {
    id: 933,
    name: 'General Leite de Castro Airport',
    city: 'Rio Verde',
    country: 'Brazil',
    iata: 'RVD',
    icao: 'SWLC',
    latitude: -17.8347225189209,
    longitude: -50.956111907959,
    altitude: 2464,
    schengen: false
  },
  {
    id: 934,
    name: 'Maturacá Airport',
    city: 'Maturaca',
    country: 'Brazil',
    iata: '',
    icao: 'SWMK',
    latitude: 0.628269016742706,
    longitude: -66.1150970458984,
    altitude: 354,
    schengen: false
  },
  {
    id: 935,
    name: 'Maués Airport',
    city: 'Maues',
    country: 'Brazil',
    iata: 'MBZ',
    icao: 'SWMW',
    latitude: -3.37217,
    longitude: -57.7248,
    altitude: 69,
    schengen: false
  },
  {
    id: 936,
    name: 'Novo Aripuanã Airport',
    city: 'Novo Aripuana',
    country: 'Brazil',
    iata: 'NVP',
    icao: 'SWNA',
    latitude: -5.11803007125855,
    longitude: -60.3648986816406,
    altitude: 118,
    schengen: false
  },
  {
    id: 937,
    name: 'Fonte Boa Airport',
    city: 'Fonte Boa',
    country: 'Brazil',
    iata: 'FBA',
    icao: 'SWOB',
    latitude: -2.53260993958,
    longitude: -66.0831985474,
    altitude: 207,
    schengen: false
  },
  {
    id: 938,
    name: 'Parintins Airport',
    city: 'Parintins',
    country: 'Brazil',
    iata: 'PIN',
    icao: 'SWPI',
    latitude: -2.67301988601685,
    longitude: -56.7771987915039,
    altitude: 87,
    schengen: false
  },
  {
    id: 939,
    name: 'Posto Leonardo Vilas Boas Airport',
    city: 'Chapada dos Guimaraes',
    country: 'Brazil',
    iata: '',
    icao: 'SWPL',
    latitude: -12.1983327865601,
    longitude: -53.3816680908203,
    altitude: 1083,
    schengen: false
  },
  {
    id: 940,
    name: 'Pimenta Bueno Airport',
    city: 'Pimenta Bueno',
    country: 'Brazil',
    iata: 'PBQ',
    icao: 'SWPM',
    latitude: -11.6415996551514,
    longitude: -61.1791000366211,
    altitude: 682,
    schengen: false
  },
  {
    id: 941,
    name: 'Primavera do Leste Airport',
    city: 'PRIMAVERA DO LESTE',
    country: 'Brazil',
    iata: '',
    icao: 'SWPY',
    latitude: -15.56277777778,
    longitude: -54.329722222,
    altitude: 2149,
    schengen: false
  },
  {
    id: 942,
    name: 'Fazenda Sao Nicolau Airport',
    city: 'COTRIGUACU',
    country: 'Brazil',
    iata: '',
    icao: 'SWQT',
    latitude: -9.9044437789917,
    longitude: -58.582167938232,
    altitude: 738,
    schengen: false
  },
  {
    id: 943,
    name: 'Maestro Marinho Franco Airport',
    city: 'Rondonopolis',
    country: 'Brazil',
    iata: 'ROO',
    icao: 'SWRD',
    latitude: -16.586,
    longitude: -54.7248,
    altitude: 1467,
    schengen: false
  },
  {
    id: 944,
    name: 'Aripuanã Airport',
    city: 'Aripuana',
    country: 'Brazil',
    iata: 'AIR',
    icao: 'SWRP',
    latitude: -10.188278,
    longitude: -59.457273,
    altitude: 623,
    schengen: false
  },
  {
    id: 945,
    name: 'Presidente João Batista Figueiredo Airport',
    city: 'Sinop',
    country: 'Brazil',
    iata: 'OPS',
    icao: 'SWSI',
    latitude: -11.8850002288818,
    longitude: -55.586109161377,
    altitude: 1227,
    schengen: false
  },
  {
    id: 946,
    name: 'Santa Terezinha Airport',
    city: 'Santa Terezinha',
    country: 'Brazil',
    iata: 'STZ',
    icao: 'SWST',
    latitude: -10.4647216796875,
    longitude: -50.518611907959,
    altitude: 663,
    schengen: false
  },
  {
    id: 947,
    name: 'Tapuruquara Airport',
    city: 'Santa Isabel do Rio Negro',
    country: 'Brazil',
    iata: 'IRZ',
    icao: 'SWTP',
    latitude: -0.3786,
    longitude: -64.9923,
    altitude: 223,
    schengen: false
  },
  {
    id: 948,
    name: 'Tangará da Serra Airport',
    city: 'Tangara da Serra',
    country: 'Brazil',
    iata: 'TGQ',
    icao: 'SWTS',
    latitude: -14.6619997025,
    longitude: -57.4435005188,
    altitude: 1460,
    schengen: false
  },
  {
    id: 949,
    name: 'Urucu Airport',
    city: 'Porto Urucu',
    country: 'Brazil',
    iata: '',
    icao: 'SWUY',
    latitude: -4.88422012329,
    longitude: -65.3554000854,
    altitude: 243,
    schengen: false
  },
  {
    id: 950,
    name: 'Vila Bela da Santíssima Trindade Airport',
    city: 'Vila Bela da Santissima Trindade ',
    country: 'Brazil',
    iata: 'MTG',
    icao: 'SWVB',
    latitude: -14.9942,
    longitude: -59.9458,
    altitude: 660,
    schengen: false
  },
  {
    id: 951,
    name: 'Vila Rica Airport',
    city: 'Vila Rica',
    country: 'Brazil',
    iata: 'VLP',
    icao: 'SWVC',
    latitude: -9.97944355010986,
    longitude: -51.1422233581543,
    altitude: 892,
    schengen: false
  },
  {
    id: 952,
    name: 'Prainha Airport',
    city: 'APUI',
    country: 'Brazil',
    iata: '',
    icao: 'SWYN',
    latitude: -7.17287015914917,
    longitude: -59.839599609375,
    altitude: 197,
    schengen: false
  },
  {
    id: 953,
    name: 'Diego Garcia Naval Support Facility',
    city: 'Diego Garcia Island',
    country: 'British Indian Ocean Territory',
    iata: 'NKW',
    icao: 'FJDG',
    latitude: -7.31327,
    longitude: 72.411102,
    altitude: 9,
    schengen: false
  },
  {
    id: 954,
    name: 'Captain Auguste George Airport',
    city: 'Anegada',
    country: 'British Virgin Islands',
    iata: 'NGD',
    icao: 'TUPA',
    latitude: 18.7271995544434,
    longitude: -64.3296966552734,
    altitude: 9,
    schengen: false
  },
  {
    id: 955,
    name: 'Terrance B. Lettsome International Airport',
    city: 'Tortola',
    country: 'British Virgin Islands',
    iata: 'EIS',
    icao: 'TUPJ',
    latitude: 18.4447994232178,
    longitude: -64.5429992675781,
    altitude: 15,
    schengen: false
  },
  {
    id: 956,
    name: 'Virgin Gorda Airport',
    city: 'Spanish Town',
    country: 'British Virgin Islands',
    iata: 'VIJ',
    icao: 'TUPW',
    latitude: 18.4463996887207,
    longitude: -64.4274978637695,
    altitude: 9,
    schengen: false
  },
  {
    id: 957,
    name: 'Anduki Airport',
    city: 'Seria',
    country: 'Brunei',
    iata: '',
    icao: 'WBAK',
    latitude: 4.63700008392,
    longitude: 114.382003784,
    altitude: 7,
    schengen: false
  },
  {
    id: 958,
    name: 'Brunei International Airport',
    city: 'Bandar Seri Begawan',
    country: 'Brunei',
    iata: 'BWN',
    icao: 'WBSB',
    latitude: 4.94420003890991,
    longitude: 114.928001403809,
    altitude: 73,
    schengen: false
  },
  {
    id: 959,
    name: 'Burgas Airport',
    city: 'Bourgas',
    country: 'Bulgaria',
    iata: 'BOJ',
    icao: 'LBBG',
    latitude: 42.5695991516113,
    longitude: 27.5151996612549,
    altitude: 135,
    schengen: false
  },
  {
    id: 960,
    name: 'Gorna Oryahovitsa Airport',
    city: 'Gorna Orechovica',
    country: 'Bulgaria',
    iata: 'GOZ',
    icao: 'LBGO',
    latitude: 43.1514015197754,
    longitude: 25.7129001617432,
    altitude: 285,
    schengen: false
  },
  {
    id: 961,
    name: 'Haskovo Malevo Airport',
    city: 'Haskovo',
    country: 'Bulgaria',
    iata: '',
    icao: 'LBHS',
    latitude: 41.976398,
    longitude: 25.5898,
    altitude: 160,
    schengen: false
  },
  {
    id: 962,
    name: 'Ihtiman Airfield',
    city: 'Ihtiman',
    country: 'Bulgaria',
    iata: '',
    icao: 'LBHT',
    latitude: 42.42189,
    longitude: 23.767221,
    altitude: 2113,
    schengen: false
  },
  {
    id: 963,
    name: 'Gabrovnitsa Air Base',
    city: 'Gabrovnitsa',
    country: 'Bulgaria',
    iata: '',
    icao: 'LBMG',
    latitude: 43.5443000793457,
    longitude: 23.2724990844727,
    altitude: 626,
    schengen: false
  },
  {
    id: 964,
    name: 'Plovdiv International Airport',
    city: 'Plovdiv',
    country: 'Bulgaria',
    iata: 'PDV',
    icao: 'LBPD',
    latitude: 42.067799,
    longitude: 24.8508,
    altitude: 597,
    schengen: false
  },
  {
    id: 965,
    name: 'Graf Ignatievo Air Base',
    city: 'Graf Ignatievo',
    country: 'Bulgaria',
    iata: '',
    icao: 'LBPG',
    latitude: 42.2904014587402,
    longitude: 24.7140007019043,
    altitude: 190,
    schengen: false
  },
  {
    id: 966,
    name: 'Dolna Mitropoliya Air Base',
    city: 'Dolna Mitropoliya',
    country: 'Bulgaria',
    iata: '',
    icao: 'LBPL',
    latitude: 43.451401,
    longitude: 24.5028,
    altitude: 330,
    schengen: false
  },
  {
    id: 967,
    name: 'Sofia Airport',
    city: 'Sofia',
    country: 'Bulgaria',
    iata: 'SOF',
    icao: 'LBSF',
    latitude: 42.6966934204102,
    longitude: 23.4114360809326,
    altitude: 1742,
    schengen: false
  },
  {
    id: 968,
    name: 'Stara Zagora Airport',
    city: 'Stara Zagora',
    country: 'Bulgaria',
    iata: 'SZR',
    icao: 'LBSZ',
    latitude: 42.3766667,
    longitude: 25.655,
    altitude: 558,
    schengen: false
  },
  {
    id: 969,
    name: 'Balchik Air Base',
    city: 'Balchik',
    country: 'Bulgaria',
    iata: '',
    icao: 'LBWB',
    latitude: 43.4238014221191,
    longitude: 28.1812992095947,
    altitude: 660,
    schengen: false
  },
  {
    id: 970,
    name: 'Varna Airport',
    city: 'Varna',
    country: 'Bulgaria',
    iata: 'VAR',
    icao: 'LBWN',
    latitude: 43.232101,
    longitude: 27.8251,
    altitude: 230,
    schengen: false
  },
  {
    id: 971,
    name: 'Ouagadougou Airport',
    city: 'Ouagadougou',
    country: 'Burkina Faso',
    iata: 'OUA',
    icao: 'DFFD',
    latitude: 12.3531999588013,
    longitude: -1.51242005825043,
    altitude: 1037,
    schengen: false
  },
  {
    id: 972,
    name: 'Bobo Dioulasso Airport',
    city: 'Bobo-dioulasso',
    country: 'Burkina Faso',
    iata: 'BOY',
    icao: 'DFOO',
    latitude: 11.1600999832153,
    longitude: -4.33096981048584,
    altitude: 1511,
    schengen: false
  },
  {
    id: 973,
    name: 'Ann Airport',
    city: 'Ann',
    country: 'Burma',
    iata: 'VBA',
    icao: 'VYAN',
    latitude: 19.769199,
    longitude: 94.0261,
    altitude: 74,
    schengen: false
  },
  {
    id: 974,
    name: 'Anisakan Airport',
    city: 'Anisakan',
    country: 'Burma',
    iata: '',
    icao: 'VYAS',
    latitude: 21.9554004669189,
    longitude: 96.4060974121094,
    altitude: 3000,
    schengen: false
  },
  {
    id: 975,
    name: 'Bagan Airport',
    city: 'Bagan',
    country: 'Burma',
    iata: 'NYU',
    icao: 'VYBG',
    latitude: 21.1788005828857,
    longitude: 94.9301986694336,
    altitude: 312,
    schengen: false
  },
  {
    id: 976,
    name: 'Banmaw Airport',
    city: 'Banmaw',
    country: 'Burma',
    iata: 'BMO',
    icao: 'VYBM',
    latitude: 24.2689990997314,
    longitude: 97.2462005615234,
    altitude: 370,
    schengen: false
  },
  {
    id: 977,
    name: 'Bokpyinn Airport',
    city: 'Bokepyin',
    country: 'Burma',
    iata: 'VBP',
    icao: 'VYBP',
    latitude: 11.1494,
    longitude: 98.735901,
    altitude: 100,
    schengen: false
  },
  {
    id: 978,
    name: 'Coco Island Airport',
    city: 'Coco Island',
    country: 'Burma',
    iata: '',
    icao: 'VYCI',
    latitude: 14.1415004730225,
    longitude: 93.3684997558594,
    altitude: 20,
    schengen: false
  },
  {
    id: 979,
    name: 'Dawei Airport',
    city: 'Dawei',
    country: 'Burma',
    iata: 'TVY',
    icao: 'VYDW',
    latitude: 14.1038999557495,
    longitude: 98.2035980224609,
    altitude: 84,
    schengen: false
  },
  {
    id: 980,
    name: 'Naypyidaw Airport',
    city: 'Naypyidaw',
    country: 'Burma',
    iata: 'NYT',
    icao: 'VYEL',
    latitude: 19.623501,
    longitude: 96.200996,
    altitude: 302,
    schengen: false
  },
  {
    id: 981,
    name: 'Heho Airport',
    city: 'Heho',
    country: 'Burma',
    iata: 'HEH',
    icao: 'VYHH',
    latitude: 20.7469997406006,
    longitude: 96.7919998168945,
    altitude: 3858,
    schengen: false
  },
  {
    id: 982,
    name: 'Hommalinn Airport',
    city: 'Hommalin',
    country: 'Burma',
    iata: 'HOX',
    icao: 'VYHL',
    latitude: 24.8995990753174,
    longitude: 94.9140014648438,
    altitude: 534,
    schengen: false
  },
  {
    id: 983,
    name: 'Kengtung Airport',
    city: 'Kengtung',
    country: 'Burma',
    iata: 'KET',
    icao: 'VYKG',
    latitude: 21.3015995025635,
    longitude: 99.6360015869141,
    altitude: 2798,
    schengen: false
  },
  {
    id: 984,
    name: 'Kanti Airport',
    city: 'Khamti',
    country: 'Burma',
    iata: 'KHM',
    icao: 'VYKI',
    latitude: 25.9883003234863,
    longitude: 95.6744003295898,
    altitude: 6000,
    schengen: false
  },
  {
    id: 985,
    name: 'Kyaukpyu Airport',
    city: 'Kyaukpyu',
    country: 'Burma',
    iata: 'KYP',
    icao: 'VYKP',
    latitude: 19.426399230957,
    longitude: 93.534797668457,
    altitude: 20,
    schengen: false
  },
  {
    id: 986,
    name: 'Kawthoung Airport',
    city: 'Kawthoung',
    country: 'Burma',
    iata: 'KAW',
    icao: 'VYKT',
    latitude: 10.0493001937866,
    longitude: 98.5380020141602,
    altitude: 180,
    schengen: false
  },
  {
    id: 987,
    name: 'Loikaw Airport',
    city: 'Loikaw',
    country: 'Burma',
    iata: 'LIW',
    icao: 'VYLK',
    latitude: 19.691499710083,
    longitude: 97.2147979736328,
    altitude: 2940,
    schengen: false
  },
  {
    id: 988,
    name: 'Lashio Airport',
    city: 'Lashio',
    country: 'Burma',
    iata: 'LSH',
    icao: 'VYLS',
    latitude: 22.9778995513916,
    longitude: 97.752197265625,
    altitude: 2450,
    schengen: false
  },
  {
    id: 989,
    name: 'Lanywa Airport',
    city: 'Lanywa',
    country: 'Burma',
    iata: '',
    icao: 'VYLY',
    latitude: 20.9403991699219,
    longitude: 94.8226013183594,
    altitude: 175,
    schengen: false
  },
  {
    id: 990,
    name: 'Mandalay International Airport',
    city: 'Mandalay',
    country: 'Burma',
    iata: 'MDL',
    icao: 'VYMD',
    latitude: 21.7021999359131,
    longitude: 95.977897644043,
    altitude: 300,
    schengen: false
  },
  {
    id: 991,
    name: 'Myeik Airport',
    city: 'Myeik',
    country: 'Burma',
    iata: 'MGZ',
    icao: 'VYME',
    latitude: 12.4398002624512,
    longitude: 98.6214981079102,
    altitude: 75,
    schengen: false
  },
  {
    id: 992,
    name: 'Myitkyina Airport',
    city: 'Myitkyina',
    country: 'Burma',
    iata: 'MYT',
    icao: 'VYMK',
    latitude: 25.3836002349853,
    longitude: 97.3518981933594,
    altitude: 475,
    schengen: false
  },
  {
    id: 993,
    name: 'Meiktila Air Base',
    city: 'Meiktila',
    country: 'Burma',
    iata: '',
    icao: 'VYML',
    latitude: 20.8864002227783,
    longitude: 95.8927917480469,
    altitude: 699,
    schengen: false
  },
  {
    id: 994,
    name: 'Mawlamyine Airport',
    city: 'Mawlamyine',
    country: 'Burma',
    iata: 'MNU',
    icao: 'VYMM',
    latitude: 16.4447002410889,
    longitude: 97.6606979370117,
    altitude: 52,
    schengen: false
  },
  {
    id: 995,
    name: 'Momeik Airport',
    city: 'Momeik',
    country: 'Burma',
    iata: 'MOE',
    icao: 'VYMO',
    latitude: 23.0925006866455,
    longitude: 96.6453018188477,
    altitude: 600,
    schengen: false
  },
  {
    id: 996,
    name: 'Mong Hsat Airport',
    city: 'Mong Hsat',
    country: 'Burma',
    iata: 'MOG',
    icao: 'VYMS',
    latitude: 20.5167999267578,
    longitude: 99.2567977905273,
    altitude: 1875,
    schengen: false
  },
  {
    id: 997,
    name: 'Magway Airport',
    city: 'Magwe',
    country: 'Burma',
    iata: 'MWQ',
    icao: 'VYMW',
    latitude: 20.1656,
    longitude: 94.941399,
    altitude: 279,
    schengen: false
  },
  {
    id: 998,
    name: 'Monywar Airport',
    city: 'Monywa',
    country: 'Burma',
    iata: 'NYW',
    icao: 'VYMY',
    latitude: 22.221638,
    longitude: 95.093479,
    altitude: 298,
    schengen: false
  },
  {
    id: 999,
    name: 'Nampong Air Base',
    city: 'Nampong',
    country: 'Burma',
    iata: '',
    icao: 'VYNP',
    latitude: 25.3544006348,
    longitude: 97.2951965332,
    altitude: 459,
    schengen: false
  },
  {
    id: 1000,
    name: 'Namsang Airport',
    city: 'Namsang',
    country: 'Burma',
    iata: 'NMS',
    icao: 'VYNS',
    latitude: 20.8904991149902,
    longitude: 97.7359008789063,
    altitude: 3100,
    schengen: false
  },
  {
    id: 1001,
    name: 'Namtu Airport',
    city: 'Naypyidaw',
    country: 'Burma',
    iata: 'NMT',
    icao: 'VYNT',
    latitude: 23.083,
    longitude: 97.383003,
    altitude: 2000,
    schengen: false
  },
  {
    id: 1002,
    name: 'Hpa-N Airport',
    city: 'Hpa-an',
    country: 'Burma',
    iata: 'PAA',
    icao: 'VYPA',
    latitude: 16.8936996459961,
    longitude: 97.6745986938477,
    altitude: 150,
    schengen: false
  },
  {
    id: 1003,
    name: 'Pathein Airport',
    city: 'Pathein',
    country: 'Burma',
    iata: 'BSX',
    icao: 'VYPN',
    latitude: 16.815201,
    longitude: 94.7799,
    altitude: 20,
    schengen: false
  },
  {
    id: 1004,
    name: 'Putao Airport',
    city: 'Putao',
    country: 'Burma',
    iata: 'PBU',
    icao: 'VYPT',
    latitude: 27.3299007415771,
    longitude: 97.4263000488281,
    altitude: 1500,
    schengen: false
  },
  {
    id: 1005,
    name: 'Pakhokku Airport',
    city: 'Pakhokku',
    country: 'Burma',
    iata: 'PKK',
    icao: 'VYPU',
    latitude: 21.4043,
    longitude: 95.11125,
    altitude: 151,
    schengen: false
  },
  {
    id: 1006,
    name: 'Pyay Airport',
    city: 'Pyay',
    country: 'Burma',
    iata: 'PRU',
    icao: 'VYPY',
    latitude: 18.824499130249,
    longitude: 95.265998840332,
    altitude: 120,
    schengen: false
  },
  {
    id: 1007,
    name: 'Shante Air Base',
    city: 'Shante',
    country: 'Burma',
    iata: '',
    icao: 'VYST',
    latitude: 20.9416999816895,
    longitude: 95.9144973754883,
    altitude: 630,
    schengen: false
  },
  {
    id: 1008,
    name: 'Sittwe Airport',
    city: 'Sittwe',
    country: 'Burma',
    iata: 'AKY',
    icao: 'VYSW',
    latitude: 20.1326999664307,
    longitude: 92.8725967407227,
    altitude: 27,
    schengen: false
  },
  {
    id: 1009,
    name: 'Thandwe Airport',
    city: 'Thandwe',
    country: 'Burma',
    iata: 'SNW',
    icao: 'VYTD',
    latitude: 18.4606990814209,
    longitude: 94.3001022338867,
    altitude: 20,
    schengen: false
  },
  {
    id: 1010,
    name: 'Tachileik Airport',
    city: 'Tachilek',
    country: 'Burma',
    iata: 'THL',
    icao: 'VYTL',
    latitude: 20.4838008880615,
    longitude: 99.9354019165039,
    altitude: 1280,
    schengen: false
  },
  {
    id: 1011,
    name: 'Taungoo Airport',
    city: 'Taungoo',
    country: 'Burma',
    iata: '',
    icao: 'VYTO',
    latitude: 19.0312995910645,
    longitude: 96.4011993408203,
    altitude: 160,
    schengen: false
  },
  {
    id: 1012,
    name: 'Kyaukhtu South Airport',
    city: 'Kyauktu',
    country: 'Burma',
    iata: '',
    icao: 'VYXG',
    latitude: 21.4067001342773,
    longitude: 94.1303024291992,
    altitude: 1345,
    schengen: false
  },
  {
    id: 1013,
    name: 'Ye Airport',
    city: 'Ye',
    country: 'Burma',
    iata: 'XYE',
    icao: 'VYYE',
    latitude: 15.3000001907349,
    longitude: 97.8669967651367,
    altitude: 30,
    schengen: false
  },
  {
    id: 1014,
    name: 'Yangon International Airport',
    city: 'Yangon',
    country: 'Burma',
    iata: 'RGN',
    icao: 'VYYY',
    latitude: 16.9073009491,
    longitude: 96.1332015991,
    altitude: 109,
    schengen: false
  },
  {
    id: 1015,
    name: 'Qinhuangdao Beidaihe Airport',
    city: 'Bagan',
    country: 'Burma',
    iata: 'BPE',
    icao: 'ZBDH',
    latitude: 39.666389,
    longitude: 119.058889,
    altitude: 46,
    schengen: false
  },
  {
    id: 1016,
    name: 'Bujumbura International Airport',
    city: 'Bujumbura',
    country: 'Burundi',
    iata: 'BJM',
    icao: 'HBBA',
    latitude: -3.32401990890503,
    longitude: 29.3185005187988,
    altitude: 2582,
    schengen: false
  },
  {
    id: 1017,
    name: 'Fazenda Campo Verde Airport',
    city: 'Sihanoukville',
    country: 'Cambodia',
    iata: '',
    icao: 'SNKV',
    latitude: 1.04278004169,
    longitude: -50.5167007446,
    altitude: 49,
    schengen: false
  },
  {
    id: 1018,
    name: 'Battambang Airport',
    city: 'Battambang',
    country: 'Cambodia',
    iata: 'BBM',
    icao: 'VDBG',
    latitude: 13.0956001281738,
    longitude: 103.223999023438,
    altitude: 59,
    schengen: false
  },
  {
    id: 1019,
    name: 'Kampong Chhnang Airport',
    city: 'Kompong Chnang',
    country: 'Cambodia',
    iata: 'KZC',
    icao: 'VDKH',
    latitude: 12.255200386,
    longitude: 104.564002991,
    altitude: 56,
    schengen: false
  },
  {
    id: 1020,
    name: 'Kratie Airport',
    city: 'Kratie',
    country: 'Cambodia',
    iata: 'KTI',
    icao: 'VDKT',
    latitude: 12.4879999160767,
    longitude: 106.055000305176,
    altitude: 0,
    schengen: false
  },
  {
    id: 1021,
    name: 'Phnom Penh International Airport',
    city: 'Phnom-penh',
    country: 'Cambodia',
    iata: 'PNH',
    icao: 'VDPP',
    latitude: 11.5466003417969,
    longitude: 104.84400177002,
    altitude: 40,
    schengen: false
  },
  {
    id: 1022,
    name: 'Ratanakiri Airport',
    city: 'Ratanakiri',
    country: 'Cambodia',
    iata: 'RBE',
    icao: 'VDRK',
    latitude: 13.7299995422363,
    longitude: 106.986999511719,
    altitude: 0,
    schengen: false
  },
  {
    id: 1023,
    name: 'Siem Reap International Airport',
    city: 'Siem-reap',
    country: 'Cambodia',
    iata: 'REP',
    icao: 'VDSR',
    latitude: 13.4106998444,
    longitude: 103.81300354,
    altitude: 60,
    schengen: false
  },
  {
    id: 1024,
    name: 'Stung Treng Airport',
    city: 'Stung Treng',
    country: 'Cambodia',
    iata: 'TNX',
    icao: 'VDST',
    latitude: 13.5319004058838,
    longitude: 106.014999389648,
    altitude: 203,
    schengen: false
  },
  {
    id: 1025,
    name: 'Sihanoukville International Airport',
    city: 'Sihanoukville',
    country: 'Cambodia',
    iata: 'KOS',
    icao: 'VDSV',
    latitude: 10.57970047,
    longitude: 103.637001038,
    altitude: 33,
    schengen: false
  },
  {
    id: 1026,
    name: 'Tiko Airport',
    city: 'Tiko',
    country: 'Cameroon',
    iata: 'TKC',
    icao: 'FKKC',
    latitude: 4.08919000626,
    longitude: 9.3605298996,
    altitude: 151,
    schengen: false
  },
  {
    id: 1027,
    name: 'Douala International Airport',
    city: 'Douala',
    country: 'Cameroon',
    iata: 'DLA',
    icao: 'FKKD',
    latitude: 4.0060801506,
    longitude: 9.71947956085,
    altitude: 33,
    schengen: false
  },
  {
    id: 1028,
    name: 'Salak Airport',
    city: 'Maroua',
    country: 'Cameroon',
    iata: 'MVR',
    icao: 'FKKL',
    latitude: 10.4513998031616,
    longitude: 14.257399559021,
    altitude: 1390,
    schengen: false
  },
  {
    id: 1029,
    name: 'Foumban Nkounja Airport',
    city: 'Foumban',
    country: 'Cameroon',
    iata: 'FOM',
    icao: 'FKKM',
    latitude: 5.63691997528076,
    longitude: 10.7508001327515,
    altitude: 3963,
    schengen: false
  },
  {
    id: 1030,
    name: "N'Gaoundéré Airport",
    city: "N'gaoundere",
    country: 'Cameroon',
    iata: 'NGE',
    icao: 'FKKN',
    latitude: 7.35700988769531,
    longitude: 13.5592002868652,
    altitude: 3655,
    schengen: false
  },
  {
    id: 1031,
    name: 'Garoua International Airport',
    city: 'Garoua',
    country: 'Cameroon',
    iata: 'GOU',
    icao: 'FKKR',
    latitude: 9.33588981628418,
    longitude: 13.3701000213623,
    altitude: 794,
    schengen: false
  },
  {
    id: 1032,
    name: 'Bafoussam Airport',
    city: 'Bafoussam',
    country: 'Cameroon',
    iata: 'BFX',
    icao: 'FKKU',
    latitude: 5.53692007065,
    longitude: 10.3545999527,
    altitude: 4347,
    schengen: false
  },
  {
    id: 1033,
    name: 'Bamenda Airport',
    city: 'Bamenda',
    country: 'Cameroon',
    iata: 'BPC',
    icao: 'FKKV',
    latitude: 6.03923988342285,
    longitude: 10.1225996017456,
    altitude: 4065,
    schengen: false
  },
  {
    id: 1034,
    name: 'Yaoundé Airport',
    city: 'Yaounde',
    country: 'Cameroon',
    iata: 'YAO',
    icao: 'FKKY',
    latitude: 3.83604001998901,
    longitude: 11.5235004425049,
    altitude: 2464,
    schengen: false
  },
  {
    id: 1035,
    name: 'Yaoundé Nsimalen International Airport',
    city: 'Yaounde',
    country: 'Cameroon',
    iata: 'NSI',
    icao: 'FKYS',
    latitude: 3.72255992889404,
    longitude: 11.5532999038696,
    altitude: 2278,
    schengen: false
  },
  {
    id: 1036,
    name: 'Bedwell Harbour Seaplane Base',
    city: 'Bedwell Harbour',
    country: 'Canada',
    iata: 'YBW',
    icao: 'CAB3',
    latitude: 48.75,
    longitude: -123.233001709,
    altitude: 0,
    schengen: false
  },
  {
    id: 1037,
    name: 'Nanaimo Harbour Water Airport',
    city: 'Nanaimo',
    country: 'Canada',
    iata: 'ZNA',
    icao: 'CAC8',
    latitude: 49.1833000183,
    longitude: -123.949996948,
    altitude: 0,
    schengen: false
  },
  {
    id: 1038,
    name: 'Trail Airport',
    city: 'Trail',
    country: 'Canada',
    iata: 'YZZ',
    icao: 'CAD4',
    latitude: 49.0555992126,
    longitude: -117.60900116,
    altitude: 1427,
    schengen: false
  },
  {
    id: 1039,
    name: 'Whistler/Green Lake Water Aerodrome',
    city: 'Whistler',
    country: 'Canada',
    iata: 'YWS',
    icao: 'CAE5',
    latitude: 50.1436004639,
    longitude: -122.948997498,
    altitude: 2100,
    schengen: false
  },
  {
    id: 1040,
    name: 'Anahim Lake Airport',
    city: 'Anahim Lake',
    country: 'Canada',
    iata: 'YAA',
    icao: 'CAJ4',
    latitude: 52.4524993896484,
    longitude: -125.303001403809,
    altitude: 3635,
    schengen: false
  },
  {
    id: 1041,
    name: 'Vancouver International Seaplane Base',
    city: 'Vancouver',
    country: 'Canada',
    iata: '',
    icao: 'CAM9',
    latitude: 49.177047,
    longitude: -123.168154,
    altitude: 0,
    schengen: false
  },
  {
    id: 1042,
    name: 'Victoria Airport',
    city: 'Patricia Bay',
    country: 'Canada',
    iata: '',
    icao: 'CAP5',
    latitude: 48.6538920506,
    longitude: -123.450450897,
    altitude: 0,
    schengen: false
  },
  {
    id: 1043,
    name: 'Coal Harbour Seaplane Base',
    city: 'Vancouver',
    country: 'Canada',
    iata: '',
    icao: 'CAQ3',
    latitude: 50.5999984741,
    longitude: -127.583000183,
    altitude: 0,
    schengen: false
  },
  {
    id: 1044,
    name: 'One Hundred Mile House Airport',
    city: 'One Hundred Mile House',
    country: 'Canada',
    iata: '',
    icao: 'CAV3',
    latitude: 51.6425018310547,
    longitude: -121.306999206543,
    altitude: 3055,
    schengen: false
  },
  {
    id: 1045,
    name: 'Port Hardy Seaplane Base',
    city: 'Port Hardy',
    country: 'Canada',
    iata: '',
    icao: 'CAW5',
    latitude: 50.7167015075684,
    longitude: -127.483001708984,
    altitude: 0,
    schengen: false
  },
  {
    id: 1046,
    name: 'Ganges Seaplane Base',
    city: 'Ganges',
    country: 'Canada',
    iata: 'YGG',
    icao: 'CAX6',
    latitude: 48.8545,
    longitude: -123.4969,
    altitude: 0,
    schengen: false
  },
  {
    id: 1047,
    name: 'Ospika Airport',
    city: 'Williston Lake',
    country: 'Canada',
    iata: '',
    icao: 'CBA9',
    latitude: 56.2750015258789,
    longitude: -124.052001953125,
    altitude: 2300,
    schengen: false
  },
  {
    id: 1048,
    name: 'Harbour (Public) Heliport',
    city: 'Vancouver',
    country: 'Canada',
    iata: '',
    icao: 'CBC7',
    latitude: 49.2868921105,
    longitude: -123.106112257,
    altitude: 2,
    schengen: false
  },
  {
    id: 1049,
    name: 'Victoria Harbour (Camel Point) Heliport',
    city: 'Victoria',
    country: 'Canada',
    iata: '',
    icao: 'CBF7',
    latitude: 48.418098449707,
    longitude: -123.388000488281,
    altitude: 15,
    schengen: false
  },
  {
    id: 1050,
    name: 'Bob Quinn Lake Airport',
    city: 'Bob Quinn Lake',
    country: 'Canada',
    iata: 'YBO',
    icao: 'CBW4',
    latitude: 56.9667015076,
    longitude: -130.25,
    altitude: 2000,
    schengen: false
  },
  {
    id: 1051,
    name: 'Williams Harbour Airport',
    city: 'Williams Harbour',
    country: 'Canada',
    iata: 'YWM',
    icao: 'CCA6',
    latitude: 52.5668983459473,
    longitude: -55.7846984863281,
    altitude: 70,
    schengen: false
  },
  {
    id: 1052,
    name: 'Postville Airport',
    city: 'Postville',
    country: 'Canada',
    iata: 'YSO',
    icao: 'CCD4',
    latitude: 54.9105,
    longitude: -59.78507,
    altitude: 193,
    schengen: false
  },
  {
    id: 1053,
    name: 'Black Tickle Airport',
    city: 'Black Tickle',
    country: 'Canada',
    iata: 'YBI',
    icao: 'CCE4',
    latitude: 53.4693984985,
    longitude: -55.7849998474,
    altitude: 57,
    schengen: false
  },
  {
    id: 1054,
    name: 'Charlottetown Airport',
    city: 'Charlottetown',
    country: 'Canada',
    iata: '',
    icao: 'CCH4',
    latitude: 52.7649993896484,
    longitude: -56.1156005859375,
    altitude: 209,
    schengen: false
  },
  {
    id: 1055,
    name: 'St. Lewis (Fox Harbour) Airport',
    city: 'St. Lewis',
    country: 'Canada',
    iata: 'YFX',
    icao: 'CCK4',
    latitude: 52.3727989196777,
    longitude: -55.673900604248,
    altitude: 74,
    schengen: false
  },
  {
    id: 1056,
    name: 'Port Hope Simpson Airport',
    city: 'Port Hope Simpson',
    country: 'Canada',
    iata: 'YHA',
    icao: 'CCP4',
    latitude: 52.5280990600586,
    longitude: -56.2860984802246,
    altitude: 347,
    schengen: false
  },
  {
    id: 1057,
    name: 'Waterville / Kings County Municipal Airport',
    city: 'Waterville',
    country: 'Canada',
    iata: '',
    icao: 'CCW3',
    latitude: 45.0518989563,
    longitude: -64.6517028809,
    altitude: 119,
    schengen: false
  },
  {
    id: 1058,
    name: 'Rigolet Airport',
    city: 'Rigolet',
    country: 'Canada',
    iata: 'YRG',
    icao: 'CCZ2',
    latitude: 54.1796989440918,
    longitude: -58.4575004577637,
    altitude: 180,
    schengen: false
  },
  {
    id: 1059,
    name: 'Clarenville Airport',
    city: 'Clarenville',
    country: 'Canada',
    iata: '',
    icao: 'CCZ3',
    latitude: 48.2747001647949,
    longitude: -53.923900604248,
    altitude: 199,
    schengen: false
  },
  {
    id: 1060,
    name: 'Doris Lake',
    city: 'Doris Lake',
    country: 'Canada',
    iata: 'JOJ',
    icao: 'CDL7',
    latitude: 68.1252746582,
    longitude: -106.585281372,
    altitude: 50,
    schengen: false
  },
  {
    id: 1061,
    name: 'Dunnville Airport',
    city: 'Dunnville',
    country: 'Canada',
    iata: 'DU9',
    icao: 'CDU9',
    latitude: 42.8722000122,
    longitude: -79.5958023071,
    altitude: 600,
    schengen: false
  },
  {
    id: 1062,
    name: 'Colville Lake Airport',
    city: 'Colville Lake',
    country: 'Canada',
    iata: 'YCK',
    icao: 'CEB3',
    latitude: 67.0392,
    longitude: -126.08,
    altitude: 850,
    schengen: false
  },
  {
    id: 1063,
    name: 'Hinton/Jasper-Hinton Airport',
    city: 'Hinton',
    country: 'Canada',
    iata: 'YJP',
    icao: 'CEC4',
    latitude: 53.3191986084,
    longitude: -117.752998352,
    altitude: 4006,
    schengen: false
  },
  {
    id: 1064,
    name: 'Drumheller Municipal Airport',
    city: 'Drumheller',
    country: 'Canada',
    iata: '',
    icao: 'CEG4',
    latitude: 51.4963989257813,
    longitude: -112.749000549316,
    altitude: 2597,
    schengen: false
  },
  {
    id: 1065,
    name: 'Whatì Airport',
    city: 'Whatì',
    country: 'Canada',
    iata: 'YLE',
    icao: 'CEM3',
    latitude: 63.1316986083984,
    longitude: -117.246002197266,
    altitude: 882,
    schengen: false
  },
  {
    id: 1066,
    name: 'Canmore Municipal Heliport',
    city: 'Canmore',
    country: 'Canada',
    iata: '',
    icao: 'CEW9',
    latitude: 51.0778007507,
    longitude: -115.337997437,
    altitude: 4296,
    schengen: false
  },
  {
    id: 1067,
    name: 'Wekweètì Airport',
    city: 'Wekweeti',
    country: 'Canada',
    iata: 'YFJ',
    icao: 'CFJ2',
    latitude: 64.190804,
    longitude: -114.077002,
    altitude: 1208,
    schengen: false
  },
  {
    id: 1068,
    name: 'Kenora Seaplane Base',
    city: 'Kenora',
    country: 'Canada',
    iata: '',
    icao: 'CJM9',
    latitude: 49.767424,
    longitude: -94.49274,
    altitude: 1060,
    schengen: false
  },
  {
    id: 1069,
    name: 'Matheson Island Airport',
    city: 'Matheson Island',
    country: 'Canada',
    iata: '',
    icao: 'CJT2',
    latitude: 51.7322006225586,
    longitude: -96.9344024658203,
    altitude: 725,
    schengen: false
  },
  {
    id: 1070,
    name: 'Summer Beaver Airport',
    city: 'Summer Beaver',
    country: 'Canada',
    iata: 'SUR',
    icao: 'CJV7',
    latitude: 52.7085990905762,
    longitude: -88.5419006347656,
    altitude: 832,
    schengen: false
  },
  {
    id: 1071,
    name: 'Old Arctic Bay Airport',
    city: 'Arctic Bay',
    country: 'Canada',
    iata: 'YAB',
    icao: 'CJX7',
    latitude: 73.0058922479,
    longitude: -85.0325489044,
    altitude: 100,
    schengen: false
  },
  {
    id: 1072,
    name: 'Wapekeka Airport',
    city: 'Angling Lake',
    country: 'Canada',
    iata: 'YAX',
    icao: 'CKB6',
    latitude: 53.8492012023926,
    longitude: -89.5793991088867,
    altitude: 712,
    schengen: false
  },
  {
    id: 1073,
    name: 'Slate Falls Airport',
    city: 'Slate Falls',
    country: 'Canada',
    iata: '',
    icao: 'CKD9',
    latitude: 51.1300010681152,
    longitude: -91.6656036376953,
    altitude: 1355,
    schengen: false
  },
  {
    id: 1074,
    name: 'Wunnumin Lake Airport',
    city: 'Wunnumin Lake',
    country: 'Canada',
    iata: 'WNN',
    icao: 'CKL3',
    latitude: 52.8939018249512,
    longitude: -89.2891998291016,
    altitude: 819,
    schengen: false
  },
  {
    id: 1075,
    name: 'Opapimiskan Lake Airport',
    city: 'Musselwhite Mine',
    country: 'Canada',
    iata: 'YBS',
    icao: 'CKM8',
    latitude: 52.6067008972,
    longitude: -90.3768997192,
    altitude: 1023,
    schengen: false
  },
  {
    id: 1076,
    name: 'North Spirit Lake Airport',
    city: 'North Spirit Lake',
    country: 'Canada',
    iata: 'YNO',
    icao: 'CKQ3',
    latitude: 52.4900016784668,
    longitude: -92.9710998535156,
    altitude: 1082,
    schengen: false
  },
  {
    id: 1077,
    name: 'Holland Landing Airpark',
    city: 'Holland Landing',
    country: 'Canada',
    iata: '',
    icao: 'CLA4',
    latitude: 44.0894012451172,
    longitude: -79.495002746582,
    altitude: 855,
    schengen: false
  },
  {
    id: 1078,
    name: 'Brampton Airport',
    city: 'Brampton',
    country: 'Canada',
    iata: '',
    icao: 'CNC3',
    latitude: 43.7602996826,
    longitude: -79.875,
    altitude: 935,
    schengen: false
  },
  {
    id: 1079,
    name: 'Stanhope Municipal Airport',
    city: 'Haliburton',
    country: 'Canada',
    iata: '',
    icao: 'CND4',
    latitude: 45.1108333333,
    longitude: -78.64,
    altitude: 1066,
    schengen: false
  },
  {
    id: 1080,
    name: 'Bearskin Lake Airport',
    city: 'Bearskin Lake',
    country: 'Canada',
    iata: 'XBE',
    icao: 'CNE3',
    latitude: 53.9655990600586,
    longitude: -91.0271987915039,
    altitude: 800,
    schengen: false
  },
  {
    id: 1081,
    name: 'Kawartha Lakes (Lindsay) Airport',
    city: 'Lindsay',
    country: 'Canada',
    iata: '',
    icao: 'CNF4',
    latitude: 44.3647,
    longitude: -78.783897,
    altitude: 882,
    schengen: false
  },
  {
    id: 1082,
    name: 'Natuashish Airport',
    city: 'Natuashish',
    country: 'Canada',
    iata: 'YNP',
    icao: 'CNH2',
    latitude: 55.913898,
    longitude: -61.184399,
    altitude: 30,
    schengen: false
  },
  {
    id: 1083,
    name: 'Orillia Airport',
    city: 'Orillia',
    country: 'Canada',
    iata: '',
    icao: 'CNJ4',
    latitude: 44.6776557839,
    longitude: -79.3102169037,
    altitude: 725,
    schengen: false
  },
  {
    id: 1084,
    name: 'Parry Sound Area Municipal Airport',
    city: 'Parry Sound',
    country: 'Canada',
    iata: 'YPD',
    icao: 'CNK4',
    latitude: 45.2575,
    longitude: -79.829697,
    altitude: 832,
    schengen: false
  },
  {
    id: 1085,
    name: 'Kingfisher Lake Airport',
    city: 'Kingfisher Lake',
    country: 'Canada',
    iata: 'KIF',
    icao: 'CNM5',
    latitude: 53.0125007629395,
    longitude: -89.8553009033203,
    altitude: 866,
    schengen: false
  },
  {
    id: 1086,
    name: 'Ogoki Post Airport',
    city: 'Ogoki Post',
    country: 'Canada',
    iata: 'YOG',
    icao: 'CNT3',
    latitude: 51.6585998535,
    longitude: -85.9017028809,
    altitude: 594,
    schengen: false
  },
  {
    id: 1087,
    name: 'Markham Airport',
    city: 'Markham',
    country: 'Canada',
    iata: '',
    icao: 'CNU8',
    latitude: 43.9357986450195,
    longitude: -79.2621994018555,
    altitude: 807,
    schengen: false
  },
  {
    id: 1088,
    name: 'Edenvale Aerodrome',
    city: 'Edenvale',
    country: 'Canada',
    iata: '',
    icao: 'CNV8',
    latitude: 44.441101,
    longitude: -79.962799,
    altitude: 718,
    schengen: false
  },
  {
    id: 1089,
    name: 'Collingwood Airport',
    city: 'Collingwood',
    country: 'Canada',
    iata: '',
    icao: 'CNY3',
    latitude: 44.4491996765,
    longitude: -80.1583023071,
    altitude: 730,
    schengen: false
  },
  {
    id: 1090,
    name: 'Poplar Hill Airport',
    city: 'Poplar Hill',
    country: 'Canada',
    iata: 'YHP',
    icao: 'CPV7',
    latitude: 52.1133,
    longitude: -94.2556,
    altitude: 1095,
    schengen: false
  },
  {
    id: 1091,
    name: 'Keewaywin Airport',
    city: 'Keewaywin',
    country: 'Canada',
    iata: 'KEW',
    icao: 'CPV8',
    latitude: 52.9911003113,
    longitude: -92.8364028931,
    altitude: 988,
    schengen: false
  },
  {
    id: 1092,
    name: 'Chisasibi Airport',
    city: 'Chisasibi',
    country: 'Canada',
    iata: 'YKU',
    icao: 'CSU2',
    latitude: 53.8055992126465,
    longitude: -78.9169006347656,
    altitude: 43,
    schengen: false
  },
  {
    id: 1093,
    name: 'Tête-à-la-Baleine Airport',
    city: 'Tête-à-la-Baleine',
    country: 'Canada',
    iata: 'ZTB',
    icao: 'CTB6',
    latitude: 50.6744003295898,
    longitude: -59.3835983276367,
    altitude: 107,
    schengen: false
  },
  {
    id: 1094,
    name: 'Kegaska Airport',
    city: 'Kegaska',
    country: 'Canada',
    iata: 'ZKG',
    icao: 'CTK6',
    latitude: 50.1958007812,
    longitude: -61.2658004761,
    altitude: 32,
    schengen: false
  },
  {
    id: 1095,
    name: 'La Romaine Airport',
    city: 'La Romaine',
    country: 'Canada',
    iata: 'ZGS',
    icao: 'CTT5',
    latitude: 50.259701,
    longitude: -60.679401,
    altitude: 90,
    schengen: false
  },
  {
    id: 1096,
    name: 'La Tabatière Airport',
    city: 'La Tabatière',
    country: 'Canada',
    iata: 'ZLT',
    icao: 'CTU5',
    latitude: 50.8307991027832,
    longitude: -58.9756011962891,
    altitude: 102,
    schengen: false
  },
  {
    id: 1097,
    name: 'Cat Lake Airport',
    city: 'Cat Lake',
    country: 'Canada',
    iata: 'YAC',
    icao: 'CYAC',
    latitude: 51.7271995544434,
    longitude: -91.8244018554688,
    altitude: 1344,
    schengen: false
  },
  {
    id: 1098,
    name: 'Fort Frances Municipal Airport',
    city: 'Fort Frances',
    country: 'Canada',
    iata: 'YAG',
    icao: 'CYAG',
    latitude: 48.6542015075684,
    longitude: -93.439697265625,
    altitude: 1125,
    schengen: false
  },
  {
    id: 1099,
    name: 'La Grande-4 Airport',
    city: 'La Grande-4',
    country: 'Canada',
    iata: 'YAH',
    icao: 'CYAH',
    latitude: 53.754699707,
    longitude: -73.6753005981,
    altitude: 1005,
    schengen: false
  },
  {
    id: 1100,
    name: 'Alert Bay Airport',
    city: 'Alert Bay',
    country: 'Canada',
    iata: 'YAL',
    icao: 'CYAL',
    latitude: 50.5821990966797,
    longitude: -126.916000366211,
    altitude: 240,
    schengen: false
  },
  {
    id: 1101,
    name: 'Sault Ste Marie Airport',
    city: 'Sault Sainte Marie',
    country: 'Canada',
    iata: 'YAM',
    icao: 'CYAM',
    latitude: 46.4850006103516,
    longitude: -84.5093994140625,
    altitude: 630,
    schengen: false
  },
  {
    id: 1102,
    name: 'Kasabonika Airport',
    city: 'Kasabonika',
    country: 'Canada',
    iata: 'XKS',
    icao: 'CYAQ',
    latitude: 53.5247001647949,
    longitude: -88.6427993774414,
    altitude: 672,
    schengen: false
  },
  {
    id: 1103,
    name: 'Kangirsuk Airport',
    city: 'Kangirsuk',
    country: 'Canada',
    iata: 'YKG',
    icao: 'CYAS',
    latitude: 60.0271987915039,
    longitude: -69.9991989135742,
    altitude: 403,
    schengen: false
  },
  {
    id: 1104,
    name: 'Attawapiskat Airport',
    city: 'Attawapiskat',
    country: 'Canada',
    iata: 'YAT',
    icao: 'CYAT',
    latitude: 52.9275016784668,
    longitude: -82.4319000244141,
    altitude: 31,
    schengen: false
  },
  {
    id: 1105,
    name: 'Liverpool South Shore Regional Airport',
    city: 'Liverpool',
    country: 'Canada',
    iata: '',
    icao: 'CYAU',
    latitude: 44.2303009033,
    longitude: -64.8561019897,
    altitude: 321,
    schengen: false
  },
  {
    id: 1106,
    name: 'Winnipeg / St. Andrews Airport',
    city: 'Winnipeg',
    country: 'Canada',
    iata: '',
    icao: 'CYAV',
    latitude: 50.0564002991,
    longitude: -97.0325012207,
    altitude: 760,
    schengen: false
  },
  {
    id: 1107,
    name: 'Halifax / CFB Shearwater Heliport',
    city: 'Halifax',
    country: 'Canada',
    iata: '',
    icao: 'CYAW',
    latitude: 44.639702,
    longitude: -63.499401,
    altitude: 144,
    schengen: false
  },
  {
    id: 1108,
    name: 'Lac Du Bonnet Airport',
    city: 'Lac Du Bonnet',
    country: 'Canada',
    iata: '',
    icao: 'CYAX',
    latitude: 50.2943992615,
    longitude: -96.0100021362,
    altitude: 850,
    schengen: false
  },
  {
    id: 1109,
    name: 'St. Anthony Airport',
    city: 'St. Anthony',
    country: 'Canada',
    iata: 'YAY',
    icao: 'CYAY',
    latitude: 51.3918991089,
    longitude: -56.0830993652,
    altitude: 108,
    schengen: false
  },
  {
    id: 1110,
    name: 'Tofino / Long Beach Airport',
    city: 'Tofino',
    country: 'Canada',
    iata: 'YAZ',
    icao: 'CYAZ',
    latitude: 49.079833,
    longitude: -125.775583,
    altitude: 80,
    schengen: false
  },
  {
    id: 1111,
    name: 'Banff Airport',
    city: 'Banff',
    country: 'Canada',
    iata: 'YBA',
    icao: 'CYBA',
    latitude: 51.2073404699,
    longitude: -115.541861057,
    altitude: 4583,
    schengen: false
  },
  {
    id: 1112,
    name: 'Kugaaruk Airport',
    city: 'Pelly Bay',
    country: 'Canada',
    iata: 'YBB',
    icao: 'CYBB',
    latitude: 68.534401,
    longitude: -89.808098,
    altitude: 56,
    schengen: false
  },
  {
    id: 1113,
    name: 'Baie Comeau Airport',
    city: 'Baie Comeau',
    country: 'Canada',
    iata: 'YBC',
    icao: 'CYBC',
    latitude: 49.1324996948242,
    longitude: -68.2043991088867,
    altitude: 71,
    schengen: false
  },
  {
    id: 1114,
    name: 'Bella Coola Airport',
    city: 'Bella Coola',
    country: 'Canada',
    iata: 'QBC',
    icao: 'CYBD',
    latitude: 52.387501,
    longitude: -126.596001,
    altitude: 117,
    schengen: false
  },
  {
    id: 1115,
    name: 'Uranium City Airport',
    city: 'Uranium City',
    country: 'Canada',
    iata: 'YBE',
    icao: 'CYBE',
    latitude: 59.5614013671875,
    longitude: -108.481002807617,
    altitude: 1044,
    schengen: false
  },
  {
    id: 1116,
    name: 'Bonnyville Airport',
    city: 'Bonnyville',
    country: 'Canada',
    iata: 'YBY',
    icao: 'CYBF',
    latitude: 54.304199,
    longitude: -110.744003,
    altitude: 1836,
    schengen: false
  },
  {
    id: 1117,
    name: 'CFB Bagotville',
    city: 'Bagotville',
    country: 'Canada',
    iata: 'YBG',
    icao: 'CYBG',
    latitude: 48.3306007385254,
    longitude: -70.9963989257813,
    altitude: 522,
    schengen: false
  },
  {
    id: 1118,
    name: 'Baker Lake Airport',
    city: 'Baker Lake',
    country: 'Canada',
    iata: 'YBK',
    icao: 'CYBK',
    latitude: 64.2988967896,
    longitude: -96.077796936,
    altitude: 59,
    schengen: false
  },
  {
    id: 1119,
    name: 'Campbell River Airport',
    city: 'Campbell River',
    country: 'Canada',
    iata: 'YBL',
    icao: 'CYBL',
    latitude: 49.9508018493652,
    longitude: -125.271003723145,
    altitude: 346,
    schengen: false
  },
  {
    id: 1120,
    name: 'Tadoule Lake Airport',
    city: 'Tadoule Lake',
    country: 'Canada',
    iata: 'XTL',
    icao: 'CYBQ',
    latitude: 58.7061,
    longitude: -98.512199,
    altitude: 923,
    schengen: false
  },
  {
    id: 1121,
    name: 'Brandon Municipal Airport',
    city: 'Brandon',
    country: 'Canada',
    iata: 'YBR',
    icao: 'CYBR',
    latitude: 49.91,
    longitude: -99.951897,
    altitude: 1343,
    schengen: false
  },
  {
    id: 1122,
    name: 'Brochet Airport',
    city: 'Brochet',
    country: 'Canada',
    iata: 'YBT',
    icao: 'CYBT',
    latitude: 57.8894,
    longitude: -101.679001,
    altitude: 1136,
    schengen: false
  },
  {
    id: 1123,
    name: 'Nipawin Airport',
    city: 'Nipawin',
    country: 'Canada',
    iata: '',
    icao: 'CYBU',
    latitude: 53.3325004577637,
    longitude: -104.008003234863,
    altitude: 1220,
    schengen: false
  },
  {
    id: 1124,
    name: 'Berens River Airport',
    city: 'Berens River',
    country: 'Canada',
    iata: 'YBV',
    icao: 'CYBV',
    latitude: 52.358898,
    longitude: -97.018303,
    altitude: 728,
    schengen: false
  },
  {
    id: 1125,
    name: 'Calgary / Springbank Airport',
    city: 'Calgary',
    country: 'Canada',
    iata: '',
    icao: 'CYBW',
    latitude: 51.103099823,
    longitude: -114.374000549,
    altitude: 3940,
    schengen: false
  },
  {
    id: 1126,
    name: 'Lourdes de Blanc Sablon Airport',
    city: 'Lourdes-De-Blanc-Sablon',
    country: 'Canada',
    iata: 'YBX',
    icao: 'CYBX',
    latitude: 51.4435997009,
    longitude: -57.1852989197,
    altitude: 121,
    schengen: false
  },
  {
    id: 1127,
    name: 'Cartwright Airport',
    city: 'Cartwright',
    country: 'Canada',
    iata: 'YRF',
    icao: 'CYCA',
    latitude: 53.6828002929687,
    longitude: -57.0419006347656,
    altitude: 40,
    schengen: false
  },
  {
    id: 1128,
    name: 'Cambridge Bay Airport',
    city: 'Cambridge Bay',
    country: 'Canada',
    iata: 'YCB',
    icao: 'CYCB',
    latitude: 69.1081008911,
    longitude: -105.138000488,
    altitude: 90,
    schengen: false
  },
  {
    id: 1129,
    name: 'Cornwall Regional Airport',
    city: 'Cornwall',
    country: 'Canada',
    iata: 'YCC',
    icao: 'CYCC',
    latitude: 45.0928001403809,
    longitude: -74.5633010864258,
    altitude: 175,
    schengen: false
  },
  {
    id: 1130,
    name: 'Nanaimo Airport',
    city: 'Nanaimo',
    country: 'Canada',
    iata: 'YCD',
    icao: 'CYCD',
    latitude: 49.0549702249,
    longitude: -123.869862556,
    altitude: 92,
    schengen: false
  },
  {
    id: 1131,
    name: 'Centralia / James T. Field Memorial Aerodrome',
    city: 'Centralia',
    country: 'Canada',
    iata: 'YCE',
    icao: 'CYCE',
    latitude: 43.285599,
    longitude: -81.508301,
    altitude: 824,
    schengen: false
  },
  {
    id: 1132,
    name: 'Castlegar/West Kootenay Regional Airport',
    city: 'Castlegar',
    country: 'Canada',
    iata: 'YCG',
    icao: 'CYCG',
    latitude: 49.2963981628,
    longitude: -117.632003784,
    altitude: 1624,
    schengen: false
  },
  {
    id: 1133,
    name: 'Miramichi Airport',
    city: 'Chatham',
    country: 'Canada',
    iata: 'YCH',
    icao: 'CYCH',
    latitude: 47.007801,
    longitude: -65.449203,
    altitude: 108,
    schengen: false
  },
  {
    id: 1134,
    name: 'Charlo Airport',
    city: 'Charlo',
    country: 'Canada',
    iata: 'YCL',
    icao: 'CYCL',
    latitude: 47.990799,
    longitude: -66.330299,
    altitude: 132,
    schengen: false
  },
  {
    id: 1135,
    name: 'Cochrane Airport',
    city: 'Cochrane',
    country: 'Canada',
    iata: 'YCN',
    icao: 'CYCN',
    latitude: 49.105598449707,
    longitude: -81.0136032104492,
    altitude: 861,
    schengen: false
  },
  {
    id: 1136,
    name: 'Kugluktuk Airport',
    city: 'Coppermine',
    country: 'Canada',
    iata: 'YCO',
    icao: 'CYCO',
    latitude: 67.816704,
    longitude: -115.143997,
    altitude: 74,
    schengen: false
  },
  {
    id: 1137,
    name: 'Blue River Airport',
    city: 'Blue River',
    country: 'Canada',
    iata: '',
    icao: 'CYCP',
    latitude: 52.11669921875,
    longitude: -119.282997131348,
    altitude: 2240,
    schengen: false
  },
  {
    id: 1138,
    name: 'Chetwynd Airport',
    city: 'Chetwynd',
    country: 'Canada',
    iata: 'YCQ',
    icao: 'CYCQ',
    latitude: 55.687198638916,
    longitude: -121.626998901367,
    altitude: 2000,
    schengen: false
  },
  {
    id: 1139,
    name: 'Cross Lake (Charlie Sinclair Memorial) Airport',
    city: 'Cross Lake',
    country: 'Canada',
    iata: 'YCR',
    icao: 'CYCR',
    latitude: 54.6105995178223,
    longitude: -97.7608032226562,
    altitude: 709,
    schengen: false
  },
  {
    id: 1140,
    name: 'Chesterfield Inlet Airport',
    city: 'Chesterfield Inlet',
    country: 'Canada',
    iata: 'YCS',
    icao: 'CYCS',
    latitude: 63.3469009399,
    longitude: -90.7311019897,
    altitude: 32,
    schengen: false
  },
  {
    id: 1141,
    name: 'Coronation Airport',
    city: 'Coronation',
    country: 'Canada',
    iata: 'YCT',
    icao: 'CYCT',
    latitude: 52.0750007629,
    longitude: -111.444999695,
    altitude: 2595,
    schengen: false
  },
  {
    id: 1142,
    name: 'Chilliwack Airport',
    city: 'Chilliwack',
    country: 'Canada',
    iata: 'YCW',
    icao: 'CYCW',
    latitude: 49.1528015137,
    longitude: -121.939002991,
    altitude: 32,
    schengen: false
  },
  {
    id: 1143,
    name: 'Clyde River Airport',
    city: 'Clyde River',
    country: 'Canada',
    iata: 'YCY',
    icao: 'CYCY',
    latitude: 70.4860992432,
    longitude: -68.5167007446,
    altitude: 87,
    schengen: false
  },
  {
    id: 1144,
    name: 'Dawson City Airport',
    city: 'Dawson',
    country: 'Canada',
    iata: 'YDA',
    icao: 'CYDA',
    latitude: 64.043098449707,
    longitude: -139.128005981445,
    altitude: 1215,
    schengen: false
  },
  {
    id: 1145,
    name: 'Burwash Airport',
    city: 'Burwash',
    country: 'Canada',
    iata: 'YDB',
    icao: 'CYDB',
    latitude: 61.3711013793945,
    longitude: -139.041000366211,
    altitude: 2647,
    schengen: false
  },
  {
    id: 1146,
    name: 'Princeton Airport',
    city: 'Princeton',
    country: 'Canada',
    iata: '',
    icao: 'CYDC',
    latitude: 49.4681015015,
    longitude: -120.511001587,
    altitude: 2298,
    schengen: false
  },
  {
    id: 1147,
    name: 'Deer Lake Airport',
    city: 'Deer Lake',
    country: 'Canada',
    iata: 'YDF',
    icao: 'CYDF',
    latitude: 49.2108001708984,
    longitude: -57.3913993835449,
    altitude: 72,
    schengen: false
  },
  {
    id: 1148,
    name: 'Dease Lake Airport',
    city: 'Dease Lake',
    country: 'Canada',
    iata: 'YDL',
    icao: 'CYDL',
    latitude: 58.4221992493,
    longitude: -130.031997681,
    altitude: 2600,
    schengen: false
  },
  {
    id: 1149,
    name: 'Ross River Airport',
    city: 'Ross River',
    country: 'Canada',
    iata: 'XRR',
    icao: 'CYDM',
    latitude: 61.9706001282,
    longitude: -132.42300415,
    altitude: 2314,
    schengen: false
  },
  {
    id: 1150,
    name: 'Dauphin Barker Airport',
    city: 'Dauphin',
    country: 'Canada',
    iata: 'YDN',
    icao: 'CYDN',
    latitude: 51.1007995605469,
    longitude: -100.052001953125,
    altitude: 999,
    schengen: false
  },
  {
    id: 1151,
    name: 'Dolbeau St Felicien Airport',
    city: 'Dolbeau-St-Félicien',
    country: 'Canada',
    iata: 'YDO',
    icao: 'CYDO',
    latitude: 48.778499603271,
    longitude: -72.375,
    altitude: 372,
    schengen: false
  },
  {
    id: 1152,
    name: 'Nain Airport',
    city: 'Nain',
    country: 'Canada',
    iata: 'YDP',
    icao: 'CYDP',
    latitude: 56.5491981506348,
    longitude: -61.6803016662598,
    altitude: 22,
    schengen: false
  },
  {
    id: 1153,
    name: 'Dawson Creek Airport',
    city: 'Dawson Creek',
    country: 'Canada',
    iata: 'YDQ',
    icao: 'CYDQ',
    latitude: 55.7422981262207,
    longitude: -120.182998657227,
    altitude: 2148,
    schengen: false
  },
  {
    id: 1154,
    name: 'Huronia Airport',
    city: 'Midland',
    country: 'Canada',
    iata: '',
    icao: 'CYEE',
    latitude: 44.6833000183,
    longitude: -79.9282989502,
    altitude: 770,
    schengen: false
  },
  {
    id: 1155,
    name: 'Edmonton International Airport',
    city: 'Edmonton',
    country: 'Canada',
    iata: 'YEG',
    icao: 'CYEG',
    latitude: 53.3097000122,
    longitude: -113.580001831,
    altitude: 2373,
    schengen: false
  },
  {
    id: 1156,
    name: 'Arviat Airport',
    city: 'Eskimo Point',
    country: 'Canada',
    iata: 'YEK',
    icao: 'CYEK',
    latitude: 61.0942001343,
    longitude: -94.0708007812,
    altitude: 32,
    schengen: false
  },
  {
    id: 1157,
    name: 'Elliot Lake Municipal Airport',
    city: 'ELLIOT LAKE',
    country: 'Canada',
    iata: 'YEL',
    icao: 'CYEL',
    latitude: 46.351398468,
    longitude: -82.5614013672,
    altitude: 1087,
    schengen: false
  },
  {
    id: 1158,
    name: 'Manitoulin East Municipal Airport',
    city: 'Manitowaning',
    country: 'Canada',
    iata: 'YEM',
    icao: 'CYEM',
    latitude: 45.8428001403809,
    longitude: -81.8581008911133,
    altitude: 869,
    schengen: false
  },
  {
    id: 1159,
    name: 'Estevan Airport',
    city: 'Estevan',
    country: 'Canada',
    iata: 'YEN',
    icao: 'CYEN',
    latitude: 49.2103004456,
    longitude: -102.966003418,
    altitude: 1905,
    schengen: false
  },
  {
    id: 1160,
    name: 'Fort Severn Airport',
    city: 'Fort Severn',
    country: 'Canada',
    iata: 'YER',
    icao: 'CYER',
    latitude: 56.0189018249512,
    longitude: -87.6761016845703,
    altitude: 48,
    schengen: false
  },
  {
    id: 1161,
    name: 'Edmundston Airport',
    city: 'Edmundston',
    country: 'Canada',
    iata: '',
    icao: 'CYES',
    latitude: 47.4906005859375,
    longitude: -68.4819030761719,
    altitude: 490,
    schengen: false
  },
  {
    id: 1162,
    name: 'Edson Airport',
    city: 'Edson',
    country: 'Canada',
    iata: 'YET',
    icao: 'CYET',
    latitude: 53.5788993835,
    longitude: -116.464996338,
    altitude: 3043,
    schengen: false
  },
  {
    id: 1163,
    name: 'Eureka Airport',
    city: 'Eureka',
    country: 'Canada',
    iata: 'YEU',
    icao: 'CYEU',
    latitude: 79.9946975708,
    longitude: -85.814201355,
    altitude: 256,
    schengen: false
  },
  {
    id: 1164,
    name: 'Inuvik Mike Zubko Airport',
    city: 'Inuvik',
    country: 'Canada',
    iata: 'YEV',
    icao: 'CYEV',
    latitude: 68.3041992188,
    longitude: -133.483001709,
    altitude: 224,
    schengen: false
  },
  {
    id: 1165,
    name: 'Amos/Magny Airport',
    city: 'Amos',
    country: 'Canada',
    iata: 'YEY',
    icao: 'CYEY',
    latitude: 48.563903,
    longitude: -78.249702,
    altitude: 1068,
    schengen: false
  },
  {
    id: 1166,
    name: 'Fort Albany Airport',
    city: 'Fort Albany',
    country: 'Canada',
    iata: 'YFA',
    icao: 'CYFA',
    latitude: 52.2014007568359,
    longitude: -81.6968994140625,
    altitude: 48,
    schengen: false
  },
  {
    id: 1167,
    name: 'Iqaluit Airport',
    city: 'Iqaluit',
    country: 'Canada',
    iata: 'YFB',
    icao: 'CYFB',
    latitude: 63.756402,
    longitude: -68.555801,
    altitude: 110,
    schengen: false
  },
  {
    id: 1168,
    name: 'Fredericton Airport',
    city: 'Fredericton',
    country: 'Canada',
    iata: 'YFC',
    icao: 'CYFC',
    latitude: 45.8689002990723,
    longitude: -66.5372009277344,
    altitude: 68,
    schengen: false
  },
  {
    id: 1169,
    name: 'Brantford Municipal Airport',
    city: 'Brantford',
    country: 'Canada',
    iata: '',
    icao: 'CYFD',
    latitude: 43.1314010620117,
    longitude: -80.3424987792969,
    altitude: 815,
    schengen: false
  },
  {
    id: 1170,
    name: 'Forestville Airport',
    city: 'Forestville',
    country: 'Canada',
    iata: 'YFE',
    icao: 'CYFE',
    latitude: 48.7461013793945,
    longitude: -69.0971984863281,
    altitude: 293,
    schengen: false
  },
  {
    id: 1171,
    name: 'Fort Hope Airport',
    city: 'Fort Hope',
    country: 'Canada',
    iata: 'YFH',
    icao: 'CYFH',
    latitude: 51.5619010925293,
    longitude: -87.9077987670899,
    altitude: 899,
    schengen: false
  },
  {
    id: 1172,
    name: 'La Macaza / Mont-Tremblant International Inc Airport',
    city: 'Mont-Tremblant',
    country: 'Canada',
    iata: 'YTM',
    icao: 'CYFJ',
    latitude: 46.4094009399,
    longitude: -74.7799987793,
    altitude: 827,
    schengen: false
  },
  {
    id: 1173,
    name: 'Flin Flon Airport',
    city: 'Flin Flon',
    country: 'Canada',
    iata: 'YFO',
    icao: 'CYFO',
    latitude: 54.6781005859375,
    longitude: -101.681999206543,
    altitude: 997,
    schengen: false
  },
  {
    id: 1174,
    name: 'Fort Resolution Airport',
    city: 'Fort Resolution',
    country: 'Canada',
    iata: 'YFR',
    icao: 'CYFR',
    latitude: 61.1808013916,
    longitude: -113.690002441,
    altitude: 526,
    schengen: false
  },
  {
    id: 1175,
    name: 'Fort Simpson Airport',
    city: 'Fort Simpson',
    country: 'Canada',
    iata: 'YFS',
    icao: 'CYFS',
    latitude: 61.7602005004883,
    longitude: -121.236999511719,
    altitude: 555,
    schengen: false
  },
  {
    id: 1176,
    name: 'Makkovik Airport',
    city: 'Makkovik',
    country: 'Canada',
    iata: 'YMN',
    icao: 'CYFT',
    latitude: 55.0769004821777,
    longitude: -59.1864013671875,
    altitude: 234,
    schengen: false
  },
  {
    id: 1177,
    name: 'Texada Gillies Bay Airport',
    city: 'Texada',
    country: 'Canada',
    iata: 'YGB',
    icao: 'CYGB',
    latitude: 49.6941986083984,
    longitude: -124.517997741699,
    altitude: 326,
    schengen: false
  },
  {
    id: 1178,
    name: 'Goderich Airport',
    city: 'Goderich',
    country: 'Canada',
    iata: '',
    icao: 'CYGD',
    latitude: 43.7668991089,
    longitude: -81.7106018066,
    altitude: 712,
    schengen: false
  },
  {
    id: 1179,
    name: 'Golden Airport',
    city: 'Golden',
    country: 'Canada',
    iata: '',
    icao: 'CYGE',
    latitude: 51.299196,
    longitude: -116.982002,
    altitude: 2575,
    schengen: false
  },
  {
    id: 1180,
    name: 'Fort Good Hope Airport',
    city: 'Fort Good Hope',
    country: 'Canada',
    iata: 'YGH',
    icao: 'CYGH',
    latitude: 66.2407989501953,
    longitude: -128.651000976563,
    altitude: 268,
    schengen: false
  },
  {
    id: 1181,
    name: 'Kingston Norman Rogers Airport',
    city: 'Kingston',
    country: 'Canada',
    iata: 'YGK',
    icao: 'CYGK',
    latitude: 44.2252998352051,
    longitude: -76.5969009399414,
    altitude: 305,
    schengen: false
  },
  {
    id: 1182,
    name: 'La Grande Rivière Airport',
    city: 'La Grande Riviere',
    country: 'Canada',
    iata: 'YGL',
    icao: 'CYGL',
    latitude: 53.625301361084,
    longitude: -77.7042007446289,
    altitude: 639,
    schengen: false
  },
  {
    id: 1183,
    name: 'Gimli Industrial Park Airport',
    city: 'Gimli',
    country: 'Canada',
    iata: 'YGM',
    icao: 'CYGM',
    latitude: 50.628101348877,
    longitude: -97.0432968139648,
    altitude: 753,
    schengen: false
  },
  {
    id: 1184,
    name: 'Gods Lake Narrows Airport',
    city: 'Gods Lake Narrows',
    country: 'Canada',
    iata: 'YGO',
    icao: 'CYGO',
    latitude: 54.5588989257813,
    longitude: -94.4914016723633,
    altitude: 617,
    schengen: false
  },
  {
    id: 1185,
    name: 'Gaspé (Michel-Pouliot) Airport',
    city: 'Gaspe',
    country: 'Canada',
    iata: 'YGP',
    icao: 'CYGP',
    latitude: 48.7752990723,
    longitude: -64.4785995483,
    altitude: 112,
    schengen: false
  },
  {
    id: 1186,
    name: 'Geraldton Greenstone Regional Airport',
    city: 'Geraldton',
    country: 'Canada',
    iata: 'YGQ',
    icao: 'CYGQ',
    latitude: 49.7783012390137,
    longitude: -86.9393997192383,
    altitude: 1144,
    schengen: false
  },
  {
    id: 1187,
    name: 'Îles-de-la-Madeleine Airport',
    city: 'Iles De La Madeleine',
    country: 'Canada',
    iata: 'YGR',
    icao: 'CYGR',
    latitude: 47.4247016906738,
    longitude: -61.7780990600586,
    altitude: 35,
    schengen: false
  },
  {
    id: 1188,
    name: 'Igloolik Airport',
    city: 'Igloolik',
    country: 'Canada',
    iata: 'YGT',
    icao: 'CYGT',
    latitude: 69.3647003174,
    longitude: -81.8161010742,
    altitude: 174,
    schengen: false
  },
  {
    id: 1189,
    name: 'Havre St Pierre Airport',
    city: 'Havre-Saint-Pierre',
    country: 'Canada',
    iata: 'YGV',
    icao: 'CYGV',
    latitude: 50.2818984985352,
    longitude: -63.611400604248,
    altitude: 124,
    schengen: false
  },
  {
    id: 1190,
    name: 'Kuujjuarapik Airport',
    city: 'Kuujjuarapik',
    country: 'Canada',
    iata: 'YGW',
    icao: 'CYGW',
    latitude: 55.2818984985352,
    longitude: -77.7652969360352,
    altitude: 34,
    schengen: false
  },
  {
    id: 1191,
    name: 'Gillam Airport',
    city: 'Gillam',
    country: 'Canada',
    iata: 'YGX',
    icao: 'CYGX',
    latitude: 56.3574981689453,
    longitude: -94.7106018066406,
    altitude: 476,
    schengen: false
  },
  {
    id: 1192,
    name: 'Grise Fiord Airport',
    city: 'Grise Fiord',
    country: 'Canada',
    iata: 'YGZ',
    icao: 'CYGZ',
    latitude: 76.4261016846,
    longitude: -82.9092025757,
    altitude: 146,
    schengen: false
  },
  {
    id: 1193,
    name: 'Quaqtaq Airport',
    city: 'Quaqtaq',
    country: 'Canada',
    iata: 'YQC',
    icao: 'CYHA',
    latitude: 61.0463981628418,
    longitude: -69.6177978515625,
    altitude: 103,
    schengen: false
  },
  {
    id: 1194,
    name: 'Hudson Bay Airport',
    city: 'Hudson Bay',
    country: 'Canada',
    iata: 'YHB',
    icao: 'CYHB',
    latitude: 52.8166999817,
    longitude: -102.310997009,
    altitude: 1175,
    schengen: false
  },
  {
    id: 1195,
    name: 'Vancouver Harbour Water Aerodrome',
    city: 'Vancouver',
    country: 'Canada',
    iata: 'CXH',
    icao: 'CYHC',
    latitude: 49.2943992615,
    longitude: -123.111000061,
    altitude: 0,
    schengen: false
  },
  {
    id: 1196,
    name: 'Dryden Regional Airport',
    city: 'Dryden',
    country: 'Canada',
    iata: 'YHD',
    icao: 'CYHD',
    latitude: 49.8316993713379,
    longitude: -92.7442016601563,
    altitude: 1354,
    schengen: false
  },
  {
    id: 1197,
    name: 'Hope Airport',
    city: 'Hope',
    country: 'Canada',
    iata: 'YHE',
    icao: 'CYHE',
    latitude: 49.3683013916,
    longitude: -121.498001099,
    altitude: 128,
    schengen: false
  },
  {
    id: 1198,
    name: 'Hearst René Fontaine Municipal Airport',
    city: 'Hearst',
    country: 'Canada',
    iata: 'YHF',
    icao: 'CYHF',
    latitude: 49.7141990661621,
    longitude: -83.6860961914062,
    altitude: 827,
    schengen: false
  },
  {
    id: 1199,
    name: 'Nemiscau Airport',
    city: 'Nemiscau',
    country: 'Canada',
    iata: 'YNS',
    icao: 'CYHH',
    latitude: 51.6911010742188,
    longitude: -76.1355972290039,
    altitude: 802,
    schengen: false
  },
  {
    id: 1200,
    name: 'Ulukhaktok Holman Airport',
    city: 'Holman Island',
    country: 'Canada',
    iata: 'YHI',
    icao: 'CYHI',
    latitude: 70.7628021240234,
    longitude: -117.805999755859,
    altitude: 117,
    schengen: false
  },
  {
    id: 1201,
    name: 'Gjoa Haven Airport',
    city: 'Gjoa Haven',
    country: 'Canada',
    iata: 'YHK',
    icao: 'CYHK',
    latitude: 68.635597229,
    longitude: -95.8497009277,
    altitude: 152,
    schengen: false
  },
  {
    id: 1202,
    name: 'John C. Munro Hamilton International Airport',
    city: 'Hamilton',
    country: 'Canada',
    iata: 'YHM',
    icao: 'CYHM',
    latitude: 43.1735992432,
    longitude: -79.9349975586,
    altitude: 780,
    schengen: false
  },
  {
    id: 1203,
    name: 'Hornepayne Municipal Airport',
    city: 'Hornepayne',
    country: 'Canada',
    iata: 'YHN',
    icao: 'CYHN',
    latitude: 49.1930999755859,
    longitude: -84.758903503418,
    altitude: 1099,
    schengen: false
  },
  {
    id: 1204,
    name: 'Hopedale Airport',
    city: 'Hopedale',
    country: 'Canada',
    iata: 'YHO',
    icao: 'CYHO',
    latitude: 55.448299407959,
    longitude: -60.2285995483399,
    altitude: 39,
    schengen: false
  },
  {
    id: 1205,
    name: 'Chevery Airport',
    city: 'Chevery',
    country: 'Canada',
    iata: 'YHR',
    icao: 'CYHR',
    latitude: 50.4688987731934,
    longitude: -59.6366996765137,
    altitude: 39,
    schengen: false
  },
  {
    id: 1206,
    name: 'Hanover / Saugeen Municipal Airport',
    city: 'Hanover',
    country: 'Canada',
    iata: '',
    icao: 'CYHS',
    latitude: 44.158298,
    longitude: -81.062798,
    altitude: 939,
    schengen: false
  },
  {
    id: 1207,
    name: 'Haines Junction Airport',
    city: 'Haines Junction',
    country: 'Canada',
    iata: 'YHT',
    icao: 'CYHT',
    latitude: 60.7891998291016,
    longitude: -137.546005249023,
    altitude: 2150,
    schengen: false
  },
  {
    id: 1208,
    name: 'Montréal / Saint-Hubert Airport',
    city: 'Montreal',
    country: 'Canada',
    iata: 'YHU',
    icao: 'CYHU',
    latitude: 45.5175018311,
    longitude: -73.4169006348,
    altitude: 90,
    schengen: false
  },
  {
    id: 1209,
    name: 'Hay River / Merlyn Carter Airport',
    city: 'Hay River',
    country: 'Canada',
    iata: 'YHY',
    icao: 'CYHY',
    latitude: 60.8396987915,
    longitude: -115.782997131,
    altitude: 541,
    schengen: false
  },
  {
    id: 1210,
    name: 'Halifax / Stanfield International Airport',
    city: 'Halifax',
    country: 'Canada',
    iata: 'YHZ',
    icao: 'CYHZ',
    latitude: 44.8807983398,
    longitude: -63.5085983276,
    altitude: 477,
    schengen: false
  },
  {
    id: 1211,
    name: 'Atikokan Municipal Airport',
    city: 'Atikokan',
    country: 'Canada',
    iata: 'YIB',
    icao: 'CYIB',
    latitude: 48.7738990784,
    longitude: -91.6386032104,
    altitude: 1408,
    schengen: false
  },
  {
    id: 1212,
    name: 'Digby / Annapolis Regional Airport',
    city: 'Digby',
    country: 'Canada',
    iata: 'YDG',
    icao: 'CYID',
    latitude: 44.5458450365,
    longitude: -65.7854247093,
    altitude: 499,
    schengen: false
  },
  {
    id: 1213,
    name: 'St Augustin Airport',
    city: 'St-Augustin',
    country: 'Canada',
    iata: 'YIF',
    icao: 'CYIF',
    latitude: 51.2117004395,
    longitude: -58.6582984924,
    altitude: 20,
    schengen: false
  },
  {
    id: 1214,
    name: 'Ivujivik Airport',
    city: 'Ivujivik',
    country: 'Canada',
    iata: 'YIK',
    icao: 'CYIK',
    latitude: 62.4173011779785,
    longitude: -77.9253005981445,
    altitude: 126,
    schengen: false
  },
  {
    id: 1215,
    name: 'Pond Inlet Airport',
    city: 'Pond Inlet',
    country: 'Canada',
    iata: 'YIO',
    icao: 'CYIO',
    latitude: 72.6832962036,
    longitude: -77.9666976929,
    altitude: 181,
    schengen: false
  },
  {
    id: 1216,
    name: 'Island Lake Airport',
    city: 'Island Lake',
    country: 'Canada',
    iata: 'YIV',
    icao: 'CYIV',
    latitude: 53.8572006225586,
    longitude: -94.6536026000977,
    altitude: 770,
    schengen: false
  },
  {
    id: 1217,
    name: 'Fort Liard Airport',
    city: 'Fort Liard',
    country: 'Canada',
    iata: 'YJF',
    icao: 'CYJF',
    latitude: 60.2358016968,
    longitude: -123.46900177,
    altitude: 708,
    schengen: false
  },
  {
    id: 1218,
    name: 'Fort St James Airport',
    city: 'Fort St. James',
    country: 'Canada',
    iata: '',
    icao: 'CYJM',
    latitude: 54.3972015380859,
    longitude: -124.263000488281,
    altitude: 2364,
    schengen: false
  },
  {
    id: 1219,
    name: 'St Jean Airport',
    city: 'St. Jean',
    country: 'Canada',
    iata: 'YJN',
    icao: 'CYJN',
    latitude: 45.2943992614746,
    longitude: -73.2810974121094,
    altitude: 136,
    schengen: false
  },
  {
    id: 1220,
    name: 'Denny Island Airport',
    city: 'Bella Bella',
    country: 'Canada',
    iata: '',
    icao: 'CYJQ',
    latitude: 52.137937,
    longitude: -128.059748,
    altitude: 162,
    schengen: false
  },
  {
    id: 1221,
    name: 'Stephenville Airport',
    city: 'Stephenville',
    country: 'Canada',
    iata: 'YJT',
    icao: 'CYJT',
    latitude: 48.5442008972168,
    longitude: -58.5499992370606,
    altitude: 84,
    schengen: false
  },
  {
    id: 1222,
    name: 'Kamloops Airport',
    city: 'Kamloops',
    country: 'Canada',
    iata: 'YKA',
    icao: 'CYKA',
    latitude: 50.7022018433,
    longitude: -120.444000244,
    altitude: 1133,
    schengen: false
  },
  {
    id: 1223,
    name: 'Aklavik/Freddie Carmichael Airport',
    city: 'Aklavik',
    country: 'Canada',
    iata: 'LAK',
    icao: 'CYKD',
    latitude: 68.223297,
    longitude: -135.00599,
    altitude: 23,
    schengen: false
  },
  {
    id: 1224,
    name: 'Waterloo Airport',
    city: 'Waterloo',
    country: 'Canada',
    iata: 'YKF',
    icao: 'CYKF',
    latitude: 43.4608001709,
    longitude: -80.3786010742,
    altitude: 1055,
    schengen: false
  },
  {
    id: 1225,
    name: 'Kangiqsujuaq (Wakeham Bay) Airport',
    city: 'Kangiqsujuaq',
    country: 'Canada',
    iata: 'YWB',
    icao: 'CYKG',
    latitude: 61.5886001587,
    longitude: -71.929397583,
    altitude: 501,
    schengen: false
  },
  {
    id: 1226,
    name: 'Key Lake Airport',
    city: 'Key Lake',
    country: 'Canada',
    iata: 'YKJ',
    icao: 'CYKJ',
    latitude: 57.2560997009277,
    longitude: -105.61799621582,
    altitude: 1679,
    schengen: false
  },
  {
    id: 1227,
    name: 'Schefferville Airport',
    city: 'Schefferville',
    country: 'Canada',
    iata: 'YKL',
    icao: 'CYKL',
    latitude: 54.8053016662598,
    longitude: -66.8052978515625,
    altitude: 1709,
    schengen: false
  },
  {
    id: 1228,
    name: 'Akulivik Airport',
    city: 'Akulivik',
    country: 'Canada',
    iata: 'AKV',
    icao: 'CYKO',
    latitude: 60.8185997009277,
    longitude: -78.1485977172852,
    altitude: 75,
    schengen: false
  },
  {
    id: 1229,
    name: 'Waskaganish Airport',
    city: 'Waskaganish',
    country: 'Canada',
    iata: 'YKQ',
    icao: 'CYKQ',
    latitude: 51.4733009338379,
    longitude: -78.75830078125,
    altitude: 80,
    schengen: false
  },
  {
    id: 1230,
    name: 'Kirkland Lake Airport',
    city: 'Kirkland Lake',
    country: 'Canada',
    iata: 'YKX',
    icao: 'CYKX',
    latitude: 48.2103004455566,
    longitude: -79.9813995361328,
    altitude: 1157,
    schengen: false
  },
  {
    id: 1231,
    name: 'Kindersley Airport',
    city: 'Kindersley',
    country: 'Canada',
    iata: 'YKY',
    icao: 'CYKY',
    latitude: 51.5175018311,
    longitude: -109.180999756,
    altitude: 2277,
    schengen: false
  },
  {
    id: 1232,
    name: 'Buttonville Municipal Airport',
    city: 'Toronto',
    country: 'Canada',
    iata: 'YKZ',
    icao: 'CYKZ',
    latitude: 43.8622016906738,
    longitude: -79.370002746582,
    altitude: 650,
    schengen: false
  },
  {
    id: 1233,
    name: 'Aupaluk Airport',
    city: 'Aupaluk',
    country: 'Canada',
    iata: 'YPJ',
    icao: 'CYLA',
    latitude: 59.2966995239258,
    longitude: -69.5997009277344,
    altitude: 119,
    schengen: false
  },
  {
    id: 1234,
    name: 'Kimmirut Airport',
    city: 'Kimmirut',
    country: 'Canada',
    iata: 'YLC',
    icao: 'CYLC',
    latitude: 62.8499984741,
    longitude: -69.8833007812,
    altitude: 175,
    schengen: false
  },
  {
    id: 1235,
    name: 'Chapleau Airport',
    city: 'Chapleau',
    country: 'Canada',
    iata: 'YLD',
    icao: 'CYLD',
    latitude: 47.8199996948242,
    longitude: -83.3467025756836,
    altitude: 1470,
    schengen: false
  },
  {
    id: 1236,
    name: 'Lansdowne House Airport',
    city: 'Lansdowne House',
    country: 'Canada',
    iata: 'YLH',
    icao: 'CYLH',
    latitude: 52.1955986022949,
    longitude: -87.934196472168,
    altitude: 834,
    schengen: false
  },
  {
    id: 1237,
    name: 'Meadow Lake Airport',
    city: 'Meadow Lake',
    country: 'Canada',
    iata: 'YLJ',
    icao: 'CYLJ',
    latitude: 54.125301361084,
    longitude: -108.523002624512,
    altitude: 1576,
    schengen: false
  },
  {
    id: 1238,
    name: "Lutselk'e Airport",
    city: "Lutselk'e",
    country: 'Canada',
    iata: 'YSG',
    icao: 'CYLK',
    latitude: 62.418303,
    longitude: -110.681998,
    altitude: 596,
    schengen: false
  },
  {
    id: 1239,
    name: 'Lloydminster Airport',
    city: 'Lloydminster',
    country: 'Canada',
    iata: 'YLL',
    icao: 'CYLL',
    latitude: 53.3092002868652,
    longitude: -110.072998046875,
    altitude: 2193,
    schengen: false
  },
  {
    id: 1240,
    name: 'Leaf Rapids Airport',
    city: 'Leaf Rapids',
    country: 'Canada',
    iata: 'YLR',
    icao: 'CYLR',
    latitude: 56.5133018493652,
    longitude: -99.9852981567383,
    altitude: 959,
    schengen: false
  },
  {
    id: 1241,
    name: 'Barrie-Orillia (Lake Simcoe Regional Airport)',
    city: 'Barrie-Orillia',
    country: 'Canada',
    iata: 'YLK',
    icao: 'CYLS',
    latitude: 44.4852981567,
    longitude: -79.5556030273,
    altitude: 972,
    schengen: false
  },
  {
    id: 1242,
    name: 'Alert Airport',
    city: 'Alert',
    country: 'Canada',
    iata: 'YLT',
    icao: 'CYLT',
    latitude: 82.5177993774,
    longitude: -62.2806015015,
    altitude: 100,
    schengen: false
  },
  {
    id: 1243,
    name: 'Kangiqsualujjuaq (Georges River) Airport',
    city: 'Kangiqsualujjuaq',
    country: 'Canada',
    iata: 'XGR',
    icao: 'CYLU',
    latitude: 58.7113990783691,
    longitude: -65.9927978515625,
    altitude: 215,
    schengen: false
  },
  {
    id: 1244,
    name: 'Kelowna International Airport',
    city: 'Kelowna',
    country: 'Canada',
    iata: 'YLW',
    icao: 'CYLW',
    latitude: 49.9561004639,
    longitude: -119.377998352,
    altitude: 1421,
    schengen: false
  },
  {
    id: 1245,
    name: 'Mayo Airport',
    city: 'Mayo',
    country: 'Canada',
    iata: 'YMA',
    icao: 'CYMA',
    latitude: 63.6164016723633,
    longitude: -135.86799621582,
    altitude: 1653,
    schengen: false
  },
  {
    id: 1246,
    name: 'Matane Airport',
    city: 'Matane',
    country: 'Canada',
    iata: 'YME',
    icao: 'CYME',
    latitude: 48.8568992614746,
    longitude: -67.4533004760742,
    altitude: 102,
    schengen: false
  },
  {
    id: 1247,
    name: 'Manitouwadge Airport',
    city: 'Manitouwadge',
    country: 'Canada',
    iata: 'YMG',
    icao: 'CYMG',
    latitude: 49.0839004516602,
    longitude: -85.8606033325195,
    altitude: 1198,
    schengen: false
  },
  {
    id: 1248,
    name: "Mary's Harbour Airport",
    city: "Mary's Harbour",
    country: 'Canada',
    iata: 'YMH',
    icao: 'CYMH',
    latitude: 52.3027992248535,
    longitude: -55.8471984863281,
    altitude: 38,
    schengen: false
  },
  {
    id: 1249,
    name: 'Moose Jaw Air Vice Marshal C. M. McEwen Airport',
    city: 'Moose Jaw',
    country: 'Canada',
    iata: 'YMJ',
    icao: 'CYMJ',
    latitude: 50.3302993774414,
    longitude: -105.55899810791,
    altitude: 1892,
    schengen: false
  },
  {
    id: 1250,
    name: 'Charlevoix Airport',
    city: 'Charlevoix',
    country: 'Canada',
    iata: 'YML',
    icao: 'CYML',
    latitude: 47.5974998474121,
    longitude: -70.2238998413086,
    altitude: 977,
    schengen: false
  },
  {
    id: 1251,
    name: 'Fort McMurray Airport',
    city: 'Fort Mcmurray',
    country: 'Canada',
    iata: 'YMM',
    icao: 'CYMM',
    latitude: 56.653301239,
    longitude: -111.222000122,
    altitude: 1211,
    schengen: false
  },
  {
    id: 1252,
    name: 'Moosonee Airport',
    city: 'Moosonee',
    country: 'Canada',
    iata: 'YMO',
    icao: 'CYMO',
    latitude: 51.2910995483398,
    longitude: -80.6078033447266,
    altitude: 30,
    schengen: false
  },
  {
    id: 1253,
    name: 'Chapais Airport',
    city: 'Chibougamau',
    country: 'Canada',
    iata: 'YMT',
    icao: 'CYMT',
    latitude: 49.771900177002,
    longitude: -74.5280990600586,
    altitude: 1270,
    schengen: false
  },
  {
    id: 1254,
    name: 'Umiujaq Airport',
    city: 'Umiujaq',
    country: 'Canada',
    iata: 'YUD',
    icao: 'CYMU',
    latitude: 56.5360984802246,
    longitude: -76.5183029174805,
    altitude: 250,
    schengen: false
  },
  {
    id: 1255,
    name: 'Maniwaki Airport',
    city: 'Maniwaki',
    country: 'Canada',
    iata: 'YMW',
    icao: 'CYMW',
    latitude: 46.2728004456,
    longitude: -75.9906005859,
    altitude: 656,
    schengen: false
  },
  {
    id: 1256,
    name: 'Montreal International (Mirabel) Airport',
    city: 'Montreal',
    country: 'Canada',
    iata: 'YMX',
    icao: 'CYMX',
    latitude: 45.679501,
    longitude: -74.038696,
    altitude: 270,
    schengen: false
  },
  {
    id: 1257,
    name: 'Natashquan Airport',
    city: 'Natashquan',
    country: 'Canada',
    iata: 'YNA',
    icao: 'CYNA',
    latitude: 50.189998626709,
    longitude: -61.7891998291016,
    altitude: 39,
    schengen: false
  },
  {
    id: 1258,
    name: 'Wemindji Airport',
    city: 'Wemindji',
    country: 'Canada',
    iata: 'YNC',
    icao: 'CYNC',
    latitude: 53.0106010437012,
    longitude: -78.8311004638672,
    altitude: 66,
    schengen: false
  },
  {
    id: 1259,
    name: 'Ottawa / Gatineau Airport',
    city: 'Gatineau',
    country: 'Canada',
    iata: 'YND',
    icao: 'CYND',
    latitude: 45.5217018127,
    longitude: -75.5635986328,
    altitude: 211,
    schengen: false
  },
  {
    id: 1260,
    name: 'Norway House Airport',
    city: 'Norway House',
    country: 'Canada',
    iata: 'YNE',
    icao: 'CYNE',
    latitude: 53.9583015441895,
    longitude: -97.8442001342773,
    altitude: 734,
    schengen: false
  },
  {
    id: 1261,
    name: 'Langley Airport',
    city: 'Langley Township',
    country: 'Canada',
    iata: 'YLY',
    icao: 'CYNJ',
    latitude: 49.1007995605,
    longitude: -122.630996704,
    altitude: 34,
    schengen: false
  },
  {
    id: 1262,
    name: 'Points North Landing Airport',
    city: 'Points North Landing',
    country: 'Canada',
    iata: 'YNL',
    icao: 'CYNL',
    latitude: 58.2766990661621,
    longitude: -104.082000732422,
    altitude: 1605,
    schengen: false
  },
  {
    id: 1263,
    name: 'Matagami Airport',
    city: 'Matagami',
    country: 'Canada',
    iata: 'YNM',
    icao: 'CYNM',
    latitude: 49.7616996765137,
    longitude: -77.8028030395508,
    altitude: 918,
    schengen: false
  },
  {
    id: 1264,
    name: 'Nejanilini Lake Airport',
    city: 'Nejanilini Lake',
    country: 'Canada',
    iata: '',
    icao: 'CYNN',
    latitude: 59.4874992371,
    longitude: -97.7802963257,
    altitude: 1000,
    schengen: false
  },
  {
    id: 1265,
    name: 'Fort Mackay / Horizon Airport',
    city: 'Wood Buffalo',
    country: 'Canada',
    iata: 'HZP',
    icao: 'CYNR',
    latitude: 57.3816986084,
    longitude: -111.700996399,
    altitude: 916,
    schengen: false
  },
  {
    id: 1266,
    name: 'Ekati Airport',
    city: 'Ekati',
    country: 'Canada',
    iata: 'YOA',
    icao: 'CYOA',
    latitude: 64.6988983154,
    longitude: -110.614997864,
    altitude: 1536,
    schengen: false
  },
  {
    id: 1267,
    name: 'Old Crow Airport',
    city: 'Old Crow',
    country: 'Canada',
    iata: 'YOC',
    icao: 'CYOC',
    latitude: 67.5706024169922,
    longitude: -139.839004516602,
    altitude: 824,
    schengen: false
  },
  {
    id: 1268,
    name: 'CFB Cold Lake',
    city: 'Cold Lake',
    country: 'Canada',
    iata: 'YOD',
    icao: 'CYOD',
    latitude: 54.4049987792969,
    longitude: -110.278999328613,
    altitude: 1775,
    schengen: false
  },
  {
    id: 1269,
    name: 'Oxford House Airport',
    city: 'Oxford House',
    country: 'Canada',
    iata: 'YOH',
    icao: 'CYOH',
    latitude: 54.9333000183106,
    longitude: -95.2789001464844,
    altitude: 663,
    schengen: false
  },
  {
    id: 1270,
    name: 'High Level Airport',
    city: 'High Level',
    country: 'Canada',
    iata: 'YOJ',
    icao: 'CYOJ',
    latitude: 58.6213989257813,
    longitude: -117.165000915527,
    altitude: 1110,
    schengen: false
  },
  {
    id: 1271,
    name: 'Toronto/Oshawa Executive Airport',
    city: 'Oshawa',
    country: 'Canada',
    iata: 'YOO',
    icao: 'CYOO',
    latitude: 43.922798,
    longitude: -78.894997,
    altitude: 460,
    schengen: false
  },
  {
    id: 1272,
    name: 'Rainbow Lake Airport',
    city: 'Rainbow Lake',
    country: 'Canada',
    iata: 'YOP',
    icao: 'CYOP',
    latitude: 58.4914016723633,
    longitude: -119.407997131348,
    altitude: 1759,
    schengen: false
  },
  {
    id: 1273,
    name: 'Owen Sound / Billy Bishop Regional Airport',
    city: 'Owen Sound',
    country: 'Canada',
    iata: 'YOS',
    icao: 'CYOS',
    latitude: 44.5903015137,
    longitude: -80.8375015259,
    altitude: 1007,
    schengen: false
  },
  {
    id: 1274,
    name: 'Ottawa Macdonald-Cartier International Airport',
    city: 'Ottawa',
    country: 'Canada',
    iata: 'YOW',
    icao: 'CYOW',
    latitude: 45.3224983215332,
    longitude: -75.6691970825195,
    altitude: 374,
    schengen: false
  },
  {
    id: 1275,
    name: 'Prince Albert Glass Field',
    city: 'Prince Albert',
    country: 'Canada',
    iata: 'YPA',
    icao: 'CYPA',
    latitude: 53.2141990662,
    longitude: -105.672996521,
    altitude: 1405,
    schengen: false
  },
  {
    id: 1276,
    name: 'Paulatuk (Nora Aliqatchialuk Ruben) Airport',
    city: 'Paulatuk',
    country: 'Canada',
    iata: 'YPC',
    icao: 'CYPC',
    latitude: 69.3608381154,
    longitude: -124.075469971,
    altitude: 15,
    schengen: false
  },
  {
    id: 1277,
    name: 'Port Hawkesbury Airport',
    city: 'Port Hawkesbury',
    country: 'Canada',
    iata: 'YPS',
    icao: 'CYPD',
    latitude: 45.6567001343,
    longitude: -61.3680992126,
    altitude: 377,
    schengen: false
  },
  {
    id: 1278,
    name: 'Peace River Airport',
    city: 'Peace River',
    country: 'Canada',
    iata: 'YPE',
    icao: 'CYPE',
    latitude: 56.226898,
    longitude: -117.446999,
    altitude: 1873,
    schengen: false
  },
  {
    id: 1279,
    name: 'Southport Airport',
    city: 'Portage-la-prairie',
    country: 'Canada',
    iata: 'YPG',
    icao: 'CYPG',
    latitude: 49.903099,
    longitude: -98.273817,
    altitude: 885,
    schengen: false
  },
  {
    id: 1280,
    name: 'Inukjuak Airport',
    city: 'Inukjuak',
    country: 'Canada',
    iata: 'YPH',
    icao: 'CYPH',
    latitude: 58.4719009399414,
    longitude: -78.0768966674805,
    altitude: 83,
    schengen: false
  },
  {
    id: 1281,
    name: 'Pitt Meadows Airport',
    city: 'Pitt Meadows',
    country: 'Canada',
    iata: '',
    icao: 'CYPK',
    latitude: 49.2160987854004,
    longitude: -122.709999084473,
    altitude: 11,
    schengen: false
  },
  {
    id: 1282,
    name: 'Pickle Lake Airport',
    city: 'Pickle Lake',
    country: 'Canada',
    iata: 'YPL',
    icao: 'CYPL',
    latitude: 51.4463996887207,
    longitude: -90.2142028808594,
    altitude: 1267,
    schengen: false
  },
  {
    id: 1283,
    name: 'Pikangikum Airport',
    city: 'Pikangikum',
    country: 'Canada',
    iata: 'YPM',
    icao: 'CYPM',
    latitude: 51.8196983337402,
    longitude: -93.9732971191406,
    altitude: 1114,
    schengen: false
  },
  {
    id: 1284,
    name: 'Port Menier Airport',
    city: 'Port Menier',
    country: 'Canada',
    iata: 'YPN',
    icao: 'CYPN',
    latitude: 49.8363990783691,
    longitude: -64.2885971069336,
    altitude: 167,
    schengen: false
  },
  {
    id: 1285,
    name: 'Peawanuck Airport',
    city: 'Peawanuck',
    country: 'Canada',
    iata: 'YPO',
    icao: 'CYPO',
    latitude: 54.9880981445313,
    longitude: -85.4432983398437,
    altitude: 173,
    schengen: false
  },
  {
    id: 1286,
    name: 'Peterborough Airport',
    city: 'Peterborough',
    country: 'Canada',
    iata: 'YPQ',
    icao: 'CYPQ',
    latitude: 44.2299995422363,
    longitude: -78.3632965087891,
    altitude: 628,
    schengen: false
  },
  {
    id: 1287,
    name: 'Prince Rupert Airport',
    city: 'Prince Pupert',
    country: 'Canada',
    iata: 'YPR',
    icao: 'CYPR',
    latitude: 54.2860984802,
    longitude: -130.445007324,
    altitude: 116,
    schengen: false
  },
  {
    id: 1288,
    name: 'Powell River Airport',
    city: 'Powell River',
    country: 'Canada',
    iata: 'YPW',
    icao: 'CYPW',
    latitude: 49.8342018127441,
    longitude: -124.5,
    altitude: 425,
    schengen: false
  },
  {
    id: 1289,
    name: 'Puvirnituq Airport',
    city: 'Puvirnituq',
    country: 'Canada',
    iata: 'YPX',
    icao: 'CYPX',
    latitude: 60.0505981445312,
    longitude: -77.2869033813477,
    altitude: 74,
    schengen: false
  },
  {
    id: 1290,
    name: 'Fort Chipewyan Airport',
    city: 'Fort Chipewyan',
    country: 'Canada',
    iata: 'YPY',
    icao: 'CYPY',
    latitude: 58.7672004699707,
    longitude: -111.116996765137,
    altitude: 761,
    schengen: false
  },
  {
    id: 1291,
    name: 'Muskoka Airport',
    city: 'Muskoka',
    country: 'Canada',
    iata: 'YQA',
    icao: 'CYQA',
    latitude: 44.9747009277344,
    longitude: -79.3032989501953,
    altitude: 925,
    schengen: false
  },
  {
    id: 1292,
    name: 'Quebec Jean Lesage International Airport',
    city: 'Quebec',
    country: 'Canada',
    iata: 'YQB',
    icao: 'CYQB',
    latitude: 46.7911,
    longitude: -71.393303,
    altitude: 244,
    schengen: false
  },
  {
    id: 1293,
    name: 'The Pas Airport',
    city: 'The Pas',
    country: 'Canada',
    iata: 'YQD',
    icao: 'CYQD',
    latitude: 53.9714012145996,
    longitude: -101.091003417969,
    altitude: 887,
    schengen: false
  },
  {
    id: 1294,
    name: 'Red Deer Regional Airport',
    city: 'Red Deer Industrial',
    country: 'Canada',
    iata: 'YQF',
    icao: 'CYQF',
    latitude: 52.1822013854981,
    longitude: -113.893997192383,
    altitude: 2968,
    schengen: false
  },
  {
    id: 1295,
    name: 'Windsor Airport',
    city: 'Windsor',
    country: 'Canada',
    iata: 'YQG',
    icao: 'CYQG',
    latitude: 42.2756004333496,
    longitude: -82.9555969238281,
    altitude: 622,
    schengen: false
  },
  {
    id: 1296,
    name: 'Watson Lake Airport',
    city: 'Watson Lake',
    country: 'Canada',
    iata: 'YQH',
    icao: 'CYQH',
    latitude: 60.1164016723633,
    longitude: -128.822006225586,
    altitude: 2255,
    schengen: false
  },
  {
    id: 1297,
    name: 'Yarmouth Airport',
    city: 'Yarmouth',
    country: 'Canada',
    iata: 'YQI',
    icao: 'CYQI',
    latitude: 43.8269004821777,
    longitude: -66.0880966186523,
    altitude: 141,
    schengen: false
  },
  {
    id: 1298,
    name: 'Kenora Airport',
    city: 'Kenora',
    country: 'Canada',
    iata: 'YQK',
    icao: 'CYQK',
    latitude: 49.7882995605469,
    longitude: -94.3630981445313,
    altitude: 1332,
    schengen: false
  },
  {
    id: 1299,
    name: 'Lethbridge County Airport',
    city: 'Lethbridge',
    country: 'Canada',
    iata: 'YQL',
    icao: 'CYQL',
    latitude: 49.6302986145,
    longitude: -112.800003052,
    altitude: 3048,
    schengen: false
  },
  {
    id: 1300,
    name: 'Greater Moncton International Airport',
    city: 'Moncton',
    country: 'Canada',
    iata: 'YQM',
    icao: 'CYQM',
    latitude: 46.1122016906738,
    longitude: -64.678596496582,
    altitude: 232,
    schengen: false
  },
  {
    id: 1301,
    name: 'Nakina Airport',
    city: 'Nakina',
    country: 'Canada',
    iata: 'YQN',
    icao: 'CYQN',
    latitude: 50.1828002929688,
    longitude: -86.696403503418,
    altitude: 1057,
    schengen: false
  },
  {
    id: 1302,
    name: 'Comox Airport',
    city: 'Comox',
    country: 'Canada',
    iata: 'YQQ',
    icao: 'CYQQ',
    latitude: 49.7108001708984,
    longitude: -124.887001037598,
    altitude: 84,
    schengen: false
  },
  {
    id: 1303,
    name: 'Regina International Airport',
    city: 'Regina',
    country: 'Canada',
    iata: 'YQR',
    icao: 'CYQR',
    latitude: 50.4319000244141,
    longitude: -104.666000366211,
    altitude: 1894,
    schengen: false
  },
  {
    id: 1304,
    name: 'St Thomas Municipal Airport',
    city: 'St Thomas',
    country: 'Canada',
    iata: 'YQS',
    icao: 'CYQS',
    latitude: 42.7700004577637,
    longitude: -81.1108016967773,
    altitude: 778,
    schengen: false
  },
  {
    id: 1305,
    name: 'Thunder Bay Airport',
    city: 'Thunder Bay',
    country: 'Canada',
    iata: 'YQT',
    icao: 'CYQT',
    latitude: 48.3718986511231,
    longitude: -89.3238983154297,
    altitude: 653,
    schengen: false
  },
  {
    id: 1306,
    name: 'Grande Prairie Airport',
    city: 'Grande Prairie',
    country: 'Canada',
    iata: 'YQU',
    icao: 'CYQU',
    latitude: 55.1796989441,
    longitude: -118.885002136,
    altitude: 2195,
    schengen: false
  },
  {
    id: 1307,
    name: 'Yorkton Municipal Airport',
    city: 'Yorkton',
    country: 'Canada',
    iata: 'YQV',
    icao: 'CYQV',
    latitude: 51.2647018432617,
    longitude: -102.46199798584,
    altitude: 1635,
    schengen: false
  },
  {
    id: 1308,
    name: 'North Battleford Airport',
    city: 'North Battleford',
    country: 'Canada',
    iata: 'YQW',
    icao: 'CYQW',
    latitude: 52.7691993713379,
    longitude: -108.244003295898,
    altitude: 1799,
    schengen: false
  },
  {
    id: 1309,
    name: 'Gander International Airport',
    city: 'Gander',
    country: 'Canada',
    iata: 'YQX',
    icao: 'CYQX',
    latitude: 48.9369010925293,
    longitude: -54.5680999755859,
    altitude: 496,
    schengen: false
  },
  {
    id: 1310,
    name: 'Sydney / J.A. Douglas McCurdy Airport',
    city: 'Sydney',
    country: 'Canada',
    iata: 'YQY',
    icao: 'CYQY',
    latitude: 46.1614,
    longitude: -60.047798,
    altitude: 203,
    schengen: false
  },
  {
    id: 1311,
    name: 'Quesnel Airport',
    city: 'Quesnel',
    country: 'Canada',
    iata: 'YQZ',
    icao: 'CYQZ',
    latitude: 53.0261001586914,
    longitude: -122.51000213623,
    altitude: 1789,
    schengen: false
  },
  {
    id: 1312,
    name: 'Rae Lakes Airport',
    city: 'Gamètì',
    country: 'Canada',
    iata: 'YRA',
    icao: 'CYRA',
    latitude: 64.116096496582,
    longitude: -117.309997558594,
    altitude: 723,
    schengen: false
  },
  {
    id: 1313,
    name: 'Resolute Bay Airport',
    city: 'Resolute',
    country: 'Canada',
    iata: 'YRB',
    icao: 'CYRB',
    latitude: 74.7169036865,
    longitude: -94.9693984985,
    altitude: 215,
    schengen: false
  },
  {
    id: 1314,
    name: 'Rivière-du-Loup Airport',
    city: 'Riviere Du Loup',
    country: 'Canada',
    iata: 'YRI',
    icao: 'CYRI',
    latitude: 47.7644004821777,
    longitude: -69.5847015380859,
    altitude: 427,
    schengen: false
  },
  {
    id: 1315,
    name: 'Roberval Airport',
    city: 'Roberval',
    country: 'Canada',
    iata: 'YRJ',
    icao: 'CYRJ',
    latitude: 48.5200004577637,
    longitude: -72.2656021118164,
    altitude: 586,
    schengen: false
  },
  {
    id: 1316,
    name: 'Red Lake Airport',
    city: 'Red Lake',
    country: 'Canada',
    iata: 'YRL',
    icao: 'CYRL',
    latitude: 51.0668983459473,
    longitude: -93.793098449707,
    altitude: 1265,
    schengen: false
  },
  {
    id: 1317,
    name: 'Rocky Mountain House Airport',
    city: 'Rocky Mountain House',
    country: 'Canada',
    iata: 'YRM',
    icao: 'CYRM',
    latitude: 52.4296989441,
    longitude: -114.903999329,
    altitude: 3244,
    schengen: false
  },
  {
    id: 1318,
    name: 'Ottawa / Rockcliffe Airport',
    city: 'Ottawa',
    country: 'Canada',
    iata: 'YRO',
    icao: 'CYRO',
    latitude: 45.4603004456,
    longitude: -75.6461029053,
    altitude: 188,
    schengen: false
  },
  {
    id: 1319,
    name: 'Ottawa / Carp Airport',
    city: 'Ottawa',
    country: 'Canada',
    iata: '',
    icao: 'CYRP',
    latitude: 45.3191986084,
    longitude: -76.0222015381,
    altitude: 382,
    schengen: false
  },
  {
    id: 1320,
    name: 'Trois-Rivières Airport',
    city: 'Trois Rivieres',
    country: 'Canada',
    iata: 'YRQ',
    icao: 'CYRQ',
    latitude: 46.3527984619141,
    longitude: -72.6793975830078,
    altitude: 199,
    schengen: false
  },
  {
    id: 1321,
    name: 'Red Sucker Lake Airport',
    city: 'Red Sucker Lake',
    country: 'Canada',
    iata: 'YRS',
    icao: 'CYRS',
    latitude: 54.1671981811524,
    longitude: -93.5571975708008,
    altitude: 729,
    schengen: false
  },
  {
    id: 1322,
    name: 'Rankin Inlet Airport',
    city: 'Rankin Inlet',
    country: 'Canada',
    iata: 'YRT',
    icao: 'CYRT',
    latitude: 62.8114013672,
    longitude: -92.1157989502,
    altitude: 94,
    schengen: false
  },
  {
    id: 1323,
    name: 'Revelstoke Airport',
    city: 'Revelstoke',
    country: 'Canada',
    iata: 'YRV',
    icao: 'CYRV',
    latitude: 50.9667015076,
    longitude: -118.182998657,
    altitude: 1459,
    schengen: false
  },
  {
    id: 1324,
    name: 'Stratford Municipal Airport',
    city: 'Stratford',
    country: 'Canada',
    iata: '',
    icao: 'CYSA',
    latitude: 43.415599823,
    longitude: -80.9344024658,
    altitude: 1215,
    schengen: false
  },
  {
    id: 1325,
    name: 'Sudbury Airport',
    city: 'Sudbury',
    country: 'Canada',
    iata: 'YSB',
    icao: 'CYSB',
    latitude: 46.625,
    longitude: -80.7988967895508,
    altitude: 1141,
    schengen: false
  },
  {
    id: 1326,
    name: 'Sherbrooke Airport',
    city: 'Sherbrooke',
    country: 'Canada',
    iata: 'YSC',
    icao: 'CYSC',
    latitude: 45.4385986328125,
    longitude: -71.6913986206055,
    altitude: 792,
    schengen: false
  },
  {
    id: 1327,
    name: 'Suffield Heliport',
    city: 'Suffield',
    country: 'Canada',
    iata: 'YSD',
    icao: 'CYSD',
    latitude: 50.2667007446289,
    longitude: -111.182998657227,
    altitude: 2525,
    schengen: false
  },
  {
    id: 1328,
    name: 'Squamish Airport',
    city: 'Squamish',
    country: 'Canada',
    iata: 'YSE',
    icao: 'CYSE',
    latitude: 49.7817001343,
    longitude: -123.162002563,
    altitude: 171,
    schengen: false
  },
  {
    id: 1329,
    name: 'Stony Rapids Airport',
    city: 'Stony Rapids',
    country: 'Canada',
    iata: 'YSF',
    icao: 'CYSF',
    latitude: 59.250301361084,
    longitude: -105.841003417969,
    altitude: 805,
    schengen: false
  },
  {
    id: 1330,
    name: 'St Georges Airport',
    city: "Lutselk'e",
    country: 'Canada',
    iata: '',
    icao: 'CYSG',
    latitude: 46.096401,
    longitude: -70.714699,
    altitude: 893,
    schengen: false
  },
  {
    id: 1331,
    name: 'Smiths Falls-Montague (Russ Beach) Airport',
    city: 'Smiths Falls',
    country: 'Canada',
    iata: 'YSH',
    icao: 'CYSH',
    latitude: 44.94580078125,
    longitude: -75.9405975341797,
    altitude: 416,
    schengen: false
  },
  {
    id: 1332,
    name: 'Saint John Airport',
    city: 'St. John',
    country: 'Canada',
    iata: 'YSJ',
    icao: 'CYSJ',
    latitude: 45.3161010742187,
    longitude: -65.8902969360352,
    altitude: 357,
    schengen: false
  },
  {
    id: 1333,
    name: 'Sanikiluaq Airport',
    city: 'Sanikiluaq',
    country: 'Canada',
    iata: 'YSK',
    icao: 'CYSK',
    latitude: 56.5377998352,
    longitude: -79.2466964722,
    altitude: 104,
    schengen: false
  },
  {
    id: 1334,
    name: 'St Leonard Airport',
    city: 'St Leonard',
    country: 'Canada',
    iata: 'YSL',
    icao: 'CYSL',
    latitude: 47.1575012207031,
    longitude: -67.8347015380859,
    altitude: 793,
    schengen: false
  },
  {
    id: 1335,
    name: 'Fort Smith Airport',
    city: 'Fort Smith',
    country: 'Canada',
    iata: 'YSM',
    icao: 'CYSM',
    latitude: 60.0203018188477,
    longitude: -111.96199798584,
    altitude: 671,
    schengen: false
  },
  {
    id: 1336,
    name: 'Niagara District Airport',
    city: 'Saint Catherines',
    country: 'Canada',
    iata: 'YCM',
    icao: 'CYSN',
    latitude: 43.1916999816895,
    longitude: -79.1716995239258,
    altitude: 321,
    schengen: false
  },
  {
    id: 1337,
    name: 'Marathon Airport',
    city: 'Marathon',
    country: 'Canada',
    iata: 'YSP',
    icao: 'CYSP',
    latitude: 48.755298614502,
    longitude: -86.3443984985352,
    altitude: 1035,
    schengen: false
  },
  {
    id: 1338,
    name: 'Nanisivik Airport',
    city: 'Nanisivik',
    country: 'Canada',
    iata: 'YSR',
    icao: 'CYSR',
    latitude: 72.982201,
    longitude: -84.613602,
    altitude: 2106,
    schengen: false
  },
  {
    id: 1339,
    name: 'St. Theresa Point Airport',
    city: 'St. Theresa Point',
    country: 'Canada',
    iata: 'YST',
    icao: 'CYST',
    latitude: 53.8456001281738,
    longitude: -94.8518981933594,
    altitude: 773,
    schengen: false
  },
  {
    id: 1340,
    name: 'Summerside Airport',
    city: 'Summerside',
    country: 'Canada',
    iata: 'YSU',
    icao: 'CYSU',
    latitude: 46.440601348877,
    longitude: -63.8335990905762,
    altitude: 56,
    schengen: false
  },
  {
    id: 1341,
    name: 'Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport',
    city: 'Sachs Harbour',
    country: 'Canada',
    iata: 'YSY',
    icao: 'CYSY',
    latitude: 71.9938964844,
    longitude: -125.242996216,
    altitude: 282,
    schengen: false
  },
  {
    id: 1342,
    name: 'Pembroke Airport',
    city: 'Pembroke',
    country: 'Canada',
    iata: 'YTA',
    icao: 'CYTA',
    latitude: 45.8643989562988,
    longitude: -77.2517013549805,
    altitude: 529,
    schengen: false
  },
  {
    id: 1343,
    name: 'Cape Dorset Airport',
    city: 'Cape Dorset',
    country: 'Canada',
    iata: 'YTE',
    icao: 'CYTE',
    latitude: 64.2300033569,
    longitude: -76.5267028809,
    altitude: 164,
    schengen: false
  },
  {
    id: 1344,
    name: 'Alma Airport',
    city: 'Alma',
    country: 'Canada',
    iata: 'YTF',
    icao: 'CYTF',
    latitude: 48.5088996887,
    longitude: -71.6418991089,
    altitude: 445,
    schengen: false
  },
  {
    id: 1345,
    name: 'Thompson Airport',
    city: 'Thompson',
    country: 'Canada',
    iata: 'YTH',
    icao: 'CYTH',
    latitude: 55.8011016845703,
    longitude: -97.8641967773438,
    altitude: 729,
    schengen: false
  },
  {
    id: 1346,
    name: 'Big Trout Lake Airport',
    city: 'Big Trout Lake',
    country: 'Canada',
    iata: 'YTL',
    icao: 'CYTL',
    latitude: 53.817798614502,
    longitude: -89.8968963623047,
    altitude: 729,
    schengen: false
  },
  {
    id: 1347,
    name: 'Tasiujaq Airport',
    city: 'Tasiujaq',
    country: 'Canada',
    iata: 'YTQ',
    icao: 'CYTQ',
    latitude: 58.6678009033203,
    longitude: -69.9558029174805,
    altitude: 122,
    schengen: false
  },
  {
    id: 1348,
    name: 'CFB Trenton',
    city: 'Trenton',
    country: 'Canada',
    iata: 'YTR',
    icao: 'CYTR',
    latitude: 44.1189002990723,
    longitude: -77.5280990600586,
    altitude: 283,
    schengen: false
  },
  {
    id: 1349,
    name: 'Timmins/Victor M. Power',
    city: 'Timmins',
    country: 'Canada',
    iata: 'YTS',
    icao: 'CYTS',
    latitude: 48.5696983337,
    longitude: -81.376701355,
    altitude: 967,
    schengen: false
  },
  {
    id: 1350,
    name: 'Billy Bishop Toronto City Centre Airport',
    city: 'Toronto',
    country: 'Canada',
    iata: 'YTZ',
    icao: 'CYTZ',
    latitude: 43.627499,
    longitude: -79.396202,
    altitude: 252,
    schengen: false
  },
  {
    id: 1351,
    name: 'Tuktoyaktuk Airport',
    city: 'Tuktoyaktuk',
    country: 'Canada',
    iata: 'YUB',
    icao: 'CYUB',
    latitude: 69.4332962036133,
    longitude: -133.026000976563,
    altitude: 15,
    schengen: false
  },
  {
    id: 1352,
    name: 'Montreal / Pierre Elliott Trudeau International Airport',
    city: 'Montreal',
    country: 'Canada',
    iata: 'YUL',
    icao: 'CYUL',
    latitude: 45.4706001282,
    longitude: -73.7407989502,
    altitude: 118,
    schengen: false
  },
  {
    id: 1353,
    name: 'Repulse Bay Airport',
    city: 'Repulse Bay',
    country: 'Canada',
    iata: 'YUT',
    icao: 'CYUT',
    latitude: 66.5214004517,
    longitude: -86.2247009277,
    altitude: 80,
    schengen: false
  },
  {
    id: 1354,
    name: 'Hall Beach Airport',
    city: 'Hall Beach',
    country: 'Canada',
    iata: 'YUX',
    icao: 'CYUX',
    latitude: 68.7761001587,
    longitude: -81.2425,
    altitude: 30,
    schengen: false
  },
  {
    id: 1355,
    name: 'Rouyn Noranda Airport',
    city: 'Rouyn',
    country: 'Canada',
    iata: 'YUY',
    icao: 'CYUY',
    latitude: 48.2061004638672,
    longitude: -78.8356018066406,
    altitude: 988,
    schengen: false
  },
  {
    id: 1356,
    name: 'Bonaventure Airport',
    city: 'Bonaventure',
    country: 'Canada',
    iata: 'YVB',
    icao: 'CYVB',
    latitude: 48.0710983276367,
    longitude: -65.4602966308594,
    altitude: 123,
    schengen: false
  },
  {
    id: 1357,
    name: 'La Ronge Airport',
    city: 'La Ronge',
    country: 'Canada',
    iata: 'YVC',
    icao: 'CYVC',
    latitude: 55.1514015198,
    longitude: -105.262001038,
    altitude: 1242,
    schengen: false
  },
  {
    id: 1358,
    name: 'Virden/R.J. (Bob) Andrew Field Regional Aerodrome',
    city: 'Virden',
    country: 'Canada',
    iata: '',
    icao: 'CYVD',
    latitude: 49.8782997131,
    longitude: -100.917999268,
    altitude: 1454,
    schengen: false
  },
  {
    id: 1359,
    name: 'Vermilion Airport',
    city: 'Vermillion',
    country: 'Canada',
    iata: 'YVG',
    icao: 'CYVG',
    latitude: 53.3558006287,
    longitude: -110.823997498,
    altitude: 2025,
    schengen: false
  },
  {
    id: 1360,
    name: 'Vernon Airport',
    city: 'Vernon',
    country: 'Canada',
    iata: 'YVE',
    icao: 'CYVK',
    latitude: 50.2481002807617,
    longitude: -119.331001281738,
    altitude: 1140,
    schengen: false
  },
  {
    id: 1361,
    name: 'Qikiqtarjuaq Airport',
    city: 'Broughton Island',
    country: 'Canada',
    iata: 'YVM',
    icao: 'CYVM',
    latitude: 67.5457992554,
    longitude: -64.0314025879,
    altitude: 21,
    schengen: false
  },
  {
    id: 1362,
    name: "Val-d'Or Airport",
    city: "Val D'or",
    country: 'Canada',
    iata: 'YVO',
    icao: 'CYVO',
    latitude: 48.0532989502,
    longitude: -77.7827987671,
    altitude: 1107,
    schengen: false
  },
  {
    id: 1363,
    name: 'Kuujjuaq Airport',
    city: 'Quujjuaq',
    country: 'Canada',
    iata: 'YVP',
    icao: 'CYVP',
    latitude: 58.0960998535156,
    longitude: -68.4269027709961,
    altitude: 129,
    schengen: false
  },
  {
    id: 1364,
    name: 'Norman Wells Airport',
    city: 'Norman Wells',
    country: 'Canada',
    iata: 'YVQ',
    icao: 'CYVQ',
    latitude: 65.2816009521484,
    longitude: -126.797996520996,
    altitude: 238,
    schengen: false
  },
  {
    id: 1365,
    name: 'Vancouver International Airport',
    city: 'Vancouver',
    country: 'Canada',
    iata: 'YVR',
    icao: 'CYVR',
    latitude: 49.193901062,
    longitude: -123.183998108,
    altitude: 14,
    schengen: false
  },
  {
    id: 1366,
    name: 'Buffalo Narrows Airport',
    city: 'Buffalo Narrows',
    country: 'Canada',
    iata: 'YVT',
    icao: 'CYVT',
    latitude: 55.8418998718,
    longitude: -108.417999268,
    altitude: 1423,
    schengen: false
  },
  {
    id: 1367,
    name: 'Wiarton Airport',
    city: 'Wiarton',
    country: 'Canada',
    iata: 'YVV',
    icao: 'CYVV',
    latitude: 44.7458,
    longitude: -81.107201,
    altitude: 729,
    schengen: false
  },
  {
    id: 1368,
    name: 'Deer Lake Airport',
    city: 'Deer Lake',
    country: 'Canada',
    iata: 'YVZ',
    icao: 'CYVZ',
    latitude: 52.6557998657227,
    longitude: -94.0614013671875,
    altitude: 1092,
    schengen: false
  },
  {
    id: 1369,
    name: 'Petawawa Airport',
    city: 'Petawawa',
    country: 'Canada',
    iata: 'YWA',
    icao: 'CYWA',
    latitude: 45.9522018432617,
    longitude: -77.3191986083984,
    altitude: 427,
    schengen: false
  },
  {
    id: 1370,
    name: 'Winnipeg / James Armstrong Richardson International Airport',
    city: 'Winnipeg',
    country: 'Canada',
    iata: 'YWG',
    icao: 'CYWG',
    latitude: 49.9099998474,
    longitude: -97.2398986816,
    altitude: 783,
    schengen: false
  },
  {
    id: 1371,
    name: 'Victoria Harbour Seaplane Base',
    city: 'Victoria',
    country: 'Canada',
    iata: 'YWH',
    icao: 'CYWH',
    latitude: 48.4249858939,
    longitude: -123.388867378,
    altitude: 0,
    schengen: false
  },
  {
    id: 1372,
    name: 'Déline Airport',
    city: 'Deline',
    country: 'Canada',
    iata: 'YWJ',
    icao: 'CYWJ',
    latitude: 65.2110977172852,
    longitude: -123.435997009277,
    altitude: 703,
    schengen: false
  },
  {
    id: 1373,
    name: 'Wabush Airport',
    city: 'Wabush',
    country: 'Canada',
    iata: 'YWK',
    icao: 'CYWK',
    latitude: 52.9219017028809,
    longitude: -66.8644027709961,
    altitude: 1808,
    schengen: false
  },
  {
    id: 1374,
    name: 'Williams Lake Airport',
    city: 'Williams Lake',
    country: 'Canada',
    iata: 'YWL',
    icao: 'CYWL',
    latitude: 52.1831016541,
    longitude: -122.054000854,
    altitude: 3085,
    schengen: false
  },
  {
    id: 1375,
    name: 'Webequie Airport',
    city: 'Webequie',
    country: 'Canada',
    iata: 'YWP',
    icao: 'CYWP',
    latitude: 52.9593933975,
    longitude: -87.3748683929,
    altitude: 685,
    schengen: false
  },
  {
    id: 1376,
    name: 'Wrigley Airport',
    city: 'Wrigley',
    country: 'Canada',
    iata: 'YWY',
    icao: 'CYWY',
    latitude: 63.209400177002,
    longitude: -123.436996459961,
    altitude: 489,
    schengen: false
  },
  {
    id: 1377,
    name: 'Cranbrook/Canadian Rockies International Airport',
    city: 'Cranbrook',
    country: 'Canada',
    iata: 'YXC',
    icao: 'CYXC',
    latitude: 49.610801696777,
    longitude: -115.78199768066,
    altitude: 3082,
    schengen: false
  },
  {
    id: 1378,
    name: 'Edmonton City Centre (Blatchford Field) Airport',
    city: 'Edmonton',
    country: 'Canada',
    iata: 'YXD',
    icao: 'CYXD',
    latitude: 53.5724983215,
    longitude: -113.521003723,
    altitude: 2202,
    schengen: false
  },
  {
    id: 1379,
    name: 'Saskatoon John G. Diefenbaker International Airport',
    city: 'Saskatoon',
    country: 'Canada',
    iata: 'YXE',
    icao: 'CYXE',
    latitude: 52.1707992553711,
    longitude: -106.699996948242,
    altitude: 1653,
    schengen: false
  },
  {
    id: 1380,
    name: 'Medicine Hat Airport',
    city: 'Medicine Hat',
    country: 'Canada',
    iata: 'YXH',
    icao: 'CYXH',
    latitude: 50.0189018249512,
    longitude: -110.721000671387,
    altitude: 2352,
    schengen: false
  },
  {
    id: 1381,
    name: 'Fort St John Airport',
    city: 'Fort Saint John',
    country: 'Canada',
    iata: 'YXJ',
    icao: 'CYXJ',
    latitude: 56.2380981445313,
    longitude: -120.73999786377,
    altitude: 2280,
    schengen: false
  },
  {
    id: 1382,
    name: 'Rimouski Airport',
    city: 'Rimouski',
    country: 'Canada',
    iata: 'YXK',
    icao: 'CYXK',
    latitude: 48.4780998229981,
    longitude: -68.4969024658203,
    altitude: 82,
    schengen: false
  },
  {
    id: 1383,
    name: 'Sioux Lookout Airport',
    city: 'Sioux Lookout',
    country: 'Canada',
    iata: 'YXL',
    icao: 'CYXL',
    latitude: 50.113899230957,
    longitude: -91.9052963256836,
    altitude: 1258,
    schengen: false
  },
  {
    id: 1384,
    name: 'Whale Cove Airport',
    city: 'Whale Cove',
    country: 'Canada',
    iata: 'YXN',
    icao: 'CYXN',
    latitude: 62.2400016785,
    longitude: -92.5980987549,
    altitude: 40,
    schengen: false
  },
  {
    id: 1385,
    name: 'Pangnirtung Airport',
    city: 'Pangnirtung',
    country: 'Canada',
    iata: 'YXP',
    icao: 'CYXP',
    latitude: 66.1449966431,
    longitude: -65.7136001587,
    altitude: 75,
    schengen: false
  },
  {
    id: 1386,
    name: 'Beaver Creek Airport',
    city: 'Beaver Creek',
    country: 'Canada',
    iata: 'YXQ',
    icao: 'CYXQ',
    latitude: 62.4103012084961,
    longitude: -140.867004394531,
    altitude: 2131,
    schengen: false
  },
  {
    id: 1387,
    name: 'Earlton (Timiskaming Regional) Airport',
    city: 'Earlton',
    country: 'Canada',
    iata: 'YXR',
    icao: 'CYXR',
    latitude: 47.6974006546,
    longitude: -79.8473453522,
    altitude: 800,
    schengen: false
  },
  {
    id: 1388,
    name: 'Prince George Airport',
    city: 'Prince George',
    country: 'Canada',
    iata: 'YXS',
    icao: 'CYXS',
    latitude: 53.8894004822,
    longitude: -122.679000854,
    altitude: 2267,
    schengen: false
  },
  {
    id: 1389,
    name: 'Northwest Regional Airport Terrace-Kitimat',
    city: 'Terrace',
    country: 'Canada',
    iata: 'YXT',
    icao: 'CYXT',
    latitude: 54.468498,
    longitude: -128.576009,
    altitude: 713,
    schengen: false
  },
  {
    id: 1390,
    name: 'London Airport',
    city: 'London',
    country: 'Canada',
    iata: 'YXU',
    icao: 'CYXU',
    latitude: 43.035599,
    longitude: -81.1539,
    altitude: 912,
    schengen: false
  },
  {
    id: 1391,
    name: 'Abbotsford Airport',
    city: 'Abbotsford',
    country: 'Canada',
    iata: 'YXX',
    icao: 'CYXX',
    latitude: 49.0252990722656,
    longitude: -122.361000061035,
    altitude: 195,
    schengen: false
  },
  {
    id: 1392,
    name: 'Whitehorse / Erik Nielsen International Airport',
    city: 'Whitehorse',
    country: 'Canada',
    iata: 'YXY',
    icao: 'CYXY',
    latitude: 60.7095985413,
    longitude: -135.067001343,
    altitude: 2317,
    schengen: false
  },
  {
    id: 1393,
    name: 'Wawa Airport',
    city: 'Wawa',
    country: 'Canada',
    iata: 'YXZ',
    icao: 'CYXZ',
    latitude: 47.9667015075684,
    longitude: -84.7866973876953,
    altitude: 942,
    schengen: false
  },
  {
    id: 1394,
    name: 'North Bay Airport',
    city: 'North Bay',
    country: 'Canada',
    iata: 'YYB',
    icao: 'CYYB',
    latitude: 46.3636016845703,
    longitude: -79.4227981567383,
    altitude: 1215,
    schengen: false
  },
  {
    id: 1395,
    name: 'Calgary International Airport',
    city: 'Calgary',
    country: 'Canada',
    iata: 'YYC',
    icao: 'CYYC',
    latitude: 51.113899231,
    longitude: -114.019996643,
    altitude: 3557,
    schengen: false
  },
  {
    id: 1396,
    name: 'Smithers Airport',
    city: 'Smithers',
    country: 'Canada',
    iata: 'YYD',
    icao: 'CYYD',
    latitude: 54.8246994018555,
    longitude: -127.182998657227,
    altitude: 1712,
    schengen: false
  },
  {
    id: 1397,
    name: 'Fort Nelson Airport',
    city: 'Fort Nelson',
    country: 'Canada',
    iata: 'YYE',
    icao: 'CYYE',
    latitude: 58.8363990784,
    longitude: -122.597000122,
    altitude: 1253,
    schengen: false
  },
  {
    id: 1398,
    name: 'Penticton Airport',
    city: 'Penticton',
    country: 'Canada',
    iata: 'YYF',
    icao: 'CYYF',
    latitude: 49.4631004333496,
    longitude: -119.601997375488,
    altitude: 1129,
    schengen: false
  },
  {
    id: 1399,
    name: 'Charlottetown Airport',
    city: 'Charlottetown',
    country: 'Canada',
    iata: 'YYG',
    icao: 'CYYG',
    latitude: 46.2900009155274,
    longitude: -63.1211013793945,
    altitude: 160,
    schengen: false
  },
  {
    id: 1400,
    name: 'Taloyoak Airport',
    city: 'Spence Bay',
    country: 'Canada',
    iata: 'YYH',
    icao: 'CYYH',
    latitude: 69.5466995239,
    longitude: -93.5766983032,
    altitude: 92,
    schengen: false
  },
  {
    id: 1401,
    name: 'Victoria International Airport',
    city: 'Victoria',
    country: 'Canada',
    iata: 'YYJ',
    icao: 'CYYJ',
    latitude: 48.646900177,
    longitude: -123.426002502,
    altitude: 63,
    schengen: false
  },
  {
    id: 1402,
    name: 'Lynn Lake Airport',
    city: 'Lynn Lake',
    country: 'Canada',
    iata: 'YYL',
    icao: 'CYYL',
    latitude: 56.863899230957,
    longitude: -101.075996398926,
    altitude: 1170,
    schengen: false
  },
  {
    id: 1403,
    name: 'Swift Current Airport',
    city: 'Swift Current',
    country: 'Canada',
    iata: 'YYN',
    icao: 'CYYN',
    latitude: 50.2919006348,
    longitude: -107.691001892,
    altitude: 2680,
    schengen: false
  },
  {
    id: 1404,
    name: 'Churchill Airport',
    city: 'Churchill',
    country: 'Canada',
    iata: 'YYQ',
    icao: 'CYYQ',
    latitude: 58.739200592041,
    longitude: -94.0650024414063,
    altitude: 94,
    schengen: false
  },
  {
    id: 1405,
    name: 'Goose Bay Airport',
    city: 'Goose Bay',
    country: 'Canada',
    iata: 'YYR',
    icao: 'CYYR',
    latitude: 53.3191986084,
    longitude: -60.4258003235,
    altitude: 160,
    schengen: false
  },
  {
    id: 1406,
    name: "St. John's International Airport",
    city: "St. John's",
    country: 'Canada',
    iata: 'YYT',
    icao: 'CYYT',
    latitude: 47.618598938,
    longitude: -52.7518997192,
    altitude: 461,
    schengen: false
  },
  {
    id: 1407,
    name: 'Kapuskasing Airport',
    city: 'Kapuskasing',
    country: 'Canada',
    iata: 'YYU',
    icao: 'CYYU',
    latitude: 49.4138984680176,
    longitude: -82.4674987792969,
    altitude: 743,
    schengen: false
  },
  {
    id: 1408,
    name: 'Armstrong Airport',
    city: 'Armstrong',
    country: 'Canada',
    iata: 'YYW',
    icao: 'CYYW',
    latitude: 50.2902984619141,
    longitude: -88.9096984863281,
    altitude: 1058,
    schengen: false
  },
  {
    id: 1409,
    name: 'Mont Joli Airport',
    city: 'Mont Joli',
    country: 'Canada',
    iata: 'YYY',
    icao: 'CYYY',
    latitude: 48.6086006164551,
    longitude: -68.2080993652344,
    altitude: 172,
    schengen: false
  },
  {
    id: 1410,
    name: 'Lester B. Pearson International Airport',
    city: 'Toronto',
    country: 'Canada',
    iata: 'YYZ',
    icao: 'CYYZ',
    latitude: 43.6772003174,
    longitude: -79.6305999756,
    altitude: 569,
    schengen: false
  },
  {
    id: 1411,
    name: 'Downsview Airport',
    city: 'Toronto',
    country: 'Canada',
    iata: 'YZD',
    icao: 'CYZD',
    latitude: 43.7425003051758,
    longitude: -79.4655990600586,
    altitude: 652,
    schengen: false
  },
  {
    id: 1412,
    name: 'Gore Bay Manitoulin Airport',
    city: 'Gore Bay',
    country: 'Canada',
    iata: 'YZE',
    icao: 'CYZE',
    latitude: 45.8852996826172,
    longitude: -82.5678024291992,
    altitude: 635,
    schengen: false
  },
  {
    id: 1413,
    name: 'Yellowknife Airport',
    city: 'Yellowknife',
    country: 'Canada',
    iata: 'YZF',
    icao: 'CYZF',
    latitude: 62.4627990722656,
    longitude: -114.440002441406,
    altitude: 675,
    schengen: false
  },
  {
    id: 1414,
    name: 'Salluit Airport',
    city: 'Salluit',
    country: 'Canada',
    iata: 'YZG',
    icao: 'CYZG',
    latitude: 62.1794013977051,
    longitude: -75.6671981811523,
    altitude: 743,
    schengen: false
  },
  {
    id: 1415,
    name: 'Slave Lake Airport',
    city: 'Slave Lake',
    country: 'Canada',
    iata: 'YZH',
    icao: 'CYZH',
    latitude: 55.2930984497,
    longitude: -114.777000427,
    altitude: 1912,
    schengen: false
  },
  {
    id: 1416,
    name: 'Sandspit Airport',
    city: 'Sandspit',
    country: 'Canada',
    iata: 'YZP',
    icao: 'CYZP',
    latitude: 53.2542991638,
    longitude: -131.813995361,
    altitude: 21,
    schengen: false
  },
  {
    id: 1417,
    name: 'Chris Hadfield Airport',
    city: 'Sarnia',
    country: 'Canada',
    iata: 'YZR',
    icao: 'CYZR',
    latitude: 42.9994010925293,
    longitude: -82.3088989257812,
    altitude: 594,
    schengen: false
  },
  {
    id: 1418,
    name: 'Coral Harbour Airport',
    city: 'Coral Harbour',
    country: 'Canada',
    iata: 'YZS',
    icao: 'CYZS',
    latitude: 64.1932983398,
    longitude: -83.3593978882,
    altitude: 210,
    schengen: false
  },
  {
    id: 1419,
    name: 'Port Hardy Airport',
    city: 'Port Hardy',
    country: 'Canada',
    iata: 'YZT',
    icao: 'CYZT',
    latitude: 50.6805992126465,
    longitude: -127.366996765137,
    altitude: 71,
    schengen: false
  },
  {
    id: 1420,
    name: 'Whitecourt Airport',
    city: 'Whitecourt',
    country: 'Canada',
    iata: 'YZU',
    icao: 'CYZU',
    latitude: 54.1439018249512,
    longitude: -115.787002563477,
    altitude: 2567,
    schengen: false
  },
  {
    id: 1421,
    name: 'Sept-Îles Airport',
    city: 'Sept-iles',
    country: 'Canada',
    iata: 'YZV',
    icao: 'CYZV',
    latitude: 50.2233009338379,
    longitude: -66.2656021118164,
    altitude: 180,
    schengen: false
  },
  {
    id: 1422,
    name: 'Teslin Airport',
    city: 'Teslin',
    country: 'Canada',
    iata: 'YZW',
    icao: 'CYZW',
    latitude: 60.1727981567383,
    longitude: -132.74299621582,
    altitude: 2313,
    schengen: false
  },
  {
    id: 1423,
    name: 'CFB Greenwood',
    city: 'Greenwood',
    country: 'Canada',
    iata: 'YZX',
    icao: 'CYZX',
    latitude: 44.9844017028809,
    longitude: -64.9169006347656,
    altitude: 92,
    schengen: false
  },
  {
    id: 1424,
    name: 'Mackenzie Airport',
    city: 'Mackenzie British Columbia',
    country: 'Canada',
    iata: '',
    icao: 'CYZY',
    latitude: 55.304402,
    longitude: -123.132004,
    altitude: 2264,
    schengen: false
  },
  {
    id: 1425,
    name: 'York Landing Airport',
    city: 'York Landing',
    country: 'Canada',
    iata: 'ZAC',
    icao: 'CZAC',
    latitude: 56.0894012451172,
    longitude: -96.0892028808594,
    altitude: 621,
    schengen: false
  },
  {
    id: 1426,
    name: 'Shuswap Regional Airport',
    city: 'Salmon Arm',
    country: 'Canada',
    iata: 'YSN',
    icao: 'CZAM',
    latitude: 50.682802,
    longitude: -119.228996,
    altitude: 1751,
    schengen: false
  },
  {
    id: 1427,
    name: 'Burlington Executive',
    city: 'Burlington',
    country: 'Canada',
    iata: '',
    icao: 'CZBA',
    latitude: 43.4414531515,
    longitude: -79.850102663,
    altitude: 602,
    schengen: false
  },
  {
    id: 1428,
    name: 'Boundary Bay Airport',
    city: 'Boundary Bay',
    country: 'Canada',
    iata: 'YDT',
    icao: 'CZBB',
    latitude: 49.0742,
    longitude: -123.012001,
    altitude: 6,
    schengen: false
  },
  {
    id: 1429,
    name: 'Ilford Airport',
    city: 'Ilford',
    country: 'Canada',
    iata: 'ILF',
    icao: 'CZBD',
    latitude: 56.0614013672,
    longitude: -95.613899231,
    altitude: 642,
    schengen: false
  },
  {
    id: 1430,
    name: 'Bathurst Airport',
    city: 'Bathurst',
    country: 'Canada',
    iata: 'ZBF',
    icao: 'CZBF',
    latitude: 47.629699707,
    longitude: -65.738899231,
    altitude: 193,
    schengen: false
  },
  {
    id: 1431,
    name: 'Bromont (Roland Desourdy) Airport',
    city: 'Bromont',
    country: 'Canada',
    iata: 'ZBM',
    icao: 'CZBM',
    latitude: 45.2907981873,
    longitude: -72.7414016724,
    altitude: 375,
    schengen: false
  },
  {
    id: 1432,
    name: 'Kelsey Airport',
    city: 'Kelsey',
    country: 'Canada',
    iata: 'KES',
    icao: 'CZEE',
    latitude: 56.0374984741,
    longitude: -96.5096969604,
    altitude: 600,
    schengen: false
  },
  {
    id: 1433,
    name: 'Eastmain River Airport',
    city: 'Eastmain River',
    country: 'Canada',
    iata: 'ZEM',
    icao: 'CZEM',
    latitude: 52.2263984680176,
    longitude: -78.5224990844727,
    altitude: 24,
    schengen: false
  },
  {
    id: 1434,
    name: 'Faro Airport',
    city: 'Faro',
    country: 'Canada',
    iata: 'ZFA',
    icao: 'CZFA',
    latitude: 62.2075004577637,
    longitude: -133.376007080078,
    altitude: 2351,
    schengen: false
  },
  {
    id: 1435,
    name: 'Fond-Du-Lac Airport',
    city: 'Fond-Du-Lac',
    country: 'Canada',
    iata: 'ZFD',
    icao: 'CZFD',
    latitude: 59.334400177002,
    longitude: -107.181999206543,
    altitude: 814,
    schengen: false
  },
  {
    id: 1436,
    name: 'Pukatawagan Airport',
    city: 'Pukatawagan',
    country: 'Canada',
    iata: 'XPK',
    icao: 'CZFG',
    latitude: 55.7491989136,
    longitude: -101.26599884,
    altitude: 958,
    schengen: false
  },
  {
    id: 1437,
    name: 'Fort Mcpherson Airport',
    city: 'Fort Mcpherson',
    country: 'Canada',
    iata: 'ZFM',
    icao: 'CZFM',
    latitude: 67.4075012207031,
    longitude: -134.860992431641,
    altitude: 116,
    schengen: false
  },
  {
    id: 1438,
    name: 'Tulita Airport',
    city: 'Tulita',
    country: 'Canada',
    iata: 'ZFN',
    icao: 'CZFN',
    latitude: 64.909697,
    longitude: -125.572998,
    altitude: 332,
    schengen: false
  },
  {
    id: 1439,
    name: 'Grand Forks Airport',
    city: 'Grand Forks',
    country: 'Canada',
    iata: 'ZGF',
    icao: 'CZGF',
    latitude: 49.0155982971191,
    longitude: -118.430999755859,
    altitude: 1720,
    schengen: false
  },
  {
    id: 1440,
    name: 'Gods River Airport',
    city: 'Gods River',
    country: 'Canada',
    iata: 'ZGI',
    icao: 'CZGI',
    latitude: 54.8396987915039,
    longitude: -94.0785980224609,
    altitude: 627,
    schengen: false
  },
  {
    id: 1441,
    name: 'Little Grand Rapids Airport',
    city: 'Little Grand Rapids',
    country: 'Canada',
    iata: 'ZGR',
    icao: 'CZGR',
    latitude: 52.0456008911133,
    longitude: -95.4657974243164,
    altitude: 1005,
    schengen: false
  },
  {
    id: 1442,
    name: 'Jenpeg Airport',
    city: 'Jenpeg',
    country: 'Canada',
    iata: 'ZJG',
    icao: 'CZJG',
    latitude: 54.5189018249512,
    longitude: -98.0460968017578,
    altitude: 729,
    schengen: false
  },
  {
    id: 1443,
    name: 'Swan River Airport',
    city: 'Swan River',
    country: 'Canada',
    iata: 'ZJN',
    icao: 'CZJN',
    latitude: 52.1206016540527,
    longitude: -101.236000061035,
    altitude: 1100,
    schengen: false
  },
  {
    id: 1444,
    name: 'Kashechewan Airport',
    city: 'Kashechewan',
    country: 'Canada',
    iata: 'ZKE',
    icao: 'CZKE',
    latitude: 52.2825012207031,
    longitude: -81.6778030395508,
    altitude: 35,
    schengen: false
  },
  {
    id: 1445,
    name: 'Thicket Portage Airport',
    city: 'Thicket Portage',
    country: 'Canada',
    iata: 'YTD',
    icao: 'CZLQ',
    latitude: 55.3189010620117,
    longitude: -97.7078018188477,
    altitude: 678,
    schengen: false
  },
  {
    id: 1446,
    name: 'Muskrat Dam Airport',
    city: 'Muskrat Dam',
    country: 'Canada',
    iata: 'MSA',
    icao: 'CZMD',
    latitude: 53.4413986206055,
    longitude: -91.7628021240234,
    altitude: 911,
    schengen: false
  },
  {
    id: 1447,
    name: 'South Cariboo Region / 108 Mile Airport',
    city: '108 Mile Ranch',
    country: 'Canada',
    iata: 'ZMH',
    icao: 'CZML',
    latitude: 51.7360992432,
    longitude: -121.333000183,
    altitude: 3126,
    schengen: false
  },
  {
    id: 1448,
    name: 'Pikwitonei Airport',
    city: 'Pikwitonei',
    country: 'Canada',
    iata: 'PIW',
    icao: 'CZMN',
    latitude: 55.5889015197754,
    longitude: -97.1641998291016,
    altitude: 630,
    schengen: false
  },
  {
    id: 1449,
    name: 'Masset Airport',
    city: 'Masset',
    country: 'Canada',
    iata: 'ZMT',
    icao: 'CZMT',
    latitude: 54.0275001525879,
    longitude: -132.125,
    altitude: 25,
    schengen: false
  },
  {
    id: 1450,
    name: 'Poplar River Airport',
    city: 'Poplar River',
    country: 'Canada',
    iata: 'XPP',
    icao: 'CZNG',
    latitude: 52.9965258788,
    longitude: -97.2741937637,
    altitude: 728,
    schengen: false
  },
  {
    id: 1451,
    name: 'Sachigo Lake Airport',
    city: 'Sachigo Lake',
    country: 'Canada',
    iata: 'ZPB',
    icao: 'CZPB',
    latitude: 53.8911018371582,
    longitude: -92.196403503418,
    altitude: 876,
    schengen: false
  },
  {
    id: 1452,
    name: 'Pincher Creek Airport',
    city: 'Pincher Creek',
    country: 'Canada',
    iata: 'WPC',
    icao: 'CZPC',
    latitude: 49.5205993652,
    longitude: -113.997001648,
    altitude: 3903,
    schengen: false
  },
  {
    id: 1453,
    name: 'Round Lake (Weagamow Lake) Airport',
    city: 'Round Lake',
    country: 'Canada',
    iata: 'ZRJ',
    icao: 'CZRJ',
    latitude: 52.9435997009277,
    longitude: -91.3127975463867,
    altitude: 974,
    schengen: false
  },
  {
    id: 1454,
    name: 'Sandy Lake Airport',
    city: 'Sandy Lake',
    country: 'Canada',
    iata: 'ZSJ',
    icao: 'CZSJ',
    latitude: 53.0642013549805,
    longitude: -93.3443984985352,
    altitude: 951,
    schengen: false
  },
  {
    id: 1455,
    name: 'South Indian Lake Airport',
    city: 'South Indian Lake',
    country: 'Canada',
    iata: 'XSI',
    icao: 'CZSN',
    latitude: 56.7928009033,
    longitude: -98.9072036743,
    altitude: 951,
    schengen: false
  },
  {
    id: 1456,
    name: 'Stewart Airport',
    city: 'Stewart',
    country: 'Canada',
    iata: 'ZST',
    icao: 'CZST',
    latitude: 55.935410448,
    longitude: -129.982434511,
    altitude: 24,
    schengen: false
  },
  {
    id: 1457,
    name: 'Prince Rupert/Seal Cove Seaplane Base',
    city: 'Prince Rupert',
    country: 'Canada',
    iata: 'ZSW',
    icao: 'CZSW',
    latitude: 54.3333015441895,
    longitude: -130.283004760742,
    altitude: 0,
    schengen: false
  },
  {
    id: 1458,
    name: 'Shamattawa Airport',
    city: 'Shamattawa',
    country: 'Canada',
    iata: 'ZTM',
    icao: 'CZTM',
    latitude: 55.8656005859375,
    longitude: -92.0813980102539,
    altitude: 289,
    schengen: false
  },
  {
    id: 1459,
    name: 'Ignace Municipal Airport',
    city: 'Ignace',
    country: 'Canada',
    iata: 'ZUC',
    icao: 'CZUC',
    latitude: 49.4296989440918,
    longitude: -91.7177963256836,
    altitude: 1435,
    schengen: false
  },
  {
    id: 1460,
    name: 'Churchill Falls Airport',
    city: 'Churchill Falls',
    country: 'Canada',
    iata: 'ZUM',
    icao: 'CZUM',
    latitude: 53.5619010925293,
    longitude: -64.1063995361328,
    altitude: 1442,
    schengen: false
  },
  {
    id: 1461,
    name: 'Lac Brochet Airport',
    city: 'Lac Brochet',
    country: 'Canada',
    iata: 'XLB',
    icao: 'CZWH',
    latitude: 58.6175003052,
    longitude: -101.46900177,
    altitude: 1211,
    schengen: false
  },
  {
    id: 1462,
    name: 'Wollaston Lake Airport',
    city: 'Wollaston Lake',
    country: 'Canada',
    iata: 'ZWL',
    icao: 'CZWL',
    latitude: 58.1068992614746,
    longitude: -103.171997070313,
    altitude: 1360,
    schengen: false
  },
  {
    id: 1463,
    name: 'Gratiot Community Airport',
    city: 'Kamloops',
    country: 'Canada',
    iata: 'AMN',
    icao: 'KAMN',
    latitude: 43.322101593,
    longitude: -84.68800354,
    altitude: 754,
    schengen: false
  },
  {
    id: 1464,
    name: 'Amílcar Cabral International Airport',
    city: 'Amilcar Cabral',
    country: 'Cape Verde',
    iata: 'SID',
    icao: 'GVAC',
    latitude: 16.7413997650146,
    longitude: -22.9493999481201,
    altitude: 177,
    schengen: false
  },
  {
    id: 1465,
    name: 'Rabil Airport',
    city: 'Boa Vista',
    country: 'Cape Verde',
    iata: 'BVC',
    icao: 'GVBA',
    latitude: 16.1364994049072,
    longitude: -22.8889007568359,
    altitude: 69,
    schengen: false
  },
  {
    id: 1466,
    name: 'Maio Airport',
    city: 'Maio',
    country: 'Cape Verde',
    iata: 'MMO',
    icao: 'GVMA',
    latitude: 15.1559000015259,
    longitude: -23.2136993408203,
    altitude: 36,
    schengen: false
  },
  {
    id: 1467,
    name: 'Praia International Airport',
    city: 'Praia, Santiago Island',
    country: 'Cape Verde',
    iata: 'RAI',
    icao: 'GVNP',
    latitude: 14.9245004653931,
    longitude: -23.4934997558594,
    altitude: 230,
    schengen: false
  },
  {
    id: 1468,
    name: 'São Filipe Airport',
    city: 'Sao Filipe, Fogo Island',
    country: 'Cape Verde',
    iata: 'SFL',
    icao: 'GVSF',
    latitude: 14.8850002289,
    longitude: -24.4799995422,
    altitude: 617,
    schengen: false
  },
  {
    id: 1469,
    name: 'Preguiça Airport',
    city: 'Sao Nocolau Island',
    country: 'Cape Verde',
    iata: 'SNE',
    icao: 'GVSN',
    latitude: 16.588399887085,
    longitude: -24.2847003936768,
    altitude: 669,
    schengen: false
  },
  {
    id: 1470,
    name: 'São Pedro Airport',
    city: 'Sao Vicente Island',
    country: 'Cape Verde',
    iata: 'VXE',
    icao: 'GVSV',
    latitude: 16.8332004547119,
    longitude: -25.0552997589111,
    altitude: 66,
    schengen: false
  },
  {
    id: 1471,
    name: 'Gerrard Smith International Airport',
    city: 'Cayman Brac',
    country: 'Cayman Islands',
    iata: 'CYB',
    icao: 'MWCB',
    latitude: 19.6870002746582,
    longitude: -79.8827972412109,
    altitude: 8,
    schengen: false
  },
  {
    id: 1472,
    name: 'Edward Bodden Airfield',
    city: 'Little Cayman',
    country: 'Cayman Islands',
    iata: 'LYB',
    icao: 'MWCL',
    latitude: 19.660161,
    longitude: -80.088826,
    altitude: 3,
    schengen: false
  },
  {
    id: 1473,
    name: 'Owen Roberts International Airport',
    city: 'Georgetown',
    country: 'Cayman Islands',
    iata: 'GCM',
    icao: 'MWCR',
    latitude: 19.2928009033,
    longitude: -81.3576965332,
    altitude: 8,
    schengen: false
  },
  {
    id: 1474,
    name: "Bangui M'Poko International Airport",
    city: 'Bangui',
    country: 'Central African Republic',
    iata: 'BGF',
    icao: 'FEFF',
    latitude: 4.39847993850708,
    longitude: 18.5188007354736,
    altitude: 1208,
    schengen: false
  },
  {
    id: 1475,
    name: 'Berbérati Airport',
    city: 'Berberati',
    country: 'Central African Republic',
    iata: 'BBT',
    icao: 'FEFT',
    latitude: 4.22158002853394,
    longitude: 15.7863998413086,
    altitude: 1929,
    schengen: false
  },
  {
    id: 1476,
    name: 'Sarh Airport',
    city: 'Sarh',
    country: 'Chad',
    iata: 'SRH',
    icao: 'FTTA',
    latitude: 9.14444,
    longitude: 18.374399,
    altitude: 1198,
    schengen: false
  },
  {
    id: 1477,
    name: 'Abeche Airport',
    city: 'Abeche',
    country: 'Chad',
    iata: 'AEH',
    icao: 'FTTC',
    latitude: 13.8470001220703,
    longitude: 20.8442993164063,
    altitude: 1788,
    schengen: false
  },
  {
    id: 1478,
    name: 'Moundou Airport',
    city: 'Moundou',
    country: 'Chad',
    iata: 'MQQ',
    icao: 'FTTD',
    latitude: 8.62440967559814,
    longitude: 16.0713996887207,
    altitude: 1407,
    schengen: false
  },
  {
    id: 1479,
    name: "N'Djamena International Airport",
    city: "N'djamena",
    country: 'Chad',
    iata: 'NDJ',
    icao: 'FTTJ',
    latitude: 12.1337003707886,
    longitude: 15.0340003967285,
    altitude: 968,
    schengen: false
  },
  {
    id: 1480,
    name: 'Am Timan Airport',
    city: 'Am Timan',
    country: 'Chad',
    iata: 'AMC',
    icao: 'FTTN',
    latitude: 11.0340003967,
    longitude: 20.2740001678,
    altitude: 1420,
    schengen: false
  },
  {
    id: 1481,
    name: 'Faya Largeau Airport',
    city: 'Faya-largeau',
    country: 'Chad',
    iata: 'FYT',
    icao: 'FTTY',
    latitude: 17.9171009063721,
    longitude: 19.1110992431641,
    altitude: 771,
    schengen: false
  },
  {
    id: 1482,
    name: 'Chacalluta Airport',
    city: 'Arica',
    country: 'Chile',
    iata: 'ARI',
    icao: 'SCAR',
    latitude: -18.348499,
    longitude: -70.338699,
    altitude: 167,
    schengen: false
  },
  {
    id: 1483,
    name: 'Balmaceda Airport',
    city: 'Balmaceda',
    country: 'Chile',
    iata: 'BBA',
    icao: 'SCBA',
    latitude: -45.9160995483398,
    longitude: -71.6894989013672,
    altitude: 1722,
    schengen: false
  },
  {
    id: 1484,
    name: 'Barriles Airport',
    city: 'Tocopilla',
    country: 'Chile',
    iata: 'TOQ',
    icao: 'SCBE',
    latitude: -22.1410999298096,
    longitude: -70.0628967285156,
    altitude: 3475,
    schengen: false
  },
  {
    id: 1485,
    name: 'El Bosque Airport',
    city: 'Santiago',
    country: 'Chile',
    iata: '',
    icao: 'SCBQ',
    latitude: -33.5617980957031,
    longitude: -70.6884002685547,
    altitude: 1844,
    schengen: false
  },
  {
    id: 1486,
    name: 'Chile Chico Airport',
    city: 'Chile Chico',
    country: 'Chile',
    iata: 'CCH',
    icao: 'SCCC',
    latitude: -46.5833015441895,
    longitude: -71.6874008178711,
    altitude: 1070,
    schengen: false
  },
  {
    id: 1487,
    name: 'El Loa Airport',
    city: 'Calama',
    country: 'Chile',
    iata: 'CJC',
    icao: 'SCCF',
    latitude: -22.4981994628906,
    longitude: -68.9036026000977,
    altitude: 7543,
    schengen: false
  },
  {
    id: 1488,
    name: 'Gral. Bernardo O´Higgins Airport',
    city: 'Chillan',
    country: 'Chile',
    iata: 'YAI',
    icao: 'SCCH',
    latitude: -36.5825004577637,
    longitude: -72.0314025878906,
    altitude: 495,
    schengen: false
  },
  {
    id: 1489,
    name: 'Pdte. Carlos Ibañez del Campo Airport',
    city: 'Punta Arenas',
    country: 'Chile',
    iata: 'PUQ',
    icao: 'SCCI',
    latitude: -53.002602,
    longitude: -70.854599,
    altitude: 139,
    schengen: false
  },
  {
    id: 1490,
    name: 'Caldera Airport',
    city: 'Caldera',
    country: 'Chile',
    iata: '',
    icao: 'SCCL',
    latitude: -27.0781002044678,
    longitude: -70.7953033447266,
    altitude: 180,
    schengen: false
  },
  {
    id: 1491,
    name: 'Teniente Vidal Airport',
    city: 'Coyhaique',
    country: 'Chile',
    iata: 'GXQ',
    icao: 'SCCY',
    latitude: -45.5942001342774,
    longitude: -72.1061019897461,
    altitude: 1020,
    schengen: false
  },
  {
    id: 1492,
    name: 'Diego Aracena Airport',
    city: 'Iquique',
    country: 'Chile',
    iata: 'IQQ',
    icao: 'SCDA',
    latitude: -20.5352001190186,
    longitude: -70.1812973022461,
    altitude: 155,
    schengen: false
  },
  {
    id: 1493,
    name: 'Comodoro Arturo Merino Benítez International Airport',
    city: 'Santiago',
    country: 'Chile',
    iata: 'SCL',
    icao: 'SCEL',
    latitude: -33.3930015563965,
    longitude: -70.7857971191406,
    altitude: 1555,
    schengen: false
  },
  {
    id: 1494,
    name: 'Ricardo García Posada Airport',
    city: 'El Salvador',
    country: 'Chile',
    iata: 'ESR',
    icao: 'SCES',
    latitude: -26.3111000061035,
    longitude: -69.7651977539063,
    altitude: 5240,
    schengen: false
  },
  {
    id: 1495,
    name: 'Cerro Moreno Airport',
    city: 'Antofagasta',
    country: 'Chile',
    iata: 'ANF',
    icao: 'SCFA',
    latitude: -23.4444999694824,
    longitude: -70.4450988769531,
    altitude: 455,
    schengen: false
  },
  {
    id: 1496,
    name: 'Capitan Fuentes Martinez Airport Airport',
    city: 'Porvenir',
    country: 'Chile',
    iata: 'WPR',
    icao: 'SCFM',
    latitude: -53.2537002563477,
    longitude: -70.3191986083984,
    altitude: 104,
    schengen: false
  },
  {
    id: 1497,
    name: 'Futaleufú Airport',
    city: 'Futaleufu',
    country: 'Chile',
    iata: 'FFU',
    icao: 'SCFT',
    latitude: -43.1892013549805,
    longitude: -71.8510971069336,
    altitude: 1148,
    schengen: false
  },
  {
    id: 1498,
    name: 'Isla San Felix Airport',
    city: 'Isla San Felix',
    country: 'Chile',
    iata: '',
    icao: 'SCFX',
    latitude: -26.2938995361328,
    longitude: -80.0961990356445,
    altitude: 165,
    schengen: false
  },
  {
    id: 1499,
    name: 'María Dolores Airport',
    city: 'Los Angeles',
    country: 'Chile',
    iata: 'LSQ',
    icao: 'SCGE',
    latitude: -37.4016990661621,
    longitude: -72.4253997802734,
    altitude: 374,
    schengen: false
  },
  {
    id: 1500,
    name: 'Guardiamarina Zañartu Airport',
    city: 'Puerto Williams',
    country: 'Chile',
    iata: 'WPU',
    icao: 'SCGZ',
    latitude: -54.9310989379883,
    longitude: -67.6262969970703,
    altitude: 88,
    schengen: false
  },
  {
    id: 1501,
    name: 'Chamonate Airport',
    city: 'Copiapo',
    country: 'Chile',
    iata: '',
    icao: 'SCHA',
    latitude: -27.2968997955,
    longitude: -70.4131011963,
    altitude: 984,
    schengen: false
  },
  {
    id: 1502,
    name: 'Cochrane Airport',
    city: 'Cochrane',
    country: 'Chile',
    iata: 'LGR',
    icao: 'SCHR',
    latitude: -47.2438011169434,
    longitude: -72.5884017944336,
    altitude: 643,
    schengen: false
  },
  {
    id: 1503,
    name: 'General Freire Airport',
    city: 'Curico',
    country: 'Chile',
    iata: '',
    icao: 'SCIC',
    latitude: -34.966667175293,
    longitude: -71.2163925170898,
    altitude: 722,
    schengen: false
  },
  {
    id: 1504,
    name: 'Carriel Sur Airport',
    city: 'Concepcion',
    country: 'Chile',
    iata: 'CCP',
    icao: 'SCIE',
    latitude: -36.772701,
    longitude: -73.063103,
    altitude: 26,
    schengen: false
  },
  {
    id: 1505,
    name: 'Mataveri Airport',
    city: 'Easter Island',
    country: 'Chile',
    iata: 'IPC',
    icao: 'SCIP',
    latitude: -27.1648006439,
    longitude: -109.42199707,
    altitude: 227,
    schengen: false
  },
  {
    id: 1506,
    name: 'Robinson Crusoe Airport',
    city: 'San Juan Bautista',
    country: 'Chile',
    iata: '',
    icao: 'SCIR',
    latitude: -33.6650009155,
    longitude: -78.9297027588,
    altitude: 433,
    schengen: false
  },
  {
    id: 1507,
    name: 'Cañal Bajo Carlos - Hott Siebert Airport',
    city: 'Osorno',
    country: 'Chile',
    iata: 'ZOS',
    icao: 'SCJO',
    latitude: -40.611198425293,
    longitude: -73.0609970092773,
    altitude: 187,
    schengen: false
  },
  {
    id: 1508,
    name: 'Vallenar Airport',
    city: 'Vallenar',
    country: 'Chile',
    iata: 'VLR',
    icao: 'SCLL',
    latitude: -28.596399307251,
    longitude: -70.7559967041016,
    altitude: 1725,
    schengen: false
  },
  {
    id: 1509,
    name: 'Tte. Julio Gallardo Airport',
    city: 'Puerto Natales',
    country: 'Chile',
    iata: 'PNT',
    icao: 'SCNT',
    latitude: -51.671501159668,
    longitude: -72.5283966064453,
    altitude: 217,
    schengen: false
  },
  {
    id: 1510,
    name: 'Pucón Airport',
    city: 'Pucon',
    country: 'Chile',
    iata: 'ZPC',
    icao: 'SCPC',
    latitude: -39.2928009033203,
    longitude: -71.915901184082,
    altitude: 853,
    schengen: false
  },
  {
    id: 1511,
    name: 'San Pedro de Atacama Airport',
    city: 'San Pedro de Atacama',
    country: 'Chile',
    iata: '',
    icao: 'SCPE',
    latitude: -22.9216995239258,
    longitude: -68.1584014892578,
    altitude: 7960,
    schengen: false
  },
  {
    id: 1512,
    name: 'Mocopulli Airport',
    city: 'Castro',
    country: 'Chile',
    iata: 'MHC',
    icao: 'SCPQ',
    latitude: -42.340388,
    longitude: -73.715693,
    altitude: 528,
    schengen: false
  },
  {
    id: 1513,
    name: 'Chañaral Airport',
    city: 'Chañaral',
    country: 'Chile',
    iata: 'CNR',
    icao: 'SCRA',
    latitude: -26.3325004577637,
    longitude: -70.6072998046875,
    altitude: 97,
    schengen: false
  },
  {
    id: 1514,
    name: 'De La Independencia Airport',
    city: 'Rancagua',
    country: 'Chile',
    iata: 'QRC',
    icao: 'SCRG',
    latitude: -34.173698425293,
    longitude: -70.7757034301758,
    altitude: 1446,
    schengen: false
  },
  {
    id: 1515,
    name: 'La Florida Airport',
    city: 'La Serena',
    country: 'Chile',
    iata: 'LSC',
    icao: 'SCSE',
    latitude: -29.916201,
    longitude: -71.199501,
    altitude: 481,
    schengen: false
  },
  {
    id: 1516,
    name: 'San Sebastián Airport',
    city: 'San Sebastian',
    country: 'Chile',
    iata: '',
    icao: 'SCSS',
    latitude: -53.3170013427734,
    longitude: -68.6500015258789,
    altitude: 50,
    schengen: false
  },
  {
    id: 1517,
    name: 'Eulogio Sánchez Airport',
    city: 'Santiago',
    country: 'Chile',
    iata: '',
    icao: 'SCTB',
    latitude: -33.456298828125,
    longitude: -70.5466995239258,
    altitude: 2129,
    schengen: false
  },
  {
    id: 1518,
    name: 'Maquehue Airport',
    city: 'Temuco',
    country: 'Chile',
    iata: 'PZS',
    icao: 'SCTC',
    latitude: -38.766799926758,
    longitude: -72.637100219727,
    altitude: 304,
    schengen: false
  },
  {
    id: 1519,
    name: 'El Tepual Airport',
    city: 'Puerto Montt',
    country: 'Chile',
    iata: 'PMC',
    icao: 'SCTE',
    latitude: -41.4388999938965,
    longitude: -73.0940017700195,
    altitude: 294,
    schengen: false
  },
  {
    id: 1520,
    name: 'Panguilemo Airport',
    city: 'Talca',
    country: 'Chile',
    iata: 'TLX',
    icao: 'SCTL',
    latitude: -35.377799987793,
    longitude: -71.6016998291016,
    altitude: 371,
    schengen: false
  },
  {
    id: 1521,
    name: 'Chaitén Airport',
    city: 'Chaiten',
    country: 'Chile',
    iata: 'WCH',
    icao: 'SCTN',
    latitude: -42.9328002929688,
    longitude: -72.6990966796875,
    altitude: 13,
    schengen: false
  },
  {
    id: 1522,
    name: 'Victoria Airport',
    city: 'Victoria',
    country: 'Chile',
    iata: 'ZIC',
    icao: 'SCTO',
    latitude: -38.2456016540527,
    longitude: -72.3486022949219,
    altitude: 1148,
    schengen: false
  },
  {
    id: 1523,
    name: 'Las Breas Airport',
    city: 'Taltal',
    country: 'Chile',
    iata: 'TTC',
    icao: 'SCTT',
    latitude: -25.5643005371094,
    longitude: -70.3759002685547,
    altitude: 2580,
    schengen: false
  },
  {
    id: 1524,
    name: 'Pichoy Airport',
    city: 'Valdivia',
    country: 'Chile',
    iata: 'ZAL',
    icao: 'SCVD',
    latitude: -39.6500015259,
    longitude: -73.0860977173,
    altitude: 59,
    schengen: false
  },
  {
    id: 1525,
    name: 'Viña del mar Airport',
    city: 'Vina del Mar',
    country: 'Chile',
    iata: 'KNA',
    icao: 'SCVM',
    latitude: -32.9496,
    longitude: -71.4786,
    altitude: 461,
    schengen: false
  },
  {
    id: 1526,
    name: 'Renk Airport',
    city: 'Huangshan',
    country: 'China',
    iata: '',
    icao: 'HSRN',
    latitude: 11.8324692723,
    longitude: 32.8104972839,
    altitude: 1278,
    schengen: false
  },
  {
    id: 1527,
    name: 'Beijing Capital International Airport',
    city: 'Beijing',
    country: 'China',
    iata: 'PEK',
    icao: 'ZBAA',
    latitude: 40.0801010131836,
    longitude: 116.584999084473,
    altitude: 116,
    schengen: false
  },
  {
    id: 1528,
    name: 'Beijing Xijiao Airport',
    city: 'Beijing',
    country: 'China',
    iata: '',
    icao: 'ZBBB',
    latitude: 39.9608,
    longitude: 116.257004,
    altitude: 0,
    schengen: false
  },
  {
    id: 1529,
    name: 'Chengde Puning Airport',
    city: 'Chengde',
    country: 'China',
    iata: 'CDE',
    icao: 'ZBCD',
    latitude: 41.1225,
    longitude: 118.073889,
    altitude: 0,
    schengen: false
  },
  {
    id: 1530,
    name: 'Chifeng Airport',
    city: 'Chifeng',
    country: 'China',
    iata: 'CIF',
    icao: 'ZBCF',
    latitude: 42.2350006103516,
    longitude: 118.907997131348,
    altitude: 0,
    schengen: false
  },
  {
    id: 1531,
    name: 'Changzhi Airport',
    city: 'Changzhi',
    country: 'China',
    iata: 'CIH',
    icao: 'ZBCZ',
    latitude: 36.247501,
    longitude: 113.125999,
    altitude: 0,
    schengen: false
  },
  {
    id: 1532,
    name: 'Ordos Ejin Horo Airport',
    city: 'Dongsheng',
    country: 'China',
    iata: 'DSN',
    icao: 'ZBDS',
    latitude: 39.49,
    longitude: 109.861388889,
    altitude: 4557,
    schengen: false
  },
  {
    id: 1533,
    name: 'Datong Airport',
    city: 'Datong',
    country: 'China',
    iata: 'DAT',
    icao: 'ZBDT',
    latitude: 40.060299,
    longitude: 113.482002,
    altitude: 3442,
    schengen: false
  },
  {
    id: 1534,
    name: 'Erenhot Saiwusu International Airport',
    city: 'Erenhot',
    country: 'China',
    iata: 'ERL',
    icao: 'ZBER',
    latitude: 43.4225,
    longitude: 112.096667,
    altitude: 3301,
    schengen: false
  },
  {
    id: 1535,
    name: "Arxan Yi'ershi Airport",
    city: 'Arxan',
    country: 'China',
    iata: 'YIE',
    icao: 'ZBES',
    latitude: 47.3106,
    longitude: 119.9117,
    altitude: 2925,
    schengen: false
  },
  {
    id: 1536,
    name: 'Handan Airport',
    city: 'Handan',
    country: 'China',
    iata: 'HDG',
    icao: 'ZBHD',
    latitude: 36.5258333333,
    longitude: 114.425555556,
    altitude: 229,
    schengen: false
  },
  {
    id: 1537,
    name: 'Baita International Airport',
    city: 'Hohhot',
    country: 'China',
    iata: 'HET',
    icao: 'ZBHH',
    latitude: 40.851398,
    longitude: 111.823997,
    altitude: 3556,
    schengen: false
  },
  {
    id: 1538,
    name: 'Holingol Huolinhe Airport',
    city: 'Holingol',
    country: 'China',
    iata: 'HUO',
    icao: 'ZBHZ',
    latitude: 45.487222,
    longitude: 119.407222,
    altitude: 0,
    schengen: false
  },
  {
    id: 1539,
    name: 'Dongshan Airport',
    city: 'Hailar',
    country: 'China',
    iata: 'HLD',
    icao: 'ZBLA',
    latitude: 49.2050018311,
    longitude: 119.824996948,
    altitude: 2169,
    schengen: false
  },
  {
    id: 1540,
    name: 'Linfen Qiaoli Airport',
    city: 'LINFEN',
    country: 'China',
    iata: 'LFQ',
    icao: 'ZBLF',
    latitude: 36.132629,
    longitude: 111.641236,
    altitude: 1483,
    schengen: false
  },
  {
    id: 1541,
    name: 'Lüliang Airport',
    city: 'Lvliang',
    country: 'China',
    iata: 'LLV',
    icao: 'ZBLL',
    latitude: 37.683333,
    longitude: 111.142778,
    altitude: 0,
    schengen: false
  },
  {
    id: 1542,
    name: 'Manzhouli Xijiao Airport',
    city: 'Manzhouli',
    country: 'China',
    iata: 'NZH',
    icao: 'ZBMZ',
    latitude: 49.566667,
    longitude: 117.33,
    altitude: 2231,
    schengen: false
  },
  {
    id: 1543,
    name: 'Beijing Nanyuan Airport',
    city: 'Beijing',
    country: 'China',
    iata: 'NAY',
    icao: 'ZBNY',
    latitude: 39.7827987670898,
    longitude: 116.388000488281,
    altitude: 0,
    schengen: false
  },
  {
    id: 1544,
    name: 'Baotou Airport',
    city: 'Baotou',
    country: 'China',
    iata: 'BAV',
    icao: 'ZBOW',
    latitude: 40.560001373291,
    longitude: 109.997001647949,
    altitude: 3321,
    schengen: false
  },
  {
    id: 1545,
    name: 'Shanhaiguan Airport',
    city: 'Qinhuangdao',
    country: 'China',
    iata: 'SHP',
    icao: 'ZBSH',
    latitude: 39.968102,
    longitude: 119.731003,
    altitude: 30,
    schengen: false
  },
  {
    id: 1546,
    name: 'Shijiazhuang Daguocun International Airport',
    city: 'Shijiazhuang',
    country: 'China',
    iata: 'SJW',
    icao: 'ZBSJ',
    latitude: 38.2807006835937,
    longitude: 114.696998596191,
    altitude: 233,
    schengen: false
  },
  {
    id: 1547,
    name: 'Tianjin Binhai International Airport',
    city: 'Tianjin',
    country: 'China',
    iata: 'TSN',
    icao: 'ZBTJ',
    latitude: 39.1244010925,
    longitude: 117.346000671,
    altitude: 10,
    schengen: false
  },
  {
    id: 1548,
    name: 'Tongliao Airport',
    city: 'Tongliao',
    country: 'China',
    iata: 'TGO',
    icao: 'ZBTL',
    latitude: 43.556702,
    longitude: 122.199997,
    altitude: 2395,
    schengen: false
  },
  {
    id: 1549,
    name: 'Ulanqab Jining Airport',
    city: 'Wulanchabu',
    country: 'China',
    iata: 'UCB',
    icao: 'ZBUC',
    latitude: 41.129722,
    longitude: 113.108056,
    altitude: 0,
    schengen: false
  },
  {
    id: 1550,
    name: 'Wuhai Airport',
    city: 'Wuhai',
    country: 'China',
    iata: 'WUA',
    icao: 'ZBUH',
    latitude: 39.7934,
    longitude: 106.7993,
    altitude: 3650,
    schengen: false
  },
  {
    id: 1551,
    name: 'Ulanhot Airport',
    city: 'Ulanhot',
    country: 'China',
    iata: 'HLH',
    icao: 'ZBUL',
    latitude: 46.195333,
    longitude: 122.008333,
    altitude: 0,
    schengen: false
  },
  {
    id: 1552,
    name: 'Xilinhot Airport',
    city: 'Xilinhot',
    country: 'China',
    iata: 'XIL',
    icao: 'ZBXH',
    latitude: 43.9155998229981,
    longitude: 115.963996887207,
    altitude: 0,
    schengen: false
  },
  {
    id: 1553,
    name: 'Xinzhou Wutaishan Airport',
    city: 'Xinzhou',
    country: 'China',
    iata: 'WUT',
    icao: 'ZBXZ',
    latitude: 38.597456,
    longitude: 112.969173,
    altitude: 2527,
    schengen: false
  },
  {
    id: 1554,
    name: 'Yuncheng Guangong Airport',
    city: 'Yuncheng',
    country: 'China',
    iata: 'YCU',
    icao: 'ZBYC',
    latitude: 35.116391,
    longitude: 111.031388889,
    altitude: 1242,
    schengen: false
  },
  {
    id: 1555,
    name: 'Taiyuan Wusu Airport',
    city: 'Taiyuan',
    country: 'China',
    iata: 'TYN',
    icao: 'ZBYN',
    latitude: 37.746898651123,
    longitude: 112.627998352051,
    altitude: 2575,
    schengen: false
  },
  {
    id: 1556,
    name: 'Bayannur Tianjitai Airport',
    city: 'Bayannur',
    country: 'China',
    iata: 'RLK',
    icao: 'ZBYZ',
    latitude: 40.926,
    longitude: 107.7428,
    altitude: 3400,
    schengen: false
  },
  {
    id: 1557,
    name: 'Chengjisihan Airport',
    city: 'Zhalantun',
    country: 'China',
    iata: 'NZL',
    icao: 'ZBZL',
    latitude: 47.865833,
    longitude: 122.767503,
    altitude: 928,
    schengen: false
  },
  {
    id: 1558,
    name: 'Beihai Airport',
    city: 'Beihai',
    country: 'China',
    iata: 'BHY',
    icao: 'ZGBH',
    latitude: 21.5394,
    longitude: 109.293999,
    altitude: 0,
    schengen: false
  },
  {
    id: 1559,
    name: 'Changde Airport',
    city: 'Changde',
    country: 'China',
    iata: 'CGD',
    icao: 'ZGCD',
    latitude: 28.9188995361,
    longitude: 111.63999939,
    altitude: 128,
    schengen: false
  },
  {
    id: 1560,
    name: 'Zhijiang Airport',
    city: 'Zhijiang',
    country: 'China',
    iata: 'HJJ',
    icao: 'ZGCJ',
    latitude: 27.4411111111,
    longitude: 109.7,
    altitude: 882,
    schengen: false
  },
  {
    id: 1561,
    name: 'Dayong Airport',
    city: 'Dayong',
    country: 'China',
    iata: 'DYG',
    icao: 'ZGDY',
    latitude: 29.1028,
    longitude: 110.443001,
    altitude: 692,
    schengen: false
  },
  {
    id: 1562,
    name: 'Guangzhou Baiyun International Airport',
    city: 'Guangzhou',
    country: 'China',
    iata: 'CAN',
    icao: 'ZGGG',
    latitude: 23.3924007415771,
    longitude: 113.299003601074,
    altitude: 50,
    schengen: false
  },
  {
    id: 1563,
    name: 'Changsha Huanghua International Airport',
    city: 'Changcha',
    country: 'China',
    iata: 'CSX',
    icao: 'ZGHA',
    latitude: 28.1891994476,
    longitude: 113.220001221,
    altitude: 217,
    schengen: false
  },
  {
    id: 1564,
    name: 'Hechi Jinchengjiang Airport',
    city: 'Hechi',
    country: 'China',
    iata: 'HCJ',
    icao: 'ZGHC',
    latitude: 24.805,
    longitude: 107.6997,
    altitude: 2221,
    schengen: false
  },
  {
    id: 1565,
    name: 'Hengyang Nanyue Airport',
    city: 'Hengyang',
    country: 'China',
    iata: 'HNY',
    icao: 'ZGHY',
    latitude: 26.9053,
    longitude: 112.627998,
    altitude: 0,
    schengen: false
  },
  {
    id: 1566,
    name: 'Guilin Liangjiang International Airport',
    city: 'Guilin',
    country: 'China',
    iata: 'KWL',
    icao: 'ZGKL',
    latitude: 25.2180995941162,
    longitude: 110.039001464844,
    altitude: 570,
    schengen: false
  },
  {
    id: 1567,
    name: 'Lingling Airport',
    city: 'Yongzhou',
    country: 'China',
    iata: 'LLF',
    icao: 'ZGLG',
    latitude: 26.338661,
    longitude: 111.610043,
    altitude: 340,
    schengen: false
  },
  {
    id: 1568,
    name: 'Meixian Airport',
    city: 'Meixian',
    country: 'China',
    iata: 'MXZ',
    icao: 'ZGMX',
    latitude: 24.3500003814697,
    longitude: 116.133003234863,
    altitude: 0,
    schengen: false
  },
  {
    id: 1569,
    name: 'Nanning Wuxu Airport',
    city: 'Nanning',
    country: 'China',
    iata: 'NNG',
    icao: 'ZGNN',
    latitude: 22.6082992553711,
    longitude: 108.171997070313,
    altitude: 421,
    schengen: false
  },
  {
    id: 1570,
    name: 'Jieyang Chaoshan International Airport',
    city: 'Shantou',
    country: 'China',
    iata: 'SWA',
    icao: 'ZGOW',
    latitude: 23.552,
    longitude: 116.5033,
    altitude: 0,
    schengen: false
  },
  {
    id: 1571,
    name: 'Zhuhai Jinwan Airport',
    city: 'Zhuhai',
    country: 'China',
    iata: 'ZUH',
    icao: 'ZGSD',
    latitude: 22.006399,
    longitude: 113.375999,
    altitude: 23,
    schengen: false
  },
  {
    id: 1572,
    name: 'Shaoyang Wugang Airport',
    city: 'Shaoyang',
    country: 'China',
    iata: 'WGN',
    icao: 'ZGSY',
    latitude: 26.802,
    longitude: 110.642,
    altitude: 1444,
    schengen: false
  },
  {
    id: 1573,
    name: "Shenzhen Bao'an International Airport",
    city: 'Shenzhen',
    country: 'China',
    iata: 'SZX',
    icao: 'ZGSZ',
    latitude: 22.6392993927002,
    longitude: 113.810997009277,
    altitude: 13,
    schengen: false
  },
  {
    id: 1574,
    name: 'Wuzhou Changzhoudao Airport',
    city: 'Wuzhou',
    country: 'China',
    iata: 'WUZ',
    icao: 'ZGWZ',
    latitude: 23.456699,
    longitude: 111.248001,
    altitude: 89,
    schengen: false
  },
  {
    id: 1575,
    name: 'Liuzhou Bailian Airport',
    city: 'Liuzhou',
    country: 'China',
    iata: 'LZH',
    icao: 'ZGZH',
    latitude: 24.2075,
    longitude: 109.390999,
    altitude: 295,
    schengen: false
  },
  {
    id: 1576,
    name: 'Zhanjiang Airport',
    city: 'Zhanjiang',
    country: 'China',
    iata: 'ZHA',
    icao: 'ZGZJ',
    latitude: 21.214399,
    longitude: 110.358002,
    altitude: 125,
    schengen: false
  },
  {
    id: 1577,
    name: 'Zhengzhou Xinzheng International Airport',
    city: 'Zhengzhou',
    country: 'China',
    iata: 'CGO',
    icao: 'ZHCC',
    latitude: 34.5196990967,
    longitude: 113.841003418,
    altitude: 495,
    schengen: false
  },
  {
    id: 1578,
    name: 'Enshi Airport',
    city: 'Enshi',
    country: 'China',
    iata: 'ENH',
    icao: 'ZHES',
    latitude: 30.3202991486,
    longitude: 109.48500061,
    altitude: 1605,
    schengen: false
  },
  {
    id: 1579,
    name: 'Guangzhou MR Air Base',
    city: 'Guanghua',
    country: 'China',
    iata: 'LHK',
    icao: 'ZHGH',
    latitude: 32.3894004821777,
    longitude: 111.694999694824,
    altitude: 0,
    schengen: false
  },
  {
    id: 1580,
    name: 'Wuhan Tianhe International Airport',
    city: 'Wuhan',
    country: 'China',
    iata: 'WUH',
    icao: 'ZHHH',
    latitude: 30.7838,
    longitude: 114.208,
    altitude: 113,
    schengen: false
  },
  {
    id: 1581,
    name: 'Luoyang Airport',
    city: 'Luoyang',
    country: 'China',
    iata: 'LYA',
    icao: 'ZHLY',
    latitude: 34.7411003113,
    longitude: 112.388000488,
    altitude: 840,
    schengen: false
  },
  {
    id: 1582,
    name: 'Nanyang Jiangying Airport',
    city: 'Nanyang',
    country: 'China',
    iata: 'NNY',
    icao: 'ZHNY',
    latitude: 32.980801,
    longitude: 112.614998,
    altitude: 840,
    schengen: false
  },
  {
    id: 1583,
    name: 'Shennongjia Hongping Airport',
    city: 'Shennongjia',
    country: 'China',
    iata: 'HPG',
    icao: 'ZHSN',
    latitude: 31.626,
    longitude: 110.34,
    altitude: 8365,
    schengen: false
  },
  {
    id: 1584,
    name: 'Shiyan Wudangshan Airport',
    city: 'Shiyan',
    country: 'China',
    iata: 'WDS',
    icao: 'ZHSY',
    latitude: 32.591667,
    longitude: 110.907778,
    altitude: 0,
    schengen: false
  },
  {
    id: 1585,
    name: 'Xiangyang Liuji Airport',
    city: 'Xiangfan',
    country: 'China',
    iata: 'XFN',
    icao: 'ZHXF',
    latitude: 32.1506,
    longitude: 112.291,
    altitude: 0,
    schengen: false
  },
  {
    id: 1586,
    name: 'Yichang Sanxia Airport',
    city: 'Yichang',
    country: 'China',
    iata: 'YIH',
    icao: 'ZHYC',
    latitude: 30.55655,
    longitude: 111.479988,
    altitude: 673,
    schengen: false
  },
  {
    id: 1587,
    name: 'Haikou Meilan International Airport',
    city: 'Haikou',
    country: 'China',
    iata: 'HAK',
    icao: 'ZJHK',
    latitude: 19.9349002838135,
    longitude: 110.458999633789,
    altitude: 75,
    schengen: false
  },
  {
    id: 1588,
    name: "Qionghai Bo'ao Airport",
    city: 'Qionghai',
    country: 'China',
    iata: 'BAR',
    icao: 'ZJQH',
    latitude: 19.13824,
    longitude: 110.454775,
    altitude: 30,
    schengen: false
  },
  {
    id: 1589,
    name: 'Sanya Phoenix International Airport',
    city: 'Sanya',
    country: 'China',
    iata: 'SYX',
    icao: 'ZJSY',
    latitude: 18.3029003143311,
    longitude: 109.412002563477,
    altitude: 92,
    schengen: false
  },
  {
    id: 1590,
    name: 'Ankang Wulipu Airport',
    city: 'Ankang',
    country: 'China',
    iata: 'AKA',
    icao: 'ZLAK',
    latitude: 32.708099,
    longitude: 108.931,
    altitude: 860,
    schengen: false
  },
  {
    id: 1591,
    name: 'Lanzhou City Airport',
    city: 'Lanzhou',
    country: 'China',
    iata: '',
    icao: 'ZLAN',
    latitude: 36.033333,
    longitude: 103.86667,
    altitude: 5040,
    schengen: false
  },
  {
    id: 1592,
    name: 'Dunhuang Airport',
    city: 'Dunhuang',
    country: 'China',
    iata: 'DNH',
    icao: 'ZLDH',
    latitude: 40.1610984802246,
    longitude: 94.809196472168,
    altitude: 0,
    schengen: false
  },
  {
    id: 1593,
    name: 'Delingha Airport',
    city: 'Haixi',
    country: 'China',
    iata: 'HXD',
    icao: 'ZLDL',
    latitude: 37.125286,
    longitude: 97.268658,
    altitude: 9843,
    schengen: false
  },
  {
    id: 1594,
    name: 'Golmud Airport',
    city: 'Golmud',
    country: 'China',
    iata: 'GOQ',
    icao: 'ZLGM',
    latitude: 36.4006,
    longitude: 94.786102,
    altitude: 9334,
    schengen: false
  },
  {
    id: 1595,
    name: 'Guyuan Liupanshan Airport',
    city: 'Guyuan',
    country: 'China',
    iata: 'GYU',
    icao: 'ZLGY',
    latitude: 36.0788888889,
    longitude: 106.216944444,
    altitude: 5696,
    schengen: false
  },
  {
    id: 1596,
    name: 'Huatugou Airport',
    city: 'Mengnai',
    country: 'China',
    iata: 'HTT',
    icao: 'ZLHX',
    latitude: 38.201984,
    longitude: 90.841495,
    altitude: 2945,
    schengen: false
  },
  {
    id: 1597,
    name: 'Hanzhong Chenggu Airport',
    city: 'Hanzhong',
    country: 'China',
    iata: 'HZG',
    icao: 'ZLHZ',
    latitude: 33.134136,
    longitude: 107.206014,
    altitude: 0,
    schengen: false
  },
  {
    id: 1598,
    name: 'Yinchuan Airport',
    city: 'Yinchuan',
    country: 'China',
    iata: 'INC',
    icao: 'ZLIC',
    latitude: 38.481899,
    longitude: 106.009003,
    altitude: 0,
    schengen: false
  },
  {
    id: 1599,
    name: 'Jining Qufu Airport',
    city: 'Jining',
    country: 'China',
    iata: 'JNG',
    icao: 'ZLJN',
    latitude: 35.292778,
    longitude: 116.346667,
    altitude: 134,
    schengen: false
  },
  {
    id: 1600,
    name: 'Jiayuguan Airport',
    city: 'Jiayuguan',
    country: 'China',
    iata: 'JGN',
    icao: 'ZLJQ',
    latitude: 39.856899,
    longitude: 98.3414,
    altitude: 5112,
    schengen: false
  },
  {
    id: 1601,
    name: 'Lanzhou Zhongchuan Airport',
    city: 'Lanzhou',
    country: 'China',
    iata: 'LHW',
    icao: 'ZLLL',
    latitude: 36.5152015686,
    longitude: 103.620002747,
    altitude: 6388,
    schengen: false
  },
  {
    id: 1602,
    name: 'Longnan Chengzhou Airport',
    city: 'Longnan',
    country: 'China',
    iata: 'LNL',
    icao: 'ZLLN',
    latitude: 33.788,
    longitude: 105.797,
    altitude: 3707,
    schengen: false
  },
  {
    id: 1603,
    name: 'Qingyang Airport',
    city: 'Qingyang',
    country: 'China',
    iata: 'IQN',
    icao: 'ZLQY',
    latitude: 35.799702,
    longitude: 107.602997,
    altitude: 0,
    schengen: false
  },
  {
    id: 1604,
    name: "Xi'an Xiguan Airport",
    city: "Xi\\'AN",
    country: 'China',
    iata: 'SIA',
    icao: 'ZLSN',
    latitude: 34.376701,
    longitude: 109.120003,
    altitude: 0,
    schengen: false
  },
  {
    id: 1605,
    name: 'Gannan Xiahe Airport',
    city: 'Xiahe city',
    country: 'China',
    iata: 'GXH',
    icao: 'ZLXH',
    latitude: 34.8105,
    longitude: 102.6447,
    altitude: 10510,
    schengen: false
  },
  {
    id: 1606,
    name: 'Xining Caojiabu Airport',
    city: 'Xining',
    country: 'China',
    iata: 'XNN',
    icao: 'ZLXN',
    latitude: 36.5275,
    longitude: 102.042999,
    altitude: 7119,
    schengen: false
  },
  {
    id: 1607,
    name: "Xi'an Xianyang International Airport",
    city: "Xi'an",
    country: 'China',
    iata: 'XIY',
    icao: 'ZLXY',
    latitude: 34.447102,
    longitude: 108.751999,
    altitude: 1572,
    schengen: false
  },
  {
    id: 1608,
    name: "Yan'an Ershilipu Airport",
    city: "Yan'an",
    country: 'China',
    iata: 'ENY',
    icao: 'ZLYA',
    latitude: 36.636902,
    longitude: 109.554001,
    altitude: 3100,
    schengen: false
  },
  {
    id: 1609,
    name: 'Yulin Yuyang Airport',
    city: 'Yulin',
    country: 'China',
    iata: 'UYN',
    icao: 'ZLYL',
    latitude: 38.35971,
    longitude: 109.590927,
    altitude: 0,
    schengen: false
  },
  {
    id: 1610,
    name: 'Zhongwei Shapotou Airport',
    city: 'Zhongwei',
    country: 'China',
    iata: 'ZHY',
    icao: 'ZLZW',
    latitude: 37.573125,
    longitude: 105.154454,
    altitude: 8202,
    schengen: false
  },
  {
    id: 1611,
    name: 'Cangyuan Washan Airport',
    city: 'Cangyuan',
    country: 'China',
    iata: 'CWJ',
    icao: 'ZPCW',
    latitude: 23.273889,
    longitude: 99.373611,
    altitude: 0,
    schengen: false
  },
  {
    id: 1612,
    name: 'Dali Airport',
    city: 'Dali',
    country: 'China',
    iata: 'DLU',
    icao: 'ZPDL',
    latitude: 25.649401,
    longitude: 100.319,
    altitude: 7050,
    schengen: false
  },
  {
    id: 1613,
    name: 'Diqing Airport',
    city: 'Shangri-La',
    country: 'China',
    iata: 'DIG',
    icao: 'ZPDQ',
    latitude: 27.7936,
    longitude: 99.6772,
    altitude: 10761,
    schengen: false
  },
  {
    id: 1614,
    name: 'Xishuangbanna Gasa Airport',
    city: 'Jinghonggasa',
    country: 'China',
    iata: 'JHG',
    icao: 'ZPJH',
    latitude: 21.9738998413086,
    longitude: 100.76000213623,
    altitude: 1815,
    schengen: false
  },
  {
    id: 1615,
    name: 'Lancang Jingmai Airport',
    city: 'Lancang Lahu',
    country: 'China',
    iata: 'JMJ',
    icao: 'ZPJM',
    latitude: 22.415833,
    longitude: 99.786389,
    altitude: 0,
    schengen: false
  },
  {
    id: 1616,
    name: 'Lijiang Airport',
    city: 'Lijiang',
    country: 'China',
    iata: 'LJG',
    icao: 'ZPLJ',
    latitude: 26.6800003052,
    longitude: 100.246002197,
    altitude: 0,
    schengen: false
  },
  {
    id: 1617,
    name: 'Mangshi Airport',
    city: 'Luxi',
    country: 'China',
    iata: 'LUM',
    icao: 'ZPLX',
    latitude: 24.4011,
    longitude: 98.5317,
    altitude: 2890,
    schengen: false
  },
  {
    id: 1618,
    name: 'Kunming Changshui International Airport',
    city: 'Kunming',
    country: 'China',
    iata: 'KMG',
    icao: 'ZPPP',
    latitude: 25.1019444,
    longitude: 102.9291667,
    altitude: 6903,
    schengen: false
  },
  {
    id: 1619,
    name: "Pu'er Simao Airport",
    city: 'Simao',
    country: 'China',
    iata: 'SYM',
    icao: 'ZPSM',
    latitude: 22.793301,
    longitude: 100.959,
    altitude: 0,
    schengen: false
  },
  {
    id: 1620,
    name: 'Zhaotong Airport',
    city: 'Zhaotong',
    country: 'China',
    iata: 'ZAT',
    icao: 'ZPZT',
    latitude: 27.3255996704102,
    longitude: 103.754997253418,
    altitude: 0,
    schengen: false
  },
  {
    id: 1621,
    name: 'Xiamen Gaoqi International Airport',
    city: 'Xiamen',
    country: 'China',
    iata: 'XMN',
    icao: 'ZSAM',
    latitude: 24.5440006256104,
    longitude: 118.127998352051,
    altitude: 59,
    schengen: false
  },
  {
    id: 1622,
    name: 'Anqing Tianzhushan Airport',
    city: 'Anqing',
    country: 'China',
    iata: 'AQG',
    icao: 'ZSAQ',
    latitude: 30.582199,
    longitude: 117.050003,
    altitude: 0,
    schengen: false
  },
  {
    id: 1623,
    name: 'Bengbu Airport',
    city: 'Bengbu',
    country: 'China',
    iata: 'BFU',
    icao: 'ZSBB',
    latitude: 32.8477333333,
    longitude: 117.320244444,
    altitude: 100,
    schengen: false
  },
  {
    id: 1624,
    name: 'Changzhou Benniu Airport',
    city: 'Changzhou',
    country: 'China',
    iata: 'CZX',
    icao: 'ZSCG',
    latitude: 31.919701,
    longitude: 119.778999,
    altitude: 0,
    schengen: false
  },
  {
    id: 1625,
    name: 'Nanchang Changbei International Airport',
    city: 'Nanchang',
    country: 'China',
    iata: 'KHN',
    icao: 'ZSCN',
    latitude: 28.8649997711182,
    longitude: 115.900001525879,
    altitude: 143,
    schengen: false
  },
  {
    id: 1626,
    name: 'Fuyang Xiguan Airport',
    city: 'Fuyang',
    country: 'China',
    iata: 'FUG',
    icao: 'ZSFY',
    latitude: 32.882157,
    longitude: 115.734364,
    altitude: 104,
    schengen: false
  },
  {
    id: 1627,
    name: 'Fuzhou Changle International Airport',
    city: 'Fuzhou',
    country: 'China',
    iata: 'FOC',
    icao: 'ZSFZ',
    latitude: 25.9351005554199,
    longitude: 119.66300201416,
    altitude: 46,
    schengen: false
  },
  {
    id: 1628,
    name: 'Ganzhou Airport',
    city: 'Ganzhou',
    country: 'China',
    iata: 'KOW',
    icao: 'ZSGZ',
    latitude: 25.853333,
    longitude: 114.778889,
    altitude: 387,
    schengen: false
  },
  {
    id: 1629,
    name: 'Hangzhou Xiaoshan International Airport',
    city: 'Hangzhou',
    country: 'China',
    iata: 'HGH',
    icao: 'ZSHC',
    latitude: 30.2294998168945,
    longitude: 120.43399810791,
    altitude: 23,
    schengen: false
  },
  {
    id: 1630,
    name: 'Jingdezhen Airport',
    city: 'Jingdezhen',
    country: 'China',
    iata: 'JDZ',
    icao: 'ZSJD',
    latitude: 29.3386001587,
    longitude: 117.176002502,
    altitude: 112,
    schengen: false
  },
  {
    id: 1631,
    name: 'Jiujiang Lushan Airport',
    city: 'Jiujiang',
    country: 'China',
    iata: 'JIU',
    icao: 'ZSJJ',
    latitude: 29.476944,
    longitude: 115.801111,
    altitude: 0,
    schengen: false
  },
  {
    id: 1632,
    name: 'Yaoqiang Airport',
    city: 'Jinan',
    country: 'China',
    iata: 'TNA',
    icao: 'ZSJN',
    latitude: 36.8572006225586,
    longitude: 117.216003417969,
    altitude: 76,
    schengen: false
  },
  {
    id: 1633,
    name: 'Quzhou Airport',
    city: 'Quzhou',
    country: 'China',
    iata: 'JUZ',
    icao: 'ZSJU',
    latitude: 28.965799,
    longitude: 118.899002,
    altitude: 0,
    schengen: false
  },
  {
    id: 1634,
    name: 'Longyan Guanzhishan Airport',
    city: 'Longyan',
    country: 'China',
    iata: 'LCX',
    icao: 'ZSLD',
    latitude: 25.6746997833,
    longitude: 116.747001648,
    altitude: 1225,
    schengen: false
  },
  {
    id: 1635,
    name: 'Lianyungang Airport',
    city: 'Lianyungang',
    country: 'China',
    iata: 'LYG',
    icao: 'ZSLG',
    latitude: 34.571667,
    longitude: 118.873611,
    altitude: 0,
    schengen: false
  },
  {
    id: 1636,
    name: 'Huangyan Luqiao Airport',
    city: 'Huangyan',
    country: 'China',
    iata: 'HYN',
    icao: 'ZSLQ',
    latitude: 28.5622005462646,
    longitude: 121.429000854492,
    altitude: 0,
    schengen: false
  },
  {
    id: 1637,
    name: 'Shubuling Airport',
    city: 'Linyi',
    country: 'China',
    iata: 'LYI',
    icao: 'ZSLY',
    latitude: 35.0461006164551,
    longitude: 118.412002563477,
    altitude: 0,
    schengen: false
  },
  {
    id: 1638,
    name: 'Ningbo Lishe International Airport',
    city: 'Ninbo',
    country: 'China',
    iata: 'NGB',
    icao: 'ZSNB',
    latitude: 29.8267002105713,
    longitude: 121.46199798584,
    altitude: 13,
    schengen: false
  },
  {
    id: 1639,
    name: 'Nanjing Lukou Airport',
    city: 'Nanjing',
    country: 'China',
    iata: 'NKG',
    icao: 'ZSNJ',
    latitude: 31.742000579834,
    longitude: 118.861999511719,
    altitude: 49,
    schengen: false
  },
  {
    id: 1640,
    name: 'Hefei Luogang International Airport',
    city: 'Hefei',
    country: 'China',
    iata: 'HFE',
    icao: 'ZSOF',
    latitude: 31.7800006866455,
    longitude: 117.297996520996,
    altitude: 108,
    schengen: false
  },
  {
    id: 1641,
    name: 'Shanghai Pudong International Airport',
    city: 'Shanghai',
    country: 'China',
    iata: 'PVG',
    icao: 'ZSPD',
    latitude: 31.1434001922607,
    longitude: 121.805000305176,
    altitude: 13,
    schengen: false
  },
  {
    id: 1642,
    name: 'Liuting Airport',
    city: 'Qingdao',
    country: 'China',
    iata: 'TAO',
    icao: 'ZSQD',
    latitude: 36.2661018372,
    longitude: 120.374000549,
    altitude: 33,
    schengen: false
  },
  {
    id: 1643,
    name: 'Quanzhou Jinjiang International Airport',
    city: 'Quanzhou',
    country: 'China',
    iata: 'JJN',
    icao: 'ZSQZ',
    latitude: 24.7964,
    longitude: 118.589996,
    altitude: 0,
    schengen: false
  },
  {
    id: 1644,
    name: 'Rugao Air Base',
    city: 'Rugao',
    country: 'China',
    iata: 'RUG',
    icao: 'ZSRG',
    latitude: 32.257884979248,
    longitude: 120.501655578613,
    altitude: 0,
    schengen: false
  },
  {
    id: 1645,
    name: 'Rizhao Shanzihe Airport',
    city: 'Rizhao',
    country: 'China',
    iata: 'RIZ',
    icao: 'ZSRZ',
    latitude: 35.405033,
    longitude: 119.324403,
    altitude: 121,
    schengen: false
  },
  {
    id: 1646,
    name: 'Shanghai Hongqiao International Airport',
    city: 'Shanghai',
    country: 'China',
    iata: 'SHA',
    icao: 'ZSSS',
    latitude: 31.1979007720947,
    longitude: 121.335998535156,
    altitude: 10,
    schengen: false
  },
  {
    id: 1647,
    name: 'Suzhou Guangfu Airport',
    city: 'Suzhou',
    country: 'China',
    iata: 'SZV',
    icao: 'ZSSZ',
    latitude: 31.2631,
    longitude: 120.401001,
    altitude: 0,
    schengen: false
  },
  {
    id: 1648,
    name: 'Tunxi International Airport',
    city: 'Huangshan',
    country: 'China',
    iata: 'TXN',
    icao: 'ZSTX',
    latitude: 29.7332992553711,
    longitude: 118.255996704102,
    altitude: 0,
    schengen: false
  },
  {
    id: 1649,
    name: 'Weifang Airport',
    city: 'Weifang',
    country: 'China',
    iata: 'WEF',
    icao: 'ZSWF',
    latitude: 36.646702,
    longitude: 119.119003,
    altitude: 0,
    schengen: false
  },
  {
    id: 1650,
    name: 'Weihai Airport',
    city: 'Weihai',
    country: 'China',
    iata: 'WEH',
    icao: 'ZSWH',
    latitude: 37.1870994567871,
    longitude: 122.228996276855,
    altitude: 145,
    schengen: false
  },
  {
    id: 1651,
    name: 'Wuhu Air Base',
    city: 'Wuhu',
    country: 'China',
    iata: 'WHU',
    icao: 'ZSWU',
    latitude: 31.3906,
    longitude: 118.408997,
    altitude: 0,
    schengen: false
  },
  {
    id: 1652,
    name: 'Sunan Shuofang International Airport',
    city: 'Wuxi',
    country: 'China',
    iata: 'WUX',
    icao: 'ZSWX',
    latitude: 31.4944000244,
    longitude: 120.429000854,
    altitude: 24,
    schengen: false
  },
  {
    id: 1653,
    name: 'Nanping Wuyishan Airport',
    city: 'Wuyishan',
    country: 'China',
    iata: 'WUS',
    icao: 'ZSWY',
    latitude: 27.7019,
    longitude: 118.000999,
    altitude: 614,
    schengen: false
  },
  {
    id: 1654,
    name: 'Wenzhou Longwan International Airport',
    city: 'Wenzhou',
    country: 'China',
    iata: 'WNZ',
    icao: 'ZSWZ',
    latitude: 27.912201,
    longitude: 120.851997,
    altitude: 0,
    schengen: false
  },
  {
    id: 1655,
    name: 'Xuzhou Guanyin Airport',
    city: 'Xuzhou',
    country: 'China',
    iata: 'XUZ',
    icao: 'ZSXZ',
    latitude: 34.059056,
    longitude: 117.555278,
    altitude: 0,
    schengen: false
  },
  {
    id: 1656,
    name: 'Yangzhou Taizhou Airport',
    city: 'Yangzhou',
    country: 'China',
    iata: 'YTY',
    icao: 'ZSYA',
    latitude: 32.5634,
    longitude: 119.7198,
    altitude: 7,
    schengen: false
  },
  {
    id: 1657,
    name: 'Yichun Mingyueshan Airport',
    city: 'Yichun',
    country: 'China',
    iata: 'YIC',
    icao: 'ZSYC',
    latitude: 27.8025,
    longitude: 114.3062,
    altitude: 430,
    schengen: false
  },
  {
    id: 1658,
    name: 'Yancheng Airport',
    city: 'Yancheng',
    country: 'China',
    iata: 'YNZ',
    icao: 'ZSYN',
    latitude: 33.425833,
    longitude: 120.203056,
    altitude: 0,
    schengen: false
  },
  {
    id: 1659,
    name: 'Yantai Laishan Airport',
    city: 'Yantai',
    country: 'China',
    iata: 'YNT',
    icao: 'ZSYT',
    latitude: 37.4016990661621,
    longitude: 121.372001647949,
    altitude: 59,
    schengen: false
  },
  {
    id: 1660,
    name: 'Yiwu Airport',
    city: 'Yiwu',
    country: 'China',
    iata: 'YIW',
    icao: 'ZSYW',
    latitude: 29.3446998596,
    longitude: 120.031997681,
    altitude: 262,
    schengen: false
  },
  {
    id: 1661,
    name: 'Zhoushan Airport',
    city: 'Zhoushan',
    country: 'China',
    iata: 'HSN',
    icao: 'ZSZS',
    latitude: 29.9342002869,
    longitude: 122.361999512,
    altitude: 3,
    schengen: false
  },
  {
    id: 1662,
    name: 'Ngari Gunsa Airport',
    city: 'Shiquanhe',
    country: 'China',
    iata: 'NGQ',
    icao: 'ZUAL',
    latitude: 32.1,
    longitude: 80.0530555556,
    altitude: 14022,
    schengen: false
  },
  {
    id: 1663,
    name: 'Anshun Huangguoshu Airport',
    city: 'Anshun',
    country: 'China',
    iata: 'AVA',
    icao: 'ZUAS',
    latitude: 26.2605555556,
    longitude: 105.873333333,
    altitude: 4812,
    schengen: false
  },
  {
    id: 1664,
    name: 'Qamdo Bangda Airport',
    city: 'Bangda',
    country: 'China',
    iata: 'BPX',
    icao: 'ZUBD',
    latitude: 30.5536003112793,
    longitude: 97.1082992553711,
    altitude: 14219,
    schengen: false
  },
  {
    id: 1665,
    name: 'Bijie Feixiong Airport',
    city: 'Bijie',
    country: 'China',
    iata: 'BFJ',
    icao: 'ZUBJ',
    latitude: 27.267066,
    longitude: 105.472097,
    altitude: 4751,
    schengen: false
  },
  {
    id: 1666,
    name: 'Chongqing Jiangbei International Airport',
    city: 'Chongqing',
    country: 'China',
    iata: 'CKG',
    icao: 'ZUCK',
    latitude: 29.7192001342773,
    longitude: 106.641998291016,
    altitude: 1365,
    schengen: false
  },
  {
    id: 1667,
    name: 'Daocheng Yading Airport',
    city: 'Daocheng',
    country: 'China',
    iata: 'DCY',
    icao: 'ZUDC',
    latitude: 29.323056,
    longitude: 100.053333,
    altitude: 14472,
    schengen: false
  },
  {
    id: 1668,
    name: 'Dachuan Airport',
    city: 'Dazhou',
    country: 'China',
    iata: 'DAX',
    icao: 'ZUDX',
    latitude: 31.1302,
    longitude: 107.4295,
    altitude: 0,
    schengen: false
  },
  {
    id: 1669,
    name: 'Guangyuan Airport',
    city: 'Guangyuan',
    country: 'China',
    iata: 'GYS',
    icao: 'ZUGU',
    latitude: 32.3911018371582,
    longitude: 105.702003479004,
    altitude: 0,
    schengen: false
  },
  {
    id: 1670,
    name: 'Longdongbao Airport',
    city: 'Guiyang',
    country: 'China',
    iata: 'KWE',
    icao: 'ZUGY',
    latitude: 26.5384998321533,
    longitude: 106.801002502441,
    altitude: 3736,
    schengen: false
  },
  {
    id: 1671,
    name: 'Jiuzhai Huanglong Airport',
    city: 'Jiuzhaigou',
    country: 'China',
    iata: 'JZH',
    icao: 'ZUJZ',
    latitude: 32.8533333333,
    longitude: 103.682222222,
    altitude: 11327,
    schengen: false
  },
  {
    id: 1672,
    name: 'Kangding Airport',
    city: 'Kangding',
    country: 'China',
    iata: 'KGT',
    icao: 'ZUKD',
    latitude: 30.1575,
    longitude: 101.734722,
    altitude: 14042,
    schengen: false
  },
  {
    id: 1673,
    name: 'Kaili Airport',
    city: 'Kaili',
    country: 'China',
    iata: 'KJH',
    icao: 'ZUKJ',
    latitude: 26.972,
    longitude: 107.988,
    altitude: 3115,
    schengen: false
  },
  {
    id: 1674,
    name: 'Libo Airport',
    city: 'Libo',
    country: 'China',
    iata: 'LLB',
    icao: 'ZULB',
    latitude: 25.4525,
    longitude: 107.961667,
    altitude: 0,
    schengen: false
  },
  {
    id: 1675,
    name: 'Lhasa Gonggar Airport',
    city: 'Lhasa',
    country: 'China',
    iata: 'LXA',
    icao: 'ZULS',
    latitude: 29.2978000641,
    longitude: 90.9119033813,
    altitude: 11713,
    schengen: false
  },
  {
    id: 1676,
    name: 'Luzhou Airport',
    city: 'Luzhou',
    country: 'China',
    iata: 'LZO',
    icao: 'ZULZ',
    latitude: 28.8521995544434,
    longitude: 105.392997741699,
    altitude: 0,
    schengen: false
  },
  {
    id: 1677,
    name: 'Zunyi Maotai Airport',
    city: 'Zunyi',
    country: 'China',
    iata: 'WMT',
    icao: 'ZUMT',
    latitude: 27.81638,
    longitude: 106.33268,
    altitude: 4068,
    schengen: false
  },
  {
    id: 1678,
    name: 'Mianyang Airport',
    city: 'Mianyang',
    country: 'China',
    iata: 'MIG',
    icao: 'ZUMY',
    latitude: 31.4281005859375,
    longitude: 104.740997314453,
    altitude: 0,
    schengen: false
  },
  {
    id: 1679,
    name: 'Nanchong Airport',
    city: 'Nanchong',
    country: 'China',
    iata: 'NAO',
    icao: 'ZUNC',
    latitude: 30.79545,
    longitude: 106.1626,
    altitude: 0,
    schengen: false
  },
  {
    id: 1680,
    name: 'Liping Airport',
    city: 'Liping',
    country: 'China',
    iata: 'HZH',
    icao: 'ZUNP',
    latitude: 26.32217,
    longitude: 109.1499,
    altitude: 1620,
    schengen: false
  },
  {
    id: 1681,
    name: 'Nyingchi Airport',
    city: 'Nyingchi',
    country: 'China',
    iata: 'LZY',
    icao: 'ZUNZ',
    latitude: 29.3033008575439,
    longitude: 94.3352966308594,
    altitude: 9675,
    schengen: false
  },
  {
    id: 1682,
    name: 'Liupanshui Yuezhao Airport',
    city: 'Liupanshui',
    country: 'China',
    iata: 'LPF',
    icao: 'ZUPS',
    latitude: 26.609417,
    longitude: 104.979,
    altitude: 0,
    schengen: false
  },
  {
    id: 1683,
    name: 'Tengchong Tuofeng Airport',
    city: 'Tengchong',
    country: 'China',
    iata: 'TCZ',
    icao: 'ZUTC',
    latitude: 24.9380555556,
    longitude: 98.4858333333,
    altitude: 6250,
    schengen: false
  },
  {
    id: 1684,
    name: 'Tongren Fenghuang Airport',
    city: 'Tongren',
    country: 'China',
    iata: 'TEN',
    icao: 'ZUTR',
    latitude: 27.883333,
    longitude: 109.308889,
    altitude: 0,
    schengen: false
  },
  {
    id: 1685,
    name: 'Chengdu Shuangliu International Airport',
    city: 'Chengdu',
    country: 'China',
    iata: 'CTU',
    icao: 'ZUUU',
    latitude: 30.5785007476807,
    longitude: 103.946998596191,
    altitude: 1625,
    schengen: false
  },
  {
    id: 1686,
    name: 'Wanxian Airport',
    city: 'Wanxian',
    country: 'China',
    iata: 'WXN',
    icao: 'ZUWX',
    latitude: 30.8017,
    longitude: 108.433,
    altitude: 0,
    schengen: false
  },
  {
    id: 1687,
    name: 'Xichang Qingshan Airport',
    city: 'Xichang',
    country: 'China',
    iata: 'XIC',
    icao: 'ZUXC',
    latitude: 27.9890995025635,
    longitude: 102.18399810791,
    altitude: 5112,
    schengen: false
  },
  {
    id: 1688,
    name: 'Yibin Caiba Airport',
    city: 'Yibin',
    country: 'China',
    iata: 'YBP',
    icao: 'ZUYB',
    latitude: 28.8005555556,
    longitude: 104.545,
    altitude: 924,
    schengen: false
  },
  {
    id: 1689,
    name: 'Xingyi Airport',
    city: 'Xingyi',
    country: 'China',
    iata: 'ACX',
    icao: 'ZUYI',
    latitude: 25.0863888889,
    longitude: 104.959444444,
    altitude: 4150,
    schengen: false
  },
  {
    id: 1690,
    name: 'Zunyi Xinzhou Airport',
    city: 'Zunyi',
    country: 'China',
    iata: 'ZYI',
    icao: 'ZUZY',
    latitude: 27.5895,
    longitude: 107.0007,
    altitude: 2920,
    schengen: false
  },
  {
    id: 1691,
    name: 'Aksu Airport',
    city: 'Aksu',
    country: 'China',
    iata: 'AKU',
    icao: 'ZWAK',
    latitude: 41.262501,
    longitude: 80.291702,
    altitude: 3816,
    schengen: false
  },
  {
    id: 1692,
    name: 'Alashankou Bole (Bortala) airport',
    city: 'Bole',
    country: 'China',
    iata: 'BPL',
    icao: 'ZWAX',
    latitude: 44.895,
    longitude: 82.3,
    altitude: 1253,
    schengen: false
  },
  {
    id: 1693,
    name: 'Qiemo Yudu Airport',
    city: 'Qiemo',
    country: 'China',
    iata: 'IQM',
    icao: 'ZWCM',
    latitude: 38.233611,
    longitude: 85.465556,
    altitude: 0,
    schengen: false
  },
  {
    id: 1694,
    name: 'Fuyun Koktokay Airport',
    city: 'Fuyun',
    country: 'China',
    iata: 'FYN',
    icao: 'ZWFY',
    latitude: 46.804169,
    longitude: 89.512006,
    altitude: 3081,
    schengen: false
  },
  {
    id: 1695,
    name: 'Hami Airport',
    city: 'Hami',
    country: 'China',
    iata: 'HMI',
    icao: 'ZWHM',
    latitude: 42.8414,
    longitude: 93.669197,
    altitude: 2703,
    schengen: false
  },
  {
    id: 1696,
    name: 'Kuqa Airport',
    city: 'Kuqa',
    country: 'China',
    iata: 'KCA',
    icao: 'ZWKC',
    latitude: 41.677856,
    longitude: 82.872917,
    altitude: 3524,
    schengen: false
  },
  {
    id: 1697,
    name: 'Korla Airport',
    city: 'Korla',
    country: 'China',
    iata: 'KRL',
    icao: 'ZWKL',
    latitude: 41.6977996826172,
    longitude: 86.1288986206055,
    altitude: 0,
    schengen: false
  },
  {
    id: 1698,
    name: 'Karamay Airport',
    city: 'Karamay',
    country: 'China',
    iata: 'KRY',
    icao: 'ZWKM',
    latitude: 45.46655,
    longitude: 84.9527,
    altitude: 0,
    schengen: false
  },
  {
    id: 1699,
    name: 'Kanas Airport',
    city: 'Burqin',
    country: 'China',
    iata: 'KJI',
    icao: 'ZWKN',
    latitude: 48.2223,
    longitude: 86.9959,
    altitude: 3921,
    schengen: false
  },
  {
    id: 1700,
    name: 'Xinyuan Nalati Airport',
    city: 'Xinyuan',
    country: 'China',
    iata: 'NLT',
    icao: 'ZWNL',
    latitude: 43.4318,
    longitude: 83.3786,
    altitude: 3050,
    schengen: false
  },
  {
    id: 1701,
    name: 'Yeerqiang Airport',
    city: 'Yarkant',
    country: 'China',
    iata: 'QSZ',
    icao: 'ZWSC',
    latitude: 38.281055,
    longitude: 77.075192,
    altitude: 4232,
    schengen: false
  },
  {
    id: 1702,
    name: 'Kashgar Airport',
    city: 'Kashi',
    country: 'China',
    iata: 'KHG',
    icao: 'ZWSH',
    latitude: 39.5429000854,
    longitude: 76.0199966431,
    altitude: 4529,
    schengen: false
  },
  {
    id: 1703,
    name: 'Shanshan Airport',
    city: 'Shanshan',
    country: 'China',
    iata: 'SXJ',
    icao: 'ZWSS',
    latitude: 42.9117012023926,
    longitude: 90.2474975585938,
    altitude: 0,
    schengen: false
  },
  {
    id: 1704,
    name: 'Tacheng Airport',
    city: 'Tacheng',
    country: 'China',
    iata: 'TCG',
    icao: 'ZWTC',
    latitude: 46.6725006103516,
    longitude: 83.3407974243164,
    altitude: 0,
    schengen: false
  },
  {
    id: 1705,
    name: 'Hotan Airport',
    city: 'Hotan',
    country: 'China',
    iata: 'HTN',
    icao: 'ZWTN',
    latitude: 37.038501739502,
    longitude: 79.8648986816406,
    altitude: 4672,
    schengen: false
  },
  {
    id: 1706,
    name: 'Turpan Jiaohe Airport',
    city: 'Turpan',
    country: 'China',
    iata: 'TLQ',
    icao: 'ZWTP',
    latitude: 43.0308,
    longitude: 89.0987,
    altitude: 934,
    schengen: false
  },
  {
    id: 1707,
    name: 'Ürümqi Diwopu International Airport',
    city: 'Urumqi',
    country: 'China',
    iata: 'URC',
    icao: 'ZWWW',
    latitude: 43.9071006774902,
    longitude: 87.4741973876953,
    altitude: 2125,
    schengen: false
  },
  {
    id: 1708,
    name: 'Yining Airport',
    city: 'Yining',
    country: 'China',
    iata: 'YIN',
    icao: 'ZWYN',
    latitude: 43.955799,
    longitude: 81.330299,
    altitude: 0,
    schengen: false
  },
  {
    id: 1709,
    name: 'Anshan Air Base',
    city: 'Anshan',
    country: 'China',
    iata: 'AOG',
    icao: 'ZYAS',
    latitude: 41.105301,
    longitude: 122.853996,
    altitude: 0,
    schengen: false
  },
  {
    id: 1710,
    name: 'Longjia Airport',
    city: 'Changchun',
    country: 'China',
    iata: 'CGQ',
    icao: 'ZYCC',
    latitude: 43.9962005615,
    longitude: 125.684997559,
    altitude: 706,
    schengen: false
  },
  {
    id: 1711,
    name: 'Changhai Airport',
    city: 'Changhai',
    country: 'China',
    iata: 'CNI',
    icao: 'ZYCH',
    latitude: 39.2666666667,
    longitude: 122.666944444,
    altitude: 80,
    schengen: false
  },
  {
    id: 1712,
    name: 'Chaoyang Airport',
    city: 'Chaoyang',
    country: 'China',
    iata: 'CHG',
    icao: 'ZYCY',
    latitude: 41.538101,
    longitude: 120.434998,
    altitude: 568,
    schengen: false
  },
  {
    id: 1713,
    name: 'Wudalianchi Dedu Airport',
    city: 'Wudalianchi',
    country: 'China',
    iata: 'DTU',
    icao: 'ZYDU',
    latitude: 48.445,
    longitude: 126.133,
    altitude: 984,
    schengen: false
  },
  {
    id: 1714,
    name: 'Dongji Aiport',
    city: 'Fuyuan',
    country: 'China',
    iata: 'FYJ',
    icao: 'ZYFY',
    latitude: 48.199494,
    longitude: 134.366447,
    altitude: 0,
    schengen: false
  },
  {
    id: 1715,
    name: 'Taiping Airport',
    city: 'Harbin',
    country: 'China',
    iata: 'HRB',
    icao: 'ZYHB',
    latitude: 45.6234016418457,
    longitude: 126.25,
    altitude: 457,
    schengen: false
  },
  {
    id: 1716,
    name: 'Heihe Airport',
    city: 'Heihe',
    country: 'China',
    iata: 'HEK',
    icao: 'ZYHE',
    latitude: 50.1716209371,
    longitude: 127.308883667,
    altitude: 8530,
    schengen: false
  },
  {
    id: 1717,
    name: 'Jiamusi Airport',
    city: 'Jiamusi',
    country: 'China',
    iata: 'JMU',
    icao: 'ZYJM',
    latitude: 46.8433990479,
    longitude: 130.464996338,
    altitude: 262,
    schengen: false
  },
  {
    id: 1718,
    name: 'Jiansanjiang Airport',
    city: 'Jiansanjiang',
    country: 'China',
    iata: 'JSJ',
    icao: 'ZYJS',
    latitude: 47.11,
    longitude: 132.660278,
    altitude: 0,
    schengen: false
  },
  {
    id: 1719,
    name: 'Jixi Xingkaihu Airport',
    city: 'Jixi',
    country: 'China',
    iata: 'JXA',
    icao: 'ZYJX',
    latitude: 45.293,
    longitude: 131.193,
    altitude: 760,
    schengen: false
  },
  {
    id: 1720,
    name: 'Jinzhou Airport',
    city: 'Jinzhou',
    country: 'China',
    iata: 'JNZ',
    icao: 'ZYJZ',
    latitude: 41.1013984680176,
    longitude: 121.061996459961,
    altitude: 0,
    schengen: false
  },
  {
    id: 1721,
    name: 'Lindu Airport',
    city: 'Yinchun',
    country: 'China',
    iata: 'LDS',
    icao: 'ZYLD',
    latitude: 47.7520555556,
    longitude: 129.019125,
    altitude: 791,
    schengen: false
  },
  {
    id: 1722,
    name: 'Yushu Batang Airport',
    city: 'Yushu',
    country: 'China',
    iata: 'YUS',
    icao: 'ZYLS',
    latitude: 32.8363888889,
    longitude: 97.0363888889,
    altitude: 12816,
    schengen: false
  },
  {
    id: 1723,
    name: 'Mudanjiang Hailang International Airport',
    city: 'Mudanjiang',
    country: 'China',
    iata: 'MDG',
    icao: 'ZYMD',
    latitude: 44.5241012573,
    longitude: 129.569000244,
    altitude: 883,
    schengen: false
  },
  {
    id: 1724,
    name: 'Gu-Lian Airport',
    city: 'Mohe County',
    country: 'China',
    iata: 'OHE',
    icao: 'ZYMH',
    latitude: 52.9127777778,
    longitude: 122.43,
    altitude: 1836,
    schengen: false
  },
  {
    id: 1725,
    name: 'Qiqihar Sanjiazi Airport',
    city: 'Qiqihar',
    country: 'China',
    iata: 'NDG',
    icao: 'ZYQQ',
    latitude: 47.2396011352539,
    longitude: 123.917999267578,
    altitude: 477,
    schengen: false
  },
  {
    id: 1726,
    name: 'Songyuan Chaganhu Airport',
    city: 'Songyuan',
    country: 'China',
    iata: 'YSQ',
    icao: 'ZYSQ',
    latitude: 44.938114,
    longitude: 124.550178,
    altitude: 459,
    schengen: false
  },
  {
    id: 1727,
    name: 'Zhoushuizi Airport',
    city: 'Dalian',
    country: 'China',
    iata: 'DLC',
    icao: 'ZYTL',
    latitude: 38.9656982421875,
    longitude: 121.539001464844,
    altitude: 107,
    schengen: false
  },
  {
    id: 1728,
    name: 'Tonghua Sanyuanpu Airport',
    city: 'Tonghua',
    country: 'China',
    iata: 'TNH',
    icao: 'ZYTN',
    latitude: 42.2538888889,
    longitude: 125.703333333,
    altitude: 1200,
    schengen: false
  },
  {
    id: 1729,
    name: 'Taoxian Airport',
    city: 'Shenyang',
    country: 'China',
    iata: 'SHE',
    icao: 'ZYTX',
    latitude: 41.6398010253906,
    longitude: 123.483001708984,
    altitude: 198,
    schengen: false
  },
  {
    id: 1730,
    name: 'Yanji Chaoyangchuan Airport',
    city: 'Yanji',
    country: 'China',
    iata: 'YNJ',
    icao: 'ZYYJ',
    latitude: 42.8828010559,
    longitude: 129.451004028,
    altitude: 624,
    schengen: false
  },
  {
    id: 1731,
    name: 'Yingkou Lanqi Airport',
    city: 'Yingkou',
    country: 'China',
    iata: 'YKH',
    icao: 'ZYYK',
    latitude: 40.542524,
    longitude: 122.3586,
    altitude: 0,
    schengen: false
  },
  {
    id: 1732,
    name: 'Shenyang Dongta Airport',
    city: 'Shenyang',
    country: 'China',
    iata: '',
    icao: 'ZYYY',
    latitude: 41.7844009399414,
    longitude: 123.496002197266,
    altitude: 0,
    schengen: false
  },
  {
    id: 1733,
    name: 'Christmas Island Airport',
    city: 'Christmas Island',
    country: 'Christmas Island',
    iata: 'XCH',
    icao: 'YPXM',
    latitude: -10.4505996704102,
    longitude: 105.690002441406,
    altitude: 916,
    schengen: false
  },
  {
    id: 1734,
    name: 'Cocos (Keeling) Islands Airport',
    city: 'Cocos Keeling Island',
    country: 'Cocos (Keeling) Islands',
    iata: 'CCK',
    icao: 'YPCC',
    latitude: -12.1883001328,
    longitude: 96.8339004517,
    altitude: 10,
    schengen: false
  },
  {
    id: 1735,
    name: 'Araracuara Airport',
    city: 'Araracuara',
    country: 'Colombia',
    iata: 'ACR',
    icao: 'SKAC',
    latitude: -0.5833,
    longitude: -72.4083,
    altitude: 1250,
    schengen: false
  },
  {
    id: 1736,
    name: 'Alcides Fernández Airport',
    city: 'Acandi',
    country: 'Colombia',
    iata: 'ACD',
    icao: 'SKAD',
    latitude: 8.51667,
    longitude: -77.3,
    altitude: 50,
    schengen: false
  },
  {
    id: 1737,
    name: 'Hacaritama Airport',
    city: 'Aguachica',
    country: 'Colombia',
    iata: '',
    icao: 'SKAG',
    latitude: 8.247,
    longitude: -73.5814,
    altitude: 545,
    schengen: false
  },
  {
    id: 1738,
    name: 'Gomez Nino Apiay Air Base',
    city: 'Apiay',
    country: 'Colombia',
    iata: 'API',
    icao: 'SKAP',
    latitude: 4.07607,
    longitude: -73.5627,
    altitude: 1207,
    schengen: false
  },
  {
    id: 1739,
    name: 'El Eden Airport',
    city: 'Armenia',
    country: 'Colombia',
    iata: 'AXM',
    icao: 'SKAR',
    latitude: 4.45278,
    longitude: -75.7664,
    altitude: 3990,
    schengen: false
  },
  {
    id: 1740,
    name: 'Tres De Mayo Airport',
    city: 'Puerto Asis',
    country: 'Colombia',
    iata: 'PUU',
    icao: 'SKAS',
    latitude: 0.505228,
    longitude: -76.5008,
    altitude: 815,
    schengen: false
  },
  {
    id: 1741,
    name: 'Las Flores Airport',
    city: 'El Banco',
    country: 'Colombia',
    iata: 'ELB',
    icao: 'SKBC',
    latitude: 9.04554,
    longitude: -73.9749,
    altitude: 111,
    schengen: false
  },
  {
    id: 1742,
    name: 'Palonegro Airport',
    city: 'Bucaramanga',
    country: 'Colombia',
    iata: 'BGA',
    icao: 'SKBG',
    latitude: 7.1265,
    longitude: -73.1848,
    altitude: 3897,
    schengen: false
  },
  {
    id: 1743,
    name: 'El Dorado International Airport',
    city: 'Bogota',
    country: 'Colombia',
    iata: 'BOG',
    icao: 'SKBO',
    latitude: 4.70159,
    longitude: -74.1469,
    altitude: 8361,
    schengen: false
  },
  {
    id: 1744,
    name: 'Ernesto Cortissoz International Airport',
    city: 'Barranquilla',
    country: 'Colombia',
    iata: 'BAQ',
    icao: 'SKBQ',
    latitude: 10.8896,
    longitude: -74.7808,
    altitude: 98,
    schengen: false
  },
  {
    id: 1745,
    name: 'José Celestino Mutis Airport',
    city: 'Bahia Solano',
    country: 'Colombia',
    iata: 'BSC',
    icao: 'SKBS',
    latitude: 6.20292,
    longitude: -77.3947,
    altitude: 80,
    schengen: false
  },
  {
    id: 1746,
    name: 'Gerardo Tobar López Airport',
    city: 'Buenaventura',
    country: 'Colombia',
    iata: 'BUN',
    icao: 'SKBU',
    latitude: 3.81963,
    longitude: -76.9898,
    altitude: 48,
    schengen: false
  },
  {
    id: 1747,
    name: 'Capurganá Airport',
    city: 'Capurgana',
    country: 'Colombia',
    iata: 'CPB',
    icao: 'SKCA',
    latitude: 8.63333,
    longitude: -77.35,
    altitude: 49,
    schengen: false
  },
  {
    id: 1748,
    name: 'Camilo Daza International Airport',
    city: 'Cucuta',
    country: 'Colombia',
    iata: 'CUC',
    icao: 'SKCC',
    latitude: 7.92757,
    longitude: -72.5115,
    altitude: 1096,
    schengen: false
  },
  {
    id: 1749,
    name: 'Mandinga Airport',
    city: 'Condoto',
    country: 'Colombia',
    iata: 'COG',
    icao: 'SKCD',
    latitude: 5.08333,
    longitude: -76.7,
    altitude: 213,
    schengen: false
  },
  {
    id: 1750,
    name: 'Rafael Nuñez International Airport',
    city: 'Cartagena',
    country: 'Colombia',
    iata: 'CTG',
    icao: 'SKCG',
    latitude: 10.4424,
    longitude: -75.513,
    altitude: 4,
    schengen: false
  },
  {
    id: 1751,
    name: 'Alfonso Bonilla Aragon International Airport',
    city: 'Cali',
    country: 'Colombia',
    iata: 'CLO',
    icao: 'SKCL',
    latitude: 3.54322,
    longitude: -76.3816,
    altitude: 3162,
    schengen: false
  },
  {
    id: 1752,
    name: 'La Florida Airport',
    city: 'Tumaco',
    country: 'Colombia',
    iata: 'TCO',
    icao: 'SKCO',
    latitude: 1.81442,
    longitude: -78.7492,
    altitude: 8,
    schengen: false
  },
  {
    id: 1753,
    name: 'Juan H White Airport',
    city: 'Caucasia',
    country: 'Colombia',
    iata: 'CAQ',
    icao: 'SKCU',
    latitude: 7.96847,
    longitude: -75.1985,
    altitude: 174,
    schengen: false
  },
  {
    id: 1754,
    name: 'Coveñas Airport',
    city: 'Coveñas',
    country: 'Colombia',
    iata: 'CVE',
    icao: 'SKCV',
    latitude: 9.40092,
    longitude: -75.6913,
    altitude: 31,
    schengen: false
  },
  {
    id: 1755,
    name: 'Las Brujas Airport',
    city: 'Corozal',
    country: 'Colombia',
    iata: 'CZU',
    icao: 'SKCZ',
    latitude: 9.33274,
    longitude: -75.2856,
    altitude: 528,
    schengen: false
  },
  {
    id: 1756,
    name: 'El Bagre Airport',
    city: 'El Bagre',
    country: 'Colombia',
    iata: 'EBG',
    icao: 'SKEB',
    latitude: 7.59647,
    longitude: -74.8089,
    altitude: 180,
    schengen: false
  },
  {
    id: 1757,
    name: 'Yariguíes Airport',
    city: 'Barrancabermeja',
    country: 'Colombia',
    iata: 'EJA',
    icao: 'SKEJ',
    latitude: 7.02433,
    longitude: -73.8068,
    altitude: 412,
    schengen: false
  },
  {
    id: 1758,
    name: 'Gustavo Artunduaga Paredes Airport',
    city: 'Florencia',
    country: 'Colombia',
    iata: 'FLA',
    icao: 'SKFL',
    latitude: 1.58919,
    longitude: -75.5644,
    altitude: 803,
    schengen: false
  },
  {
    id: 1759,
    name: 'Santiago Vila Airport',
    city: 'Girardot',
    country: 'Colombia',
    iata: 'GIR',
    icao: 'SKGI',
    latitude: 4.27624,
    longitude: -74.7967,
    altitude: 900,
    schengen: false
  },
  {
    id: 1760,
    name: 'Santa Ana Airport',
    city: 'Cartago',
    country: 'Colombia',
    iata: 'CRC',
    icao: 'SKGO',
    latitude: 4.75818,
    longitude: -75.9557,
    altitude: 2979,
    schengen: false
  },
  {
    id: 1761,
    name: 'Juan Casiano Airport',
    city: 'Guapi',
    country: 'Colombia',
    iata: 'GPI',
    icao: 'SKGP',
    latitude: 2.57013,
    longitude: -77.8986,
    altitude: 164,
    schengen: false
  },
  {
    id: 1762,
    name: 'Guaymaral Airport',
    city: 'Guaymaral',
    country: 'Colombia',
    iata: '',
    icao: 'SKGY',
    latitude: 4.81233,
    longitude: -74.0649,
    altitude: 8390,
    schengen: false
  },
  {
    id: 1763,
    name: 'La Jagua Airport',
    city: 'Garzón',
    country: 'Colombia',
    iata: '',
    icao: 'SKGZ',
    latitude: 2.1464,
    longitude: -75.6944,
    altitude: 2620,
    schengen: false
  },
  {
    id: 1764,
    name: 'Perales Airport',
    city: 'Ibague',
    country: 'Colombia',
    iata: 'IBE',
    icao: 'SKIB',
    latitude: 4.42161,
    longitude: -75.1333,
    altitude: 2999,
    schengen: false
  },
  {
    id: 1765,
    name: 'San Luis Airport',
    city: 'Ipiales',
    country: 'Colombia',
    iata: 'IPI',
    icao: 'SKIP',
    latitude: 0.861925,
    longitude: -77.6718,
    altitude: 9765,
    schengen: false
  },
  {
    id: 1766,
    name: 'Antonio Roldan Betancourt Airport',
    city: 'Carepa',
    country: 'Colombia',
    iata: 'APO',
    icao: 'SKLC',
    latitude: 7.81196,
    longitude: -76.7164,
    altitude: 46,
    schengen: false
  },
  {
    id: 1767,
    name: 'Caucaya Airport',
    city: 'Puerto Leguízamo',
    country: 'Colombia',
    iata: 'LQM',
    icao: 'SKLG',
    latitude: -0.182278,
    longitude: -74.7708,
    altitude: 573,
    schengen: false
  },
  {
    id: 1768,
    name: 'Jorge Isaac Airport',
    city: 'La Mina',
    country: 'Colombia',
    iata: 'MCJ',
    icao: 'SKLM',
    latitude: 11.2325,
    longitude: -72.4901,
    altitude: 281,
    schengen: false
  },
  {
    id: 1769,
    name: 'La Pedrera Airport',
    city: 'La Pedrera',
    country: 'Colombia',
    iata: 'LPD',
    icao: 'SKLP',
    latitude: -1.32861,
    longitude: -69.5797,
    altitude: 590,
    schengen: false
  },
  {
    id: 1770,
    name: 'Alfredo Vásquez Cobo International Airport',
    city: 'Leticia',
    country: 'Colombia',
    iata: 'LET',
    icao: 'SKLT',
    latitude: -4.19355,
    longitude: -69.9432,
    altitude: 277,
    schengen: false
  },
  {
    id: 1771,
    name: 'Enrique Olaya Herrera Airport',
    city: 'Medellin',
    country: 'Colombia',
    iata: 'EOH',
    icao: 'SKMD',
    latitude: 6.220549,
    longitude: -75.590582,
    altitude: 4949,
    schengen: false
  },
  {
    id: 1772,
    name: 'Baracoa Airport',
    city: 'Magangue',
    country: 'Colombia',
    iata: 'MGN',
    icao: 'SKMG',
    latitude: 9.28474,
    longitude: -74.8461,
    altitude: 178,
    schengen: false
  },
  {
    id: 1773,
    name: 'Los Garzones Airport',
    city: 'Monteria',
    country: 'Colombia',
    iata: 'MTR',
    icao: 'SKMR',
    latitude: 8.82374,
    longitude: -75.8258,
    altitude: 36,
    schengen: false
  },
  {
    id: 1774,
    name: 'Fabio Alberto Leon Bentley Airport',
    city: 'Mitu',
    country: 'Colombia',
    iata: 'MVP',
    icao: 'SKMU',
    latitude: 1.25366,
    longitude: -70.2339,
    altitude: 680,
    schengen: false
  },
  {
    id: 1775,
    name: 'La Nubia Airport',
    city: 'Manizales',
    country: 'Colombia',
    iata: 'MZL',
    icao: 'SKMZ',
    latitude: 5.0296,
    longitude: -75.4647,
    altitude: 6871,
    schengen: false
  },
  {
    id: 1776,
    name: 'Reyes Murillo Airport',
    city: 'Nuquí',
    country: 'Colombia',
    iata: 'NQU',
    icao: 'SKNQ',
    latitude: 5.6964,
    longitude: -77.2806,
    altitude: 12,
    schengen: false
  },
  {
    id: 1777,
    name: 'Benito Salas Airport',
    city: 'Neiva',
    country: 'Colombia',
    iata: 'NVA',
    icao: 'SKNV',
    latitude: 2.95015,
    longitude: -75.294,
    altitude: 1464,
    schengen: false
  },
  {
    id: 1778,
    name: 'Aguas Claras Airport',
    city: 'Ocana',
    country: 'Colombia',
    iata: 'OCV',
    icao: 'SKOC',
    latitude: 8.31506,
    longitude: -73.3583,
    altitude: 3850,
    schengen: false
  },
  {
    id: 1779,
    name: 'Otu Airport',
    city: 'Otu',
    country: 'Colombia',
    iata: 'OTU',
    icao: 'SKOT',
    latitude: 7.01037,
    longitude: -74.7155,
    altitude: 2060,
    schengen: false
  },
  {
    id: 1780,
    name: 'Puerto Bolívar Airport',
    city: 'Puerto Bolivar',
    country: 'Colombia',
    iata: '',
    icao: 'SKPB',
    latitude: 12.2215,
    longitude: -71.9848,
    altitude: 90,
    schengen: false
  },
  {
    id: 1781,
    name: 'German Olano Airport',
    city: 'Puerto Carreno',
    country: 'Colombia',
    iata: 'PCR',
    icao: 'SKPC',
    latitude: 6.18472,
    longitude: -67.4932,
    altitude: 177,
    schengen: false
  },
  {
    id: 1782,
    name: 'Obando Airport',
    city: 'Puerto Inírida',
    country: 'Colombia',
    iata: 'PDA',
    icao: 'SKPD',
    latitude: 3.85353,
    longitude: -67.9062,
    altitude: 460,
    schengen: false
  },
  {
    id: 1783,
    name: 'Matecaña International Airport',
    city: 'Pereira',
    country: 'Colombia',
    iata: 'PEI',
    icao: 'SKPE',
    latitude: 4.81267,
    longitude: -75.7395,
    altitude: 4416,
    schengen: false
  },
  {
    id: 1784,
    name: 'Pitalito Airport',
    city: 'Pitalito',
    country: 'Colombia',
    iata: 'PTX',
    icao: 'SKPI',
    latitude: 1.85777,
    longitude: -76.0857,
    altitude: 4212,
    schengen: false
  },
  {
    id: 1785,
    name: 'Guillermo León Valencia Airport',
    city: 'Popayan',
    country: 'Colombia',
    iata: 'PPN',
    icao: 'SKPP',
    latitude: 2.4544,
    longitude: -76.6093,
    altitude: 5687,
    schengen: false
  },
  {
    id: 1786,
    name: 'German Olano Air Base',
    city: 'La Dorada',
    country: 'Colombia',
    iata: 'PAL',
    icao: 'SKPQ',
    latitude: 5.48361,
    longitude: -74.6574,
    altitude: 566,
    schengen: false
  },
  {
    id: 1787,
    name: 'Antonio Narino Airport',
    city: 'Pasto',
    country: 'Colombia',
    iata: 'PSO',
    icao: 'SKPS',
    latitude: 1.39625,
    longitude: -77.2915,
    altitude: 5951,
    schengen: false
  },
  {
    id: 1788,
    name: 'El Embrujo Airport',
    city: 'Providencia',
    country: 'Colombia',
    iata: 'PVA',
    icao: 'SKPV',
    latitude: 13.3569,
    longitude: -81.3583,
    altitude: 10,
    schengen: false
  },
  {
    id: 1789,
    name: 'Paz De Ariporo Airport',
    city: 'Paz De Ariporo',
    country: 'Colombia',
    iata: 'PZA',
    icao: 'SKPZ',
    latitude: 5.87615,
    longitude: -71.8866,
    altitude: 900,
    schengen: false
  },
  {
    id: 1790,
    name: 'Mariquita Airport',
    city: 'Mariquita',
    country: 'Colombia',
    iata: 'MQU',
    icao: 'SKQU',
    latitude: 5.21256,
    longitude: -74.8836,
    altitude: 1531,
    schengen: false
  },
  {
    id: 1791,
    name: 'Jose Maria Córdova International Airport',
    city: 'Rio Negro',
    country: 'Colombia',
    iata: 'MDE',
    icao: 'SKRG',
    latitude: 6.16454,
    longitude: -75.4231,
    altitude: 6955,
    schengen: false
  },
  {
    id: 1792,
    name: 'Almirante Padilla Airport',
    city: 'Rio Hacha',
    country: 'Colombia',
    iata: 'RCH',
    icao: 'SKRH',
    latitude: 11.5262,
    longitude: -72.926,
    altitude: 43,
    schengen: false
  },
  {
    id: 1793,
    name: 'Los Colonizadores Airport',
    city: 'Saravena',
    country: 'Colombia',
    iata: 'RVE',
    icao: 'SKSA',
    latitude: 6.951868,
    longitude: -71.857179,
    altitude: 680,
    schengen: false
  },
  {
    id: 1794,
    name: 'Jorge E. Gonzalez Torres Airport',
    city: 'San Jose Del Guaviare',
    country: 'Colombia',
    iata: 'SJE',
    icao: 'SKSJ',
    latitude: 2.57969,
    longitude: -72.6394,
    altitude: 605,
    schengen: false
  },
  {
    id: 1795,
    name: 'Simón Bolívar International Airport',
    city: 'Santa Marta',
    country: 'Colombia',
    iata: 'SMR',
    icao: 'SKSM',
    latitude: 11.1196,
    longitude: -74.2306,
    altitude: 22,
    schengen: false
  },
  {
    id: 1796,
    name: 'Gustavo Rojas Pinilla International Airport',
    city: 'San Andres Island',
    country: 'Colombia',
    iata: 'ADZ',
    icao: 'SKSP',
    latitude: 12.5836,
    longitude: -81.7112,
    altitude: 19,
    schengen: false
  },
  {
    id: 1797,
    name: 'Eduardo Falla Solano Airport',
    city: 'San Vincente De Caguan',
    country: 'Colombia',
    iata: 'SVI',
    icao: 'SKSV',
    latitude: 2.15217,
    longitude: -74.7663,
    altitude: 920,
    schengen: false
  },
  {
    id: 1798,
    name: 'Tunja Airport',
    city: 'Tunja',
    country: 'Colombia',
    iata: '',
    icao: 'SKTJ',
    latitude: 5.54138,
    longitude: -73.3445,
    altitude: 8940,
    schengen: false
  },
  {
    id: 1799,
    name: 'Golfo de Morrosquillo Airport',
    city: 'Tolu',
    country: 'Colombia',
    iata: 'TLU',
    icao: 'SKTL',
    latitude: 9.50945,
    longitude: -75.5854,
    altitude: 16,
    schengen: false
  },
  {
    id: 1800,
    name: 'Gustavo Vargas Airport',
    city: 'Tame',
    country: 'Colombia',
    iata: 'TME',
    icao: 'SKTM',
    latitude: 6.45108,
    longitude: -71.7603,
    altitude: 1050,
    schengen: false
  },
  {
    id: 1801,
    name: 'Tres Esquinas Air Base',
    city: 'Tres Esquinas',
    country: 'Colombia',
    iata: 'TQS',
    icao: 'SKTQ',
    latitude: 0.7459,
    longitude: -75.234,
    altitude: 585,
    schengen: false
  },
  {
    id: 1802,
    name: 'Marandúa Air Base',
    city: 'Marandúa',
    country: 'Colombia',
    iata: '',
    icao: 'SKUA',
    latitude: 5.52448,
    longitude: -68.6856,
    altitude: 285,
    schengen: false
  },
  {
    id: 1803,
    name: 'Santiago Perez Airport',
    city: 'Arauca',
    country: 'Colombia',
    iata: 'AUC',
    icao: 'SKUC',
    latitude: 7.06888,
    longitude: -70.7369,
    altitude: 420,
    schengen: false
  },
  {
    id: 1804,
    name: 'El Caraño Airport',
    city: 'Quibdo',
    country: 'Colombia',
    iata: 'UIB',
    icao: 'SKUI',
    latitude: 5.69076,
    longitude: -76.6412,
    altitude: 204,
    schengen: false
  },
  {
    id: 1805,
    name: 'Heriberto Gíl Martínez Airport',
    city: 'Tulua',
    country: 'Colombia',
    iata: 'ULQ',
    icao: 'SKUL',
    latitude: 4.08836,
    longitude: -76.2351,
    altitude: 3132,
    schengen: false
  },
  {
    id: 1806,
    name: 'Villa Garzón Airport',
    city: 'Villa Garzon',
    country: 'Colombia',
    iata: 'VGZ',
    icao: 'SKVG',
    latitude: 0.978767,
    longitude: -76.6056,
    altitude: 1248,
    schengen: false
  },
  {
    id: 1807,
    name: 'Alfonso López Pumarejo Airport',
    city: 'Valledupar',
    country: 'Colombia',
    iata: 'VUP',
    icao: 'SKVP',
    latitude: 10.435,
    longitude: -73.2495,
    altitude: 483,
    schengen: false
  },
  {
    id: 1808,
    name: 'Vanguardia Airport',
    city: 'Villavicencio',
    country: 'Colombia',
    iata: 'VVC',
    icao: 'SKVV',
    latitude: 4.16787,
    longitude: -73.6138,
    altitude: 1394,
    schengen: false
  },
  {
    id: 1809,
    name: 'El Yopal Airport',
    city: 'Yopal',
    country: 'Colombia',
    iata: 'EYP',
    icao: 'SKYP',
    latitude: 5.31911,
    longitude: -72.384,
    altitude: 1028,
    schengen: false
  },
  {
    id: 1810,
    name: 'Prince Said Ibrahim International Airport',
    city: 'Moroni',
    country: 'Comoros',
    iata: 'HAH',
    icao: 'FMCH',
    latitude: -11.5337,
    longitude: 43.2719,
    altitude: 93,
    schengen: false
  },
  {
    id: 1811,
    name: 'Mohéli Bandar Es Eslam Airport',
    city: 'Moheli',
    country: 'Comoros',
    iata: 'NWA',
    icao: 'FMCI',
    latitude: -12.2981004714966,
    longitude: 43.7663993835449,
    altitude: 46,
    schengen: false
  },
  {
    id: 1812,
    name: 'Iconi Airport',
    city: 'Moroni',
    country: 'Comoros',
    iata: 'YVA',
    icao: 'FMCN',
    latitude: -11.7108001709,
    longitude: 43.2439002991,
    altitude: 33,
    schengen: false
  },
  {
    id: 1813,
    name: 'Ouani Airport',
    city: 'Anjouan',
    country: 'Comoros',
    iata: 'AJN',
    icao: 'FMCV',
    latitude: -12.1316995620728,
    longitude: 44.4303016662598,
    altitude: 62,
    schengen: false
  },
  {
    id: 1814,
    name: 'Maya-Maya Airport',
    city: 'Brazzaville',
    country: 'Congo (Brazzaville)',
    iata: 'BZV',
    icao: 'FCBB',
    latitude: -4.25169992446899,
    longitude: 15.2530002593994,
    altitude: 1048,
    schengen: false
  },
  {
    id: 1815,
    name: 'Oyo Ollombo Airport',
    city: 'Oyo',
    country: 'Congo (Brazzaville)',
    iata: 'OLL',
    icao: 'FCOD',
    latitude: -1.226666,
    longitude: 15.91,
    altitude: 1073,
    schengen: false
  },
  {
    id: 1816,
    name: 'Impfondo Airport',
    city: 'Impfondo',
    country: 'Congo (Brazzaville)',
    iata: 'ION',
    icao: 'FCOI',
    latitude: 1.61699998378754,
    longitude: 18.0669994354248,
    altitude: 1099,
    schengen: false
  },
  {
    id: 1817,
    name: 'Ngot Nzoungou Airport',
    city: 'Loubomo',
    country: 'Congo (Brazzaville)',
    iata: 'DIS',
    icao: 'FCPL',
    latitude: -4.20635,
    longitude: 12.6599,
    altitude: 1079,
    schengen: false
  },
  {
    id: 1818,
    name: 'Pointe Noire Airport',
    city: 'Pointe-noire',
    country: 'Congo (Brazzaville)',
    iata: 'PNR',
    icao: 'FCPP',
    latitude: -4.81603002548218,
    longitude: 11.8865995407105,
    altitude: 55,
    schengen: false
  },
  {
    id: 1819,
    name: 'Owando Airport',
    city: 'Owando',
    country: 'Congo (Kinshasa)',
    iata: 'FTX',
    icao: 'FCOO',
    latitude: -0.531350016593933,
    longitude: 15.9500999450684,
    altitude: 1214,
    schengen: false
  },
  {
    id: 1820,
    name: 'Ouesso Airport',
    city: 'Ouesso',
    country: 'Congo (Kinshasa)',
    iata: 'OUE',
    icao: 'FCOU',
    latitude: 1.61599004269,
    longitude: 16.0379009247,
    altitude: 1158,
    schengen: false
  },
  {
    id: 1821,
    name: 'Ndjili International Airport',
    city: 'Kinshasa',
    country: 'Congo (Kinshasa)',
    iata: 'FIH',
    icao: 'FZAA',
    latitude: -4.38574981689,
    longitude: 15.4446001053,
    altitude: 1027,
    schengen: false
  },
  {
    id: 1822,
    name: 'Ndolo Airport',
    city: 'Kinshasa',
    country: 'Congo (Kinshasa)',
    iata: 'NLO',
    icao: 'FZAB',
    latitude: -4.32666015625,
    longitude: 15.327500343323,
    altitude: 915,
    schengen: false
  },
  {
    id: 1823,
    name: 'Muanda Airport',
    city: 'Muanda',
    country: 'Congo (Kinshasa)',
    iata: 'MNB',
    icao: 'FZAG',
    latitude: -5.93086004257202,
    longitude: 12.3517999649048,
    altitude: 89,
    schengen: false
  },
  {
    id: 1824,
    name: 'Kitona Base Airport',
    city: 'Kitona Base',
    country: 'Congo (Kinshasa)',
    iata: '',
    icao: 'FZAI',
    latitude: -5.91805982589722,
    longitude: 12.447699546814,
    altitude: 394,
    schengen: false
  },
  {
    id: 1825,
    name: 'Boma Airport',
    city: 'Boma',
    country: 'Congo (Kinshasa)',
    iata: 'BOA',
    icao: 'FZAJ',
    latitude: -5.85400009155273,
    longitude: 13.0640001296997,
    altitude: 26,
    schengen: false
  },
  {
    id: 1826,
    name: 'Tshimpi Airport',
    city: 'Matadi',
    country: 'Congo (Kinshasa)',
    iata: 'MAT',
    icao: 'FZAM',
    latitude: -5.79961013793945,
    longitude: 13.4404001235962,
    altitude: 1115,
    schengen: false
  },
  {
    id: 1827,
    name: 'Inongo Airport',
    city: 'Inongo',
    country: 'Congo (Kinshasa)',
    iata: 'INO',
    icao: 'FZBA',
    latitude: -1.9472199678421,
    longitude: 18.2858009338379,
    altitude: 1040,
    schengen: false
  },
  {
    id: 1828,
    name: 'Nioki Airport',
    city: 'Nioki',
    country: 'Congo (Kinshasa)',
    iata: 'NIO',
    icao: 'FZBI',
    latitude: -2.71749997138977,
    longitude: 17.684700012207,
    altitude: 1043,
    schengen: false
  },
  {
    id: 1829,
    name: 'Bandundu Airport',
    city: 'Bandoundu',
    country: 'Congo (Kinshasa)',
    iata: 'FDU',
    icao: 'FZBO',
    latitude: -3.31132006645203,
    longitude: 17.3817005157471,
    altitude: 1063,
    schengen: false
  },
  {
    id: 1830,
    name: 'Basango Mboliasa Airport',
    city: 'Kiri',
    country: 'Congo (Kinshasa)',
    iata: 'KRZ',
    icao: 'FZBT',
    latitude: -1.43499994277954,
    longitude: 19.0240001678467,
    altitude: 1013,
    schengen: false
  },
  {
    id: 1831,
    name: 'Kikwit Airport',
    city: 'Kikwit',
    country: 'Congo (Kinshasa)',
    iata: 'KKW',
    icao: 'FZCA',
    latitude: -5.03576993942261,
    longitude: 18.7856006622314,
    altitude: 1572,
    schengen: false
  },
  {
    id: 1832,
    name: 'Kahemba Airport',
    city: 'Kahemba',
    country: 'Congo (Kinshasa)',
    iata: '',
    icao: 'FZCF',
    latitude: -7.33300018310547,
    longitude: 19.0170001983643,
    altitude: 3425,
    schengen: false
  },
  {
    id: 1833,
    name: 'Mbandaka Airport',
    city: 'Mbandaka',
    country: 'Congo (Kinshasa)',
    iata: 'MDK',
    icao: 'FZEA',
    latitude: 0.0226000007242,
    longitude: 18.2887001038,
    altitude: 1040,
    schengen: false
  },
  {
    id: 1834,
    name: 'Basankusu Airport',
    city: 'Basankusu',
    country: 'Congo (Kinshasa)',
    iata: 'BSU',
    icao: 'FZEN',
    latitude: 1.2247200012207,
    longitude: 19.7889003753662,
    altitude: 1217,
    schengen: false
  },
  {
    id: 1835,
    name: 'Gbadolite Airport',
    city: 'Gbadolite',
    country: 'Congo (Kinshasa)',
    iata: 'BDT',
    icao: 'FZFD',
    latitude: 4.25321006774902,
    longitude: 20.9752998352051,
    altitude: 1509,
    schengen: false
  },
  {
    id: 1836,
    name: 'Gemena Airport',
    city: 'Gemena',
    country: 'Congo (Kinshasa)',
    iata: 'GMA',
    icao: 'FZFK',
    latitude: 3.23536992073,
    longitude: 19.7712993622,
    altitude: 1378,
    schengen: false
  },
  {
    id: 1837,
    name: 'Kotakoli Airport',
    city: 'Kotakoli',
    country: 'Congo (Kinshasa)',
    iata: 'KLI',
    icao: 'FZFP',
    latitude: 4.15763998031616,
    longitude: 21.650899887085,
    altitude: 1801,
    schengen: false
  },
  {
    id: 1838,
    name: 'Bumbar Airport',
    city: 'Bumba',
    country: 'Congo (Kinshasa)',
    iata: 'BMB',
    icao: 'FZFU',
    latitude: 2.18278,
    longitude: 22.481701,
    altitude: 0,
    schengen: false
  },
  {
    id: 1839,
    name: 'Lisala Airport',
    city: 'Lisala',
    country: 'Congo (Kinshasa)',
    iata: 'LIQ',
    icao: 'FZGA',
    latitude: 2.17066,
    longitude: 21.496901,
    altitude: 1509,
    schengen: false
  },
  {
    id: 1840,
    name: 'Boende Airport',
    city: 'Boende',
    country: 'Congo (Kinshasa)',
    iata: 'BNB',
    icao: 'FZGN',
    latitude: -0.216999992728233,
    longitude: 20.8500003814697,
    altitude: 1168,
    schengen: false
  },
  {
    id: 1841,
    name: 'Ikela Airport',
    city: 'Ikela',
    country: 'Congo (Kinshasa)',
    iata: 'IKL',
    icao: 'FZGV',
    latitude: -1.048109,
    longitude: 23.372501,
    altitude: 1283,
    schengen: false
  },
  {
    id: 1842,
    name: 'Bangoka International Airport',
    city: 'Kisangani',
    country: 'Congo (Kinshasa)',
    iata: 'FKI',
    icao: 'FZIC',
    latitude: 0.481638997793,
    longitude: 25.3379993439,
    altitude: 1417,
    schengen: false
  },
  {
    id: 1843,
    name: 'Matari Airport',
    city: 'Isiro',
    country: 'Congo (Kinshasa)',
    iata: 'IRP',
    icao: 'FZJH',
    latitude: 2.82761001586914,
    longitude: 27.5883007049561,
    altitude: 2438,
    schengen: false
  },
  {
    id: 1844,
    name: 'Bunia Airport',
    city: 'Bunia',
    country: 'Congo (Kinshasa)',
    iata: 'BUX',
    icao: 'FZKA',
    latitude: 1.56571996212006,
    longitude: 30.2208003997803,
    altitude: 4045,
    schengen: false
  },
  {
    id: 1845,
    name: 'Buta Zega Airport',
    city: 'Buta Zega',
    country: 'Congo (Kinshasa)',
    iata: 'BZU',
    icao: 'FZKJ',
    latitude: 2.81835007667542,
    longitude: 24.7936992645264,
    altitude: 1378,
    schengen: false
  },
  {
    id: 1846,
    name: 'Bukavu Kavumu Airport',
    city: 'Bukavu/kavumu',
    country: 'Congo (Kinshasa)',
    iata: 'BKY',
    icao: 'FZMA',
    latitude: -2.30897998809814,
    longitude: 28.8087997436523,
    altitude: 5643,
    schengen: false
  },
  {
    id: 1847,
    name: 'Goma International Airport',
    city: 'Goma',
    country: 'Congo (Kinshasa)',
    iata: 'GOM',
    icao: 'FZNA',
    latitude: -1.67080998420715,
    longitude: 29.2385005950928,
    altitude: 5089,
    schengen: false
  },
  {
    id: 1848,
    name: 'Beni Airport',
    city: 'Beni',
    country: 'Congo (Kinshasa)',
    iata: 'BNC',
    icao: 'FZNP',
    latitude: 0.575,
    longitude: 29.4739,
    altitude: 3517,
    schengen: false
  },
  {
    id: 1849,
    name: 'Kindu Airport',
    city: 'Kindu',
    country: 'Congo (Kinshasa)',
    iata: 'KND',
    icao: 'FZOA',
    latitude: -2.91917991638,
    longitude: 25.9153995514,
    altitude: 1630,
    schengen: false
  },
  {
    id: 1850,
    name: 'Kasongo Airport',
    city: 'Kasongo',
    country: 'Congo (Kinshasa)',
    iata: '',
    icao: 'FZOK',
    latitude: -4.53299999237061,
    longitude: 26.617000579834,
    altitude: 1785,
    schengen: false
  },
  {
    id: 1851,
    name: 'Lubumbashi International Airport',
    city: 'Lubumashi',
    country: 'Congo (Kinshasa)',
    iata: 'FBM',
    icao: 'FZQA',
    latitude: -11.5913000107,
    longitude: 27.5308990479,
    altitude: 4295,
    schengen: false
  },
  {
    id: 1852,
    name: 'Kolwezi Airport',
    city: 'Kolwezi',
    country: 'Congo (Kinshasa)',
    iata: 'KWZ',
    icao: 'FZQM',
    latitude: -10.7658996582031,
    longitude: 25.5056991577148,
    altitude: 5007,
    schengen: false
  },
  {
    id: 1853,
    name: 'Kalemie Airport',
    city: 'Kalemie',
    country: 'Congo (Kinshasa)',
    iata: 'FMI',
    icao: 'FZRF',
    latitude: -5.87555980682373,
    longitude: 29.25,
    altitude: 2569,
    schengen: false
  },
  {
    id: 1854,
    name: 'Kongolo Airport',
    city: 'Kongolo',
    country: 'Congo (Kinshasa)',
    iata: 'KOO',
    icao: 'FZRQ',
    latitude: -5.39444,
    longitude: 26.99,
    altitude: 1850,
    schengen: false
  },
  {
    id: 1855,
    name: 'Kamina Base Airport',
    city: 'Kamina Base',
    country: 'Congo (Kinshasa)',
    iata: 'KMN',
    icao: 'FZSA',
    latitude: -8.6420202255249,
    longitude: 25.2528991699219,
    altitude: 3543,
    schengen: false
  },
  {
    id: 1856,
    name: 'Kananga Airport',
    city: 'Kananga',
    country: 'Congo (Kinshasa)',
    iata: 'KGA',
    icao: 'FZUA',
    latitude: -5.90005016327,
    longitude: 22.4692001343,
    altitude: 2139,
    schengen: false
  },
  {
    id: 1857,
    name: 'Tshikapa Airport',
    city: 'Tshikapa',
    country: 'Congo (Kinshasa)',
    iata: 'TSH',
    icao: 'FZUK',
    latitude: -6.43833,
    longitude: 20.794701,
    altitude: 1595,
    schengen: false
  },
  {
    id: 1858,
    name: 'Lodja Airport',
    city: 'Lodja',
    country: 'Congo (Kinshasa)',
    iata: 'LJA',
    icao: 'FZVA',
    latitude: -3.41700005531311,
    longitude: 23.4500007629395,
    altitude: 1647,
    schengen: false
  },
  {
    id: 1859,
    name: 'Ilebo Airport',
    city: 'Ilebo',
    country: 'Congo (Kinshasa)',
    iata: 'PFR',
    icao: 'FZVS',
    latitude: -4.329919,
    longitude: 20.590124,
    altitude: 1450,
    schengen: false
  },
  {
    id: 1860,
    name: 'Mbuji Mayi Airport',
    city: 'Mbuji-mayi',
    country: 'Congo (Kinshasa)',
    iata: 'MJM',
    icao: 'FZWA',
    latitude: -6.12124013901,
    longitude: 23.5690002441,
    altitude: 2221,
    schengen: false
  },
  {
    id: 1861,
    name: 'Mwene-Ditu Airport',
    city: 'Mwene-Ditu',
    country: 'Congo (Kinshasa)',
    iata: '',
    icao: 'FZWE',
    latitude: -6.98299980163574,
    longitude: 23.0830001831055,
    altitude: 3198,
    schengen: false
  },
  {
    id: 1862,
    name: 'Tunta Airport',
    city: 'Kabinda',
    country: 'Congo (Kinshasa)',
    iata: 'KBN',
    icao: 'FZWT',
    latitude: -6.132999897,
    longitude: 24.4829998016,
    altitude: 2766,
    schengen: false
  },
  {
    id: 1863,
    name: 'Aitutaki Airport',
    city: 'Aitutaki',
    country: 'Cook Islands',
    iata: 'AIT',
    icao: 'NCAI',
    latitude: -18.8309001922607,
    longitude: -159.764007568359,
    altitude: 14,
    schengen: false
  },
  {
    id: 1864,
    name: 'Enua Airport',
    city: 'Atiu Island',
    country: 'Cook Islands',
    iata: 'AIU',
    icao: 'NCAT',
    latitude: -19.9678001403809,
    longitude: -158.119003295898,
    altitude: 36,
    schengen: false
  },
  {
    id: 1865,
    name: 'Mangaia Island Airport',
    city: 'Mangaia Island',
    country: 'Cook Islands',
    iata: 'MGS',
    icao: 'NCMG',
    latitude: -21.8959865570068,
    longitude: -157.906661987305,
    altitude: 45,
    schengen: false
  },
  {
    id: 1866,
    name: 'Manihiki Island Airport',
    city: 'Manihiki Island',
    country: 'Cook Islands',
    iata: 'MHX',
    icao: 'NCMH',
    latitude: -10.3767004013062,
    longitude: -161.001998901367,
    altitude: 0,
    schengen: false
  },
  {
    id: 1867,
    name: 'Mauke Airport',
    city: 'Mauke Island',
    country: 'Cook Islands',
    iata: 'MUK',
    icao: 'NCMK',
    latitude: -20.136100769043,
    longitude: -157.345001220703,
    altitude: 26,
    schengen: false
  },
  {
    id: 1868,
    name: 'Mitiaro Island Airport',
    city: 'Mitiaro Island',
    country: 'Cook Islands',
    iata: 'MOI',
    icao: 'NCMR',
    latitude: -19.8425006866455,
    longitude: -157.703002929688,
    altitude: 25,
    schengen: false
  },
  {
    id: 1869,
    name: 'Tongareva Airport',
    city: 'Penrhyn Island',
    country: 'Cook Islands',
    iata: 'PYE',
    icao: 'NCPY',
    latitude: -9.01436996459961,
    longitude: -158.032409667969,
    altitude: 8,
    schengen: false
  },
  {
    id: 1870,
    name: 'Rarotonga International Airport',
    city: 'Avarua',
    country: 'Cook Islands',
    iata: 'RAR',
    icao: 'NCRG',
    latitude: -21.2026996613,
    longitude: -159.805999756,
    altitude: 19,
    schengen: false
  },
  {
    id: 1871,
    name: 'Arenal Airport',
    city: 'La Fortuna/San Carlos',
    country: 'Costa Rica',
    iata: 'FON',
    icao: 'MRAN',
    latitude: 10.478,
    longitude: -84.634499,
    altitude: 342,
    schengen: false
  },
  {
    id: 1872,
    name: 'Aerotortuguero Airport',
    city: 'Roxana',
    country: 'Costa Rica',
    iata: 'TTQ',
    icao: 'MRAO',
    latitude: 10.42,
    longitude: -83.6095,
    altitude: 92,
    schengen: false
  },
  {
    id: 1873,
    name: 'Buenos Aires Airport',
    city: 'Buenos Aires',
    country: 'Costa Rica',
    iata: 'BAI',
    icao: 'MRBA',
    latitude: 9.163949,
    longitude: -83.330171,
    altitude: 1214,
    schengen: false
  },
  {
    id: 1874,
    name: 'Barra del Colorado Airport',
    city: 'Pococi',
    country: 'Costa Rica',
    iata: 'BCL',
    icao: 'MRBC',
    latitude: 10.7686996459961,
    longitude: -83.5856018066406,
    altitude: 3,
    schengen: false
  },
  {
    id: 1875,
    name: 'Codela Airport',
    city: 'Guapiles',
    country: 'Costa Rica',
    iata: '',
    icao: 'MRCA',
    latitude: 10.4139995575,
    longitude: -85.0916976929,
    altitude: 328,
    schengen: false
  },
  {
    id: 1876,
    name: 'Coto 47 Airport',
    city: 'Coto 47',
    country: 'Costa Rica',
    iata: 'OTR',
    icao: 'MRCC',
    latitude: 8.60155963897705,
    longitude: -82.9685974121094,
    altitude: 26,
    schengen: false
  },
  {
    id: 1877,
    name: 'Chacarita Airport',
    city: 'Chacarita',
    country: 'Costa Rica',
    iata: 'JAP',
    icao: 'MRCH',
    latitude: 9.98141002655,
    longitude: -84.7726974487,
    altitude: 7,
    schengen: false
  },
  {
    id: 1878,
    name: 'Playa Samara/Carrillo Airport',
    city: 'Carrillo',
    country: 'Costa Rica',
    iata: 'PLD',
    icao: 'MRCR',
    latitude: 9.8705101013184,
    longitude: -85.481399536133,
    altitude: 50,
    schengen: false
  },
  {
    id: 1879,
    name: 'Cabo Velas Airport',
    city: 'Nicoya',
    country: 'Costa Rica',
    iata: '',
    icao: 'MRCV',
    latitude: 10.355699539185,
    longitude: -85.852897644043,
    altitude: 33,
    schengen: false
  },
  {
    id: 1880,
    name: 'Drake Bay Airport',
    city: 'Puntarenas',
    country: 'Costa Rica',
    iata: 'DRK',
    icao: 'MRDK',
    latitude: 8.71889019012,
    longitude: -83.6417007446,
    altitude: 12,
    schengen: false
  },
  {
    id: 1881,
    name: 'El Carmen de Siquirres Airport',
    city: 'El Carmen',
    country: 'Costa Rica',
    iata: '',
    icao: 'MREC',
    latitude: 10.2019996643066,
    longitude: -83.4721984863281,
    altitude: 56,
    schengen: false
  },
  {
    id: 1882,
    name: 'Finca 10 / Nuevo Palmar Sur Airport',
    city: 'Finca 10',
    country: 'Costa Rica',
    iata: '',
    icao: 'MRFI',
    latitude: 8.91635036468506,
    longitude: -83.5073013305664,
    altitude: 25,
    schengen: false
  },
  {
    id: 1883,
    name: 'Golfito Airport',
    city: 'Golfito',
    country: 'Costa Rica',
    iata: 'GLF',
    icao: 'MRGF',
    latitude: 8.65400981903076,
    longitude: -83.1821975708008,
    altitude: 49,
    schengen: false
  },
  {
    id: 1884,
    name: 'Guapiles Airport',
    city: 'Guapiles',
    country: 'Costa Rica',
    iata: 'GPL',
    icao: 'MRGP',
    latitude: 10.2172002792,
    longitude: -83.7969970703,
    altitude: 883,
    schengen: false
  },
  {
    id: 1885,
    name: 'Islita Airport',
    city: 'Nandayure',
    country: 'Costa Rica',
    iata: 'PBP',
    icao: 'MRIA',
    latitude: 9.85610961914063,
    longitude: -85.3707962036133,
    altitude: 7,
    schengen: false
  },
  {
    id: 1886,
    name: 'Daniel Oduber Quiros International Airport',
    city: 'Liberia',
    country: 'Costa Rica',
    iata: 'LIR',
    icao: 'MRLB',
    latitude: 10.5933,
    longitude: -85.544403,
    altitude: 270,
    schengen: false
  },
  {
    id: 1887,
    name: 'Los Chiles Airport',
    city: 'Los Chiles',
    country: 'Costa Rica',
    iata: 'LSL',
    icao: 'MRLC',
    latitude: 11.0353002548218,
    longitude: -84.7061004638672,
    altitude: 131,
    schengen: false
  },
  {
    id: 1888,
    name: 'Limon International Airport',
    city: 'Limon',
    country: 'Costa Rica',
    iata: 'LIO',
    icao: 'MRLM',
    latitude: 9.95796012878418,
    longitude: -83.0220031738281,
    altitude: 7,
    schengen: false
  },
  {
    id: 1889,
    name: 'Nosara Airport',
    city: 'Nosara Beach',
    country: 'Costa Rica',
    iata: 'NOB',
    icao: 'MRNS',
    latitude: 9.97649002075,
    longitude: -85.6529998779,
    altitude: 33,
    schengen: false
  },
  {
    id: 1890,
    name: 'Juan Santamaria International Airport',
    city: 'San Jose',
    country: 'Costa Rica',
    iata: 'SJO',
    icao: 'MROC',
    latitude: 9.99386024475098,
    longitude: -84.2088012695313,
    altitude: 3021,
    schengen: false
  },
  {
    id: 1891,
    name: 'Pandora Airport',
    city: 'Pandora',
    country: 'Costa Rica',
    iata: '',
    icao: 'MRPD',
    latitude: 9.73217010498047,
    longitude: -82.9832000732422,
    altitude: 98,
    schengen: false
  },
  {
    id: 1892,
    name: 'Puerto Jimenez Airport',
    city: 'Puerto Jimenez',
    country: 'Costa Rica',
    iata: 'PJM',
    icao: 'MRPJ',
    latitude: 8.53332996368408,
    longitude: -83.3000030517578,
    altitude: 7,
    schengen: false
  },
  {
    id: 1893,
    name: 'Palmar Sur Airport',
    city: 'Palmar Sur',
    country: 'Costa Rica',
    iata: 'PMZ',
    icao: 'MRPM',
    latitude: 8.95102977752686,
    longitude: -83.4685974121094,
    altitude: 49,
    schengen: false
  },
  {
    id: 1894,
    name: 'Tobias Bolanos International Airport',
    city: 'San Jose',
    country: 'Costa Rica',
    iata: 'SYQ',
    icao: 'MRPV',
    latitude: 9.95705032348633,
    longitude: -84.1398010253906,
    altitude: 3287,
    schengen: false
  },
  {
    id: 1895,
    name: 'Quepos Managua Airport',
    city: 'Quepos',
    country: 'Costa Rica',
    iata: 'XQP',
    icao: 'MRQP',
    latitude: 9.44316005706787,
    longitude: -84.1297988891602,
    altitude: 85,
    schengen: false
  },
  {
    id: 1896,
    name: 'Santa Clara De Guapiles Airport',
    city: 'Santa Clara',
    country: 'Costa Rica',
    iata: '',
    icao: 'MRSG',
    latitude: 10.2882995605469,
    longitude: -83.7135009765625,
    altitude: 262,
    schengen: false
  },
  {
    id: 1897,
    name: '(Duplicate) Playa Samara Airport',
    city: 'Playa Samara',
    country: 'Costa Rica',
    iata: '',
    icao: 'MRSR',
    latitude: 9.87,
    longitude: -85.48,
    altitude: 10,
    schengen: false
  },
  {
    id: 1898,
    name: 'San Vito De Java Airport',
    city: 'San Vito De Jaba',
    country: 'Costa Rica',
    iata: 'TOO',
    icao: 'MRSV',
    latitude: 8.82610988616943,
    longitude: -82.9589004516602,
    altitude: 3228,
    schengen: false
  },
  {
    id: 1899,
    name: 'Tambor Airport',
    city: 'Nicoya',
    country: 'Costa Rica',
    iata: 'TMU',
    icao: 'MRTR',
    latitude: 9.73852,
    longitude: -85.013802,
    altitude: 33,
    schengen: false
  },
  {
    id: 1900,
    name: 'Upala Airport',
    city: 'Upala',
    country: 'Costa Rica',
    iata: 'UPL',
    icao: 'MRUP',
    latitude: 10.8922,
    longitude: -85.016197,
    altitude: 184,
    schengen: false
  },
  {
    id: 1901,
    name: 'Port Bouet Airport',
    city: 'Abidjan',
    country: "Cote d'Ivoire",
    iata: 'ABJ',
    icao: 'DIAP',
    latitude: 5.261390209198,
    longitude: -3.9262900352478,
    altitude: 21,
    schengen: false
  },
  {
    id: 1902,
    name: 'Bouaké Airport',
    city: 'Bouake',
    country: "Cote d'Ivoire",
    iata: 'BYK',
    icao: 'DIBK',
    latitude: 7.73880004882813,
    longitude: -5.07366991043091,
    altitude: 1230,
    schengen: false
  },
  {
    id: 1903,
    name: 'Daloa Airport',
    city: 'Daloa',
    country: "Cote d'Ivoire",
    iata: 'DJO',
    icao: 'DIDL',
    latitude: 6.79280996322632,
    longitude: -6.47318983078003,
    altitude: 823,
    schengen: false
  },
  {
    id: 1904,
    name: 'Korhogo Airport',
    city: 'Korhogo',
    country: "Cote d'Ivoire",
    iata: 'HGO',
    icao: 'DIKO',
    latitude: 9.38718032837,
    longitude: -5.55666017532,
    altitude: 1214,
    schengen: false
  },
  {
    id: 1905,
    name: 'Man Airport',
    city: 'Man',
    country: "Cote d'Ivoire",
    iata: 'MJC',
    icao: 'DIMN',
    latitude: 7.27206993103027,
    longitude: -7.58735990524292,
    altitude: 1089,
    schengen: false
  },
  {
    id: 1906,
    name: 'Odienne Airport',
    city: 'Odienne',
    country: "Cote d'Ivoire",
    iata: 'KEO',
    icao: 'DIOD',
    latitude: 9.5,
    longitude: -7.56699991226196,
    altitude: 1365,
    schengen: false
  },
  {
    id: 1907,
    name: 'San Pedro Airport',
    city: 'San Pedro',
    country: "Cote d'Ivoire",
    iata: 'SPY',
    icao: 'DISP',
    latitude: 4.74671983718872,
    longitude: -6.66082000732422,
    altitude: 26,
    schengen: false
  },
  {
    id: 1908,
    name: 'Yamoussoukro Airport',
    city: 'Yamoussoukro',
    country: "Cote d'Ivoire",
    iata: 'ASK',
    icao: 'DIYO',
    latitude: 6.9031701088,
    longitude: -5.36558008194,
    altitude: 699,
    schengen: false
  },
  {
    id: 1909,
    name: 'Dubrovnik Airport',
    city: 'Dubrovnik',
    country: 'Croatia',
    iata: 'DBV',
    icao: 'LDDU',
    latitude: 42.5614013671875,
    longitude: 18.2681999206543,
    altitude: 527,
    schengen: false
  },
  {
    id: 1910,
    name: 'Lošinj Island Airport',
    city: 'Mali Losinj',
    country: 'Croatia',
    iata: 'LSZ',
    icao: 'LDLO',
    latitude: 44.5657997131,
    longitude: 14.3930997849,
    altitude: 151,
    schengen: false
  },
  {
    id: 1911,
    name: 'Osijek-Čepin Airfield',
    city: 'Cepin',
    country: 'Croatia',
    iata: '',
    icao: 'LDOC',
    latitude: 45.5427778,
    longitude: 18.6319444,
    altitude: 299,
    schengen: false
  },
  {
    id: 1912,
    name: 'Osijek Airport',
    city: 'Osijek',
    country: 'Croatia',
    iata: 'OSI',
    icao: 'LDOS',
    latitude: 45.4626998901367,
    longitude: 18.8101997375488,
    altitude: 290,
    schengen: false
  },
  {
    id: 1913,
    name: 'Pula Airport',
    city: 'Pula',
    country: 'Croatia',
    iata: 'PUY',
    icao: 'LDPL',
    latitude: 44.8935012817383,
    longitude: 13.9222002029419,
    altitude: 274,
    schengen: false
  },
  {
    id: 1914,
    name: 'Vrsar Crljenka Airport',
    city: 'Vsar',
    country: 'Croatia',
    iata: '',
    icao: 'LDPV',
    latitude: 45.1417007446289,
    longitude: 13.6305999755859,
    altitude: 121,
    schengen: false
  },
  {
    id: 1915,
    name: 'Grobnicko Polje Airport',
    city: 'Grobnik',
    country: 'Croatia',
    iata: '',
    icao: 'LDRG',
    latitude: 45.3795013427734,
    longitude: 14.5038003921509,
    altitude: 951,
    schengen: false
  },
  {
    id: 1916,
    name: 'Rijeka Airport',
    city: 'Rijeka',
    country: 'Croatia',
    iata: 'RJK',
    icao: 'LDRI',
    latitude: 45.2168998718262,
    longitude: 14.5703001022339,
    altitude: 278,
    schengen: false
  },
  {
    id: 1917,
    name: 'Bol Airport',
    city: 'Brac',
    country: 'Croatia',
    iata: 'BWK',
    icao: 'LDSB',
    latitude: 43.285701751709,
    longitude: 16.6797008514404,
    altitude: 1776,
    schengen: false
  },
  {
    id: 1918,
    name: 'Split Airport',
    city: 'Split',
    country: 'Croatia',
    iata: 'SPU',
    icao: 'LDSP',
    latitude: 43.5388984680176,
    longitude: 16.2980003356934,
    altitude: 79,
    schengen: false
  },
  {
    id: 1919,
    name: 'Varaždin Airport',
    city: 'Varazdin',
    country: 'Croatia',
    iata: '',
    icao: 'LDVA',
    latitude: 46.2946472167969,
    longitude: 16.3829326629639,
    altitude: 548,
    schengen: false
  },
  {
    id: 1920,
    name: 'Zagreb Airport',
    city: 'Zagreb',
    country: 'Croatia',
    iata: 'ZAG',
    icao: 'LDZA',
    latitude: 45.7429008484,
    longitude: 16.0687999725,
    altitude: 353,
    schengen: false
  },
  {
    id: 1921,
    name: 'Zadar Airport',
    city: 'Zadar',
    country: 'Croatia',
    iata: 'ZAD',
    icao: 'LDZD',
    latitude: 44.108299,
    longitude: 15.3467,
    altitude: 289,
    schengen: false
  },
  {
    id: 1922,
    name: 'Udbina Air Base',
    city: 'Udbina',
    country: 'Croatia',
    iata: '',
    icao: 'LDZU',
    latitude: 44.5575981140137,
    longitude: 15.7743997573853,
    altitude: 2462,
    schengen: false
  },
  {
    id: 1923,
    name: 'Gustavo Rizo Airport',
    city: 'Baracoa Playa',
    country: 'Cuba',
    iata: 'BCA',
    icao: 'MUBA',
    latitude: 20.3652992248535,
    longitude: -74.5062026977539,
    altitude: 26,
    schengen: false
  },
  {
    id: 1924,
    name: 'Las Brujas Airport',
    city: 'Cayo Santa Maria',
    country: 'Cuba',
    iata: 'BWW',
    icao: 'MUBR',
    latitude: 22.6212997437,
    longitude: -79.1472015381,
    altitude: 13,
    schengen: false
  },
  {
    id: 1925,
    name: 'Carlos Manuel de Cespedes Airport',
    city: 'Bayamo',
    country: 'Cuba',
    iata: 'BYM',
    icao: 'MUBY',
    latitude: 20.3964004516602,
    longitude: -76.6213989257813,
    altitude: 203,
    schengen: false
  },
  {
    id: 1926,
    name: 'Maximo Gomez Airport',
    city: 'Ciego De Avila',
    country: 'Cuba',
    iata: 'AVI',
    icao: 'MUCA',
    latitude: 22.027099609375,
    longitude: -78.7895965576172,
    altitude: 335,
    schengen: false
  },
  {
    id: 1927,
    name: 'Jardines Del Rey Airport',
    city: 'Cunagua',
    country: 'Cuba',
    iata: 'CCC',
    icao: 'MUCC',
    latitude: 22.4610004425,
    longitude: -78.3283996582,
    altitude: 13,
    schengen: false
  },
  {
    id: 1928,
    name: 'Jaime Gonzalez Airport',
    city: 'Cienfuegos',
    country: 'Cuba',
    iata: 'CFG',
    icao: 'MUCF',
    latitude: 22.1499996185303,
    longitude: -80.4141998291016,
    altitude: 102,
    schengen: false
  },
  {
    id: 1929,
    name: 'Vilo Acuña International Airport',
    city: 'Cayo Largo del Sur',
    country: 'Cuba',
    iata: 'CYO',
    icao: 'MUCL',
    latitude: 21.6165008545,
    longitude: -81.5459976196,
    altitude: 10,
    schengen: false
  },
  {
    id: 1930,
    name: 'Ignacio Agramonte International Airport',
    city: 'Camaguey',
    country: 'Cuba',
    iata: 'CMW',
    icao: 'MUCM',
    latitude: 21.4202995300293,
    longitude: -77.8475036621094,
    altitude: 413,
    schengen: false
  },
  {
    id: 1931,
    name: 'Antonio Maceo International Airport',
    city: 'Santiago De Cuba',
    country: 'Cuba',
    iata: 'SCU',
    icao: 'MUCU',
    latitude: 19.9698009490967,
    longitude: -75.8354034423828,
    altitude: 249,
    schengen: false
  },
  {
    id: 1932,
    name: 'Florida Airport',
    city: 'Florida',
    country: 'Cuba',
    iata: '',
    icao: 'MUFL',
    latitude: 21.4997005462646,
    longitude: -78.2027969360352,
    altitude: 197,
    schengen: false
  },
  {
    id: 1933,
    name: 'Leeward Point Field',
    city: 'Guantanamo',
    country: 'Cuba',
    iata: 'NBW',
    icao: 'MUGM',
    latitude: 19.906499862671,
    longitude: -75.207099914551,
    altitude: 56,
    schengen: false
  },
  {
    id: 1934,
    name: 'Mariana Grajales Airport',
    city: 'Guantanamo',
    country: 'Cuba',
    iata: 'GAO',
    icao: 'MUGT',
    latitude: 20.0853004455566,
    longitude: -75.1583023071289,
    altitude: 56,
    schengen: false
  },
  {
    id: 1935,
    name: 'José Martí International Airport',
    city: 'Havana',
    country: 'Cuba',
    iata: 'HAV',
    icao: 'MUHA',
    latitude: 22.989200592041,
    longitude: -82.4091033935547,
    altitude: 210,
    schengen: false
  },
  {
    id: 1936,
    name: 'Frank Pais International Airport',
    city: 'Holguin',
    country: 'Cuba',
    iata: 'HOG',
    icao: 'MUHG',
    latitude: 20.7856006622314,
    longitude: -76.3151016235352,
    altitude: 361,
    schengen: false
  },
  {
    id: 1937,
    name: 'Kawama Airport',
    city: 'Kawama',
    country: 'Cuba',
    iata: 'VRO',
    icao: 'MUKW',
    latitude: 23.1240005493,
    longitude: -81.3015975952,
    altitude: 16,
    schengen: false
  },
  {
    id: 1938,
    name: 'La Coloma Airport',
    city: 'La Coloma',
    country: 'Cuba',
    iata: 'LCL',
    icao: 'MULM',
    latitude: 22.3360996246338,
    longitude: -83.6418991088867,
    altitude: 131,
    schengen: false
  },
  {
    id: 1939,
    name: 'Orestes Acosta Airport',
    city: 'Moa',
    country: 'Cuba',
    iata: 'MOA',
    icao: 'MUMO',
    latitude: 20.6539001464844,
    longitude: -74.9222030639649,
    altitude: 16,
    schengen: false
  },
  {
    id: 1940,
    name: 'Sierra Maestra Airport',
    city: 'Manzanillo',
    country: 'Cuba',
    iata: 'MZO',
    icao: 'MUMZ',
    latitude: 20.2880992889404,
    longitude: -77.0892028808594,
    altitude: 112,
    schengen: false
  },
  {
    id: 1941,
    name: 'San Nicolas De Bari Airport',
    city: 'San Nicolás',
    country: 'Cuba',
    iata: 'QSN',
    icao: 'MUNB',
    latitude: 22.7560997009277,
    longitude: -81.9208984375,
    altitude: 49,
    schengen: false
  },
  {
    id: 1942,
    name: 'Rafael Cabrera Airport',
    city: 'Nueva Gerona',
    country: 'Cuba',
    iata: 'GER',
    icao: 'MUNG',
    latitude: 21.8346996307373,
    longitude: -82.7837982177734,
    altitude: 79,
    schengen: false
  },
  {
    id: 1943,
    name: 'Cayo Coco Airport',
    city: 'Cayo Coco',
    country: 'Cuba',
    iata: '',
    icao: 'MUOC',
    latitude: 22.5132007599,
    longitude: -78.5110015869,
    altitude: 6,
    schengen: false
  },
  {
    id: 1944,
    name: 'Playa Baracoa Airport',
    city: 'Baracoa Playa',
    country: 'Cuba',
    iata: 'UPB',
    icao: 'MUPB',
    latitude: 23.0328006744,
    longitude: -82.5793991089,
    altitude: 102,
    schengen: false
  },
  {
    id: 1945,
    name: 'Pinar Del Rio Airport',
    city: 'Pinar Del Rio Norte',
    country: 'Cuba',
    iata: 'QPD',
    icao: 'MUPR',
    latitude: 22.4214000701904,
    longitude: -83.6783981323242,
    altitude: 131,
    schengen: false
  },
  {
    id: 1946,
    name: 'San Antonio De Los Banos Airport',
    city: 'San Antonio De Banos',
    country: 'Cuba',
    iata: '',
    icao: 'MUSA',
    latitude: 22.8715000152588,
    longitude: -82.5093002319336,
    altitude: 164,
    schengen: false
  },
  {
    id: 1947,
    name: 'Abel Santamaria Airport',
    city: 'Santa Clara',
    country: 'Cuba',
    iata: 'SNU',
    icao: 'MUSC',
    latitude: 22.4922008514404,
    longitude: -79.943603515625,
    altitude: 338,
    schengen: false
  },
  {
    id: 1948,
    name: 'San Julian Air Base',
    city: 'Pinar Del Rio',
    country: 'Cuba',
    iata: 'SNJ',
    icao: 'MUSJ',
    latitude: 22.0953006744385,
    longitude: -84.1520004272461,
    altitude: 98,
    schengen: false
  },
  {
    id: 1949,
    name: 'Joaquín de Agüero Airport',
    city: 'Santa Lucia',
    country: 'Cuba',
    iata: '',
    icao: 'MUSL',
    latitude: 21.5094559067,
    longitude: -77.0205974579,
    altitude: 13,
    schengen: false
  },
  {
    id: 1950,
    name: 'Siguanea Airport',
    city: 'Siguanea',
    country: 'Cuba',
    iata: 'SZJ',
    icao: 'MUSN',
    latitude: 21.6424999237061,
    longitude: -82.9551010131836,
    altitude: 39,
    schengen: false
  },
  {
    id: 1951,
    name: 'Sancti Spiritus Airport',
    city: 'Sancti Spiritus',
    country: 'Cuba',
    iata: 'USS',
    icao: 'MUSS',
    latitude: 21.9704,
    longitude: -79.442703,
    altitude: 295,
    schengen: false
  },
  {
    id: 1952,
    name: 'Alberto Delgado Airport',
    city: 'Trinidad',
    country: 'Cuba',
    iata: 'TND',
    icao: 'MUTD',
    latitude: 21.7882995605469,
    longitude: -79.997200012207,
    altitude: 125,
    schengen: false
  },
  {
    id: 1953,
    name: 'Juan Gualberto Gomez International Airport',
    city: 'Varadero',
    country: 'Cuba',
    iata: 'VRA',
    icao: 'MUVR',
    latitude: 23.0344009399414,
    longitude: -81.435302734375,
    altitude: 210,
    schengen: false
  },
  {
    id: 1954,
    name: 'Hermanos Ameijeiras Airport',
    city: 'Las Tunas',
    country: 'Cuba',
    iata: 'VTU',
    icao: 'MUVT',
    latitude: 20.9876003265381,
    longitude: -76.9357986450195,
    altitude: 328,
    schengen: false
  },
  {
    id: 1955,
    name: 'Ercan International Airport',
    city: 'Nicosia',
    country: 'Cyprus',
    iata: 'ECN',
    icao: 'LCEN',
    latitude: 35.1547012329102,
    longitude: 33.4961013793945,
    altitude: 404,
    schengen: false
  },
  {
    id: 1956,
    name: 'Larnaca International Airport',
    city: 'Larnaca',
    country: 'Cyprus',
    iata: 'LCA',
    icao: 'LCLK',
    latitude: 34.8750991821289,
    longitude: 33.6249008178711,
    altitude: 8,
    schengen: false
  },
  {
    id: 1957,
    name: 'Paphos International Airport',
    city: 'Paphos',
    country: 'Cyprus',
    iata: 'PFO',
    icao: 'LCPH',
    latitude: 34.7179985046387,
    longitude: 32.4856986999512,
    altitude: 41,
    schengen: false
  },
  {
    id: 1958,
    name: 'RAF Akrotiri',
    city: 'Akrotiri',
    country: 'Cyprus',
    iata: 'AKT',
    icao: 'LCRA',
    latitude: 34.590401,
    longitude: 32.9879,
    altitude: 76,
    schengen: false
  },
  {
    id: 1959,
    name: 'Benešov Airport',
    city: 'Benesov',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKBE',
    latitude: 49.7407989501953,
    longitude: 14.644700050354,
    altitude: 1319,
    schengen: true
  },
  {
    id: 1960,
    name: 'Bubovice Airport',
    city: 'Bubovice',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKBU',
    latitude: 49.9743995666504,
    longitude: 14.1780996322632,
    altitude: 1401,
    schengen: true
  },
  {
    id: 1961,
    name: 'České Budějovice Airport',
    city: 'Ceske Budejovice',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKCS',
    latitude: 48.9463996887207,
    longitude: 14.4274997711182,
    altitude: 1417,
    schengen: true
  },
  {
    id: 1962,
    name: 'Čáslav Air Base',
    city: 'Caslav',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKCV',
    latitude: 49.9397010803223,
    longitude: 15.381799697876,
    altitude: 794,
    schengen: true
  },
  {
    id: 1963,
    name: 'Hradec Králové Airport',
    city: 'Hradec Kralove',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKHK',
    latitude: 50.253201,
    longitude: 15.8452,
    altitude: 791,
    schengen: true
  },
  {
    id: 1964,
    name: 'Hořovice Airport',
    city: 'Horovice',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKHV',
    latitude: 49.8480987548828,
    longitude: 13.893500328064,
    altitude: 1214,
    schengen: true
  },
  {
    id: 1965,
    name: 'Kbely Air Base',
    city: 'Praha',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKKB',
    latitude: 50.1213989257812,
    longitude: 14.5436000823975,
    altitude: 939,
    schengen: true
  },
  {
    id: 1966,
    name: 'Klatovy Airport',
    city: 'Klatovy',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKKT',
    latitude: 49.4183006286621,
    longitude: 13.3219003677368,
    altitude: 1299,
    schengen: true
  },
  {
    id: 1967,
    name: 'Kunovice Airport',
    city: 'Kunovice',
    country: 'Czech Republic',
    iata: 'UHE',
    icao: 'LKKU',
    latitude: 49.0293998718262,
    longitude: 17.4396991729736,
    altitude: 581,
    schengen: true
  },
  {
    id: 1968,
    name: 'Karlovy Vary International Airport',
    city: 'Karlovy Vary',
    country: 'Czech Republic',
    iata: 'KLV',
    icao: 'LKKV',
    latitude: 50.2029991149902,
    longitude: 12.914999961853,
    altitude: 1989,
    schengen: true
  },
  {
    id: 1969,
    name: 'Plzeň-Líně Airport',
    city: 'Line',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKLN',
    latitude: 49.675201416016,
    longitude: 13.274600028992,
    altitude: 1188,
    schengen: true
  },
  {
    id: 1970,
    name: 'Mnichovo Hradiště Airport',
    city: 'Mnichovo Hradiste',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKMH',
    latitude: 50.5401992797852,
    longitude: 15.0066003799438,
    altitude: 800,
    schengen: true
  },
  {
    id: 1971,
    name: 'Ostrava Leos Janáček Airport',
    city: 'Ostrava',
    country: 'Czech Republic',
    iata: 'OSR',
    icao: 'LKMT',
    latitude: 49.6963005065918,
    longitude: 18.1110992431641,
    altitude: 844,
    schengen: true
  },
  {
    id: 1972,
    name: 'Náměšť Air Base',
    city: 'Namest',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKNA',
    latitude: 49.1657981872559,
    longitude: 16.1249008178711,
    altitude: 1548,
    schengen: true
  },
  {
    id: 1973,
    name: 'Pardubice Airport',
    city: 'Pardubice',
    country: 'Czech Republic',
    iata: 'PED',
    icao: 'LKPD',
    latitude: 50.0134010314941,
    longitude: 15.7385997772217,
    altitude: 741,
    schengen: true
  },
  {
    id: 1974,
    name: 'Pribram Airport',
    city: 'Pribram',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKPM',
    latitude: 49.7201,
    longitude: 14.1006,
    altitude: 1529,
    schengen: true
  },
  {
    id: 1975,
    name: 'Přerov Air Base',
    city: 'Prerov',
    country: 'Czech Republic',
    iata: 'PRV',
    icao: 'LKPO',
    latitude: 49.4258003234863,
    longitude: 17.4046993255615,
    altitude: 676,
    schengen: true
  },
  {
    id: 1976,
    name: 'Václav Havel Airport Prague',
    city: 'Prague',
    country: 'Czech Republic',
    iata: 'PRG',
    icao: 'LKPR',
    latitude: 50.1008,
    longitude: 14.26,
    altitude: 1247,
    schengen: true
  },
  {
    id: 1977,
    name: 'Roudnice Airport',
    city: 'Roudnice nad Lebem',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKRO',
    latitude: 50.41059875,
    longitude: 14.2261,
    altitude: 732,
    schengen: true
  },
  {
    id: 1978,
    name: 'Sazená Airport',
    city: 'Sazena',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKSZ',
    latitude: 50.3246994019,
    longitude: 14.2588996887,
    altitude: 761,
    schengen: true
  },
  {
    id: 1979,
    name: 'Brno-Tuřany Airport',
    city: 'Brno',
    country: 'Czech Republic',
    iata: 'BRQ',
    icao: 'LKTB',
    latitude: 49.1512985229492,
    longitude: 16.6944007873535,
    altitude: 778,
    schengen: true
  },
  {
    id: 1980,
    name: 'Usti Nad Labem Airfield',
    city: 'Usti Nad Labem',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKUL',
    latitude: 50.699699,
    longitude: 13.9697,
    altitude: 791,
    schengen: true
  },
  {
    id: 1981,
    name: 'Letiště Vysoké Mýto',
    city: 'Vysoke Myto',
    country: 'Czech Republic',
    iata: '',
    icao: 'LKVM',
    latitude: 49.926899,
    longitude: 16.185801,
    altitude: 991,
    schengen: true
  },
  {
    id: 1982,
    name: 'Vodochody Airport',
    city: 'Vodochody',
    country: 'Czech Republic',
    iata: 'VOD',
    icao: 'LKVO',
    latitude: 50.216599,
    longitude: 14.3958,
    altitude: 919,
    schengen: true
  },
  {
    id: 1983,
    name: 'Aarhus Seaplne Terminal',
    city: 'Aarhus',
    country: 'Denmark',
    iata: '',
    icao: 'EKAC',
    latitude: 56.151717,
    longitude: 10.248428,
    altitude: 0,
    schengen: true
  },
  {
    id: 1984,
    name: 'Ærø Airport',
    city: 'Aero',
    country: 'Denmark',
    iata: '',
    icao: 'EKAE',
    latitude: 54.8527984619141,
    longitude: 10.4563999176025,
    altitude: 3,
    schengen: true
  },
  {
    id: 1985,
    name: 'Aarhus Airport',
    city: 'Aarhus',
    country: 'Denmark',
    iata: 'AAR',
    icao: 'EKAH',
    latitude: 56.2999992371,
    longitude: 10.6190004349,
    altitude: 82,
    schengen: true
  },
  {
    id: 1986,
    name: 'Anholt Airport',
    city: 'Anholt',
    country: 'Denmark',
    iata: '',
    icao: 'EKAT',
    latitude: 56.6988983154,
    longitude: 11.5558996201,
    altitude: 23,
    schengen: true
  },
  {
    id: 1987,
    name: 'Billund Airport',
    city: 'Billund',
    country: 'Denmark',
    iata: 'BLL',
    icao: 'EKBI',
    latitude: 55.7402992249,
    longitude: 9.15178012848,
    altitude: 247,
    schengen: true
  },
  {
    id: 1988,
    name: 'Copenhagen Kastrup Airport',
    city: 'Copenhagen',
    country: 'Denmark',
    iata: 'CPH',
    icao: 'EKCH',
    latitude: 55.617900848389,
    longitude: 12.656000137329,
    altitude: 17,
    schengen: true
  },
  {
    id: 1989,
    name: 'Esbjerg Airport',
    city: 'Esbjerg',
    country: 'Denmark',
    iata: 'EBJ',
    icao: 'EKEB',
    latitude: 55.525902,
    longitude: 8.5534,
    altitude: 97,
    schengen: true
  },
  {
    id: 1990,
    name: 'Endelave Flyveplads',
    city: 'Endelage',
    country: 'Denmark',
    iata: '',
    icao: 'EKEL',
    latitude: 55.7565,
    longitude: 10.2485,
    altitude: 15,
    schengen: true
  },
  {
    id: 1991,
    name: 'Grønholt Hillerød Airport',
    city: 'Gronholt',
    country: 'Denmark',
    iata: '',
    icao: 'EKGH',
    latitude: 55.9413986206055,
    longitude: 12.3822002410889,
    altitude: 97,
    schengen: true
  },
  {
    id: 1992,
    name: 'Herning Airport',
    city: 'Herning',
    country: 'Denmark',
    iata: '',
    icao: 'EKHG',
    latitude: 56.184700012207,
    longitude: 9.04444980621338,
    altitude: 167,
    schengen: true
  },
  {
    id: 1993,
    name: 'Karup Airport',
    city: 'Karup',
    country: 'Denmark',
    iata: 'KRP',
    icao: 'EKKA',
    latitude: 56.2975006103516,
    longitude: 9.12462997436523,
    altitude: 170,
    schengen: true
  },
  {
    id: 1994,
    name: 'Kalundborg Airport',
    city: 'Kalundborg',
    country: 'Denmark',
    iata: '',
    icao: 'EKKL',
    latitude: 55.7002983093262,
    longitude: 11.25,
    altitude: 1,
    schengen: true
  },
  {
    id: 1995,
    name: 'Læsø Airport',
    city: 'Laeso',
    country: 'Denmark',
    iata: 'BYR',
    icao: 'EKLS',
    latitude: 57.2771987915039,
    longitude: 11.0001001358032,
    altitude: 25,
    schengen: true
  },
  {
    id: 1996,
    name: 'Lolland Falster Maribo Airport',
    city: 'Maribo',
    country: 'Denmark',
    iata: 'MRW',
    icao: 'EKMB',
    latitude: 54.699299,
    longitude: 11.4401,
    altitude: 16,
    schengen: true
  },
  {
    id: 1997,
    name: 'Odense Airport',
    city: 'Odense',
    country: 'Denmark',
    iata: 'ODE',
    icao: 'EKOD',
    latitude: 55.4766998291016,
    longitude: 10.3309001922607,
    altitude: 56,
    schengen: true
  },
  {
    id: 1998,
    name: 'Kruså-Padborg Airport',
    city: 'Krusa-padborg',
    country: 'Denmark',
    iata: '',
    icao: 'EKPB',
    latitude: 54.8703002929688,
    longitude: 9.27900981903076,
    altitude: 88,
    schengen: true
  },
  {
    id: 1999,
    name: 'Copenhagen Roskilde Airport',
    city: 'Copenhagen',
    country: 'Denmark',
    iata: 'RKE',
    icao: 'EKRK',
    latitude: 55.5856018066406,
    longitude: 12.1314001083374,
    altitude: 146,
    schengen: true
  },
  {
    id: 2000,
    name: 'Bornholm Airport',
    city: 'Ronne',
    country: 'Denmark',
    iata: 'RNN',
    icao: 'EKRN',
    latitude: 55.0633010864258,
    longitude: 14.7595996856689,
    altitude: 52,
    schengen: true
  },
  {
    id: 2001,
    name: 'Sønderborg Airport',
    city: 'Soenderborg',
    country: 'Denmark',
    iata: 'SGD',
    icao: 'EKSB',
    latitude: 54.9644012451172,
    longitude: 9.79172992706299,
    altitude: 24,
    schengen: true
  },
  {
    id: 2002,
    name: 'Sindal Airport',
    city: 'Sindal',
    country: 'Denmark',
    iata: 'CNL',
    icao: 'EKSN',
    latitude: 57.503502,
    longitude: 10.2294,
    altitude: 92,
    schengen: true
  },
  {
    id: 2003,
    name: 'Skrydstrup Air Base',
    city: 'Skrydstrup',
    country: 'Denmark',
    iata: 'SKS',
    icao: 'EKSP',
    latitude: 55.221048,
    longitude: 9.26702,
    altitude: 141,
    schengen: true
  },
  {
    id: 2004,
    name: 'Samsø Airport',
    city: 'Samsoe',
    country: 'Denmark',
    iata: '',
    icao: 'EKSS',
    latitude: 55.8894996643066,
    longitude: 10.6136999130249,
    altitude: 1,
    schengen: true
  },
  {
    id: 2005,
    name: 'Skive Airport',
    city: 'Skive',
    country: 'Denmark',
    iata: 'SQW',
    icao: 'EKSV',
    latitude: 56.5502014160156,
    longitude: 9.17298030853272,
    altitude: 74,
    schengen: true
  },
  {
    id: 2006,
    name: 'Tønder Airport',
    city: 'Toender',
    country: 'Denmark',
    iata: '',
    icao: 'EKTD',
    latitude: 54.9296989440918,
    longitude: 8.8405704498291,
    altitude: 1,
    schengen: true
  },
  {
    id: 2007,
    name: 'Thisted Airport',
    city: 'Thisted',
    country: 'Denmark',
    iata: 'TED',
    icao: 'EKTS',
    latitude: 57.0688018798828,
    longitude: 8.70522022247314,
    altitude: 23,
    schengen: true
  },
  {
    id: 2008,
    name: 'Kolding Vamdrup Airfield',
    city: 'Kolding',
    country: 'Denmark',
    iata: '',
    icao: 'EKVD',
    latitude: 55.436298,
    longitude: 9.33092,
    altitude: 143,
    schengen: true
  },
  {
    id: 2009,
    name: 'Vesthimmerlands Flyveplads',
    city: 'Vesthimmerland',
    country: 'Denmark',
    iata: '',
    icao: 'EKVH',
    latitude: 56.846901,
    longitude: 9.45861,
    altitude: 119,
    schengen: true
  },
  {
    id: 2010,
    name: 'Stauning Airport',
    city: 'Stauning',
    country: 'Denmark',
    iata: 'STA',
    icao: 'EKVJ',
    latitude: 55.9901008605957,
    longitude: 8.35391044616699,
    altitude: 17,
    schengen: true
  },
  {
    id: 2011,
    name: 'Aalborg Airport',
    city: 'Aalborg',
    country: 'Denmark',
    iata: 'AAL',
    icao: 'EKYT',
    latitude: 57.0927589138,
    longitude: 9.84924316406,
    altitude: 10,
    schengen: true
  },
  {
    id: 2012,
    name: 'El Fuentero Airport',
    city: 'Svenborg',
    country: 'Denmark',
    iata: '',
    icao: 'SVFT',
    latitude: 7.09999990463257,
    longitude: -69.5833358764648,
    altitude: 420,
    schengen: true
  },
  {
    id: 2013,
    name: 'Djibouti-Ambouli Airport',
    city: 'Djibouti',
    country: 'Djibouti',
    iata: 'JIB',
    icao: 'HDAM',
    latitude: 11.5473003387451,
    longitude: 43.1595001220703,
    altitude: 49,
    schengen: false
  },
  {
    id: 2014,
    name: 'Obock Airport',
    city: 'Obock',
    country: 'Djibouti',
    iata: 'OBC',
    icao: 'HDOB',
    latitude: 11.9670000076294,
    longitude: 43.2669982910156,
    altitude: 69,
    schengen: false
  },
  {
    id: 2015,
    name: 'Tadjoura Airport',
    city: 'Tadjoura',
    country: 'Djibouti',
    iata: 'TDJ',
    icao: 'HDTJ',
    latitude: 11.7829999923706,
    longitude: 42.9169998168945,
    altitude: 246,
    schengen: false
  },
  {
    id: 2016,
    name: 'Canefield Airport',
    city: 'Canefield',
    country: 'Dominica',
    iata: 'DCF',
    icao: 'TDCF',
    latitude: 15.3367004394531,
    longitude: -61.3922004699707,
    altitude: 13,
    schengen: false
  },
  {
    id: 2017,
    name: 'Douglas-Charles Airport',
    city: 'Dominica',
    country: 'Dominica',
    iata: 'DOM',
    icao: 'TDPD',
    latitude: 15.547,
    longitude: -61.299999,
    altitude: 73,
    schengen: false
  },
  {
    id: 2018,
    name: 'Arroyo Barril Airport',
    city: 'Samana',
    country: 'Dominican Republic',
    iata: '',
    icao: 'MDAB',
    latitude: 19.198600769,
    longitude: -69.4298019409,
    altitude: 57,
    schengen: false
  },
  {
    id: 2019,
    name: 'Maria Montez International Airport',
    city: 'Barahona',
    country: 'Dominican Republic',
    iata: 'BRX',
    icao: 'MDBH',
    latitude: 18.2514991760254,
    longitude: -71.1203994750977,
    altitude: 10,
    schengen: false
  },
  {
    id: 2020,
    name: 'Cabo Rojo Airport',
    city: 'Cabo Rojo',
    country: 'Dominican Republic',
    iata: 'CBJ',
    icao: 'MDCR',
    latitude: 17.9290008544922,
    longitude: -71.6447982788086,
    altitude: 262,
    schengen: false
  },
  {
    id: 2021,
    name: 'Samaná El Catey International Airport',
    city: 'Samana',
    country: 'Dominican Republic',
    iata: 'AZS',
    icao: 'MDCY',
    latitude: 19.2670001984,
    longitude: -69.7419967651,
    altitude: 30,
    schengen: false
  },
  {
    id: 2022,
    name: 'Constanza - Expedición 14 de Junio National Airport',
    city: 'Constanza',
    country: 'Dominican Republic',
    iata: 'COZ',
    icao: 'MDCZ',
    latitude: 18.907499313354,
    longitude: -70.721900939941,
    altitude: 3950,
    schengen: false
  },
  {
    id: 2023,
    name: 'Herrera Airport',
    city: 'Santo Domingo',
    country: 'Dominican Republic',
    iata: 'HEX',
    icao: 'MDHE',
    latitude: 18.4696998596,
    longitude: -69.9693984985,
    altitude: 190,
    schengen: false
  },
  {
    id: 2024,
    name: 'La Isabela International Airport',
    city: 'La Isabela',
    country: 'Dominican Republic',
    iata: 'JBQ',
    icao: 'MDJB',
    latitude: 18.5725002288818,
    longitude: -69.9856033325195,
    altitude: 98,
    schengen: false
  },
  {
    id: 2025,
    name: 'Casa De Campo International Airport',
    city: 'La Romana',
    country: 'Dominican Republic',
    iata: 'LRM',
    icao: 'MDLR',
    latitude: 18.4507007598877,
    longitude: -68.9117965698242,
    altitude: 240,
    schengen: false
  },
  {
    id: 2026,
    name: 'Monte Cristi Airport',
    city: 'Montecristi',
    country: 'Dominican Republic',
    iata: '',
    icao: 'MDMC',
    latitude: 19.8652992249,
    longitude: -71.6453018188,
    altitude: 121,
    schengen: false
  },
  {
    id: 2027,
    name: 'Punta Cana International Airport',
    city: 'Punta Cana',
    country: 'Dominican Republic',
    iata: 'PUJ',
    icao: 'MDPC',
    latitude: 18.5673999786,
    longitude: -68.3634033203,
    altitude: 47,
    schengen: false
  },
  {
    id: 2028,
    name: 'Gregorio Luperon International Airport',
    city: 'Puerto Plata',
    country: 'Dominican Republic',
    iata: 'POP',
    icao: 'MDPP',
    latitude: 19.7579002380371,
    longitude: -70.5699996948242,
    altitude: 15,
    schengen: false
  },
  {
    id: 2029,
    name: 'Las Américas International Airport',
    city: 'Santo Domingo',
    country: 'Dominican Republic',
    iata: 'SDQ',
    icao: 'MDSD',
    latitude: 18.42970085144,
    longitude: -69.668899536133,
    altitude: 59,
    schengen: false
  },
  {
    id: 2030,
    name: 'San Isidro Air Base',
    city: 'San Isidoro',
    country: 'Dominican Republic',
    iata: '',
    icao: 'MDSI',
    latitude: 18.5037002563477,
    longitude: -69.7617034912109,
    altitude: 111,
    schengen: false
  },
  {
    id: 2031,
    name: 'Cibao International Airport',
    city: 'Santiago',
    country: 'Dominican Republic',
    iata: 'STI',
    icao: 'MDST',
    latitude: 19.406099319458,
    longitude: -70.6046981811523,
    altitude: 565,
    schengen: false
  },
  {
    id: 2032,
    name: 'Suai Airport',
    city: 'Suai',
    country: 'East Timor',
    iata: 'UAI',
    icao: 'WPDB',
    latitude: -9.30331039428711,
    longitude: 125.287002563477,
    altitude: 96,
    schengen: false
  },
  {
    id: 2033,
    name: 'Presidente Nicolau Lobato International Airport',
    city: 'Dili',
    country: 'East Timor',
    iata: 'DIL',
    icao: 'WPDL',
    latitude: -8.54640007019,
    longitude: 125.526000977,
    altitude: 154,
    schengen: false
  },
  {
    id: 2034,
    name: 'Cakung Airport',
    city: 'Baucau',
    country: 'East Timor',
    iata: 'BCH',
    icao: 'WPEC',
    latitude: -8.48902988434,
    longitude: 126.401000977,
    altitude: 1771,
    schengen: false
  },
  {
    id: 2035,
    name: 'Chachoán Airport',
    city: 'Ambato',
    country: 'Ecuador',
    iata: 'ATF',
    icao: 'SEAM',
    latitude: -1.21206998825073,
    longitude: -78.5746002197266,
    altitude: 8502,
    schengen: false
  },
  {
    id: 2036,
    name: 'Hacienda Clementina Airport',
    city: 'Clementia',
    country: 'Ecuador',
    iata: '',
    icao: 'SECM',
    latitude: -1.70626997947693,
    longitude: -79.3788986206055,
    altitude: 328,
    schengen: false
  },
  {
    id: 2037,
    name: 'Francisco De Orellana Airport',
    city: 'Coca',
    country: 'Ecuador',
    iata: 'OCC',
    icao: 'SECO',
    latitude: -0.46288600564003,
    longitude: -76.9868011474609,
    altitude: 834,
    schengen: false
  },
  {
    id: 2038,
    name: 'Mariscal Lamar Airport',
    city: 'Cuenca',
    country: 'Ecuador',
    iata: 'CUE',
    icao: 'SECU',
    latitude: -2.88947,
    longitude: -78.984398,
    altitude: 8306,
    schengen: false
  },
  {
    id: 2039,
    name: 'Seymour Airport',
    city: 'Galapagos',
    country: 'Ecuador',
    iata: 'GPS',
    icao: 'SEGS',
    latitude: -0.453758001327515,
    longitude: -90.2658996582031,
    altitude: 207,
    schengen: false
  },
  {
    id: 2040,
    name: 'José Joaquín de Olmedo International Airport',
    city: 'Guayaquil',
    country: 'Ecuador',
    iata: 'GYE',
    icao: 'SEGU',
    latitude: -2.15741991997,
    longitude: -79.8835983276,
    altitude: 19,
    schengen: false
  },
  {
    id: 2041,
    name: 'Gualaquiza Airport',
    city: 'Gualaquiza',
    country: 'Ecuador',
    iata: '',
    icao: 'SEGZ',
    latitude: -3.42320990562439,
    longitude: -78.5670013427734,
    altitude: 2640,
    schengen: false
  },
  {
    id: 2042,
    name: 'Atahualpa Airport',
    city: 'Ibarra',
    country: 'Ecuador',
    iata: '',
    icao: 'SEIB',
    latitude: 0.338418990373611,
    longitude: -78.1363983154297,
    altitude: 7304,
    schengen: false
  },
  {
    id: 2043,
    name: 'General Villamil Airport',
    city: 'Isabela',
    country: 'Ecuador',
    iata: 'IBB',
    icao: 'SEII',
    latitude: -0.942628026009,
    longitude: -90.9530029297,
    altitude: 35,
    schengen: false
  },
  {
    id: 2044,
    name: 'Km 192 Airport',
    city: 'Km-192',
    country: 'Ecuador',
    iata: '',
    icao: 'SEKK',
    latitude: 0.184202998876572,
    longitude: -79.3919982910156,
    altitude: 1247,
    schengen: false
  },
  {
    id: 2045,
    name: 'Hacienda La Julia Airport',
    city: 'La Julia',
    country: 'Ecuador',
    iata: '',
    icao: 'SELJ',
    latitude: -1.70438003540039,
    longitude: -79.5522994995117,
    altitude: 50,
    schengen: false
  },
  {
    id: 2046,
    name: 'Cotopaxi International Airport',
    city: 'Latacunga',
    country: 'Ecuador',
    iata: 'LTX',
    icao: 'SELT',
    latitude: -0.906833,
    longitude: -78.615799,
    altitude: 9205,
    schengen: false
  },
  {
    id: 2047,
    name: 'Jose Maria Velasco Ibarra Airport',
    city: 'Macara',
    country: 'Ecuador',
    iata: 'MRR',
    icao: 'SEMA',
    latitude: -4.37823009491,
    longitude: -79.9410018921,
    altitude: 1508,
    schengen: false
  },
  {
    id: 2048,
    name: 'Coronel E Carvajal Airport',
    city: 'Macas',
    country: 'Ecuador',
    iata: 'XMS',
    icao: 'SEMC',
    latitude: -2.29917001724243,
    longitude: -78.1207962036133,
    altitude: 3452,
    schengen: false
  },
  {
    id: 2049,
    name: 'General Manuel Serrano Airport',
    city: 'Machala',
    country: 'Ecuador',
    iata: 'MCH',
    icao: 'SEMH',
    latitude: -3.2689,
    longitude: -79.961601,
    altitude: 11,
    schengen: false
  },
  {
    id: 2050,
    name: 'El Carmen Airport',
    city: 'Montalvo',
    country: 'Ecuador',
    iata: '',
    icao: 'SEMO',
    latitude: -2.06700992584229,
    longitude: -76.975700378418,
    altitude: 960,
    schengen: false
  },
  {
    id: 2051,
    name: 'Eloy Alfaro International Airport',
    city: 'Manta',
    country: 'Ecuador',
    iata: 'MEC',
    icao: 'SEMT',
    latitude: -0.94607800245285,
    longitude: -80.6788024902344,
    altitude: 48,
    schengen: false
  },
  {
    id: 2052,
    name: 'Maragrosa Airport',
    city: 'Maragrosa',
    country: 'Ecuador',
    iata: '',
    icao: 'SEMX',
    latitude: -2.85109996795654,
    longitude: -79.803596496582,
    altitude: 18,
    schengen: false
  },
  {
    id: 2053,
    name: 'Amable Calle Gutierrez Airport',
    city: 'Pasaje',
    country: 'Ecuador',
    iata: '',
    icao: 'SEPS',
    latitude: -3.31966996192932,
    longitude: -79.7692031860352,
    altitude: 22,
    schengen: false
  },
  {
    id: 2054,
    name: 'Reales Tamarindos Airport',
    city: 'Portoviejo',
    country: 'Ecuador',
    iata: 'PVO',
    icao: 'SEPV',
    latitude: -1.04165005683899,
    longitude: -80.4721984863281,
    altitude: 130,
    schengen: false
  },
  {
    id: 2055,
    name: 'Quevedo Airport',
    city: 'Quevedo',
    country: 'Ecuador',
    iata: '',
    icao: 'SEQE',
    latitude: -0.989400029182434,
    longitude: -79.4651031494141,
    altitude: 350,
    schengen: false
  },
  {
    id: 2056,
    name: 'Mariscal Sucre International Airport',
    city: 'Quito',
    country: 'Ecuador',
    iata: 'UIO',
    icao: 'SEQM',
    latitude: -0.129166666667,
    longitude: -78.3575,
    altitude: 7841,
    schengen: false
  },
  {
    id: 2057,
    name: 'Chimborazo Airport',
    city: 'Riobamba',
    country: 'Ecuador',
    iata: '',
    icao: 'SERB',
    latitude: -1.65342998504639,
    longitude: -78.6560974121094,
    altitude: 9151,
    schengen: false
  },
  {
    id: 2058,
    name: 'Santa Rosa International Airport',
    city: 'Santa Rosa',
    country: 'Ecuador',
    iata: 'ETR',
    icao: 'SERO',
    latitude: -3.441986,
    longitude: -79.996957,
    altitude: 20,
    schengen: false
  },
  {
    id: 2059,
    name: 'General Ulpiano Paez Airport',
    city: 'Salinas',
    country: 'Ecuador',
    iata: 'SNC',
    icao: 'SESA',
    latitude: -2.20499,
    longitude: -80.988899,
    altitude: 18,
    schengen: false
  },
  {
    id: 2060,
    name: 'Santo Domingo de Los Colorados Airport',
    city: 'Santo Domingo',
    country: 'Ecuador',
    iata: '',
    icao: 'SESD',
    latitude: -0.24822199344635,
    longitude: -79.2144012451172,
    altitude: 1714,
    schengen: false
  },
  {
    id: 2061,
    name: 'Rio Amazonas Airport',
    city: 'Pastaza',
    country: 'Ecuador',
    iata: 'PTZ',
    icao: 'SESM',
    latitude: -1.50523996353,
    longitude: -78.0626983643,
    altitude: 3465,
    schengen: false
  },
  {
    id: 2062,
    name: 'San Cristóbal Airport',
    city: 'San Cristóbal',
    country: 'Ecuador',
    iata: 'SCY',
    icao: 'SEST',
    latitude: -0.910206,
    longitude: -89.617401,
    altitude: 62,
    schengen: false
  },
  {
    id: 2063,
    name: 'Taura Airport',
    city: 'Taura',
    country: 'Ecuador',
    iata: '',
    icao: 'SETA',
    latitude: -2.2610399723053,
    longitude: -79.6801986694336,
    altitude: 56,
    schengen: false
  },
  {
    id: 2064,
    name: 'Mayor Galo Torres Airport',
    city: 'Tena',
    country: 'Ecuador',
    iata: '',
    icao: 'SETE',
    latitude: -0.986766993999481,
    longitude: -77.8195037841797,
    altitude: 1708,
    schengen: false
  },
  {
    id: 2065,
    name: 'Tiputini Airport',
    city: 'Tiputini',
    country: 'Ecuador',
    iata: 'TPN',
    icao: 'SETI',
    latitude: -0.776111006736755,
    longitude: -75.5263977050781,
    altitude: 997,
    schengen: false
  },
  {
    id: 2066,
    name: 'Camilo Ponce Enriquez Airport',
    city: 'La Toma (Catamayo)',
    country: 'Ecuador',
    iata: 'LOH',
    icao: 'SETM',
    latitude: -3.99589,
    longitude: -79.371902,
    altitude: 4056,
    schengen: false
  },
  {
    id: 2067,
    name: 'General Rivadeneira Airport',
    city: 'Esmeraldas',
    country: 'Ecuador',
    iata: 'ESM',
    icao: 'SETN',
    latitude: 0.978519022464752,
    longitude: -79.6266021728516,
    altitude: 32,
    schengen: false
  },
  {
    id: 2068,
    name: 'Tarapoa Airport',
    city: 'Tarapoa',
    country: 'Ecuador',
    iata: 'TPC',
    icao: 'SETR',
    latitude: -0.122956000268459,
    longitude: -76.3377990722656,
    altitude: 814,
    schengen: false
  },
  {
    id: 2069,
    name: 'Teniente Coronel Luis a Mantilla Airport',
    city: 'Tulcan',
    country: 'Ecuador',
    iata: 'TUA',
    icao: 'SETU',
    latitude: 0.809505999088287,
    longitude: -77.7080993652344,
    altitude: 9649,
    schengen: false
  },
  {
    id: 2070,
    name: 'El Alamein International Airport',
    city: 'Dabaa City',
    country: 'Egypt',
    iata: 'DBB',
    icao: 'HEAL',
    latitude: 30.9244995117187,
    longitude: 28.4613990783691,
    altitude: 143,
    schengen: false
  },
  {
    id: 2071,
    name: 'El Arish International Airport',
    city: 'El Arish',
    country: 'Egypt',
    iata: 'AAC',
    icao: 'HEAR',
    latitude: 31.073299408,
    longitude: 33.8358001709,
    altitude: 121,
    schengen: false
  },
  {
    id: 2072,
    name: 'Assiut International Airport',
    city: 'Asyut',
    country: 'Egypt',
    iata: 'ATZ',
    icao: 'HEAT',
    latitude: 27.0464992523,
    longitude: 31.0119991302,
    altitude: 772,
    schengen: false
  },
  {
    id: 2073,
    name: 'El Nouzha Airport',
    city: 'Alexandria',
    country: 'Egypt',
    iata: 'ALY',
    icao: 'HEAX',
    latitude: 31.1839008331299,
    longitude: 29.9489002227783,
    altitude: -6,
    schengen: false
  },
  {
    id: 2074,
    name: 'Almaza Air Force Base',
    city: 'Cairo',
    country: 'Egypt',
    iata: '',
    icao: 'HEAZ',
    latitude: 30.0918006896973,
    longitude: 31.3600006103516,
    altitude: 300,
    schengen: false
  },
  {
    id: 2075,
    name: 'Borg El Arab International Airport',
    city: 'Alexandria',
    country: 'Egypt',
    iata: 'HBE',
    icao: 'HEBA',
    latitude: 30.9176998138428,
    longitude: 29.6963996887207,
    altitude: 177,
    schengen: false
  },
  {
    id: 2076,
    name: 'Abu Simbel Airport',
    city: 'Abu Simbel',
    country: 'Egypt',
    iata: 'ABS',
    icao: 'HEBL',
    latitude: 22.3759994507,
    longitude: 31.611700058,
    altitude: 616,
    schengen: false
  },
  {
    id: 2077,
    name: 'Beni Suef Air Base',
    city: 'Beni Suef',
    country: 'Egypt',
    iata: '',
    icao: 'HEBS',
    latitude: 29.2082996368408,
    longitude: 31.0165996551514,
    altitude: 108,
    schengen: false
  },
  {
    id: 2078,
    name: 'Cairo International Airport',
    city: 'Cairo',
    country: 'Egypt',
    iata: 'CAI',
    icao: 'HECA',
    latitude: 30.1219005584717,
    longitude: 31.4055995941162,
    altitude: 382,
    schengen: false
  },
  {
    id: 2079,
    name: 'Cairo West Airport',
    city: 'Cairo',
    country: 'Egypt',
    iata: 'CWE',
    icao: 'HECW',
    latitude: 30.116399765,
    longitude: 30.9153995514,
    altitude: 550,
    schengen: false
  },
  {
    id: 2080,
    name: 'Hurghada International Airport',
    city: 'Hurghada',
    country: 'Egypt',
    iata: 'HRG',
    icao: 'HEGN',
    latitude: 27.1783008575439,
    longitude: 33.7994003295898,
    altitude: 52,
    schengen: false
  },
  {
    id: 2081,
    name: 'El Gora Airport',
    city: 'El Gorah',
    country: 'Egypt',
    iata: 'EGH',
    icao: 'HEGR',
    latitude: 31.068559,
    longitude: 34.129629,
    altitude: 324,
    schengen: false
  },
  {
    id: 2082,
    name: 'Jiyanklis Air Base',
    city: 'Jiyanklis',
    country: 'Egypt',
    iata: '',
    icao: 'HEGS',
    latitude: 30.8197994232178,
    longitude: 30.1912002563477,
    altitude: 49,
    schengen: false
  },
  {
    id: 2083,
    name: 'Luxor International Airport',
    city: 'Luxor',
    country: 'Egypt',
    iata: 'LXR',
    icao: 'HELX',
    latitude: 25.671,
    longitude: 32.7066,
    altitude: 294,
    schengen: false
  },
  {
    id: 2084,
    name: 'Marsa Alam International Airport',
    city: 'Marsa Alam',
    country: 'Egypt',
    iata: 'RMF',
    icao: 'HEMA',
    latitude: 25.557100296,
    longitude: 34.5836982727,
    altitude: 251,
    schengen: false
  },
  {
    id: 2085,
    name: 'Sohag International Airport',
    city: 'Sohag',
    country: 'Egypt',
    iata: 'HMB',
    icao: 'HEMK',
    latitude: 26.342778,
    longitude: 31.742778,
    altitude: 310,
    schengen: false
  },
  {
    id: 2086,
    name: 'Mersa Matruh Airport',
    city: 'Mersa-matruh',
    country: 'Egypt',
    iata: 'MUH',
    icao: 'HEMM',
    latitude: 31.3253993988,
    longitude: 27.2217006683,
    altitude: 94,
    schengen: false
  },
  {
    id: 2087,
    name: 'Shark El Oweinat International Airport',
    city: 'Sharq Al-Owainat',
    country: 'Egypt',
    iata: 'GSQ',
    icao: 'HEOW',
    latitude: 22.5856990814209,
    longitude: 28.7166004180908,
    altitude: 859,
    schengen: false
  },
  {
    id: 2088,
    name: 'Port Said Airport',
    city: 'Port Said',
    country: 'Egypt',
    iata: 'PSD',
    icao: 'HEPS',
    latitude: 31.2793998718262,
    longitude: 32.2400016784668,
    altitude: 8,
    schengen: false
  },
  {
    id: 2089,
    name: 'St Catherine International Airport',
    city: 'St. Catherine',
    country: 'Egypt',
    iata: 'SKV',
    icao: 'HESC',
    latitude: 28.685300827,
    longitude: 34.0625,
    altitude: 4368,
    schengen: false
  },
  {
    id: 2090,
    name: 'Sharm El Sheikh International Airport',
    city: 'Sharm El Sheikh',
    country: 'Egypt',
    iata: 'SSH',
    icao: 'HESH',
    latitude: 27.9773006439,
    longitude: 34.3950004578,
    altitude: 143,
    schengen: false
  },
  {
    id: 2091,
    name: 'Aswan International Airport',
    city: 'Aswan',
    country: 'Egypt',
    iata: 'ASW',
    icao: 'HESN',
    latitude: 23.9643993378,
    longitude: 32.8199996948,
    altitude: 662,
    schengen: false
  },
  {
    id: 2092,
    name: 'Taba International Airport',
    city: 'Taba',
    country: 'Egypt',
    iata: 'TCP',
    icao: 'HETB',
    latitude: 29.5877990723,
    longitude: 34.7780990601,
    altitude: 2415,
    schengen: false
  },
  {
    id: 2093,
    name: 'El Tor Airport',
    city: 'El-tor',
    country: 'Egypt',
    iata: 'ELT',
    icao: 'HETR',
    latitude: 28.2089996337891,
    longitude: 33.6455001831055,
    altitude: 115,
    schengen: false
  },
  {
    id: 2094,
    name: 'Monseñor Óscar Arnulfo Romero International Airport',
    city: 'San Salvador',
    country: 'El Salvador',
    iata: 'SAL',
    icao: 'MSLP',
    latitude: 13.4409,
    longitude: -89.055702,
    altitude: 101,
    schengen: false
  },
  {
    id: 2095,
    name: 'El Papalon Airport',
    city: 'San Miguel',
    country: 'El Salvador',
    iata: '',
    icao: 'MSSM',
    latitude: 13.4441003799438,
    longitude: -88.1269989013672,
    altitude: 283,
    schengen: false
  },
  {
    id: 2096,
    name: 'Ilopango International Airport',
    city: 'San Salvador',
    country: 'El Salvador',
    iata: '',
    icao: 'MSSS',
    latitude: 13.6995000839233,
    longitude: -89.1199035644531,
    altitude: 2021,
    schengen: false
  },
  {
    id: 2097,
    name: 'Annobón Airport',
    city: 'San Antonio de Pale',
    country: 'Equatorial Guinea',
    iata: 'NBN',
    icao: 'FGAB',
    latitude: -1.410277,
    longitude: 5.621944,
    altitude: 82,
    schengen: false
  },
  {
    id: 2098,
    name: 'Bata Airport',
    city: 'Bata',
    country: 'Equatorial Guinea',
    iata: 'BSG',
    icao: 'FGBT',
    latitude: 1.90547001361847,
    longitude: 9.80568027496338,
    altitude: 13,
    schengen: false
  },
  {
    id: 2099,
    name: 'Malabo Airport',
    city: 'Malabo',
    country: 'Equatorial Guinea',
    iata: 'SSG',
    icao: 'FGSL',
    latitude: 3.75527000427246,
    longitude: 8.70872020721436,
    altitude: 76,
    schengen: false
  },
  {
    id: 2100,
    name: 'Asmara International Airport',
    city: 'Asmara',
    country: 'Eritrea',
    iata: 'ASM',
    icao: 'HHAS',
    latitude: 15.2918996810913,
    longitude: 38.910701751709,
    altitude: 7661,
    schengen: false
  },
  {
    id: 2101,
    name: 'Massawa International Airport',
    city: 'Massawa',
    country: 'Eritrea',
    iata: 'MSW',
    icao: 'HHMS',
    latitude: 15.6700000762939,
    longitude: 39.3700981140137,
    altitude: 194,
    schengen: false
  },
  {
    id: 2102,
    name: 'Assab International Airport',
    city: 'Assab',
    country: 'Eritrea',
    iata: 'ASA',
    icao: 'HHSB',
    latitude: 13.0718002319336,
    longitude: 42.6450004577637,
    altitude: 46,
    schengen: false
  },
  {
    id: 2103,
    name: 'Tallinn Linnahall Heliport',
    city: 'Tallinn',
    country: 'Estonia',
    iata: '',
    icao: 'EECL',
    latitude: 59.4486198425293,
    longitude: 24.7532272338867,
    altitude: 23,
    schengen: true
  },
  {
    id: 2104,
    name: 'Ämari Air Base',
    city: 'Armari Air Force Base',
    country: 'Estonia',
    iata: '',
    icao: 'EEEI',
    latitude: 59.2602996826172,
    longitude: 24.2084999084473,
    altitude: 65,
    schengen: true
  },
  {
    id: 2105,
    name: 'Kärdla Airport',
    city: 'Kardla',
    country: 'Estonia',
    iata: 'KDL',
    icao: 'EEKA',
    latitude: 58.9907989501953,
    longitude: 22.8306999206543,
    altitude: 18,
    schengen: true
  },
  {
    id: 2106,
    name: 'Kuressaare Airport',
    city: 'Kuressaare',
    country: 'Estonia',
    iata: 'URE',
    icao: 'EEKE',
    latitude: 58.2299003601074,
    longitude: 22.50950050354,
    altitude: 14,
    schengen: true
  },
  {
    id: 2107,
    name: 'Kihnu Airfield',
    city: 'Kihnu',
    country: 'Estonia',
    iata: '',
    icao: 'EEKU',
    latitude: 58.1483001708984,
    longitude: 24.0025005340576,
    altitude: 10,
    schengen: true
  },
  {
    id: 2108,
    name: 'Pärnu Airport',
    city: 'Parnu',
    country: 'Estonia',
    iata: 'EPU',
    icao: 'EEPU',
    latitude: 58.4189987182617,
    longitude: 24.4727993011475,
    altitude: 47,
    schengen: true
  },
  {
    id: 2109,
    name: 'Ruhnu Airfield',
    city: 'Ruhnu',
    country: 'Estonia',
    iata: '',
    icao: 'EERU',
    latitude: 57.7839012145996,
    longitude: 23.2660999298096,
    altitude: 10,
    schengen: true
  },
  {
    id: 2110,
    name: 'Lennart Meri Tallinn Airport',
    city: 'Tallinn-ulemiste International',
    country: 'Estonia',
    iata: 'TLL',
    icao: 'EETN',
    latitude: 59.4132995605,
    longitude: 24.8327999115,
    altitude: 131,
    schengen: true
  },
  {
    id: 2111,
    name: 'Tartu Airport',
    city: 'Tartu',
    country: 'Estonia',
    iata: 'TAY',
    icao: 'EETU',
    latitude: 58.3074989319,
    longitude: 26.6903991699,
    altitude: 219,
    schengen: true
  },
  {
    id: 2112,
    name: 'Addis Ababa Bole International Airport',
    city: 'Addis Ababa',
    country: 'Ethiopia',
    iata: 'ADD',
    icao: 'HAAB',
    latitude: 8.97789001465,
    longitude: 38.7993011475,
    altitude: 7630,
    schengen: false
  },
  {
    id: 2113,
    name: 'Lideta Army Airport',
    city: 'Addis Ababa',
    country: 'Ethiopia',
    iata: '',
    icao: 'HAAL',
    latitude: 9.003685,
    longitude: 38.725498,
    altitude: 7749,
    schengen: false
  },
  {
    id: 2114,
    name: 'Arba Minch Airport',
    city: 'Arba Minch',
    country: 'Ethiopia',
    iata: 'AMH',
    icao: 'HAAM',
    latitude: 6.03939008712769,
    longitude: 37.5904998779297,
    altitude: 3901,
    schengen: false
  },
  {
    id: 2115,
    name: 'Axum Airport',
    city: 'Axum',
    country: 'Ethiopia',
    iata: 'AXU',
    icao: 'HAAX',
    latitude: 14.1468000411987,
    longitude: 38.7728004455566,
    altitude: 6959,
    schengen: false
  },
  {
    id: 2116,
    name: 'Baco Airport',
    city: 'Baco',
    country: 'Ethiopia',
    iata: 'BCO',
    icao: 'HABC',
    latitude: 5.78287,
    longitude: 36.562,
    altitude: 4580,
    schengen: false
  },
  {
    id: 2117,
    name: 'Bahir Dar Airport',
    city: 'Bahar Dar',
    country: 'Ethiopia',
    iata: 'BJR',
    icao: 'HABD',
    latitude: 11.608099937439,
    longitude: 37.3216018676758,
    altitude: 5978,
    schengen: false
  },
  {
    id: 2118,
    name: 'Beica Airport',
    city: 'Beica',
    country: 'Ethiopia',
    iata: 'BEI',
    icao: 'HABE',
    latitude: 9.38638973236084,
    longitude: 34.521900177002,
    altitude: 5410,
    schengen: false
  },
  {
    id: 2119,
    name: 'Combolcha Airport',
    city: 'Dessie',
    country: 'Ethiopia',
    iata: 'DSE',
    icao: 'HADC',
    latitude: 11.0825004577637,
    longitude: 39.7113990783691,
    altitude: 6117,
    schengen: false
  },
  {
    id: 2120,
    name: 'Dembidollo Airport',
    city: 'Dembidollo',
    country: 'Ethiopia',
    iata: 'DEM',
    icao: 'HADD',
    latitude: 8.55399990081787,
    longitude: 34.8580017089844,
    altitude: 5200,
    schengen: false
  },
  {
    id: 2121,
    name: 'Debra Marcos Airport',
    city: 'Debre Marqos',
    country: 'Ethiopia',
    iata: 'DBM',
    icao: 'HADM',
    latitude: 10.3500003814697,
    longitude: 37.7169990539551,
    altitude: 8136,
    schengen: false
  },
  {
    id: 2122,
    name: 'Aba Tenna Dejazmach Yilma International Airport',
    city: 'Dire Dawa',
    country: 'Ethiopia',
    iata: 'DIR',
    icao: 'HADR',
    latitude: 9.62469959259033,
    longitude: 41.8541984558105,
    altitude: 3827,
    schengen: false
  },
  {
    id: 2123,
    name: 'Debre Tabor Airport',
    city: 'Debre Tabor',
    country: 'Ethiopia',
    iata: 'DBT',
    icao: 'HADT',
    latitude: 11.9670000076294,
    longitude: 38,
    altitude: 8490,
    schengen: false
  },
  {
    id: 2124,
    name: 'Robe Airport',
    city: 'Goba',
    country: 'Ethiopia',
    iata: 'GOB',
    icao: 'HAGB',
    latitude: 7.1160634,
    longitude: 40.0463033,
    altitude: 7892,
    schengen: false
  },
  {
    id: 2125,
    name: 'Gambella Airport',
    city: 'Gambella',
    country: 'Ethiopia',
    iata: 'GMB',
    icao: 'HAGM',
    latitude: 8.12876033782959,
    longitude: 34.5630989074707,
    altitude: 1614,
    schengen: false
  },
  {
    id: 2126,
    name: 'Gonder Airport',
    city: 'Gondar',
    country: 'Ethiopia',
    iata: 'GDQ',
    icao: 'HAGN',
    latitude: 12.5199003219604,
    longitude: 37.4339981079102,
    altitude: 6449,
    schengen: false
  },
  {
    id: 2127,
    name: 'Gode Airport',
    city: 'Gode',
    country: 'Ethiopia',
    iata: 'GDE',
    icao: 'HAGO',
    latitude: 5.93513011932,
    longitude: 43.5786018372,
    altitude: 834,
    schengen: false
  },
  {
    id: 2128,
    name: 'Gore Airport',
    city: 'Gore',
    country: 'Ethiopia',
    iata: 'GOR',
    icao: 'HAGR',
    latitude: 8.1614,
    longitude: 35.5529,
    altitude: 6580,
    schengen: false
  },
  {
    id: 2129,
    name: 'Harar Meda Airport',
    city: 'Debre Zeyit',
    country: 'Ethiopia',
    iata: 'QHR',
    icao: 'HAHM',
    latitude: 8.7163,
    longitude: 39.0059,
    altitude: 6201,
    schengen: false
  },
  {
    id: 2130,
    name: 'Humera Airport',
    city: 'Humera',
    country: 'Ethiopia',
    iata: 'HUE',
    icao: 'HAHU',
    latitude: 14.25,
    longitude: 36.5830001831055,
    altitude: 1930,
    schengen: false
  },
  {
    id: 2131,
    name: 'Wilwal International Airport',
    city: 'Jijiga',
    country: 'Ethiopia',
    iata: 'JIJ',
    icao: 'HAJJ',
    latitude: 9.3325,
    longitude: 42.9121,
    altitude: 5954,
    schengen: false
  },
  {
    id: 2132,
    name: 'Jimma Airport',
    city: 'Jimma',
    country: 'Ethiopia',
    iata: 'JIM',
    icao: 'HAJM',
    latitude: 7.66609001159668,
    longitude: 36.8166007995605,
    altitude: 5500,
    schengen: false
  },
  {
    id: 2133,
    name: 'Kabri Dehar Airport',
    city: 'Kabri Dehar',
    country: 'Ethiopia',
    iata: 'ABK',
    icao: 'HAKD',
    latitude: 6.734,
    longitude: 44.252998,
    altitude: 1800,
    schengen: false
  },
  {
    id: 2134,
    name: 'Awassa Airport',
    city: 'Awasa',
    country: 'Ethiopia',
    iata: 'AWA',
    icao: 'HALA',
    latitude: 7.06699991226196,
    longitude: 38.5,
    altitude: 5450,
    schengen: false
  },
  {
    id: 2135,
    name: 'Lalibella Airport',
    city: 'Lalibella',
    country: 'Ethiopia',
    iata: 'LLI',
    icao: 'HALL',
    latitude: 11.9750003814697,
    longitude: 38.9799995422363,
    altitude: 6506,
    schengen: false
  },
  {
    id: 2136,
    name: 'Mekane Selam Airport',
    city: 'Mekane Selam',
    country: 'Ethiopia',
    iata: 'MKS',
    icao: 'HAMA',
    latitude: 10.7254,
    longitude: 38.7415,
    altitude: 8405,
    schengen: false
  },
  {
    id: 2137,
    name: 'Mekele Airport',
    city: 'Makale',
    country: 'Ethiopia',
    iata: 'MQX',
    icao: 'HAMK',
    latitude: 13.467399597168,
    longitude: 39.5335006713867,
    altitude: 7396,
    schengen: false
  },
  {
    id: 2138,
    name: 'Mizan Teferi Airport',
    city: 'Mizan Teferi',
    country: 'Ethiopia',
    iata: 'MTF',
    icao: 'HAMT',
    latitude: 6.9571,
    longitude: 35.5547,
    altitude: 4396,
    schengen: false
  },
  {
    id: 2139,
    name: 'Asosa Airport',
    city: 'Asosa',
    country: 'Ethiopia',
    iata: 'ASO',
    icao: 'HASO',
    latitude: 10.018500328064,
    longitude: 34.5862998962402,
    altitude: 5100,
    schengen: false
  },
  {
    id: 2140,
    name: 'Tippi Airport',
    city: 'Tippi',
    country: 'Ethiopia',
    iata: 'TIE',
    icao: 'HATP',
    latitude: 7.2024,
    longitude: 35.415,
    altitude: 1100,
    schengen: false
  },
  {
    id: 2141,
    name: 'Mount Pleasant Airport',
    city: 'Mount Pleasant',
    country: 'Falkland Islands',
    iata: 'MPN',
    icao: 'EGYP',
    latitude: -51.8227996826172,
    longitude: -58.4472007751465,
    altitude: 244,
    schengen: false
  },
  {
    id: 2142,
    name: 'Port Stanley Airport',
    city: 'Stanley',
    country: 'Falkland Islands',
    iata: 'PSY',
    icao: 'SFAL',
    latitude: -51.685699462891,
    longitude: -57.777599334717,
    altitude: 75,
    schengen: false
  },
  {
    id: 2143,
    name: 'Frooba Heliport',
    city: 'Frooba',
    country: 'Faroe Islands',
    iata: '',
    icao: 'EKFA',
    latitude: 61.543701171875,
    longitude: -6.7744197845459,
    altitude: 77,
    schengen: false
  },
  {
    id: 2144,
    name: 'Mykines Heliport',
    city: 'Mykines',
    country: 'Faroe Islands',
    iata: '',
    icao: 'EKMS',
    latitude: 62.1021003723,
    longitude: -7.6459197998,
    altitude: 110,
    schengen: false
  },
  {
    id: 2145,
    name: 'Stóra Dímun Heliport',
    city: 'Stora Dimun',
    country: 'Faroe Islands',
    iata: '',
    icao: 'EKSR',
    latitude: 61.6852989196777,
    longitude: -6.75850009918213,
    altitude: 305,
    schengen: false
  },
  {
    id: 2146,
    name: 'Skúvoy Heliport',
    city: 'Skuvoy',
    country: 'Faroe Islands',
    iata: '',
    icao: 'EKSY',
    latitude: 61.7695999145508,
    longitude: -6.80341005325317,
    altitude: 70,
    schengen: false
  },
  {
    id: 2147,
    name: 'Tórshavn/Bodanes Heliport',
    city: 'Torshavn',
    country: 'Faroe Islands',
    iata: '',
    icao: 'EKTB',
    latitude: 62.0217018127441,
    longitude: -6.7574200630188,
    altitude: 68,
    schengen: false
  },
  {
    id: 2148,
    name: 'Vagar Airport',
    city: 'Vagar',
    country: 'Faroe Islands',
    iata: 'FAE',
    icao: 'EKVG',
    latitude: 62.0635986328125,
    longitude: -7.27721977233887,
    altitude: 280,
    schengen: false
  },
  {
    id: 2149,
    name: 'Cicia Airport',
    city: 'Cicia',
    country: 'Fiji',
    iata: 'ICI',
    icao: 'NFCI',
    latitude: -17.7432994843,
    longitude: -179.341995239,
    altitude: 13,
    schengen: false
  },
  {
    id: 2150,
    name: 'Nadi International Airport',
    city: 'Nandi',
    country: 'Fiji',
    iata: 'NAN',
    icao: 'NFFN',
    latitude: -17.7553997039795,
    longitude: 177.442993164063,
    altitude: 59,
    schengen: false
  },
  {
    id: 2151,
    name: 'Malolo Lailai Island Airport',
    city: 'Malolo Lailai Island',
    country: 'Fiji',
    iata: 'PTF',
    icao: 'NFFO',
    latitude: -17.7779006958,
    longitude: 177.197006226,
    altitude: 10,
    schengen: false
  },
  {
    id: 2152,
    name: 'Vunisea Airport',
    city: 'Vunisea',
    country: 'Fiji',
    iata: 'KDV',
    icao: 'NFKD',
    latitude: -19.0580997467,
    longitude: 178.156997681,
    altitude: 6,
    schengen: false
  },
  {
    id: 2153,
    name: 'Mana Island Airport',
    city: 'Mana Island',
    country: 'Fiji',
    iata: 'MNF',
    icao: 'NFMA',
    latitude: -17.6730995178,
    longitude: 177.098007202,
    altitude: 0,
    schengen: false
  },
  {
    id: 2154,
    name: 'Moala Airport',
    city: 'Moala',
    country: 'Fiji',
    iata: 'MFJ',
    icao: 'NFMO',
    latitude: -18.5666999817,
    longitude: 179.951004028,
    altitude: 13,
    schengen: false
  },
  {
    id: 2155,
    name: 'Nausori International Airport',
    city: 'Nausori',
    country: 'Fiji',
    iata: 'SUV',
    icao: 'NFNA',
    latitude: -18.0433006286621,
    longitude: 178.559005737305,
    altitude: 17,
    schengen: false
  },
  {
    id: 2156,
    name: 'Levuka Airfield',
    city: 'Levuka',
    country: 'Fiji',
    iata: 'LEV',
    icao: 'NFNB',
    latitude: -17.7110996246,
    longitude: 178.759002686,
    altitude: 11,
    schengen: false
  },
  {
    id: 2157,
    name: 'Ngau Airport',
    city: 'Ngau',
    country: 'Fiji',
    iata: 'NGI',
    icao: 'NFNG',
    latitude: -18.1156005859,
    longitude: 179.339996338,
    altitude: 50,
    schengen: false
  },
  {
    id: 2158,
    name: 'Laucala Island Airport',
    city: 'Laucala',
    country: 'Fiji',
    iata: 'LUC',
    icao: 'NFNH',
    latitude: -16.7481002807617,
    longitude: -179.667007446289,
    altitude: 10,
    schengen: false
  },
  {
    id: 2159,
    name: 'Lakeba Island Airport',
    city: 'Lakeba Island',
    country: 'Fiji',
    iata: 'LKB',
    icao: 'NFNK',
    latitude: -18.1991996765,
    longitude: -178.817001343,
    altitude: 280,
    schengen: false
  },
  {
    id: 2160,
    name: 'Labasa Airport',
    city: 'Lambasa',
    country: 'Fiji',
    iata: 'LBS',
    icao: 'NFNL',
    latitude: -16.4666996002197,
    longitude: 179.339996337891,
    altitude: 44,
    schengen: false
  },
  {
    id: 2161,
    name: 'Matei Airport',
    city: 'Matei',
    country: 'Fiji',
    iata: 'TVU',
    icao: 'NFNM',
    latitude: -16.6905994415,
    longitude: -179.876998901,
    altitude: 60,
    schengen: false
  },
  {
    id: 2162,
    name: 'Koro Island Airport',
    city: 'Koro Island',
    country: 'Fiji',
    iata: 'KXF',
    icao: 'NFNO',
    latitude: -17.3458003998,
    longitude: 179.42199707,
    altitude: 358,
    schengen: false
  },
  {
    id: 2163,
    name: 'Rotuma Airport',
    city: 'Rotuma',
    country: 'Fiji',
    iata: 'RTA',
    icao: 'NFNR',
    latitude: -12.4825000762939,
    longitude: 177.070999145508,
    altitude: 22,
    schengen: false
  },
  {
    id: 2164,
    name: 'Savusavu Airport',
    city: 'Savusavu',
    country: 'Fiji',
    iata: 'SVU',
    icao: 'NFNS',
    latitude: -16.8027992249,
    longitude: 179.341003418,
    altitude: 17,
    schengen: false
  },
  {
    id: 2165,
    name: 'Yasawa Island Airport',
    city: 'Yasawa Island',
    country: 'Fiji',
    iata: 'YAS',
    icao: 'NFSW',
    latitude: -16.7588996887207,
    longitude: 177.544998168945,
    altitude: 37,
    schengen: false
  },
  {
    id: 2166,
    name: 'Turtle Island Seaplane Base',
    city: 'Turtle Island',
    country: 'Fiji',
    iata: 'TTL',
    icao: 'NFUL',
    latitude: -16.966,
    longitude: 177.368,
    altitude: 0,
    schengen: false
  },
  {
    id: 2167,
    name: 'Vanua Balavu Airport',
    city: 'Vanua Balavu',
    country: 'Fiji',
    iata: 'VBV',
    icao: 'NFVB',
    latitude: -17.2689990997314,
    longitude: -178.975997924805,
    altitude: 76,
    schengen: false
  },
  {
    id: 2168,
    name: 'Enontekio Airport',
    city: 'Enontekio',
    country: 'Finland',
    iata: 'ENF',
    icao: 'EFET',
    latitude: 68.362602233887,
    longitude: 23.424299240112,
    altitude: 1005,
    schengen: true
  },
  {
    id: 2169,
    name: 'Eura Airport',
    city: 'Eura',
    country: 'Finland',
    iata: '',
    icao: 'EFEU',
    latitude: 61.1161003112793,
    longitude: 22.2014007568359,
    altitude: 259,
    schengen: true
  },
  {
    id: 2170,
    name: 'Halli Airport',
    city: 'Halli',
    country: 'Finland',
    iata: 'KEV',
    icao: 'EFHA',
    latitude: 61.856039,
    longitude: 24.786686,
    altitude: 479,
    schengen: true
  },
  {
    id: 2171,
    name: 'Hernesaari Heliport',
    city: 'Helsinki',
    country: 'Finland',
    iata: '',
    icao: 'EFHE',
    latitude: 60.147777557373,
    longitude: 24.9244441986084,
    altitude: 7,
    schengen: true
  },
  {
    id: 2172,
    name: 'Helsinki Malmi Airport',
    city: 'Helsinki',
    country: 'Finland',
    iata: 'HEM',
    icao: 'EFHF',
    latitude: 60.2546005249023,
    longitude: 25.0428009033203,
    altitude: 57,
    schengen: true
  },
  {
    id: 2173,
    name: 'Helsinki Vantaa Airport',
    city: 'Helsinki',
    country: 'Finland',
    iata: 'HEL',
    icao: 'EFHK',
    latitude: 60.317199707031,
    longitude: 24.963300704956,
    altitude: 179,
    schengen: true
  },
  {
    id: 2174,
    name: 'Hameenkyro Airport',
    city: 'Hameenkyro',
    country: 'Finland',
    iata: '',
    icao: 'EFHM',
    latitude: 61.6897010803223,
    longitude: 23.0736999511719,
    altitude: 449,
    schengen: true
  },
  {
    id: 2175,
    name: 'Hanko Airport',
    city: 'Hanko',
    country: 'Finland',
    iata: '',
    icao: 'EFHN',
    latitude: 59.8488998413086,
    longitude: 23.0835990905762,
    altitude: 20,
    schengen: true
  },
  {
    id: 2176,
    name: 'Hyvinkää Airfield',
    city: 'Hyvinkaa',
    country: 'Finland',
    iata: 'HYV',
    icao: 'EFHV',
    latitude: 60.6543998718,
    longitude: 24.8810997009,
    altitude: 430,
    schengen: true
  },
  {
    id: 2177,
    name: 'Kiikala Airport',
    city: 'Kikala',
    country: 'Finland',
    iata: '',
    icao: 'EFIK',
    latitude: 60.4625015259,
    longitude: 23.6525001526,
    altitude: 381,
    schengen: true
  },
  {
    id: 2178,
    name: 'Immola Airport',
    city: 'Immola',
    country: 'Finland',
    iata: '',
    icao: 'EFIM',
    latitude: 61.2491989135742,
    longitude: 28.9036998748779,
    altitude: 338,
    schengen: true
  },
  {
    id: 2179,
    name: 'Kitee Airport',
    city: 'Kitee',
    country: 'Finland',
    iata: 'KTQ',
    icao: 'EFIT',
    latitude: 62.1661,
    longitude: 30.073601,
    altitude: 364,
    schengen: true
  },
  {
    id: 2180,
    name: 'Ivalo Airport',
    city: 'Ivalo',
    country: 'Finland',
    iata: 'IVL',
    icao: 'EFIV',
    latitude: 68.607299804688,
    longitude: 27.405300140381,
    altitude: 481,
    schengen: true
  },
  {
    id: 2181,
    name: 'Joensuu Airport',
    city: 'Joensuu',
    country: 'Finland',
    iata: 'JOE',
    icao: 'EFJO',
    latitude: 62.662899017334,
    longitude: 29.607500076294,
    altitude: 398,
    schengen: true
  },
  {
    id: 2182,
    name: 'Jyvaskyla Airport',
    city: 'Jyvaskyla',
    country: 'Finland',
    iata: 'JYV',
    icao: 'EFJY',
    latitude: 62.399501800537,
    longitude: 25.678300857544,
    altitude: 459,
    schengen: true
  },
  {
    id: 2183,
    name: 'Kauhava Airport',
    city: 'Kauhava',
    country: 'Finland',
    iata: 'KAU',
    icao: 'EFKA',
    latitude: 63.127102,
    longitude: 23.051399,
    altitude: 151,
    schengen: true
  },
  {
    id: 2184,
    name: 'Kemi-Tornio Airport',
    city: 'Kemi',
    country: 'Finland',
    iata: 'KEM',
    icao: 'EFKE',
    latitude: 65.778701782227,
    longitude: 24.582099914551,
    altitude: 61,
    schengen: true
  },
  {
    id: 2185,
    name: 'Kajaani Airport',
    city: 'Kajaani',
    country: 'Finland',
    iata: 'KAJ',
    icao: 'EFKI',
    latitude: 64.285499572754,
    longitude: 27.692399978638,
    altitude: 483,
    schengen: true
  },
  {
    id: 2186,
    name: 'Kauhajoki Airport',
    city: 'Kauhajoki',
    country: 'Finland',
    iata: 'KHJ',
    icao: 'EFKJ',
    latitude: 62.4625015259,
    longitude: 22.3931007385,
    altitude: 407,
    schengen: true
  },
  {
    id: 2187,
    name: 'Kokkola-Pietarsaari Airport',
    city: 'Kruunupyy',
    country: 'Finland',
    iata: 'KOK',
    icao: 'EFKK',
    latitude: 63.721199035645,
    longitude: 23.143100738525,
    altitude: 84,
    schengen: true
  },
  {
    id: 2188,
    name: 'Kemijarvi Airport',
    city: 'Kemijarvi',
    country: 'Finland',
    iata: '',
    icao: 'EFKM',
    latitude: 66.712898,
    longitude: 27.156799,
    altitude: 692,
    schengen: true
  },
  {
    id: 2189,
    name: 'Kuusamo Airport',
    city: 'Kuusamo',
    country: 'Finland',
    iata: 'KAO',
    icao: 'EFKS',
    latitude: 65.987602233887,
    longitude: 29.239400863647,
    altitude: 866,
    schengen: true
  },
  {
    id: 2190,
    name: 'Kittilä Airport',
    city: 'Kittila',
    country: 'Finland',
    iata: 'KTT',
    icao: 'EFKT',
    latitude: 67.700996398926,
    longitude: 24.846799850464,
    altitude: 644,
    schengen: true
  },
  {
    id: 2191,
    name: 'Kuopio Airport',
    city: 'Kuopio',
    country: 'Finland',
    iata: 'KUO',
    icao: 'EFKU',
    latitude: 63.007099151611,
    longitude: 27.797800064087,
    altitude: 323,
    schengen: true
  },
  {
    id: 2192,
    name: 'Kymi Airport',
    city: 'Kotka',
    country: 'Finland',
    iata: '',
    icao: 'EFKY',
    latitude: 60.5713996887207,
    longitude: 26.8960990905762,
    altitude: 223,
    schengen: true
  },
  {
    id: 2193,
    name: 'Lahti Vesivehmaa Airport',
    city: 'Vesivehmaa',
    country: 'Finland',
    iata: 'QLF',
    icao: 'EFLA',
    latitude: 61.144199,
    longitude: 25.693501,
    altitude: 502,
    schengen: true
  },
  {
    id: 2194,
    name: 'Lappeenranta Airport',
    city: 'Lappeenranta',
    country: 'Finland',
    iata: 'LPP',
    icao: 'EFLP',
    latitude: 61.044601,
    longitude: 28.144743,
    altitude: 349,
    schengen: true
  },
  {
    id: 2195,
    name: 'Mariehamn Airport',
    city: 'Mariehamn',
    country: 'Finland',
    iata: 'MHQ',
    icao: 'EFMA',
    latitude: 60.122200012207,
    longitude: 19.898199081421,
    altitude: 17,
    schengen: true
  },
  {
    id: 2196,
    name: 'Menkijarvi Airport',
    city: 'Menkijarvi',
    country: 'Finland',
    iata: '',
    icao: 'EFME',
    latitude: 62.9467010498047,
    longitude: 23.5188999176025,
    altitude: 331,
    schengen: true
  },
  {
    id: 2197,
    name: 'Mikkeli Airport',
    city: 'Mikkeli',
    country: 'Finland',
    iata: 'MIK',
    icao: 'EFMI',
    latitude: 61.6866,
    longitude: 27.201799,
    altitude: 329,
    schengen: true
  },
  {
    id: 2198,
    name: 'Nummela Airport',
    city: 'Nummela',
    country: 'Finland',
    iata: '',
    icao: 'EFNU',
    latitude: 60.3339004517,
    longitude: 24.2964000702,
    altitude: 367,
    schengen: true
  },
  {
    id: 2199,
    name: 'Oulu Airport',
    city: 'Oulu',
    country: 'Finland',
    iata: 'OUL',
    icao: 'EFOU',
    latitude: 64.930099487305,
    longitude: 25.354600906372,
    altitude: 47,
    schengen: true
  },
  {
    id: 2200,
    name: 'Piikajarvi Airport',
    city: 'Piikajarvi',
    country: 'Finland',
    iata: '',
    icao: 'EFPI',
    latitude: 61.2456016540527,
    longitude: 22.1933994293213,
    altitude: 148,
    schengen: true
  },
  {
    id: 2201,
    name: 'Pori Airport',
    city: 'Pori',
    country: 'Finland',
    iata: 'POR',
    icao: 'EFPO',
    latitude: 61.461700439453,
    longitude: 21.799999237061,
    altitude: 44,
    schengen: true
  },
  {
    id: 2202,
    name: 'Pudasjärvi Airport',
    city: 'Pudasjarvi',
    country: 'Finland',
    iata: '',
    icao: 'EFPU',
    latitude: 65.4021987915,
    longitude: 26.9468994141,
    altitude: 397,
    schengen: true
  },
  {
    id: 2203,
    name: 'Pyhäsalmi Airport',
    city: 'Pyhasalmi',
    country: 'Finland',
    iata: '',
    icao: 'EFPY',
    latitude: 63.7318992615,
    longitude: 25.9263000488,
    altitude: 528,
    schengen: true
  },
  {
    id: 2204,
    name: 'Raahe Pattijoki Airport',
    city: 'Pattijoki',
    country: 'Finland',
    iata: '',
    icao: 'EFRH',
    latitude: 64.6881027222,
    longitude: 24.6958007812,
    altitude: 118,
    schengen: true
  },
  {
    id: 2205,
    name: 'Rantasalmi Airport',
    city: 'Rantasalmi',
    country: 'Finland',
    iata: '',
    icao: 'EFRN',
    latitude: 62.0654983521,
    longitude: 28.3565006256,
    altitude: 292,
    schengen: true
  },
  {
    id: 2206,
    name: 'Rovaniemi Airport',
    city: 'Rovaniemi',
    country: 'Finland',
    iata: 'RVN',
    icao: 'EFRO',
    latitude: 66.564796447754,
    longitude: 25.830400466919,
    altitude: 642,
    schengen: true
  },
  {
    id: 2207,
    name: 'Rayskala Airport',
    city: 'Rayskala',
    country: 'Finland',
    iata: '',
    icao: 'EFRY',
    latitude: 60.744701385498,
    longitude: 24.1077995300293,
    altitude: 407,
    schengen: true
  },
  {
    id: 2208,
    name: 'Savonlinna Airport',
    city: 'Savonlinna',
    country: 'Finland',
    iata: 'SVL',
    icao: 'EFSA',
    latitude: 61.943099975586,
    longitude: 28.945100784302,
    altitude: 311,
    schengen: true
  },
  {
    id: 2209,
    name: 'Selanpaa Airport',
    city: 'Selanpaa',
    country: 'Finland',
    iata: '',
    icao: 'EFSE',
    latitude: 61.0624008178711,
    longitude: 26.798900604248,
    altitude: 417,
    schengen: true
  },
  {
    id: 2210,
    name: 'Seinäjoki Airport',
    city: 'Seinäjoki / Ilmajoki',
    country: 'Finland',
    iata: 'SJY',
    icao: 'EFSI',
    latitude: 62.692101,
    longitude: 22.8323,
    altitude: 302,
    schengen: true
  },
  {
    id: 2211,
    name: 'Sodankyla Airport',
    city: 'Sodankyla',
    country: 'Finland',
    iata: 'SOT',
    icao: 'EFSO',
    latitude: 67.3949966431,
    longitude: 26.6191005707,
    altitude: 602,
    schengen: true
  },
  {
    id: 2212,
    name: 'Tampere-Pirkkala Airport',
    city: 'Tampere',
    country: 'Finland',
    iata: 'TMP',
    icao: 'EFTP',
    latitude: 61.414100646973,
    longitude: 23.604400634766,
    altitude: 390,
    schengen: true
  },
  {
    id: 2213,
    name: 'Teisko Airport',
    city: 'Teisko',
    country: 'Finland',
    iata: '',
    icao: 'EFTS',
    latitude: 61.7733,
    longitude: 24.027,
    altitude: 515,
    schengen: true
  },
  {
    id: 2214,
    name: 'Turku Airport',
    city: 'Turku',
    country: 'Finland',
    iata: 'TKU',
    icao: 'EFTU',
    latitude: 60.514099121094,
    longitude: 22.262800216675,
    altitude: 161,
    schengen: true
  },
  {
    id: 2215,
    name: 'Utti Air Base',
    city: 'Utti',
    country: 'Finland',
    iata: 'UTI',
    icao: 'EFUT',
    latitude: 60.89640045166,
    longitude: 26.938400268555,
    altitude: 339,
    schengen: true
  },
  {
    id: 2216,
    name: 'Vaasa Airport',
    city: 'Vaasa',
    country: 'Finland',
    iata: 'VAA',
    icao: 'EFVA',
    latitude: 63.050701141357,
    longitude: 21.762199401855,
    altitude: 19,
    schengen: true
  },
  {
    id: 2217,
    name: 'Varkaus Airport',
    city: 'Varkaus',
    country: 'Finland',
    iata: 'VRK',
    icao: 'EFVR',
    latitude: 62.171100616455,
    longitude: 27.868600845337,
    altitude: 286,
    schengen: true
  },
  {
    id: 2218,
    name: 'Ylivieska Airfield',
    city: 'Ylivieska-raudaskyla',
    country: 'Finland',
    iata: 'YLI',
    icao: 'EFYL',
    latitude: 64.0547222,
    longitude: 24.7252778,
    altitude: 252,
    schengen: true
  },
  {
    id: 2219,
    name: 'Digby (General Hospital) Heliport',
    city: 'Tremblay-en-France',
    country: 'France',
    iata: '',
    icao: 'CDG2',
    latitude: 44.6161003112793,
    longitude: -65.7619018554688,
    altitude: 105,
    schengen: true
  },
  {
    id: 2220,
    name: 'Calais-Dunkerque Airport',
    city: 'Calais',
    country: 'France',
    iata: 'CQF',
    icao: 'LFAC',
    latitude: 50.962100982666,
    longitude: 1.95475995540619,
    altitude: 12,
    schengen: true
  },
  {
    id: 2221,
    name: 'Péronne-Saint-Quentin Airport',
    city: 'Peronne',
    country: 'France',
    iata: '',
    icao: 'LFAG',
    latitude: 49.8685,
    longitude: 3.02958,
    altitude: 295,
    schengen: true
  },
  {
    id: 2222,
    name: 'Nangis-Les Loges Airport',
    city: 'Nangis',
    country: 'France',
    iata: '',
    icao: 'LFAI',
    latitude: 48.5961990356445,
    longitude: 3.00678992271423,
    altitude: 428,
    schengen: true
  },
  {
    id: 2223,
    name: "Bagnoles-de-l'Orne-Couterne Airport",
    city: "Bagnole-de-l'orne",
    country: 'France',
    iata: '',
    icao: 'LFAO',
    latitude: 48.5457992553711,
    longitude: -0.387443989515305,
    altitude: 718,
    schengen: true
  },
  {
    id: 2224,
    name: 'Albert-Bray Airport',
    city: 'Albert',
    country: 'France',
    iata: 'BYF',
    icao: 'LFAQ',
    latitude: 49.9715003967,
    longitude: 2.69765996933,
    altitude: 364,
    schengen: true
  },
  {
    id: 2225,
    name: "Le Touquet-Côte d'Opale Airport",
    city: 'Le Tourquet',
    country: 'France',
    iata: 'LTQ',
    icao: 'LFAT',
    latitude: 50.5173988342285,
    longitude: 1.62058997154236,
    altitude: 36,
    schengen: true
  },
  {
    id: 2226,
    name: 'Valenciennes-Denain Airport',
    city: 'Valenciennes',
    country: 'France',
    iata: 'XVS',
    icao: 'LFAV',
    latitude: 50.3258018493652,
    longitude: 3.46126008033752,
    altitude: 177,
    schengen: true
  },
  {
    id: 2227,
    name: 'Amiens-Glisy Airport',
    city: 'Amiens',
    country: 'France',
    iata: '',
    icao: 'LFAY',
    latitude: 49.873004,
    longitude: 2.387074,
    altitude: 208,
    schengen: true
  },
  {
    id: 2228,
    name: 'Agen-La Garenne Airport',
    city: 'Agen',
    country: 'France',
    iata: 'AGF',
    icao: 'LFBA',
    latitude: 44.1747016906738,
    longitude: 0.590556025505066,
    altitude: 204,
    schengen: true
  },
  {
    id: 2229,
    name: 'Cazaux (BA 120) Air Base',
    city: 'Cazaux',
    country: 'France',
    iata: '',
    icao: 'LFBC',
    latitude: 44.5332984924316,
    longitude: -1.125,
    altitude: 84,
    schengen: true
  },
  {
    id: 2230,
    name: 'Bordeaux-Mérignac Airport',
    city: 'Bordeaux',
    country: 'France',
    iata: 'BOD',
    icao: 'LFBD',
    latitude: 44.8283004761,
    longitude: -0.715556025505,
    altitude: 162,
    schengen: true
  },
  {
    id: 2231,
    name: 'Bergerac-Roumanière Airport',
    city: 'Bergerac',
    country: 'France',
    iata: 'EGC',
    icao: 'LFBE',
    latitude: 44.8252983093262,
    longitude: 0.518611013889313,
    altitude: 171,
    schengen: true
  },
  {
    id: 2232,
    name: 'Toulouse-Francazal (BA 101) Air Base',
    city: 'Toulouse',
    country: 'France',
    iata: '',
    icao: 'LFBF',
    latitude: 43.5456008911133,
    longitude: 1.36749994754791,
    altitude: 535,
    schengen: true
  },
  {
    id: 2233,
    name: 'Cognac-Châteaubernard (BA 709) Air Base',
    city: 'Cognac',
    country: 'France',
    iata: 'CNG',
    icao: 'LFBG',
    latitude: 45.6582984924316,
    longitude: -0.317499995231628,
    altitude: 102,
    schengen: true
  },
  {
    id: 2234,
    name: 'La Rochelle-Île de Ré Airport',
    city: 'La Rochelle',
    country: 'France',
    iata: 'LRH',
    icao: 'LFBH',
    latitude: 46.17919921875,
    longitude: -1.19527995586395,
    altitude: 74,
    schengen: true
  },
  {
    id: 2235,
    name: 'Poitiers-Biard Airport',
    city: 'Poitiers',
    country: 'France',
    iata: 'PIS',
    icao: 'LFBI',
    latitude: 46.5876998901367,
    longitude: 0.306665986776352,
    altitude: 423,
    schengen: true
  },
  {
    id: 2236,
    name: 'Montluçon-Guéret Airport',
    city: 'Montlucon-gueret',
    country: 'France',
    iata: 'MCU',
    icao: 'LFBK',
    latitude: 46.222599029541,
    longitude: 2.3639600276947,
    altitude: 1497,
    schengen: true
  },
  {
    id: 2237,
    name: 'Limoges Airport',
    city: 'Limoges',
    country: 'France',
    iata: 'LIG',
    icao: 'LFBL',
    latitude: 45.8628005981445,
    longitude: 1.17944002151489,
    altitude: 1300,
    schengen: true
  },
  {
    id: 2238,
    name: 'Mont-de-Marsan (BA 118) Air Base',
    city: 'Mont-de-marsan',
    country: 'France',
    iata: '',
    icao: 'LFBM',
    latitude: 43.911701,
    longitude: -0.5075,
    altitude: 203,
    schengen: true
  },
  {
    id: 2239,
    name: 'Niort-Souché Airport',
    city: 'Niort',
    country: 'France',
    iata: 'NIT',
    icao: 'LFBN',
    latitude: 46.313477,
    longitude: -0.394529,
    altitude: 203,
    schengen: true
  },
  {
    id: 2240,
    name: 'Toulouse-Blagnac Airport',
    city: 'Toulouse',
    country: 'France',
    iata: 'TLS',
    icao: 'LFBO',
    latitude: 43.629101,
    longitude: 1.36382,
    altitude: 499,
    schengen: true
  },
  {
    id: 2241,
    name: 'Pau Pyrénées Airport',
    city: 'Pau',
    country: 'France',
    iata: 'PUF',
    icao: 'LFBP',
    latitude: 43.3800010681152,
    longitude: -0.418610990047455,
    altitude: 616,
    schengen: true
  },
  {
    id: 2242,
    name: 'Muret-Lherm Airport',
    city: 'La Rochelle',
    country: 'France',
    iata: '',
    icao: 'LFBR',
    latitude: 43.4488983154297,
    longitude: 1.26332998275757,
    altitude: 622,
    schengen: true
  },
  {
    id: 2243,
    name: 'Tarbes-Lourdes-Pyrénées Airport',
    city: 'Tarbes',
    country: 'France',
    iata: 'LDE',
    icao: 'LFBT',
    latitude: 43.1786994934082,
    longitude: -0.006438999902457,
    altitude: 1260,
    schengen: true
  },
  {
    id: 2244,
    name: 'Angoulême-Brie-Champniers Airport',
    city: 'Angouleme',
    country: 'France',
    iata: 'ANG',
    icao: 'LFBU',
    latitude: 45.7291984558106,
    longitude: 0.221456006169319,
    altitude: 436,
    schengen: true
  },
  {
    id: 2245,
    name: 'Périgueux-Bassillac Airport',
    city: 'Perigueux',
    country: 'France',
    iata: 'PGX',
    icao: 'LFBX',
    latitude: 45.1981010437012,
    longitude: 0.815555989742279,
    altitude: 328,
    schengen: true
  },
  {
    id: 2246,
    name: 'Biarritz-Anglet-Bayonne Airport',
    city: 'Biarritz-bayonne',
    country: 'France',
    iata: 'BIQ',
    icao: 'LFBZ',
    latitude: 43.4683333,
    longitude: -1.5311111,
    altitude: 245,
    schengen: true
  },
  {
    id: 2247,
    name: 'Cahors-Lalbenque Airport',
    city: 'Cahors',
    country: 'France',
    iata: 'ZAO',
    icao: 'LFCC',
    latitude: 44.3513984680176,
    longitude: 1.47528004646301,
    altitude: 912,
    schengen: true
  },
  {
    id: 2248,
    name: 'Andernos Les Bains Airport',
    city: 'Andernos-Les-Bains',
    country: 'France',
    iata: '',
    icao: 'LFCD',
    latitude: 44.7561,
    longitude: -1.06333,
    altitude: 66,
    schengen: true
  },
  {
    id: 2249,
    name: 'Saint-Girons-Antichan Airport',
    city: 'St.-girons',
    country: 'France',
    iata: '',
    icao: 'LFCG',
    latitude: 43.0078010559082,
    longitude: 1.10315001010895,
    altitude: 1368,
    schengen: true
  },
  {
    id: 2250,
    name: 'Arcachon-La Teste-de-Buch Airport',
    city: 'Arcachon',
    country: 'France',
    iata: '',
    icao: 'LFCH',
    latitude: 44.596401,
    longitude: -1.11083,
    altitude: 49,
    schengen: true
  },
  {
    id: 2251,
    name: 'Albi-Le Séquestre Airport',
    city: 'Albi',
    country: 'France',
    iata: 'LBI',
    icao: 'LFCI',
    latitude: 43.9138984680176,
    longitude: 2.11305999755859,
    altitude: 564,
    schengen: true
  },
  {
    id: 2252,
    name: 'Castres-Mazamet Airport',
    city: 'Castres',
    country: 'France',
    iata: 'DCM',
    icao: 'LFCK',
    latitude: 43.5563011169434,
    longitude: 2.2891800403595,
    altitude: 788,
    schengen: true
  },
  {
    id: 2253,
    name: 'Toulouse-Lasbordes Airport',
    city: 'Toulouse',
    country: 'France',
    iata: '',
    icao: 'LFCL',
    latitude: 43.5861015319824,
    longitude: 1.49916994571686,
    altitude: 459,
    schengen: true
  },
  {
    id: 2254,
    name: 'Millau-Larzac Airport',
    city: 'Millau',
    country: 'France',
    iata: '',
    icao: 'LFCM',
    latitude: 43.9892997741699,
    longitude: 3.18300008773804,
    altitude: 2606,
    schengen: true
  },
  {
    id: 2255,
    name: 'Graulhet-Montdragon Airport',
    city: 'Graulhet',
    country: 'France',
    iata: '',
    icao: 'LFCQ',
    latitude: 43.7710990905762,
    longitude: 2.01082992553711,
    altitude: 581,
    schengen: true
  },
  {
    id: 2256,
    name: 'Rodez-Marcillac Airport',
    city: 'Rodez',
    country: 'France',
    iata: 'RDZ',
    icao: 'LFCR',
    latitude: 44.407901763916,
    longitude: 2.48267006874084,
    altitude: 1910,
    schengen: true
  },
  {
    id: 2257,
    name: 'Ussel-Thalamy Airport',
    city: 'Ussel',
    country: 'France',
    iata: '',
    icao: 'LFCU',
    latitude: 45.5346984863281,
    longitude: 2.42389011383057,
    altitude: 2428,
    schengen: true
  },
  {
    id: 2258,
    name: 'Villeneuve-sur-Lot Airport',
    city: 'Villeneuve-sur-lot',
    country: 'France',
    iata: '',
    icao: 'LFCW',
    latitude: 44.396900177002,
    longitude: 0.758889019489288,
    altitude: 190,
    schengen: true
  },
  {
    id: 2259,
    name: 'Royan-Médis Airport',
    city: 'Royan',
    country: 'France',
    iata: 'RYN',
    icao: 'LFCY',
    latitude: 45.628101348877,
    longitude: -0.972500026226044,
    altitude: 72,
    schengen: true
  },
  {
    id: 2260,
    name: 'Mimizan Airport',
    city: 'Mimizan',
    country: 'France',
    iata: '',
    icao: 'LFCZ',
    latitude: 44.145928,
    longitude: -1.16432,
    altitude: 164,
    schengen: true
  },
  {
    id: 2261,
    name: "Aire-sur-l'Adour Airport",
    city: "Aire-sur-l'adour",
    country: 'France',
    iata: '',
    icao: 'LFDA',
    latitude: 43.709400177002,
    longitude: -0.245278000831604,
    altitude: 259,
    schengen: true
  },
  {
    id: 2262,
    name: 'Montauban Airport',
    city: 'Montauban',
    country: 'France',
    iata: 'XMW',
    icao: 'LFDB',
    latitude: 44.0256996154785,
    longitude: 1.37803995609283,
    altitude: 351,
    schengen: true
  },
  {
    id: 2263,
    name: 'Auch-Lamothe Airport',
    city: 'Auch',
    country: 'France',
    iata: '',
    icao: 'LFDH',
    latitude: 43.687801,
    longitude: 0.601667,
    altitude: 411,
    schengen: true
  },
  {
    id: 2264,
    name: 'Libourne-Artigues-de-Lussac Airport',
    city: 'Libourne',
    country: 'France',
    iata: '',
    icao: 'LFDI',
    latitude: 44.982498,
    longitude: -0.134722,
    altitude: 157,
    schengen: true
  },
  {
    id: 2265,
    name: 'Pamiers-Les Pujols Airport',
    city: 'Pamiers',
    country: 'France',
    iata: '',
    icao: 'LFDJ',
    latitude: 43.0905990600586,
    longitude: 1.69582998752594,
    altitude: 1115,
    schengen: true
  },
  {
    id: 2266,
    name: 'Marmande-Virazeil Airport',
    city: 'Marmande',
    country: 'France',
    iata: '',
    icao: 'LFDM',
    latitude: 44.4989013671875,
    longitude: 0.200514003634453,
    altitude: 105,
    schengen: true
  },
  {
    id: 2267,
    name: 'Rochefort-Saint-Agnant (BA 721) Airport',
    city: 'Rochefort',
    country: 'France',
    iata: 'RCO',
    icao: 'LFDN',
    latitude: 45.8877983093262,
    longitude: -0.983056008815765,
    altitude: 60,
    schengen: true
  },
  {
    id: 2268,
    name: "St Pierre d'Oléron Airfield",
    city: "Saint Pierre d'Oleron",
    country: 'France',
    iata: '',
    icao: 'LFDP',
    latitude: 45.959202,
    longitude: -1.31611,
    altitude: 20,
    schengen: true
  },
  {
    id: 2269,
    name: 'Ouessant Airport',
    city: 'Ouessant',
    country: 'France',
    iata: '',
    icao: 'LFEC',
    latitude: 48.4631996154785,
    longitude: -5.06358003616333,
    altitude: 142,
    schengen: true
  },
  {
    id: 2270,
    name: 'Pontivy Airport',
    city: 'Pontivy',
    country: 'France',
    iata: '',
    icao: 'LFED',
    latitude: 48.0584983825684,
    longitude: -2.92182993888855,
    altitude: 407,
    schengen: true
  },
  {
    id: 2271,
    name: 'Aubigny-sur-Nère Airport',
    city: 'Aubigny-sur-nere',
    country: 'France',
    iata: '',
    icao: 'LFEH',
    latitude: 47.480556,
    longitude: 2.394167,
    altitude: 630,
    schengen: true
  },
  {
    id: 2272,
    name: 'Guiscriff Scaer Airport',
    city: 'Guiscriff-scaer',
    country: 'France',
    iata: '',
    icao: 'LFES',
    latitude: 48.0525016784668,
    longitude: -3.66472005844116,
    altitude: 574,
    schengen: true
  },
  {
    id: 2273,
    name: 'Saulieu Liernais Airfield',
    city: 'Saulieu',
    country: 'France',
    iata: '',
    icao: 'LFEW',
    latitude: 47.239399,
    longitude: 4.26583,
    altitude: 1722,
    schengen: true
  },
  {
    id: 2274,
    name: "Île d'Yeu Airport",
    city: "Île d'Yeu",
    country: 'France',
    iata: 'IDY',
    icao: 'LFEY',
    latitude: 46.7186012268066,
    longitude: -2.39110994338989,
    altitude: 79,
    schengen: true
  },
  {
    id: 2275,
    name: 'Enghien Moisselles Airport',
    city: 'Enghien-moisselles',
    country: 'France',
    iata: '',
    icao: 'LFFE',
    latitude: 49.0463981628418,
    longitude: 2.35306000709534,
    altitude: 335,
    schengen: true
  },
  {
    id: 2276,
    name: 'Château-Thierry - Belleau Airport',
    city: 'Chateau-Thierry',
    country: 'France',
    iata: '',
    icao: 'LFFH',
    latitude: 49.0671997070313,
    longitude: 3.35694003105164,
    altitude: 728,
    schengen: true
  },
  {
    id: 2277,
    name: 'Ancenis Airport',
    city: 'Ancenis',
    country: 'France',
    iata: '',
    icao: 'LFFI',
    latitude: 47.4081001281738,
    longitude: -1.17750000953674,
    altitude: 111,
    schengen: true
  },
  {
    id: 2278,
    name: 'Brienne-le-Château Airport',
    city: 'Brienne-le Chateau',
    country: 'France',
    iata: '',
    icao: 'LFFN',
    latitude: 48.4297981262207,
    longitude: 4.48222017288208,
    altitude: 381,
    schengen: true
  },
  {
    id: 2279,
    name: 'La Ferté Alais Airfield',
    city: 'La Ferte Alais',
    country: 'France',
    iata: '',
    icao: 'LFFQ',
    latitude: 48.498652,
    longitude: 2.338867,
    altitude: 453,
    schengen: true
  },
  {
    id: 2280,
    name: 'Colmar-Houssen Airport',
    city: 'Colmar',
    country: 'France',
    iata: 'CMR',
    icao: 'LFGA',
    latitude: 48.1099014282227,
    longitude: 7.35901021957397,
    altitude: 628,
    schengen: true
  },
  {
    id: 2281,
    name: 'Strasbourg Neuhof Airport',
    city: 'Strasbourg Neudorf',
    country: 'France',
    iata: '',
    icao: 'LFGC',
    latitude: 48.5544013977051,
    longitude: 7.77805995941162,
    altitude: 456,
    schengen: true
  },
  {
    id: 2282,
    name: 'Beaune-Challanges Airport',
    city: 'Beaune',
    country: 'France',
    iata: '',
    icao: 'LFGF',
    latitude: 47.005901,
    longitude: 4.89342,
    altitude: 656,
    schengen: true
  },
  {
    id: 2283,
    name: 'Dole-Tavaux Airport',
    city: 'Dole',
    country: 'France',
    iata: 'DLE',
    icao: 'LFGJ',
    latitude: 47.042686,
    longitude: 5.435061,
    altitude: 645,
    schengen: true
  },
  {
    id: 2284,
    name: 'Joigny Airport',
    city: 'Joigny',
    country: 'France',
    iata: '',
    icao: 'LFGK',
    latitude: 47.9921989440918,
    longitude: 3.39222002029419,
    altitude: 732,
    schengen: true
  },
  {
    id: 2285,
    name: 'Pont Sur Yonne Airfield',
    city: 'Pont Sur Yonne',
    country: 'France',
    iata: '',
    icao: 'LFGO',
    latitude: 48.289172,
    longitude: 3.247912,
    altitude: 236,
    schengen: true
  },
  {
    id: 2286,
    name: 'St Florentin Cheu Airfield',
    city: 'St Florentin Cheu',
    country: 'France',
    iata: '',
    icao: 'LFGP',
    latitude: 47.98019,
    longitude: 3.775583,
    altitude: 351,
    schengen: true
  },
  {
    id: 2287,
    name: 'Semur En Auxois Airfield',
    city: 'Semur en Auxois',
    country: 'France',
    iata: '',
    icao: 'LFGQ',
    latitude: 47.481899,
    longitude: 4.34417,
    altitude: 1053,
    schengen: true
  },
  {
    id: 2288,
    name: 'Verdun-Le Rozelier Airfield',
    city: 'Verdun',
    country: 'France',
    iata: '',
    icao: 'LFGW',
    latitude: 49.122398,
    longitude: 5.46905,
    altitude: 1230,
    schengen: true
  },
  {
    id: 2289,
    name: 'Megève Airport',
    city: 'Verdun',
    country: 'France',
    iata: 'MVV',
    icao: 'LFHM',
    latitude: 45.82080078125,
    longitude: 6.65221977233887,
    altitude: 4823,
    schengen: true
  },
  {
    id: 2290,
    name: 'Aubenas-Ardèche Méridional Airport',
    city: 'Aubenas-vals-lanas',
    country: 'France',
    iata: 'OBS',
    icao: 'LFHO',
    latitude: 44.544203,
    longitude: 4.372192,
    altitude: 923,
    schengen: true
  },
  {
    id: 2291,
    name: 'Le Puy-Loudes Airport',
    city: 'Le Puy',
    country: 'France',
    iata: 'LPY',
    icao: 'LFHP',
    latitude: 45.0806999206543,
    longitude: 3.76289010047913,
    altitude: 2731,
    schengen: true
  },
  {
    id: 2292,
    name: 'Saint-Flour-Coltines Airport',
    city: 'St.-flour',
    country: 'France',
    iata: '',
    icao: 'LFHQ',
    latitude: 45.0764007568359,
    longitude: 2.99360990524292,
    altitude: 3218,
    schengen: true
  },
  {
    id: 2293,
    name: 'Bourg-Ceyzériat Airport',
    city: 'Bourg',
    country: 'France',
    iata: '',
    icao: 'LFHS',
    latitude: 46.2009010314941,
    longitude: 5.2920298576355,
    altitude: 857,
    schengen: true
  },
  {
    id: 2294,
    name: 'Villefranche-Tarare Airport',
    city: 'Vilefrance',
    country: 'France',
    iata: '',
    icao: 'LFHV',
    latitude: 45.919983,
    longitude: 4.634931,
    altitude: 1076,
    schengen: true
  },
  {
    id: 2295,
    name: 'Moulins-Montbeugny Airport',
    city: 'Moulins',
    country: 'France',
    iata: '',
    icao: 'LFHY',
    latitude: 46.5345993041992,
    longitude: 3.42371988296509,
    altitude: 915,
    schengen: true
  },
  {
    id: 2296,
    name: 'Saint-Affrique-Belmont Airport',
    city: 'St.-afrique-belmont',
    country: 'France',
    iata: '',
    icao: 'LFIF',
    latitude: 43.823299407959,
    longitude: 2.74528002738953,
    altitude: 1686,
    schengen: true
  },
  {
    id: 2297,
    name: 'Cassagnes-Bégonhès Airport',
    city: 'Cassagnes-beghones',
    country: 'France',
    iata: '',
    icao: 'LFIG',
    latitude: 44.1777992248535,
    longitude: 2.51500010490417,
    altitude: 2024,
    schengen: true
  },
  {
    id: 2298,
    name: 'Metz-Nancy-Lorraine Airport',
    city: 'Metz',
    country: 'France',
    iata: 'ETZ',
    icao: 'LFJL',
    latitude: 48.9821014404,
    longitude: 6.25131988525,
    altitude: 870,
    schengen: true
  },
  {
    id: 2299,
    name: 'Angers-Loire Airport',
    city: 'Angers/Marcé',
    country: 'France',
    iata: 'ANE',
    icao: 'LFJR',
    latitude: 47.560299,
    longitude: -0.312222,
    altitude: 194,
    schengen: true
  },
  {
    id: 2300,
    name: 'Bastia-Poretta Airport',
    city: 'Bastia',
    country: 'France',
    iata: 'BIA',
    icao: 'LFKB',
    latitude: 42.5527000427246,
    longitude: 9.48373031616211,
    altitude: 26,
    schengen: true
  },
  {
    id: 2301,
    name: 'Calvi-Sainte-Catherine Airport',
    city: 'Calvi',
    country: 'France',
    iata: 'CLY',
    icao: 'LFKC',
    latitude: 42.5244444,
    longitude: 8.7930556,
    altitude: 209,
    schengen: true
  },
  {
    id: 2302,
    name: 'Figari Sud-Corse Airport',
    city: 'Figari',
    country: 'France',
    iata: 'FSC',
    icao: 'LFKF',
    latitude: 41.5005989074707,
    longitude: 9.09778022766113,
    altitude: 87,
    schengen: true
  },
  {
    id: 2303,
    name: 'Ajaccio-Napoléon Bonaparte Airport',
    city: 'Ajaccio',
    country: 'France',
    iata: 'AJA',
    icao: 'LFKJ',
    latitude: 41.9235992431641,
    longitude: 8.8029203414917,
    altitude: 18,
    schengen: true
  },
  {
    id: 2304,
    name: 'Propriano Airport',
    city: 'Propriano',
    country: 'France',
    iata: 'PRP',
    icao: 'LFKO',
    latitude: 41.6605987548828,
    longitude: 8.88974952697754,
    altitude: 13,
    schengen: true
  },
  {
    id: 2305,
    name: 'Solenzara (BA 126) Air Base',
    city: 'Solenzara',
    country: 'France',
    iata: 'SOZ',
    icao: 'LFKS',
    latitude: 41.9244003295898,
    longitude: 9.4060001373291,
    altitude: 28,
    schengen: true
  },
  {
    id: 2306,
    name: 'Corte Airport',
    city: 'Corte',
    country: 'France',
    iata: '',
    icao: 'LFKT',
    latitude: 42.2935981750488,
    longitude: 9.19305992126465,
    altitude: 1132,
    schengen: true
  },
  {
    id: 2307,
    name: 'Méribel Altiport',
    city: 'Ajaccio',
    country: 'France',
    iata: 'MFX',
    icao: 'LFKX',
    latitude: 45.407003,
    longitude: 6.577942,
    altitude: 5636,
    schengen: true
  },
  {
    id: 2308,
    name: 'Auxerre-Branches Airport',
    city: 'Auxerre',
    country: 'France',
    iata: 'AUF',
    icao: 'LFLA',
    latitude: 47.8502006530762,
    longitude: 3.49710988998413,
    altitude: 523,
    schengen: true
  },
  {
    id: 2309,
    name: 'Chambéry-Savoie Airport',
    city: 'Chambery',
    country: 'France',
    iata: 'CMF',
    icao: 'LFLB',
    latitude: 45.6380996704102,
    longitude: 5.88022994995117,
    altitude: 779,
    schengen: true
  },
  {
    id: 2310,
    name: 'Clermont-Ferrand Auvergne Airport',
    city: 'Clermont-Ferrand',
    country: 'France',
    iata: 'CFE',
    icao: 'LFLC',
    latitude: 45.7867012024,
    longitude: 3.1691699028,
    altitude: 1090,
    schengen: true
  },
  {
    id: 2311,
    name: 'Bourges Airport',
    city: 'Bourges',
    country: 'France',
    iata: 'BOU',
    icao: 'LFLD',
    latitude: 47.0581016540527,
    longitude: 2.37028002738953,
    altitude: 529,
    schengen: true
  },
  {
    id: 2312,
    name: 'Chambéry-Challes-les-Eaux Airport',
    city: 'Chambery',
    country: 'France',
    iata: '',
    icao: 'LFLE',
    latitude: 45.5611000061,
    longitude: 5.97575998306,
    altitude: 973,
    schengen: true
  },
  {
    id: 2313,
    name: 'Chalon-Champforgeuil Airport',
    city: 'Chalon',
    country: 'France',
    iata: '',
    icao: 'LFLH',
    latitude: 46.826099395752,
    longitude: 4.81762981414795,
    altitude: 623,
    schengen: true
  },
  {
    id: 2314,
    name: 'Annemasse Airport',
    city: 'Annemasse',
    country: 'France',
    iata: 'QNJ',
    icao: 'LFLI',
    latitude: 46.1920013428,
    longitude: 6.26839017868,
    altitude: 1620,
    schengen: true
  },
  {
    id: 2315,
    name: 'Courchevel Airport',
    city: 'Courcheval',
    country: 'France',
    iata: 'CVF',
    icao: 'LFLJ',
    latitude: 45.3967018127441,
    longitude: 6.63471984863281,
    altitude: 6588,
    schengen: true
  },
  {
    id: 2316,
    name: 'Lyon Saint-Exupéry Airport',
    city: 'Lyon',
    country: 'France',
    iata: 'LYS',
    icao: 'LFLL',
    latitude: 45.725556,
    longitude: 5.081111,
    altitude: 821,
    schengen: true
  },
  {
    id: 2317,
    name: 'Mâcon-Charnay Airport',
    city: 'Macon',
    country: 'France',
    iata: '',
    icao: 'LFLM',
    latitude: 46.295101,
    longitude: 4.79577,
    altitude: 728,
    schengen: true
  },
  {
    id: 2318,
    name: 'Saint-Yan Airport',
    city: 'St.-yan',
    country: 'France',
    iata: 'SYT',
    icao: 'LFLN',
    latitude: 46.4124984741211,
    longitude: 4.01325988769531,
    altitude: 796,
    schengen: true
  },
  {
    id: 2319,
    name: 'Roanne-Renaison Airport',
    city: 'Roanne',
    country: 'France',
    iata: 'RNE',
    icao: 'LFLO',
    latitude: 46.0583000183105,
    longitude: 4.00138998031616,
    altitude: 1106,
    schengen: true
  },
  {
    id: 2320,
    name: 'Annecy-Haute-Savoie-Mont Blanc Airport',
    city: 'Annecy',
    country: 'France',
    iata: 'NCY',
    icao: 'LFLP',
    latitude: 45.9308333,
    longitude: 6.1063889,
    altitude: 1521,
    schengen: true
  },
  {
    id: 2321,
    name: 'Grenoble-Isère Airport',
    city: 'Grenoble',
    country: 'France',
    iata: 'GNB',
    icao: 'LFLS',
    latitude: 45.3628997802734,
    longitude: 5.32937002182007,
    altitude: 1302,
    schengen: true
  },
  {
    id: 2322,
    name: 'Montluçon-Domérat Airport',
    city: 'Montlucon',
    country: 'France',
    iata: '',
    icao: 'LFLT',
    latitude: 46.352501,
    longitude: 2.57049,
    altitude: 771,
    schengen: true
  },
  {
    id: 2323,
    name: 'Valence-Chabeuil Airport',
    city: 'Valence',
    country: 'France',
    iata: 'VAF',
    icao: 'LFLU',
    latitude: 44.9216,
    longitude: 4.9699,
    altitude: 525,
    schengen: true
  },
  {
    id: 2324,
    name: 'Vichy-Charmeil Airport',
    city: 'Vichy',
    country: 'France',
    iata: 'VHY',
    icao: 'LFLV',
    latitude: 46.1697006225586,
    longitude: 3.40373992919922,
    altitude: 817,
    schengen: true
  },
  {
    id: 2325,
    name: 'Aurillac Airport',
    city: 'Aurillac',
    country: 'France',
    iata: 'AUR',
    icao: 'LFLW',
    latitude: 44.8913993835449,
    longitude: 2.42194008827209,
    altitude: 2096,
    schengen: true
  },
  {
    id: 2326,
    name: 'Châteauroux-Déols "Marcel Dassault" Airport',
    city: 'Chateauroux',
    country: 'France',
    iata: 'CHR',
    icao: 'LFLX',
    latitude: 46.86027778,
    longitude: 1.7211111,
    altitude: 529,
    schengen: true
  },
  {
    id: 2327,
    name: 'Lyon-Bron Airport',
    city: 'Lyon',
    country: 'France',
    iata: 'LYN',
    icao: 'LFLY',
    latitude: 45.7271995544434,
    longitude: 4.94427013397217,
    altitude: 659,
    schengen: true
  },
  {
    id: 2328,
    name: 'Aix-en-Provence (BA 114) Airport',
    city: 'Aix-les-milles',
    country: 'France',
    iata: '',
    icao: 'LFMA',
    latitude: 43.5056,
    longitude: 5.36778,
    altitude: 367,
    schengen: true
  },
  {
    id: 2329,
    name: 'Le Luc-Le Cannet Airport',
    city: 'Le Luc',
    country: 'France',
    iata: '',
    icao: 'LFMC',
    latitude: 43.3847007751465,
    longitude: 6.38713979721069,
    altitude: 265,
    schengen: true
  },
  {
    id: 2330,
    name: 'Cannes-Mandelieu Airport',
    city: 'Cannes',
    country: 'France',
    iata: 'CEQ',
    icao: 'LFMD',
    latitude: 43.542,
    longitude: 6.95348,
    altitude: 13,
    schengen: true
  },
  {
    id: 2331,
    name: 'Saint-Étienne-Bouthéon Airport',
    city: 'St-Etienne',
    country: 'France',
    iata: 'EBU',
    icao: 'LFMH',
    latitude: 45.5405998229981,
    longitude: 4.29639005661011,
    altitude: 1325,
    schengen: true
  },
  {
    id: 2332,
    name: 'Istres Le Tubé/Istres Air Base (BA 125) Airport',
    city: 'Istres',
    country: 'France',
    iata: '',
    icao: 'LFMI',
    latitude: 43.522701,
    longitude: 4.92384,
    altitude: 82,
    schengen: true
  },
  {
    id: 2333,
    name: 'Carcassonne Airport',
    city: 'Carcassonne',
    country: 'France',
    iata: 'CCF',
    icao: 'LFMK',
    latitude: 43.2159996032715,
    longitude: 2.30631995201111,
    altitude: 433,
    schengen: true
  },
  {
    id: 2334,
    name: 'Marseille Provence Airport',
    city: 'Marseille',
    country: 'France',
    iata: 'MRS',
    icao: 'LFML',
    latitude: 43.439271922,
    longitude: 5.22142410278,
    altitude: 74,
    schengen: true
  },
  {
    id: 2335,
    name: "Nice-Côte d'Azur Airport",
    city: 'Nice',
    country: 'France',
    iata: 'NCE',
    icao: 'LFMN',
    latitude: 43.6584014893,
    longitude: 7.21586990356,
    altitude: 12,
    schengen: true
  },
  {
    id: 2336,
    name: 'Orange-Caritat (BA 115) Air Base',
    city: 'Orange',
    country: 'France',
    iata: 'XOG',
    icao: 'LFMO',
    latitude: 44.1404991149902,
    longitude: 4.86672019958496,
    altitude: 197,
    schengen: true
  },
  {
    id: 2337,
    name: 'Perpignan-Rivesaltes (Llabanère) Airport',
    city: 'Perpignan',
    country: 'France',
    iata: 'PGF',
    icao: 'LFMP',
    latitude: 42.7403984069824,
    longitude: 2.87067008018494,
    altitude: 144,
    schengen: true
  },
  {
    id: 2338,
    name: 'Le Castellet Airport',
    city: 'Le Castellet',
    country: 'France',
    iata: 'CTT',
    icao: 'LFMQ',
    latitude: 43.252498626709,
    longitude: 5.78519010543823,
    altitude: 1391,
    schengen: true
  },
  {
    id: 2339,
    name: 'Alès-Deaux Airport',
    city: 'Ales',
    country: 'France',
    iata: '',
    icao: 'LFMS',
    latitude: 44.069698,
    longitude: 4.14212,
    altitude: 668,
    schengen: true
  },
  {
    id: 2340,
    name: 'Montpellier-Méditerranée Airport',
    city: 'Montpellier',
    country: 'France',
    iata: 'MPL',
    icao: 'LFMT',
    latitude: 43.5761985778809,
    longitude: 3.96301007270813,
    altitude: 17,
    schengen: true
  },
  {
    id: 2341,
    name: 'Béziers-Vias Airport',
    city: 'Beziers',
    country: 'France',
    iata: 'BZR',
    icao: 'LFMU',
    latitude: 43.3235015869141,
    longitude: 3.35389995574951,
    altitude: 56,
    schengen: true
  },
  {
    id: 2342,
    name: 'Avignon-Caumont Airport',
    city: 'Avignon',
    country: 'France',
    iata: 'AVN',
    icao: 'LFMV',
    latitude: 43.9072990417481,
    longitude: 4.90183019638062,
    altitude: 124,
    schengen: true
  },
  {
    id: 2343,
    name: 'Salon-de-Provence (BA 701) Air Base',
    city: 'Salon',
    country: 'France',
    iata: '',
    icao: 'LFMY',
    latitude: 43.6063995361328,
    longitude: 5.10925006866455,
    altitude: 195,
    schengen: true
  },
  {
    id: 2344,
    name: 'Lézignan-Corbières Airport',
    city: 'Lezignan-corbieres',
    country: 'France',
    iata: '',
    icao: 'LFMZ',
    latitude: 43.1758003234863,
    longitude: 2.73416996002197,
    altitude: 207,
    schengen: true
  },
  {
    id: 2345,
    name: 'Mende-Brenoux Airport',
    city: 'Mende',
    country: 'France',
    iata: 'MEN',
    icao: 'LFNB',
    latitude: 44.5021018981934,
    longitude: 3.53281998634338,
    altitude: 3362,
    schengen: true
  },
  {
    id: 2346,
    name: 'Carpentras Airport',
    city: 'Carpentras',
    country: 'France',
    iata: '',
    icao: 'LFNH',
    latitude: 44.0298004150391,
    longitude: 5.07806015014648,
    altitude: 394,
    schengen: true
  },
  {
    id: 2347,
    name: 'Avord (BA 702) Air Base',
    city: 'Avord',
    country: 'France',
    iata: '',
    icao: 'LFOA',
    latitude: 47.053299,
    longitude: 2.6325,
    altitude: 580,
    schengen: true
  },
  {
    id: 2348,
    name: 'Paris Beauvais Tillé Airport',
    city: 'Beauvais',
    country: 'France',
    iata: 'BVA',
    icao: 'LFOB',
    latitude: 49.4543991088867,
    longitude: 2.11278009414673,
    altitude: 359,
    schengen: true
  },
  {
    id: 2349,
    name: 'Châteaudun (BA 279) Air Base',
    city: 'Chateaudun',
    country: 'France',
    iata: '',
    icao: 'LFOC',
    latitude: 48.058102,
    longitude: 1.37662,
    altitude: 433,
    schengen: true
  },
  {
    id: 2350,
    name: 'Saumur-Saint-Florent Airport',
    city: 'Saumur',
    country: 'France',
    iata: '',
    icao: 'LFOD',
    latitude: 47.256802,
    longitude: -0.115142,
    altitude: 269,
    schengen: true
  },
  {
    id: 2351,
    name: 'Évreux-Fauville (BA 105) Air Base',
    city: 'Evreux',
    country: 'France',
    iata: 'EVX',
    icao: 'LFOE',
    latitude: 49.0287017822266,
    longitude: 1.21985995769501,
    altitude: 464,
    schengen: true
  },
  {
    id: 2352,
    name: 'Le Havre Octeville Airport',
    city: 'Le Havre',
    country: 'France',
    iata: 'LEH',
    icao: 'LFOH',
    latitude: 49.5339012145996,
    longitude: 0.088055998086929,
    altitude: 313,
    schengen: true
  },
  {
    id: 2353,
    name: 'Abbeville',
    city: 'Abbeville',
    country: 'France',
    iata: 'XAB',
    icao: 'LFOI',
    latitude: 50.143501,
    longitude: 1.831891,
    altitude: 220,
    schengen: true
  },
  {
    id: 2354,
    name: 'Orléans-Bricy (BA 123) Air Base',
    city: 'Orleans',
    country: 'France',
    iata: 'ORE',
    icao: 'LFOJ',
    latitude: 47.9878005981,
    longitude: 1.76056003571,
    altitude: 412,
    schengen: true
  },
  {
    id: 2355,
    name: 'Châlons-Vatry Airport',
    city: 'Chalons',
    country: 'France',
    iata: 'XCR',
    icao: 'LFOK',
    latitude: 48.7733333333,
    longitude: 4.206111111,
    altitude: 587,
    schengen: true
  },
  {
    id: 2356,
    name: 'Rouen Airport',
    city: 'Rouen',
    country: 'France',
    iata: 'URO',
    icao: 'LFOP',
    latitude: 49.3842010498047,
    longitude: 1.17480003833771,
    altitude: 512,
    schengen: true
  },
  {
    id: 2357,
    name: 'Blois-Le Breuil Airport',
    city: 'Blois/Le Breuil',
    country: 'France',
    iata: '',
    icao: 'LFOQ',
    latitude: 47.678502,
    longitude: 1.20884,
    altitude: 398,
    schengen: true
  },
  {
    id: 2358,
    name: 'Tours-Val-de-Loire Airport',
    city: 'Tours',
    country: 'France',
    iata: 'TUF',
    icao: 'LFOT',
    latitude: 47.4322013855,
    longitude: 0.727605998516,
    altitude: 357,
    schengen: true
  },
  {
    id: 2359,
    name: 'Cholet Le Pontreau Airport',
    city: 'Cholet',
    country: 'France',
    iata: 'CET',
    icao: 'LFOU',
    latitude: 47.0820999145508,
    longitude: -0.877063989639282,
    altitude: 443,
    schengen: true
  },
  {
    id: 2360,
    name: 'Laval-Entrammes Airport',
    city: 'Laval',
    country: 'France',
    iata: 'LVA',
    icao: 'LFOV',
    latitude: 48.0313987731934,
    longitude: -0.742986023426056,
    altitude: 330,
    schengen: true
  },
  {
    id: 2361,
    name: "Orléans-Saint-Denis-de-l'Hôtel Airport",
    city: 'Orleans',
    country: 'France',
    iata: '',
    icao: 'LFOZ',
    latitude: 47.8969,
    longitude: 2.16333,
    altitude: 396,
    schengen: true
  },
  {
    id: 2362,
    name: 'Paris-Le Bourget Airport',
    city: 'Paris',
    country: 'France',
    iata: 'LBG',
    icao: 'LFPB',
    latitude: 48.9693984985352,
    longitude: 2.44139003753662,
    altitude: 218,
    schengen: true
  },
  {
    id: 2363,
    name: 'Creil Air Base',
    city: 'Creil',
    country: 'France',
    iata: 'CSF',
    icao: 'LFPC',
    latitude: 49.2535018920898,
    longitude: 2.51914000511169,
    altitude: 291,
    schengen: true
  },
  {
    id: 2364,
    name: 'Meaux Esbly Airport',
    city: 'Meaux',
    country: 'France',
    iata: '',
    icao: 'LFPE',
    latitude: 48.9277992248535,
    longitude: 2.83527994155884,
    altitude: 217,
    schengen: true
  },
  {
    id: 2365,
    name: 'Charles de Gaulle International Airport',
    city: 'Paris',
    country: 'France',
    iata: 'CDG',
    icao: 'LFPG',
    latitude: 49.012798,
    longitude: 2.55,
    altitude: 392,
    schengen: true
  },
  {
    id: 2366,
    name: 'Coulommiers-Voisins Airport',
    city: 'Coulommiers',
    country: 'France',
    iata: '',
    icao: 'LFPK',
    latitude: 48.8376998901367,
    longitude: 3.01611995697021,
    altitude: 470,
    schengen: true
  },
  {
    id: 2367,
    name: 'Lognes Emerainville Airport',
    city: 'Lognes',
    country: 'France',
    iata: '',
    icao: 'LFPL',
    latitude: 48.821028,
    longitude: 2.625967,
    altitude: 359,
    schengen: true
  },
  {
    id: 2368,
    name: 'Melun-Villaroche Air Base',
    city: 'Melun',
    country: 'France',
    iata: '',
    icao: 'LFPM',
    latitude: 48.6046981811523,
    longitude: 2.67111992835999,
    altitude: 302,
    schengen: true
  },
  {
    id: 2369,
    name: 'Toussus-le-Noble Airport',
    city: 'Toussous-le-noble',
    country: 'France',
    iata: 'TNF',
    icao: 'LFPN',
    latitude: 48.7518997192383,
    longitude: 2.10618996620178,
    altitude: 538,
    schengen: true
  },
  {
    id: 2370,
    name: 'Paris-Orly Airport',
    city: 'Paris',
    country: 'France',
    iata: 'ORY',
    icao: 'LFPO',
    latitude: 48.7233333,
    longitude: 2.3794444,
    altitude: 291,
    schengen: true
  },
  {
    id: 2371,
    name: 'Pontoise - Cormeilles-en-Vexin Airport',
    city: 'Pontoise',
    country: 'France',
    iata: 'POX',
    icao: 'LFPT',
    latitude: 49.096667,
    longitude: 2.040833,
    altitude: 325,
    schengen: true
  },
  {
    id: 2372,
    name: 'Villacoublay-Vélizy (BA 107) Air Base',
    city: 'Villacoublay',
    country: 'France',
    iata: 'VIY',
    icao: 'LFPV',
    latitude: 48.7741667,
    longitude: 2.1916667,
    altitude: 584,
    schengen: true
  },
  {
    id: 2373,
    name: "Saint-Cyr-l'École Airport",
    city: 'Saint-Cyr',
    country: 'France',
    iata: '',
    icao: 'LFPZ',
    latitude: 48.8114013671875,
    longitude: 2.07471990585327,
    altitude: 371,
    schengen: true
  },
  {
    id: 2374,
    name: 'Reims-Prunay Airport',
    city: 'Reims',
    country: 'France',
    iata: '',
    icao: 'LFQA',
    latitude: 49.2086982727051,
    longitude: 4.15657997131348,
    altitude: 313,
    schengen: true
  },
  {
    id: 2375,
    name: 'Troyes-Barberey Airport',
    city: 'Troyes',
    country: 'France',
    iata: '',
    icao: 'LFQB',
    latitude: 48.322102,
    longitude: 4.0167,
    altitude: 388,
    schengen: true
  },
  {
    id: 2376,
    name: 'Lunéville-Croismare Airport',
    city: 'Luneville',
    country: 'France',
    iata: '',
    icao: 'LFQC',
    latitude: 48.5932998657227,
    longitude: 6.54345989227295,
    altitude: 790,
    schengen: true
  },
  {
    id: 2377,
    name: 'Étain-Rouvres Air Base',
    city: 'Etain',
    country: 'France',
    iata: '',
    icao: 'LFQE',
    latitude: 49.2268981933594,
    longitude: 5.67222023010254,
    altitude: 770,
    schengen: true
  },
  {
    id: 2378,
    name: 'Autun-Bellevue Airport',
    city: 'Autun',
    country: 'France',
    iata: '',
    icao: 'LFQF',
    latitude: 46.9673004150391,
    longitude: 4.26057004928589,
    altitude: 997,
    schengen: true
  },
  {
    id: 2379,
    name: 'Nevers-Fourchambault Airport',
    city: 'Nevers',
    country: 'France',
    iata: 'NVS',
    icao: 'LFQG',
    latitude: 47.0026016235352,
    longitude: 3.11332988739014,
    altitude: 602,
    schengen: true
  },
  {
    id: 2380,
    name: 'Cambrai-Épinoy (BA 103) Air Base',
    city: 'Cambrai',
    country: 'France',
    iata: '',
    icao: 'LFQI',
    latitude: 50.221802,
    longitude: 3.15424,
    altitude: 257,
    schengen: true
  },
  {
    id: 2381,
    name: 'Maubeuge-Élesmes Airport',
    city: 'Maubeuge',
    country: 'France',
    iata: 'XME',
    icao: 'LFQJ',
    latitude: 50.3105010986328,
    longitude: 4.03312015533447,
    altitude: 452,
    schengen: true
  },
  {
    id: 2382,
    name: 'Besançon-La Vèze Airport',
    city: 'Besancon-la-veze',
    country: 'France',
    iata: '',
    icao: 'LFQM',
    latitude: 47.2066,
    longitude: 6.083681,
    altitude: 1271,
    schengen: true
  },
  {
    id: 2383,
    name: 'Lille/Marcq-en-Baroeul Airport',
    city: 'Marcq En Baroeul',
    country: 'France',
    iata: '',
    icao: 'LFQO',
    latitude: 50.687198638916,
    longitude: 3.07556009292603,
    altitude: 69,
    schengen: true
  },
  {
    id: 2384,
    name: 'Phalsbourg-Bourscheid Air Base',
    city: 'Phalsbourg',
    country: 'France',
    iata: '',
    icao: 'LFQP',
    latitude: 48.7680556,
    longitude: 7.205,
    altitude: 1017,
    schengen: true
  },
  {
    id: 2385,
    name: 'Lille-Lesquin Airport',
    city: 'Lille',
    country: 'France',
    iata: 'LIL',
    icao: 'LFQQ',
    latitude: 50.563332,
    longitude: 3.086886,
    altitude: 157,
    schengen: true
  },
  {
    id: 2386,
    name: 'Merville-Calonne Airport',
    city: 'Merville',
    country: 'France',
    iata: 'HZB',
    icao: 'LFQT',
    latitude: 50.6184005737305,
    longitude: 2.64224004745483,
    altitude: 61,
    schengen: true
  },
  {
    id: 2387,
    name: 'Charleville-Mézières Airport',
    city: 'Charleville',
    country: 'France',
    iata: 'XCZ',
    icao: 'LFQV',
    latitude: 49.7839012145996,
    longitude: 4.6470799446106,
    altitude: 492,
    schengen: true
  },
  {
    id: 2388,
    name: 'Vesoul-Frotey Airport',
    city: 'Vesoul-frotey',
    country: 'France',
    iata: '',
    icao: 'LFQW',
    latitude: 47.6376,
    longitude: 6.20392,
    altitude: 1249,
    schengen: true
  },
  {
    id: 2389,
    name: 'Brest Bretagne Airport',
    city: 'Brest',
    country: 'France',
    iata: 'BES',
    icao: 'LFRB',
    latitude: 48.4478988647461,
    longitude: -4.41854000091553,
    altitude: 325,
    schengen: true
  },
  {
    id: 2390,
    name: 'Cherbourg-Maupertus Airport',
    city: 'Cherbourg',
    country: 'France',
    iata: 'CER',
    icao: 'LFRC',
    latitude: 49.6501007080078,
    longitude: -1.47028005123138,
    altitude: 459,
    schengen: true
  },
  {
    id: 2391,
    name: 'Dinard-Pleurtuit-Saint-Malo Airport',
    city: 'Dinard',
    country: 'France',
    iata: 'DNR',
    icao: 'LFRD',
    latitude: 48.5876998901367,
    longitude: -2.07996010780334,
    altitude: 219,
    schengen: true
  },
  {
    id: 2392,
    name: 'La Baule-Escoublac Airport',
    city: 'La Baule',
    country: 'France',
    iata: 'LBY',
    icao: 'LFRE',
    latitude: 47.2893981933594,
    longitude: -2.34639000892639,
    altitude: 105,
    schengen: true
  },
  {
    id: 2393,
    name: 'Granville Airport',
    city: 'Granville',
    country: 'France',
    iata: 'GFR',
    icao: 'LFRF',
    latitude: 48.8830986022949,
    longitude: -1.56417000293732,
    altitude: 45,
    schengen: true
  },
  {
    id: 2394,
    name: 'Deauville-Saint-Gatien Airport',
    city: 'Deauville',
    country: 'France',
    iata: 'DOL',
    icao: 'LFRG',
    latitude: 49.3652992249,
    longitude: 0.154305994511,
    altitude: 479,
    schengen: true
  },
  {
    id: 2395,
    name: 'Lorient South Brittany (Bretagne Sud) Airport',
    city: 'Lorient',
    country: 'France',
    iata: 'LRT',
    icao: 'LFRH',
    latitude: 47.7606010437012,
    longitude: -3.44000005722046,
    altitude: 160,
    schengen: true
  },
  {
    id: 2396,
    name: 'La Roche-sur-Yon Airport',
    city: 'La Roche-sur-yon',
    country: 'France',
    iata: 'EDM',
    icao: 'LFRI',
    latitude: 46.7019004821777,
    longitude: -1.37863004207611,
    altitude: 299,
    schengen: true
  },
  {
    id: 2397,
    name: 'Landivisiau Air Base',
    city: 'Landivisiau',
    country: 'France',
    iata: 'LDV',
    icao: 'LFRJ',
    latitude: 48.5303001403809,
    longitude: -4.15163993835449,
    altitude: 348,
    schengen: true
  },
  {
    id: 2398,
    name: 'Caen-Carpiquet Airport',
    city: 'Caen',
    country: 'France',
    iata: 'CFR',
    icao: 'LFRK',
    latitude: 49.1733016967774,
    longitude: -0.449999988079071,
    altitude: 256,
    schengen: true
  },
  {
    id: 2399,
    name: 'Lanvéoc-Poulmic Air Base',
    city: 'Lanvedoc',
    country: 'France',
    iata: '',
    icao: 'LFRL',
    latitude: 48.2817001342773,
    longitude: -4.44502019882202,
    altitude: 287,
    schengen: true
  },
  {
    id: 2400,
    name: 'Le Mans-Arnage Airport',
    city: 'Le Mans',
    country: 'France',
    iata: 'LME',
    icao: 'LFRM',
    latitude: 47.948600769043,
    longitude: 0.201666995882988,
    altitude: 194,
    schengen: true
  },
  {
    id: 2401,
    name: 'Rennes-Saint-Jacques Airport',
    city: 'Rennes',
    country: 'France',
    iata: 'RNS',
    icao: 'LFRN',
    latitude: 48.0694999695,
    longitude: -1.73478996754,
    altitude: 124,
    schengen: true
  },
  {
    id: 2402,
    name: 'Lannion-Côte de Granit Airport',
    city: 'Lannion',
    country: 'France',
    iata: 'LAI',
    icao: 'LFRO',
    latitude: 48.754398,
    longitude: -3.47166,
    altitude: 290,
    schengen: true
  },
  {
    id: 2403,
    name: 'Quimper-Cornouaille Airport',
    city: 'Quimper',
    country: 'France',
    iata: 'UIP',
    icao: 'LFRQ',
    latitude: 47.9749984741211,
    longitude: -4.16778993606567,
    altitude: 297,
    schengen: true
  },
  {
    id: 2404,
    name: 'Nantes Atlantique Airport',
    city: 'Nantes',
    country: 'France',
    iata: 'NTE',
    icao: 'LFRS',
    latitude: 47.1531982422,
    longitude: -1.61073005199,
    altitude: 90,
    schengen: true
  },
  {
    id: 2405,
    name: 'Saint-Brieuc-Armor Airport',
    city: 'St.-brieuc Armor',
    country: 'France',
    iata: 'SBK',
    icao: 'LFRT',
    latitude: 48.5378,
    longitude: -2.85444,
    altitude: 453,
    schengen: true
  },
  {
    id: 2406,
    name: 'Morlaix-Ploujean Airport',
    city: 'Morlaix',
    country: 'France',
    iata: 'MXN',
    icao: 'LFRU',
    latitude: 48.6031990051,
    longitude: -3.81577992439,
    altitude: 272,
    schengen: true
  },
  {
    id: 2407,
    name: 'Vannes-Meucon Airport',
    city: 'Vannes',
    country: 'France',
    iata: 'VNE',
    icao: 'LFRV',
    latitude: 47.7233009338379,
    longitude: -2.71855998039246,
    altitude: 446,
    schengen: true
  },
  {
    id: 2408,
    name: 'Saint-Nazaire-Montoir Airport',
    city: 'St.-nazaire',
    country: 'France',
    iata: 'SNR',
    icao: 'LFRZ',
    latitude: 47.3105556,
    longitude: -2.1566667,
    altitude: 13,
    schengen: true
  },
  {
    id: 2409,
    name: 'EuroAirport Basel-Mulhouse-Freiburg Airport',
    city: 'Mulhouse',
    country: 'France',
    iata: 'BSL',
    icao: 'LFSB',
    latitude: 47.59,
    longitude: 7.5291667,
    altitude: 885,
    schengen: true
  },
  {
    id: 2410,
    name: 'Colmar-Meyenheim Air Base',
    city: 'Colmar',
    country: 'France',
    iata: '',
    icao: 'LFSC',
    latitude: 47.922000885,
    longitude: 7.39967012405,
    altitude: 693,
    schengen: true
  },
  {
    id: 2411,
    name: 'Dijon-Bourgogne Airport',
    city: 'Dijon',
    country: 'France',
    iata: 'DIJ',
    icao: 'LFSD',
    latitude: 47.268902,
    longitude: 5.09,
    altitude: 726,
    schengen: true
  },
  {
    id: 2412,
    name: 'Metz-Frescaty (BA 128) Air Base',
    city: 'Metz',
    country: 'France',
    iata: 'MZM',
    icao: 'LFSF',
    latitude: 49.0717010498047,
    longitude: 6.13166999816895,
    altitude: 629,
    schengen: true
  },
  {
    id: 2413,
    name: 'Épinal-Mirecourt Airport',
    city: 'Epinal',
    country: 'France',
    iata: 'EPL',
    icao: 'LFSG',
    latitude: 48.325001,
    longitude: 6.06998,
    altitude: 1084,
    schengen: true
  },
  {
    id: 2414,
    name: 'Haguenau Airport',
    city: 'Haguenau',
    country: 'France',
    iata: '',
    icao: 'LFSH',
    latitude: 48.7943000793,
    longitude: 7.81760978699,
    altitude: 491,
    schengen: true
  },
  {
    id: 2415,
    name: 'Saint-Dizier-Robinson (BA 113) Air Base',
    city: 'St.-dizier',
    country: 'France',
    iata: '',
    icao: 'LFSI',
    latitude: 48.6360015869141,
    longitude: 4.8994197845459,
    altitude: 458,
    schengen: true
  },
  {
    id: 2416,
    name: 'Toul Rosières Air Base',
    city: 'Brive',
    country: 'France',
    iata: 'BVE',
    icao: 'LFSL',
    latitude: 48.780001,
    longitude: 5.980003,
    altitude: 936,
    schengen: true
  },
  {
    id: 2417,
    name: 'Montbéliard-Courcelles Airfield',
    city: 'Montbeliard',
    country: 'France',
    iata: '',
    icao: 'LFSM',
    latitude: 47.487,
    longitude: 6.79054,
    altitude: 1041,
    schengen: true
  },
  {
    id: 2418,
    name: 'Nancy-Essey Airport',
    city: 'Nancy',
    country: 'France',
    iata: 'ENC',
    icao: 'LFSN',
    latitude: 48.6921005249023,
    longitude: 6.23046016693115,
    altitude: 751,
    schengen: true
  },
  {
    id: 2419,
    name: 'Nancy-Ochey (BA 133) Air Base',
    city: 'Nancy',
    country: 'France',
    iata: '',
    icao: 'LFSO',
    latitude: 48.5830993652344,
    longitude: 5.95499992370606,
    altitude: 1106,
    schengen: true
  },
  {
    id: 2420,
    name: 'Pontarlier Airport',
    city: 'Pontarlier',
    country: 'France',
    iata: '',
    icao: 'LFSP',
    latitude: 46.9039993286133,
    longitude: 6.32737016677856,
    altitude: 2683,
    schengen: true
  },
  {
    id: 2421,
    name: 'Fontaine Airport',
    city: 'Belfort',
    country: 'France',
    iata: 'BOR',
    icao: 'LFSQ',
    latitude: 47.655601501465,
    longitude: 7.0108299255371,
    altitude: 1208,
    schengen: true
  },
  {
    id: 2422,
    name: 'Reims-Champagne (BA 112) Air Base',
    city: 'Reims',
    country: 'France',
    iata: 'RHE',
    icao: 'LFSR',
    latitude: 49.310001,
    longitude: 4.05,
    altitude: 312,
    schengen: true
  },
  {
    id: 2423,
    name: 'Strasbourg Airport',
    city: 'Strasbourg',
    country: 'France',
    iata: 'SXB',
    icao: 'LFST',
    latitude: 48.5382995605469,
    longitude: 7.62823009490967,
    altitude: 505,
    schengen: true
  },
  {
    id: 2424,
    name: 'Luxeuil-Saint-Sauveur (BA 116) Air Base',
    city: 'Luxeuil',
    country: 'France',
    iata: '',
    icao: 'LFSX',
    latitude: 47.7830556,
    longitude: 6.36416667,
    altitude: 913,
    schengen: true
  },
  {
    id: 2425,
    name: 'Cuers-Pierrefeu Airport',
    city: 'Cuers',
    country: 'France',
    iata: '',
    icao: 'LFTF',
    latitude: 43.2477989196777,
    longitude: 6.12669992446899,
    altitude: 266,
    schengen: true
  },
  {
    id: 2426,
    name: 'Toulon-Hyères Airport',
    city: 'Hyeres',
    country: 'France',
    iata: 'TLN',
    icao: 'LFTH',
    latitude: 43.0973014832,
    longitude: 6.14602994919,
    altitude: 7,
    schengen: true
  },
  {
    id: 2427,
    name: "La Grand'combe Airport",
    city: "La Grand'Combe",
    country: 'France',
    iata: '',
    icao: 'LFTN',
    latitude: 44.2444000244141,
    longitude: 4.01221990585327,
    altitude: 1647,
    schengen: true
  },
  {
    id: 2428,
    name: 'Fréjus Airport',
    city: 'Frejus',
    country: 'France',
    iata: 'FRJ',
    icao: 'LFTU',
    latitude: 43.4175,
    longitude: 6.7357,
    altitude: 33,
    schengen: true
  },
  {
    id: 2429,
    name: 'Nîmes-Arles-Camargue Airport',
    city: 'Nimes',
    country: 'France',
    iata: 'FNI',
    icao: 'LFTW',
    latitude: 43.7574005126953,
    longitude: 4.4163498878479,
    altitude: 309,
    schengen: true
  },
  {
    id: 2430,
    name: 'La Môle Airport',
    city: 'La Môle',
    country: 'France',
    iata: 'LTT',
    icao: 'LFTZ',
    latitude: 43.205399,
    longitude: 6.482,
    altitude: 59,
    schengen: true
  },
  {
    id: 2431,
    name: 'Ambérieu Air Base (BA 278)',
    city: 'Amberieu',
    country: 'France',
    iata: '',
    icao: 'LFXA',
    latitude: 45.987301,
    longitude: 5.32844,
    altitude: 823,
    schengen: true
  },
  {
    id: 2432,
    name: 'Damblain Airport',
    city: 'Damblain',
    country: 'France',
    iata: '',
    icao: 'LFYD',
    latitude: 48.0862998962402,
    longitude: 5.66406011581421,
    altitude: 1280,
    schengen: true
  },
  {
    id: 2433,
    name: 'Lure Malbouhans Air Base',
    city: 'Malbouhans',
    country: 'France',
    iata: '',
    icao: 'LFYL',
    latitude: 47.7047,
    longitude: 6.54583,
    altitude: 1040,
    schengen: true
  },
  {
    id: 2434,
    name: 'Guatuso Airport',
    city: 'Marigot',
    country: 'France',
    iata: '',
    icao: 'MRGT',
    latitude: 10.6833333969116,
    longitude: -84.8333358764648,
    altitude: 164,
    schengen: true
  },
  {
    id: 2435,
    name: 'Gustaf III Airport',
    city: 'Gustavia',
    country: 'France',
    iata: 'SBH',
    icao: 'TFFJ',
    latitude: 17.9043998718262,
    longitude: -62.8436012268066,
    altitude: 49,
    schengen: true
  },
  {
    id: 2436,
    name: 'Cayenne-Rochambeau Airport',
    city: 'Cayenne',
    country: 'French Guiana',
    iata: 'CAY',
    icao: 'SOCA',
    latitude: 4.81980991364,
    longitude: -52.3604011536,
    altitude: 26,
    schengen: false
  },
  {
    id: 2437,
    name: 'Grand-Santi Airport',
    city: 'Grand-Santi',
    country: 'French Guiana',
    iata: 'GSI',
    icao: 'SOGS',
    latitude: 4.285833,
    longitude: -54.373056,
    altitude: 207,
    schengen: false
  },
  {
    id: 2438,
    name: 'Maripasoula Airport',
    city: 'Maripasoula',
    country: 'French Guiana',
    iata: 'MPY',
    icao: 'SOOA',
    latitude: 3.6575,
    longitude: -54.037201,
    altitude: 406,
    schengen: false
  },
  {
    id: 2439,
    name: "Saint-Georges-de-l'Oyapock Airport",
    city: 'St.-georges Oyapock',
    country: 'French Guiana',
    iata: 'OYP',
    icao: 'SOOG',
    latitude: 3.89759993553,
    longitude: -51.8041000366,
    altitude: 46,
    schengen: false
  },
  {
    id: 2440,
    name: 'Saint-Laurent-du-Maroni Airport',
    city: 'Saint-Laurent-du-Maroni',
    country: 'French Guiana',
    iata: 'LDX',
    icao: 'SOOM',
    latitude: 5.48306,
    longitude: -54.034401,
    altitude: 16,
    schengen: false
  },
  {
    id: 2441,
    name: 'Saúl Airport',
    city: 'Saul',
    country: 'French Guiana',
    iata: 'XAU',
    icao: 'SOOS',
    latitude: 3.61361,
    longitude: -53.204201,
    altitude: 656,
    schengen: false
  },
  {
    id: 2442,
    name: "Faa'a International Airport",
    city: 'Papeete',
    country: 'French Polynesia',
    iata: 'PPT',
    icao: 'NTAA',
    latitude: -17.5536994934,
    longitude: -149.606994629,
    altitude: 5,
    schengen: false
  },
  {
    id: 2443,
    name: 'Rimatara Airport',
    city: 'Rimatara',
    country: 'French Polynesia',
    iata: 'RMT',
    icao: 'NTAM',
    latitude: -22.63725,
    longitude: -152.8059,
    altitude: 60,
    schengen: false
  },
  {
    id: 2444,
    name: 'Rurutu Airport',
    city: 'Rurutu',
    country: 'French Polynesia',
    iata: 'RUR',
    icao: 'NTAR',
    latitude: -22.4340991973877,
    longitude: -151.360992431641,
    altitude: 18,
    schengen: false
  },
  {
    id: 2445,
    name: 'Tubuai Airport',
    city: 'Tubuai',
    country: 'French Polynesia',
    iata: 'TUB',
    icao: 'NTAT',
    latitude: -23.3654003143311,
    longitude: -149.524002075195,
    altitude: 7,
    schengen: false
  },
  {
    id: 2446,
    name: 'Raivavae Airport',
    city: 'Raivavae',
    country: 'French Polynesia',
    iata: 'RVV',
    icao: 'NTAV',
    latitude: -23.8852005005,
    longitude: -147.662002563,
    altitude: 7,
    schengen: false
  },
  {
    id: 2447,
    name: 'Anaa Airport',
    city: 'Anaa',
    country: 'French Polynesia',
    iata: 'AAA',
    icao: 'NTGA',
    latitude: -17.3526000976563,
    longitude: -145.509994506836,
    altitude: 10,
    schengen: false
  },
  {
    id: 2448,
    name: 'Fangatau Airport',
    city: 'Fangatau',
    country: 'French Polynesia',
    iata: 'FGU',
    icao: 'NTGB',
    latitude: -15.819899559021,
    longitude: -140.886993408203,
    altitude: 9,
    schengen: false
  },
  {
    id: 2449,
    name: 'Tikehau Airport',
    city: 'Tikehau',
    country: 'French Polynesia',
    iata: 'TIH',
    icao: 'NTGC',
    latitude: -15.1196002960205,
    longitude: -148.231002807617,
    altitude: 6,
    schengen: false
  },
  {
    id: 2450,
    name: 'Apataki Airport',
    city: 'Apataki',
    country: 'French Polynesia',
    iata: 'APK',
    icao: 'NTGD',
    latitude: -15.5736,
    longitude: -146.414993,
    altitude: 8,
    schengen: false
  },
  {
    id: 2451,
    name: 'Reao Airport',
    city: 'Reao',
    country: 'French Polynesia',
    iata: 'REA',
    icao: 'NTGE',
    latitude: -18.4659004211426,
    longitude: -136.440002441406,
    altitude: 12,
    schengen: false
  },
  {
    id: 2452,
    name: 'Fakarava Airport',
    city: 'Fakarava',
    country: 'French Polynesia',
    iata: 'FAV',
    icao: 'NTGF',
    latitude: -16.0541000366211,
    longitude: -145.656997680664,
    altitude: 13,
    schengen: false
  },
  {
    id: 2453,
    name: 'Hikueru Atoll Airport',
    city: 'Hikueru',
    country: 'French Polynesia',
    iata: 'HHZ',
    icao: 'NTGH',
    latitude: -17.5447006225586,
    longitude: -142.613998413086,
    altitude: 5,
    schengen: false
  },
  {
    id: 2454,
    name: 'Manihi Airport',
    city: 'Manihi',
    country: 'French Polynesia',
    iata: 'XMH',
    icao: 'NTGI',
    latitude: -14.4368000030518,
    longitude: -146.070007324219,
    altitude: 14,
    schengen: false
  },
  {
    id: 2455,
    name: 'Totegegie Airport',
    city: 'Totegegie',
    country: 'French Polynesia',
    iata: 'GMR',
    icao: 'NTGJ',
    latitude: -23.0799007415771,
    longitude: -134.889999389648,
    altitude: 7,
    schengen: false
  },
  {
    id: 2456,
    name: 'Kaukura Airport',
    city: 'Kaukura Atoll',
    country: 'French Polynesia',
    iata: 'KKR',
    icao: 'NTGK',
    latitude: -15.6632995605469,
    longitude: -146.884994506836,
    altitude: 11,
    schengen: false
  },
  {
    id: 2457,
    name: 'Makemo Airport',
    city: 'Makemo',
    country: 'French Polynesia',
    iata: 'MKP',
    icao: 'NTGM',
    latitude: -16.5839004516602,
    longitude: -143.658004760742,
    altitude: 3,
    schengen: false
  },
  {
    id: 2458,
    name: 'Napuka Island Airport',
    city: 'Napuka',
    country: 'French Polynesia',
    iata: 'NAU',
    icao: 'NTGN',
    latitude: -14.1767997741699,
    longitude: -141.266998291016,
    altitude: 7,
    schengen: false
  },
  {
    id: 2459,
    name: 'Tatakoto Airport',
    city: 'Tatakoto',
    country: 'French Polynesia',
    iata: 'TKV',
    icao: 'NTGO',
    latitude: -17.3553009033203,
    longitude: -138.445007324219,
    altitude: 12,
    schengen: false
  },
  {
    id: 2460,
    name: 'Puka Puka Airport',
    city: 'Puka Puka',
    country: 'French Polynesia',
    iata: 'PKP',
    icao: 'NTGP',
    latitude: -14.8094997406006,
    longitude: -138.813003540039,
    altitude: 5,
    schengen: false
  },
  {
    id: 2461,
    name: 'Pukarua Airport',
    city: 'Pukarua',
    country: 'French Polynesia',
    iata: 'PUK',
    icao: 'NTGQ',
    latitude: -18.2956008911133,
    longitude: -137.016998291016,
    altitude: 5,
    schengen: false
  },
  {
    id: 2462,
    name: 'Takapoto Airport',
    city: 'Takapoto',
    country: 'French Polynesia',
    iata: 'TKP',
    icao: 'NTGT',
    latitude: -14.7095003128052,
    longitude: -145.246002197266,
    altitude: 12,
    schengen: false
  },
  {
    id: 2463,
    name: 'Arutua Airport',
    city: 'Arutua',
    country: 'French Polynesia',
    iata: 'AXR',
    icao: 'NTGU',
    latitude: -15.2482995986938,
    longitude: -146.617004394531,
    altitude: 9,
    schengen: false
  },
  {
    id: 2464,
    name: 'Mataiva Airport',
    city: 'Mataiva',
    country: 'French Polynesia',
    iata: 'MVT',
    icao: 'NTGV',
    latitude: -14.8681001663208,
    longitude: -148.716995239258,
    altitude: 11,
    schengen: false
  },
  {
    id: 2465,
    name: 'Nukutavake Airport',
    city: 'Nukutavake',
    country: 'French Polynesia',
    iata: 'NUK',
    icao: 'NTGW',
    latitude: -19.2849998474121,
    longitude: -138.772003173828,
    altitude: 17,
    schengen: false
  },
  {
    id: 2466,
    name: 'Tureia Airport',
    city: 'Tureia',
    country: 'French Polynesia',
    iata: 'ZTA',
    icao: 'NTGY',
    latitude: -20.7896995544434,
    longitude: -138.570007324219,
    altitude: 12,
    schengen: false
  },
  {
    id: 2467,
    name: 'Ahe Airport',
    city: 'Ahe',
    country: 'French Polynesia',
    iata: 'AHE',
    icao: 'NTHE',
    latitude: -14.4280996322632,
    longitude: -146.25700378418,
    altitude: 11,
    schengen: false
  },
  {
    id: 2468,
    name: 'Kauehi Airport',
    city: 'Kauehi',
    country: 'French Polynesia',
    iata: 'KHZ',
    icao: 'NTKA',
    latitude: -15.7807998657227,
    longitude: -145.123992919922,
    altitude: 13,
    schengen: false
  },
  {
    id: 2469,
    name: 'Faaite Airport',
    city: 'Faaite',
    country: 'French Polynesia',
    iata: 'FAC',
    icao: 'NTKF',
    latitude: -16.6867008209229,
    longitude: -145.328994750977,
    altitude: 7,
    schengen: false
  },
  {
    id: 2470,
    name: 'Naiu Airport',
    city: 'Niau',
    country: 'French Polynesia',
    iata: 'NIU',
    icao: 'NTKN',
    latitude: -16.1191,
    longitude: -146.3683,
    altitude: 50,
    schengen: false
  },
  {
    id: 2471,
    name: 'Raroia Airport',
    city: 'Raroia',
    country: 'French Polynesia',
    iata: 'RRR',
    icao: 'NTKO',
    latitude: -16.045,
    longitude: -142.476944,
    altitude: 18,
    schengen: false
  },
  {
    id: 2472,
    name: 'Takaroa Airport',
    city: 'Takaroa',
    country: 'French Polynesia',
    iata: 'TKX',
    icao: 'NTKR',
    latitude: -14.4558000564575,
    longitude: -145.024993896484,
    altitude: 13,
    schengen: false
  },
  {
    id: 2473,
    name: 'Nuku Hiva Airport',
    city: 'Nuku Hiva',
    country: 'French Polynesia',
    iata: 'NHV',
    icao: 'NTMD',
    latitude: -8.79559993743897,
    longitude: -140.22900390625,
    altitude: 220,
    schengen: false
  },
  {
    id: 2474,
    name: 'Hiva Oa-Atuona Airport',
    city: 'Hiva-oa',
    country: 'French Polynesia',
    iata: 'AUQ',
    icao: 'NTMN',
    latitude: -9.76879024506,
    longitude: -139.011001587,
    altitude: 1481,
    schengen: false
  },
  {
    id: 2475,
    name: 'Ua Pou Airport',
    city: 'Ua Pou',
    country: 'French Polynesia',
    iata: 'UAP',
    icao: 'NTMP',
    latitude: -9.35167,
    longitude: -140.078003,
    altitude: 16,
    schengen: false
  },
  {
    id: 2476,
    name: 'Ua Huka Airport',
    city: 'Ua Huka',
    country: 'French Polynesia',
    iata: 'UAH',
    icao: 'NTMU',
    latitude: -8.93611,
    longitude: -139.552002,
    altitude: 160,
    schengen: false
  },
  {
    id: 2477,
    name: 'Bora Bora Airport',
    city: 'Bora Bora',
    country: 'French Polynesia',
    iata: 'BOB',
    icao: 'NTTB',
    latitude: -16.4444007873535,
    longitude: -151.751007080078,
    altitude: 10,
    schengen: false
  },
  {
    id: 2478,
    name: 'Tetiaroa Airport',
    city: 'Tetiaroa',
    country: 'French Polynesia',
    iata: 'TTI',
    icao: 'NTTE',
    latitude: -17.0132999420166,
    longitude: -149.587005615234,
    altitude: 7,
    schengen: false
  },
  {
    id: 2479,
    name: 'Rangiroa Airport',
    city: 'Rangiroa',
    country: 'French Polynesia',
    iata: 'RGI',
    icao: 'NTTG',
    latitude: -14.9542999267578,
    longitude: -147.660995483398,
    altitude: 10,
    schengen: false
  },
  {
    id: 2480,
    name: 'Huahine-Fare Airport',
    city: 'Huahine Island',
    country: 'French Polynesia',
    iata: 'HUH',
    icao: 'NTTH',
    latitude: -16.6872005462646,
    longitude: -151.022003173828,
    altitude: 7,
    schengen: false
  },
  {
    id: 2481,
    name: 'Moorea Airport',
    city: 'Moorea',
    country: 'French Polynesia',
    iata: 'MOZ',
    icao: 'NTTM',
    latitude: -17.49,
    longitude: -149.761993,
    altitude: 9,
    schengen: false
  },
  {
    id: 2482,
    name: 'Hao Airport',
    city: 'Hao Island',
    country: 'French Polynesia',
    iata: 'HOI',
    icao: 'NTTO',
    latitude: -18.074800491333,
    longitude: -140.945999145508,
    altitude: 10,
    schengen: false
  },
  {
    id: 2483,
    name: 'Maupiti Airport',
    city: 'Maupiti',
    country: 'French Polynesia',
    iata: 'MAU',
    icao: 'NTTP',
    latitude: -16.4265003204346,
    longitude: -152.244003295898,
    altitude: 15,
    schengen: false
  },
  {
    id: 2484,
    name: 'Raiatea Airport',
    city: 'Raiatea Island',
    country: 'French Polynesia',
    iata: 'RFP',
    icao: 'NTTR',
    latitude: -16.7229,
    longitude: -151.466003,
    altitude: 3,
    schengen: false
  },
  {
    id: 2485,
    name: 'Vahitahi Airport',
    city: 'Vahitahi',
    country: 'French Polynesia',
    iata: 'VHZ',
    icao: 'NTUV',
    latitude: -18.7800006866455,
    longitude: -138.852996826172,
    altitude: 9,
    schengen: false
  },
  {
    id: 2486,
    name: 'Koulamoutou Mabimbi Airport',
    city: 'Koulamoutou',
    country: 'Gabon',
    iata: 'KOU',
    icao: 'FOGK',
    latitude: -1.1846100091934,
    longitude: 12.441300392151,
    altitude: 1070,
    schengen: false
  },
  {
    id: 2487,
    name: 'Mouilla Ville Airport',
    city: 'Mouila',
    country: 'Gabon',
    iata: 'MJL',
    icao: 'FOGM',
    latitude: -1.84513998031616,
    longitude: 11.0566997528076,
    altitude: 295,
    schengen: false
  },
  {
    id: 2488,
    name: 'Oyem Airport',
    city: 'Oyem',
    country: 'Gabon',
    iata: 'OYE',
    icao: 'FOGO',
    latitude: 1.54311001300812,
    longitude: 11.5813999176025,
    altitude: 2158,
    schengen: false
  },
  {
    id: 2489,
    name: 'Okondja Airport',
    city: 'Okondja',
    country: 'Gabon',
    iata: 'OKN',
    icao: 'FOGQ',
    latitude: -0.665214002132416,
    longitude: 13.6731004714966,
    altitude: 1325,
    schengen: false
  },
  {
    id: 2490,
    name: 'Lambarene Airport',
    city: 'Lambarene',
    country: 'Gabon',
    iata: 'LBQ',
    icao: 'FOGR',
    latitude: -0.704388976097107,
    longitude: 10.2456998825073,
    altitude: 82,
    schengen: false
  },
  {
    id: 2491,
    name: 'Bitam Airport',
    city: 'Bitam',
    country: 'Gabon',
    iata: 'BMM',
    icao: 'FOOB',
    latitude: 2.07563996315002,
    longitude: 11.493200302124,
    altitude: 1969,
    schengen: false
  },
  {
    id: 2492,
    name: 'Port Gentil Airport',
    city: 'Port Gentil',
    country: 'Gabon',
    iata: 'POG',
    icao: 'FOOG',
    latitude: -0.711739003658295,
    longitude: 8.75438022613525,
    altitude: 13,
    schengen: false
  },
  {
    id: 2493,
    name: 'Omboue Hopital Airport',
    city: 'Omboue Hospial',
    country: 'Gabon',
    iata: 'OMB',
    icao: 'FOOH',
    latitude: -1.57473003864288,
    longitude: 9.2626895904541,
    altitude: 33,
    schengen: false
  },
  {
    id: 2494,
    name: 'Makokou Airport',
    city: 'Makokou',
    country: 'Gabon',
    iata: 'MKU',
    icao: 'FOOK',
    latitude: 0.579210996627808,
    longitude: 12.8908996582031,
    altitude: 1726,
    schengen: false
  },
  {
    id: 2495,
    name: "Libreville Leon M'ba International Airport",
    city: 'Libreville',
    country: 'Gabon',
    iata: 'LBV',
    icao: 'FOOL',
    latitude: 0.458600014448,
    longitude: 9.4122800827,
    altitude: 39,
    schengen: false
  },
  {
    id: 2496,
    name: "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
    city: 'Franceville',
    country: 'Gabon',
    iata: 'MVB',
    icao: 'FOON',
    latitude: -1.65615999698639,
    longitude: 13.4379997253418,
    altitude: 1450,
    schengen: false
  },
  {
    id: 2497,
    name: 'Tchibanga Airport',
    city: 'Tchibanga',
    country: 'Gabon',
    iata: 'TCH',
    icao: 'FOOT',
    latitude: -2.84999990463257,
    longitude: 11.0170001983643,
    altitude: 269,
    schengen: false
  },
  {
    id: 2498,
    name: 'Mayumba Airport',
    city: 'Mayumba',
    country: 'Gabon',
    iata: 'MYB',
    icao: 'FOOY',
    latitude: -3.45841979980469,
    longitude: 10.6740760803223,
    altitude: 13,
    schengen: false
  },
  {
    id: 2499,
    name: 'Banjul International Airport',
    city: 'Banjul',
    country: 'Gambia',
    iata: 'BJL',
    icao: 'GBYD',
    latitude: 13.3380002975464,
    longitude: -16.6522006988525,
    altitude: 95,
    schengen: false
  },
  {
    id: 2500,
    name: 'Kopitnari Airport',
    city: 'Kutaisi',
    country: 'Georgia',
    iata: 'KUT',
    icao: 'UGKO',
    latitude: 42.176700592,
    longitude: 42.4826011658,
    altitude: 223,
    schengen: false
  },
  {
    id: 2501,
    name: 'Mestia Queen Tamar Airport',
    city: 'Mestia',
    country: 'Georgia',
    iata: '',
    icao: 'UGMS',
    latitude: 43.053597,
    longitude: 42.749012,
    altitude: 4778,
    schengen: false
  },
  {
    id: 2502,
    name: 'Batumi International Airport',
    city: 'Batumi',
    country: 'Georgia',
    iata: 'BUS',
    icao: 'UGSB',
    latitude: 41.6102981567,
    longitude: 41.5997009277,
    altitude: 105,
    schengen: false
  },
  {
    id: 2503,
    name: 'Sukhumi Dranda Airport',
    city: 'Sukhumi',
    country: 'Georgia',
    iata: 'SUI',
    icao: 'UGSS',
    latitude: 42.8582000732,
    longitude: 41.1281013489,
    altitude: 53,
    schengen: false
  },
  {
    id: 2504,
    name: 'Tbilisi International Airport',
    city: 'Tbilisi',
    country: 'Georgia',
    iata: 'TBS',
    icao: 'UGTB',
    latitude: 41.6692008972,
    longitude: 44.95470047,
    altitude: 1624,
    schengen: false
  },
  {
    id: 2505,
    name: 'Engels heliport',
    city: 'Ebenhofen',
    country: 'Germany',
    iata: '',
    icao: 'EBEN',
    latitude: 51.2116661071777,
    longitude: 4.58083295822144,
    altitude: 33,
    schengen: true
  },
  {
    id: 2506,
    name: 'Bautzen Airport',
    city: 'Bautzen',
    country: 'Germany',
    iata: '',
    icao: 'EDAB',
    latitude: 51.1936111450195,
    longitude: 14.5197219848633,
    altitude: 568,
    schengen: true
  },
  {
    id: 2507,
    name: 'Altenburg-Nobitz Airport',
    city: 'Altenburg',
    country: 'Germany',
    iata: 'AOC',
    icao: 'EDAC',
    latitude: 50.981945,
    longitude: 12.506389,
    altitude: 640,
    schengen: true
  },
  {
    id: 2508,
    name: 'Dessau Airport',
    city: 'Dessau',
    country: 'Germany',
    iata: '',
    icao: 'EDAD',
    latitude: 51.831693541,
    longitude: 12.1909618378,
    altitude: 187,
    schengen: true
  },
  {
    id: 2509,
    name: 'Eisenhüttenstadt Airport',
    city: 'Eisenhuettenstadt',
    country: 'Germany',
    iata: '',
    icao: 'EDAE',
    latitude: 52.195856,
    longitude: 14.58753,
    altitude: 144,
    schengen: true
  },
  {
    id: 2510,
    name: 'Heringsdorf Airport',
    city: 'Heringsdorf',
    country: 'Germany',
    iata: 'HDF',
    icao: 'EDAH',
    latitude: 53.8787002563,
    longitude: 14.152299881,
    altitude: 93,
    schengen: true
  },
  {
    id: 2511,
    name: 'Segeletz Airport',
    city: 'Segeletz',
    country: 'Germany',
    iata: '',
    icao: 'EDAI',
    latitude: 52.8266677856445,
    longitude: 12.5419435501099,
    altitude: 141,
    schengen: true
  },
  {
    id: 2512,
    name: 'Gera-Leumnitz Airport',
    city: 'Gera',
    country: 'Germany',
    iata: '',
    icao: 'EDAJ',
    latitude: 50.8816680908203,
    longitude: 12.1358327865601,
    altitude: 1014,
    schengen: true
  },
  {
    id: 2513,
    name: 'Großenhain Airport',
    city: 'Suhl',
    country: 'Germany',
    iata: '',
    icao: 'EDAK',
    latitude: 51.3080558776855,
    longitude: 13.5555562973022,
    altitude: 417,
    schengen: true
  },
  {
    id: 2514,
    name: 'Fuerstenwalde Airport',
    city: 'Furstenwalde',
    country: 'Germany',
    iata: '',
    icao: 'EDAL',
    latitude: 52.3905982971,
    longitude: 14.0972003937,
    altitude: 184,
    schengen: true
  },
  {
    id: 2515,
    name: 'Merseburg Airport',
    city: 'Muehlhausen',
    country: 'Germany',
    iata: '',
    icao: 'EDAM',
    latitude: 51.3630556,
    longitude: 11.9408333,
    altitude: 341,
    schengen: true
  },
  {
    id: 2516,
    name: 'Neustadt-Glewe Airport',
    city: 'Neustadt-Glewe',
    country: 'Germany',
    iata: '',
    icao: 'EDAN',
    latitude: 53.3597221374512,
    longitude: 11.6152782440186,
    altitude: 115,
    schengen: true
  },
  {
    id: 2517,
    name: 'Halle-Oppin Airport',
    city: 'Halle',
    country: 'Germany',
    iata: '',
    icao: 'EDAQ',
    latitude: 51.552223,
    longitude: 12.053889,
    altitude: 348,
    schengen: true
  },
  {
    id: 2518,
    name: 'Flugplatz Finsterwalde/Heinrichsruh',
    city: 'Finsterwalde',
    country: 'Germany',
    iata: '',
    icao: 'EDAS',
    latitude: 51.634445,
    longitude: 13.675556,
    altitude: 384,
    schengen: true
  },
  {
    id: 2519,
    name: 'Riesa-Göhlis Airport',
    city: 'Riesa',
    country: 'Germany',
    iata: 'IES',
    icao: 'EDAU',
    latitude: 51.2936096191,
    longitude: 13.3561105728,
    altitude: 322,
    schengen: true
  },
  {
    id: 2520,
    name: 'Eberswalde-Finow Airport',
    city: 'Eberswalde',
    country: 'Germany',
    iata: '',
    icao: 'EDAV',
    latitude: 52.827220916748,
    longitude: 13.6936111450195,
    altitude: 121,
    schengen: true
  },
  {
    id: 2521,
    name: 'Roitzschjora Airport',
    city: 'Roitzschjora',
    country: 'Germany',
    iata: '',
    icao: 'EDAW',
    latitude: 51.5777778625488,
    longitude: 12.4944438934326,
    altitude: 289,
    schengen: true
  },
  {
    id: 2522,
    name: 'Rechlin-Lärz Airport',
    city: 'Rechlin-laerz',
    country: 'Germany',
    iata: 'REB',
    icao: 'EDAX',
    latitude: 53.306388855,
    longitude: 12.7522220612,
    altitude: 220,
    schengen: true
  },
  {
    id: 2523,
    name: 'Strausberg Airport',
    city: 'Strausberg',
    country: 'Germany',
    iata: '',
    icao: 'EDAY',
    latitude: 52.5805549621582,
    longitude: 13.9166669845581,
    altitude: 262,
    schengen: true
  },
  {
    id: 2524,
    name: 'Schönhagen Airport',
    city: 'Schoenhagen',
    country: 'Germany',
    iata: 'QXH',
    icao: 'EDAZ',
    latitude: 52.203609,
    longitude: 13.156389,
    altitude: 152,
    schengen: true
  },
  {
    id: 2525,
    name: 'Cochstedt Airport',
    city: 'Cochstedt',
    country: 'Germany',
    iata: 'CSO',
    icao: 'EDBC',
    latitude: 51.8563995361,
    longitude: 11.42029953,
    altitude: 594,
    schengen: true
  },
  {
    id: 2526,
    name: 'Burg Airport',
    city: 'Burg',
    country: 'Germany',
    iata: '',
    icao: 'EDBG',
    latitude: 52.2416687011719,
    longitude: 11.8561105728149,
    altitude: 174,
    schengen: true
  },
  {
    id: 2527,
    name: 'Barth Airport',
    city: 'Barth',
    country: 'Germany',
    iata: 'BBH',
    icao: 'EDBH',
    latitude: 54.338253,
    longitude: 12.710515,
    altitude: 23,
    schengen: true
  },
  {
    id: 2528,
    name: 'Jena-Schöngleina Airport',
    city: 'Jena',
    country: 'Germany',
    iata: '',
    icao: 'EDBJ',
    latitude: 50.9152793884277,
    longitude: 11.7144441604614,
    altitude: 1247,
    schengen: true
  },
  {
    id: 2529,
    name: 'Kyritz Airport',
    city: 'Kyritz',
    country: 'Germany',
    iata: '',
    icao: 'EDBK',
    latitude: 52.918888092041,
    longitude: 12.4252777099609,
    altitude: 131,
    schengen: true
  },
  {
    id: 2530,
    name: 'Magdeburg "City" Airport',
    city: 'Magdeburg',
    country: 'Germany',
    iata: 'ZMG',
    icao: 'EDBM',
    latitude: 52.073612,
    longitude: 11.626389,
    altitude: 259,
    schengen: true
  },
  {
    id: 2531,
    name: 'Neubrandenburg Airport',
    city: 'Neubrandenburg',
    country: 'Germany',
    iata: 'FNB',
    icao: 'EDBN',
    latitude: 53.6022,
    longitude: 13.306,
    altitude: 228,
    schengen: true
  },
  {
    id: 2532,
    name: 'Rothenburg/Görlitz Airport',
    city: 'Rothenburg/ol',
    country: 'Germany',
    iata: '',
    icao: 'EDBR',
    latitude: 51.3633346557617,
    longitude: 14.9499998092651,
    altitude: 518,
    schengen: true
  },
  {
    id: 2533,
    name: 'Allstedt Airport',
    city: 'Allstedt',
    country: 'Germany',
    iata: '',
    icao: 'EDBT',
    latitude: 51.3805541992187,
    longitude: 11.4466667175293,
    altitude: 932,
    schengen: true
  },
  {
    id: 2534,
    name: 'Anklam Airport',
    city: 'Anklam',
    country: 'Germany',
    iata: '',
    icao: 'EDCA',
    latitude: 53.8327789306641,
    longitude: 13.6686105728149,
    altitude: 16,
    schengen: true
  },
  {
    id: 2535,
    name: 'Flugplatz Ballenstedt',
    city: 'Ballenstedt',
    country: 'Germany',
    iata: '',
    icao: 'EDCB',
    latitude: 51.745834,
    longitude: 11.229722,
    altitude: 535,
    schengen: true
  },
  {
    id: 2536,
    name: 'Cottbus-Drewitz Airport',
    city: 'Cottbus',
    country: 'Germany',
    iata: 'CBU',
    icao: 'EDCD',
    latitude: 51.889442,
    longitude: 14.531944,
    altitude: 272,
    schengen: true
  },
  {
    id: 2537,
    name: 'Rügen Airport',
    city: 'Ruegen',
    country: 'Germany',
    iata: 'GTI',
    icao: 'EDCG',
    latitude: 54.3833312988,
    longitude: 13.3255558014,
    altitude: 69,
    schengen: true
  },
  {
    id: 2538,
    name: 'Köthen Airport',
    city: 'Koethen',
    country: 'Germany',
    iata: 'KOQ',
    icao: 'EDCK',
    latitude: 51.7211112976,
    longitude: 11.9527778625,
    altitude: 305,
    schengen: true
  },
  {
    id: 2539,
    name: 'Klietz/Scharlibbe Airport',
    city: 'Scharlibbe',
    country: 'Germany',
    iata: '',
    icao: 'EDCL',
    latitude: 52.7094421386719,
    longitude: 12.0733327865601,
    altitude: 95,
    schengen: true
  },
  {
    id: 2540,
    name: 'Kamenz Airport',
    city: 'Kamenz',
    country: 'Germany',
    iata: '',
    icao: 'EDCM',
    latitude: 51.2969436645508,
    longitude: 14.1274995803833,
    altitude: 495,
    schengen: true
  },
  {
    id: 2541,
    name: 'Obermehler-Schlotheim Airport',
    city: 'Obermehler',
    country: 'Germany',
    iata: '',
    icao: 'EDCO',
    latitude: 51.2677764892578,
    longitude: 10.6347217559814,
    altitude: 909,
    schengen: true
  },
  {
    id: 2542,
    name: 'Peenemünde Airport',
    city: 'Peenemunde',
    country: 'Germany',
    iata: 'PEF',
    icao: 'EDCP',
    latitude: 54.1577796936,
    longitude: 13.7744436264,
    altitude: 7,
    schengen: true
  },
  {
    id: 2543,
    name: 'Rerik-Zweedorf Airport',
    city: 'Rerik',
    country: 'Germany',
    iata: '',
    icao: 'EDCR',
    latitude: 54.0819435119629,
    longitude: 11.6491670608521,
    altitude: 30,
    schengen: true
  },
  {
    id: 2544,
    name: 'Saarmund Airport',
    city: 'Saarmund',
    country: 'Germany',
    iata: '',
    icao: 'EDCS',
    latitude: 52.3083343505859,
    longitude: 13.1005563735962,
    altitude: 174,
    schengen: true
  },
  {
    id: 2545,
    name: 'Spremberg-Welzow Airport',
    city: 'Welzow',
    country: 'Germany',
    iata: '',
    icao: 'EDCY',
    latitude: 51.5755577087402,
    longitude: 14.1369438171387,
    altitude: 374,
    schengen: true
  },
  {
    id: 2546,
    name: 'Berlin-Schönefeld Airport',
    city: 'Berlin',
    country: 'Germany',
    iata: 'SXF',
    icao: 'EDDB',
    latitude: 52.380001,
    longitude: 13.5225,
    altitude: 157,
    schengen: true
  },
  {
    id: 2547,
    name: 'Dresden Airport',
    city: 'Dresden',
    country: 'Germany',
    iata: 'DRS',
    icao: 'EDDC',
    latitude: 51.1328010559082,
    longitude: 13.7672004699707,
    altitude: 755,
    schengen: true
  },
  {
    id: 2548,
    name: 'Erfurt Airport',
    city: 'Erfurt',
    country: 'Germany',
    iata: 'ERF',
    icao: 'EDDE',
    latitude: 50.9798011779785,
    longitude: 10.9581003189087,
    altitude: 1036,
    schengen: true
  },
  {
    id: 2549,
    name: 'Frankfurt am Main Airport',
    city: 'Frankfurt',
    country: 'Germany',
    iata: 'FRA',
    icao: 'EDDF',
    latitude: 50.033333,
    longitude: 8.570556,
    altitude: 364,
    schengen: true
  },
  {
    id: 2550,
    name: 'Münster Osnabrück Airport',
    city: 'Munster',
    country: 'Germany',
    iata: 'FMO',
    icao: 'EDDG',
    latitude: 52.134601593,
    longitude: 7.68483018875,
    altitude: 160,
    schengen: true
  },
  {
    id: 2551,
    name: 'Hamburg Airport',
    city: 'Hamburg',
    country: 'Germany',
    iata: 'HAM',
    icao: 'EDDH',
    latitude: 53.630401611328,
    longitude: 9.9882297515869,
    altitude: 53,
    schengen: true
  },
  {
    id: 2552,
    name: 'Berlin-Tempelhof International Airport',
    city: 'Berlin',
    country: 'Germany',
    iata: 'THF',
    icao: 'EDDI',
    latitude: 52.4729995727539,
    longitude: 13.4039001464844,
    altitude: 167,
    schengen: true
  },
  {
    id: 2553,
    name: 'Cologne Bonn Airport',
    city: 'Cologne',
    country: 'Germany',
    iata: 'CGN',
    icao: 'EDDK',
    latitude: 50.8658981323,
    longitude: 7.1427397728,
    altitude: 302,
    schengen: true
  },
  {
    id: 2554,
    name: 'Düsseldorf Airport',
    city: 'Duesseldorf',
    country: 'Germany',
    iata: 'DUS',
    icao: 'EDDL',
    latitude: 51.289501,
    longitude: 6.76678,
    altitude: 147,
    schengen: true
  },
  {
    id: 2555,
    name: 'Munich Airport',
    city: 'Munich',
    country: 'Germany',
    iata: 'MUC',
    icao: 'EDDM',
    latitude: 48.353802,
    longitude: 11.7861,
    altitude: 1487,
    schengen: true
  },
  {
    id: 2556,
    name: 'Nuremberg Airport',
    city: 'Nuernberg',
    country: 'Germany',
    iata: 'NUE',
    icao: 'EDDN',
    latitude: 49.498699,
    longitude: 11.078056,
    altitude: 1046,
    schengen: true
  },
  {
    id: 2557,
    name: 'Leipzig/Halle Airport',
    city: 'Leipzig',
    country: 'Germany',
    iata: 'LEJ',
    icao: 'EDDP',
    latitude: 51.423889,
    longitude: 12.236389,
    altitude: 465,
    schengen: true
  },
  {
    id: 2558,
    name: 'Saarbrücken Airport',
    city: 'Saarbruecken',
    country: 'Germany',
    iata: 'SCN',
    icao: 'EDDR',
    latitude: 49.2145996094,
    longitude: 7.10950994492,
    altitude: 1058,
    schengen: true
  },
  {
    id: 2559,
    name: 'Stuttgart Airport',
    city: 'Stuttgart',
    country: 'Germany',
    iata: 'STR',
    icao: 'EDDS',
    latitude: 48.6898994446,
    longitude: 9.22196006775,
    altitude: 1276,
    schengen: true
  },
  {
    id: 2560,
    name: 'Berlin-Tegel Airport',
    city: 'Berlin',
    country: 'Germany',
    iata: 'TXL',
    icao: 'EDDT',
    latitude: 52.5597,
    longitude: 13.2877,
    altitude: 122,
    schengen: true
  },
  {
    id: 2561,
    name: 'Hannover Airport',
    city: 'Hannover',
    country: 'Germany',
    iata: 'HAJ',
    icao: 'EDDV',
    latitude: 52.461101532,
    longitude: 9.68507957458,
    altitude: 183,
    schengen: true
  },
  {
    id: 2562,
    name: 'Bremen Airport',
    city: 'Bremen',
    country: 'Germany',
    iata: 'BRE',
    icao: 'EDDW',
    latitude: 53.0475006104,
    longitude: 8.78666973114,
    altitude: 14,
    schengen: true
  },
  {
    id: 2563,
    name: 'Reichelsheim Airport',
    city: 'Reichelsheim',
    country: 'Germany',
    iata: '',
    icao: 'EDFB',
    latitude: 50.3358345031738,
    longitude: 8.87805557250977,
    altitude: 397,
    schengen: true
  },
  {
    id: 2564,
    name: 'Aschaffenburg Airport',
    city: 'Aschaffenburg',
    country: 'Germany',
    iata: '',
    icao: 'EDFC',
    latitude: 49.9388885498047,
    longitude: 9.06388854980469,
    altitude: 410,
    schengen: true
  },
  {
    id: 2565,
    name: 'Frankfurt-Egelsbach Airport',
    city: 'Egelsbach',
    country: 'Germany',
    iata: 'QEF',
    icao: 'EDFE',
    latitude: 49.959999,
    longitude: 8.645833,
    altitude: 384,
    schengen: true
  },
  {
    id: 2566,
    name: 'Gelnhausen Airport',
    city: 'Gelnhausen',
    country: 'Germany',
    iata: '',
    icao: 'EDFG',
    latitude: 50.1972236633301,
    longitude: 9.17000007629395,
    altitude: 446,
    schengen: true
  },
  {
    id: 2567,
    name: 'Frankfurt-Hahn Airport',
    city: 'Hahn',
    country: 'Germany',
    iata: 'HHN',
    icao: 'EDFH',
    latitude: 49.9487,
    longitude: 7.26389,
    altitude: 1649,
    schengen: true
  },
  {
    id: 2568,
    name: 'Gießen-Lützellinden Airport',
    city: 'Giessen',
    country: 'Germany',
    iata: '',
    icao: 'EDFL',
    latitude: 50.543888092041,
    longitude: 8.59027767181397,
    altitude: 755,
    schengen: true
  },
  {
    id: 2569,
    name: 'Mannheim-City Airport',
    city: 'Mannheim',
    country: 'Germany',
    iata: 'MHG',
    icao: 'EDFM',
    latitude: 49.473057,
    longitude: 8.514167,
    altitude: 308,
    schengen: true
  },
  {
    id: 2570,
    name: 'Marburg-Schönstadt Airport',
    city: 'Schoenstadt',
    country: 'Germany',
    iata: '',
    icao: 'EDFN',
    latitude: 50.8744430541992,
    longitude: 8.8149995803833,
    altitude: 833,
    schengen: true
  },
  {
    id: 2571,
    name: 'Allendorf/Eder Airport',
    city: 'Allendorf',
    country: 'Germany',
    iata: '',
    icao: 'EDFQ',
    latitude: 51.0349998474121,
    longitude: 8.680832862854,
    altitude: 1158,
    schengen: true
  },
  {
    id: 2572,
    name: 'Lauterbach Airport',
    city: 'Lauterbach-Wernges',
    country: 'Germany',
    iata: '',
    icao: 'EDFT',
    latitude: 50.683334,
    longitude: 9.410833,
    altitude: 1211,
    schengen: true
  },
  {
    id: 2573,
    name: 'Worms Airport',
    city: 'Worms',
    country: 'Germany',
    iata: '',
    icao: 'EDFV',
    latitude: 49.606945,
    longitude: 8.368333,
    altitude: 295,
    schengen: true
  },
  {
    id: 2574,
    name: 'Würzburg-Schenkenturm Airport',
    city: 'Wuerzburg',
    country: 'Germany',
    iata: '',
    icao: 'EDFW',
    latitude: 49.81778,
    longitude: 9.8975,
    altitude: 991,
    schengen: true
  },
  {
    id: 2575,
    name: 'Mainz-Finthen Airport',
    city: 'Mainz',
    country: 'Germany',
    iata: '',
    icao: 'EDFZ',
    latitude: 49.967499,
    longitude: 8.147222,
    altitude: 525,
    schengen: true
  },
  {
    id: 2576,
    name: 'Eisenach-Kindel Airport',
    city: 'Eisenach',
    country: 'Germany',
    iata: 'EIB',
    icao: 'EDGE',
    latitude: 50.991604,
    longitude: 10.47973,
    altitude: 1112,
    schengen: true
  },
  {
    id: 2577,
    name: 'Siegerland Airport',
    city: 'Siegerland',
    country: 'Germany',
    iata: 'SGE',
    icao: 'EDGS',
    latitude: 50.7076988220215,
    longitude: 8.08296966552734,
    altitude: 1966,
    schengen: true
  },
  {
    id: 2578,
    name: 'Weinheim/Bergstraße Airport',
    city: 'Weinheim',
    country: 'Germany',
    iata: '',
    icao: 'EDGZ',
    latitude: 49.5675010681152,
    longitude: 8.61055564880371,
    altitude: 318,
    schengen: true
  },
  {
    id: 2579,
    name: 'Grube Airport',
    city: 'Grube',
    country: 'Germany',
    iata: '',
    icao: 'EDHB',
    latitude: 54.2444458007813,
    longitude: 11.0247220993042,
    altitude: 7,
    schengen: true
  },
  {
    id: 2580,
    name: 'Lüchow-Rehbeck Airport',
    city: 'Luechow',
    country: 'Germany',
    iata: '',
    icao: 'EDHC',
    latitude: 53.0161094665527,
    longitude: 11.1444444656372,
    altitude: 49,
    schengen: true
  },
  {
    id: 2581,
    name: 'Uetersen/Heist Airport',
    city: 'Uetersen',
    country: 'Germany',
    iata: '',
    icao: 'EDHE',
    latitude: 53.6463890075684,
    longitude: 9.70416736602783,
    altitude: 23,
    schengen: true
  },
  {
    id: 2582,
    name: 'Itzehoe/Hungriger Wolf Airport',
    city: 'Itzehoe',
    country: 'Germany',
    iata: '',
    icao: 'EDHF',
    latitude: 53.9944458007813,
    longitude: 9.57861137390137,
    altitude: 89,
    schengen: true
  },
  {
    id: 2583,
    name: 'Hamburg-Finkenwerder Airport',
    city: 'Hamburg',
    country: 'Germany',
    iata: 'XFW',
    icao: 'EDHI',
    latitude: 53.5352783203125,
    longitude: 9.83555603027344,
    altitude: 23,
    schengen: true
  },
  {
    id: 2584,
    name: 'Kiel-Holtenau Airport',
    city: 'Kiel',
    country: 'Germany',
    iata: 'KEL',
    icao: 'EDHK',
    latitude: 54.3794441223145,
    longitude: 10.1452779769897,
    altitude: 102,
    schengen: true
  },
  {
    id: 2585,
    name: 'Lübeck Blankensee Airport',
    city: 'Luebeck',
    country: 'Germany',
    iata: 'LBC',
    icao: 'EDHL',
    latitude: 53.8054008484,
    longitude: 10.7192001343,
    altitude: 53,
    schengen: true
  },
  {
    id: 2586,
    name: 'Flugplatz Hartenholm',
    city: 'Hasenmoor',
    country: 'Germany',
    iata: '',
    icao: 'EDHM',
    latitude: 53.915001,
    longitude: 10.035556,
    altitude: 108,
    schengen: true
  },
  {
    id: 2587,
    name: 'Neumünster Airport',
    city: 'Neumuenster',
    country: 'Germany',
    iata: 'EUM',
    icao: 'EDHN',
    latitude: 54.0794448852539,
    longitude: 9.94138908386231,
    altitude: 72,
    schengen: true
  },
  {
    id: 2588,
    name: 'Pellworm Field',
    city: 'Pellworm',
    country: 'Germany',
    iata: '',
    icao: 'EDHP',
    latitude: 54.5363883972,
    longitude: 8.68000030518,
    altitude: 3,
    schengen: true
  },
  {
    id: 2589,
    name: 'Stade Airport',
    city: 'Stade',
    country: 'Germany',
    iata: '',
    icao: 'EDHS',
    latitude: 53.5611114501953,
    longitude: 9.49916744232178,
    altitude: 62,
    schengen: true
  },
  {
    id: 2590,
    name: 'Lauenbrück Airport',
    city: 'Lauenbrueck',
    country: 'Germany',
    iata: '',
    icao: 'EDHU',
    latitude: 53.2075004577637,
    longitude: 9.57333278656006,
    altitude: 98,
    schengen: true
  },
  {
    id: 2591,
    name: 'Memmingen Allgau Airport',
    city: 'Memmingen',
    country: 'Germany',
    iata: 'FMM',
    icao: 'EDJA',
    latitude: 47.9888000488,
    longitude: 10.2395000458,
    altitude: 2077,
    schengen: true
  },
  {
    id: 2592,
    name: 'Aachen-Merzbrück Airport',
    city: 'Aachen',
    country: 'Germany',
    iata: 'AAH',
    icao: 'EDKA',
    latitude: 50.823055267334,
    longitude: 6.18638896942139,
    altitude: 623,
    schengen: true
  },
  {
    id: 2593,
    name: 'Bonn-Hangelar Airport',
    city: 'Sankt-Augustin',
    country: 'Germany',
    iata: 'BNJ',
    icao: 'EDKB',
    latitude: 50.7688903809,
    longitude: 7.16333293915,
    altitude: 197,
    schengen: true
  },
  {
    id: 2594,
    name: 'Leverkusen Airport',
    city: 'Leverkusen',
    country: 'Germany',
    iata: '',
    icao: 'EDKL',
    latitude: 51.0152778625488,
    longitude: 7.00555610656738,
    altitude: 157,
    schengen: true
  },
  {
    id: 2595,
    name: 'Meschede-Schüren Airport',
    city: 'Meschede',
    country: 'Germany',
    iata: '',
    icao: 'EDKM',
    latitude: 51.3027763366699,
    longitude: 8.23916721343994,
    altitude: 1434,
    schengen: true
  },
  {
    id: 2596,
    name: 'Wipperfürth-Neye Airport',
    city: 'Wipperfuerth',
    country: 'Germany',
    iata: '',
    icao: 'EDKN',
    latitude: 51.1241683959961,
    longitude: 7.37361097335815,
    altitude: 863,
    schengen: true
  },
  {
    id: 2597,
    name: 'Brilon/Hochsauerlandkreis Airport',
    city: 'Brilon',
    country: 'Germany',
    iata: '',
    icao: 'EDKO',
    latitude: 51.4025001525879,
    longitude: 8.64166736602783,
    altitude: 1509,
    schengen: true
  },
  {
    id: 2598,
    name: 'Flugplatz Dahlemer Binz',
    city: 'Dahlemer Binz',
    country: 'Germany',
    iata: '',
    icao: 'EDKV',
    latitude: 50.405555725,
    longitude: 6.5288891792,
    altitude: 1896,
    schengen: true
  },
  {
    id: 2599,
    name: 'Meinerzhagen Airport',
    city: 'Meinerzhagen',
    country: 'Germany',
    iata: '',
    icao: 'EDKZ',
    latitude: 51.0999984741211,
    longitude: 7.59999990463257,
    altitude: 1549,
    schengen: true
  },
  {
    id: 2600,
    name: 'Arnsberg-Menden Airport',
    city: 'Arnsberg',
    country: 'Germany',
    iata: '',
    icao: 'EDLA',
    latitude: 51.4838905334473,
    longitude: 7.89833307266235,
    altitude: 794,
    schengen: true
  },
  {
    id: 2601,
    name: 'Borkenberge Airport',
    city: 'Duelmen',
    country: 'Germany',
    iata: '',
    icao: 'EDLB',
    latitude: 51.7799987793,
    longitude: 7.28805589676,
    altitude: 157,
    schengen: true
  },
  {
    id: 2602,
    name: 'Dinslaken/Schwarze Heide Airport',
    city: 'Dinslaken',
    country: 'Germany',
    iata: '',
    icao: 'EDLD',
    latitude: 51.616112,
    longitude: 6.865278,
    altitude: 217,
    schengen: true
  },
  {
    id: 2603,
    name: 'Essen Mulheim Airport',
    city: 'Essen',
    country: 'Germany',
    iata: 'ESS',
    icao: 'EDLE',
    latitude: 51.4023017883301,
    longitude: 6.93732976913452,
    altitude: 424,
    schengen: true
  },
  {
    id: 2604,
    name: 'Grefrath-Niershorst Airport',
    city: 'Grefrath',
    country: 'Germany',
    iata: '',
    icao: 'EDLF',
    latitude: 51.3338890075684,
    longitude: 6.35944414138794,
    altitude: 105,
    schengen: true
  },
  {
    id: 2605,
    name: 'Hamm-Lippewiesen Airport',
    city: 'Hamm',
    country: 'Germany',
    iata: '',
    icao: 'EDLH',
    latitude: 51.6897201538086,
    longitude: 7.81611108779907,
    altitude: 190,
    schengen: true
  },
  {
    id: 2606,
    name: 'Bielefeld Airport',
    city: 'Bielefeld',
    country: 'Germany',
    iata: 'BFE',
    icao: 'EDLI',
    latitude: 51.964722,
    longitude: 8.544444,
    altitude: 454,
    schengen: true
  },
  {
    id: 2607,
    name: 'Krefeld-Egelsberg Airport',
    city: 'Krefeld',
    country: 'Germany',
    iata: '',
    icao: 'EDLK',
    latitude: 51.3849983215332,
    longitude: 6.58777809143066,
    altitude: 141,
    schengen: true
  },
  {
    id: 2608,
    name: 'Marl-Loemühle Airport',
    city: 'Recklinghausen',
    country: 'Germany',
    iata: '',
    icao: 'EDLM',
    latitude: 51.647202,
    longitude: 7.16333,
    altitude: 240,
    schengen: true
  },
  {
    id: 2609,
    name: 'Mönchengladbach Airport',
    city: 'Moenchengladbach',
    country: 'Germany',
    iata: 'MGL',
    icao: 'EDLN',
    latitude: 51.230278,
    longitude: 6.504444,
    altitude: 125,
    schengen: true
  },
  {
    id: 2610,
    name: 'Oerlinghausen Airport',
    city: 'TELGTE',
    country: 'Germany',
    iata: '',
    icao: 'EDLO',
    latitude: 51.9322204589844,
    longitude: 8.66166687011719,
    altitude: 558,
    schengen: true
  },
  {
    id: 2611,
    name: 'Paderborn Lippstadt Airport',
    city: 'Paderborn',
    country: 'Germany',
    iata: 'PAD',
    icao: 'EDLP',
    latitude: 51.6141014099,
    longitude: 8.61631965637,
    altitude: 699,
    schengen: true
  },
  {
    id: 2612,
    name: 'Stadtlohn-Vreden Airport',
    city: 'Stadtlohn',
    country: 'Germany',
    iata: '',
    icao: 'EDLS',
    latitude: 51.9958343505859,
    longitude: 6.84055614471436,
    altitude: 157,
    schengen: true
  },
  {
    id: 2613,
    name: 'Münster-Telgte Airport',
    city: 'Muenster',
    country: 'Germany',
    iata: '',
    icao: 'EDLT',
    latitude: 51.9444427490234,
    longitude: 7.77388906478882,
    altitude: 177,
    schengen: true
  },
  {
    id: 2614,
    name: 'Weeze Airport',
    city: 'Weeze',
    country: 'Germany',
    iata: 'NRN',
    icao: 'EDLV',
    latitude: 51.6024017334,
    longitude: 6.14216995239,
    altitude: 106,
    schengen: true
  },
  {
    id: 2615,
    name: 'Dortmund Airport',
    city: 'Dortmund',
    country: 'Germany',
    iata: 'DTM',
    icao: 'EDLW',
    latitude: 51.5182991028,
    longitude: 7.61223983765,
    altitude: 425,
    schengen: true
  },
  {
    id: 2616,
    name: 'Augsburg Airport',
    city: 'Augsburg',
    country: 'Germany',
    iata: 'AGB',
    icao: 'EDMA',
    latitude: 48.425278,
    longitude: 10.931667,
    altitude: 1516,
    schengen: true
  },
  {
    id: 2617,
    name: 'Biberach a.d. Riß Airport',
    city: 'Biberach',
    country: 'Germany',
    iata: '',
    icao: 'EDMB',
    latitude: 48.1111106872559,
    longitude: 9.76277828216553,
    altitude: 1903,
    schengen: true
  },
  {
    id: 2618,
    name: 'Eggenfelden Airport',
    city: 'Eggenfelden',
    country: 'Germany',
    iata: '',
    icao: 'EDME',
    latitude: 48.396110534668,
    longitude: 12.7236108779907,
    altitude: 1342,
    schengen: true
  },
  {
    id: 2619,
    name: 'Landshut Airport',
    city: 'Landshut',
    country: 'Germany',
    iata: '',
    icao: 'EDML',
    latitude: 48.5116653442383,
    longitude: 12.033332824707,
    altitude: 1312,
    schengen: true
  },
  {
    id: 2620,
    name: 'Mindelheim-Mattsies Airport',
    city: 'Mindelheim',
    country: 'Germany',
    iata: '',
    icao: 'EDMN',
    latitude: 48.1069450378418,
    longitude: 10.5249996185303,
    altitude: 1857,
    schengen: true
  },
  {
    id: 2621,
    name: 'Oberpfaffenhofen Airport',
    city: 'Oberpfaffenhofen',
    country: 'Germany',
    iata: 'OBF',
    icao: 'EDMO',
    latitude: 48.081402,
    longitude: 11.2831,
    altitude: 1947,
    schengen: true
  },
  {
    id: 2622,
    name: 'Straubing Airport',
    city: 'Straubing',
    country: 'Germany',
    iata: 'RBM',
    icao: 'EDMS',
    latitude: 48.9008331298828,
    longitude: 12.5166673660278,
    altitude: 1047,
    schengen: true
  },
  {
    id: 2623,
    name: 'Tannheim Airport',
    city: 'Tannheim',
    country: 'Germany',
    iata: '',
    icao: 'EDMT',
    latitude: 48.0099983215,
    longitude: 10.098610878,
    altitude: 1903,
    schengen: true
  },
  {
    id: 2624,
    name: 'Vilshofen Airport',
    city: 'Vilshofen',
    country: 'Germany',
    iata: '',
    icao: 'EDMV',
    latitude: 48.6349983215332,
    longitude: 13.1955556869507,
    altitude: 991,
    schengen: true
  },
  {
    id: 2625,
    name: 'Arnbruck Airport',
    city: 'Arnbruck',
    country: 'Germany',
    iata: '',
    icao: 'EDNB',
    latitude: 49.1247215270996,
    longitude: 12.9855556488037,
    altitude: 1716,
    schengen: true
  },
  {
    id: 2626,
    name: 'Elsenthal Grafe Airport',
    city: 'Elsenthal',
    country: 'Germany',
    iata: '',
    icao: 'EDNF',
    latitude: 48.8224983215332,
    longitude: 13.3675003051758,
    altitude: 1417,
    schengen: true
  },
  {
    id: 2627,
    name: 'Leutkirch-Unterzeil Airport',
    city: 'Leutkirch',
    country: 'Germany',
    iata: '',
    icao: 'EDNL',
    latitude: 47.8588905334473,
    longitude: 10.0141668319702,
    altitude: 2100,
    schengen: true
  },
  {
    id: 2628,
    name: 'Nittenau-Bruck Airport',
    city: 'Nittenau',
    country: 'Germany',
    iata: '',
    icao: 'EDNM',
    latitude: 49.2224998474121,
    longitude: 12.296667098999,
    altitude: 1161,
    schengen: true
  },
  {
    id: 2629,
    name: 'Regensburg-Oberhub Airport',
    city: 'Regensburg',
    country: 'Germany',
    iata: '',
    icao: 'EDNR',
    latitude: 49.1419448852539,
    longitude: 12.0819444656372,
    altitude: 1299,
    schengen: true
  },
  {
    id: 2630,
    name: 'Vogtareuth Airport',
    city: 'Vogtareuth',
    country: 'Germany',
    iata: '',
    icao: 'EDNV',
    latitude: 47.9461097717285,
    longitude: 12.20472240448,
    altitude: 1535,
    schengen: true
  },
  {
    id: 2631,
    name: 'Oberschleißheim Airfield',
    city: 'Schleissheim',
    country: 'Germany',
    iata: '',
    icao: 'EDNX',
    latitude: 48.239445,
    longitude: 11.561389,
    altitude: 1594,
    schengen: true
  },
  {
    id: 2632,
    name: 'Friedrichshafen Airport',
    city: 'Friedrichshafen',
    country: 'Germany',
    iata: 'FDH',
    icao: 'EDNY',
    latitude: 47.6712989807,
    longitude: 9.51148986816,
    altitude: 1367,
    schengen: true
  },
  {
    id: 2633,
    name: 'Bienenfarm Airport',
    city: 'Nauen',
    country: 'Germany',
    iata: '',
    icao: 'EDOI',
    latitude: 52.661667,
    longitude: 12.745833,
    altitude: 120,
    schengen: true
  },
  {
    id: 2634,
    name: 'Lüsse Airport',
    city: 'Luesse',
    country: 'Germany',
    iata: '',
    icao: 'EDOJ',
    latitude: 52.1411094665527,
    longitude: 12.664722442627,
    altitude: 217,
    schengen: true
  },
  {
    id: 2635,
    name: 'Schwerin Parchim Airport',
    city: 'Parchim',
    country: 'Germany',
    iata: 'SZW',
    icao: 'EDOP',
    latitude: 53.426998,
    longitude: 11.7834,
    altitude: 166,
    schengen: true
  },
  {
    id: 2636,
    name: 'Stendal-Borstel Airport',
    city: 'Stendal',
    country: 'Germany',
    iata: '',
    icao: 'EDOV',
    latitude: 52.6288871765137,
    longitude: 11.8186111450195,
    altitude: 184,
    schengen: true
  },
  {
    id: 2637,
    name: 'Aalen-Heidenheim/Elchingen Airport',
    city: 'Aalen-heidenheim',
    country: 'Germany',
    iata: '',
    icao: 'EDPA',
    latitude: 48.7777786254883,
    longitude: 10.2647218704224,
    altitude: 1916,
    schengen: true
  },
  {
    id: 2638,
    name: 'Thalmässing-Waizenhofen Airport',
    city: 'Thalmaessing',
    country: 'Germany',
    iata: '',
    icao: 'EDPW',
    latitude: 49.0641670227051,
    longitude: 11.2091665267944,
    altitude: 1893,
    schengen: true
  },
  {
    id: 2639,
    name: 'Airport Bamberg-Breitenau',
    city: 'Bamberg',
    country: 'Germany',
    iata: '',
    icao: 'EDQA',
    latitude: 49.920403,
    longitude: 10.914233,
    altitude: 811,
    schengen: true
  },
  {
    id: 2640,
    name: 'Bayreuth Airport',
    city: 'Bayreuth',
    country: 'Germany',
    iata: 'BYU',
    icao: 'EDQD',
    latitude: 49.985001,
    longitude: 11.64,
    altitude: 1601,
    schengen: true
  },
  {
    id: 2641,
    name: 'Burg Feuerstein Airport',
    city: 'Burg Feuerstein',
    country: 'Germany',
    iata: 'URD',
    icao: 'EDQE',
    latitude: 49.794166564941,
    longitude: 11.133610725403,
    altitude: 1673,
    schengen: true
  },
  {
    id: 2642,
    name: 'Ansbach-Petersdorf Airport',
    city: 'Ansbach',
    country: 'Germany',
    iata: '',
    icao: 'EDQF',
    latitude: 49.361111,
    longitude: 10.669444,
    altitude: 1371,
    schengen: true
  },
  {
    id: 2643,
    name: 'Hof-Plauen Airport',
    city: 'Hof',
    country: 'Germany',
    iata: 'HOQ',
    icao: 'EDQM',
    latitude: 50.2886123657227,
    longitude: 11.8563890457153,
    altitude: 1959,
    schengen: true
  },
  {
    id: 2644,
    name: 'Haßfurt-Schweinfurt Airport',
    city: 'Hassfurt',
    country: 'Germany',
    iata: '',
    icao: 'EDQT',
    latitude: 50.0180549621582,
    longitude: 10.5294437408447,
    altitude: 719,
    schengen: true
  },
  {
    id: 2645,
    name: 'Bad Neuenahr-Ahrweiler Airport',
    city: 'Bad Neuenahr',
    country: 'Germany',
    iata: '',
    icao: 'EDRA',
    latitude: 50.5577774047852,
    longitude: 7.13638877868652,
    altitude: 673,
    schengen: true
  },
  {
    id: 2646,
    name: 'Bitburg Airport',
    city: 'Birburg',
    country: 'Germany',
    iata: 'BBJ',
    icao: 'EDRB',
    latitude: 49.945278,
    longitude: 6.565,
    altitude: 1220,
    schengen: true
  },
  {
    id: 2647,
    name: 'Bad Dürkheim Airport',
    city: 'Bad Duerkheim',
    country: 'Germany',
    iata: '',
    icao: 'EDRF',
    latitude: 49.4730567932129,
    longitude: 8.19638919830322,
    altitude: 351,
    schengen: true
  },
  {
    id: 2648,
    name: 'Linkenheim Airport',
    city: 'Linkenheim',
    country: 'Germany',
    iata: '',
    icao: 'EDRI',
    latitude: 49.1416664123535,
    longitude: 8.39472198486328,
    altitude: 325,
    schengen: true
  },
  {
    id: 2649,
    name: 'Koblenz-Winningen Airport',
    city: 'Koblenz',
    country: 'Germany',
    iata: '',
    icao: 'EDRK',
    latitude: 50.325558,
    longitude: 7.528611,
    altitude: 640,
    schengen: true
  },
  {
    id: 2650,
    name: 'Nannhausen Airport',
    city: 'Nannhausen',
    country: 'Germany',
    iata: '',
    icao: 'EDRN',
    latitude: 49.9702796936,
    longitude: 7.47916698456,
    altitude: 1224,
    schengen: true
  },
  {
    id: 2651,
    name: 'Trier-Föhren Airport',
    city: 'Trier',
    country: 'Germany',
    iata: '',
    icao: 'EDRT',
    latitude: 49.863888,
    longitude: 6.7875,
    altitude: 666,
    schengen: true
  },
  {
    id: 2652,
    name: 'Wershofen/Eifel Airfield',
    city: 'Wershofen',
    country: 'Germany',
    iata: '',
    icao: 'EDRV',
    latitude: 50.451389,
    longitude: 6.783333,
    altitude: 1581,
    schengen: true
  },
  {
    id: 2653,
    name: 'Speyer Airport',
    city: 'Speyer',
    country: 'Germany',
    iata: '',
    icao: 'EDRY',
    latitude: 49.304722,
    longitude: 8.451389,
    altitude: 312,
    schengen: true
  },
  {
    id: 2654,
    name: 'Zweibrücken Airport',
    city: 'Zweibruecken',
    country: 'Germany',
    iata: 'ZQW',
    icao: 'EDRZ',
    latitude: 49.2094,
    longitude: 7.40056,
    altitude: 1132,
    schengen: true
  },
  {
    id: 2655,
    name: 'Karlsruhe Baden-Baden Airport',
    city: 'Karlsruhe/Baden-Baden',
    country: 'Germany',
    iata: 'FKB',
    icao: 'EDSB',
    latitude: 48.7793998718,
    longitude: 8.08049964905,
    altitude: 408,
    schengen: true
  },
  {
    id: 2656,
    name: 'Donaueschingen-Villingen Airport',
    city: 'Donaueschingen',
    country: 'Germany',
    iata: 'ZQL',
    icao: 'EDTD',
    latitude: 47.9733314514,
    longitude: 8.52222156525,
    altitude: 2231,
    schengen: true
  },
  {
    id: 2657,
    name: 'Freiburg i. Br. Airport',
    city: 'Freiburg',
    country: 'Germany',
    iata: '',
    icao: 'EDTF',
    latitude: 48.0227775574,
    longitude: 7.83249998093,
    altitude: 801,
    schengen: true
  },
  {
    id: 2658,
    name: 'Bremgarten Airport',
    city: 'Bremgarten',
    country: 'Germany',
    iata: '',
    icao: 'EDTG',
    latitude: 47.9027786255,
    longitude: 7.6177778244,
    altitude: 696,
    schengen: true
  },
  {
    id: 2659,
    name: 'Karlsruhe-Forchheim Airport',
    city: 'Karlsruhe',
    country: 'Germany',
    iata: '',
    icao: 'EDTK',
    latitude: 48.9843,
    longitude: 8.3355,
    altitude: 381,
    schengen: true
  },
  {
    id: 2660,
    name: 'Lahr Airport',
    city: 'Lahr',
    country: 'Germany',
    iata: 'LHA',
    icao: 'EDTL',
    latitude: 48.3693008423,
    longitude: 7.82772016525,
    altitude: 511,
    schengen: true
  },
  {
    id: 2661,
    name: 'Mengen-Hohentengen Airport',
    city: 'Mengen',
    country: 'Germany',
    iata: '',
    icao: 'EDTM',
    latitude: 48.0538902282715,
    longitude: 9.37277793884277,
    altitude: 1818,
    schengen: true
  },
  {
    id: 2662,
    name: 'Nabern/Teck Airport',
    city: 'Kirchheim-Teck',
    country: 'Germany',
    iata: '',
    icao: 'EDTN',
    latitude: 48.61277771,
    longitude: 9.47722244263,
    altitude: 1214,
    schengen: true
  },
  {
    id: 2663,
    name: 'Adolf Würth Airport',
    city: 'Schwaebisch Hall',
    country: 'Germany',
    iata: '',
    icao: 'EDTY',
    latitude: 49.1183319092,
    longitude: 9.78388881683,
    altitude: 1299,
    schengen: true
  },
  {
    id: 2664,
    name: 'Finsterwalde/Schacksdorf Airport',
    city: 'Soest',
    country: 'Germany',
    iata: '',
    icao: 'EDUS',
    latitude: 51.607498,
    longitude: 13.743611,
    altitude: 384,
    schengen: true
  },
  {
    id: 2665,
    name: 'Tutow Airport',
    city: 'Wildenrath',
    country: 'Germany',
    iata: '',
    icao: 'EDUW',
    latitude: 53.9219436645508,
    longitude: 13.2188892364502,
    altitude: 26,
    schengen: true
  },
  {
    id: 2666,
    name: 'Zerbst Airport',
    city: 'Zerbst/Anhalt',
    country: 'Germany',
    iata: '',
    icao: 'EDUZ',
    latitude: 52.000832,
    longitude: 12.148611,
    altitude: 289,
    schengen: true
  },
  {
    id: 2667,
    name: 'Celle-Arloh Airport',
    city: 'Celle',
    country: 'Germany',
    iata: '',
    icao: 'EDVC',
    latitude: 52.6872215270996,
    longitude: 10.1113891601563,
    altitude: 207,
    schengen: true
  },
  {
    id: 2668,
    name: 'Braunschweig-Wolfsburg Airport',
    city: 'Braunschweig',
    country: 'Germany',
    iata: 'BWE',
    icao: 'EDVE',
    latitude: 52.319199,
    longitude: 10.5561,
    altitude: 295,
    schengen: true
  },
  {
    id: 2669,
    name: 'Hodenhagen Airport',
    city: 'Hodenhagen',
    country: 'Germany',
    iata: '',
    icao: 'EDVH',
    latitude: 52.761865,
    longitude: 9.61056,
    altitude: 79,
    schengen: true
  },
  {
    id: 2670,
    name: 'Höxter-Holzminden Airport',
    city: 'Hoexter Holzminden',
    country: 'Germany',
    iata: '',
    icao: 'EDVI',
    latitude: 51.8066673278809,
    longitude: 9.37833309173584,
    altitude: 1263,
    schengen: true
  },
  {
    id: 2671,
    name: 'Kassel-Calden Airport',
    city: 'Kassel',
    country: 'Germany',
    iata: 'KSF',
    icao: 'EDVK',
    latitude: 51.417273,
    longitude: 9.384967,
    altitude: 820,
    schengen: true
  },
  {
    id: 2672,
    name: 'Hildesheim Airport',
    city: 'Hildesheim',
    country: 'Germany',
    iata: '',
    icao: 'EDVM',
    latitude: 52.1813888549805,
    longitude: 9.94638919830322,
    altitude: 292,
    schengen: true
  },
  {
    id: 2673,
    name: 'Northeim Airport',
    city: 'Northeim',
    country: 'Germany',
    iata: '',
    icao: 'EDVN',
    latitude: 51.7063903808594,
    longitude: 10.039722442627,
    altitude: 404,
    schengen: true
  },
  {
    id: 2674,
    name: 'Peine-Eddesse Airport',
    city: 'Peine',
    country: 'Germany',
    iata: '',
    icao: 'EDVP',
    latitude: 52.4025001526,
    longitude: 10.2288894653,
    altitude: 249,
    schengen: true
  },
  {
    id: 2675,
    name: 'Rinteln Airport',
    city: 'Rinteln',
    country: 'Germany',
    iata: '',
    icao: 'EDVR',
    latitude: 52.1752777099609,
    longitude: 9.0533332824707,
    altitude: 180,
    schengen: true
  },
  {
    id: 2676,
    name: 'Salzgitter-Drütte Airport',
    city: 'Salzgitter',
    country: 'Germany',
    iata: '',
    icao: 'EDVS',
    latitude: 52.1544456481934,
    longitude: 10.4266672134399,
    altitude: 328,
    schengen: true
  },
  {
    id: 2677,
    name: 'Uelzen Airport',
    city: 'Uelzen',
    country: 'Germany',
    iata: '',
    icao: 'EDVU',
    latitude: 52.9838905334,
    longitude: 10.4650001526,
    altitude: 246,
    schengen: true
  },
  {
    id: 2678,
    name: 'Hameln-Pyrmont Airport',
    city: 'Bad Pyrmont',
    country: 'Germany',
    iata: '',
    icao: 'EDVW',
    latitude: 51.966667175293,
    longitude: 9.29166698455811,
    altitude: 1178,
    schengen: true
  },
  {
    id: 2679,
    name: 'Porta Westfalica Airport',
    city: 'Bad Oeynhausen',
    country: 'Germany',
    iata: '',
    icao: 'EDVY',
    latitude: 52.220832824707,
    longitude: 8.85916709899902,
    altitude: 148,
    schengen: true
  },
  {
    id: 2680,
    name: 'Bremerhaven Airport',
    city: 'Bremerhaven',
    country: 'Germany',
    iata: 'BRV',
    icao: 'EDWB',
    latitude: 53.506943,
    longitude: 8.572778,
    altitude: 10,
    schengen: true
  },
  {
    id: 2681,
    name: 'Damme Airport',
    city: 'Damme',
    country: 'Germany',
    iata: '',
    icao: 'EDWC',
    latitude: 52.4874992370606,
    longitude: 8.18555641174316,
    altitude: 151,
    schengen: true
  },
  {
    id: 2682,
    name: 'Emden Airport',
    city: 'Emden',
    country: 'Germany',
    iata: 'EME',
    icao: 'EDWE',
    latitude: 53.391109,
    longitude: 7.2275,
    altitude: 3,
    schengen: true
  },
  {
    id: 2683,
    name: 'Leer-Papenburg Airport',
    city: 'Leer',
    country: 'Germany',
    iata: '',
    icao: 'EDWF',
    latitude: 53.2719421386719,
    longitude: 7.44166707992554,
    altitude: 3,
    schengen: true
  },
  {
    id: 2684,
    name: 'Wangerooge Airport',
    city: 'Wangerooge',
    country: 'Germany',
    iata: 'AGE',
    icao: 'EDWG',
    latitude: 53.7827796936035,
    longitude: 7.91388893127441,
    altitude: 7,
    schengen: true
  },
  {
    id: 2685,
    name: 'Oldenburg-Hatten Airfield',
    city: 'Oldenburg',
    country: 'Germany',
    iata: '',
    icao: 'EDWH',
    latitude: 53.06889,
    longitude: 8.313611,
    altitude: 26,
    schengen: true
  },
  {
    id: 2686,
    name: 'Wilhelmshaven-Mariensiel Airport',
    city: 'Wilhelmshaven',
    country: 'Germany',
    iata: 'WVN',
    icao: 'EDWI',
    latitude: 53.5022201538086,
    longitude: 8.05222225189209,
    altitude: 16,
    schengen: true
  },
  {
    id: 2687,
    name: 'Juist Airport',
    city: 'Juist',
    country: 'Germany',
    iata: 'JUI',
    icao: 'EDWJ',
    latitude: 53.6811103820801,
    longitude: 7.055832862854,
    altitude: 7,
    schengen: true
  },
  {
    id: 2688,
    name: 'Karlshöfen Airport',
    city: 'Karlshoefen',
    country: 'Germany',
    iata: '',
    icao: 'EDWK',
    latitude: 53.3327789306641,
    longitude: 9.02833271026611,
    altitude: 20,
    schengen: true
  },
  {
    id: 2689,
    name: 'Langeoog Airport',
    city: 'Langeoog',
    country: 'Germany',
    iata: 'LGO',
    icao: 'EDWL',
    latitude: 53.7425003051758,
    longitude: 7.49777793884277,
    altitude: 7,
    schengen: true
  },
  {
    id: 2690,
    name: 'Weser-Wümme Airport',
    city: 'Hellwege',
    country: 'Germany',
    iata: '',
    icao: 'EDWM',
    latitude: 53.0538902282715,
    longitude: 9.20861053466797,
    altitude: 59,
    schengen: true
  },
  {
    id: 2691,
    name: 'Osnabrück-Atterheide Airport',
    city: 'Osnabrueck',
    country: 'Germany',
    iata: '',
    icao: 'EDWO',
    latitude: 52.286388,
    longitude: 7.969722,
    altitude: 287,
    schengen: true
  },
  {
    id: 2692,
    name: 'Ganderkesee Atlas Airfield Airport',
    city: 'Ganderkesee',
    country: 'Germany',
    iata: '',
    icao: 'EDWQ',
    latitude: 53.0361099243164,
    longitude: 8.50555610656738,
    altitude: 95,
    schengen: true
  },
  {
    id: 2693,
    name: 'Borkum Airport',
    city: 'Borkum',
    country: 'Germany',
    iata: 'BMK',
    icao: 'EDWR',
    latitude: 53.59639,
    longitude: 6.709167,
    altitude: 3,
    schengen: true
  },
  {
    id: 2694,
    name: 'Norden-Norddeich Airport',
    city: 'Norden',
    country: 'Germany',
    iata: 'NOD',
    icao: 'EDWS',
    latitude: 53.6330566406,
    longitude: 7.19027805328,
    altitude: 3,
    schengen: true
  },
  {
    id: 2695,
    name: 'Verden-Scharnhorst Airfield',
    city: 'Verden',
    country: 'Germany',
    iata: '',
    icao: 'EDWV',
    latitude: 52.965279,
    longitude: 9.282778,
    altitude: 144,
    schengen: true
  },
  {
    id: 2696,
    name: 'Norderney Airport',
    city: 'Norderney',
    country: 'Germany',
    iata: 'NRD',
    icao: 'EDWY',
    latitude: 53.7069435119629,
    longitude: 7.23000001907349,
    altitude: 7,
    schengen: true
  },
  {
    id: 2697,
    name: 'Baltrum Airport',
    city: 'Baltrum',
    country: 'Germany',
    iata: 'BMR',
    icao: 'EDWZ',
    latitude: 53.724723815918,
    longitude: 7.37333297729492,
    altitude: 7,
    schengen: true
  },
  {
    id: 2698,
    name: 'Heide-Büsum Airport',
    city: 'Büsum',
    country: 'Germany',
    iata: 'HEI',
    icao: 'EDXB',
    latitude: 54.1533317566,
    longitude: 8.90166664124,
    altitude: 7,
    schengen: true
  },
  {
    id: 2699,
    name: 'Flensburg-Schäferhaus Airport',
    city: 'Flensburg',
    country: 'Germany',
    iata: 'FLF',
    icao: 'EDXF',
    latitude: 54.7733345031738,
    longitude: 9.37888908386231,
    altitude: 131,
    schengen: true
  },
  {
    id: 2700,
    name: 'Helgoland-Düne Airport',
    city: 'Helgoland',
    country: 'Germany',
    iata: 'HGL',
    icao: 'EDXH',
    latitude: 54.1852798462,
    longitude: 7.91583299637,
    altitude: 8,
    schengen: true
  },
  {
    id: 2701,
    name: 'Nienburg-Holzbalge Airport',
    city: 'Nienburg Weser',
    country: 'Germany',
    iata: '',
    icao: 'EDXI',
    latitude: 52.7097206115723,
    longitude: 9.16250038146973,
    altitude: 82,
    schengen: true
  },
  {
    id: 2702,
    name: 'St. Michaelisdonn Airport',
    city: 'Sankt Michaelisdonn',
    country: 'Germany',
    iata: '',
    icao: 'EDXM',
    latitude: 53.9780578613281,
    longitude: 9.14472198486328,
    altitude: 125,
    schengen: true
  },
  {
    id: 2703,
    name: 'Nordholz-Spieka Airfield',
    city: 'Cuxhaven',
    country: 'Germany',
    iata: '',
    icao: 'EDXN',
    latitude: 53.767223,
    longitude: 8.643611,
    altitude: 72,
    schengen: true
  },
  {
    id: 2704,
    name: 'St. Peter-Ording Airport',
    city: 'Sankt Peter-Ording',
    country: 'Germany',
    iata: 'PSH',
    icao: 'EDXO',
    latitude: 54.3088874816895,
    longitude: 8.68694400787354,
    altitude: 7,
    schengen: true
  },
  {
    id: 2705,
    name: 'Harle Airport',
    city: 'Harlesiel',
    country: 'Germany',
    iata: '',
    icao: 'EDXP',
    latitude: 53.7066688537598,
    longitude: 7.82027816772461,
    altitude: 7,
    schengen: true
  },
  {
    id: 2706,
    name: 'Rotenburg (Wümme) Airport',
    city: 'Rotenburg Wuemme',
    country: 'Germany',
    iata: '',
    icao: 'EDXQ',
    latitude: 53.1283340454102,
    longitude: 9.34861087799072,
    altitude: 98,
    schengen: true
  },
  {
    id: 2707,
    name: 'Rendsburg-Schachtholm Airport',
    city: 'Rendsburg',
    country: 'Germany',
    iata: '',
    icao: 'EDXR',
    latitude: 54.2200012207031,
    longitude: 9.59944438934326,
    altitude: 23,
    schengen: true
  },
  {
    id: 2708,
    name: 'Hüttenbusch Airport',
    city: 'Huettenbusch',
    country: 'Germany',
    iata: '',
    icao: 'EDXU',
    latitude: 53.2866668701172,
    longitude: 8.94722175598145,
    altitude: 10,
    schengen: true
  },
  {
    id: 2709,
    name: 'Westerland Sylt Airport',
    city: 'Westerland',
    country: 'Germany',
    iata: 'GWT',
    icao: 'EDXW',
    latitude: 54.9132003784,
    longitude: 8.34047031403,
    altitude: 51,
    schengen: true
  },
  {
    id: 2710,
    name: 'Skå-Edeby Airport',
    city: 'Essen',
    country: 'Germany',
    iata: '',
    icao: 'ESSE',
    latitude: 59.345100402832,
    longitude: 17.74049949646,
    altitude: 0,
    schengen: true
  },
  {
    id: 2711,
    name: 'Spangdahlem Air Base',
    city: 'Spangdahlem',
    country: 'Germany',
    iata: 'SPM',
    icao: 'ETAD',
    latitude: 49.9726982117,
    longitude: 6.69250011444,
    altitude: 1197,
    schengen: true
  },
  {
    id: 2712,
    name: 'Ramstein Air Base',
    city: 'Ramstein',
    country: 'Germany',
    iata: 'RMS',
    icao: 'ETAR',
    latitude: 49.4369010925293,
    longitude: 7.60027980804443,
    altitude: 776,
    schengen: true
  },
  {
    id: 2713,
    name: '[Duplicate] Giebelstadt Army Air Field',
    city: 'Giebelstadt',
    country: 'Germany',
    iata: 'GHF',
    icao: 'ETEU',
    latitude: 49.6481018066,
    longitude: 9.96648979187,
    altitude: 980,
    schengen: true
  },
  {
    id: 2714,
    name: 'Bückeburg Air Base',
    city: 'Brueckeburg',
    country: 'Germany',
    iata: '',
    icao: 'ETHB',
    latitude: 52.2785,
    longitude: 9.08217,
    altitude: 230,
    schengen: true
  },
  {
    id: 2715,
    name: 'Celle Airport',
    city: 'Celle',
    country: 'Germany',
    iata: 'ZCN',
    icao: 'ETHC',
    latitude: 52.5912017822266,
    longitude: 10.0221004486084,
    altitude: 129,
    schengen: true
  },
  {
    id: 2716,
    name: 'Rheine Bentlage Air Base',
    city: 'Rheine-brentlange',
    country: 'Germany',
    iata: '',
    icao: 'ETHE',
    latitude: 52.291199,
    longitude: 7.387,
    altitude: 129,
    schengen: true
  },
  {
    id: 2717,
    name: 'Fritzlar Airport',
    city: 'Fritzlar',
    country: 'Germany',
    iata: 'FRZ',
    icao: 'ETHF',
    latitude: 51.1146,
    longitude: 9.286,
    altitude: 1345,
    schengen: true
  },
  {
    id: 2718,
    name: 'Laupheim Air Base',
    city: 'Laupheim',
    country: 'Germany',
    iata: '',
    icao: 'ETHL',
    latitude: 48.220299,
    longitude: 9.91002,
    altitude: 1766,
    schengen: true
  },
  {
    id: 2719,
    name: 'Mendig Airport',
    city: 'Mendig',
    country: 'Germany',
    iata: '',
    icao: 'ETHM',
    latitude: 50.3660011292,
    longitude: 7.31533002853,
    altitude: 597,
    schengen: true
  },
  {
    id: 2720,
    name: 'Niederstetten Army Air Base',
    city: 'Niederstetten',
    country: 'Germany',
    iata: '',
    icao: 'ETHN',
    latitude: 49.391945,
    longitude: 9.958889,
    altitude: 1339,
    schengen: true
  },
  {
    id: 2721,
    name: 'Roth Airport',
    city: 'Roth',
    country: 'Germany',
    iata: '',
    icao: 'ETHR',
    latitude: 49.2174987792969,
    longitude: 11.1001996994019,
    altitude: 1268,
    schengen: true
  },
  {
    id: 2722,
    name: 'Fassberg Air Base',
    city: 'Fassberg',
    country: 'Germany',
    iata: '',
    icao: 'ETHS',
    latitude: 52.919399,
    longitude: 10.185827,
    altitude: 245,
    schengen: true
  },
  {
    id: 2723,
    name: 'Grafenwohr Army Air Field',
    city: 'Grafenwoehr',
    country: 'Germany',
    iata: '',
    icao: 'ETIC',
    latitude: 49.6986999511719,
    longitude: 11.9401998519897,
    altitude: 1363,
    schengen: true
  },
  {
    id: 2724,
    name: 'Hanau Army Air Field',
    city: 'Hanau',
    country: 'Germany',
    iata: 'ZNF',
    icao: 'ETID',
    latitude: 50.169201,
    longitude: 8.96159,
    altitude: 368,
    schengen: true
  },
  {
    id: 2725,
    name: 'Hohenfels Army Air Field',
    city: 'Hohenfels',
    country: 'Germany',
    iata: '',
    icao: 'ETIH',
    latitude: 49.2181015014648,
    longitude: 11.8360996246338,
    altitude: 1455,
    schengen: true
  },
  {
    id: 2726,
    name: 'Flugplatz Kitzingen',
    city: 'Kitzingen',
    country: 'Germany',
    iata: 'KZG',
    icao: 'ETIN',
    latitude: 49.743099,
    longitude: 10.2006,
    altitude: 689,
    schengen: true
  },
  {
    id: 2727,
    name: 'Nordholz Naval Airbase',
    city: 'Nordholz',
    country: 'Germany',
    iata: 'FCN',
    icao: 'ETMN',
    latitude: 53.7677001953,
    longitude: 8.65849971771,
    altitude: 74,
    schengen: true
  },
  {
    id: 2728,
    name: 'Diepholz Air Base',
    city: 'Diepholz',
    country: 'Germany',
    iata: '',
    icao: 'ETND',
    latitude: 52.585556,
    longitude: 8.342222,
    altitude: 128,
    schengen: true
  },
  {
    id: 2729,
    name: 'Geilenkirchen Air Base',
    city: 'Geilenkirchen',
    country: 'Germany',
    iata: 'GKE',
    icao: 'ETNG',
    latitude: 50.9608,
    longitude: 6.04242,
    altitude: 296,
    schengen: true
  },
  {
    id: 2730,
    name: 'Hohn Airport',
    city: 'Hohn',
    country: 'Germany',
    iata: '',
    icao: 'ETNH',
    latitude: 54.312198638916,
    longitude: 9.53816986083984,
    altitude: 39,
    schengen: true
  },
  {
    id: 2731,
    name: 'Jever Air Base',
    city: 'Jever',
    country: 'Germany',
    iata: '',
    icao: 'ETNJ',
    latitude: 53.533501,
    longitude: 7.88867,
    altitude: 24,
    schengen: true
  },
  {
    id: 2732,
    name: 'Rostock-Laage Airport',
    city: 'Laage',
    country: 'Germany',
    iata: 'RLG',
    icao: 'ETNL',
    latitude: 53.9182014465,
    longitude: 12.2783002853,
    altitude: 138,
    schengen: true
  },
  {
    id: 2733,
    name: 'Nörvenich Air Base',
    city: 'Noervenich',
    country: 'Germany',
    iata: '',
    icao: 'ETNN',
    latitude: 50.8312,
    longitude: 6.65817,
    altitude: 386,
    schengen: true
  },
  {
    id: 2734,
    name: 'Hopsten Air Base',
    city: 'Hopsten',
    country: 'Germany',
    iata: '',
    icao: 'ETNP',
    latitude: 52.3386993408203,
    longitude: 7.54132986068726,
    altitude: 423,
    schengen: true
  },
  {
    id: 2735,
    name: 'Schleswig Air Base',
    city: 'Schleswig',
    country: 'Germany',
    iata: 'WBG',
    icao: 'ETNS',
    latitude: 54.459301,
    longitude: 9.51633,
    altitude: 70,
    schengen: true
  },
  {
    id: 2736,
    name: 'Wittmundhafen Airport',
    city: 'Wittmundhafen',
    country: 'Germany',
    iata: '',
    icao: 'ETNT',
    latitude: 53.5477981567383,
    longitude: 7.66732978820801,
    altitude: 26,
    schengen: true
  },
  {
    id: 2737,
    name: 'Wunstorf Air Base',
    city: 'Wunstorf',
    country: 'Germany',
    iata: '',
    icao: 'ETNW',
    latitude: 52.457298,
    longitude: 9.42717,
    altitude: 187,
    schengen: true
  },
  {
    id: 2738,
    name: 'Vilseck Army Air Field',
    city: 'Vilseck',
    country: 'Germany',
    iata: '',
    icao: 'ETOI',
    latitude: 49.6335983276367,
    longitude: 11.7672004699707,
    altitude: 1353,
    schengen: true
  },
  {
    id: 2739,
    name: 'Coleman Army Air Field',
    city: 'Coleman',
    country: 'Germany',
    iata: '',
    icao: 'ETOR',
    latitude: 49.5635986328125,
    longitude: 8.4633903503418,
    altitude: 309,
    schengen: true
  },
  {
    id: 2740,
    name: 'Wiesbaden Army Airfield',
    city: 'Wiesbaden',
    country: 'Germany',
    iata: 'WIE',
    icao: 'ETOU',
    latitude: 50.0498008728027,
    longitude: 8.32540035247803,
    altitude: 461,
    schengen: true
  },
  {
    id: 2741,
    name: 'Landsberg Lech Air Base',
    city: 'Landsberg',
    country: 'Germany',
    iata: '',
    icao: 'ETSA',
    latitude: 48.070599,
    longitude: 10.906,
    altitude: 2044,
    schengen: true
  },
  {
    id: 2742,
    name: 'Büchel Air Base',
    city: 'Buechel',
    country: 'Germany',
    iata: '',
    icao: 'ETSB',
    latitude: 50.1738014221,
    longitude: 7.06333017349,
    altitude: 1568,
    schengen: true
  },
  {
    id: 2743,
    name: 'Erding Airport',
    city: 'Erding',
    country: 'Germany',
    iata: '',
    icao: 'ETSE',
    latitude: 48.3222999572754,
    longitude: 11.9486999511719,
    altitude: 1515,
    schengen: true
  },
  {
    id: 2744,
    name: 'Fürstenfeldbruck Air Base',
    city: 'Fuerstenfeldbruck',
    country: 'Germany',
    iata: 'FEL',
    icao: 'ETSF',
    latitude: 48.205555,
    longitude: 11.266944,
    altitude: 1703,
    schengen: true
  },
  {
    id: 2745,
    name: 'Holzdorf Air Base',
    city: 'Holzdorf',
    country: 'Germany',
    iata: '',
    icao: 'ETSH',
    latitude: 51.767799,
    longitude: 13.1677,
    altitude: 265,
    schengen: true
  },
  {
    id: 2746,
    name: 'Ingolstadt Manching Airport',
    city: 'Ingolstadt',
    country: 'Germany',
    iata: 'IGS',
    icao: 'ETSI',
    latitude: 48.7156982421875,
    longitude: 11.5340003967285,
    altitude: 1202,
    schengen: true
  },
  {
    id: 2747,
    name: 'Lechfeld Air Base',
    city: 'Lechfeld',
    country: 'Germany',
    iata: '',
    icao: 'ETSL',
    latitude: 48.185504,
    longitude: 10.8612,
    altitude: 1822,
    schengen: true
  },
  {
    id: 2748,
    name: 'Neuburg AFB',
    city: 'Neuburg',
    country: 'Germany',
    iata: '',
    icao: 'ETSN',
    latitude: 48.710999,
    longitude: 11.2115,
    altitude: 1249,
    schengen: true
  },
  {
    id: 2749,
    name: 'Gütersloh Air Base',
    city: 'Guetersloh',
    country: 'Germany',
    iata: 'GUT',
    icao: 'ETUO',
    latitude: 51.922798,
    longitude: 8.30633,
    altitude: 236,
    schengen: true
  },
  {
    id: 2750,
    name: 'Emporia Municipal Airport',
    city: 'Kempten',
    country: 'Germany',
    iata: 'EMP',
    icao: 'KEMP',
    latitude: 38.3320999146,
    longitude: -96.1912002563,
    altitude: 1208,
    schengen: true
  },
  {
    id: 2751,
    name: 'Hunt Field',
    city: 'Lindau',
    country: 'Germany',
    iata: 'LND',
    icao: 'KLND',
    latitude: 42.8152008057,
    longitude: -108.730003357,
    altitude: 5586,
    schengen: true
  },
  {
    id: 2752,
    name: 'Whiting Field Naval Air Station South Airport',
    city: 'Cuxhaven',
    country: 'Germany',
    iata: '',
    icao: 'KNDZ',
    latitude: 30.7043991088867,
    longitude: -87.0230026245117,
    altitude: 177,
    schengen: true
  },
  {
    id: 2753,
    name: 'Kotoka International Airport',
    city: 'Accra',
    country: 'Ghana',
    iata: 'ACC',
    icao: 'DGAA',
    latitude: 5.60518980026245,
    longitude: -0.166786000132561,
    altitude: 205,
    schengen: false
  },
  {
    id: 2754,
    name: 'Tamale Airport',
    city: 'Tamale',
    country: 'Ghana',
    iata: 'TML',
    icao: 'DGLE',
    latitude: 9.55718994140625,
    longitude: -0.863214015960693,
    altitude: 553,
    schengen: false
  },
  {
    id: 2755,
    name: 'Wa Airport',
    city: 'Wa',
    country: 'Ghana',
    iata: '',
    icao: 'DGLW',
    latitude: 10.0826997756958,
    longitude: -2.50768995285034,
    altitude: 1060,
    schengen: false
  },
  {
    id: 2756,
    name: 'Kumasi Airport',
    city: 'Kumasi',
    country: 'Ghana',
    iata: 'KMS',
    icao: 'DGSI',
    latitude: 6.71456003189087,
    longitude: -1.59081995487213,
    altitude: 942,
    schengen: false
  },
  {
    id: 2757,
    name: 'Sunyani Airport',
    city: 'Sunyani',
    country: 'Ghana',
    iata: 'NYI',
    icao: 'DGSN',
    latitude: 7.36183023452759,
    longitude: -2.32875990867615,
    altitude: 1014,
    schengen: false
  },
  {
    id: 2758,
    name: 'Takoradi Airport',
    city: 'Takoradi',
    country: 'Ghana',
    iata: 'TKD',
    icao: 'DGTK',
    latitude: 4.8960599899292,
    longitude: -1.77476000785828,
    altitude: 21,
    schengen: false
  },
  {
    id: 2759,
    name: 'Gibraltar Airport',
    city: 'Gibraltar',
    country: 'Gibraltar',
    iata: 'GIB',
    icao: 'LXGB',
    latitude: 36.1511993408,
    longitude: -5.34965991974,
    altitude: 15,
    schengen: false
  },
  {
    id: 2760,
    name: 'Andravida Air Base',
    city: 'Andravida',
    country: 'Greece',
    iata: 'PYR',
    icao: 'LGAD',
    latitude: 37.9207,
    longitude: 21.292601,
    altitude: 55,
    schengen: true
  },
  {
    id: 2761,
    name: 'Agrinion Air Base',
    city: 'Agrinion',
    country: 'Greece',
    iata: 'AGQ',
    icao: 'LGAG',
    latitude: 38.602001,
    longitude: 21.3512001,
    altitude: 154,
    schengen: true
  },
  {
    id: 2762,
    name: 'Dimokritos Airport',
    city: 'Alexandroupolis',
    country: 'Greece',
    iata: 'AXD',
    icao: 'LGAL',
    latitude: 40.855899810791,
    longitude: 25.9563007354736,
    altitude: 24,
    schengen: true
  },
  {
    id: 2763,
    name: 'Athen Helenikon Airport',
    city: 'Athens',
    country: 'Greece',
    iata: 'HEW',
    icao: 'LGAT',
    latitude: 37.893299,
    longitude: 23.726101,
    altitude: 69,
    schengen: true
  },
  {
    id: 2764,
    name: 'Eleftherios Venizelos International Airport',
    city: 'Athens',
    country: 'Greece',
    iata: 'ATH',
    icao: 'LGAV',
    latitude: 37.9364013672,
    longitude: 23.9444999695,
    altitude: 308,
    schengen: true
  },
  {
    id: 2765,
    name: 'Alexandria Airport',
    city: 'Alexandria',
    country: 'Greece',
    iata: '',
    icao: 'LGAX',
    latitude: 40.6511001586914,
    longitude: 22.4887008666992,
    altitude: 27,
    schengen: true
  },
  {
    id: 2766,
    name: 'Nea Anchialos Airport',
    city: 'Nea Anghialos',
    country: 'Greece',
    iata: 'VOL',
    icao: 'LGBL',
    latitude: 39.2196006774902,
    longitude: 22.7943000793457,
    altitude: 83,
    schengen: true
  },
  {
    id: 2767,
    name: 'Elefsis Airport',
    city: 'Elefsis',
    country: 'Greece',
    iata: '',
    icao: 'LGEL',
    latitude: 38.0638008117676,
    longitude: 23.5559997558594,
    altitude: 143,
    schengen: true
  },
  {
    id: 2768,
    name: 'Chios Island National Airport',
    city: 'Chios',
    country: 'Greece',
    iata: 'JKH',
    icao: 'LGHI',
    latitude: 38.3432006835937,
    longitude: 26.1406002044678,
    altitude: 15,
    schengen: true
  },
  {
    id: 2769,
    name: 'Porto Cheli Airport',
    city: 'Porto Heli',
    country: 'Greece',
    iata: 'PKH',
    icao: 'LGHL',
    latitude: 37.297501,
    longitude: 23.1478,
    altitude: 69,
    schengen: true
  },
  {
    id: 2770,
    name: 'Ikaria Airport',
    city: 'Ikaria',
    country: 'Greece',
    iata: 'JIK',
    icao: 'LGIK',
    latitude: 37.6827011108,
    longitude: 26.3470993042,
    altitude: 79,
    schengen: true
  },
  {
    id: 2771,
    name: 'Ioannina Airport',
    city: 'Ioannina',
    country: 'Greece',
    iata: 'IOA',
    icao: 'LGIO',
    latitude: 39.6963996887207,
    longitude: 20.8225002288818,
    altitude: 1558,
    schengen: true
  },
  {
    id: 2772,
    name: 'Heraklion International Nikos Kazantzakis Airport',
    city: 'Heraklion',
    country: 'Greece',
    iata: 'HER',
    icao: 'LGIR',
    latitude: 35.3396987915,
    longitude: 25.1802997589,
    altitude: 115,
    schengen: true
  },
  {
    id: 2773,
    name: 'Kastoria National Airport',
    city: 'Kastoria',
    country: 'Greece',
    iata: 'KSO',
    icao: 'LGKA',
    latitude: 40.4463005066,
    longitude: 21.2821998596,
    altitude: 2167,
    schengen: true
  },
  {
    id: 2774,
    name: 'Kithira Airport',
    city: 'Kithira',
    country: 'Greece',
    iata: 'KIT',
    icao: 'LGKC',
    latitude: 36.2742996216,
    longitude: 23.0170001984,
    altitude: 1045,
    schengen: true
  },
  {
    id: 2775,
    name: 'Kefallinia Airport',
    city: 'Keffallinia',
    country: 'Greece',
    iata: 'EFL',
    icao: 'LGKF',
    latitude: 38.1200981140137,
    longitude: 20.5004997253418,
    altitude: 59,
    schengen: true
  },
  {
    id: 2776,
    name: 'Kastelorizo Airport',
    city: 'Kastelorizo',
    country: 'Greece',
    iata: 'KZS',
    icao: 'LGKJ',
    latitude: 36.1417007446,
    longitude: 29.5764007568,
    altitude: 489,
    schengen: true
  },
  {
    id: 2777,
    name: 'Kalamata Airport',
    city: 'Kalamata',
    country: 'Greece',
    iata: 'KLX',
    icao: 'LGKL',
    latitude: 37.0682983398437,
    longitude: 22.0254993438721,
    altitude: 26,
    schengen: true
  },
  {
    id: 2778,
    name: 'Amigdhaleon Airport',
    city: 'Kavala',
    country: 'Greece',
    iata: '',
    icao: 'LGKM',
    latitude: 40.9728012084961,
    longitude: 24.3414001464844,
    altitude: 203,
    schengen: true
  },
  {
    id: 2779,
    name: 'Kos Airport',
    city: 'Kos',
    country: 'Greece',
    iata: 'KGS',
    icao: 'LGKO',
    latitude: 36.7933006286621,
    longitude: 27.0916996002197,
    altitude: 412,
    schengen: true
  },
  {
    id: 2780,
    name: 'Karpathos Airport',
    city: 'Karpathos',
    country: 'Greece',
    iata: 'AOK',
    icao: 'LGKP',
    latitude: 35.4213981628418,
    longitude: 27.1459999084473,
    altitude: 66,
    schengen: true
  },
  {
    id: 2781,
    name: 'Ioannis Kapodistrias International Airport',
    city: 'Kerkyra/corfu',
    country: 'Greece',
    iata: 'CFU',
    icao: 'LGKR',
    latitude: 39.6018981933594,
    longitude: 19.9116992950439,
    altitude: 6,
    schengen: true
  },
  {
    id: 2782,
    name: 'Kasos Airport',
    city: 'Kasos',
    country: 'Greece',
    iata: 'KSJ',
    icao: 'LGKS',
    latitude: 35.4213981628,
    longitude: 26.9099998474,
    altitude: 35,
    schengen: true
  },
  {
    id: 2783,
    name: 'Alexander the Great International Airport',
    city: 'Kavala',
    country: 'Greece',
    iata: 'KVA',
    icao: 'LGKV',
    latitude: 40.9132995605469,
    longitude: 24.6191997528076,
    altitude: 18,
    schengen: true
  },
  {
    id: 2784,
    name: 'Kalymnos Airport',
    city: 'Kalymnos',
    country: 'Greece',
    iata: 'JKL',
    icao: 'LGKY',
    latitude: 36.9632987976,
    longitude: 26.9405994415,
    altitude: 771,
    schengen: true
  },
  {
    id: 2785,
    name: 'Filippos Airport',
    city: 'Kozani',
    country: 'Greece',
    iata: 'KZI',
    icao: 'LGKZ',
    latitude: 40.2860984802246,
    longitude: 21.840799331665,
    altitude: 2059,
    schengen: true
  },
  {
    id: 2786,
    name: 'Leros Airport',
    city: 'Leros',
    country: 'Greece',
    iata: 'LRS',
    icao: 'LGLE',
    latitude: 37.184898,
    longitude: 26.800301,
    altitude: 39,
    schengen: true
  },
  {
    id: 2787,
    name: 'Limnos Airport',
    city: 'Limnos',
    country: 'Greece',
    iata: 'LXS',
    icao: 'LGLM',
    latitude: 39.917098999,
    longitude: 25.2362995148,
    altitude: 14,
    schengen: true
  },
  {
    id: 2788,
    name: 'Larisa Airport',
    city: 'Larissa',
    country: 'Greece',
    iata: 'LRA',
    icao: 'LGLR',
    latitude: 39.650253,
    longitude: 22.4655,
    altitude: 241,
    schengen: true
  },
  {
    id: 2789,
    name: 'Megara Airport',
    city: 'Megara',
    country: 'Greece',
    iata: '',
    icao: 'LGMG',
    latitude: 37.9810981750488,
    longitude: 23.3654003143311,
    altitude: 12,
    schengen: true
  },
  {
    id: 2790,
    name: 'Mikonos Airport',
    city: 'Mykonos',
    country: 'Greece',
    iata: 'JMK',
    icao: 'LGMK',
    latitude: 37.4351005554199,
    longitude: 25.3481006622314,
    altitude: 405,
    schengen: true
  },
  {
    id: 2791,
    name: 'Milos Airport',
    city: 'Milos',
    country: 'Greece',
    iata: 'MLO',
    icao: 'LGML',
    latitude: 36.696899,
    longitude: 24.4769,
    altitude: 10,
    schengen: true
  },
  {
    id: 2792,
    name: 'Mytilene International Airport',
    city: 'Mytilini',
    country: 'Greece',
    iata: 'MJT',
    icao: 'LGMT',
    latitude: 39.0567016602,
    longitude: 26.5983009338,
    altitude: 60,
    schengen: true
  },
  {
    id: 2793,
    name: 'Naxos Airport',
    city: 'Cyclades Islands',
    country: 'Greece',
    iata: 'JNX',
    icao: 'LGNX',
    latitude: 37.0811004639,
    longitude: 25.3680992126,
    altitude: 10,
    schengen: true
  },
  {
    id: 2794,
    name: 'Paros National Airport',
    city: 'Paros',
    country: 'Greece',
    iata: 'PAS',
    icao: 'LGPA',
    latitude: 37.020495,
    longitude: 25.113195,
    altitude: 131,
    schengen: true
  },
  {
    id: 2795,
    name: 'Astypalaia Airport',
    city: 'Astypalaia',
    country: 'Greece',
    iata: 'JTY',
    icao: 'LGPL',
    latitude: 36.5798988342,
    longitude: 26.3757991791,
    altitude: 165,
    schengen: true
  },
  {
    id: 2796,
    name: 'Aktion National Airport',
    city: 'Preveza',
    country: 'Greece',
    iata: 'PVK',
    icao: 'LGPZ',
    latitude: 38.9254989624023,
    longitude: 20.7653007507324,
    altitude: 11,
    schengen: true
  },
  {
    id: 2797,
    name: 'Maritsa Airport',
    city: 'Rhodos',
    country: 'Greece',
    iata: '',
    icao: 'LGRD',
    latitude: 36.3830986022949,
    longitude: 28.1089000701904,
    altitude: 204,
    schengen: true
  },
  {
    id: 2798,
    name: 'Diagoras Airport',
    city: 'Rhodos',
    country: 'Greece',
    iata: 'RHO',
    icao: 'LGRP',
    latitude: 36.4053993225098,
    longitude: 28.0862007141113,
    altitude: 17,
    schengen: true
  },
  {
    id: 2799,
    name: 'Araxos Airport',
    city: 'Patras',
    country: 'Greece',
    iata: 'GPA',
    icao: 'LGRX',
    latitude: 38.1511,
    longitude: 21.4256,
    altitude: 46,
    schengen: true
  },
  {
    id: 2800,
    name: 'Chania International Airport',
    city: 'Chania',
    country: 'Greece',
    iata: 'CHQ',
    icao: 'LGSA',
    latitude: 35.5317001342773,
    longitude: 24.1497001647949,
    altitude: 490,
    schengen: true
  },
  {
    id: 2801,
    name: 'Skiathos Island National Airport',
    city: 'Skiathos',
    country: 'Greece',
    iata: 'JSI',
    icao: 'LGSK',
    latitude: 39.1771011352539,
    longitude: 23.5037002563477,
    altitude: 54,
    schengen: true
  },
  {
    id: 2802,
    name: 'Samos Airport',
    city: 'Samos',
    country: 'Greece',
    iata: 'SMI',
    icao: 'LGSM',
    latitude: 37.689998626709,
    longitude: 26.9116992950439,
    altitude: 19,
    schengen: true
  },
  {
    id: 2803,
    name: 'Syros Airport',
    city: 'Syros Island',
    country: 'Greece',
    iata: 'JSY',
    icao: 'LGSO',
    latitude: 37.4227981567,
    longitude: 24.9508991241,
    altitude: 236,
    schengen: true
  },
  {
    id: 2804,
    name: 'Sparti Airport',
    city: 'Sparti',
    country: 'Greece',
    iata: 'SPJ',
    icao: 'LGSP',
    latitude: 36.9738998413086,
    longitude: 22.5263004302978,
    altitude: 500,
    schengen: true
  },
  {
    id: 2805,
    name: 'Santorini Airport',
    city: 'Thira',
    country: 'Greece',
    iata: 'JTR',
    icao: 'LGSR',
    latitude: 36.3992004394531,
    longitude: 25.4792995452881,
    altitude: 127,
    schengen: true
  },
  {
    id: 2806,
    name: 'Sitia Airport',
    city: 'Sitia',
    country: 'Greece',
    iata: 'JSH',
    icao: 'LGST',
    latitude: 35.2160987854004,
    longitude: 26.1012992858887,
    altitude: 376,
    schengen: true
  },
  {
    id: 2807,
    name: 'Stefanovikion Air Base',
    city: 'Stefanovikion',
    country: 'Greece',
    iata: '',
    icao: 'LGSV',
    latitude: 39.48,
    longitude: 22.7672,
    altitude: 146,
    schengen: true
  },
  {
    id: 2808,
    name: 'Skiros Airport',
    city: 'Skiros',
    country: 'Greece',
    iata: 'SKU',
    icao: 'LGSY',
    latitude: 38.9676017761,
    longitude: 24.4871997833,
    altitude: 44,
    schengen: true
  },
  {
    id: 2809,
    name: 'Tanagra Air Base',
    city: 'Tanagra',
    country: 'Greece',
    iata: '',
    icao: 'LGTG',
    latitude: 38.339802,
    longitude: 23.565001,
    altitude: 485,
    schengen: true
  },
  {
    id: 2810,
    name: 'Kasteli Airport',
    city: 'Kasteli',
    country: 'Greece',
    iata: '',
    icao: 'LGTL',
    latitude: 35.192001,
    longitude: 25.327,
    altitude: 1180,
    schengen: true
  },
  {
    id: 2811,
    name: 'Tripolis Airport',
    city: 'Tripolis',
    country: 'Greece',
    iata: '',
    icao: 'LGTP',
    latitude: 37.5306015014648,
    longitude: 22.403600692749,
    altitude: 2113,
    schengen: true
  },
  {
    id: 2812,
    name: 'Thessaloniki Macedonia International Airport',
    city: 'Thessaloniki',
    country: 'Greece',
    iata: 'SKG',
    icao: 'LGTS',
    latitude: 40.5196990966797,
    longitude: 22.9708995819092,
    altitude: 22,
    schengen: true
  },
  {
    id: 2813,
    name: 'Tatoi Airport',
    city: 'Dekelia',
    country: 'Greece',
    iata: '',
    icao: 'LGTT',
    latitude: 38.1088981628418,
    longitude: 23.7838001251221,
    altitude: 785,
    schengen: true
  },
  {
    id: 2814,
    name: 'Zakynthos International Airport "Dionysios Solomos"',
    city: 'Zakynthos',
    country: 'Greece',
    iata: 'ZTH',
    icao: 'LGZA',
    latitude: 37.7509,
    longitude: 20.8843,
    altitude: 15,
    schengen: true
  },
  {
    id: 2815,
    name: 'Aasiaat Airport',
    city: 'Aasiaat',
    country: 'Greenland',
    iata: 'JEG',
    icao: 'BGAA',
    latitude: 68.7218017578,
    longitude: -52.7846984863,
    altitude: 74,
    schengen: false
  },
  {
    id: 2816,
    name: 'Aappilattoq (Qaasuitsup) Heliport',
    city: 'Aappilattoq',
    country: 'Greenland',
    iata: '',
    icao: 'BGAG',
    latitude: 72.8870298223,
    longitude: -55.5962866545,
    altitude: 42,
    schengen: false
  },
  {
    id: 2817,
    name: 'Tasiilaq Heliport',
    city: 'Angmagssalik',
    country: 'Greenland',
    iata: '',
    icao: 'BGAM',
    latitude: 65.612296,
    longitude: -37.618335,
    altitude: 24,
    schengen: false
  },
  {
    id: 2818,
    name: 'Alluitsup Paa Heliport',
    city: 'Alluitsup Paa',
    country: 'Greenland',
    iata: 'LLU',
    icao: 'BGAP',
    latitude: 60.46445,
    longitude: -45.56917,
    altitude: 54,
    schengen: false
  },
  {
    id: 2819,
    name: 'Aappilattoq (Kujalleq) Heliport',
    city: 'Aappilattoq',
    country: 'Greenland',
    iata: '',
    icao: 'BGAQ',
    latitude: 60.148357,
    longitude: -44.286916,
    altitude: 30,
    schengen: false
  },
  {
    id: 2820,
    name: 'Ammassivik Heliport',
    city: 'Ammassivik',
    country: 'Greenland',
    iata: '',
    icao: 'BGAS',
    latitude: 60.597376,
    longitude: -45.382445,
    altitude: 71,
    schengen: false
  },
  {
    id: 2821,
    name: 'Attu Heliport',
    city: 'Attu',
    country: 'Greenland',
    iata: 'QGQ',
    icao: 'BGAT',
    latitude: 67.9406628551,
    longitude: -53.6218696833,
    altitude: 32,
    schengen: false
  },
  {
    id: 2822,
    name: 'Narsarsuaq Airport',
    city: 'Narssarssuaq',
    country: 'Greenland',
    iata: 'UAK',
    icao: 'BGBW',
    latitude: 61.1604995728,
    longitude: -45.4259986877,
    altitude: 112,
    schengen: false
  },
  {
    id: 2823,
    name: 'Qasigiannguit Heliport',
    city: 'Qasigiannguit',
    country: 'Greenland',
    iata: 'JCH',
    icao: 'BGCH',
    latitude: 68.822815547,
    longitude: -51.1734473705,
    altitude: 70,
    schengen: false
  },
  {
    id: 2824,
    name: 'Neerlerit Inaat Airport',
    city: 'Neerlerit Inaat',
    country: 'Greenland',
    iata: 'CNP',
    icao: 'BGCO',
    latitude: 70.7431030273,
    longitude: -22.6504993439,
    altitude: 45,
    schengen: false
  },
  {
    id: 2825,
    name: 'Eqalugaarsuit Heliport',
    city: 'Eqalugaarsuit',
    country: 'Greenland',
    iata: 'QFG',
    icao: 'BGET',
    latitude: 60.6197196536,
    longitude: -45.9140619636,
    altitude: 9,
    schengen: false
  },
  {
    id: 2826,
    name: 'Narsaq Kujalleq Heliport',
    city: 'Narsaq Kujalleq',
    country: 'Greenland',
    iata: '',
    icao: 'BGFD',
    latitude: 60.004694,
    longitude: -44.656935,
    altitude: 44,
    schengen: false
  },
  {
    id: 2827,
    name: 'Paamiut Heliport',
    city: 'Paamiut',
    country: 'Greenland',
    iata: 'JFR',
    icao: 'BGFH',
    latitude: 61.9921989441,
    longitude: -49.6624984741,
    altitude: 63,
    schengen: false
  },
  {
    id: 2828,
    name: 'Godthaab / Nuuk Airport',
    city: 'Godthaab',
    country: 'Greenland',
    iata: 'GOH',
    icao: 'BGGH',
    latitude: 64.19090271,
    longitude: -51.6781005859,
    altitude: 283,
    schengen: false
  },
  {
    id: 2829,
    name: 'Qeqertarsuaq Heliport',
    city: 'Qeqertarsuaq Airport',
    country: 'Greenland',
    iata: 'JGO',
    icao: 'BGGN',
    latitude: 69.251181993,
    longitude: -53.5148763657,
    altitude: 9,
    schengen: false
  },
  {
    id: 2830,
    name: 'Ikerasak Heliport',
    city: 'Ikerasak',
    country: 'Greenland',
    iata: '',
    icao: 'BGIA',
    latitude: 70.4981453548,
    longitude: -51.3030838966,
    altitude: 66,
    schengen: false
  },
  {
    id: 2831,
    name: 'Ikerassaarsuk Heliport',
    city: 'Ikerasaarsuk',
    country: 'Greenland',
    iata: 'QRY',
    icao: 'BGIK',
    latitude: 68.1408810063,
    longitude: -53.4414589405,
    altitude: 165,
    schengen: false
  },
  {
    id: 2832,
    name: 'Ilimanaq Heliport',
    city: 'Ilimanaq',
    country: 'Greenland',
    iata: 'XIQ',
    icao: 'BGIL',
    latitude: 69.0809150007,
    longitude: -51.1143153906,
    altitude: 52,
    schengen: false
  },
  {
    id: 2833,
    name: 'Innarsuit Heliport',
    city: 'Innaarsuit',
    country: 'Greenland',
    iata: '',
    icao: 'BGIN',
    latitude: 73.1998953377,
    longitude: -56.0108166933,
    altitude: 95,
    schengen: false
  },
  {
    id: 2834,
    name: 'Isortoq Heliport',
    city: 'Isortoq',
    country: 'Greenland',
    iata: '',
    icao: 'BGIS',
    latitude: 65.5477923903,
    longitude: -38.9765524864,
    altitude: 92,
    schengen: false
  },
  {
    id: 2835,
    name: 'Ikamiut Heliport',
    city: 'Ikamiut',
    country: 'Greenland',
    iata: 'QJI',
    icao: 'BGIT',
    latitude: 68.6342530984,
    longitude: -51.8322622776,
    altitude: 49,
    schengen: false
  },
  {
    id: 2836,
    name: 'Qaqortoq Heliport',
    city: 'Qaqortoq',
    country: 'Greenland',
    iata: 'JJU',
    icao: 'BGJH',
    latitude: 60.7156841553,
    longitude: -46.0299186409,
    altitude: 53,
    schengen: false
  },
  {
    id: 2837,
    name: 'Ilulissat Airport',
    city: 'Ilulissat',
    country: 'Greenland',
    iata: 'JAV',
    icao: 'BGJN',
    latitude: 69.2432022095,
    longitude: -51.0570983887,
    altitude: 95,
    schengen: false
  },
  {
    id: 2838,
    name: 'Kangaatsiaq Heliport',
    city: 'Kangaatsiaq',
    country: 'Greenland',
    iata: 'QPW',
    icao: 'BGKA',
    latitude: 68.3126574861,
    longitude: -53.4602075815,
    altitude: 17,
    schengen: false
  },
  {
    id: 2839,
    name: 'Upernavik Kujalleq Heliport',
    city: 'Upernavik Kujalleq',
    country: 'Greenland',
    iata: '',
    icao: 'BGKL',
    latitude: 72.1527425265,
    longitude: -55.5309855938,
    altitude: 92,
    schengen: false
  },
  {
    id: 2840,
    name: 'Kuummiut Heliport',
    city: 'Kuummiit',
    country: 'Greenland',
    iata: '',
    icao: 'BGKM',
    latitude: 65.8639347361,
    longitude: -36.9979190826,
    altitude: 89,
    schengen: false
  },
  {
    id: 2841,
    name: 'Kullorsuaq Heliport',
    city: 'Kullorsuaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGKQ',
    latitude: 74.5780498288,
    longitude: -57.2268283367,
    altitude: 148,
    schengen: false
  },
  {
    id: 2842,
    name: 'Kangersuatsiaq Heliport',
    city: 'Kangersuatsiaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGKS',
    latitude: 72.3810915507,
    longitude: -55.5365860462,
    altitude: 112,
    schengen: false
  },
  {
    id: 2843,
    name: 'Kitsissuarsuit Heliport',
    city: 'Kitsissuarsuit',
    country: 'Greenland',
    iata: 'QJE',
    icao: 'BGKT',
    latitude: 68.8579259976,
    longitude: -53.1232953072,
    altitude: 18,
    schengen: false
  },
  {
    id: 2844,
    name: 'Illorsuit Heliport',
    city: 'Illorsuit',
    country: 'Greenland',
    iata: '',
    icao: 'BGLL',
    latitude: 71.2419440428,
    longitude: -53.5628771782,
    altitude: 154,
    schengen: false
  },
  {
    id: 2845,
    name: 'Maniitsoq Airport',
    city: 'Maniitsoq',
    country: 'Greenland',
    iata: 'JSU',
    icao: 'BGMQ',
    latitude: 65.4124984741,
    longitude: -52.9393997192,
    altitude: 91,
    schengen: false
  },
  {
    id: 2846,
    name: 'Niaqornaarsuk Heliport',
    city: 'Niaqornaarsuk',
    country: 'Greenland',
    iata: '',
    icao: 'BGNK',
    latitude: 68.236381,
    longitude: -52.852148,
    altitude: 152,
    schengen: false
  },
  {
    id: 2847,
    name: 'Nanortalik Heliport',
    city: 'Nanortalik',
    country: 'Greenland',
    iata: 'JNN',
    icao: 'BGNN',
    latitude: 60.1418839759,
    longitude: -45.232976675,
    altitude: 17,
    schengen: false
  },
  {
    id: 2848,
    name: 'Nuugaatsiaq Heliport',
    city: 'Nuugaatsiaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGNQ',
    latitude: 71.5387687528,
    longitude: -53.2050383091,
    altitude: 57,
    schengen: false
  },
  {
    id: 2849,
    name: 'Narsaq Heliport',
    city: 'Narsaq',
    country: 'Greenland',
    iata: 'JNS',
    icao: 'BGNS',
    latitude: 60.9172827256,
    longitude: -46.059923172,
    altitude: 83,
    schengen: false
  },
  {
    id: 2850,
    name: 'Niaqornat Heliport',
    city: 'Niaqornat',
    country: 'Greenland',
    iata: '',
    icao: 'BGNT',
    latitude: 70.7893846882,
    longitude: -53.6629450321,
    altitude: 10,
    schengen: false
  },
  {
    id: 2851,
    name: 'Nuussuaq Heliport',
    city: 'Nuussuaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGNU',
    latitude: 74.1098528514,
    longitude: -57.0650374889,
    altitude: 184,
    schengen: false
  },
  {
    id: 2852,
    name: 'Qeqertaq Heliport',
    city: 'Qeqertaq',
    country: 'Greenland',
    iata: 'QQT',
    icao: 'BGQE',
    latitude: 69.9958611087,
    longitude: -51.3009166718,
    altitude: 69,
    schengen: false
  },
  {
    id: 2853,
    name: 'Qaanaaq Airport',
    city: 'Qaanaaq',
    country: 'Greenland',
    iata: 'NAQ',
    icao: 'BGQQ',
    latitude: 77.4886016846,
    longitude: -69.3887023926,
    altitude: 51,
    schengen: false
  },
  {
    id: 2854,
    name: 'Qassimiut Heliport',
    city: 'Qassimiut',
    country: 'Greenland',
    iata: '',
    icao: 'BGQT',
    latitude: 60.7809592284,
    longitude: -47.1562492847,
    altitude: 29,
    schengen: false
  },
  {
    id: 2855,
    name: 'Ittoqqortoormiit Heliport',
    city: 'Ittoqqortoormiit',
    country: 'Greenland',
    iata: 'OBY',
    icao: 'BGSC',
    latitude: 70.4882288244,
    longitude: -21.9716799259,
    altitude: 238,
    schengen: false
  },
  {
    id: 2856,
    name: 'Kangerlussuaq Airport',
    city: 'Sondrestrom',
    country: 'Greenland',
    iata: 'SFJ',
    icao: 'BGSF',
    latitude: 67.0122218992,
    longitude: -50.7116031647,
    altitude: 165,
    schengen: false
  },
  {
    id: 2857,
    name: 'Sermiligaaq Heliport',
    city: 'Sermiligaaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGSG',
    latitude: 65.9059197626,
    longitude: -36.3782536983,
    altitude: 33,
    schengen: false
  },
  {
    id: 2858,
    name: 'Siorapaluk Heliport',
    city: 'Siorapaluk',
    country: 'Greenland',
    iata: '',
    icao: 'BGSI',
    latitude: 77.7865173475,
    longitude: -70.6386566162,
    altitude: 102,
    schengen: false
  },
  {
    id: 2859,
    name: 'Saqqaq Heliport',
    city: 'Saqqaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGSQ',
    latitude: 70.011448,
    longitude: -51.932142,
    altitude: 38,
    schengen: false
  },
  {
    id: 2860,
    name: 'Sisimiut Airport',
    city: 'Sisimiut',
    country: 'Greenland',
    iata: 'JHS',
    icao: 'BGSS',
    latitude: 66.9513015747,
    longitude: -53.7293014526,
    altitude: 33,
    schengen: false
  },
  {
    id: 2861,
    name: 'Saattut Heliport',
    city: 'Saattut',
    country: 'Greenland',
    iata: '',
    icao: 'BGST',
    latitude: 70.8111719824,
    longitude: -51.6312897205,
    altitude: 131,
    schengen: false
  },
  {
    id: 2862,
    name: 'Savissivik Heliport',
    city: 'Savissivik',
    country: 'Greenland',
    iata: '',
    icao: 'BGSV',
    latitude: 76.0186134646,
    longitude: -65.1176834106,
    altitude: 24,
    schengen: false
  },
  {
    id: 2863,
    name: 'Tasiusaq (Qaasuitsup) Heliport',
    city: 'Tasiusaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGTA',
    latitude: 73.3730545044,
    longitude: -56.0602798462,
    altitude: 181,
    schengen: false
  },
  {
    id: 2864,
    name: 'Thule Air Base',
    city: 'Thule',
    country: 'Greenland',
    iata: 'THU',
    icao: 'BGTL',
    latitude: 76.5311965942,
    longitude: -68.7032012939,
    altitude: 251,
    schengen: false
  },
  {
    id: 2865,
    name: 'Tiniteqilaaq Heliport',
    city: 'Tiniteqilaaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGTN',
    latitude: 65.8920273392,
    longitude: -37.7834093571,
    altitude: 15,
    schengen: false
  },
  {
    id: 2866,
    name: 'Tasiusaq (Kujalleq) Heliport',
    city: 'Tasiusaq',
    country: 'Greenland',
    iata: '',
    icao: 'BGTQ',
    latitude: 60.1943053097,
    longitude: -44.8134469986,
    altitude: 21,
    schengen: false
  },
  {
    id: 2867,
    name: 'Upernavik Airport',
    city: 'Upernavik',
    country: 'Greenland',
    iata: 'JUV',
    icao: 'BGUK',
    latitude: 72.7901992798,
    longitude: -56.1305999756,
    altitude: 414,
    schengen: false
  },
  {
    id: 2868,
    name: 'Uummannaq Heliport',
    city: 'Uummannaq',
    country: 'Greenland',
    iata: 'UMD',
    icao: 'BGUM',
    latitude: 70.6804279261,
    longitude: -52.1116304398,
    altitude: 50,
    schengen: false
  },
  {
    id: 2869,
    name: 'Qaarsut Airport',
    city: 'Uummannaq',
    country: 'Greenland',
    iata: 'JQA',
    icao: 'BGUQ',
    latitude: 70.7341995239,
    longitude: -52.6962013245,
    altitude: 289,
    schengen: false
  },
  {
    id: 2870,
    name: 'Ukkusissat Heliport',
    city: 'Ukkusissat',
    country: 'Greenland',
    iata: '',
    icao: 'BGUT',
    latitude: 71.0494384946,
    longitude: -51.8900156021,
    altitude: 223,
    schengen: false
  },
  {
    id: 2871,
    name: 'Point Salines International Airport',
    city: 'Point Salines',
    country: 'Grenada',
    iata: 'GND',
    icao: 'TGPY',
    latitude: 12.0041999816895,
    longitude: -61.7862014770508,
    altitude: 41,
    schengen: false
  },
  {
    id: 2872,
    name: 'La Désirade Airport',
    city: 'Grande Anse',
    country: 'Guadeloupe',
    iata: 'DSD',
    icao: 'TFFA',
    latitude: 16.296902,
    longitude: -61.0844,
    altitude: 10,
    schengen: false
  },
  {
    id: 2873,
    name: 'Baillif Airport',
    city: 'Basse Terre',
    country: 'Guadeloupe',
    iata: 'BBR',
    icao: 'TFFB',
    latitude: 16.0132999420166,
    longitude: -61.7421989440918,
    altitude: 59,
    schengen: false
  },
  {
    id: 2874,
    name: 'St-François Airport',
    city: 'St-François',
    country: 'Guadeloupe',
    iata: 'SFC',
    icao: 'TFFC',
    latitude: 16.2577991485596,
    longitude: -61.2625007629395,
    altitude: 10,
    schengen: false
  },
  {
    id: 2875,
    name: "L'Espérance Airport",
    city: 'St. Martin',
    country: 'Guadeloupe',
    iata: 'SFG',
    icao: 'TFFG',
    latitude: 18.099899291992,
    longitude: -63.047199249268,
    altitude: 7,
    schengen: false
  },
  {
    id: 2876,
    name: 'Les Bases Airport',
    city: 'Grand Bourg',
    country: 'Guadeloupe',
    iata: 'GBJ',
    icao: 'TFFM',
    latitude: 15.8687000274658,
    longitude: -61.2700004577637,
    altitude: 16,
    schengen: false
  },
  {
    id: 2877,
    name: 'Pointe-à-Pitre Le Raizet',
    city: 'Pointe-a-pitre',
    country: 'Guadeloupe',
    iata: 'PTP',
    icao: 'TFFR',
    latitude: 16.265301,
    longitude: -61.531799,
    altitude: 36,
    schengen: false
  },
  {
    id: 2878,
    name: 'Terre-de-Haut Airport',
    city: 'Les Saintes',
    country: 'Guadeloupe',
    iata: 'LSS',
    icao: 'TFFS',
    latitude: 15.86439991,
    longitude: -61.5806007385,
    altitude: 46,
    schengen: false
  },
  {
    id: 2879,
    name: 'Andersen Air Force Base',
    city: 'Andersen',
    country: 'Guam',
    iata: 'UAM',
    icao: 'PGUA',
    latitude: 13.584,
    longitude: 144.929998,
    altitude: 627,
    schengen: false
  },
  {
    id: 2880,
    name: 'Antonio B. Won Pat International Airport',
    city: 'Agana',
    country: 'Guam',
    iata: 'GUM',
    icao: 'PGUM',
    latitude: 13.4834003448,
    longitude: 144.796005249,
    altitude: 298,
    schengen: false
  },
  {
    id: 2881,
    name: 'Bananera Airport',
    city: 'Bananera',
    country: 'Guatemala',
    iata: '',
    icao: 'MGBN',
    latitude: 15.47350025177,
    longitude: -88.8371963500977,
    altitude: 130,
    schengen: false
  },
  {
    id: 2882,
    name: 'Coban Airport',
    city: 'Coban',
    country: 'Guatemala',
    iata: 'CBV',
    icao: 'MGCB',
    latitude: 15.4689998626709,
    longitude: -90.4067001342773,
    altitude: 4339,
    schengen: false
  },
  {
    id: 2883,
    name: 'La Aurora Airport',
    city: 'Guatemala City',
    country: 'Guatemala',
    iata: 'GUA',
    icao: 'MGGT',
    latitude: 14.5832996368408,
    longitude: -90.5274963378906,
    altitude: 4952,
    schengen: false
  },
  {
    id: 2884,
    name: 'Puerto Barrios Airport',
    city: 'Puerto Barrios',
    country: 'Guatemala',
    iata: 'PBR',
    icao: 'MGPB',
    latitude: 15.7309,
    longitude: -88.583801,
    altitude: 33,
    schengen: false
  },
  {
    id: 2885,
    name: 'Santa Cruz del Quiche Airport',
    city: 'Santa Cruz des Quiche',
    country: 'Guatemala',
    iata: 'AQB',
    icao: 'MGQC',
    latitude: 15.0122003555298,
    longitude: -91.1505966186524,
    altitude: 6631,
    schengen: false
  },
  {
    id: 2886,
    name: 'Quezaltenango Airport',
    city: 'Quezaltenango',
    country: 'Guatemala',
    iata: 'AAZ',
    icao: 'MGQZ',
    latitude: 14.8656,
    longitude: -91.501999,
    altitude: 7779,
    schengen: false
  },
  {
    id: 2887,
    name: 'Rubelsanto Airport',
    city: 'Rubelsanto',
    country: 'Guatemala',
    iata: 'RUV',
    icao: 'MGRB',
    latitude: 15.9919996261597,
    longitude: -90.4452972412109,
    altitude: 426,
    schengen: false
  },
  {
    id: 2888,
    name: 'Retalhuleu Airport',
    city: 'Retalhuleu',
    country: 'Guatemala',
    iata: 'RER',
    icao: 'MGRT',
    latitude: 14.5209999084473,
    longitude: -91.6973037719727,
    altitude: 656,
    schengen: false
  },
  {
    id: 2889,
    name: 'San José Airport',
    city: 'San Jose',
    country: 'Guatemala',
    iata: 'GSJ',
    icao: 'MGSJ',
    latitude: 13.9362001419,
    longitude: -90.8358001709,
    altitude: 29,
    schengen: false
  },
  {
    id: 2890,
    name: 'Mundo Maya International Airport',
    city: 'Flores',
    country: 'Guatemala',
    iata: 'FRS',
    icao: 'MGTK',
    latitude: 16.9137992859,
    longitude: -89.8664016724,
    altitude: 427,
    schengen: false
  },
  {
    id: 2891,
    name: 'Alderney Airport',
    city: 'Alderney',
    country: 'Guernsey',
    iata: 'ACI',
    icao: 'EGJA',
    latitude: 49.706104,
    longitude: -2.21472,
    altitude: 290,
    schengen: false
  },
  {
    id: 2892,
    name: 'Guernsey Airport',
    city: 'Guernsey',
    country: 'Guernsey',
    iata: 'GCI',
    icao: 'EGJB',
    latitude: 49.435001373291,
    longitude: -2.60196995735168,
    altitude: 336,
    schengen: false
  },
  {
    id: 2893,
    name: 'Conakry International Airport',
    city: 'Conakry',
    country: 'Guinea',
    iata: 'CKY',
    icao: 'GUCY',
    latitude: 9.57689,
    longitude: -13.612,
    altitude: 72,
    schengen: false
  },
  {
    id: 2894,
    name: 'Fria Airport',
    city: 'Fira',
    country: 'Guinea',
    iata: 'FIG',
    icao: 'GUFA',
    latitude: 10.350600242615,
    longitude: -13.569199562073,
    altitude: 499,
    schengen: false
  },
  {
    id: 2895,
    name: 'Faranah Airport',
    city: 'Faranah',
    country: 'Guinea',
    iata: 'FAA',
    icao: 'GUFH',
    latitude: 10.0354995728,
    longitude: -10.7698001862,
    altitude: 1476,
    schengen: false
  },
  {
    id: 2896,
    name: 'Kawass Airport',
    city: 'Kamsar',
    country: 'Guinea',
    iata: '',
    icao: 'GUKR',
    latitude: 10.651000022888,
    longitude: -14.533599853516,
    altitude: 36,
    schengen: false
  },
  {
    id: 2897,
    name: 'Kissidougou Airport',
    city: 'Kissidougou',
    country: 'Guinea',
    iata: 'KSI',
    icao: 'GUKU',
    latitude: 9.1605596542358,
    longitude: -10.124400138855,
    altitude: 1808,
    schengen: false
  },
  {
    id: 2898,
    name: 'Tata Airport',
    city: 'Labe',
    country: 'Guinea',
    iata: 'LEK',
    icao: 'GULB',
    latitude: 11.326100349426,
    longitude: -12.286800384521,
    altitude: 3396,
    schengen: false
  },
  {
    id: 2899,
    name: 'Nzérékoré Airport',
    city: 'Nzerekore',
    country: 'Guinea',
    iata: 'NZE',
    icao: 'GUNZ',
    latitude: 7.8060193061829,
    longitude: -8.7017974853516,
    altitude: 1657,
    schengen: false
  },
  {
    id: 2900,
    name: 'Siguiri Airport',
    city: 'Siguiri',
    country: 'Guinea',
    iata: 'GII',
    icao: 'GUSI',
    latitude: 11.432999610901,
    longitude: -9.1669998168945,
    altitude: 1296,
    schengen: false
  },
  {
    id: 2901,
    name: 'Bubaque Airport',
    city: 'Bubaque',
    country: 'Guinea-Bissau',
    iata: 'BQE',
    icao: 'GGBU',
    latitude: 11.2973556518555,
    longitude: -15.8380794525146,
    altitude: 0,
    schengen: false
  },
  {
    id: 2902,
    name: 'Cufar Airport',
    city: 'Cufar',
    country: 'Guinea-Bissau',
    iata: '',
    icao: 'GGCF',
    latitude: 11.2878999710083,
    longitude: -15.1805000305176,
    altitude: 65,
    schengen: false
  },
  {
    id: 2903,
    name: 'Osvaldo Vieira International Airport',
    city: 'Bissau',
    country: 'Guinea-Bissau',
    iata: 'OXB',
    icao: 'GGOV',
    latitude: 11.8948001861572,
    longitude: -15.6536998748779,
    altitude: 129,
    schengen: false
  },
  {
    id: 2904,
    name: 'Kaieteur International Airport',
    city: 'Kaieteur',
    country: 'Guyana',
    iata: 'KAI',
    icao: 'PKSA',
    latitude: 5.17275476456,
    longitude: -59.491481781,
    altitude: 1520,
    schengen: false
  },
  {
    id: 2905,
    name: 'Annai Airport',
    city: 'Annai',
    country: 'Guyana',
    iata: 'NAI',
    icao: 'SYAN',
    latitude: 3.95943999290466,
    longitude: -59.1241989135742,
    altitude: 301,
    schengen: false
  },
  {
    id: 2906,
    name: 'Apoteri Airport',
    city: 'Apoteri',
    country: 'Guyana',
    iata: '',
    icao: 'SYAP',
    latitude: 4.01387977600098,
    longitude: -58.6049995422363,
    altitude: 301,
    schengen: false
  },
  {
    id: 2907,
    name: 'Bartica A Airport',
    city: 'Bartica',
    country: 'Guyana',
    iata: 'GFO',
    icao: 'SYBT',
    latitude: 6.358864,
    longitude: -58.655207,
    altitude: 3,
    schengen: false
  },
  {
    id: 2908,
    name: 'Cheddi Jagan International Airport',
    city: 'Georgetown',
    country: 'Guyana',
    iata: 'GEO',
    icao: 'SYCJ',
    latitude: 6.4985499382019,
    longitude: -58.2541007995606,
    altitude: 95,
    schengen: false
  },
  {
    id: 2909,
    name: 'Eugene F. Correira International Airport',
    city: 'Georgetown',
    country: 'Guyana',
    iata: 'OGL',
    icao: 'SYGO',
    latitude: 6.80628,
    longitude: -58.1059,
    altitude: 10,
    schengen: false
  },
  {
    id: 2910,
    name: 'Imbaimadai Airport',
    city: 'Imbaimadai',
    country: 'Guyana',
    iata: 'IMB',
    icao: 'SYIB',
    latitude: 5.70810985565186,
    longitude: -60.2942008972168,
    altitude: 1646,
    schengen: false
  },
  {
    id: 2911,
    name: 'Kamarang Airport',
    city: 'Kamarang',
    country: 'Guyana',
    iata: 'KAR',
    icao: 'SYKM',
    latitude: 5.86534023284912,
    longitude: -60.614200592041,
    altitude: 1601,
    schengen: false
  },
  {
    id: 2912,
    name: 'Linden Airport',
    city: 'Linden',
    country: 'Guyana',
    iata: '',
    icao: 'SYLD',
    latitude: 5.96592,
    longitude: -58.270302,
    altitude: 180,
    schengen: false
  },
  {
    id: 2913,
    name: 'Lethem Airport',
    city: 'Lethem',
    country: 'Guyana',
    iata: 'LTM',
    icao: 'SYLT',
    latitude: 3.37276,
    longitude: -59.789398,
    altitude: 351,
    schengen: false
  },
  {
    id: 2914,
    name: 'Mabaruma Airport',
    city: 'Mabaruma',
    country: 'Guyana',
    iata: 'USI',
    icao: 'SYMB',
    latitude: 8.19999980926514,
    longitude: -59.7832984924316,
    altitude: 45,
    schengen: false
  },
  {
    id: 2915,
    name: 'Mahdia Airport',
    city: 'Mahdia',
    country: 'Guyana',
    iata: 'MHA',
    icao: 'SYMD',
    latitude: 5.27749013900757,
    longitude: -59.1511001586914,
    altitude: 300,
    schengen: false
  },
  {
    id: 2916,
    name: 'Orinduik Airport',
    city: 'Orinduik',
    country: 'Guyana',
    iata: 'ORJ',
    icao: 'SYOR',
    latitude: 4.72526979446411,
    longitude: -60.0349998474121,
    altitude: 1797,
    schengen: false
  },
  {
    id: 2917,
    name: 'Les Cayes Airport',
    city: 'Cayes',
    country: 'Haiti',
    iata: 'CYA',
    icao: 'MTCA',
    latitude: 18.2710990905762,
    longitude: -73.7882995605469,
    altitude: 203,
    schengen: false
  },
  {
    id: 2918,
    name: 'Cap Haitien International Airport',
    city: 'Cap Haitien',
    country: 'Haiti',
    iata: 'CAP',
    icao: 'MTCH',
    latitude: 19.733,
    longitude: -72.194702,
    altitude: 10,
    schengen: false
  },
  {
    id: 2919,
    name: 'Jacmel Airport',
    city: 'Jacmel',
    country: 'Haiti',
    iata: 'JAK',
    icao: 'MTJA',
    latitude: 18.2411003112793,
    longitude: -72.5185012817383,
    altitude: 167,
    schengen: false
  },
  {
    id: 2920,
    name: 'Jérémie Airport',
    city: 'Jeremie',
    country: 'Haiti',
    iata: 'JEE',
    icao: 'MTJE',
    latitude: 18.6630992889404,
    longitude: -74.1703033447266,
    altitude: 147,
    schengen: false
  },
  {
    id: 2921,
    name: 'Toussaint Louverture International Airport',
    city: 'Port-au-prince',
    country: 'Haiti',
    iata: 'PAP',
    icao: 'MTPP',
    latitude: 18.5799999237061,
    longitude: -72.2925033569336,
    altitude: 122,
    schengen: false
  },
  {
    id: 2922,
    name: 'Port-de-Paix Airport',
    city: 'Port-de-Paix',
    country: 'Haiti',
    iata: 'PAX',
    icao: 'MTPX',
    latitude: 19.9335994720459,
    longitude: -72.8486022949219,
    altitude: 9,
    schengen: false
  },
  {
    id: 2923,
    name: 'Ahuas Airport',
    city: 'Ahuas',
    country: 'Honduras',
    iata: 'AHS',
    icao: 'MHAH',
    latitude: 15.4722,
    longitude: -84.352203,
    altitude: 249,
    schengen: false
  },
  {
    id: 2924,
    name: 'Brus Laguna Airport',
    city: 'Brus Laguna',
    country: 'Honduras',
    iata: 'BHG',
    icao: 'MHBL',
    latitude: 15.7631,
    longitude: -84.543602,
    altitude: 19,
    schengen: false
  },
  {
    id: 2925,
    name: 'Goloson International Airport',
    city: 'La Ceiba',
    country: 'Honduras',
    iata: 'LCE',
    icao: 'MHLC',
    latitude: 15.7425,
    longitude: -86.852997,
    altitude: 39,
    schengen: false
  },
  {
    id: 2926,
    name: 'Ramón Villeda Morales International Airport',
    city: 'San Pedro Sula',
    country: 'Honduras',
    iata: 'SAP',
    icao: 'MHLM',
    latitude: 15.4526,
    longitude: -87.923599,
    altitude: 91,
    schengen: false
  },
  {
    id: 2927,
    name: 'La Laguna Airport',
    city: 'Guanaja',
    country: 'Honduras',
    iata: 'GJA',
    icao: 'MHNJ',
    latitude: 16.4454,
    longitude: -85.906601,
    altitude: 49,
    schengen: false
  },
  {
    id: 2928,
    name: 'Puerto Lempira Airport',
    city: 'Puerto Lempira',
    country: 'Honduras',
    iata: 'PEU',
    icao: 'MHPL',
    latitude: 15.2622,
    longitude: -83.781197,
    altitude: 33,
    schengen: false
  },
  {
    id: 2929,
    name: 'Juan Manuel Galvez International Airport',
    city: 'Roatan',
    country: 'Honduras',
    iata: 'RTB',
    icao: 'MHRO',
    latitude: 16.316799,
    longitude: -86.523003,
    altitude: 39,
    schengen: false
  },
  {
    id: 2930,
    name: 'Coronel Enrique Soto Cano Air Base',
    city: 'Comayagua',
    country: 'Honduras',
    iata: 'XPL',
    icao: 'MHSC',
    latitude: 14.3824,
    longitude: -87.621201,
    altitude: 2061,
    schengen: false
  },
  {
    id: 2931,
    name: 'Tela Airport',
    city: 'Tela',
    country: 'Honduras',
    iata: 'TEA',
    icao: 'MHTE',
    latitude: 15.7759,
    longitude: -87.4758,
    altitude: 7,
    schengen: false
  },
  {
    id: 2932,
    name: 'Toncontín International Airport',
    city: 'Tegucigalpa',
    country: 'Honduras',
    iata: 'TGU',
    icao: 'MHTG',
    latitude: 14.0608997344971,
    longitude: -87.2172012329102,
    altitude: 3294,
    schengen: false
  },
  {
    id: 2933,
    name: 'Trujillo Airport',
    city: 'Trujillo',
    country: 'Honduras',
    iata: 'TJI',
    icao: 'MHTJ',
    latitude: 15.9268,
    longitude: -85.938202,
    altitude: 3,
    schengen: false
  },
  {
    id: 2934,
    name: 'Utila Airport',
    city: 'Utila',
    country: 'Honduras',
    iata: 'UII',
    icao: 'MHUT',
    latitude: 16.1131,
    longitude: -86.880302,
    altitude: 29,
    schengen: false
  },
  {
    id: 2935,
    name: 'Hong Kong International Airport',
    city: 'Hong Kong',
    country: 'Hong Kong',
    iata: 'HKG',
    icao: 'VHHH',
    latitude: 22.308901,
    longitude: 113.915001,
    altitude: 28,
    schengen: false
  },
  {
    id: 2936,
    name: 'Shek Kong Air Base',
    city: 'Sek Kong',
    country: 'Hong Kong',
    iata: '',
    icao: 'VHSK',
    latitude: 22.4365997314453,
    longitude: 114.080001831055,
    altitude: 50,
    schengen: false
  },
  {
    id: 2937,
    name: 'Shun Tak Heliport',
    city: 'Hong Kong',
    country: 'Hong Kong',
    iata: 'HHP',
    icao: 'VHST',
    latitude: 22.2893714905,
    longitude: 114.152153015,
    altitude: 107,
    schengen: false
  },
  {
    id: 2938,
    name: 'Sturgate Airfield',
    city: 'Eger',
    country: 'Hungary',
    iata: '',
    icao: 'EGCS',
    latitude: 53.3810997009277,
    longitude: -0.685277998447418,
    altitude: 58,
    schengen: true
  },
  {
    id: 2939,
    name: 'Budapest Liszt Ferenc International Airport',
    city: 'Budapest',
    country: 'Hungary',
    iata: 'BUD',
    icao: 'LHBP',
    latitude: 47.42976,
    longitude: 19.261093,
    altitude: 495,
    schengen: true
  },
  {
    id: 2940,
    name: 'Budaörs Airfield',
    city: 'Budaoers',
    country: 'Hungary',
    iata: '',
    icao: 'LHBS',
    latitude: 47.451099,
    longitude: 18.9806,
    altitude: 397,
    schengen: true
  },
  {
    id: 2941,
    name: 'Debrecen International Airport',
    city: 'Debrecen',
    country: 'Hungary',
    iata: 'DEB',
    icao: 'LHDC',
    latitude: 47.488899230957,
    longitude: 21.6152992248535,
    altitude: 359,
    schengen: true
  },
  {
    id: 2942,
    name: 'Jakabszállás Airport',
    city: 'Jakabszallas',
    country: 'Hungary',
    iata: '',
    icao: 'LHJK',
    latitude: 46.747200012207,
    longitude: 19.6056003570557,
    altitude: 348,
    schengen: true
  },
  {
    id: 2943,
    name: 'Kecskemét Airport',
    city: 'Kecskemet',
    country: 'Hungary',
    iata: '',
    icao: 'LHKE',
    latitude: 46.9174995422363,
    longitude: 19.7492008209229,
    altitude: 376,
    schengen: true
  },
  {
    id: 2944,
    name: 'Nyíregyháza Airport',
    city: 'Nyirregyhaza',
    country: 'Hungary',
    iata: '',
    icao: 'LHNY',
    latitude: 47.9838981628418,
    longitude: 21.6923007965088,
    altitude: 338,
    schengen: true
  },
  {
    id: 2945,
    name: 'Őcsény Airport',
    city: 'Ocseny',
    country: 'Hungary',
    iata: '',
    icao: 'LHOY',
    latitude: 46.3039016723633,
    longitude: 18.7691993713379,
    altitude: 295,
    schengen: true
  },
  {
    id: 2946,
    name: 'Pápa Air Base',
    city: 'Papa',
    country: 'Hungary',
    iata: '',
    icao: 'LHPA',
    latitude: 47.3636016846,
    longitude: 17.5007991791,
    altitude: 466,
    schengen: true
  },
  {
    id: 2947,
    name: 'Pécs-Pogány Airport',
    city: 'Pécs-Pogány',
    country: 'Hungary',
    iata: 'PEV',
    icao: 'LHPP',
    latitude: 45.990898,
    longitude: 18.240996,
    altitude: 1000,
    schengen: true
  },
  {
    id: 2948,
    name: 'Győr-Pér International Airport',
    city: 'Győr',
    country: 'Hungary',
    iata: '',
    icao: 'LHPR',
    latitude: 47.624401,
    longitude: 17.813601,
    altitude: 424,
    schengen: true
  },
  {
    id: 2949,
    name: 'Szentkirályszabadja Airport',
    city: 'Azentkilyszabadja',
    country: 'Hungary',
    iata: '',
    icao: 'LHSA',
    latitude: 47.0778999328613,
    longitude: 17.9683990478516,
    altitude: 919,
    schengen: true
  },
  {
    id: 2950,
    name: 'Sármellék International Airport',
    city: 'Sármellék',
    country: 'Hungary',
    iata: 'SOB',
    icao: 'LHSM',
    latitude: 46.686391,
    longitude: 17.159084,
    altitude: 408,
    schengen: true
  },
  {
    id: 2951,
    name: 'Szolnok Air Base',
    city: 'Szolnok',
    country: 'Hungary',
    iata: '',
    icao: 'LHSN',
    latitude: 47.122897,
    longitude: 20.2355,
    altitude: 322,
    schengen: true
  },
  {
    id: 2952,
    name: 'Tököl Airport',
    city: 'Budapest',
    country: 'Hungary',
    iata: '',
    icao: 'LHTL',
    latitude: 47.3452987671,
    longitude: 18.9808006287,
    altitude: 318,
    schengen: true
  },
  {
    id: 2953,
    name: 'Akureyri Airport',
    city: 'Akureyri',
    country: 'Iceland',
    iata: 'AEY',
    icao: 'BIAR',
    latitude: 65.6600036621094,
    longitude: -18.0727005004883,
    altitude: 6,
    schengen: true
  },
  {
    id: 2954,
    name: 'Bakki Airport',
    city: 'Bakki',
    country: 'Iceland',
    iata: '',
    icao: 'BIBA',
    latitude: 63.5560989379883,
    longitude: -20.1375007629395,
    altitude: 45,
    schengen: true
  },
  {
    id: 2955,
    name: 'Bildudalur Airport',
    city: 'Bildudalur',
    country: 'Iceland',
    iata: 'BIU',
    icao: 'BIBD',
    latitude: 65.6412963867188,
    longitude: -23.546199798584,
    altitude: 18,
    schengen: true
  },
  {
    id: 2956,
    name: 'Egilsstaðir Airport',
    city: 'Egilsstadir',
    country: 'Iceland',
    iata: 'EGS',
    icao: 'BIEG',
    latitude: 65.2833023071289,
    longitude: -14.4013996124268,
    altitude: 76,
    schengen: true
  },
  {
    id: 2957,
    name: 'Grundarfjörður Airport',
    city: 'Grundarfjordur',
    country: 'Iceland',
    iata: 'GUU',
    icao: 'BIGF',
    latitude: 64.9914016723633,
    longitude: -23.2247009277344,
    altitude: 17,
    schengen: true
  },
  {
    id: 2958,
    name: 'Gjögur Airport',
    city: 'Gjogur',
    country: 'Iceland',
    iata: 'GJR',
    icao: 'BIGJ',
    latitude: 65.9953002929688,
    longitude: -21.3269004821777,
    altitude: 83,
    schengen: true
  },
  {
    id: 2959,
    name: 'Grímsey Airport',
    city: 'Grímsey',
    country: 'Iceland',
    iata: 'GRY',
    icao: 'BIGR',
    latitude: 66.5458,
    longitude: -18.0173,
    altitude: 66,
    schengen: true
  },
  {
    id: 2960,
    name: 'Hornafjörður Airport',
    city: 'Hofn',
    country: 'Iceland',
    iata: 'HFN',
    icao: 'BIHN',
    latitude: 64.295601,
    longitude: -15.2272,
    altitude: 24,
    schengen: true
  },
  {
    id: 2961,
    name: 'Húsavík Airport',
    city: 'Husavik',
    country: 'Iceland',
    iata: 'HZK',
    icao: 'BIHU',
    latitude: 65.952301,
    longitude: -17.426001,
    altitude: 48,
    schengen: true
  },
  {
    id: 2962,
    name: 'Ísafjörður Airport',
    city: 'Isafjordur',
    country: 'Iceland',
    iata: 'IFJ',
    icao: 'BIIS',
    latitude: 66.0580978393555,
    longitude: -23.1352996826172,
    altitude: 8,
    schengen: true
  },
  {
    id: 2963,
    name: 'Keflavik International Airport',
    city: 'Keflavik',
    country: 'Iceland',
    iata: 'KEF',
    icao: 'BIKF',
    latitude: 63.985000610352,
    longitude: -22.605600357056,
    altitude: 171,
    schengen: true
  },
  {
    id: 2964,
    name: 'Sauðárkrókur Airport',
    city: 'Saudarkrokur',
    country: 'Iceland',
    iata: 'SAK',
    icao: 'BIKR',
    latitude: 65.7316970825,
    longitude: -19.5727996826,
    altitude: 8,
    schengen: true
  },
  {
    id: 2965,
    name: 'Norðfjörður Airport',
    city: 'Nordfjordur',
    country: 'Iceland',
    iata: 'NOR',
    icao: 'BINF',
    latitude: 65.1318969726563,
    longitude: -13.7463998794556,
    altitude: 13,
    schengen: true
  },
  {
    id: 2966,
    name: 'Patreksfjörður Airport',
    city: 'Patreksfjordur',
    country: 'Iceland',
    iata: 'PFJ',
    icao: 'BIPA',
    latitude: 65.555801,
    longitude: -23.965,
    altitude: 11,
    schengen: true
  },
  {
    id: 2967,
    name: 'Reykjavik Airport',
    city: 'Reykjavik',
    country: 'Iceland',
    iata: 'RKV',
    icao: 'BIRK',
    latitude: 64.1299972534,
    longitude: -21.9405994415,
    altitude: 48,
    schengen: true
  },
  {
    id: 2968,
    name: 'Reykjahlíð Airport',
    city: 'Myvatn',
    country: 'Iceland',
    iata: 'MVA',
    icao: 'BIRL',
    latitude: 65.6557998657227,
    longitude: -16.9181003570557,
    altitude: 1030,
    schengen: true
  },
  {
    id: 2969,
    name: 'Selfoss Airport',
    city: 'Selfoss',
    country: 'Iceland',
    iata: '',
    icao: 'BISF',
    latitude: 63.92919921875,
    longitude: -21.0377998352051,
    altitude: 45,
    schengen: true
  },
  {
    id: 2970,
    name: 'Siglufjörður Airport',
    city: 'Siglufjordur',
    country: 'Iceland',
    iata: 'SIJ',
    icao: 'BISI',
    latitude: 66.133301,
    longitude: -18.9167,
    altitude: 10,
    schengen: true
  },
  {
    id: 2971,
    name: 'Thorshofn Airport',
    city: 'Thorshofn',
    country: 'Iceland',
    iata: 'THO',
    icao: 'BITN',
    latitude: 66.2184982299805,
    longitude: -15.335599899292,
    altitude: 65,
    schengen: true
  },
  {
    id: 2972,
    name: 'Vestmannaeyjar Airport',
    city: 'Vestmannaeyjar',
    country: 'Iceland',
    iata: 'VEY',
    icao: 'BIVM',
    latitude: 63.4243011474609,
    longitude: -20.2789001464844,
    altitude: 326,
    schengen: true
  },
  {
    id: 2973,
    name: 'Vopnafjörður Airport',
    city: 'Vopnafjörður',
    country: 'Iceland',
    iata: 'VPN',
    icao: 'BIVO',
    latitude: 65.7205963134766,
    longitude: -14.8506002426147,
    altitude: 16,
    schengen: true
  },
  {
    id: 2974,
    name: 'Diu Airport',
    city: 'Diu',
    country: 'India',
    iata: 'DIU',
    icao: 'VA1P',
    latitude: 20.7131004333496,
    longitude: 70.9210968017578,
    altitude: 31,
    schengen: false
  },
  {
    id: 2975,
    name: 'Sardar Vallabhbhai Patel International Airport',
    city: 'Ahmedabad',
    country: 'India',
    iata: 'AMD',
    icao: 'VAAH',
    latitude: 23.0771999359,
    longitude: 72.6346969604,
    altitude: 189,
    schengen: false
  },
  {
    id: 2976,
    name: 'Akola Airport',
    city: 'Akola',
    country: 'India',
    iata: 'AKD',
    icao: 'VAAK',
    latitude: 20.698999,
    longitude: 77.058601,
    altitude: 999,
    schengen: false
  },
  {
    id: 2977,
    name: 'Aurangabad Airport',
    city: 'Aurangabad',
    country: 'India',
    iata: 'IXU',
    icao: 'VAAU',
    latitude: 19.862699508667,
    longitude: 75.3981018066406,
    altitude: 1911,
    schengen: false
  },
  {
    id: 2978,
    name: 'Chhatrapati Shivaji International Airport',
    city: 'Mumbai',
    country: 'India',
    iata: 'BOM',
    icao: 'VABB',
    latitude: 19.0886993408,
    longitude: 72.8678970337,
    altitude: 39,
    schengen: false
  },
  {
    id: 2979,
    name: 'Bilaspur Airport',
    city: 'Bilaspur',
    country: 'India',
    iata: 'PAB',
    icao: 'VABI',
    latitude: 21.9884,
    longitude: 82.111,
    altitude: 899,
    schengen: false
  },
  {
    id: 2980,
    name: 'Bhuj Airport',
    city: 'Bhuj',
    country: 'India',
    iata: 'BHJ',
    icao: 'VABJ',
    latitude: 23.2877998352,
    longitude: 69.6701965332,
    altitude: 268,
    schengen: false
  },
  {
    id: 2981,
    name: 'Belgaum Airport',
    city: 'Belgaum',
    country: 'India',
    iata: 'IXG',
    icao: 'VABM',
    latitude: 15.8592996597,
    longitude: 74.6183013916,
    altitude: 2487,
    schengen: false
  },
  {
    id: 2982,
    name: 'Vadodara Airport',
    city: 'Baroda',
    country: 'India',
    iata: 'BDQ',
    icao: 'VABO',
    latitude: 22.336201,
    longitude: 73.226303,
    altitude: 129,
    schengen: false
  },
  {
    id: 2983,
    name: 'Raja Bhoj International Airport',
    city: 'Bhopal',
    country: 'India',
    iata: 'BHO',
    icao: 'VABP',
    latitude: 23.2875003815,
    longitude: 77.3374023438,
    altitude: 1711,
    schengen: false
  },
  {
    id: 2984,
    name: 'Bhavnagar Airport',
    city: 'Bhaunagar',
    country: 'India',
    iata: 'BHU',
    icao: 'VABV',
    latitude: 21.752199173,
    longitude: 72.1852035522,
    altitude: 44,
    schengen: false
  },
  {
    id: 2985,
    name: 'Daman Airport',
    city: 'Daman',
    country: 'India',
    iata: 'NMB',
    icao: 'VADN',
    latitude: 20.4344005584717,
    longitude: 72.8432006835938,
    altitude: 33,
    schengen: false
  },
  {
    id: 2986,
    name: 'Deesa Airport',
    city: 'Deesa',
    country: 'India',
    iata: '',
    icao: 'VADS',
    latitude: 24.2679004669189,
    longitude: 72.2043991088867,
    altitude: 485,
    schengen: false
  },
  {
    id: 2987,
    name: 'Guna Airport',
    city: 'Guna',
    country: 'India',
    iata: 'GUX',
    icao: 'VAGN',
    latitude: 24.654699,
    longitude: 77.347298,
    altitude: 1600,
    schengen: false
  },
  {
    id: 2988,
    name: 'Dabolim Airport',
    city: 'Goa',
    country: 'India',
    iata: 'GOI',
    icao: 'VAGO',
    latitude: 15.3808002472,
    longitude: 73.8313980103,
    altitude: 150,
    schengen: false
  },
  {
    id: 2989,
    name: 'Hubli Airport',
    city: 'Hubli',
    country: 'India',
    iata: 'HBX',
    icao: 'VAHB',
    latitude: 15.361700058,
    longitude: 75.0848999023,
    altitude: 2171,
    schengen: false
  },
  {
    id: 2990,
    name: 'Devi Ahilyabai Holkar Airport',
    city: 'Indore',
    country: 'India',
    iata: 'IDR',
    icao: 'VAID',
    latitude: 22.7217998505,
    longitude: 75.8011016846,
    altitude: 1850,
    schengen: false
  },
  {
    id: 2991,
    name: 'Jabalpur Airport',
    city: 'Jabalpur',
    country: 'India',
    iata: 'JLR',
    icao: 'VAJB',
    latitude: 23.1777992248535,
    longitude: 80.052001953125,
    altitude: 1624,
    schengen: false
  },
  {
    id: 2992,
    name: 'Jamnagar Airport',
    city: 'Jamnagar',
    country: 'India',
    iata: 'JGA',
    icao: 'VAJM',
    latitude: 22.4654998779297,
    longitude: 70.0126037597656,
    altitude: 69,
    schengen: false
  },
  {
    id: 2993,
    name: 'Kandla Airport',
    city: 'Kandla',
    country: 'India',
    iata: 'IXY',
    icao: 'VAKE',
    latitude: 23.1126995087,
    longitude: 70.1003036499,
    altitude: 96,
    schengen: false
  },
  {
    id: 2994,
    name: 'Khajuraho Airport',
    city: 'Khajuraho',
    country: 'India',
    iata: 'HJR',
    icao: 'VAKJ',
    latitude: 24.817199707,
    longitude: 79.9186019897,
    altitude: 728,
    schengen: false
  },
  {
    id: 2995,
    name: 'Kolhapur Airport',
    city: 'Kolhapur',
    country: 'India',
    iata: 'KLH',
    icao: 'VAKP',
    latitude: 16.6646995544,
    longitude: 74.2893981934,
    altitude: 1996,
    schengen: false
  },
  {
    id: 2996,
    name: 'Keshod Airport',
    city: 'Keshod',
    country: 'India',
    iata: 'IXK',
    icao: 'VAKS',
    latitude: 21.3171005249023,
    longitude: 70.2704010009766,
    altitude: 167,
    schengen: false
  },
  {
    id: 2997,
    name: 'Nanded Airport',
    city: 'Nanded',
    country: 'India',
    iata: 'NDC',
    icao: 'VAND',
    latitude: 19.1833000183,
    longitude: 77.3167037964,
    altitude: 1250,
    schengen: false
  },
  {
    id: 2998,
    name: 'Dr. Babasaheb Ambedkar International Airport',
    city: 'Nagpur',
    country: 'India',
    iata: 'NAG',
    icao: 'VANP',
    latitude: 21.0921993255615,
    longitude: 79.0472030639648,
    altitude: 1033,
    schengen: false
  },
  {
    id: 2999,
    name: 'Nashik Airport',
    city: 'Nasik Road',
    country: 'India',
    iata: 'ISK',
    icao: 'VAOZ',
    latitude: 20.119101,
    longitude: 73.912903,
    altitude: 1900,
    schengen: false
  },
  {
    id: 3000,
    name: 'Pune Airport',
    city: 'Pune',
    country: 'India',
    iata: 'PNQ',
    icao: 'VAPO',
    latitude: 18.5820999145508,
    longitude: 73.9197006225586,
    altitude: 1942,
    schengen: false
  },
  {
    id: 3001,
    name: 'Porbandar Airport',
    city: 'Porbandar',
    country: 'India',
    iata: 'PBD',
    icao: 'VAPR',
    latitude: 21.6487007141,
    longitude: 69.6572036743,
    altitude: 23,
    schengen: false
  },
  {
    id: 3002,
    name: 'Ratnagiri Airport',
    city: '',
    country: 'India',
    iata: 'RTC',
    icao: 'VARG',
    latitude: 17.013599,
    longitude: 73.327797,
    altitude: 305,
    schengen: false
  },
  {
    id: 3003,
    name: 'Rajkot Airport',
    city: 'Rajkot',
    country: 'India',
    iata: 'RAJ',
    icao: 'VARK',
    latitude: 22.3092002869,
    longitude: 70.7795028687,
    altitude: 441,
    schengen: false
  },
  {
    id: 3004,
    name: 'Raipur Airport',
    city: 'Raipur',
    country: 'India',
    iata: 'RPR',
    icao: 'VARP',
    latitude: 21.180401,
    longitude: 81.7388,
    altitude: 1041,
    schengen: false
  },
  {
    id: 3005,
    name: 'Shirdi Airport',
    city: 'Shirdi',
    country: 'India',
    iata: 'SAG',
    icao: 'VASD',
    latitude: 19.688611,
    longitude: 74.378889,
    altitude: 1926,
    schengen: false
  },
  {
    id: 3006,
    name: 'Solapur Airport',
    city: 'Sholapur',
    country: 'India',
    iata: 'SSE',
    icao: 'VASL',
    latitude: 17.6280002594,
    longitude: 75.9347991943,
    altitude: 1584,
    schengen: false
  },
  {
    id: 3007,
    name: 'Surat Airport',
    city: 'Surat',
    country: 'India',
    iata: 'STV',
    icao: 'VASU',
    latitude: 21.1140995026,
    longitude: 72.7417984009,
    altitude: 16,
    schengen: false
  },
  {
    id: 3008,
    name: 'Maharana Pratap Airport',
    city: 'Udaipur',
    country: 'India',
    iata: 'UDR',
    icao: 'VAUD',
    latitude: 24.6177005768,
    longitude: 73.8961029053,
    altitude: 1684,
    schengen: false
  },
  {
    id: 3009,
    name: 'Along Airport',
    city: 'Along',
    country: 'India',
    iata: 'IXV',
    icao: 'VEAN',
    latitude: 28.1753005981445,
    longitude: 94.802001953125,
    altitude: 900,
    schengen: false
  },
  {
    id: 3010,
    name: 'Agartala Airport',
    city: 'Agartala',
    country: 'India',
    iata: 'IXA',
    icao: 'VEAT',
    latitude: 23.8869991302,
    longitude: 91.2404022217,
    altitude: 46,
    schengen: false
  },
  {
    id: 3011,
    name: 'Bagdogra Airport',
    city: 'Baghdogra',
    country: 'India',
    iata: 'IXB',
    icao: 'VEBD',
    latitude: 26.6812000274658,
    longitude: 88.3285980224609,
    altitude: 412,
    schengen: false
  },
  {
    id: 3012,
    name: 'Shillong Airport',
    city: 'Shillong',
    country: 'India',
    iata: 'SHL',
    icao: 'VEBI',
    latitude: 25.7035999298096,
    longitude: 91.9786987304687,
    altitude: 2910,
    schengen: false
  },
  {
    id: 3013,
    name: 'Bokaro Airport',
    city: 'Bokaro',
    country: 'India',
    iata: '',
    icao: 'VEBK',
    latitude: 23.6434993743896,
    longitude: 86.1489028930664,
    altitude: 715,
    schengen: false
  },
  {
    id: 3014,
    name: 'Biju Patnaik Airport',
    city: 'Bhubaneswar',
    country: 'India',
    iata: 'BBI',
    icao: 'VEBS',
    latitude: 20.2444000244,
    longitude: 85.8178024292,
    altitude: 138,
    schengen: false
  },
  {
    id: 3015,
    name: 'Netaji Subhash Chandra Bose International Airport',
    city: 'Kolkata',
    country: 'India',
    iata: 'CCU',
    icao: 'VECC',
    latitude: 22.6546993255615,
    longitude: 88.4467010498047,
    altitude: 16,
    schengen: false
  },
  {
    id: 3016,
    name: 'Cooch Behar Airport',
    city: 'Cooch-behar',
    country: 'India',
    iata: 'COH',
    icao: 'VECO',
    latitude: 26.330499649,
    longitude: 89.4672012329,
    altitude: 138,
    schengen: false
  },
  {
    id: 3017,
    name: 'Dhanbad Airport',
    city: 'Dhanbad',
    country: 'India',
    iata: 'DBD',
    icao: 'VEDB',
    latitude: 23.8339996337891,
    longitude: 86.4253005981445,
    altitude: 847,
    schengen: false
  },
  {
    id: 3018,
    name: 'Kazi Nazrul Islam Airport',
    city: 'Durgapur',
    country: 'India',
    iata: 'RDP',
    icao: 'VEDG',
    latitude: 23.6225,
    longitude: 87.243,
    altitude: 300,
    schengen: false
  },
  {
    id: 3019,
    name: 'Gorakhpur Airport',
    city: 'Gorakhpur',
    country: 'India',
    iata: 'GOP',
    icao: 'VEGK',
    latitude: 26.7397003174,
    longitude: 83.4496994019,
    altitude: 259,
    schengen: false
  },
  {
    id: 3020,
    name: 'Lokpriya Gopinath Bordoloi International Airport',
    city: 'Guwahati',
    country: 'India',
    iata: 'GAU',
    icao: 'VEGT',
    latitude: 26.1061000823975,
    longitude: 91.5858993530273,
    altitude: 162,
    schengen: false
  },
  {
    id: 3021,
    name: 'Gaya Airport',
    city: 'Gaya',
    country: 'India',
    iata: 'GAY',
    icao: 'VEGY',
    latitude: 24.7443008422852,
    longitude: 84.9512023925781,
    altitude: 380,
    schengen: false
  },
  {
    id: 3022,
    name: 'Hirakud Airport',
    city: 'Hirakud',
    country: 'India',
    iata: '',
    icao: 'VEHK',
    latitude: 21.5802001953125,
    longitude: 84.0056991577148,
    altitude: 658,
    schengen: false
  },
  {
    id: 3023,
    name: 'Imphal Airport',
    city: 'Imphal',
    country: 'India',
    iata: 'IMF',
    icao: 'VEIM',
    latitude: 24.7600002289,
    longitude: 93.896697998,
    altitude: 2540,
    schengen: false
  },
  {
    id: 3024,
    name: 'Jharsuguda Airport',
    city: 'Jharsuguda',
    country: 'India',
    iata: '',
    icao: 'VEJH',
    latitude: 21.9134998321533,
    longitude: 84.0503997802734,
    altitude: 751,
    schengen: false
  },
  {
    id: 3025,
    name: 'Jeypore Airport',
    city: 'Jeypore',
    country: 'India',
    iata: 'PYB',
    icao: 'VEJP',
    latitude: 18.8799991607666,
    longitude: 82.552001953125,
    altitude: 1952,
    schengen: false
  },
  {
    id: 3026,
    name: 'Sonari Airport',
    city: 'Jamshedpur',
    country: 'India',
    iata: 'IXW',
    icao: 'VEJS',
    latitude: 22.8132,
    longitude: 86.1688,
    altitude: 475,
    schengen: false
  },
  {
    id: 3027,
    name: 'Jorhat Airport',
    city: 'Jorhat',
    country: 'India',
    iata: 'JRH',
    icao: 'VEJT',
    latitude: 26.7315006256,
    longitude: 94.1754989624,
    altitude: 311,
    schengen: false
  },
  {
    id: 3028,
    name: 'Kailashahar Airport',
    city: 'Kailashahar',
    country: 'India',
    iata: 'IXH',
    icao: 'VEKR',
    latitude: 24.3082008361816,
    longitude: 92.0072021484375,
    altitude: 79,
    schengen: false
  },
  {
    id: 3029,
    name: 'Silchar Airport',
    city: 'Silchar',
    country: 'India',
    iata: 'IXS',
    icao: 'VEKU',
    latitude: 24.9129009247,
    longitude: 92.9786987305,
    altitude: 352,
    schengen: false
  },
  {
    id: 3030,
    name: 'Lengpui Airport',
    city: 'Aizwal',
    country: 'India',
    iata: 'AJL',
    icao: 'VELP',
    latitude: 23.8405990601,
    longitude: 92.6196975708,
    altitude: 1398,
    schengen: false
  },
  {
    id: 3031,
    name: 'North Lakhimpur Airport',
    city: 'Lilabari',
    country: 'India',
    iata: 'IXI',
    icao: 'VELR',
    latitude: 27.2954998016357,
    longitude: 94.0976028442383,
    altitude: 330,
    schengen: false
  },
  {
    id: 3032,
    name: 'Dibrugarh Airport',
    city: 'Mohanbari',
    country: 'India',
    iata: 'DIB',
    icao: 'VEMN',
    latitude: 27.4839000702,
    longitude: 95.0168991089,
    altitude: 362,
    schengen: false
  },
  {
    id: 3033,
    name: 'Dimapur Airport',
    city: 'Dimapur',
    country: 'India',
    iata: 'DMU',
    icao: 'VEMR',
    latitude: 25.8838996887,
    longitude: 93.7711029053,
    altitude: 487,
    schengen: false
  },
  {
    id: 3034,
    name: 'Muzaffarpur Airport',
    city: 'Mazuffarpur',
    country: 'India',
    iata: 'MZU',
    icao: 'VEMZ',
    latitude: 26.1191005706787,
    longitude: 85.3136978149414,
    altitude: 174,
    schengen: false
  },
  {
    id: 3035,
    name: 'Nawapara Airport',
    city: 'Nawapara',
    country: 'India',
    iata: '',
    icao: 'VENP',
    latitude: 20.8700008392334,
    longitude: 82.5195999145508,
    altitude: 1058,
    schengen: false
  },
  {
    id: 3036,
    name: 'Pasighat Airport',
    city: 'Pasighat',
    country: 'India',
    iata: 'IXT',
    icao: 'VEPG',
    latitude: 28.0660991668701,
    longitude: 95.3356018066406,
    altitude: 477,
    schengen: false
  },
  {
    id: 3037,
    name: 'Panagarh Air Force Station',
    city: 'Panagarh',
    country: 'India',
    iata: '',
    icao: 'VEPH',
    latitude: 23.4743003845,
    longitude: 87.4274978638,
    altitude: 240,
    schengen: false
  },
  {
    id: 3038,
    name: 'Lok Nayak Jayaprakash Airport',
    city: 'Patina',
    country: 'India',
    iata: 'PAT',
    icao: 'VEPT',
    latitude: 25.591299057,
    longitude: 85.0879974365,
    altitude: 170,
    schengen: false
  },
  {
    id: 3039,
    name: 'Purnea Airport',
    city: 'Purnea',
    country: 'India',
    iata: '',
    icao: 'VEPU',
    latitude: 25.7595996856689,
    longitude: 87.4100036621094,
    altitude: 129,
    schengen: false
  },
  {
    id: 3040,
    name: 'Birsa Munda Airport',
    city: 'Ranchi',
    country: 'India',
    iata: 'IXR',
    icao: 'VERC',
    latitude: 23.3143005371,
    longitude: 85.3217010498,
    altitude: 2148,
    schengen: false
  },
  {
    id: 3041,
    name: 'Rourkela Airport',
    city: 'Rourkela',
    country: 'India',
    iata: 'RRK',
    icao: 'VERK',
    latitude: 22.256701,
    longitude: 84.814598,
    altitude: 659,
    schengen: false
  },
  {
    id: 3042,
    name: 'Tezpur Airport',
    city: 'Tezpur',
    country: 'India',
    iata: 'TEZ',
    icao: 'VETZ',
    latitude: 26.7091007232666,
    longitude: 92.7846984863281,
    altitude: 240,
    schengen: false
  },
  {
    id: 3043,
    name: 'Utkela Airport',
    city: 'Utkela',
    country: 'India',
    iata: '',
    icao: 'VEUK',
    latitude: 20.0974006652832,
    longitude: 83.1837997436523,
    altitude: 680,
    schengen: false
  },
  {
    id: 3044,
    name: 'Vishakhapatnam Airport',
    city: 'Vishakhapatnam',
    country: 'India',
    iata: 'VTZ',
    icao: 'VEVZ',
    latitude: 17.721200943,
    longitude: 83.2245025635,
    altitude: 15,
    schengen: false
  },
  {
    id: 3045,
    name: 'Ziro Airport',
    city: 'Zero',
    country: 'India',
    iata: 'ZER',
    icao: 'VEZO',
    latitude: 27.588301,
    longitude: 93.828102,
    altitude: 5403,
    schengen: false
  },
  {
    id: 3046,
    name: 'Nagaur Airport',
    city: 'Nagaur',
    country: 'India',
    iata: '',
    icao: 'VI73',
    latitude: 27.2082996368408,
    longitude: 73.7114028930664,
    altitude: 950,
    schengen: false
  },
  {
    id: 3047,
    name: 'Agra Airport',
    city: 'Agra',
    country: 'India',
    iata: 'AGR',
    icao: 'VIAG',
    latitude: 27.1557998657227,
    longitude: 77.9608993530273,
    altitude: 551,
    schengen: false
  },
  {
    id: 3048,
    name: 'Allahabad Airport',
    city: 'Allahabad',
    country: 'India',
    iata: 'IXD',
    icao: 'VIAL',
    latitude: 25.4400997161865,
    longitude: 81.7339019775391,
    altitude: 322,
    schengen: false
  },
  {
    id: 3049,
    name: 'Ambala Air Force Station',
    city: '',
    country: 'India',
    iata: '',
    icao: 'VIAM',
    latitude: 30.3680992126465,
    longitude: 76.8167037963867,
    altitude: 909,
    schengen: false
  },
  {
    id: 3050,
    name: 'Sri Guru Ram Dass Jee International Airport',
    city: 'Amritsar',
    country: 'India',
    iata: 'ATQ',
    icao: 'VIAR',
    latitude: 31.7096004486,
    longitude: 74.7973022461,
    altitude: 756,
    schengen: false
  },
  {
    id: 3051,
    name: 'Adampur Airport',
    city: 'Adampur',
    country: 'India',
    iata: 'AIP',
    icao: 'VIAX',
    latitude: 31.4338,
    longitude: 75.758797,
    altitude: 775,
    schengen: false
  },
  {
    id: 3052,
    name: 'Nal Airport',
    city: 'Bikaner',
    country: 'India',
    iata: 'BKB',
    icao: 'VIBK',
    latitude: 28.0706005096436,
    longitude: 73.2071990966797,
    altitude: 750,
    schengen: false
  },
  {
    id: 3053,
    name: 'Bakshi Ka Talab Air Force Station',
    city: 'Bakshi Ka Talab',
    country: 'India',
    iata: '',
    icao: 'VIBL',
    latitude: 26.9883003234863,
    longitude: 80.8930969238281,
    altitude: 385,
    schengen: false
  },
  {
    id: 3054,
    name: 'Lal Bahadur Shastri Airport',
    city: 'Varanasi',
    country: 'India',
    iata: 'VNS',
    icao: 'VIBN',
    latitude: 25.4524002075,
    longitude: 82.8592987061,
    altitude: 266,
    schengen: false
  },
  {
    id: 3055,
    name: 'Kullu Manali Airport',
    city: 'Kulu',
    country: 'India',
    iata: 'KUU',
    icao: 'VIBR',
    latitude: 31.8766994476318,
    longitude: 77.1544036865234,
    altitude: 3573,
    schengen: false
  },
  {
    id: 3056,
    name: 'Bhatinda Air Force Station',
    city: 'Bhatinda',
    country: 'India',
    iata: 'BUP',
    icao: 'VIBT',
    latitude: 30.2700996398926,
    longitude: 74.7557983398437,
    altitude: 662,
    schengen: false
  },
  {
    id: 3057,
    name: 'Bhiwani Airport',
    city: 'Bhiwani',
    country: 'India',
    iata: '',
    icao: 'VIBW',
    latitude: 28.8369998931885,
    longitude: 76.1791000366211,
    altitude: 720,
    schengen: false
  },
  {
    id: 3058,
    name: 'Bareilly Air Force Station',
    city: 'Bareilly',
    country: 'India',
    iata: 'BEK',
    icao: 'VIBY',
    latitude: 28.4221000671,
    longitude: 79.4507980347,
    altitude: 580,
    schengen: false
  },
  {
    id: 3059,
    name: 'Chandigarh Airport',
    city: 'Chandigarh',
    country: 'India',
    iata: 'IXC',
    icao: 'VICG',
    latitude: 30.6735000610352,
    longitude: 76.7884979248047,
    altitude: 1012,
    schengen: false
  },
  {
    id: 3060,
    name: 'Kanpur Airport',
    city: 'Kanpur',
    country: 'India',
    iata: 'KNU',
    icao: 'VICX',
    latitude: 26.404301,
    longitude: 80.410103,
    altitude: 410,
    schengen: false
  },
  {
    id: 3061,
    name: 'Safdarjung Airport',
    city: 'Delhi',
    country: 'India',
    iata: '',
    icao: 'VIDD',
    latitude: 28.5844993591309,
    longitude: 77.2058029174805,
    altitude: 705,
    schengen: false
  },
  {
    id: 3062,
    name: 'Dehradun Airport',
    city: 'Dehra Dun',
    country: 'India',
    iata: 'DED',
    icao: 'VIDN',
    latitude: 30.189699,
    longitude: 78.180298,
    altitude: 1831,
    schengen: false
  },
  {
    id: 3063,
    name: 'Indira Gandhi International Airport',
    city: 'Delhi',
    country: 'India',
    iata: 'DEL',
    icao: 'VIDP',
    latitude: 28.5665,
    longitude: 77.103104,
    altitude: 777,
    schengen: false
  },
  {
    id: 3064,
    name: 'Kangra Airport',
    city: 'Kangra',
    country: 'India',
    iata: 'DHM',
    icao: 'VIGG',
    latitude: 32.1651000976563,
    longitude: 76.2633972167969,
    altitude: 2525,
    schengen: false
  },
  {
    id: 3065,
    name: 'Gwalior Airport',
    city: 'Gwalior',
    country: 'India',
    iata: 'GWL',
    icao: 'VIGR',
    latitude: 26.2933006286621,
    longitude: 78.2277984619141,
    altitude: 617,
    schengen: false
  },
  {
    id: 3066,
    name: 'Hissar Airport',
    city: 'Hissar',
    country: 'India',
    iata: 'HSS',
    icao: 'VIHR',
    latitude: 29.1793994903564,
    longitude: 75.7553024291992,
    altitude: 700,
    schengen: false
  },
  {
    id: 3067,
    name: 'Jhansi Airport',
    city: 'Jhansi',
    country: 'India',
    iata: '',
    icao: 'VIJN',
    latitude: 25.4911994934082,
    longitude: 78.5584030151367,
    altitude: 801,
    schengen: false
  },
  {
    id: 3068,
    name: 'Jodhpur Airport',
    city: 'Jodhpur',
    country: 'India',
    iata: 'JDH',
    icao: 'VIJO',
    latitude: 26.2511005401611,
    longitude: 73.0488967895508,
    altitude: 717,
    schengen: false
  },
  {
    id: 3069,
    name: 'Jaipur International Airport',
    city: 'Jaipur',
    country: 'India',
    iata: 'JAI',
    icao: 'VIJP',
    latitude: 26.8242,
    longitude: 75.812202,
    altitude: 1263,
    schengen: false
  },
  {
    id: 3070,
    name: 'Jaisalmer Airport',
    city: 'Jaisalmer',
    country: 'India',
    iata: 'JSA',
    icao: 'VIJR',
    latitude: 26.8887004852295,
    longitude: 70.8649978637695,
    altitude: 751,
    schengen: false
  },
  {
    id: 3071,
    name: 'Jammu Airport',
    city: 'Jammu',
    country: 'India',
    iata: 'IXJ',
    icao: 'VIJU',
    latitude: 32.6890983582,
    longitude: 74.8374023438,
    altitude: 1029,
    schengen: false
  },
  {
    id: 3072,
    name: 'Kanpur Civil Airport',
    city: 'Kanpur',
    country: 'India',
    iata: '',
    icao: 'VIKA',
    latitude: 26.441401,
    longitude: 80.364899,
    altitude: 411,
    schengen: false
  },
  {
    id: 3073,
    name: 'Kishangarh Airport',
    city: 'Ajmer',
    country: 'India',
    iata: 'KQH',
    icao: 'VIKG',
    latitude: 26.601473,
    longitude: 74.814147,
    altitude: 0,
    schengen: false
  },
  {
    id: 3074,
    name: 'Kota Airport',
    city: 'Kota',
    country: 'India',
    iata: 'KTU',
    icao: 'VIKO',
    latitude: 25.160200119,
    longitude: 75.8455963135,
    altitude: 896,
    schengen: false
  },
  {
    id: 3075,
    name: 'Ludhiana Airport',
    city: 'Ludhiaha',
    country: 'India',
    iata: 'LUH',
    icao: 'VILD',
    latitude: 30.854700088501,
    longitude: 75.9525985717773,
    altitude: 834,
    schengen: false
  },
  {
    id: 3076,
    name: 'Leh Kushok Bakula Rimpochee Airport',
    city: 'Leh',
    country: 'India',
    iata: 'IXL',
    icao: 'VILH',
    latitude: 34.1358985901,
    longitude: 77.5465011597,
    altitude: 10682,
    schengen: false
  },
  {
    id: 3077,
    name: 'Chaudhary Charan Singh International Airport',
    city: 'Lucknow',
    country: 'India',
    iata: 'LKO',
    icao: 'VILK',
    latitude: 26.7605991364,
    longitude: 80.8892974854,
    altitude: 410,
    schengen: false
  },
  {
    id: 3078,
    name: 'Pathankot Airport',
    city: 'Pathankot',
    country: 'India',
    iata: 'IXP',
    icao: 'VIPK',
    latitude: 32.233611,
    longitude: 75.634444,
    altitude: 1017,
    schengen: false
  },
  {
    id: 3079,
    name: 'Patiala Airport',
    city: 'Patiala',
    country: 'India',
    iata: '',
    icao: 'VIPL',
    latitude: 30.3148002624512,
    longitude: 76.364501953125,
    altitude: 820,
    schengen: false
  },
  {
    id: 3080,
    name: 'Pantnagar Airport',
    city: 'Nainital',
    country: 'India',
    iata: 'PGH',
    icao: 'VIPT',
    latitude: 29.0333995819092,
    longitude: 79.4737014770508,
    altitude: 769,
    schengen: false
  },
  {
    id: 3081,
    name: 'Fursatganj Airport',
    city: 'Raibarelli',
    country: 'India',
    iata: '',
    icao: 'VIRB',
    latitude: 26.2485008239746,
    longitude: 81.380500793457,
    altitude: 360,
    schengen: false
  },
  {
    id: 3082,
    name: 'Sirsa Air Force Station',
    city: '',
    country: 'India',
    iata: '',
    icao: 'VISA',
    latitude: 29.5606002807617,
    longitude: 75.006103515625,
    altitude: 650,
    schengen: false
  },
  {
    id: 3083,
    name: 'Shimla Airport',
    city: 'Shimla',
    country: 'India',
    iata: 'SLV',
    icao: 'VISM',
    latitude: 31.0818,
    longitude: 77.068001,
    altitude: 5072,
    schengen: false
  },
  {
    id: 3084,
    name: 'Sarsawa Air Force Station',
    city: 'Saharanpur',
    country: 'India',
    iata: '',
    icao: 'VISP',
    latitude: 29.9939002990723,
    longitude: 77.4253005981445,
    altitude: 891,
    schengen: false
  },
  {
    id: 3085,
    name: 'Sheikh ul Alam Airport',
    city: 'Srinagar',
    country: 'India',
    iata: 'SXR',
    icao: 'VISR',
    latitude: 33.9870986938477,
    longitude: 74.7742004394531,
    altitude: 5429,
    schengen: false
  },
  {
    id: 3086,
    name: 'Satna Airport',
    city: 'Satna',
    country: 'India',
    iata: 'TNI',
    icao: 'VIST',
    latitude: 24.5623,
    longitude: 80.854897,
    altitude: 1060,
    schengen: false
  },
  {
    id: 3087,
    name: 'Udhampur Air Force Station',
    city: '',
    country: 'India',
    iata: '',
    icao: 'VIUX',
    latitude: 32.9021987915039,
    longitude: 75.1561965942383,
    altitude: 2066,
    schengen: false
  },
  {
    id: 3088,
    name: 'Agatti Airport',
    city: 'Agatti Island',
    country: 'India',
    iata: 'AGX',
    icao: 'VOAT',
    latitude: 10.8236999511719,
    longitude: 72.1760025024414,
    altitude: 14,
    schengen: false
  },
  {
    id: 3089,
    name: 'HAL Airport',
    city: 'Bangalore',
    country: 'India',
    iata: '',
    icao: 'VOBG',
    latitude: 12.9499998093,
    longitude: 77.6681976318,
    altitude: 2912,
    schengen: false
  },
  {
    id: 3090,
    name: 'Bellary Airport',
    city: 'Bellary',
    country: 'India',
    iata: 'BEP',
    icao: 'VOBI',
    latitude: 15.1627998352051,
    longitude: 76.8827972412109,
    altitude: 30,
    schengen: false
  },
  {
    id: 3091,
    name: 'Kempegowda International Airport',
    city: 'Bangalore',
    country: 'India',
    iata: 'BLR',
    icao: 'VOBL',
    latitude: 13.1979,
    longitude: 77.706299,
    altitude: 3000,
    schengen: false
  },
  {
    id: 3092,
    name: 'Bidar Air Force Station',
    city: 'Bidar',
    country: 'India',
    iata: '',
    icao: 'VOBR',
    latitude: 17.9081001281738,
    longitude: 77.4870986938477,
    altitude: 2178,
    schengen: false
  },
  {
    id: 3093,
    name: 'Vijayawada Airport',
    city: 'Vijayawada',
    country: 'India',
    iata: 'VGA',
    icao: 'VOBZ',
    latitude: 16.5303993225098,
    longitude: 80.7967987060547,
    altitude: 82,
    schengen: false
  },
  {
    id: 3094,
    name: 'Coimbatore International Airport',
    city: 'Coimbatore',
    country: 'India',
    iata: 'CJB',
    icao: 'VOCB',
    latitude: 11.029999733,
    longitude: 77.0434036255,
    altitude: 1324,
    schengen: false
  },
  {
    id: 3095,
    name: 'Cochin International Airport',
    city: 'Kochi',
    country: 'India',
    iata: 'COK',
    icao: 'VOCI',
    latitude: 10.152,
    longitude: 76.401901,
    altitude: 30,
    schengen: false
  },
  {
    id: 3096,
    name: 'Calicut International Airport',
    city: 'Calicut',
    country: 'India',
    iata: 'CCJ',
    icao: 'VOCL',
    latitude: 11.1367998123,
    longitude: 75.9552993774,
    altitude: 342,
    schengen: false
  },
  {
    id: 3097,
    name: 'Kadapa Airport',
    city: 'Cuddapah',
    country: 'India',
    iata: 'CDP',
    icao: 'VOCP',
    latitude: 14.51,
    longitude: 78.772778,
    altitude: 430,
    schengen: false
  },
  {
    id: 3098,
    name: 'Car Nicobar Air Force Station',
    city: 'Carnicobar',
    country: 'India',
    iata: 'CBD',
    icao: 'VOCX',
    latitude: 9.15250968933106,
    longitude: 92.8196029663086,
    altitude: 5,
    schengen: false
  },
  {
    id: 3099,
    name: 'Dundigul Air Force Academy',
    city: 'Dundigul',
    country: 'India',
    iata: '',
    icao: 'VODG',
    latitude: 17.627199173,
    longitude: 78.4033966064,
    altitude: 2013,
    schengen: false
  },
  {
    id: 3100,
    name: 'Rajiv Gandhi International Airport',
    city: 'Hyderabad',
    country: 'India',
    iata: 'HYD',
    icao: 'VOHS',
    latitude: 17.2313175201,
    longitude: 78.4298553467,
    altitude: 2024,
    schengen: false
  },
  {
    id: 3101,
    name: 'Begumpet Airport',
    city: 'Hyderabad',
    country: 'India',
    iata: 'BPM',
    icao: 'VOHY',
    latitude: 17.4531002045,
    longitude: 78.4675979614,
    altitude: 1742,
    schengen: false
  },
  {
    id: 3102,
    name: 'Kannur International Airport',
    city: 'Kannur',
    country: 'India',
    iata: 'CNN',
    icao: 'VOKN',
    latitude: 11.918614,
    longitude: 75.547211,
    altitude: 330,
    schengen: false
  },
  {
    id: 3103,
    name: 'Madurai Airport',
    city: 'Madurai',
    country: 'India',
    iata: 'IXM',
    icao: 'VOMD',
    latitude: 9.83450984955,
    longitude: 78.0933990479,
    altitude: 459,
    schengen: false
  },
  {
    id: 3104,
    name: 'Mangalore International Airport',
    city: 'Mangalore',
    country: 'India',
    iata: 'IXE',
    icao: 'VOML',
    latitude: 12.9612998962,
    longitude: 74.8900985718,
    altitude: 337,
    schengen: false
  },
  {
    id: 3105,
    name: 'Chennai International Airport',
    city: 'Madras',
    country: 'India',
    iata: 'MAA',
    icao: 'VOMM',
    latitude: 12.9900054931641,
    longitude: 80.1692962646484,
    altitude: 52,
    schengen: false
  },
  {
    id: 3106,
    name: 'Mysore Airport',
    city: 'Mysore',
    country: 'India',
    iata: 'MYQ',
    icao: 'VOMY',
    latitude: 12.23,
    longitude: 76.655833,
    altitude: 2349,
    schengen: false
  },
  {
    id: 3107,
    name: 'Nagarjuna Sagar Airport',
    city: 'Nagarjunsagar',
    country: 'India',
    iata: '',
    icao: 'VONS',
    latitude: 16.5426998138428,
    longitude: 79.3187026977539,
    altitude: 658,
    schengen: false
  },
  {
    id: 3108,
    name: 'Vir Savarkar International Airport',
    city: 'Port Blair',
    country: 'India',
    iata: 'IXZ',
    icao: 'VOPB',
    latitude: 11.6412000656128,
    longitude: 92.7296981811523,
    altitude: 14,
    schengen: false
  },
  {
    id: 3109,
    name: 'Pondicherry Airport',
    city: 'Pendicherry',
    country: 'India',
    iata: 'PNY',
    icao: 'VOPC',
    latitude: 11.968,
    longitude: 79.812,
    altitude: 134,
    schengen: false
  },
  {
    id: 3110,
    name: 'Sri Sathya Sai Airport',
    city: 'Puttaparthi',
    country: 'India',
    iata: 'PUT',
    icao: 'VOPN',
    latitude: 14.1492996216,
    longitude: 77.7910995483,
    altitude: 1558,
    schengen: false
  },
  {
    id: 3111,
    name: 'Rajahmundry Airport',
    city: 'Rajahmundry',
    country: 'India',
    iata: 'RJA',
    icao: 'VORY',
    latitude: 17.1103992462,
    longitude: 81.8181991577,
    altitude: 151,
    schengen: false
  },
  {
    id: 3112,
    name: 'Salem Airport',
    city: 'Salem',
    country: 'India',
    iata: 'SXV',
    icao: 'VOSM',
    latitude: 11.78330039978,
    longitude: 78.06559753418,
    altitude: 1008,
    schengen: false
  },
  {
    id: 3113,
    name: 'Tanjore Air Force Base',
    city: 'Tanjore',
    country: 'India',
    iata: 'TJV',
    icao: 'VOTJ',
    latitude: 10.7223997116089,
    longitude: 79.1016006469727,
    altitude: 253,
    schengen: false
  },
  {
    id: 3114,
    name: 'Tirupati Airport',
    city: 'Tirupeti',
    country: 'India',
    iata: 'TIR',
    icao: 'VOTP',
    latitude: 13.6324996948,
    longitude: 79.543296814,
    altitude: 350,
    schengen: false
  },
  {
    id: 3115,
    name: 'Tiruchirapally Civil Airport Airport',
    city: 'Tiruchirappalli',
    country: 'India',
    iata: 'TRZ',
    icao: 'VOTR',
    latitude: 10.7653999328613,
    longitude: 78.7097015380859,
    altitude: 288,
    schengen: false
  },
  {
    id: 3116,
    name: 'Trivandrum International Airport',
    city: 'Trivandrum',
    country: 'India',
    iata: 'TRV',
    icao: 'VOTV',
    latitude: 8.48211956024,
    longitude: 76.9200973511,
    altitude: 15,
    schengen: false
  },
  {
    id: 3117,
    name: 'Tambaram Air Force Station',
    city: 'Tambaram',
    country: 'India',
    iata: '',
    icao: 'VOTX',
    latitude: 12.9071998596191,
    longitude: 80.1219024658203,
    altitude: 90,
    schengen: false
  },
  {
    id: 3118,
    name: 'Yelahanka Air Force Station',
    city: 'Bangalore',
    country: 'India',
    iata: '',
    icao: 'VOYK',
    latitude: 13.1354999542236,
    longitude: 77.6060028076172,
    altitude: 3045,
    schengen: false
  },
  {
    id: 3119,
    name: 'Berkley Structures Heliport',
    city: 'Pagerungan',
    country: 'Indonesia',
    iata: '',
    icao: 'WA19',
    latitude: 47.682043,
    longitude: -122.148456,
    altitude: 50,
    schengen: false
  },
  {
    id: 3120,
    name: 'Maranggo Airport',
    city: 'Sulawesi Tenggara',
    country: 'Indonesia',
    iata: 'TQQ',
    icao: 'WA44',
    latitude: -5.7645702362061,
    longitude: 123.91699981689,
    altitude: 169,
    schengen: false
  },
  {
    id: 3121,
    name: 'Cascade Heliport',
    city: 'Kobok',
    country: 'Indonesia',
    iata: '',
    icao: 'WA98',
    latitude: 47.512346,
    longitude: -120.488691,
    altitude: 860,
    schengen: false
  },
  {
    id: 3122,
    name: 'Hasanuddin International Airport',
    city: 'Ujung Pandang',
    country: 'Indonesia',
    iata: 'UPG',
    icao: 'WAAA',
    latitude: -5.06162977218628,
    longitude: 119.554000854492,
    altitude: 47,
    schengen: false
  },
  {
    id: 3123,
    name: 'Frans Kaisiepo Airport',
    city: 'Biak',
    country: 'Indonesia',
    iata: 'BIK',
    icao: 'WABB',
    latitude: -1.19001996517181,
    longitude: 136.108001708984,
    altitude: 46,
    schengen: false
  },
  {
    id: 3124,
    name: 'Nabire Airport',
    city: 'Nabire',
    country: 'Indonesia',
    iata: 'NBX',
    icao: 'WABI',
    latitude: -3.36818,
    longitude: 135.496002,
    altitude: 20,
    schengen: false
  },
  {
    id: 3125,
    name: 'Illaga Airport',
    city: 'Illaga',
    country: 'Indonesia',
    iata: 'ILA',
    icao: 'WABL',
    latitude: -3.97648,
    longitude: 137.6225,
    altitude: 7989,
    schengen: false
  },
  {
    id: 3126,
    name: 'Kokonau Airport',
    city: 'Kokonau',
    country: 'Indonesia',
    iata: 'KOX',
    icao: 'WABN',
    latitude: -4.71075,
    longitude: 136.43515,
    altitude: 59,
    schengen: false
  },
  {
    id: 3127,
    name: 'Moses Kilangin Airport',
    city: 'Timika',
    country: 'Indonesia',
    iata: 'TIM',
    icao: 'WABP',
    latitude: -4.52827978134155,
    longitude: 136.886993408203,
    altitude: 103,
    schengen: false
  },
  {
    id: 3128,
    name: 'Selaparang Airport',
    city: 'Mataram',
    country: 'Indonesia',
    iata: 'AMI',
    icao: 'WADA',
    latitude: -8.56070995331,
    longitude: 116.095001221,
    altitude: 52,
    schengen: false
  },
  {
    id: 3129,
    name: 'Muhammad Salahuddin Airport',
    city: 'Bima',
    country: 'Indonesia',
    iata: 'BMU',
    icao: 'WADB',
    latitude: -8.5396499633789,
    longitude: 118.68699645996,
    altitude: 3,
    schengen: false
  },
  {
    id: 3130,
    name: 'Ngurah Rai (Bali) International Airport',
    city: 'Denpasar',
    country: 'Indonesia',
    iata: 'DPS',
    icao: 'WADD',
    latitude: -8.7481698989868,
    longitude: 115.16699981689,
    altitude: 14,
    schengen: false
  },
  {
    id: 3131,
    name: 'Lombok International Airport',
    city: 'Praya',
    country: 'Indonesia',
    iata: 'LOP',
    icao: 'WADL',
    latitude: -8.757322,
    longitude: 116.276675,
    altitude: 319,
    schengen: false
  },
  {
    id: 3132,
    name: 'Sumbawa Besar Airport',
    city: 'Sumbawa Island',
    country: 'Indonesia',
    iata: 'SWQ',
    icao: 'WADS',
    latitude: -8.48904037475586,
    longitude: 117.412002563477,
    altitude: 16,
    schengen: false
  },
  {
    id: 3133,
    name: 'Tambolaka Airport',
    city: 'Waikabubak-Sumba Island',
    country: 'Indonesia',
    iata: 'TMC',
    icao: 'WADT',
    latitude: -9.40972,
    longitude: 119.244003,
    altitude: 161,
    schengen: false
  },
  {
    id: 3134,
    name: 'Umbu Mehang Kunda Airport',
    city: 'Waingapu',
    country: 'Indonesia',
    iata: 'WGP',
    icao: 'WADW',
    latitude: -9.66922,
    longitude: 120.302002,
    altitude: 33,
    schengen: false
  },
  {
    id: 3135,
    name: 'Bokondini Airport',
    city: 'Bokondini-Papua Island',
    country: 'Indonesia',
    iata: 'BUI',
    icao: 'WAJB',
    latitude: -3.6822,
    longitude: 138.6755,
    altitude: 4550,
    schengen: false
  },
  {
    id: 3136,
    name: 'Sentani Airport',
    city: 'Jayapura',
    country: 'Indonesia',
    iata: 'DJJ',
    icao: 'WAJJ',
    latitude: -2.57695007324219,
    longitude: 140.516006469727,
    altitude: 289,
    schengen: false
  },
  {
    id: 3137,
    name: 'Mulia Airport',
    city: 'Mulia',
    country: 'Indonesia',
    iata: 'LII',
    icao: 'WAJM',
    latitude: -3.7018,
    longitude: 137.957,
    altitude: 6527,
    schengen: false
  },
  {
    id: 3138,
    name: 'Oksibil Airport',
    city: 'Oksibil',
    country: 'Indonesia',
    iata: 'OKL',
    icao: 'WAJO',
    latitude: -4.9071,
    longitude: 140.6277,
    altitude: 4315,
    schengen: false
  },
  {
    id: 3139,
    name: 'Waris Airport',
    city: 'Waris-Papua Island',
    country: 'Indonesia',
    iata: 'WAR',
    icao: 'WAJR',
    latitude: -3.235,
    longitude: 140.994,
    altitude: 1500,
    schengen: false
  },
  {
    id: 3140,
    name: 'Senggeh Airport',
    city: 'Senggeh-Papua Island',
    country: 'Indonesia',
    iata: 'SEH',
    icao: 'WAJS',
    latitude: -3.45,
    longitude: 140.779,
    altitude: 914,
    schengen: false
  },
  {
    id: 3141,
    name: 'Wamena Airport',
    city: 'Wamena',
    country: 'Indonesia',
    iata: 'WMX',
    icao: 'WAJW',
    latitude: -4.10251,
    longitude: 138.957001,
    altitude: 5085,
    schengen: false
  },
  {
    id: 3142,
    name: 'Mopah Airport',
    city: 'Merauke',
    country: 'Indonesia',
    iata: 'MKQ',
    icao: 'WAKK',
    latitude: -8.52029037475586,
    longitude: 140.417999267578,
    altitude: 10,
    schengen: false
  },
  {
    id: 3143,
    name: 'Tanah Merah Airport',
    city: 'Boven Digoel',
    country: 'Indonesia',
    iata: 'TMH',
    icao: 'WAKT',
    latitude: -6.09921979904175,
    longitude: 140.298004150391,
    altitude: 57,
    schengen: false
  },
  {
    id: 3144,
    name: 'Tanjung Harapan Airport',
    city: 'Tanjung Selor-Borneo Island',
    country: 'Indonesia',
    iata: 'TJS',
    icao: 'WALG',
    latitude: 2.83583333333,
    longitude: 117.373611111,
    altitude: 10,
    schengen: false
  },
  {
    id: 3145,
    name: 'Datadawai Airport',
    city: 'Datadawai-Borneo Island',
    country: 'Indonesia',
    iata: 'DTD',
    icao: 'WALJ',
    latitude: 0.8106,
    longitude: 114.5306,
    altitude: 508,
    schengen: false
  },
  {
    id: 3146,
    name: 'Kalimarau Airport',
    city: 'Tanjung Redep-Borneo Island',
    country: 'Indonesia',
    iata: 'BEJ',
    icao: 'WALK',
    latitude: 2.1555,
    longitude: 117.431999,
    altitude: 59,
    schengen: false
  },
  {
    id: 3147,
    name: 'Sultan Aji Muhamad Sulaiman Airport',
    city: 'Balikpapan',
    country: 'Indonesia',
    iata: 'BPN',
    icao: 'WALL',
    latitude: -1.26827001572,
    longitude: 116.893997192,
    altitude: 12,
    schengen: false
  },
  {
    id: 3148,
    name: 'Juwata Airport',
    city: 'Taraken',
    country: 'Indonesia',
    iata: 'TRK',
    icao: 'WALR',
    latitude: 3.326667,
    longitude: 117.569444,
    altitude: 23,
    schengen: false
  },
  {
    id: 3149,
    name: 'Temindung Airport',
    city: 'Samarinda',
    country: 'Indonesia',
    iata: 'SRI',
    icao: 'WALS',
    latitude: -0.484530985355,
    longitude: 117.156997681,
    altitude: 33,
    schengen: false
  },
  {
    id: 3150,
    name: 'Tanjung Santan Airport',
    city: 'Tanjung Santan',
    country: 'Indonesia',
    iata: 'TSX',
    icao: 'WALT',
    latitude: -0.0929730013013,
    longitude: 117.45300293,
    altitude: 121,
    schengen: false
  },
  {
    id: 3151,
    name: 'Bunyu Airport',
    city: 'Bunyu',
    country: 'Indonesia',
    iata: 'BYQ',
    icao: 'WALV',
    latitude: 3.45571994781,
    longitude: 117.866996765,
    altitude: 118,
    schengen: false
  },
  {
    id: 3152,
    name: 'Jalaluddin Airport',
    city: 'Gorontalo',
    country: 'Indonesia',
    iata: 'GTO',
    icao: 'WAMG',
    latitude: 0.63711899519,
    longitude: 122.849998474,
    altitude: 105,
    schengen: false
  },
  {
    id: 3153,
    name: 'Naha Airport',
    city: 'Naha',
    country: 'Indonesia',
    iata: 'NAH',
    icao: 'WAMH',
    latitude: 3.68320989608765,
    longitude: 125.52799987793,
    altitude: 16,
    schengen: false
  },
  {
    id: 3154,
    name: 'Sultan Bantilan Airport',
    city: 'Toli-Toli',
    country: 'Indonesia',
    iata: 'TLI',
    icao: 'WAMI',
    latitude: 1.123428,
    longitude: 120.793658,
    altitude: 40,
    schengen: false
  },
  {
    id: 3155,
    name: 'Kao Airport',
    city: 'Kao',
    country: 'Indonesia',
    iata: 'KAZ',
    icao: 'WAMK',
    latitude: 1.1852799654007,
    longitude: 127.896003723145,
    altitude: 27,
    schengen: false
  },
  {
    id: 3156,
    name: 'Mutiara Airport',
    city: 'Palu',
    country: 'Indonesia',
    iata: 'PLW',
    icao: 'WAML',
    latitude: -0.91854202747345,
    longitude: 119.910003662109,
    altitude: 284,
    schengen: false
  },
  {
    id: 3157,
    name: 'Sam Ratulangi Airport',
    city: 'Manado',
    country: 'Indonesia',
    iata: 'MDC',
    icao: 'WAMM',
    latitude: 1.54926002025604,
    longitude: 124.926002502441,
    altitude: 264,
    schengen: false
  },
  {
    id: 3158,
    name: 'Melangguane Airport',
    city: 'Melonguane',
    country: 'Indonesia',
    iata: 'MNA',
    icao: 'WAMN',
    latitude: 4.00694,
    longitude: 126.672997,
    altitude: 3,
    schengen: false
  },
  {
    id: 3159,
    name: 'Kasiguncu Airport',
    city: 'Poso',
    country: 'Indonesia',
    iata: 'PSJ',
    icao: 'WAMP',
    latitude: -1.41674995422,
    longitude: 120.657997131,
    altitude: 174,
    schengen: false
  },
  {
    id: 3160,
    name: 'Pitu Airport',
    city: 'Morotai Island',
    country: 'Indonesia',
    iata: 'OTI',
    icao: 'WAMR',
    latitude: 2.04598999023,
    longitude: 128.324996948,
    altitude: 49,
    schengen: false
  },
  {
    id: 3161,
    name: 'Sultan Khairun Babullah Airport',
    city: 'Ternate',
    country: 'Indonesia',
    iata: 'TTE',
    icao: 'WAMT',
    latitude: 0.831413984298706,
    longitude: 127.380996704102,
    altitude: 49,
    schengen: false
  },
  {
    id: 3162,
    name: 'Syukuran Aminuddin Amir Airport',
    city: 'Luwuk',
    country: 'Indonesia',
    iata: 'LUW',
    icao: 'WAMW',
    latitude: -1.03892,
    longitude: 122.772003,
    altitude: 56,
    schengen: false
  },
  {
    id: 3163,
    name: 'Buol Airport',
    city: 'Buol',
    country: 'Indonesia',
    iata: 'UOL',
    icao: 'WAMY',
    latitude: 1.1027,
    longitude: 121.4141,
    altitude: 27,
    schengen: false
  },
  {
    id: 3164,
    name: 'Batu Licin Airport',
    city: 'Batu Licin',
    country: 'Indonesia',
    iata: 'BTW',
    icao: 'WAOC',
    latitude: -3.41241002083,
    longitude: 115.995002747,
    altitude: 3,
    schengen: false
  },
  {
    id: 3165,
    name: 'Iskandar Airport',
    city: 'Pangkalan Bun',
    country: 'Indonesia',
    iata: 'PKN',
    icao: 'WAOI',
    latitude: -2.70519995689,
    longitude: 111.672996521,
    altitude: 75,
    schengen: false
  },
  {
    id: 3166,
    name: 'Beringin Airport',
    city: 'Muara Teweh',
    country: 'Indonesia',
    iata: '',
    icao: 'WAOM',
    latitude: -0.940325021744,
    longitude: 114.893875122,
    altitude: 126,
    schengen: false
  },
  {
    id: 3167,
    name: 'Warukin Airport',
    city: 'Tanjung-Borneo Island',
    country: 'Indonesia',
    iata: 'TJG',
    icao: 'WAON',
    latitude: -2.21655988693,
    longitude: 115.435997009,
    altitude: 197,
    schengen: false
  },
  {
    id: 3168,
    name: 'Syamsudin Noor Airport',
    city: 'Banjarmasin',
    country: 'Indonesia',
    iata: 'BDJ',
    icao: 'WAOO',
    latitude: -3.44235992431641,
    longitude: 114.763000488281,
    altitude: 66,
    schengen: false
  },
  {
    id: 3169,
    name: 'Tjilik Riwut Airport',
    city: 'Palangkaraya',
    country: 'Indonesia',
    iata: 'PKY',
    icao: 'WAOP',
    latitude: -2.22513008118,
    longitude: 113.943000793,
    altitude: 82,
    schengen: false
  },
  {
    id: 3170,
    name: 'Sampit(Hasan) Airport',
    city: 'Sampit-Borneo Island',
    country: 'Indonesia',
    iata: 'SMQ',
    icao: 'WAOS',
    latitude: -2.49919009209,
    longitude: 112.974998474,
    altitude: 50,
    schengen: false
  },
  {
    id: 3171,
    name: 'Rar Gwamar Airport',
    city: 'Dobo',
    country: 'Indonesia',
    iata: 'DOB',
    icao: 'WAPD',
    latitude: -5.77222013474,
    longitude: 134.212005615,
    altitude: 61,
    schengen: false
  },
  {
    id: 3172,
    name: 'Namrole Airport',
    city: 'Buru Island',
    country: 'Indonesia',
    iata: 'NRE',
    icao: 'WAPG',
    latitude: -3.8548,
    longitude: 126.7012,
    altitude: 31,
    schengen: false
  },
  {
    id: 3173,
    name: 'Saumlaki/Olilit Airport',
    city: 'Saumlaki',
    country: 'Indonesia',
    iata: 'SXK',
    icao: 'WAPI',
    latitude: -7.9886097908,
    longitude: 131.305999756,
    altitude: 218,
    schengen: false
  },
  {
    id: 3174,
    name: 'Dumatumbun Airport',
    city: 'Langgur-Kei Islands',
    country: 'Indonesia',
    iata: 'LUV',
    icao: 'WAPL',
    latitude: -5.66162014007568,
    longitude: 132.731002807617,
    altitude: 10,
    schengen: false
  },
  {
    id: 3175,
    name: 'Emalamo Sanana Airport',
    city: 'Sanana',
    country: 'Indonesia',
    iata: 'SQN',
    icao: 'WAPN',
    latitude: -2.08050990104675,
    longitude: 125.967002868652,
    altitude: 39,
    schengen: false
  },
  {
    id: 3176,
    name: 'Pattimura Airport, Ambon',
    city: 'Ambon',
    country: 'Indonesia',
    iata: 'AMQ',
    icao: 'WAPP',
    latitude: -3.7102599144,
    longitude: 128.089004517,
    altitude: 33,
    schengen: false
  },
  {
    id: 3177,
    name: 'Namlea Airport',
    city: 'Namlea',
    country: 'Indonesia',
    iata: 'NAM',
    icao: 'WAPR',
    latitude: -3.23556995391846,
    longitude: 127.099998474121,
    altitude: 41,
    schengen: false
  },
  {
    id: 3178,
    name: 'Abdul Rachman Saleh Airport',
    city: 'Malang',
    country: 'Indonesia',
    iata: 'MLG',
    icao: 'WARA',
    latitude: -7.92655992508,
    longitude: 112.714996338,
    altitude: 1726,
    schengen: false
  },
  {
    id: 3179,
    name: 'Iswahyudi Airport',
    city: 'Madiun',
    country: 'Indonesia',
    iata: '',
    icao: 'WARI',
    latitude: -7.61576986312866,
    longitude: 111.43399810791,
    altitude: 361,
    schengen: false
  },
  {
    id: 3180,
    name: 'Adi Sutjipto International Airport',
    city: 'Yogyakarta',
    country: 'Indonesia',
    iata: 'JOG',
    icao: 'WARJ',
    latitude: -7.78817987442017,
    longitude: 110.431999206543,
    altitude: 350,
    schengen: false
  },
  {
    id: 3181,
    name: 'Adi Sumarmo Wiryokusumo Airport',
    city: 'Solo City',
    country: 'Indonesia',
    iata: 'SOC',
    icao: 'WARQ',
    latitude: -7.51608991622925,
    longitude: 110.75700378418,
    altitude: 421,
    schengen: false
  },
  {
    id: 3182,
    name: 'Juanda International Airport',
    city: 'Surabaya',
    country: 'Indonesia',
    iata: 'SUB',
    icao: 'WARR',
    latitude: -7.37982988357544,
    longitude: 112.787002563477,
    altitude: 9,
    schengen: false
  },
  {
    id: 3183,
    name: 'Achmad Yani Airport',
    city: 'Semarang',
    country: 'Indonesia',
    iata: 'SRG',
    icao: 'WARS',
    latitude: -6.97273,
    longitude: 110.375,
    altitude: 10,
    schengen: false
  },
  {
    id: 3184,
    name: 'Trunojoyo Airport',
    city: 'Sumenep',
    country: 'Indonesia',
    iata: 'SUP',
    icao: 'WART',
    latitude: -7.0242,
    longitude: 113.89023,
    altitude: 10,
    schengen: false
  },
  {
    id: 3185,
    name: 'Dewadaru - Kemujan Island',
    city: 'Karimunjawa',
    country: 'Indonesia',
    iata: 'KWB',
    icao: 'WARU',
    latitude: -5.80091,
    longitude: 110.47838,
    altitude: 35,
    schengen: false
  },
  {
    id: 3186,
    name: 'Stenkol Airport',
    city: 'Bintuni',
    country: 'Indonesia',
    iata: 'NTI',
    icao: 'WASB',
    latitude: -2.1033,
    longitude: 133.5164,
    altitude: 57,
    schengen: false
  },
  {
    id: 3187,
    name: 'Fakfak Airport',
    city: 'Fak Fak',
    country: 'Indonesia',
    iata: 'FKQ',
    icao: 'WASF',
    latitude: -2.92019009590149,
    longitude: 132.266998291016,
    altitude: 462,
    schengen: false
  },
  {
    id: 3188,
    name: 'Kaimana Airport',
    city: 'Kaimana',
    country: 'Indonesia',
    iata: 'KNG',
    icao: 'WASK',
    latitude: -3.64452004432678,
    longitude: 133.695999145508,
    altitude: 19,
    schengen: false
  },
  {
    id: 3189,
    name: 'Babo Airport',
    city: 'Babo',
    country: 'Indonesia',
    iata: 'BXB',
    icao: 'WASO',
    latitude: -2.53223991394043,
    longitude: 133.438995361328,
    altitude: 10,
    schengen: false
  },
  {
    id: 3190,
    name: 'Rendani Airport',
    city: 'Manokwari',
    country: 'Indonesia',
    iata: 'MKW',
    icao: 'WASR',
    latitude: -0.891833007335663,
    longitude: 134.04899597168,
    altitude: 23,
    schengen: false
  },
  {
    id: 3191,
    name: 'Wasior Airport',
    city: 'Wasior',
    country: 'Indonesia',
    iata: 'WSR',
    icao: 'WASW',
    latitude: -2.721,
    longitude: 134.5061,
    altitude: 49,
    schengen: false
  },
  {
    id: 3192,
    name: 'Bajawa Soa Airport',
    city: 'Bajawa',
    country: 'Indonesia',
    iata: 'BJW',
    icao: 'WATB',
    latitude: -8.70743498008,
    longitude: 121.057426929,
    altitude: 4326,
    schengen: false
  },
  {
    id: 3193,
    name: 'Maumere(Wai Oti) Airport',
    city: 'Maumere',
    country: 'Indonesia',
    iata: 'MOF',
    icao: 'WATC',
    latitude: -8.64064979553,
    longitude: 122.236999512,
    altitude: 115,
    schengen: false
  },
  {
    id: 3194,
    name: 'Ende (H Hasan Aroeboesman) Airport',
    city: 'Ende',
    country: 'Indonesia',
    iata: 'ENE',
    icao: 'WATE',
    latitude: -8.8492898941,
    longitude: 121.661003113,
    altitude: 49,
    schengen: false
  },
  {
    id: 3195,
    name: 'Frans Sales Lega Airport',
    city: 'Ruteng',
    country: 'Indonesia',
    iata: 'RTG',
    icao: 'WATG',
    latitude: -8.5970096588135,
    longitude: 120.47699737549,
    altitude: 3510,
    schengen: false
  },
  {
    id: 3196,
    name: 'Mali Airport',
    city: 'Alor Island',
    country: 'Indonesia',
    iata: 'ARD',
    icao: 'WATM',
    latitude: -8.13234043121338,
    longitude: 124.59700012207,
    altitude: 10,
    schengen: false
  },
  {
    id: 3197,
    name: 'Komodo Airport',
    city: 'Labuhan Bajo',
    country: 'Indonesia',
    iata: 'LBJ',
    icao: 'WATO',
    latitude: -8.48666,
    longitude: 119.889,
    altitude: 66,
    schengen: false
  },
  {
    id: 3198,
    name: 'El Tari Airport',
    city: 'Kupang',
    country: 'Indonesia',
    iata: 'KOE',
    icao: 'WATT',
    latitude: -10.1716003417969,
    longitude: 123.670997619629,
    altitude: 335,
    schengen: false
  },
  {
    id: 3199,
    name: 'Betoambari Airport',
    city: 'Bau-Bau',
    country: 'Indonesia',
    iata: 'BUW',
    icao: 'WAWB',
    latitude: -5.48687982559204,
    longitude: 122.569000244141,
    altitude: 164,
    schengen: false
  },
  {
    id: 3200,
    name: 'Selayar/Aroepala Airport',
    city: 'Selayar',
    country: 'Indonesia',
    iata: '',
    icao: 'WAWH',
    latitude: -6.1751,
    longitude: 120.4362,
    altitude: 30,
    schengen: false
  },
  {
    id: 3201,
    name: 'Andi Jemma Airport',
    city: 'Masamba',
    country: 'Indonesia',
    iata: 'MXB',
    icao: 'WAWM',
    latitude: -2.55803990364075,
    longitude: 120.323997497559,
    altitude: 164,
    schengen: false
  },
  {
    id: 3202,
    name: 'Soroako Airport',
    city: 'Soroako',
    country: 'Indonesia',
    iata: 'SQR',
    icao: 'WAWS',
    latitude: -2.53119993209839,
    longitude: 121.358001708984,
    altitude: 1388,
    schengen: false
  },
  {
    id: 3203,
    name: 'Pongtiku Airport',
    city: 'Makale',
    country: 'Indonesia',
    iata: 'TTR',
    icao: 'WAWT',
    latitude: -3.0447399616241,
    longitude: 119.82199859619,
    altitude: 2884,
    schengen: false
  },
  {
    id: 3204,
    name: 'Wolter Monginsidi Airport',
    city: 'Kendari',
    country: 'Indonesia',
    iata: 'KDI',
    icao: 'WAWW',
    latitude: -4.08161020278931,
    longitude: 122.417999267578,
    altitude: 538,
    schengen: false
  },
  {
    id: 3205,
    name: 'Dominique Edward Osok Airport',
    city: 'Sorong',
    country: 'Indonesia',
    iata: 'SOQ',
    icao: 'WAXX',
    latitude: -0.894,
    longitude: 131.287,
    altitude: 10,
    schengen: false
  },
  {
    id: 3206,
    name: 'Nusawiru Airport',
    city: 'Nusawiru',
    country: 'Indonesia',
    iata: '',
    icao: 'WI1A',
    latitude: -7.72038984298706,
    longitude: 108.48999786377,
    altitude: 16,
    schengen: false
  },
  {
    id: 3207,
    name: 'Radin Inten II (Branti) Airport',
    city: 'Bandar Lampung-Sumatra Island',
    country: 'Indonesia',
    iata: 'TKG',
    icao: 'WIAT',
    latitude: -5.240556,
    longitude: 105.175556,
    altitude: 282,
    schengen: false
  },
  {
    id: 3208,
    name: 'Sultan Syarif Kasim Ii (Simpang Tiga) Airport',
    city: 'Pekanbaru',
    country: 'Indonesia',
    iata: 'PKU',
    icao: 'WIBB',
    latitude: 0.460786014795303,
    longitude: 101.444999694824,
    altitude: 102,
    schengen: false
  },
  {
    id: 3209,
    name: 'Pinang Kampai Airport',
    city: 'Dumai',
    country: 'Indonesia',
    iata: 'DUM',
    icao: 'WIBD',
    latitude: 1.60919,
    longitude: 101.433998,
    altitude: 55,
    schengen: false
  },
  {
    id: 3210,
    name: 'Rokot Airport',
    city: 'Sipora',
    country: 'Indonesia',
    iata: 'RKO',
    icao: 'WIBR',
    latitude: -2.0991199016571,
    longitude: 99.7006988525391,
    altitude: 1,
    schengen: false
  },
  {
    id: 3211,
    name: 'Sei Bati Airport',
    city: 'Tanjung Balai Karimun',
    country: 'Indonesia',
    iata: 'TJB',
    icao: 'WIBT',
    latitude: 1.0527,
    longitude: 103.3931,
    altitude: 33,
    schengen: false
  },
  {
    id: 3212,
    name: 'Budiarto Airport',
    city: 'Tangerang',
    country: 'Indonesia',
    iata: '',
    icao: 'WICB',
    latitude: -6.293171,
    longitude: 106.57,
    altitude: 151,
    schengen: false
  },
  {
    id: 3213,
    name: 'Husein Sastranegara International Airport',
    city: 'Bandung',
    country: 'Indonesia',
    iata: 'BDO',
    icao: 'WICC',
    latitude: -6.90062999725342,
    longitude: 107.575996398926,
    altitude: 2436,
    schengen: false
  },
  {
    id: 3214,
    name: 'Penggung Airport',
    city: 'Cirebon',
    country: 'Indonesia',
    iata: 'CBN',
    icao: 'WICD',
    latitude: -6.75614023209,
    longitude: 108.540000916,
    altitude: 89,
    schengen: false
  },
  {
    id: 3215,
    name: 'Cibeureum Airport',
    city: 'Tasikmalaya',
    country: 'Indonesia',
    iata: 'TSY',
    icao: 'WICM',
    latitude: -7.34660005569,
    longitude: 108.246002197,
    altitude: 1148,
    schengen: false
  },
  {
    id: 3216,
    name: 'Hang Nadim International Airport',
    city: 'Batam',
    country: 'Indonesia',
    iata: 'BTH',
    icao: 'WIDD',
    latitude: 1.12102997303,
    longitude: 104.119003296,
    altitude: 126,
    schengen: false
  },
  {
    id: 3217,
    name: 'Pasir Pangaraan Airport',
    city: 'Pasir Pangaraian',
    country: 'Indonesia',
    iata: 'PPR',
    icao: 'WIDE',
    latitude: 0.8454310297966,
    longitude: 100.370002746582,
    altitude: 151,
    schengen: false
  },
  {
    id: 3218,
    name: 'Raja Haji Fisabilillah International Airport',
    city: 'Tanjung Pinang',
    country: 'Indonesia',
    iata: 'TNJ',
    icao: 'WIDN',
    latitude: 0.922683000565,
    longitude: 104.531997681,
    altitude: 52,
    schengen: false
  },
  {
    id: 3219,
    name: 'Dabo Airport',
    city: 'Singkep',
    country: 'Indonesia',
    iata: 'SIQ',
    icao: 'WIDS',
    latitude: -0.47918900847435,
    longitude: 104.579002380371,
    altitude: 95,
    schengen: false
  },
  {
    id: 3220,
    name: 'Halim Perdanakusuma International Airport',
    city: 'Jakarta',
    country: 'Indonesia',
    iata: 'HLP',
    icao: 'WIHH',
    latitude: -6.26661014556885,
    longitude: 106.890998840332,
    altitude: 84,
    schengen: false
  },
  {
    id: 3221,
    name: 'Tunggul Wulung Airport',
    city: 'Cilacap',
    country: 'Indonesia',
    iata: 'CXP',
    icao: 'WIHL',
    latitude: -7.64506006241,
    longitude: 109.033996582,
    altitude: 69,
    schengen: false
  },
  {
    id: 3222,
    name: 'Pondok Cabe Air Base',
    city: 'Jakarta',
    country: 'Indonesia',
    iata: 'PCB',
    icao: 'WIHP',
    latitude: -6.33695983886719,
    longitude: 106.764999389648,
    altitude: 200,
    schengen: false
  },
  {
    id: 3223,
    name: 'Soekarno-Hatta International Airport',
    city: 'Jakarta',
    country: 'Indonesia',
    iata: 'CGK',
    icao: 'WIII',
    latitude: -6.1255698204,
    longitude: 106.65599823,
    altitude: 34,
    schengen: false
  },
  {
    id: 3224,
    name: 'Binaka Airport',
    city: 'Gunung Sitoli',
    country: 'Indonesia',
    iata: 'GNS',
    icao: 'WIMB',
    latitude: 1.16638,
    longitude: 97.704697,
    altitude: 20,
    schengen: false
  },
  {
    id: 3225,
    name: 'Aek Godang Airport',
    city: 'Padang Sidempuan',
    country: 'Indonesia',
    iata: 'AEG',
    icao: 'WIME',
    latitude: 1.4001,
    longitude: 99.430496,
    altitude: 922,
    schengen: false
  },
  {
    id: 3226,
    name: 'Tabing Airport',
    city: 'Padang',
    country: 'Indonesia',
    iata: '',
    icao: 'WIMG',
    latitude: -0.874989,
    longitude: 100.351997,
    altitude: 9,
    schengen: false
  },
  {
    id: 3227,
    name: 'Soewondo Air Force Base',
    city: 'Medan',
    country: 'Indonesia',
    iata: 'MES',
    icao: 'WIMK',
    latitude: 3.559167,
    longitude: 98.671111,
    altitude: 114,
    schengen: false
  },
  {
    id: 3228,
    name: 'Kualanamu International Airport',
    city: 'Medan',
    country: 'Indonesia',
    iata: 'KNO',
    icao: 'WIMM',
    latitude: 3.642222,
    longitude: 98.885278,
    altitude: 23,
    schengen: false
  },
  {
    id: 3229,
    name: 'Silangit Airport',
    city: 'Siborong-Borong',
    country: 'Indonesia',
    iata: 'DTB',
    icao: 'WIMN',
    latitude: 2.25973,
    longitude: 98.991898,
    altitude: 4700,
    schengen: false
  },
  {
    id: 3230,
    name: 'Dr Ferdinand Lumban Tobing Airport',
    city: 'Sibolga',
    country: 'Indonesia',
    iata: 'FLZ',
    icao: 'WIMS',
    latitude: 1.55594,
    longitude: 98.888901,
    altitude: 33,
    schengen: false
  },
  {
    id: 3231,
    name: 'Buluh Tumbang (H A S Hanandjoeddin) Airport',
    city: 'Tanjung Pandan',
    country: 'Indonesia',
    iata: 'TJQ',
    icao: 'WIOD',
    latitude: -2.74571990967,
    longitude: 107.754997253,
    altitude: 164,
    schengen: false
  },
  {
    id: 3232,
    name: 'Nanga Pinoh Airport',
    city: 'Nangapinoh',
    country: 'Indonesia',
    iata: 'NPO',
    icao: 'WIOG',
    latitude: -0.34886899590492,
    longitude: 111.74800109863,
    altitude: 123,
    schengen: false
  },
  {
    id: 3233,
    name: 'Ketapang(Rahadi Usman) Airport',
    city: 'Ketapang',
    country: 'Indonesia',
    iata: 'KTG',
    icao: 'WIOK',
    latitude: -1.81664001941681,
    longitude: 109.962997436523,
    altitude: 46,
    schengen: false
  },
  {
    id: 3234,
    name: 'Tarempa Airport',
    city: 'Anambas Islands',
    country: 'Indonesia',
    iata: 'MWK',
    icao: 'WIOM',
    latitude: 3.34811997413635,
    longitude: 106.258003234863,
    altitude: 10,
    schengen: false
  },
  {
    id: 3235,
    name: 'Ranai Airport',
    city: 'Ranai-Natuna Besar Island',
    country: 'Indonesia',
    iata: 'NTX',
    icao: 'WION',
    latitude: 3.90871,
    longitude: 108.388,
    altitude: 7,
    schengen: false
  },
  {
    id: 3236,
    name: 'Supadio Airport',
    city: 'Pontianak',
    country: 'Indonesia',
    iata: 'PNK',
    icao: 'WIOO',
    latitude: -0.150710999965668,
    longitude: 109.403999328613,
    altitude: 10,
    schengen: false
  },
  {
    id: 3237,
    name: 'Pangsuma Airport',
    city: 'Putussibau-Borneo Island',
    country: 'Indonesia',
    iata: 'PSU',
    icao: 'WIOP',
    latitude: 0.83557802438736,
    longitude: 112.936996459961,
    altitude: 297,
    schengen: false
  },
  {
    id: 3238,
    name: 'Sintang(Susilo) Airport',
    city: 'Sintang-Borneo Island',
    country: 'Indonesia',
    iata: 'SQG',
    icao: 'WIOS',
    latitude: 0.063619002699852,
    longitude: 111.472999572754,
    altitude: 98,
    schengen: false
  },
  {
    id: 3239,
    name: 'Sultan Thaha Airport',
    city: 'Jambi',
    country: 'Indonesia',
    iata: 'DJB',
    icao: 'WIPA',
    latitude: -1.63802003860474,
    longitude: 103.643997192383,
    altitude: 82,
    schengen: false
  },
  {
    id: 3240,
    name: 'Muara Bungo Airport',
    city: 'Muara Bungo',
    country: 'Indonesia',
    iata: '',
    icao: 'WIPI',
    latitude: -1.1278,
    longitude: 102.135,
    altitude: 214,
    schengen: false
  },
  {
    id: 3241,
    name: 'Pangkal Pinang (Depati Amir) Airport',
    city: 'Pangkal Pinang',
    country: 'Indonesia',
    iata: 'PGK',
    icao: 'WIPK',
    latitude: -2.16219997406,
    longitude: 106.138999939,
    altitude: 109,
    schengen: false
  },
  {
    id: 3242,
    name: 'Fatmawati Soekarno Airport',
    city: 'Bengkulu',
    country: 'Indonesia',
    iata: 'BKS',
    icao: 'WIPL',
    latitude: -3.8637,
    longitude: 102.338997,
    altitude: 50,
    schengen: false
  },
  {
    id: 3243,
    name: 'Sultan Mahmud Badaruddin II Airport',
    city: 'Palembang',
    country: 'Indonesia',
    iata: 'PLM',
    icao: 'WIPP',
    latitude: -2.8982501029968,
    longitude: 104.69999694824,
    altitude: 49,
    schengen: false
  },
  {
    id: 3244,
    name: 'Pendopo Airport',
    city: 'Talang Gudang-Sumatra Island',
    country: 'Indonesia',
    iata: 'PDO',
    icao: 'WIPQ',
    latitude: -3.2860701084137,
    longitude: 103.879997253418,
    altitude: 184,
    schengen: false
  },
  {
    id: 3245,
    name: 'Japura Airport',
    city: 'Rengat',
    country: 'Indonesia',
    iata: 'RGT',
    icao: 'WIPR',
    latitude: -0.352807998657227,
    longitude: 102.334999084473,
    altitude: 62,
    schengen: false
  },
  {
    id: 3246,
    name: 'Minangkabau International Airport',
    city: 'Padang',
    country: 'Indonesia',
    iata: 'PDG',
    icao: 'WIPT',
    latitude: -0.786917,
    longitude: 100.280998,
    altitude: 18,
    schengen: false
  },
  {
    id: 3247,
    name: 'Maimun Saleh Airport',
    city: 'Sabang',
    country: 'Indonesia',
    iata: 'SBG',
    icao: 'WITB',
    latitude: 5.87412977219,
    longitude: 95.3396987915,
    altitude: 393,
    schengen: false
  },
  {
    id: 3248,
    name: 'Seunagan Airport',
    city: 'Nagan Raya',
    country: 'Indonesia',
    iata: 'MEQ',
    icao: 'WITC',
    latitude: 4.25,
    longitude: 96.2170028686523,
    altitude: 10,
    schengen: false
  },
  {
    id: 3249,
    name: 'Lasikin Airport',
    city: 'Sinabang',
    country: 'Indonesia',
    iata: '',
    icao: 'WITG',
    latitude: 2.41027998924255,
    longitude: 96.3255996704102,
    altitude: 19,
    schengen: false
  },
  {
    id: 3250,
    name: 'Lhok Sukon Airport',
    city: 'Lhok Sukon',
    country: 'Indonesia',
    iata: 'LSX',
    icao: 'WITL',
    latitude: 5.06950998306274,
    longitude: 97.2592010498047,
    altitude: 28,
    schengen: false
  },
  {
    id: 3251,
    name: 'Malikus Saleh Airport',
    city: 'Lhok Seumawe-Sumatra Island',
    country: 'Indonesia',
    iata: 'LSW',
    icao: 'WITM',
    latitude: 5.22667980194092,
    longitude: 96.9503021240234,
    altitude: 90,
    schengen: false
  },
  {
    id: 3252,
    name: 'Sultan Iskandar Muda International Airport',
    city: 'Banda Aceh',
    country: 'Indonesia',
    iata: 'BTJ',
    icao: 'WITT',
    latitude: 5.52287202401,
    longitude: 95.4206371307,
    altitude: 65,
    schengen: false
  },
  {
    id: 3253,
    name: 'Sanggata/Sangkimah Airport',
    city: 'Sanggata',
    country: 'Indonesia',
    iata: 'SGQ',
    icao: 'WRLA',
    latitude: 0.3847,
    longitude: 117.543,
    altitude: 60,
    schengen: false
  },
  {
    id: 3254,
    name: 'Long Bawan Airport',
    city: 'Long Bawan-Borneo Island',
    country: 'Indonesia',
    iata: 'LBW',
    icao: 'WRLB',
    latitude: 3.9028,
    longitude: 115.6921,
    altitude: 3165,
    schengen: false
  },
  {
    id: 3255,
    name: 'Nunukan Airport',
    city: 'Nunukan-Nunukan Island',
    country: 'Indonesia',
    iata: 'NNX',
    icao: 'WRLF',
    latitude: 4.13333333333,
    longitude: 117.666666667,
    altitude: 30,
    schengen: false
  },
  {
    id: 3256,
    name: 'Long Apung Airport',
    city: 'Long Apung-Borneo Island',
    country: 'Indonesia',
    iata: 'LPU',
    icao: 'WRLP',
    latitude: 1.704486,
    longitude: 114.970297,
    altitude: 627,
    schengen: false
  },
  {
    id: 3257,
    name: 'Abadan Airport',
    city: 'Abadan',
    country: 'Iran',
    iata: 'ABD',
    icao: 'OIAA',
    latitude: 30.371099472,
    longitude: 48.2282981873,
    altitude: 10,
    schengen: false
  },
  {
    id: 3258,
    name: 'Dezful Airport',
    city: 'Dezful',
    country: 'Iran',
    iata: 'DEF',
    icao: 'OIAD',
    latitude: 32.434399,
    longitude: 48.397598,
    altitude: 474,
    schengen: false
  },
  {
    id: 3259,
    name: 'Aghajari Airport',
    city: 'Aghajari',
    country: 'Iran',
    iata: 'AKW',
    icao: 'OIAG',
    latitude: 30.7444,
    longitude: 49.6772,
    altitude: 88,
    schengen: false
  },
  {
    id: 3260,
    name: 'Gachsaran Airport',
    city: 'Gachsaran',
    country: 'Iran',
    iata: 'GCH',
    icao: 'OIAH',
    latitude: 30.337600708,
    longitude: 50.827999115,
    altitude: 2414,
    schengen: false
  },
  {
    id: 3261,
    name: 'Shahid Asyaee Airport',
    city: 'Masjed Soleiman',
    country: 'Iran',
    iata: '',
    icao: 'OIAI',
    latitude: 32.0023994445801,
    longitude: 49.2704010009766,
    altitude: 1206,
    schengen: false
  },
  {
    id: 3262,
    name: 'Omidiyeh Airport',
    city: 'Omidyeh',
    country: 'Iran',
    iata: 'OMI',
    icao: 'OIAJ',
    latitude: 30.8351993560791,
    longitude: 49.5349006652832,
    altitude: 85,
    schengen: false
  },
  {
    id: 3263,
    name: 'Mahshahr Airport',
    city: 'Bandar Mahshahr',
    country: 'Iran',
    iata: 'MRX',
    icao: 'OIAM',
    latitude: 30.5562000274658,
    longitude: 49.1519012451172,
    altitude: 8,
    schengen: false
  },
  {
    id: 3264,
    name: 'Ahwaz Airport',
    city: 'Ahwaz',
    country: 'Iran',
    iata: 'AWZ',
    icao: 'OIAW',
    latitude: 31.3374004364,
    longitude: 48.7620010376,
    altitude: 66,
    schengen: false
  },
  {
    id: 3265,
    name: 'Abumusa Island Airport',
    city: 'Abumusa I.',
    country: 'Iran',
    iata: 'AEU',
    icao: 'OIBA',
    latitude: 25.8756999969482,
    longitude: 55.0330009460449,
    altitude: 23,
    schengen: false
  },
  {
    id: 3266,
    name: 'Bushehr Airport',
    city: 'Bushehr',
    country: 'Iran',
    iata: 'BUZ',
    icao: 'OIBB',
    latitude: 28.9447994232,
    longitude: 50.8345985413,
    altitude: 68,
    schengen: false
  },
  {
    id: 3267,
    name: 'Bastak Airport',
    city: 'Bastak',
    country: 'Iran',
    iata: '',
    icao: 'OIBH',
    latitude: 27.2126998901367,
    longitude: 54.3185997009277,
    altitude: 1350,
    schengen: false
  },
  {
    id: 3268,
    name: 'Asaloyeh Airport',
    city: 'Golbandi',
    country: 'Iran',
    iata: '',
    icao: 'OIBI',
    latitude: 27.4814,
    longitude: 52.615501,
    altitude: 15,
    schengen: false
  },
  {
    id: 3269,
    name: 'Jam Airport',
    city: 'Kangan',
    country: 'Iran',
    iata: 'KNR',
    icao: 'OIBJ',
    latitude: 27.820499420166,
    longitude: 52.3521995544434,
    altitude: 2173,
    schengen: false
  },
  {
    id: 3270,
    name: 'Kish International Airport',
    city: 'Kish Island',
    country: 'Iran',
    iata: 'KIH',
    icao: 'OIBK',
    latitude: 26.5261993408,
    longitude: 53.9802017212,
    altitude: 101,
    schengen: false
  },
  {
    id: 3271,
    name: 'Bandar Lengeh Airport',
    city: 'Bandar Lengeh',
    country: 'Iran',
    iata: 'BDH',
    icao: 'OIBL',
    latitude: 26.531999588,
    longitude: 54.824798584,
    altitude: 67,
    schengen: false
  },
  {
    id: 3272,
    name: 'Persian Gulf International Airport',
    city: 'Khalije Fars',
    country: 'Iran',
    iata: 'PGU',
    icao: 'OIBP',
    latitude: 27.379601,
    longitude: 52.737701,
    altitude: 27,
    schengen: false
  },
  {
    id: 3273,
    name: 'Khark Island Airport',
    city: 'Khark Island',
    country: 'Iran',
    iata: 'KHK',
    icao: 'OIBQ',
    latitude: 29.2602996826172,
    longitude: 50.3238983154297,
    altitude: 17,
    schengen: false
  },
  {
    id: 3274,
    name: 'Sirri Island Airport',
    city: 'Siri Island',
    country: 'Iran',
    iata: 'SXI',
    icao: 'OIBS',
    latitude: 25.908899307251,
    longitude: 54.5393981933594,
    altitude: 43,
    schengen: false
  },
  {
    id: 3275,
    name: 'Lavan Island Airport',
    city: 'Lavan Island',
    country: 'Iran',
    iata: 'LVP',
    icao: 'OIBV',
    latitude: 26.8103008270264,
    longitude: 53.3563003540039,
    altitude: 76,
    schengen: false
  },
  {
    id: 3276,
    name: 'Shahid Ashrafi Esfahani Airport',
    city: 'Bakhtaran',
    country: 'Iran',
    iata: 'KSH',
    icao: 'OICC',
    latitude: 34.3459014893,
    longitude: 47.1581001282,
    altitude: 4307,
    schengen: false
  },
  {
    id: 3277,
    name: 'Ilam Airport',
    city: 'Ilam',
    country: 'Iran',
    iata: 'IIL',
    icao: 'OICI',
    latitude: 33.5866012573242,
    longitude: 46.4048004150391,
    altitude: 4404,
    schengen: false
  },
  {
    id: 3278,
    name: 'Khoram Abad Airport',
    city: 'Khorram Abad',
    country: 'Iran',
    iata: 'KHD',
    icao: 'OICK',
    latitude: 33.4353981018066,
    longitude: 48.282901763916,
    altitude: 3782,
    schengen: false
  },
  {
    id: 3279,
    name: 'Sanandaj Airport',
    city: 'Sanandaj',
    country: 'Iran',
    iata: 'SDG',
    icao: 'OICS',
    latitude: 35.2458992004395,
    longitude: 47.0092010498047,
    altitude: 4522,
    schengen: false
  },
  {
    id: 3280,
    name: 'Hesa Airport',
    city: 'Daran',
    country: 'Iran',
    iata: 'IFH',
    icao: 'OIFE',
    latitude: 32.9289016723633,
    longitude: 51.5611000061035,
    altitude: 5256,
    schengen: false
  },
  {
    id: 3281,
    name: 'Shahid Vatan Pour Air Base',
    city: 'Esfahan',
    country: 'Iran',
    iata: '',
    icao: 'OIFH',
    latitude: 32.5670013427734,
    longitude: 51.6916007995606,
    altitude: 5310,
    schengen: false
  },
  {
    id: 3282,
    name: 'Kashan Airport',
    city: 'Kashan',
    country: 'Iran',
    iata: '',
    icao: 'OIFK',
    latitude: 33.8953018188477,
    longitude: 51.5769996643066,
    altitude: 3465,
    schengen: false
  },
  {
    id: 3283,
    name: 'Esfahan Shahid Beheshti International Airport',
    city: 'Esfahan',
    country: 'Iran',
    iata: 'IFN',
    icao: 'OIFM',
    latitude: 32.7508010864258,
    longitude: 51.8613014221191,
    altitude: 5059,
    schengen: false
  },
  {
    id: 3284,
    name: 'Badr Air Base',
    city: 'Sepah',
    country: 'Iran',
    iata: '',
    icao: 'OIFP',
    latitude: 32.6211013793945,
    longitude: 51.6969985961914,
    altitude: 5242,
    schengen: false
  },
  {
    id: 3285,
    name: 'Shahrekord Airport',
    city: 'Shahre Kord',
    country: 'Iran',
    iata: 'CQD',
    icao: 'OIFS',
    latitude: 32.2971992493,
    longitude: 50.8422012329,
    altitude: 6723,
    schengen: false
  },
  {
    id: 3286,
    name: 'Sardar-e-Jangal Airport',
    city: 'Rasht',
    country: 'Iran',
    iata: 'RAS',
    icao: 'OIGG',
    latitude: 37.323333,
    longitude: 49.617778,
    altitude: -40,
    schengen: false
  },
  {
    id: 3287,
    name: 'Hamadan Airport',
    city: 'Hamadan',
    country: 'Iran',
    iata: 'HDM',
    icao: 'OIHH',
    latitude: 34.8692016601562,
    longitude: 48.5525016784668,
    altitude: 5755,
    schengen: false
  },
  {
    id: 3288,
    name: 'Arak Airport',
    city: 'Arak',
    country: 'Iran',
    iata: 'AJK',
    icao: 'OIHR',
    latitude: 34.1380996704102,
    longitude: 49.8473014831543,
    altitude: 5440,
    schengen: false
  },
  {
    id: 3289,
    name: 'Hamadan Air Base',
    city: 'Hamadan',
    country: 'Iran',
    iata: '',
    icao: 'OIHS',
    latitude: 35.2116012573242,
    longitude: 48.6534004211426,
    altitude: 5609,
    schengen: false
  },
  {
    id: 3290,
    name: 'Ghazvin Azadi Airport',
    city: 'Abe-ali',
    country: 'Iran',
    iata: '',
    icao: 'OIIA',
    latitude: 35.9520988464355,
    longitude: 50.4508018493652,
    altitude: 3769,
    schengen: false
  },
  {
    id: 3291,
    name: 'Kushke Nosrat Airport',
    city: 'Kushke Nosrat',
    country: 'Iran',
    iata: '',
    icao: 'OIIC',
    latitude: 34.984001159668,
    longitude: 50.8061981201172,
    altitude: 3008,
    schengen: false
  },
  {
    id: 3292,
    name: 'Doshan Tappeh Air Base',
    city: 'Teheran',
    country: 'Iran',
    iata: '',
    icao: 'OIID',
    latitude: 35.7029991149902,
    longitude: 51.4751014709473,
    altitude: 4046,
    schengen: false
  },
  {
    id: 3293,
    name: 'Imam Khomeini International Airport',
    city: 'Tehran',
    country: 'Iran',
    iata: 'IKA',
    icao: 'OIIE',
    latitude: 35.4160995483398,
    longitude: 51.1521987915039,
    altitude: 3305,
    schengen: false
  },
  {
    id: 3294,
    name: 'Ghale Morghi Airport',
    city: 'Teheran',
    country: 'Iran',
    iata: '',
    icao: 'OIIG',
    latitude: 35.6447982788086,
    longitude: 51.3806991577148,
    altitude: 3627,
    schengen: false
  },
  {
    id: 3295,
    name: 'Mehrabad International Airport',
    city: 'Teheran',
    country: 'Iran',
    iata: 'THR',
    icao: 'OIII',
    latitude: 35.6892013549805,
    longitude: 51.3134002685547,
    altitude: 3962,
    schengen: false
  },
  {
    id: 3296,
    name: 'Qazvin Airport',
    city: 'Ghazvin',
    country: 'Iran',
    iata: 'GZW',
    icao: 'OIIK',
    latitude: 36.240101,
    longitude: 50.0471,
    altitude: 4184,
    schengen: false
  },
  {
    id: 3297,
    name: 'Naja Airport',
    city: 'Khoram Dareh',
    country: 'Iran',
    iata: '',
    icao: 'OIIM',
    latitude: 35.7762985229492,
    longitude: 50.8810005187988,
    altitude: 4040,
    schengen: false
  },
  {
    id: 3298,
    name: 'Payam International Airport',
    city: 'Karaj',
    country: 'Iran',
    iata: 'PYK',
    icao: 'OIIP',
    latitude: 35.776100158691,
    longitude: 50.826698303223,
    altitude: 4170,
    schengen: false
  },
  {
    id: 3299,
    name: 'Bandar Abbas International Airport',
    city: 'Bandar Abbas',
    country: 'Iran',
    iata: 'BND',
    icao: 'OIKB',
    latitude: 27.2182998657227,
    longitude: 56.377799987793,
    altitude: 22,
    schengen: false
  },
  {
    id: 3300,
    name: 'Jiroft Airport',
    city: 'Jiroft',
    country: 'Iran',
    iata: 'JYR',
    icao: 'OIKJ',
    latitude: 28.7269001007,
    longitude: 57.67029953,
    altitude: 2663,
    schengen: false
  },
  {
    id: 3301,
    name: 'Kerman Airport',
    city: 'Kerman',
    country: 'Iran',
    iata: 'KER',
    icao: 'OIKK',
    latitude: 30.2744007111,
    longitude: 56.9510993958,
    altitude: 5741,
    schengen: false
  },
  {
    id: 3302,
    name: 'Bam Airport',
    city: 'Bam',
    country: 'Iran',
    iata: 'BXR',
    icao: 'OIKM',
    latitude: 29.0841999053955,
    longitude: 58.4500007629395,
    altitude: 3231,
    schengen: false
  },
  {
    id: 3303,
    name: 'Havadarya Airport',
    city: 'Bandar Abbas',
    country: 'Iran',
    iata: 'HDR',
    icao: 'OIKP',
    latitude: 27.1583003997803,
    longitude: 56.1725006103516,
    altitude: 19,
    schengen: false
  },
  {
    id: 3304,
    name: 'Dayrestan Airport',
    city: 'Gheshm I.',
    country: 'Iran',
    iata: '',
    icao: 'OIKQ',
    latitude: 26.7546005249023,
    longitude: 55.902400970459,
    altitude: 45,
    schengen: false
  },
  {
    id: 3305,
    name: 'Rafsanjan Airport',
    city: 'Rafsanjan',
    country: 'Iran',
    iata: 'RJN',
    icao: 'OIKR',
    latitude: 30.297700881958,
    longitude: 56.0511016845703,
    altitude: 5298,
    schengen: false
  },
  {
    id: 3306,
    name: 'Sirjan Airport',
    city: 'Sirjan',
    country: 'Iran',
    iata: 'SYJ',
    icao: 'OIKY',
    latitude: 29.5508995056152,
    longitude: 55.6726989746094,
    altitude: 5846,
    schengen: false
  },
  {
    id: 3307,
    name: 'Birjand Airport',
    city: 'Birjand',
    country: 'Iran',
    iata: 'XBJ',
    icao: 'OIMB',
    latitude: 32.8981018066406,
    longitude: 59.2661018371582,
    altitude: 4952,
    schengen: false
  },
  {
    id: 3308,
    name: 'Sarakhs Airport',
    city: 'Sarakhs',
    country: 'Iran',
    iata: 'CKT',
    icao: 'OIMC',
    latitude: 36.5012016296387,
    longitude: 61.0648994445801,
    altitude: 945,
    schengen: false
  },
  {
    id: 3309,
    name: 'Shahroud Airport',
    city: 'Emam Shahr',
    country: 'Iran',
    iata: 'RUD',
    icao: 'OIMJ',
    latitude: 36.4253005981,
    longitude: 55.1041984558,
    altitude: 4215,
    schengen: false
  },
  {
    id: 3310,
    name: 'Mashhad International Airport',
    city: 'Mashhad',
    country: 'Iran',
    iata: 'MHD',
    icao: 'OIMM',
    latitude: 36.2351989746094,
    longitude: 59.640998840332,
    altitude: 3263,
    schengen: false
  },
  {
    id: 3311,
    name: 'Bojnord Airport',
    city: 'Bojnourd',
    country: 'Iran',
    iata: 'BJB',
    icao: 'OIMN',
    latitude: 37.4930000305176,
    longitude: 57.3082008361816,
    altitude: 3499,
    schengen: false
  },
  {
    id: 3312,
    name: 'Sabzevar National Airport',
    city: 'Sabzevar',
    country: 'Iran',
    iata: 'AFZ',
    icao: 'OIMS',
    latitude: 36.168098449707,
    longitude: 57.5951995849609,
    altitude: 3010,
    schengen: false
  },
  {
    id: 3313,
    name: 'Tabas Airport',
    city: 'Tabas',
    country: 'Iran',
    iata: 'TCX',
    icao: 'OIMT',
    latitude: 33.6678009033,
    longitude: 56.8927001953,
    altitude: 2312,
    schengen: false
  },
  {
    id: 3314,
    name: 'Kalaleh Airport',
    city: 'Kalaleh',
    country: 'Iran',
    iata: 'KLM',
    icao: 'OINE',
    latitude: 37.3833007812,
    longitude: 55.4519996643,
    altitude: 425,
    schengen: false
  },
  {
    id: 3315,
    name: 'Gorgan Airport',
    city: 'Gorgan',
    country: 'Iran',
    iata: 'GBT',
    icao: 'OING',
    latitude: 36.9094009399,
    longitude: 54.4012985229,
    altitude: -24,
    schengen: false
  },
  {
    id: 3316,
    name: 'Noshahr Airport',
    city: 'Noshahr',
    country: 'Iran',
    iata: 'NSH',
    icao: 'OINN',
    latitude: 36.6632995605469,
    longitude: 51.4646987915039,
    altitude: -61,
    schengen: false
  },
  {
    id: 3317,
    name: 'Ramsar Airport',
    city: 'Ramsar',
    country: 'Iran',
    iata: 'RZR',
    icao: 'OINR',
    latitude: 36.9099006652832,
    longitude: 50.6795997619629,
    altitude: -70,
    schengen: false
  },
  {
    id: 3318,
    name: 'Dasht-e Naz Airport',
    city: 'Dasht-e-naz',
    country: 'Iran',
    iata: 'SRY',
    icao: 'OINZ',
    latitude: 36.635799408,
    longitude: 53.1935997009,
    altitude: 35,
    schengen: false
  },
  {
    id: 3319,
    name: 'Fasa Airport',
    city: 'Fasa',
    country: 'Iran',
    iata: 'FAZ',
    icao: 'OISF',
    latitude: 28.8917999267578,
    longitude: 53.7233009338379,
    altitude: 4261,
    schengen: false
  },
  {
    id: 3320,
    name: 'Jahrom Airport',
    city: 'Jahrom',
    country: 'Iran',
    iata: 'JAR',
    icao: 'OISJ',
    latitude: 28.5867004395,
    longitude: 53.5791015625,
    altitude: 3358,
    schengen: false
  },
  {
    id: 3321,
    name: 'Lar Airport',
    city: 'Lar',
    country: 'Iran',
    iata: 'LRR',
    icao: 'OISL',
    latitude: 27.6746997833,
    longitude: 54.3833007812,
    altitude: 2641,
    schengen: false
  },
  {
    id: 3322,
    name: 'Lamerd Airport',
    city: 'Lamerd',
    country: 'Iran',
    iata: 'LFM',
    icao: 'OISR',
    latitude: 27.3726997375,
    longitude: 53.1888008118,
    altitude: 1337,
    schengen: false
  },
  {
    id: 3323,
    name: 'Shiraz Shahid Dastghaib International Airport',
    city: 'Shiraz',
    country: 'Iran',
    iata: 'SYZ',
    icao: 'OISS',
    latitude: 29.5391998291016,
    longitude: 52.5898017883301,
    altitude: 4920,
    schengen: false
  },
  {
    id: 3324,
    name: 'Yasouj Airport',
    city: 'Yasuj',
    country: 'Iran',
    iata: 'YES',
    icao: 'OISY',
    latitude: 30.700500488281,
    longitude: 51.545101165771,
    altitude: 5939,
    schengen: false
  },
  {
    id: 3325,
    name: 'Khoy Airport',
    city: 'Khoy',
    country: 'Iran',
    iata: 'KHY',
    icao: 'OITK',
    latitude: 38.4275016784668,
    longitude: 44.9735984802246,
    altitude: 3981,
    schengen: false
  },
  {
    id: 3326,
    name: 'Ardabil Airport',
    city: 'Ardabil',
    country: 'Iran',
    iata: 'ADU',
    icao: 'OITL',
    latitude: 38.3256988525,
    longitude: 48.4244003296,
    altitude: 4315,
    schengen: false
  },
  {
    id: 3327,
    name: 'Sahand Airport',
    city: 'Maragheh',
    country: 'Iran',
    iata: 'ACP',
    icao: 'OITM',
    latitude: 37.3479995727539,
    longitude: 46.1278991699219,
    altitude: 4396,
    schengen: false
  },
  {
    id: 3328,
    name: 'Parsabade Moghan Airport',
    city: 'Parsabad',
    country: 'Iran',
    iata: 'PFQ',
    icao: 'OITP',
    latitude: 39.60359954834,
    longitude: 47.881500244141,
    altitude: 251,
    schengen: false
  },
  {
    id: 3329,
    name: 'Urmia Airport',
    city: 'Uromiyeh',
    country: 'Iran',
    iata: 'OMH',
    icao: 'OITR',
    latitude: 37.6680984497,
    longitude: 45.0686988831,
    altitude: 4343,
    schengen: false
  },
  {
    id: 3330,
    name: 'Tabriz International Airport',
    city: 'Tabriz',
    country: 'Iran',
    iata: 'TBZ',
    icao: 'OITT',
    latitude: 38.1338996887207,
    longitude: 46.2350006103516,
    altitude: 4459,
    schengen: false
  },
  {
    id: 3331,
    name: 'Zanjan Airport',
    city: 'Zanjan',
    country: 'Iran',
    iata: 'JWN',
    icao: 'OITZ',
    latitude: 36.7737007141,
    longitude: 48.3594017029,
    altitude: 5382,
    schengen: false
  },
  {
    id: 3332,
    name: 'Shahid Sadooghi Airport',
    city: 'Yazd',
    country: 'Iran',
    iata: 'AZD',
    icao: 'OIYY',
    latitude: 31.9048995972,
    longitude: 54.2765007019,
    altitude: 4054,
    schengen: false
  },
  {
    id: 3333,
    name: 'Zabol Airport',
    city: 'Zabol',
    country: 'Iran',
    iata: 'ACZ',
    icao: 'OIZB',
    latitude: 31.0983009338379,
    longitude: 61.5438995361328,
    altitude: 1628,
    schengen: false
  },
  {
    id: 3334,
    name: 'Konarak Airport',
    city: 'Chah Bahar',
    country: 'Iran',
    iata: 'ZBR',
    icao: 'OIZC',
    latitude: 25.4433002472,
    longitude: 60.3820991516,
    altitude: 43,
    schengen: false
  },
  {
    id: 3335,
    name: 'Zahedan International Airport',
    city: 'Zahedan',
    country: 'Iran',
    iata: 'ZAH',
    icao: 'OIZH',
    latitude: 29.475700378418,
    longitude: 60.9062004089356,
    altitude: 4564,
    schengen: false
  },
  {
    id: 3336,
    name: 'Iran Shahr Airport',
    city: 'Iran Shahr',
    country: 'Iran',
    iata: 'IHR',
    icao: 'OIZI',
    latitude: 27.2360992432,
    longitude: 60.7200012207,
    altitude: 2040,
    schengen: false
  },
  {
    id: 3337,
    name: 'Jask Airport',
    city: 'Jask',
    country: 'Iran',
    iata: '',
    icao: 'OIZJ',
    latitude: 25.653600692749,
    longitude: 57.7993011474609,
    altitude: 19,
    schengen: false
  },
  {
    id: 3338,
    name: 'Saravan Airport',
    city: 'Saravan',
    country: 'Iran',
    iata: '',
    icao: 'OIZS',
    latitude: 27.4193000793457,
    longitude: 62.3157997131348,
    altitude: 3930,
    schengen: false
  },
  {
    id: 3339,
    name: 'Al Asad Air Base',
    city: 'Al Asad',
    country: 'Iraq',
    iata: 'IQA',
    icao: 'ORAA',
    latitude: 33.7855987549,
    longitude: 42.4412002563,
    altitude: 618,
    schengen: false
  },
  {
    id: 3340,
    name: 'Al Taqaddum Air Base',
    city: 'Al Taqaddum',
    country: 'Iraq',
    iata: 'TQD',
    icao: 'ORAT',
    latitude: 33.33810043335,
    longitude: 43.597099304199,
    altitude: 275,
    schengen: false
  },
  {
    id: 3341,
    name: 'Joint Base Balad',
    city: 'Al Bakr',
    country: 'Iraq',
    iata: 'XQC',
    icao: 'ORBD',
    latitude: 33.9402008057,
    longitude: 44.3615989685,
    altitude: 161,
    schengen: false
  },
  {
    id: 3342,
    name: 'Baghdad International Airport',
    city: 'Baghdad',
    country: 'Iraq',
    iata: 'BGW',
    icao: 'ORBI',
    latitude: 33.2625007629,
    longitude: 44.2346000671,
    altitude: 114,
    schengen: false
  },
  {
    id: 3343,
    name: 'Mosul International Airport',
    city: 'Mosul',
    country: 'Iraq',
    iata: 'OSM',
    icao: 'ORBM',
    latitude: 36.305801,
    longitude: 43.1474,
    altitude: 719,
    schengen: false
  },
  {
    id: 3344,
    name: 'Erbil International Airport',
    city: 'Erbil',
    country: 'Iraq',
    iata: 'EBL',
    icao: 'ORER',
    latitude: 36.2375984191895,
    longitude: 43.9631996154785,
    altitude: 1341,
    schengen: false
  },
  {
    id: 3345,
    name: 'Kirkuk Air Base',
    city: 'Kirkuk',
    country: 'Iraq',
    iata: 'KIK',
    icao: 'ORKK',
    latitude: 35.4695014953613,
    longitude: 44.3488998413086,
    altitude: 1061,
    schengen: false
  },
  {
    id: 3346,
    name: 'Basrah International Airport',
    city: 'Basrah',
    country: 'Iraq',
    iata: 'BSR',
    icao: 'ORMM',
    latitude: 30.5491008758545,
    longitude: 47.6621017456055,
    altitude: 11,
    schengen: false
  },
  {
    id: 3347,
    name: 'Al Najaf International Airport',
    city: 'Najaf',
    country: 'Iraq',
    iata: 'NJF',
    icao: 'ORNI',
    latitude: 31.989853,
    longitude: 44.404317,
    altitude: 103,
    schengen: false
  },
  {
    id: 3348,
    name: 'Qayyarah West Airport',
    city: 'Qayyarah',
    country: 'Iraq',
    iata: 'RQW',
    icao: 'ORQW',
    latitude: 35.76720047,
    longitude: 43.1250991821,
    altitude: 749,
    schengen: false
  },
  {
    id: 3349,
    name: 'Al Sahra Army Air Field',
    city: 'Tikrit',
    country: 'Iraq',
    iata: '',
    icao: 'ORSH',
    latitude: 34.6731986999512,
    longitude: 43.5429992675781,
    altitude: 451,
    schengen: false
  },
  {
    id: 3350,
    name: 'Sulaymaniyah International Airport',
    city: 'Sulaymaniyah',
    country: 'Iraq',
    iata: 'ISU',
    icao: 'ORSU',
    latitude: 35.5617485046,
    longitude: 45.3167381287,
    altitude: 2494,
    schengen: false
  },
  {
    id: 3351,
    name: 'Tall Afar Army Air Field',
    city: 'Tall Afar',
    country: 'Iraq',
    iata: '',
    icao: 'ORTF',
    latitude: 36.2831001281738,
    longitude: 42.4029998779297,
    altitude: 996,
    schengen: false
  },
  {
    id: 3352,
    name: 'Al Taji Army Air Field',
    city: 'Taji',
    country: 'Iraq',
    iata: '',
    icao: 'ORTI',
    latitude: 33.5238990783691,
    longitude: 44.2566986083984,
    altitude: 121,
    schengen: false
  },
  {
    id: 3353,
    name: 'Ali Air Base',
    city: 'Nasiriyah',
    country: 'Iraq',
    iata: '',
    icao: 'ORTL',
    latitude: 30.9358005523682,
    longitude: 46.0900993347168,
    altitude: 20,
    schengen: false
  },
  {
    id: 3354,
    name: 'Ubaydah Bin Al Jarrah Airport',
    city: 'Al Cut',
    country: 'Iraq',
    iata: '',
    icao: 'ORUB',
    latitude: 32.4819984436035,
    longitude: 45.7570991516113,
    altitude: 68,
    schengen: false
  },
  {
    id: 3355,
    name: 'Bantry Aerodrome',
    city: 'Bantry',
    country: 'Ireland',
    iata: 'BYT',
    icao: 'EIBN',
    latitude: 51.6685981750488,
    longitude: -9.48416996002197,
    altitude: 7,
    schengen: false
  },
  {
    id: 3356,
    name: 'Connemara Regional Airport',
    city: 'Indreabhan',
    country: 'Ireland',
    iata: 'NNR',
    icao: 'EICA',
    latitude: 53.2303009033203,
    longitude: -9.46778011322022,
    altitude: 70,
    schengen: false
  },
  {
    id: 3357,
    name: 'Cork Airport',
    city: 'Cork',
    country: 'Ireland',
    iata: 'ORK',
    icao: 'EICK',
    latitude: 51.8413009643555,
    longitude: -8.49110984802246,
    altitude: 502,
    schengen: false
  },
  {
    id: 3358,
    name: 'Galway Airport',
    city: 'Galway',
    country: 'Ireland',
    iata: 'GWY',
    icao: 'EICM',
    latitude: 53.3002014160156,
    longitude: -8.94159030914307,
    altitude: 81,
    schengen: false
  },
  {
    id: 3359,
    name: 'Donegal Airport',
    city: 'Dongloe',
    country: 'Ireland',
    iata: 'CFN',
    icao: 'EIDL',
    latitude: 55.0442008972168,
    longitude: -8.34099960327148,
    altitude: 30,
    schengen: false
  },
  {
    id: 3360,
    name: 'Dublin Airport',
    city: 'Dublin',
    country: 'Ireland',
    iata: 'DUB',
    icao: 'EIDW',
    latitude: 53.421299,
    longitude: -6.27007,
    altitude: 242,
    schengen: false
  },
  {
    id: 3361,
    name: 'Inishmore Aerodrome',
    city: 'Inis Mor',
    country: 'Ireland',
    iata: 'IOR',
    icao: 'EIIM',
    latitude: 53.1067008972168,
    longitude: -9.65361022949219,
    altitude: 24,
    schengen: false
  },
  {
    id: 3362,
    name: 'Inisheer Aerodrome',
    city: 'Inisheer',
    country: 'Ireland',
    iata: 'INQ',
    icao: 'EIIR',
    latitude: 53.0647010803223,
    longitude: -9.51089954376221,
    altitude: 40,
    schengen: false
  },
  {
    id: 3363,
    name: 'Ireland West Knock Airport',
    city: 'Connaught',
    country: 'Ireland',
    iata: 'NOC',
    icao: 'EIKN',
    latitude: 53.9103012084961,
    longitude: -8.81849002838135,
    altitude: 665,
    schengen: false
  },
  {
    id: 3364,
    name: 'Kerry Airport',
    city: 'Kerry',
    country: 'Ireland',
    iata: 'KIR',
    icao: 'EIKY',
    latitude: 52.1809005737305,
    longitude: -9.52377986907959,
    altitude: 112,
    schengen: false
  },
  {
    id: 3365,
    name: 'Casement Air Base',
    city: 'Casement',
    country: 'Ireland',
    iata: '',
    icao: 'EIME',
    latitude: 53.301700592,
    longitude: -6.45133018494,
    altitude: 319,
    schengen: false
  },
  {
    id: 3366,
    name: 'Inishmaan Aerodrome',
    city: 'Inishmaan',
    country: 'Ireland',
    iata: 'IIA',
    icao: 'EIMN',
    latitude: 53.0929985046387,
    longitude: -9.56805992126465,
    altitude: 15,
    schengen: false
  },
  {
    id: 3367,
    name: 'Newcastle Aerodrome',
    city: 'Newcastle',
    country: 'Ireland',
    iata: '',
    icao: 'EINC',
    latitude: 53.0710983276367,
    longitude: -6.04527997970581,
    altitude: 1,
    schengen: false
  },
  {
    id: 3368,
    name: 'Shannon Airport',
    city: 'Shannon',
    country: 'Ireland',
    iata: 'SNN',
    icao: 'EINN',
    latitude: 52.701999664307,
    longitude: -8.9248199462891,
    altitude: 46,
    schengen: false
  },
  {
    id: 3369,
    name: 'Sligo Airport',
    city: 'Sligo',
    country: 'Ireland',
    iata: 'SXL',
    icao: 'EISG',
    latitude: 54.280200958252,
    longitude: -8.5992097854614,
    altitude: 11,
    schengen: false
  },
  {
    id: 3370,
    name: 'Waterford Airport',
    city: 'Waterford',
    country: 'Ireland',
    iata: 'WAT',
    icao: 'EIWF',
    latitude: 52.187198638916,
    longitude: -7.08695983886719,
    altitude: 119,
    schengen: false
  },
  {
    id: 3371,
    name: 'Weston Airport',
    city: 'Leixlip',
    country: 'Ireland',
    iata: '',
    icao: 'EIWT',
    latitude: 53.3521995544,
    longitude: -6.48611021042,
    altitude: 150,
    schengen: false
  },
  {
    id: 3372,
    name: 'Isle of Man Airport',
    city: 'Isle Of Man',
    country: 'Isle of Man',
    iata: 'IOM',
    icao: 'EGNS',
    latitude: 54.0833015441895,
    longitude: -4.6238899230957,
    altitude: 52,
    schengen: false
  },
  {
    id: 3373,
    name: 'Ben Gurion International Airport',
    city: 'Tel-aviv',
    country: 'Israel',
    iata: 'TLV',
    icao: 'LLBG',
    latitude: 32.0113983154297,
    longitude: 34.8866996765137,
    altitude: 135,
    schengen: false
  },
  {
    id: 3374,
    name: 'Beersheba (Teyman) Airport',
    city: 'Beer-sheba',
    country: 'Israel',
    iata: 'BEV',
    icao: 'LLBS',
    latitude: 31.287000656128,
    longitude: 34.722999572754,
    altitude: 656,
    schengen: false
  },
  {
    id: 3375,
    name: 'Tel Nof Air Base',
    city: 'Tel-nof',
    country: 'Israel',
    iata: '',
    icao: 'LLEK',
    latitude: 31.8395004272,
    longitude: 34.8218002319,
    altitude: 193,
    schengen: false
  },
  {
    id: 3376,
    name: 'Ein Shemer Airfield',
    city: 'Eyn-shemer',
    country: 'Israel',
    iata: '',
    icao: 'LLES',
    latitude: 32.4407997131348,
    longitude: 35.0077018737793,
    altitude: 95,
    schengen: false
  },
  {
    id: 3377,
    name: 'Eilat Airport',
    city: 'Elat',
    country: 'Israel',
    iata: 'ETH',
    icao: 'LLET',
    latitude: 29.56130027771,
    longitude: 34.9600982666016,
    altitude: 42,
    schengen: false
  },
  {
    id: 3378,
    name: 'Ein Yahav Airfield',
    city: 'Eyn-yahav',
    country: 'Israel',
    iata: 'EIY',
    icao: 'LLEY',
    latitude: 30.6217002868652,
    longitude: 35.2033004760742,
    altitude: -164,
    schengen: false
  },
  {
    id: 3379,
    name: 'Haifa International Airport',
    city: 'Haifa',
    country: 'Israel',
    iata: 'HFA',
    icao: 'LLHA',
    latitude: 32.809398651123,
    longitude: 35.043098449707,
    altitude: 28,
    schengen: false
  },
  {
    id: 3380,
    name: 'Hatzor Air Base',
    city: 'Haztor',
    country: 'Israel',
    iata: '',
    icao: 'LLHS',
    latitude: 31.7625007629,
    longitude: 34.7271995544,
    altitude: 148,
    schengen: false
  },
  {
    id: 3381,
    name: "Ben Ya'akov Airport",
    city: 'Rosh Pina',
    country: 'Israel',
    iata: 'RPN',
    icao: 'LLIB',
    latitude: 32.9809989929199,
    longitude: 35.5718994140625,
    altitude: 922,
    schengen: false
  },
  {
    id: 3382,
    name: 'Megiddo Airport',
    city: 'Megido Airstrip',
    country: 'Israel',
    iata: '',
    icao: 'LLMG',
    latitude: 32.5973014831543,
    longitude: 35.2288017272949,
    altitude: 200,
    schengen: false
  },
  {
    id: 3383,
    name: 'Bar Yehuda Airfield',
    city: 'Metzada',
    country: 'Israel',
    iata: 'MTZ',
    icao: 'LLMZ',
    latitude: 31.3281993865967,
    longitude: 35.388599395752,
    altitude: -1266,
    schengen: false
  },
  {
    id: 3384,
    name: 'Nevatim Air Base',
    city: 'Nevatim',
    country: 'Israel',
    iata: 'VTM',
    icao: 'LLNV',
    latitude: 31.2082996368,
    longitude: 35.012298584,
    altitude: 1330,
    schengen: false
  },
  {
    id: 3385,
    name: 'Ovda International Airport',
    city: 'Ovda',
    country: 'Israel',
    iata: 'VDA',
    icao: 'LLOV',
    latitude: 29.940299987793,
    longitude: 34.9357986450195,
    altitude: 1492,
    schengen: false
  },
  {
    id: 3386,
    name: 'Ramat David Air Base',
    city: 'Ramat David',
    country: 'Israel',
    iata: '',
    icao: 'LLRD',
    latitude: 32.6651000976562,
    longitude: 35.179500579834,
    altitude: 185,
    schengen: false
  },
  {
    id: 3387,
    name: 'Ramon Air Base',
    city: 'Ramon',
    country: 'Israel',
    iata: 'MIP',
    icao: 'LLRM',
    latitude: 30.7761001586914,
    longitude: 34.6666984558105,
    altitude: 2126,
    schengen: false
  },
  {
    id: 3388,
    name: 'Sde Dov Airport',
    city: 'Tel-aviv',
    country: 'Israel',
    iata: 'SDV',
    icao: 'LLSD',
    latitude: 32.1147003173828,
    longitude: 34.7821998596191,
    altitude: 43,
    schengen: false
  },
  {
    id: 3389,
    name: 'Amendola Air Base',
    city: 'Amendola',
    country: 'Italy',
    iata: '',
    icao: 'LIBA',
    latitude: 41.541401,
    longitude: 15.7181,
    altitude: 183,
    schengen: true
  },
  {
    id: 3390,
    name: 'Crotone Airport',
    city: 'Crotone',
    country: 'Italy',
    iata: 'CRV',
    icao: 'LIBC',
    latitude: 38.9972,
    longitude: 17.0802,
    altitude: 522,
    schengen: true
  },
  {
    id: 3391,
    name: 'Bari Karol Wojtyła Airport',
    city: 'Bari',
    country: 'Italy',
    iata: 'BRI',
    icao: 'LIBD',
    latitude: 41.138901,
    longitude: 16.760599,
    altitude: 177,
    schengen: true
  },
  {
    id: 3392,
    name: 'Foggia "Gino Lisa" Airport',
    city: 'Foggia',
    country: 'Italy',
    iata: 'FOG',
    icao: 'LIBF',
    latitude: 41.432899,
    longitude: 15.535,
    altitude: 265,
    schengen: true
  },
  {
    id: 3393,
    name: 'Taranto-Grottaglie "Marcello Arlotta" Airport',
    city: 'Grottaglie',
    country: 'Italy',
    iata: 'TAR',
    icao: 'LIBG',
    latitude: 40.517502,
    longitude: 17.4032,
    altitude: 215,
    schengen: true
  },
  {
    id: 3394,
    name: 'Lecce Galatina Air Base',
    city: 'Lecce',
    country: 'Italy',
    iata: 'LCC',
    icao: 'LIBN',
    latitude: 40.239201,
    longitude: 18.133301,
    altitude: 156,
    schengen: true
  },
  {
    id: 3395,
    name: 'Pescara International Airport',
    city: 'Pescara',
    country: 'Italy',
    iata: 'PSR',
    icao: 'LIBP',
    latitude: 42.431702,
    longitude: 14.1811,
    altitude: 48,
    schengen: true
  },
  {
    id: 3396,
    name: 'Brindisi – Salento Airport',
    city: 'Brindisi',
    country: 'Italy',
    iata: 'BDS',
    icao: 'LIBR',
    latitude: 40.6576,
    longitude: 17.947001,
    altitude: 47,
    schengen: true
  },
  {
    id: 3397,
    name: 'Gioia Del Colle Air Base',
    city: 'Gioia Del Colle',
    country: 'Italy',
    iata: '',
    icao: 'LIBV',
    latitude: 40.767799,
    longitude: 16.9333,
    altitude: 1187,
    schengen: true
  },
  {
    id: 3398,
    name: 'Lamezia Terme Airport',
    city: 'Lamezia',
    country: 'Italy',
    iata: 'SUF',
    icao: 'LICA',
    latitude: 38.905399,
    longitude: 16.2423,
    altitude: 39,
    schengen: true
  },
  {
    id: 3399,
    name: 'Comiso Airport',
    city: 'Comiso',
    country: 'Italy',
    iata: 'CIY',
    icao: 'LICB',
    latitude: 36.994601,
    longitude: 14.607182,
    altitude: 623,
    schengen: true
  },
  {
    id: 3400,
    name: 'Catania-Fontanarossa Airport',
    city: 'Catania',
    country: 'Italy',
    iata: 'CTA',
    icao: 'LICC',
    latitude: 37.466801,
    longitude: 15.0664,
    altitude: 39,
    schengen: true
  },
  {
    id: 3401,
    name: 'Lampedusa Airport',
    city: 'Lampedusa',
    country: 'Italy',
    iata: 'LMP',
    icao: 'LICD',
    latitude: 35.497898,
    longitude: 12.6181,
    altitude: 70,
    schengen: true
  },
  {
    id: 3402,
    name: 'Pantelleria Airport',
    city: 'Pantelleria',
    country: 'Italy',
    iata: 'PNL',
    icao: 'LICG',
    latitude: 36.816502,
    longitude: 11.9689,
    altitude: 628,
    schengen: true
  },
  {
    id: 3403,
    name: 'Falcone–Borsellino Airport',
    city: 'Palermo',
    country: 'Italy',
    iata: 'PMO',
    icao: 'LICJ',
    latitude: 38.175999,
    longitude: 13.091,
    altitude: 65,
    schengen: true
  },
  {
    id: 3404,
    name: 'Palermo-Boccadifalco Airport',
    city: 'Palermo',
    country: 'Italy',
    iata: '',
    icao: 'LICP',
    latitude: 38.110802,
    longitude: 13.3133,
    altitude: 345,
    schengen: true
  },
  {
    id: 3405,
    name: 'Reggio Calabria Airport',
    city: 'Reggio Calabria',
    country: 'Italy',
    iata: 'REG',
    icao: 'LICR',
    latitude: 38.071201,
    longitude: 15.6516,
    altitude: 96,
    schengen: true
  },
  {
    id: 3406,
    name: 'Vincenzo Florio Airport Trapani-Birgi',
    city: 'Trapani',
    country: 'Italy',
    iata: 'TPS',
    icao: 'LICT',
    latitude: 37.9114,
    longitude: 12.488,
    altitude: 25,
    schengen: true
  },
  {
    id: 3407,
    name: 'Sigonella Navy Air Base',
    city: 'Sigonella',
    country: 'Italy',
    iata: 'NSY',
    icao: 'LICZ',
    latitude: 37.401699,
    longitude: 14.9224,
    altitude: 79,
    schengen: true
  },
  {
    id: 3408,
    name: 'Reggio Emilia Airport',
    city: 'Reggio Emilia',
    country: 'Italy',
    iata: '',
    icao: 'LIDE',
    latitude: 44.698299,
    longitude: 10.6628,
    altitude: 152,
    schengen: true
  },
  {
    id: 3409,
    name: 'Trento-Mattarello Airport',
    city: 'Trento (TN)',
    country: 'Italy',
    iata: '',
    icao: 'LIDT',
    latitude: 46.0214,
    longitude: 11.1242,
    altitude: 610,
    schengen: true
  },
  {
    id: 3410,
    name: 'Alghero-Fertilia Airport',
    city: 'Alghero',
    country: 'Italy',
    iata: 'AHO',
    icao: 'LIEA',
    latitude: 40.632099,
    longitude: 8.29077,
    altitude: 87,
    schengen: true
  },
  {
    id: 3411,
    name: 'Decimomannu Air Base',
    city: 'Decimomannu',
    country: 'Italy',
    iata: 'DCI',
    icao: 'LIED',
    latitude: 39.354198,
    longitude: 8.97248,
    altitude: 100,
    schengen: true
  },
  {
    id: 3412,
    name: 'Cagliari Elmas Airport',
    city: 'Cagliari',
    country: 'Italy',
    iata: 'CAG',
    icao: 'LIEE',
    latitude: 39.251499,
    longitude: 9.05428,
    altitude: 13,
    schengen: true
  },
  {
    id: 3413,
    name: 'Olbia Costa Smeralda Airport',
    city: 'Olbia',
    country: 'Italy',
    iata: 'OLB',
    icao: 'LIEO',
    latitude: 40.898701,
    longitude: 9.51763,
    altitude: 37,
    schengen: true
  },
  {
    id: 3414,
    name: 'Oristano-Fenosu Airport',
    city: 'Oristano',
    country: 'Italy',
    iata: 'FNU',
    icao: 'LIER',
    latitude: 39.895308,
    longitude: 8.642661,
    altitude: 36,
    schengen: true
  },
  {
    id: 3415,
    name: 'Tortolì Airport',
    city: 'Tortoli',
    country: 'Italy',
    iata: 'TTB',
    icao: 'LIET',
    latitude: 39.9188,
    longitude: 9.68298,
    altitude: 23,
    schengen: true
  },
  {
    id: 3416,
    name: 'Alessandria Airport',
    city: 'Alessandria',
    country: 'Italy',
    iata: '',
    icao: 'LILA',
    latitude: 44.925201,
    longitude: 8.62513,
    altitude: 299,
    schengen: true
  },
  {
    id: 3417,
    name: 'Biella-Cerrione Airport',
    city: 'Biella (BI)',
    country: 'Italy',
    iata: '',
    icao: 'LILE',
    latitude: 45.4953,
    longitude: 8.10278,
    altitude: 920,
    schengen: true
  },
  {
    id: 3418,
    name: 'Vercelli Airport',
    city: 'Vercelli',
    country: 'Italy',
    iata: '',
    icao: 'LILI',
    latitude: 45.310233,
    longitude: 8.417935,
    altitude: 417,
    schengen: true
  },
  {
    id: 3419,
    name: 'Casale Monferrato Airport',
    city: 'Casale Monferrato',
    country: 'Italy',
    iata: '',
    icao: 'LILM',
    latitude: 45.111198,
    longitude: 8.45603,
    altitude: 377,
    schengen: true
  },
  {
    id: 3420,
    name: 'Varese-Venegono Airport',
    city: 'Varese',
    country: 'Italy',
    iata: '',
    icao: 'LILN',
    latitude: 45.742199,
    longitude: 8.888233,
    altitude: 1050,
    schengen: true
  },
  {
    id: 3421,
    name: 'Como (Idroscalo - Water Ad) Hidroport',
    city: 'Como',
    country: 'Italy',
    iata: '',
    icao: 'LILY',
    latitude: 45.814701,
    longitude: 9.06972,
    altitude: 663,
    schengen: true
  },
  {
    id: 3422,
    name: 'Torino-Aeritalia Airport',
    city: 'Turin',
    country: 'Italy',
    iata: '',
    icao: 'LIMA',
    latitude: 45.086399,
    longitude: 7.60337,
    altitude: 943,
    schengen: true
  },
  {
    id: 3423,
    name: 'Milano-Bresso Airfield',
    city: 'Milano',
    country: 'Italy',
    iata: '',
    icao: 'LIMB',
    latitude: 45.542198,
    longitude: 9.20333,
    altitude: 484,
    schengen: true
  },
  {
    id: 3424,
    name: 'Malpensa International Airport',
    city: 'Milano',
    country: 'Italy',
    iata: 'MXP',
    icao: 'LIMC',
    latitude: 45.6306,
    longitude: 8.72811,
    altitude: 768,
    schengen: true
  },
  {
    id: 3425,
    name: 'Il Caravaggio International Airport',
    city: 'Bergamo',
    country: 'Italy',
    iata: 'BGY',
    icao: 'LIME',
    latitude: 45.673901,
    longitude: 9.70417,
    altitude: 782,
    schengen: true
  },
  {
    id: 3426,
    name: 'Turin Airport',
    city: 'Torino',
    country: 'Italy',
    iata: 'TRN',
    icao: 'LIMF',
    latitude: 45.200802,
    longitude: 7.64963,
    altitude: 989,
    schengen: true
  },
  {
    id: 3427,
    name: "Villanova D'Albenga International Airport",
    city: 'Albenga',
    country: 'Italy',
    iata: 'ALL',
    icao: 'LIMG',
    latitude: 44.050598,
    longitude: 8.12743,
    altitude: 148,
    schengen: true
  },
  {
    id: 3428,
    name: 'Genoa Cristoforo Colombo Airport',
    city: 'Genoa',
    country: 'Italy',
    iata: 'GOA',
    icao: 'LIMJ',
    latitude: 44.4133,
    longitude: 8.8375,
    altitude: 13,
    schengen: true
  },
  {
    id: 3429,
    name: 'Milano Linate Airport',
    city: 'Milan',
    country: 'Italy',
    iata: 'LIN',
    icao: 'LIML',
    latitude: 45.445099,
    longitude: 9.27674,
    altitude: 353,
    schengen: true
  },
  {
    id: 3430,
    name: 'Cameri Air Base [MIL]',
    city: 'Cameri',
    country: 'Italy',
    iata: '',
    icao: 'LIMN',
    latitude: 45.529598,
    longitude: 8.66922,
    altitude: 586,
    schengen: true
  },
  {
    id: 3431,
    name: 'Parma Airport',
    city: 'Parma',
    country: 'Italy',
    iata: 'PMF',
    icao: 'LIMP',
    latitude: 44.824501,
    longitude: 10.2964,
    altitude: 161,
    schengen: true
  },
  {
    id: 3432,
    name: 'Novi Ligure Airport',
    city: 'Novi Ligure',
    country: 'Italy',
    iata: '',
    icao: 'LIMR',
    latitude: 44.779999,
    longitude: 8.78639,
    altitude: 607,
    schengen: true
  },
  {
    id: 3433,
    name: 'Piacenza San Damiano Air Base',
    city: 'Piacenza',
    country: 'Italy',
    iata: '',
    icao: 'LIMS',
    latitude: 44.913102,
    longitude: 9.723322,
    altitude: 456,
    schengen: true
  },
  {
    id: 3434,
    name: 'Aosta Airport',
    city: 'Aosta',
    country: 'Italy',
    iata: 'AOT',
    icao: 'LIMW',
    latitude: 45.738499,
    longitude: 7.36872,
    altitude: 1791,
    schengen: true
  },
  {
    id: 3435,
    name: 'Cuneo International Airport',
    city: 'Cuneo',
    country: 'Italy',
    iata: 'CUF',
    icao: 'LIMZ',
    latitude: 44.547001,
    longitude: 7.62322,
    altitude: 1267,
    schengen: true
  },
  {
    id: 3436,
    name: 'Aviano Air Base',
    city: 'Aviano',
    country: 'Italy',
    iata: 'AVB',
    icao: 'LIPA',
    latitude: 46.031898,
    longitude: 12.596503,
    altitude: 410,
    schengen: true
  },
  {
    id: 3437,
    name: 'Bolzano Airport',
    city: 'Bolzano',
    country: 'Italy',
    iata: 'BZO',
    icao: 'LIPB',
    latitude: 46.460201,
    longitude: 11.3264,
    altitude: 789,
    schengen: true
  },
  {
    id: 3438,
    name: 'Cervia Air Base',
    city: 'Cervia',
    country: 'Italy',
    iata: '',
    icao: 'LIPC',
    latitude: 44.224201,
    longitude: 12.3072,
    altitude: 18,
    schengen: true
  },
  {
    id: 3439,
    name: 'Bologna Guglielmo Marconi Airport',
    city: 'Bologna',
    country: 'Italy',
    iata: 'BLQ',
    icao: 'LIPE',
    latitude: 44.5354,
    longitude: 11.2887,
    altitude: 123,
    schengen: true
  },
  {
    id: 3440,
    name: "Treviso-Sant'Angelo Airport",
    city: 'Treviso',
    country: 'Italy',
    iata: 'TSF',
    icao: 'LIPH',
    latitude: 45.648399,
    longitude: 12.1944,
    altitude: 59,
    schengen: true
  },
  {
    id: 3441,
    name: 'Rivolto Air Base',
    city: 'Rivolto',
    country: 'Italy',
    iata: '',
    icao: 'LIPI',
    latitude: 45.978699,
    longitude: 13.0493,
    altitude: 179,
    schengen: true
  },
  {
    id: 3442,
    name: 'Forlì Airport',
    city: 'Forli',
    country: 'Italy',
    iata: 'FRL',
    icao: 'LIPK',
    latitude: 44.194801,
    longitude: 12.0701,
    altitude: 97,
    schengen: true
  },
  {
    id: 3443,
    name: 'Ghedi Air Base',
    city: 'Ghedi',
    country: 'Italy',
    iata: '',
    icao: 'LIPL',
    latitude: 45.432201,
    longitude: 10.2677,
    altitude: 333,
    schengen: true
  },
  {
    id: 3444,
    name: 'Verona-Boscomantico Airport',
    city: 'Verona',
    country: 'Italy',
    iata: '',
    icao: 'LIPN',
    latitude: 45.472,
    longitude: 10.9279,
    altitude: 286,
    schengen: true
  },
  {
    id: 3445,
    name: 'Brescia Airport',
    city: 'Brescia',
    country: 'Italy',
    iata: 'VBS',
    icao: 'LIPO',
    latitude: 45.428902,
    longitude: 10.3306,
    altitude: 355,
    schengen: true
  },
  {
    id: 3446,
    name: 'Trieste–Friuli Venezia Giulia Airport',
    city: 'Ronchi De Legionari',
    country: 'Italy',
    iata: 'TRS',
    icao: 'LIPQ',
    latitude: 45.827499,
    longitude: 13.4722,
    altitude: 39,
    schengen: true
  },
  {
    id: 3447,
    name: 'Federico Fellini International Airport',
    city: 'Rimini',
    country: 'Italy',
    iata: 'RMI',
    icao: 'LIPR',
    latitude: 44.020302,
    longitude: 12.6117,
    altitude: 40,
    schengen: true
  },
  {
    id: 3448,
    name: 'Istrana Air Base',
    city: 'Treviso',
    country: 'Italy',
    iata: '',
    icao: 'LIPS',
    latitude: 45.684898,
    longitude: 12.0829,
    altitude: 137,
    schengen: true
  },
  {
    id: 3449,
    name: 'Vicenza Airport',
    city: 'Vicenza',
    country: 'Italy',
    iata: 'VIC',
    icao: 'LIPT',
    latitude: 45.573399,
    longitude: 11.5295,
    altitude: 128,
    schengen: true
  },
  {
    id: 3450,
    name: 'Padova Airport',
    city: 'Padova',
    country: 'Italy',
    iata: 'QPA',
    icao: 'LIPU',
    latitude: 45.395802,
    longitude: 11.8479,
    altitude: 44,
    schengen: true
  },
  {
    id: 3451,
    name: 'Venice-Lido Airport',
    city: 'Venice',
    country: 'Italy',
    iata: '',
    icao: 'LIPV',
    latitude: 45.428299,
    longitude: 12.3881,
    altitude: 13,
    schengen: true
  },
  {
    id: 3452,
    name: 'Verona Villafranca Airport',
    city: 'Villafranca',
    country: 'Italy',
    iata: 'VRN',
    icao: 'LIPX',
    latitude: 45.395699,
    longitude: 10.8885,
    altitude: 239,
    schengen: true
  },
  {
    id: 3453,
    name: 'Ancona Falconara Airport',
    city: 'Ancona',
    country: 'Italy',
    iata: 'AOI',
    icao: 'LIPY',
    latitude: 43.616299,
    longitude: 13.3623,
    altitude: 49,
    schengen: true
  },
  {
    id: 3454,
    name: 'Venice Marco Polo Airport',
    city: 'Venice',
    country: 'Italy',
    iata: 'VCE',
    icao: 'LIPZ',
    latitude: 45.505299,
    longitude: 12.3519,
    altitude: 7,
    schengen: true
  },
  {
    id: 3455,
    name: 'Siena-Ampugnano Airport',
    city: 'Siena',
    country: 'Italy',
    iata: 'SAY',
    icao: 'LIQS',
    latitude: 43.256302,
    longitude: 11.255,
    altitude: 634,
    schengen: true
  },
  {
    id: 3456,
    name: 'Sarzana-Luni Air Base',
    city: 'Sarzana (SP)',
    country: 'Italy',
    iata: 'QLP',
    icao: 'LIQW',
    latitude: 44.088001,
    longitude: 9.98795,
    altitude: 45,
    schengen: true
  },
  {
    id: 3457,
    name: 'Ciampino–G. B. Pastine International Airport',
    city: 'Rome',
    country: 'Italy',
    iata: 'CIA',
    icao: 'LIRA',
    latitude: 41.7994,
    longitude: 12.5949,
    altitude: 427,
    schengen: true
  },
  {
    id: 3458,
    name: 'Pratica Di Mare Air Base',
    city: 'Pratica Di Mare',
    country: 'Italy',
    iata: '',
    icao: 'LIRE',
    latitude: 41.654499,
    longitude: 12.4452,
    altitude: 41,
    schengen: true
  },
  {
    id: 3459,
    name: 'Leonardo da Vinci–Fiumicino Airport',
    city: 'Rome',
    country: 'Italy',
    iata: 'FCO',
    icao: 'LIRF',
    latitude: 41.8002778,
    longitude: 12.2388889,
    altitude: 13,
    schengen: true
  },
  {
    id: 3460,
    name: 'Guidonia Air Base',
    city: 'Guidonia',
    country: 'Italy',
    iata: '',
    icao: 'LIRG',
    latitude: 41.990299,
    longitude: 12.7408,
    altitude: 289,
    schengen: true
  },
  {
    id: 3461,
    name: "Salerno Costa d'Amalfi Airport",
    city: 'Salerno',
    country: 'Italy',
    iata: 'QSR',
    icao: 'LIRI',
    latitude: 40.620399,
    longitude: 14.9113,
    altitude: 119,
    schengen: true
  },
  {
    id: 3462,
    name: 'Marina Di Campo Airport',
    city: 'Marina Di Campo',
    country: 'Italy',
    iata: 'EBA',
    icao: 'LIRJ',
    latitude: 42.7603,
    longitude: 10.2394,
    altitude: 31,
    schengen: true
  },
  {
    id: 3463,
    name: 'Latina Air Base',
    city: 'Latina',
    country: 'Italy',
    iata: 'QLT',
    icao: 'LIRL',
    latitude: 41.5424,
    longitude: 12.909,
    altitude: 94,
    schengen: true
  },
  {
    id: 3464,
    name: 'Grazzanise Air Base',
    city: 'Grazzanise',
    country: 'Italy',
    iata: '',
    icao: 'LIRM',
    latitude: 41.060799,
    longitude: 14.0819,
    altitude: 29,
    schengen: true
  },
  {
    id: 3465,
    name: 'Naples International Airport',
    city: 'Naples',
    country: 'Italy',
    iata: 'NAP',
    icao: 'LIRN',
    latitude: 40.886002,
    longitude: 14.2908,
    altitude: 294,
    schengen: true
  },
  {
    id: 3466,
    name: 'Pisa International Airport',
    city: 'Pisa',
    country: 'Italy',
    iata: 'PSA',
    icao: 'LIRP',
    latitude: 43.683899,
    longitude: 10.3927,
    altitude: 6,
    schengen: true
  },
  {
    id: 3467,
    name: 'Peretola Airport',
    city: 'Florence',
    country: 'Italy',
    iata: 'FLR',
    icao: 'LIRQ',
    latitude: 43.810001,
    longitude: 11.2051,
    altitude: 142,
    schengen: true
  },
  {
    id: 3468,
    name: 'Grosseto Air Base',
    city: 'Grosseto',
    country: 'Italy',
    iata: 'GRS',
    icao: 'LIRS',
    latitude: 42.759701,
    longitude: 11.0719,
    altitude: 17,
    schengen: true
  },
  {
    id: 3469,
    name: 'Urbe Airport',
    city: 'Rome',
    country: 'Italy',
    iata: '',
    icao: 'LIRU',
    latitude: 41.9519,
    longitude: 12.4989,
    altitude: 55,
    schengen: true
  },
  {
    id: 3470,
    name: 'Viterbo Airport',
    city: 'Viterbo',
    country: 'Italy',
    iata: '',
    icao: 'LIRV',
    latitude: 42.430199,
    longitude: 12.0642,
    altitude: 992,
    schengen: true
  },
  {
    id: 3471,
    name: "Perugia San Francesco d'Assisi – Umbria International Airport",
    city: 'Perugia',
    country: 'Italy',
    iata: 'PEG',
    icao: 'LIRZ',
    latitude: 43.095901,
    longitude: 12.5132,
    altitude: 697,
    schengen: true
  },
  {
    id: 3472,
    name: 'Boscobel Aerodrome',
    city: 'Ocho Rios',
    country: 'Jamaica',
    iata: 'OCJ',
    icao: 'MKBS',
    latitude: 18.4041996002197,
    longitude: -76.9690017700195,
    altitude: 90,
    schengen: false
  },
  {
    id: 3473,
    name: 'Norman Manley International Airport',
    city: 'Kingston',
    country: 'Jamaica',
    iata: 'KIN',
    icao: 'MKJP',
    latitude: 17.9356994628906,
    longitude: -76.7874984741211,
    altitude: 10,
    schengen: false
  },
  {
    id: 3474,
    name: 'Sangster International Airport',
    city: 'Montego Bay',
    country: 'Jamaica',
    iata: 'MBJ',
    icao: 'MKJS',
    latitude: 18.5037002563477,
    longitude: -77.9133987426758,
    altitude: 4,
    schengen: false
  },
  {
    id: 3475,
    name: 'Ken Jones Airport',
    city: 'Port Antonio',
    country: 'Jamaica',
    iata: 'POT',
    icao: 'MKKJ',
    latitude: 18.1987991333,
    longitude: -76.5345001221,
    altitude: 20,
    schengen: false
  },
  {
    id: 3476,
    name: 'Negril Airport',
    city: 'Negril',
    country: 'Jamaica',
    iata: 'NEG',
    icao: 'MKNG',
    latitude: 18.3428001403809,
    longitude: -78.3320999145508,
    altitude: 9,
    schengen: false
  },
  {
    id: 3477,
    name: 'Tinson Pen Airport',
    city: 'Kingston',
    country: 'Jamaica',
    iata: 'KTP',
    icao: 'MKTP',
    latitude: 17.9885997772217,
    longitude: -76.8237991333008,
    altitude: 16,
    schengen: false
  },
  {
    id: 3478,
    name: 'Narita International Airport',
    city: 'Tokyo',
    country: 'Japan',
    iata: 'NRT',
    icao: 'RJAA',
    latitude: 35.7647018433,
    longitude: 140.386001587,
    altitude: 141,
    schengen: false
  },
  {
    id: 3479,
    name: 'Matsumoto Airport',
    city: 'Matsumoto',
    country: 'Japan',
    iata: 'MMJ',
    icao: 'RJAF',
    latitude: 36.1668014526367,
    longitude: 137.923004150391,
    altitude: 2182,
    schengen: false
  },
  {
    id: 3480,
    name: 'Hyakuri Airport',
    city: 'Ibaraki',
    country: 'Japan',
    iata: 'IBR',
    icao: 'RJAH',
    latitude: 36.181098938,
    longitude: 140.414993286,
    altitude: 105,
    schengen: false
  },
  {
    id: 3481,
    name: 'Minami Torishima Airport',
    city: 'Minami Tori Shima',
    country: 'Japan',
    iata: 'MUS',
    icao: 'RJAM',
    latitude: 24.2896995544,
    longitude: 153.979003906,
    altitude: 22,
    schengen: false
  },
  {
    id: 3482,
    name: 'Niijima Airport',
    city: 'Niijima',
    country: 'Japan',
    iata: '',
    icao: 'RJAN',
    latitude: 34.3694000244,
    longitude: 139.268997192,
    altitude: 92,
    schengen: false
  },
  {
    id: 3483,
    name: 'Iwo Jima Airport',
    city: 'Iwojima',
    country: 'Japan',
    iata: 'IWO',
    icao: 'RJAW',
    latitude: 24.7840003967285,
    longitude: 141.322998046875,
    altitude: 384,
    schengen: false
  },
  {
    id: 3484,
    name: 'Kozushima Airport',
    city: 'Kozushima',
    country: 'Japan',
    iata: '',
    icao: 'RJAZ',
    latitude: 34.1899986267,
    longitude: 139.134002686,
    altitude: 452,
    schengen: false
  },
  {
    id: 3485,
    name: 'Kansai International Airport',
    city: 'Osaka',
    country: 'Japan',
    iata: 'KIX',
    icao: 'RJBB',
    latitude: 34.4272994995117,
    longitude: 135.244003295898,
    altitude: 26,
    schengen: false
  },
  {
    id: 3486,
    name: 'Nanki Shirahama Airport',
    city: 'Nanki-shirahama',
    country: 'Japan',
    iata: 'SHM',
    icao: 'RJBD',
    latitude: 33.6622009277,
    longitude: 135.363998413,
    altitude: 298,
    schengen: false
  },
  {
    id: 3487,
    name: 'Kobe Airport',
    city: 'Kobe',
    country: 'Japan',
    iata: 'UKB',
    icao: 'RJBE',
    latitude: 34.6328010559082,
    longitude: 135.223999023438,
    altitude: 22,
    schengen: false
  },
  {
    id: 3488,
    name: 'Hiroshimanishi Airport',
    city: 'Hiroshima',
    country: 'Japan',
    iata: 'HIW',
    icao: 'RJBH',
    latitude: 34.3669013977051,
    longitude: 132.414001464844,
    altitude: 15,
    schengen: false
  },
  {
    id: 3489,
    name: 'Kohnan Airport',
    city: 'Kohnan',
    country: 'Japan',
    iata: '',
    icao: 'RJBK',
    latitude: 34.5908012390137,
    longitude: 133.932998657227,
    altitude: 3,
    schengen: false
  },
  {
    id: 3490,
    name: 'Tajima Airport',
    city: 'Toyooka',
    country: 'Japan',
    iata: 'TJH',
    icao: 'RJBT',
    latitude: 35.5127983093262,
    longitude: 134.787002563477,
    altitude: 584,
    schengen: false
  },
  {
    id: 3491,
    name: 'Asahikawa Airfield',
    city: '',
    country: 'Japan',
    iata: '',
    icao: 'RJCA',
    latitude: 43.794734,
    longitude: 142.365432,
    altitude: 377,
    schengen: false
  },
  {
    id: 3492,
    name: 'Tokachi-Obihiro Airport',
    city: 'Obihiro',
    country: 'Japan',
    iata: 'OBO',
    icao: 'RJCB',
    latitude: 42.7332992554,
    longitude: 143.216995239,
    altitude: 505,
    schengen: false
  },
  {
    id: 3493,
    name: 'New Chitose Airport',
    city: 'Sapporo',
    country: 'Japan',
    iata: 'CTS',
    icao: 'RJCC',
    latitude: 42.7751998901367,
    longitude: 141.692001342773,
    altitude: 82,
    schengen: false
  },
  {
    id: 3494,
    name: 'Hakodate Airport',
    city: 'Hakodate',
    country: 'Japan',
    iata: 'HKD',
    icao: 'RJCH',
    latitude: 41.7700004578,
    longitude: 140.822006226,
    altitude: 151,
    schengen: false
  },
  {
    id: 3495,
    name: 'Chitose Air Base',
    city: 'Chitose',
    country: 'Japan',
    iata: '',
    icao: 'RJCJ',
    latitude: 42.7944984436,
    longitude: 141.666000366,
    altitude: 87,
    schengen: false
  },
  {
    id: 3496,
    name: 'Kushiro Airport',
    city: 'Kushiro',
    country: 'Japan',
    iata: 'KUH',
    icao: 'RJCK',
    latitude: 43.0410003662,
    longitude: 144.192993164,
    altitude: 327,
    schengen: false
  },
  {
    id: 3497,
    name: 'Memanbetsu Airport',
    city: 'Memanbetsu',
    country: 'Japan',
    iata: 'MMB',
    icao: 'RJCM',
    latitude: 43.8805999756,
    longitude: 144.164001465,
    altitude: 135,
    schengen: false
  },
  {
    id: 3498,
    name: 'Nakashibetsu Airport',
    city: 'Nakashibetsu',
    country: 'Japan',
    iata: 'SHB',
    icao: 'RJCN',
    latitude: 43.5774993896,
    longitude: 144.960006714,
    altitude: 234,
    schengen: false
  },
  {
    id: 3499,
    name: 'Okadama Airport',
    city: 'Sapporo',
    country: 'Japan',
    iata: 'OKD',
    icao: 'RJCO',
    latitude: 43.117447,
    longitude: 141.38134,
    altitude: 25,
    schengen: false
  },
  {
    id: 3500,
    name: 'Kenebetsu JASDF Airfield',
    city: 'Kenebetsu',
    country: 'Japan',
    iata: '',
    icao: 'RJCS',
    latitude: 43.425154,
    longitude: 144.741354,
    altitude: 0,
    schengen: false
  },
  {
    id: 3501,
    name: 'Tokachi Airport',
    city: 'Tokachi',
    country: 'Japan',
    iata: '',
    icao: 'RJCT',
    latitude: 42.8904991149902,
    longitude: 143.158004760742,
    altitude: 281,
    schengen: false
  },
  {
    id: 3502,
    name: 'Wakkanai Airport',
    city: 'Wakkanai',
    country: 'Japan',
    iata: 'WKJ',
    icao: 'RJCW',
    latitude: 45.4042015076,
    longitude: 141.800994873,
    altitude: 30,
    schengen: false
  },
  {
    id: 3503,
    name: 'Amakusa Airport',
    city: 'Amakusa',
    country: 'Japan',
    iata: 'AXJ',
    icao: 'RJDA',
    latitude: 32.482498,
    longitude: 130.158997,
    altitude: 340,
    schengen: false
  },
  {
    id: 3504,
    name: 'Iki Airport',
    city: 'Iki',
    country: 'Japan',
    iata: 'IKI',
    icao: 'RJDB',
    latitude: 33.7490005493,
    longitude: 129.785003662,
    altitude: 41,
    schengen: false
  },
  {
    id: 3505,
    name: 'Yamaguchi Ube Airport',
    city: 'Yamaguchi',
    country: 'Japan',
    iata: 'UBJ',
    icao: 'RJDC',
    latitude: 33.9300003052,
    longitude: 131.279006958,
    altitude: 23,
    schengen: false
  },
  {
    id: 3506,
    name: 'Kamigoto Airport',
    city: 'Shin-kamigoto',
    country: 'Japan',
    iata: '',
    icao: 'RJDK',
    latitude: 33.0130996704,
    longitude: 129.192001343,
    altitude: 263,
    schengen: false
  },
  {
    id: 3507,
    name: 'Ojika Airport',
    city: 'Odika',
    country: 'Japan',
    iata: '',
    icao: 'RJDO',
    latitude: 33.1907997131,
    longitude: 129.089996338,
    altitude: 30,
    schengen: false
  },
  {
    id: 3508,
    name: 'Tsushima Airport',
    city: 'Tsushima',
    country: 'Japan',
    iata: 'TSJ',
    icao: 'RJDT',
    latitude: 34.2849006653,
    longitude: 129.330993652,
    altitude: 213,
    schengen: false
  },
  {
    id: 3509,
    name: 'Monbetsu Airport',
    city: 'Monbetsu',
    country: 'Japan',
    iata: 'MBE',
    icao: 'RJEB',
    latitude: 44.3039016724,
    longitude: 143.404006958,
    altitude: 80,
    schengen: false
  },
  {
    id: 3510,
    name: 'Asahikawa Airport',
    city: 'Asahikawa',
    country: 'Japan',
    iata: 'AKJ',
    icao: 'RJEC',
    latitude: 43.6707992553711,
    longitude: 142.447006225586,
    altitude: 721,
    schengen: false
  },
  {
    id: 3511,
    name: 'Okushiri Airport',
    city: 'Okushiri',
    country: 'Japan',
    iata: 'OIR',
    icao: 'RJEO',
    latitude: 42.0717010498,
    longitude: 139.432998657,
    altitude: 161,
    schengen: false
  },
  {
    id: 3512,
    name: 'Rishiri Airport',
    city: 'Rishiri Island',
    country: 'Japan',
    iata: 'RIS',
    icao: 'RJER',
    latitude: 45.2420005798,
    longitude: 141.186004639,
    altitude: 112,
    schengen: false
  },
  {
    id: 3513,
    name: 'Ashiya Airport',
    city: 'Ashiya',
    country: 'Japan',
    iata: '',
    icao: 'RJFA',
    latitude: 33.8830986022949,
    longitude: 130.65299987793,
    altitude: 98,
    schengen: false
  },
  {
    id: 3514,
    name: 'Yakushima Airport',
    city: 'Yakushima',
    country: 'Japan',
    iata: 'KUM',
    icao: 'RJFC',
    latitude: 30.3855991364,
    longitude: 130.658996582,
    altitude: 124,
    schengen: false
  },
  {
    id: 3515,
    name: 'Fukue Airport',
    city: 'Fukue',
    country: 'Japan',
    iata: 'FUJ',
    icao: 'RJFE',
    latitude: 32.6663017272949,
    longitude: 128.832992553711,
    altitude: 273,
    schengen: false
  },
  {
    id: 3516,
    name: 'Fukuoka Airport',
    city: 'Fukuoka',
    country: 'Japan',
    iata: 'FUK',
    icao: 'RJFF',
    latitude: 33.5858993530273,
    longitude: 130.45100402832,
    altitude: 32,
    schengen: false
  },
  {
    id: 3517,
    name: 'New Tanegashima Airport',
    city: 'Tanegashima',
    country: 'Japan',
    iata: 'TNE',
    icao: 'RJFG',
    latitude: 30.6051006317,
    longitude: 130.990997314,
    altitude: 768,
    schengen: false
  },
  {
    id: 3518,
    name: 'Kagoshima Airport',
    city: 'Kagoshima',
    country: 'Japan',
    iata: 'KOJ',
    icao: 'RJFK',
    latitude: 31.8034000396729,
    longitude: 130.718994140625,
    altitude: 906,
    schengen: false
  },
  {
    id: 3519,
    name: 'Miyazaki Airport',
    city: 'Miyazaki',
    country: 'Japan',
    iata: 'KMI',
    icao: 'RJFM',
    latitude: 31.877199173,
    longitude: 131.449005127,
    altitude: 20,
    schengen: false
  },
  {
    id: 3520,
    name: 'Nyutabaru Airport',
    city: 'Nyutabaru',
    country: 'Japan',
    iata: '',
    icao: 'RJFN',
    latitude: 32.0835990905762,
    longitude: 131.45100402832,
    altitude: 259,
    schengen: false
  },
  {
    id: 3521,
    name: 'Oita Airport',
    city: 'Oita',
    country: 'Japan',
    iata: 'OIT',
    icao: 'RJFO',
    latitude: 33.4794006348,
    longitude: 131.736999512,
    altitude: 19,
    schengen: false
  },
  {
    id: 3522,
    name: 'Kitakyūshū Airport',
    city: 'Kitakyushu',
    country: 'Japan',
    iata: 'KKJ',
    icao: 'RJFR',
    latitude: 33.8459014893,
    longitude: 131.035003662,
    altitude: 21,
    schengen: false
  },
  {
    id: 3523,
    name: 'Saga Airport',
    city: 'Saga',
    country: 'Japan',
    iata: 'HSG',
    icao: 'RJFS',
    latitude: 33.1497001648,
    longitude: 130.302001953,
    altitude: 6,
    schengen: false
  },
  {
    id: 3524,
    name: 'Kumamoto Airport',
    city: 'Kumamoto',
    country: 'Japan',
    iata: 'KMJ',
    icao: 'RJFT',
    latitude: 32.8372993469238,
    longitude: 130.854995727539,
    altitude: 642,
    schengen: false
  },
  {
    id: 3525,
    name: 'Nagasaki Airport',
    city: 'Nagasaki',
    country: 'Japan',
    iata: 'NGS',
    icao: 'RJFU',
    latitude: 32.9169006348,
    longitude: 129.914001465,
    altitude: 15,
    schengen: false
  },
  {
    id: 3526,
    name: 'Kanoya Airport',
    city: 'Kanoya',
    country: 'Japan',
    iata: '',
    icao: 'RJFY',
    latitude: 31.3675994873047,
    longitude: 130.845001220703,
    altitude: 214,
    schengen: false
  },
  {
    id: 3527,
    name: 'Tsuiki Air Field',
    city: 'Tsuiki',
    country: 'Japan',
    iata: '',
    icao: 'RJFZ',
    latitude: 33.6850013733,
    longitude: 131.039993286,
    altitude: 55,
    schengen: false
  },
  {
    id: 3528,
    name: 'Chubu Centrair International Airport',
    city: 'Nagoya',
    country: 'Japan',
    iata: 'NGO',
    icao: 'RJGG',
    latitude: 34.8583984375,
    longitude: 136.804992675781,
    altitude: 15,
    schengen: false
  },
  {
    id: 3529,
    name: 'Amami Airport',
    city: 'Amami',
    country: 'Japan',
    iata: 'ASJ',
    icao: 'RJKA',
    latitude: 28.4305992126465,
    longitude: 129.712997436523,
    altitude: 27,
    schengen: false
  },
  {
    id: 3530,
    name: 'Okierabu Airport',
    city: 'Okierabu',
    country: 'Japan',
    iata: 'OKE',
    icao: 'RJKB',
    latitude: 27.4255008698,
    longitude: 128.701004028,
    altitude: 101,
    schengen: false
  },
  {
    id: 3531,
    name: 'Kikai Airport',
    city: 'Kikai',
    country: 'Japan',
    iata: 'KKX',
    icao: 'RJKI',
    latitude: 28.3213005066,
    longitude: 129.927993774,
    altitude: 21,
    schengen: false
  },
  {
    id: 3532,
    name: 'Tokunoshima Airport',
    city: 'Tokunoshima',
    country: 'Japan',
    iata: 'TKN',
    icao: 'RJKN',
    latitude: 27.8363990783691,
    longitude: 128.880996704102,
    altitude: 17,
    schengen: false
  },
  {
    id: 3533,
    name: 'Nagoya Airport',
    city: 'Nagoya',
    country: 'Japan',
    iata: 'NKM',
    icao: 'RJNA',
    latitude: 35.2550010681152,
    longitude: 136.92399597168,
    altitude: 52,
    schengen: false
  },
  {
    id: 3534,
    name: 'Fukui Airport',
    city: 'Fukui',
    country: 'Japan',
    iata: 'FKJ',
    icao: 'RJNF',
    latitude: 36.1427993774,
    longitude: 136.223999023,
    altitude: 19,
    schengen: false
  },
  {
    id: 3535,
    name: 'Gifu Airport',
    city: 'Gifu',
    country: 'Japan',
    iata: 'QGU',
    icao: 'RJNG',
    latitude: 35.394100189209,
    longitude: 136.869995117188,
    altitude: 128,
    schengen: false
  },
  {
    id: 3536,
    name: 'Hamamatsu Airport',
    city: 'Hamamatsu',
    country: 'Japan',
    iata: '',
    icao: 'RJNH',
    latitude: 34.7501983642578,
    longitude: 137.703002929688,
    altitude: 150,
    schengen: false
  },
  {
    id: 3537,
    name: 'Komatsu Airport',
    city: 'Kanazawa',
    country: 'Japan',
    iata: 'KMQ',
    icao: 'RJNK',
    latitude: 36.3945999145508,
    longitude: 136.406997680664,
    altitude: 36,
    schengen: false
  },
  {
    id: 3538,
    name: 'Oki Airport',
    city: 'Oki Island',
    country: 'Japan',
    iata: 'OKI',
    icao: 'RJNO',
    latitude: 36.1810989379883,
    longitude: 133.324996948242,
    altitude: 311,
    schengen: false
  },
  {
    id: 3539,
    name: 'Toyama Airport',
    city: 'Toyama',
    country: 'Japan',
    iata: 'TOY',
    icao: 'RJNT',
    latitude: 36.6483001708984,
    longitude: 137.188003540039,
    altitude: 95,
    schengen: false
  },
  {
    id: 3540,
    name: 'Noto Airport',
    city: 'Wajima',
    country: 'Japan',
    iata: 'NTQ',
    icao: 'RJNW',
    latitude: 37.2930984497,
    longitude: 136.962005615,
    altitude: 718,
    schengen: false
  },
  {
    id: 3541,
    name: 'Shizuhama Airport',
    city: 'Yaizu',
    country: 'Japan',
    iata: '',
    icao: 'RJNY',
    latitude: 34.812801361084,
    longitude: 138.298004150391,
    altitude: 23,
    schengen: false
  },
  {
    id: 3542,
    name: 'Hiroshima Airport',
    city: 'Hiroshima',
    country: 'Japan',
    iata: 'HIJ',
    icao: 'RJOA',
    latitude: 34.4361000061,
    longitude: 132.919006348,
    altitude: 1088,
    schengen: false
  },
  {
    id: 3543,
    name: 'Okayama Airport',
    city: 'Okayama',
    country: 'Japan',
    iata: 'OKJ',
    icao: 'RJOB',
    latitude: 34.7569007874,
    longitude: 133.854995728,
    altitude: 806,
    schengen: false
  },
  {
    id: 3544,
    name: 'Izumo Airport',
    city: 'Izumo',
    country: 'Japan',
    iata: 'IZO',
    icao: 'RJOC',
    latitude: 35.4136009216,
    longitude: 132.88999939,
    altitude: 15,
    schengen: false
  },
  {
    id: 3545,
    name: 'Akeno Airport',
    city: 'Akeno',
    country: 'Japan',
    iata: '',
    icao: 'RJOE',
    latitude: 34.5332984924316,
    longitude: 136.671997070313,
    altitude: 20,
    schengen: false
  },
  {
    id: 3546,
    name: 'Hofu Airport',
    city: 'Hofu',
    country: 'Japan',
    iata: '',
    icao: 'RJOF',
    latitude: 34.0346984863281,
    longitude: 131.54899597168,
    altitude: 7,
    schengen: false
  },
  {
    id: 3547,
    name: 'Miho Yonago Airport',
    city: 'Miho',
    country: 'Japan',
    iata: 'YGJ',
    icao: 'RJOH',
    latitude: 35.4921989440918,
    longitude: 133.235992431641,
    altitude: 20,
    schengen: false
  },
  {
    id: 3548,
    name: 'Iwakuni Marine Corps Air Station',
    city: 'Iwakuni',
    country: 'Japan',
    iata: 'IWK',
    icao: 'RJOI',
    latitude: 34.143902,
    longitude: 132.235992,
    altitude: 7,
    schengen: false
  },
  {
    id: 3549,
    name: 'Kōchi Ryōma Airport',
    city: 'Kochi',
    country: 'Japan',
    iata: 'KCZ',
    icao: 'RJOK',
    latitude: 33.546101,
    longitude: 133.669006,
    altitude: 42,
    schengen: false
  },
  {
    id: 3550,
    name: 'Matsuyama Airport',
    city: 'Matsuyama',
    country: 'Japan',
    iata: 'MYJ',
    icao: 'RJOM',
    latitude: 33.8272018432617,
    longitude: 132.699996948242,
    altitude: 25,
    schengen: false
  },
  {
    id: 3551,
    name: 'Osaka International Airport',
    city: 'Osaka',
    country: 'Japan',
    iata: 'ITM',
    icao: 'RJOO',
    latitude: 34.7854995727539,
    longitude: 135.438003540039,
    altitude: 50,
    schengen: false
  },
  {
    id: 3552,
    name: 'Tottori Airport',
    city: 'Tottori',
    country: 'Japan',
    iata: 'TTJ',
    icao: 'RJOR',
    latitude: 35.5301017761,
    longitude: 134.167007446,
    altitude: 65,
    schengen: false
  },
  {
    id: 3553,
    name: 'Tokushima Airport/JMSDF Air Base',
    city: 'Tokushima',
    country: 'Japan',
    iata: 'TKS',
    icao: 'RJOS',
    latitude: 34.132801,
    longitude: 134.606995,
    altitude: 26,
    schengen: false
  },
  {
    id: 3554,
    name: 'Takamatsu Airport',
    city: 'Takamatsu',
    country: 'Japan',
    iata: 'TAK',
    icao: 'RJOT',
    latitude: 34.2141990662,
    longitude: 134.01600647,
    altitude: 607,
    schengen: false
  },
  {
    id: 3555,
    name: 'Iwami Airport',
    city: 'Iwami',
    country: 'Japan',
    iata: 'IWJ',
    icao: 'RJOW',
    latitude: 34.676399231,
    longitude: 131.789993286,
    altitude: 184,
    schengen: false
  },
  {
    id: 3556,
    name: 'Yao Airport',
    city: 'Osaka',
    country: 'Japan',
    iata: '',
    icao: 'RJOY',
    latitude: 34.5962982177734,
    longitude: 135.602996826172,
    altitude: 39,
    schengen: false
  },
  {
    id: 3557,
    name: 'Ozuki Airport',
    city: 'Ozuki',
    country: 'Japan',
    iata: '',
    icao: 'RJOZ',
    latitude: 34.0452995300293,
    longitude: 131.052001953125,
    altitude: 13,
    schengen: false
  },
  {
    id: 3558,
    name: 'Aomori Airport',
    city: 'Aomori',
    country: 'Japan',
    iata: 'AOJ',
    icao: 'RJSA',
    latitude: 40.7346992492676,
    longitude: 140.690994262695,
    altitude: 664,
    schengen: false
  },
  {
    id: 3559,
    name: 'Yamagata Airport',
    city: 'Yamagata',
    country: 'Japan',
    iata: 'GAJ',
    icao: 'RJSC',
    latitude: 38.4118995667,
    longitude: 140.371002197,
    altitude: 353,
    schengen: false
  },
  {
    id: 3560,
    name: 'Sado Airport',
    city: 'Sado',
    country: 'Japan',
    iata: 'SDS',
    icao: 'RJSD',
    latitude: 38.0601997375,
    longitude: 138.414001465,
    altitude: 88,
    schengen: false
  },
  {
    id: 3561,
    name: 'Fukushima Airport',
    city: 'Fukushima',
    country: 'Japan',
    iata: 'FKS',
    icao: 'RJSF',
    latitude: 37.2274017333984,
    longitude: 140.430999755859,
    altitude: 1221,
    schengen: false
  },
  {
    id: 3562,
    name: 'Hachinohe Airport',
    city: 'Hachinoe',
    country: 'Japan',
    iata: 'HHE',
    icao: 'RJSH',
    latitude: 40.5564002990723,
    longitude: 141.466003417969,
    altitude: 152,
    schengen: false
  },
  {
    id: 3563,
    name: 'Hanamaki Airport',
    city: 'Hanamaki',
    country: 'Japan',
    iata: 'HNA',
    icao: 'RJSI',
    latitude: 39.4286003112793,
    longitude: 141.134994506836,
    altitude: 297,
    schengen: false
  },
  {
    id: 3564,
    name: 'Akita Airport',
    city: 'Akita',
    country: 'Japan',
    iata: 'AXT',
    icao: 'RJSK',
    latitude: 39.6156005859375,
    longitude: 140.218994140625,
    altitude: 313,
    schengen: false
  },
  {
    id: 3565,
    name: 'Misawa Air Base',
    city: 'Misawa',
    country: 'Japan',
    iata: 'MSJ',
    icao: 'RJSM',
    latitude: 40.7032012939,
    longitude: 141.367996216,
    altitude: 119,
    schengen: false
  },
  {
    id: 3566,
    name: 'Niigata Airport',
    city: 'Niigata',
    country: 'Japan',
    iata: 'KIJ',
    icao: 'RJSN',
    latitude: 37.9558982849,
    longitude: 139.121002197,
    altitude: 29,
    schengen: false
  },
  {
    id: 3567,
    name: 'Odate Noshiro Airport',
    city: 'Odate Noshiro',
    country: 'Japan',
    iata: 'ONJ',
    icao: 'RJSR',
    latitude: 40.1918983459,
    longitude: 140.371002197,
    altitude: 292,
    schengen: false
  },
  {
    id: 3568,
    name: 'Sendai Airport',
    city: 'Sendai',
    country: 'Japan',
    iata: 'SDJ',
    icao: 'RJSS',
    latitude: 38.1397018433,
    longitude: 140.917007446,
    altitude: 15,
    schengen: false
  },
  {
    id: 3569,
    name: 'Matsushima Air Base',
    city: 'Matsushima',
    country: 'Japan',
    iata: '',
    icao: 'RJST',
    latitude: 38.4048995972,
    longitude: 141.220001221,
    altitude: 7,
    schengen: false
  },
  {
    id: 3570,
    name: 'Shonai Airport',
    city: 'Shonai',
    country: 'Japan',
    iata: 'SYO',
    icao: 'RJSY',
    latitude: 38.8121986389,
    longitude: 139.787002563,
    altitude: 86,
    schengen: false
  },
  {
    id: 3571,
    name: 'Atsugi Naval Air Facility',
    city: 'Atsugi',
    country: 'Japan',
    iata: 'NJA',
    icao: 'RJTA',
    latitude: 35.4546012878418,
    longitude: 139.449996948242,
    altitude: 205,
    schengen: false
  },
  {
    id: 3572,
    name: 'Tateyama Airport',
    city: 'Tateyama',
    country: 'Japan',
    iata: '',
    icao: 'RJTE',
    latitude: 34.987099,
    longitude: 139.828995,
    altitude: 10,
    schengen: false
  },
  {
    id: 3573,
    name: 'Chofu Airport',
    city: 'Tokyo',
    country: 'Japan',
    iata: '',
    icao: 'RJTF',
    latitude: 35.6716995239258,
    longitude: 139.52799987793,
    altitude: 141,
    schengen: false
  },
  {
    id: 3574,
    name: 'Hachijojima Airport',
    city: 'Hachijojima',
    country: 'Japan',
    iata: 'HAC',
    icao: 'RJTH',
    latitude: 33.1150016785,
    longitude: 139.785995483,
    altitude: 303,
    schengen: false
  },
  {
    id: 3575,
    name: 'Iruma Air Base',
    city: 'Iruma',
    country: 'Japan',
    iata: '',
    icao: 'RJTJ',
    latitude: 35.8418998718262,
    longitude: 139.410995483398,
    altitude: 295,
    schengen: false
  },
  {
    id: 3576,
    name: 'Kisarazu Airport',
    city: 'Kisarazu',
    country: 'Japan',
    iata: '',
    icao: 'RJTK',
    latitude: 35.3983001708984,
    longitude: 139.910003662109,
    altitude: 10,
    schengen: false
  },
  {
    id: 3577,
    name: 'Shimofusa Airport',
    city: 'Shimofusa',
    country: 'Japan',
    iata: '',
    icao: 'RJTL',
    latitude: 35.798900604248,
    longitude: 140.011001586914,
    altitude: 98,
    schengen: false
  },
  {
    id: 3578,
    name: 'Oshima Airport',
    city: 'Oshima',
    country: 'Japan',
    iata: 'OIM',
    icao: 'RJTO',
    latitude: 34.7820014954,
    longitude: 139.36000061,
    altitude: 130,
    schengen: false
  },
  {
    id: 3579,
    name: 'Miyakejima Airport',
    city: 'Miyakejima',
    country: 'Japan',
    iata: 'MYE',
    icao: 'RJTQ',
    latitude: 34.073600769,
    longitude: 139.559997559,
    altitude: 67,
    schengen: false
  },
  {
    id: 3580,
    name: 'Kastner Army Heliport',
    city: 'Zama',
    country: 'Japan',
    iata: '',
    icao: 'RJTR',
    latitude: 35.5138015747,
    longitude: 139.393997192,
    altitude: 360,
    schengen: false
  },
  {
    id: 3581,
    name: 'Tokyo Haneda International Airport',
    city: 'Tokyo',
    country: 'Japan',
    iata: 'HND',
    icao: 'RJTT',
    latitude: 35.552299,
    longitude: 139.779999,
    altitude: 35,
    schengen: false
  },
  {
    id: 3582,
    name: 'Utsunomiya Airport',
    city: '',
    country: 'Japan',
    iata: '',
    icao: 'RJTU',
    latitude: 36.5145,
    longitude: 139.87101,
    altitude: 334,
    schengen: false
  },
  {
    id: 3583,
    name: 'Yokota Air Base',
    city: 'Yokota',
    country: 'Japan',
    iata: 'OKO',
    icao: 'RJTY',
    latitude: 35.7485008239746,
    longitude: 139.348007202148,
    altitude: 463,
    schengen: false
  },
  {
    id: 3584,
    name: 'Naha Airport',
    city: 'Okinawa',
    country: 'Japan',
    iata: 'OKA',
    icao: 'ROAH',
    latitude: 26.1958007812,
    longitude: 127.646003723,
    altitude: 12,
    schengen: false
  },
  {
    id: 3585,
    name: 'Ie Shima Auxiliary Air Base',
    city: 'Iejima',
    country: 'Japan',
    iata: '',
    icao: 'RODE',
    latitude: 26.725757598877,
    longitude: 127.76490020752,
    altitude: 184,
    schengen: false
  },
  {
    id: 3586,
    name: 'Kadena Air Base',
    city: 'Kadena',
    country: 'Japan',
    iata: 'DNA',
    icao: 'RODN',
    latitude: 26.3556,
    longitude: 127.767998,
    altitude: 143,
    schengen: false
  },
  {
    id: 3587,
    name: 'Ishigaki Airport',
    city: 'Ishigaki',
    country: 'Japan',
    iata: 'ISG',
    icao: 'ROIG',
    latitude: 24.344499588,
    longitude: 124.18699646,
    altitude: 93,
    schengen: false
  },
  {
    id: 3588,
    name: 'Kumejima Airport',
    city: 'Kumejima',
    country: 'Japan',
    iata: 'UEO',
    icao: 'ROKJ',
    latitude: 26.3635005950928,
    longitude: 126.713996887207,
    altitude: 23,
    schengen: false
  },
  {
    id: 3589,
    name: 'Kerama Airport',
    city: 'Kerama',
    country: 'Japan',
    iata: 'KJP',
    icao: 'ROKR',
    latitude: 26.1683006287,
    longitude: 127.292999268,
    altitude: 156,
    schengen: false
  },
  {
    id: 3590,
    name: 'Minami-Daito Airport',
    city: 'Minami Daito',
    country: 'Japan',
    iata: 'MMD',
    icao: 'ROMD',
    latitude: 25.8465003967,
    longitude: 131.263000488,
    altitude: 167,
    schengen: false
  },
  {
    id: 3591,
    name: 'Miyako Airport',
    city: 'Miyako',
    country: 'Japan',
    iata: 'MMY',
    icao: 'ROMY',
    latitude: 24.7828006744,
    longitude: 125.294998169,
    altitude: 150,
    schengen: false
  },
  {
    id: 3592,
    name: 'Aguni Airport',
    city: 'Aguni',
    country: 'Japan',
    iata: 'AGJ',
    icao: 'RORA',
    latitude: 26.5925006866,
    longitude: 127.240997314,
    altitude: 38,
    schengen: false
  },
  {
    id: 3593,
    name: 'Ie Jima Airport',
    city: 'Ie',
    country: 'Japan',
    iata: 'IEJ',
    icao: 'RORE',
    latitude: 26.7220001221,
    longitude: 127.785003662,
    altitude: 246,
    schengen: false
  },
  {
    id: 3594,
    name: 'Hateruma Airport',
    city: 'Taketomi',
    country: 'Japan',
    iata: 'HTR',
    icao: 'RORH',
    latitude: 24.0589008331,
    longitude: 123.805999756,
    altitude: 43,
    schengen: false
  },
  {
    id: 3595,
    name: 'Kitadaito Airport',
    city: 'Kitadaito',
    country: 'Japan',
    iata: 'KTD',
    icao: 'RORK',
    latitude: 25.9447002411,
    longitude: 131.32699585,
    altitude: 80,
    schengen: false
  },
  {
    id: 3596,
    name: 'Shimojishima Airport',
    city: 'Shimojishima',
    country: 'Japan',
    iata: 'SHI',
    icao: 'RORS',
    latitude: 24.8267002106,
    longitude: 125.144996643,
    altitude: 54,
    schengen: false
  },
  {
    id: 3597,
    name: 'Tarama Airport',
    city: 'Tarama',
    country: 'Japan',
    iata: 'TRA',
    icao: 'RORT',
    latitude: 24.6539001465,
    longitude: 124.675003052,
    altitude: 36,
    schengen: false
  },
  {
    id: 3598,
    name: 'Yoron Airport',
    city: 'Yoron',
    country: 'Japan',
    iata: 'RNJ',
    icao: 'RORY',
    latitude: 27.0440006256,
    longitude: 128.401992798,
    altitude: 52,
    schengen: false
  },
  {
    id: 3599,
    name: 'Futenma Marine Corps Air Station',
    city: 'Futema',
    country: 'Japan',
    iata: '',
    icao: 'ROTM',
    latitude: 26.274299621582,
    longitude: 127.755996704102,
    altitude: 247,
    schengen: false
  },
  {
    id: 3600,
    name: 'Yonaguni Airport',
    city: 'Yonaguni Jima',
    country: 'Japan',
    iata: 'OGN',
    icao: 'ROYN',
    latitude: 24.4668998718262,
    longitude: 122.977996826172,
    altitude: 70,
    schengen: false
  },
  {
    id: 3601,
    name: 'Jersey Airport',
    city: 'Jersey',
    country: 'Jersey',
    iata: 'JER',
    icao: 'EGJJ',
    latitude: 49.2079010009766,
    longitude: -2.1955099105835,
    altitude: 277,
    schengen: false
  },
  {
    id: 3602,
    name: 'Johnston Atoll Airport',
    city: 'Johnston Island',
    country: 'Johnston Atoll',
    iata: 'JON',
    icao: 'PJON',
    latitude: 16.7285995483,
    longitude: -169.533996582,
    altitude: 7,
    schengen: false
  },
  {
    id: 3603,
    name: 'Queen Alia International Airport',
    city: 'Amman',
    country: 'Jordan',
    iata: 'AMM',
    icao: 'OJAI',
    latitude: 31.7226009369,
    longitude: 35.9931983948,
    altitude: 2395,
    schengen: false
  },
  {
    id: 3604,
    name: 'Amman-Marka International Airport',
    city: 'Amman',
    country: 'Jordan',
    iata: 'ADJ',
    icao: 'OJAM',
    latitude: 31.9727001190186,
    longitude: 35.9916000366211,
    altitude: 2555,
    schengen: false
  },
  {
    id: 3605,
    name: 'Aqaba King Hussein International Airport',
    city: 'Aqaba',
    country: 'Jordan',
    iata: 'AQJ',
    icao: 'OJAQ',
    latitude: 29.6116008758545,
    longitude: 35.0181007385254,
    altitude: 175,
    schengen: false
  },
  {
    id: 3606,
    name: 'Prince Hassan Air Base',
    city: 'Hotel Four',
    country: 'Jordan',
    iata: '',
    icao: 'OJHF',
    latitude: 32.160702,
    longitude: 37.149399,
    altitude: 2220,
    schengen: false
  },
  {
    id: 3607,
    name: 'King Hussein Air College',
    city: 'Mafraq',
    country: 'Jordan',
    iata: 'OMF',
    icao: 'OJMF',
    latitude: 32.3564,
    longitude: 36.259201,
    altitude: 2240,
    schengen: false
  },
  {
    id: 3608,
    name: 'Almaty Airport',
    city: 'Alma-ata',
    country: 'Kazakhstan',
    iata: 'ALA',
    icao: 'UAAA',
    latitude: 43.3521003723145,
    longitude: 77.0404968261719,
    altitude: 2234,
    schengen: false
  },
  {
    id: 3609,
    name: 'Balkhash Airport',
    city: 'Balkhash',
    country: 'Kazakhstan',
    iata: 'BXH',
    icao: 'UAAH',
    latitude: 46.8932991027832,
    longitude: 75.004997253418,
    altitude: 1446,
    schengen: false
  },
  {
    id: 3610,
    name: 'Ak Bashat Airport',
    city: 'Taldykorgan',
    country: 'Kazakhstan',
    iata: '',
    icao: 'UAAT',
    latitude: 42.88958,
    longitude: 73.602004,
    altitude: 0,
    schengen: false
  },
  {
    id: 3611,
    name: 'Astana International Airport',
    city: 'Tselinograd',
    country: 'Kazakhstan',
    iata: 'TSE',
    icao: 'UACC',
    latitude: 51.0222015380859,
    longitude: 71.4669036865234,
    altitude: 1165,
    schengen: false
  },
  {
    id: 3612,
    name: 'Kokshetau Airport',
    city: 'Kokshetau',
    country: 'Kazakhstan',
    iata: 'KOV',
    icao: 'UACK',
    latitude: 53.329102,
    longitude: 69.594597,
    altitude: 900,
    schengen: false
  },
  {
    id: 3613,
    name: 'Petropavlosk South Airport',
    city: 'Petropavlosk',
    country: 'Kazakhstan',
    iata: 'PPK',
    icao: 'UACP',
    latitude: 54.7747001647949,
    longitude: 69.1838989257812,
    altitude: 453,
    schengen: false
  },
  {
    id: 3614,
    name: 'Taraz Airport',
    city: 'Dzhambul',
    country: 'Kazakhstan',
    iata: 'DMB',
    icao: 'UADD',
    latitude: 42.8535995483398,
    longitude: 71.303596496582,
    altitude: 2184,
    schengen: false
  },
  {
    id: 3615,
    name: 'Shymkent Airport',
    city: 'Chimkent',
    country: 'Kazakhstan',
    iata: 'CIT',
    icao: 'UAII',
    latitude: 42.364200592041,
    longitude: 69.4788970947266,
    altitude: 1385,
    schengen: false
  },
  {
    id: 3616,
    name: 'Zhezkazgan Airport',
    city: 'Zhezkazgan',
    country: 'Kazakhstan',
    iata: 'DZN',
    icao: 'UAKD',
    latitude: 47.708302,
    longitude: 67.733299,
    altitude: 1250,
    schengen: false
  },
  {
    id: 3617,
    name: 'Sary-Arka Airport',
    city: 'Karaganda',
    country: 'Kazakhstan',
    iata: 'KGF',
    icao: 'UAKK',
    latitude: 49.6707992553711,
    longitude: 73.3343963623047,
    altitude: 1765,
    schengen: false
  },
  {
    id: 3618,
    name: 'Yubileyniy Airfield',
    city: 'Baikonur',
    country: 'Kazakhstan',
    iata: '',
    icao: 'UAON',
    latitude: 46.0550003051758,
    longitude: 63.25,
    altitude: 328,
    schengen: false
  },
  {
    id: 3619,
    name: 'Kzyl-Orda Southwest Airport',
    city: 'Kzyl-Orda',
    country: 'Kazakhstan',
    iata: 'KZO',
    icao: 'UAOO',
    latitude: 44.706902,
    longitude: 65.592499,
    altitude: 433,
    schengen: false
  },
  {
    id: 3620,
    name: 'Uralsk Airport',
    city: 'Uralsk',
    country: 'Kazakhstan',
    iata: 'URA',
    icao: 'UARR',
    latitude: 51.1507987976074,
    longitude: 51.543098449707,
    altitude: 125,
    schengen: false
  },
  {
    id: 3621,
    name: 'Ayaguz Airport',
    city: 'Ayaguz',
    country: 'Kazakhstan',
    iata: '',
    icao: 'UASA',
    latitude: 47.9185981750488,
    longitude: 80.4527969360352,
    altitude: 2119,
    schengen: false
  },
  {
    id: 3622,
    name: 'Ekibastuz Airport',
    city: 'Ekibastuz',
    country: 'Kazakhstan',
    iata: 'EKB',
    icao: 'UASB',
    latitude: 51.5909996032715,
    longitude: 75.2149963378906,
    altitude: 621,
    schengen: false
  },
  {
    id: 3623,
    name: 'Ust-Kamennogorsk Airport',
    city: 'Ust Kamenogorsk',
    country: 'Kazakhstan',
    iata: 'UKK',
    icao: 'UASK',
    latitude: 50.0365982055664,
    longitude: 82.4942016601563,
    altitude: 939,
    schengen: false
  },
  {
    id: 3624,
    name: 'Pavlodar Airport',
    city: 'Pavlodar',
    country: 'Kazakhstan',
    iata: 'PWQ',
    icao: 'UASP',
    latitude: 52.1949996948242,
    longitude: 77.0738983154297,
    altitude: 410,
    schengen: false
  },
  {
    id: 3625,
    name: 'Semipalatinsk Airport',
    city: 'Semiplatinsk',
    country: 'Kazakhstan',
    iata: 'PLX',
    icao: 'UASS',
    latitude: 50.351295,
    longitude: 80.234398,
    altitude: 761,
    schengen: false
  },
  {
    id: 3626,
    name: 'Aktau Airport',
    city: 'Aktau',
    country: 'Kazakhstan',
    iata: 'SCO',
    icao: 'UATE',
    latitude: 43.8601,
    longitude: 51.091999,
    altitude: 73,
    schengen: false
  },
  {
    id: 3627,
    name: 'Atyrau Airport',
    city: 'Atyrau',
    country: 'Kazakhstan',
    iata: 'GUW',
    icao: 'UATG',
    latitude: 47.1218986511231,
    longitude: 51.8213996887207,
    altitude: -72,
    schengen: false
  },
  {
    id: 3628,
    name: 'Chelkar Airport',
    city: 'Chelkar',
    country: 'Kazakhstan',
    iata: '',
    icao: 'UATR',
    latitude: 47.9049987792969,
    longitude: 59.6199989318848,
    altitude: 0,
    schengen: false
  },
  {
    id: 3629,
    name: 'Aktobe Airport',
    city: 'Aktyubinsk',
    country: 'Kazakhstan',
    iata: 'AKX',
    icao: 'UATT',
    latitude: 50.2458,
    longitude: 57.206699,
    altitude: 738,
    schengen: false
  },
  {
    id: 3630,
    name: 'Arkalyk North Airport',
    city: 'Arkalyk',
    country: 'Kazakhstan',
    iata: 'AYK',
    icao: 'UAUR',
    latitude: 50.3185997009277,
    longitude: 66.9527969360352,
    altitude: 1266,
    schengen: false
  },
  {
    id: 3631,
    name: 'Kostanay West Airport',
    city: 'Kostanay',
    country: 'Kazakhstan',
    iata: 'KSN',
    icao: 'UAUU',
    latitude: 53.206901550293,
    longitude: 63.5503005981445,
    altitude: 595,
    schengen: false
  },
  {
    id: 3632,
    name: 'Amboseli Airport',
    city: 'Amboseli National Park',
    country: 'Kenya',
    iata: 'ASV',
    icao: 'HKAM',
    latitude: -2.64505004882812,
    longitude: 37.253101348877,
    altitude: 3755,
    schengen: false
  },
  {
    id: 3633,
    name: 'Busia Airport',
    city: 'Busia',
    country: 'Kenya',
    iata: '',
    icao: 'HKBA',
    latitude: 0.457085013389587,
    longitude: 34.1302795410156,
    altitude: 3989,
    schengen: false
  },
  {
    id: 3634,
    name: 'Bura East Airport',
    city: 'Bura',
    country: 'Kenya',
    iata: '',
    icao: 'HKBR',
    latitude: -1.10000002384186,
    longitude: 39.9500007629395,
    altitude: 345,
    schengen: false
  },
  {
    id: 3635,
    name: 'Bungoma Airport',
    city: 'Bungoma',
    country: 'Kenya',
    iata: '',
    icao: 'HKBU',
    latitude: 0.576207995414734,
    longitude: 34.5534515380859,
    altitude: 4726,
    schengen: false
  },
  {
    id: 3636,
    name: 'Eldoret International Airport',
    city: 'Eldoret',
    country: 'Kenya',
    iata: 'EDL',
    icao: 'HKEL',
    latitude: 0.404457986354828,
    longitude: 35.238899230957,
    altitude: 6941,
    schengen: false
  },
  {
    id: 3637,
    name: 'Embu Airport',
    city: 'Embu',
    country: 'Kenya',
    iata: '',
    icao: 'HKEM',
    latitude: -0.57327,
    longitude: 37.49726,
    altitude: 4150,
    schengen: false
  },
  {
    id: 3638,
    name: 'Garissa Airport',
    city: 'Garissa',
    country: 'Kenya',
    iata: 'GAS',
    icao: 'HKGA',
    latitude: -0.463508009910584,
    longitude: 39.6483001708984,
    altitude: 475,
    schengen: false
  },
  {
    id: 3639,
    name: 'Garba Tula Airport',
    city: 'Garba Tula',
    country: 'Kenya',
    iata: '',
    icao: 'HKGT',
    latitude: 0.533333,
    longitude: 38.516667,
    altitude: 2000,
    schengen: false
  },
  {
    id: 3640,
    name: 'Homa Bay Airport',
    city: 'Homa Bay',
    country: 'Kenya',
    iata: '',
    icao: 'HKHB',
    latitude: -0.600000023841858,
    longitude: 34.4669990539551,
    altitude: 4280,
    schengen: false
  },
  {
    id: 3641,
    name: 'Hola Airport',
    city: 'Hola',
    country: 'Kenya',
    iata: 'HOA',
    icao: 'HKHO',
    latitude: -1.52199995517731,
    longitude: 40.0040016174316,
    altitude: 195,
    schengen: false
  },
  {
    id: 3642,
    name: 'Isiolo Airport',
    city: 'Isiolo',
    country: 'Kenya',
    iata: '',
    icao: 'HKIS',
    latitude: 0.338170945644379,
    longitude: 37.5916938781738,
    altitude: 3495,
    schengen: false
  },
  {
    id: 3643,
    name: 'Jomo Kenyatta International Airport',
    city: 'Nairobi',
    country: 'Kenya',
    iata: 'NBO',
    icao: 'HKJK',
    latitude: -1.31923997402,
    longitude: 36.9277992249,
    altitude: 5330,
    schengen: false
  },
  {
    id: 3644,
    name: 'Keekorok Airport',
    city: 'Keekorok',
    country: 'Kenya',
    iata: '',
    icao: 'HKKE',
    latitude: -1.58299994468689,
    longitude: 35.25,
    altitude: 5800,
    schengen: false
  },
  {
    id: 3645,
    name: 'Kakamega Airport',
    city: 'Kakamega',
    country: 'Kenya',
    iata: 'GGM',
    icao: 'HKKG',
    latitude: 0.271342009306,
    longitude: 34.7873001099,
    altitude: 5020,
    schengen: false
  },
  {
    id: 3646,
    name: 'Kisumu Airport',
    city: 'Kisumu',
    country: 'Kenya',
    iata: 'KIS',
    icao: 'HKKI',
    latitude: -0.086139000952244,
    longitude: 34.7289009094238,
    altitude: 3734,
    schengen: false
  },
  {
    id: 3647,
    name: 'Kilaguni Airport',
    city: 'Kilaguni',
    country: 'Kenya',
    iata: 'ILU',
    icao: 'HKKL',
    latitude: -2.91060996055603,
    longitude: 38.0652008056641,
    altitude: 2750,
    schengen: false
  },
  {
    id: 3648,
    name: 'Kericho Airport',
    city: 'Kericho',
    country: 'Kenya',
    iata: 'KEY',
    icao: 'HKKR',
    latitude: -0.3899,
    longitude: 35.242093,
    altitude: 6446,
    schengen: false
  },
  {
    id: 3649,
    name: 'Kitale Airport',
    city: 'Kitale',
    country: 'Kenya',
    iata: 'KTL',
    icao: 'HKKT',
    latitude: 0.97198897600174,
    longitude: 34.9585990905762,
    altitude: 6070,
    schengen: false
  },
  {
    id: 3650,
    name: 'Lokichoggio Airport',
    city: 'Lokichoggio',
    country: 'Kenya',
    iata: 'LKG',
    icao: 'HKLK',
    latitude: 4.20412015914917,
    longitude: 34.348201751709,
    altitude: 2074,
    schengen: false
  },
  {
    id: 3651,
    name: 'Lodwar Airport',
    city: 'Lodwar',
    country: 'Kenya',
    iata: 'LOK',
    icao: 'HKLO',
    latitude: 3.1219699382782,
    longitude: 35.608699798584,
    altitude: 1715,
    schengen: false
  },
  {
    id: 3652,
    name: 'Manda Airstrip',
    city: 'Lamu',
    country: 'Kenya',
    iata: 'LAU',
    icao: 'HKLU',
    latitude: -2.25241994857788,
    longitude: 40.9131011962891,
    altitude: 20,
    schengen: false
  },
  {
    id: 3653,
    name: 'Meru Mulika Airport',
    city: 'Meru National Park',
    country: 'Kenya',
    iata: '',
    icao: 'HKMK',
    latitude: 0.229567,
    longitude: 38.171412,
    altitude: 2230,
    schengen: false
  },
  {
    id: 3654,
    name: 'Malindi Airport',
    city: 'Malindi',
    country: 'Kenya',
    iata: 'MYD',
    icao: 'HKML',
    latitude: -3.22931003570557,
    longitude: 40.1016998291016,
    altitude: 80,
    schengen: false
  },
  {
    id: 3655,
    name: 'Mombasa Moi International Airport',
    city: 'Mombasa',
    country: 'Kenya',
    iata: 'MBA',
    icao: 'HKMO',
    latitude: -4.03483009338379,
    longitude: 39.5942001342773,
    altitude: 200,
    schengen: false
  },
  {
    id: 3656,
    name: 'Mara Serena Lodge Airstrip',
    city: 'Masai Mara',
    country: 'Kenya',
    iata: 'MRE',
    icao: 'HKMS',
    latitude: -1.406111,
    longitude: 35.008057,
    altitude: 5200,
    schengen: false
  },
  {
    id: 3657,
    name: 'Moyale Airport',
    city: 'Moyale',
    country: 'Kenya',
    iata: 'OYL',
    icao: 'HKMY',
    latitude: 3.46972,
    longitude: 39.101398,
    altitude: 2790,
    schengen: false
  },
  {
    id: 3658,
    name: 'Nyeri Airport',
    city: 'NYERI',
    country: 'Kenya',
    iata: 'NYE',
    icao: 'HKNI',
    latitude: -0.364414006471634,
    longitude: 36.9784851074219,
    altitude: 5830,
    schengen: false
  },
  {
    id: 3659,
    name: 'Naivasha Airport',
    city: 'Naivasha',
    country: 'Kenya',
    iata: '',
    icao: 'HKNV',
    latitude: -0.787953,
    longitude: 36.433494,
    altitude: 6380,
    schengen: false
  },
  {
    id: 3660,
    name: 'Nairobi Wilson Airport',
    city: 'Nairobi',
    country: 'Kenya',
    iata: 'WIL',
    icao: 'HKNW',
    latitude: -1.32172000408173,
    longitude: 36.8148002624512,
    altitude: 5536,
    schengen: false
  },
  {
    id: 3661,
    name: 'Nanyuki Airport',
    city: 'Nanyuki',
    country: 'Kenya',
    iata: 'NYK',
    icao: 'HKNY',
    latitude: -0.06239889934659,
    longitude: 37.0410079956055,
    altitude: 6250,
    schengen: false
  },
  {
    id: 3662,
    name: 'Moi Air Base',
    city: 'Nairobi',
    country: 'Kenya',
    iata: '',
    icao: 'HKRE',
    latitude: -1.27726995945,
    longitude: 36.8623008728,
    altitude: 5336,
    schengen: false
  },
  {
    id: 3663,
    name: 'Buffalo Spring',
    city: 'Samburu South',
    country: 'Kenya',
    iata: 'UAS',
    icao: 'HKSB',
    latitude: 0.530583,
    longitude: 37.534195,
    altitude: 3295,
    schengen: false
  },
  {
    id: 3664,
    name: 'Ukunda Airstrip',
    city: 'Ukunda',
    country: 'Kenya',
    iata: 'UKA',
    icao: 'HKUK',
    latitude: -4.29333,
    longitude: 39.571098,
    altitude: 98,
    schengen: false
  },
  {
    id: 3665,
    name: 'Wajir Airport',
    city: 'Wajir',
    country: 'Kenya',
    iata: 'WJR',
    icao: 'HKWJ',
    latitude: 1.73324,
    longitude: 40.091599,
    altitude: 770,
    schengen: false
  },
  {
    id: 3666,
    name: 'Burnet Municipal Kate Craddock Field',
    city: 'Bamburi',
    country: 'Kenya',
    iata: '',
    icao: 'KBMQ',
    latitude: 30.738899230957,
    longitude: -98.2386016845703,
    altitude: 1284,
    schengen: false
  },
  {
    id: 3667,
    name: 'Abaiang Airport',
    city: 'Abaiang Atoll',
    country: 'Kiribati',
    iata: 'ABF',
    icao: 'NGAB',
    latitude: 1.79860997200012,
    longitude: 173.041000366211,
    altitude: 0,
    schengen: false
  },
  {
    id: 3668,
    name: 'Beru Airport',
    city: 'Beru Island',
    country: 'Kiribati',
    iata: 'BEZ',
    icao: 'NGBR',
    latitude: -1.35471999645233,
    longitude: 176.00700378418,
    altitude: 6,
    schengen: false
  },
  {
    id: 3669,
    name: 'Kuria Airport',
    city: 'Kuria',
    country: 'Kiribati',
    iata: 'KUC',
    icao: 'NGKT',
    latitude: 0.218611001968384,
    longitude: 173.442001342773,
    altitude: 0,
    schengen: false
  },
  {
    id: 3670,
    name: 'Maiana Airport',
    city: 'Maiana',
    country: 'Kiribati',
    iata: 'MNK',
    icao: 'NGMA',
    latitude: 1.00361001491547,
    longitude: 173.031005859375,
    altitude: 8,
    schengen: false
  },
  {
    id: 3671,
    name: 'Marakei Airport',
    city: 'Marakei',
    country: 'Kiribati',
    iata: 'MZK',
    icao: 'NGMK',
    latitude: 2.05860996246338,
    longitude: 173.27099609375,
    altitude: 10,
    schengen: false
  },
  {
    id: 3672,
    name: 'Makin Island Airport',
    city: 'Makin',
    country: 'Kiribati',
    iata: 'MTK',
    icao: 'NGMN',
    latitude: 3.37443995475769,
    longitude: 172.992004394531,
    altitude: 0,
    schengen: false
  },
  {
    id: 3673,
    name: 'Nikunau Airport',
    city: 'Nikunau',
    country: 'Kiribati',
    iata: 'NIG',
    icao: 'NGNU',
    latitude: -1.31444001198,
    longitude: 176.410003662,
    altitude: 6,
    schengen: false
  },
  {
    id: 3674,
    name: 'Bonriki International Airport',
    city: 'Tarawa',
    country: 'Kiribati',
    iata: 'TRW',
    icao: 'NGTA',
    latitude: 1.38163995742798,
    longitude: 173.147003173828,
    altitude: 9,
    schengen: false
  },
  {
    id: 3675,
    name: 'Abemama Atoll Airport',
    city: 'Abemama',
    country: 'Kiribati',
    iata: 'AEA',
    icao: 'NGTB',
    latitude: 0.490833014249802,
    longitude: 173.828994750977,
    altitude: 8,
    schengen: false
  },
  {
    id: 3676,
    name: 'Tabiteuea North Airport',
    city: 'Tabiteuea North',
    country: 'Kiribati',
    iata: 'TBF',
    icao: 'NGTE',
    latitude: -1.22447001934052,
    longitude: 174.776000976563,
    altitude: 7,
    schengen: false
  },
  {
    id: 3677,
    name: 'Tamana Island Airport',
    city: 'Tamana',
    country: 'Kiribati',
    iata: 'TMN',
    icao: 'NGTM',
    latitude: -2.48583006858826,
    longitude: 175.970001220703,
    altitude: 0,
    schengen: false
  },
  {
    id: 3678,
    name: 'Nonouti Airport',
    city: 'Nonouti',
    country: 'Kiribati',
    iata: 'NON',
    icao: 'NGTO',
    latitude: -0.639721989631653,
    longitude: 174.427993774414,
    altitude: 10,
    schengen: false
  },
  {
    id: 3679,
    name: 'Arorae Island Airport',
    city: 'Arorae',
    country: 'Kiribati',
    iata: 'AIS',
    icao: 'NGTR',
    latitude: -2.61611008644104,
    longitude: 176.802993774414,
    altitude: 0,
    schengen: false
  },
  {
    id: 3680,
    name: 'Tabiteuea South Airport',
    city: 'Tabiteuea',
    country: 'Kiribati',
    iata: 'TSU',
    icao: 'NGTS',
    latitude: -1.47443997859955,
    longitude: 175.063995361328,
    altitude: 0,
    schengen: false
  },
  {
    id: 3681,
    name: 'Butaritari Atoll Airport',
    city: 'Butaritari',
    country: 'Kiribati',
    iata: 'BBG',
    icao: 'NGTU',
    latitude: 3.08583,
    longitude: 172.811005,
    altitude: 5,
    schengen: false
  },
  {
    id: 3682,
    name: 'Buariki Airport',
    city: 'Buariki',
    country: 'Kiribati',
    iata: 'AAK',
    icao: 'NGUK',
    latitude: 0.185277998447418,
    longitude: 173.636993408203,
    altitude: 0,
    schengen: false
  },
  {
    id: 3683,
    name: 'Canton Island Airport',
    city: 'Canton Island',
    country: 'Kiribati',
    iata: 'CIS',
    icao: 'PCIS',
    latitude: -2.76812005043,
    longitude: -171.710006714,
    altitude: 9,
    schengen: false
  },
  {
    id: 3684,
    name: 'Cassidy International Airport',
    city: 'Kiritimati',
    country: 'Kiribati',
    iata: 'CXI',
    icao: 'PLCH',
    latitude: 1.98616003990173,
    longitude: -157.350006103516,
    altitude: 5,
    schengen: false
  },
  {
    id: 3685,
    name: 'Ahmed Al Jaber Air Base',
    city: 'Ahmed Al Jaber AB',
    country: 'Kuwait',
    iata: 'XIJ',
    icao: 'OKAJ',
    latitude: 28.9347991943,
    longitude: 47.7919006348,
    altitude: 409,
    schengen: false
  },
  {
    id: 3686,
    name: 'Ali Al Salem Air Base',
    city: 'Kuwait',
    country: 'Kuwait',
    iata: '',
    icao: 'OKAS',
    latitude: 29.346700668335,
    longitude: 47.5208015441895,
    altitude: 472,
    schengen: false
  },
  {
    id: 3687,
    name: 'Kuwait International Airport',
    city: 'Kuwait',
    country: 'Kuwait',
    iata: 'KWI',
    icao: 'OKBK',
    latitude: 29.2266006469727,
    longitude: 47.9688987731934,
    altitude: 206,
    schengen: false
  },
  {
    id: 3688,
    name: 'Batken Airport',
    city: 'Batken',
    country: 'Kyrgyzstan',
    iata: '',
    icao: 'UA30',
    latitude: 40.042899,
    longitude: 70.83784,
    altitude: 3517,
    schengen: false
  },
  {
    id: 3689,
    name: 'Issyk-Kul International Airport',
    city: 'Tamchy',
    country: 'Kyrgyzstan',
    iata: 'IKU',
    icao: 'UAFL',
    latitude: 42.58792,
    longitude: 76.713046,
    altitude: 5425,
    schengen: false
  },
  {
    id: 3690,
    name: 'Manas International Airport',
    city: 'Bishkek',
    country: 'Kyrgyzstan',
    iata: 'FRU',
    icao: 'UAFM',
    latitude: 43.0612983704,
    longitude: 74.4776000977,
    altitude: 2058,
    schengen: false
  },
  {
    id: 3691,
    name: 'Osh Airport',
    city: 'Osh',
    country: 'Kyrgyzstan',
    iata: 'OSS',
    icao: 'UAFO',
    latitude: 40.6090011597,
    longitude: 72.793296814,
    altitude: 2927,
    schengen: false
  },
  {
    id: 3692,
    name: 'Kant Air Base',
    city: 'Kant',
    country: 'Kyrgyzstan',
    iata: '',
    icao: 'UAFW',
    latitude: 42.853199005127,
    longitude: 74.8464965820313,
    altitude: 2549,
    schengen: false
  },
  {
    id: 3693,
    name: 'Kazarman Airport',
    city: 'Kazarman',
    country: 'Kyrgyzstan',
    iata: '',
    icao: 'UAFZ',
    latitude: 41.4102908129,
    longitude: 74.044418335,
    altitude: 4298,
    schengen: false
  },
  {
    id: 3694,
    name: 'Attopeu Airport',
    city: 'Attopeu',
    country: 'Laos',
    iata: 'AOU',
    icao: 'VLAP',
    latitude: 14.814715357,
    longitude: 106.821699142,
    altitude: 344,
    schengen: false
  },
  {
    id: 3695,
    name: 'Ban Huoeisay Airport',
    city: 'Huay Xai',
    country: 'Laos',
    iata: 'HOE',
    icao: 'VLHS',
    latitude: 20.2572994232,
    longitude: 100.43699646,
    altitude: 1380,
    schengen: false
  },
  {
    id: 3696,
    name: 'Luang Phabang International Airport',
    city: 'Luang Prabang',
    country: 'Laos',
    iata: 'LPQ',
    icao: 'VLLB',
    latitude: 19.8973007202148,
    longitude: 102.161003112793,
    altitude: 955,
    schengen: false
  },
  {
    id: 3697,
    name: 'Luang Namtha Airport',
    city: 'Luang Namtha',
    country: 'Laos',
    iata: 'LXG',
    icao: 'VLLN',
    latitude: 20.966999,
    longitude: 101.400002,
    altitude: 1968,
    schengen: false
  },
  {
    id: 3698,
    name: 'Oudomsay Airport',
    city: 'Muang Xay',
    country: 'Laos',
    iata: 'ODY',
    icao: 'VLOS',
    latitude: 20.6826992034912,
    longitude: 101.994003295898,
    altitude: 1804,
    schengen: false
  },
  {
    id: 3699,
    name: 'Pakse International Airport',
    city: 'Pakse',
    country: 'Laos',
    iata: 'PKZ',
    icao: 'VLPS',
    latitude: 15.1321001052856,
    longitude: 105.78099822998,
    altitude: 351,
    schengen: false
  },
  {
    id: 3700,
    name: 'Phonesavanh Airport',
    city: 'Phong Savanh',
    country: 'Laos',
    iata: '',
    icao: 'VLPV',
    latitude: 19.4549007415771,
    longitude: 103.218002319336,
    altitude: 3628,
    schengen: false
  },
  {
    id: 3701,
    name: 'Sayaboury Airport',
    city: 'Sainyabuli',
    country: 'Laos',
    iata: 'ZBY',
    icao: 'VLSB',
    latitude: 19.2436,
    longitude: 101.7093,
    altitude: 962,
    schengen: false
  },
  {
    id: 3702,
    name: 'Savannakhet Airport',
    city: 'Savannakhet',
    country: 'Laos',
    iata: 'ZVK',
    icao: 'VLSK',
    latitude: 16.5566005706787,
    longitude: 104.76000213623,
    altitude: 509,
    schengen: false
  },
  {
    id: 3703,
    name: 'Sam Neua Airport',
    city: 'Sam Neua',
    country: 'Laos',
    iata: 'NEU',
    icao: 'VLSN',
    latitude: 20.418399810791,
    longitude: 104.067001342773,
    altitude: 3281,
    schengen: false
  },
  {
    id: 3704,
    name: 'Saravane Airport',
    city: 'Saravane',
    country: 'Laos',
    iata: 'VNA',
    icao: 'VLSV',
    latitude: 15.7094392077,
    longitude: 106.410698891,
    altitude: 574,
    schengen: false
  },
  {
    id: 3705,
    name: 'Wattay International Airport',
    city: 'Vientiane',
    country: 'Laos',
    iata: 'VTE',
    icao: 'VLVT',
    latitude: 17.9883003235,
    longitude: 102.56300354,
    altitude: 564,
    schengen: false
  },
  {
    id: 3706,
    name: 'Xieng Khouang Airport',
    city: 'Phon Savan',
    country: 'Laos',
    iata: 'XKH',
    icao: 'VLXK',
    latitude: 19.450001,
    longitude: 103.157997,
    altitude: 3445,
    schengen: false
  },
  {
    id: 3707,
    name: 'Daugavpils Intrenational Airport',
    city: 'Daugavpils',
    country: 'Latvia',
    iata: 'DGP',
    icao: 'EVDA',
    latitude: 55.9447212219,
    longitude: 26.6650009155,
    altitude: 398,
    schengen: true
  },
  {
    id: 3708,
    name: 'Jēkabpils Air Base',
    city: 'Jēkabpils',
    country: 'Latvia',
    iata: '',
    icao: 'EVKA',
    latitude: 56.534698,
    longitude: 25.8925,
    altitude: 289,
    schengen: true
  },
  {
    id: 3709,
    name: 'Liepāja International Airport',
    city: 'Liepaja',
    country: 'Latvia',
    iata: 'LPX',
    icao: 'EVLA',
    latitude: 56.5175018310547,
    longitude: 21.0969009399414,
    altitude: 16,
    schengen: true
  },
  {
    id: 3710,
    name: 'Riga International Airport',
    city: 'Riga',
    country: 'Latvia',
    iata: 'RIX',
    icao: 'EVRA',
    latitude: 56.9235992431641,
    longitude: 23.9710998535156,
    altitude: 36,
    schengen: true
  },
  {
    id: 3711,
    name: 'Jūrmala Airport',
    city: 'Tukums',
    country: 'Latvia',
    iata: '',
    icao: 'EVTA',
    latitude: 56.9422,
    longitude: 23.2239,
    altitude: 220,
    schengen: true
  },
  {
    id: 3712,
    name: 'Ventspils International Airport',
    city: 'Ventspils',
    country: 'Latvia',
    iata: 'VNT',
    icao: 'EVVA',
    latitude: 57.35779953,
    longitude: 21.5442008972,
    altitude: 19,
    schengen: true
  },
  {
    id: 3713,
    name: 'Beirut Rafic Hariri International Airport',
    city: 'Beirut',
    country: 'Lebanon',
    iata: 'BEY',
    icao: 'OLBA',
    latitude: 33.8208999633789,
    longitude: 35.4883995056152,
    altitude: 87,
    schengen: false
  },
  {
    id: 3714,
    name: 'Rene Mouawad Air Base',
    city: 'Kleiat',
    country: 'Lebanon',
    iata: 'KYE',
    icao: 'OLKA',
    latitude: 34.589298248291,
    longitude: 36.0112991333008,
    altitude: 75,
    schengen: false
  },
  {
    id: 3715,
    name: 'Rayak Air Base',
    city: 'Rayak',
    country: 'Lebanon',
    iata: '',
    icao: 'OLRA',
    latitude: 33.8507995605469,
    longitude: 35.9877014160156,
    altitude: 3018,
    schengen: false
  },
  {
    id: 3716,
    name: 'Moshoeshoe I International Airport',
    city: 'Maseru',
    country: 'Lesotho',
    iata: 'MSU',
    icao: 'FXMM',
    latitude: -29.4622993469238,
    longitude: 27.5524997711182,
    altitude: 5348,
    schengen: false
  },
  {
    id: 3717,
    name: 'Mantsonyane Airport',
    city: 'Mantsonyane',
    country: 'Lesotho',
    iata: '',
    icao: 'FXMN',
    latitude: -29.5461006164551,
    longitude: 28.2709999084473,
    altitude: 7100,
    schengen: false
  },
  {
    id: 3718,
    name: 'Mejametalana Airbase',
    city: 'Maseru',
    country: 'Lesotho',
    iata: '',
    icao: 'FXMU',
    latitude: -29.3041000366211,
    longitude: 27.5034999847412,
    altitude: 5105,
    schengen: false
  },
  {
    id: 3719,
    name: 'Cape Palmas Airport',
    city: 'Greenville',
    country: 'Liberia',
    iata: 'CPA',
    icao: 'GLCP',
    latitude: 4.37902021408081,
    longitude: -7.69694995880127,
    altitude: 20,
    schengen: false
  },
  {
    id: 3720,
    name: 'Spriggs Payne Airport',
    city: 'Monrovia',
    country: 'Liberia',
    iata: 'MLW',
    icao: 'GLMR',
    latitude: 6.28906011581421,
    longitude: -10.7587003707886,
    altitude: 25,
    schengen: false
  },
  {
    id: 3721,
    name: 'Roberts International Airport',
    city: 'Monrovia',
    country: 'Liberia',
    iata: 'ROB',
    icao: 'GLRB',
    latitude: 6.23378992080689,
    longitude: -10.3622999191284,
    altitude: 31,
    schengen: false
  },
  {
    id: 3722,
    name: 'Bu Attifel Airport',
    city: 'Buattifel',
    country: 'Libya',
    iata: '',
    icao: 'HLFL',
    latitude: 28.7954006195068,
    longitude: 22.0809001922607,
    altitude: 161,
    schengen: false
  },
  {
    id: 3723,
    name: 'Gardabya Airport',
    city: 'Sirt',
    country: 'Libya',
    iata: 'SRX',
    icao: 'HLGD',
    latitude: 31.0634994507,
    longitude: 16.5949993134,
    altitude: 267,
    schengen: false
  },
  {
    id: 3724,
    name: 'Warehouse 59e Airport',
    city: 'Giallo',
    country: 'Libya',
    iata: '',
    icao: 'HLGL',
    latitude: 28.638500213623,
    longitude: 21.4379997253418,
    altitude: 325,
    schengen: false
  },
  {
    id: 3725,
    name: 'Gamal Abdel Nasser Airport',
    city: 'Tobruk',
    country: 'Libya',
    iata: 'TOB',
    icao: 'HLGN',
    latitude: 31.861,
    longitude: 23.907,
    altitude: 519,
    schengen: false
  },
  {
    id: 3726,
    name: 'Ghat Airport',
    city: 'Ghat',
    country: 'Libya',
    iata: 'GHT',
    icao: 'HLGT',
    latitude: 25.1455993652,
    longitude: 10.1426000595,
    altitude: 2296,
    schengen: false
  },
  {
    id: 3727,
    name: 'Kufra Airport',
    city: 'Kufra',
    country: 'Libya',
    iata: 'AKF',
    icao: 'HLKF',
    latitude: 24.1786994934082,
    longitude: 23.3139991760254,
    altitude: 1367,
    schengen: false
  },
  {
    id: 3728,
    name: 'Benina International Airport',
    city: 'Benghazi',
    country: 'Libya',
    iata: 'BEN',
    icao: 'HLLB',
    latitude: 32.0968017578,
    longitude: 20.2695007324,
    altitude: 433,
    schengen: false
  },
  {
    id: 3729,
    name: 'Mitiga Airport',
    city: 'Tripoli',
    country: 'Libya',
    iata: 'MJI',
    icao: 'HLLM',
    latitude: 32.894100189209,
    longitude: 13.2760000228882,
    altitude: 36,
    schengen: false
  },
  {
    id: 3730,
    name: 'La Abraq Airport',
    city: "Al Bayda'",
    country: 'Libya',
    iata: 'LAQ',
    icao: 'HLLQ',
    latitude: 32.7887001037598,
    longitude: 21.9643001556396,
    altitude: 2157,
    schengen: false
  },
  {
    id: 3731,
    name: 'Sabha Airport',
    city: 'Sebha',
    country: 'Libya',
    iata: 'SEB',
    icao: 'HLLS',
    latitude: 26.9869995117187,
    longitude: 14.4724998474121,
    altitude: 1427,
    schengen: false
  },
  {
    id: 3732,
    name: 'Tripoli International Airport',
    city: 'Tripoli',
    country: 'Libya',
    iata: 'TIP',
    icao: 'HLLT',
    latitude: 32.6635017395,
    longitude: 13.1590003967,
    altitude: 263,
    schengen: false
  },
  {
    id: 3733,
    name: 'Marsa Brega Airport',
    city: 'Marsa Brega',
    country: 'Libya',
    iata: 'LMQ',
    icao: 'HLMB',
    latitude: 30.3780994415283,
    longitude: 19.5764007568359,
    altitude: 50,
    schengen: false
  },
  {
    id: 3734,
    name: 'Ras Lanuf Oil Airport',
    city: 'Ras Lanouf V 40',
    country: 'Libya',
    iata: '',
    icao: 'HLNF',
    latitude: 30.5,
    longitude: 18.5272006988525,
    altitude: 42,
    schengen: false
  },
  {
    id: 3735,
    name: 'Hon Airport',
    city: 'Hon',
    country: 'Libya',
    iata: 'HUQ',
    icao: 'HLON',
    latitude: 29.1100997924805,
    longitude: 15.9656000137329,
    altitude: 919,
    schengen: false
  },
  {
    id: 3736,
    name: 'Dahra Airport',
    city: 'Dahra',
    country: 'Libya',
    iata: '',
    icao: 'HLRA',
    latitude: 29.4726009368896,
    longitude: 17.9349002838135,
    altitude: 1050,
    schengen: false
  },
  {
    id: 3737,
    name: 'Ghadames East Airport',
    city: 'Ghadames',
    country: 'Libya',
    iata: 'LTD',
    icao: 'HLTD',
    latitude: 30.1516990661621,
    longitude: 9.71531009674072,
    altitude: 1122,
    schengen: false
  },
  {
    id: 3738,
    name: 'Zella 74 Airport',
    city: 'Zella 74',
    country: 'Libya',
    iata: '',
    icao: 'HLZA',
    latitude: 28.5898990631104,
    longitude: 17.2938995361328,
    altitude: 1085,
    schengen: false
  },
  {
    id: 3739,
    name: 'Alzintan Airport',
    city: 'Zintan',
    country: 'Libya',
    iata: 'ZIS',
    icao: 'HLZN',
    latitude: 31.774878,
    longitude: 12.25006,
    altitude: 2080,
    schengen: false
  },
  {
    id: 3740,
    name: 'Kaunas International Airport',
    city: 'Kaunas',
    country: 'Lithuania',
    iata: 'KUN',
    icao: 'EYKA',
    latitude: 54.9639015197754,
    longitude: 24.0848007202148,
    altitude: 256,
    schengen: true
  },
  {
    id: 3741,
    name: 'Kėdainiai Air Base',
    city: 'Kėdainiai',
    country: 'Lithuania',
    iata: '',
    icao: 'EYKD',
    latitude: 55.3116989135742,
    longitude: 23.9563999176025,
    altitude: 171,
    schengen: true
  },
  {
    id: 3742,
    name: 'Klaipėda Airport',
    city: 'Klaipeda',
    country: 'Lithuania',
    iata: 'KLJ',
    icao: 'EYKL',
    latitude: 55.7118988037,
    longitude: 21.2427997589,
    altitude: 59,
    schengen: true
  },
  {
    id: 3743,
    name: 'S. Darius and S. Girėnas Airport',
    city: 'Kaunas',
    country: 'Lithuania',
    iata: '',
    icao: 'EYKS',
    latitude: 54.8797988892,
    longitude: 23.8815002441,
    altitude: 246,
    schengen: true
  },
  {
    id: 3744,
    name: 'Palanga International Airport',
    city: 'Palanga',
    country: 'Lithuania',
    iata: 'PLQ',
    icao: 'EYPA',
    latitude: 55.973201751709,
    longitude: 21.093900680542,
    altitude: 33,
    schengen: true
  },
  {
    id: 3745,
    name: 'Panevėžys Air Base',
    city: 'Panevezys',
    country: 'Lithuania',
    iata: 'PNV',
    icao: 'EYPP',
    latitude: 55.7294006347656,
    longitude: 24.4608001708984,
    altitude: 197,
    schengen: true
  },
  {
    id: 3746,
    name: 'Šiauliai International Airport',
    city: 'Siauliai',
    country: 'Lithuania',
    iata: 'SQQ',
    icao: 'EYSA',
    latitude: 55.8939018249512,
    longitude: 23.3950004577637,
    altitude: 443,
    schengen: true
  },
  {
    id: 3747,
    name: 'Barysiai Airport',
    city: 'Barysiai',
    country: 'Lithuania',
    iata: 'HLJ',
    icao: 'EYSB',
    latitude: 56.0705986022949,
    longitude: 23.5580997467041,
    altitude: 270,
    schengen: true
  },
  {
    id: 3748,
    name: 'Vilnius International Airport',
    city: 'Vilnius',
    country: 'Lithuania',
    iata: 'VNO',
    icao: 'EYVI',
    latitude: 54.634102,
    longitude: 25.285801,
    altitude: 648,
    schengen: true
  },
  {
    id: 3749,
    name: 'Paluknys Airport',
    city: 'Paluknys',
    country: 'Lithuania',
    iata: '',
    icao: 'EYVP',
    latitude: 54.4848480224609,
    longitude: 24.9873733520508,
    altitude: 400,
    schengen: true
  },
  {
    id: 3750,
    name: 'Luxembourg-Findel International Airport',
    city: 'Luxemburg',
    country: 'Luxembourg',
    iata: 'LUX',
    icao: 'ELLX',
    latitude: 49.6233333,
    longitude: 6.2044444,
    altitude: 1234,
    schengen: true
  },
  {
    id: 3751,
    name: 'Macau International Airport',
    city: 'Macau',
    country: 'Macau',
    iata: 'MFM',
    icao: 'VMMC',
    latitude: 22.149599,
    longitude: 113.592003,
    altitude: 20,
    schengen: false
  },
  {
    id: 3752,
    name: 'Ohrid St. Paul the Apostle Airport',
    city: 'Ohrid',
    country: 'Macedonia',
    iata: 'OHD',
    icao: 'LWOH',
    latitude: 41.18,
    longitude: 20.7423,
    altitude: 2313,
    schengen: false
  },
  {
    id: 3753,
    name: 'Skopje Alexander the Great Airport',
    city: 'Skopje',
    country: 'Macedonia',
    iata: 'SKP',
    icao: 'LWSK',
    latitude: 41.961601,
    longitude: 21.621401,
    altitude: 781,
    schengen: false
  },
  {
    id: 3754,
    name: 'Antsirabe Airport',
    city: 'Antsirabe',
    country: 'Madagascar',
    iata: 'ATJ',
    icao: 'FMME',
    latitude: -19.8392214824,
    longitude: 47.0637130737,
    altitude: 4997,
    schengen: false
  },
  {
    id: 3755,
    name: 'Antsalova Airport',
    city: 'Antsalova',
    country: 'Madagascar',
    iata: 'WAQ',
    icao: 'FMMG',
    latitude: -18.701273,
    longitude: 44.614921,
    altitude: 551,
    schengen: false
  },
  {
    id: 3756,
    name: 'Ivato Airport',
    city: 'Antananarivo',
    country: 'Madagascar',
    iata: 'TNR',
    icao: 'FMMI',
    latitude: -18.7968997955,
    longitude: 47.4788017273,
    altitude: 4198,
    schengen: false
  },
  {
    id: 3757,
    name: 'Ankavandra Airport',
    city: 'Ankavandra',
    country: 'Madagascar',
    iata: 'JVA',
    icao: 'FMMK',
    latitude: -18.80501,
    longitude: 45.273467,
    altitude: 427,
    schengen: false
  },
  {
    id: 3758,
    name: 'Belo sur Tsiribihina Airport',
    city: 'Belo sur Tsiribihina',
    country: 'Madagascar',
    iata: 'BMD',
    icao: 'FMML',
    latitude: -19.6867008209,
    longitude: 44.5419006348,
    altitude: 154,
    schengen: false
  },
  {
    id: 3759,
    name: 'Miandrivazo Airport',
    city: 'Miandrivazo',
    country: 'Madagascar',
    iata: 'ZVA',
    icao: 'FMMN',
    latitude: -19.5627994537354,
    longitude: 45.4508018493652,
    altitude: 203,
    schengen: false
  },
  {
    id: 3760,
    name: 'Maintirano Airport',
    city: 'Maintirano',
    country: 'Madagascar',
    iata: 'MXT',
    icao: 'FMMO',
    latitude: -18.049999,
    longitude: 44.033001,
    altitude: 95,
    schengen: false
  },
  {
    id: 3761,
    name: 'Morafenobe Airport',
    city: 'Morafenobe',
    country: 'Madagascar',
    iata: 'TVA',
    icao: 'FMMR',
    latitude: -17.850083,
    longitude: 44.920467,
    altitude: 748,
    schengen: false
  },
  {
    id: 3762,
    name: 'Sainte Marie Airport',
    city: 'Sainte Marie',
    country: 'Madagascar',
    iata: 'SMS',
    icao: 'FMMS',
    latitude: -17.093900680542,
    longitude: 49.8157997131348,
    altitude: 7,
    schengen: false
  },
  {
    id: 3763,
    name: 'Toamasina Airport',
    city: 'Toamasina',
    country: 'Madagascar',
    iata: 'TMM',
    icao: 'FMMT',
    latitude: -18.1095008850098,
    longitude: 49.3925018310547,
    altitude: 22,
    schengen: false
  },
  {
    id: 3764,
    name: 'Tambohorano Airport',
    city: 'Tambohorano',
    country: 'Madagascar',
    iata: 'WTA',
    icao: 'FMMU',
    latitude: -17.4761009216309,
    longitude: 43.9728012084961,
    altitude: 23,
    schengen: false
  },
  {
    id: 3765,
    name: 'Morondava Airport',
    city: 'Morondava',
    country: 'Madagascar',
    iata: 'MOQ',
    icao: 'FMMV',
    latitude: -20.2847003936768,
    longitude: 44.3176002502441,
    altitude: 30,
    schengen: false
  },
  {
    id: 3766,
    name: 'Tsiroanomandidy Airport',
    city: 'Tsiroanomandidy',
    country: 'Madagascar',
    iata: 'WTS',
    icao: 'FMMX',
    latitude: -18.759677,
    longitude: 46.054065,
    altitude: 2776,
    schengen: false
  },
  {
    id: 3767,
    name: 'Ambatondrazaka Airport',
    city: 'Ambatondrazaka',
    country: 'Madagascar',
    iata: 'WAM',
    icao: 'FMMZ',
    latitude: -17.795378,
    longitude: 48.442583,
    altitude: 2513,
    schengen: false
  },
  {
    id: 3768,
    name: 'Arrachart Airport',
    city: 'Antsiranana',
    country: 'Madagascar',
    iata: 'DIE',
    icao: 'FMNA',
    latitude: -12.3493995666504,
    longitude: 49.2916984558106,
    altitude: 374,
    schengen: false
  },
  {
    id: 3769,
    name: 'Mananara Nord Airport',
    city: 'Mananara',
    country: 'Madagascar',
    iata: 'WMR',
    icao: 'FMNC',
    latitude: -16.1639003753662,
    longitude: 49.7737998962402,
    altitude: 9,
    schengen: false
  },
  {
    id: 3770,
    name: 'Andapa Airport',
    city: 'Andapa',
    country: 'Madagascar',
    iata: 'ZWA',
    icao: 'FMND',
    latitude: -14.6517000198364,
    longitude: 49.6206016540527,
    altitude: 1552,
    schengen: false
  },
  {
    id: 3771,
    name: 'Ambilobe Airport',
    city: 'Ambilobe',
    country: 'Madagascar',
    iata: 'AMB',
    icao: 'FMNE',
    latitude: -13.1884002685547,
    longitude: 48.9879989624023,
    altitude: 72,
    schengen: false
  },
  {
    id: 3772,
    name: 'Port Bergé Airport',
    city: 'Port Bergé',
    country: 'Madagascar',
    iata: 'WPB',
    icao: 'FMNG',
    latitude: -15.584286,
    longitude: 47.623587,
    altitude: 213,
    schengen: false
  },
  {
    id: 3773,
    name: 'Antsirabato Airport',
    city: 'Antalaha',
    country: 'Madagascar',
    iata: 'ANM',
    icao: 'FMNH',
    latitude: -14.999400138855,
    longitude: 50.3202018737793,
    altitude: 20,
    schengen: false
  },
  {
    id: 3774,
    name: 'Ambanja Airport',
    city: 'Ambanja',
    country: 'Madagascar',
    iata: '',
    icao: 'FMNJ',
    latitude: -13.645705,
    longitude: 48.459427,
    altitude: 36,
    schengen: false
  },
  {
    id: 3775,
    name: 'Analalava Airport',
    city: 'Analalava',
    country: 'Madagascar',
    iata: 'HVA',
    icao: 'FMNL',
    latitude: -14.6296997070313,
    longitude: 47.763801574707,
    altitude: 345,
    schengen: false
  },
  {
    id: 3776,
    name: 'Amborovy Airport',
    city: 'Mahajanga',
    country: 'Madagascar',
    iata: 'MJN',
    icao: 'FMNM',
    latitude: -15.6668417421,
    longitude: 46.3512325287,
    altitude: 87,
    schengen: false
  },
  {
    id: 3777,
    name: 'Fascene Airport',
    city: 'Nosy-be',
    country: 'Madagascar',
    iata: 'NOS',
    icao: 'FMNN',
    latitude: -13.3121004105,
    longitude: 48.3148002625,
    altitude: 36,
    schengen: false
  },
  {
    id: 3778,
    name: 'Soalala Airport',
    city: 'Soalala',
    country: 'Madagascar',
    iata: 'DWB',
    icao: 'FMNO',
    latitude: -16.10169,
    longitude: 45.358837,
    altitude: 141,
    schengen: false
  },
  {
    id: 3779,
    name: 'Mampikony Airport',
    city: 'Mampikony',
    country: 'Madagascar',
    iata: 'WMP',
    icao: 'FMNP',
    latitude: -16.0722693402,
    longitude: 47.6441645622,
    altitude: 0,
    schengen: false
  },
  {
    id: 3780,
    name: 'Besalampy Airport',
    city: 'Besalampy',
    country: 'Madagascar',
    iata: 'BPY',
    icao: 'FMNQ',
    latitude: -16.7445302965,
    longitude: 44.4824838638,
    altitude: 125,
    schengen: false
  },
  {
    id: 3781,
    name: 'Maroantsetra Airport',
    city: 'Maroantsetra',
    country: 'Madagascar',
    iata: 'WMN',
    icao: 'FMNR',
    latitude: -15.4366998672485,
    longitude: 49.6883010864258,
    altitude: 13,
    schengen: false
  },
  {
    id: 3782,
    name: 'Sambava Airport',
    city: 'Sambava',
    country: 'Madagascar',
    iata: 'SVB',
    icao: 'FMNS',
    latitude: -14.2785997390747,
    longitude: 50.1747016906738,
    altitude: 20,
    schengen: false
  },
  {
    id: 3783,
    name: 'Vohimarina Airport',
    city: 'Vohemar',
    country: 'Madagascar',
    iata: 'VOH',
    icao: 'FMNV',
    latitude: -13.3758001327515,
    longitude: 50.002799987793,
    altitude: 19,
    schengen: false
  },
  {
    id: 3784,
    name: 'Ambalabe Airport',
    city: 'Antsohihy',
    country: 'Madagascar',
    iata: 'WAI',
    icao: 'FMNW',
    latitude: -14.8987998962402,
    longitude: 47.9939002990723,
    altitude: 92,
    schengen: false
  },
  {
    id: 3785,
    name: 'Mandritsara Airport',
    city: 'Mandritsara',
    country: 'Madagascar',
    iata: 'WMA',
    icao: 'FMNX',
    latitude: -15.833049,
    longitude: 48.833284,
    altitude: 1007,
    schengen: false
  },
  {
    id: 3786,
    name: 'Ampampamena Airport',
    city: 'Ampampamena',
    country: 'Madagascar',
    iata: 'IVA',
    icao: 'FMNZ',
    latitude: -13.484816,
    longitude: 48.632702,
    altitude: 49,
    schengen: false
  },
  {
    id: 3787,
    name: 'Tôlanaro Airport',
    city: 'Tolagnaro',
    country: 'Madagascar',
    iata: 'FTU',
    icao: 'FMSD',
    latitude: -25.0380992889404,
    longitude: 46.9561004638672,
    altitude: 29,
    schengen: false
  },
  {
    id: 3788,
    name: 'Fianarantsoa Airport',
    city: 'Fianarantsoa',
    country: 'Madagascar',
    iata: 'WFI',
    icao: 'FMSF',
    latitude: -21.4416007995605,
    longitude: 47.1116981506348,
    altitude: 3658,
    schengen: false
  },
  {
    id: 3789,
    name: 'Farafangana Airport',
    city: 'Farafangana',
    country: 'Madagascar',
    iata: 'RVA',
    icao: 'FMSG',
    latitude: -22.8052997589111,
    longitude: 47.8205986022949,
    altitude: 26,
    schengen: false
  },
  {
    id: 3790,
    name: 'Manja Airport',
    city: 'Manja',
    country: 'Madagascar',
    iata: 'MJA',
    icao: 'FMSJ',
    latitude: -21.426105,
    longitude: 44.316509,
    altitude: 787,
    schengen: false
  },
  {
    id: 3791,
    name: 'Manakara Airport',
    city: 'Manakara',
    country: 'Madagascar',
    iata: 'WVK',
    icao: 'FMSK',
    latitude: -22.1196994781494,
    longitude: 48.0217018127441,
    altitude: 33,
    schengen: false
  },
  {
    id: 3792,
    name: 'Bekily Airport',
    city: 'Bekily',
    country: 'Madagascar',
    iata: 'OVA',
    icao: 'FMSL',
    latitude: -24.2356947547,
    longitude: 45.3045272827,
    altitude: 1270,
    schengen: false
  },
  {
    id: 3793,
    name: 'Mananjary Airport',
    city: 'Mananjary',
    country: 'Madagascar',
    iata: 'MNJ',
    icao: 'FMSM',
    latitude: -21.2017993927002,
    longitude: 48.3582992553711,
    altitude: 20,
    schengen: false
  },
  {
    id: 3794,
    name: 'Morombe Airport',
    city: 'Morombe',
    country: 'Madagascar',
    iata: 'MXM',
    icao: 'FMSR',
    latitude: -21.7539005279541,
    longitude: 43.3754997253418,
    altitude: 16,
    schengen: false
  },
  {
    id: 3795,
    name: 'Toliara Airport',
    city: 'Toliara',
    country: 'Madagascar',
    iata: 'TLE',
    icao: 'FMST',
    latitude: -23.3833999633789,
    longitude: 43.7285003662109,
    altitude: 29,
    schengen: false
  },
  {
    id: 3796,
    name: 'Nasa Shuttle Landing Facility Airport',
    city: 'Tsaratanana',
    country: 'Madagascar',
    iata: '',
    icao: 'KTTS',
    latitude: 28.6149997711182,
    longitude: -80.6945037841797,
    altitude: 10,
    schengen: false
  },
  {
    id: 3797,
    name: 'Chileka International Airport',
    city: 'Blantyre',
    country: 'Malawi',
    iata: 'BLZ',
    icao: 'FWCL',
    latitude: -15.6791000366211,
    longitude: 34.9739990234375,
    altitude: 2555,
    schengen: false
  },
  {
    id: 3798,
    name: 'Club Makokola Airport',
    city: 'Club Makokola',
    country: 'Malawi',
    iata: 'CMK',
    icao: 'FWCM',
    latitude: -14.3069000244141,
    longitude: 35.1324996948242,
    altitude: 1587,
    schengen: false
  },
  {
    id: 3799,
    name: 'Dwangwa Airport',
    city: 'Dwangwa',
    country: 'Malawi',
    iata: 'DWA',
    icao: 'FWDW',
    latitude: -12.5183,
    longitude: 34.131901,
    altitude: 1605,
    schengen: false
  },
  {
    id: 3800,
    name: 'Karonga Airport',
    city: 'Karonga',
    country: 'Malawi',
    iata: 'KGJ',
    icao: 'FWKA',
    latitude: -9.95357036590576,
    longitude: 33.8930015563965,
    altitude: 1765,
    schengen: false
  },
  {
    id: 3801,
    name: 'Kasungu Airport',
    city: 'Kasungu',
    country: 'Malawi',
    iata: 'KBQ',
    icao: 'FWKG',
    latitude: -13.0145998001099,
    longitude: 33.4686012268066,
    altitude: 3470,
    schengen: false
  },
  {
    id: 3802,
    name: 'Lilongwe International Airport',
    city: 'Lilongwe',
    country: 'Malawi',
    iata: 'LLW',
    icao: 'FWKI',
    latitude: -13.7894001007,
    longitude: 33.78099823,
    altitude: 4035,
    schengen: false
  },
  {
    id: 3803,
    name: 'Likoma Island Airport',
    city: 'Likoma Island',
    country: 'Malawi',
    iata: 'LIX',
    icao: 'FWLK',
    latitude: -12.075833,
    longitude: 34.737222,
    altitude: 1600,
    schengen: false
  },
  {
    id: 3804,
    name: 'Mangochi Airport',
    city: 'Mangochi',
    country: 'Malawi',
    iata: 'MAI',
    icao: 'FWMG',
    latitude: -14.4829998016357,
    longitude: 35.2669982910156,
    altitude: 1580,
    schengen: false
  },
  {
    id: 3805,
    name: 'Monkey Bay Airport',
    city: 'Monkey Bay',
    country: 'Malawi',
    iata: 'MYZ',
    icao: 'FWMY',
    latitude: -14.0836000443,
    longitude: 34.9197006226,
    altitude: 1580,
    schengen: false
  },
  {
    id: 3806,
    name: 'Mzuzu Airport',
    city: 'Mzuzu',
    country: 'Malawi',
    iata: 'ZZU',
    icao: 'FWUU',
    latitude: -11.4447002410889,
    longitude: 34.0117988586426,
    altitude: 4115,
    schengen: false
  },
  {
    id: 3807,
    name: 'Bintulu Airport',
    city: 'Bintulu',
    country: 'Malaysia',
    iata: 'BTU',
    icao: 'WBGB',
    latitude: 3.12385010719,
    longitude: 113.019996643,
    altitude: 74,
    schengen: false
  },
  {
    id: 3808,
    name: 'Belaga Airport',
    city: 'Belaga',
    country: 'Malaysia',
    iata: 'BLG',
    icao: 'WBGC',
    latitude: 2.65000009537,
    longitude: 113.766998291,
    altitude: 200,
    schengen: false
  },
  {
    id: 3809,
    name: 'Long Lellang Airport',
    city: 'Long Datih',
    country: 'Malaysia',
    iata: 'LGL',
    icao: 'WBGF',
    latitude: 3.42100000381,
    longitude: 115.153999329,
    altitude: 1400,
    schengen: false
  },
  {
    id: 3810,
    name: 'Kuching International Airport',
    city: 'Kuching',
    country: 'Malaysia',
    iata: 'KCH',
    icao: 'WBGG',
    latitude: 1.48469996452332,
    longitude: 110.34700012207,
    altitude: 89,
    schengen: false
  },
  {
    id: 3811,
    name: 'Long Seridan Airport',
    city: 'Long Seridan',
    country: 'Malaysia',
    iata: 'ODN',
    icao: 'WBGI',
    latitude: 3.96700000762939,
    longitude: 115.050003051758,
    altitude: 607,
    schengen: false
  },
  {
    id: 3812,
    name: 'Limbang Airport',
    city: 'Limbang',
    country: 'Malaysia',
    iata: 'LMN',
    icao: 'WBGJ',
    latitude: 4.80830001831055,
    longitude: 115.01000213623,
    altitude: 14,
    schengen: false
  },
  {
    id: 3813,
    name: 'Mukah Airport',
    city: 'Mukah',
    country: 'Malaysia',
    iata: 'MKM',
    icao: 'WBGK',
    latitude: 2.90639,
    longitude: 112.080002,
    altitude: 13,
    schengen: false
  },
  {
    id: 3814,
    name: 'Long Akah Airport',
    city: 'Long Akah',
    country: 'Malaysia',
    iata: 'LKH',
    icao: 'WBGL',
    latitude: 3.29999995231628,
    longitude: 114.782997131348,
    altitude: 289,
    schengen: false
  },
  {
    id: 3815,
    name: 'Marudi Airport',
    city: 'Marudi',
    country: 'Malaysia',
    iata: 'MUR',
    icao: 'WBGM',
    latitude: 4.17897987365723,
    longitude: 114.329002380371,
    altitude: 103,
    schengen: false
  },
  {
    id: 3816,
    name: 'Bakalalan Airport',
    city: 'Bakalalan',
    country: 'Malaysia',
    iata: 'BKM',
    icao: 'WBGQ',
    latitude: 3.97399997711182,
    longitude: 115.61799621582,
    altitude: 2900,
    schengen: false
  },
  {
    id: 3817,
    name: 'Miri Airport',
    city: 'Miri',
    country: 'Malaysia',
    iata: 'MYY',
    icao: 'WBGR',
    latitude: 4.3220100402832,
    longitude: 113.986999511719,
    altitude: 59,
    schengen: false
  },
  {
    id: 3818,
    name: 'Sibu Airport',
    city: 'Sibu',
    country: 'Malaysia',
    iata: 'SBW',
    icao: 'WBGS',
    latitude: 2.26160001754761,
    longitude: 111.985000610352,
    altitude: 122,
    schengen: false
  },
  {
    id: 3819,
    name: 'Lawas Airport',
    city: 'Lawas',
    country: 'Malaysia',
    iata: 'LWY',
    icao: 'WBGW',
    latitude: 4.84917,
    longitude: 115.407997,
    altitude: 5,
    schengen: false
  },
  {
    id: 3820,
    name: 'Bario Airport',
    city: 'Bario',
    country: 'Malaysia',
    iata: 'BBN',
    icao: 'WBGZ',
    latitude: 3.73389005661011,
    longitude: 115.478996276855,
    altitude: 3350,
    schengen: false
  },
  {
    id: 3821,
    name: 'Lahad Datu Airport',
    city: 'Lahad Datu',
    country: 'Malaysia',
    iata: 'LDU',
    icao: 'WBKD',
    latitude: 5.03224992752075,
    longitude: 118.323997497559,
    altitude: 45,
    schengen: false
  },
  {
    id: 3822,
    name: 'Kota Kinabalu International Airport',
    city: 'Kota Kinabalu',
    country: 'Malaysia',
    iata: 'BKI',
    icao: 'WBKK',
    latitude: 5.93721008300781,
    longitude: 116.051002502441,
    altitude: 10,
    schengen: false
  },
  {
    id: 3823,
    name: 'Labuan Airport',
    city: 'Labuan',
    country: 'Malaysia',
    iata: 'LBU',
    icao: 'WBKL',
    latitude: 5.30068016052246,
    longitude: 115.25,
    altitude: 101,
    schengen: false
  },
  {
    id: 3824,
    name: 'Tomanggong Airport',
    city: 'Tomanggong',
    country: 'Malaysia',
    iata: 'TMG',
    icao: 'WBKM',
    latitude: 5.40257,
    longitude: 118.65763,
    altitude: 26,
    schengen: false
  },
  {
    id: 3825,
    name: 'Sandakan Airport',
    city: 'Sandakan',
    country: 'Malaysia',
    iata: 'SDK',
    icao: 'WBKS',
    latitude: 5.90089988708496,
    longitude: 118.05899810791,
    altitude: 46,
    schengen: false
  },
  {
    id: 3826,
    name: 'Kudat Airport',
    city: 'Kudat',
    country: 'Malaysia',
    iata: 'KUD',
    icao: 'WBKT',
    latitude: 6.9225001335144,
    longitude: 116.835998535156,
    altitude: 10,
    schengen: false
  },
  {
    id: 3827,
    name: 'Tawau Airport',
    city: 'Tawau',
    country: 'Malaysia',
    iata: 'TWU',
    icao: 'WBKW',
    latitude: 4.32015991210938,
    longitude: 118.127998352051,
    altitude: 57,
    schengen: false
  },
  {
    id: 3828,
    name: 'Mulu Airport',
    city: 'Mulu',
    country: 'Malaysia',
    iata: 'MZV',
    icao: 'WBMU',
    latitude: 4.04832983016968,
    longitude: 114.805000305176,
    altitude: 80,
    schengen: false
  },
  {
    id: 3829,
    name: 'Kluang Airport',
    city: 'Kluang',
    country: 'Malaysia',
    iata: '',
    icao: 'WMAP',
    latitude: 2.04138994217,
    longitude: 103.306999207,
    altitude: 150,
    schengen: false
  },
  {
    id: 3830,
    name: 'Pulau Tioman Airport',
    city: 'Tioman',
    country: 'Malaysia',
    iata: 'TOD',
    icao: 'WMBT',
    latitude: 2.81818008422852,
    longitude: 104.160003662109,
    altitude: 15,
    schengen: false
  },
  {
    id: 3831,
    name: 'Sultan Abdul Halim Airport',
    city: 'Alor Setar',
    country: 'Malaysia',
    iata: 'AOR',
    icao: 'WMKA',
    latitude: 6.18967008590698,
    longitude: 100.398002624512,
    altitude: 15,
    schengen: false
  },
  {
    id: 3832,
    name: 'Butterworth Airport',
    city: 'Butterworth',
    country: 'Malaysia',
    iata: 'BWH',
    icao: 'WMKB',
    latitude: 5.46591997146606,
    longitude: 100.390998840332,
    altitude: 11,
    schengen: false
  },
  {
    id: 3833,
    name: 'Sultan Ismail Petra Airport',
    city: 'Kota Bahru',
    country: 'Malaysia',
    iata: 'KBR',
    icao: 'WMKC',
    latitude: 6.16685009002686,
    longitude: 102.292999267578,
    altitude: 16,
    schengen: false
  },
  {
    id: 3834,
    name: 'Kuantan Airport',
    city: 'Kuantan',
    country: 'Malaysia',
    iata: 'KUA',
    icao: 'WMKD',
    latitude: 3.77538990974426,
    longitude: 103.208999633789,
    altitude: 58,
    schengen: false
  },
  {
    id: 3835,
    name: 'Kerteh Airport',
    city: 'Kerteh',
    country: 'Malaysia',
    iata: 'KTE',
    icao: 'WMKE',
    latitude: 4.5372200012207,
    longitude: 103.427001953125,
    altitude: 18,
    schengen: false
  },
  {
    id: 3836,
    name: 'Simpang Airport',
    city: 'Simpang',
    country: 'Malaysia',
    iata: '',
    icao: 'WMKF',
    latitude: 3.11225008964539,
    longitude: 101.703002929688,
    altitude: 111,
    schengen: false
  },
  {
    id: 3837,
    name: 'Sultan Azlan Shah Airport',
    city: 'Ipoh',
    country: 'Malaysia',
    iata: 'IPH',
    icao: 'WMKI',
    latitude: 4.56796979904175,
    longitude: 101.092002868652,
    altitude: 130,
    schengen: false
  },
  {
    id: 3838,
    name: 'Senai International Airport',
    city: 'Johor Bahru',
    country: 'Malaysia',
    iata: 'JHB',
    icao: 'WMKJ',
    latitude: 1.64131,
    longitude: 103.669998,
    altitude: 135,
    schengen: false
  },
  {
    id: 3839,
    name: 'Kuala Lumpur International Airport',
    city: 'Kuala Lumpur',
    country: 'Malaysia',
    iata: 'KUL',
    icao: 'WMKK',
    latitude: 2.745579957962,
    longitude: 101.70999908447,
    altitude: 69,
    schengen: false
  },
  {
    id: 3840,
    name: 'Langkawi International Airport',
    city: 'Langkawi',
    country: 'Malaysia',
    iata: 'LGK',
    icao: 'WMKL',
    latitude: 6.32973003387451,
    longitude: 99.7286987304688,
    altitude: 29,
    schengen: false
  },
  {
    id: 3841,
    name: 'Malacca Airport',
    city: 'Malacca',
    country: 'Malaysia',
    iata: 'MKZ',
    icao: 'WMKM',
    latitude: 2.2633600235,
    longitude: 102.251998901,
    altitude: 35,
    schengen: false
  },
  {
    id: 3842,
    name: 'Sultan Mahmud Airport',
    city: 'Kuala Terengganu',
    country: 'Malaysia',
    iata: 'TGG',
    icao: 'WMKN',
    latitude: 5.38263988494873,
    longitude: 103.102996826172,
    altitude: 21,
    schengen: false
  },
  {
    id: 3843,
    name: 'Penang International Airport',
    city: 'Penang',
    country: 'Malaysia',
    iata: 'PEN',
    icao: 'WMKP',
    latitude: 5.29714012145996,
    longitude: 100.277000427246,
    altitude: 11,
    schengen: false
  },
  {
    id: 3844,
    name: 'Pulau Pangkor Airport',
    city: 'Pangkor Island',
    country: 'Malaysia',
    iata: 'PKG',
    icao: 'WMPA',
    latitude: 4.24471998214722,
    longitude: 100.553001403809,
    altitude: 19,
    schengen: false
  },
  {
    id: 3845,
    name: 'LTS Pulau Redang Airport',
    city: 'Redang',
    country: 'Malaysia',
    iata: 'RDN',
    icao: 'WMPR',
    latitude: 5.76527976989746,
    longitude: 103.00700378418,
    altitude: 36,
    schengen: false
  },
  {
    id: 3846,
    name: 'Sultan Abdul Aziz Shah International Airport',
    city: 'Kuala Lumpur',
    country: 'Malaysia',
    iata: 'SZB',
    icao: 'WMSA',
    latitude: 3.13057994842529,
    longitude: 101.549003601074,
    altitude: 90,
    schengen: false
  },
  {
    id: 3847,
    name: 'Ifuru Airport',
    city: 'Ifuru',
    country: 'Maldives',
    iata: 'IFU',
    icao: 'VREI',
    latitude: 5.7083,
    longitude: 73.025,
    altitude: 20,
    schengen: false
  },
  {
    id: 3848,
    name: 'Dharavandhoo Airport',
    city: 'DHARAVANDHOO',
    country: 'Maldives',
    iata: 'DRV',
    icao: 'VRMD',
    latitude: 5.1561,
    longitude: 73.1302,
    altitude: 6,
    schengen: false
  },
  {
    id: 3849,
    name: 'Gan International Airport',
    city: 'Gan Island',
    country: 'Maldives',
    iata: 'GAN',
    icao: 'VRMG',
    latitude: -0.693342,
    longitude: 73.155602,
    altitude: 6,
    schengen: false
  },
  {
    id: 3850,
    name: 'Hanimaadhoo Airport',
    city: 'Haa Dhaalu Atoll',
    country: 'Maldives',
    iata: 'HAQ',
    icao: 'VRMH',
    latitude: 6.74422979354858,
    longitude: 73.1705017089844,
    altitude: 4,
    schengen: false
  },
  {
    id: 3851,
    name: 'Kadhdhoo Airport',
    city: 'Laamu Atoll',
    country: 'Maldives',
    iata: 'KDO',
    icao: 'VRMK',
    latitude: 1.85916996002197,
    longitude: 73.5218963623047,
    altitude: 4,
    schengen: false
  },
  {
    id: 3852,
    name: 'Malé International Airport',
    city: 'Male',
    country: 'Maldives',
    iata: 'MLE',
    icao: 'VRMM',
    latitude: 4.19183015823364,
    longitude: 73.5290985107422,
    altitude: 6,
    schengen: false
  },
  {
    id: 3853,
    name: 'Kooddoo Airport',
    city: 'Kooddoo',
    country: 'Maldives',
    iata: 'GKK',
    icao: 'VRMO',
    latitude: 0.7324,
    longitude: 73.4336,
    altitude: 29,
    schengen: false
  },
  {
    id: 3854,
    name: 'Kaadedhdhoo Airport',
    city: 'Kaadedhdhoo',
    country: 'Maldives',
    iata: 'KDM',
    icao: 'VRMT',
    latitude: 0.488130986690521,
    longitude: 72.9969024658203,
    altitude: 2,
    schengen: false
  },
  {
    id: 3855,
    name: 'Villa Airport',
    city: 'Maamigili',
    country: 'Maldives',
    iata: 'VAM',
    icao: 'VRMV',
    latitude: 3.47055555556,
    longitude: 72.8358333333,
    altitude: 6,
    schengen: false
  },
  {
    id: 3856,
    name: 'Thimarafushi Airport',
    city: 'Thimarafushi',
    country: 'Maldives',
    iata: 'TMF',
    icao: 'VRNT',
    latitude: 2.211,
    longitude: 73.1533,
    altitude: 6,
    schengen: false
  },
  {
    id: 3857,
    name: 'Sun Island Resort and SPA',
    city: 'South Aari Atoll',
    country: 'Maldives',
    iata: '',
    icao: '',
    latitude: 3.488334,
    longitude: 72.862989,
    altitude: 0,
    schengen: false
  },
  {
    id: 3858,
    name: 'Bougouni Airport',
    city: 'Bougouni',
    country: 'Mali',
    iata: '',
    icao: 'GABG',
    latitude: 11.4499998092651,
    longitude: -7.51700019836426,
    altitude: 1139,
    schengen: false
  },
  {
    id: 3859,
    name: 'Senou Airport',
    city: 'Bamako',
    country: 'Mali',
    iata: 'BKO',
    icao: 'GABS',
    latitude: 12.5334997177124,
    longitude: -7.94994020462036,
    altitude: 1247,
    schengen: false
  },
  {
    id: 3860,
    name: 'Gao Airport',
    city: 'Gao',
    country: 'Mali',
    iata: 'GAQ',
    icao: 'GAGO',
    latitude: 16.2483997344971,
    longitude: -0.005456000100821,
    altitude: 870,
    schengen: false
  },
  {
    id: 3861,
    name: 'Kayes Dag Dag Airport',
    city: 'Kayes',
    country: 'Mali',
    iata: 'KYS',
    icao: 'GAKY',
    latitude: 14.4812002182007,
    longitude: -11.4043998718262,
    altitude: 164,
    schengen: false
  },
  {
    id: 3862,
    name: 'Mopti Airport',
    city: 'Mopti',
    country: 'Mali',
    iata: 'MZI',
    icao: 'GAMB',
    latitude: 14.5128002167,
    longitude: -4.07955980301,
    altitude: 906,
    schengen: false
  },
  {
    id: 3863,
    name: 'Sikasso Airport',
    city: 'Sikasso',
    country: 'Mali',
    iata: 'KSS',
    icao: 'GASK',
    latitude: 11.3330001831055,
    longitude: -5.69999980926514,
    altitude: 1378,
    schengen: false
  },
  {
    id: 3864,
    name: 'Timbuktu Airport',
    city: 'Tombouctou',
    country: 'Mali',
    iata: 'TOM',
    icao: 'GATB',
    latitude: 16.7304992675781,
    longitude: -3.00758004188538,
    altitude: 863,
    schengen: false
  },
  {
    id: 3865,
    name: 'Tessalit Airport',
    city: 'Tessalit',
    country: 'Mali',
    iata: '',
    icao: 'GATS',
    latitude: 20.2430000305176,
    longitude: 0.977307975292206,
    altitude: 1621,
    schengen: false
  },
  {
    id: 3866,
    name: 'Xewkija Heliport',
    city: 'Gozo',
    country: 'Malta',
    iata: 'GZM',
    icao: 'LMMG',
    latitude: 36.027199,
    longitude: 14.2728,
    altitude: 0,
    schengen: true
  },
  {
    id: 3867,
    name: 'Malta International Airport',
    city: 'Malta',
    country: 'Malta',
    iata: 'MLA',
    icao: 'LMML',
    latitude: 35.857498,
    longitude: 14.4775,
    altitude: 300,
    schengen: true
  },
  {
    id: 3868,
    name: 'Utirik Airport',
    city: 'Utirik Island',
    country: 'Marshall Islands',
    iata: 'UTK',
    icao: '03N',
    latitude: 11.222,
    longitude: 169.852005,
    altitude: 4,
    schengen: false
  },
  {
    id: 3869,
    name: 'Mili Island Airport',
    city: 'Mili Island',
    country: 'Marshall Islands',
    iata: 'MIJ',
    icao: 'MLIP',
    latitude: 6.08333,
    longitude: 171.733002,
    altitude: 4,
    schengen: false
  },
  {
    id: 3870,
    name: 'Eniwetok Airport',
    city: 'Eniwetok Atoll',
    country: 'Marshall Islands',
    iata: 'ENT',
    icao: 'PKMA',
    latitude: 11.3407001495361,
    longitude: 162.328002929688,
    altitude: 13,
    schengen: false
  },
  {
    id: 3871,
    name: 'Marshall Islands International Airport',
    city: 'Majuro',
    country: 'Marshall Islands',
    iata: 'MAJ',
    icao: 'PKMJ',
    latitude: 7.06476020812988,
    longitude: 171.272003173828,
    altitude: 6,
    schengen: false
  },
  {
    id: 3872,
    name: 'Dyess Army Air Field',
    city: 'Kwajalein',
    country: 'Marshall Islands',
    iata: '',
    icao: 'PKRO',
    latitude: 9.39688968658,
    longitude: 167.470993042,
    altitude: 9,
    schengen: false
  },
  {
    id: 3873,
    name: 'Bucholz Army Air Field',
    city: 'Kwajalein',
    country: 'Marshall Islands',
    iata: 'KWA',
    icao: 'PKWA',
    latitude: 8.72012042999268,
    longitude: 167.731994628906,
    altitude: 9,
    schengen: false
  },
  {
    id: 3874,
    name: 'Kili Airport',
    city: 'Kili Island',
    country: 'Marshall Islands',
    iata: 'KIO',
    icao: 'Q51',
    latitude: 5.644515,
    longitude: 169.119507,
    altitude: 5,
    schengen: false
  },
  {
    id: 3875,
    name: 'Ujae Atoll Airport',
    city: 'Ujae Atoll',
    country: 'Marshall Islands',
    iata: 'UJE',
    icao: 'UJAP',
    latitude: 8.92805957794,
    longitude: 165.761993408,
    altitude: 29,
    schengen: false
  },
  {
    id: 3876,
    name: 'Martinique Aimé Césaire International Airport',
    city: 'Fort-de-france',
    country: 'Martinique',
    iata: 'FDF',
    icao: 'TFFF',
    latitude: 14.5909996032715,
    longitude: -61.0032005310059,
    altitude: 16,
    schengen: false
  },
  {
    id: 3877,
    name: 'Aioun el Atrouss Airport',
    city: 'Aioun El Atrouss',
    country: 'Mauritania',
    iata: 'AEO',
    icao: 'GQNA',
    latitude: 16.7112998962402,
    longitude: -9.63788032531738,
    altitude: 951,
    schengen: false
  },
  {
    id: 3878,
    name: 'Tidjikja Airport',
    city: 'Tidjikja',
    country: 'Mauritania',
    iata: 'TIY',
    icao: 'GQND',
    latitude: 18.5701007843018,
    longitude: -11.4235000610352,
    altitude: 1363,
    schengen: false
  },
  {
    id: 3879,
    name: 'Kiffa Airport',
    city: 'Kiffa',
    country: 'Mauritania',
    iata: 'KFA',
    icao: 'GQNF',
    latitude: 16.5900001525879,
    longitude: -11.4062004089355,
    altitude: 424,
    schengen: false
  },
  {
    id: 3880,
    name: 'Néma Airport',
    city: 'Nema',
    country: 'Mauritania',
    iata: 'EMN',
    icao: 'GQNI',
    latitude: 16.6219997406006,
    longitude: -7.31659984588623,
    altitude: 751,
    schengen: false
  },
  {
    id: 3881,
    name: 'Kaédi Airport',
    city: 'Kaedi',
    country: 'Mauritania',
    iata: 'KED',
    icao: 'GQNK',
    latitude: 16.1595001220703,
    longitude: -13.5075998306274,
    altitude: 66,
    schengen: false
  },
  {
    id: 3882,
    name: 'Nouakchott–Oumtounsy International Airport',
    city: 'Nouakschott',
    country: 'Mauritania',
    iata: 'NKC',
    icao: 'GQNO',
    latitude: 18.31,
    longitude: -15.9697222,
    altitude: 9,
    schengen: false
  },
  {
    id: 3883,
    name: 'Sélibaby Airport',
    city: 'Selibabi',
    country: 'Mauritania',
    iata: 'SEY',
    icao: 'GQNS',
    latitude: 15.1796998977661,
    longitude: -12.2073001861572,
    altitude: 219,
    schengen: false
  },
  {
    id: 3884,
    name: 'Atar International Airport',
    city: 'Atar',
    country: 'Mauritania',
    iata: 'ATR',
    icao: 'GQPA',
    latitude: 20.506799697876,
    longitude: -13.0431995391846,
    altitude: 734,
    schengen: false
  },
  {
    id: 3885,
    name: 'Nouadhibou International Airport',
    city: 'Nouadhibou',
    country: 'Mauritania',
    iata: 'NDB',
    icao: 'GQPP',
    latitude: 20.9330997467041,
    longitude: -17.0300006866455,
    altitude: 24,
    schengen: false
  },
  {
    id: 3886,
    name: 'Bir Moghrein Airport',
    city: 'Bir Moghrein',
    country: 'Mauritania',
    iata: '',
    icao: 'GQPT',
    latitude: 25.2367000579834,
    longitude: -11.5887002944946,
    altitude: 1206,
    schengen: false
  },
  {
    id: 3887,
    name: 'Tazadit Airport',
    city: 'Zouerat',
    country: 'Mauritania',
    iata: 'OUZ',
    icao: 'GQPZ',
    latitude: 22.7563991546631,
    longitude: -12.4835996627808,
    altitude: 1129,
    schengen: false
  },
  {
    id: 3888,
    name: 'Sir Seewoosagur Ramgoolam International Airport',
    city: 'Plaisance',
    country: 'Mauritius',
    iata: 'MRU',
    icao: 'FIMP',
    latitude: -20.4302005767822,
    longitude: 57.6836013793945,
    altitude: 186,
    schengen: false
  },
  {
    id: 3889,
    name: 'Sir Charles Gaetan Duval Airport',
    city: 'Rodriguez Island',
    country: 'Mauritius',
    iata: 'RRG',
    icao: 'FIMR',
    latitude: -19.7577,
    longitude: 63.361,
    altitude: 95,
    schengen: false
  },
  {
    id: 3890,
    name: 'Dzaoudzi Pamandzi International Airport',
    city: 'Dzaoudzi',
    country: 'Mayotte',
    iata: 'DZA',
    icao: 'FMCZ',
    latitude: -12.8046998977661,
    longitude: 45.2811012268066,
    altitude: 23,
    schengen: false
  },
  {
    id: 3891,
    name: 'Camaguey-Campo Cuatro Milpas Airport',
    city: 'Guasave',
    country: 'Mexico',
    iata: '',
    icao: 'MM52',
    latitude: 25.652200698853,
    longitude: -108.53800201416,
    altitude: 92,
    schengen: false
  },
  {
    id: 3892,
    name: 'General Juan N Alvarez International Airport',
    city: 'Acapulco',
    country: 'Mexico',
    iata: 'ACA',
    icao: 'MMAA',
    latitude: 16.7570991516113,
    longitude: -99.7539978027344,
    altitude: 16,
    schengen: false
  },
  {
    id: 3893,
    name: 'Del Norte International Airport',
    city: 'Monterrey',
    country: 'Mexico',
    iata: 'NTR',
    icao: 'MMAN',
    latitude: 25.865601,
    longitude: -100.237,
    altitude: 1476,
    schengen: false
  },
  {
    id: 3894,
    name: 'Jesús Terán Paredo International Airport',
    city: 'Aguascalientes',
    country: 'Mexico',
    iata: 'AGU',
    icao: 'MMAS',
    latitude: 21.705601,
    longitude: -102.318001,
    altitude: 6112,
    schengen: false
  },
  {
    id: 3895,
    name: 'Bahías de Huatulco International Airport',
    city: 'Huatulco',
    country: 'Mexico',
    iata: 'HUX',
    icao: 'MMBT',
    latitude: 15.7753,
    longitude: -96.262604,
    altitude: 464,
    schengen: false
  },
  {
    id: 3896,
    name: 'General Mariano Matamoros Airport',
    city: 'Cuernavaca',
    country: 'Mexico',
    iata: 'CVJ',
    icao: 'MMCB',
    latitude: 18.8348007202148,
    longitude: -99.2612991333008,
    altitude: 4277,
    schengen: false
  },
  {
    id: 3897,
    name: 'Ciudad Acuña New International Airport',
    city: 'Ciudad Acuna',
    country: 'Mexico',
    iata: 'ACN',
    icao: 'MMCC',
    latitude: 29.332899,
    longitude: -101.098998,
    altitude: 1410,
    schengen: false
  },
  {
    id: 3898,
    name: 'Ciudad del Carmen International Airport',
    city: 'Ciudad Del Carmen',
    country: 'Mexico',
    iata: 'CME',
    icao: 'MMCE',
    latitude: 18.6536998748779,
    longitude: -91.7990036010742,
    altitude: 10,
    schengen: false
  },
  {
    id: 3899,
    name: 'Nuevo Casas Grandes Airport',
    city: 'Nuevo Casas Grandes',
    country: 'Mexico',
    iata: 'NCG',
    icao: 'MMCG',
    latitude: 30.3974,
    longitude: -107.875,
    altitude: 4850,
    schengen: false
  },
  {
    id: 3900,
    name: 'Chilpancingo Airport',
    city: 'Chilpancingo',
    country: 'Mexico',
    iata: '',
    icao: 'MMCH',
    latitude: 17.574239,
    longitude: -99.515424,
    altitude: 4199,
    schengen: false
  },
  {
    id: 3901,
    name: 'Bachigualato Federal International Airport',
    city: 'Culiacan',
    country: 'Mexico',
    iata: 'CUL',
    icao: 'MMCL',
    latitude: 24.7644996643,
    longitude: -107.474998474,
    altitude: 108,
    schengen: false
  },
  {
    id: 3902,
    name: 'Chetumal International Airport',
    city: 'Chetumal',
    country: 'Mexico',
    iata: 'CTM',
    icao: 'MMCM',
    latitude: 18.5046997070312,
    longitude: -88.3267974853516,
    altitude: 39,
    schengen: false
  },
  {
    id: 3903,
    name: 'Ciudad Obregón International Airport',
    city: 'Ciudad Obregon',
    country: 'Mexico',
    iata: 'CEN',
    icao: 'MMCN',
    latitude: 27.392599105835,
    longitude: -109.833000183105,
    altitude: 243,
    schengen: false
  },
  {
    id: 3904,
    name: 'Ingeniero Alberto Acuña Ongay International Airport',
    city: 'Campeche',
    country: 'Mexico',
    iata: 'CPE',
    icao: 'MMCP',
    latitude: 19.8167991638,
    longitude: -90.5002975464,
    altitude: 34,
    schengen: false
  },
  {
    id: 3905,
    name: 'Abraham González International Airport',
    city: 'Ciudad Juarez',
    country: 'Mexico',
    iata: 'CJS',
    icao: 'MMCS',
    latitude: 31.636100769043,
    longitude: -106.429000854492,
    altitude: 3904,
    schengen: false
  },
  {
    id: 3906,
    name: 'Chichen Itza International Airport',
    city: 'Chichen Itza',
    country: 'Mexico',
    iata: 'CZA',
    icao: 'MMCT',
    latitude: 20.6413002014,
    longitude: -88.4461975098,
    altitude: 102,
    schengen: false
  },
  {
    id: 3907,
    name: 'General Roberto Fierro Villalobos International Airport',
    city: 'Chihuahua',
    country: 'Mexico',
    iata: 'CUU',
    icao: 'MMCU',
    latitude: 28.7028999329,
    longitude: -105.964996338,
    altitude: 4462,
    schengen: false
  },
  {
    id: 3908,
    name: 'General Pedro Jose Mendez International Airport',
    city: 'Ciudad Victoria',
    country: 'Mexico',
    iata: 'CVM',
    icao: 'MMCV',
    latitude: 23.7033004761,
    longitude: -98.9564971924,
    altitude: 761,
    schengen: false
  },
  {
    id: 3909,
    name: 'Captain Rogelio Castillo National Airport',
    city: 'Celaya',
    country: 'Mexico',
    iata: 'CYW',
    icao: 'MMCY',
    latitude: 20.546,
    longitude: -100.887001,
    altitude: 5709,
    schengen: false
  },
  {
    id: 3910,
    name: 'Cozumel International Airport',
    city: 'Cozumel',
    country: 'Mexico',
    iata: 'CZM',
    icao: 'MMCZ',
    latitude: 20.5223999023437,
    longitude: -86.9255981445312,
    altitude: 15,
    schengen: false
  },
  {
    id: 3911,
    name: 'Ciudad Constitución Airport',
    city: 'Ciudad Constitución',
    country: 'Mexico',
    iata: 'CUA',
    icao: 'MMDA',
    latitude: 25.053800582886,
    longitude: -111.61499786377,
    altitude: 213,
    schengen: false
  },
  {
    id: 3912,
    name: 'General Guadalupe Victoria International Airport',
    city: 'Durango',
    country: 'Mexico',
    iata: 'DGO',
    icao: 'MMDO',
    latitude: 24.1242008209,
    longitude: -104.527999878,
    altitude: 6104,
    schengen: false
  },
  {
    id: 3913,
    name: 'Amado Nervo National Airport',
    city: 'Tepic',
    country: 'Mexico',
    iata: 'TPQ',
    icao: 'MMEP',
    latitude: 21.4195,
    longitude: -104.843002,
    altitude: 3020,
    schengen: false
  },
  {
    id: 3914,
    name: 'Ensenada International Airport',
    city: 'Ensenada',
    country: 'Mexico',
    iata: 'ESE',
    icao: 'MMES',
    latitude: 31.7953,
    longitude: -116.602997,
    altitude: 66,
    schengen: false
  },
  {
    id: 3915,
    name: 'Don Miguel Hidalgo Y Costilla International Airport',
    city: 'Guadalajara',
    country: 'Mexico',
    iata: 'GDL',
    icao: 'MMGL',
    latitude: 20.5217990875244,
    longitude: -103.310997009277,
    altitude: 5016,
    schengen: false
  },
  {
    id: 3916,
    name: 'General José María Yáñez International Airport',
    city: 'Guaymas',
    country: 'Mexico',
    iata: 'GYM',
    icao: 'MMGM',
    latitude: 27.9689998626709,
    longitude: -110.925003051758,
    altitude: 59,
    schengen: false
  },
  {
    id: 3917,
    name: 'Guerrero Negro Airport',
    city: 'Guerrero Negro',
    country: 'Mexico',
    iata: 'GUB',
    icao: 'MMGR',
    latitude: 28.0261001586914,
    longitude: -114.024002075195,
    altitude: 59,
    schengen: false
  },
  {
    id: 3918,
    name: 'Tehuacan Airport',
    city: 'Tehuacan',
    country: 'Mexico',
    iata: 'TCN',
    icao: 'MMHC',
    latitude: 18.497200012207,
    longitude: -97.4198989868164,
    altitude: 5509,
    schengen: false
  },
  {
    id: 3919,
    name: 'General Ignacio P. Garcia International Airport',
    city: 'Hermosillo',
    country: 'Mexico',
    iata: 'HMO',
    icao: 'MMHO',
    latitude: 29.0958995819,
    longitude: -111.047996521,
    altitude: 627,
    schengen: false
  },
  {
    id: 3920,
    name: 'Licenciado Miguel de la Madrid Airport',
    city: 'Colima',
    country: 'Mexico',
    iata: 'CLQ',
    icao: 'MMIA',
    latitude: 19.277,
    longitude: -103.577002,
    altitude: 2467,
    schengen: false
  },
  {
    id: 3921,
    name: 'Isla Mujeres Airport',
    city: 'Isla Mujeres',
    country: 'Mexico',
    iata: 'ISJ',
    icao: 'MMIM',
    latitude: 21.2450008392334,
    longitude: -86.7399978637695,
    altitude: 7,
    schengen: false
  },
  {
    id: 3922,
    name: 'Plan De Guadalupe International Airport',
    city: 'Saltillo',
    country: 'Mexico',
    iata: 'SLW',
    icao: 'MMIO',
    latitude: 25.5494995117188,
    longitude: -100.929000854492,
    altitude: 4778,
    schengen: false
  },
  {
    id: 3923,
    name: 'Ixtepec Airport',
    city: 'Iztepec',
    country: 'Mexico',
    iata: 'IZT',
    icao: 'MMIT',
    latitude: 16.449301,
    longitude: -95.093697,
    altitude: 164,
    schengen: false
  },
  {
    id: 3924,
    name: 'El Lencero Airport',
    city: 'Jalapa',
    country: 'Mexico',
    iata: 'JAL',
    icao: 'MMJA',
    latitude: 19.4750995636,
    longitude: -96.7975006104,
    altitude: 3127,
    schengen: false
  },
  {
    id: 3925,
    name: 'Lázaro Cárdenas Airport',
    city: 'Lazard Cardenas',
    country: 'Mexico',
    iata: 'LZC',
    icao: 'MMLC',
    latitude: 18.0016994476,
    longitude: -102.221000671,
    altitude: 39,
    schengen: false
  },
  {
    id: 3926,
    name: 'Valle del Fuerte International Airport',
    city: 'Los Mochis',
    country: 'Mexico',
    iata: 'LMM',
    icao: 'MMLM',
    latitude: 25.6851997375,
    longitude: -109.081001282,
    altitude: 16,
    schengen: false
  },
  {
    id: 3927,
    name: 'Del Bajío International Airport',
    city: 'Del Bajio',
    country: 'Mexico',
    iata: 'BJX',
    icao: 'MMLO',
    latitude: 20.9935,
    longitude: -101.481003,
    altitude: 5956,
    schengen: false
  },
  {
    id: 3928,
    name: 'Manuel Márquez de León International Airport',
    city: 'La Paz',
    country: 'Mexico',
    iata: 'LAP',
    icao: 'MMLP',
    latitude: 24.0727005005,
    longitude: -110.361999512,
    altitude: 69,
    schengen: false
  },
  {
    id: 3929,
    name: 'Loreto International Airport',
    city: 'Loreto',
    country: 'Mexico',
    iata: 'LTO',
    icao: 'MMLT',
    latitude: 25.989200592041,
    longitude: -111.347999572754,
    altitude: 34,
    schengen: false
  },
  {
    id: 3930,
    name: 'General Servando Canales International Airport',
    city: 'Matamoros',
    country: 'Mexico',
    iata: 'MAM',
    icao: 'MMMA',
    latitude: 25.7698993683,
    longitude: -97.5252990723,
    altitude: 25,
    schengen: false
  },
  {
    id: 3931,
    name: 'Licenciado Manuel Crescencio Rejon Int Airport',
    city: 'Merida',
    country: 'Mexico',
    iata: 'MID',
    icao: 'MMMD',
    latitude: 20.9370002747,
    longitude: -89.657699585,
    altitude: 38,
    schengen: false
  },
  {
    id: 3932,
    name: 'General Rodolfo Sánchez Taboada International Airport',
    city: 'Mexicali',
    country: 'Mexico',
    iata: 'MXL',
    icao: 'MMML',
    latitude: 32.6306,
    longitude: -115.241997,
    altitude: 74,
    schengen: false
  },
  {
    id: 3933,
    name: 'General Francisco J. Mujica International Airport',
    city: 'Morelia',
    country: 'Mexico',
    iata: 'MLM',
    icao: 'MMMM',
    latitude: 19.849899292,
    longitude: -101.025001526,
    altitude: 6033,
    schengen: false
  },
  {
    id: 3934,
    name: 'Minatitlán/Coatzacoalcos National Airport',
    city: 'Minatitlan',
    country: 'Mexico',
    iata: 'MTT',
    icao: 'MMMT',
    latitude: 18.1033992767,
    longitude: -94.5807037354,
    altitude: 36,
    schengen: false
  },
  {
    id: 3935,
    name: 'Monclova International Airport',
    city: 'Monclova',
    country: 'Mexico',
    iata: 'LOV',
    icao: 'MMMV',
    latitude: 26.9557,
    longitude: -101.470001,
    altitude: 1864,
    schengen: false
  },
  {
    id: 3936,
    name: 'Licenciado Benito Juarez International Airport',
    city: 'Mexico City',
    country: 'Mexico',
    iata: 'MEX',
    icao: 'MMMX',
    latitude: 19.4363,
    longitude: -99.072098,
    altitude: 7316,
    schengen: false
  },
  {
    id: 3937,
    name: 'General Mariano Escobedo International Airport',
    city: 'Monterrey',
    country: 'Mexico',
    iata: 'MTY',
    icao: 'MMMY',
    latitude: 25.7784996033,
    longitude: -100.107002258,
    altitude: 1278,
    schengen: false
  },
  {
    id: 3938,
    name: 'General Rafael Buelna International Airport',
    city: 'Mazatlan',
    country: 'Mexico',
    iata: 'MZT',
    icao: 'MMMZ',
    latitude: 23.1613998413,
    longitude: -106.26599884,
    altitude: 38,
    schengen: false
  },
  {
    id: 3939,
    name: 'Nogales International Airport',
    city: 'Nogales',
    country: 'Mexico',
    iata: 'NOG',
    icao: 'MMNG',
    latitude: 31.2261009216309,
    longitude: -110.975997924805,
    altitude: 3990,
    schengen: false
  },
  {
    id: 3940,
    name: 'Quetzalcóatl International Airport',
    city: 'Nuevo Laredo',
    country: 'Mexico',
    iata: 'NLD',
    icao: 'MMNL',
    latitude: 27.4438991547,
    longitude: -99.5705032349,
    altitude: 484,
    schengen: false
  },
  {
    id: 3941,
    name: 'Xoxocotlán International Airport',
    city: 'Oaxaca',
    country: 'Mexico',
    iata: 'OAX',
    icao: 'MMOX',
    latitude: 16.9999008179,
    longitude: -96.726600647,
    altitude: 4989,
    schengen: false
  },
  {
    id: 3942,
    name: 'El Tajín National Airport',
    city: 'Poza Rico',
    country: 'Mexico',
    iata: 'PAZ',
    icao: 'MMPA',
    latitude: 20.6026992798,
    longitude: -97.4608001709,
    altitude: 497,
    schengen: false
  },
  {
    id: 3943,
    name: 'Hermanos Serdán International Airport',
    city: 'Puebla',
    country: 'Mexico',
    iata: 'PBC',
    icao: 'MMPB',
    latitude: 19.1581001282,
    longitude: -98.3713989258,
    altitude: 7361,
    schengen: false
  },
  {
    id: 3944,
    name: 'Ingeniero Juan Guillermo Villasana Airport',
    city: 'Pachuca',
    country: 'Mexico',
    iata: '',
    icao: 'MMPC',
    latitude: 20.0774,
    longitude: -98.782501,
    altitude: 7600,
    schengen: false
  },
  {
    id: 3945,
    name: 'Puerto Peñasco International Airport',
    city: 'Punta Penasco',
    country: 'Mexico',
    iata: 'PPE',
    icao: 'MMPE',
    latitude: 31.356202,
    longitude: -113.525677,
    altitude: 30,
    schengen: false
  },
  {
    id: 3946,
    name: 'Piedras Negras International Airport',
    city: 'Piedras Negras',
    country: 'Mexico',
    iata: 'PDS',
    icao: 'MMPG',
    latitude: 28.627399,
    longitude: -100.535004,
    altitude: 901,
    schengen: false
  },
  {
    id: 3947,
    name: 'Licenciado y General Ignacio Lopez Rayon Airport',
    city: 'Uruapan',
    country: 'Mexico',
    iata: 'UPN',
    icao: 'MMPN',
    latitude: 19.3966999053955,
    longitude: -102.039001464844,
    altitude: 5258,
    schengen: false
  },
  {
    id: 3948,
    name: 'Palenque International Airport',
    city: 'Palenque',
    country: 'Mexico',
    iata: 'PQM',
    icao: 'MMPQ',
    latitude: 17.533153,
    longitude: -92.015484,
    altitude: 200,
    schengen: false
  },
  {
    id: 3949,
    name: 'Licenciado Gustavo Díaz Ordaz International Airport',
    city: 'Puerto Vallarta',
    country: 'Mexico',
    iata: 'PVR',
    icao: 'MMPR',
    latitude: 20.6800994873047,
    longitude: -105.253997802734,
    altitude: 23,
    schengen: false
  },
  {
    id: 3950,
    name: 'Puerto Escondido International Airport',
    city: 'Puerto Escondido',
    country: 'Mexico',
    iata: 'PXM',
    icao: 'MMPS',
    latitude: 15.8769,
    longitude: -97.089103,
    altitude: 294,
    schengen: false
  },
  {
    id: 3951,
    name: 'Querétaro Intercontinental Airport',
    city: 'Queretaro',
    country: 'Mexico',
    iata: 'QRO',
    icao: 'MMQT',
    latitude: 20.6173,
    longitude: -100.185997,
    altitude: 6296,
    schengen: false
  },
  {
    id: 3952,
    name: 'General Lucio Blanco International Airport',
    city: 'Reynosa',
    country: 'Mexico',
    iata: 'REX',
    icao: 'MMRX',
    latitude: 26.0089,
    longitude: -98.2285,
    altitude: 139,
    schengen: false
  },
  {
    id: 3953,
    name: 'San Cristobal de las Casas Airport',
    city: 'San Cristobal de las Casas',
    country: 'Mexico',
    iata: 'SZT',
    icao: 'MMSC',
    latitude: 16.690299987793,
    longitude: -92.530097961426,
    altitude: 7707,
    schengen: false
  },
  {
    id: 3954,
    name: 'Los Cabos International Airport',
    city: 'San Jose Del Cabo',
    country: 'Mexico',
    iata: 'SJD',
    icao: 'MMSD',
    latitude: 23.1518001556396,
    longitude: -109.721000671387,
    altitude: 374,
    schengen: false
  },
  {
    id: 3955,
    name: 'San Felipe International Airport',
    city: 'San Filipe',
    country: 'Mexico',
    iata: 'SFH',
    icao: 'MMSF',
    latitude: 30.930200576782,
    longitude: -114.80899810791,
    altitude: 148,
    schengen: false
  },
  {
    id: 3956,
    name: 'Cabo San Lucas International Airport',
    city: 'Cabo San Lucas',
    country: 'Mexico',
    iata: '',
    icao: 'MMSL',
    latitude: 22.947701,
    longitude: -109.936996,
    altitude: 459,
    schengen: false
  },
  {
    id: 3957,
    name: 'Ponciano Arriaga International Airport',
    city: 'San Luis Potosi',
    country: 'Mexico',
    iata: 'SLP',
    icao: 'MMSP',
    latitude: 22.2542991638,
    longitude: -100.930999756,
    altitude: 6035,
    schengen: false
  },
  {
    id: 3958,
    name: 'Tlaxcala Airport',
    city: 'Tlaxcala',
    country: 'Mexico',
    iata: '',
    icao: 'MMTA',
    latitude: 19.537992,
    longitude: -98.173492,
    altitude: 8229,
    schengen: false
  },
  {
    id: 3959,
    name: 'Terán Air Base',
    city: 'Tuxtla Gutierrez',
    country: 'Mexico',
    iata: '',
    icao: 'MMTB',
    latitude: 16.739901,
    longitude: -93.173301,
    altitude: 1909,
    schengen: false
  },
  {
    id: 3960,
    name: 'Francisco Sarabia International Airport',
    city: 'Torreon',
    country: 'Mexico',
    iata: 'TRC',
    icao: 'MMTC',
    latitude: 25.5683002472,
    longitude: -103.411003113,
    altitude: 3688,
    schengen: false
  },
  {
    id: 3961,
    name: 'Angel Albino Corzo International Airport',
    city: 'Tuxtla Gutierrez',
    country: 'Mexico',
    iata: 'TGZ',
    icao: 'MMTG',
    latitude: 16.5636005402,
    longitude: -93.0224990845,
    altitude: 1499,
    schengen: false
  },
  {
    id: 3962,
    name: 'General Abelardo L. Rodríguez International Airport',
    city: 'Tijuana',
    country: 'Mexico',
    iata: 'TIJ',
    icao: 'MMTJ',
    latitude: 32.5410995483398,
    longitude: -116.970001220703,
    altitude: 489,
    schengen: false
  },
  {
    id: 3963,
    name: 'General Francisco Javier Mina International Airport',
    city: 'Tampico',
    country: 'Mexico',
    iata: 'TAM',
    icao: 'MMTM',
    latitude: 22.2964000702,
    longitude: -97.8658981323,
    altitude: 80,
    schengen: false
  },
  {
    id: 3964,
    name: 'Tamuin Airport',
    city: 'Tamuin',
    country: 'Mexico',
    iata: 'TSL',
    icao: 'MMTN',
    latitude: 22.0383,
    longitude: -98.806502,
    altitude: 164,
    schengen: false
  },
  {
    id: 3965,
    name: 'Licenciado Adolfo Lopez Mateos International Airport',
    city: 'Toluca',
    country: 'Mexico',
    iata: 'TLC',
    icao: 'MMTO',
    latitude: 19.3370990753,
    longitude: -99.5660018921,
    altitude: 8466,
    schengen: false
  },
  {
    id: 3966,
    name: 'Tapachula International Airport',
    city: 'Tapachula',
    country: 'Mexico',
    iata: 'TAP',
    icao: 'MMTP',
    latitude: 14.7943000793,
    longitude: -92.3700027466,
    altitude: 97,
    schengen: false
  },
  {
    id: 3967,
    name: 'Cancún International Airport',
    city: 'Cancun',
    country: 'Mexico',
    iata: 'CUN',
    icao: 'MMUN',
    latitude: 21.0365009308,
    longitude: -86.8770980835,
    altitude: 22,
    schengen: false
  },
  {
    id: 3968,
    name: 'Carlos Rovirosa Pérez International Airport',
    city: 'Villahermosa',
    country: 'Mexico',
    iata: 'VSA',
    icao: 'MMVA',
    latitude: 17.9969997406006,
    longitude: -92.8173980712891,
    altitude: 46,
    schengen: false
  },
  {
    id: 3969,
    name: 'General Heriberto Jara International Airport',
    city: 'Vera Cruz',
    country: 'Mexico',
    iata: 'VER',
    icao: 'MMVR',
    latitude: 19.1459007263,
    longitude: -96.1873016357,
    altitude: 90,
    schengen: false
  },
  {
    id: 3970,
    name: 'General Leobardo C. Ruiz International Airport',
    city: 'Zacatecas',
    country: 'Mexico',
    iata: 'ZCL',
    icao: 'MMZC',
    latitude: 22.8971004486,
    longitude: -102.68699646,
    altitude: 7141,
    schengen: false
  },
  {
    id: 3971,
    name: 'Ixtapa Zihuatanejo International Airport',
    city: 'Zihuatanejo',
    country: 'Mexico',
    iata: 'ZIH',
    icao: 'MMZH',
    latitude: 17.601600647,
    longitude: -101.460998535,
    altitude: 26,
    schengen: false
  },
  {
    id: 3972,
    name: 'Zamora Airport',
    city: 'Zamora',
    country: 'Mexico',
    iata: 'ZMM',
    icao: 'MMZM',
    latitude: 20.045,
    longitude: -102.276001,
    altitude: 5141,
    schengen: false
  },
  {
    id: 3973,
    name: 'Playa De Oro International Airport',
    city: 'Manzanillo',
    country: 'Mexico',
    iata: 'ZLO',
    icao: 'MMZO',
    latitude: 19.1448001862,
    longitude: -104.558998108,
    altitude: 30,
    schengen: false
  },
  {
    id: 3974,
    name: 'Zapopan Airport',
    city: 'Zapopan',
    country: 'Mexico',
    iata: '',
    icao: 'MMZP',
    latitude: 20.747922,
    longitude: -103.461709,
    altitude: 5333,
    schengen: false
  },
  {
    id: 3975,
    name: 'Chuuk International Airport',
    city: 'Chuuk',
    country: 'Micronesia',
    iata: 'TKK',
    icao: 'PTKK',
    latitude: 7.46187019348145,
    longitude: 151.843002319336,
    altitude: 11,
    schengen: false
  },
  {
    id: 3976,
    name: 'Pohnpei International Airport',
    city: 'Pohnpei',
    country: 'Micronesia',
    iata: 'PNI',
    icao: 'PTPN',
    latitude: 6.98509979248047,
    longitude: 158.208999633789,
    altitude: 10,
    schengen: false
  },
  {
    id: 3977,
    name: 'Kosrae International Airport',
    city: 'Kosrae',
    country: 'Micronesia',
    iata: 'KSA',
    icao: 'PTSA',
    latitude: 5.35698,
    longitude: 162.957993,
    altitude: 11,
    schengen: false
  },
  {
    id: 3978,
    name: 'Yap International Airport',
    city: 'Yap',
    country: 'Micronesia',
    iata: 'YAP',
    icao: 'PTYA',
    latitude: 9.49891,
    longitude: 138.082993,
    altitude: 91,
    schengen: false
  },
  {
    id: 3979,
    name: 'Henderson Field',
    city: 'Midway',
    country: 'Midway Islands',
    iata: 'MDY',
    icao: 'PMDY',
    latitude: 28.2017002105713,
    longitude: -177.380996704102,
    altitude: 13,
    schengen: false
  },
  {
    id: 3980,
    name: 'Bălți International Airport',
    city: 'Saltsy',
    country: 'Moldova',
    iata: 'BZY',
    icao: 'LUBL',
    latitude: 47.843056,
    longitude: 27.777222,
    altitude: 758,
    schengen: false
  },
  {
    id: 3981,
    name: 'Mărculeşti International Airport',
    city: 'Mărculeşti',
    country: 'Moldova',
    iata: '',
    icao: 'LUBM',
    latitude: 47.8627014160156,
    longitude: 28.2127990722656,
    altitude: 312,
    schengen: false
  },
  {
    id: 3982,
    name: 'Cahul International Airport',
    city: 'Cahul',
    country: 'Moldova',
    iata: '',
    icao: 'LUCH',
    latitude: 45.8437995911,
    longitude: 28.2637004852,
    altitude: 652,
    schengen: false
  },
  {
    id: 3983,
    name: 'Chişinău International Airport',
    city: 'Chisinau',
    country: 'Moldova',
    iata: 'KIV',
    icao: 'LUKK',
    latitude: 46.9277000427246,
    longitude: 28.9309997558594,
    altitude: 399,
    schengen: false
  },
  {
    id: 3984,
    name: 'Tiraspol Airport',
    city: 'Tiraspol',
    country: 'Moldova',
    iata: '',
    icao: 'LUTR',
    latitude: 46.8680992126465,
    longitude: 29.5905990600586,
    altitude: 141,
    schengen: false
  },
  {
    id: 3985,
    name: 'Arvaikheer Airport',
    city: 'Arvaikheer',
    country: 'Mongolia',
    iata: 'AVK',
    icao: 'ZMAH',
    latitude: 46.250301361084,
    longitude: 102.802001953125,
    altitude: 5932,
    schengen: false
  },
  {
    id: 3986,
    name: 'Altai Airport',
    city: 'Altai',
    country: 'Mongolia',
    iata: 'LTI',
    icao: 'ZMAT',
    latitude: 46.3763999938965,
    longitude: 96.2210998535156,
    altitude: 7260,
    schengen: false
  },
  {
    id: 3987,
    name: 'Bayankhongor Airport',
    city: 'Bayankhongor',
    country: 'Mongolia',
    iata: 'BYN',
    icao: 'ZMBH',
    latitude: 46.1632995605469,
    longitude: 100.704002380371,
    altitude: 6085,
    schengen: false
  },
  {
    id: 3988,
    name: 'Bulgan Airport',
    city: 'Bulgan',
    country: 'Mongolia',
    iata: 'UGA',
    icao: 'ZMBN',
    latitude: 48.8549995422363,
    longitude: 103.475997924805,
    altitude: 4311,
    schengen: false
  },
  {
    id: 3989,
    name: 'Baruun Urt Airport',
    city: '',
    country: 'Mongolia',
    iata: 'UUN',
    icao: 'ZMBU',
    latitude: 46.6603012084961,
    longitude: 113.285003662109,
    altitude: 3205,
    schengen: false
  },
  {
    id: 3990,
    name: 'Choibalsan Airport',
    city: 'Choibalsan',
    country: 'Mongolia',
    iata: 'COQ',
    icao: 'ZMCD',
    latitude: 48.1357002258301,
    longitude: 114.646003723145,
    altitude: 2457,
    schengen: false
  },
  {
    id: 3991,
    name: 'Donoi Airport',
    city: 'Uliastai',
    country: 'Mongolia',
    iata: 'ULZ',
    icao: 'ZMDN',
    latitude: 47.7093,
    longitude: 96.5258,
    altitude: 5800,
    schengen: false
  },
  {
    id: 3992,
    name: 'Dalanzadgad Airport',
    city: 'Dalanzadgad',
    country: 'Mongolia',
    iata: 'DLZ',
    icao: 'ZMDZ',
    latitude: 43.5917015075684,
    longitude: 104.430000305176,
    altitude: 4787,
    schengen: false
  },
  {
    id: 3993,
    name: 'Khovd Airport',
    city: 'Khovd',
    country: 'Mongolia',
    iata: 'HVD',
    icao: 'ZMKD',
    latitude: 47.9541015625,
    longitude: 91.6281967163086,
    altitude: 4898,
    schengen: false
  },
  {
    id: 3994,
    name: 'Mörön Airport',
    city: 'Muren',
    country: 'Mongolia',
    iata: 'MXV',
    icao: 'ZMMN',
    latitude: 49.6632995605469,
    longitude: 100.098999023438,
    altitude: 4272,
    schengen: false
  },
  {
    id: 3995,
    name: 'Chinggis Khaan International Airport',
    city: 'Ulan Bator',
    country: 'Mongolia',
    iata: 'ULN',
    icao: 'ZMUB',
    latitude: 47.8431015014648,
    longitude: 106.766998291016,
    altitude: 4364,
    schengen: false
  },
  {
    id: 3996,
    name: 'Ulaangom Airport',
    city: 'Ulaangom',
    country: 'Mongolia',
    iata: 'ULO',
    icao: 'ZMUG',
    latitude: 50.066588,
    longitude: 91.938273,
    altitude: 0,
    schengen: false
  },
  {
    id: 3997,
    name: 'Ulgii Mongolei Airport',
    city: 'Olgii',
    country: 'Mongolia',
    iata: 'ULG',
    icao: 'ZMUL',
    latitude: 48.9933013916,
    longitude: 89.9225006104,
    altitude: 5732,
    schengen: false
  },
  {
    id: 3998,
    name: 'Podgorica Airport',
    city: 'Podgorica',
    country: 'Montenegro',
    iata: 'TGD',
    icao: 'LYPG',
    latitude: 42.359402,
    longitude: 19.2519,
    altitude: 141,
    schengen: false
  },
  {
    id: 3999,
    name: 'Tivat Airport',
    city: 'Tivat',
    country: 'Montenegro',
    iata: 'TIV',
    icao: 'LYTV',
    latitude: 42.4047012329102,
    longitude: 18.7233009338379,
    altitude: 20,
    schengen: false
  },
  {
    id: 4000,
    name: 'John A. Osborne Airport',
    city: 'Geralds',
    country: 'Montserrat',
    iata: 'MNI',
    icao: 'TRPG',
    latitude: 16.7914009094238,
    longitude: -62.1932983398438,
    altitude: 550,
    schengen: false
  },
  {
    id: 4001,
    name: 'Al Massira Airport',
    city: 'Agadir',
    country: 'Morocco',
    iata: 'AGA',
    icao: 'GMAD',
    latitude: 30.3250007629395,
    longitude: -9.41306972503662,
    altitude: 250,
    schengen: false
  },
  {
    id: 4002,
    name: 'Tan Tan Airport',
    city: 'Tan Tan',
    country: 'Morocco',
    iata: 'TTA',
    icao: 'GMAT',
    latitude: 28.4482002258301,
    longitude: -11.1612997055054,
    altitude: 653,
    schengen: false
  },
  {
    id: 4003,
    name: 'Zagora Airport',
    city: 'Zagora',
    country: 'Morocco',
    iata: 'OZG',
    icao: 'GMAZ',
    latitude: 30.3202991486,
    longitude: -5.86667013168,
    altitude: 2631,
    schengen: false
  },
  {
    id: 4004,
    name: 'Bouarfa Airport',
    city: 'Bouarfa',
    country: 'Morocco',
    iata: 'UAR',
    icao: 'GMFB',
    latitude: 32.5143055556,
    longitude: -1.98305555556,
    altitude: 3630,
    schengen: false
  },
  {
    id: 4005,
    name: 'Saïss Airport',
    city: 'Fes',
    country: 'Morocco',
    iata: 'FEZ',
    icao: 'GMFF',
    latitude: 33.9272994995,
    longitude: -4.97796010971,
    altitude: 1900,
    schengen: false
  },
  {
    id: 4006,
    name: 'Ifrane Airport',
    city: 'Ifrane',
    country: 'Morocco',
    iata: '',
    icao: 'GMFI',
    latitude: 33.505298614502,
    longitude: -5.15290021896362,
    altitude: 5459,
    schengen: false
  },
  {
    id: 4007,
    name: 'Moulay Ali Cherif Airport',
    city: 'Er-rachidia',
    country: 'Morocco',
    iata: 'ERH',
    icao: 'GMFK',
    latitude: 31.9475002289,
    longitude: -4.39833021164,
    altitude: 3428,
    schengen: false
  },
  {
    id: 4008,
    name: 'Bassatine Airport',
    city: 'Meknes',
    country: 'Morocco',
    iata: 'MEK',
    icao: 'GMFM',
    latitude: 33.8791007995605,
    longitude: -5.51512002944946,
    altitude: 1890,
    schengen: false
  },
  {
    id: 4009,
    name: 'Angads Airport',
    city: 'Oujda',
    country: 'Morocco',
    iata: 'OUD',
    icao: 'GMFO',
    latitude: 34.7872009277344,
    longitude: -1.92399001121521,
    altitude: 1535,
    schengen: false
  },
  {
    id: 4010,
    name: 'Fes Sefrou Airport',
    city: 'Fes',
    country: 'Morocco',
    iata: '',
    icao: 'GMFU',
    latitude: 34.0080986022949,
    longitude: -4.96555995941162,
    altitude: 1539,
    schengen: false
  },
  {
    id: 4011,
    name: 'Ben Slimane Airport',
    city: 'Ben Slimane',
    country: 'Morocco',
    iata: 'GMD',
    icao: 'GMMB',
    latitude: 33.655399,
    longitude: -7.22145,
    altitude: 627,
    schengen: false
  },
  {
    id: 4012,
    name: 'Beni Mellal Airport',
    city: 'Beni Mellal',
    country: 'Morocco',
    iata: 'BEM',
    icao: 'GMMD',
    latitude: 32.401895,
    longitude: -6.315905,
    altitude: 1694,
    schengen: false
  },
  {
    id: 4013,
    name: 'Rabat-Salé Airport',
    city: 'Rabat',
    country: 'Morocco',
    iata: 'RBA',
    icao: 'GMME',
    latitude: 34.051498,
    longitude: -6.75152,
    altitude: 276,
    schengen: false
  },
  {
    id: 4014,
    name: 'Mogador Airport',
    city: 'Essadouira',
    country: 'Morocco',
    iata: 'ESU',
    icao: 'GMMI',
    latitude: 31.3974990845,
    longitude: -9.6816701889,
    altitude: 384,
    schengen: false
  },
  {
    id: 4015,
    name: 'Mohammed V International Airport',
    city: 'Casablanca',
    country: 'Morocco',
    iata: 'CMN',
    icao: 'GMMN',
    latitude: 33.3675003051758,
    longitude: -7.58997011184692,
    altitude: 656,
    schengen: false
  },
  {
    id: 4016,
    name: 'Nador International Airport',
    city: 'El Aroui',
    country: 'Morocco',
    iata: 'NDR',
    icao: 'GMMW',
    latitude: 34.9888000488,
    longitude: -3.0282099247,
    altitude: 574,
    schengen: false
  },
  {
    id: 4017,
    name: 'Menara Airport',
    city: 'Marrakech',
    country: 'Morocco',
    iata: 'RAK',
    icao: 'GMMX',
    latitude: 31.6068992615,
    longitude: -8.03629970551,
    altitude: 1545,
    schengen: false
  },
  {
    id: 4018,
    name: 'Kenitra Airport',
    city: 'Kentira',
    country: 'Morocco',
    iata: 'NNA',
    icao: 'GMMY',
    latitude: 34.298900604248,
    longitude: -6.59588003158569,
    altitude: 16,
    schengen: false
  },
  {
    id: 4019,
    name: 'Ouarzazate Airport',
    city: 'Ouarzazate',
    country: 'Morocco',
    iata: 'OZZ',
    icao: 'GMMZ',
    latitude: 30.9391002655,
    longitude: -6.90943002701,
    altitude: 3782,
    schengen: false
  },
  {
    id: 4020,
    name: 'Cherif Al Idrissi Airport',
    city: 'Al Hociema',
    country: 'Morocco',
    iata: 'AHU',
    icao: 'GMTA',
    latitude: 35.1771011352539,
    longitude: -3.83951997756958,
    altitude: 95,
    schengen: false
  },
  {
    id: 4021,
    name: "Saniat R'mel Airport",
    city: 'Tetouan',
    country: 'Morocco',
    iata: 'TTU',
    icao: 'GMTN',
    latitude: 35.594299316406,
    longitude: -5.320020198822,
    altitude: 10,
    schengen: false
  },
  {
    id: 4022,
    name: 'Ibn Batouta Airport',
    city: 'Tanger',
    country: 'Morocco',
    iata: 'TNG',
    icao: 'GMTT',
    latitude: 35.7268981934,
    longitude: -5.91689014435,
    altitude: 62,
    schengen: false
  },
  {
    id: 4023,
    name: 'Beira Airport',
    city: 'Beira',
    country: 'Mozambique',
    iata: 'BEW',
    icao: 'FQBR',
    latitude: -19.7964000701904,
    longitude: 34.907600402832,
    altitude: 33,
    schengen: false
  },
  {
    id: 4024,
    name: 'Cuamba Airport',
    city: 'Cuamba',
    country: 'Mozambique',
    iata: 'FXO',
    icao: 'FQCB',
    latitude: -14.815,
    longitude: 36.529999,
    altitude: 1919,
    schengen: false
  },
  {
    id: 4025,
    name: 'Chimoio Airport',
    city: 'Chimoio',
    country: 'Mozambique',
    iata: 'VPY',
    icao: 'FQCH',
    latitude: -19.1513004302979,
    longitude: 33.4290008544922,
    altitude: 2287,
    schengen: false
  },
  {
    id: 4026,
    name: 'Inhaca Airport',
    city: 'Inhaca',
    country: 'Mozambique',
    iata: 'IHC',
    icao: 'FQIA',
    latitude: -25.9971446991,
    longitude: 32.9293518066,
    altitude: 10,
    schengen: false
  },
  {
    id: 4027,
    name: 'Inhambane Airport',
    city: 'Inhambane',
    country: 'Mozambique',
    iata: 'INH',
    icao: 'FQIN',
    latitude: -23.8763999938965,
    longitude: 35.4085006713867,
    altitude: 30,
    schengen: false
  },
  {
    id: 4028,
    name: 'Lichinga Airport',
    city: 'Lichinga',
    country: 'Mozambique',
    iata: 'VXC',
    icao: 'FQLC',
    latitude: -13.274,
    longitude: 35.2663,
    altitude: 4505,
    schengen: false
  },
  {
    id: 4029,
    name: 'Lumbo Airport',
    city: 'Lumbo',
    country: 'Mozambique',
    iata: 'LFB',
    icao: 'FQLU',
    latitude: -15.0331001282,
    longitude: 40.6716995239,
    altitude: 33,
    schengen: false
  },
  {
    id: 4030,
    name: 'Maputo Airport',
    city: 'Maputo',
    country: 'Mozambique',
    iata: 'MPM',
    icao: 'FQMA',
    latitude: -25.9207992553711,
    longitude: 32.5726013183594,
    altitude: 145,
    schengen: false
  },
  {
    id: 4031,
    name: 'Mueda Airport',
    city: 'Mueda',
    country: 'Mozambique',
    iata: 'MUD',
    icao: 'FQMD',
    latitude: -11.6729001998901,
    longitude: 39.5630989074707,
    altitude: 2789,
    schengen: false
  },
  {
    id: 4032,
    name: 'Mocímboa da Praia Airport',
    city: 'Mocimboa Da Praia',
    country: 'Mozambique',
    iata: 'MZB',
    icao: 'FQMP',
    latitude: -11.3618001937866,
    longitude: 40.3549003601074,
    altitude: 89,
    schengen: false
  },
  {
    id: 4033,
    name: 'Marrupa Airport',
    city: 'Marrupa',
    country: 'Mozambique',
    iata: '',
    icao: 'FQMR',
    latitude: -13.2250995635986,
    longitude: 37.5521011352539,
    altitude: 2480,
    schengen: false
  },
  {
    id: 4034,
    name: 'Nacala Airport',
    city: 'Nacala',
    country: 'Mozambique',
    iata: 'MNC',
    icao: 'FQNC',
    latitude: -14.4882001876831,
    longitude: 40.7122001647949,
    altitude: 410,
    schengen: false
  },
  {
    id: 4035,
    name: 'Nampula Airport',
    city: 'Nampula',
    country: 'Mozambique',
    iata: 'APL',
    icao: 'FQNP',
    latitude: -15.1056003570557,
    longitude: 39.2817993164062,
    altitude: 1444,
    schengen: false
  },
  {
    id: 4036,
    name: 'Pemba Airport',
    city: 'Pemba',
    country: 'Mozambique',
    iata: 'POL',
    icao: 'FQPB',
    latitude: -12.9917621612549,
    longitude: 40.5240135192871,
    altitude: 331,
    schengen: false
  },
  {
    id: 4037,
    name: 'Quelimane Airport',
    city: 'Quelimane',
    country: 'Mozambique',
    iata: 'UEL',
    icao: 'FQQL',
    latitude: -17.8554992675781,
    longitude: 36.8690986633301,
    altitude: 36,
    schengen: false
  },
  {
    id: 4038,
    name: 'Songo Airport',
    city: 'Songo',
    country: 'Mozambique',
    iata: '',
    icao: 'FQSG',
    latitude: -15.6027002334595,
    longitude: 32.7732009887695,
    altitude: 2904,
    schengen: false
  },
  {
    id: 4039,
    name: 'Chingozi Airport',
    city: 'Tete',
    country: 'Mozambique',
    iata: 'TET',
    icao: 'FQTT',
    latitude: -16.1047992706299,
    longitude: 33.6402015686035,
    altitude: 525,
    schengen: false
  },
  {
    id: 4040,
    name: 'Ulongwe Airport',
    city: 'Ulongwe',
    country: 'Mozambique',
    iata: '',
    icao: 'FQUG',
    latitude: -14.7046003341675,
    longitude: 34.3524017333984,
    altitude: 4265,
    schengen: false
  },
  {
    id: 4041,
    name: 'Vilankulo Airport',
    city: 'Vilankulu',
    country: 'Mozambique',
    iata: 'VNX',
    icao: 'FQVL',
    latitude: -22.0184001922607,
    longitude: 35.3133010864258,
    altitude: 46,
    schengen: false
  },
  {
    id: 4042,
    name: 'Kalay Airport',
    city: 'Kalemyo',
    country: 'Myanmar',
    iata: 'KMV',
    icao: 'VYKL',
    latitude: 23.1888008117676,
    longitude: 94.0511016845703,
    altitude: 499,
    schengen: false
  },
  {
    id: 4043,
    name: 'Arandis Airport',
    city: 'Arandis',
    country: 'Namibia',
    iata: 'ADI',
    icao: 'FYAR',
    latitude: -22.4622001647949,
    longitude: 14.9799995422363,
    altitude: 1905,
    schengen: false
  },
  {
    id: 4044,
    name: 'Grootfontein Airport',
    city: 'Grootfontein',
    country: 'Namibia',
    iata: 'GFY',
    icao: 'FYGF',
    latitude: -19.6021995544434,
    longitude: 18.1226997375488,
    altitude: 4636,
    schengen: false
  },
  {
    id: 4045,
    name: 'Katima Mulilo Airport',
    city: 'Mpacha',
    country: 'Namibia',
    iata: 'MPA',
    icao: 'FYKM',
    latitude: -17.634399,
    longitude: 24.176701,
    altitude: 3144,
    schengen: false
  },
  {
    id: 4046,
    name: 'Keetmanshoop Airport',
    city: 'Keetmanshoop',
    country: 'Namibia',
    iata: 'KMP',
    icao: 'FYKT',
    latitude: -26.5398006439209,
    longitude: 18.111400604248,
    altitude: 3506,
    schengen: false
  },
  {
    id: 4047,
    name: 'Luderitz Airport',
    city: 'Luderitz',
    country: 'Namibia',
    iata: 'LUD',
    icao: 'FYLZ',
    latitude: -26.6874008178711,
    longitude: 15.2428998947144,
    altitude: 457,
    schengen: false
  },
  {
    id: 4048,
    name: 'Mariental Airport',
    city: 'Mariental',
    country: 'Namibia',
    iata: '',
    icao: 'FYML',
    latitude: -24.6054000854492,
    longitude: 17.9253997802734,
    altitude: 3650,
    schengen: false
  },
  {
    id: 4049,
    name: 'Mokuti Lodge Airport',
    city: 'Mokuti Lodge',
    country: 'Namibia',
    iata: 'OKU',
    icao: 'FYMO',
    latitude: -18.8127994537354,
    longitude: 17.0594005584717,
    altitude: 3665,
    schengen: false
  },
  {
    id: 4050,
    name: 'Ondangwa Airport',
    city: 'Ondangwa',
    country: 'Namibia',
    iata: 'OND',
    icao: 'FYOA',
    latitude: -17.878201,
    longitude: 15.9526,
    altitude: 3599,
    schengen: false
  },
  {
    id: 4051,
    name: 'Oranjemund Airport',
    city: 'Oranjemund',
    country: 'Namibia',
    iata: 'OMD',
    icao: 'FYOG',
    latitude: -28.5847,
    longitude: 16.446699,
    altitude: 14,
    schengen: false
  },
  {
    id: 4052,
    name: 'Okaukuejo Airport',
    city: 'Okaukuejo',
    country: 'Namibia',
    iata: 'OKF',
    icao: 'FYOO',
    latitude: -19.1492004394531,
    longitude: 15.9118995666504,
    altitude: 3911,
    schengen: false
  },
  {
    id: 4053,
    name: 'Otjiwarongo Airport',
    city: 'Otjiwarongo',
    country: 'Namibia',
    iata: 'OTJ',
    icao: 'FYOW',
    latitude: -20.434700012207,
    longitude: 16.660800933838,
    altitude: 4859,
    schengen: false
  },
  {
    id: 4054,
    name: 'Rundu Airport',
    city: 'Rundu',
    country: 'Namibia',
    iata: 'NDU',
    icao: 'FYRU',
    latitude: -17.956499099731,
    longitude: 19.719400405884,
    altitude: 3627,
    schengen: false
  },
  {
    id: 4055,
    name: 'Swakopmund Airport',
    city: 'Swakopmund',
    country: 'Namibia',
    iata: 'SWP',
    icao: 'FYSM',
    latitude: -22.66189956665,
    longitude: 14.568099975586,
    altitude: 207,
    schengen: false
  },
  {
    id: 4056,
    name: 'Tsumeb Airport',
    city: 'Tsumeb',
    country: 'Namibia',
    iata: 'TSB',
    icao: 'FYTM',
    latitude: -19.26189994812,
    longitude: 17.732500076294,
    altitude: 4353,
    schengen: false
  },
  {
    id: 4057,
    name: 'Walvis Bay Airport',
    city: 'Walvis Bay',
    country: 'Namibia',
    iata: 'WVB',
    icao: 'FYWB',
    latitude: -22.9799,
    longitude: 14.6453,
    altitude: 299,
    schengen: false
  },
  {
    id: 4058,
    name: 'Eros Airport',
    city: 'Windhoek',
    country: 'Namibia',
    iata: 'ERS',
    icao: 'FYWE',
    latitude: -22.6121997833252,
    longitude: 17.0804004669189,
    altitude: 5575,
    schengen: false
  },
  {
    id: 4059,
    name: 'Hosea Kutako International Airport',
    city: 'Windhoek',
    country: 'Namibia',
    iata: 'WDH',
    icao: 'FYWH',
    latitude: -22.4799,
    longitude: 17.4709,
    altitude: 5640,
    schengen: false
  },
  {
    id: 4060,
    name: 'Nauru International Airport',
    city: 'Nauru',
    country: 'Nauru',
    iata: 'INU',
    icao: 'ANYN',
    latitude: -0.547458,
    longitude: 166.919006,
    altitude: 22,
    schengen: false
  },
  {
    id: 4061,
    name: 'Bajhang Airport',
    city: 'Bajhang',
    country: 'Nepal',
    iata: 'BJH',
    icao: 'VNBG',
    latitude: 29.5389995574951,
    longitude: 81.1854019165039,
    altitude: 4100,
    schengen: false
  },
  {
    id: 4062,
    name: 'Bhojpur Airport',
    city: 'Bhojpur',
    country: 'Nepal',
    iata: 'BHP',
    icao: 'VNBJ',
    latitude: 27.1473999023438,
    longitude: 87.0507965087891,
    altitude: 4000,
    schengen: false
  },
  {
    id: 4063,
    name: 'Baglung Airport',
    city: 'Baglung',
    country: 'Nepal',
    iata: 'BGL',
    icao: 'VNBL',
    latitude: 28.2127990722656,
    longitude: 83.6662979125977,
    altitude: 3320,
    schengen: false
  },
  {
    id: 4064,
    name: 'Bharatpur Airport',
    city: 'Bharatpur',
    country: 'Nepal',
    iata: 'BHR',
    icao: 'VNBP',
    latitude: 27.678101,
    longitude: 84.429398,
    altitude: 600,
    schengen: false
  },
  {
    id: 4065,
    name: 'Bajura Airport',
    city: 'Bajura',
    country: 'Nepal',
    iata: 'BJU',
    icao: 'VNBR',
    latitude: 29.5020008087158,
    longitude: 81.6689987182617,
    altitude: 4300,
    schengen: false
  },
  {
    id: 4066,
    name: 'Gautam Buddha Airport',
    city: 'Bhairawa',
    country: 'Nepal',
    iata: 'BWA',
    icao: 'VNBW',
    latitude: 27.505685,
    longitude: 83.416293,
    altitude: 358,
    schengen: false
  },
  {
    id: 4067,
    name: 'Bhadrapur Airport',
    city: 'Chandragarhi',
    country: 'Nepal',
    iata: 'BDP',
    icao: 'VNCG',
    latitude: 26.5708007812,
    longitude: 88.0795974731,
    altitude: 300,
    schengen: false
  },
  {
    id: 4068,
    name: 'Tulsipur Airport',
    city: 'Dang',
    country: 'Nepal',
    iata: 'DNP',
    icao: 'VNDG',
    latitude: 28.1110992431641,
    longitude: 82.2941970825195,
    altitude: 2100,
    schengen: false
  },
  {
    id: 4069,
    name: 'Dhangarhi Airport',
    city: 'Dhangarhi',
    country: 'Nepal',
    iata: 'DHI',
    icao: 'VNDH',
    latitude: 28.7532997131348,
    longitude: 80.581901550293,
    altitude: 690,
    schengen: false
  },
  {
    id: 4070,
    name: 'Dolpa Airport',
    city: 'Dolpa',
    country: 'Nepal',
    iata: 'DOP',
    icao: 'VNDP',
    latitude: 28.9857006072998,
    longitude: 82.8190994262695,
    altitude: 8200,
    schengen: false
  },
  {
    id: 4071,
    name: 'Jumla Airport',
    city: 'Jumla',
    country: 'Nepal',
    iata: 'JUM',
    icao: 'VNJL',
    latitude: 29.2742,
    longitude: 82.193298,
    altitude: 7700,
    schengen: false
  },
  {
    id: 4072,
    name: 'Janakpur Airport',
    city: 'Janakpur',
    country: 'Nepal',
    iata: 'JKR',
    icao: 'VNJP',
    latitude: 26.7087993622,
    longitude: 85.9224014282,
    altitude: 256,
    schengen: false
  },
  {
    id: 4073,
    name: 'Jomsom Airport',
    city: 'Jomsom',
    country: 'Nepal',
    iata: 'JMO',
    icao: 'VNJS',
    latitude: 28.780426,
    longitude: 83.723,
    altitude: 9000,
    schengen: false
  },
  {
    id: 4074,
    name: 'Kangel Danda Airport',
    city: 'Kangel Danda',
    country: 'Nepal',
    iata: '',
    icao: 'VNKL',
    latitude: 27.4106333137,
    longitude: 86.6465950012,
    altitude: 0,
    schengen: false
  },
  {
    id: 4075,
    name: 'Tribhuvan International Airport',
    city: 'Kathmandu',
    country: 'Nepal',
    iata: 'KTM',
    icao: 'VNKT',
    latitude: 27.6965999603,
    longitude: 85.3591003418,
    altitude: 4390,
    schengen: false
  },
  {
    id: 4076,
    name: 'Lamidanda Airport',
    city: 'Lamidanda',
    country: 'Nepal',
    iata: 'LDN',
    icao: 'VNLD',
    latitude: 27.2530994415283,
    longitude: 86.6699981689453,
    altitude: 4100,
    schengen: false
  },
  {
    id: 4077,
    name: 'Lukla Airport',
    city: 'Lukla',
    country: 'Nepal',
    iata: 'LUA',
    icao: 'VNLK',
    latitude: 27.6868991851807,
    longitude: 86.7296981811523,
    altitude: 9380,
    schengen: false
  },
  {
    id: 4078,
    name: 'Manang Airport',
    city: 'Manang',
    country: 'Nepal',
    iata: 'NGX',
    icao: 'VNMA',
    latitude: 28.641399,
    longitude: 84.089203,
    altitude: 11001,
    schengen: false
  },
  {
    id: 4079,
    name: 'Meghauli Airport',
    city: 'Meghauli',
    country: 'Nepal',
    iata: 'MEY',
    icao: 'VNMG',
    latitude: 27.5774,
    longitude: 84.22875,
    altitude: 600,
    schengen: false
  },
  {
    id: 4080,
    name: 'Nepalgunj Airport',
    city: 'Nepalgunj',
    country: 'Nepal',
    iata: 'KEP',
    icao: 'VNNG',
    latitude: 28.1035995483398,
    longitude: 81.6669998168945,
    altitude: 540,
    schengen: false
  },
  {
    id: 4081,
    name: 'Pokhara Airport',
    city: 'Pokhara',
    country: 'Nepal',
    iata: 'PKR',
    icao: 'VNPK',
    latitude: 28.2008991241455,
    longitude: 83.9821014404297,
    altitude: 2712,
    schengen: false
  },
  {
    id: 4082,
    name: 'Phaplu Airport',
    city: 'Phaplu',
    country: 'Nepal',
    iata: 'PPL',
    icao: 'VNPL',
    latitude: 27.517787,
    longitude: 86.584454,
    altitude: 7918,
    schengen: false
  },
  {
    id: 4083,
    name: 'Rajbiraj Airport',
    city: 'Rajbiraj',
    country: 'Nepal',
    iata: 'RJB',
    icao: 'VNRB',
    latitude: 26.510066,
    longitude: 86.733902,
    altitude: 250,
    schengen: false
  },
  {
    id: 4084,
    name: 'Ramechhap Airport',
    city: 'Ramechhap',
    country: 'Nepal',
    iata: 'RHP',
    icao: 'VNRC',
    latitude: 27.3939990997314,
    longitude: 86.0614013671875,
    altitude: 1555,
    schengen: false
  },
  {
    id: 4085,
    name: 'Rukumkot Airport',
    city: 'Rukumkot',
    country: 'Nepal',
    iata: 'RUK',
    icao: 'VNRK',
    latitude: 28.6270008087,
    longitude: 82.1949996948,
    altitude: 2500,
    schengen: false
  },
  {
    id: 4086,
    name: 'Rumjatar Airport',
    city: 'Rumjatar',
    country: 'Nepal',
    iata: 'RUM',
    icao: 'VNRT',
    latitude: 27.3034992218018,
    longitude: 86.5503997802734,
    altitude: 4500,
    schengen: false
  },
  {
    id: 4087,
    name: 'Syangboche Airport',
    city: 'Syangboche',
    country: 'Nepal',
    iata: 'SYH',
    icao: 'VNSB',
    latitude: 27.8112,
    longitude: 86.7124,
    altitude: 12400,
    schengen: false
  },
  {
    id: 4088,
    name: 'Simara Airport',
    city: 'Simara',
    country: 'Nepal',
    iata: 'SIF',
    icao: 'VNSI',
    latitude: 27.1595001220703,
    longitude: 84.9801025390625,
    altitude: 450,
    schengen: false
  },
  {
    id: 4089,
    name: 'Surkhet Airport',
    city: 'Surkhet',
    country: 'Nepal',
    iata: 'SKH',
    icao: 'VNSK',
    latitude: 28.586,
    longitude: 81.636002,
    altitude: 2400,
    schengen: false
  },
  {
    id: 4090,
    name: 'Simikot Airport',
    city: 'Simikot',
    country: 'Nepal',
    iata: 'IMK',
    icao: 'VNST',
    latitude: 29.9710998535156,
    longitude: 81.8189010620117,
    altitude: 9246,
    schengen: false
  },
  {
    id: 4091,
    name: 'Thamkharka Airport',
    city: 'Thamkharka',
    country: 'Nepal',
    iata: '',
    icao: 'VNTH',
    latitude: 27.04787,
    longitude: 86.858022,
    altitude: 5240,
    schengen: false
  },
  {
    id: 4092,
    name: 'Taplejung Airport',
    city: 'Taplejung',
    country: 'Nepal',
    iata: 'TPJ',
    icao: 'VNTJ',
    latitude: 27.3509,
    longitude: 87.69525,
    altitude: 7990,
    schengen: false
  },
  {
    id: 4093,
    name: 'Tumling Tar Airport',
    city: 'Tumling Tar',
    country: 'Nepal',
    iata: 'TMI',
    icao: 'VNTR',
    latitude: 27.315001,
    longitude: 87.193298,
    altitude: 1700,
    schengen: false
  },
  {
    id: 4094,
    name: 'Biratnagar Airport',
    city: 'Biratnagar',
    country: 'Nepal',
    iata: 'BIR',
    icao: 'VNVT',
    latitude: 26.4815006256104,
    longitude: 87.2639999389648,
    altitude: 236,
    schengen: false
  },
  {
    id: 4095,
    name: 'Ameland Airport',
    city: 'Ameland',
    country: 'Netherlands',
    iata: '',
    icao: 'EHAL',
    latitude: 53.4516983032,
    longitude: 5.67721986771,
    altitude: 11,
    schengen: true
  },
  {
    id: 4096,
    name: 'Amsterdam Airport Schiphol',
    city: 'Amsterdam',
    country: 'Netherlands',
    iata: 'AMS',
    icao: 'EHAM',
    latitude: 52.308601,
    longitude: 4.76389,
    altitude: -11,
    schengen: true
  },
  {
    id: 4097,
    name: 'Budel Airport',
    city: 'Weert',
    country: 'Netherlands',
    iata: '',
    icao: 'EHBD',
    latitude: 51.2552986145,
    longitude: 5.60138988495,
    altitude: 114,
    schengen: true
  },
  {
    id: 4098,
    name: 'Maastricht Aachen Airport',
    city: 'Maastricht',
    country: 'Netherlands',
    iata: 'MST',
    icao: 'EHBK',
    latitude: 50.9117012024,
    longitude: 5.77014017105,
    altitude: 375,
    schengen: true
  },
  {
    id: 4099,
    name: 'Deelen Air Base',
    city: 'Deelen',
    country: 'Netherlands',
    iata: '',
    icao: 'EHDL',
    latitude: 52.0606,
    longitude: 5.87306,
    altitude: 158,
    schengen: true
  },
  {
    id: 4100,
    name: 'De Peel Air Base',
    city: 'Deurne',
    country: 'Netherlands',
    iata: '',
    icao: 'EHDP',
    latitude: 51.5172996520996,
    longitude: 5.85572004318237,
    altitude: 98,
    schengen: true
  },
  {
    id: 4101,
    name: 'Drachten Airport',
    city: 'Drachten',
    country: 'Netherlands',
    iata: '',
    icao: 'EHDR',
    latitude: 53.1192016602,
    longitude: 6.12972021103,
    altitude: 14,
    schengen: true
  },
  {
    id: 4102,
    name: 'Eindhoven Airport',
    city: 'Eindhoven',
    country: 'Netherlands',
    iata: 'EIN',
    icao: 'EHEH',
    latitude: 51.4500999451,
    longitude: 5.37452983856,
    altitude: 74,
    schengen: true
  },
  {
    id: 4103,
    name: 'Eelde Airport',
    city: 'Groningen',
    country: 'Netherlands',
    iata: 'GRQ',
    icao: 'EHGG',
    latitude: 53.1197013855,
    longitude: 6.57944011688,
    altitude: 17,
    schengen: true
  },
  {
    id: 4104,
    name: 'Gilze Rijen Air Base',
    city: 'Gilze-rijen',
    country: 'Netherlands',
    iata: 'GLZ',
    icao: 'EHGR',
    latitude: 51.5674018859863,
    longitude: 4.93182992935181,
    altitude: 49,
    schengen: true
  },
  {
    id: 4105,
    name: 'Hoogeveen Airport',
    city: 'Hoogeveen',
    country: 'Netherlands',
    iata: '',
    icao: 'EHHO',
    latitude: 52.7308006287,
    longitude: 6.51610994339,
    altitude: 40,
    schengen: true
  },
  {
    id: 4106,
    name: 'Hilversum Airport',
    city: 'Hilversum',
    country: 'Netherlands',
    iata: '',
    icao: 'EHHV',
    latitude: 52.1918983459,
    longitude: 5.14694023132,
    altitude: 3,
    schengen: true
  },
  {
    id: 4107,
    name: 'De Kooy Airport',
    city: 'De Kooy',
    country: 'Netherlands',
    iata: 'DHR',
    icao: 'EHKD',
    latitude: 52.9234008789063,
    longitude: 4.78062009811401,
    altitude: 3,
    schengen: true
  },
  {
    id: 4108,
    name: 'Lelystad Airport',
    city: 'Lelystad',
    country: 'Netherlands',
    iata: 'LEY',
    icao: 'EHLE',
    latitude: 52.4603,
    longitude: 5.52722,
    altitude: -13,
    schengen: true
  },
  {
    id: 4109,
    name: 'Leeuwarden Air Base',
    city: 'Leeuwarden',
    country: 'Netherlands',
    iata: 'LWR',
    icao: 'EHLW',
    latitude: 53.2285995483398,
    longitude: 5.76056003570557,
    altitude: 3,
    schengen: true
  },
  {
    id: 4110,
    name: 'Midden-Zeeland Airport',
    city: 'Middelburg',
    country: 'Netherlands',
    iata: '',
    icao: 'EHMZ',
    latitude: 51.5121994019,
    longitude: 3.73111009598,
    altitude: 6,
    schengen: true
  },
  {
    id: 4111,
    name: 'Rotterdam The Hague Airport',
    city: 'Rotterdam',
    country: 'Netherlands',
    iata: 'RTM',
    icao: 'EHRD',
    latitude: 51.956902,
    longitude: 4.43722,
    altitude: -15,
    schengen: true
  },
  {
    id: 4112,
    name: 'Soesterberg Air Base',
    city: 'Soesterberg',
    country: 'Netherlands',
    iata: 'UTC',
    icao: 'EHSB',
    latitude: 52.1273002625,
    longitude: 5.27618980408,
    altitude: 66,
    schengen: true
  },
  {
    id: 4113,
    name: 'Seppe Airport',
    city: 'Bosschenhoofd',
    country: 'Netherlands',
    iata: '',
    icao: 'EHSE',
    latitude: 51.5546989441,
    longitude: 4.55249977112,
    altitude: 30,
    schengen: true
  },
  {
    id: 4114,
    name: 'Teuge Airport',
    city: 'Deventer',
    country: 'Netherlands',
    iata: '',
    icao: 'EHTE',
    latitude: 52.2447013855,
    longitude: 6.04666996002,
    altitude: 17,
    schengen: true
  },
  {
    id: 4115,
    name: 'Terlet Glider Field',
    city: 'Arnhem',
    country: 'Netherlands',
    iata: '',
    icao: 'EHTL',
    latitude: 52.057201,
    longitude: 5.92444,
    altitude: 276,
    schengen: true
  },
  {
    id: 4116,
    name: 'Twente Airport',
    city: 'Enschede',
    country: 'Netherlands',
    iata: 'ENS',
    icao: 'EHTW',
    latitude: 52.275833,
    longitude: 6.889167,
    altitude: 114,
    schengen: true
  },
  {
    id: 4117,
    name: 'Texel Airport',
    city: 'Texel',
    country: 'Netherlands',
    iata: '',
    icao: 'EHTX',
    latitude: 53.1152992249,
    longitude: 4.83361005783,
    altitude: 2,
    schengen: true
  },
  {
    id: 4118,
    name: 'Valkenburg Naval Air Base',
    city: 'Valkenburg',
    country: 'Netherlands',
    iata: 'LID',
    icao: 'EHVB',
    latitude: 52.1660995483,
    longitude: 4.41794013977,
    altitude: 1,
    schengen: true
  },
  {
    id: 4119,
    name: 'Volkel Air Base',
    city: 'Volkel',
    country: 'Netherlands',
    iata: '',
    icao: 'EHVK',
    latitude: 51.657222,
    longitude: 5.7077778,
    altitude: 72,
    schengen: true
  },
  {
    id: 4120,
    name: 'Woensdrecht Air Base',
    city: 'Woensdrecht',
    country: 'Netherlands',
    iata: 'WOE',
    icao: 'EHWO',
    latitude: 51.4491,
    longitude: 4.34203,
    altitude: 63,
    schengen: true
  },
  {
    id: 4121,
    name: 'Flamingo International Airport',
    city: 'Kralendijk',
    country: 'Netherlands Antilles',
    iata: 'BON',
    icao: 'TNCB',
    latitude: 12.1309995651245,
    longitude: -68.2685012817383,
    altitude: 20,
    schengen: false
  },
  {
    id: 4122,
    name: 'Hato International Airport',
    city: 'Willemstad',
    country: 'Netherlands Antilles',
    iata: 'CUR',
    icao: 'TNCC',
    latitude: 12.1889,
    longitude: -68.959801,
    altitude: 29,
    schengen: false
  },
  {
    id: 4123,
    name: 'F. D. Roosevelt Airport',
    city: 'Oranjestad',
    country: 'Netherlands Antilles',
    iata: 'EUX',
    icao: 'TNCE',
    latitude: 17.4965000152588,
    longitude: -62.9794006347656,
    altitude: 129,
    schengen: false
  },
  {
    id: 4124,
    name: 'Princess Juliana International Airport',
    city: 'Philipsburg',
    country: 'Netherlands Antilles',
    iata: 'SXM',
    icao: 'TNCM',
    latitude: 18.0410003662,
    longitude: -63.1088981628,
    altitude: 13,
    schengen: false
  },
  {
    id: 4125,
    name: 'Juancho E. Yrausquin Airport',
    city: 'Saba',
    country: 'Netherlands Antilles',
    iata: 'SAB',
    icao: 'TNCS',
    latitude: 17.6450004577637,
    longitude: -63.2200012207031,
    altitude: 60,
    schengen: false
  },
  {
    id: 4126,
    name: 'Tiga Airport',
    city: 'Tiga',
    country: 'New Caledonia',
    iata: 'TGJ',
    icao: 'NWWA',
    latitude: -21.0960998535156,
    longitude: 167.804000854492,
    altitude: 128,
    schengen: false
  },
  {
    id: 4127,
    name: 'Île Art - Waala Airport',
    city: 'Waala',
    country: 'New Caledonia',
    iata: 'BMY',
    icao: 'NWWC',
    latitude: -19.7206001281738,
    longitude: 163.660995483398,
    altitude: 306,
    schengen: false
  },
  {
    id: 4128,
    name: 'Koné Airport',
    city: 'Kone',
    country: 'New Caledonia',
    iata: 'KNQ',
    icao: 'NWWD',
    latitude: -21.0543003082275,
    longitude: 164.837005615234,
    altitude: 23,
    schengen: false
  },
  {
    id: 4129,
    name: 'Île des Pins Airport',
    city: 'Île des Pins',
    country: 'New Caledonia',
    iata: 'ILP',
    icao: 'NWWE',
    latitude: -22.5888996124268,
    longitude: 167.455993652344,
    altitude: 315,
    schengen: false
  },
  {
    id: 4130,
    name: 'Koumac Airport',
    city: 'Koumac',
    country: 'New Caledonia',
    iata: 'KOC',
    icao: 'NWWK',
    latitude: -20.5463008880615,
    longitude: 164.255996704102,
    altitude: 42,
    schengen: false
  },
  {
    id: 4131,
    name: 'Lifou Airport',
    city: 'Lifou',
    country: 'New Caledonia',
    iata: 'LIF',
    icao: 'NWWL',
    latitude: -20.7747993469238,
    longitude: 167.240005493164,
    altitude: 92,
    schengen: false
  },
  {
    id: 4132,
    name: 'Nouméa Magenta Airport',
    city: 'Noumea',
    country: 'New Caledonia',
    iata: 'GEA',
    icao: 'NWWM',
    latitude: -22.25830078125,
    longitude: 166.473007202148,
    altitude: 10,
    schengen: false
  },
  {
    id: 4133,
    name: 'Maré Airport',
    city: 'Mare',
    country: 'New Caledonia',
    iata: 'MEE',
    icao: 'NWWR',
    latitude: -21.4817008972168,
    longitude: 168.037994384766,
    altitude: 141,
    schengen: false
  },
  {
    id: 4134,
    name: 'Touho Airport',
    city: 'Touho',
    country: 'New Caledonia',
    iata: 'TOU',
    icao: 'NWWU',
    latitude: -20.7900009155273,
    longitude: 165.259002685547,
    altitude: 10,
    schengen: false
  },
  {
    id: 4135,
    name: 'Ouvéa Airport',
    city: 'Ouvea',
    country: 'New Caledonia',
    iata: 'UVE',
    icao: 'NWWV',
    latitude: -20.6406002044678,
    longitude: 166.572998046875,
    altitude: 23,
    schengen: false
  },
  {
    id: 4136,
    name: 'La Tontouta International Airport',
    city: 'Noumea',
    country: 'New Caledonia',
    iata: 'NOU',
    icao: 'NWWW',
    latitude: -22.0146007537842,
    longitude: 166.212997436523,
    altitude: 52,
    schengen: false
  },
  {
    id: 4137,
    name: 'Auckland International Airport',
    city: 'Auckland',
    country: 'New Zealand',
    iata: 'AKL',
    icao: 'NZAA',
    latitude: -37.0080986023,
    longitude: 174.792007446,
    altitude: 23,
    schengen: false
  },
  {
    id: 4138,
    name: 'Taupo Airport',
    city: 'Taupo',
    country: 'New Zealand',
    iata: 'TUO',
    icao: 'NZAP',
    latitude: -38.7397003173828,
    longitude: 176.083999633789,
    altitude: 1335,
    schengen: false
  },
  {
    id: 4139,
    name: 'Ardmore Airport',
    city: 'Ardmore',
    country: 'New Zealand',
    iata: 'AMZ',
    icao: 'NZAR',
    latitude: -37.0297012329102,
    longitude: 174.973007202148,
    altitude: 111,
    schengen: false
  },
  {
    id: 4140,
    name: 'Christchurch International Airport',
    city: 'Christchurch',
    country: 'New Zealand',
    iata: 'CHC',
    icao: 'NZCH',
    latitude: -43.4893989562988,
    longitude: 172.531997680664,
    altitude: 123,
    schengen: false
  },
  {
    id: 4141,
    name: 'Chatham Islands-Tuuta Airport',
    city: 'Chatham Island',
    country: 'New Zealand',
    iata: 'CHT',
    icao: 'NZCI',
    latitude: -43.810001373291,
    longitude: -176.457000732422,
    altitude: 43,
    schengen: false
  },
  {
    id: 4142,
    name: 'Dunedin Airport',
    city: 'Dunedin',
    country: 'New Zealand',
    iata: 'DUD',
    icao: 'NZDN',
    latitude: -45.9281005859375,
    longitude: 170.197998046875,
    altitude: 4,
    schengen: false
  },
  {
    id: 4143,
    name: 'Great Barrier Aerodrome',
    city: 'Claris',
    country: 'New Zealand',
    iata: 'GBZ',
    icao: 'NZGB',
    latitude: -36.2414016723633,
    longitude: 175.47200012207,
    altitude: 20,
    schengen: false
  },
  {
    id: 4144,
    name: 'Glacier Country Heliport',
    city: 'Franz Josef',
    country: 'New Zealand',
    iata: '',
    icao: 'NZGH',
    latitude: -43.38722,
    longitude: 170.18,
    altitude: 457,
    schengen: false
  },
  {
    id: 4145,
    name: 'Gisborne Airport',
    city: 'Gisborne',
    country: 'New Zealand',
    iata: 'GIS',
    icao: 'NZGS',
    latitude: -38.6632995605469,
    longitude: 177.977996826172,
    altitude: 15,
    schengen: false
  },
  {
    id: 4146,
    name: 'Glentanner Airport',
    city: 'Glentanner',
    country: 'New Zealand',
    iata: 'GTN',
    icao: 'NZGT',
    latitude: -43.9067001342773,
    longitude: 170.128005981445,
    altitude: 1824,
    schengen: false
  },
  {
    id: 4147,
    name: 'Hokitika Airfield',
    city: 'Hokitika',
    country: 'New Zealand',
    iata: 'HKK',
    icao: 'NZHK',
    latitude: -42.7136001586914,
    longitude: 170.985000610352,
    altitude: 146,
    schengen: false
  },
  {
    id: 4148,
    name: 'Hamilton International Airport',
    city: 'Hamilton',
    country: 'New Zealand',
    iata: 'HLZ',
    icao: 'NZHN',
    latitude: -37.8666992188,
    longitude: 175.332000732,
    altitude: 172,
    schengen: false
  },
  {
    id: 4149,
    name: 'Hastings Aerodrome',
    city: 'Hastings',
    country: 'New Zealand',
    iata: '',
    icao: 'NZHS',
    latitude: -39.646702,
    longitude: 176.766998,
    altitude: 72,
    schengen: false
  },
  {
    id: 4150,
    name: 'Haast Aerodrome',
    city: 'Haast',
    country: 'New Zealand',
    iata: '',
    icao: 'NZHT',
    latitude: -43.8652992248535,
    longitude: 169.041000366211,
    altitude: 13,
    schengen: false
  },
  {
    id: 4151,
    name: 'Waiheke Reeve Airport',
    city: 'Waiheke Island',
    country: 'New Zealand',
    iata: 'WIK',
    icao: 'NZKE',
    latitude: -36.8088989257812,
    longitude: 175.085998535156,
    altitude: 226,
    schengen: false
  },
  {
    id: 4152,
    name: 'Kaikoura Airport',
    city: 'Kaikoura',
    country: 'New Zealand',
    iata: 'KBZ',
    icao: 'NZKI',
    latitude: -42.4249992370605,
    longitude: 173.604995727539,
    altitude: 20,
    schengen: false
  },
  {
    id: 4153,
    name: 'Kerikeri Airport',
    city: 'Kerikeri',
    country: 'New Zealand',
    iata: 'KKE',
    icao: 'NZKK',
    latitude: -35.2627983093262,
    longitude: 173.912002563477,
    altitude: 492,
    schengen: false
  },
  {
    id: 4154,
    name: 'Karamea Airport',
    city: 'Karamea',
    country: 'New Zealand',
    iata: '',
    icao: 'NZKM',
    latitude: -41.2366981506348,
    longitude: 172.104995727539,
    altitude: 30,
    schengen: false
  },
  {
    id: 4155,
    name: 'Kaitaia Airport',
    city: 'Kaitaia',
    country: 'New Zealand',
    iata: 'KAT',
    icao: 'NZKT',
    latitude: -35.0699996948242,
    longitude: 173.285003662109,
    altitude: 270,
    schengen: false
  },
  {
    id: 4156,
    name: 'Alexandra Airport',
    city: 'Alexandra',
    country: 'New Zealand',
    iata: 'ALR',
    icao: 'NZLX',
    latitude: -45.2117004394531,
    longitude: 169.373001098633,
    altitude: 752,
    schengen: false
  },
  {
    id: 4157,
    name: 'Mount Cook Airport',
    city: 'Mount Cook',
    country: 'New Zealand',
    iata: 'MON',
    icao: 'NZMC',
    latitude: -43.7649993896484,
    longitude: 170.132995605469,
    altitude: 2153,
    schengen: false
  },
  {
    id: 4158,
    name: 'Milford Sound Airport',
    city: 'Milford Sound',
    country: 'New Zealand',
    iata: 'MFN',
    icao: 'NZMF',
    latitude: -44.6733016967773,
    longitude: 167.923004150391,
    altitude: 10,
    schengen: false
  },
  {
    id: 4159,
    name: 'Motueka Airport',
    city: 'Motueka',
    country: 'New Zealand',
    iata: 'MZP',
    icao: 'NZMK',
    latitude: -41.1232986450195,
    longitude: 172.988998413086,
    altitude: 39,
    schengen: false
  },
  {
    id: 4160,
    name: 'Manapouri Airport',
    city: 'Manapouri',
    country: 'New Zealand',
    iata: 'TEU',
    icao: 'NZMO',
    latitude: -45.5331001281738,
    longitude: 167.649993896484,
    altitude: 687,
    schengen: false
  },
  {
    id: 4161,
    name: 'Hood Airport',
    city: 'Masterton',
    country: 'New Zealand',
    iata: 'MRO',
    icao: 'NZMS',
    latitude: -40.9733009338379,
    longitude: 175.634002685547,
    altitude: 364,
    schengen: false
  },
  {
    id: 4162,
    name: 'North Shore Aerodrome',
    city: 'North Shore',
    country: 'New Zealand',
    iata: '',
    icao: 'NZNE',
    latitude: -36.6567001342773,
    longitude: 174.654998779297,
    altitude: 210,
    schengen: false
  },
  {
    id: 4163,
    name: 'New Plymouth Airport',
    city: 'New Plymouth',
    country: 'New Zealand',
    iata: 'NPL',
    icao: 'NZNP',
    latitude: -39.0085983276367,
    longitude: 174.179000854492,
    altitude: 97,
    schengen: false
  },
  {
    id: 4164,
    name: "Hawke's Bay Airport",
    city: 'NAPIER',
    country: 'New Zealand',
    iata: 'NPE',
    icao: 'NZNR',
    latitude: -39.465801,
    longitude: 176.869995,
    altitude: 6,
    schengen: false
  },
  {
    id: 4165,
    name: 'Nelson Airport',
    city: 'Nelson',
    country: 'New Zealand',
    iata: 'NSN',
    icao: 'NZNS',
    latitude: -41.2983016967773,
    longitude: 173.220993041992,
    altitude: 17,
    schengen: false
  },
  {
    id: 4166,
    name: 'Invercargill Airport',
    city: 'Invercargill',
    country: 'New Zealand',
    iata: 'IVC',
    icao: 'NZNV',
    latitude: -46.4123992919922,
    longitude: 168.313003540039,
    altitude: 5,
    schengen: false
  },
  {
    id: 4167,
    name: 'Omarama Glider Airport',
    city: 'Omarama',
    country: 'New Zealand',
    iata: '',
    icao: 'NZOA',
    latitude: -44.4866981506348,
    longitude: 169.985992431641,
    altitude: 1381,
    schengen: false
  },
  {
    id: 4168,
    name: 'RNZAF Base Ohakea',
    city: 'Ohakea',
    country: 'New Zealand',
    iata: 'OHA',
    icao: 'NZOH',
    latitude: -40.2060012817383,
    longitude: 175.388000488281,
    altitude: 164,
    schengen: false
  },
  {
    id: 4169,
    name: 'Oamaru Airport',
    city: 'Oamaru',
    country: 'New Zealand',
    iata: 'OAM',
    icao: 'NZOU',
    latitude: -44.9700012207031,
    longitude: 171.082000732422,
    altitude: 99,
    schengen: false
  },
  {
    id: 4170,
    name: 'Okiwi Station Airport',
    city: 'Okiwi',
    country: 'New Zealand',
    iata: '',
    icao: 'NZOX',
    latitude: -36.1464004516602,
    longitude: 175.419006347656,
    altitude: 10,
    schengen: false
  },
  {
    id: 4171,
    name: 'Palmerston North Airport',
    city: 'Palmerston North',
    country: 'New Zealand',
    iata: 'PMR',
    icao: 'NZPM',
    latitude: -40.3205986022949,
    longitude: 175.617004394531,
    altitude: 151,
    schengen: false
  },
  {
    id: 4172,
    name: 'Picton Aerodrome',
    city: 'Picton',
    country: 'New Zealand',
    iata: 'PCN',
    icao: 'NZPN',
    latitude: -41.346099853516,
    longitude: 173.95599365234,
    altitude: 161,
    schengen: false
  },
  {
    id: 4173,
    name: 'Paraparaumu Airport',
    city: 'Paraparaumu',
    country: 'New Zealand',
    iata: 'PPQ',
    icao: 'NZPP',
    latitude: -40.9047012329102,
    longitude: 174.988998413086,
    altitude: 22,
    schengen: false
  },
  {
    id: 4174,
    name: 'Queenstown International Airport',
    city: 'Queenstown International',
    country: 'New Zealand',
    iata: 'ZQN',
    icao: 'NZQN',
    latitude: -45.0210990906,
    longitude: 168.738998413,
    altitude: 1171,
    schengen: false
  },
  {
    id: 4175,
    name: "Ryan's Creek Aerodrome",
    city: 'Stewart Island',
    country: 'New Zealand',
    iata: 'SZS',
    icao: 'NZRC',
    latitude: -46.8997,
    longitude: 168.100998,
    altitude: 62,
    schengen: false
  },
  {
    id: 4176,
    name: 'Rotorua Regional Airport',
    city: 'Rotorua',
    country: 'New Zealand',
    iata: 'ROT',
    icao: 'NZRO',
    latitude: -38.1091995239258,
    longitude: 176.317001342773,
    altitude: 935,
    schengen: false
  },
  {
    id: 4177,
    name: 'Rangiora Airfield',
    city: 'Rangiora',
    country: 'New Zealand',
    iata: '',
    icao: 'NZRT',
    latitude: -43.2900009155273,
    longitude: 172.542007446289,
    altitude: 180,
    schengen: false
  },
  {
    id: 4178,
    name: 'Waiouru Airport',
    city: 'Waiouru',
    country: 'New Zealand',
    iata: '',
    icao: 'NZRU',
    latitude: -39.4463996887207,
    longitude: 175.658004760742,
    altitude: 2686,
    schengen: false
  },
  {
    id: 4179,
    name: 'Tauranga Airport',
    city: 'Tauranga',
    country: 'New Zealand',
    iata: 'TRG',
    icao: 'NZTG',
    latitude: -37.6719017028809,
    longitude: 176.195999145508,
    altitude: 13,
    schengen: false
  },
  {
    id: 4180,
    name: 'Takaka Airport',
    city: 'Takaka',
    country: 'New Zealand',
    iata: 'KTF',
    icao: 'NZTK',
    latitude: -40.8133010864258,
    longitude: 172.774993896484,
    altitude: 102,
    schengen: false
  },
  {
    id: 4181,
    name: 'Tekapo Aerodrome',
    city: 'Lake Tekapo',
    country: 'New Zealand',
    iata: '',
    icao: 'NZTL',
    latitude: -44.0052986145,
    longitude: 170.444000244,
    altitude: 2496,
    schengen: false
  },
  {
    id: 4182,
    name: 'Timaru Airport',
    city: 'Timaru',
    country: 'New Zealand',
    iata: 'TIU',
    icao: 'NZTU',
    latitude: -44.3027992248535,
    longitude: 171.225006103516,
    altitude: 89,
    schengen: false
  },
  {
    id: 4183,
    name: 'Pukaki Airport',
    city: 'Pukaki',
    country: 'New Zealand',
    iata: 'TWZ',
    icao: 'NZUK',
    latitude: -44.2350006104,
    longitude: 170.117996216,
    altitude: 1575,
    schengen: false
  },
  {
    id: 4184,
    name: 'Woodbourne Airport',
    city: 'Woodbourne',
    country: 'New Zealand',
    iata: 'BHE',
    icao: 'NZWB',
    latitude: -41.5182991027832,
    longitude: 173.869995117188,
    altitude: 109,
    schengen: false
  },
  {
    id: 4185,
    name: 'Wanaka Airport',
    city: 'Wanaka',
    country: 'New Zealand',
    iata: 'WKA',
    icao: 'NZWF',
    latitude: -44.722198486328,
    longitude: 169.24600219727,
    altitude: 1142,
    schengen: false
  },
  {
    id: 4186,
    name: 'Wigram Airport',
    city: 'Wigram',
    country: 'New Zealand',
    iata: '',
    icao: 'NZWG',
    latitude: -43.5511016846,
    longitude: 172.552993774,
    altitude: 74,
    schengen: false
  },
  {
    id: 4187,
    name: 'Whakatane Airport',
    city: 'Whakatane',
    country: 'New Zealand',
    iata: 'WHK',
    icao: 'NZWK',
    latitude: -37.9206008911133,
    longitude: 176.914001464844,
    altitude: 20,
    schengen: false
  },
  {
    id: 4188,
    name: 'West Melton Aerodrome',
    city: 'West Melton',
    country: 'New Zealand',
    iata: '',
    icao: 'NZWL',
    latitude: -43.4766998291016,
    longitude: 172.397003173828,
    altitude: 312,
    schengen: false
  },
  {
    id: 4189,
    name: 'Wellington International Airport',
    city: 'Wellington',
    country: 'New Zealand',
    iata: 'WLG',
    icao: 'NZWN',
    latitude: -41.3272018433,
    longitude: 174.804992676,
    altitude: 41,
    schengen: false
  },
  {
    id: 4190,
    name: 'Wairoa Airport',
    city: 'Wairoa',
    country: 'New Zealand',
    iata: 'WIR',
    icao: 'NZWO',
    latitude: -39.0069007873535,
    longitude: 177.406997680664,
    altitude: 42,
    schengen: false
  },
  {
    id: 4191,
    name: 'RNZAF Base Auckland-Whenuapai',
    city: 'Whenuapai',
    country: 'New Zealand',
    iata: '',
    icao: 'NZWP',
    latitude: -36.7877998352051,
    longitude: 174.630004882813,
    altitude: 100,
    schengen: false
  },
  {
    id: 4192,
    name: 'Whangarei Airport',
    city: 'Whangarei',
    country: 'New Zealand',
    iata: 'WRE',
    icao: 'NZWR',
    latitude: -35.7682991027832,
    longitude: 174.365005493164,
    altitude: 133,
    schengen: false
  },
  {
    id: 4193,
    name: 'Westport Airport',
    city: 'Westport',
    country: 'New Zealand',
    iata: 'WSZ',
    icao: 'NZWS',
    latitude: -41.7380981445313,
    longitude: 171.580993652344,
    altitude: 13,
    schengen: false
  },
  {
    id: 4194,
    name: 'Whitianga Airport',
    city: 'Whitianga',
    country: 'New Zealand',
    iata: 'WTZ',
    icao: 'NZWT',
    latitude: -36.8316993713379,
    longitude: 175.679000854492,
    altitude: 10,
    schengen: false
  },
  {
    id: 4195,
    name: 'Wanganui Airport',
    city: 'Wanganui',
    country: 'New Zealand',
    iata: 'WAG',
    icao: 'NZWU',
    latitude: -39.9622001647949,
    longitude: 175.024993896484,
    altitude: 27,
    schengen: false
  },
  {
    id: 4196,
    name: 'Bluefields Airport',
    city: 'Bluefields',
    country: 'Nicaragua',
    iata: 'BEF',
    icao: 'MNBL',
    latitude: 11.9910001754761,
    longitude: -83.7741012573242,
    altitude: 20,
    schengen: false
  },
  {
    id: 4197,
    name: 'Los Brasiles Airport',
    city: 'Los Brasiles',
    country: 'Nicaragua',
    iata: '',
    icao: 'MNBR',
    latitude: 12.1899995803833,
    longitude: -86.3538970947266,
    altitude: 262,
    schengen: false
  },
  {
    id: 4198,
    name: 'San Pedro Airport',
    city: 'Bonanza',
    country: 'Nicaragua',
    iata: 'BZA',
    icao: 'MNBZ',
    latitude: 13.9499998092651,
    longitude: -84.5999984741211,
    altitude: 600,
    schengen: false
  },
  {
    id: 4199,
    name: 'Costa Esmeralda Airport',
    city: 'Tola',
    country: 'Nicaragua',
    iata: 'ECI',
    icao: 'MNCE',
    latitude: 11.427542,
    longitude: -86.033361,
    altitude: 84,
    schengen: false
  },
  {
    id: 4200,
    name: 'Corn Island',
    city: 'Corn Island',
    country: 'Nicaragua',
    iata: 'RNI',
    icao: 'MNCI',
    latitude: 12.1628999710083,
    longitude: -83.0637969970703,
    altitude: 1,
    schengen: false
  },
  {
    id: 4201,
    name: 'Leon (Fanor Urroz) Airport',
    city: 'Leon',
    country: 'Nicaragua',
    iata: '',
    icao: 'MNLN',
    latitude: 12.4292001724243,
    longitude: -86.9028015136719,
    altitude: 328,
    schengen: false
  },
  {
    id: 4202,
    name: 'Omtepe Airport',
    city: 'Moyogalpa',
    country: 'Nicaragua',
    iata: '',
    icao: 'MNLP',
    latitude: 11.523616,
    longitude: -85.702995,
    altitude: 200,
    schengen: false
  },
  {
    id: 4203,
    name: 'Augusto C. Sandino (Managua) International Airport',
    city: 'Managua',
    country: 'Nicaragua',
    iata: 'MGA',
    icao: 'MNMG',
    latitude: 12.1415004730225,
    longitude: -86.1681976318359,
    altitude: 194,
    schengen: false
  },
  {
    id: 4204,
    name: 'Puerto Cabezas Airport',
    city: 'Puerto Cabezas',
    country: 'Nicaragua',
    iata: 'PUZ',
    icao: 'MNPC',
    latitude: 14.0472002029419,
    longitude: -83.3867034912109,
    altitude: 52,
    schengen: false
  },
  {
    id: 4205,
    name: 'Rosita Airport',
    city: 'Rosita',
    country: 'Nicaragua',
    iata: 'RFS',
    icao: 'MNRT',
    latitude: 13.8896999359131,
    longitude: -84.4088973999024,
    altitude: 193,
    schengen: false
  },
  {
    id: 4206,
    name: 'San Carlos',
    city: 'San Carlos',
    country: 'Nicaragua',
    iata: 'NCR',
    icao: 'MNSC',
    latitude: 11.1333999633789,
    longitude: -84.7699966430664,
    altitude: 91,
    schengen: false
  },
  {
    id: 4207,
    name: 'Siuna',
    city: 'Siuna',
    country: 'Nicaragua',
    iata: 'SIU',
    icao: 'MNSI',
    latitude: 13.727222442627,
    longitude: -84.7777786254883,
    altitude: 606,
    schengen: false
  },
  {
    id: 4208,
    name: 'Waspam Airport',
    city: 'Waspam',
    country: 'Nicaragua',
    iata: 'WSP',
    icao: 'MNWP',
    latitude: 14.7391996383667,
    longitude: -83.9693984985352,
    altitude: 98,
    schengen: false
  },
  {
    id: 4209,
    name: 'Maradi Airport',
    city: 'Maradi',
    country: 'Niger',
    iata: 'MFQ',
    icao: 'DRRM',
    latitude: 13.5024995803833,
    longitude: 7.12674999237061,
    altitude: 1240,
    schengen: false
  },
  {
    id: 4210,
    name: 'Diori Hamani International Airport',
    city: 'Niamey',
    country: 'Niger',
    iata: 'NIM',
    icao: 'DRRN',
    latitude: 13.481499671936,
    longitude: 2.18360996246338,
    altitude: 732,
    schengen: false
  },
  {
    id: 4211,
    name: 'Tahoua Airport',
    city: 'Tahoua',
    country: 'Niger',
    iata: 'THZ',
    icao: 'DRRT',
    latitude: 14.8756999969482,
    longitude: 5.26535987854004,
    altitude: 1266,
    schengen: false
  },
  {
    id: 4212,
    name: 'Mano Dayak International Airport',
    city: 'Agadez',
    country: 'Niger',
    iata: 'AJY',
    icao: 'DRZA',
    latitude: 16.9659996032715,
    longitude: 8.00010967254639,
    altitude: 1657,
    schengen: false
  },
  {
    id: 4213,
    name: 'Dirkou Airport',
    city: 'Dirkou',
    country: 'Niger',
    iata: '',
    icao: 'DRZD',
    latitude: 18.9687004089355,
    longitude: 12.8687000274658,
    altitude: 1273,
    schengen: false
  },
  {
    id: 4214,
    name: 'Diffa Airport',
    city: 'Diffa',
    country: 'Niger',
    iata: '',
    icao: 'DRZF',
    latitude: 13.3729000091553,
    longitude: 12.6267004013062,
    altitude: 994,
    schengen: false
  },
  {
    id: 4215,
    name: 'Zinder Airport',
    city: 'Zinder',
    country: 'Niger',
    iata: 'ZND',
    icao: 'DRZR',
    latitude: 13.7790002822876,
    longitude: 8.98375988006592,
    altitude: 1516,
    schengen: false
  },
  {
    id: 4216,
    name: 'Nnamdi Azikiwe International Airport',
    city: 'Abuja',
    country: 'Nigeria',
    iata: 'ABV',
    icao: 'DNAA',
    latitude: 9.00679016113281,
    longitude: 7.26316976547241,
    altitude: 1123,
    schengen: false
  },
  {
    id: 4217,
    name: 'Akwa Ibom International Airport',
    city: 'Uyo',
    country: 'Nigeria',
    iata: 'QUO',
    icao: 'DNAI',
    latitude: 4.8725,
    longitude: 8.093,
    altitude: 170,
    schengen: false
  },
  {
    id: 4218,
    name: 'Akure Airport',
    city: 'Akure',
    country: 'Nigeria',
    iata: 'AKR',
    icao: 'DNAK',
    latitude: 7.24673986434937,
    longitude: 5.30101013183594,
    altitude: 1100,
    schengen: false
  },
  {
    id: 4219,
    name: 'Asaba International Airport',
    city: 'Asaba',
    country: 'Nigeria',
    iata: 'ABB',
    icao: 'DNAS',
    latitude: 6.204167,
    longitude: 6.665278,
    altitude: 305,
    schengen: false
  },
  {
    id: 4220,
    name: 'Sir Abubakar Tafawa Balewa International Airport',
    city: 'Bauchi',
    country: 'Nigeria',
    iata: 'BCU',
    icao: 'DNBC',
    latitude: 10.482833,
    longitude: 9.744,
    altitude: 1965,
    schengen: false
  },
  {
    id: 4221,
    name: 'Benin Airport',
    city: 'Benin',
    country: 'Nigeria',
    iata: 'BNI',
    icao: 'DNBE',
    latitude: 6.31697988510132,
    longitude: 5.59950017929077,
    altitude: 258,
    schengen: false
  },
  {
    id: 4222,
    name: 'Margaret Ekpo International Airport',
    city: 'Calabar',
    country: 'Nigeria',
    iata: 'CBQ',
    icao: 'DNCA',
    latitude: 4.97601985931397,
    longitude: 8.34720039367676,
    altitude: 210,
    schengen: false
  },
  {
    id: 4223,
    name: 'Dutse International Airport',
    city: 'Dutse',
    country: 'Nigeria',
    iata: '',
    icao: 'DNDS',
    latitude: 11.795168,
    longitude: 9.311667,
    altitude: 1365,
    schengen: false
  },
  {
    id: 4224,
    name: 'Akanu Ibiam International Airport',
    city: 'Enugu',
    country: 'Nigeria',
    iata: 'ENU',
    icao: 'DNEN',
    latitude: 6.47426986694336,
    longitude: 7.56196022033691,
    altitude: 466,
    schengen: false
  },
  {
    id: 4225,
    name: 'Gusau Airport',
    city: 'Gusau',
    country: 'Nigeria',
    iata: '',
    icao: 'DNGU',
    latitude: 12.1717,
    longitude: 6.69611,
    altitude: 1520,
    schengen: false
  },
  {
    id: 4226,
    name: 'Ibadan Airport',
    city: 'Ibadan',
    country: 'Nigeria',
    iata: 'IBA',
    icao: 'DNIB',
    latitude: 7.36246013641357,
    longitude: 3.97832989692688,
    altitude: 725,
    schengen: false
  },
  {
    id: 4227,
    name: 'Ilorin International Airport',
    city: 'Ilorin',
    country: 'Nigeria',
    iata: 'ILR',
    icao: 'DNIL',
    latitude: 8.44021034240723,
    longitude: 4.49391984939575,
    altitude: 1126,
    schengen: false
  },
  {
    id: 4228,
    name: 'Sam Mbakwe International Airport',
    city: 'Imo',
    country: 'Nigeria',
    iata: 'QOW',
    icao: 'DNIM',
    latitude: 5.4270601272583,
    longitude: 7.20602989196777,
    altitude: 373,
    schengen: false
  },
  {
    id: 4229,
    name: 'Jalingo Airport',
    city: 'Jalingo',
    country: 'Nigeria',
    iata: '',
    icao: 'DNJA',
    latitude: 8.900587,
    longitude: 11.27946,
    altitude: 685,
    schengen: false
  },
  {
    id: 4230,
    name: 'Yakubu Gowon Airport',
    city: 'Jos',
    country: 'Nigeria',
    iata: 'JOS',
    icao: 'DNJO',
    latitude: 9.63982963562012,
    longitude: 8.86905002593994,
    altitude: 4232,
    schengen: false
  },
  {
    id: 4231,
    name: 'Kaduna Airport',
    city: 'Kaduna',
    country: 'Nigeria',
    iata: 'KAD',
    icao: 'DNKA',
    latitude: 10.6960000991821,
    longitude: 7.32010984420776,
    altitude: 2073,
    schengen: false
  },
  {
    id: 4232,
    name: 'Mallam Aminu International Airport',
    city: 'Kano',
    country: 'Nigeria',
    iata: 'KAN',
    icao: 'DNKN',
    latitude: 12.0476,
    longitude: 8.52462,
    altitude: 1562,
    schengen: false
  },
  {
    id: 4233,
    name: 'Maiduguri International Airport',
    city: 'Maiduguri',
    country: 'Nigeria',
    iata: 'MIU',
    icao: 'DNMA',
    latitude: 11.855299949646,
    longitude: 13.0809001922607,
    altitude: 1099,
    schengen: false
  },
  {
    id: 4234,
    name: 'Makurdi Airport',
    city: 'Makurdi',
    country: 'Nigeria',
    iata: 'MDI',
    icao: 'DNMK',
    latitude: 7.70388,
    longitude: 8.61394,
    altitude: 371,
    schengen: false
  },
  {
    id: 4235,
    name: 'Murtala Muhammed International Airport',
    city: 'Lagos',
    country: 'Nigeria',
    iata: 'LOS',
    icao: 'DNMM',
    latitude: 6.57737016677856,
    longitude: 3.32116007804871,
    altitude: 135,
    schengen: false
  },
  {
    id: 4236,
    name: 'Minna Airport',
    city: 'Minna',
    country: 'Nigeria',
    iata: 'MXJ',
    icao: 'DNMN',
    latitude: 9.65217018127441,
    longitude: 6.4622597694397,
    altitude: 834,
    schengen: false
  },
  {
    id: 4237,
    name: 'Port Harcourt International Airport',
    city: 'Port Hartcourt',
    country: 'Nigeria',
    iata: 'PHC',
    icao: 'DNPO',
    latitude: 5.01549005508423,
    longitude: 6.94959020614624,
    altitude: 87,
    schengen: false
  },
  {
    id: 4238,
    name: 'Sadiq Abubakar III International Airport',
    city: 'Sokoto',
    country: 'Nigeria',
    iata: 'SKO',
    icao: 'DNSO',
    latitude: 12.9162998199463,
    longitude: 5.20719003677368,
    altitude: 1010,
    schengen: false
  },
  {
    id: 4239,
    name: 'Yola Airport',
    city: 'Yola',
    country: 'Nigeria',
    iata: 'YOL',
    icao: 'DNYO',
    latitude: 9.25755023956299,
    longitude: 12.4303998947144,
    altitude: 599,
    schengen: false
  },
  {
    id: 4240,
    name: 'Zaria Airport',
    city: 'Zaria',
    country: 'Nigeria',
    iata: 'ZAR',
    icao: 'DNZA',
    latitude: 11.1302,
    longitude: 7.68581,
    altitude: 2170,
    schengen: false
  },
  {
    id: 4241,
    name: 'Niue International Airport',
    city: 'Alofi',
    country: 'Niue',
    iata: 'IUE',
    icao: 'NIUE',
    latitude: -19.0790309906006,
    longitude: -169.925598144531,
    altitude: 209,
    schengen: false
  },
  {
    id: 4242,
    name: 'Norfolk Island International Airport',
    city: 'Norfolk Island',
    country: 'Norfolk Island',
    iata: 'NLK',
    icao: 'YSNF',
    latitude: -29.0415992736816,
    longitude: 167.938995361328,
    altitude: 371,
    schengen: false
  },
  {
    id: 4243,
    name: 'Pyongyang Sunan International Airport',
    city: 'Pyongyang',
    country: 'North Korea',
    iata: 'FNJ',
    icao: 'ZKPY',
    latitude: 39.224098,
    longitude: 125.669998,
    altitude: 117,
    schengen: false
  },
  {
    id: 4244,
    name: 'Sunchon Air Base',
    city: 'Sunchon',
    country: 'North Korea',
    iata: '',
    icao: 'ZKSC',
    latitude: 39.415699,
    longitude: 125.894997,
    altitude: 141,
    schengen: false
  },
  {
    id: 4245,
    name: 'Sondok Airport',
    city: 'Hamhung',
    country: 'North Korea',
    iata: 'DSO',
    icao: 'ZKSD',
    latitude: 39.745201,
    longitude: 127.473999,
    altitude: 12,
    schengen: false
  },
  {
    id: 4246,
    name: 'Wonsan Kalma International Airport',
    city: 'Wonsan',
    country: 'North Korea',
    iata: 'WOS',
    icao: 'ZKWS',
    latitude: 39.166801,
    longitude: 127.486,
    altitude: 7,
    schengen: false
  },
  {
    id: 4247,
    name: 'Rota International Airport',
    city: 'Rota',
    country: 'Northern Mariana Islands',
    iata: 'ROP',
    icao: 'PGRO',
    latitude: 14.1743001937866,
    longitude: 145.24299621582,
    altitude: 607,
    schengen: false
  },
  {
    id: 4248,
    name: 'Saipan International Airport',
    city: 'Saipan',
    country: 'Northern Mariana Islands',
    iata: 'SPN',
    icao: 'PGSN',
    latitude: 15.119,
    longitude: 145.729004,
    altitude: 215,
    schengen: false
  },
  {
    id: 4249,
    name: 'Tinian International Airport',
    city: 'West Tinian',
    country: 'Northern Mariana Islands',
    iata: 'TIQ',
    icao: 'PGWT',
    latitude: 14.9991998672485,
    longitude: 145.619003295898,
    altitude: 271,
    schengen: false
  },
  {
    id: 4250,
    name: 'Ålesund Airport',
    city: 'Alesund',
    country: 'Norway',
    iata: 'AES',
    icao: 'ENAL',
    latitude: 62.5625,
    longitude: 6.11969995498657,
    altitude: 69,
    schengen: true
  },
  {
    id: 4251,
    name: 'Andøya Airport',
    city: 'Andoya',
    country: 'Norway',
    iata: 'ANX',
    icao: 'ENAN',
    latitude: 69.292503356934,
    longitude: 16.144199371338,
    altitude: 43,
    schengen: true
  },
  {
    id: 4252,
    name: 'Alta Airport',
    city: 'Alta',
    country: 'Norway',
    iata: 'ALF',
    icao: 'ENAT',
    latitude: 69.976097106934,
    longitude: 23.371700286865,
    altitude: 9,
    schengen: true
  },
  {
    id: 4253,
    name: 'Bømoen Airport',
    city: 'Voss',
    country: 'Norway',
    iata: '',
    icao: 'ENBM',
    latitude: 60.6389007568359,
    longitude: 6.50150012969971,
    altitude: 300,
    schengen: true
  },
  {
    id: 4254,
    name: 'Brønnøysund Airport',
    city: 'Bronnoysund',
    country: 'Norway',
    iata: 'BNN',
    icao: 'ENBN',
    latitude: 65.461097717285,
    longitude: 12.217499732971,
    altitude: 25,
    schengen: true
  },
  {
    id: 4255,
    name: 'Bodø Airport',
    city: 'Bodo',
    country: 'Norway',
    iata: 'BOO',
    icao: 'ENBO',
    latitude: 67.2692031860352,
    longitude: 14.3653001785278,
    altitude: 42,
    schengen: true
  },
  {
    id: 4256,
    name: 'Bergen Airport Flesland',
    city: 'Bergen',
    country: 'Norway',
    iata: 'BGO',
    icao: 'ENBR',
    latitude: 60.29339981,
    longitude: 5.218140125,
    altitude: 170,
    schengen: true
  },
  {
    id: 4257,
    name: 'Båtsfjord Airport',
    city: 'Batsfjord',
    country: 'Norway',
    iata: 'BJF',
    icao: 'ENBS',
    latitude: 70.60050201416,
    longitude: 29.691400527954,
    altitude: 490,
    schengen: true
  },
  {
    id: 4258,
    name: 'Berlevåg Airport',
    city: 'Berlevag',
    country: 'Norway',
    iata: 'BVG',
    icao: 'ENBV',
    latitude: 70.871399,
    longitude: 29.034201,
    altitude: 42,
    schengen: true
  },
  {
    id: 4259,
    name: 'Kristiansand Airport',
    city: 'Kristiansand',
    country: 'Norway',
    iata: 'KRS',
    icao: 'ENCN',
    latitude: 58.204201,
    longitude: 8.08537,
    altitude: 57,
    schengen: true
  },
  {
    id: 4260,
    name: 'Geilo Airport Dagali',
    city: 'Geilo',
    country: 'Norway',
    iata: 'DLD',
    icao: 'ENDI',
    latitude: 60.4173011779785,
    longitude: 8.51834964752197,
    altitude: 2618,
    schengen: true
  },
  {
    id: 4261,
    name: 'Bardufoss Airport',
    city: 'Bardufoss',
    country: 'Norway',
    iata: 'BDU',
    icao: 'ENDU',
    latitude: 69.055801391602,
    longitude: 18.540399551392,
    altitude: 252,
    schengen: true
  },
  {
    id: 4262,
    name: 'Engeløy Airport',
    city: 'Engeløy',
    country: 'Norway',
    iata: '',
    icao: 'ENEN',
    latitude: 67.967222,
    longitude: 14.9925,
    altitude: 0,
    schengen: true
  },
  {
    id: 4263,
    name: 'Harstad/Narvik Airport, Evenes',
    city: 'Harstad/Narvik',
    country: 'Norway',
    iata: 'EVE',
    icao: 'ENEV',
    latitude: 68.491302490234,
    longitude: 16.678100585938,
    altitude: 84,
    schengen: true
  },
  {
    id: 4264,
    name: 'Oslo, Fornebu Airport',
    city: 'Oslo',
    country: 'Norway',
    iata: 'FBU',
    icao: 'ENFB',
    latitude: 59.8958015441895,
    longitude: 10.6171998977661,
    altitude: 0,
    schengen: true
  },
  {
    id: 4265,
    name: 'Leirin Airport',
    city: 'Fagernes',
    country: 'Norway',
    iata: 'VDB',
    icao: 'ENFG',
    latitude: 61.015598297119,
    longitude: 9.2880601882935,
    altitude: 2697,
    schengen: true
  },
  {
    id: 4266,
    name: 'Florø Airport',
    city: 'Floro',
    country: 'Norway',
    iata: 'FRO',
    icao: 'ENFL',
    latitude: 61.583599090576,
    longitude: 5.0247201919556,
    altitude: 37,
    schengen: true
  },
  {
    id: 4267,
    name: 'Oslo Gardermoen Airport',
    city: 'Oslo',
    country: 'Norway',
    iata: 'OSL',
    icao: 'ENGM',
    latitude: 60.193901062012,
    longitude: 11.100399971008,
    altitude: 681,
    schengen: true
  },
  {
    id: 4268,
    name: 'Stafsberg Airport',
    city: 'Hamar',
    country: 'Norway',
    iata: 'HMR',
    icao: 'ENHA',
    latitude: 60.8180999755859,
    longitude: 11.0679998397827,
    altitude: 713,
    schengen: true
  },
  {
    id: 4269,
    name: 'Haugesund Airport',
    city: 'Haugesund',
    country: 'Norway',
    iata: 'HAU',
    icao: 'ENHD',
    latitude: 59.34529876709,
    longitude: 5.2083601951599,
    altitude: 86,
    schengen: true
  },
  {
    id: 4270,
    name: 'Hammerfest Airport',
    city: 'Hammerfest',
    country: 'Norway',
    iata: 'HFT',
    icao: 'ENHF',
    latitude: 70.679702758789,
    longitude: 23.668600082397,
    altitude: 266,
    schengen: true
  },
  {
    id: 4271,
    name: 'Hasvik Airport',
    city: 'Hasvik',
    country: 'Norway',
    iata: 'HAA',
    icao: 'ENHK',
    latitude: 70.486701965332,
    longitude: 22.139699935913,
    altitude: 21,
    schengen: true
  },
  {
    id: 4272,
    name: 'Valan Airport',
    city: 'Honningsvag',
    country: 'Norway',
    iata: 'HVG',
    icao: 'ENHV',
    latitude: 71.009696960449,
    longitude: 25.983600616455,
    altitude: 44,
    schengen: true
  },
  {
    id: 4273,
    name: 'Jan Mayensfield',
    city: 'Jan Mayen',
    country: 'Norway',
    iata: '',
    icao: 'ENJA',
    latitude: 70.9441166,
    longitude: -8.6520736,
    altitude: 39,
    schengen: true
  },
  {
    id: 4274,
    name: 'Kautokeino Air Base',
    city: 'Kautokeino',
    country: 'Norway',
    iata: 'QKX',
    icao: 'ENKA',
    latitude: 69.0402984619141,
    longitude: 23.0340003967285,
    altitude: 1165,
    schengen: true
  },
  {
    id: 4275,
    name: 'Kristiansund Airport (Kvernberget)',
    city: 'Kristiansund',
    country: 'Norway',
    iata: 'KSU',
    icao: 'ENKB',
    latitude: 63.111801147461,
    longitude: 7.824520111084,
    altitude: 204,
    schengen: true
  },
  {
    id: 4276,
    name: 'Kjeller Airport',
    city: 'Kjeller',
    country: 'Norway',
    iata: '',
    icao: 'ENKJ',
    latitude: 59.9692993164,
    longitude: 11.0361003876,
    altitude: 354,
    schengen: true
  },
  {
    id: 4277,
    name: 'Kirkenes Airport (Høybuktmoen)',
    city: 'Kirkenes',
    country: 'Norway',
    iata: 'KKN',
    icao: 'ENKR',
    latitude: 69.725799560547,
    longitude: 29.891300201416,
    altitude: 283,
    schengen: true
  },
  {
    id: 4278,
    name: 'Lista Airport',
    city: 'Farsund',
    country: 'Norway',
    iata: 'FAN',
    icao: 'ENLI',
    latitude: 58.0994987487793,
    longitude: 6.62604999542236,
    altitude: 29,
    schengen: true
  },
  {
    id: 4279,
    name: 'Leknes Airport',
    city: 'Leknes',
    country: 'Norway',
    iata: 'LKN',
    icao: 'ENLK',
    latitude: 68.152496337891,
    longitude: 13.609399795532,
    altitude: 78,
    schengen: true
  },
  {
    id: 4280,
    name: 'Mehamn Airport',
    city: 'Mehamn',
    country: 'Norway',
    iata: 'MEH',
    icao: 'ENMH',
    latitude: 71.02970123291,
    longitude: 27.826700210571,
    altitude: 39,
    schengen: true
  },
  {
    id: 4281,
    name: 'Molde Airport',
    city: 'Molde',
    country: 'Norway',
    iata: 'MOL',
    icao: 'ENML',
    latitude: 62.744701385498,
    longitude: 7.2624998092651,
    altitude: 10,
    schengen: true
  },
  {
    id: 4282,
    name: 'Mosjøen Airport (Kjærstad)',
    city: 'Mosjoen',
    country: 'Norway',
    iata: 'MJF',
    icao: 'ENMS',
    latitude: 65.783996582031,
    longitude: 13.214900016785,
    altitude: 237,
    schengen: true
  },
  {
    id: 4283,
    name: 'Banak Airport',
    city: 'Lakselv',
    country: 'Norway',
    iata: 'LKL',
    icao: 'ENNA',
    latitude: 70.068801879883,
    longitude: 24.973499298096,
    altitude: 25,
    schengen: true
  },
  {
    id: 4284,
    name: 'Narvik Framnes Airport',
    city: 'Narvik',
    country: 'Norway',
    iata: 'NVK',
    icao: 'ENNK',
    latitude: 68.436897277832,
    longitude: 17.386699676514,
    altitude: 95,
    schengen: true
  },
  {
    id: 4285,
    name: 'Namsos Høknesøra Airport',
    city: 'Namsos',
    country: 'Norway',
    iata: 'OSY',
    icao: 'ENNM',
    latitude: 64.472198486328,
    longitude: 11.57859992981,
    altitude: 7,
    schengen: true
  },
  {
    id: 4286,
    name: 'Notodden Airport',
    city: 'Notodden',
    country: 'Norway',
    iata: 'NTB',
    icao: 'ENNO',
    latitude: 59.565701,
    longitude: 9.21222,
    altitude: 63,
    schengen: true
  },
  {
    id: 4287,
    name: 'Ørland Airport',
    city: 'Orland',
    country: 'Norway',
    iata: 'OLA',
    icao: 'ENOL',
    latitude: 63.6988983154297,
    longitude: 9.60400009155273,
    altitude: 28,
    schengen: true
  },
  {
    id: 4288,
    name: 'Ørsta-Volda Airport, Hovden',
    city: 'Orsta-Volda',
    country: 'Norway',
    iata: 'HOV',
    icao: 'ENOV',
    latitude: 62.180000305176,
    longitude: 6.0741000175476,
    altitude: 243,
    schengen: true
  },
  {
    id: 4289,
    name: 'Pyramiden Heliport',
    city: 'Pyramiden',
    country: 'Norway',
    iata: '',
    icao: 'ENPY',
    latitude: 78.652322,
    longitude: 16.337208,
    altitude: 6,
    schengen: true
  },
  {
    id: 4290,
    name: 'Mo i Rana Airport, Røssvoll',
    city: 'Mo i Rana',
    country: 'Norway',
    iata: 'MQN',
    icao: 'ENRA',
    latitude: 66.363899230957,
    longitude: 14.301400184631,
    altitude: 229,
    schengen: true
  },
  {
    id: 4291,
    name: 'Ringebu Airfield Frya',
    city: 'Frya',
    country: 'Norway',
    iata: '',
    icao: 'ENRI',
    latitude: 61.545440673,
    longitude: 10.061588287,
    altitude: 571,
    schengen: true
  },
  {
    id: 4292,
    name: 'Rakkestad Astorp Airport',
    city: 'Rakkestad',
    country: 'Norway',
    iata: '',
    icao: 'ENRK',
    latitude: 59.397499,
    longitude: 11.3469,
    altitude: 400,
    schengen: true
  },
  {
    id: 4293,
    name: 'Rørvik Airport, Ryum',
    city: 'Rørvik',
    country: 'Norway',
    iata: 'RVK',
    icao: 'ENRM',
    latitude: 64.838302612305,
    longitude: 11.14610004425,
    altitude: 14,
    schengen: true
  },
  {
    id: 4294,
    name: 'Røros Airport',
    city: 'Roros',
    country: 'Norway',
    iata: 'RRS',
    icao: 'ENRO',
    latitude: 62.578399658203,
    longitude: 11.342300415039,
    altitude: 2054,
    schengen: true
  },
  {
    id: 4295,
    name: 'Røst Airport',
    city: 'Røst',
    country: 'Norway',
    iata: 'RET',
    icao: 'ENRS',
    latitude: 67.527801513672,
    longitude: 12.103300094604,
    altitude: 7,
    schengen: true
  },
  {
    id: 4296,
    name: 'Moss Airport, Rygge',
    city: 'Rygge',
    country: 'Norway',
    iata: 'RYG',
    icao: 'ENRY',
    latitude: 59.378817,
    longitude: 10.785439,
    altitude: 174,
    schengen: true
  },
  {
    id: 4297,
    name: 'Svalbard Airport, Longyear',
    city: 'Svalbard',
    country: 'Norway',
    iata: 'LYR',
    icao: 'ENSB',
    latitude: 78.246101379395,
    longitude: 15.465600013733,
    altitude: 88,
    schengen: true
  },
  {
    id: 4298,
    name: 'Sandane Airport (Anda)',
    city: 'Sandane',
    country: 'Norway',
    iata: 'SDN',
    icao: 'ENSD',
    latitude: 61.830001831055,
    longitude: 6.1058301925659,
    altitude: 196,
    schengen: true
  },
  {
    id: 4299,
    name: 'Sogndal Airport',
    city: 'Sogndal',
    country: 'Norway',
    iata: 'SOG',
    icao: 'ENSG',
    latitude: 61.156101,
    longitude: 7.13778,
    altitude: 1633,
    schengen: true
  },
  {
    id: 4300,
    name: 'Svolvær Helle Airport',
    city: 'Svolvær',
    country: 'Norway',
    iata: 'SVJ',
    icao: 'ENSH',
    latitude: 68.243301391602,
    longitude: 14.669199943542,
    altitude: 27,
    schengen: true
  },
  {
    id: 4301,
    name: 'Stokmarknes Skagen Airport',
    city: 'Stokmarknes',
    country: 'Norway',
    iata: 'SKN',
    icao: 'ENSK',
    latitude: 68.578826904297,
    longitude: 15.033416748047,
    altitude: 11,
    schengen: true
  },
  {
    id: 4302,
    name: 'Skien Airport',
    city: 'Skien',
    country: 'Norway',
    iata: 'SKE',
    icao: 'ENSN',
    latitude: 59.185001373291,
    longitude: 9.56694030761719,
    altitude: 463,
    schengen: true
  },
  {
    id: 4303,
    name: 'Stord Airport',
    city: 'Stord',
    country: 'Norway',
    iata: 'SRP',
    icao: 'ENSO',
    latitude: 59.7919006347656,
    longitude: 5.34084987640381,
    altitude: 160,
    schengen: true
  },
  {
    id: 4304,
    name: 'Sørkjosen Airport',
    city: 'Sorkjosen',
    country: 'Norway',
    iata: 'SOJ',
    icao: 'ENSR',
    latitude: 69.786796569824,
    longitude: 20.959400177002,
    altitude: 16,
    schengen: true
  },
  {
    id: 4305,
    name: 'Vardø Airport, Svartnes',
    city: 'Vardø',
    country: 'Norway',
    iata: 'VAW',
    icao: 'ENSS',
    latitude: 70.355400085449,
    longitude: 31.044900894165,
    altitude: 42,
    schengen: true
  },
  {
    id: 4306,
    name: 'Sandnessjøen Airport (Stokka)',
    city: 'Sandnessjoen',
    country: 'Norway',
    iata: 'SSJ',
    icao: 'ENST',
    latitude: 65.956802368164,
    longitude: 12.468899726868,
    altitude: 56,
    schengen: true
  },
  {
    id: 4307,
    name: 'Tromsø Airport',
    city: 'Tromso',
    country: 'Norway',
    iata: 'TOS',
    icao: 'ENTC',
    latitude: 69.6832962036133,
    longitude: 18.9188995361328,
    altitude: 31,
    schengen: true
  },
  {
    id: 4308,
    name: 'Sandefjord Airport, Torp',
    city: 'Sandefjord',
    country: 'Norway',
    iata: 'TRF',
    icao: 'ENTO',
    latitude: 59.1866989136,
    longitude: 10.258600235,
    altitude: 286,
    schengen: true
  },
  {
    id: 4309,
    name: 'Trondheim Airport Værnes',
    city: 'Trondheim',
    country: 'Norway',
    iata: 'TRD',
    icao: 'ENVA',
    latitude: 63.4578018,
    longitude: 10.9239998,
    altitude: 56,
    schengen: true
  },
  {
    id: 4310,
    name: 'Vadsø Airport',
    city: 'Vadsø',
    country: 'Norway',
    iata: 'VDS',
    icao: 'ENVD',
    latitude: 70.065299987793,
    longitude: 29.844699859619,
    altitude: 127,
    schengen: true
  },
  {
    id: 4311,
    name: 'Værøy Heliport',
    city: 'Værøy',
    country: 'Norway',
    iata: 'VRY',
    icao: 'ENVR',
    latitude: 67.654555,
    longitude: 12.727257,
    altitude: 12,
    schengen: true
  },
  {
    id: 4312,
    name: 'Stavanger Airport Sola',
    city: 'Stavanger',
    country: 'Norway',
    iata: 'SVG',
    icao: 'ENZV',
    latitude: 58.876701354,
    longitude: 5.6377801895,
    altitude: 29,
    schengen: true
  },
  {
    id: 4313,
    name: 'Khasab Air Base',
    city: 'Khasab',
    country: 'Oman',
    iata: 'KHS',
    icao: 'OOKB',
    latitude: 26.1709995269775,
    longitude: 56.2406005859375,
    altitude: 100,
    schengen: false
  },
  {
    id: 4314,
    name: 'Masirah Air Base',
    city: 'Masirah',
    country: 'Oman',
    iata: 'MSH',
    icao: 'OOMA',
    latitude: 20.6753997802734,
    longitude: 58.8904991149902,
    altitude: 64,
    schengen: false
  },
  {
    id: 4315,
    name: 'Muscat International Airport',
    city: 'Muscat',
    country: 'Oman',
    iata: 'MCT',
    icao: 'OOMS',
    latitude: 23.5932998657227,
    longitude: 58.2844009399414,
    altitude: 48,
    schengen: false
  },
  {
    id: 4316,
    name: 'Salalah Airport',
    city: 'Salalah',
    country: 'Oman',
    iata: 'SLL',
    icao: 'OOSA',
    latitude: 17.0387001037598,
    longitude: 54.0913009643555,
    altitude: 73,
    schengen: false
  },
  {
    id: 4317,
    name: 'Sohar Airport',
    city: 'Sohar',
    country: 'Oman',
    iata: 'OHS',
    icao: 'OOSH',
    latitude: 24.38604,
    longitude: 56.62541,
    altitude: 20,
    schengen: false
  },
  {
    id: 4318,
    name: 'Thumrait Air Base',
    city: 'Thumrait',
    country: 'Oman',
    iata: 'TTH',
    icao: 'OOTH',
    latitude: 17.6660003662109,
    longitude: 54.024600982666,
    altitude: 1570,
    schengen: false
  },
  {
    id: 4319,
    name: 'Bhagatanwala Airport',
    city: 'Bhagtanwala',
    country: 'Pakistan',
    iata: 'BHW',
    icao: 'OPBG',
    latitude: 32.056098938,
    longitude: 72.9484024048,
    altitude: 600,
    schengen: false
  },
  {
    id: 4320,
    name: 'Bannu Airport',
    city: 'Bannu',
    country: 'Pakistan',
    iata: 'BNP',
    icao: 'OPBN',
    latitude: 32.9729,
    longitude: 70.527901,
    altitude: 1325,
    schengen: false
  },
  {
    id: 4321,
    name: 'Bahawalpur Airport',
    city: 'Bahawalpur',
    country: 'Pakistan',
    iata: 'BHV',
    icao: 'OPBW',
    latitude: 29.3481006622314,
    longitude: 71.7180023193359,
    altitude: 392,
    schengen: false
  },
  {
    id: 4322,
    name: 'Chitral Airport',
    city: 'Chitral',
    country: 'Pakistan',
    iata: 'CJL',
    icao: 'OPCH',
    latitude: 35.8866004943848,
    longitude: 71.8005981445313,
    altitude: 4920,
    schengen: false
  },
  {
    id: 4323,
    name: 'Dalbandin Airport',
    city: 'Dalbandin',
    country: 'Pakistan',
    iata: 'DBA',
    icao: 'OPDB',
    latitude: 28.8782997131,
    longitude: 64.3998031616,
    altitude: 2800,
    schengen: false
  },
  {
    id: 4324,
    name: 'Dera Ghazi Khan Airport',
    city: 'Dera Ghazi Khan',
    country: 'Pakistan',
    iata: 'DEA',
    icao: 'OPDG',
    latitude: 29.9610004425049,
    longitude: 70.4859008789063,
    altitude: 492,
    schengen: false
  },
  {
    id: 4325,
    name: 'Dera Ismael Khan Airport',
    city: 'Dera Ismael Khan',
    country: 'Pakistan',
    iata: 'DSK',
    icao: 'OPDI',
    latitude: 31.9094009399414,
    longitude: 70.896598815918,
    altitude: 594,
    schengen: false
  },
  {
    id: 4326,
    name: 'Faisalabad International Airport',
    city: 'Faisalabad',
    country: 'Pakistan',
    iata: 'LYP',
    icao: 'OPFA',
    latitude: 31.3649997711182,
    longitude: 72.9947967529297,
    altitude: 591,
    schengen: false
  },
  {
    id: 4327,
    name: 'Gwadar International Airport',
    city: 'Gwadar',
    country: 'Pakistan',
    iata: 'GWD',
    icao: 'OPGD',
    latitude: 25.2332992553711,
    longitude: 62.3294982910156,
    altitude: 36,
    schengen: false
  },
  {
    id: 4328,
    name: 'Gilgit Airport',
    city: 'Gilgit',
    country: 'Pakistan',
    iata: 'GIL',
    icao: 'OPGT',
    latitude: 35.9188003540039,
    longitude: 74.3336029052734,
    altitude: 4796,
    schengen: false
  },
  {
    id: 4329,
    name: 'Shahbaz Air Base',
    city: 'Jacobsbad',
    country: 'Pakistan',
    iata: 'JAG',
    icao: 'OPJA',
    latitude: 28.284200668335,
    longitude: 68.4496994018555,
    altitude: 185,
    schengen: false
  },
  {
    id: 4330,
    name: 'Jiwani Airport',
    city: 'Jiwani',
    country: 'Pakistan',
    iata: 'JIW',
    icao: 'OPJI',
    latitude: 25.0678005219,
    longitude: 61.8054008484,
    altitude: 186,
    schengen: false
  },
  {
    id: 4331,
    name: 'Jinnah International Airport',
    city: 'Karachi',
    country: 'Pakistan',
    iata: 'KHI',
    icao: 'OPKC',
    latitude: 24.9065,
    longitude: 67.160797,
    altitude: 100,
    schengen: false
  },
  {
    id: 4332,
    name: 'Hyderabad Airport',
    city: 'Hyderabad',
    country: 'Pakistan',
    iata: 'HDD',
    icao: 'OPKD',
    latitude: 25.3181,
    longitude: 68.366096,
    altitude: 130,
    schengen: false
  },
  {
    id: 4333,
    name: 'Khuzdar Airport',
    city: 'Khuzdar',
    country: 'Pakistan',
    iata: 'KDD',
    icao: 'OPKH',
    latitude: 27.790599823,
    longitude: 66.6473007202,
    altitude: 4012,
    schengen: false
  },
  {
    id: 4334,
    name: 'Alama Iqbal International Airport',
    city: 'Lahore',
    country: 'Pakistan',
    iata: 'LHE',
    icao: 'OPLA',
    latitude: 31.5216007232666,
    longitude: 74.4036026000977,
    altitude: 712,
    schengen: false
  },
  {
    id: 4335,
    name: 'Walton Airport',
    city: 'Lahore',
    country: 'Pakistan',
    iata: '',
    icao: 'OPLH',
    latitude: 31.494800567627,
    longitude: 74.3461990356445,
    altitude: 679,
    schengen: false
  },
  {
    id: 4336,
    name: 'Mangla Airport',
    city: 'Mangla',
    country: 'Pakistan',
    iata: 'XJM',
    icao: 'OPMA',
    latitude: 33.0500984191895,
    longitude: 73.6383972167969,
    altitude: 902,
    schengen: false
  },
  {
    id: 4337,
    name: 'Muzaffarabad Airport',
    city: 'Muzaffarabad',
    country: 'Pakistan',
    iata: 'MFG',
    icao: 'OPMF',
    latitude: 34.3390007019043,
    longitude: 73.5085983276367,
    altitude: 2691,
    schengen: false
  },
  {
    id: 4338,
    name: 'Mianwali Air Base',
    city: 'Mianwali',
    country: 'Pakistan',
    iata: 'MWD',
    icao: 'OPMI',
    latitude: 32.5630989074707,
    longitude: 71.5707015991211,
    altitude: 690,
    schengen: false
  },
  {
    id: 4339,
    name: 'Moenjodaro Airport',
    city: 'Moenjodaro',
    country: 'Pakistan',
    iata: 'MJD',
    icao: 'OPMJ',
    latitude: 27.3351993560791,
    longitude: 68.1430969238281,
    altitude: 154,
    schengen: false
  },
  {
    id: 4340,
    name: 'Mirpur Khas Air Base',
    city: 'Mirpur Khas',
    country: 'Pakistan',
    iata: '',
    icao: 'OPMK',
    latitude: 25.682501,
    longitude: 69.0728,
    altitude: 180,
    schengen: false
  },
  {
    id: 4341,
    name: 'Masroor Air Base',
    city: 'Karachi',
    country: 'Pakistan',
    iata: '',
    icao: 'OPMR',
    latitude: 24.8936004638672,
    longitude: 66.9387969970703,
    altitude: 35,
    schengen: false
  },
  {
    id: 4342,
    name: 'Minhas Air Base',
    city: 'Kamra',
    country: 'Pakistan',
    iata: 'ATG',
    icao: 'OPMS',
    latitude: 33.8690986633,
    longitude: 72.4009017944,
    altitude: 1023,
    schengen: false
  },
  {
    id: 4343,
    name: 'Multan International Airport',
    city: 'Multan',
    country: 'Pakistan',
    iata: 'MUX',
    icao: 'OPMT',
    latitude: 30.2031993865967,
    longitude: 71.4190979003906,
    altitude: 403,
    schengen: false
  },
  {
    id: 4344,
    name: 'Shaheed Benazirabad Airport',
    city: 'Nawabshah',
    country: 'Pakistan',
    iata: 'WNS',
    icao: 'OPNH',
    latitude: 26.2194,
    longitude: 68.390099,
    altitude: 95,
    schengen: false
  },
  {
    id: 4345,
    name: 'Okara Cantonment Airstrip',
    city: 'Okara',
    country: 'Pakistan',
    iata: '',
    icao: 'OPOK',
    latitude: 30.7409992218,
    longitude: 73.3576965332,
    altitude: 568,
    schengen: false
  },
  {
    id: 4346,
    name: 'Ormara Airport',
    city: 'Ormara Raik',
    country: 'Pakistan',
    iata: 'ORW',
    icao: 'OPOR',
    latitude: 25.2747001648,
    longitude: 64.5859985352,
    altitude: 10,
    schengen: false
  },
  {
    id: 4347,
    name: 'Parachinar Airport',
    city: 'Parachinar',
    country: 'Pakistan',
    iata: 'PAJ',
    icao: 'OPPC',
    latitude: 33.9020996094,
    longitude: 70.0716018677,
    altitude: 5800,
    schengen: false
  },
  {
    id: 4348,
    name: 'Panjgur Airport',
    city: 'Panjgur',
    country: 'Pakistan',
    iata: 'PJG',
    icao: 'OPPG',
    latitude: 26.9545001983643,
    longitude: 64.1324996948242,
    altitude: 3289,
    schengen: false
  },
  {
    id: 4349,
    name: 'Pasni Airport',
    city: 'Pasni',
    country: 'Pakistan',
    iata: 'PSI',
    icao: 'OPPI',
    latitude: 25.2905006408691,
    longitude: 63.345100402832,
    altitude: 33,
    schengen: false
  },
  {
    id: 4350,
    name: 'Peshawar International Airport',
    city: 'Peshawar',
    country: 'Pakistan',
    iata: 'PEW',
    icao: 'OPPS',
    latitude: 33.9939002990723,
    longitude: 71.5146026611328,
    altitude: 1158,
    schengen: false
  },
  {
    id: 4351,
    name: 'Qasim Airport',
    city: 'Qasim',
    country: 'Pakistan',
    iata: '',
    icao: 'OPQS',
    latitude: 33.5601997375488,
    longitude: 73.033203125,
    altitude: 1581,
    schengen: false
  },
  {
    id: 4352,
    name: 'Quetta International Airport',
    city: 'Quetta',
    country: 'Pakistan',
    iata: 'UET',
    icao: 'OPQT',
    latitude: 30.2513999938965,
    longitude: 66.9377975463867,
    altitude: 5267,
    schengen: false
  },
  {
    id: 4353,
    name: 'Shaikh Zaid Airport',
    city: 'Rahim Yar Khan',
    country: 'Pakistan',
    iata: 'RYK',
    icao: 'OPRK',
    latitude: 28.3838996887207,
    longitude: 70.2796020507813,
    altitude: 271,
    schengen: false
  },
  {
    id: 4354,
    name: 'Benazir Bhutto International Airport',
    city: 'Islamabad',
    country: 'Pakistan',
    iata: '',
    icao: 'OPRN',
    latitude: 33.616699,
    longitude: 73.099197,
    altitude: 1668,
    schengen: false
  },
  {
    id: 4355,
    name: 'Rafiqui Air Base',
    city: 'Shorkot',
    country: 'Pakistan',
    iata: '',
    icao: 'OPRQ',
    latitude: 30.7581005096436,
    longitude: 72.2825012207031,
    altitude: 492,
    schengen: false
  },
  {
    id: 4356,
    name: 'Risalpur Air Base',
    city: 'Risalpur',
    country: 'Pakistan',
    iata: '',
    icao: 'OPRS',
    latitude: 34.0811004638672,
    longitude: 71.9726028442383,
    altitude: 1050,
    schengen: false
  },
  {
    id: 4357,
    name: 'Rawalakot Airport',
    city: 'Rawala Kot',
    country: 'Pakistan',
    iata: 'RAZ',
    icao: 'OPRT',
    latitude: 33.8497009277344,
    longitude: 73.7981033325195,
    altitude: 5479,
    schengen: false
  },
  {
    id: 4358,
    name: 'Skardu Airport',
    city: 'Skardu',
    country: 'Pakistan',
    iata: 'KDU',
    icao: 'OPSD',
    latitude: 35.3354988098145,
    longitude: 75.536003112793,
    altitude: 7316,
    schengen: false
  },
  {
    id: 4359,
    name: 'Faisal Air Base',
    city: 'Karachi',
    country: 'Pakistan',
    iata: '',
    icao: 'OPSF',
    latitude: 24.8742008209229,
    longitude: 67.1184997558594,
    altitude: 29,
    schengen: false
  },
  {
    id: 4360,
    name: 'Sukkur Airport',
    city: 'Sukkur',
    country: 'Pakistan',
    iata: 'SKZ',
    icao: 'OPSK',
    latitude: 27.7220001220703,
    longitude: 68.7917022705078,
    altitude: 196,
    schengen: false
  },
  {
    id: 4361,
    name: 'Sehwan Sharif Airport',
    city: 'Sehwan Sharif',
    country: 'Pakistan',
    iata: 'SYW',
    icao: 'OPSN',
    latitude: 26.4731006622314,
    longitude: 67.7172012329102,
    altitude: 121,
    schengen: false
  },
  {
    id: 4362,
    name: 'Mushaf Air Base',
    city: 'Sargodha',
    country: 'Pakistan',
    iata: 'SGI',
    icao: 'OPSR',
    latitude: 32.0485992431641,
    longitude: 72.6650009155273,
    altitude: 614,
    schengen: false
  },
  {
    id: 4363,
    name: 'Saidu Sharif Airport',
    city: 'Saidu Sharif',
    country: 'Pakistan',
    iata: 'SDT',
    icao: 'OPSS',
    latitude: 34.8135986328125,
    longitude: 72.3527984619141,
    altitude: 3183,
    schengen: false
  },
  {
    id: 4364,
    name: 'Sialkot Airport',
    city: 'Sialkot',
    country: 'Pakistan',
    iata: 'SKT',
    icao: 'OPST',
    latitude: 32.5355567932,
    longitude: 74.3638916016,
    altitude: 837,
    schengen: false
  },
  {
    id: 4365,
    name: 'Sui Airport',
    city: 'Sui',
    country: 'Pakistan',
    iata: 'SUL',
    icao: 'OPSU',
    latitude: 28.6450996398926,
    longitude: 69.1769027709961,
    altitude: 763,
    schengen: false
  },
  {
    id: 4366,
    name: 'Sahiwal Airport',
    city: 'Sindh',
    country: 'Pakistan',
    iata: 'SWN',
    icao: 'OPSW',
    latitude: 31.8894443511963,
    longitude: 72.3916702270508,
    altitude: 570,
    schengen: false
  },
  {
    id: 4367,
    name: 'Talhar Airport',
    city: 'Talhar',
    country: 'Pakistan',
    iata: 'BDN',
    icao: 'OPTH',
    latitude: 24.8414993286133,
    longitude: 68.8384017944336,
    altitude: 28,
    schengen: false
  },
  {
    id: 4368,
    name: 'Turbat International Airport',
    city: 'Turbat',
    country: 'Pakistan',
    iata: 'TUK',
    icao: 'OPTU',
    latitude: 25.986400604248,
    longitude: 63.030200958252,
    altitude: 498,
    schengen: false
  },
  {
    id: 4369,
    name: 'Wana Airport',
    city: 'Wana',
    country: 'Pakistan',
    iata: 'WAF',
    icao: 'OPWN',
    latitude: 32.3046989440918,
    longitude: 69.5703964233398,
    altitude: 4550,
    schengen: false
  },
  {
    id: 4370,
    name: 'Zhob Airport',
    city: 'Zhob',
    country: 'Pakistan',
    iata: 'PZH',
    icao: 'OPZB',
    latitude: 31.3584003448486,
    longitude: 69.4636001586914,
    altitude: 4728,
    schengen: false
  },
  {
    id: 4371,
    name: 'Babelthuap Airport',
    city: 'Babelthuap',
    country: 'Palau',
    iata: 'ROR',
    icao: 'PTRO',
    latitude: 7.36731,
    longitude: 134.544236,
    altitude: 176,
    schengen: false
  },
  {
    id: 4372,
    name: 'Yasser Arafat International Airport',
    city: 'Gaza',
    country: 'Palestine',
    iata: 'GZA',
    icao: 'LVGZ',
    latitude: 31.24640083313,
    longitude: 34.276100158691,
    altitude: 320,
    schengen: false
  },
  {
    id: 4373,
    name: 'Bocas Del Toro International Airport',
    city: 'Bocas Del Toro',
    country: 'Panama',
    iata: 'BOC',
    icao: 'MPBO',
    latitude: 9.34084987640381,
    longitude: -82.2508010864258,
    altitude: 10,
    schengen: false
  },
  {
    id: 4374,
    name: 'Alonso Valderrama Airport',
    city: 'Chitré',
    country: 'Panama',
    iata: 'CTD',
    icao: 'MPCE',
    latitude: 7.98784017562866,
    longitude: -80.4096984863281,
    altitude: 33,
    schengen: false
  },
  {
    id: 4375,
    name: 'Cap Manuel Niño International Airport',
    city: 'Changuinola',
    country: 'Panama',
    iata: 'CHX',
    icao: 'MPCH',
    latitude: 9.458962,
    longitude: -82.515062,
    altitude: 19,
    schengen: false
  },
  {
    id: 4376,
    name: 'Enrique Malek International Airport',
    city: 'David',
    country: 'Panama',
    iata: 'DAV',
    icao: 'MPDA',
    latitude: 8.39099979400635,
    longitude: -82.4349975585938,
    altitude: 89,
    schengen: false
  },
  {
    id: 4377,
    name: 'Enrique Adolfo Jimenez Airport',
    city: 'Colón',
    country: 'Panama',
    iata: 'ONX',
    icao: 'MPEJ',
    latitude: 9.35663986206055,
    longitude: -79.8674011230469,
    altitude: 25,
    schengen: false
  },
  {
    id: 4378,
    name: 'Panama Pacific International Airport',
    city: 'Howard',
    country: 'Panama',
    iata: 'BLB',
    icao: 'MPHO',
    latitude: 8.91479,
    longitude: -79.599602,
    altitude: 52,
    schengen: false
  },
  {
    id: 4379,
    name: 'Jaqué Airport',
    city: 'Jaqué',
    country: 'Panama',
    iata: 'JQE',
    icao: 'MPJE',
    latitude: 7.51777982711792,
    longitude: -78.1572036743164,
    altitude: 29,
    schengen: false
  },
  {
    id: 4380,
    name: 'Captain Ramon Xatruch Airport',
    city: 'La Palma',
    country: 'Panama',
    iata: 'PLP',
    icao: 'MPLP',
    latitude: 8.40666961669922,
    longitude: -78.1417007446289,
    altitude: 30,
    schengen: false
  },
  {
    id: 4381,
    name: 'Marcos A. Gelabert International Airport',
    city: 'Panama',
    country: 'Panama',
    iata: 'PAC',
    icao: 'MPMG',
    latitude: 8.97334003448486,
    longitude: -79.5556030273437,
    altitude: 31,
    schengen: false
  },
  {
    id: 4382,
    name: 'Puerto Obaldia Airport',
    city: 'Puerto Obaldia',
    country: 'Panama',
    iata: 'PUE',
    icao: 'MPOA',
    latitude: 8.667,
    longitude: -77.418,
    altitude: 223,
    schengen: false
  },
  {
    id: 4383,
    name: 'Scarlett Martinez International Airport',
    city: 'Rio Hato',
    country: 'Panama',
    iata: 'RIH',
    icao: 'MPRH',
    latitude: 8.375880241394,
    longitude: -80.127899169922,
    altitude: 105,
    schengen: false
  },
  {
    id: 4384,
    name: 'Ruben Cantu Airport',
    city: 'Santiago',
    country: 'Panama',
    iata: 'SYP',
    icao: 'MPSA',
    latitude: 8.08559989929199,
    longitude: -80.9452972412109,
    altitude: 272,
    schengen: false
  },
  {
    id: 4385,
    name: 'Tocumen International Airport',
    city: 'Panama City',
    country: 'Panama',
    iata: 'PTY',
    icao: 'MPTO',
    latitude: 9.0713596344,
    longitude: -79.3834991455,
    altitude: 135,
    schengen: false
  },
  {
    id: 4386,
    name: 'Buka Airport',
    city: 'Buka Island',
    country: 'Papua New Guinea',
    iata: 'BUA',
    icao: 'AYBK',
    latitude: -5.4223198890686,
    longitude: 154.673004150391,
    altitude: 11,
    schengen: false
  },
  {
    id: 4387,
    name: 'Chimbu Airport',
    city: 'Kundiawa',
    country: 'Papua New Guinea',
    iata: 'CMU',
    icao: 'AYCH',
    latitude: -6.02429008483887,
    longitude: 144.970993041992,
    altitude: 4974,
    schengen: false
  },
  {
    id: 4388,
    name: 'Daru Airport',
    city: 'Daru',
    country: 'Papua New Guinea',
    iata: 'DAU',
    icao: 'AYDU',
    latitude: -9.08675956726,
    longitude: 143.207992554,
    altitude: 20,
    schengen: false
  },
  {
    id: 4389,
    name: 'Goroka Airport',
    city: 'Goroka',
    country: 'Papua New Guinea',
    iata: 'GKA',
    icao: 'AYGA',
    latitude: -6.08168983459,
    longitude: 145.391998291,
    altitude: 5282,
    schengen: false
  },
  {
    id: 4390,
    name: 'Gurney Airport',
    city: 'Gurney',
    country: 'Papua New Guinea',
    iata: 'GUR',
    icao: 'AYGN',
    latitude: -10.3114995956,
    longitude: 150.333999634,
    altitude: 88,
    schengen: false
  },
  {
    id: 4391,
    name: 'Girua Airport',
    city: 'Girua',
    country: 'Papua New Guinea',
    iata: 'PNP',
    icao: 'AYGR',
    latitude: -8.80453968048,
    longitude: 148.309005737,
    altitude: 311,
    schengen: false
  },
  {
    id: 4392,
    name: 'Kimbe Airport',
    city: 'Hoskins',
    country: 'Papua New Guinea',
    iata: 'HKN',
    icao: 'AYHK',
    latitude: -5.46217012405396,
    longitude: 150.404998779297,
    altitude: 66,
    schengen: false
  },
  {
    id: 4393,
    name: 'Kiunga Airport',
    city: 'Kiunga',
    country: 'Papua New Guinea',
    iata: 'UNG',
    icao: 'AYKI',
    latitude: -6.12571001052856,
    longitude: 141.281997680664,
    altitude: 88,
    schengen: false
  },
  {
    id: 4394,
    name: 'Kikori Airport',
    city: 'Kikori',
    country: 'Papua New Guinea',
    iata: 'KRI',
    icao: 'AYKK',
    latitude: -7.42437982559204,
    longitude: 144.250076293945,
    altitude: 50,
    schengen: false
  },
  {
    id: 4395,
    name: 'Kerema Airport',
    city: 'Kerema',
    country: 'Papua New Guinea',
    iata: 'KMA',
    icao: 'AYKM',
    latitude: -7.96361017227,
    longitude: 145.770996094,
    altitude: 10,
    schengen: false
  },
  {
    id: 4396,
    name: 'Aropa Airport',
    city: 'Kieta',
    country: 'Papua New Guinea',
    iata: 'KIE',
    icao: 'AYKT',
    latitude: -6.305417,
    longitude: 155.728139,
    altitude: 20,
    schengen: false
  },
  {
    id: 4397,
    name: 'Kavieng Airport',
    city: 'Kavieng',
    country: 'Papua New Guinea',
    iata: 'KVG',
    icao: 'AYKV',
    latitude: -2.57940006256,
    longitude: 150.807998657,
    altitude: 7,
    schengen: false
  },
  {
    id: 4398,
    name: 'Madang Airport',
    city: 'Madang',
    country: 'Papua New Guinea',
    iata: 'MAG',
    icao: 'AYMD',
    latitude: -5.20707988739,
    longitude: 145.789001465,
    altitude: 20,
    schengen: false
  },
  {
    id: 4399,
    name: 'Mount Hagen Kagamuga Airport',
    city: 'Mount Hagen',
    country: 'Papua New Guinea',
    iata: 'HGU',
    icao: 'AYMH',
    latitude: -5.82678985595703,
    longitude: 144.296005249023,
    altitude: 5388,
    schengen: false
  },
  {
    id: 4400,
    name: 'Mendi Airport',
    city: 'Mendi',
    country: 'Papua New Guinea',
    iata: 'MDU',
    icao: 'AYMN',
    latitude: -6.14774,
    longitude: 143.656998,
    altitude: 5680,
    schengen: false
  },
  {
    id: 4401,
    name: 'Momote Airport',
    city: 'Momote',
    country: 'Papua New Guinea',
    iata: 'MAS',
    icao: 'AYMO',
    latitude: -2.06189,
    longitude: 147.423996,
    altitude: 12,
    schengen: false
  },
  {
    id: 4402,
    name: 'Moro Airport',
    city: 'Moro',
    country: 'Papua New Guinea',
    iata: 'MXH',
    icao: 'AYMR',
    latitude: -6.36332988739,
    longitude: 143.238006592,
    altitude: 2740,
    schengen: false
  },
  {
    id: 4403,
    name: 'Misima Island Airport',
    city: 'Misima Island',
    country: 'Papua New Guinea',
    iata: 'MIS',
    icao: 'AYMS',
    latitude: -10.6892004013,
    longitude: 152.837997437,
    altitude: 26,
    schengen: false
  },
  {
    id: 4404,
    name: 'Nadzab Airport',
    city: 'Nadzab',
    country: 'Papua New Guinea',
    iata: 'LAE',
    icao: 'AYNZ',
    latitude: -6.569803,
    longitude: 146.725977,
    altitude: 239,
    schengen: false
  },
  {
    id: 4405,
    name: 'Port Moresby Jacksons International Airport',
    city: 'Port Moresby',
    country: 'Papua New Guinea',
    iata: 'POM',
    icao: 'AYPY',
    latitude: -9.44338035583496,
    longitude: 147.220001220703,
    altitude: 146,
    schengen: false
  },
  {
    id: 4406,
    name: 'Simberi Airport',
    city: 'Simberi Island',
    country: 'Papua New Guinea',
    iata: 'NIS',
    icao: 'AYSE',
    latitude: -2.66222222222,
    longitude: 151.997777778,
    altitude: 10,
    schengen: false
  },
  {
    id: 4407,
    name: 'Tari Airport',
    city: 'Tari',
    country: 'Papua New Guinea',
    iata: 'TIZ',
    icao: 'AYTA',
    latitude: -5.84499979019,
    longitude: 142.947998047,
    altitude: 5500,
    schengen: false
  },
  {
    id: 4408,
    name: 'Tabubil Airport',
    city: 'Tabubil',
    country: 'Papua New Guinea',
    iata: 'TBG',
    icao: 'AYTB',
    latitude: -5.27861,
    longitude: 141.225998,
    altitude: 1570,
    schengen: false
  },
  {
    id: 4409,
    name: 'Tokua Airport',
    city: 'Tokua',
    country: 'Papua New Guinea',
    iata: 'RAB',
    icao: 'AYTK',
    latitude: -4.34045982361,
    longitude: 152.380004883,
    altitude: 32,
    schengen: false
  },
  {
    id: 4410,
    name: 'Vanimo Airport',
    city: 'Vanimo',
    country: 'Papua New Guinea',
    iata: 'VAI',
    icao: 'AYVN',
    latitude: -2.6926,
    longitude: 141.3028,
    altitude: 10,
    schengen: false
  },
  {
    id: 4411,
    name: 'Wapenamanda Airport',
    city: 'Wapenamanda',
    country: 'Papua New Guinea',
    iata: 'WBM',
    icao: 'AYWD',
    latitude: -5.64330005645752,
    longitude: 143.895004272461,
    altitude: 5889,
    schengen: false
  },
  {
    id: 4412,
    name: 'Wewak International Airport',
    city: 'Wewak',
    country: 'Papua New Guinea',
    iata: 'WWK',
    icao: 'AYWK',
    latitude: -3.58383011818,
    longitude: 143.669006348,
    altitude: 19,
    schengen: false
  },
  {
    id: 4413,
    name: 'Silvio Pettirossi International Airport',
    city: 'Asuncion',
    country: 'Paraguay',
    iata: 'ASU',
    icao: 'SGAS',
    latitude: -25.2399997711182,
    longitude: -57.5200004577637,
    altitude: 292,
    schengen: false
  },
  {
    id: 4414,
    name: 'Juan De Ayolas Airport',
    city: 'Ayolas',
    country: 'Paraguay',
    iata: 'AYO',
    icao: 'SGAY',
    latitude: -27.370554,
    longitude: -56.854064,
    altitude: 223,
    schengen: false
  },
  {
    id: 4415,
    name: 'Teniente Col Carmelo Peralta Airport',
    city: 'Conception',
    country: 'Paraguay',
    iata: 'CIO',
    icao: 'SGCO',
    latitude: -23.442363,
    longitude: -57.427253,
    altitude: 253,
    schengen: false
  },
  {
    id: 4416,
    name: 'Encarnación Airport',
    city: 'Encarnacion',
    country: 'Paraguay',
    iata: 'ENO',
    icao: 'SGEN',
    latitude: -27.227366,
    longitude: -55.837495,
    altitude: 659,
    schengen: false
  },
  {
    id: 4417,
    name: 'Guarani International Airport',
    city: 'Ciudad del Este',
    country: 'Paraguay',
    iata: 'AGT',
    icao: 'SGES',
    latitude: -25.454516,
    longitude: -54.842682,
    altitude: 846,
    schengen: false
  },
  {
    id: 4418,
    name: 'Itaipú Airport',
    city: 'Itaipu',
    country: 'Paraguay',
    iata: '',
    icao: 'SGIB',
    latitude: -25.407519,
    longitude: -54.619377,
    altitude: 762,
    schengen: false
  },
  {
    id: 4419,
    name: 'Dr. Luis Maria Argaña International Airport',
    city: 'Mariscal Estigarribia',
    country: 'Paraguay',
    iata: 'ESG',
    icao: 'SGME',
    latitude: -22.0499992370605,
    longitude: -60.6199989318848,
    altitude: 553,
    schengen: false
  },
  {
    id: 4420,
    name: 'Carlos Miguel Gimenez Airport',
    city: 'Pilar',
    country: 'Paraguay',
    iata: 'PIL',
    icao: 'SGPI',
    latitude: -26.881224,
    longitude: -58.318026,
    altitude: 249,
    schengen: false
  },
  {
    id: 4421,
    name: 'Dr Augusto Roberto Fuster International Airport',
    city: 'Pedro Juan Caballero',
    country: 'Paraguay',
    iata: 'PJC',
    icao: 'SGPJ',
    latitude: -22.6399993896484,
    longitude: -55.8300018310547,
    altitude: 1873,
    schengen: false
  },
  {
    id: 4422,
    name: 'Palmar Airport',
    city: 'Santa Lucia',
    country: 'Peru',
    iata: '',
    icao: 'SLPA',
    latitude: -18.9716663360596,
    longitude: -62.9753341674805,
    altitude: 1342,
    schengen: false
  },
  {
    id: 4423,
    name: 'Huancabamba Airport',
    city: 'Huancabamba',
    country: 'Peru',
    iata: '',
    icao: 'SPAB',
    latitude: -5.25677013397217,
    longitude: -79.4429016113281,
    altitude: 6312,
    schengen: false
  },
  {
    id: 4424,
    name: 'Alferez FAP Alfredo Vladimir Sara Bauer Airport',
    city: 'Andoas',
    country: 'Peru',
    iata: 'AOP',
    icao: 'SPAS',
    latitude: -2.79612994194,
    longitude: -76.4665985107,
    altitude: 728,
    schengen: false
  },
  {
    id: 4425,
    name: 'Aguas Calientes Airport',
    city: 'Aguas Calientes',
    country: 'Peru',
    iata: '',
    icao: 'SPAT',
    latitude: -8.83333015441895,
    longitude: -74.6832962036133,
    altitude: 9840,
    schengen: false
  },
  {
    id: 4426,
    name: 'Teniente General Gerardo Pérez Pinedo Airport',
    city: 'Atalaya',
    country: 'Peru',
    iata: '',
    icao: 'SPAY',
    latitude: -10.7291002274,
    longitude: -73.7665023804,
    altitude: 751,
    schengen: false
  },
  {
    id: 4427,
    name: 'Caballococha Airport',
    city: 'Caballococha',
    country: 'Peru',
    iata: '',
    icao: 'SPBC',
    latitude: -3.91686010361,
    longitude: -70.5082015991,
    altitude: 328,
    schengen: false
  },
  {
    id: 4428,
    name: 'Iberia Airport',
    city: 'Iberia',
    country: 'Peru',
    iata: 'IBP',
    icao: 'SPBR',
    latitude: -11.411600112915,
    longitude: -69.4887008666992,
    altitude: 750,
    schengen: false
  },
  {
    id: 4429,
    name: 'Tocache Airport',
    city: 'Tocache',
    country: 'Peru',
    iata: '',
    icao: 'SPCH',
    latitude: -8.1829996109,
    longitude: -76.516998291,
    altitude: 1500,
    schengen: false
  },
  {
    id: 4430,
    name: 'Cap FAP David Abenzur Rengifo International Airport',
    city: 'Pucallpa',
    country: 'Peru',
    iata: 'PCL',
    icao: 'SPCL',
    latitude: -8.37794017791748,
    longitude: -74.5743026733399,
    altitude: 513,
    schengen: false
  },
  {
    id: 4431,
    name: 'Teniente FAP Jaime A De Montreuil Morales Airport',
    city: 'Chimbote',
    country: 'Peru',
    iata: 'CHM',
    icao: 'SPEO',
    latitude: -9.14960956573486,
    longitude: -78.5238037109375,
    altitude: 69,
    schengen: false
  },
  {
    id: 4432,
    name: 'Puerto Esperanza Airport',
    city: 'Puerto Esperanza',
    country: 'Peru',
    iata: '',
    icao: 'SPEP',
    latitude: -9.7681303024292,
    longitude: -70.7064971923828,
    altitude: 725,
    schengen: false
  },
  {
    id: 4433,
    name: 'Cesar Torke Podesta Airport',
    city: 'Moquegua',
    country: 'Peru',
    iata: '',
    icao: 'SPEQ',
    latitude: -17.1790008544922,
    longitude: -70.9308013916016,
    altitude: 4480,
    schengen: false
  },
  {
    id: 4434,
    name: 'Tingo Maria Airport',
    city: 'Tingo Maria',
    country: 'Peru',
    iata: 'TGI',
    icao: 'SPGM',
    latitude: -9.13300037384033,
    longitude: -75.9499969482422,
    altitude: 2010,
    schengen: false
  },
  {
    id: 4435,
    name: 'Capitan FAP Jose A Quinones Gonzales International Airport',
    city: 'Chiclayo',
    country: 'Peru',
    iata: 'CIX',
    icao: 'SPHI',
    latitude: -6.78747987747192,
    longitude: -79.8281021118164,
    altitude: 97,
    schengen: false
  },
  {
    id: 4436,
    name: 'Coronel FAP Alfredo Mendivil Duarte Airport',
    city: 'Ayacucho',
    country: 'Peru',
    iata: 'AYP',
    icao: 'SPHO',
    latitude: -13.1548004150391,
    longitude: -74.2043991088867,
    altitude: 8917,
    schengen: false
  },
  {
    id: 4437,
    name: 'Andahuaylas Airport',
    city: 'Andahuaylas',
    country: 'Peru',
    iata: 'ANS',
    icao: 'SPHY',
    latitude: -13.7063999176025,
    longitude: -73.3504028320313,
    altitude: 11300,
    schengen: false
  },
  {
    id: 4438,
    name: 'Comandante FAP German Arias Graziani Airport',
    city: 'Anta',
    country: 'Peru',
    iata: 'ATA',
    icao: 'SPHZ',
    latitude: -9.34743976593018,
    longitude: -77.5983963012695,
    altitude: 9097,
    schengen: false
  },
  {
    id: 4439,
    name: 'Jorge Chávez International Airport',
    city: 'Lima',
    country: 'Peru',
    iata: 'LIM',
    icao: 'SPIM',
    latitude: -12.0219,
    longitude: -77.114305,
    altitude: 113,
    schengen: false
  },
  {
    id: 4440,
    name: 'Juan Simons Vela Airport',
    city: 'Rioja',
    country: 'Peru',
    iata: 'RIJ',
    icao: 'SPJA',
    latitude: -6.06786012649536,
    longitude: -77.1600036621094,
    altitude: 2707,
    schengen: false
  },
  {
    id: 4441,
    name: 'Shumba Airport',
    city: 'Jaén',
    country: 'Peru',
    iata: 'JAE',
    icao: 'SPJE',
    latitude: -5.59248,
    longitude: -78.774002,
    altitude: 2477,
    schengen: false
  },
  {
    id: 4442,
    name: 'Juanjui Airport',
    city: 'Juanjui',
    country: 'Peru',
    iata: 'JJI',
    icao: 'SPJI',
    latitude: -7.16909980773926,
    longitude: -76.7285995483398,
    altitude: 1148,
    schengen: false
  },
  {
    id: 4443,
    name: 'Francisco Carle Airport',
    city: 'Jauja',
    country: 'Peru',
    iata: 'JAU',
    icao: 'SPJJ',
    latitude: -11.7831001282,
    longitude: -75.4733963013,
    altitude: 11034,
    schengen: false
  },
  {
    id: 4444,
    name: 'Inca Manco Capac International Airport',
    city: 'Juliaca',
    country: 'Peru',
    iata: 'JUL',
    icao: 'SPJL',
    latitude: -15.4671001434326,
    longitude: -70.158203125,
    altitude: 12552,
    schengen: false
  },
  {
    id: 4445,
    name: 'Mayor General FAP Armando Revoredo Iglesias Airport',
    city: 'Cajamarca',
    country: 'Peru',
    iata: 'CJA',
    icao: 'SPJR',
    latitude: -7.13918018341064,
    longitude: -78.4894027709961,
    altitude: 8781,
    schengen: false
  },
  {
    id: 4446,
    name: 'Ilo Airport',
    city: 'Ilo',
    country: 'Peru',
    iata: 'ILQ',
    icao: 'SPLO',
    latitude: -17.6949996948242,
    longitude: -71.3440017700195,
    altitude: 72,
    schengen: false
  },
  {
    id: 4447,
    name: 'Las Palmas Air Base',
    city: 'Las Palmas',
    country: 'Peru',
    iata: '',
    icao: 'SPLP',
    latitude: -12.16069984436,
    longitude: -76.998901367188,
    altitude: 250,
    schengen: false
  },
  {
    id: 4448,
    name: 'Capitan FAP Pedro Canga Rodriguez Airport',
    city: 'Tumbes',
    country: 'Peru',
    iata: 'TBP',
    icao: 'SPME',
    latitude: -3.55253005027771,
    longitude: -80.3814010620117,
    altitude: 115,
    schengen: false
  },
  {
    id: 4449,
    name: 'Moises Benzaquen Rengifo Airport',
    city: 'Yurimaguas',
    country: 'Peru',
    iata: 'YMS',
    icao: 'SPMS',
    latitude: -5.89377021789551,
    longitude: -76.1182022094727,
    altitude: 587,
    schengen: false
  },
  {
    id: 4450,
    name: 'Alferez Fap David Figueroa Fernandini Airport',
    city: 'Huánuco',
    country: 'Peru',
    iata: 'HUU',
    icao: 'SPNC',
    latitude: -9.87880992889404,
    longitude: -76.2048034667969,
    altitude: 6070,
    schengen: false
  },
  {
    id: 4451,
    name: 'Collique Airport',
    city: 'Collique',
    country: 'Peru',
    iata: '',
    icao: 'SPOL',
    latitude: -11.9287004470825,
    longitude: -77.0610961914062,
    altitude: 410,
    schengen: false
  },
  {
    id: 4452,
    name: 'Chachapoyas Airport',
    city: 'Chachapoyas',
    country: 'Peru',
    iata: 'CHH',
    icao: 'SPPY',
    latitude: -6.20180988311768,
    longitude: -77.8561019897461,
    altitude: 8333,
    schengen: false
  },
  {
    id: 4453,
    name: 'Coronel FAP Francisco Secada Vignetta International Airport',
    city: 'Iquitos',
    country: 'Peru',
    iata: 'IQT',
    icao: 'SPQT',
    latitude: -3.78473997116089,
    longitude: -73.3087997436523,
    altitude: 306,
    schengen: false
  },
  {
    id: 4454,
    name: 'Rodríguez Ballón International Airport',
    city: 'Arequipa',
    country: 'Peru',
    iata: 'AQP',
    icao: 'SPQU',
    latitude: -16.3411006927,
    longitude: -71.5830993652,
    altitude: 8405,
    schengen: false
  },
  {
    id: 4455,
    name: 'Capitán FAP Leonardo Alvariño Herr Airport',
    city: 'San Ramon',
    country: 'Peru',
    iata: '',
    icao: 'SPRM',
    latitude: -11.1286001205444,
    longitude: -75.3505020141602,
    altitude: 2600,
    schengen: false
  },
  {
    id: 4456,
    name: 'Capitan FAP Carlos Martinez De Pinillos International Airport',
    city: 'Trujillo',
    country: 'Peru',
    iata: 'TRU',
    icao: 'SPRU',
    latitude: -8.08141040802002,
    longitude: -79.1088027954102,
    altitude: 106,
    schengen: false
  },
  {
    id: 4457,
    name: 'Capitán FAP Renán Elías Olivera International Airport',
    city: 'Pisco',
    country: 'Peru',
    iata: 'PIO',
    icao: 'SPSO',
    latitude: -13.7448997497559,
    longitude: -76.2202987670898,
    altitude: 39,
    schengen: false
  },
  {
    id: 4458,
    name: 'Cadete FAP Guillermo Del Castillo Paredes Airport',
    city: 'Tarapoto',
    country: 'Peru',
    iata: 'TPP',
    icao: 'SPST',
    latitude: -6.50873994827271,
    longitude: -76.3731994628906,
    altitude: 869,
    schengen: false
  },
  {
    id: 4459,
    name: 'Coronel FAP Carlos Ciriani Santa Rosa International Airport',
    city: 'Tacna',
    country: 'Peru',
    iata: 'TCQ',
    icao: 'SPTN',
    latitude: -18.0533008575,
    longitude: -70.2758026123,
    altitude: 1538,
    schengen: false
  },
  {
    id: 4460,
    name: 'Padre Aldamiz International Airport',
    city: 'Puerto Maldonado',
    country: 'Peru',
    iata: 'PEM',
    icao: 'SPTU',
    latitude: -12.6135997772,
    longitude: -69.2285995483,
    altitude: 659,
    schengen: false
  },
  {
    id: 4461,
    name: 'Capitán FAP Guillermo Concha Iberico International Airport',
    city: 'Piura',
    country: 'Peru',
    iata: 'PIU',
    icao: 'SPUR',
    latitude: -5.20574998856,
    longitude: -80.6164016724,
    altitude: 120,
    schengen: false
  },
  {
    id: 4462,
    name: 'Capitan Montes Airport',
    city: 'Talara',
    country: 'Peru',
    iata: 'TYL',
    icao: 'SPYL',
    latitude: -4.5766401290894,
    longitude: -81.254096984863,
    altitude: 282,
    schengen: false
  },
  {
    id: 4463,
    name: 'Maria Reiche Neuman Airport',
    city: 'Nazca',
    country: 'Peru',
    iata: 'NZC',
    icao: 'SPZA',
    latitude: -14.8540000916,
    longitude: -74.9615020752,
    altitude: 1860,
    schengen: false
  },
  {
    id: 4464,
    name: 'Alejandro Velasco Astete International Airport',
    city: 'Cuzco',
    country: 'Peru',
    iata: 'CUZ',
    icao: 'SPZO',
    latitude: -13.5356998444,
    longitude: -71.9387969971,
    altitude: 10860,
    schengen: false
  },
  {
    id: 4465,
    name: 'Subic Bay International Airport',
    city: 'Olongapo City',
    country: 'Philippines',
    iata: 'SFS',
    icao: 'RPLB',
    latitude: 14.7944002151489,
    longitude: 120.271003723145,
    altitude: 64,
    schengen: false
  },
  {
    id: 4466,
    name: 'Diosdado Macapagal International Airport',
    city: 'Angeles City',
    country: 'Philippines',
    iata: 'CRK',
    icao: 'RPLC',
    latitude: 15.186,
    longitude: 120.559998,
    altitude: 484,
    schengen: false
  },
  {
    id: 4467,
    name: 'Laoag International Airport',
    city: 'Laoag',
    country: 'Philippines',
    iata: 'LAO',
    icao: 'RPLI',
    latitude: 18.1781005859375,
    longitude: 120.531997680664,
    altitude: 25,
    schengen: false
  },
  {
    id: 4468,
    name: 'Ninoy Aquino International Airport',
    city: 'Manila',
    country: 'Philippines',
    iata: 'MNL',
    icao: 'RPLL',
    latitude: 14.5086,
    longitude: 121.019997,
    altitude: 75,
    schengen: false
  },
  {
    id: 4469,
    name: 'Palanan Community Airport',
    city: 'Palanan',
    country: 'Philippines',
    iata: '',
    icao: 'RPLN',
    latitude: 17.065667,
    longitude: 122.427671,
    altitude: 52,
    schengen: false
  },
  {
    id: 4470,
    name: 'Cuyo Airport',
    city: 'Cuyo',
    country: 'Philippines',
    iata: 'CYU',
    icao: 'RPLO',
    latitude: 10.858099937439,
    longitude: 121.069000244141,
    altitude: 0,
    schengen: false
  },
  {
    id: 4471,
    name: 'Legazpi City International Airport',
    city: 'Legazpi',
    country: 'Philippines',
    iata: 'LGP',
    icao: 'RPLP',
    latitude: 13.1575,
    longitude: 123.735,
    altitude: 66,
    schengen: false
  },
  {
    id: 4472,
    name: 'Itbayat Airport',
    city: 'Itbayat',
    country: 'Philippines',
    iata: '',
    icao: 'RPLT',
    latitude: 20.722521,
    longitude: 121.809969,
    altitude: 328,
    schengen: false
  },
  {
    id: 4473,
    name: 'Lubang Airport',
    city: 'Lubang',
    country: 'Philippines',
    iata: 'LBX',
    icao: 'RPLU',
    latitude: 13.8554000854492,
    longitude: 120.105003356934,
    altitude: 43,
    schengen: false
  },
  {
    id: 4474,
    name: 'Allah Valley Airport',
    city: 'Surallah',
    country: 'Philippines',
    iata: 'AAV',
    icao: 'RPMA',
    latitude: 6.36681985855103,
    longitude: 124.750999450684,
    altitude: 659,
    schengen: false
  },
  {
    id: 4475,
    name: 'Rajah Buayan Air Base',
    city: 'General Santos City',
    country: 'Philippines',
    iata: '',
    icao: 'RPMB',
    latitude: 6.10644006729,
    longitude: 125.23500061,
    altitude: 28,
    schengen: false
  },
  {
    id: 4476,
    name: 'Awang Airport',
    city: 'Cotabato',
    country: 'Philippines',
    iata: 'CBO',
    icao: 'RPMC',
    latitude: 7.1652398109436,
    longitude: 124.209999084473,
    altitude: 189,
    schengen: false
  },
  {
    id: 4477,
    name: 'Francisco Bangoy International Airport',
    city: 'Davao',
    country: 'Philippines',
    iata: 'DVO',
    icao: 'RPMD',
    latitude: 7.1255202293396,
    longitude: 125.646003723145,
    altitude: 96,
    schengen: false
  },
  {
    id: 4478,
    name: 'Bancasi Airport',
    city: 'Butuan',
    country: 'Philippines',
    iata: 'BXU',
    icao: 'RPME',
    latitude: 8.9515,
    longitude: 125.4788,
    altitude: 141,
    schengen: false
  },
  {
    id: 4479,
    name: 'Bislig Airport',
    city: '',
    country: 'Philippines',
    iata: 'BPH',
    icao: 'RPMF',
    latitude: 8.19594955444336,
    longitude: 126.321998596191,
    altitude: 12,
    schengen: false
  },
  {
    id: 4480,
    name: 'Dipolog Airport',
    city: 'Dipolog',
    country: 'Philippines',
    iata: 'DPL',
    icao: 'RPMG',
    latitude: 8.60198349877,
    longitude: 123.341875076,
    altitude: 12,
    schengen: false
  },
  {
    id: 4481,
    name: 'Camiguin Airport',
    city: 'Camiguin',
    country: 'Philippines',
    iata: 'CGM',
    icao: 'RPMH',
    latitude: 9.25352001190186,
    longitude: 124.707000732422,
    altitude: 53,
    schengen: false
  },
  {
    id: 4482,
    name: 'Jolo Airport',
    city: 'Jolo',
    country: 'Philippines',
    iata: 'JOL',
    icao: 'RPMJ',
    latitude: 6.05366992950439,
    longitude: 121.011001586914,
    altitude: 118,
    schengen: false
  },
  {
    id: 4483,
    name: 'Cagayan De Oro Airport',
    city: 'Ladag',
    country: 'Philippines',
    iata: '',
    icao: 'RPML',
    latitude: 8.41562,
    longitude: 124.611,
    altitude: 601,
    schengen: false
  },
  {
    id: 4484,
    name: 'Sanga Sanga Airport',
    city: 'Sanga Sanga',
    country: 'Philippines',
    iata: 'TWT',
    icao: 'RPMN',
    latitude: 5.046991,
    longitude: 119.742996,
    altitude: 15,
    schengen: false
  },
  {
    id: 4485,
    name: 'Labo Airport',
    city: 'Ozamis',
    country: 'Philippines',
    iata: 'OZC',
    icao: 'RPMO',
    latitude: 8.17850971221924,
    longitude: 123.842002868652,
    altitude: 75,
    schengen: false
  },
  {
    id: 4486,
    name: 'Pagadian Airport',
    city: 'Pagadian',
    country: 'Philippines',
    iata: 'PAG',
    icao: 'RPMP',
    latitude: 7.83073144787,
    longitude: 123.461179733,
    altitude: 5,
    schengen: false
  },
  {
    id: 4487,
    name: 'Mati National Airport',
    city: '',
    country: 'Philippines',
    iata: 'MXI',
    icao: 'RPMQ',
    latitude: 6.949091,
    longitude: 126.27368,
    altitude: 156,
    schengen: false
  },
  {
    id: 4488,
    name: 'General Santos International Airport',
    city: 'Romblon',
    country: 'Philippines',
    iata: 'GES',
    icao: 'RPMR',
    latitude: 6.05800008774,
    longitude: 125.096000671,
    altitude: 505,
    schengen: false
  },
  {
    id: 4489,
    name: 'Surigao Airport',
    city: 'Sangley Point',
    country: 'Philippines',
    iata: 'SUG',
    icao: 'RPMS',
    latitude: 9.75583832563,
    longitude: 125.480947495,
    altitude: 20,
    schengen: false
  },
  {
    id: 4490,
    name: 'Tandag Airport',
    city: 'Tandag',
    country: 'Philippines',
    iata: 'TDG',
    icao: 'RPMW',
    latitude: 9.07211017608643,
    longitude: 126.170997619629,
    altitude: 16,
    schengen: false
  },
  {
    id: 4491,
    name: 'Zamboanga International Airport',
    city: 'Zamboanga',
    country: 'Philippines',
    iata: 'ZAM',
    icao: 'RPMZ',
    latitude: 6.92242002487183,
    longitude: 122.059997558594,
    altitude: 33,
    schengen: false
  },
  {
    id: 4492,
    name: 'Siargao Airport',
    city: 'Siargao',
    country: 'Philippines',
    iata: 'IAO',
    icao: 'RPNS',
    latitude: 9.8591,
    longitude: 126.014,
    altitude: 10,
    schengen: false
  },
  {
    id: 4493,
    name: 'Bantayan Airport',
    city: 'Bantayan',
    country: 'Philippines',
    iata: '',
    icao: 'RPSB',
    latitude: 11.1624,
    longitude: 123.7848,
    altitude: 60,
    schengen: false
  },
  {
    id: 4494,
    name: 'Cesar Lim Rodriguez Airport',
    city: 'Taytay',
    country: 'Philippines',
    iata: 'RZP',
    icao: 'RPSD',
    latitude: 10.81874,
    longitude: 119.507697,
    altitude: 80,
    schengen: false
  },
  {
    id: 4495,
    name: 'Maasin Airport',
    city: 'Maasin',
    country: 'Philippines',
    iata: '',
    icao: 'RPSM',
    latitude: 10.1872997283936,
    longitude: 124.781997680664,
    altitude: 328,
    schengen: false
  },
  {
    id: 4496,
    name: 'Loakan Airport',
    city: 'Baguio',
    country: 'Philippines',
    iata: 'BAG',
    icao: 'RPUB',
    latitude: 16.3750991821289,
    longitude: 120.620002746582,
    altitude: 4251,
    schengen: false
  },
  {
    id: 4497,
    name: 'Daet Airport',
    city: 'Daet',
    country: 'Philippines',
    iata: 'DTE',
    icao: 'RPUD',
    latitude: 14.1291999816895,
    longitude: 122.980003356934,
    altitude: 10,
    schengen: false
  },
  {
    id: 4498,
    name: 'Basa Air Base',
    city: 'Floridablanca',
    country: 'Philippines',
    iata: '',
    icao: 'RPUF',
    latitude: 14.98724,
    longitude: 120.492554,
    altitude: 151,
    schengen: false
  },
  {
    id: 4499,
    name: 'Lingayen Airport',
    city: 'Lingayen',
    country: 'Philippines',
    iata: '',
    icao: 'RPUG',
    latitude: 16.0347995758057,
    longitude: 120.240997314453,
    altitude: 7,
    schengen: false
  },
  {
    id: 4500,
    name: 'San Jose Airport',
    city: 'San Jose',
    country: 'Philippines',
    iata: 'SJI',
    icao: 'RPUH',
    latitude: 12.3614997864,
    longitude: 121.04699707,
    altitude: 14,
    schengen: false
  },
  {
    id: 4501,
    name: 'Fernando Air Base',
    city: 'Lipa',
    country: 'Philippines',
    iata: '',
    icao: 'RPUL',
    latitude: 13.9549999237061,
    longitude: 121.125,
    altitude: 1220,
    schengen: false
  },
  {
    id: 4502,
    name: 'Mamburao Airport',
    city: 'Mamburao',
    country: 'Philippines',
    iata: 'MBO',
    icao: 'RPUM',
    latitude: 13.2081003189087,
    longitude: 120.605003356934,
    altitude: 13,
    schengen: false
  },
  {
    id: 4503,
    name: 'Naga Airport',
    city: 'Naga',
    country: 'Philippines',
    iata: 'WNP',
    icao: 'RPUN',
    latitude: 13.5848999023438,
    longitude: 123.269996643066,
    altitude: 142,
    schengen: false
  },
  {
    id: 4504,
    name: 'Basco Airport',
    city: 'Basco',
    country: 'Philippines',
    iata: 'BSO',
    icao: 'RPUO',
    latitude: 20.4513,
    longitude: 121.980003,
    altitude: 291,
    schengen: false
  },
  {
    id: 4505,
    name: 'Vigan Airport',
    city: 'Vigan',
    country: 'Philippines',
    iata: '',
    icao: 'RPUQ',
    latitude: 17.5552997589111,
    longitude: 120.356002807617,
    altitude: 16,
    schengen: false
  },
  {
    id: 4506,
    name: 'Dr.Juan C. Angara Airport',
    city: 'Baler',
    country: 'Philippines',
    iata: 'BQA',
    icao: 'RPUR',
    latitude: 15.729309,
    longitude: 121.500034,
    altitude: 108,
    schengen: false
  },
  {
    id: 4507,
    name: 'San Fernando Airport',
    city: 'San Fernando',
    country: 'Philippines',
    iata: 'SFE',
    icao: 'RPUS',
    latitude: 16.5956001281738,
    longitude: 120.303001403809,
    altitude: 13,
    schengen: false
  },
  {
    id: 4508,
    name: 'Tuguegarao Airport',
    city: 'Tuguegarao',
    country: 'Philippines',
    iata: 'TUG',
    icao: 'RPUT',
    latitude: 17.6433676823,
    longitude: 121.733150482,
    altitude: 70,
    schengen: false
  },
  {
    id: 4509,
    name: 'Virac Airport',
    city: 'Virac',
    country: 'Philippines',
    iata: 'VRC',
    icao: 'RPUV',
    latitude: 13.5763998031616,
    longitude: 124.206001281738,
    altitude: 121,
    schengen: false
  },
  {
    id: 4510,
    name: 'Marinduque Airport',
    city: 'Gasan',
    country: 'Philippines',
    iata: 'MRQ',
    icao: 'RPUW',
    latitude: 13.3610000610352,
    longitude: 121.825996398926,
    altitude: 32,
    schengen: false
  },
  {
    id: 4511,
    name: 'Cauayan Airport',
    city: 'Cauayan',
    country: 'Philippines',
    iata: 'CYZ',
    icao: 'RPUY',
    latitude: 16.9298992157,
    longitude: 121.752998352,
    altitude: 200,
    schengen: false
  },
  {
    id: 4512,
    name: 'Bagabag Airport',
    city: 'Bagabag',
    country: 'Philippines',
    iata: '',
    icao: 'RPUZ',
    latitude: 16.6192,
    longitude: 121.251999,
    altitude: 820,
    schengen: false
  },
  {
    id: 4513,
    name: 'Daniel Z. Romualdez Airport',
    city: 'Tacloban',
    country: 'Philippines',
    iata: 'TAC',
    icao: 'RPVA',
    latitude: 11.228035,
    longitude: 125.027761,
    altitude: 10,
    schengen: false
  },
  {
    id: 4514,
    name: 'Bacolod-Silay Airport',
    city: 'Bacolod',
    country: 'Philippines',
    iata: 'BCD',
    icao: 'RPVB',
    latitude: 10.7764,
    longitude: 123.014999,
    altitude: 82,
    schengen: false
  },
  {
    id: 4515,
    name: 'Calbayog Airport',
    city: 'Calbayog City',
    country: 'Philippines',
    iata: 'CYP',
    icao: 'RPVC',
    latitude: 12.072699546814,
    longitude: 124.544998168945,
    altitude: 12,
    schengen: false
  },
  {
    id: 4516,
    name: 'Sibulan Airport',
    city: 'Dumaguete',
    country: 'Philippines',
    iata: 'DGT',
    icao: 'RPVD',
    latitude: 9.3337097168,
    longitude: 123.300003052,
    altitude: 15,
    schengen: false
  },
  {
    id: 4517,
    name: 'Godofredo P. Ramos Airport',
    city: 'Caticlan',
    country: 'Philippines',
    iata: 'MPH',
    icao: 'RPVE',
    latitude: 11.9245,
    longitude: 121.954002,
    altitude: 7,
    schengen: false
  },
  {
    id: 4518,
    name: 'Catarman National Airport',
    city: 'Catarman',
    country: 'Philippines',
    iata: 'CRM',
    icao: 'RPVF',
    latitude: 12.5024003982544,
    longitude: 124.636001586914,
    altitude: 6,
    schengen: false
  },
  {
    id: 4519,
    name: 'Guiuan Airport',
    city: 'Guiuan',
    country: 'Philippines',
    iata: '',
    icao: 'RPVG',
    latitude: 11.0354995728,
    longitude: 125.741996765,
    altitude: 7,
    schengen: false
  },
  {
    id: 4520,
    name: 'Iloilo International Airport',
    city: 'Iloilo',
    country: 'Philippines',
    iata: 'ILO',
    icao: 'RPVI',
    latitude: 10.833017,
    longitude: 122.493358,
    altitude: 27,
    schengen: false
  },
  {
    id: 4521,
    name: 'Moises R. Espinosa Airport',
    city: 'Masbate',
    country: 'Philippines',
    iata: 'MBT',
    icao: 'RPVJ',
    latitude: 12.369682,
    longitude: 123.630095,
    altitude: 49,
    schengen: false
  },
  {
    id: 4522,
    name: 'Kalibo International Airport',
    city: 'Kalibo',
    country: 'Philippines',
    iata: 'KLO',
    icao: 'RPVK',
    latitude: 11.679400444,
    longitude: 122.375999451,
    altitude: 14,
    schengen: false
  },
  {
    id: 4523,
    name: 'Mactan Cebu International Airport',
    city: 'Cebu',
    country: 'Philippines',
    iata: 'CEB',
    icao: 'RPVM',
    latitude: 10.307499885559,
    longitude: 123.97899627686,
    altitude: 31,
    schengen: false
  },
  {
    id: 4524,
    name: 'Ormoc Airport',
    city: 'Ormoc City',
    country: 'Philippines',
    iata: 'OMC',
    icao: 'RPVO',
    latitude: 11.0579996109009,
    longitude: 124.565002441406,
    altitude: 83,
    schengen: false
  },
  {
    id: 4525,
    name: 'Puerto Princesa Airport',
    city: 'Puerto Princesa',
    country: 'Philippines',
    iata: 'PPS',
    icao: 'RPVP',
    latitude: 9.74211978912354,
    longitude: 118.759002685547,
    altitude: 71,
    schengen: false
  },
  {
    id: 4526,
    name: 'Biliran Airport',
    city: 'Biliran',
    country: 'Philippines',
    iata: '',
    icao: 'RPVQ',
    latitude: 11.5159178299,
    longitude: 124.428985119,
    altitude: 0,
    schengen: false
  },
  {
    id: 4527,
    name: 'Roxas Airport',
    city: 'Roxas City',
    country: 'Philippines',
    iata: 'RXS',
    icao: 'RPVR',
    latitude: 11.5977001190186,
    longitude: 122.751998901367,
    altitude: 10,
    schengen: false
  },
  {
    id: 4528,
    name: 'Evelio Javier Airport',
    city: 'San Jose',
    country: 'Philippines',
    iata: 'EUQ',
    icao: 'RPVS',
    latitude: 10.765999794,
    longitude: 121.932998657,
    altitude: 23,
    schengen: false
  },
  {
    id: 4529,
    name: 'Tagbilaran Airport',
    city: 'Tagbilaran',
    country: 'Philippines',
    iata: 'TAG',
    icao: 'RPVT',
    latitude: 9.665442,
    longitude: 123.853533,
    altitude: 38,
    schengen: false
  },
  {
    id: 4530,
    name: 'Tugdan Airport',
    city: 'Romblon',
    country: 'Philippines',
    iata: 'TBH',
    icao: 'RPVU',
    latitude: 12.3109998703,
    longitude: 122.084999084,
    altitude: 10,
    schengen: false
  },
  {
    id: 4531,
    name: 'Francisco B. Reyes Airport',
    city: 'Busuanga',
    country: 'Philippines',
    iata: 'USU',
    icao: 'RPVV',
    latitude: 12.1215000153,
    longitude: 120.099998474,
    altitude: 148,
    schengen: false
  },
  {
    id: 4532,
    name: 'Borongan Airport',
    city: 'Borongan',
    country: 'Philippines',
    iata: '',
    icao: 'RPVW',
    latitude: 11.6743001938,
    longitude: 125.478996277,
    altitude: 7,
    schengen: false
  },
  {
    id: 4533,
    name: 'Catbalogan Airport',
    city: 'Catbalogan City',
    country: 'Philippines',
    iata: '',
    icao: 'RPVY',
    latitude: 11.81,
    longitude: 124.83,
    altitude: 0,
    schengen: false
  },
  {
    id: 4534,
    name: 'Siquijor Airport',
    city: 'Siquijor',
    country: 'Philippines',
    iata: '',
    icao: 'RPVZ',
    latitude: 9.21420322319,
    longitude: 123.472251892,
    altitude: 0,
    schengen: false
  },
  {
    id: 4535,
    name: 'Bielsko Biala Airport',
    city: 'Bielsko-Biala',
    country: 'Poland',
    iata: '',
    icao: 'EPBA',
    latitude: 49.8050003051758,
    longitude: 19.0018997192383,
    altitude: 1319,
    schengen: true
  },
  {
    id: 4536,
    name: 'Babice Airport',
    city: 'Warsaw',
    country: 'Poland',
    iata: '',
    icao: 'EPBC',
    latitude: 52.2685012817383,
    longitude: 20.9109992980957,
    altitude: 352,
    schengen: true
  },
  {
    id: 4537,
    name: 'Biała Podlaska Airfield',
    city: 'Biała Podlaska',
    country: 'Poland',
    iata: 'BXP',
    icao: 'EPBP',
    latitude: 52.00078,
    longitude: 23.1325278,
    altitude: 485,
    schengen: true
  },
  {
    id: 4538,
    name: 'Bydgoszcz Ignacy Jan Paderewski Airport',
    city: 'Bydgoszcz',
    country: 'Poland',
    iata: 'BZG',
    icao: 'EPBY',
    latitude: 53.0968017578,
    longitude: 17.9776992798,
    altitude: 235,
    schengen: true
  },
  {
    id: 4539,
    name: 'Cewice Military Airport',
    city: 'Lębork',
    country: 'Poland',
    iata: '',
    icao: 'EPCE',
    latitude: 54.4160003662,
    longitude: 17.763299942,
    altitude: 495,
    schengen: true
  },
  {
    id: 4540,
    name: 'Deblin Military Air Base',
    city: 'Dęblin',
    country: 'Poland',
    iata: '',
    icao: 'EPDE',
    latitude: 51.551399231,
    longitude: 21.8936004639,
    altitude: 392,
    schengen: true
  },
  {
    id: 4541,
    name: 'Gdańsk Lech Wałęsa Airport',
    city: 'Gdansk',
    country: 'Poland',
    iata: 'GDN',
    icao: 'EPGD',
    latitude: 54.3776016235352,
    longitude: 18.4661998748779,
    altitude: 489,
    schengen: true
  },
  {
    id: 4542,
    name: 'Góraszka Airport',
    city: 'Goraszka',
    country: 'Poland',
    iata: '',
    icao: 'EPGO',
    latitude: 52.184440612793,
    longitude: 21.2811107635498,
    altitude: 361,
    schengen: true
  },
  {
    id: 4543,
    name: 'Inowroclaw Military Air Base',
    city: 'Inowroclaw',
    country: 'Poland',
    iata: '',
    icao: 'EPIR',
    latitude: 52.8293991089,
    longitude: 18.3306007385,
    altitude: 259,
    schengen: true
  },
  {
    id: 4544,
    name: 'Jastarnia Airport',
    city: 'Jastarnia',
    country: 'Poland',
    iata: '',
    icao: 'EPJA',
    latitude: 54.7102813720703,
    longitude: 18.6452808380127,
    altitude: 3,
    schengen: true
  },
  {
    id: 4545,
    name: 'Jelenia Góra Glider Airport',
    city: 'Jelenia Gora',
    country: 'Poland',
    iata: '',
    icao: 'EPJG',
    latitude: 50.8988990783691,
    longitude: 15.7855997085571,
    altitude: 1119,
    schengen: true
  },
  {
    id: 4546,
    name: 'Kraków John Paul II International Airport',
    city: 'Krakow',
    country: 'Poland',
    iata: 'KRK',
    icao: 'EPKK',
    latitude: 50.077702,
    longitude: 19.7848,
    altitude: 791,
    schengen: true
  },
  {
    id: 4547,
    name: 'Muchowiec Airport',
    city: 'Katowice',
    country: 'Poland',
    iata: '',
    icao: 'EPKM',
    latitude: 50.2380981445313,
    longitude: 19.034200668335,
    altitude: 909,
    schengen: true
  },
  {
    id: 4548,
    name: 'Koszalin Zegrze Pomorskie Air Base',
    city: 'Koszalin',
    country: 'Poland',
    iata: 'OSZ',
    icao: 'EPKO',
    latitude: 54.0425,
    longitude: 16.2656,
    altitude: 249,
    schengen: true
  },
  {
    id: 4549,
    name: 'Krzesiny Military Air Base',
    city: 'Poznan',
    country: 'Poland',
    iata: '',
    icao: 'EPKS',
    latitude: 52.3316993713,
    longitude: 16.9664001465,
    altitude: 265,
    schengen: true
  },
  {
    id: 4550,
    name: 'Katowice International Airport',
    city: 'Katowice',
    country: 'Poland',
    iata: 'KTW',
    icao: 'EPKT',
    latitude: 50.4743,
    longitude: 19.08,
    altitude: 995,
    schengen: true
  },
  {
    id: 4551,
    name: 'Lask Military Air Base',
    city: 'Lask',
    country: 'Poland',
    iata: '',
    icao: 'EPLK',
    latitude: 51.551700592,
    longitude: 19.1791000366,
    altitude: 633,
    schengen: true
  },
  {
    id: 4552,
    name: 'Łódź Władysław Reymont Airport',
    city: 'Lodz',
    country: 'Poland',
    iata: 'LCJ',
    icao: 'EPLL',
    latitude: 51.7219009399,
    longitude: 19.3980998993,
    altitude: 604,
    schengen: true
  },
  {
    id: 4553,
    name: 'Leczyca Military Air Base',
    city: 'Leczyca',
    country: 'Poland',
    iata: '',
    icao: 'EPLY',
    latitude: 52.004699707,
    longitude: 19.1455993652,
    altitude: 377,
    schengen: true
  },
  {
    id: 4554,
    name: 'Malbork Military Air Base',
    city: 'Malbork',
    country: 'Poland',
    iata: '',
    icao: 'EPMB',
    latitude: 54.0269012451,
    longitude: 19.1341991425,
    altitude: 16,
    schengen: true
  },
  {
    id: 4555,
    name: 'Miroslawiec Military Air Base',
    city: 'Miroslawiec',
    country: 'Poland',
    iata: '',
    icao: 'EPMI',
    latitude: 53.3950996399,
    longitude: 16.0827999115,
    altitude: 459,
    schengen: true
  },
  {
    id: 4556,
    name: 'Mielec Airport',
    city: 'Mielec',
    country: 'Poland',
    iata: '',
    icao: 'EPML',
    latitude: 50.3222999572754,
    longitude: 21.4620990753174,
    altitude: 548,
    schengen: true
  },
  {
    id: 4557,
    name: 'Minsk Mazowiecki Military Air Base',
    city: '',
    country: 'Poland',
    iata: '',
    icao: 'EPMM',
    latitude: 52.1954994202,
    longitude: 21.6558990479,
    altitude: 604,
    schengen: true
  },
  {
    id: 4558,
    name: 'Modlin Airport',
    city: 'Warsaw',
    country: 'Poland',
    iata: 'WMI',
    icao: 'EPMO',
    latitude: 52.451099,
    longitude: 20.6518,
    altitude: 341,
    schengen: true
  },
  {
    id: 4559,
    name: 'Oksywie Military Air Base',
    city: 'Gdynia',
    country: 'Poland',
    iata: 'QYD',
    icao: 'EPOK',
    latitude: 54.57970047,
    longitude: 18.51720047,
    altitude: 144,
    schengen: true
  },
  {
    id: 4560,
    name: 'Poznań-Ławica Airport',
    city: 'Poznan',
    country: 'Poland',
    iata: 'POZ',
    icao: 'EPPO',
    latitude: 52.4210014343,
    longitude: 16.8262996674,
    altitude: 308,
    schengen: true
  },
  {
    id: 4561,
    name: 'Pruszcz Gdanski Air Base',
    city: 'Pruszcz Gdansk',
    country: 'Poland',
    iata: '',
    icao: 'EPPR',
    latitude: 54.248001,
    longitude: 18.6716,
    altitude: 21,
    schengen: true
  },
  {
    id: 4562,
    name: 'Powidz Military Air Base',
    city: '',
    country: 'Poland',
    iata: '',
    icao: 'EPPW',
    latitude: 52.379398,
    longitude: 17.853901,
    altitude: 371,
    schengen: true
  },
  {
    id: 4563,
    name: 'Radom Airport',
    city: 'RADOM',
    country: 'Poland',
    iata: 'RDO',
    icao: 'EPRA',
    latitude: 51.3891983032,
    longitude: 21.213300705,
    altitude: 610,
    schengen: true
  },
  {
    id: 4564,
    name: 'Rzeszów-Jasionka Airport',
    city: 'Rzeszow',
    country: 'Poland',
    iata: 'RZE',
    icao: 'EPRZ',
    latitude: 50.1100006104,
    longitude: 22.0189990997,
    altitude: 675,
    schengen: true
  },
  {
    id: 4565,
    name: 'Szczecin-Goleniów "Solidarność" Airport',
    city: 'Szczecin',
    country: 'Poland',
    iata: 'SZZ',
    icao: 'EPSC',
    latitude: 53.5847015381,
    longitude: 14.9021997452,
    altitude: 154,
    schengen: true
  },
  {
    id: 4566,
    name: 'Szczecin-Dąbie Airport',
    city: 'Szczecin',
    country: 'Poland',
    iata: '',
    icao: 'EPSD',
    latitude: 53.3921012878418,
    longitude: 14.6337995529175,
    altitude: 3,
    schengen: true
  },
  {
    id: 4567,
    name: 'Redzikowo Air Base',
    city: 'Slupsk',
    country: 'Poland',
    iata: 'OSP',
    icao: 'EPSK',
    latitude: 54.4789009094238,
    longitude: 17.1075000762939,
    altitude: 217,
    schengen: true
  },
  {
    id: 4568,
    name: 'Swidwin Military Air Base',
    city: 'Shapaja',
    country: 'Poland',
    iata: '',
    icao: 'EPSN',
    latitude: 53.790599823,
    longitude: 15.8262996674,
    altitude: 385,
    schengen: true
  },
  {
    id: 4569,
    name: 'Suwałki Airport',
    city: 'Suwalki',
    country: 'Poland',
    iata: '',
    icao: 'EPSU',
    latitude: 54.0727996826172,
    longitude: 22.8992004394531,
    altitude: 581,
    schengen: true
  },
  {
    id: 4570,
    name: 'Olsztyn-Mazury Airport',
    city: 'Szczytno-Szymany',
    country: 'Poland',
    iata: 'SZY',
    icao: 'EPSY',
    latitude: 53.481899,
    longitude: 20.9377,
    altitude: 463,
    schengen: true
  },
  {
    id: 4571,
    name: 'Tomaszow Mazowiecki Military Air Base',
    city: 'Tomaszów Mazowiecki',
    country: 'Poland',
    iata: '',
    icao: 'EPTM',
    latitude: 51.584400177,
    longitude: 20.0977993011,
    altitude: 571,
    schengen: true
  },
  {
    id: 4572,
    name: 'Warsaw Chopin Airport',
    city: 'Warsaw',
    country: 'Poland',
    iata: 'WAW',
    icao: 'EPWA',
    latitude: 52.1656990051,
    longitude: 20.9671001434,
    altitude: 362,
    schengen: true
  },
  {
    id: 4573,
    name: 'Copernicus Wrocław Airport',
    city: 'Wroclaw',
    country: 'Poland',
    iata: 'WRO',
    icao: 'EPWR',
    latitude: 51.1026992798,
    longitude: 16.885799408,
    altitude: 404,
    schengen: true
  },
  {
    id: 4574,
    name: 'Zielona Góra-Babimost Airport',
    city: 'Zielona Gora',
    country: 'Poland',
    iata: 'IEG',
    icao: 'EPZG',
    latitude: 52.1385002136,
    longitude: 15.7986001968,
    altitude: 194,
    schengen: true
  },
  {
    id: 4575,
    name: 'Alverca Air Base',
    city: 'Alverca',
    country: 'Portugal',
    iata: 'AVR',
    icao: 'LPAR',
    latitude: 38.883301,
    longitude: -9.0301,
    altitude: 11,
    schengen: true
  },
  {
    id: 4576,
    name: 'São Jacinto Airport',
    city: 'Aveiro',
    country: 'Portugal',
    iata: '',
    icao: 'LPAV',
    latitude: 40.656741,
    longitude: -8.741544,
    altitude: 26,
    schengen: true
  },
  {
    id: 4577,
    name: 'Santa Maria Airport',
    city: 'Santa Maria (island)',
    country: 'Portugal',
    iata: 'SMA',
    icao: 'LPAZ',
    latitude: 36.9714012145996,
    longitude: -25.1706008911133,
    altitude: 308,
    schengen: true
  },
  {
    id: 4578,
    name: 'Bragança Airport',
    city: 'Braganca',
    country: 'Portugal',
    iata: 'BGC',
    icao: 'LPBG',
    latitude: 41.8578,
    longitude: -6.70713,
    altitude: 2241,
    schengen: true
  },
  {
    id: 4579,
    name: 'Beja Airport / Airbase',
    city: 'Beja (madeira)',
    country: 'Portugal',
    iata: 'BYJ',
    icao: 'LPBJ',
    latitude: 38.078899,
    longitude: -7.9324,
    altitude: 636,
    schengen: true
  },
  {
    id: 4580,
    name: 'Braga Municipal Aerodrome',
    city: 'Braga',
    country: 'Portugal',
    iata: 'BGZ',
    icao: 'LPBR',
    latitude: 41.5871009827,
    longitude: -8.44513988495,
    altitude: 247,
    schengen: true
  },
  {
    id: 4581,
    name: 'Aerodromo de Castelo Branco',
    city: 'Castelo Branco',
    country: 'Portugal',
    iata: '',
    icao: 'LPCB',
    latitude: 39.847022,
    longitude: -7.44111,
    altitude: 1300,
    schengen: true
  },
  {
    id: 4582,
    name: 'Aerodromo Municipal de Coimbra',
    city: 'Coimba',
    country: 'Portugal',
    iata: '',
    icao: 'LPCO',
    latitude: 40.158758,
    longitude: -8.470815,
    altitude: 587,
    schengen: true
  },
  {
    id: 4583,
    name: 'Corvo Airport',
    city: 'Corvo',
    country: 'Portugal',
    iata: 'CVU',
    icao: 'LPCR',
    latitude: 39.671501,
    longitude: -31.1136,
    altitude: 0,
    schengen: true
  },
  {
    id: 4584,
    name: 'Cascais Airport',
    city: 'Cascais',
    country: 'Portugal',
    iata: 'CAT',
    icao: 'LPCS',
    latitude: 38.724998,
    longitude: -9.35523,
    altitude: 325,
    schengen: true
  },
  {
    id: 4585,
    name: 'Covilhã Airport',
    city: 'Covilha',
    country: 'Portugal',
    iata: '',
    icao: 'LPCV',
    latitude: 40.264801,
    longitude: -7.47996,
    altitude: 1572,
    schengen: true
  },
  {
    id: 4586,
    name: 'Évora Airport',
    city: 'Evora',
    country: 'Portugal',
    iata: '',
    icao: 'LPEV',
    latitude: 38.533501,
    longitude: -7.88964,
    altitude: 807,
    schengen: true
  },
  {
    id: 4587,
    name: 'Flores Airport',
    city: 'Flores',
    country: 'Portugal',
    iata: 'FLW',
    icao: 'LPFL',
    latitude: 39.4552993774414,
    longitude: -31.1313991546631,
    altitude: 112,
    schengen: true
  },
  {
    id: 4588,
    name: 'Faro Airport',
    city: 'Faro',
    country: 'Portugal',
    iata: 'FAO',
    icao: 'LPFR',
    latitude: 37.0144004822,
    longitude: -7.96590995789,
    altitude: 24,
    schengen: true
  },
  {
    id: 4589,
    name: 'Graciosa Airport',
    city: 'Graciosa Island',
    country: 'Portugal',
    iata: 'GRW',
    icao: 'LPGR',
    latitude: 39.0922012329102,
    longitude: -28.0298004150391,
    altitude: 86,
    schengen: true
  },
  {
    id: 4590,
    name: 'Horta Airport',
    city: 'Horta',
    country: 'Portugal',
    iata: 'HOR',
    icao: 'LPHR',
    latitude: 38.5199012756348,
    longitude: -28.7159004211426,
    altitude: 118,
    schengen: true
  },
  {
    id: 4591,
    name: 'Lajes Airport',
    city: 'Lajes (terceira Island)',
    country: 'Portugal',
    iata: 'TER',
    icao: 'LPLA',
    latitude: 38.761799,
    longitude: -27.090799,
    altitude: 180,
    schengen: true
  },
  {
    id: 4592,
    name: 'Madeira Airport',
    city: 'Funchal',
    country: 'Portugal',
    iata: 'FNC',
    icao: 'LPMA',
    latitude: 32.697899,
    longitude: -16.7745,
    altitude: 192,
    schengen: true
  },
  {
    id: 4593,
    name: 'Monte Real Air Base',
    city: 'Monte Real',
    country: 'Portugal',
    iata: 'QLR',
    icao: 'LPMR',
    latitude: 39.828335,
    longitude: -8.8875,
    altitude: 187,
    schengen: true
  },
  {
    id: 4594,
    name: 'Montijo Air Base',
    city: 'Montijo',
    country: 'Portugal',
    iata: '',
    icao: 'LPMT',
    latitude: 38.703899,
    longitude: -9.03592,
    altitude: 46,
    schengen: true
  },
  {
    id: 4595,
    name: 'Ota Air Base',
    city: 'Ota',
    country: 'Portugal',
    iata: '',
    icao: 'LPOT',
    latitude: 39.087502,
    longitude: -8.96278,
    altitude: 140,
    schengen: true
  },
  {
    id: 4596,
    name: 'Ovar Air Base',
    city: 'Ovar',
    country: 'Portugal',
    iata: '',
    icao: 'LPOV',
    latitude: 40.915901,
    longitude: -8.64592,
    altitude: 56,
    schengen: true
  },
  {
    id: 4597,
    name: 'João Paulo II Airport',
    city: 'Ponta Delgada',
    country: 'Portugal',
    iata: 'PDL',
    icao: 'LPPD',
    latitude: 37.7411994934,
    longitude: -25.6979007721,
    altitude: 259,
    schengen: true
  },
  {
    id: 4598,
    name: 'Pico Airport',
    city: 'Pico',
    country: 'Portugal',
    iata: 'PIX',
    icao: 'LPPI',
    latitude: 38.5542984008789,
    longitude: -28.4412994384766,
    altitude: 109,
    schengen: true
  },
  {
    id: 4599,
    name: 'Portimão Airport',
    city: 'Portimao',
    country: 'Portugal',
    iata: 'PRM',
    icao: 'LPPM',
    latitude: 37.1493,
    longitude: -8.58396,
    altitude: 5,
    schengen: true
  },
  {
    id: 4600,
    name: 'Francisco de Sá Carneiro Airport',
    city: 'Porto',
    country: 'Portugal',
    iata: 'OPO',
    icao: 'LPPR',
    latitude: 41.2481002808,
    longitude: -8.68138980865,
    altitude: 228,
    schengen: true
  },
  {
    id: 4601,
    name: 'Porto Santo Airport',
    city: 'Porto Santo',
    country: 'Portugal',
    iata: 'PXO',
    icao: 'LPPS',
    latitude: 33.0733985901,
    longitude: -16.3500003815,
    altitude: 341,
    schengen: true
  },
  {
    id: 4602,
    name: 'Humberto Delgado Airport (Lisbon Portela Airport)',
    city: 'Lisbon',
    country: 'Portugal',
    iata: 'LIS',
    icao: 'LPPT',
    latitude: 38.7813,
    longitude: -9.13592,
    altitude: 374,
    schengen: true
  },
  {
    id: 4603,
    name: 'São Jorge Airport',
    city: 'Sao Jorge Island',
    country: 'Portugal',
    iata: 'SJZ',
    icao: 'LPSJ',
    latitude: 38.6655006408691,
    longitude: -28.1758003234863,
    altitude: 311,
    schengen: true
  },
  {
    id: 4604,
    name: 'Ponte de Sor Airport',
    city: 'Ponte de Sôr',
    country: 'Portugal',
    iata: '',
    icao: 'LPSO',
    latitude: 39.211559,
    longitude: -8.056542,
    altitude: 0,
    schengen: true
  },
  {
    id: 4605,
    name: 'Sintra Air Base',
    city: 'Sintra',
    country: 'Portugal',
    iata: '',
    icao: 'LPST',
    latitude: 38.8311,
    longitude: -9.33955,
    altitude: 440,
    schengen: true
  },
  {
    id: 4606,
    name: 'Tancos Airbase',
    city: 'Tancos',
    country: 'Portugal',
    iata: '',
    icao: 'LPTN',
    latitude: 39.475101,
    longitude: -8.36458,
    altitude: 266,
    schengen: true
  },
  {
    id: 4607,
    name: 'Vilar Da Luz Airport',
    city: 'Maia',
    country: 'Portugal',
    iata: '',
    icao: 'LPVL',
    latitude: 41.27729,
    longitude: -8.516207,
    altitude: 0,
    schengen: true
  },
  {
    id: 4608,
    name: 'Vila Real Airport',
    city: 'Vila Real',
    country: 'Portugal',
    iata: 'VRL',
    icao: 'LPVR',
    latitude: 41.2743,
    longitude: -7.72047,
    altitude: 1805,
    schengen: true
  },
  {
    id: 4609,
    name: 'Aerodromo Goncalves Lobato (Viseu Airport)',
    city: 'Viseu',
    country: 'Portugal',
    iata: 'VSE',
    icao: 'LPVZ',
    latitude: 40.725498,
    longitude: -7.88899,
    altitude: 2060,
    schengen: true
  },
  {
    id: 4610,
    name: 'Antonio Nery Juarbe Pol Airport',
    city: 'Arecibo',
    country: 'Puerto Rico',
    iata: 'ARE',
    icao: 'TJAB',
    latitude: 18.4500007629,
    longitude: -66.6753005981,
    altitude: 23,
    schengen: false
  },
  {
    id: 4611,
    name: 'Rafael Hernandez Airport',
    city: 'Aguadilla',
    country: 'Puerto Rico',
    iata: 'BQN',
    icao: 'TJBQ',
    latitude: 18.4948997497559,
    longitude: -67.1294021606445,
    altitude: 237,
    schengen: false
  },
  {
    id: 4612,
    name: 'Vieques Airport',
    city: 'Vieques Island',
    country: 'Puerto Rico',
    iata: 'VQS',
    icao: 'TJCG',
    latitude: 18.1158008575,
    longitude: -65.4226989746,
    altitude: 19,
    schengen: false
  },
  {
    id: 4613,
    name: 'Benjamin Rivera Noriega Airport',
    city: 'Culebra Island',
    country: 'Puerto Rico',
    iata: 'CPX',
    icao: 'TJCP',
    latitude: 18.313289,
    longitude: -65.304324,
    altitude: 49,
    schengen: false
  },
  {
    id: 4614,
    name: 'Diego Jimenez Torres Airport',
    city: 'Fajardo',
    country: 'Puerto Rico',
    iata: 'FAJ',
    icao: 'TJFA',
    latitude: 18.3089008331299,
    longitude: -65.6619033813477,
    altitude: 64,
    schengen: false
  },
  {
    id: 4615,
    name: 'Fernando Luis Ribas Dominicci Airport',
    city: 'San Juan',
    country: 'Puerto Rico',
    iata: 'SIG',
    icao: 'TJIG',
    latitude: 18.4568004608154,
    longitude: -66.0980987548828,
    altitude: 10,
    schengen: false
  },
  {
    id: 4616,
    name: 'Eugenio Maria De Hostos Airport',
    city: 'Mayaguez',
    country: 'Puerto Rico',
    iata: 'MAZ',
    icao: 'TJMZ',
    latitude: 18.2556991577148,
    longitude: -67.1484985351563,
    altitude: 28,
    schengen: false
  },
  {
    id: 4617,
    name: 'Mercedita Airport',
    city: 'Ponce',
    country: 'Puerto Rico',
    iata: 'PSE',
    icao: 'TJPS',
    latitude: 18.00830078125,
    longitude: -66.5630035400391,
    altitude: 29,
    schengen: false
  },
  {
    id: 4618,
    name: 'José Aponte de la Torre Airport',
    city: 'Ceiba',
    country: 'Puerto Rico',
    iata: 'NRR',
    icao: 'TJRV',
    latitude: 18.245300293,
    longitude: -65.6434020996,
    altitude: 38,
    schengen: false
  },
  {
    id: 4619,
    name: 'Luis Munoz Marin International Airport',
    city: 'San Juan',
    country: 'Puerto Rico',
    iata: 'SJU',
    icao: 'TJSJ',
    latitude: 18.4393997192,
    longitude: -66.0018005371,
    altitude: 9,
    schengen: false
  },
  {
    id: 4620,
    name: 'Doha International Airport',
    city: 'Doha',
    country: 'Qatar',
    iata: '',
    icao: 'OTBD',
    latitude: 25.261101,
    longitude: 51.565102,
    altitude: 35,
    schengen: false
  },
  {
    id: 4621,
    name: 'Al Udeid Air Base',
    city: 'Doha',
    country: 'Qatar',
    iata: 'XJD',
    icao: 'OTBH',
    latitude: 25.1173000336,
    longitude: 51.3149986267,
    altitude: 130,
    schengen: false
  },
  {
    id: 4622,
    name: 'Roland Garros Airport',
    city: 'St.-denis',
    country: 'Reunion',
    iata: 'RUN',
    icao: 'FMEE',
    latitude: -20.8871002197266,
    longitude: 55.5102996826172,
    altitude: 66,
    schengen: false
  },
  {
    id: 4623,
    name: 'Pierrefonds Airport',
    city: 'St.-pierre',
    country: 'Reunion',
    iata: 'ZSE',
    icao: 'FMEP',
    latitude: -21.3208999633789,
    longitude: 55.4249992370606,
    altitude: 59,
    schengen: false
  },
  {
    id: 4624,
    name: 'Samambaia Heliport',
    city: 'Saint Denis',
    country: 'Reunion',
    iata: '',
    icao: 'SDNS',
    latitude: -22.4624996185303,
    longitude: -43.1305541992188,
    altitude: 3167,
    schengen: false
  },
  {
    id: 4625,
    name: 'Arad International Airport',
    city: 'Arad',
    country: 'Romania',
    iata: 'ARW',
    icao: 'LRAR',
    latitude: 46.1766014099121,
    longitude: 21.261999130249,
    altitude: 352,
    schengen: false
  },
  {
    id: 4626,
    name: 'Bacău Airport',
    city: 'Bacau',
    country: 'Romania',
    iata: 'BCM',
    icao: 'LRBC',
    latitude: 46.521900177002,
    longitude: 26.9102993011475,
    altitude: 607,
    schengen: false
  },
  {
    id: 4627,
    name: 'IAR Gimbav Heliport',
    city: 'Brasov',
    country: 'Romania',
    iata: '',
    icao: 'LRBG',
    latitude: 45.686443,
    longitude: 25.529058,
    altitude: 0,
    schengen: false
  },
  {
    id: 4628,
    name: 'Tautii Magheraus Airport',
    city: 'Baia Mare',
    country: 'Romania',
    iata: 'BAY',
    icao: 'LRBM',
    latitude: 47.6584014892578,
    longitude: 23.4699993133545,
    altitude: 605,
    schengen: false
  },
  {
    id: 4629,
    name: 'Băneasa International Airport',
    city: 'Bucharest',
    country: 'Romania',
    iata: 'BBU',
    icao: 'LRBS',
    latitude: 44.5032005310059,
    longitude: 26.1021003723145,
    altitude: 297,
    schengen: false
  },
  {
    id: 4630,
    name: 'Mihail Kogălniceanu International Airport',
    city: 'Constanta',
    country: 'Romania',
    iata: 'CND',
    icao: 'LRCK',
    latitude: 44.3622016906738,
    longitude: 28.4883003234863,
    altitude: 353,
    schengen: false
  },
  {
    id: 4631,
    name: 'Cluj-Napoca International Airport',
    city: 'Cluj-napoca',
    country: 'Romania',
    iata: 'CLJ',
    icao: 'LRCL',
    latitude: 46.7851982116699,
    longitude: 23.6861991882324,
    altitude: 1036,
    schengen: false
  },
  {
    id: 4632,
    name: 'Caransebeş Airport',
    city: 'Caransebes',
    country: 'Romania',
    iata: 'CSB',
    icao: 'LRCS',
    latitude: 45.4199981689453,
    longitude: 22.2532997131348,
    altitude: 866,
    schengen: false
  },
  {
    id: 4633,
    name: 'Câmpia Turzii Air Base',
    city: 'Câmpia Turzii',
    country: 'Romania',
    iata: '',
    icao: 'LRCT',
    latitude: 46.5023002624512,
    longitude: 23.8859004974365,
    altitude: 1083,
    schengen: false
  },
  {
    id: 4634,
    name: 'Craiova Airport',
    city: 'Craiova',
    country: 'Romania',
    iata: 'CRA',
    icao: 'LRCV',
    latitude: 44.3180999755859,
    longitude: 23.888599395752,
    altitude: 626,
    schengen: false
  },
  {
    id: 4635,
    name: 'Iaşi Airport',
    city: 'Iasi',
    country: 'Romania',
    iata: 'IAS',
    icao: 'LRIA',
    latitude: 47.1785011291504,
    longitude: 27.6205997467041,
    altitude: 397,
    schengen: false
  },
  {
    id: 4636,
    name: 'Oradea International Airport',
    city: 'Oradea',
    country: 'Romania',
    iata: 'OMR',
    icao: 'LROD',
    latitude: 47.0252990722656,
    longitude: 21.9025001525879,
    altitude: 465,
    schengen: false
  },
  {
    id: 4637,
    name: 'Henri Coandă International Airport',
    city: 'Bucharest',
    country: 'Romania',
    iata: 'OTP',
    icao: 'LROP',
    latitude: 44.5711111,
    longitude: 26.085,
    altitude: 314,
    schengen: false
  },
  {
    id: 4638,
    name: 'Sibiu International Airport',
    city: 'Sibiu',
    country: 'Romania',
    iata: 'SBZ',
    icao: 'LRSB',
    latitude: 45.7855987548828,
    longitude: 24.0912990570068,
    altitude: 1496,
    schengen: false
  },
  {
    id: 4639,
    name: 'Satu Mare Airport',
    city: 'Satu Mare',
    country: 'Romania',
    iata: 'SUJ',
    icao: 'LRSM',
    latitude: 47.7033004760742,
    longitude: 22.8857002258301,
    altitude: 405,
    schengen: false
  },
  {
    id: 4640,
    name: 'Suceava Stefan cel Mare Airport',
    city: 'Suceava',
    country: 'Romania',
    iata: 'SCV',
    icao: 'LRSV',
    latitude: 47.6875,
    longitude: 26.3540992736816,
    altitude: 1375,
    schengen: false
  },
  {
    id: 4641,
    name: 'Tulcea Airport',
    city: 'Tulcea',
    country: 'Romania',
    iata: 'TCE',
    icao: 'LRTC',
    latitude: 45.0625,
    longitude: 28.7143001556396,
    altitude: 200,
    schengen: false
  },
  {
    id: 4642,
    name: 'Transilvania Târgu Mureş International Airport',
    city: 'Tirgu Mures',
    country: 'Romania',
    iata: 'TGM',
    icao: 'LRTM',
    latitude: 46.467700958252,
    longitude: 24.4125003814697,
    altitude: 963,
    schengen: false
  },
  {
    id: 4643,
    name: 'Timişoara Traian Vuia Airport',
    city: 'Timisoara',
    country: 'Romania',
    iata: 'TSR',
    icao: 'LRTR',
    latitude: 45.8098983764648,
    longitude: 21.3379001617432,
    altitude: 348,
    schengen: false
  },
  {
    id: 4644,
    name: 'Tuzla Romania Airport',
    city: 'Tuzla',
    country: 'Romania',
    iata: '',
    icao: 'LRTZ',
    latitude: 43.9841995239258,
    longitude: 28.6096992492676,
    altitude: 161,
    schengen: false
  },
  {
    id: 4645,
    name: 'Batagay Airport',
    city: 'Batagay',
    country: 'Russia',
    iata: 'BQJ',
    icao: 'UEBB',
    latitude: 67.648002624512,
    longitude: 134.69500732422,
    altitude: 696,
    schengen: false
  },
  {
    id: 4646,
    name: 'Sakkyryr Airport',
    city: 'Batagay-Alyta',
    country: 'Russia',
    iata: 'SUK',
    icao: 'UEBS',
    latitude: 67.792,
    longitude: 130.394,
    altitude: 1686,
    schengen: false
  },
  {
    id: 4647,
    name: 'Ust-Kuyga Airport',
    city: 'Ust-Kuyga',
    country: 'Russia',
    iata: 'UKG',
    icao: 'UEBT',
    latitude: 70.011001586914,
    longitude: 135.64500427246,
    altitude: 315,
    schengen: false
  },
  {
    id: 4648,
    name: 'Talakan Airport',
    city: 'Talakan',
    country: 'Russia',
    iata: 'TLK',
    icao: 'UECT',
    latitude: 59.876389,
    longitude: 111.044444,
    altitude: 1329,
    schengen: false
  },
  {
    id: 4649,
    name: 'Aldan Airport',
    city: 'Aldan',
    country: 'Russia',
    iata: 'ADH',
    icao: 'UEEA',
    latitude: 58.6027984619141,
    longitude: 125.408996582031,
    altitude: 2241,
    schengen: false
  },
  {
    id: 4650,
    name: 'Yakutsk Airport',
    city: 'Yakutsk',
    country: 'Russia',
    iata: 'YKS',
    icao: 'UEEE',
    latitude: 62.0932998657227,
    longitude: 129.77099609375,
    altitude: 325,
    schengen: false
  },
  {
    id: 4651,
    name: 'Chulman Airport',
    city: 'Neryungri',
    country: 'Russia',
    iata: '',
    icao: 'UELL',
    latitude: 56.913898468018,
    longitude: 124.91400146484,
    altitude: 2812,
    schengen: false
  },
  {
    id: 4652,
    name: 'Moma Airport',
    city: 'Honuu',
    country: 'Russia',
    iata: 'MQJ',
    icao: 'UEMA',
    latitude: 66.450859,
    longitude: 143.261551,
    altitude: 656,
    schengen: false
  },
  {
    id: 4653,
    name: 'Magan Airport',
    city: 'Yakutsk',
    country: 'Russia',
    iata: 'GYG',
    icao: 'UEMM',
    latitude: 62.103484,
    longitude: 129.545288,
    altitude: 577,
    schengen: false
  },
  {
    id: 4654,
    name: 'Olyokminsk Airport',
    city: 'Olekminsk',
    country: 'Russia',
    iata: 'OLZ',
    icao: 'UEMO',
    latitude: 60.397499,
    longitude: 120.471001,
    altitude: 656,
    schengen: false
  },
  {
    id: 4655,
    name: 'Ust-Nera Airport',
    city: 'Ust-Nera',
    country: 'Russia',
    iata: 'USR',
    icao: 'UEMT',
    latitude: 64.550003051758,
    longitude: 143.11500549316,
    altitude: 1805,
    schengen: false
  },
  {
    id: 4656,
    name: 'Ust-Maya Airport',
    city: 'Ust-Maya',
    country: 'Russia',
    iata: 'UMS',
    icao: 'UEMU',
    latitude: 60.356998443604,
    longitude: 134.43499755859,
    altitude: 561,
    schengen: false
  },
  {
    id: 4657,
    name: 'Verkhnevilyuisk Airport',
    city: 'Verkhnevilyuysk',
    country: 'Russia',
    iata: 'VHV',
    icao: 'UENI',
    latitude: 63.458057403564,
    longitude: 120.26916503906,
    altitude: 411,
    schengen: false
  },
  {
    id: 4658,
    name: 'Kyzyl-Syr Airport',
    city: 'Kyzyl-Syr',
    country: 'Russia',
    iata: '',
    icao: 'UENK',
    latitude: 63.8849983215332,
    longitude: 122.777000427246,
    altitude: 331,
    schengen: false
  },
  {
    id: 4659,
    name: 'Nyurba Airport',
    city: 'Nyurba',
    country: 'Russia',
    iata: 'NYR',
    icao: 'UENN',
    latitude: 63.294998,
    longitude: 118.336998,
    altitude: 394,
    schengen: false
  },
  {
    id: 4660,
    name: 'Suntar Airport',
    city: 'Suntar',
    country: 'Russia',
    iata: 'SUY',
    icao: 'UENS',
    latitude: 62.185001373291,
    longitude: 117.63500213623,
    altitude: 452,
    schengen: false
  },
  {
    id: 4661,
    name: 'Vilyuisk Airport',
    city: 'Vilyuisk',
    country: 'Russia',
    iata: 'VYI',
    icao: 'UENW',
    latitude: 63.75666809082,
    longitude: 121.69333648682,
    altitude: 361,
    schengen: false
  },
  {
    id: 4662,
    name: 'Aykhal Airport',
    city: 'Aykhal',
    country: 'Russia',
    iata: '',
    icao: 'UERA',
    latitude: 65.959197998047,
    longitude: 111.54650115967,
    altitude: 0,
    schengen: false
  },
  {
    id: 4663,
    name: 'Lensk Airport',
    city: 'Lensk',
    country: 'Russia',
    iata: 'ULK',
    icao: 'UERL',
    latitude: 60.7206001282,
    longitude: 114.825996399,
    altitude: 801,
    schengen: false
  },
  {
    id: 4664,
    name: 'Olenyok Airport',
    city: 'Olenyok',
    country: 'Russia',
    iata: 'ONK',
    icao: 'UERO',
    latitude: 68.514999,
    longitude: 112.480003,
    altitude: 847,
    schengen: false
  },
  {
    id: 4665,
    name: 'Polyarny Airport',
    city: 'Yakutia',
    country: 'Russia',
    iata: 'PYJ',
    icao: 'UERP',
    latitude: 66.4003982544,
    longitude: 112.029998779,
    altitude: 1660,
    schengen: false
  },
  {
    id: 4666,
    name: 'Mirny Airport',
    city: 'Mirnyj',
    country: 'Russia',
    iata: 'MJZ',
    icao: 'UERR',
    latitude: 62.5346984863281,
    longitude: 114.039001464844,
    altitude: 1156,
    schengen: false
  },
  {
    id: 4667,
    name: 'Saskylakh Airport',
    city: 'Saskylakh',
    country: 'Russia',
    iata: 'SYS',
    icao: 'UERS',
    latitude: 71.92790222168,
    longitude: 114.08000183105,
    altitude: 0,
    schengen: false
  },
  {
    id: 4668,
    name: 'Vitim Airport',
    city: 'Vitim',
    country: 'Russia',
    iata: '',
    icao: 'UERT',
    latitude: 59.458,
    longitude: 112.563004,
    altitude: 610,
    schengen: false
  },
  {
    id: 4669,
    name: 'Belaya Gora Airport',
    city: '',
    country: 'Russia',
    iata: 'BGN',
    icao: 'UESG',
    latitude: 68.556602,
    longitude: 146.231506,
    altitude: 118,
    schengen: false
  },
  {
    id: 4670,
    name: 'Srednekolymsk Airport',
    city: 'Srednekolymsk',
    country: 'Russia',
    iata: 'SEK',
    icao: 'UESK',
    latitude: 67.4805,
    longitude: 153.7364,
    altitude: 60,
    schengen: false
  },
  {
    id: 4671,
    name: 'Chokurdakh Airport',
    city: 'Chokurdah',
    country: 'Russia',
    iata: 'CKH',
    icao: 'UESO',
    latitude: 70.6231002807617,
    longitude: 147.901992797852,
    altitude: 151,
    schengen: false
  },
  {
    id: 4672,
    name: 'Cherskiy Airport',
    city: 'Cherskiy',
    country: 'Russia',
    iata: 'CYX',
    icao: 'UESS',
    latitude: 68.7406005859375,
    longitude: 161.337997436523,
    altitude: 20,
    schengen: false
  },
  {
    id: 4673,
    name: 'Tiksi Airport',
    city: 'Tiksi',
    country: 'Russia',
    iata: 'IKS',
    icao: 'UEST',
    latitude: 71.697700500488,
    longitude: 128.90299987793,
    altitude: 26,
    schengen: false
  },
  {
    id: 4674,
    name: 'Zyryanka Airport',
    city: 'Zyryanka',
    country: 'Russia',
    iata: 'ZKP',
    icao: 'UESU',
    latitude: 65.7485,
    longitude: 150.8889,
    altitude: 140,
    schengen: false
  },
  {
    id: 4675,
    name: 'Ignatyevo Airport',
    city: 'Blagoveschensk',
    country: 'Russia',
    iata: 'BQS',
    icao: 'UHBB',
    latitude: 50.4253997802734,
    longitude: 127.412002563477,
    altitude: 638,
    schengen: false
  },
  {
    id: 4676,
    name: 'Khabarovsk-Novy Airport',
    city: 'Khabarovsk',
    country: 'Russia',
    iata: 'KHV',
    icao: 'UHHH',
    latitude: 48.52799987793,
    longitude: 135.18800354004,
    altitude: 244,
    schengen: false
  },
  {
    id: 4677,
    name: 'Khabarovsk Airport',
    city: 'Khabarovsk',
    country: 'Russia',
    iata: '',
    icao: 'UHHT',
    latitude: 48.5250015258789,
    longitude: 135.15299987793,
    altitude: 262,
    schengen: false
  },
  {
    id: 4678,
    name: 'Dzemgi Airport',
    city: 'Komsomolsk-on-Amur',
    country: 'Russia',
    iata: '',
    icao: 'UHKD',
    latitude: 50.605598449707,
    longitude: 137.080993652344,
    altitude: 89,
    schengen: false
  },
  {
    id: 4679,
    name: 'Kamenny Ruchey Naval Air Base',
    city: 'Sovetskaya Gavan',
    country: 'Russia',
    iata: '',
    icao: 'UHKG',
    latitude: 49.2357025146484,
    longitude: 140.193099975586,
    altitude: 659,
    schengen: false
  },
  {
    id: 4680,
    name: 'Komsomolsk-on-Amur Airport',
    city: 'Komsomolsk-on-Amur',
    country: 'Russia',
    iata: 'KXK',
    icao: 'UHKK',
    latitude: 50.4090003967285,
    longitude: 136.934005737305,
    altitude: 92,
    schengen: false
  },
  {
    id: 4681,
    name: 'Maygatka Airport.',
    city: 'Sovetskaya Gavan',
    country: 'Russia',
    iata: 'GVN',
    icao: 'UHKM',
    latitude: 48.9269981384,
    longitude: 140.033996582,
    altitude: 768,
    schengen: false
  },
  {
    id: 4682,
    name: 'Ugolny Airport',
    city: 'Anadyr',
    country: 'Russia',
    iata: 'DYR',
    icao: 'UHMA',
    latitude: 64.734902,
    longitude: 177.740997,
    altitude: 194,
    schengen: false
  },
  {
    id: 4683,
    name: 'Provideniya Bay Airport',
    city: 'Provideniya Bay',
    country: 'Russia',
    iata: 'PVS',
    icao: 'UHMD',
    latitude: 64.3780975341797,
    longitude: -173.24299621582,
    altitude: 72,
    schengen: false
  },
  {
    id: 4684,
    name: 'Omsukchan Airport',
    city: 'Omsukchan',
    country: 'Russia',
    iata: '',
    icao: 'UHMF',
    latitude: 62.4570007324219,
    longitude: 155.744995117188,
    altitude: 1732,
    schengen: false
  },
  {
    id: 4685,
    name: 'Chaybukha Airport',
    city: 'Chaybukha',
    country: 'Russia',
    iata: '',
    icao: 'UHMG',
    latitude: 61.8349990844727,
    longitude: 160.548004150391,
    altitude: 207,
    schengen: false
  },
  {
    id: 4686,
    name: 'Susuman Airport',
    city: 'Susuman',
    country: 'Russia',
    iata: '',
    icao: 'UHMH',
    latitude: 62.7666664123535,
    longitude: 148.146667480469,
    altitude: 2129,
    schengen: false
  },
  {
    id: 4687,
    name: 'Mys Shmidta Airport',
    city: 'Mys Shmidta',
    country: 'Russia',
    iata: '',
    icao: 'UHMI',
    latitude: 68.8683013916016,
    longitude: -179.373001098633,
    altitude: 20,
    schengen: false
  },
  {
    id: 4688,
    name: 'Keperveem Airport',
    city: 'Keperveem',
    country: 'Russia',
    iata: '',
    icao: 'UHMK',
    latitude: 67.8450012207,
    longitude: 166.13999939,
    altitude: 623,
    schengen: false
  },
  {
    id: 4689,
    name: 'Lavrentiya Airport',
    city: 'Lavrentiya',
    country: 'Russia',
    iata: '',
    icao: 'UHML',
    latitude: 65.5800018310547,
    longitude: -170.996673583984,
    altitude: 30,
    schengen: false
  },
  {
    id: 4690,
    name: 'Sokol Airport',
    city: 'Magadan',
    country: 'Russia',
    iata: 'GDX',
    icao: 'UHMM',
    latitude: 59.9109992980957,
    longitude: 150.720001220703,
    altitude: 574,
    schengen: false
  },
  {
    id: 4691,
    name: 'Markovo Airport',
    city: 'Markovo',
    country: 'Russia',
    iata: 'KVM',
    icao: 'UHMO',
    latitude: 64.667,
    longitude: 170.417007,
    altitude: 0,
    schengen: false
  },
  {
    id: 4692,
    name: 'Pevek Airport',
    city: 'Pevek',
    country: 'Russia',
    iata: 'PWE',
    icao: 'UHMP',
    latitude: 69.783302307129,
    longitude: 170.59700012207,
    altitude: 11,
    schengen: false
  },
  {
    id: 4693,
    name: 'Seymchan Airport',
    city: 'Seymchan',
    country: 'Russia',
    iata: '',
    icao: 'UHMS',
    latitude: 62.9207801818848,
    longitude: 152.422775268555,
    altitude: 679,
    schengen: false
  },
  {
    id: 4694,
    name: 'Magadan-13 Airport',
    city: 'Magadan',
    country: 'Russia',
    iata: '',
    icao: 'UHMT',
    latitude: 59.6232986450195,
    longitude: 150.921997070313,
    altitude: 164,
    schengen: false
  },
  {
    id: 4695,
    name: 'Severo-Evensk Airport',
    city: 'Evensk',
    country: 'Russia',
    iata: '',
    icao: 'UHMW',
    latitude: 61.9216651916504,
    longitude: 159.229995727539,
    altitude: 0,
    schengen: false
  },
  {
    id: 4696,
    name: 'Bogorodskoye Airport',
    city: 'Bogorodskoe',
    country: 'Russia',
    iata: 'BQG',
    icao: 'UHNB',
    latitude: 52.38,
    longitude: 140.448,
    altitude: 0,
    schengen: false
  },
  {
    id: 4697,
    name: 'Nikolayevsk-na-Amure Airport',
    city: 'Nikolayevsk-na-Amure Airport',
    country: 'Russia',
    iata: 'NLI',
    icao: 'UHNN',
    latitude: 53.154999,
    longitude: 140.649994,
    altitude: 170,
    schengen: false
  },
  {
    id: 4698,
    name: 'Okhotsk Airport',
    city: 'Okhotsk',
    country: 'Russia',
    iata: 'OHO',
    icao: 'UHOO',
    latitude: 59.4100646972656,
    longitude: 143.056503295898,
    altitude: 0,
    schengen: false
  },
  {
    id: 4699,
    name: 'Ust-Kamchatsk Airport',
    city: 'Ust Kamchatsk',
    country: 'Russia',
    iata: '',
    icao: 'UHPK',
    latitude: 56.2386016845703,
    longitude: 162.688003540039,
    altitude: 200,
    schengen: false
  },
  {
    id: 4700,
    name: 'Palana Airport',
    city: 'Palana',
    country: 'Russia',
    iata: '',
    icao: 'UHPL',
    latitude: 59.0816993713379,
    longitude: 159.888000488281,
    altitude: 112,
    schengen: false
  },
  {
    id: 4701,
    name: 'Kozyrevsk Airport',
    city: 'Kozyrevsk',
    country: 'Russia',
    iata: '',
    icao: 'UHPO',
    latitude: 56.0900001525879,
    longitude: 159.876663208008,
    altitude: 331,
    schengen: false
  },
  {
    id: 4702,
    name: 'Yelizovo Airport',
    city: 'Petropavlovsk',
    country: 'Russia',
    iata: 'PKC',
    icao: 'UHPP',
    latitude: 53.1679000854492,
    longitude: 158.453994750977,
    altitude: 131,
    schengen: false
  },
  {
    id: 4703,
    name: 'Tilichiki Airport',
    city: 'Tilichiki',
    country: 'Russia',
    iata: '',
    icao: 'UHPT',
    latitude: 60.3828010559082,
    longitude: 166.026000976563,
    altitude: 7,
    schengen: false
  },
  {
    id: 4704,
    name: 'Nikolskoye Airport',
    city: 'Nikolskoye',
    country: 'Russia',
    iata: '',
    icao: 'UHPX',
    latitude: 55.1783332824707,
    longitude: 166.04833984375,
    altitude: 49,
    schengen: false
  },
  {
    id: 4705,
    name: 'Shakhtyorsk Airport',
    city: 'Shakhtyorsk',
    country: 'Russia',
    iata: 'EKS',
    icao: 'UHSK',
    latitude: 49.1903,
    longitude: 142.082993,
    altitude: 50,
    schengen: false
  },
  {
    id: 4706,
    name: 'Mendeleyevo Airport',
    city: 'Yuzhno-Kurilsk',
    country: 'Russia',
    iata: 'DEE',
    icao: 'UHSM',
    latitude: 43.9584007263,
    longitude: 145.682998657,
    altitude: 584,
    schengen: false
  },
  {
    id: 4707,
    name: 'Yuzhno-Sakhalinsk Airport',
    city: 'Yuzhno-sakhalinsk',
    country: 'Russia',
    iata: 'UUS',
    icao: 'UHSS',
    latitude: 46.8886985778809,
    longitude: 142.718002319336,
    altitude: 59,
    schengen: false
  },
  {
    id: 4708,
    name: 'Kavalerovo Airport',
    city: 'Kavalerovo',
    country: 'Russia',
    iata: 'KVR',
    icao: 'UHWK',
    latitude: 44.2726,
    longitude: 135.029,
    altitude: 730,
    schengen: false
  },
  {
    id: 4709,
    name: 'Vladivostok International Airport',
    city: 'Vladivostok',
    country: 'Russia',
    iata: 'VVO',
    icao: 'UHWW',
    latitude: 43.398998260498,
    longitude: 132.147994995117,
    altitude: 46,
    schengen: false
  },
  {
    id: 4710,
    name: 'Chita-Kadala Airport',
    city: 'Chita',
    country: 'Russia',
    iata: 'HTA',
    icao: 'UIAA',
    latitude: 52.026299,
    longitude: 113.306,
    altitude: 2272,
    schengen: false
  },
  {
    id: 4711,
    name: 'Krasnokamensk Airport',
    city: 'Krasnokamensk',
    country: 'Russia',
    iata: '',
    icao: 'UIAE',
    latitude: 50.0331077575684,
    longitude: 118.061141967773,
    altitude: 2139,
    schengen: false
  },
  {
    id: 4712,
    name: 'Chara Airport',
    city: 'Chara',
    country: 'Russia',
    iata: '',
    icao: 'UIAR',
    latitude: 56.9133338928223,
    longitude: 118.269996643066,
    altitude: 2201,
    schengen: false
  },
  {
    id: 4713,
    name: 'Bratsk Airport',
    city: 'Bratsk',
    country: 'Russia',
    iata: 'BTK',
    icao: 'UIBB',
    latitude: 56.3706016540527,
    longitude: 101.697998046875,
    altitude: 1610,
    schengen: false
  },
  {
    id: 4714,
    name: 'Ust-Ilimsk Airport',
    city: 'Ust Ilimsk',
    country: 'Russia',
    iata: 'UIK',
    icao: 'UIBS',
    latitude: 58.136100769043,
    longitude: 102.565002441406,
    altitude: 1339,
    schengen: false
  },
  {
    id: 4715,
    name: 'Zheleznogorsk Airport',
    city: 'Zheleznogorsk-Ilimsky',
    country: 'Russia',
    iata: '',
    icao: 'UIBV',
    latitude: 56.4877014160156,
    longitude: 104.106002807617,
    altitude: 1946,
    schengen: false
  },
  {
    id: 4716,
    name: 'Belaya Air Base',
    city: 'Sredniiy',
    country: 'Russia',
    iata: '',
    icao: 'UIIB',
    latitude: 52.9150009155273,
    longitude: 103.574996948242,
    altitude: 1503,
    schengen: false
  },
  {
    id: 4717,
    name: 'Irkutsk Airport',
    city: 'Irkutsk',
    country: 'Russia',
    iata: 'IKT',
    icao: 'UIII',
    latitude: 52.268001556396,
    longitude: 104.38899993896,
    altitude: 1675,
    schengen: false
  },
  {
    id: 4718,
    name: 'Bodaybo Airport',
    city: 'Bodaibo',
    country: 'Russia',
    iata: 'ODO',
    icao: 'UIKB',
    latitude: 57.8661003113,
    longitude: 114.242996216,
    altitude: 919,
    schengen: false
  },
  {
    id: 4719,
    name: 'Yerbogachen Airport',
    city: 'Yerbogachen',
    country: 'Russia',
    iata: 'ERG',
    icao: 'UIKE',
    latitude: 61.2750015259,
    longitude: 108.029998779,
    altitude: 400,
    schengen: false
  },
  {
    id: 4720,
    name: 'Kirensk Airport',
    city: 'Kirensk',
    country: 'Russia',
    iata: 'KCK',
    icao: 'UIKK',
    latitude: 57.773,
    longitude: 108.064,
    altitude: 840,
    schengen: false
  },
  {
    id: 4721,
    name: 'Mama Airport',
    city: 'Mama',
    country: 'Russia',
    iata: '',
    icao: 'UIKM',
    latitude: 58.3133316040039,
    longitude: 112.889999389648,
    altitude: 659,
    schengen: false
  },
  {
    id: 4722,
    name: 'Ust-Kut Airport',
    city: 'Ust-Kut',
    country: 'Russia',
    iata: 'UKX',
    icao: 'UITT',
    latitude: 56.8567008972168,
    longitude: 105.730003356934,
    altitude: 2188,
    schengen: false
  },
  {
    id: 4723,
    name: 'Bagdarin Airport',
    city: 'Bagdarin',
    country: 'Russia',
    iata: '',
    icao: 'UIUB',
    latitude: 54.3692016601563,
    longitude: 113.478996276855,
    altitude: 3084,
    schengen: false
  },
  {
    id: 4724,
    name: 'Nizhneangarsk Airport',
    city: 'Nizhneangarsk',
    country: 'Russia',
    iata: '',
    icao: 'UIUN',
    latitude: 55.8008003234863,
    longitude: 109.595001220703,
    altitude: 1545,
    schengen: false
  },
  {
    id: 4725,
    name: 'Ulan-Ude Airport (Mukhino)',
    city: 'Ulan-ude',
    country: 'Russia',
    iata: 'UUD',
    icao: 'UIUU',
    latitude: 51.8078002929687,
    longitude: 107.438003540039,
    altitude: 1690,
    schengen: false
  },
  {
    id: 4726,
    name: 'Talagi Airport',
    city: 'Arkhangelsk',
    country: 'Russia',
    iata: 'ARH',
    icao: 'ULAA',
    latitude: 64.6003036499023,
    longitude: 40.7167015075684,
    altitude: 62,
    schengen: false
  },
  {
    id: 4727,
    name: 'Mezen Airport',
    city: 'Mezen',
    country: 'Russia',
    iata: '',
    icao: 'ULAE',
    latitude: 65.8783340454102,
    longitude: 44.2150001525879,
    altitude: 46,
    schengen: false
  },
  {
    id: 4728,
    name: 'Vaskovo Airport',
    city: 'Arkhangelsk',
    country: 'Russia',
    iata: '',
    icao: 'ULAH',
    latitude: 64.4417037964,
    longitude: 40.4216995239,
    altitude: 82,
    schengen: false
  },
  {
    id: 4729,
    name: 'Severomorsk-1 Naval Air Base',
    city: 'Severomorsk',
    country: 'Russia',
    iata: '',
    icao: 'ULAK',
    latitude: 69.0316696166992,
    longitude: 33.4183311462402,
    altitude: 239,
    schengen: false
  },
  {
    id: 4730,
    name: 'Leshukonskoye Airport',
    city: 'Arkhangelsk',
    country: 'Russia',
    iata: 'LDG',
    icao: 'ULAL',
    latitude: 64.8960037231,
    longitude: 45.7229995728,
    altitude: 220,
    schengen: false
  },
  {
    id: 4731,
    name: 'Naryan Mar Airport',
    city: 'Naryan-Mar',
    country: 'Russia',
    iata: 'NNM',
    icao: 'ULAM',
    latitude: 67.6399993896484,
    longitude: 53.121898651123,
    altitude: 36,
    schengen: false
  },
  {
    id: 4732,
    name: 'Solovki Airport',
    city: 'Solovetsky Islands',
    country: 'Russia',
    iata: 'CSH',
    icao: 'ULAS',
    latitude: 65.0299987793,
    longitude: 35.7333335876,
    altitude: 60,
    schengen: false
  },
  {
    id: 4733,
    name: 'Cherepovets Airport',
    city: 'Cherepovets',
    country: 'Russia',
    iata: 'CEE',
    icao: 'ULBC',
    latitude: 59.273601532,
    longitude: 38.0158004761,
    altitude: 377,
    schengen: false
  },
  {
    id: 4734,
    name: 'Amderma Airport',
    city: 'Amderma',
    country: 'Russia',
    iata: 'AMV',
    icao: 'ULDD',
    latitude: 69.763298034668,
    longitude: 61.5564002990723,
    altitude: 13,
    schengen: false
  },
  {
    id: 4735,
    name: 'Kotlas Airport',
    city: 'Kotlas',
    country: 'Russia',
    iata: 'KSZ',
    icao: 'ULKK',
    latitude: 61.2358016967773,
    longitude: 46.6974983215332,
    altitude: 184,
    schengen: false
  },
  {
    id: 4736,
    name: 'Pulkovo Airport',
    city: 'St. Petersburg',
    country: 'Russia',
    iata: 'LED',
    icao: 'ULLI',
    latitude: 59.8003005981445,
    longitude: 30.2625007629395,
    altitude: 78,
    schengen: false
  },
  {
    id: 4737,
    name: 'Pushkin Airport',
    city: 'St. Petersburg',
    country: 'Russia',
    iata: '',
    icao: 'ULLP',
    latitude: 59.685001,
    longitude: 30.338301,
    altitude: 230,
    schengen: false
  },
  {
    id: 4738,
    name: 'Siversky Air Base',
    city: 'Siversky',
    country: 'Russia',
    iata: '',
    icao: 'ULLS',
    latitude: 59.3567008972168,
    longitude: 30.0366992950439,
    altitude: 341,
    schengen: false
  },
  {
    id: 4739,
    name: 'Kirovsk-Apatity Airport',
    city: 'Apatity',
    country: 'Russia',
    iata: 'KVK',
    icao: 'ULMK',
    latitude: 67.4633026123047,
    longitude: 33.5882987976074,
    altitude: 515,
    schengen: false
  },
  {
    id: 4740,
    name: 'Murmansk Airport',
    city: 'Murmansk',
    country: 'Russia',
    iata: 'MMK',
    icao: 'ULMM',
    latitude: 68.7817001342773,
    longitude: 32.7508010864258,
    altitude: 266,
    schengen: false
  },
  {
    id: 4741,
    name: 'Staraya Russa Air Base',
    city: 'Staraya Russa',
    country: 'Russia',
    iata: '',
    icao: 'ULNR',
    latitude: 57.961399,
    longitude: 31.384399,
    altitude: 52,
    schengen: false
  },
  {
    id: 4742,
    name: 'Velikiye Luki Airport',
    city: 'Velikiye Luki',
    country: 'Russia',
    iata: 'VLU',
    icao: 'ULOL',
    latitude: 56.3810997009277,
    longitude: 30.6081008911133,
    altitude: 328,
    schengen: false
  },
  {
    id: 4743,
    name: 'Pskov Airport',
    city: 'Pskov',
    country: 'Russia',
    iata: 'PKV',
    icao: 'ULOO',
    latitude: 57.7839012145996,
    longitude: 28.3955993652344,
    altitude: 154,
    schengen: false
  },
  {
    id: 4744,
    name: 'Petrozavodsk Airport',
    city: 'Petrozavodsk',
    country: 'Russia',
    iata: 'PES',
    icao: 'ULPB',
    latitude: 61.8852005004883,
    longitude: 34.1547012329102,
    altitude: 151,
    schengen: false
  },
  {
    id: 4745,
    name: 'Peski Airport',
    city: 'Petrozavodsk',
    country: 'Russia',
    iata: '',
    icao: 'ULPP',
    latitude: 61.8319435119629,
    longitude: 34.2955551147461,
    altitude: 0,
    schengen: false
  },
  {
    id: 4746,
    name: 'Sortavala Airport',
    city: 'Sortavala',
    country: 'Russia',
    iata: '',
    icao: 'ULPW',
    latitude: 61.7360992431641,
    longitude: 30.6735992431641,
    altitude: 0,
    schengen: false
  },
  {
    id: 4747,
    name: 'Vologda Airport',
    city: 'Vologda',
    country: 'Russia',
    iata: 'VGD',
    icao: 'ULWW',
    latitude: 59.2825012207031,
    longitude: 39.9444007873535,
    altitude: 387,
    schengen: false
  },
  {
    id: 4748,
    name: 'Khrabrovo Airport',
    city: 'Kaliningrad',
    country: 'Russia',
    iata: 'KGD',
    icao: 'UMKK',
    latitude: 54.8899993896484,
    longitude: 20.5925998687744,
    altitude: 42,
    schengen: false
  },
  {
    id: 4749,
    name: 'Abakan Airport',
    city: 'Abakan',
    country: 'Russia',
    iata: 'ABA',
    icao: 'UNAA',
    latitude: 53.7400016784668,
    longitude: 91.3850021362305,
    altitude: 831,
    schengen: false
  },
  {
    id: 4750,
    name: 'Shushenskoye Airport',
    city: 'Shushenskoye',
    country: 'Russia',
    iata: '',
    icao: 'UNAU',
    latitude: 53.3849983215332,
    longitude: 92.0449981689453,
    altitude: 0,
    schengen: false
  },
  {
    id: 4751,
    name: 'Barnaul Airport',
    city: 'Barnaul',
    country: 'Russia',
    iata: 'BAX',
    icao: 'UNBB',
    latitude: 53.3638000488281,
    longitude: 83.5384979248047,
    altitude: 837,
    schengen: false
  },
  {
    id: 4752,
    name: 'Gorno-Altaysk Airport',
    city: 'Gorno-Altaysk',
    country: 'Russia',
    iata: 'RGK',
    icao: 'UNBG',
    latitude: 51.9667015076,
    longitude: 85.8332977295,
    altitude: 965,
    schengen: false
  },
  {
    id: 4753,
    name: 'Biysk Airport',
    city: 'Biysk',
    country: 'Russia',
    iata: '',
    icao: 'UNBI',
    latitude: 52.4799995422363,
    longitude: 85.3399963378906,
    altitude: 620,
    schengen: false
  },
  {
    id: 4754,
    name: 'Novosibirsk North Airport',
    city: 'Novosibirsk',
    country: 'Russia',
    iata: '',
    icao: 'UNCC',
    latitude: 55.0917015075684,
    longitude: 82.9067001342773,
    altitude: 558,
    schengen: false
  },
  {
    id: 4755,
    name: 'Kemerovo Airport',
    city: 'Kemorovo',
    country: 'Russia',
    iata: 'KEJ',
    icao: 'UNEE',
    latitude: 55.2700996398926,
    longitude: 86.1072006225586,
    altitude: 863,
    schengen: false
  },
  {
    id: 4756,
    name: 'Baykit Airport',
    city: 'Baykit',
    country: 'Russia',
    iata: '',
    icao: 'UNIB',
    latitude: 61.676700592041,
    longitude: 96.3550033569336,
    altitude: 853,
    schengen: false
  },
  {
    id: 4757,
    name: 'Yeniseysk Airport',
    city: 'Yeniseysk',
    country: 'Russia',
    iata: 'EIE',
    icao: 'UNII',
    latitude: 58.4742012023926,
    longitude: 92.1125030517578,
    altitude: 253,
    schengen: false
  },
  {
    id: 4758,
    name: 'Podkamennaya Tunguska Airport',
    city: 'Bor',
    country: 'Russia',
    iata: 'TGP',
    icao: 'UNIP',
    latitude: 61.589699,
    longitude: 89.994003,
    altitude: 213,
    schengen: false
  },
  {
    id: 4759,
    name: 'Severo-Eniseysk Airport',
    city: 'Severo-Eniseysk',
    country: 'Russia',
    iata: '',
    icao: 'UNIS',
    latitude: 60.3732986450195,
    longitude: 93.0117034912109,
    altitude: 1706,
    schengen: false
  },
  {
    id: 4760,
    name: 'Tura Mountain Airport',
    city: 'Tura',
    country: 'Russia',
    iata: '',
    icao: 'UNIT',
    latitude: 64.333511352539,
    longitude: 100.4328918457,
    altitude: 2044,
    schengen: false
  },
  {
    id: 4761,
    name: 'Vanavara Airport',
    city: 'Vanavara',
    country: 'Russia',
    iata: '',
    icao: 'UNIW',
    latitude: 60.3596992492676,
    longitude: 102.323997497559,
    altitude: 892,
    schengen: false
  },
  {
    id: 4762,
    name: 'Kodinsk Airport',
    city: 'Kodinsk',
    country: 'Russia',
    iata: '',
    icao: 'UNKI',
    latitude: 58.4794006347656,
    longitude: 99.0939025878906,
    altitude: 0,
    schengen: false
  },
  {
    id: 4763,
    name: 'Yemelyanovo Airport',
    city: 'Krasnoyarsk',
    country: 'Russia',
    iata: 'KJA',
    icao: 'UNKL',
    latitude: 56.172901,
    longitude: 92.493301,
    altitude: 942,
    schengen: false
  },
  {
    id: 4764,
    name: 'Cheremshanka Airport',
    city: 'Krasnoyarsk',
    country: 'Russia',
    iata: '',
    icao: 'UNKM',
    latitude: 56.1769981384,
    longitude: 92.5400009155,
    altitude: 0,
    schengen: false
  },
  {
    id: 4765,
    name: 'Sharypovo Airport',
    city: 'Sharypovo',
    country: 'Russia',
    iata: '',
    icao: 'UNKO',
    latitude: 55.454914093,
    longitude: 89.1738815308,
    altitude: 1099,
    schengen: false
  },
  {
    id: 4766,
    name: 'Achinsk Airport',
    city: 'Achinsk',
    country: 'Russia',
    iata: 'ACS',
    icao: 'UNKS',
    latitude: 56.2682991027832,
    longitude: 90.57080078125,
    altitude: 1033,
    schengen: false
  },
  {
    id: 4767,
    name: 'Kyzyl Airport',
    city: 'Kyzyl',
    country: 'Russia',
    iata: 'KYZ',
    icao: 'UNKY',
    latitude: 51.6693992614746,
    longitude: 94.4005966186523,
    altitude: 2123,
    schengen: false
  },
  {
    id: 4768,
    name: 'Kolpashevo Airport',
    city: 'Kolpashevo',
    country: 'Russia',
    iata: '',
    icao: 'UNLL',
    latitude: 58.3252983093262,
    longitude: 82.932502746582,
    altitude: 243,
    schengen: false
  },
  {
    id: 4769,
    name: 'Yeltsovka Airport',
    city: 'Novosibirsk',
    country: 'Russia',
    iata: '',
    icao: 'UNNE',
    latitude: 55.092399597168,
    longitude: 83.0045013427734,
    altitude: 617,
    schengen: false
  },
  {
    id: 4770,
    name: 'Tolmachevo Airport',
    city: 'Novosibirsk',
    country: 'Russia',
    iata: 'OVB',
    icao: 'UNNT',
    latitude: 55.012599945068,
    longitude: 82.650703430176,
    altitude: 365,
    schengen: false
  },
  {
    id: 4771,
    name: 'Omsk Central Airport',
    city: 'Omsk',
    country: 'Russia',
    iata: 'OMS',
    icao: 'UNOO',
    latitude: 54.9669990539551,
    longitude: 73.3105010986328,
    altitude: 311,
    schengen: false
  },
  {
    id: 4772,
    name: 'Omsk Severny Airport',
    city: 'Omsk',
    country: 'Russia',
    iata: '',
    icao: 'UNOS',
    latitude: 54.9749984741,
    longitude: 73.5550003052,
    altitude: 0,
    schengen: false
  },
  {
    id: 4773,
    name: 'Strezhevoy Airport',
    city: 'Strezhevoy',
    country: 'Russia',
    iata: 'SWT',
    icao: 'UNSS',
    latitude: 60.709400177,
    longitude: 77.6600036621,
    altitude: 164,
    schengen: false
  },
  {
    id: 4774,
    name: 'Bogashevo Airport',
    city: 'Tomsk',
    country: 'Russia',
    iata: 'TOF',
    icao: 'UNTT',
    latitude: 56.380298614502,
    longitude: 85.208297729492,
    altitude: 597,
    schengen: false
  },
  {
    id: 4775,
    name: 'Spichenkovo Airport',
    city: 'Novokuznetsk',
    country: 'Russia',
    iata: 'NOZ',
    icao: 'UNWW',
    latitude: 53.811401,
    longitude: 86.877197,
    altitude: 1024,
    schengen: false
  },
  {
    id: 4776,
    name: 'Dikson Airport',
    city: 'Dikson',
    country: 'Russia',
    iata: 'DKS',
    icao: 'UODD',
    latitude: 73.5178070068359,
    longitude: 80.3796691894531,
    altitude: 47,
    schengen: false
  },
  {
    id: 4777,
    name: 'Nagurskoye',
    city: 'Murmansk',
    country: 'Russia',
    iata: '',
    icao: 'UODN',
    latitude: 80.803207,
    longitude: 47.663586,
    altitude: 59,
    schengen: false
  },
  {
    id: 4778,
    name: 'Khatanga Airport',
    city: 'Khatanga',
    country: 'Russia',
    iata: 'HTG',
    icao: 'UOHH',
    latitude: 71.9781036376953,
    longitude: 102.490997314453,
    altitude: 95,
    schengen: false
  },
  {
    id: 4779,
    name: 'Svetlogorsk Airport',
    city: 'Svetlogorsk',
    country: 'Russia',
    iata: '',
    icao: 'UOIG',
    latitude: 66.8399963378906,
    longitude: 88.4033355712891,
    altitude: 394,
    schengen: false
  },
  {
    id: 4780,
    name: 'Igarka Airport',
    city: 'Igarka',
    country: 'Russia',
    iata: 'IAA',
    icao: 'UOII',
    latitude: 67.4372024536133,
    longitude: 86.6219024658203,
    altitude: 82,
    schengen: false
  },
  {
    id: 4781,
    name: 'Norilsk-Alykel Airport',
    city: 'Norilsk',
    country: 'Russia',
    iata: 'NSK',
    icao: 'UOOO',
    latitude: 69.3110961914063,
    longitude: 87.3321990966797,
    altitude: 574,
    schengen: false
  },
  {
    id: 4782,
    name: 'Valek Airport',
    city: 'Valek',
    country: 'Russia',
    iata: '',
    icao: 'UOOW',
    latitude: 69.3969039916992,
    longitude: 88.3536834716797,
    altitude: 108,
    schengen: false
  },
  {
    id: 4783,
    name: 'Turukhansk Airport',
    city: 'Turukhansk',
    country: 'Russia',
    iata: 'THX',
    icao: 'UOTT',
    latitude: 65.797203064,
    longitude: 87.9353027344,
    altitude: 128,
    schengen: false
  },
  {
    id: 4784,
    name: 'Anapa Vityazevo Airport',
    city: 'Anapa',
    country: 'Russia',
    iata: 'AAQ',
    icao: 'URKA',
    latitude: 45.002101898193,
    longitude: 37.347301483154,
    altitude: 174,
    schengen: false
  },
  {
    id: 4785,
    name: 'Yeysk Airport',
    city: 'Eysk',
    country: 'Russia',
    iata: 'EIK',
    icao: 'URKE',
    latitude: 46.68,
    longitude: 38.21,
    altitude: 60,
    schengen: false
  },
  {
    id: 4786,
    name: 'Gelendzhik Airport',
    city: 'Gelendzhik',
    country: 'Russia',
    iata: 'GDZ',
    icao: 'URKG',
    latitude: 44.5820926295,
    longitude: 38.0124807358,
    altitude: 98,
    schengen: false
  },
  {
    id: 4787,
    name: 'Khanskaya Air Base',
    city: 'Maykop',
    country: 'Russia',
    iata: '',
    icao: 'URKH',
    latitude: 44.68,
    longitude: 40.035,
    altitude: 600,
    schengen: false
  },
  {
    id: 4788,
    name: 'Krasnodar Pashkovsky International Airport',
    city: 'Krasnodar',
    country: 'Russia',
    iata: 'KRR',
    icao: 'URKK',
    latitude: 45.034698486328,
    longitude: 39.170501708984,
    altitude: 118,
    schengen: false
  },
  {
    id: 4789,
    name: 'Khankala Air Base',
    city: 'Grozny',
    country: 'Russia',
    iata: '',
    icao: 'URMG',
    latitude: 43.2980995178223,
    longitude: 45.7840995788574,
    altitude: 548,
    schengen: false
  },
  {
    id: 4790,
    name: 'Uytash Airport',
    city: 'Makhachkala',
    country: 'Russia',
    iata: 'MCX',
    icao: 'URML',
    latitude: 42.8167991638184,
    longitude: 47.6523017883301,
    altitude: 12,
    schengen: false
  },
  {
    id: 4791,
    name: 'Mineralnyye Vody Airport',
    city: 'Mineralnye Vody',
    country: 'Russia',
    iata: 'MRV',
    icao: 'URMM',
    latitude: 44.2251014709473,
    longitude: 43.081901550293,
    altitude: 1054,
    schengen: false
  },
  {
    id: 4792,
    name: 'Nalchik Airport',
    city: 'Nalchik',
    country: 'Russia',
    iata: 'NAL',
    icao: 'URMN',
    latitude: 43.5129013061524,
    longitude: 43.6366004943848,
    altitude: 1461,
    schengen: false
  },
  {
    id: 4793,
    name: 'Beslan Airport',
    city: 'Beslan',
    country: 'Russia',
    iata: 'OGZ',
    icao: 'URMO',
    latitude: 43.2051010132,
    longitude: 44.6066017151,
    altitude: 1673,
    schengen: false
  },
  {
    id: 4794,
    name: 'Magas Airport',
    city: 'Magas',
    country: 'Russia',
    iata: 'IGT',
    icao: 'URMS',
    latitude: 43.3222999573,
    longitude: 45.0125999451,
    altitude: 1165,
    schengen: false
  },
  {
    id: 4795,
    name: 'Stavropol Shpakovskoye Airport',
    city: 'Stavropol',
    country: 'Russia',
    iata: 'STW',
    icao: 'URMT',
    latitude: 45.1091995239258,
    longitude: 42.1128005981445,
    altitude: 1486,
    schengen: false
  },
  {
    id: 4796,
    name: 'Dudinka Airport',
    city: 'Dudinka',
    country: 'Russia',
    iata: '',
    icao: 'UROD',
    latitude: 69.375,
    longitude: 86.1566696166992,
    altitude: 82,
    schengen: false
  },
  {
    id: 4797,
    name: 'Platov International Airport',
    city: 'Rostov',
    country: 'Russia',
    iata: 'ROV',
    icao: 'URRP',
    latitude: 47.493888,
    longitude: 39.924722,
    altitude: 213,
    schengen: false
  },
  {
    id: 4798,
    name: 'Taganrog Yuzhny Airport',
    city: 'Taganrog',
    country: 'Russia',
    iata: 'TGK',
    icao: 'URRT',
    latitude: 47.1983333,
    longitude: 38.8491667,
    altitude: 117,
    schengen: false
  },
  {
    id: 4799,
    name: 'Volgodonsk Airport',
    city: '',
    country: 'Russia',
    iata: 'VLK',
    icao: 'URRY',
    latitude: 47.6820983886719,
    longitude: 42.0727996826172,
    altitude: 276,
    schengen: false
  },
  {
    id: 4800,
    name: 'Sochi International Airport',
    city: 'Sochi',
    country: 'Russia',
    iata: 'AER',
    icao: 'URSS',
    latitude: 43.449902,
    longitude: 39.9566,
    altitude: 89,
    schengen: false
  },
  {
    id: 4801,
    name: 'Astrakhan Airport',
    city: 'Astrakhan',
    country: 'Russia',
    iata: 'ASF',
    icao: 'URWA',
    latitude: 46.2832984924,
    longitude: 48.0063018799,
    altitude: -65,
    schengen: false
  },
  {
    id: 4802,
    name: 'Elista Airport',
    city: 'Elista',
    country: 'Russia',
    iata: 'ESL',
    icao: 'URWI',
    latitude: 46.3739013671875,
    longitude: 44.3308982849121,
    altitude: 501,
    schengen: false
  },
  {
    id: 4803,
    name: 'Volgograd International Airport',
    city: 'Volgograd',
    country: 'Russia',
    iata: 'VOG',
    icao: 'URWW',
    latitude: 48.7825012207031,
    longitude: 44.3455009460449,
    altitude: 482,
    schengen: false
  },
  {
    id: 4804,
    name: 'Chelyabinsk Balandino Airport',
    city: 'Chelyabinsk',
    country: 'Russia',
    iata: 'CEK',
    icao: 'USCC',
    latitude: 55.305801,
    longitude: 61.5033,
    altitude: 769,
    schengen: false
  },
  {
    id: 4805,
    name: 'Magnitogorsk International Airport',
    city: 'Magnetiogorsk',
    country: 'Russia',
    iata: 'MQF',
    icao: 'USCM',
    latitude: 53.3931007385254,
    longitude: 58.7556991577148,
    altitude: 1430,
    schengen: false
  },
  {
    id: 4806,
    name: 'Sabetta International Airport',
    city: 'Sabetta',
    country: 'Russia',
    iata: 'SBT',
    icao: 'USDA',
    latitude: 71.219167,
    longitude: 72.052222,
    altitude: 46,
    schengen: false
  },
  {
    id: 4807,
    name: 'Salekhard Airport',
    city: 'Salekhard',
    country: 'Russia',
    iata: 'SLY',
    icao: 'USDD',
    latitude: 66.5907974243164,
    longitude: 66.6110000610352,
    altitude: 218,
    schengen: false
  },
  {
    id: 4808,
    name: 'Tarko-Sale Airport',
    city: 'Tarko-Sale',
    country: 'Russia',
    iata: 'TQL',
    icao: 'USDS',
    latitude: 64.9308013916,
    longitude: 77.8180999756,
    altitude: 82,
    schengen: false
  },
  {
    id: 4809,
    name: 'Berezovo Airport',
    city: 'Berezovo',
    country: 'Russia',
    iata: 'EZV',
    icao: 'USHB',
    latitude: 63.9210014343262,
    longitude: 65.0305023193359,
    altitude: 98,
    schengen: false
  },
  {
    id: 4810,
    name: 'Khanty Mansiysk Airport',
    city: 'Khanty-Mansiysk',
    country: 'Russia',
    iata: 'HMA',
    icao: 'USHH',
    latitude: 61.0284996032715,
    longitude: 69.0860977172852,
    altitude: 76,
    schengen: false
  },
  {
    id: 4811,
    name: 'Nyagan Airport',
    city: 'Nyagan',
    country: 'Russia',
    iata: 'NYA',
    icao: 'USHN',
    latitude: 62.110001,
    longitude: 65.614998,
    altitude: 361,
    schengen: false
  },
  {
    id: 4812,
    name: 'Sovetskiy Airport',
    city: 'Sovetskiy',
    country: 'Russia',
    iata: 'OVS',
    icao: 'USHS',
    latitude: 61.3266220092774,
    longitude: 63.6019134521484,
    altitude: 351,
    schengen: false
  },
  {
    id: 4813,
    name: 'Uray Airport',
    city: 'Uraj',
    country: 'Russia',
    iata: 'URJ',
    icao: 'USHU',
    latitude: 60.1032981872559,
    longitude: 64.8266983032227,
    altitude: 190,
    schengen: false
  },
  {
    id: 4814,
    name: 'Beloyarskiy Airport',
    city: 'Beloyarsky',
    country: 'Russia',
    iata: 'EYK',
    icao: 'USHY',
    latitude: 63.686901,
    longitude: 66.698601,
    altitude: 82,
    schengen: false
  },
  {
    id: 4815,
    name: 'Izhevsk Airport',
    city: 'Izhevsk',
    country: 'Russia',
    iata: 'IJK',
    icao: 'USII',
    latitude: 56.8280982971191,
    longitude: 53.4575004577637,
    altitude: 531,
    schengen: false
  },
  {
    id: 4816,
    name: 'Pobedilovo Airport',
    city: 'Kirov',
    country: 'Russia',
    iata: 'KVX',
    icao: 'USKK',
    latitude: 58.503299713135,
    longitude: 49.348300933838,
    altitude: 479,
    schengen: false
  },
  {
    id: 4817,
    name: 'Nadym Airport',
    city: 'Nadym',
    country: 'Russia',
    iata: 'NYM',
    icao: 'USMM',
    latitude: 65.4809036254883,
    longitude: 72.6988983154297,
    altitude: 49,
    schengen: false
  },
  {
    id: 4818,
    name: 'Novy Urengoy Airport',
    city: 'Novy Urengoy',
    country: 'Russia',
    iata: 'NUX',
    icao: 'USMU',
    latitude: 66.0693969726563,
    longitude: 76.5203018188477,
    altitude: 210,
    schengen: false
  },
  {
    id: 4819,
    name: 'Nizhnevartovsk Airport',
    city: 'Nizhnevartovsk',
    country: 'Russia',
    iata: 'NJC',
    icao: 'USNN',
    latitude: 60.9492988586426,
    longitude: 76.4835968017578,
    altitude: 177,
    schengen: false
  },
  {
    id: 4820,
    name: 'Raduzhny Airport',
    city: 'Raduzhnyi',
    country: 'Russia',
    iata: 'RAT',
    icao: 'USNR',
    latitude: 62.1585998535,
    longitude: 77.3289031982,
    altitude: 250,
    schengen: false
  },
  {
    id: 4821,
    name: 'Bolshoye Savino Airport',
    city: 'Perm',
    country: 'Russia',
    iata: 'PEE',
    icao: 'USPP',
    latitude: 57.914501190186,
    longitude: 56.021198272705,
    altitude: 404,
    schengen: false
  },
  {
    id: 4822,
    name: 'Berezniki Airport',
    city: 'Berezniki',
    country: 'Russia',
    iata: '',
    icao: 'USPT',
    latitude: 59.5795478820801,
    longitude: 56.8576812744141,
    altitude: 207,
    schengen: false
  },
  {
    id: 4823,
    name: 'Kogalym International Airport',
    city: 'Kogalym',
    country: 'Russia',
    iata: 'KGP',
    icao: 'USRK',
    latitude: 62.1903991699219,
    longitude: 74.5337982177734,
    altitude: 220,
    schengen: false
  },
  {
    id: 4824,
    name: 'Nefteyugansk Airport',
    city: 'Nefteyugansk',
    country: 'Russia',
    iata: 'NFG',
    icao: 'USRN',
    latitude: 61.1082992553711,
    longitude: 72.6500015258789,
    altitude: 115,
    schengen: false
  },
  {
    id: 4825,
    name: 'Noyabrsk Airport',
    city: 'Noyabrsk',
    country: 'Russia',
    iata: 'NOJ',
    icao: 'USRO',
    latitude: 63.1833000183106,
    longitude: 75.2699966430664,
    altitude: 446,
    schengen: false
  },
  {
    id: 4826,
    name: 'Surgut Airport',
    city: 'Surgut',
    country: 'Russia',
    iata: 'SGC',
    icao: 'USRR',
    latitude: 61.3437004089356,
    longitude: 73.4018020629883,
    altitude: 200,
    schengen: false
  },
  {
    id: 4827,
    name: 'Uktus Airport',
    city: 'Yekaterinburg',
    country: 'Russia',
    iata: '',
    icao: 'USSK',
    latitude: 56.7016983032227,
    longitude: 60.7900009155273,
    altitude: 643,
    schengen: false
  },
  {
    id: 4828,
    name: 'Koltsovo Airport',
    city: 'Yekaterinburg',
    country: 'Russia',
    iata: 'SVX',
    icao: 'USSS',
    latitude: 56.743099212646,
    longitude: 60.802700042725,
    altitude: 764,
    schengen: false
  },
  {
    id: 4829,
    name: 'Roshchino International Airport',
    city: 'Tyumen',
    country: 'Russia',
    iata: 'TJM',
    icao: 'USTR',
    latitude: 57.1896018982,
    longitude: 65.3243026733,
    altitude: 378,
    schengen: false
  },
  {
    id: 4830,
    name: 'Kurgan Airport',
    city: 'Kurgan',
    country: 'Russia',
    iata: 'KRO',
    icao: 'USUU',
    latitude: 55.4752998352051,
    longitude: 65.4156036376953,
    altitude: 240,
    schengen: false
  },
  {
    id: 4831,
    name: 'Kostroma Sokerkino Airport',
    city: 'Kostroma',
    country: 'Russia',
    iata: 'KMW',
    icao: 'UUBA',
    latitude: 57.7969017029,
    longitude: 41.0194015503,
    altitude: 446,
    schengen: false
  },
  {
    id: 4832,
    name: 'Bykovo Airport',
    city: 'Moscow',
    country: 'Russia',
    iata: 'BKA',
    icao: 'UUBB',
    latitude: 55.6171989441,
    longitude: 38.0600013733,
    altitude: 427,
    schengen: false
  },
  {
    id: 4833,
    name: 'Grabtsevo Airport',
    city: 'Kaluga',
    country: 'Russia',
    iata: 'KLF',
    icao: 'UUBC',
    latitude: 54.5499992371,
    longitude: 36.3666687012,
    altitude: 656,
    schengen: false
  },
  {
    id: 4834,
    name: 'Dyagilevo Air Base',
    city: 'Kostroma',
    country: 'Russia',
    iata: '',
    icao: 'UUBD',
    latitude: 54.6446990966797,
    longitude: 39.5704002380371,
    altitude: 440,
    schengen: false
  },
  {
    id: 4835,
    name: 'Ivanovo South Airport',
    city: 'Ivanovo',
    country: 'Russia',
    iata: 'IWA',
    icao: 'UUBI',
    latitude: 56.9393997192383,
    longitude: 40.9407997131348,
    altitude: 410,
    schengen: false
  },
  {
    id: 4836,
    name: 'Staroselye Airport',
    city: 'Rybinsk',
    country: 'Russia',
    iata: 'RYB',
    icao: 'UUBK',
    latitude: 58.1041984558105,
    longitude: 38.9294013977051,
    altitude: 423,
    schengen: false
  },
  {
    id: 4837,
    name: 'Semyazino Airport',
    city: 'Vladimir',
    country: 'Russia',
    iata: '',
    icao: 'UUBL',
    latitude: 56.1267013549805,
    longitude: 40.314998626709,
    altitude: 554,
    schengen: false
  },
  {
    id: 4838,
    name: 'Myachkovo Airport',
    city: 'Moscow',
    country: 'Russia',
    iata: '',
    icao: 'UUBM',
    latitude: 55.5600013733,
    longitude: 37.9850006104,
    altitude: 410,
    schengen: false
  },
  {
    id: 4839,
    name: 'Bryansk Airport',
    city: 'Bryansk',
    country: 'Russia',
    iata: 'BZK',
    icao: 'UUBP',
    latitude: 53.214199,
    longitude: 34.176399,
    altitude: 663,
    schengen: false
  },
  {
    id: 4840,
    name: 'Zhukovsky International Airport',
    city: 'Ramenskoe',
    country: 'Russia',
    iata: 'ZIA',
    icao: 'UUBW',
    latitude: 55.553299,
    longitude: 38.150002,
    altitude: 377,
    schengen: false
  },
  {
    id: 4841,
    name: 'Domodedovo International Airport',
    city: 'Moscow',
    country: 'Russia',
    iata: 'DME',
    icao: 'UUDD',
    latitude: 55.4087982177734,
    longitude: 37.9062995910645,
    altitude: 588,
    schengen: false
  },
  {
    id: 4842,
    name: 'Tunoshna Airport',
    city: 'Yaroslavl',
    country: 'Russia',
    iata: 'IAR',
    icao: 'UUDL',
    latitude: 57.5606994628906,
    longitude: 40.157398223877,
    altitude: 287,
    schengen: false
  },
  {
    id: 4843,
    name: 'Sheremetyevo International Airport',
    city: 'Moscow',
    country: 'Russia',
    iata: 'SVO',
    icao: 'UUEE',
    latitude: 55.972599,
    longitude: 37.4146,
    altitude: 622,
    schengen: false
  },
  {
    id: 4844,
    name: 'Migalovo Air Base',
    city: 'Tver',
    country: 'Russia',
    iata: 'KLD',
    icao: 'UUEM',
    latitude: 56.8246994018555,
    longitude: 35.7577018737793,
    altitude: 469,
    schengen: false
  },
  {
    id: 4845,
    name: 'Ostafyevo International Airport',
    city: 'Moscow',
    country: 'Russia',
    iata: 'OSF',
    icao: 'UUMO',
    latitude: 55.511667,
    longitude: 37.507222,
    altitude: 568,
    schengen: false
  },
  {
    id: 4846,
    name: 'Tretyakovo Airport',
    city: 'Lukhovitsy',
    country: 'Russia',
    iata: '',
    icao: 'UUMT',
    latitude: 54.9049987793,
    longitude: 39.0266685486,
    altitude: 515,
    schengen: false
  },
  {
    id: 4847,
    name: 'Chkalovskiy Air Base',
    city: 'Shchyolkovo',
    country: 'Russia',
    iata: 'CKL',
    icao: 'UUMU',
    latitude: 55.8783,
    longitude: 38.061699,
    altitude: 499,
    schengen: false
  },
  {
    id: 4848,
    name: 'Belgorod International Airport',
    city: 'Belgorod',
    country: 'Russia',
    iata: 'EGO',
    icao: 'UUOB',
    latitude: 50.643798828125,
    longitude: 36.5900993347168,
    altitude: 735,
    schengen: false
  },
  {
    id: 4849,
    name: 'Kursk East Airport',
    city: 'Kursk',
    country: 'Russia',
    iata: 'URS',
    icao: 'UUOK',
    latitude: 51.7505989074707,
    longitude: 36.2956008911133,
    altitude: 686,
    schengen: false
  },
  {
    id: 4850,
    name: 'Lipetsk Airport',
    city: 'Lipetsk',
    country: 'Russia',
    iata: 'LPK',
    icao: 'UUOL',
    latitude: 52.7028007507324,
    longitude: 39.5377998352051,
    altitude: 584,
    schengen: false
  },
  {
    id: 4851,
    name: 'Voronezh International Airport',
    city: 'Voronezh',
    country: 'Russia',
    iata: 'VOZ',
    icao: 'UUOO',
    latitude: 51.8142013549805,
    longitude: 39.2295989990234,
    altitude: 514,
    schengen: false
  },
  {
    id: 4852,
    name: 'Stary Oskol Airport',
    city: 'Stary Oskol',
    country: 'Russia',
    iata: '',
    icao: 'UUOS',
    latitude: 51.329216003418,
    longitude: 37.7688484191895,
    altitude: 791,
    schengen: false
  },
  {
    id: 4853,
    name: 'Donskoye Airport',
    city: 'Tambow',
    country: 'Russia',
    iata: 'TBW',
    icao: 'UUOT',
    latitude: 52.806098937988,
    longitude: 41.482799530029,
    altitude: 413,
    schengen: false
  },
  {
    id: 4854,
    name: 'Yermolino Airport',
    city: 'Balabanovo',
    country: 'Russia',
    iata: '',
    icao: 'UUWE',
    latitude: 55.2283325195,
    longitude: 36.6083335876,
    altitude: 640,
    schengen: false
  },
  {
    id: 4855,
    name: 'Vnukovo International Airport',
    city: 'Moscow',
    country: 'Russia',
    iata: 'VKO',
    icao: 'UUWW',
    latitude: 55.5914993286,
    longitude: 37.2615013123,
    altitude: 685,
    schengen: false
  },
  {
    id: 4856,
    name: 'Ukhta Airport',
    city: 'Ukhta',
    country: 'Russia',
    iata: 'UCT',
    icao: 'UUYH',
    latitude: 63.5668983459473,
    longitude: 53.8046989440918,
    altitude: 482,
    schengen: false
  },
  {
    id: 4857,
    name: 'Inta Airport',
    city: 'Inta',
    country: 'Russia',
    iata: 'INA',
    icao: 'UUYI',
    latitude: 66.0548324584961,
    longitude: 60.1103210449219,
    altitude: 184,
    schengen: false
  },
  {
    id: 4858,
    name: 'Vuktyl Airport',
    city: 'Vuktyl',
    country: 'Russia',
    iata: '',
    icao: 'UUYK',
    latitude: 63.823299407959,
    longitude: 57.2799987792969,
    altitude: 358,
    schengen: false
  },
  {
    id: 4859,
    name: 'Pechora Airport',
    city: 'Pechora',
    country: 'Russia',
    iata: 'PEX',
    icao: 'UUYP',
    latitude: 65.1211013793945,
    longitude: 57.1307983398438,
    altitude: 98,
    schengen: false
  },
  {
    id: 4860,
    name: 'Usinsk Airport',
    city: 'Usinsk',
    country: 'Russia',
    iata: 'USK',
    icao: 'UUYS',
    latitude: 66.0046997070313,
    longitude: 57.3671989440918,
    altitude: 262,
    schengen: false
  },
  {
    id: 4861,
    name: 'Izhma Airport',
    city: 'Izhma',
    country: 'Russia',
    iata: '',
    icao: 'UUYV',
    latitude: 65.0317001342773,
    longitude: 53.9700012207031,
    altitude: 230,
    schengen: false
  },
  {
    id: 4862,
    name: 'Vorkuta Airport',
    city: 'Vorkuta',
    country: 'Russia',
    iata: 'VKT',
    icao: 'UUYW',
    latitude: 67.4886016845703,
    longitude: 63.9930992126465,
    altitude: 604,
    schengen: false
  },
  {
    id: 4863,
    name: 'Ust-Tsylma Airport',
    city: 'Ust-Tsylma',
    country: 'Russia',
    iata: 'UTS',
    icao: 'UUYX',
    latitude: 65.4372940063,
    longitude: 52.2003364563,
    altitude: 262,
    schengen: false
  },
  {
    id: 4864,
    name: 'Syktyvkar Airport',
    city: 'Syktyvkar',
    country: 'Russia',
    iata: 'SCW',
    icao: 'UUYY',
    latitude: 61.6469993591309,
    longitude: 50.845100402832,
    altitude: 342,
    schengen: false
  },
  {
    id: 4865,
    name: 'Nizhny Novgorod Strigino International Airport',
    city: 'Nizhniy Novgorod',
    country: 'Russia',
    iata: 'GOJ',
    icao: 'UWGG',
    latitude: 56.230098724365,
    longitude: 43.784000396729,
    altitude: 256,
    schengen: false
  },
  {
    id: 4866,
    name: 'Bugulma Airport',
    city: 'Bugulma',
    country: 'Russia',
    iata: 'UUA',
    icao: 'UWKB',
    latitude: 54.6399993896484,
    longitude: 52.801700592041,
    altitude: 991,
    schengen: false
  },
  {
    id: 4867,
    name: 'Kazan International Airport',
    city: 'Kazan',
    country: 'Russia',
    iata: 'KZN',
    icao: 'UWKD',
    latitude: 55.606201171875,
    longitude: 49.278701782227,
    altitude: 411,
    schengen: false
  },
  {
    id: 4868,
    name: 'Begishevo Airport',
    city: 'Nizhnekamsk',
    country: 'Russia',
    iata: 'NBC',
    icao: 'UWKE',
    latitude: 55.5647010803223,
    longitude: 52.0924987792969,
    altitude: 643,
    schengen: false
  },
  {
    id: 4869,
    name: 'Borisoglebskoye Airport',
    city: 'Kazan',
    country: 'Russia',
    iata: '',
    icao: 'UWKG',
    latitude: 55.86669921875,
    longitude: 49.13330078125,
    altitude: 213,
    schengen: false
  },
  {
    id: 4870,
    name: 'Yoshkar-Ola Airport',
    city: 'Yoshkar-Ola',
    country: 'Russia',
    iata: 'JOK',
    icao: 'UWKJ',
    latitude: 56.7005996704102,
    longitude: 47.9047012329102,
    altitude: 348,
    schengen: false
  },
  {
    id: 4871,
    name: 'Cheboksary Airport',
    city: 'Cheboksary',
    country: 'Russia',
    iata: 'CSY',
    icao: 'UWKS',
    latitude: 56.0903015136719,
    longitude: 47.3473014831543,
    altitude: 558,
    schengen: false
  },
  {
    id: 4872,
    name: 'Zhigansk Airport',
    city: 'Zhigansk',
    country: 'Russia',
    iata: 'ZIX',
    icao: 'UWKV',
    latitude: 66.7965011597,
    longitude: 123.361000061,
    altitude: 292,
    schengen: false
  },
  {
    id: 4873,
    name: 'Ulyanovsk Baratayevka Airport',
    city: 'Ulyanovsk',
    country: 'Russia',
    iata: 'ULV',
    icao: 'UWLL',
    latitude: 54.2682991028,
    longitude: 48.2266998291,
    altitude: 463,
    schengen: false
  },
  {
    id: 4874,
    name: 'Ulyanovsk East Airport',
    city: 'Ulyanovsk',
    country: 'Russia',
    iata: 'ULY',
    icao: 'UWLW',
    latitude: 54.4010009765625,
    longitude: 48.8027000427246,
    altitude: 252,
    schengen: false
  },
  {
    id: 4875,
    name: 'Adamovka',
    city: 'Adamovka',
    country: 'Russia',
    iata: '',
    icao: 'UWOD',
    latitude: 51.5,
    longitude: 59.936111,
    altitude: 0,
    schengen: false
  },
  {
    id: 4876,
    name: 'Kvarkeno',
    city: 'Kvarkeno',
    country: 'Russia',
    iata: '',
    icao: 'UWOH',
    latitude: 52.078333,
    longitude: 59.683333,
    altitude: 0,
    schengen: false
  },
  {
    id: 4877,
    name: 'Orenburg Central Airport',
    city: 'Orenburg',
    country: 'Russia',
    iata: 'REN',
    icao: 'UWOO',
    latitude: 51.7957992553711,
    longitude: 55.4566993713379,
    altitude: 387,
    schengen: false
  },
  {
    id: 4878,
    name: 'Orsk Airport',
    city: 'Orsk',
    country: 'Russia',
    iata: 'OSW',
    icao: 'UWOR',
    latitude: 51.0724983215332,
    longitude: 58.5956001281738,
    altitude: 909,
    schengen: false
  },
  {
    id: 4879,
    name: 'Penza Airport',
    city: 'Penza',
    country: 'Russia',
    iata: 'PEZ',
    icao: 'UWPP',
    latitude: 53.1105995178223,
    longitude: 45.0210990905762,
    altitude: 614,
    schengen: false
  },
  {
    id: 4880,
    name: 'Saransk Airport',
    city: 'Saransk',
    country: 'Russia',
    iata: 'SKX',
    icao: 'UWPS',
    latitude: 54.125129699707,
    longitude: 45.2122573852539,
    altitude: 676,
    schengen: false
  },
  {
    id: 4881,
    name: 'Balakovo Airport',
    city: 'Balakovo',
    country: 'Russia',
    iata: 'BWO',
    icao: 'UWSB',
    latitude: 51.8582992554,
    longitude: 47.7456016541,
    altitude: 95,
    schengen: false
  },
  {
    id: 4882,
    name: 'Saratov Central Airport',
    city: 'Saratov',
    country: 'Russia',
    iata: 'RTW',
    icao: 'UWSS',
    latitude: 51.564998626709,
    longitude: 46.0466995239258,
    altitude: 499,
    schengen: false
  },
  {
    id: 4883,
    name: 'Maksimovka Airport',
    city: 'Ufa',
    country: 'Russia',
    iata: '',
    icao: 'UWUM',
    latitude: 54.8300018310547,
    longitude: 56.1683006286621,
    altitude: 325,
    schengen: false
  },
  {
    id: 4884,
    name: 'Ufa International Airport',
    city: 'Ufa',
    country: 'Russia',
    iata: 'UFA',
    icao: 'UWUU',
    latitude: 54.557498931885,
    longitude: 55.874401092529,
    altitude: 449,
    schengen: false
  },
  {
    id: 4885,
    name: 'Buguruslan Severny Airport',
    city: 'Buguruslan',
    country: 'Russia',
    iata: '',
    icao: 'UWWB',
    latitude: 53.7183990479,
    longitude: 52.3718986511,
    altitude: 728,
    schengen: false
  },
  {
    id: 4886,
    name: 'Bezymyanka Airfield',
    city: 'Samara',
    country: 'Russia',
    iata: '',
    icao: 'UWWG',
    latitude: 53.2200012207031,
    longitude: 50.3250007629395,
    altitude: 135,
    schengen: false
  },
  {
    id: 4887,
    name: 'Kurumoch International Airport',
    city: 'Samara',
    country: 'Russia',
    iata: 'KUF',
    icao: 'UWWW',
    latitude: 53.504901885986,
    longitude: 50.16429901123,
    altitude: 477,
    schengen: false
  },
  {
    id: 4888,
    name: 'Soltsy-2 Air Base',
    city: 'Soltsy',
    country: 'Russia',
    iata: '',
    icao: 'XLLL',
    latitude: 58.139545,
    longitude: 30.33042,
    altitude: 266,
    schengen: false
  },
  {
    id: 4889,
    name: 'Kasimovo Airfield',
    city: 'Saint-Petersburg',
    country: 'Russia',
    iata: '',
    icao: 'XLLN',
    latitude: 60.198299407959,
    longitude: 30.3349990844727,
    altitude: 230,
    schengen: false
  },
  {
    id: 4890,
    name: 'Severomorsk-3 Naval Air Base',
    city: 'Severomorsk',
    country: 'Russia',
    iata: '',
    icao: 'XLMV',
    latitude: 68.866669,
    longitude: 33.716667,
    altitude: 564,
    schengen: false
  },
  {
    id: 4891,
    name: 'Fedotovo Naval Air Base',
    city: 'Kipelovo',
    country: 'Russia',
    iata: '',
    icao: 'XLWF',
    latitude: 59.188301,
    longitude: 39.123299,
    altitude: 574,
    schengen: false
  },
  {
    id: 4892,
    name: 'Lebyazhye Air Base',
    city: 'Kamyshin',
    country: 'Russia',
    iata: '',
    icao: 'XRWL',
    latitude: 50.2016983032,
    longitude: 45.2083015442,
    altitude: 381,
    schengen: false
  },
  {
    id: 4893,
    name: 'Smolensk North Airport',
    city: 'Smolensk',
    country: 'Russia',
    iata: 'LNX',
    icao: 'XUBS',
    latitude: 54.824,
    longitude: 32.025,
    altitude: 820,
    schengen: false
  },
  {
    id: 4894,
    name: 'Rtishchevo Air Base',
    city: 'Rtishchevo',
    country: 'Russia',
    iata: '',
    icao: 'XWPR',
    latitude: 52.297317,
    longitude: 43.7241,
    altitude: 673,
    schengen: false
  },
  {
    id: 4895,
    name: 'Cooma Hospital Helipad',
    city: 'Yuzhno-Kurilsk',
    country: 'Russia',
    iata: '',
    icao: 'YXCM',
    latitude: -36.242155,
    longitude: 149.130147,
    altitude: 2656,
    schengen: false
  },
  {
    id: 4896,
    name: 'Gisenyi Airport',
    city: 'Gisenyi',
    country: 'Rwanda',
    iata: 'GYI',
    icao: 'HRYG',
    latitude: -1.67719995975494,
    longitude: 29.2588996887207,
    altitude: 5082,
    schengen: false
  },
  {
    id: 4897,
    name: 'Kigali International Airport',
    city: 'Kigali',
    country: 'Rwanda',
    iata: 'KGL',
    icao: 'HRYR',
    latitude: -1.96862995625,
    longitude: 30.1394996643,
    altitude: 4859,
    schengen: false
  },
  {
    id: 4898,
    name: 'Kamembe Airport',
    city: 'Kamembe',
    country: 'Rwanda',
    iata: 'KME',
    icao: 'HRZA',
    latitude: -2.46223998069763,
    longitude: 28.9078998565674,
    altitude: 5192,
    schengen: false
  },
  {
    id: 4899,
    name: 'RAF Ascension Island',
    city: 'Wide Awake',
    country: 'Saint Helena',
    iata: 'ASI',
    icao: 'FHAW',
    latitude: -7.9696,
    longitude: -14.3937,
    altitude: 278,
    schengen: false
  },
  {
    id: 4900,
    name: 'Robert L. Bradshaw International Airport',
    city: 'Basse Terre',
    country: 'Saint Kitts and Nevis',
    iata: 'SKB',
    icao: 'TKPK',
    latitude: 17.3111991882324,
    longitude: -62.7187004089356,
    altitude: 170,
    schengen: false
  },
  {
    id: 4901,
    name: 'Vance W. Amory International Airport',
    city: 'Charlestown',
    country: 'Saint Kitts and Nevis',
    iata: 'NEV',
    icao: 'TKPN',
    latitude: 17.2056999206543,
    longitude: -62.589900970459,
    altitude: 14,
    schengen: false
  },
  {
    id: 4902,
    name: 'George F. L. Charles Airport',
    city: 'Castries',
    country: 'Saint Lucia',
    iata: 'SLU',
    icao: 'TLPC',
    latitude: 14.0202,
    longitude: -60.992901,
    altitude: 22,
    schengen: false
  },
  {
    id: 4903,
    name: 'Hewanorra International Airport',
    city: 'Hewandorra',
    country: 'Saint Lucia',
    iata: 'UVF',
    icao: 'TLPL',
    latitude: 13.7332,
    longitude: -60.952599,
    altitude: 14,
    schengen: false
  },
  {
    id: 4904,
    name: 'Miquelon Airport',
    city: 'Miquelon',
    country: 'Saint Pierre and Miquelon',
    iata: 'MQC',
    icao: 'LFVM',
    latitude: 47.095500946,
    longitude: -56.3802986145,
    altitude: 10,
    schengen: false
  },
  {
    id: 4905,
    name: 'St Pierre Airport',
    city: 'St.-pierre',
    country: 'Saint Pierre and Miquelon',
    iata: 'FSP',
    icao: 'LFVP',
    latitude: 46.7629013061523,
    longitude: -56.1730995178223,
    altitude: 27,
    schengen: false
  },
  {
    id: 4906,
    name: 'Argyle International Airport',
    city: 'Kingstown',
    country: 'Saint Vincent and the Grenadines',
    iata: 'SVD',
    icao: 'TVSA',
    latitude: 13.156695,
    longitude: -61.149945,
    altitude: 136,
    schengen: false
  },
  {
    id: 4907,
    name: 'J F Mitchell Airport',
    city: 'Bequia',
    country: 'Saint Vincent and the Grenadines',
    iata: 'BQU',
    icao: 'TVSB',
    latitude: 12.9884004593,
    longitude: -61.2620010376,
    altitude: 15,
    schengen: false
  },
  {
    id: 4908,
    name: 'Canouan Airport',
    city: 'Canouan Island',
    country: 'Saint Vincent and the Grenadines',
    iata: 'CIW',
    icao: 'TVSC',
    latitude: 12.699,
    longitude: -61.3424,
    altitude: 11,
    schengen: false
  },
  {
    id: 4909,
    name: 'Mustique Airport',
    city: 'Mustique',
    country: 'Saint Vincent and the Grenadines',
    iata: 'MQS',
    icao: 'TVSM',
    latitude: 12.887900352478,
    longitude: -61.1801986694336,
    altitude: 8,
    schengen: false
  },
  {
    id: 4910,
    name: 'Union Island International Airport',
    city: 'Union Island',
    country: 'Saint Vincent and the Grenadines',
    iata: 'UNI',
    icao: 'TVSU',
    latitude: 12.6001348495483,
    longitude: -61.4119453430176,
    altitude: 16,
    schengen: false
  },
  {
    id: 4911,
    name: 'Faleolo International Airport',
    city: 'Faleolo',
    country: 'Samoa',
    iata: 'APW',
    icao: 'NSFA',
    latitude: -13.8299999237061,
    longitude: -172.007995605469,
    altitude: 58,
    schengen: false
  },
  {
    id: 4912,
    name: "Fagali'i Airport",
    city: 'Apia',
    country: 'Samoa',
    iata: 'FGI',
    icao: 'NSFI',
    latitude: -13.8486995697,
    longitude: -171.740005493,
    altitude: 131,
    schengen: false
  },
  {
    id: 4913,
    name: 'Maota Airport',
    city: 'Savaii Island',
    country: 'Samoa',
    iata: 'MXS',
    icao: 'NSMA',
    latitude: -13.7423000335693,
    longitude: -172.257995605469,
    altitude: 0,
    schengen: false
  },
  {
    id: 4914,
    name: 'Principe Airport',
    city: 'Principe',
    country: 'Sao Tome and Principe',
    iata: 'PCP',
    icao: 'FPPR',
    latitude: 1.66294002532959,
    longitude: 7.41173982620239,
    altitude: 591,
    schengen: false
  },
  {
    id: 4915,
    name: 'São Tomé International Airport',
    city: 'Sao Tome',
    country: 'Sao Tome and Principe',
    iata: 'TMS',
    icao: 'FPST',
    latitude: 0.378174990415573,
    longitude: 6.71215009689331,
    altitude: 33,
    schengen: false
  },
  {
    id: 4916,
    name: 'Abha Regional Airport',
    city: 'Abha',
    country: 'Saudi Arabia',
    iata: 'AHB',
    icao: 'OEAB',
    latitude: 18.2404003143,
    longitude: 42.6566009521,
    altitude: 6858,
    schengen: false
  },
  {
    id: 4917,
    name: 'Al Ahsa Airport',
    city: 'Al-ahsa',
    country: 'Saudi Arabia',
    iata: 'HOF',
    icao: 'OEAH',
    latitude: 25.2852993011475,
    longitude: 49.4851989746094,
    altitude: 588,
    schengen: false
  },
  {
    id: 4918,
    name: 'Al Baha Airport',
    city: 'El-baha',
    country: 'Saudi Arabia',
    iata: 'ABT',
    icao: 'OEBA',
    latitude: 20.2961006165,
    longitude: 41.6343002319,
    altitude: 5486,
    schengen: false
  },
  {
    id: 4919,
    name: 'Bisha Airport',
    city: 'Bisha',
    country: 'Saudi Arabia',
    iata: 'BHH',
    icao: 'OEBH',
    latitude: 19.9843997955322,
    longitude: 42.6208992004395,
    altitude: 3887,
    schengen: false
  },
  {
    id: 4920,
    name: 'Abqaiq Airport',
    city: 'Abqaiq',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OEBQ',
    latitude: 25.9113006591797,
    longitude: 49.5912017822266,
    altitude: 229,
    schengen: false
  },
  {
    id: 4921,
    name: 'King Fahd International Airport',
    city: 'Dammam',
    country: 'Saudi Arabia',
    iata: 'DMM',
    icao: 'OEDF',
    latitude: 26.4712009429932,
    longitude: 49.7979011535645,
    altitude: 72,
    schengen: false
  },
  {
    id: 4922,
    name: 'King Salman Abdulaziz Airport',
    city: '',
    country: 'Saudi Arabia',
    iata: 'DWD',
    icao: 'OEDM',
    latitude: 24.4499,
    longitude: 44.121201,
    altitude: 3026,
    schengen: false
  },
  {
    id: 4923,
    name: 'King Abdulaziz Air Base',
    city: 'Dhahran',
    country: 'Saudi Arabia',
    iata: 'DHA',
    icao: 'OEDR',
    latitude: 26.2653999329,
    longitude: 50.1520004272,
    altitude: 84,
    schengen: false
  },
  {
    id: 4924,
    name: 'Dawadmi Domestic Airport',
    city: 'Dawadmi',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OEDW',
    latitude: 24.5,
    longitude: 44.4000015258789,
    altitude: 3429,
    schengen: false
  },
  {
    id: 4925,
    name: 'Jizan Regional Airport',
    city: 'Gizan',
    country: 'Saudi Arabia',
    iata: 'GIZ',
    icao: 'OEGN',
    latitude: 16.9011001586914,
    longitude: 42.5858001708984,
    altitude: 20,
    schengen: false
  },
  {
    id: 4926,
    name: 'Gassim Airport',
    city: 'Gassim',
    country: 'Saudi Arabia',
    iata: 'ELQ',
    icao: 'OEGS',
    latitude: 26.3027992248535,
    longitude: 43.7743988037109,
    altitude: 2126,
    schengen: false
  },
  {
    id: 4927,
    name: 'Gurayat Domestic Airport',
    city: 'Guriat',
    country: 'Saudi Arabia',
    iata: 'URY',
    icao: 'OEGT',
    latitude: 31.412413,
    longitude: 37.278898,
    altitude: 1672,
    schengen: false
  },
  {
    id: 4928,
    name: "Ha'il Airport",
    city: 'Hail',
    country: 'Saudi Arabia',
    iata: 'HAS',
    icao: 'OEHL',
    latitude: 27.437901,
    longitude: 41.686298,
    altitude: 3331,
    schengen: false
  },
  {
    id: 4929,
    name: 'Jubail Airport',
    city: 'Jubail',
    country: 'Saudi Arabia',
    iata: 'QJB',
    icao: 'OEJB',
    latitude: 27.0389995574951,
    longitude: 49.4051017761231,
    altitude: 26,
    schengen: false
  },
  {
    id: 4930,
    name: 'King Faisal Naval Base',
    city: 'Jeddah',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OEJF',
    latitude: 21.3481006622314,
    longitude: 39.1730003356934,
    altitude: 7,
    schengen: false
  },
  {
    id: 4931,
    name: 'King Abdulaziz International Airport',
    city: 'Jeddah',
    country: 'Saudi Arabia',
    iata: 'JED',
    icao: 'OEJN',
    latitude: 21.6796,
    longitude: 39.156502,
    altitude: 48,
    schengen: false
  },
  {
    id: 4932,
    name: 'King Khaled Military City Airport',
    city: 'King Khalid Mil.city',
    country: 'Saudi Arabia',
    iata: 'KMC',
    icao: 'OEKK',
    latitude: 27.9009,
    longitude: 45.528198,
    altitude: 1352,
    schengen: false
  },
  {
    id: 4933,
    name: 'King Khaled Air Base',
    city: '',
    country: 'Saudi Arabia',
    iata: 'KMX',
    icao: 'OEKM',
    latitude: 18.2973003387451,
    longitude: 42.8035011291504,
    altitude: 6778,
    schengen: false
  },
  {
    id: 4934,
    name: 'Prince Mohammad Bin Abdulaziz Airport',
    city: 'Madinah',
    country: 'Saudi Arabia',
    iata: 'MED',
    icao: 'OEMA',
    latitude: 24.5534,
    longitude: 39.705101,
    altitude: 2151,
    schengen: false
  },
  {
    id: 4935,
    name: 'Nejran Airport',
    city: 'Nejran',
    country: 'Saudi Arabia',
    iata: 'EAM',
    icao: 'OENG',
    latitude: 17.611400604248,
    longitude: 44.4192008972168,
    altitude: 3982,
    schengen: false
  },
  {
    id: 4936,
    name: 'Al Qaisumah/Hafr Al Batin Airport',
    city: 'Hafr Al-batin',
    country: 'Saudi Arabia',
    iata: 'AQI',
    icao: 'OEPA',
    latitude: 28.335199,
    longitude: 46.125099,
    altitude: 1174,
    schengen: false
  },
  {
    id: 4937,
    name: 'Pump Station 3 Airport',
    city: 'Petroline 3',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OEPC',
    latitude: 25.1744995117187,
    longitude: 47.4883995056152,
    altitude: 1740,
    schengen: false
  },
  {
    id: 4938,
    name: 'Pump Station 6 Airport',
    city: 'Petroline 6',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OEPF',
    latitude: 24.7103004455566,
    longitude: 44.9645004272461,
    altitude: 2530,
    schengen: false
  },
  {
    id: 4939,
    name: 'Pump Station 10 Airport',
    city: 'Petroline 10',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OEPJ',
    latitude: 24.1072998046875,
    longitude: 41.0359992980957,
    altitude: 2840,
    schengen: false
  },
  {
    id: 4940,
    name: 'Prince Sultan Air Base',
    city: 'Al Kharj',
    country: 'Saudi Arabia',
    iata: 'AKH',
    icao: 'OEPS',
    latitude: 24.0627002716064,
    longitude: 47.5805015563965,
    altitude: 1651,
    schengen: false
  },
  {
    id: 4941,
    name: 'Rabigh Airport',
    city: 'Rabigh',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OERB',
    latitude: 22.702600479126,
    longitude: 39.0698013305664,
    altitude: 22,
    schengen: false
  },
  {
    id: 4942,
    name: 'Rafha Domestic Airport',
    city: 'Rafha',
    country: 'Saudi Arabia',
    iata: 'RAH',
    icao: 'OERF',
    latitude: 29.6263999938965,
    longitude: 43.4906005859375,
    altitude: 1474,
    schengen: false
  },
  {
    id: 4943,
    name: 'King Khaled International Airport',
    city: 'Riyadh',
    country: 'Saudi Arabia',
    iata: 'RUH',
    icao: 'OERK',
    latitude: 24.9575996398926,
    longitude: 46.6987991333008,
    altitude: 2049,
    schengen: false
  },
  {
    id: 4944,
    name: 'Ras Mishab Airport',
    city: 'Rash Mishab',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OERM',
    latitude: 28.0795993804932,
    longitude: 48.6110000610352,
    altitude: 13,
    schengen: false
  },
  {
    id: 4945,
    name: 'Arar Domestic Airport',
    city: 'Arar',
    country: 'Saudi Arabia',
    iata: 'RAE',
    icao: 'OERR',
    latitude: 30.9066009521484,
    longitude: 41.1381988525391,
    altitude: 1813,
    schengen: false
  },
  {
    id: 4946,
    name: 'Ras Tanura Airport',
    city: 'Ras Tanura',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OERT',
    latitude: 26.7231006622314,
    longitude: 50.0307998657227,
    altitude: 6,
    schengen: false
  },
  {
    id: 4947,
    name: 'Riyadh Air Base',
    city: 'Riyadh',
    country: 'Saudi Arabia',
    iata: 'XXN',
    icao: 'OERY',
    latitude: 24.7098007202148,
    longitude: 46.7252006530762,
    altitude: 2082,
    schengen: false
  },
  {
    id: 4948,
    name: 'Shaibah Airport',
    city: 'Shaybah',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OESB',
    latitude: 22.5144996643066,
    longitude: 53.9640998840332,
    altitude: 300,
    schengen: false
  },
  {
    id: 4949,
    name: 'Sharurah Airport',
    city: 'Sharurah',
    country: 'Saudi Arabia',
    iata: 'SHW',
    icao: 'OESH',
    latitude: 17.4668998718262,
    longitude: 47.1213989257813,
    altitude: 2363,
    schengen: false
  },
  {
    id: 4950,
    name: 'Al-Jawf Domestic Airport',
    city: 'Al-Jawf',
    country: 'Saudi Arabia',
    iata: 'AJF',
    icao: 'OESK',
    latitude: 29.7851009368896,
    longitude: 40.0999984741211,
    altitude: 2261,
    schengen: false
  },
  {
    id: 4951,
    name: 'Sulayel Airport',
    city: 'Sulayel',
    country: 'Saudi Arabia',
    iata: 'SLF',
    icao: 'OESL',
    latitude: 20.4647006988525,
    longitude: 45.6195983886719,
    altitude: 2021,
    schengen: false
  },
  {
    id: 4952,
    name: 'Tabuk Airport',
    city: 'Tabuk',
    country: 'Saudi Arabia',
    iata: 'TUU',
    icao: 'OETB',
    latitude: 28.3654,
    longitude: 36.6189,
    altitude: 2551,
    schengen: false
  },
  {
    id: 4953,
    name: 'Ta’if Regional Airport',
    city: 'Taif',
    country: 'Saudi Arabia',
    iata: 'TIF',
    icao: 'OETF',
    latitude: 21.483001,
    longitude: 40.543442,
    altitude: 4848,
    schengen: false
  },
  {
    id: 4954,
    name: 'Thumamah Airport',
    city: 'Thumamah',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OETH',
    latitude: 25.2129993438721,
    longitude: 46.640998840332,
    altitude: 1870,
    schengen: false
  },
  {
    id: 4955,
    name: 'Ras Tanajib Airport',
    city: 'Ras Tanajib',
    country: 'Saudi Arabia',
    iata: '',
    icao: 'OETN',
    latitude: 27.8677997589111,
    longitude: 48.769100189209,
    altitude: 30,
    schengen: false
  },
  {
    id: 4956,
    name: 'Turaif Domestic Airport',
    city: 'Turaif',
    country: 'Saudi Arabia',
    iata: 'TUI',
    icao: 'OETR',
    latitude: 31.692188,
    longitude: 38.731544,
    altitude: 2803,
    schengen: false
  },
  {
    id: 4957,
    name: 'Wadi Al Dawasir Airport',
    city: 'Wadi-al-dawasir',
    country: 'Saudi Arabia',
    iata: 'WAE',
    icao: 'OEWD',
    latitude: 20.5042991638,
    longitude: 45.1996002197,
    altitude: 2062,
    schengen: false
  },
  {
    id: 4958,
    name: 'Al Wajh Domestic Airport',
    city: 'Wejh',
    country: 'Saudi Arabia',
    iata: 'EJH',
    icao: 'OEWJ',
    latitude: 26.198600769043,
    longitude: 36.4763984680176,
    altitude: 66,
    schengen: false
  },
  {
    id: 4959,
    name: 'Prince Abdulmohsin Bin Abdulaziz Airport',
    city: 'Yenbo',
    country: 'Saudi Arabia',
    iata: 'YNB',
    icao: 'OEYN',
    latitude: 24.144199,
    longitude: 38.0634,
    altitude: 26,
    schengen: false
  },
  {
    id: 4960,
    name: 'Diass-Thies Blaise Diagne International Airport',
    city: 'Diass',
    country: 'Senegal',
    iata: 'DSS',
    icao: 'GOBD',
    latitude: 14.67,
    longitude: -17.073333333,
    altitude: 290,
    schengen: false
  },
  {
    id: 4961,
    name: 'Kolda North Airport',
    city: 'Kolda',
    country: 'Senegal',
    iata: 'KDA',
    icao: 'GODK',
    latitude: 12.8985004425049,
    longitude: -14.9680995941162,
    altitude: 33,
    schengen: false
  },
  {
    id: 4962,
    name: 'Ziguinchor Airport',
    city: 'Ziguinchor',
    country: 'Senegal',
    iata: 'ZIG',
    icao: 'GOGG',
    latitude: 12.5556,
    longitude: -16.281799,
    altitude: 75,
    schengen: false
  },
  {
    id: 4963,
    name: 'Cap Skirring Airport',
    city: 'Cap Skiring',
    country: 'Senegal',
    iata: 'CSK',
    icao: 'GOGS',
    latitude: 12.39533,
    longitude: -16.748,
    altitude: 52,
    schengen: false
  },
  {
    id: 4964,
    name: 'Kaolack Airport',
    city: 'Kaolack',
    country: 'Senegal',
    iata: 'KLC',
    icao: 'GOOK',
    latitude: 14.146900177002,
    longitude: -16.0513000488281,
    altitude: 26,
    schengen: false
  },
  {
    id: 4965,
    name: 'Léopold Sédar Senghor International Airport',
    city: 'Dakar',
    country: 'Senegal',
    iata: 'DKR',
    icao: 'GOOY',
    latitude: 14.7397003173828,
    longitude: -17.4902000427246,
    altitude: 85,
    schengen: false
  },
  {
    id: 4966,
    name: 'Ouro Sogui Airport',
    city: 'Matam',
    country: 'Senegal',
    iata: 'MAX',
    icao: 'GOSM',
    latitude: 15.5936002731323,
    longitude: -13.3227996826172,
    altitude: 85,
    schengen: false
  },
  {
    id: 4967,
    name: 'Saint Louis Airport',
    city: 'St. Louis',
    country: 'Senegal',
    iata: 'XLS',
    icao: 'GOSS',
    latitude: 16.0508003234863,
    longitude: -16.4631996154785,
    altitude: 9,
    schengen: false
  },
  {
    id: 4968,
    name: 'Bakel Airport',
    city: 'Bakel',
    country: 'Senegal',
    iata: 'BXE',
    icao: 'GOTB',
    latitude: 14.8472995758057,
    longitude: -12.4682998657227,
    altitude: 98,
    schengen: false
  },
  {
    id: 4969,
    name: 'Kédougou Airport',
    city: 'Kedougou',
    country: 'Senegal',
    iata: 'KGG',
    icao: 'GOTK',
    latitude: 12.5722999572754,
    longitude: -12.2202997207642,
    altitude: 584,
    schengen: false
  },
  {
    id: 4970,
    name: 'Tambacounda Airport',
    city: 'Tambacounda',
    country: 'Senegal',
    iata: 'TUD',
    icao: 'GOTT',
    latitude: 13.7368001937866,
    longitude: -13.6531000137329,
    altitude: 161,
    schengen: false
  },
  {
    id: 4971,
    name: 'Priština International Airport',
    city: 'Pristina',
    country: 'Serbia',
    iata: 'PRN',
    icao: 'BKPR',
    latitude: 42.5728,
    longitude: 21.035801,
    altitude: 1789,
    schengen: false
  },
  {
    id: 4972,
    name: 'Belgrade Nikola Tesla Airport',
    city: 'Belgrade',
    country: 'Serbia',
    iata: 'BEG',
    icao: 'LYBE',
    latitude: 44.8184013367,
    longitude: 20.3090991974,
    altitude: 335,
    schengen: false
  },
  {
    id: 4973,
    name: 'Batajnica Air Base',
    city: 'Batajnica',
    country: 'Serbia',
    iata: 'BJY',
    icao: 'LYBT',
    latitude: 44.935299,
    longitude: 20.2575,
    altitude: 265,
    schengen: false
  },
  {
    id: 4974,
    name: 'Nis Airport',
    city: 'Nis',
    country: 'Serbia',
    iata: 'INI',
    icao: 'LYNI',
    latitude: 43.337299,
    longitude: 21.853701,
    altitude: 648,
    schengen: false
  },
  {
    id: 4975,
    name: 'Cenej Airport',
    city: 'Novi Sad',
    country: 'Serbia',
    iata: 'QND',
    icao: 'LYNS',
    latitude: 45.385799,
    longitude: 19.839199,
    altitude: 266,
    schengen: false
  },
  {
    id: 4976,
    name: 'Vršac International Airport',
    city: 'Vrsac',
    country: 'Serbia',
    iata: '',
    icao: 'LYVR',
    latitude: 45.1469,
    longitude: 21.3099,
    altitude: 276,
    schengen: false
  },
  {
    id: 4977,
    name: 'Alphonse Airport',
    city: 'Alphonse',
    country: 'Seychelles',
    iata: '',
    icao: 'FSAL',
    latitude: -7.00478,
    longitude: 52.7262,
    altitude: 10,
    schengen: false
  },
  {
    id: 4978,
    name: 'Assumption Island Airport',
    city: 'Assumption Island',
    country: 'Seychelles',
    iata: '',
    icao: 'FSAS',
    latitude: -9.7422199993,
    longitude: 46.506802,
    altitude: 10,
    schengen: false
  },
  {
    id: 4979,
    name: 'Desroches Airport',
    city: 'Desroches',
    country: 'Seychelles',
    iata: 'DES',
    icao: 'FSDR',
    latitude: -5.6967,
    longitude: 53.6558,
    altitude: 10,
    schengen: false
  },
  {
    id: 4980,
    name: 'Farquhar Airport',
    city: 'Farquhar',
    country: 'Seychelles',
    iata: '',
    icao: 'FSFA',
    latitude: -10.1096,
    longitude: 51.176102,
    altitude: 10,
    schengen: false
  },
  {
    id: 4981,
    name: 'Seychelles International Airport',
    city: 'Mahe',
    country: 'Seychelles',
    iata: 'SEZ',
    icao: 'FSIA',
    latitude: -4.67433977127075,
    longitude: 55.521800994873,
    altitude: 10,
    schengen: false
  },
  {
    id: 4982,
    name: 'Praslin Airport',
    city: 'Praslin',
    country: 'Seychelles',
    iata: 'PRI',
    icao: 'FSPP',
    latitude: -4.31929016113281,
    longitude: 55.6913986206055,
    altitude: 10,
    schengen: false
  },
  {
    id: 4983,
    name: 'Bird Island Airport',
    city: 'Bird Island',
    country: 'Seychelles',
    iata: 'BDI',
    icao: 'FSSB',
    latitude: -3.72472,
    longitude: 55.205299,
    altitude: 6,
    schengen: false
  },
  {
    id: 4984,
    name: 'Coetivy Airport',
    city: 'Coetivy',
    country: 'Seychelles',
    iata: '',
    icao: 'FSSC',
    latitude: -7.13457,
    longitude: 56.278198,
    altitude: 10,
    schengen: false
  },
  {
    id: 4985,
    name: 'Sherbro International Airport',
    city: 'Bonthe',
    country: 'Sierra Leone',
    iata: 'BTE',
    icao: 'GFBN',
    latitude: 7.53242015838623,
    longitude: -12.5188999176025,
    altitude: 14,
    schengen: false
  },
  {
    id: 4986,
    name: 'Bo Airport',
    city: 'Bo',
    country: 'Sierra Leone',
    iata: 'KBS',
    icao: 'GFBO',
    latitude: 7.9443998336792,
    longitude: -11.7609996795654,
    altitude: 328,
    schengen: false
  },
  {
    id: 4987,
    name: 'Gbangbatok Airport',
    city: 'Gbangbatok',
    country: 'Sierra Leone',
    iata: 'GBK',
    icao: 'GFGK',
    latitude: 7.76700019836426,
    longitude: -12.3830003738403,
    altitude: 75,
    schengen: false
  },
  {
    id: 4988,
    name: 'Hastings Airport',
    city: 'Freetown',
    country: 'Sierra Leone',
    iata: 'HGS',
    icao: 'GFHA',
    latitude: 8.39713001251221,
    longitude: -13.1290998458862,
    altitude: 60,
    schengen: false
  },
  {
    id: 4989,
    name: 'Kenema Airport',
    city: 'Kenema',
    country: 'Sierra Leone',
    iata: 'KEN',
    icao: 'GFKE',
    latitude: 7.89129018783569,
    longitude: -11.1766004562378,
    altitude: 485,
    schengen: false
  },
  {
    id: 4990,
    name: 'Lungi International Airport',
    city: 'Freetown',
    country: 'Sierra Leone',
    iata: 'FNA',
    icao: 'GFLL',
    latitude: 8.61643981933594,
    longitude: -13.1955003738403,
    altitude: 84,
    schengen: false
  },
  {
    id: 4991,
    name: 'Yengema Airport',
    city: 'Yengema',
    country: 'Sierra Leone',
    iata: 'WYE',
    icao: 'GFYE',
    latitude: 8.61046981811523,
    longitude: -11.0453996658325,
    altitude: 1300,
    schengen: false
  },
  {
    id: 4992,
    name: 'Changi Air Base (East)',
    city: 'Singapore',
    country: 'Singapore',
    iata: '',
    icao: 'WSAC',
    latitude: 1.3441400528,
    longitude: 104.009002686,
    altitude: 22,
    schengen: false
  },
  {
    id: 4993,
    name: 'Sembawang Air Base',
    city: 'Sembawang',
    country: 'Singapore',
    iata: '',
    icao: 'WSAG',
    latitude: 1.42525994777679,
    longitude: 103.813003540039,
    altitude: 86,
    schengen: false
  },
  {
    id: 4994,
    name: 'Paya Lebar Air Base',
    city: 'Paya Lebar',
    country: 'Singapore',
    iata: 'QPG',
    icao: 'WSAP',
    latitude: 1.3604199886322,
    longitude: 103.910003662109,
    altitude: 65,
    schengen: false
  },
  {
    id: 4995,
    name: 'Tengah Air Base',
    city: 'Tengah',
    country: 'Singapore',
    iata: 'TGA',
    icao: 'WSAT',
    latitude: 1.38725996017,
    longitude: 103.708999634,
    altitude: 50,
    schengen: false
  },
  {
    id: 4996,
    name: 'Seletar Airport',
    city: 'Singapore',
    country: 'Singapore',
    iata: 'XSP',
    icao: 'WSSL',
    latitude: 1.4169499874115,
    longitude: 103.86799621582,
    altitude: 36,
    schengen: false
  },
  {
    id: 4997,
    name: 'Singapore Changi Airport',
    city: 'Singapore',
    country: 'Singapore',
    iata: 'SIN',
    icao: 'WSSS',
    latitude: 1.35019,
    longitude: 103.994003,
    altitude: 22,
    schengen: false
  },
  {
    id: 4998,
    name: 'M. R. Štefánik Airport',
    city: 'Bratislava',
    country: 'Slovakia',
    iata: 'BTS',
    icao: 'LZIB',
    latitude: 48.1702003479004,
    longitude: 17.2126998901367,
    altitude: 436,
    schengen: true
  },
  {
    id: 4999,
    name: 'Košice Airport',
    city: 'Kosice',
    country: 'Slovakia',
    iata: 'KSC',
    icao: 'LZKZ',
    latitude: 48.6631011962891,
    longitude: 21.2411003112793,
    altitude: 755,
    schengen: true
  },
  {
    id: 5000,
    name: 'Kuchyňa Air Base',
    city: 'Malacky',
    country: 'Slovakia',
    iata: '',
    icao: 'LZMC',
    latitude: 48.4020004272461,
    longitude: 17.1184005737305,
    altitude: 679,
    schengen: true
  },
  {
    id: 5001,
    name: 'Piešťany Airport',
    city: 'Piestany',
    country: 'Slovakia',
    iata: 'PZY',
    icao: 'LZPP',
    latitude: 48.6251983642578,
    longitude: 17.8283996582031,
    altitude: 545,
    schengen: true
  },
  {
    id: 5002,
    name: 'Sliač Airport',
    city: 'Sliac',
    country: 'Slovakia',
    iata: 'SLD',
    icao: 'LZSL',
    latitude: 48.6377983093262,
    longitude: 19.1340999603271,
    altitude: 1043,
    schengen: true
  },
  {
    id: 5003,
    name: 'Trenčín Airport',
    city: 'Trencin',
    country: 'Slovakia',
    iata: '',
    icao: 'LZTN',
    latitude: 48.8650016785,
    longitude: 17.9923000336,
    altitude: 676,
    schengen: true
  },
  {
    id: 5004,
    name: 'Poprad-Tatry Airport',
    city: 'Poprad',
    country: 'Slovakia',
    iata: 'TAT',
    icao: 'LZTT',
    latitude: 49.073600769,
    longitude: 20.2411003113,
    altitude: 2356,
    schengen: true
  },
  {
    id: 5005,
    name: 'Žilina Airport',
    city: 'Žilina',
    country: 'Slovakia',
    iata: 'ILZ',
    icao: 'LZZI',
    latitude: 49.2314987183,
    longitude: 18.6135005951,
    altitude: 1020,
    schengen: true
  },
  {
    id: 5006,
    name: 'Cerklje Airport',
    city: 'Cerklje',
    country: 'Slovenia',
    iata: '',
    icao: 'LJCE',
    latitude: 45.900001525879,
    longitude: 15.530200004578,
    altitude: 510,
    schengen: true
  },
  {
    id: 5007,
    name: 'Ljubljana Jože Pučnik Airport',
    city: 'Ljubljana',
    country: 'Slovenia',
    iata: 'LJU',
    icao: 'LJLJ',
    latitude: 46.223701,
    longitude: 14.4576,
    altitude: 1273,
    schengen: true
  },
  {
    id: 5008,
    name: 'Maribor Airport',
    city: 'Maribor',
    country: 'Slovenia',
    iata: 'MBX',
    icao: 'LJMB',
    latitude: 46.4799003601074,
    longitude: 15.6861000061035,
    altitude: 876,
    schengen: true
  },
  {
    id: 5009,
    name: 'Portoroz Airport',
    city: 'Portoroz',
    country: 'Slovenia',
    iata: 'POW',
    icao: 'LJPZ',
    latitude: 45.4734001159668,
    longitude: 13.6149997711182,
    altitude: 7,
    schengen: true
  },
  {
    id: 5010,
    name: 'Slovenj Gradec Airport',
    city: 'Slovenj Gradec',
    country: 'Slovenia',
    iata: '',
    icao: 'LJSG',
    latitude: 46.4720001220703,
    longitude: 15.1169996261597,
    altitude: 1645,
    schengen: true
  },
  {
    id: 5011,
    name: 'Afutara Aerodrome',
    city: 'Afutara',
    country: 'Solomon Islands',
    iata: 'AFT',
    icao: 'AGAF',
    latitude: -9.19138888889,
    longitude: 160.948611111,
    altitude: 23,
    schengen: false
  },
  {
    id: 5012,
    name: 'Ulawa Airport',
    city: 'Ulawa',
    country: 'Solomon Islands',
    iata: 'RNA',
    icao: 'AGAR',
    latitude: -9.86054358262,
    longitude: 161.979546547,
    altitude: 40,
    schengen: false
  },
  {
    id: 5013,
    name: 'Uru Harbour Airport',
    city: 'Atoifi',
    country: 'Solomon Islands',
    iata: 'ATD',
    icao: 'AGAT',
    latitude: -8.87333,
    longitude: 161.011002,
    altitude: 0,
    schengen: false
  },
  {
    id: 5014,
    name: "Gwaunaru'u Airport",
    city: 'Auki',
    country: 'Solomon Islands',
    iata: 'AKS',
    icao: 'AGGA',
    latitude: -8.70257,
    longitude: 160.682007,
    altitude: 5,
    schengen: false
  },
  {
    id: 5015,
    name: 'Choiseul Bay Airport',
    city: 'Choiseul Bay',
    country: 'Solomon Islands',
    iata: 'CHY',
    icao: 'AGGC',
    latitude: -6.711944,
    longitude: 156.396111,
    altitude: 0,
    schengen: false
  },
  {
    id: 5016,
    name: 'Ballalae Airport',
    city: 'Ballalae',
    country: 'Solomon Islands',
    iata: 'BAS',
    icao: 'AGGE',
    latitude: -6.990745,
    longitude: 155.886656,
    altitude: 5,
    schengen: false
  },
  {
    id: 5017,
    name: 'Fera/Maringe Airport',
    city: 'Fera Island',
    country: 'Solomon Islands',
    iata: 'FRE',
    icao: 'AGGF',
    latitude: -8.1075,
    longitude: 159.576996,
    altitude: 0,
    schengen: false
  },
  {
    id: 5018,
    name: 'Honiara International Airport',
    city: 'Honiara',
    country: 'Solomon Islands',
    iata: 'HIR',
    icao: 'AGGH',
    latitude: -9.4280004501343,
    longitude: 160.05499267578,
    altitude: 28,
    schengen: false
  },
  {
    id: 5019,
    name: 'Babanakira Airport',
    city: 'Mbambanakira',
    country: 'Solomon Islands',
    iata: 'MBU',
    icao: 'AGGI',
    latitude: -9.7475004196167,
    longitude: 159.839004516602,
    altitude: 0,
    schengen: false
  },
  {
    id: 5020,
    name: 'Ngorangora Airport',
    city: 'Kirakira',
    country: 'Solomon Islands',
    iata: 'IRA',
    icao: 'AGGK',
    latitude: -10.4497003555,
    longitude: 161.897994995,
    altitude: 54,
    schengen: false
  },
  {
    id: 5021,
    name: 'Santa Cruz/Graciosa Bay/Luova Airport',
    city: 'Santa Cruz/Graciosa Bay/Luova',
    country: 'Solomon Islands',
    iata: 'SCZ',
    icao: 'AGGL',
    latitude: -10.7202997207642,
    longitude: 165.794998168945,
    altitude: 18,
    schengen: false
  },
  {
    id: 5022,
    name: 'Munda Airport',
    city: 'Munda',
    country: 'Solomon Islands',
    iata: 'MUA',
    icao: 'AGGM',
    latitude: -8.32796955108643,
    longitude: 157.263000488281,
    altitude: 10,
    schengen: false
  },
  {
    id: 5023,
    name: 'Nusatupe Airport',
    city: 'Gizo',
    country: 'Solomon Islands',
    iata: 'GZO',
    icao: 'AGGN',
    latitude: -8.09778022766,
    longitude: 156.863998413,
    altitude: 13,
    schengen: false
  },
  {
    id: 5024,
    name: 'Mono Airport',
    city: 'Stirling Island',
    country: 'Solomon Islands',
    iata: 'MNY',
    icao: 'AGGO',
    latitude: -7.41694021224976,
    longitude: 155.565002441406,
    altitude: 0,
    schengen: false
  },
  {
    id: 5025,
    name: 'Rennell/Tingoa Airport',
    city: 'Rennell Island',
    country: 'Solomon Islands',
    iata: 'RNL',
    icao: 'AGGR',
    latitude: -11.5339002609253,
    longitude: 160.063003540039,
    altitude: 0,
    schengen: false
  },
  {
    id: 5026,
    name: 'Sege Airport',
    city: 'Sege',
    country: 'Solomon Islands',
    iata: 'EGM',
    icao: 'AGGS',
    latitude: -8.57888984680176,
    longitude: 157.876007080078,
    altitude: 0,
    schengen: false
  },
  {
    id: 5027,
    name: 'Santa Ana Airport',
    city: 'Santa Ana',
    country: 'Solomon Islands',
    iata: 'NNB',
    icao: 'AGGT',
    latitude: -10.847994,
    longitude: 162.454108,
    altitude: 3,
    schengen: false
  },
  {
    id: 5028,
    name: 'Marau Airport',
    city: 'Marau',
    country: 'Solomon Islands',
    iata: 'RUS',
    icao: 'AGGU',
    latitude: -9.86166954041,
    longitude: 160.824996948,
    altitude: 0,
    schengen: false
  },
  {
    id: 5029,
    name: 'Suavanao Airport',
    city: 'Suavanao',
    country: 'Solomon Islands',
    iata: 'VAO',
    icao: 'AGGV',
    latitude: -7.5855598449707,
    longitude: 158.731002807617,
    altitude: 0,
    schengen: false
  },
  {
    id: 5030,
    name: 'Yandina Airport',
    city: 'Yandina',
    country: 'Solomon Islands',
    iata: 'XYA',
    icao: 'AGGY',
    latitude: -9.092816,
    longitude: 159.21841,
    altitude: 60,
    schengen: false
  },
  {
    id: 5031,
    name: 'Kaghau Airport',
    city: 'Kagau Island',
    country: 'Solomon Islands',
    iata: 'KGE',
    icao: 'AGKG',
    latitude: -7.3305,
    longitude: 157.585,
    altitude: 30,
    schengen: false
  },
  {
    id: 5032,
    name: 'Ringi Cove Airport',
    city: 'Ringi Cove',
    country: 'Solomon Islands',
    iata: 'RIN',
    icao: 'AGRC',
    latitude: -8.12639045715332,
    longitude: 157.143005371094,
    altitude: 0,
    schengen: false
  },
  {
    id: 5033,
    name: 'Ramata Airport',
    city: 'Ramata',
    country: 'Solomon Islands',
    iata: 'RBV',
    icao: 'AGRM',
    latitude: -8.16806030273438,
    longitude: 157.643005371094,
    altitude: 0,
    schengen: false
  },
  {
    id: 5034,
    name: 'Alula Airport',
    city: 'Alula',
    country: 'Somalia',
    iata: 'ALU',
    icao: 'HCMA',
    latitude: 11.9582,
    longitude: 50.748,
    altitude: 6,
    schengen: false
  },
  {
    id: 5035,
    name: 'Baidoa Airport',
    city: 'Baidoa',
    country: 'Somalia',
    iata: 'BIB',
    icao: 'HCMB',
    latitude: 3.10222005844116,
    longitude: 43.6286010742188,
    altitude: 1820,
    schengen: false
  },
  {
    id: 5036,
    name: 'Bosaso Airport',
    city: 'Bosaso',
    country: 'Somalia',
    iata: 'BSA',
    icao: 'HCMF',
    latitude: 11.2753000259399,
    longitude: 49.1493988037109,
    altitude: 3,
    schengen: false
  },
  {
    id: 5037,
    name: 'Egal International Airport',
    city: 'Hargeisa',
    country: 'Somalia',
    iata: 'HGA',
    icao: 'HCMH',
    latitude: 9.51817035675049,
    longitude: 44.0887985229492,
    altitude: 4423,
    schengen: false
  },
  {
    id: 5038,
    name: 'Berbera Airport',
    city: 'Berbera',
    country: 'Somalia',
    iata: 'BBO',
    icao: 'HCMI',
    latitude: 10.3892002105713,
    longitude: 44.9411010742188,
    altitude: 30,
    schengen: false
  },
  {
    id: 5039,
    name: 'Kisimayu Airport',
    city: 'Kismayu',
    country: 'Somalia',
    iata: 'KMU',
    icao: 'HCMK',
    latitude: -0.377353012561798,
    longitude: 42.4592018127441,
    altitude: 49,
    schengen: false
  },
  {
    id: 5040,
    name: 'Aden Adde International Airport',
    city: 'Mogadishu',
    country: 'Somalia',
    iata: 'MGQ',
    icao: 'HCMM',
    latitude: 2.01444005966187,
    longitude: 45.3046989440918,
    altitude: 29,
    schengen: false
  },
  {
    id: 5041,
    name: 'Galcaio Airport',
    city: 'Galcaio',
    country: 'Somalia',
    iata: 'GLK',
    icao: 'HCMR',
    latitude: 6.78082990646,
    longitude: 47.45470047,
    altitude: 975,
    schengen: false
  },
  {
    id: 5042,
    name: 'Burao Airport',
    city: 'Burao',
    country: 'Somalia',
    iata: 'BUO',
    icao: 'HCMV',
    latitude: 9.5275,
    longitude: 45.5549,
    altitude: 3400,
    schengen: false
  },
  {
    id: 5043,
    name: 'Alexander Bay Airport',
    city: 'Alexander Bay',
    country: 'South Africa',
    iata: 'ALJ',
    icao: 'FAAB',
    latitude: -28.575001,
    longitude: 16.5333,
    altitude: 98,
    schengen: false
  },
  {
    id: 5044,
    name: 'Aggeneys Airport',
    city: 'Aggeneys',
    country: 'South Africa',
    iata: 'AGZ',
    icao: 'FAAG',
    latitude: -29.2817993164062,
    longitude: 18.8138999938965,
    altitude: 2648,
    schengen: false
  },
  {
    id: 5045,
    name: 'Alldays Airport',
    city: 'Alldays',
    country: 'South Africa',
    iata: 'ADY',
    icao: 'FAAL',
    latitude: -22.6790008545,
    longitude: 29.0555000305,
    altitude: 2600,
    schengen: false
  },
  {
    id: 5046,
    name: 'Aliwal North Airport',
    city: 'Aliwal North',
    country: 'South Africa',
    iata: '',
    icao: 'FAAN',
    latitude: -30.6800003051758,
    longitude: 26.7299995422363,
    altitude: 4405,
    schengen: false
  },
  {
    id: 5047,
    name: 'Brakpan Airport',
    city: 'Brakpan',
    country: 'South Africa',
    iata: '',
    icao: 'FABB',
    latitude: -26.2385997772217,
    longitude: 28.3017997741699,
    altitude: 5300,
    schengen: false
  },
  {
    id: 5048,
    name: 'Bisho Airport',
    city: 'Bisho',
    country: 'South Africa',
    iata: 'BIY',
    icao: 'FABE',
    latitude: -32.8970985413,
    longitude: 27.2791004181,
    altitude: 1950,
    schengen: false
  },
  {
    id: 5049,
    name: 'Bram Fischer International Airport',
    city: 'Bloemfontein',
    country: 'South Africa',
    iata: 'BFN',
    icao: 'FABL',
    latitude: -29.0926990509,
    longitude: 26.302400589,
    altitude: 4458,
    schengen: false
  },
  {
    id: 5050,
    name: 'Bethlehem Airport',
    city: 'Bethlehem',
    country: 'South Africa',
    iata: '',
    icao: 'FABM',
    latitude: -28.2483997345,
    longitude: 28.3360996246,
    altitude: 5561,
    schengen: false
  },
  {
    id: 5051,
    name: 'Hendrik Potgieter Airport',
    city: 'Bothaville',
    country: 'South Africa',
    iata: '',
    icao: 'FABO',
    latitude: -27.3668003082275,
    longitude: 26.6291999816895,
    altitude: 4236,
    schengen: false
  },
  {
    id: 5052,
    name: 'Barberton Airport',
    city: 'Barberton',
    country: 'South Africa',
    iata: '',
    icao: 'FABR',
    latitude: -25.716869,
    longitude: 30.97518,
    altitude: 2250,
    schengen: false
  },
  {
    id: 5053,
    name: 'Brits Airport',
    city: 'Brits',
    country: 'South Africa',
    iata: '',
    icao: 'FABS',
    latitude: -25.5323009490967,
    longitude: 27.775899887085,
    altitude: 3756,
    schengen: false
  },
  {
    id: 5054,
    name: 'Alkantpan Copper Airport',
    city: 'Alkantpan',
    country: 'South Africa',
    iata: '',
    icao: 'FACO',
    latitude: -29.906400680542,
    longitude: 22.3166999816895,
    altitude: 3589,
    schengen: false
  },
  {
    id: 5055,
    name: 'Cape Town International Airport',
    city: 'Cape Town',
    country: 'South Africa',
    iata: 'CPT',
    icao: 'FACT',
    latitude: -33.9648017883,
    longitude: 18.6016998291,
    altitude: 151,
    schengen: false
  },
  {
    id: 5056,
    name: 'Calvinia Airport',
    city: 'Calvinia',
    country: 'South Africa',
    iata: '',
    icao: 'FACV',
    latitude: -31.5002994537353,
    longitude: 19.7259006500244,
    altitude: 3250,
    schengen: false
  },
  {
    id: 5057,
    name: 'Zulu Inyala Airport',
    city: 'Phinda',
    country: 'South Africa',
    iata: 'PZL',
    icao: 'FADQ',
    latitude: -27.8493995666504,
    longitude: 32.309700012207,
    altitude: 160,
    schengen: false
  },
  {
    id: 5058,
    name: 'Ben Schoeman Airport',
    city: 'East London',
    country: 'South Africa',
    iata: 'ELS',
    icao: 'FAEL',
    latitude: -33.0355987549,
    longitude: 27.8258991241,
    altitude: 435,
    schengen: false
  },
  {
    id: 5059,
    name: 'Ermelo Airport',
    city: 'Ermelo',
    country: 'South Africa',
    iata: '',
    icao: 'FAEO',
    latitude: -26.4955997467041,
    longitude: 29.9797992706299,
    altitude: 5700,
    schengen: false
  },
  {
    id: 5060,
    name: 'Ficksburg Sentraoes Airport',
    city: 'Ficksburg',
    country: 'South Africa',
    iata: 'FCB',
    icao: 'FAFB',
    latitude: -28.8230991363525,
    longitude: 27.908899307251,
    altitude: 5315,
    schengen: false
  },
  {
    id: 5061,
    name: 'Grand Central Airport',
    city: 'Johannesburg',
    country: 'South Africa',
    iata: 'GCJ',
    icao: 'FAGC',
    latitude: -25.9862995148,
    longitude: 28.1401004791,
    altitude: 5325,
    schengen: false
  },
  {
    id: 5062,
    name: 'George Airport',
    city: 'George',
    country: 'South Africa',
    iata: 'GRJ',
    icao: 'FAGG',
    latitude: -34.0056,
    longitude: 22.378902,
    altitude: 648,
    schengen: false
  },
  {
    id: 5063,
    name: 'Rand Airport',
    city: 'Johannesburg',
    country: 'South Africa',
    iata: 'QRA',
    icao: 'FAGM',
    latitude: -26.2425003052,
    longitude: 28.1511993408,
    altitude: 5483,
    schengen: false
  },
  {
    id: 5064,
    name: 'Graaff Reinet Airport',
    city: 'Graaff Reinet',
    country: 'South Africa',
    iata: '',
    icao: 'FAGR',
    latitude: -32.1935997009277,
    longitude: 24.5414009094238,
    altitude: 2604,
    schengen: false
  },
  {
    id: 5065,
    name: 'Grahamstown Airport',
    city: 'Grahamstown',
    country: 'South Africa',
    iata: '',
    icao: 'FAGT',
    latitude: -33.2846984863281,
    longitude: 26.4981002807617,
    altitude: 2135,
    schengen: false
  },
  {
    id: 5066,
    name: 'Greytown Airport',
    city: 'Greytown',
    country: 'South Africa',
    iata: '',
    icao: 'FAGY',
    latitude: -29.1219997406,
    longitude: 30.5867004395,
    altitude: 3531,
    schengen: false
  },
  {
    id: 5067,
    name: 'Harmony Airport',
    city: 'Harmony',
    country: 'South Africa',
    iata: '',
    icao: 'FAHA',
    latitude: -28.0786991119385,
    longitude: 26.8612003326416,
    altitude: 4399,
    schengen: false
  },
  {
    id: 5068,
    name: 'Harrismith Airport',
    city: 'Harrismith',
    country: 'South Africa',
    iata: 'HRS',
    icao: 'FAHR',
    latitude: -28.2350997924805,
    longitude: 29.1061992645264,
    altitude: 5585,
    schengen: false
  },
  {
    id: 5069,
    name: 'Hoedspruit Air Force Base Airport',
    city: 'Hoedspruit',
    country: 'South Africa',
    iata: 'HDS',
    icao: 'FAHS',
    latitude: -24.3686008453,
    longitude: 31.0487003326,
    altitude: 1743,
    schengen: false
  },
  {
    id: 5070,
    name: 'Gariep Dam Airport',
    city: 'Hendrik Verwoerddam',
    country: 'South Africa',
    iata: '',
    icao: 'FAHV',
    latitude: -30.5622005462646,
    longitude: 25.528299331665,
    altitude: 4176,
    schengen: false
  },
  {
    id: 5071,
    name: 'P C Pelser Airport',
    city: 'Klerksdorp',
    country: 'South Africa',
    iata: 'KXE',
    icao: 'FAKD',
    latitude: -26.8710994720459,
    longitude: 26.7180004119873,
    altitude: 4444,
    schengen: false
  },
  {
    id: 5072,
    name: 'Kimberley Airport',
    city: 'Kimberley',
    country: 'South Africa',
    iata: 'KIM',
    icao: 'FAKM',
    latitude: -28.8027992249,
    longitude: 24.7651996613,
    altitude: 3950,
    schengen: false
  },
  {
    id: 5073,
    name: 'Kruger Mpumalanga International Airport',
    city: 'Mpumalanga',
    country: 'South Africa',
    iata: 'MQP',
    icao: 'FAKN',
    latitude: -25.3831996918,
    longitude: 31.1056003571,
    altitude: 2829,
    schengen: false
  },
  {
    id: 5074,
    name: 'Krugersdorp Airport',
    city: 'Krugersdorp',
    country: 'South Africa',
    iata: '',
    icao: 'FAKR',
    latitude: -26.0808259199,
    longitude: 27.7259149551,
    altitude: 5499,
    schengen: false
  },
  {
    id: 5075,
    name: 'Kroonstad Airport',
    city: 'Kroonstad',
    country: 'South Africa',
    iata: '',
    icao: 'FAKS',
    latitude: -27.6606006622314,
    longitude: 27.3157997131348,
    altitude: 4700,
    schengen: false
  },
  {
    id: 5076,
    name: 'Johan Pienaar Airport',
    city: 'Kuruman',
    country: 'South Africa',
    iata: 'KMH',
    icao: 'FAKU',
    latitude: -27.4566993713379,
    longitude: 23.4113998413086,
    altitude: 4382,
    schengen: false
  },
  {
    id: 5077,
    name: 'Kleinsee Airport',
    city: 'Kleinsee',
    country: 'South Africa',
    iata: 'KLZ',
    icao: 'FAKZ',
    latitude: -29.6884002686,
    longitude: 17.0939998627,
    altitude: 270,
    schengen: false
  },
  {
    id: 5078,
    name: 'Lanseria Airport',
    city: 'Johannesburg',
    country: 'South Africa',
    iata: 'HLA',
    icao: 'FALA',
    latitude: -25.9384994507,
    longitude: 27.9260997772,
    altitude: 4517,
    schengen: false
  },
  {
    id: 5079,
    name: 'Lime Acres Finsch Mine Airport',
    city: 'Lime Acres',
    country: 'South Africa',
    iata: 'LMR',
    icao: 'FALC',
    latitude: -28.3600997924805,
    longitude: 23.4391002655029,
    altitude: 4900,
    schengen: false
  },
  {
    id: 5080,
    name: 'King Shaka International Airport',
    city: 'Durban',
    country: 'South Africa',
    iata: 'DUR',
    icao: 'FALE',
    latitude: -29.6144444444,
    longitude: 31.1197222222,
    altitude: 295,
    schengen: false
  },
  {
    id: 5081,
    name: 'Lichtenburg Airport',
    city: 'Lichtenburg',
    country: 'South Africa',
    iata: '',
    icao: 'FALI',
    latitude: -26.1756992340088,
    longitude: 26.1846008300781,
    altitude: 4875,
    schengen: false
  },
  {
    id: 5082,
    name: 'Makhado Air Force Base Airport',
    city: 'Lambertsbaai',
    country: 'South Africa',
    iata: '',
    icao: 'FALM',
    latitude: -23.1599006652832,
    longitude: 29.6965007781982,
    altitude: 3069,
    schengen: false
  },
  {
    id: 5083,
    name: 'Langebaanweg Airport',
    city: 'Langebaanweg',
    country: 'South Africa',
    iata: 'SDB',
    icao: 'FALW',
    latitude: -32.9688987732,
    longitude: 18.1602993011,
    altitude: 108,
    schengen: false
  },
  {
    id: 5084,
    name: 'Ladysmith Airport',
    city: 'Ladysmith',
    country: 'South Africa',
    iata: 'LAY',
    icao: 'FALY',
    latitude: -28.5816993713,
    longitude: 29.7497005463,
    altitude: 3548,
    schengen: false
  },
  {
    id: 5085,
    name: 'Middelburg Airport',
    city: 'Middelburg',
    country: 'South Africa',
    iata: '',
    icao: 'FAMB',
    latitude: -25.6847991943359,
    longitude: 29.4402008056641,
    altitude: 4886,
    schengen: false
  },
  {
    id: 5086,
    name: 'Malamala Airport',
    city: 'Malamala',
    country: 'South Africa',
    iata: 'AAM',
    icao: 'FAMD',
    latitude: -24.8180999755859,
    longitude: 31.5445995330811,
    altitude: 1124,
    schengen: false
  },
  {
    id: 5087,
    name: 'Margate Airport',
    city: 'Margate',
    country: 'South Africa',
    iata: 'MGH',
    icao: 'FAMG',
    latitude: -30.8574008942,
    longitude: 30.343000412,
    altitude: 495,
    schengen: false
  },
  {
    id: 5088,
    name: 'Marble Hall Airport',
    city: 'Marble Hall',
    country: 'South Africa',
    iata: '',
    icao: 'FAMI',
    latitude: -24.9890995025635,
    longitude: 29.2831001281738,
    altitude: 2980,
    schengen: false
  },
  {
    id: 5089,
    name: 'Majuba Power Station Airport',
    city: 'Majuba Power Station',
    country: 'South Africa',
    iata: '',
    icao: 'FAMJ',
    latitude: -27.0792999268,
    longitude: 29.7784996033,
    altitude: 5600,
    schengen: false
  },
  {
    id: 5090,
    name: 'Mmabatho International Airport',
    city: 'Mafeking',
    country: 'South Africa',
    iata: 'MBD',
    icao: 'FAMM',
    latitude: -25.7984008789,
    longitude: 25.5480003357,
    altitude: 4181,
    schengen: false
  },
  {
    id: 5091,
    name: 'Riverside Airport',
    city: 'Malalane',
    country: 'South Africa',
    iata: 'LLE',
    icao: 'FAMN',
    latitude: -25.4300003052,
    longitude: 31.5767002106,
    altitude: 1024,
    schengen: false
  },
  {
    id: 5092,
    name: 'Morningside Farm Airport',
    city: 'Musina',
    country: 'South Africa',
    iata: '',
    icao: 'FAMS',
    latitude: -25.7045001984,
    longitude: 26.9090003967,
    altitude: 4251,
    schengen: false
  },
  {
    id: 5093,
    name: 'Mkuze Airport',
    city: 'Mkuze',
    country: 'South Africa',
    iata: 'MZQ',
    icao: 'FAMU',
    latitude: -27.6261005401611,
    longitude: 32.0443000793457,
    altitude: 400,
    schengen: false
  },
  {
    id: 5094,
    name: 'Newcastle Airport',
    city: 'Newcastle',
    country: 'South Africa',
    iata: 'NCS',
    icao: 'FANC',
    latitude: -27.7705993652,
    longitude: 29.9769001007,
    altitude: 4074,
    schengen: false
  },
  {
    id: 5095,
    name: 'Nelspruit Airport',
    city: 'Nelspruit',
    country: 'South Africa',
    iata: 'NLP',
    icao: 'FANS',
    latitude: -25.5,
    longitude: 30.9137992859,
    altitude: 2875,
    schengen: false
  },
  {
    id: 5096,
    name: 'Nylstroom Airfield',
    city: 'Nylstroom',
    country: 'South Africa',
    iata: '',
    icao: 'FANY',
    latitude: -24.6861000061035,
    longitude: 28.4349002838135,
    altitude: 3900,
    schengen: false
  },
  {
    id: 5097,
    name: 'Overberg Airport',
    city: 'Overberg',
    country: 'South Africa',
    iata: 'OVG',
    icao: 'FAOB',
    latitude: -34.554901123,
    longitude: 20.2506999969,
    altitude: 52,
    schengen: false
  },
  {
    id: 5098,
    name: 'Oudtshoorn Airport',
    city: 'Oudtshoorn',
    country: 'South Africa',
    iata: 'OUH',
    icao: 'FAOH',
    latitude: -33.6069984436,
    longitude: 22.188999176,
    altitude: 1063,
    schengen: false
  },
  {
    id: 5099,
    name: 'OR Tambo International Airport',
    city: 'Johannesburg',
    country: 'South Africa',
    iata: 'JNB',
    icao: 'FAOR',
    latitude: -26.1392,
    longitude: 28.246,
    altitude: 5558,
    schengen: false
  },
  {
    id: 5100,
    name: 'Port Elizabeth Airport',
    city: 'Port Elizabeth',
    country: 'South Africa',
    iata: 'PLZ',
    icao: 'FAPE',
    latitude: -33.9849014282,
    longitude: 25.6173000336,
    altitude: 226,
    schengen: false
  },
  {
    id: 5101,
    name: 'Plettenberg Bay Airport',
    city: 'Plettenberg Bay',
    country: 'South Africa',
    iata: 'PBZ',
    icao: 'FAPG',
    latitude: -34.0881601675,
    longitude: 23.3287234306,
    altitude: 465,
    schengen: false
  },
  {
    id: 5102,
    name: 'Hendrik Van Eck Airport',
    city: 'Phalaborwa',
    country: 'South Africa',
    iata: 'PHW',
    icao: 'FAPH',
    latitude: -23.9372005463,
    longitude: 31.1553993225,
    altitude: 1432,
    schengen: false
  },
  {
    id: 5103,
    name: 'Pietersburg Municipal Airport',
    city: 'Polokwane',
    country: 'South Africa',
    iata: '',
    icao: 'FAPI',
    latitude: -23.9260997772,
    longitude: 29.4843997955,
    altitude: 4354,
    schengen: false
  },
  {
    id: 5104,
    name: 'Port St Johns Airport',
    city: 'Port Saint Johns',
    country: 'South Africa',
    iata: 'JOH',
    icao: 'FAPJ',
    latitude: -31.605899810791,
    longitude: 29.5198001861572,
    altitude: 1227,
    schengen: false
  },
  {
    id: 5105,
    name: 'Pietermaritzburg Airport',
    city: 'Pietermaritzburg',
    country: 'South Africa',
    iata: 'PZB',
    icao: 'FAPM',
    latitude: -29.6490001678,
    longitude: 30.3987007141,
    altitude: 2423,
    schengen: false
  },
  {
    id: 5106,
    name: 'Pilanesberg International Airport',
    city: 'Pilanesberg',
    country: 'South Africa',
    iata: 'NTY',
    icao: 'FAPN',
    latitude: -25.3337993622,
    longitude: 27.1734008789,
    altitude: 3412,
    schengen: false
  },
  {
    id: 5107,
    name: 'Polokwane International Airport',
    city: 'Potgietersrus',
    country: 'South Africa',
    iata: 'PTG',
    icao: 'FAPP',
    latitude: -23.845269,
    longitude: 29.458615,
    altitude: 4076,
    schengen: false
  },
  {
    id: 5108,
    name: 'Potchefstroom Airport',
    city: 'Potchefstroom',
    country: 'South Africa',
    iata: 'PCF',
    icao: 'FAPS',
    latitude: -26.670999527,
    longitude: 27.0818996429,
    altitude: 4520,
    schengen: false
  },
  {
    id: 5109,
    name: 'Parys Airport',
    city: 'Parys',
    country: 'South Africa',
    iata: '',
    icao: 'FAPY',
    latitude: -26.8892993927002,
    longitude: 27.5034008026123,
    altitude: 4740,
    schengen: false
  },
  {
    id: 5110,
    name: 'Queenstown Airport',
    city: 'Queenstown',
    country: 'South Africa',
    iata: 'UTW',
    icao: 'FAQT',
    latitude: -31.9202003479004,
    longitude: 26.8822002410889,
    altitude: 3637,
    schengen: false
  },
  {
    id: 5111,
    name: 'Richards Bay Airport',
    city: "Richard's Bay",
    country: 'South Africa',
    iata: 'RCB',
    icao: 'FARB',
    latitude: -28.7409992218,
    longitude: 32.0920982361,
    altitude: 109,
    schengen: false
  },
  {
    id: 5112,
    name: 'Rustenburg Airport',
    city: 'Rustenburg',
    country: 'South Africa',
    iata: '',
    icao: 'FARG',
    latitude: -25.6443004608,
    longitude: 27.2710990906,
    altitude: 3700,
    schengen: false
  },
  {
    id: 5113,
    name: 'Robertson Airport',
    city: 'Robertson',
    country: 'South Africa',
    iata: 'ROD',
    icao: 'FARS',
    latitude: -33.812198638916,
    longitude: 19.9027996063232,
    altitude: 640,
    schengen: false
  },
  {
    id: 5114,
    name: 'Springbok Airport',
    city: 'Springbok',
    country: 'South Africa',
    iata: 'SBU',
    icao: 'FASB',
    latitude: -29.6893005371094,
    longitude: 17.9395999908447,
    altitude: 2690,
    schengen: false
  },
  {
    id: 5115,
    name: 'Secunda Airport',
    city: 'Secunda',
    country: 'South Africa',
    iata: 'ZEC',
    icao: 'FASC',
    latitude: -26.52409935,
    longitude: 29.1700992584,
    altitude: 5250,
    schengen: false
  },
  {
    id: 5116,
    name: 'Saldanha /Vredenburg Airport',
    city: 'Saldanha',
    country: 'South Africa',
    iata: '',
    icao: 'FASD',
    latitude: -32.964099884,
    longitude: 17.9692993164,
    altitude: 50,
    schengen: false
  },
  {
    id: 5117,
    name: 'Springs Airfield',
    city: 'Springs',
    country: 'South Africa',
    iata: '',
    icao: 'FASI',
    latitude: -26.2494004736,
    longitude: 28.3982715607,
    altitude: 5340,
    schengen: false
  },
  {
    id: 5118,
    name: 'Swartkop Air Force Base',
    city: 'Swartkop',
    country: 'South Africa',
    iata: '',
    icao: 'FASK',
    latitude: -25.8097000122,
    longitude: 28.1646003723,
    altitude: 4780,
    schengen: false
  },
  {
    id: 5119,
    name: 'Sishen Airport',
    city: 'Sishen',
    country: 'South Africa',
    iata: 'SIS',
    icao: 'FASS',
    latitude: -27.6485996246,
    longitude: 22.9993000031,
    altitude: 3848,
    schengen: false
  },
  {
    id: 5120,
    name: 'Hendrik Swellengrebel Airport',
    city: 'Swellendam',
    country: 'South Africa',
    iata: '',
    icao: 'FASX',
    latitude: -34.0481986999512,
    longitude: 20.4745998382568,
    altitude: 407,
    schengen: false
  },
  {
    id: 5121,
    name: 'Skukuza Airport',
    city: 'Skukuza',
    country: 'South Africa',
    iata: 'SZK',
    icao: 'FASZ',
    latitude: -24.960899353,
    longitude: 31.5886993408,
    altitude: 1020,
    schengen: false
  },
  {
    id: 5122,
    name: 'Tommys Field Airport',
    city: "Tommy's Field",
    country: 'South Africa',
    iata: '',
    icao: 'FATF',
    latitude: -28.2600002288818,
    longitude: 22.993200302124,
    altitude: 4360,
    schengen: false
  },
  {
    id: 5123,
    name: 'New Tempe Airport',
    city: 'Bloemfontein',
    country: 'South Africa',
    iata: '',
    icao: 'FATP',
    latitude: -29.0328998565674,
    longitude: 26.157600402832,
    altitude: 4547,
    schengen: false
  },
  {
    id: 5124,
    name: 'Tutuka Power Station Airport',
    city: 'Tutuka',
    country: 'South Africa',
    iata: '',
    icao: 'FATT',
    latitude: -26.776599884,
    longitude: 29.3388004303,
    altitude: 5313,
    schengen: false
  },
  {
    id: 5125,
    name: 'Witberg Tswalu Airport',
    city: 'Dedeben',
    country: 'South Africa',
    iata: '',
    icao: 'FATW',
    latitude: -27.2049999237061,
    longitude: 22.4818992614746,
    altitude: 3921,
    schengen: false
  },
  {
    id: 5126,
    name: 'Tzaneen Airport',
    city: 'Tzaneen',
    country: 'South Africa',
    iata: 'LTA',
    icao: 'FATZ',
    latitude: -23.8243999481,
    longitude: 30.3292999268,
    altitude: 1914,
    schengen: false
  },
  {
    id: 5127,
    name: 'Prince Mangosuthu Buthelezi Airport',
    city: 'Ulundi',
    country: 'South Africa',
    iata: 'ULD',
    icao: 'FAUL',
    latitude: -28.3206005096,
    longitude: 31.4165000916,
    altitude: 1720,
    schengen: false
  },
  {
    id: 5128,
    name: 'Pierre Van Ryneveld Airport',
    city: 'Upington',
    country: 'South Africa',
    iata: 'UTN',
    icao: 'FAUP',
    latitude: -28.39909935,
    longitude: 21.2602005005,
    altitude: 2782,
    schengen: false
  },
  {
    id: 5129,
    name: 'K. D. Matanzima Airport',
    city: 'Umtata',
    country: 'South Africa',
    iata: 'UTT',
    icao: 'FAUT',
    latitude: -31.5463631849,
    longitude: 28.6733551025,
    altitude: 2400,
    schengen: false
  },
  {
    id: 5130,
    name: 'Vryburg Airport',
    city: 'Vryburg',
    country: 'South Africa',
    iata: 'VRU',
    icao: 'FAVB',
    latitude: -26.9824008942,
    longitude: 24.7287998199,
    altitude: 3920,
    schengen: false
  },
  {
    id: 5131,
    name: 'Virginia Airport',
    city: 'Durban',
    country: 'South Africa',
    iata: 'VIR',
    icao: 'FAVG',
    latitude: -29.7705993652344,
    longitude: 31.0583992004395,
    altitude: 20,
    schengen: false
  },
  {
    id: 5132,
    name: 'Vredendal Airport',
    city: 'Vredendal',
    country: 'South Africa',
    iata: 'VRE',
    icao: 'FAVR',
    latitude: -31.6410007476807,
    longitude: 18.5447998046875,
    altitude: 330,
    schengen: false
  },
  {
    id: 5133,
    name: 'Vereeniging Airport',
    city: 'Vereeniging',
    country: 'South Africa',
    iata: '',
    icao: 'FAVV',
    latitude: -26.566400528,
    longitude: 27.9608001709,
    altitude: 4846,
    schengen: false
  },
  {
    id: 5134,
    name: 'Wonderboom Airport',
    city: 'Pretoria',
    country: 'South Africa',
    iata: 'PRY',
    icao: 'FAWB',
    latitude: -25.6539,
    longitude: 28.224199,
    altitude: 4095,
    schengen: false
  },
  {
    id: 5135,
    name: 'Witbank Airport',
    city: 'Witbank',
    country: 'South Africa',
    iata: '',
    icao: 'FAWI',
    latitude: -25.8323001862,
    longitude: 29.1919994354,
    altitude: 5078,
    schengen: false
  },
  {
    id: 5136,
    name: 'Waterkloof Air Force Base',
    city: 'Waterkloof',
    country: 'South Africa',
    iata: 'WKF',
    icao: 'FAWK',
    latitude: -25.8299999237,
    longitude: 28.2224998474,
    altitude: 4940,
    schengen: false
  },
  {
    id: 5137,
    name: 'Welkom Airport',
    city: 'Welkom',
    country: 'South Africa',
    iata: '',
    icao: 'FAWM',
    latitude: -27.9968245111,
    longitude: 26.6633338928,
    altitude: 4421,
    schengen: false
  },
  {
    id: 5138,
    name: 'Ysterplaat Air Force Base',
    city: 'Ysterplaat',
    country: 'South Africa',
    iata: '',
    icao: 'FAYP',
    latitude: -33.9001998901367,
    longitude: 18.4983005523682,
    altitude: 52,
    schengen: false
  },
  {
    id: 5139,
    name: 'Zeerust Airport',
    city: 'Zeerust',
    country: 'South Africa',
    iata: '',
    icao: 'FAZR',
    latitude: -25.5990009307861,
    longitude: 26.0422992706299,
    altitude: 4258,
    schengen: false
  },
  {
    id: 5140,
    name: 'N 104 Helipad',
    city: 'Ulleung',
    country: 'South Korea',
    iata: '',
    icao: 'RKDU',
    latitude: 37.4791666667,
    longitude: 130.895555556,
    altitude: 476,
    schengen: false
  },
  {
    id: 5141,
    name: 'Muan International Airport',
    city: 'Muan',
    country: 'South Korea',
    iata: 'MWX',
    icao: 'RKJB',
    latitude: 34.991406,
    longitude: 126.382814,
    altitude: 35,
    schengen: false
  },
  {
    id: 5142,
    name: 'Gwangju Airport',
    city: 'Kwangju',
    country: 'South Korea',
    iata: 'KWJ',
    icao: 'RKJJ',
    latitude: 35.123173,
    longitude: 126.805444,
    altitude: 39,
    schengen: false
  },
  {
    id: 5143,
    name: 'Kunsan Air Base',
    city: 'Kunsan',
    country: 'South Korea',
    iata: 'KUV',
    icao: 'RKJK',
    latitude: 35.9038009643555,
    longitude: 126.615997314453,
    altitude: 29,
    schengen: false
  },
  {
    id: 5144,
    name: 'Mokpo Heliport',
    city: 'Mokpo',
    country: 'South Korea',
    iata: 'MPK',
    icao: 'RKJM',
    latitude: 34.7588996887,
    longitude: 126.379997253,
    altitude: 23,
    schengen: false
  },
  {
    id: 5145,
    name: 'Jeon Ju Airport (G-703)',
    city: 'Jhunju',
    country: 'South Korea',
    iata: 'CHN',
    icao: 'RKJU',
    latitude: 35.87808,
    longitude: 127.119112,
    altitude: 96,
    schengen: false
  },
  {
    id: 5146,
    name: 'Yeosu Airport',
    city: 'Yeosu',
    country: 'South Korea',
    iata: 'RSU',
    icao: 'RKJY',
    latitude: 34.8423004150391,
    longitude: 127.616996765137,
    altitude: 53,
    schengen: false
  },
  {
    id: 5147,
    name: 'Sokcho Airport',
    city: "Sokch'o",
    country: 'South Korea',
    iata: '',
    icao: 'RKND',
    latitude: 38.1426010131836,
    longitude: 128.598999023438,
    altitude: 92,
    schengen: false
  },
  {
    id: 5148,
    name: 'Gangneung Airport (K-18)',
    city: 'Kangnung',
    country: 'South Korea',
    iata: 'KAG',
    icao: 'RKNN',
    latitude: 37.753601,
    longitude: 128.943915,
    altitude: 35,
    schengen: false
  },
  {
    id: 5149,
    name: 'Wonju/Hoengseong Air Base (K-38/K-46)',
    city: 'Wonju',
    country: 'South Korea',
    iata: 'WJU',
    icao: 'RKNW',
    latitude: 37.441201,
    longitude: 127.963858,
    altitude: 329,
    schengen: false
  },
  {
    id: 5150,
    name: 'Yangyang International Airport',
    city: 'Sokcho / Gangneung',
    country: 'South Korea',
    iata: 'YNY',
    icao: 'RKNY',
    latitude: 38.061298,
    longitude: 128.669006,
    altitude: 241,
    schengen: false
  },
  {
    id: 5151,
    name: 'Jeju International Airport',
    city: 'Cheju',
    country: 'South Korea',
    iata: 'CJU',
    icao: 'RKPC',
    latitude: 33.5112991333008,
    longitude: 126.49299621582,
    altitude: 118,
    schengen: false
  },
  {
    id: 5152,
    name: 'Jeongseok Airport',
    city: 'Seogwipo',
    country: 'South Korea',
    iata: 'JDG',
    icao: 'RKPD',
    latitude: 33.3996009827,
    longitude: 126.711997986,
    altitude: 1171,
    schengen: false
  },
  {
    id: 5153,
    name: 'Jinhae Airbase/Airport (G-813/K-10)',
    city: 'Chinhae',
    country: 'South Korea',
    iata: 'CHF',
    icao: 'RKPE',
    latitude: 35.140248,
    longitude: 128.696229,
    altitude: 8,
    schengen: false
  },
  {
    id: 5154,
    name: 'Gimhae International Airport',
    city: 'Busan',
    country: 'South Korea',
    iata: 'PUS',
    icao: 'RKPK',
    latitude: 35.1795005798,
    longitude: 128.93800354,
    altitude: 6,
    schengen: false
  },
  {
    id: 5155,
    name: 'Sacheon Air Base/Airport',
    city: 'Sacheon',
    country: 'South Korea',
    iata: 'HIN',
    icao: 'RKPS',
    latitude: 35.088591,
    longitude: 128.071747,
    altitude: 25,
    schengen: false
  },
  {
    id: 5156,
    name: 'Ulsan Airport',
    city: 'Ulsan',
    country: 'South Korea',
    iata: 'USN',
    icao: 'RKPU',
    latitude: 35.59349823,
    longitude: 129.352005005,
    altitude: 45,
    schengen: false
  },
  {
    id: 5157,
    name: 'A 511 Airport',
    city: 'Pyongtaek',
    country: 'South Korea',
    iata: '',
    icao: 'RKSG',
    latitude: 36.9622001647949,
    longitude: 127.03099822998,
    altitude: 51,
    schengen: false
  },
  {
    id: 5158,
    name: 'Incheon International Airport',
    city: 'Seoul',
    country: 'South Korea',
    iata: 'ICN',
    icao: 'RKSI',
    latitude: 37.4691009521484,
    longitude: 126.450996398926,
    altitude: 23,
    schengen: false
  },
  {
    id: 5159,
    name: 'Seoul Air Base (K-16)',
    city: 'Seoul East',
    country: 'South Korea',
    iata: 'SSN',
    icao: 'RKSM',
    latitude: 37.444744,
    longitude: 127.112718,
    altitude: 92,
    schengen: false
  },
  {
    id: 5160,
    name: 'Osan Air Base',
    city: 'Osan',
    country: 'South Korea',
    iata: 'OSN',
    icao: 'RKSO',
    latitude: 37.090599,
    longitude: 127.029999,
    altitude: 38,
    schengen: false
  },
  {
    id: 5161,
    name: 'Gimpo International Airport',
    city: 'Seoul',
    country: 'South Korea',
    iata: 'GMP',
    icao: 'RKSS',
    latitude: 37.5583,
    longitude: 126.791,
    altitude: 59,
    schengen: false
  },
  {
    id: 5162,
    name: 'Suwon Airport',
    city: 'Suwon',
    country: 'South Korea',
    iata: 'SWU',
    icao: 'RKSW',
    latitude: 37.2393989562988,
    longitude: 127.00700378418,
    altitude: 88,
    schengen: false
  },
  {
    id: 5163,
    name: 'Pohang Airport (G-815/K-3)',
    city: 'Pohang',
    country: 'South Korea',
    iata: 'KPO',
    icao: 'RKTH',
    latitude: 35.987955,
    longitude: 129.420383,
    altitude: 70,
    schengen: false
  },
  {
    id: 5164,
    name: 'Jungwon Air Base/Chungju Airport',
    city: '',
    country: 'South Korea',
    iata: '',
    icao: 'RKTI',
    latitude: 37.03024,
    longitude: 127.886353,
    altitude: 281,
    schengen: false
  },
  {
    id: 5165,
    name: 'Daegu Airport',
    city: 'Taegu',
    country: 'South Korea',
    iata: 'TAE',
    icao: 'RKTN',
    latitude: 35.896872,
    longitude: 128.65531,
    altitude: 116,
    schengen: false
  },
  {
    id: 5166,
    name: 'Seosan Air Base',
    city: 'Seosan',
    country: 'South Korea',
    iata: 'HMY',
    icao: 'RKTP',
    latitude: 36.703999,
    longitude: 126.486,
    altitude: 39,
    schengen: false
  },
  {
    id: 5167,
    name: 'Cheongju International Airport/Cheongju Air Base (K-59/G-513)',
    city: 'Chongju',
    country: 'South Korea',
    iata: 'CJJ',
    icao: 'RKTU',
    latitude: 36.717008,
    longitude: 127.498741,
    altitude: 191,
    schengen: false
  },
  {
    id: 5168,
    name: 'Yecheon Airbase',
    city: 'Yechon',
    country: 'South Korea',
    iata: 'YEC',
    icao: 'RKTY',
    latitude: 36.630373,
    longitude: 128.34971,
    altitude: 354,
    schengen: false
  },
  {
    id: 5169,
    name: 'Paloich Airport, Heliport',
    city: 'Higleig',
    country: 'South Sudan',
    iata: 'HGI',
    icao: 'HSFA',
    latitude: 10.529167,
    longitude: 32.500556,
    altitude: 1290,
    schengen: false
  },
  {
    id: 5170,
    name: 'Juba International Airport',
    city: 'Juba',
    country: 'South Sudan',
    iata: 'JUB',
    icao: 'HSSJ',
    latitude: 4.87201023102,
    longitude: 31.6011009216,
    altitude: 1513,
    schengen: false
  },
  {
    id: 5171,
    name: 'Torit Airport',
    city: 'Torit',
    country: 'South Sudan',
    iata: '',
    icao: 'HSTR',
    latitude: 4.40000009536743,
    longitude: 32.5830001831055,
    altitude: 2050,
    schengen: false
  },
  {
    id: 5172,
    name: 'Fuerteventura Airport',
    city: 'Fuerteventura',
    country: 'Spain',
    iata: 'FUE',
    icao: 'GCFV',
    latitude: 28.4526996612549,
    longitude: -13.8638000488281,
    altitude: 85,
    schengen: true
  },
  {
    id: 5173,
    name: 'La Gomera Airport',
    city: 'La Gomera',
    country: 'Spain',
    iata: 'GMZ',
    icao: 'GCGM',
    latitude: 28.0296001434326,
    longitude: -17.214599609375,
    altitude: 716,
    schengen: true
  },
  {
    id: 5174,
    name: 'Hierro Airport',
    city: 'Hierro',
    country: 'Spain',
    iata: 'VDE',
    icao: 'GCHI',
    latitude: 27.8148002624512,
    longitude: -17.8871002197266,
    altitude: 103,
    schengen: true
  },
  {
    id: 5175,
    name: 'La Palma Airport',
    city: 'Santa Cruz De La Palma',
    country: 'Spain',
    iata: 'SPC',
    icao: 'GCLA',
    latitude: 28.6264991760254,
    longitude: -17.7555999755859,
    altitude: 107,
    schengen: true
  },
  {
    id: 5176,
    name: 'Gran Canaria Airport',
    city: 'Gran Canaria',
    country: 'Spain',
    iata: 'LPA',
    icao: 'GCLP',
    latitude: 27.9319000244141,
    longitude: -15.3865995407104,
    altitude: 78,
    schengen: true
  },
  {
    id: 5177,
    name: 'Lanzarote Airport',
    city: 'Arrecife',
    country: 'Spain',
    iata: 'ACE',
    icao: 'GCRR',
    latitude: 28.945499,
    longitude: -13.6052,
    altitude: 46,
    schengen: true
  },
  {
    id: 5178,
    name: 'Tenerife South Airport',
    city: 'Tenerife',
    country: 'Spain',
    iata: 'TFS',
    icao: 'GCTS',
    latitude: 28.044500351,
    longitude: -16.5725002289,
    altitude: 209,
    schengen: true
  },
  {
    id: 5179,
    name: 'Tenerife Norte Airport',
    city: 'Tenerife',
    country: 'Spain',
    iata: 'TFN',
    icao: 'GCXO',
    latitude: 28.4827003479,
    longitude: -16.3414993286,
    altitude: 2076,
    schengen: true
  },
  {
    id: 5180,
    name: 'Melilla Airport',
    city: 'Melilla',
    country: 'Spain',
    iata: 'MLN',
    icao: 'GEML',
    latitude: 35.279800415,
    longitude: -2.9562599659,
    altitude: 156,
    schengen: true
  },
  {
    id: 5181,
    name: 'Albacete-Los Llanos Airport',
    city: 'Albacete',
    country: 'Spain',
    iata: 'ABC',
    icao: 'LEAB',
    latitude: 38.9485015869,
    longitude: -1.86352002621,
    altitude: 2302,
    schengen: true
  },
  {
    id: 5182,
    name: 'Alicante International Airport',
    city: 'Alicante',
    country: 'Spain',
    iata: 'ALC',
    icao: 'LEAL',
    latitude: 38.2821998596191,
    longitude: -0.55815601348877,
    altitude: 142,
    schengen: true
  },
  {
    id: 5183,
    name: 'Almería International Airport',
    city: 'Almeria',
    country: 'Spain',
    iata: 'LEI',
    icao: 'LEAM',
    latitude: 36.8438987731934,
    longitude: -2.3701000213623,
    altitude: 70,
    schengen: true
  },
  {
    id: 5184,
    name: 'Asturias Airport',
    city: 'Aviles',
    country: 'Spain',
    iata: 'OVD',
    icao: 'LEAS',
    latitude: 43.5635986328125,
    longitude: -6.03461980819702,
    altitude: 416,
    schengen: true
  },
  {
    id: 5185,
    name: 'Córdoba Airport',
    city: 'Cordoba',
    country: 'Spain',
    iata: 'ODB',
    icao: 'LEBA',
    latitude: 37.8419990539551,
    longitude: -4.84887981414795,
    altitude: 297,
    schengen: true
  },
  {
    id: 5186,
    name: 'Bilbao Airport',
    city: 'Bilbao',
    country: 'Spain',
    iata: 'BIO',
    icao: 'LEBB',
    latitude: 43.3011016845703,
    longitude: -2.91060996055603,
    altitude: 138,
    schengen: true
  },
  {
    id: 5187,
    name: 'Burgos Airport',
    city: 'Burgos',
    country: 'Spain',
    iata: 'RGS',
    icao: 'LEBG',
    latitude: 42.3576011657715,
    longitude: -3.62075996398926,
    altitude: 2945,
    schengen: true
  },
  {
    id: 5188,
    name: 'Barcelona International Airport',
    city: 'Barcelona',
    country: 'Spain',
    iata: 'BCN',
    icao: 'LEBL',
    latitude: 41.2971,
    longitude: 2.07846,
    altitude: 12,
    schengen: true
  },
  {
    id: 5189,
    name: 'Badajoz Airport',
    city: 'Badajoz',
    country: 'Spain',
    iata: 'BJZ',
    icao: 'LEBZ',
    latitude: 38.891300201416,
    longitude: -6.82133007049561,
    altitude: 609,
    schengen: true
  },
  {
    id: 5190,
    name: 'La Cerdanya Airport',
    city: 'Das i Fontanals de Cerdanya',
    country: 'Spain',
    iata: '',
    icao: 'LECD',
    latitude: 42.3863983154297,
    longitude: 1.86667001247406,
    altitude: 3586,
    schengen: true
  },
  {
    id: 5191,
    name: 'A Coruña Airport',
    city: 'La Coruna',
    country: 'Spain',
    iata: 'LCG',
    icao: 'LECO',
    latitude: 43.302101,
    longitude: -8.37726,
    altitude: 326,
    schengen: true
  },
  {
    id: 5192,
    name: 'Cuatro Vientos Airport',
    city: 'Madrid',
    country: 'Spain',
    iata: 'ECV',
    icao: 'LECU',
    latitude: 40.370701,
    longitude: -3.78514,
    altitude: 2269,
    schengen: true
  },
  {
    id: 5193,
    name: 'Lleida-Alguaire Airport',
    city: 'Lleida',
    country: 'Spain',
    iata: 'ILD',
    icao: 'LEDA',
    latitude: 41.728185,
    longitude: 0.535023,
    altitude: 1170,
    schengen: true
  },
  {
    id: 5194,
    name: 'Castellón-Costa Azahar Airport',
    city: 'Castellón de la Plana',
    country: 'Spain',
    iata: 'CDT',
    icao: 'LEDS',
    latitude: 40.213889,
    longitude: 0.073333,
    altitude: 1145,
    schengen: true
  },
  {
    id: 5195,
    name: 'Fuentemilanos Airport',
    city: 'Segovia',
    country: 'Spain',
    iata: '',
    icao: 'LEFM',
    latitude: 40.8886108398,
    longitude: -4.23750019073,
    altitude: 3307,
    schengen: true
  },
  {
    id: 5196,
    name: 'Armilla Air Base',
    city: 'Granada',
    country: 'Spain',
    iata: '',
    icao: 'LEGA',
    latitude: 37.1332016,
    longitude: -3.63568997,
    altitude: 2297,
    schengen: true
  },
  {
    id: 5197,
    name: 'Girona Airport',
    city: 'Gerona',
    country: 'Spain',
    iata: 'GRO',
    icao: 'LEGE',
    latitude: 41.901000977,
    longitude: 2.7605500221,
    altitude: 468,
    schengen: true
  },
  {
    id: 5198,
    name: 'Federico Garcia Lorca Airport',
    city: 'Granada',
    country: 'Spain',
    iata: 'GRX',
    icao: 'LEGR',
    latitude: 37.1887016296387,
    longitude: -3.77735996246338,
    altitude: 1860,
    schengen: true
  },
  {
    id: 5199,
    name: 'Getafe Air Base',
    city: 'Madrid',
    country: 'Spain',
    iata: '',
    icao: 'LEGT',
    latitude: 40.2941017150879,
    longitude: -3.72382998466492,
    altitude: 2031,
    schengen: true
  },
  {
    id: 5200,
    name: 'Huesca/Pirineos Airport',
    city: 'Huesca',
    country: 'Spain',
    iata: 'HSK',
    icao: 'LEHC',
    latitude: 42.0760993958,
    longitude: -0.316666990519,
    altitude: 1768,
    schengen: true
  },
  {
    id: 5201,
    name: 'Ibiza Airport',
    city: 'Ibiza',
    country: 'Spain',
    iata: 'IBZ',
    icao: 'LEIB',
    latitude: 38.8728981018,
    longitude: 1.37311995029,
    altitude: 24,
    schengen: true
  },
  {
    id: 5202,
    name: 'Jerez Airport',
    city: 'Jerez',
    country: 'Spain',
    iata: 'XRY',
    icao: 'LEJR',
    latitude: 36.7445983886719,
    longitude: -6.06011009216309,
    altitude: 93,
    schengen: true
  },
  {
    id: 5203,
    name: 'San Javier Airport',
    city: 'Murcia',
    country: 'Spain',
    iata: 'MJV',
    icao: 'LELC',
    latitude: 37.7750015258789,
    longitude: -0.812389016151428,
    altitude: 11,
    schengen: true
  },
  {
    id: 5204,
    name: 'Sabadell Airport',
    city: 'Sabadell',
    country: 'Spain',
    iata: 'QSA',
    icao: 'LELL',
    latitude: 41.5209007263184,
    longitude: 2.10507988929749,
    altitude: 485,
    schengen: true
  },
  {
    id: 5205,
    name: 'Leon Airport',
    city: 'Leon',
    country: 'Spain',
    iata: 'LEN',
    icao: 'LELN',
    latitude: 42.5890007019043,
    longitude: -5.65556001663208,
    altitude: 3006,
    schengen: true
  },
  {
    id: 5206,
    name: 'Logroño-Agoncillo Airport',
    city: 'Logroño-Agoncillo',
    country: 'Spain',
    iata: 'RJL',
    icao: 'LELO',
    latitude: 42.4609534888,
    longitude: -2.32223510742,
    altitude: 1161,
    schengen: true
  },
  {
    id: 5207,
    name: 'Adolfo Suárez Madrid–Barajas Airport',
    city: 'Madrid',
    country: 'Spain',
    iata: 'MAD',
    icao: 'LEMD',
    latitude: 40.471926,
    longitude: -3.56264,
    altitude: 1998,
    schengen: true
  },
  {
    id: 5208,
    name: 'Málaga Airport',
    city: 'Malaga',
    country: 'Spain',
    iata: 'AGP',
    icao: 'LEMG',
    latitude: 36.6749000549316,
    longitude: -4.49911022186279,
    altitude: 53,
    schengen: true
  },
  {
    id: 5209,
    name: 'Menorca Airport',
    city: 'Menorca',
    country: 'Spain',
    iata: 'MAH',
    icao: 'LEMH',
    latitude: 39.8625984191895,
    longitude: 4.21864986419678,
    altitude: 302,
    schengen: true
  },
  {
    id: 5210,
    name: 'Moron Air Base',
    city: 'Sevilla',
    country: 'Spain',
    iata: 'OZP',
    icao: 'LEMO',
    latitude: 37.1749000549316,
    longitude: -5.61594009399414,
    altitude: 285,
    schengen: true
  },
  {
    id: 5211,
    name: 'Ocaña Airport',
    city: 'Ocana',
    country: 'Spain',
    iata: '',
    icao: 'LEOC',
    latitude: 39.9375,
    longitude: -3.50332999229431,
    altitude: 2405,
    schengen: true
  },
  {
    id: 5212,
    name: 'Palma De Mallorca Airport',
    city: 'Palma de Mallorca',
    country: 'Spain',
    iata: 'PMI',
    icao: 'LEPA',
    latitude: 39.551700592,
    longitude: 2.73881006241,
    altitude: 27,
    schengen: true
  },
  {
    id: 5213,
    name: 'Pamplona Airport',
    city: 'Pamplona',
    country: 'Spain',
    iata: 'PNA',
    icao: 'LEPP',
    latitude: 42.7700004577637,
    longitude: -1.64632999897003,
    altitude: 1504,
    schengen: true
  },
  {
    id: 5214,
    name: 'Alcantarilla Air Base',
    city: 'Murcia',
    country: 'Spain',
    iata: '',
    icao: 'LERI',
    latitude: 37.951099,
    longitude: -1.23032,
    altitude: 250,
    schengen: true
  },
  {
    id: 5215,
    name: 'Ciudad Real Central Airport',
    city: 'Ciudad Real',
    country: 'Spain',
    iata: 'CQM',
    icao: 'LERL',
    latitude: 38.8563888889,
    longitude: -3.97,
    altitude: 0,
    schengen: true
  },
  {
    id: 5216,
    name: 'Reus Air Base',
    city: 'Reus',
    country: 'Spain',
    iata: 'REU',
    icao: 'LERS',
    latitude: 41.1473999023437,
    longitude: 1.16717004776001,
    altitude: 233,
    schengen: true
  },
  {
    id: 5217,
    name: 'Rota Naval Station Airport',
    city: 'Rota',
    country: 'Spain',
    iata: 'ROZ',
    icao: 'LERT',
    latitude: 36.645198822,
    longitude: -6.34946012497,
    altitude: 86,
    schengen: true
  },
  {
    id: 5218,
    name: 'Salamanca Airport',
    city: 'Salamanca',
    country: 'Spain',
    iata: 'SLM',
    icao: 'LESA',
    latitude: 40.9520988464355,
    longitude: -5.50198984146118,
    altitude: 2595,
    schengen: true
  },
  {
    id: 5219,
    name: 'Son Bonet Airport',
    city: 'Son Bonet',
    country: 'Spain',
    iata: '',
    icao: 'LESB',
    latitude: 39.5988998413086,
    longitude: 2.70278000831604,
    altitude: 157,
    schengen: true
  },
  {
    id: 5220,
    name: 'San Luis Airport',
    city: 'San Luis',
    country: 'Spain',
    iata: '',
    icao: 'LESL',
    latitude: 39.8622016906738,
    longitude: 4.25832986831665,
    altitude: 197,
    schengen: true
  },
  {
    id: 5221,
    name: 'San Sebastian Airport',
    city: 'San Sebastian',
    country: 'Spain',
    iata: 'EAS',
    icao: 'LESO',
    latitude: 43.3564987182617,
    longitude: -1.79060995578766,
    altitude: 16,
    schengen: true
  },
  {
    id: 5222,
    name: 'Santiago de Compostela Airport',
    city: 'Santiago',
    country: 'Spain',
    iata: 'SCQ',
    icao: 'LEST',
    latitude: 42.8963012695313,
    longitude: -8.41514015197754,
    altitude: 1213,
    schengen: true
  },
  {
    id: 5223,
    name: "Pirineus - la Seu d'Urgel Airport",
    city: 'Seo De Urgel',
    country: 'Spain',
    iata: 'LEU',
    icao: 'LESU',
    latitude: 42.3386,
    longitude: 1.40917,
    altitude: 2625,
    schengen: true
  },
  {
    id: 5224,
    name: 'Teruel Airport',
    city: 'Teruel',
    country: 'Spain',
    iata: 'TEV',
    icao: 'LETL',
    latitude: 40.403,
    longitude: -1.2183,
    altitude: 3380,
    schengen: true
  },
  {
    id: 5225,
    name: 'Torrejón Airport',
    city: 'Madrid',
    country: 'Spain',
    iata: 'TOJ',
    icao: 'LETO',
    latitude: 40.4967002868652,
    longitude: -3.44586992263794,
    altitude: 2026,
    schengen: true
  },
  {
    id: 5226,
    name: 'Valencia Airport',
    city: 'Valencia',
    country: 'Spain',
    iata: 'VLC',
    icao: 'LEVC',
    latitude: 39.4893,
    longitude: -0.481625,
    altitude: 240,
    schengen: true
  },
  {
    id: 5227,
    name: 'Valladolid Airport',
    city: 'Valladolid',
    country: 'Spain',
    iata: 'VLL',
    icao: 'LEVD',
    latitude: 41.7061004639,
    longitude: -4.85194015503,
    altitude: 2776,
    schengen: true
  },
  {
    id: 5228,
    name: 'Vitoria/Foronda Airport',
    city: 'Vitoria',
    country: 'Spain',
    iata: 'VIT',
    icao: 'LEVT',
    latitude: 42.8828010559082,
    longitude: -2.72446990013123,
    altitude: 1682,
    schengen: true
  },
  {
    id: 5229,
    name: 'Vigo Airport',
    city: 'Vigo',
    country: 'Spain',
    iata: 'VGO',
    icao: 'LEVX',
    latitude: 42.2318000793457,
    longitude: -8.62677001953125,
    altitude: 856,
    schengen: true
  },
  {
    id: 5230,
    name: 'Santander Airport',
    city: 'Santander',
    country: 'Spain',
    iata: 'SDR',
    icao: 'LEXJ',
    latitude: 43.4271011352539,
    longitude: -3.82000994682312,
    altitude: 16,
    schengen: true
  },
  {
    id: 5231,
    name: 'Zaragoza Air Base',
    city: 'Zaragoza',
    country: 'Spain',
    iata: 'ZAZ',
    icao: 'LEZG',
    latitude: 41.6661987304687,
    longitude: -1.04155004024506,
    altitude: 863,
    schengen: true
  },
  {
    id: 5232,
    name: 'Sevilla Airport',
    city: 'Sevilla',
    country: 'Spain',
    iata: 'SVQ',
    icao: 'LEZL',
    latitude: 37.4179992675781,
    longitude: -5.8931097984314,
    altitude: 112,
    schengen: true
  },
  {
    id: 5233,
    name: 'Central Bolívar Airport',
    city: 'Sevilla',
    country: 'Spain',
    iata: '',
    icao: 'SVSJ',
    latitude: 8.91666698455811,
    longitude: -71.9333343505859,
    altitude: 39,
    schengen: true
  },
  {
    id: 5234,
    name: 'Bandaranaike International Colombo Airport',
    city: 'Colombo',
    country: 'Sri Lanka',
    iata: 'CMB',
    icao: 'VCBI',
    latitude: 7.1807599067688,
    longitude: 79.8841018676758,
    altitude: 30,
    schengen: false
  },
  {
    id: 5235,
    name: 'Anuradhapura Air Force Base',
    city: 'Anuradhapura',
    country: 'Sri Lanka',
    iata: 'ACJ',
    icao: 'VCCA',
    latitude: 8.30148983002,
    longitude: 80.4279022217,
    altitude: 324,
    schengen: false
  },
  {
    id: 5236,
    name: 'Batticaloa Airport',
    city: 'Batticaloa',
    country: 'Sri Lanka',
    iata: 'BTC',
    icao: 'VCCB',
    latitude: 7.70576,
    longitude: 81.678802,
    altitude: 20,
    schengen: false
  },
  {
    id: 5237,
    name: 'Colombo Ratmalana Airport',
    city: 'Colombo',
    country: 'Sri Lanka',
    iata: 'RML',
    icao: 'VCCC',
    latitude: 6.82199001312256,
    longitude: 79.8861999511719,
    altitude: 22,
    schengen: false
  },
  {
    id: 5238,
    name: 'Ampara Airport',
    city: 'Galoya',
    country: 'Sri Lanka',
    iata: 'ADP',
    icao: 'VCCG',
    latitude: 7.33776,
    longitude: 81.62594,
    altitude: 150,
    schengen: false
  },
  {
    id: 5239,
    name: 'Hingurakgoda Air Force Base',
    city: 'Polonnaruwa Town',
    country: 'Sri Lanka',
    iata: 'HIM',
    icao: 'VCCH',
    latitude: 8.04981,
    longitude: 80.9814,
    altitude: 170,
    schengen: false
  },
  {
    id: 5240,
    name: 'Kankesanturai Airport',
    city: 'Jaffna',
    country: 'Sri Lanka',
    iata: 'JAF',
    icao: 'VCCJ',
    latitude: 9.79232978820801,
    longitude: 80.0700988769531,
    altitude: 33,
    schengen: false
  },
  {
    id: 5241,
    name: 'Koggala Airport',
    city: 'Koggala',
    country: 'Sri Lanka',
    iata: 'KCT',
    icao: 'VCCK',
    latitude: 5.99368000030518,
    longitude: 80.3202972412109,
    altitude: 10,
    schengen: false
  },
  {
    id: 5242,
    name: 'Sigiriya Air Force Base',
    city: 'Sigiriya',
    country: 'Sri Lanka',
    iata: 'GIU',
    icao: 'VCCS',
    latitude: 7.95666980743,
    longitude: 80.7285003662,
    altitude: 630,
    schengen: false
  },
  {
    id: 5243,
    name: 'China Bay Airport',
    city: 'Trinciomalee',
    country: 'Sri Lanka',
    iata: 'TRR',
    icao: 'VCCT',
    latitude: 8.5385103225708,
    longitude: 81.1819000244141,
    altitude: 6,
    schengen: false
  },
  {
    id: 5244,
    name: 'Weerawila Airport',
    city: 'Wirawila',
    country: 'Sri Lanka',
    iata: 'WRZ',
    icao: 'VCCW',
    latitude: 6.25448989868,
    longitude: 81.2351989746,
    altitude: 50,
    schengen: false
  },
  {
    id: 5245,
    name: 'Mattala Rajapaksa International Airport',
    city: 'Mattala',
    country: 'Sri Lanka',
    iata: 'HRI',
    icao: 'VCRI',
    latitude: 6.284467,
    longitude: 81.124128,
    altitude: 157,
    schengen: false
  },
  {
    id: 5246,
    name: 'Atbara Airport',
    city: 'Atbara',
    country: 'Sudan',
    iata: 'ATB',
    icao: 'HSAT',
    latitude: 17.7103443145752,
    longitude: 34.0570182800293,
    altitude: 1181,
    schengen: false
  },
  {
    id: 5247,
    name: 'Aweil Airport',
    city: 'Aweil',
    country: 'Sudan',
    iata: '',
    icao: 'HSAW',
    latitude: 8.76700019836426,
    longitude: 27.3999996185303,
    altitude: 1394,
    schengen: false
  },
  {
    id: 5248,
    name: 'Dongola Airport',
    city: 'Dongola',
    country: 'Sudan',
    iata: 'DOG',
    icao: 'HSDN',
    latitude: 19.1539001465,
    longitude: 30.4300994873,
    altitude: 772,
    schengen: false
  },
  {
    id: 5249,
    name: 'Damazin Airport',
    city: 'Damazin',
    country: 'Sudan',
    iata: 'RSS',
    icao: 'HSDZ',
    latitude: 11.7859,
    longitude: 34.3367,
    altitude: 1582,
    schengen: false
  },
  {
    id: 5250,
    name: 'El Fasher Airport',
    city: 'El Fasher',
    country: 'Sudan',
    iata: 'ELF',
    icao: 'HSFS',
    latitude: 13.6148996353149,
    longitude: 25.3246002197266,
    altitude: 2393,
    schengen: false
  },
  {
    id: 5251,
    name: 'Geneina Airport',
    city: 'Geneina',
    country: 'Sudan',
    iata: 'EGN',
    icao: 'HSGN',
    latitude: 13.4816999435425,
    longitude: 22.4671993255615,
    altitude: 2650,
    schengen: false
  },
  {
    id: 5252,
    name: 'Kassala Airport',
    city: 'Kassala',
    country: 'Sudan',
    iata: 'KSL',
    icao: 'HSKA',
    latitude: 15.3874998092651,
    longitude: 36.328800201416,
    altitude: 1671,
    schengen: false
  },
  {
    id: 5253,
    name: 'Kadugli Airport',
    city: 'Kadugli',
    country: 'Sudan',
    iata: 'KDX',
    icao: 'HSLI',
    latitude: 11.1379995346,
    longitude: 29.7010993958,
    altitude: 1848,
    schengen: false
  },
  {
    id: 5254,
    name: 'Rumbek Airport',
    city: 'Rumbek',
    country: 'Sudan',
    iata: 'RBX',
    icao: 'HSMK',
    latitude: 6.82499980927,
    longitude: 29.6690006256,
    altitude: 1378,
    schengen: false
  },
  {
    id: 5255,
    name: 'Merowe New Airport',
    city: 'Merowe',
    country: 'Sudan',
    iata: 'MWE',
    icao: 'HSMN',
    latitude: 18.4433333333,
    longitude: 31.8433333333,
    altitude: 897,
    schengen: false
  },
  {
    id: 5256,
    name: 'Nyala Airport',
    city: 'Nyala',
    country: 'Sudan',
    iata: 'UYL',
    icao: 'HSNN',
    latitude: 12.0535001754761,
    longitude: 24.9561996459961,
    altitude: 2106,
    schengen: false
  },
  {
    id: 5257,
    name: 'El Obeid Airport',
    city: 'El Obeid',
    country: 'Sudan',
    iata: 'EBD',
    icao: 'HSOB',
    latitude: 13.1532001495361,
    longitude: 30.2327003479004,
    altitude: 1927,
    schengen: false
  },
  {
    id: 5258,
    name: 'Port Sudan New International Airport',
    city: 'Port Sudan',
    country: 'Sudan',
    iata: 'PZU',
    icao: 'HSPN',
    latitude: 19.4335994720459,
    longitude: 37.2341003417969,
    altitude: 135,
    schengen: false
  },
  {
    id: 5259,
    name: 'Malakal Airport',
    city: 'Malakal',
    country: 'Sudan',
    iata: 'MAK',
    icao: 'HSSM',
    latitude: 9.55897045135498,
    longitude: 31.6522006988525,
    altitude: 1291,
    schengen: false
  },
  {
    id: 5260,
    name: 'Khartoum International Airport',
    city: 'Khartoum',
    country: 'Sudan',
    iata: 'KRT',
    icao: 'HSSS',
    latitude: 15.5895004272461,
    longitude: 32.5531997680664,
    altitude: 1265,
    schengen: false
  },
  {
    id: 5261,
    name: 'Wadi Halfa Airport',
    city: 'Wadi Halfa',
    country: 'Sudan',
    iata: 'WHF',
    icao: 'HSSW',
    latitude: 21.802698135376,
    longitude: 31.521577835083,
    altitude: 961,
    schengen: false
  },
  {
    id: 5262,
    name: 'Wau Airport',
    city: 'Wau',
    country: 'Sudan',
    iata: 'WUU',
    icao: 'HSWW',
    latitude: 7.72583007812,
    longitude: 27.9750003815,
    altitude: 1529,
    schengen: false
  },
  {
    id: 5263,
    name: 'Yei Airport',
    city: 'Yei',
    country: 'Sudan',
    iata: '',
    icao: 'HSYE',
    latitude: 4.13028160099,
    longitude: 30.7280731201,
    altitude: 2000,
    schengen: false
  },
  {
    id: 5264,
    name: 'Afobakka Airstrip',
    city: 'Afobaka',
    country: 'Suriname',
    iata: '',
    icao: 'SMAF',
    latitude: 4.998505,
    longitude: -54.992033,
    altitude: 80,
    schengen: false
  },
  {
    id: 5265,
    name: 'Albina Airport',
    city: 'Albina',
    country: 'Suriname',
    iata: 'ABN',
    icao: 'SMBN',
    latitude: 5.51272010803223,
    longitude: -54.0500984191895,
    altitude: 19,
    schengen: false
  },
  {
    id: 5266,
    name: 'Totness Airport',
    city: 'Totness',
    country: 'Suriname',
    iata: 'TOT',
    icao: 'SMCO',
    latitude: 5.8658299446106,
    longitude: -56.3274993896484,
    altitude: 10,
    schengen: false
  },
  {
    id: 5267,
    name: 'Drietabbetje Airport',
    city: 'Drietabbetje',
    country: 'Suriname',
    iata: 'DRJ',
    icao: 'SMDA',
    latitude: 4.111359,
    longitude: -54.672766,
    altitude: 236,
    schengen: false
  },
  {
    id: 5268,
    name: 'Alalapadu Airstrip',
    city: 'Alapadu',
    country: 'Suriname',
    iata: '',
    icao: 'SMDU',
    latitude: 2.5235,
    longitude: -56.3247,
    altitude: 880,
    schengen: false
  },
  {
    id: 5269,
    name: 'Godo Holo Airstrip',
    city: 'Pikienkondre of Miranda',
    country: 'Suriname',
    iata: '',
    icao: 'SMGH',
    latitude: 4.0583,
    longitude: -54.7861,
    altitude: 280,
    schengen: false
  },
  {
    id: 5270,
    name: 'Johan Adolf Pengel International Airport',
    city: 'Zandery',
    country: 'Suriname',
    iata: 'PBM',
    icao: 'SMJP',
    latitude: 5.4528298378,
    longitude: -55.1878013611,
    altitude: 59,
    schengen: false
  },
  {
    id: 5271,
    name: 'Kabalebo Airport',
    city: 'Kabalebo',
    country: 'Suriname',
    iata: '',
    icao: 'SMKA',
    latitude: 4.406,
    longitude: -57.223,
    altitude: 535,
    schengen: false
  },
  {
    id: 5272,
    name: 'Kayser Airport',
    city: 'Kayser',
    country: 'Suriname',
    iata: '',
    icao: 'SMKE',
    latitude: 3.094343,
    longitude: -56.472702,
    altitude: 849,
    schengen: false
  },
  {
    id: 5273,
    name: 'Lawa Anapaike Airstrip',
    city: 'Anapaike',
    country: 'Suriname',
    iata: '',
    icao: 'SMLA',
    latitude: 3.411301,
    longitude: -54.028049,
    altitude: 373,
    schengen: false
  },
  {
    id: 5274,
    name: 'Moengo Airstrip',
    city: 'Moengo',
    country: 'Suriname',
    iata: 'MOJ',
    icao: 'SMMO',
    latitude: 5.6076,
    longitude: -54.4003,
    altitude: 49,
    schengen: false
  },
  {
    id: 5275,
    name: 'Nieuw Nickerie Airport',
    city: 'Nieuw Nickerie',
    country: 'Suriname',
    iata: 'ICK',
    icao: 'SMNI',
    latitude: 5.95556020736694,
    longitude: -57.0393981933594,
    altitude: 9,
    schengen: false
  },
  {
    id: 5276,
    name: 'Vincent Fayks Airport',
    city: 'Paloemeu',
    country: 'Suriname',
    iata: 'OEM',
    icao: 'SMPA',
    latitude: 3.34527993202209,
    longitude: -55.4425010681152,
    altitude: 714,
    schengen: false
  },
  {
    id: 5277,
    name: 'Poeketi Airstrip',
    city: 'Poeketi',
    country: 'Suriname',
    iata: '',
    icao: 'SMPE',
    latitude: 4.126389,
    longitude: -54.623611,
    altitude: 0,
    schengen: false
  },
  {
    id: 5278,
    name: 'Apetina Airstrip',
    city: 'Apetina',
    country: 'Suriname',
    iata: '',
    icao: 'SMPT',
    latitude: 3.50278,
    longitude: -55.058791,
    altitude: 0,
    schengen: false
  },
  {
    id: 5279,
    name: 'Sipaliwini Airport',
    city: 'Sipaliwini',
    country: 'Suriname',
    iata: '',
    icao: 'SMSI',
    latitude: 2.026438,
    longitude: -56.126412,
    altitude: 744,
    schengen: false
  },
  {
    id: 5280,
    name: 'Sarakreek Airstrip',
    city: 'Sarakreek',
    country: 'Suriname',
    iata: '',
    icao: 'SMSK',
    latitude: 4.319444,
    longitude: -54.966667,
    altitude: 223,
    schengen: false
  },
  {
    id: 5281,
    name: 'Kwamalasoemoetoe Airport',
    city: 'Kwamelasemoetoe',
    country: 'Suriname',
    iata: '',
    icao: 'SMSM',
    latitude: 2.354427,
    longitude: -56.792431,
    altitude: 905,
    schengen: false
  },
  {
    id: 5282,
    name: 'Stoelmanseiland Airport',
    city: 'Stoelmans Eiland',
    country: 'Suriname',
    iata: 'SMZ',
    icao: 'SMST',
    latitude: 4.34999990463257,
    longitude: -54.4166679382324,
    altitude: 187,
    schengen: false
  },
  {
    id: 5283,
    name: 'Wageningen Airstrip',
    city: 'Wageningen',
    country: 'Suriname',
    iata: 'AGI',
    icao: 'SMWA',
    latitude: 5.841128,
    longitude: -56.673328,
    altitude: 6,
    schengen: false
  },
  {
    id: 5284,
    name: 'Zorg en Hoop Airport',
    city: 'Paramaribo',
    country: 'Suriname',
    iata: 'ORG',
    icao: 'SMZO',
    latitude: 5.81108,
    longitude: -55.190701,
    altitude: 10,
    schengen: false
  },
  {
    id: 5285,
    name: 'Ny-Ålesund Airport (Hamnerabben)',
    city: 'Ny-Alesund',
    country: 'Svalbard',
    iata: '',
    icao: 'ENAS',
    latitude: 78.9274978638,
    longitude: 11.8743,
    altitude: 50,
    schengen: false
  },
  {
    id: 5286,
    name: 'Svea Airport',
    city: 'Sveagruva',
    country: 'Svalbard',
    iata: '',
    icao: 'ENSA',
    latitude: 77.8969444,
    longitude: 16.725,
    altitude: 32,
    schengen: false
  },
  {
    id: 5287,
    name: 'Matsapha Airport',
    city: 'Manzini',
    country: 'Swaziland',
    iata: 'MTS',
    icao: 'FDMS',
    latitude: -26.5289993286133,
    longitude: 31.3075008392334,
    altitude: 2075,
    schengen: false
  },
  {
    id: 5288,
    name: 'King Mswati III International Airport',
    city: 'Manzini',
    country: 'Swaziland',
    iata: 'SHO',
    icao: 'FDSK',
    latitude: -26.358611,
    longitude: 31.716944,
    altitude: 1092,
    schengen: false
  },
  {
    id: 5289,
    name: 'Malmen Air Base',
    city: 'Linkoeping',
    country: 'Sweden',
    iata: '',
    icao: 'ESCF',
    latitude: 58.4023017883301,
    longitude: 15.5256996154785,
    altitude: 308,
    schengen: true
  },
  {
    id: 5290,
    name: 'Bråvalla Air Base',
    city: 'Norrkoeping',
    country: 'Sweden',
    iata: '',
    icao: 'ESCK',
    latitude: 58.6109008789063,
    longitude: 16.1035995483398,
    altitude: 90,
    schengen: true
  },
  {
    id: 5291,
    name: 'Uppsala Airport',
    city: 'Uppsala',
    country: 'Sweden',
    iata: '',
    icao: 'ESCM',
    latitude: 59.8973007202148,
    longitude: 17.5886001586914,
    altitude: 68,
    schengen: true
  },
  {
    id: 5292,
    name: 'Ronneby Airport',
    city: 'Ronneby',
    country: 'Sweden',
    iata: 'RNB',
    icao: 'ESDF',
    latitude: 56.266700744629,
    longitude: 15.265000343323,
    altitude: 191,
    schengen: true
  },
  {
    id: 5293,
    name: 'Råda Air Base',
    city: 'Rada',
    country: 'Sweden',
    iata: '',
    icao: 'ESFR',
    latitude: 58.4981002807617,
    longitude: 13.0531997680664,
    altitude: 230,
    schengen: true
  },
  {
    id: 5294,
    name: 'Gothenburg-Landvetter Airport',
    city: 'Gothenborg',
    country: 'Sweden',
    iata: 'GOT',
    icao: 'ESGG',
    latitude: 57.662799835205,
    longitude: 12.279800415039,
    altitude: 506,
    schengen: true
  },
  {
    id: 5295,
    name: 'Jönköping Airport',
    city: 'Joenkoeping',
    country: 'Sweden',
    iata: 'JKG',
    icao: 'ESGJ',
    latitude: 57.7575988769531,
    longitude: 14.068699836731,
    altitude: 741,
    schengen: true
  },
  {
    id: 5296,
    name: 'Falköping Airport',
    city: 'Falkoping',
    country: 'Sweden',
    iata: '',
    icao: 'ESGK',
    latitude: 58.1697998046875,
    longitude: 13.5878000259399,
    altitude: 785,
    schengen: true
  },
  {
    id: 5297,
    name: 'Lidköping-Hovby Airport',
    city: 'Lidkoping',
    country: 'Sweden',
    iata: 'LDK',
    icao: 'ESGL',
    latitude: 58.4655,
    longitude: 13.1744,
    altitude: 200,
    schengen: true
  },
  {
    id: 5298,
    name: 'Gothenburg City Airport',
    city: 'Gothenborg',
    country: 'Sweden',
    iata: 'GSE',
    icao: 'ESGP',
    latitude: 57.7747001647949,
    longitude: 11.870400428772,
    altitude: 59,
    schengen: true
  },
  {
    id: 5299,
    name: 'Skövde Airport',
    city: 'Skovde',
    country: 'Sweden',
    iata: 'KVB',
    icao: 'ESGR',
    latitude: 58.4564018249512,
    longitude: 13.9727001190186,
    altitude: 324,
    schengen: true
  },
  {
    id: 5300,
    name: 'Trollhättan-Vänersborg Airport',
    city: 'Trollhattan',
    country: 'Sweden',
    iata: 'THN',
    icao: 'ESGT',
    latitude: 58.3180999755859,
    longitude: 12.3450002670288,
    altitude: 137,
    schengen: true
  },
  {
    id: 5301,
    name: 'Karlsborg Air Base',
    city: 'Karlsborg',
    country: 'Sweden',
    iata: '',
    icao: 'ESIA',
    latitude: 58.513801574707,
    longitude: 14.5071001052856,
    altitude: 308,
    schengen: true
  },
  {
    id: 5302,
    name: 'Såtenäs Air Base',
    city: 'Satenas',
    country: 'Sweden',
    iata: '',
    icao: 'ESIB',
    latitude: 58.426399230957,
    longitude: 12.7144002914429,
    altitude: 181,
    schengen: true
  },
  {
    id: 5303,
    name: 'Barkarby Airport',
    city: 'Stockholm',
    country: 'Sweden',
    iata: '',
    icao: 'ESKB',
    latitude: 59.4144743644,
    longitude: 17.8821372986,
    altitude: 50,
    schengen: true
  },
  {
    id: 5304,
    name: 'Karlskoga Airport',
    city: 'Karlskoga',
    country: 'Sweden',
    iata: 'KSK',
    icao: 'ESKK',
    latitude: 59.3459014892578,
    longitude: 14.4959001541138,
    altitude: 400,
    schengen: true
  },
  {
    id: 5305,
    name: 'Mora Airport',
    city: 'Mora',
    country: 'Sweden',
    iata: 'MXX',
    icao: 'ESKM',
    latitude: 60.957901,
    longitude: 14.5114,
    altitude: 634,
    schengen: true
  },
  {
    id: 5306,
    name: 'Stockholm Skavsta Airport',
    city: 'Stockholm',
    country: 'Sweden',
    iata: 'NYO',
    icao: 'ESKN',
    latitude: 58.7886009216309,
    longitude: 16.9122009277344,
    altitude: 140,
    schengen: true
  },
  {
    id: 5307,
    name: 'Arvika Airport',
    city: 'Arvika',
    country: 'Sweden',
    iata: '',
    icao: 'ESKV',
    latitude: 59.6758995056152,
    longitude: 12.6393995285034,
    altitude: 237,
    schengen: true
  },
  {
    id: 5308,
    name: 'Björkvik Air Base',
    city: 'Björkvik',
    country: 'Sweden',
    iata: '',
    icao: 'ESKX',
    latitude: 58.7907981872559,
    longitude: 16.5711994171143,
    altitude: 0,
    schengen: true
  },
  {
    id: 5309,
    name: 'Emmaboda Airfield',
    city: 'Emmaboda',
    country: 'Sweden',
    iata: '',
    icao: 'ESMA',
    latitude: 56.610802,
    longitude: 15.6048,
    altitude: 442,
    schengen: true
  },
  {
    id: 5310,
    name: 'Feringe Airport',
    city: 'Ljungby',
    country: 'Sweden',
    iata: '',
    icao: 'ESMG',
    latitude: 56.9502983093,
    longitude: 13.9216995239,
    altitude: 538,
    schengen: true
  },
  {
    id: 5311,
    name: 'Kristianstad Airport',
    city: 'Kristianstad',
    country: 'Sweden',
    iata: 'KID',
    icao: 'ESMK',
    latitude: 55.9216995239258,
    longitude: 14.0854997634888,
    altitude: 76,
    schengen: true
  },
  {
    id: 5312,
    name: 'Landskrona Airport',
    city: 'Landskrona',
    country: 'Sweden',
    iata: '',
    icao: 'ESML',
    latitude: 55.9459991455078,
    longitude: 12.8699998855591,
    altitude: 194,
    schengen: true
  },
  {
    id: 5313,
    name: 'Oskarshamn Airport',
    city: 'Oskarshamn',
    country: 'Sweden',
    iata: 'OSK',
    icao: 'ESMO',
    latitude: 57.350498,
    longitude: 16.497999,
    altitude: 96,
    schengen: true
  },
  {
    id: 5314,
    name: 'Anderstorp Airport',
    city: 'Anderstorp',
    country: 'Sweden',
    iata: '',
    icao: 'ESMP',
    latitude: 57.2641983032227,
    longitude: 13.5993995666504,
    altitude: 507,
    schengen: true
  },
  {
    id: 5315,
    name: 'Kalmar Airport',
    city: 'Kalkmar',
    country: 'Sweden',
    iata: 'KLR',
    icao: 'ESMQ',
    latitude: 56.6855010986328,
    longitude: 16.2875995635986,
    altitude: 17,
    schengen: true
  },
  {
    id: 5316,
    name: 'Malmö Sturup Airport',
    city: 'Malmoe',
    country: 'Sweden',
    iata: 'MMX',
    icao: 'ESMS',
    latitude: 55.536305364,
    longitude: 13.3761978149,
    altitude: 236,
    schengen: true
  },
  {
    id: 5317,
    name: 'Halmstad Airport',
    city: 'Halmstad',
    country: 'Sweden',
    iata: 'HAD',
    icao: 'ESMT',
    latitude: 56.6911010742188,
    longitude: 12.8201999664307,
    altitude: 101,
    schengen: true
  },
  {
    id: 5318,
    name: 'Hagshult Air Base',
    city: 'Hagshult',
    country: 'Sweden',
    iata: '',
    icao: 'ESMV',
    latitude: 57.292198,
    longitude: 14.1372,
    altitude: 556,
    schengen: true
  },
  {
    id: 5319,
    name: 'Växjö Kronoberg Airport',
    city: 'Vaxjo',
    country: 'Sweden',
    iata: 'VXO',
    icao: 'ESMX',
    latitude: 56.9291000366211,
    longitude: 14.7279996871948,
    altitude: 610,
    schengen: true
  },
  {
    id: 5320,
    name: 'Hallviken Airport',
    city: 'Hallviken',
    country: 'Sweden',
    iata: '',
    icao: 'ESNA',
    latitude: 63.7383003234863,
    longitude: 15.4582996368408,
    altitude: 1119,
    schengen: true
  },
  {
    id: 5321,
    name: 'Hedlanda Airport',
    city: 'Hede',
    country: 'Sweden',
    iata: '',
    icao: 'ESNC',
    latitude: 62.4089012146,
    longitude: 13.7472000122,
    altitude: 1460,
    schengen: true
  },
  {
    id: 5322,
    name: 'Sveg Airport',
    city: 'Sveg',
    country: 'Sweden',
    iata: 'EVG',
    icao: 'ESND',
    latitude: 62.0477981567383,
    longitude: 14.4229001998901,
    altitude: 1178,
    schengen: true
  },
  {
    id: 5323,
    name: 'Färila Air Base',
    city: 'Farila',
    country: 'Sweden',
    iata: '',
    icao: 'ESNF',
    latitude: 61.897998809814,
    longitude: 15.705300331116,
    altitude: 0,
    schengen: true
  },
  {
    id: 5324,
    name: 'Gällivare Airport',
    city: 'Gallivare',
    country: 'Sweden',
    iata: 'GEV',
    icao: 'ESNG',
    latitude: 67.1324005126953,
    longitude: 20.8145999908447,
    altitude: 1027,
    schengen: true
  },
  {
    id: 5325,
    name: 'Hudiksvall Airport',
    city: 'Hudiksvall',
    country: 'Sweden',
    iata: 'HUV',
    icao: 'ESNH',
    latitude: 61.7681007385,
    longitude: 17.0806999207,
    altitude: 95,
    schengen: true
  },
  {
    id: 5326,
    name: 'Jokkmokk Airport',
    city: 'Jokkmokk',
    country: 'Sweden',
    iata: '',
    icao: 'ESNJ',
    latitude: 66.4962005615234,
    longitude: 20.1471996307373,
    altitude: 904,
    schengen: true
  },
  {
    id: 5327,
    name: 'Kramfors Sollefteå Airport',
    city: 'Kramfors',
    country: 'Sweden',
    iata: 'KRF',
    icao: 'ESNK',
    latitude: 63.0485992431641,
    longitude: 17.7688999176025,
    altitude: 34,
    schengen: true
  },
  {
    id: 5328,
    name: 'Lycksele Airport',
    city: 'Lycksele',
    country: 'Sweden',
    iata: 'LYC',
    icao: 'ESNL',
    latitude: 64.5483016967773,
    longitude: 18.7161998748779,
    altitude: 705,
    schengen: true
  },
  {
    id: 5329,
    name: 'Optand Airport',
    city: 'Optand',
    country: 'Sweden',
    iata: '',
    icao: 'ESNM',
    latitude: 63.1286010742187,
    longitude: 14.8028001785278,
    altitude: 1236,
    schengen: true
  },
  {
    id: 5330,
    name: 'Sundsvall-Härnösand Airport',
    city: 'Sundsvall',
    country: 'Sweden',
    iata: 'SDL',
    icao: 'ESNN',
    latitude: 62.5280990600586,
    longitude: 17.4438991546631,
    altitude: 16,
    schengen: true
  },
  {
    id: 5331,
    name: 'Örnsköldsvik Airport',
    city: 'Ornskoldsvik',
    country: 'Sweden',
    iata: 'OER',
    icao: 'ESNO',
    latitude: 63.4082984924316,
    longitude: 18.9899997711182,
    altitude: 354,
    schengen: true
  },
  {
    id: 5332,
    name: 'Piteå Airport',
    city: 'Pitea',
    country: 'Sweden',
    iata: '',
    icao: 'ESNP',
    latitude: 65.3983001708984,
    longitude: 21.260799407959,
    altitude: 43,
    schengen: true
  },
  {
    id: 5333,
    name: 'Kiruna Airport',
    city: 'Kiruna',
    country: 'Sweden',
    iata: 'KRN',
    icao: 'ESNQ',
    latitude: 67.821998596191,
    longitude: 20.336799621582,
    altitude: 1508,
    schengen: true
  },
  {
    id: 5334,
    name: 'Orsa Airport',
    city: 'Orsa',
    country: 'Sweden',
    iata: '',
    icao: 'ESNR',
    latitude: 61.189998626709,
    longitude: 14.7125997543335,
    altitude: 683,
    schengen: true
  },
  {
    id: 5335,
    name: 'Skellefteå Airport',
    city: 'Skelleftea',
    country: 'Sweden',
    iata: 'SFT',
    icao: 'ESNS',
    latitude: 64.6248016357422,
    longitude: 21.0769004821777,
    altitude: 157,
    schengen: true
  },
  {
    id: 5336,
    name: 'Sättna Airport',
    city: 'Sattna',
    country: 'Sweden',
    iata: '',
    icao: 'ESNT',
    latitude: 62.4814,
    longitude: 17.002899,
    altitude: 886,
    schengen: true
  },
  {
    id: 5337,
    name: 'Umeå Airport',
    city: 'Umea',
    country: 'Sweden',
    iata: 'UME',
    icao: 'ESNU',
    latitude: 63.791801452637,
    longitude: 20.282800674438,
    altitude: 24,
    schengen: true
  },
  {
    id: 5338,
    name: 'Vilhelmina Airport',
    city: 'Vilhelmina',
    country: 'Sweden',
    iata: 'VHM',
    icao: 'ESNV',
    latitude: 64.5791015625,
    longitude: 16.8335990905762,
    altitude: 1140,
    schengen: true
  },
  {
    id: 5339,
    name: 'Arvidsjaur Airport',
    city: 'Arvidsjaur',
    country: 'Sweden',
    iata: 'AJR',
    icao: 'ESNX',
    latitude: 65.5903015136719,
    longitude: 19.2819004058838,
    altitude: 1245,
    schengen: true
  },
  {
    id: 5340,
    name: 'Söderhamn Airport',
    city: 'Soderhamn',
    country: 'Sweden',
    iata: 'SOO',
    icao: 'ESNY',
    latitude: 61.2615013122559,
    longitude: 17.099100112915,
    altitude: 88,
    schengen: true
  },
  {
    id: 5341,
    name: 'Åre Östersund Airport',
    city: 'Östersund',
    country: 'Sweden',
    iata: 'OSD',
    icao: 'ESNZ',
    latitude: 63.194400787354,
    longitude: 14.50030040741,
    altitude: 1233,
    schengen: true
  },
  {
    id: 5342,
    name: 'Örebro Airport',
    city: 'Orebro',
    country: 'Sweden',
    iata: 'ORB',
    icao: 'ESOE',
    latitude: 59.2237014770508,
    longitude: 15.0380001068115,
    altitude: 188,
    schengen: true
  },
  {
    id: 5343,
    name: 'Hagfors Airport',
    city: 'Hagfors',
    country: 'Sweden',
    iata: 'HFS',
    icao: 'ESOH',
    latitude: 60.0200996398926,
    longitude: 13.5789003372192,
    altitude: 474,
    schengen: true
  },
  {
    id: 5344,
    name: 'Karlstad Airport',
    city: 'Karlstad',
    country: 'Sweden',
    iata: 'KSD',
    icao: 'ESOK',
    latitude: 59.4446983337,
    longitude: 13.3374004364,
    altitude: 352,
    schengen: true
  },
  {
    id: 5345,
    name: 'Stockholm Västerås Airport',
    city: 'Vasteras',
    country: 'Sweden',
    iata: 'VST',
    icao: 'ESOW',
    latitude: 59.5894012451172,
    longitude: 16.6336002349854,
    altitude: 21,
    schengen: true
  },
  {
    id: 5346,
    name: 'Luleå Airport',
    city: 'Lulea',
    country: 'Sweden',
    iata: 'LLA',
    icao: 'ESPA',
    latitude: 65.543800354004,
    longitude: 22.121999740601,
    altitude: 65,
    schengen: true
  },
  {
    id: 5347,
    name: 'Vidsel Air Base',
    city: 'Vidsel',
    country: 'Sweden',
    iata: '',
    icao: 'ESPE',
    latitude: 65.8752975463867,
    longitude: 20.1499004364014,
    altitude: 597,
    schengen: true
  },
  {
    id: 5348,
    name: 'Arboga Airport',
    city: 'Arboga',
    country: 'Sweden',
    iata: '',
    icao: 'ESQO',
    latitude: 59.3866004943848,
    longitude: 15.9240999221802,
    altitude: 33,
    schengen: true
  },
  {
    id: 5349,
    name: 'Stockholm-Arlanda Airport',
    city: 'Stockholm',
    country: 'Sweden',
    iata: 'ARN',
    icao: 'ESSA',
    latitude: 59.651901245117,
    longitude: 17.918600082397,
    altitude: 137,
    schengen: true
  },
  {
    id: 5350,
    name: 'Stockholm-Bromma Airport',
    city: 'Stockholm',
    country: 'Sweden',
    iata: 'BMA',
    icao: 'ESSB',
    latitude: 59.3544006347656,
    longitude: 17.9416999816895,
    altitude: 47,
    schengen: true
  },
  {
    id: 5351,
    name: 'Borlange Airport',
    city: 'Borlange',
    country: 'Sweden',
    iata: 'BLE',
    icao: 'ESSD',
    latitude: 60.4220008850098,
    longitude: 15.5151996612549,
    altitude: 503,
    schengen: true
  },
  {
    id: 5352,
    name: 'Hultsfred Airport',
    city: 'Hultsfred',
    country: 'Sweden',
    iata: 'HLF',
    icao: 'ESSF',
    latitude: 57.525799,
    longitude: 15.8233,
    altitude: 366,
    schengen: true
  },
  {
    id: 5353,
    name: 'Gävle Sandviken Airport',
    city: 'Gavle',
    country: 'Sweden',
    iata: 'GVX',
    icao: 'ESSK',
    latitude: 60.5932998657227,
    longitude: 16.9514007568359,
    altitude: 224,
    schengen: true
  },
  {
    id: 5354,
    name: 'Linköping City Airport',
    city: 'Linkoeping',
    country: 'Sweden',
    iata: 'LPI',
    icao: 'ESSL',
    latitude: 58.4062004089,
    longitude: 15.6805000305,
    altitude: 172,
    schengen: true
  },
  {
    id: 5355,
    name: 'Norrköping Airport',
    city: 'Norrkoeping',
    country: 'Sweden',
    iata: 'NRK',
    icao: 'ESSP',
    latitude: 58.5862998962402,
    longitude: 16.2506008148193,
    altitude: 32,
    schengen: true
  },
  {
    id: 5356,
    name: 'Torsby Airport',
    city: 'Torsby',
    country: 'Sweden',
    iata: 'TYF',
    icao: 'ESST',
    latitude: 60.1576004028,
    longitude: 12.9912996292,
    altitude: 393,
    schengen: true
  },
  {
    id: 5357,
    name: 'Eskilstuna Airport',
    city: 'Eskilstuna',
    country: 'Sweden',
    iata: 'EKT',
    icao: 'ESSU',
    latitude: 59.3511009216309,
    longitude: 16.7084007263184,
    altitude: 139,
    schengen: true
  },
  {
    id: 5358,
    name: 'Visby Airport',
    city: 'Visby',
    country: 'Sweden',
    iata: 'VBY',
    icao: 'ESSV',
    latitude: 57.662799835205,
    longitude: 18.346200942993,
    altitude: 164,
    schengen: true
  },
  {
    id: 5359,
    name: 'Vängsö Airport',
    city: 'Vangso',
    country: 'Sweden',
    iata: '',
    icao: 'ESSZ',
    latitude: 59.1011009216309,
    longitude: 17.2110996246338,
    altitude: 0,
    schengen: true
  },
  {
    id: 5360,
    name: 'Ängelholm-Helsingborg Airport',
    city: 'Ängelholm',
    country: 'Sweden',
    iata: 'AGH',
    icao: 'ESTA',
    latitude: 56.2961006164551,
    longitude: 12.8471002578735,
    altitude: 68,
    schengen: true
  },
  {
    id: 5361,
    name: 'Ljungbyhed Airport',
    city: 'Ljungbyhed',
    country: 'Sweden',
    iata: '',
    icao: 'ESTL',
    latitude: 56.0828018188477,
    longitude: 13.2124996185303,
    altitude: 140,
    schengen: true
  },
  {
    id: 5362,
    name: 'Storuman Airport',
    city: 'Mohed',
    country: 'Sweden',
    iata: 'SQO',
    icao: 'ESUD',
    latitude: 64.9608993530273,
    longitude: 17.6965999603271,
    altitude: 915,
    schengen: true
  },
  {
    id: 5363,
    name: 'Kalixfors Airport',
    city: 'Kalixfors',
    country: 'Sweden',
    iata: '',
    icao: 'ESUK',
    latitude: 67.7648010253906,
    longitude: 20.2572002410889,
    altitude: 1549,
    schengen: true
  },
  {
    id: 5364,
    name: 'Pajala Airport',
    city: 'Pajala',
    country: 'Sweden',
    iata: 'PJA',
    icao: 'ESUP',
    latitude: 67.2455978393555,
    longitude: 23.0688991546631,
    altitude: 542,
    schengen: true
  },
  {
    id: 5365,
    name: 'Hemavan Airport',
    city: 'Hemavan',
    country: 'Sweden',
    iata: 'HMV',
    icao: 'ESUT',
    latitude: 65.8060989379883,
    longitude: 15.082799911499,
    altitude: 1503,
    schengen: true
  },
  {
    id: 5366,
    name: 'Zermatt  Heliport',
    city: 'Zermatt',
    country: 'Switzerland',
    iata: '',
    icao: 'LSEZ',
    latitude: 46.0293197631836,
    longitude: 7.75336647033691,
    altitude: 0,
    schengen: true
  },
  {
    id: 5367,
    name: 'Les Eplatures Airport',
    city: 'Les Eplatures',
    country: 'Switzerland',
    iata: '',
    icao: 'LSGC',
    latitude: 47.0839004517,
    longitude: 6.79284000397,
    altitude: 3368,
    schengen: true
  },
  {
    id: 5368,
    name: 'Ecuvillens Airport',
    city: 'Ecuvillens',
    country: 'Switzerland',
    iata: '',
    icao: 'LSGE',
    latitude: 46.7550010681152,
    longitude: 7.07610988616943,
    altitude: 2293,
    schengen: true
  },
  {
    id: 5369,
    name: 'Geneva Cointrin International Airport',
    city: 'Geneva',
    country: 'Switzerland',
    iata: 'GVA',
    icao: 'LSGG',
    latitude: 46.2380981445313,
    longitude: 6.10895013809204,
    altitude: 1411,
    schengen: true
  },
  {
    id: 5370,
    name: 'Saanen Airport',
    city: 'Saanen',
    country: 'Switzerland',
    iata: '',
    icao: 'LSGK',
    latitude: 46.4874992371,
    longitude: 7.25083017349,
    altitude: 3307,
    schengen: true
  },
  {
    id: 5371,
    name: 'Lausanne-Blécherette Airport',
    city: 'Lausanne',
    country: 'Switzerland',
    iata: 'QLS',
    icao: 'LSGL',
    latitude: 46.54529953,
    longitude: 6.61667013168,
    altitude: 2041,
    schengen: true
  },
  {
    id: 5372,
    name: 'Neuchatel Airport',
    city: 'Neuchatel',
    country: 'Switzerland',
    iata: 'QNC',
    icao: 'LSGN',
    latitude: 46.9575004578,
    longitude: 6.86471986771,
    altitude: 1427,
    schengen: true
  },
  {
    id: 5373,
    name: 'La Côte Airport',
    city: 'Prangins',
    country: 'Switzerland',
    iata: '',
    icao: 'LSGP',
    latitude: 46.4063987731934,
    longitude: 6.25805997848511,
    altitude: 1352,
    schengen: true
  },
  {
    id: 5374,
    name: 'Reichenbach Air Base',
    city: 'Reichenbach im Kandertal',
    country: 'Switzerland',
    iata: '',
    icao: 'LSGR',
    latitude: 46.6136016845703,
    longitude: 7.67778015136719,
    altitude: 2385,
    schengen: true
  },
  {
    id: 5375,
    name: 'Sion Airport',
    city: 'Sion',
    country: 'Switzerland',
    iata: 'SIR',
    icao: 'LSGS',
    latitude: 46.2196006775,
    longitude: 7.32675981522,
    altitude: 1585,
    schengen: true
  },
  {
    id: 5376,
    name: 'Yverdon-les-Bains Airport',
    city: 'Yverdon-Les-Bains',
    country: 'Switzerland',
    iata: '',
    icao: 'LSGY',
    latitude: 46.7619018555,
    longitude: 6.61332988739,
    altitude: 1421,
    schengen: true
  },
  {
    id: 5377,
    name: 'Alpnach Air Base',
    city: 'Alpnach',
    country: 'Switzerland',
    iata: '',
    icao: 'LSMA',
    latitude: 46.943901,
    longitude: 8.28417,
    altitude: 1460,
    schengen: true
  },
  {
    id: 5378,
    name: 'Dübendorf Air Base',
    city: 'Dubendorf',
    country: 'Switzerland',
    iata: '',
    icao: 'LSMD',
    latitude: 47.398601532,
    longitude: 8.648229599,
    altitude: 1470,
    schengen: true
  },
  {
    id: 5379,
    name: 'Emmen Air Base',
    city: 'Emmen',
    country: 'Switzerland',
    iata: 'EML',
    icao: 'LSME',
    latitude: 47.092444,
    longitude: 8.305184,
    altitude: 1400,
    schengen: true
  },
  {
    id: 5380,
    name: 'Mollis Airport',
    city: 'Mollis',
    country: 'Switzerland',
    iata: '',
    icao: 'LSMF',
    latitude: 47.0788993835449,
    longitude: 9.06482982635498,
    altitude: 1485,
    schengen: true
  },
  {
    id: 5381,
    name: 'Interlaken Air Base',
    city: 'Interlaken',
    country: 'Switzerland',
    iata: 'ZIN',
    icao: 'LSMI',
    latitude: 46.6766014,
    longitude: 7.8790798,
    altitude: 0,
    schengen: true
  },
  {
    id: 5382,
    name: 'Lodrino Air Base',
    city: 'Lodrino',
    country: 'Switzerland',
    iata: '',
    icao: 'LSML',
    latitude: 46.2958984375,
    longitude: 8.99213027954102,
    altitude: 0,
    schengen: true
  },
  {
    id: 5383,
    name: 'Meiringen Airport',
    city: 'Meiringen',
    country: 'Switzerland',
    iata: '',
    icao: 'LSMM',
    latitude: 46.7433013916016,
    longitude: 8.10999965667725,
    altitude: 1895,
    schengen: true
  },
  {
    id: 5384,
    name: 'Payerne Air Base',
    city: 'Payerne',
    country: 'Switzerland',
    iata: '',
    icao: 'LSMP',
    latitude: 46.8432,
    longitude: 6.91506004333,
    altitude: 1465,
    schengen: true
  },
  {
    id: 5385,
    name: 'Amlikon Glider Airport',
    city: 'Amlikon',
    country: 'Switzerland',
    iata: '',
    icao: 'LSPA',
    latitude: 47.5741996765137,
    longitude: 9.04749965667725,
    altitude: 1371,
    schengen: true
  },
  {
    id: 5386,
    name: 'Ambri Airport',
    city: 'Quinto',
    country: 'Switzerland',
    iata: '',
    icao: 'LSPM',
    latitude: 46.512501,
    longitude: 8.68978,
    altitude: 3241,
    schengen: true
  },
  {
    id: 5387,
    name: 'Triengen Airport',
    city: 'Triengen',
    country: 'Switzerland',
    iata: '',
    icao: 'LSPN',
    latitude: 47.2266998291016,
    longitude: 8.07806015014648,
    altitude: 1594,
    schengen: true
  },
  {
    id: 5388,
    name: 'Olten Airport',
    city: 'Olten',
    country: 'Switzerland',
    iata: '',
    icao: 'LSPO',
    latitude: 47.3443984985352,
    longitude: 7.88944005966187,
    altitude: 0,
    schengen: true
  },
  {
    id: 5389,
    name: 'Wangen-Lachen Airport',
    city: 'Wangen-Lachen',
    country: 'Switzerland',
    iata: '',
    icao: 'LSPV',
    latitude: 47.2047,
    longitude: 8.8675,
    altitude: 1335,
    schengen: true
  },
  {
    id: 5390,
    name: 'Raron Airport',
    city: 'Raron',
    country: 'Switzerland',
    iata: '',
    icao: 'LSTA',
    latitude: 46.3036003112793,
    longitude: 7.82332992553711,
    altitude: 2029,
    schengen: true
  },
  {
    id: 5391,
    name: 'St Stephan Airport',
    city: 'St.Stephan',
    country: 'Switzerland',
    iata: '',
    icao: 'LSTS',
    latitude: 46.4973983764648,
    longitude: 7.41256999969482,
    altitude: 3304,
    schengen: true
  },
  {
    id: 5392,
    name: 'Balzers Heliport',
    city: 'Balzers',
    country: 'Switzerland',
    iata: '',
    icao: 'LSXB',
    latitude: 47.0681,
    longitude: 9.48111,
    altitude: 1585,
    schengen: true
  },
  {
    id: 5393,
    name: 'Lugano Airport',
    city: 'Lugano',
    country: 'Switzerland',
    iata: 'LUG',
    icao: 'LSZA',
    latitude: 46.0042991638,
    longitude: 8.9105796814,
    altitude: 915,
    schengen: true
  },
  {
    id: 5394,
    name: 'Bern Belp Airport',
    city: 'Bern',
    country: 'Switzerland',
    iata: 'BRN',
    icao: 'LSZB',
    latitude: 46.914100647,
    longitude: 7.49714994431,
    altitude: 1674,
    schengen: true
  },
  {
    id: 5395,
    name: 'Buochs Airport',
    city: 'Buochs',
    country: 'Switzerland',
    iata: 'BXO',
    icao: 'LSZC',
    latitude: 46.974444,
    longitude: 8.396944,
    altitude: 1475,
    schengen: true
  },
  {
    id: 5396,
    name: 'Birrfeld Airport',
    city: 'Birrfeld',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZF',
    latitude: 47.4436,
    longitude: 8.23361,
    altitude: 1300,
    schengen: true
  },
  {
    id: 5397,
    name: 'Grenchen Airport',
    city: 'Grenchen',
    country: 'Switzerland',
    iata: 'ZHI',
    icao: 'LSZG',
    latitude: 47.181599,
    longitude: 7.41719,
    altitude: 1411,
    schengen: true
  },
  {
    id: 5398,
    name: 'Zürich Airport',
    city: 'Zurich',
    country: 'Switzerland',
    iata: 'ZRH',
    icao: 'LSZH',
    latitude: 47.464699,
    longitude: 8.54917,
    altitude: 1416,
    schengen: true
  },
  {
    id: 5399,
    name: 'Courtelary Airport',
    city: 'Courtelary ',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZJ',
    latitude: 47.1836013793945,
    longitude: 7.09082984924316,
    altitude: 2247,
    schengen: true
  },
  {
    id: 5400,
    name: 'Speck-Fehraltorf Airport',
    city: 'Fehraltorf',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZK',
    latitude: 47.3763999938965,
    longitude: 8.75749969482422,
    altitude: 1748,
    schengen: true
  },
  {
    id: 5401,
    name: 'Locarno Airport',
    city: 'Locarno',
    country: 'Switzerland',
    iata: 'ZJI',
    icao: 'LSZL',
    latitude: 46.1608009338,
    longitude: 8.87860965729,
    altitude: 650,
    schengen: true
  },
  {
    id: 5402,
    name: 'Luzern-Beromunster Airport',
    city: 'Lucerne',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZO',
    latitude: 47.189998626709,
    longitude: 8.20471954345703,
    altitude: 2146,
    schengen: true
  },
  {
    id: 5403,
    name: 'Biel-Kappelen Airport',
    city: 'Biel',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZP',
    latitude: 47.0891990661621,
    longitude: 7.28999996185303,
    altitude: 1437,
    schengen: true
  },
  {
    id: 5404,
    name: 'Bressaucourt Airport',
    city: 'Bressaucourt-Porrentruy',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZQ',
    latitude: 47.392677,
    longitude: 7.028648,
    altitude: 1866,
    schengen: true
  },
  {
    id: 5405,
    name: 'St Gallen Altenrhein Airport',
    city: 'Altenrhein',
    country: 'Switzerland',
    iata: 'ACH',
    icao: 'LSZR',
    latitude: 47.4850006104,
    longitude: 9.56077003479,
    altitude: 1306,
    schengen: true
  },
  {
    id: 5406,
    name: 'Samedan Airport',
    city: 'Samedan',
    country: 'Switzerland',
    iata: 'SMV',
    icao: 'LSZS',
    latitude: 46.5340995788574,
    longitude: 9.88411045074463,
    altitude: 5600,
    schengen: true
  },
  {
    id: 5407,
    name: 'Lommis Airfield',
    city: 'Lommis',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZT',
    latitude: 47.524399,
    longitude: 9.00306,
    altitude: 1539,
    schengen: true
  },
  {
    id: 5408,
    name: 'Thun Airport',
    city: 'Thun',
    country: 'Switzerland',
    iata: '',
    icao: 'LSZW',
    latitude: 46.756401062,
    longitude: 7.60056018829,
    altitude: 1837,
    schengen: true
  },
  {
    id: 5409,
    name: 'Aleppo International Airport',
    city: 'Aleppo',
    country: 'Syria',
    iata: 'ALP',
    icao: 'OSAP',
    latitude: 36.1806983947754,
    longitude: 37.2243995666504,
    altitude: 1276,
    schengen: false
  },
  {
    id: 5410,
    name: 'Damascus International Airport',
    city: 'Damascus',
    country: 'Syria',
    iata: 'DAM',
    icao: 'OSDI',
    latitude: 33.4114990234375,
    longitude: 36.5155982971191,
    altitude: 2020,
    schengen: false
  },
  {
    id: 5411,
    name: 'Deir ez-Zor Air Base',
    city: 'Deire Zor',
    country: 'Syria',
    iata: 'DEZ',
    icao: 'OSDZ',
    latitude: 35.2854,
    longitude: 40.175999,
    altitude: 700,
    schengen: false
  },
  {
    id: 5412,
    name: 'Kamishly Airport',
    city: 'Kamishly',
    country: 'Syria',
    iata: 'KAC',
    icao: 'OSKL',
    latitude: 37.0205993652344,
    longitude: 41.1913986206055,
    altitude: 1480,
    schengen: false
  },
  {
    id: 5413,
    name: 'Bassel Al-Assad International Airport',
    city: 'Latakia',
    country: 'Syria',
    iata: 'LTK',
    icao: 'OSLK',
    latitude: 35.4011001586914,
    longitude: 35.9486999511719,
    altitude: 157,
    schengen: false
  },
  {
    id: 5414,
    name: 'Palmyra Airport',
    city: 'Palmyra',
    country: 'Syria',
    iata: 'PMS',
    icao: 'OSPR',
    latitude: 34.5574,
    longitude: 38.316898,
    altitude: 1322,
    schengen: false
  },
  {
    id: 5415,
    name: 'Gangshan Air Force Base',
    city: 'Gangshan',
    country: 'Taiwan',
    iata: '',
    icao: 'RCAY',
    latitude: 22.7824993133545,
    longitude: 120.263000488281,
    altitude: 34,
    schengen: false
  },
  {
    id: 5416,
    name: 'Kinmen Airport',
    city: 'Kinmen',
    country: 'Taiwan',
    iata: 'KNH',
    icao: 'RCBS',
    latitude: 24.4279,
    longitude: 118.359001,
    altitude: 93,
    schengen: false
  },
  {
    id: 5417,
    name: 'Pingtung South Airport',
    city: 'Pingtung',
    country: 'Taiwan',
    iata: '',
    icao: 'RCDC',
    latitude: 22.672399520874,
    longitude: 120.46199798584,
    altitude: 78,
    schengen: false
  },
  {
    id: 5418,
    name: 'Longtan Air Base',
    city: 'Longtang',
    country: 'Taiwan',
    iata: '',
    icao: 'RCDI',
    latitude: 24.8551006317139,
    longitude: 121.237998962402,
    altitude: 790,
    schengen: false
  },
  {
    id: 5419,
    name: 'Matsu Nangan Airport',
    city: 'Matsu Islands',
    country: 'Taiwan',
    iata: 'LZN',
    icao: 'RCFG',
    latitude: 26.1598,
    longitude: 119.958,
    altitude: 232,
    schengen: false
  },
  {
    id: 5420,
    name: 'Taitung Airport',
    city: 'Fengnin',
    country: 'Taiwan',
    iata: 'TTT',
    icao: 'RCFN',
    latitude: 22.7549991607666,
    longitude: 121.101997375488,
    altitude: 143,
    schengen: false
  },
  {
    id: 5421,
    name: 'Lyudao Airport',
    city: 'Green Island',
    country: 'Taiwan',
    iata: 'GNI',
    icao: 'RCGI',
    latitude: 22.673900604248,
    longitude: 121.466003417969,
    altitude: 28,
    schengen: false
  },
  {
    id: 5422,
    name: 'Taoyuan Air Base',
    city: 'Taoyuan',
    country: 'Taiwan',
    iata: '',
    icao: 'RCGM',
    latitude: 25.0566005706787,
    longitude: 121.244262695313,
    altitude: 144,
    schengen: false
  },
  {
    id: 5423,
    name: 'Kaohsiung International Airport',
    city: 'Kaohsiung',
    country: 'Taiwan',
    iata: 'KHH',
    icao: 'RCKH',
    latitude: 22.5771007537842,
    longitude: 120.349998474121,
    altitude: 31,
    schengen: false
  },
  {
    id: 5424,
    name: 'Chiayi Airport',
    city: 'Chiayi',
    country: 'Taiwan',
    iata: 'CYI',
    icao: 'RCKU',
    latitude: 23.461799621582,
    longitude: 120.392997741699,
    altitude: 85,
    schengen: false
  },
  {
    id: 5425,
    name: 'Hengchun Airport',
    city: 'Hengchun',
    country: 'Taiwan',
    iata: 'HCN',
    icao: 'RCKW',
    latitude: 22.0410995483398,
    longitude: 120.730003356934,
    altitude: 46,
    schengen: false
  },
  {
    id: 5426,
    name: 'Taichung Airport',
    city: 'Taichung',
    country: 'Taiwan',
    iata: 'TXG',
    icao: 'RCLG',
    latitude: 24.18630027771,
    longitude: 120.653999328613,
    altitude: 369,
    schengen: false
  },
  {
    id: 5427,
    name: 'Lanyu Airport',
    city: 'Lanyu',
    country: 'Taiwan',
    iata: 'KYD',
    icao: 'RCLY',
    latitude: 22.0270004272461,
    longitude: 121.535003662109,
    altitude: 44,
    schengen: false
  },
  {
    id: 5428,
    name: 'Taichung Ching Chuang Kang Airport',
    city: 'Taichung',
    country: 'Taiwan',
    iata: 'RMQ',
    icao: 'RCMQ',
    latitude: 24.2646999359131,
    longitude: 120.621002197266,
    altitude: 663,
    schengen: false
  },
  {
    id: 5429,
    name: 'Matsu Beigan Airport',
    city: 'Matsu Islands',
    country: 'Taiwan',
    iata: 'MFK',
    icao: 'RCMT',
    latitude: 26.224199,
    longitude: 120.002998,
    altitude: 41,
    schengen: false
  },
  {
    id: 5430,
    name: 'Tainan Airport',
    city: 'Tainan',
    country: 'Taiwan',
    iata: 'TNN',
    icao: 'RCNN',
    latitude: 22.9503993988037,
    longitude: 120.206001281738,
    altitude: 63,
    schengen: false
  },
  {
    id: 5431,
    name: 'Hsinchu Air Base',
    city: 'Hsinchu',
    country: 'Taiwan',
    iata: 'HSZ',
    icao: 'RCPO',
    latitude: 24.8180007935,
    longitude: 120.939002991,
    altitude: 26,
    schengen: false
  },
  {
    id: 5432,
    name: 'Makung Airport',
    city: 'Makung',
    country: 'Taiwan',
    iata: 'MZG',
    icao: 'RCQC',
    latitude: 23.5687007904053,
    longitude: 119.627998352051,
    altitude: 103,
    schengen: false
  },
  {
    id: 5433,
    name: 'Chihhang Air Base',
    city: 'Taitung',
    country: 'Taiwan',
    iata: '',
    icao: 'RCQS',
    latitude: 22.7931003570557,
    longitude: 121.181999206543,
    altitude: 121,
    schengen: false
  },
  {
    id: 5434,
    name: 'Pingtung North Airport',
    city: 'Pingtung',
    country: 'Taiwan',
    iata: 'PIF',
    icao: 'RCSQ',
    latitude: 22.7001991271973,
    longitude: 120.482002258301,
    altitude: 97,
    schengen: false
  },
  {
    id: 5435,
    name: 'Taipei Songshan Airport',
    city: 'Taipei',
    country: 'Taiwan',
    iata: 'TSA',
    icao: 'RCSS',
    latitude: 25.0694007873535,
    longitude: 121.552001953125,
    altitude: 18,
    schengen: false
  },
  {
    id: 5436,
    name: 'Taiwan Taoyuan International Airport',
    city: 'Taipei',
    country: 'Taiwan',
    iata: 'TPE',
    icao: 'RCTP',
    latitude: 25.0777,
    longitude: 121.233002,
    altitude: 106,
    schengen: false
  },
  {
    id: 5437,
    name: 'Wang-an Airport',
    city: 'Wang An',
    country: 'Taiwan',
    iata: 'WOT',
    icao: 'RCWA',
    latitude: 23.3673725128174,
    longitude: 119.502777099609,
    altitude: 115,
    schengen: false
  },
  {
    id: 5438,
    name: 'Hualien Airport',
    city: 'Hualien',
    country: 'Taiwan',
    iata: 'HUN',
    icao: 'RCYU',
    latitude: 24.023099899292,
    longitude: 121.61799621582,
    altitude: 52,
    schengen: false
  },
  {
    id: 5439,
    name: 'Dushanbe Airport',
    city: 'Dushanbe',
    country: 'Tajikistan',
    iata: 'DYU',
    icao: 'UTDD',
    latitude: 38.5433006287,
    longitude: 68.8249969482,
    altitude: 2575,
    schengen: false
  },
  {
    id: 5440,
    name: 'Kulob Airport',
    city: 'Kulyab',
    country: 'Tajikistan',
    iata: 'TJU',
    icao: 'UTDK',
    latitude: 37.9880981445312,
    longitude: 69.8050003051758,
    altitude: 2293,
    schengen: false
  },
  {
    id: 5441,
    name: 'Khudzhand Airport',
    city: 'Khudzhand',
    country: 'Tajikistan',
    iata: 'LBD',
    icao: 'UTDL',
    latitude: 40.2154006958008,
    longitude: 69.6947021484375,
    altitude: 1450,
    schengen: false
  },
  {
    id: 5442,
    name: 'Qurghonteppa International Airport',
    city: 'Kurgan Tyube',
    country: 'Tajikistan',
    iata: 'KQT',
    icao: 'UTDT',
    latitude: 37.8664016723633,
    longitude: 68.8647003173828,
    altitude: 1473,
    schengen: false
  },
  {
    id: 5443,
    name: 'Arusha Airport',
    city: 'Arusha',
    country: 'Tanzania',
    iata: 'ARK',
    icao: 'HTAR',
    latitude: -3.36778998374939,
    longitude: 36.63330078125,
    altitude: 4550,
    schengen: false
  },
  {
    id: 5444,
    name: 'Bukoba Airport',
    city: 'Bukoba',
    country: 'Tanzania',
    iata: 'BKZ',
    icao: 'HTBU',
    latitude: -1.332,
    longitude: 31.8212,
    altitude: 3784,
    schengen: false
  },
  {
    id: 5445,
    name: 'Julius Nyerere International Airport',
    city: 'Dar Es Salaam',
    country: 'Tanzania',
    iata: 'DAR',
    icao: 'HTDA',
    latitude: -6.87811,
    longitude: 39.202599,
    altitude: 182,
    schengen: false
  },
  {
    id: 5446,
    name: 'Dodoma Airport',
    city: 'Dodoma',
    country: 'Tanzania',
    iata: 'DOD',
    icao: 'HTDO',
    latitude: -6.17044019699097,
    longitude: 35.7526016235352,
    altitude: 3673,
    schengen: false
  },
  {
    id: 5447,
    name: 'Iringa Airport',
    city: 'Iringa',
    country: 'Tanzania',
    iata: 'IRI',
    icao: 'HTIR',
    latitude: -7.66863012313843,
    longitude: 35.7521018981934,
    altitude: 4678,
    schengen: false
  },
  {
    id: 5448,
    name: 'Kigoma Airport',
    city: 'Kigoma',
    country: 'Tanzania',
    iata: 'TKQ',
    icao: 'HTKA',
    latitude: -4.8862,
    longitude: 29.6709,
    altitude: 2700,
    schengen: false
  },
  {
    id: 5449,
    name: 'Kilwa Masoko Airport',
    city: 'Kilwa Masoko',
    country: 'Tanzania',
    iata: 'KIY',
    icao: 'HTKI',
    latitude: -8.91123,
    longitude: 39.508619,
    altitude: 50,
    schengen: false
  },
  {
    id: 5450,
    name: 'Kilimanjaro International Airport',
    city: 'Kilimanjaro',
    country: 'Tanzania',
    iata: 'JRO',
    icao: 'HTKJ',
    latitude: -3.42940998077,
    longitude: 37.0745010376,
    altitude: 2932,
    schengen: false
  },
  {
    id: 5451,
    name: 'Lindi Airport',
    city: 'Lindi',
    country: 'Tanzania',
    iata: 'LDI',
    icao: 'HTLI',
    latitude: -9.85111,
    longitude: 39.757801,
    altitude: 100,
    schengen: false
  },
  {
    id: 5452,
    name: 'Lake Manyara Airport',
    city: 'Lake Manyara',
    country: 'Tanzania',
    iata: 'LKY',
    icao: 'HTLM',
    latitude: -3.37631011009216,
    longitude: 35.8182983398437,
    altitude: 4150,
    schengen: false
  },
  {
    id: 5453,
    name: 'Mafia Island Airport',
    city: 'Mafia Island',
    country: 'Tanzania',
    iata: 'MFA',
    icao: 'HTMA',
    latitude: -7.917478,
    longitude: 39.668502,
    altitude: 60,
    schengen: false
  },
  {
    id: 5454,
    name: 'Mbeya Airport',
    city: 'Mbeya',
    country: 'Tanzania',
    iata: '',
    icao: 'HTMB',
    latitude: -8.919444,
    longitude: 33.463889,
    altitude: 5600,
    schengen: false
  },
  {
    id: 5455,
    name: 'Morogoro Airport',
    city: 'Morogoro',
    country: 'Tanzania',
    iata: '',
    icao: 'HTMG',
    latitude: -6.79722023010254,
    longitude: 37.6530990600586,
    altitude: 1676,
    schengen: false
  },
  {
    id: 5456,
    name: 'Mpanda Airport',
    city: 'Mpanda',
    country: 'Tanzania',
    iata: '',
    icao: 'HTMP',
    latitude: -6.355374,
    longitude: 31.084116,
    altitude: 3520,
    schengen: false
  },
  {
    id: 5457,
    name: 'Moshi Airport',
    city: 'Moshi',
    country: 'Tanzania',
    iata: 'QSI',
    icao: 'HTMS',
    latitude: -3.36332988739014,
    longitude: 37.3269004821777,
    altitude: 2801,
    schengen: false
  },
  {
    id: 5458,
    name: 'Mtwara Airport',
    city: 'Mtwara',
    country: 'Tanzania',
    iata: 'MYW',
    icao: 'HTMT',
    latitude: -10.3390998840332,
    longitude: 40.1818008422852,
    altitude: 371,
    schengen: false
  },
  {
    id: 5459,
    name: 'Musoma Airport',
    city: 'Musoma',
    country: 'Tanzania',
    iata: 'MUZ',
    icao: 'HTMU',
    latitude: -1.503,
    longitude: 33.8021,
    altitude: 3806,
    schengen: false
  },
  {
    id: 5460,
    name: 'Mwanza Airport',
    city: 'Mwanza',
    country: 'Tanzania',
    iata: 'MWZ',
    icao: 'HTMW',
    latitude: -2.4444899559021,
    longitude: 32.9327011108398,
    altitude: 3763,
    schengen: false
  },
  {
    id: 5461,
    name: 'Pemba Airport',
    city: 'Pemba',
    country: 'Tanzania',
    iata: 'PMA',
    icao: 'HTPE',
    latitude: -5.25725984573364,
    longitude: 39.8114013671875,
    altitude: 80,
    schengen: false
  },
  {
    id: 5462,
    name: 'Seronera Airport',
    city: 'Seronera',
    country: 'Tanzania',
    iata: 'SEU',
    icao: 'HTSN',
    latitude: -2.45806,
    longitude: 34.822498,
    altitude: 5080,
    schengen: false
  },
  {
    id: 5463,
    name: 'Songea Airport',
    city: 'Songea',
    country: 'Tanzania',
    iata: 'SGX',
    icao: 'HTSO',
    latitude: -10.6829996109009,
    longitude: 35.5830001831055,
    altitude: 3445,
    schengen: false
  },
  {
    id: 5464,
    name: 'Shinyanga Airport',
    city: 'Shinyanga',
    country: 'Tanzania',
    iata: 'SHY',
    icao: 'HTSY',
    latitude: -3.6093,
    longitude: 33.5035,
    altitude: 3800,
    schengen: false
  },
  {
    id: 5465,
    name: 'Tabora Airport',
    city: 'Tabora',
    country: 'Tanzania',
    iata: 'TBO',
    icao: 'HTTB',
    latitude: -5.0763897895813,
    longitude: 32.8333015441895,
    altitude: 3868,
    schengen: false
  },
  {
    id: 5466,
    name: 'Tanga Airport',
    city: 'Tanga',
    country: 'Tanzania',
    iata: 'TGT',
    icao: 'HTTG',
    latitude: -5.09236001968384,
    longitude: 39.0712013244629,
    altitude: 129,
    schengen: false
  },
  {
    id: 5467,
    name: 'West Kilimanjaro Airport',
    city: 'Kilimanjaro',
    country: 'Tanzania',
    iata: '',
    icao: 'HTWK',
    latitude: -3.04999995231628,
    longitude: 37,
    altitude: 4300,
    schengen: false
  },
  {
    id: 5468,
    name: 'Abeid Amani Karume International Airport',
    city: 'Zanzibar',
    country: 'Tanzania',
    iata: 'ZNZ',
    icao: 'HTZA',
    latitude: -6.22202,
    longitude: 39.224899,
    altitude: 54,
    schengen: false
  },
  {
    id: 5469,
    name: 'Don Mueang International Airport',
    city: 'Bangkok',
    country: 'Thailand',
    iata: 'DMK',
    icao: 'VTBD',
    latitude: 13.9125995636,
    longitude: 100.607002258,
    altitude: 9,
    schengen: false
  },
  {
    id: 5470,
    name: 'Kamphaeng Saen Airport',
    city: 'Nakhon Pathom',
    country: 'Thailand',
    iata: 'KDT',
    icao: 'VTBK',
    latitude: 14.1020002365,
    longitude: 99.9171981812,
    altitude: 30,
    schengen: false
  },
  {
    id: 5471,
    name: 'Khok Kathiam Airport',
    city: 'Lop Buri',
    country: 'Thailand',
    iata: '',
    icao: 'VTBL',
    latitude: 14.8746004105,
    longitude: 100.663002014,
    altitude: 123,
    schengen: false
  },
  {
    id: 5472,
    name: 'Trat Airport',
    city: 'Trat',
    country: 'Thailand',
    iata: 'TDX',
    icao: 'VTBO',
    latitude: 12.274600029,
    longitude: 102.319000244,
    altitude: 105,
    schengen: false
  },
  {
    id: 5473,
    name: 'Suvarnabhumi Airport',
    city: 'Bangkok',
    country: 'Thailand',
    iata: 'BKK',
    icao: 'VTBS',
    latitude: 13.6810998916626,
    longitude: 100.747001647949,
    altitude: 5,
    schengen: false
  },
  {
    id: 5474,
    name: 'U-Tapao International Airport',
    city: 'Pattaya',
    country: 'Thailand',
    iata: 'UTP',
    icao: 'VTBU',
    latitude: 12.6799001693726,
    longitude: 101.004997253418,
    altitude: 42,
    schengen: false
  },
  {
    id: 5475,
    name: 'Watthana Nakhon Airport',
    city: 'Prachin Buri',
    country: 'Thailand',
    iata: '',
    icao: 'VTBW',
    latitude: 13.7687997817993,
    longitude: 102.315002441406,
    altitude: 200,
    schengen: false
  },
  {
    id: 5476,
    name: 'Chiang Mai International Airport',
    city: 'Chiang Mai',
    country: 'Thailand',
    iata: 'CNX',
    icao: 'VTCC',
    latitude: 18.7667999268,
    longitude: 98.962600708,
    altitude: 1036,
    schengen: false
  },
  {
    id: 5477,
    name: 'Mae Hong Son Airport',
    city: 'Mae Hong Son',
    country: 'Thailand',
    iata: 'HGN',
    icao: 'VTCH',
    latitude: 19.3013000488281,
    longitude: 97.9757995605469,
    altitude: 929,
    schengen: false
  },
  {
    id: 5478,
    name: 'Mae Hong Son Airport',
    city: 'Pai',
    country: 'Thailand',
    iata: 'PYY',
    icao: 'VTCI',
    latitude: 19.3719997406,
    longitude: 98.43699646,
    altitude: 1271,
    schengen: false
  },
  {
    id: 5479,
    name: 'Lampang Airport',
    city: 'Lampang',
    country: 'Thailand',
    iata: 'LPT',
    icao: 'VTCL',
    latitude: 18.2709007263184,
    longitude: 99.5042037963867,
    altitude: 811,
    schengen: false
  },
  {
    id: 5480,
    name: 'Nan Airport',
    city: 'Nan',
    country: 'Thailand',
    iata: 'NNT',
    icao: 'VTCN',
    latitude: 18.8078994750977,
    longitude: 100.782997131348,
    altitude: 685,
    schengen: false
  },
  {
    id: 5481,
    name: 'Phrae Airport',
    city: 'Phrae',
    country: 'Thailand',
    iata: 'PRH',
    icao: 'VTCP',
    latitude: 18.1322002410889,
    longitude: 100.165000915527,
    altitude: 538,
    schengen: false
  },
  {
    id: 5482,
    name: 'Chiang Rai International Airport',
    city: 'Chiang Rai',
    country: 'Thailand',
    iata: 'CEI',
    icao: 'VTCT',
    latitude: 19.952299118,
    longitude: 99.8828964233,
    altitude: 1280,
    schengen: false
  },
  {
    id: 5483,
    name: 'Phetchabun Airport',
    city: 'Phetchabun',
    country: 'Thailand',
    iata: 'PHY',
    icao: 'VTPB',
    latitude: 16.6760005951,
    longitude: 101.194999695,
    altitude: 450,
    schengen: false
  },
  {
    id: 5484,
    name: 'Hua Hin Airport',
    city: 'Prachuap Khiri Khan',
    country: 'Thailand',
    iata: 'HHQ',
    icao: 'VTPH',
    latitude: 12.6361999512,
    longitude: 99.951499939,
    altitude: 62,
    schengen: false
  },
  {
    id: 5485,
    name: 'Takhli Airport',
    city: 'Nakhon Sawan',
    country: 'Thailand',
    iata: 'TKH',
    icao: 'VTPI',
    latitude: 15.277299881,
    longitude: 100.29599762,
    altitude: 107,
    schengen: false
  },
  {
    id: 5486,
    name: 'Sak Long Airport',
    city: 'Phetchabun',
    country: 'Thailand',
    iata: '',
    icao: 'VTPL',
    latitude: 16.8243007659912,
    longitude: 101.250999450684,
    altitude: 500,
    schengen: false
  },
  {
    id: 5487,
    name: 'Mae Sot Airport',
    city: 'Tak',
    country: 'Thailand',
    iata: 'MAQ',
    icao: 'VTPM',
    latitude: 16.6998996734619,
    longitude: 98.5450973510742,
    altitude: 690,
    schengen: false
  },
  {
    id: 5488,
    name: 'Nakhon Sawan Airport',
    city: 'Nakhon Sawan',
    country: 'Thailand',
    iata: '',
    icao: 'VTPN',
    latitude: 15.6730003356934,
    longitude: 100.137001037598,
    altitude: 113,
    schengen: false
  },
  {
    id: 5489,
    name: 'Sukhothai Airport',
    city: 'Sukhothai',
    country: 'Thailand',
    iata: 'THS',
    icao: 'VTPO',
    latitude: 17.238000869751,
    longitude: 99.8181991577149,
    altitude: 179,
    schengen: false
  },
  {
    id: 5490,
    name: 'Phitsanulok Airport',
    city: 'Phitsanulok',
    country: 'Thailand',
    iata: 'PHS',
    icao: 'VTPP',
    latitude: 16.7828998565674,
    longitude: 100.278999328613,
    altitude: 154,
    schengen: false
  },
  {
    id: 5491,
    name: 'Tak Airport',
    city: 'Tak',
    country: 'Thailand',
    iata: 'TKT',
    icao: 'VTPT',
    latitude: 16.8959999084473,
    longitude: 99.253303527832,
    altitude: 478,
    schengen: false
  },
  {
    id: 5492,
    name: 'Khunan Phumipol Airport',
    city: 'Tak',
    country: 'Thailand',
    iata: '',
    icao: 'VTPY',
    latitude: 17.2341995239258,
    longitude: 99.0578994750977,
    altitude: 492,
    schengen: false
  },
  {
    id: 5493,
    name: 'Khoun Khan Airport',
    city: 'Satun',
    country: 'Thailand',
    iata: '',
    icao: 'VTSA',
    latitude: 6.66139984130859,
    longitude: 100.080001831055,
    altitude: 18,
    schengen: false
  },
  {
    id: 5494,
    name: 'Surat Thani Airport',
    city: 'Surat Thani',
    country: 'Thailand',
    iata: 'URT',
    icao: 'VTSB',
    latitude: 9.13259983063,
    longitude: 99.135597229,
    altitude: 20,
    schengen: false
  },
  {
    id: 5495,
    name: 'Narathiwat Airport',
    city: 'Narathiwat',
    country: 'Thailand',
    iata: 'NAW',
    icao: 'VTSC',
    latitude: 6.51991987228394,
    longitude: 101.74299621582,
    altitude: 16,
    schengen: false
  },
  {
    id: 5496,
    name: 'Chumphon Airport',
    city: 'Chumphon',
    country: 'Thailand',
    iata: 'CJM',
    icao: 'VTSE',
    latitude: 10.711199760437,
    longitude: 99.361701965332,
    altitude: 18,
    schengen: false
  },
  {
    id: 5497,
    name: 'Nakhon Si Thammarat Airport',
    city: 'Nakhon Si Thammarat',
    country: 'Thailand',
    iata: 'NST',
    icao: 'VTSF',
    latitude: 8.5396203994751,
    longitude: 99.9447021484375,
    altitude: 13,
    schengen: false
  },
  {
    id: 5498,
    name: 'Krabi Airport',
    city: 'Krabi',
    country: 'Thailand',
    iata: 'KBV',
    icao: 'VTSG',
    latitude: 8.09912014008,
    longitude: 98.9861984253,
    altitude: 82,
    schengen: false
  },
  {
    id: 5499,
    name: 'Songkhla Airport',
    city: 'Songkhla',
    country: 'Thailand',
    iata: 'SGZ',
    icao: 'VTSH',
    latitude: 7.18656015396118,
    longitude: 100.608001708984,
    altitude: 12,
    schengen: false
  },
  {
    id: 5500,
    name: 'Pattani Airport',
    city: 'Pattani',
    country: 'Thailand',
    iata: 'PAN',
    icao: 'VTSK',
    latitude: 6.78545999526978,
    longitude: 101.153999328613,
    altitude: 8,
    schengen: false
  },
  {
    id: 5501,
    name: 'Samui Airport',
    city: 'Ko Samui',
    country: 'Thailand',
    iata: 'USM',
    icao: 'VTSM',
    latitude: 9.54778957367,
    longitude: 100.06199646,
    altitude: 64,
    schengen: false
  },
  {
    id: 5502,
    name: 'Cha Eian Airport',
    city: 'Nakhon Si Thammarat',
    country: 'Thailand',
    iata: '',
    icao: 'VTSN',
    latitude: 8.47115039825,
    longitude: 99.9555969238,
    altitude: 44,
    schengen: false
  },
  {
    id: 5503,
    name: 'Phuket International Airport',
    city: 'Phuket',
    country: 'Thailand',
    iata: 'HKT',
    icao: 'VTSP',
    latitude: 8.1132,
    longitude: 98.316902,
    altitude: 82,
    schengen: false
  },
  {
    id: 5504,
    name: 'Ranong Airport',
    city: 'Ranong',
    country: 'Thailand',
    iata: 'UNN',
    icao: 'VTSR',
    latitude: 9.77762031555176,
    longitude: 98.5855026245117,
    altitude: 57,
    schengen: false
  },
  {
    id: 5505,
    name: 'Hat Yai International Airport',
    city: 'Hat Yai',
    country: 'Thailand',
    iata: 'HDY',
    icao: 'VTSS',
    latitude: 6.93320989609,
    longitude: 100.392997742,
    altitude: 90,
    schengen: false
  },
  {
    id: 5506,
    name: 'Trang Airport',
    city: 'Trang',
    country: 'Thailand',
    iata: 'TST',
    icao: 'VTST',
    latitude: 7.50873994827271,
    longitude: 99.6166000366211,
    altitude: 67,
    schengen: false
  },
  {
    id: 5507,
    name: 'Udon Thani Airport',
    city: 'Udon Thani',
    country: 'Thailand',
    iata: 'UTH',
    icao: 'VTUD',
    latitude: 17.3864002228,
    longitude: 102.788002014,
    altitude: 579,
    schengen: false
  },
  {
    id: 5508,
    name: 'Sakon Nakhon Airport',
    city: 'Sakon Nakhon',
    country: 'Thailand',
    iata: 'SNO',
    icao: 'VTUI',
    latitude: 17.1951007843018,
    longitude: 104.119003295898,
    altitude: 529,
    schengen: false
  },
  {
    id: 5509,
    name: 'Surin Airport',
    city: 'Surin',
    country: 'Thailand',
    iata: 'PXR',
    icao: 'VTUJ',
    latitude: 14.868300437927,
    longitude: 103.49800109863,
    altitude: 478,
    schengen: false
  },
  {
    id: 5510,
    name: 'Khon Kaen Airport',
    city: 'Khon Kaen',
    country: 'Thailand',
    iata: 'KKC',
    icao: 'VTUK',
    latitude: 16.4666004181,
    longitude: 102.783996582,
    altitude: 670,
    schengen: false
  },
  {
    id: 5511,
    name: 'Loei Airport',
    city: 'Loei',
    country: 'Thailand',
    iata: 'LOE',
    icao: 'VTUL',
    latitude: 17.4391002655029,
    longitude: 101.72200012207,
    altitude: 860,
    schengen: false
  },
  {
    id: 5512,
    name: 'Khorat Airport',
    city: 'Nakhon Ratchasima',
    country: 'Thailand',
    iata: '',
    icao: 'VTUN',
    latitude: 14.9344997406,
    longitude: 102.07900238,
    altitude: 729,
    schengen: false
  },
  {
    id: 5513,
    name: 'Buri Ram Airport',
    city: 'Buri Ram',
    country: 'Thailand',
    iata: 'BFV',
    icao: 'VTUO',
    latitude: 15.2294998168945,
    longitude: 103.252998352051,
    altitude: 590,
    schengen: false
  },
  {
    id: 5514,
    name: 'Nakhon Ratchasima Airport',
    city: 'Nakhon Ratchasima',
    country: 'Thailand',
    iata: 'NAK',
    icao: 'VTUQ',
    latitude: 14.9495000839233,
    longitude: 102.313003540039,
    altitude: 765,
    schengen: false
  },
  {
    id: 5515,
    name: 'Rob Muang Airport',
    city: 'Roi Et',
    country: 'Thailand',
    iata: '',
    icao: 'VTUR',
    latitude: 16.0704002380371,
    longitude: 103.646003723145,
    altitude: 459,
    schengen: false
  },
  {
    id: 5516,
    name: 'Ubon Ratchathani Airport',
    city: 'Ubon Ratchathani',
    country: 'Thailand',
    iata: 'UBP',
    icao: 'VTUU',
    latitude: 15.2512998581,
    longitude: 104.870002747,
    altitude: 406,
    schengen: false
  },
  {
    id: 5517,
    name: 'Roi Et Airport',
    city: 'Roi Et',
    country: 'Thailand',
    iata: 'ROI',
    icao: 'VTUV',
    latitude: 16.1168003082275,
    longitude: 103.774002075195,
    altitude: 451,
    schengen: false
  },
  {
    id: 5518,
    name: 'Nakhon Phanom Airport',
    city: 'Nakhon Phanom',
    country: 'Thailand',
    iata: 'KOP',
    icao: 'VTUW',
    latitude: 17.3838005065918,
    longitude: 104.642997741699,
    altitude: 587,
    schengen: false
  },
  {
    id: 5519,
    name: 'Sansanné-Mango Airport',
    city: 'Mango',
    country: 'Togo',
    iata: '',
    icao: 'DXMG',
    latitude: 10.373010635376,
    longitude: 0.471382141113281,
    altitude: 460,
    schengen: false
  },
  {
    id: 5520,
    name: 'Niamtougou International Airport',
    city: 'Niatougou',
    country: 'Togo',
    iata: 'LRL',
    icao: 'DXNG',
    latitude: 9.76733016967773,
    longitude: 1.09124994277954,
    altitude: 1515,
    schengen: false
  },
  {
    id: 5521,
    name: 'Sokodé Airport',
    city: 'Sokode',
    country: 'Togo',
    iata: '',
    icao: 'DXSK',
    latitude: 8.994275,
    longitude: 1.153004,
    altitude: 1214,
    schengen: false
  },
  {
    id: 5522,
    name: 'Lomé-Tokoin Airport',
    city: 'Lome',
    country: 'Togo',
    iata: 'LFW',
    icao: 'DXXX',
    latitude: 6.16560983657837,
    longitude: 1.25451004505157,
    altitude: 72,
    schengen: false
  },
  {
    id: 5523,
    name: 'Kaufana Airport',
    city: 'Eua Island',
    country: 'Tonga',
    iata: 'EUA',
    icao: 'NFTE',
    latitude: -21.3782997131,
    longitude: -174.957992554,
    altitude: 325,
    schengen: false
  },
  {
    id: 5524,
    name: "Fua'amotu International Airport",
    city: 'Tongatapu',
    country: 'Tonga',
    iata: 'TBU',
    icao: 'NFTF',
    latitude: -21.2411994934082,
    longitude: -175.149993896484,
    altitude: 126,
    schengen: false
  },
  {
    id: 5525,
    name: 'Lifuka Island Airport',
    city: 'Lifuka',
    country: 'Tonga',
    iata: 'HPA',
    icao: 'NFTL',
    latitude: -19.7770004272461,
    longitude: -174.341003417969,
    altitude: 31,
    schengen: false
  },
  {
    id: 5526,
    name: "Mata'aho Airport",
    city: "Angaha, Niuafo'ou Island",
    country: 'Tonga',
    iata: 'NFO',
    icao: 'NFTO',
    latitude: -15.5707998276,
    longitude: -175.632995605,
    altitude: 160,
    schengen: false
  },
  {
    id: 5527,
    name: 'Kuini Lavenia Airport',
    city: 'Niuatoputapu',
    country: 'Tonga',
    iata: 'NTT',
    icao: 'NFTP',
    latitude: -15.977297,
    longitude: -173.791089,
    altitude: 30,
    schengen: false
  },
  {
    id: 5528,
    name: "Vava'u International Airport",
    city: "Vava'u",
    country: 'Tonga',
    iata: 'VAV',
    icao: 'NFTV',
    latitude: -18.5853004455566,
    longitude: -173.962005615234,
    altitude: 236,
    schengen: false
  },
  {
    id: 5529,
    name: 'Tobago-Crown Point Airport',
    city: 'Scarborough',
    country: 'Trinidad and Tobago',
    iata: 'TAB',
    icao: 'TTCP',
    latitude: 11.1497001647949,
    longitude: -60.8321990966797,
    altitude: 38,
    schengen: false
  },
  {
    id: 5530,
    name: 'Piarco International Airport',
    city: 'Port-of-spain',
    country: 'Trinidad and Tobago',
    iata: 'POS',
    icao: 'TTPP',
    latitude: 10.5953998565674,
    longitude: -61.3372001647949,
    altitude: 58,
    schengen: false
  },
  {
    id: 5531,
    name: 'Tabarka 7 Novembre Airport',
    city: 'Tabarka',
    country: 'Tunisia',
    iata: 'TBJ',
    icao: 'DTKA',
    latitude: 36.9799995422363,
    longitude: 8.87693977355957,
    altitude: 230,
    schengen: false
  },
  {
    id: 5532,
    name: 'Monastir Habib Bourguiba International Airport',
    city: 'Monastir',
    country: 'Tunisia',
    iata: 'MIR',
    icao: 'DTMB',
    latitude: 35.7580986022949,
    longitude: 10.7546997070313,
    altitude: 9,
    schengen: false
  },
  {
    id: 5533,
    name: 'Tunis Carthage International Airport',
    city: 'Tunis',
    country: 'Tunisia',
    iata: 'TUN',
    icao: 'DTTA',
    latitude: 36.851001739502,
    longitude: 10.2271995544434,
    altitude: 22,
    schengen: false
  },
  {
    id: 5534,
    name: 'Sidi Ahmed Air Base',
    city: 'Bizerte',
    country: 'Tunisia',
    iata: '',
    icao: 'DTTB',
    latitude: 37.245398,
    longitude: 9.79145,
    altitude: 20,
    schengen: false
  },
  {
    id: 5535,
    name: 'Remada Air Base',
    city: 'Remada',
    country: 'Tunisia',
    iata: '',
    icao: 'DTTD',
    latitude: 32.3061981201172,
    longitude: 10.3821001052856,
    altitude: 1004,
    schengen: false
  },
  {
    id: 5536,
    name: 'Gafsa Ksar International Airport',
    city: 'Gafsa',
    country: 'Tunisia',
    iata: 'GAF',
    icao: 'DTTF',
    latitude: 34.4220008850098,
    longitude: 8.82250022888184,
    altitude: 1060,
    schengen: false
  },
  {
    id: 5537,
    name: 'Gabès Matmata International Airport',
    city: 'Gabes',
    country: 'Tunisia',
    iata: 'GAE',
    icao: 'DTTG',
    latitude: 33.8768997192383,
    longitude: 10.1033000946045,
    altitude: 26,
    schengen: false
  },
  {
    id: 5538,
    name: 'Borj El Amri Airport',
    city: 'Bordj El Amri',
    country: 'Tunisia',
    iata: '',
    icao: 'DTTI',
    latitude: 36.7212982177734,
    longitude: 9.94314956665039,
    altitude: 110,
    schengen: false
  },
  {
    id: 5539,
    name: 'Djerba Zarzis International Airport',
    city: 'Djerba',
    country: 'Tunisia',
    iata: 'DJE',
    icao: 'DTTJ',
    latitude: 33.875,
    longitude: 10.7755002975464,
    altitude: 19,
    schengen: false
  },
  {
    id: 5540,
    name: 'El Borma Airport',
    city: 'El Borma',
    country: 'Tunisia',
    iata: 'EBM',
    icao: 'DTTR',
    latitude: 31.7042999267578,
    longitude: 9.25461959838867,
    altitude: 827,
    schengen: false
  },
  {
    id: 5541,
    name: 'Sfax Thyna International Airport',
    city: 'Sfax',
    country: 'Tunisia',
    iata: 'SFA',
    icao: 'DTTX',
    latitude: 34.7179985046387,
    longitude: 10.6909999847412,
    altitude: 85,
    schengen: false
  },
  {
    id: 5542,
    name: 'Tozeur Nefta International Airport',
    city: 'Tozeur',
    country: 'Tunisia',
    iata: 'TOE',
    icao: 'DTTZ',
    latitude: 33.9397010803223,
    longitude: 8.11056041717529,
    altitude: 287,
    schengen: false
  },
  {
    id: 5543,
    name: 'Güvercinlik Airport',
    city: 'Ankara',
    country: 'Turkey',
    iata: '',
    icao: 'LTAB',
    latitude: 39.9350013733,
    longitude: 32.7407989502,
    altitude: 2687,
    schengen: false
  },
  {
    id: 5544,
    name: 'Esenboğa International Airport',
    city: 'Ankara',
    country: 'Turkey',
    iata: 'ESB',
    icao: 'LTAC',
    latitude: 40.1281013489,
    longitude: 32.995098114,
    altitude: 3125,
    schengen: false
  },
  {
    id: 5545,
    name: 'Etimesgut Air Base',
    city: 'Ankara',
    country: 'Turkey',
    iata: 'ANK',
    icao: 'LTAD',
    latitude: 39.949798584,
    longitude: 32.6885986328,
    altitude: 2653,
    schengen: false
  },
  {
    id: 5546,
    name: 'Akıncı Air Base',
    city: 'Ankara',
    country: 'Turkey',
    iata: '',
    icao: 'LTAE',
    latitude: 40.0788993835,
    longitude: 32.5656013489,
    altitude: 2767,
    schengen: false
  },
  {
    id: 5547,
    name: 'Adana Airport',
    city: 'Adana',
    country: 'Turkey',
    iata: 'ADA',
    icao: 'LTAF',
    latitude: 36.9822006226,
    longitude: 35.2803993225,
    altitude: 65,
    schengen: false
  },
  {
    id: 5548,
    name: 'İncirlik Air Base',
    city: 'Adana',
    country: 'Turkey',
    iata: 'UAB',
    icao: 'LTAG',
    latitude: 37.0021018982,
    longitude: 35.4258995056,
    altitude: 238,
    schengen: false
  },
  {
    id: 5549,
    name: 'Afyon Airport',
    city: 'Afyon',
    country: 'Turkey',
    iata: 'AFY',
    icao: 'LTAH',
    latitude: 38.726398468,
    longitude: 30.6011009216,
    altitude: 3310,
    schengen: false
  },
  {
    id: 5550,
    name: 'Antalya International Airport',
    city: 'Antalya',
    country: 'Turkey',
    iata: 'AYT',
    icao: 'LTAI',
    latitude: 36.898701,
    longitude: 30.800501,
    altitude: 177,
    schengen: false
  },
  {
    id: 5551,
    name: 'Gaziantep International Airport',
    city: 'Gaziantep',
    country: 'Turkey',
    iata: 'GZT',
    icao: 'LTAJ',
    latitude: 36.9472007751,
    longitude: 37.4786987305,
    altitude: 2315,
    schengen: false
  },
  {
    id: 5552,
    name: 'İskenderun Airport',
    city: 'Iskenderun',
    country: 'Turkey',
    iata: '',
    icao: 'LTAK',
    latitude: 36.5744552612,
    longitude: 36.1534194946,
    altitude: 25,
    schengen: false
  },
  {
    id: 5553,
    name: 'Kastamonu Airport',
    city: 'Kastamonu',
    country: 'Turkey',
    iata: 'KFS',
    icao: 'LTAL',
    latitude: 41.3142013549805,
    longitude: 33.7957992553711,
    altitude: 3520,
    schengen: false
  },
  {
    id: 5554,
    name: 'Konya Airport',
    city: 'Konya',
    country: 'Turkey',
    iata: 'KYA',
    icao: 'LTAN',
    latitude: 37.979,
    longitude: 32.561901,
    altitude: 3392,
    schengen: false
  },
  {
    id: 5555,
    name: 'Malatya Tulga Airport',
    city: 'Malatya',
    country: 'Turkey',
    iata: '',
    icao: 'LTAO',
    latitude: 38.353699,
    longitude: 38.253899,
    altitude: 3016,
    schengen: false
  },
  {
    id: 5556,
    name: 'Amasya Merzifon Airport',
    city: 'Merzifon',
    country: 'Turkey',
    iata: 'MZH',
    icao: 'LTAP',
    latitude: 40.829399,
    longitude: 35.521999,
    altitude: 1758,
    schengen: false
  },
  {
    id: 5557,
    name: 'Sivas Nuri Demirağ Airport',
    city: 'Sivas',
    country: 'Turkey',
    iata: 'VAS',
    icao: 'LTAR',
    latitude: 39.813801,
    longitude: 36.9035,
    altitude: 5239,
    schengen: false
  },
  {
    id: 5558,
    name: 'Zonguldak Airport',
    city: 'Zonguldak',
    country: 'Turkey',
    iata: 'ONQ',
    icao: 'LTAS',
    latitude: 41.506401062,
    longitude: 32.0886001587,
    altitude: 39,
    schengen: false
  },
  {
    id: 5559,
    name: 'Malatya Erhaç Airport',
    city: 'Malatya',
    country: 'Turkey',
    iata: 'MLX',
    icao: 'LTAT',
    latitude: 38.4352989197,
    longitude: 38.0909996033,
    altitude: 2828,
    schengen: false
  },
  {
    id: 5560,
    name: 'Kayseri Erkilet Airport',
    city: 'Kayseri',
    country: 'Turkey',
    iata: 'ASR',
    icao: 'LTAU',
    latitude: 38.770401001,
    longitude: 35.4953994751,
    altitude: 3463,
    schengen: false
  },
  {
    id: 5561,
    name: 'Sivrihisar Airport',
    city: 'Sivrihisar',
    country: 'Turkey',
    iata: '',
    icao: 'LTAV',
    latitude: 39.4514999389648,
    longitude: 31.3652992248535,
    altitude: 3185,
    schengen: false
  },
  {
    id: 5562,
    name: 'Tokat Airport',
    city: 'Tokat',
    country: 'Turkey',
    iata: 'TJK',
    icao: 'LTAW',
    latitude: 40.307430267334,
    longitude: 36.3674087524414,
    altitude: 1831,
    schengen: false
  },
  {
    id: 5563,
    name: 'Çardak Airport',
    city: 'Denizli',
    country: 'Turkey',
    iata: 'DNZ',
    icao: 'LTAY',
    latitude: 37.7855987549,
    longitude: 29.7012996674,
    altitude: 2795,
    schengen: false
  },
  {
    id: 5564,
    name: 'Nevşehir Kapadokya Airport',
    city: 'Nevsehir',
    country: 'Turkey',
    iata: 'NAV',
    icao: 'LTAZ',
    latitude: 38.7719,
    longitude: 34.5345,
    altitude: 3100,
    schengen: false
  },
  {
    id: 5565,
    name: 'Atatürk International Airport',
    city: 'Istanbul',
    country: 'Turkey',
    iata: 'IST',
    icao: 'LTBA',
    latitude: 40.976898,
    longitude: 28.8146,
    altitude: 163,
    schengen: false
  },
  {
    id: 5566,
    name: 'Bursa Airport',
    city: 'Bursa',
    country: 'Turkey',
    iata: 'BTZ',
    icao: 'LTBE',
    latitude: 40.2332992553711,
    longitude: 29.0091991424561,
    altitude: 331,
    schengen: false
  },
  {
    id: 5567,
    name: 'Balıkesir Merkez Airport',
    city: 'Balikesir',
    country: 'Turkey',
    iata: 'BZI',
    icao: 'LTBF',
    latitude: 39.6193008422852,
    longitude: 27.9260005950928,
    altitude: 340,
    schengen: false
  },
  {
    id: 5568,
    name: 'Bandırma Airport',
    city: 'Bandirma',
    country: 'Turkey',
    iata: 'BDM',
    icao: 'LTBG',
    latitude: 40.318000793457,
    longitude: 27.9776992797852,
    altitude: 170,
    schengen: false
  },
  {
    id: 5569,
    name: 'Çanakkale Airport',
    city: 'Canakkale',
    country: 'Turkey',
    iata: 'CKZ',
    icao: 'LTBH',
    latitude: 40.1376991272,
    longitude: 26.4267997742,
    altitude: 23,
    schengen: false
  },
  {
    id: 5570,
    name: 'Eskişehir Air Base',
    city: 'Eskisehir',
    country: 'Turkey',
    iata: 'ESK',
    icao: 'LTBI',
    latitude: 39.7840995789,
    longitude: 30.5820999146,
    altitude: 2581,
    schengen: false
  },
  {
    id: 5571,
    name: 'Adnan Menderes International Airport',
    city: 'Izmir',
    country: 'Turkey',
    iata: 'ADB',
    icao: 'LTBJ',
    latitude: 38.2924003601,
    longitude: 27.156999588,
    altitude: 412,
    schengen: false
  },
  {
    id: 5572,
    name: 'Gaziemir Airport',
    city: 'Izmir',
    country: 'Turkey',
    iata: '',
    icao: 'LTBK',
    latitude: 38.3190994262695,
    longitude: 27.1594009399414,
    altitude: 433,
    schengen: false
  },
  {
    id: 5573,
    name: 'Çiğli Airport',
    city: 'Izmir',
    country: 'Turkey',
    iata: 'IGL',
    icao: 'LTBL',
    latitude: 38.5130004883,
    longitude: 27.010099411,
    altitude: 16,
    schengen: false
  },
  {
    id: 5574,
    name: 'Isparta Airport',
    city: 'Isparta',
    country: 'Turkey',
    iata: '',
    icao: 'LTBM',
    latitude: 37.7851219177246,
    longitude: 30.5900192260742,
    altitude: 3250,
    schengen: false
  },
  {
    id: 5575,
    name: 'Kütahya Airport',
    city: 'Kutahya',
    country: 'Turkey',
    iata: '',
    icao: 'LTBN',
    latitude: 39.426700592041,
    longitude: 30.0168991088867,
    altitude: 3026,
    schengen: false
  },
  {
    id: 5576,
    name: 'Uşak Airport',
    city: 'Usak',
    country: 'Turkey',
    iata: 'USQ',
    icao: 'LTBO',
    latitude: 38.6814994812012,
    longitude: 29.471700668335,
    altitude: 2897,
    schengen: false
  },
  {
    id: 5577,
    name: 'Yalova Airport',
    city: 'Yalova',
    country: 'Turkey',
    iata: '',
    icao: 'LTBP',
    latitude: 40.6843986511231,
    longitude: 29.3756999969482,
    altitude: 6,
    schengen: false
  },
  {
    id: 5578,
    name: 'Cengiz Topel Airport',
    city: 'Topel',
    country: 'Turkey',
    iata: 'KCO',
    icao: 'LTBQ',
    latitude: 40.7350006103516,
    longitude: 30.0832996368408,
    altitude: 182,
    schengen: false
  },
  {
    id: 5579,
    name: 'Bursa Yenişehir Airport',
    city: 'Yenisehir',
    country: 'Turkey',
    iata: 'YEI',
    icao: 'LTBR',
    latitude: 40.2551994324,
    longitude: 29.5625991821,
    altitude: 764,
    schengen: false
  },
  {
    id: 5580,
    name: 'Dalaman International Airport',
    city: 'Dalaman',
    country: 'Turkey',
    iata: 'DLM',
    icao: 'LTBS',
    latitude: 36.7131004333,
    longitude: 28.7924995422,
    altitude: 20,
    schengen: false
  },
  {
    id: 5581,
    name: 'Akhisar Airport',
    city: 'Akhisar',
    country: 'Turkey',
    iata: '',
    icao: 'LTBT',
    latitude: 38.8088989257812,
    longitude: 27.8339004516602,
    altitude: 263,
    schengen: false
  },
  {
    id: 5582,
    name: 'Tekirdağ Çorlu Airport',
    city: 'Çorlu',
    country: 'Turkey',
    iata: 'TEQ',
    icao: 'LTBU',
    latitude: 41.1381988525391,
    longitude: 27.9190998077393,
    altitude: 574,
    schengen: false
  },
  {
    id: 5583,
    name: 'Imsık Airport',
    city: 'Bodrum',
    country: 'Turkey',
    iata: 'BXN',
    icao: 'LTBV',
    latitude: 37.1400985717773,
    longitude: 27.6697006225586,
    altitude: 202,
    schengen: false
  },
  {
    id: 5584,
    name: 'Samandıra Air Base',
    city: 'Istanbul',
    country: 'Turkey',
    iata: '',
    icao: 'LTBX',
    latitude: 40.9930000305176,
    longitude: 29.2164993286133,
    altitude: 400,
    schengen: false
  },
  {
    id: 5585,
    name: 'Anadolu Airport',
    city: 'Eskissehir',
    country: 'Turkey',
    iata: 'AOE',
    icao: 'LTBY',
    latitude: 39.809898,
    longitude: 30.5194,
    altitude: 2588,
    schengen: false
  },
  {
    id: 5586,
    name: 'Zafer Airport',
    city: 'Kutahya',
    country: 'Turkey',
    iata: 'KZR',
    icao: 'LTBZ',
    latitude: 39.113079,
    longitude: 30.128111,
    altitude: 3327,
    schengen: false
  },
  {
    id: 5587,
    name: 'Elazığ Airport',
    city: 'Elazig',
    country: 'Turkey',
    iata: 'EZS',
    icao: 'LTCA',
    latitude: 38.6068992615,
    longitude: 39.2914009094,
    altitude: 2927,
    schengen: false
  },
  {
    id: 5588,
    name: 'Diyarbakir Airport',
    city: 'Diyabakir',
    country: 'Turkey',
    iata: 'DIY',
    icao: 'LTCC',
    latitude: 37.893901825,
    longitude: 40.2010002136,
    altitude: 2251,
    schengen: false
  },
  {
    id: 5589,
    name: 'Erzincan Airport',
    city: 'Erzincan',
    country: 'Turkey',
    iata: 'ERC',
    icao: 'LTCD',
    latitude: 39.7102012634,
    longitude: 39.5270004272,
    altitude: 3783,
    schengen: false
  },
  {
    id: 5590,
    name: 'Erzurum International Airport',
    city: 'Erzurum',
    country: 'Turkey',
    iata: 'ERZ',
    icao: 'LTCE',
    latitude: 39.9565010071,
    longitude: 41.1702003479,
    altitude: 5763,
    schengen: false
  },
  {
    id: 5591,
    name: 'Kars Airport',
    city: 'Kars',
    country: 'Turkey',
    iata: 'KSY',
    icao: 'LTCF',
    latitude: 40.562198638916,
    longitude: 43.1150016784668,
    altitude: 5889,
    schengen: false
  },
  {
    id: 5592,
    name: 'Trabzon International Airport',
    city: 'Trabzon',
    country: 'Turkey',
    iata: 'TZX',
    icao: 'LTCG',
    latitude: 40.9950981140137,
    longitude: 39.7896995544434,
    altitude: 104,
    schengen: false
  },
  {
    id: 5593,
    name: 'Şanlıurfa Airport',
    city: 'Sanliurfa',
    country: 'Turkey',
    iata: 'SFQ',
    icao: 'LTCH',
    latitude: 37.0942993164063,
    longitude: 38.8470993041992,
    altitude: 1483,
    schengen: false
  },
  {
    id: 5594,
    name: 'Van Ferit Melen Airport',
    city: 'Van',
    country: 'Turkey',
    iata: 'VAN',
    icao: 'LTCI',
    latitude: 38.4682006835937,
    longitude: 43.3322982788086,
    altitude: 5480,
    schengen: false
  },
  {
    id: 5595,
    name: 'Batman Airport',
    city: 'Batman',
    country: 'Turkey',
    iata: 'BAL',
    icao: 'LTCJ',
    latitude: 37.9290008545,
    longitude: 41.1166000366,
    altitude: 1822,
    schengen: false
  },
  {
    id: 5596,
    name: 'Muş Airport',
    city: 'Mus',
    country: 'Turkey',
    iata: 'MSR',
    icao: 'LTCK',
    latitude: 38.7477989196777,
    longitude: 41.6612014770508,
    altitude: 4157,
    schengen: false
  },
  {
    id: 5597,
    name: 'Siirt Airport',
    city: 'Siirt',
    country: 'Turkey',
    iata: 'SXZ',
    icao: 'LTCL',
    latitude: 37.9789009094238,
    longitude: 41.8404006958008,
    altitude: 2001,
    schengen: false
  },
  {
    id: 5598,
    name: 'Sinop Airport',
    city: 'Sinop',
    country: 'Turkey',
    iata: 'NOP',
    icao: 'LTCM',
    latitude: 42.015800476074,
    longitude: 35.066398620605,
    altitude: 20,
    schengen: false
  },
  {
    id: 5599,
    name: 'Kahramanmaraş Airport',
    city: 'Kahramanmaras',
    country: 'Turkey',
    iata: 'KCM',
    icao: 'LTCN',
    latitude: 37.5388259888,
    longitude: 36.9535217285,
    altitude: 1723,
    schengen: false
  },
  {
    id: 5600,
    name: 'Ağrı Airport',
    city: 'Agri',
    country: 'Turkey',
    iata: 'AJI',
    icao: 'LTCO',
    latitude: 39.654541015625,
    longitude: 43.0259780883789,
    altitude: 5462,
    schengen: false
  },
  {
    id: 5601,
    name: 'Adıyaman Airport',
    city: 'Adiyaman',
    country: 'Turkey',
    iata: 'ADF',
    icao: 'LTCP',
    latitude: 37.7313995361,
    longitude: 38.4688987732,
    altitude: 2216,
    schengen: false
  },
  {
    id: 5602,
    name: 'Mardin Airport',
    city: 'Mardin',
    country: 'Turkey',
    iata: 'MQM',
    icao: 'LTCR',
    latitude: 37.2233009338,
    longitude: 40.6316986084,
    altitude: 1729,
    schengen: false
  },
  {
    id: 5603,
    name: 'Şanlıurfa GAP Airport',
    city: 'Sanliurfa',
    country: 'Turkey',
    iata: 'GNY',
    icao: 'LTCS',
    latitude: 37.445663,
    longitude: 38.895592,
    altitude: 2708,
    schengen: false
  },
  {
    id: 5604,
    name: 'Şırnak Şerafettin Elçi Airport',
    city: 'Cizre',
    country: 'Turkey',
    iata: 'NKT',
    icao: 'LTCV',
    latitude: 37.3647,
    longitude: 42.0582,
    altitude: 2038,
    schengen: false
  },
  {
    id: 5605,
    name: 'Hakkari Yüksekova Airport',
    city: 'Hakkari',
    country: 'Turkey',
    iata: 'YKO',
    icao: 'LTCW',
    latitude: 37.5497,
    longitude: 44.2381,
    altitude: 6400,
    schengen: false
  },
  {
    id: 5606,
    name: 'Hatay Airport',
    city: 'Hatay',
    country: 'Turkey',
    iata: 'HTY',
    icao: 'LTDA',
    latitude: 36.36277771,
    longitude: 36.2822227478,
    altitude: 269,
    schengen: false
  },
  {
    id: 5607,
    name: 'Kaklıç Airport',
    city: 'Izmir',
    country: 'Turkey',
    iata: '',
    icao: 'LTFA',
    latitude: 38.5176010131836,
    longitude: 26.9773998260498,
    altitude: 13,
    schengen: false
  },
  {
    id: 5608,
    name: 'Selçuk Efes Airport',
    city: 'Izmir',
    country: 'Turkey',
    iata: '',
    icao: 'LTFB',
    latitude: 37.9506988525391,
    longitude: 27.3290004730225,
    altitude: 10,
    schengen: false
  },
  {
    id: 5609,
    name: 'Süleyman Demirel International Airport',
    city: 'Isparta',
    country: 'Turkey',
    iata: 'ISE',
    icao: 'LTFC',
    latitude: 37.8554000854,
    longitude: 30.3684005737,
    altitude: 2835,
    schengen: false
  },
  {
    id: 5610,
    name: 'Balıkesir Körfez Airport',
    city: 'Balikesir Korfez',
    country: 'Turkey',
    iata: 'EDO',
    icao: 'LTFD',
    latitude: 39.554599762,
    longitude: 27.0137996674,
    altitude: 50,
    schengen: false
  },
  {
    id: 5611,
    name: 'Milas Bodrum International Airport',
    city: 'Bodrum',
    country: 'Turkey',
    iata: 'BJV',
    icao: 'LTFE',
    latitude: 37.2505989075,
    longitude: 27.6643009186,
    altitude: 21,
    schengen: false
  },
  {
    id: 5612,
    name: 'Samsun Çarşamba Airport',
    city: 'Samsun',
    country: 'Turkey',
    iata: 'SZF',
    icao: 'LTFH',
    latitude: 41.254501,
    longitude: 36.567101,
    altitude: 18,
    schengen: false
  },
  {
    id: 5613,
    name: 'Sabiha Gökçen International Airport',
    city: 'Istanbul',
    country: 'Turkey',
    iata: 'SAW',
    icao: 'LTFJ',
    latitude: 40.898601532,
    longitude: 29.3092002869,
    altitude: 312,
    schengen: false
  },
  {
    id: 5614,
    name: 'Gazipaşa Airport',
    city: 'Alanya',
    country: 'Turkey',
    iata: 'GZP',
    icao: 'LTGP',
    latitude: 36.299217,
    longitude: 32.300598,
    altitude: 86,
    schengen: false
  },
  {
    id: 5615,
    name: 'Ashgabat Airport',
    city: 'Ashkhabad',
    country: 'Turkmenistan',
    iata: 'ASB',
    icao: 'UTAA',
    latitude: 37.9868011474609,
    longitude: 58.3610000610352,
    altitude: 692,
    schengen: false
  },
  {
    id: 5616,
    name: 'Turkmenbashi Airport',
    city: 'Krasnovodsk',
    country: 'Turkmenistan',
    iata: 'KRW',
    icao: 'UTAK',
    latitude: 40.063301,
    longitude: 53.007198,
    altitude: 279,
    schengen: false
  },
  {
    id: 5617,
    name: 'Mary Airport',
    city: 'Mary',
    country: 'Turkmenistan',
    iata: 'MYP',
    icao: 'UTAM',
    latitude: 37.6194,
    longitude: 61.896702,
    altitude: 728,
    schengen: false
  },
  {
    id: 5618,
    name: 'Daşoguz Airport',
    city: 'Dasoguz',
    country: 'Turkmenistan',
    iata: 'TAZ',
    icao: 'UTAT',
    latitude: 41.761101,
    longitude: 59.826698,
    altitude: 272,
    schengen: false
  },
  {
    id: 5619,
    name: 'Turkmenabat Airport',
    city: 'Chardzhou',
    country: 'Turkmenistan',
    iata: 'CRZ',
    icao: 'UTAV',
    latitude: 39.0833015441895,
    longitude: 63.6133003234863,
    altitude: 630,
    schengen: false
  },
  {
    id: 5620,
    name: 'JAGS McCartney International Airport',
    city: 'Cockburn Town',
    country: 'Turks and Caicos Islands',
    iata: 'GDT',
    icao: 'MBGT',
    latitude: 21.4444999694824,
    longitude: -71.1423034667969,
    altitude: 13,
    schengen: false
  },
  {
    id: 5621,
    name: 'Middle Caicos Airport',
    city: 'Middle Caicos',
    country: 'Turks and Caicos Islands',
    iata: 'MDS',
    icao: 'MBMC',
    latitude: 21.82602,
    longitude: -71.8025,
    altitude: 9,
    schengen: false
  },
  {
    id: 5622,
    name: 'North Caicos Airport',
    city: 'North Caicos',
    country: 'Turks and Caicos Islands',
    iata: 'NCA',
    icao: 'MBNC',
    latitude: 21.9175,
    longitude: -71.939598,
    altitude: 10,
    schengen: false
  },
  {
    id: 5623,
    name: 'Providenciales Airport',
    city: 'Providenciales',
    country: 'Turks and Caicos Islands',
    iata: 'PLS',
    icao: 'MBPV',
    latitude: 21.7735996246338,
    longitude: -72.2658996582031,
    altitude: 15,
    schengen: false
  },
  {
    id: 5624,
    name: 'South Caicos Airport',
    city: 'South Caicos',
    country: 'Turks and Caicos Islands',
    iata: 'XSC',
    icao: 'MBSC',
    latitude: 21.5156993866,
    longitude: -71.528503418,
    altitude: 6,
    schengen: false
  },
  {
    id: 5625,
    name: 'Salt Cay Airport',
    city: 'Salt Cay',
    country: 'Turks and Caicos Islands',
    iata: 'SLX',
    icao: 'MBSY',
    latitude: 21.3330001831,
    longitude: -71.1999969482,
    altitude: 3,
    schengen: false
  },
  {
    id: 5626,
    name: 'Funafuti International Airport',
    city: 'Funafuti',
    country: 'Tuvalu',
    iata: 'FUN',
    icao: 'NGFU',
    latitude: -8.525,
    longitude: 179.195999,
    altitude: 9,
    schengen: false
  },
  {
    id: 5627,
    name: 'Arua Airport',
    city: 'Arua',
    country: 'Uganda',
    iata: 'RUA',
    icao: 'HUAR',
    latitude: 3.04999995231628,
    longitude: 30.9169998168945,
    altitude: 3951,
    schengen: false
  },
  {
    id: 5628,
    name: 'Entebbe International Airport',
    city: 'Entebbe',
    country: 'Uganda',
    iata: 'EBB',
    icao: 'HUEN',
    latitude: 0.042385999113321,
    longitude: 32.4435005187988,
    altitude: 3782,
    schengen: false
  },
  {
    id: 5629,
    name: 'Gulu Airport',
    city: 'Gulu',
    country: 'Uganda',
    iata: 'ULU',
    icao: 'HUGU',
    latitude: 2.80556011199951,
    longitude: 32.271800994873,
    altitude: 3510,
    schengen: false
  },
  {
    id: 5630,
    name: 'Kabale Airport',
    city: 'Kabale',
    country: 'Uganda',
    iata: '',
    icao: 'HUKB',
    latitude: -1.226111,
    longitude: 29.96,
    altitude: 6000,
    schengen: false
  },
  {
    id: 5631,
    name: 'Kidepo Airport',
    city: 'Kidepo',
    country: 'Uganda',
    iata: '',
    icao: 'HUKD',
    latitude: 3.7176,
    longitude: 33.7487,
    altitude: 4033,
    schengen: false
  },
  {
    id: 5632,
    name: 'Kajjansi Airfield',
    city: 'Kampala',
    country: 'Uganda',
    iata: '',
    icao: 'HUKJ',
    latitude: 0.1968,
    longitude: 32.55297,
    altitude: 3743,
    schengen: false
  },
  {
    id: 5633,
    name: 'Kotido Airport',
    city: 'Kotido',
    country: 'Uganda',
    iata: '',
    icao: 'HUKO',
    latitude: 2.953,
    longitude: 34.1232,
    altitude: 4020,
    schengen: false
  },
  {
    id: 5634,
    name: 'Kasese Airport',
    city: 'Kasese',
    country: 'Uganda',
    iata: 'KSE',
    icao: 'HUKS',
    latitude: 0.18299999833107,
    longitude: 30.1000003814697,
    altitude: 3146,
    schengen: false
  },
  {
    id: 5635,
    name: 'Kitgum Airport',
    city: 'Kitgum',
    country: 'Uganda',
    iata: '',
    icao: 'HUKT',
    latitude: 3.2817,
    longitude: 32.8882,
    altitude: 3130,
    schengen: false
  },
  {
    id: 5636,
    name: 'Bugungu Airport',
    city: 'Pakuba',
    country: 'Uganda',
    iata: '',
    icao: 'HUPA',
    latitude: 2.2,
    longitude: 31.55,
    altitude: 2472,
    schengen: false
  },
  {
    id: 5637,
    name: 'Soroti Airport',
    city: 'Soroti',
    country: 'Uganda',
    iata: 'SRT',
    icao: 'HUSO',
    latitude: 1.72768998146057,
    longitude: 33.6227989196777,
    altitude: 3697,
    schengen: false
  },
  {
    id: 5638,
    name: 'Boryspil International Airport',
    city: 'Kiev',
    country: 'Ukraine',
    iata: 'KBP',
    icao: 'UKBB',
    latitude: 50.3450012207031,
    longitude: 30.8946990966797,
    altitude: 427,
    schengen: false
  },
  {
    id: 5639,
    name: 'Donetsk International Airport',
    city: 'Donetsk',
    country: 'Ukraine',
    iata: 'DOK',
    icao: 'UKCC',
    latitude: 48.073600769043,
    longitude: 37.7397003173828,
    altitude: 791,
    schengen: false
  },
  {
    id: 5640,
    name: 'Kramatorsk Airport',
    city: 'Kramatorsk',
    country: 'Ukraine',
    iata: 'KRQ',
    icao: 'UKCK',
    latitude: 48.7056007385254,
    longitude: 37.6288986206055,
    altitude: 646,
    schengen: false
  },
  {
    id: 5641,
    name: 'Mariupol International Airport',
    city: 'Mariupol International',
    country: 'Ukraine',
    iata: 'MPW',
    icao: 'UKCM',
    latitude: 47.076099395752,
    longitude: 37.4496002197266,
    altitude: 251,
    schengen: false
  },
  {
    id: 5642,
    name: 'Luhansk International Airport',
    city: 'Lugansk',
    country: 'Ukraine',
    iata: 'VSG',
    icao: 'UKCW',
    latitude: 48.4174003601,
    longitude: 39.3740997314,
    altitude: 636,
    schengen: false
  },
  {
    id: 5643,
    name: 'Berdyansk Airport',
    city: 'Berdyansk',
    country: 'Ukraine',
    iata: 'ERD',
    icao: 'UKDB',
    latitude: 46.814998626709,
    longitude: 36.7580986022949,
    altitude: 171,
    schengen: false
  },
  {
    id: 5644,
    name: 'Dnipropetrovsk International Airport',
    city: 'Dnepropetrovsk',
    country: 'Ukraine',
    iata: 'DNK',
    icao: 'UKDD',
    latitude: 48.3572006225586,
    longitude: 35.1006011962891,
    altitude: 481,
    schengen: false
  },
  {
    id: 5645,
    name: 'Zaporizhzhia International Airport',
    city: 'Zaporozhye',
    country: 'Ukraine',
    iata: 'OZH',
    icao: 'UKDE',
    latitude: 47.867000579834,
    longitude: 35.3157005310059,
    altitude: 373,
    schengen: false
  },
  {
    id: 5646,
    name: 'Kryvyi Rih International Airport',
    city: 'Krivoy Rog',
    country: 'Ukraine',
    iata: 'KWG',
    icao: 'UKDR',
    latitude: 48.0433006286621,
    longitude: 33.2099990844727,
    altitude: 408,
    schengen: false
  },
  {
    id: 5647,
    name: 'Belbek Airport',
    city: 'Sevastopol',
    country: 'Ukraine',
    iata: 'UKS',
    icao: 'UKFB',
    latitude: 44.688999176,
    longitude: 33.5709991455,
    altitude: 344,
    schengen: false
  },
  {
    id: 5648,
    name: 'Simferopol International Airport',
    city: 'Simferopol',
    country: 'Ukraine',
    iata: 'SIP',
    icao: 'UKFF',
    latitude: 45.0522003173828,
    longitude: 33.9751014709473,
    altitude: 639,
    schengen: false
  },
  {
    id: 5649,
    name: 'Gvardeyskoe Air Base',
    city: 'Sarabuz',
    country: 'Ukraine',
    iata: '',
    icao: 'UKFG',
    latitude: 45.1150016784668,
    longitude: 33.9770011901855,
    altitude: 721,
    schengen: false
  },
  {
    id: 5650,
    name: 'Saki Air Base',
    city: 'Saki',
    country: 'Ukraine',
    iata: '',
    icao: 'UKFI',
    latitude: 45.0929985046387,
    longitude: 33.5950012207031,
    altitude: 36,
    schengen: false
  },
  {
    id: 5651,
    name: 'Kerch Airport',
    city: 'Kerch',
    country: 'Ukraine',
    iata: 'KHC',
    icao: 'UKFK',
    latitude: 45.372501373291,
    longitude: 36.4014015197754,
    altitude: 171,
    schengen: false
  },
  {
    id: 5652,
    name: 'Dzhankoy Airport',
    city: 'Dzhankoy',
    country: 'Ukraine',
    iata: '',
    icao: 'UKFY',
    latitude: 45.7009010314941,
    longitude: 34.4188995361328,
    altitude: 75,
    schengen: false
  },
  {
    id: 5653,
    name: 'Kharkiv International Airport',
    city: 'Kharkov',
    country: 'Ukraine',
    iata: 'HRK',
    icao: 'UKHH',
    latitude: 49.9248008728027,
    longitude: 36.2900009155273,
    altitude: 508,
    schengen: false
  },
  {
    id: 5654,
    name: 'Suprunovka Airport',
    city: 'Poltava',
    country: 'Ukraine',
    iata: 'PLV',
    icao: 'UKHP',
    latitude: 49.5685997009277,
    longitude: 34.3972015380859,
    altitude: 505,
    schengen: false
  },
  {
    id: 5655,
    name: 'Cherkasy International Airport',
    city: 'Cherkassy',
    country: 'Ukraine',
    iata: 'CKC',
    icao: 'UKKE',
    latitude: 49.4155998229981,
    longitude: 31.9953002929687,
    altitude: 375,
    schengen: false
  },
  {
    id: 5656,
    name: 'Kirovograd Airport',
    city: 'Kirovograd',
    country: 'Ukraine',
    iata: 'KGO',
    icao: 'UKKG',
    latitude: 48.5428009033203,
    longitude: 32.2849998474121,
    altitude: 568,
    schengen: false
  },
  {
    id: 5657,
    name: 'Chepelevka Airport',
    city: 'Uzyn',
    country: 'Ukraine',
    iata: '',
    icao: 'UKKH',
    latitude: 49.790599822998,
    longitude: 30.4414005279541,
    altitude: 568,
    schengen: false
  },
  {
    id: 5658,
    name: 'Kiev Zhuliany International Airport',
    city: 'Kiev',
    country: 'Ukraine',
    iata: 'IEV',
    icao: 'UKKK',
    latitude: 50.40194,
    longitude: 30.45194,
    altitude: 587,
    schengen: false
  },
  {
    id: 5659,
    name: 'Gostomel Airport',
    city: 'Kiev',
    country: 'Ukraine',
    iata: 'GML',
    icao: 'UKKM',
    latitude: 50.6035003662109,
    longitude: 30.1919002532959,
    altitude: 517,
    schengen: false
  },
  {
    id: 5660,
    name: 'Ozerne Air Base',
    city: 'Zhytomyr',
    country: 'Ukraine',
    iata: '',
    icao: 'UKKO',
    latitude: 50.158298,
    longitude: 28.7383,
    altitude: 761,
    schengen: false
  },
  {
    id: 5661,
    name: 'Zhytomyr Airport',
    city: 'Zhytomyr',
    country: 'Ukraine',
    iata: 'ZTR',
    icao: 'UKKV',
    latitude: 50.270556,
    longitude: 28.738611,
    altitude: 0,
    schengen: false
  },
  {
    id: 5662,
    name: 'Lutsk Airport',
    city: 'Lutsk',
    country: 'Ukraine',
    iata: 'UCK',
    icao: 'UKLC',
    latitude: 50.678404,
    longitude: 25.487165,
    altitude: 774,
    schengen: false
  },
  {
    id: 5663,
    name: 'Khmelnytskyi Airport',
    city: 'Khmeinitskiy',
    country: 'Ukraine',
    iata: 'HMJ',
    icao: 'UKLH',
    latitude: 49.359699,
    longitude: 26.933399,
    altitude: 1150,
    schengen: false
  },
  {
    id: 5664,
    name: 'Ivano-Frankivsk International Airport',
    city: 'Ivano-Frankivsk',
    country: 'Ukraine',
    iata: 'IFO',
    icao: 'UKLI',
    latitude: 48.8842010498047,
    longitude: 24.6861000061035,
    altitude: 919,
    schengen: false
  },
  {
    id: 5665,
    name: 'Lviv International Airport',
    city: 'Lvov',
    country: 'Ukraine',
    iata: 'LWO',
    icao: 'UKLL',
    latitude: 49.8125,
    longitude: 23.9561004638672,
    altitude: 1071,
    schengen: false
  },
  {
    id: 5666,
    name: 'Chernivtsi International Airport',
    city: 'Chernovtsk',
    country: 'Ukraine',
    iata: 'CWC',
    icao: 'UKLN',
    latitude: 48.2593002319336,
    longitude: 25.9808006286621,
    altitude: 826,
    schengen: false
  },
  {
    id: 5667,
    name: 'Rivne International Airport',
    city: 'Rivne',
    country: 'Ukraine',
    iata: 'RWN',
    icao: 'UKLR',
    latitude: 50.6071014404297,
    longitude: 26.1415996551514,
    altitude: 755,
    schengen: false
  },
  {
    id: 5668,
    name: 'Ternopil International Airport',
    city: 'Ternopol',
    country: 'Ukraine',
    iata: '',
    icao: 'UKLT',
    latitude: 49.5242,
    longitude: 25.7001,
    altitude: 1072,
    schengen: false
  },
  {
    id: 5669,
    name: 'Uzhhorod International Airport',
    city: 'Uzhgorod',
    country: 'Ukraine',
    iata: 'UDJ',
    icao: 'UKLU',
    latitude: 48.6343002319336,
    longitude: 22.2633991241455,
    altitude: 383,
    schengen: false
  },
  {
    id: 5670,
    name: 'Kherson International Airport',
    city: 'Kherson',
    country: 'Ukraine',
    iata: 'KHE',
    icao: 'UKOH',
    latitude: 46.6758,
    longitude: 32.506401,
    altitude: 148,
    schengen: false
  },
  {
    id: 5671,
    name: 'Mykolaiv International Airport',
    city: 'Nikolayev',
    country: 'Ukraine',
    iata: 'NLV',
    icao: 'UKON',
    latitude: 47.0578994750977,
    longitude: 31.9197998046875,
    altitude: 184,
    schengen: false
  },
  {
    id: 5672,
    name: 'Odessa International Airport',
    city: 'Odessa',
    country: 'Ukraine',
    iata: 'ODS',
    icao: 'UKOO',
    latitude: 46.4267997741699,
    longitude: 30.6765003204346,
    altitude: 172,
    schengen: false
  },
  {
    id: 5673,
    name: 'Chernihiv Shestovitsa Airport',
    city: 'Chernigov',
    country: 'Ukraine',
    iata: 'CEJ',
    icao: 'UKRR',
    latitude: 51.4021987915,
    longitude: 31.1583003998,
    altitude: 446,
    schengen: false
  },
  {
    id: 5674,
    name: 'Vinnytsia/Gavyryshivka Airport',
    city: 'Vinnitsa',
    country: 'Ukraine',
    iata: 'VIN',
    icao: 'UKWW',
    latitude: 49.242531,
    longitude: 28.613778,
    altitude: 961,
    schengen: false
  },
  {
    id: 5675,
    name: 'Lake Country Regional Airport',
    city: 'Clarksville',
    country: 'United Arab Emirates',
    iata: '',
    icao: 'KW63',
    latitude: 36.5957984924,
    longitude: -78.5600967407,
    altitude: 421,
    schengen: false
  },
  {
    id: 5676,
    name: 'Abu Dhabi International Airport',
    city: 'Abu Dhabi',
    country: 'United Arab Emirates',
    iata: 'AUH',
    icao: 'OMAA',
    latitude: 24.4330005645752,
    longitude: 54.6511001586914,
    altitude: 88,
    schengen: false
  },
  {
    id: 5677,
    name: 'Bateen Airport',
    city: 'Abu Dhabi',
    country: 'United Arab Emirates',
    iata: 'AZI',
    icao: 'OMAD',
    latitude: 24.4283008575439,
    longitude: 54.4580993652344,
    altitude: 16,
    schengen: false
  },
  {
    id: 5678,
    name: 'Al Hamra Aux Airport',
    city: 'Al Hamra',
    country: 'United Arab Emirates',
    iata: '',
    icao: 'OMAH',
    latitude: 24.0739994049072,
    longitude: 52.4636001586914,
    altitude: 50,
    schengen: false
  },
  {
    id: 5679,
    name: 'Jebel Dhana Airport',
    city: 'Jebel Dhana',
    country: 'United Arab Emirates',
    iata: '',
    icao: 'OMAJ',
    latitude: 24.1874008178711,
    longitude: 52.6139984130859,
    altitude: 43,
    schengen: false
  },
  {
    id: 5680,
    name: 'Al Ain International Airport',
    city: 'Al Ain',
    country: 'United Arab Emirates',
    iata: 'AAN',
    icao: 'OMAL',
    latitude: 24.2616996765137,
    longitude: 55.6091995239258,
    altitude: 869,
    schengen: false
  },
  {
    id: 5681,
    name: 'Al Dhafra Air Base',
    city: 'Abu Dhabi',
    country: 'United Arab Emirates',
    iata: 'DHF',
    icao: 'OMAM',
    latitude: 24.2481994629,
    longitude: 54.5476989746,
    altitude: 77,
    schengen: false
  },
  {
    id: 5682,
    name: 'Arzanah Airport',
    city: 'Arzana',
    country: 'United Arab Emirates',
    iata: '',
    icao: 'OMAR',
    latitude: 24.7805004119873,
    longitude: 52.5598983764648,
    altitude: 15,
    schengen: false
  },
  {
    id: 5683,
    name: 'Das Island Airport',
    city: 'Das Island',
    country: 'United Arab Emirates',
    iata: '',
    icao: 'OMAS',
    latitude: 25.1462001800537,
    longitude: 52.8736991882324,
    altitude: 12,
    schengen: false
  },
  {
    id: 5684,
    name: 'Zirku Airport',
    city: 'Zirku',
    country: 'United Arab Emirates',
    iata: '',
    icao: 'OMAZ',
    latitude: 24.862491607666,
    longitude: 53.0771408081055,
    altitude: 14,
    schengen: false
  },
  {
    id: 5685,
    name: 'Sir Bani Yas Airport',
    city: 'Sir Bani Yas Island',
    country: 'United Arab Emirates',
    iata: 'XSB',
    icao: 'OMBY',
    latitude: 24.283611,
    longitude: 52.580278,
    altitude: 25,
    schengen: false
  },
  {
    id: 5686,
    name: 'Dubai International Airport',
    city: 'Dubai',
    country: 'United Arab Emirates',
    iata: 'DXB',
    icao: 'OMDB',
    latitude: 25.2527999878,
    longitude: 55.3643989563,
    altitude: 62,
    schengen: false
  },
  {
    id: 5687,
    name: 'Al Minhad Air Base',
    city: 'Minhad AB',
    country: 'United Arab Emirates',
    iata: 'NHD',
    icao: 'OMDM',
    latitude: 25.0268001556,
    longitude: 55.3661994934,
    altitude: 165,
    schengen: false
  },
  {
    id: 5688,
    name: 'Al Maktoum International Airport',
    city: 'Dubai',
    country: 'United Arab Emirates',
    iata: 'DWC',
    icao: 'OMDW',
    latitude: 24.896356,
    longitude: 55.161389,
    altitude: 114,
    schengen: false
  },
  {
    id: 5689,
    name: 'Fujairah International Airport',
    city: 'Fujeirah',
    country: 'United Arab Emirates',
    iata: 'FJR',
    icao: 'OMFJ',
    latitude: 25.1121997833252,
    longitude: 56.3240013122559,
    altitude: 152,
    schengen: false
  },
  {
    id: 5690,
    name: 'Ras Al Khaimah International Airport',
    city: 'Ras Al Khaimah',
    country: 'United Arab Emirates',
    iata: 'RKT',
    icao: 'OMRK',
    latitude: 25.6135005950928,
    longitude: 55.9388008117676,
    altitude: 102,
    schengen: false
  },
  {
    id: 5691,
    name: 'Sharjah International Airport',
    city: 'Sharjah',
    country: 'United Arab Emirates',
    iata: 'SHJ',
    icao: 'OMSJ',
    latitude: 25.3285999298096,
    longitude: 55.5172004699707,
    altitude: 111,
    schengen: false
  },
  {
    id: 5692,
    name: 'Belfast International Airport',
    city: 'Belfast',
    country: 'United Kingdom',
    iata: 'BFS',
    icao: 'EGAA',
    latitude: 54.6575012207,
    longitude: -6.21582984924,
    altitude: 268,
    schengen: false
  },
  {
    id: 5693,
    name: 'St Angelo Airport',
    city: 'Enniskillen',
    country: 'United Kingdom',
    iata: 'ENK',
    icao: 'EGAB',
    latitude: 54.3988990783691,
    longitude: -7.65166997909546,
    altitude: 155,
    schengen: false
  },
  {
    id: 5694,
    name: 'George Best Belfast City Airport',
    city: 'Belfast',
    country: 'United Kingdom',
    iata: 'BHD',
    icao: 'EGAC',
    latitude: 54.6180992126465,
    longitude: -5.87249994277954,
    altitude: 15,
    schengen: false
  },
  {
    id: 5695,
    name: 'Newtownards Airport',
    city: 'Newtownards',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGAD',
    latitude: 54.5811004639,
    longitude: -5.69193983078,
    altitude: 9,
    schengen: false
  },
  {
    id: 5696,
    name: 'City of Derry Airport',
    city: 'Londonderry',
    country: 'United Kingdom',
    iata: 'LDY',
    icao: 'EGAE',
    latitude: 55.0428009033203,
    longitude: -7.16110992431641,
    altitude: 22,
    schengen: false
  },
  {
    id: 5697,
    name: 'Rothera Research Station',
    city: 'Rothera Research Station',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGAR',
    latitude: -67.5675,
    longitude: -68.1274027778,
    altitude: 9,
    schengen: false
  },
  {
    id: 5698,
    name: 'Birmingham International Airport',
    city: 'Birmingham',
    country: 'United Kingdom',
    iata: 'BHX',
    icao: 'EGBB',
    latitude: 52.4538993835,
    longitude: -1.74802994728,
    altitude: 327,
    schengen: false
  },
  {
    id: 5699,
    name: 'Coventry Airport',
    city: 'Coventry',
    country: 'United Kingdom',
    iata: 'CVT',
    icao: 'EGBE',
    latitude: 52.3697013855,
    longitude: -1.47971999645,
    altitude: 267,
    schengen: false
  },
  {
    id: 5700,
    name: 'Leicester Airport',
    city: 'Leicester',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGBG',
    latitude: 52.6077995300293,
    longitude: -1.03193998336792,
    altitude: 469,
    schengen: false
  },
  {
    id: 5701,
    name: 'Gloucestershire Airport',
    city: 'Golouchestershire',
    country: 'United Kingdom',
    iata: 'GLO',
    icao: 'EGBJ',
    latitude: 51.8941993713379,
    longitude: -2.16722011566162,
    altitude: 101,
    schengen: false
  },
  {
    id: 5702,
    name: 'Tatenhill Airfield',
    city: 'Burton-on-Trent',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGBM',
    latitude: 52.8147010803,
    longitude: -1.76110994816,
    altitude: 439,
    schengen: false
  },
  {
    id: 5703,
    name: 'Nottingham Airport',
    city: 'Nottingham',
    country: 'United Kingdom',
    iata: 'NQT',
    icao: 'EGBN',
    latitude: 52.9199981689453,
    longitude: -1.0791699886322,
    altitude: 138,
    schengen: false
  },
  {
    id: 5704,
    name: 'Wolverhampton Halfpenny Green Airport',
    city: 'Halfpenny Green',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGBO',
    latitude: 52.5175018310547,
    longitude: -2.25943994522095,
    altitude: 283,
    schengen: false
  },
  {
    id: 5705,
    name: 'Cotswold Airport',
    city: 'Pailton',
    country: 'United Kingdom',
    iata: 'GBA',
    icao: 'EGBP',
    latitude: 51.668095,
    longitude: -2.05694,
    altitude: 433,
    schengen: false
  },
  {
    id: 5706,
    name: 'Shobdon Aerodrome',
    city: 'Shobdon',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGBS',
    latitude: 52.2416992188,
    longitude: -2.88110995293,
    altitude: 318,
    schengen: false
  },
  {
    id: 5707,
    name: 'Turweston Airport',
    city: 'Turweston',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGBT',
    latitude: 52.0407981873,
    longitude: -1.09555995464,
    altitude: 448,
    schengen: false
  },
  {
    id: 5708,
    name: 'Wellesbourne Mountford Airport',
    city: 'Wellesbourne',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGBW',
    latitude: 52.1921997070313,
    longitude: -1.61443996429443,
    altitude: 159,
    schengen: false
  },
  {
    id: 5709,
    name: 'City Airport Manchester',
    city: 'Manchester',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGCB',
    latitude: 53.471698761,
    longitude: -2.38971996307,
    altitude: 73,
    schengen: false
  },
  {
    id: 5710,
    name: 'Manchester Airport',
    city: 'Manchester',
    country: 'United Kingdom',
    iata: 'MAN',
    icao: 'EGCC',
    latitude: 53.3536987304688,
    longitude: -2.27495002746582,
    altitude: 257,
    schengen: false
  },
  {
    id: 5711,
    name: 'Manchester Woodford Airport',
    city: 'Woodfort',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGCD',
    latitude: 53.3381,
    longitude: -2.14889,
    altitude: 295,
    schengen: false
  },
  {
    id: 5712,
    name: 'Sandtoft Airfield',
    city: 'Sandtoft',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGCF',
    latitude: 53.5597000122,
    longitude: -0.8583329916,
    altitude: 11,
    schengen: false
  },
  {
    id: 5713,
    name: 'Sherburn-In-Elmet Airfield',
    city: 'Leeds',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGCJ',
    latitude: 53.788458,
    longitude: -1.216877,
    altitude: 26,
    schengen: false
  },
  {
    id: 5714,
    name: 'Caernarfon Airport',
    city: 'Caernarfon',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGCK',
    latitude: 53.101819,
    longitude: -4.337614,
    altitude: 1,
    schengen: false
  },
  {
    id: 5715,
    name: 'Robin Hood Doncaster Sheffield Airport',
    city: 'Doncaster, Sheffield',
    country: 'United Kingdom',
    iata: 'DSA',
    icao: 'EGCN',
    latitude: 53.4805378105,
    longitude: -1.01065635681,
    altitude: 55,
    schengen: false
  },
  {
    id: 5716,
    name: 'Sleap Airport',
    city: 'Shrewsbury',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGCV',
    latitude: 52.8339004517,
    longitude: -2.77167010307,
    altitude: 275,
    schengen: false
  },
  {
    id: 5717,
    name: 'RAF Brawdy',
    city: 'Brawdy',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGDA',
    latitude: 51.88375,
    longitude: -5.119972,
    altitude: 0,
    schengen: false
  },
  {
    id: 5718,
    name: 'Royal Marines Base Chivenor Airport',
    city: 'Chivenor',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGDC',
    latitude: 51.0872001647949,
    longitude: -4.15034008026123,
    altitude: 27,
    schengen: false
  },
  {
    id: 5719,
    name: 'RAF Lyneham',
    city: 'Lyneham',
    country: 'United Kingdom',
    iata: 'LYE',
    icao: 'EGDL',
    latitude: 51.5051,
    longitude: -1.99343,
    altitude: 513,
    schengen: false
  },
  {
    id: 5720,
    name: 'MoD Boscombe Down Airport',
    city: 'Boscombe Down',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGDM',
    latitude: 51.1521987915039,
    longitude: -1.74741005897522,
    altitude: 407,
    schengen: false
  },
  {
    id: 5721,
    name: 'RNAS Culdrose',
    city: 'Culdrose',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGDR',
    latitude: 50.0861015319824,
    longitude: -5.25571012496948,
    altitude: 267,
    schengen: false
  },
  {
    id: 5722,
    name: 'MoD St. Athan',
    city: 'St. Athan',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGDX',
    latitude: 51.4048,
    longitude: -3.43575,
    altitude: 163,
    schengen: false
  },
  {
    id: 5723,
    name: 'RNAS Yeovilton',
    city: 'Yeovilton',
    country: 'United Kingdom',
    iata: 'YEO',
    icao: 'EGDY',
    latitude: 51.0093994140625,
    longitude: -2.63881993293762,
    altitude: 75,
    schengen: false
  },
  {
    id: 5724,
    name: 'Campbeltown Airport',
    city: 'Campbeltown',
    country: 'United Kingdom',
    iata: 'CAL',
    icao: 'EGEC',
    latitude: 55.437198638916,
    longitude: -5.6863899230957,
    altitude: 42,
    schengen: false
  },
  {
    id: 5725,
    name: 'Eday Airport',
    city: 'Eday',
    country: 'United Kingdom',
    iata: 'EOI',
    icao: 'EGED',
    latitude: 59.190601348877,
    longitude: -2.77221989631653,
    altitude: 10,
    schengen: false
  },
  {
    id: 5726,
    name: 'Fair Isle Airport',
    city: 'Fair Isle',
    country: 'United Kingdom',
    iata: 'FIE',
    icao: 'EGEF',
    latitude: 59.5358009338379,
    longitude: -1.62805998325348,
    altitude: 223,
    schengen: false
  },
  {
    id: 5727,
    name: 'Glasgow City Heliport',
    city: 'Glasgow',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGEG',
    latitude: 55.8613891601563,
    longitude: -4.29694414138794,
    altitude: 0,
    schengen: false
  },
  {
    id: 5728,
    name: 'North Ronaldsay Airport',
    city: 'North Ronaldsay',
    country: 'United Kingdom',
    iata: 'NRL',
    icao: 'EGEN',
    latitude: 59.3675003052,
    longitude: -2.43443989754,
    altitude: 40,
    schengen: false
  },
  {
    id: 5729,
    name: 'Oban Airport',
    city: 'North Connel',
    country: 'United Kingdom',
    iata: 'OBN',
    icao: 'EGEO',
    latitude: 56.4635009765625,
    longitude: -5.39967012405396,
    altitude: 20,
    schengen: false
  },
  {
    id: 5730,
    name: 'Papa Westray Airport',
    city: 'Papa Westray',
    country: 'United Kingdom',
    iata: 'PPW',
    icao: 'EGEP',
    latitude: 59.3516998291,
    longitude: -2.90027999878,
    altitude: 91,
    schengen: false
  },
  {
    id: 5731,
    name: 'Stronsay Airport',
    city: 'Stronsay',
    country: 'United Kingdom',
    iata: 'SOY',
    icao: 'EGER',
    latitude: 59.1553001404,
    longitude: -2.64139008522,
    altitude: 39,
    schengen: false
  },
  {
    id: 5732,
    name: 'Sanday Airport',
    city: 'Sanday',
    country: 'United Kingdom',
    iata: 'NDY',
    icao: 'EGES',
    latitude: 59.250301361084,
    longitude: -2.57666993141174,
    altitude: 68,
    schengen: false
  },
  {
    id: 5733,
    name: 'Lerwick / Tingwall Airport',
    city: 'Lerwick',
    country: 'United Kingdom',
    iata: 'LWK',
    icao: 'EGET',
    latitude: 60.192199707,
    longitude: -1.24361002445,
    altitude: 43,
    schengen: false
  },
  {
    id: 5734,
    name: 'Westray Airport',
    city: 'Westray',
    country: 'United Kingdom',
    iata: 'WRY',
    icao: 'EGEW',
    latitude: 59.3502998352,
    longitude: -2.95000004768,
    altitude: 29,
    schengen: false
  },
  {
    id: 5735,
    name: 'Colonsay Airstrip',
    city: 'Colonsay',
    country: 'United Kingdom',
    iata: 'CSA',
    icao: 'EGEY',
    latitude: 56.0574989319,
    longitude: -6.243060112,
    altitude: 44,
    schengen: false
  },
  {
    id: 5736,
    name: 'Haverfordwest Airport',
    city: 'Haverfordwest',
    country: 'United Kingdom',
    iata: 'HAW',
    icao: 'EGFE',
    latitude: 51.8330993652344,
    longitude: -4.96111011505127,
    altitude: 159,
    schengen: false
  },
  {
    id: 5737,
    name: 'Cardiff International Airport',
    city: 'Cardiff',
    country: 'United Kingdom',
    iata: 'CWL',
    icao: 'EGFF',
    latitude: 51.3967018127441,
    longitude: -3.34332990646362,
    altitude: 220,
    schengen: false
  },
  {
    id: 5738,
    name: 'Swansea Airport',
    city: 'Swansea',
    country: 'United Kingdom',
    iata: 'SWS',
    icao: 'EGFH',
    latitude: 51.6053009033203,
    longitude: -4.06783008575439,
    altitude: 299,
    schengen: false
  },
  {
    id: 5739,
    name: 'Bristol Airport',
    city: 'Bristol',
    country: 'United Kingdom',
    iata: 'BRS',
    icao: 'EGGD',
    latitude: 51.382702,
    longitude: -2.71909,
    altitude: 622,
    schengen: false
  },
  {
    id: 5740,
    name: 'Liverpool John Lennon Airport',
    city: 'Liverpool',
    country: 'United Kingdom',
    iata: 'LPL',
    icao: 'EGGP',
    latitude: 53.3335990905762,
    longitude: -2.8497200012207,
    altitude: 80,
    schengen: false
  },
  {
    id: 5741,
    name: 'London Luton Airport',
    city: 'London',
    country: 'United Kingdom',
    iata: 'LTN',
    icao: 'EGGW',
    latitude: 51.874698638916,
    longitude: -0.368333011865616,
    altitude: 526,
    schengen: false
  },
  {
    id: 5742,
    name: 'Compton Abbas Aerodrome',
    city: 'Shaftesbury',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGHA',
    latitude: 50.9672012329102,
    longitude: -2.15360999107361,
    altitude: 811,
    schengen: false
  },
  {
    id: 5743,
    name: "Land's End Airport",
    city: "Land's End",
    country: 'United Kingdom',
    iata: 'LEQ',
    icao: 'EGHC',
    latitude: 50.102798,
    longitude: -5.67056,
    altitude: 398,
    schengen: false
  },
  {
    id: 5744,
    name: 'Plymouth City Airport',
    city: 'Plymouth',
    country: 'United Kingdom',
    iata: 'PLH',
    icao: 'EGHD',
    latitude: 50.422798,
    longitude: -4.10583,
    altitude: 476,
    schengen: false
  },
  {
    id: 5745,
    name: "St. Mary's Airport",
    city: "ST MARY\\'S",
    country: 'United Kingdom',
    iata: 'ISC',
    icao: 'EGHE',
    latitude: 49.9132995605469,
    longitude: -6.29166984558106,
    altitude: 116,
    schengen: false
  },
  {
    id: 5746,
    name: 'Bournemouth Airport',
    city: 'Bournemouth',
    country: 'United Kingdom',
    iata: 'BOH',
    icao: 'EGHH',
    latitude: 50.7799987792969,
    longitude: -1.84249997138977,
    altitude: 38,
    schengen: false
  },
  {
    id: 5747,
    name: 'Southampton Airport',
    city: 'Southampton',
    country: 'United Kingdom',
    iata: 'SOU',
    icao: 'EGHI',
    latitude: 50.9502983093262,
    longitude: -1.35679996013641,
    altitude: 44,
    schengen: false
  },
  {
    id: 5748,
    name: 'Bembridge Airport',
    city: 'Bembridge',
    country: 'United Kingdom',
    iata: 'BBP',
    icao: 'EGHJ',
    latitude: 50.6781005859,
    longitude: -1.10943996906,
    altitude: 53,
    schengen: false
  },
  {
    id: 5749,
    name: 'Penzance Heliport',
    city: 'Penzance',
    country: 'United Kingdom',
    iata: 'PZE',
    icao: 'EGHK',
    latitude: 50.128101,
    longitude: -5.51845,
    altitude: 14,
    schengen: false
  },
  {
    id: 5750,
    name: 'Lasham Airport',
    city: 'Lasham',
    country: 'United Kingdom',
    iata: 'QLA',
    icao: 'EGHL',
    latitude: 51.187198638916,
    longitude: -1.03349995613098,
    altitude: 618,
    schengen: false
  },
  {
    id: 5751,
    name: 'Isle of Wight / Sandown Airport',
    city: 'Isle Of Wight',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGHN',
    latitude: 50.653099,
    longitude: -1.18221998,
    altitude: 55,
    schengen: false
  },
  {
    id: 5752,
    name: 'Popham Airfield',
    city: 'Popham',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGHP',
    latitude: 51.193901,
    longitude: -1.23472,
    altitude: 550,
    schengen: false
  },
  {
    id: 5753,
    name: 'Newquay Cornwall Airport',
    city: 'Newquai',
    country: 'United Kingdom',
    iata: 'NQY',
    icao: 'EGHQ',
    latitude: 50.440601348877,
    longitude: -4.99540996551514,
    altitude: 390,
    schengen: false
  },
  {
    id: 5754,
    name: 'Chichester/Goodwood Airport',
    city: 'Goodwood',
    country: 'United Kingdom',
    iata: 'QUG',
    icao: 'EGHR',
    latitude: 50.8594017028809,
    longitude: -0.759167015552521,
    altitude: 98,
    schengen: false
  },
  {
    id: 5755,
    name: 'Tresco Heliport',
    city: 'Tresco',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGHT',
    latitude: 49.9455986022949,
    longitude: -6.33138990402222,
    altitude: 20,
    schengen: false
  },
  {
    id: 5756,
    name: 'Shoreham Airport',
    city: 'Shoreham By Sea',
    country: 'United Kingdom',
    iata: 'ESH',
    icao: 'EGKA',
    latitude: 50.835602,
    longitude: -0.297222,
    altitude: 7,
    schengen: false
  },
  {
    id: 5757,
    name: 'London Biggin Hill Airport',
    city: 'Biggin Hill',
    country: 'United Kingdom',
    iata: 'BQH',
    icao: 'EGKB',
    latitude: 51.3307991028,
    longitude: 0.0324999988079,
    altitude: 598,
    schengen: false
  },
  {
    id: 5758,
    name: 'Lashenden (Headcorn) Airfield',
    city: 'Headcorn',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGKH',
    latitude: 51.156898,
    longitude: 0.641667,
    altitude: 72,
    schengen: false
  },
  {
    id: 5759,
    name: 'London Gatwick Airport',
    city: 'London',
    country: 'United Kingdom',
    iata: 'LGW',
    icao: 'EGKK',
    latitude: 51.148102,
    longitude: -0.190278,
    altitude: 202,
    schengen: false
  },
  {
    id: 5760,
    name: 'Redhill Aerodrome',
    city: 'Redhill',
    country: 'United Kingdom',
    iata: 'KRH',
    icao: 'EGKR',
    latitude: 51.2136001587,
    longitude: -0.138611003757,
    altitude: 222,
    schengen: false
  },
  {
    id: 5761,
    name: 'London City Airport',
    city: 'London',
    country: 'United Kingdom',
    iata: 'LCY',
    icao: 'EGLC',
    latitude: 51.505299,
    longitude: 0.055278,
    altitude: 19,
    schengen: false
  },
  {
    id: 5762,
    name: 'Farnborough Airport',
    city: 'Farnborough',
    country: 'United Kingdom',
    iata: 'FAB',
    icao: 'EGLF',
    latitude: 51.2757987976,
    longitude: -0.776332974434,
    altitude: 238,
    schengen: false
  },
  {
    id: 5763,
    name: 'Panshanger Aerodrome',
    city: 'Panshanger',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGLG',
    latitude: 51.8025016785,
    longitude: -0.158056005836,
    altitude: 249,
    schengen: false
  },
  {
    id: 5764,
    name: 'Chalgrove Airport',
    city: 'Chalsgrove',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGLJ',
    latitude: 51.6761016845703,
    longitude: -1.0808299779892,
    altitude: 240,
    schengen: false
  },
  {
    id: 5765,
    name: 'Blackbushe Airport',
    city: 'Blackbushe',
    country: 'United Kingdom',
    iata: 'BBS',
    icao: 'EGLK',
    latitude: 51.3238983154297,
    longitude: -0.847500026226044,
    altitude: 325,
    schengen: false
  },
  {
    id: 5766,
    name: 'London Heathrow Airport',
    city: 'London',
    country: 'United Kingdom',
    iata: 'LHR',
    icao: 'EGLL',
    latitude: 51.4706,
    longitude: -0.461941,
    altitude: 83,
    schengen: false
  },
  {
    id: 5767,
    name: 'White Waltham Airfield',
    city: 'Maidenhead',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGLM',
    latitude: 51.5008010864,
    longitude: -0.774443984032,
    altitude: 131,
    schengen: false
  },
  {
    id: 5768,
    name: 'London Heliport',
    city: 'London',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGLW',
    latitude: 51.4697227478,
    longitude: -0.179444000125,
    altitude: 18,
    schengen: false
  },
  {
    id: 5769,
    name: 'Southend Airport',
    city: 'Southend',
    country: 'United Kingdom',
    iata: 'SEN',
    icao: 'EGMC',
    latitude: 51.5713996887207,
    longitude: 0.695555984973908,
    altitude: 49,
    schengen: false
  },
  {
    id: 5770,
    name: 'Lydd Airport',
    city: 'Lydd',
    country: 'United Kingdom',
    iata: 'LYX',
    icao: 'EGMD',
    latitude: 50.9561004638672,
    longitude: 0.939167022705078,
    altitude: 13,
    schengen: false
  },
  {
    id: 5771,
    name: 'Kent International Airport',
    city: 'Manston',
    country: 'United Kingdom',
    iata: 'MSE',
    icao: 'EGMH',
    latitude: 51.342201,
    longitude: 1.34611,
    altitude: 178,
    schengen: false
  },
  {
    id: 5772,
    name: 'Lympne Airport',
    city: 'Lympne',
    country: 'United Kingdom',
    iata: 'LYM',
    icao: 'EGMK',
    latitude: 51.08,
    longitude: 1.013,
    altitude: 351,
    schengen: false
  },
  {
    id: 5773,
    name: 'Thurrock Airfield',
    city: 'Thurrock',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGMT',
    latitude: 51.537505,
    longitude: 0.367634,
    altitude: 20,
    schengen: false
  },
  {
    id: 5774,
    name: 'Brough Airport',
    city: 'Brough',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGNB',
    latitude: 53.7197,
    longitude: -0.566333,
    altitude: 12,
    schengen: false
  },
  {
    id: 5775,
    name: 'Carlisle Airport',
    city: 'Carlisle',
    country: 'United Kingdom',
    iata: 'CAX',
    icao: 'EGNC',
    latitude: 54.9375,
    longitude: -2.80917000770569,
    altitude: 190,
    schengen: false
  },
  {
    id: 5776,
    name: 'Retford Gamston Airport',
    city: 'Repton',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGNE',
    latitude: 53.2806015014648,
    longitude: -0.951389014720917,
    altitude: 91,
    schengen: false
  },
  {
    id: 5777,
    name: 'Blackpool International Airport',
    city: 'Blackpool',
    country: 'United Kingdom',
    iata: 'BLK',
    icao: 'EGNH',
    latitude: 53.7717018127441,
    longitude: -3.02860999107361,
    altitude: 34,
    schengen: false
  },
  {
    id: 5778,
    name: 'Humberside Airport',
    city: 'Humberside',
    country: 'United Kingdom',
    iata: 'HUY',
    icao: 'EGNJ',
    latitude: 53.5744018554688,
    longitude: -0.350832998752594,
    altitude: 121,
    schengen: false
  },
  {
    id: 5779,
    name: 'Barrow Walney Island Airport',
    city: 'Barrow Island',
    country: 'United Kingdom',
    iata: 'BWF',
    icao: 'EGNL',
    latitude: 54.1286111,
    longitude: -3.2675,
    altitude: 173,
    schengen: false
  },
  {
    id: 5780,
    name: 'Leeds Bradford Airport',
    city: 'Leeds',
    country: 'United Kingdom',
    iata: 'LBA',
    icao: 'EGNM',
    latitude: 53.8658981323242,
    longitude: -1.66057002544403,
    altitude: 681,
    schengen: false
  },
  {
    id: 5781,
    name: 'Warton Airport',
    city: 'Warton',
    country: 'United Kingdom',
    iata: 'WRT',
    icao: 'EGNO',
    latitude: 53.745098,
    longitude: -2.88306,
    altitude: 55,
    schengen: false
  },
  {
    id: 5782,
    name: 'Hawarden Airport',
    city: 'Hawarden',
    country: 'United Kingdom',
    iata: 'CEG',
    icao: 'EGNR',
    latitude: 53.1781005859375,
    longitude: -2.97778010368347,
    altitude: 45,
    schengen: false
  },
  {
    id: 5783,
    name: 'Newcastle Airport',
    city: 'Newcastle',
    country: 'United Kingdom',
    iata: 'NCL',
    icao: 'EGNT',
    latitude: 55.0374984741211,
    longitude: -1.69166994094849,
    altitude: 266,
    schengen: false
  },
  {
    id: 5784,
    name: 'Full Sutton Airfield',
    city: 'York',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGNU',
    latitude: 53.980598,
    longitude: -0.864722,
    altitude: 86,
    schengen: false
  },
  {
    id: 5785,
    name: 'Durham Tees Valley Airport',
    city: 'Teesside',
    country: 'United Kingdom',
    iata: 'MME',
    icao: 'EGNV',
    latitude: 54.5092010498047,
    longitude: -1.42940998077393,
    altitude: 120,
    schengen: false
  },
  {
    id: 5786,
    name: 'East Midlands Airport',
    city: 'East Midlands',
    country: 'United Kingdom',
    iata: 'EMA',
    icao: 'EGNX',
    latitude: 52.8311004639,
    longitude: -1.32806003094,
    altitude: 306,
    schengen: false
  },
  {
    id: 5787,
    name: 'Llanbedr Airport',
    city: 'Llanbedr',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGOD',
    latitude: 52.811698913574,
    longitude: -4.1235799789429,
    altitude: 30,
    schengen: false
  },
  {
    id: 5788,
    name: 'RAF Ternhill',
    city: 'Ternhill',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGOE',
    latitude: 52.8712005615234,
    longitude: -2.53356003761291,
    altitude: 272,
    schengen: false
  },
  {
    id: 5789,
    name: 'RAF Spadeadam',
    city: 'Spadeadam',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGOM',
    latitude: 55.0499992371,
    longitude: -2.54999995232,
    altitude: 1066,
    schengen: false
  },
  {
    id: 5790,
    name: 'RAF Mona',
    city: 'Mona',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGOQ',
    latitude: 53.258598,
    longitude: -4.37355,
    altitude: 202,
    schengen: false
  },
  {
    id: 5791,
    name: 'RAF Shawbury',
    city: 'Shawbury',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGOS',
    latitude: 52.7981986999512,
    longitude: -2.66804003715515,
    altitude: 249,
    schengen: false
  },
  {
    id: 5792,
    name: 'Anglesey Airport',
    city: 'Angelsey',
    country: 'United Kingdom',
    iata: 'VLY',
    icao: 'EGOV',
    latitude: 53.2481002808,
    longitude: -4.53533983231,
    altitude: 37,
    schengen: false
  },
  {
    id: 5793,
    name: 'RAF Woodvale',
    city: 'Woodvale',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGOW',
    latitude: 53.5816001892,
    longitude: -3.05552005768,
    altitude: 37,
    schengen: false
  },
  {
    id: 5794,
    name: 'Kirkwall Airport',
    city: 'Kirkwall',
    country: 'United Kingdom',
    iata: 'KOI',
    icao: 'EGPA',
    latitude: 58.9578018188477,
    longitude: -2.90499997138977,
    altitude: 50,
    schengen: false
  },
  {
    id: 5795,
    name: 'Sumburgh Airport',
    city: 'Sumburgh',
    country: 'United Kingdom',
    iata: 'LSI',
    icao: 'EGPB',
    latitude: 59.8788986206055,
    longitude: -1.29556000232697,
    altitude: 20,
    schengen: false
  },
  {
    id: 5796,
    name: 'Wick Airport',
    city: 'Wick',
    country: 'United Kingdom',
    iata: 'WIC',
    icao: 'EGPC',
    latitude: 58.4589004516602,
    longitude: -3.09306001663208,
    altitude: 126,
    schengen: false
  },
  {
    id: 5797,
    name: 'Aberdeen Dyce Airport',
    city: 'Aberdeen',
    country: 'United Kingdom',
    iata: 'ABZ',
    icao: 'EGPD',
    latitude: 57.2019004821777,
    longitude: -2.19777989387512,
    altitude: 215,
    schengen: false
  },
  {
    id: 5798,
    name: 'Inverness Airport',
    city: 'Inverness',
    country: 'United Kingdom',
    iata: 'INV',
    icao: 'EGPE',
    latitude: 57.5424995422363,
    longitude: -4.0475001335144,
    altitude: 31,
    schengen: false
  },
  {
    id: 5799,
    name: 'Glasgow International Airport',
    city: 'Glasgow',
    country: 'United Kingdom',
    iata: 'GLA',
    icao: 'EGPF',
    latitude: 55.8718986511,
    longitude: -4.43306016922,
    altitude: 26,
    schengen: false
  },
  {
    id: 5800,
    name: 'Edinburgh Airport',
    city: 'Edinburgh',
    country: 'United Kingdom',
    iata: 'EDI',
    icao: 'EGPH',
    latitude: 55.9500007629395,
    longitude: -3.37249994277954,
    altitude: 135,
    schengen: false
  },
  {
    id: 5801,
    name: 'Islay Airport',
    city: 'Islay',
    country: 'United Kingdom',
    iata: 'ILY',
    icao: 'EGPI',
    latitude: 55.6819000244141,
    longitude: -6.25666999816895,
    altitude: 56,
    schengen: false
  },
  {
    id: 5802,
    name: 'Glasgow Prestwick Airport',
    city: 'Prestwick',
    country: 'United Kingdom',
    iata: 'PIK',
    icao: 'EGPK',
    latitude: 55.5093994140625,
    longitude: -4.586669921875,
    altitude: 65,
    schengen: false
  },
  {
    id: 5803,
    name: 'Benbecula Airport',
    city: 'Benbecula',
    country: 'United Kingdom',
    iata: 'BEB',
    icao: 'EGPL',
    latitude: 57.4810981750488,
    longitude: -7.36278009414673,
    altitude: 19,
    schengen: false
  },
  {
    id: 5804,
    name: 'Scatsta Airport',
    city: 'Scatsta',
    country: 'United Kingdom',
    iata: 'SCS',
    icao: 'EGPM',
    latitude: 60.4328002929688,
    longitude: -1.29611003398895,
    altitude: 81,
    schengen: false
  },
  {
    id: 5805,
    name: 'Dundee Airport',
    city: 'Dundee',
    country: 'United Kingdom',
    iata: 'DND',
    icao: 'EGPN',
    latitude: 56.4524993896484,
    longitude: -3.02583003044128,
    altitude: 17,
    schengen: false
  },
  {
    id: 5806,
    name: 'Stornoway Airport',
    city: 'Stornoway',
    country: 'United Kingdom',
    iata: 'SYY',
    icao: 'EGPO',
    latitude: 58.2155990600586,
    longitude: -6.33111000061035,
    altitude: 26,
    schengen: false
  },
  {
    id: 5807,
    name: 'Barra Airport',
    city: 'Barra',
    country: 'United Kingdom',
    iata: 'BRR',
    icao: 'EGPR',
    latitude: 57.0228,
    longitude: -7.44306,
    altitude: 5,
    schengen: false
  },
  {
    id: 5808,
    name: 'Perth/Scone Airport',
    city: 'Perth',
    country: 'United Kingdom',
    iata: 'PSL',
    icao: 'EGPT',
    latitude: 56.4392013549805,
    longitude: -3.37222003936768,
    altitude: 397,
    schengen: false
  },
  {
    id: 5809,
    name: 'Tiree Airport',
    city: 'Tiree',
    country: 'United Kingdom',
    iata: 'TRE',
    icao: 'EGPU',
    latitude: 56.4991989135742,
    longitude: -6.86917018890381,
    altitude: 38,
    schengen: false
  },
  {
    id: 5810,
    name: 'Unst Airport',
    city: 'Unst',
    country: 'United Kingdom',
    iata: 'UNT',
    icao: 'EGPW',
    latitude: 60.747200012207,
    longitude: -0.85385000705719,
    altitude: 0,
    schengen: false
  },
  {
    id: 5811,
    name: 'RAF Kinloss',
    city: 'Kinloss',
    country: 'United Kingdom',
    iata: 'FSS',
    icao: 'EGQK',
    latitude: 57.6493988037,
    longitude: -3.56064009666,
    altitude: 22,
    schengen: false
  },
  {
    id: 5812,
    name: 'RAF Leuchars',
    city: 'Leuchars',
    country: 'United Kingdom',
    iata: 'ADX',
    icao: 'EGQL',
    latitude: 56.3728981018066,
    longitude: -2.86843991279602,
    altitude: 38,
    schengen: false
  },
  {
    id: 5813,
    name: 'RAF Lossiemouth',
    city: 'Lossiemouth',
    country: 'United Kingdom',
    iata: 'LMO',
    icao: 'EGQS',
    latitude: 57.7052001953125,
    longitude: -3.33916997909546,
    altitude: 42,
    schengen: false
  },
  {
    id: 5814,
    name: 'Cambridge Airport',
    city: 'Cambridge',
    country: 'United Kingdom',
    iata: 'CBG',
    icao: 'EGSC',
    latitude: 52.205002,
    longitude: 0.175,
    altitude: 47,
    schengen: false
  },
  {
    id: 5815,
    name: 'Peterborough Business Airport',
    city: 'Peterborough',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGSF',
    latitude: 52.4681015014648,
    longitude: -0.251111000776291,
    altitude: 26,
    schengen: false
  },
  {
    id: 5816,
    name: 'Stapleford Aerodrome',
    city: 'Stapleford',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGSG',
    latitude: 51.6525001526,
    longitude: 0.155833005905,
    altitude: 183,
    schengen: false
  },
  {
    id: 5817,
    name: 'Norwich International Airport',
    city: 'Norwich',
    country: 'United Kingdom',
    iata: 'NWI',
    icao: 'EGSH',
    latitude: 52.6758003235,
    longitude: 1.28278005123,
    altitude: 117,
    schengen: false
  },
  {
    id: 5818,
    name: 'Beccles Airport',
    city: 'Beccles',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGSM',
    latitude: 52.4352989197,
    longitude: 1.61833000183,
    altitude: 80,
    schengen: false
  },
  {
    id: 5819,
    name: 'London Stansted Airport',
    city: 'London',
    country: 'United Kingdom',
    iata: 'STN',
    icao: 'EGSS',
    latitude: 51.8849983215,
    longitude: 0.234999999404,
    altitude: 348,
    schengen: false
  },
  {
    id: 5820,
    name: 'Duxford Aerodrome',
    city: 'Duxford',
    country: 'United Kingdom',
    iata: 'QFO',
    icao: 'EGSU',
    latitude: 52.090801,
    longitude: 0.131944,
    altitude: 125,
    schengen: false
  },
  {
    id: 5821,
    name: 'North Weald Airport',
    city: 'North Weald',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGSX',
    latitude: 51.721698761,
    longitude: 0.154166996479,
    altitude: 321,
    schengen: false
  },
  {
    id: 5822,
    name: 'Sheffield City Heliport',
    city: 'Fowlmere',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGSY',
    latitude: 53.394299,
    longitude: -1.38849,
    altitude: 231,
    schengen: false
  },
  {
    id: 5823,
    name: 'Wycombe Air Park',
    city: 'Wycombe',
    country: 'United Kingdom',
    iata: 'HYC',
    icao: 'EGTB',
    latitude: 51.6116981506,
    longitude: -0.808332979679,
    altitude: 515,
    schengen: false
  },
  {
    id: 5824,
    name: 'Cranfield Airport',
    city: 'Cranfield',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGTC',
    latitude: 52.0722007751,
    longitude: -0.616666972637,
    altitude: 358,
    schengen: false
  },
  {
    id: 5825,
    name: 'Exeter International Airport',
    city: 'Exeter',
    country: 'United Kingdom',
    iata: 'EXT',
    icao: 'EGTE',
    latitude: 50.7344017028809,
    longitude: -3.41388988494873,
    altitude: 102,
    schengen: false
  },
  {
    id: 5826,
    name: 'Fairoaks Airport',
    city: 'Fairoaks',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGTF',
    latitude: 51.3480987549,
    longitude: -0.558888971806,
    altitude: 80,
    schengen: false
  },
  {
    id: 5827,
    name: 'Bristol Filton Airport',
    city: 'Bristol',
    country: 'United Kingdom',
    iata: 'FZO',
    icao: 'EGTG',
    latitude: 51.5194015503,
    longitude: -2.59083008766,
    altitude: 226,
    schengen: false
  },
  {
    id: 5828,
    name: 'Oxford (Kidlington) Airport',
    city: 'Oxford',
    country: 'United Kingdom',
    iata: 'OXF',
    icao: 'EGTK',
    latitude: 51.8368988037,
    longitude: -1.32000005245,
    altitude: 270,
    schengen: false
  },
  {
    id: 5829,
    name: 'Rochester Airport',
    city: 'Rochester',
    country: 'United Kingdom',
    iata: 'RCS',
    icao: 'EGTO',
    latitude: 51.3518981933594,
    longitude: 0.50333297252655,
    altitude: 436,
    schengen: false
  },
  {
    id: 5830,
    name: 'Elstree Airfield',
    city: 'Elstree',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGTR',
    latitude: 51.6557998657,
    longitude: -0.325832992792,
    altitude: 332,
    schengen: false
  },
  {
    id: 5831,
    name: 'RAF Benson',
    city: 'Benson',
    country: 'United Kingdom',
    iata: 'BEX',
    icao: 'EGUB',
    latitude: 51.6164016724,
    longitude: -1.09582996368,
    altitude: 226,
    schengen: false
  },
  {
    id: 5832,
    name: 'RAF Lakenheath',
    city: 'Lakenheath',
    country: 'United Kingdom',
    iata: 'LKZ',
    icao: 'EGUL',
    latitude: 52.4093017578,
    longitude: 0.56099998951,
    altitude: 32,
    schengen: false
  },
  {
    id: 5833,
    name: 'RAF Mildenhall',
    city: 'Mildenhall',
    country: 'United Kingdom',
    iata: 'MHZ',
    icao: 'EGUN',
    latitude: 52.3619003295898,
    longitude: 0.486405998468399,
    altitude: 33,
    schengen: false
  },
  {
    id: 5834,
    name: 'RAF Wattisham',
    city: 'Wattisham',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGUW',
    latitude: 52.1273002625,
    longitude: 0.956264019012,
    altitude: 284,
    schengen: false
  },
  {
    id: 5835,
    name: 'RAF Wyton',
    city: 'Wyton',
    country: 'United Kingdom',
    iata: 'QUY',
    icao: 'EGUY',
    latitude: 52.3572006226,
    longitude: -0.107832998037,
    altitude: 135,
    schengen: false
  },
  {
    id: 5836,
    name: 'RAF Fairford',
    city: 'Fairford',
    country: 'United Kingdom',
    iata: 'FFD',
    icao: 'EGVA',
    latitude: 51.6822013855,
    longitude: -1.79003000259,
    altitude: 286,
    schengen: false
  },
  {
    id: 5837,
    name: 'RAF Brize Norton',
    city: 'Brize Norton',
    country: 'United Kingdom',
    iata: 'BZZ',
    icao: 'EGVN',
    latitude: 51.75,
    longitude: -1.58362,
    altitude: 288,
    schengen: false
  },
  {
    id: 5838,
    name: 'RAF Odiham',
    city: 'Odiham',
    country: 'United Kingdom',
    iata: 'ODH',
    icao: 'EGVO',
    latitude: 51.2341003418,
    longitude: -0.94282501936,
    altitude: 405,
    schengen: false
  },
  {
    id: 5839,
    name: 'DCAE Cosford Air Base',
    city: 'Cosford',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGWC',
    latitude: 52.639999,
    longitude: -2.30558,
    altitude: 272,
    schengen: false
  },
  {
    id: 5840,
    name: 'RAF Northolt',
    city: 'Northolt',
    country: 'United Kingdom',
    iata: 'NHT',
    icao: 'EGWU',
    latitude: 51.5530014038,
    longitude: -0.418166995049,
    altitude: 124,
    schengen: false
  },
  {
    id: 5841,
    name: 'RAF Coningsby',
    city: 'Coningsby',
    country: 'United Kingdom',
    iata: 'QCY',
    icao: 'EGXC',
    latitude: 53.0929985046,
    longitude: -0.166014000773,
    altitude: 25,
    schengen: false
  },
  {
    id: 5842,
    name: 'RAF Dishforth',
    city: 'Dishforth',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGXD',
    latitude: 54.1371994019,
    longitude: -1.42025005817,
    altitude: 117,
    schengen: false
  },
  {
    id: 5843,
    name: 'Leeming Airport',
    city: 'Leeming',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGXE',
    latitude: 54.2924,
    longitude: -1.5354,
    altitude: 132,
    schengen: false
  },
  {
    id: 5844,
    name: 'Leeds East Airport',
    city: 'Church Fenton',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGXG',
    latitude: 53.834301,
    longitude: -1.1955,
    altitude: 29,
    schengen: false
  },
  {
    id: 5845,
    name: 'RAF Honington',
    city: 'Honington',
    country: 'United Kingdom',
    iata: 'BEQ',
    icao: 'EGXH',
    latitude: 52.342601776123,
    longitude: 0.772939026355743,
    altitude: 174,
    schengen: false
  },
  {
    id: 5846,
    name: 'RAF Cottesmore',
    city: 'Cottesmore',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGXJ',
    latitude: 52.735699,
    longitude: -0.648769,
    altitude: 461,
    schengen: false
  },
  {
    id: 5847,
    name: 'RAF Scampton',
    city: 'Scampton',
    country: 'United Kingdom',
    iata: 'SQZ',
    icao: 'EGXP',
    latitude: 53.307800293,
    longitude: -0.550832986832,
    altitude: 202,
    schengen: false
  },
  {
    id: 5848,
    name: 'RAF Wittering',
    city: 'Wittering',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGXT',
    latitude: 52.612598,
    longitude: -0.476453,
    altitude: 273,
    schengen: false
  },
  {
    id: 5849,
    name: 'RAF Linton-On-Ouse',
    city: 'Linton-on-ouse',
    country: 'United Kingdom',
    iata: 'HRT',
    icao: 'EGXU',
    latitude: 54.0489006042,
    longitude: -1.2527500391,
    altitude: 53,
    schengen: false
  },
  {
    id: 5850,
    name: 'RAF Waddington',
    city: 'Waddington',
    country: 'United Kingdom',
    iata: 'WTN',
    icao: 'EGXW',
    latitude: 53.1661987305,
    longitude: -0.523810982704,
    altitude: 231,
    schengen: false
  },
  {
    id: 5851,
    name: 'RAF Topcliffe',
    city: 'Topcliffe',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGXZ',
    latitude: 54.2055015564,
    longitude: -1.3820899725,
    altitude: 92,
    schengen: false
  },
  {
    id: 5852,
    name: 'RAF Cranwell',
    city: 'Cranwell',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGYD',
    latitude: 53.0303001404,
    longitude: -0.48324200511,
    altitude: 218,
    schengen: false
  },
  {
    id: 5853,
    name: 'RAF Barkston Heath',
    city: 'Barkston Heath',
    country: 'United Kingdom',
    iata: '',
    icao: 'EGYE',
    latitude: 52.9622001648,
    longitude: -0.561625003815,
    altitude: 367,
    schengen: false
  },
  {
    id: 5854,
    name: 'RAF Marham',
    city: 'Marham',
    country: 'United Kingdom',
    iata: 'KNF',
    icao: 'EGYM',
    latitude: 52.648395,
    longitude: 0.550692,
    altitude: 75,
    schengen: false
  },
  {
    id: 5855,
    name: 'North Sea',
    city: 'Forties Alpha',
    country: 'United Kingdom',
    iata: '',
    icao: 'FA08',
    latitude: 57.7168,
    longitude: -1.0168,
    altitude: 0,
    schengen: false
  },
  {
    id: 5856,
    name: 'St. Helena Airport',
    city: 'Longwood',
    country: 'United Kingdom',
    iata: 'HLE',
    icao: 'FHSH',
    latitude: -15.957725,
    longitude: -5.645943,
    altitude: 1017,
    schengen: false
  },
  {
    id: 5857,
    name: 'Ocean Reef Club Airport',
    city: 'Ocean Reef Club Airport',
    country: 'United States',
    iata: 'OCA',
    icao: '07FA',
    latitude: 25.325399398804,
    longitude: -80.274803161621,
    altitude: 8,
    schengen: false
  },
  {
    id: 5858,
    name: 'Glasgow Industrial Airport',
    city: 'Glasgow',
    country: 'United States',
    iata: '',
    icao: '07MT',
    latitude: 48.4211006164551,
    longitude: -106.52799987793,
    altitude: 2762,
    schengen: false
  },
  {
    id: 5859,
    name: 'Oconomowoc Airport',
    city: 'Oconomowoc',
    country: 'United States',
    iata: '',
    icao: '0WI8',
    latitude: 43.1389007568359,
    longitude: -88.472297668457,
    altitude: 885,
    schengen: false
  },
  {
    id: 5860,
    name: 'Sky Ranch At Carefree Airport',
    city: 'Carefree',
    country: 'United States',
    iata: '',
    icao: '18AZ',
    latitude: 33.8180999755859,
    longitude: -111.898002624512,
    altitude: 2568,
    schengen: false
  },
  {
    id: 5861,
    name: 'Mobile Airport',
    city: 'Mobile',
    country: 'United States',
    iata: '',
    icao: '1AZ0',
    latitude: 33.1119003295898,
    longitude: -112.268997192383,
    altitude: 1261,
    schengen: false
  },
  {
    id: 5862,
    name: 'Earl L. Small Jr. Field/Stockmar Airport',
    city: 'Villa Rica',
    country: 'United States',
    iata: '',
    icao: '20GA',
    latitude: 33.7565002441,
    longitude: -84.8846969604,
    altitude: 1110,
    schengen: false
  },
  {
    id: 5863,
    name: 'The Farm Airport',
    city: 'Summit',
    country: 'United States',
    iata: '',
    icao: '24SC',
    latitude: 33.9357986450195,
    longitude: -81.4297027587891,
    altitude: 580,
    schengen: false
  },
  {
    id: 5864,
    name: 'Air Orlando Heliport',
    city: 'Orlando',
    country: 'United States',
    iata: '',
    icao: '2FD7',
    latitude: 28.4356002807617,
    longitude: -81.4730987548828,
    altitude: 120,
    schengen: false
  },
  {
    id: 5865,
    name: 'Benson Airstrip',
    city: 'Uvalde',
    country: 'United States',
    iata: '',
    icao: '2XS8',
    latitude: 29.2294006347656,
    longitude: -99.8238983154297,
    altitude: 929,
    schengen: false
  },
  {
    id: 5866,
    name: 'Aleknagik / New Airport',
    city: 'Aleknagik',
    country: 'United States',
    iata: 'WKK',
    icao: '5A8',
    latitude: 59.2826004028,
    longitude: -158.617996216,
    altitude: 66,
    schengen: false
  },
  {
    id: 5867,
    name: 'Lathan Strip',
    city: 'Mocksville',
    country: 'United States',
    iata: '',
    icao: '5NC2',
    latitude: 34.8763008117676,
    longitude: -80.6192016601563,
    altitude: 575,
    schengen: false
  },
  {
    id: 5868,
    name: 'Rancho San Simeon Airport',
    city: 'Cambria',
    country: 'United States',
    iata: '',
    icao: '66CA',
    latitude: 35.6077003479004,
    longitude: -121.110000610352,
    altitude: 320,
    schengen: false
  },
  {
    id: 5869,
    name: 'Sugar Loaf Shores Airport',
    city: 'Key West',
    country: 'United States',
    iata: '',
    icao: '7FA1',
    latitude: 24.6487998962402,
    longitude: -81.5798034667969,
    altitude: 4,
    schengen: false
  },
  {
    id: 5870,
    name: 'Haller Airpark',
    city: 'Green Cove Springs',
    country: 'United States',
    iata: '',
    icao: '7FL4',
    latitude: 29.904057,
    longitude: -81.68515,
    altitude: 75,
    schengen: false
  },
  {
    id: 5871,
    name: 'Denali Airport',
    city: 'McKinley Park',
    country: 'United States',
    iata: '',
    icao: 'AK06',
    latitude: 63.6450996398926,
    longitude: -148.798004150391,
    altitude: 2050,
    schengen: false
  },
  {
    id: 5872,
    name: 'King Ranch Airport',
    city: 'Sutton',
    country: 'United States',
    iata: '',
    icao: 'AK59',
    latitude: 61.7956008911133,
    longitude: -148.354995727539,
    altitude: 1350,
    schengen: false
  },
  {
    id: 5873,
    name: 'Flagler Aerial Spraying Inc Airport',
    city: 'Flagler',
    country: 'United States',
    iata: '',
    icao: 'CO00',
    latitude: 39.2799987792969,
    longitude: -103.067001342773,
    altitude: 4945,
    schengen: false
  },
  {
    id: 5874,
    name: 'Coral Creek Airport',
    city: 'Placida',
    country: 'United States',
    iata: '',
    icao: 'FA54',
    latitude: 26.8544998168945,
    longitude: -82.2511978149414,
    altitude: 4,
    schengen: false
  },
  {
    id: 5875,
    name: 'Mallards Landing Airport',
    city: 'Locust Grove',
    country: 'United States',
    iata: '',
    icao: 'GA04',
    latitude: 33.3656997680664,
    longitude: -84.1651992797852,
    altitude: 837,
    schengen: false
  },
  {
    id: 5876,
    name: 'Waikoloa Heliport',
    city: 'Waikoloa Village',
    country: 'United States',
    iata: 'WKL',
    icao: 'HI07',
    latitude: 19.9205,
    longitude: -155.8607,
    altitude: 119,
    schengen: false
  },
  {
    id: 5877,
    name: 'Brookneal/Campbell County Airport',
    city: 'Brookneal',
    country: 'United States',
    iata: '',
    icao: 'K0V4',
    latitude: 37.1417007446,
    longitude: -79.0164031982,
    altitude: 596,
    schengen: false
  },
  {
    id: 5878,
    name: 'Brenham Municipal Airport',
    city: 'Brenham',
    country: 'United States',
    iata: '',
    icao: 'K11R',
    latitude: 30.21899986,
    longitude: -96.3742981,
    altitude: 307,
    schengen: false
  },
  {
    id: 5879,
    name: 'Sublette Municipal Airport',
    city: 'Sublette',
    country: 'United States',
    iata: '',
    icao: 'K19S',
    latitude: 37.49140167,
    longitude: -100.8300018,
    altitude: 2908,
    schengen: false
  },
  {
    id: 5880,
    name: 'Kestrel Airpark',
    city: 'San Antonio',
    country: 'United States',
    iata: '',
    icao: 'K1T7',
    latitude: 29.8127002716064,
    longitude: -98.4253005981445,
    altitude: 1250,
    schengen: false
  },
  {
    id: 5881,
    name: 'Clarke County Airport',
    city: 'Quitman',
    country: 'United States',
    iata: '',
    icao: 'K23M',
    latitude: 32.0849,
    longitude: -88.738897,
    altitude: 320,
    schengen: false
  },
  {
    id: 5882,
    name: 'Causey Airport',
    city: 'Liberty',
    country: 'United States',
    iata: '',
    icao: 'K2A5',
    latitude: 35.9118003845215,
    longitude: -79.6175994873047,
    altitude: 723,
    schengen: false
  },
  {
    id: 5883,
    name: 'Iraan Municipal Airport',
    city: 'Iraan',
    country: 'United States',
    iata: 'IRB',
    icao: 'K2F0',
    latitude: 30.9057006836,
    longitude: -101.891998291,
    altitude: 2200,
    schengen: false
  },
  {
    id: 5884,
    name: 'Shelby County Airport',
    city: 'Shelbyville',
    country: 'United States',
    iata: '',
    icao: 'K2H0',
    latitude: 39.4104003906,
    longitude: -88.8453979492,
    altitude: 618,
    schengen: false
  },
  {
    id: 5885,
    name: 'Rough River State Park Airport',
    city: 'Falls of Rough',
    country: 'United States',
    iata: '',
    icao: 'K2I3',
    latitude: 37.610022,
    longitude: -86.507262,
    altitude: 577,
    schengen: false
  },
  {
    id: 5886,
    name: 'Neodesha Municipal Airport',
    city: 'Neodesha',
    country: 'United States',
    iata: '',
    icao: 'K2K7',
    latitude: 37.4353981018066,
    longitude: -95.6461029052734,
    altitude: 841,
    schengen: false
  },
  {
    id: 5887,
    name: 'Augusta Municipal Airport',
    city: 'Augusta',
    country: 'United States',
    iata: '',
    icao: 'K3AU',
    latitude: 37.6716003417969,
    longitude: -97.0779037475586,
    altitude: 1328,
    schengen: false
  },
  {
    id: 5888,
    name: 'Lakeway Airpark',
    city: 'Lakeway',
    country: 'United States',
    iata: '',
    icao: 'K3R9',
    latitude: 30.3575000762939,
    longitude: -97.994499206543,
    altitude: 909,
    schengen: false
  },
  {
    id: 5889,
    name: 'Henry County Airport',
    city: 'Hampton',
    country: 'United States',
    iata: '',
    icao: 'K4A7',
    latitude: 33.389099,
    longitude: -84.332397,
    altitude: 874,
    schengen: false
  },
  {
    id: 5890,
    name: 'Dell Flight Strip',
    city: 'Dell',
    country: 'United States',
    iata: '',
    icao: 'K4U9',
    latitude: 44.7356987,
    longitude: -112.720001221,
    altitude: 6007,
    schengen: false
  },
  {
    id: 5891,
    name: 'Madison Municipal Airport',
    city: 'Madison',
    country: 'United States',
    iata: '',
    icao: 'K52A',
    latitude: 33.6120986938,
    longitude: -83.4604034424,
    altitude: 694,
    schengen: false
  },
  {
    id: 5892,
    name: 'East Troy Municipal Airport',
    city: 'East Troy',
    country: 'United States',
    iata: '',
    icao: 'K57C',
    latitude: 42.7971992492676,
    longitude: -88.3725967407227,
    altitude: 860,
    schengen: false
  },
  {
    id: 5893,
    name: 'Saratoga County Airport',
    city: 'Ballston Spa',
    country: 'United States',
    iata: '',
    icao: 'K5B2',
    latitude: 43.05130005,
    longitude: -73.86119843,
    altitude: 434,
    schengen: false
  },
  {
    id: 5894,
    name: 'Christmas Valley Airport',
    city: 'Christmas Valley',
    country: 'United States',
    iata: '',
    icao: 'K62S',
    latitude: 43.236499786377,
    longitude: -120.666000366211,
    altitude: 4317,
    schengen: false
  },
  {
    id: 5895,
    name: 'Iosco County Airport',
    city: 'East Tawas',
    country: 'United States',
    iata: 'ECA',
    icao: 'K6D9',
    latitude: 44.312801,
    longitude: -83.422302,
    altitude: 606,
    schengen: false
  },
  {
    id: 5896,
    name: 'Saluda County Airport',
    city: 'Saluda',
    country: 'United States',
    iata: '',
    icao: 'K6J4',
    latitude: 33.9267997741699,
    longitude: -81.7946014404297,
    altitude: 555,
    schengen: false
  },
  {
    id: 5897,
    name: 'Big Timber Airport',
    city: 'Big Timber',
    country: 'United States',
    iata: '',
    icao: 'K6S0',
    latitude: 45.8064002990723,
    longitude: -109.981002807617,
    altitude: 4492,
    schengen: false
  },
  {
    id: 5898,
    name: 'Hyde County Airport',
    city: 'Engelhard',
    country: 'United States',
    iata: '',
    icao: 'K7W6',
    latitude: 35.5624008178711,
    longitude: -75.9552001953125,
    altitude: 8,
    schengen: false
  },
  {
    id: 5899,
    name: 'El Dorado Springs Memorial Airport',
    city: 'El dorado springs',
    country: 'United States',
    iata: '',
    icao: 'K87K',
    latitude: 37.8567008972168,
    longitude: -93.9990997314453,
    altitude: 931,
    schengen: false
  },
  {
    id: 5900,
    name: 'Ak-Chin Regional Airport',
    city: 'Phoenix',
    country: 'United States',
    iata: '',
    icao: 'KA39',
    latitude: 32.990806,
    longitude: -111.918528,
    altitude: 1300,
    schengen: false
  },
  {
    id: 5901,
    name: 'Colorado Springs East Airport',
    city: 'Ellicott',
    country: 'United States',
    iata: '',
    icao: 'KA50',
    latitude: 38.8744010925293,
    longitude: -104.410003662109,
    altitude: 6145,
    schengen: false
  },
  {
    id: 5902,
    name: 'Apalachicola Regional Airport',
    city: 'Apalachicola',
    country: 'United States',
    iata: 'AAF',
    icao: 'KAAF',
    latitude: 29.72750092,
    longitude: -85.02749634,
    altitude: 20,
    schengen: false
  },
  {
    id: 5903,
    name: 'Andrau Airpark',
    city: 'Houston',
    country: 'United States',
    iata: 'AAP',
    icao: 'KAAP',
    latitude: 29.7224998474,
    longitude: -95.5883026123,
    altitude: 79,
    schengen: false
  },
  {
    id: 5904,
    name: 'Lehigh Valley International Airport',
    city: 'Allentown',
    country: 'United States',
    iata: 'ABE',
    icao: 'KABE',
    latitude: 40.652099609375,
    longitude: -75.440803527832,
    altitude: 393,
    schengen: false
  },
  {
    id: 5905,
    name: 'Abilene Regional Airport',
    city: 'Abilene',
    country: 'United States',
    iata: 'ABI',
    icao: 'KABI',
    latitude: 32.4113006592,
    longitude: -99.6819000244,
    altitude: 1791,
    schengen: false
  },
  {
    id: 5906,
    name: 'Albuquerque International Sunport',
    city: 'Albuquerque',
    country: 'United States',
    iata: 'ABQ',
    icao: 'KABQ',
    latitude: 35.040199,
    longitude: -106.609001,
    altitude: 5355,
    schengen: false
  },
  {
    id: 5907,
    name: 'Aberdeen Regional Airport',
    city: 'Aberdeen',
    country: 'United States',
    iata: 'ABR',
    icao: 'KABR',
    latitude: 45.4491004943848,
    longitude: -98.4217987060547,
    altitude: 1302,
    schengen: false
  },
  {
    id: 5908,
    name: 'Southwest Georgia Regional Airport',
    city: 'Albany',
    country: 'United States',
    iata: 'ABY',
    icao: 'KABY',
    latitude: 31.5354995727539,
    longitude: -84.1945037841797,
    altitude: 197,
    schengen: false
  },
  {
    id: 5909,
    name: 'Jimmy Carter Regional Airport',
    city: 'Americus',
    country: 'United States',
    iata: '',
    icao: 'KACJ',
    latitude: 32.110802,
    longitude: -84.188904,
    altitude: 468,
    schengen: false
  },
  {
    id: 5910,
    name: 'Nantucket Memorial Airport',
    city: 'Nantucket',
    country: 'United States',
    iata: 'ACK',
    icao: 'KACK',
    latitude: 41.25310135,
    longitude: -70.06020355,
    altitude: 47,
    schengen: false
  },
  {
    id: 5911,
    name: 'Waco Regional Airport',
    city: 'Waco',
    country: 'United States',
    iata: 'ACT',
    icao: 'KACT',
    latitude: 31.6112995147705,
    longitude: -97.2304992675781,
    altitude: 516,
    schengen: false
  },
  {
    id: 5912,
    name: 'California Redwood Coast-Humboldt County Airport',
    city: 'Arcata CA',
    country: 'United States',
    iata: 'ACV',
    icao: 'KACV',
    latitude: 40.978101,
    longitude: -124.109,
    altitude: 221,
    schengen: false
  },
  {
    id: 5913,
    name: 'Atlantic City International Airport',
    city: 'Atlantic City',
    country: 'United States',
    iata: 'ACY',
    icao: 'KACY',
    latitude: 39.4575996398926,
    longitude: -74.5772018432617,
    altitude: 75,
    schengen: false
  },
  {
    id: 5914,
    name: 'Henderson Field',
    city: 'Wallace',
    country: 'United States',
    iata: '',
    icao: 'KACZ',
    latitude: 34.7178993225098,
    longitude: -78.0036010742188,
    altitude: 39,
    schengen: false
  },
  {
    id: 5915,
    name: 'Ada Regional Airport',
    city: 'Ada',
    country: 'United States',
    iata: 'ADT',
    icao: 'KADH',
    latitude: 34.804298,
    longitude: -96.671303,
    altitude: 1016,
    schengen: false
  },
  {
    id: 5916,
    name: 'Ardmore Municipal Airport',
    city: 'Ardmore',
    country: 'United States',
    iata: 'ADM',
    icao: 'KADM',
    latitude: 34.30301,
    longitude: -97.0196342,
    altitude: 777,
    schengen: false
  },
  {
    id: 5917,
    name: 'Addison Airport',
    city: 'Addison',
    country: 'United States',
    iata: 'ADS',
    icao: 'KADS',
    latitude: 32.9686012268,
    longitude: -96.8364028931,
    altitude: 644,
    schengen: false
  },
  {
    id: 5918,
    name: 'Andrews Air Force Base',
    city: 'Camp Springs',
    country: 'United States',
    iata: 'ADW',
    icao: 'KADW',
    latitude: 38.810799,
    longitude: -76.866997,
    altitude: 280,
    schengen: false
  },
  {
    id: 5919,
    name: 'Double Eagle II Airport',
    city: 'Albuquerque',
    country: 'United States',
    iata: '',
    icao: 'KAEG',
    latitude: 35.1451988220215,
    longitude: -106.794998168945,
    altitude: 5837,
    schengen: false
  },
  {
    id: 5920,
    name: 'Alexandria International Airport',
    city: 'Alexandria',
    country: 'United States',
    iata: 'AEX',
    icao: 'KAEX',
    latitude: 31.3274002075195,
    longitude: -92.5497970581055,
    altitude: 89,
    schengen: false
  },
  {
    id: 5921,
    name: 'Fort Worth Alliance Airport',
    city: 'Fort Worth',
    country: 'United States',
    iata: 'AFW',
    icao: 'KAFW',
    latitude: 32.9875984192,
    longitude: -97.3188018799,
    altitude: 722,
    schengen: false
  },
  {
    id: 5922,
    name: 'Allegheny County Airport',
    city: 'Pittsburgh',
    country: 'United States',
    iata: 'AGC',
    icao: 'KAGC',
    latitude: 40.3544006347656,
    longitude: -79.9301986694336,
    altitude: 1252,
    schengen: false
  },
  {
    id: 5923,
    name: 'Augusta Regional At Bush Field',
    city: 'Bush Field',
    country: 'United States',
    iata: 'AGS',
    icao: 'KAGS',
    latitude: 33.3698997497559,
    longitude: -81.9645004272461,
    altitude: 144,
    schengen: false
  },
  {
    id: 5924,
    name: 'Athens Ben Epps Airport',
    city: 'Athens',
    country: 'United States',
    iata: 'AHN',
    icao: 'KAHN',
    latitude: 33.948600769043,
    longitude: -83.326301574707,
    altitude: 808,
    schengen: false
  },
  {
    id: 5925,
    name: 'Wahoo Municipal Airport',
    city: 'Wahoo',
    country: 'United States',
    iata: '',
    icao: 'KAHQ',
    latitude: 41.2412986755,
    longitude: -96.59400177,
    altitude: 1224,
    schengen: false
  },
  {
    id: 5926,
    name: 'Alliance Municipal Airport',
    city: 'Alliance',
    country: 'United States',
    iata: 'AIA',
    icao: 'KAIA',
    latitude: 42.0531997681,
    longitude: -102.804000854,
    altitude: 3931,
    schengen: false
  },
  {
    id: 5927,
    name: 'Anderson Municipal Darlington Field',
    city: 'ANDERSON',
    country: 'United States',
    iata: 'AID',
    icao: 'KAID',
    latitude: 40.1086006165,
    longitude: -85.6129989624,
    altitude: 919,
    schengen: false
  },
  {
    id: 5928,
    name: 'Aiken Municipal Airport',
    city: 'Aiken',
    country: 'United States',
    iata: 'AIK',
    icao: 'KAIK',
    latitude: 33.6493988037,
    longitude: -81.6849975586,
    altitude: 528,
    schengen: false
  },
  {
    id: 5929,
    name: 'Lee C Fine Memorial Airport',
    city: 'Kaiser Lake Ozark',
    country: 'United States',
    iata: 'AIZ',
    icao: 'KAIZ',
    latitude: 38.0960006714,
    longitude: -92.5494995117,
    altitude: 869,
    schengen: false
  },
  {
    id: 5930,
    name: 'Habersham County Airport',
    city: 'Cornelia',
    country: 'United States',
    iata: '',
    icao: 'KAJR',
    latitude: 34.49990082,
    longitude: -83.55670166,
    altitude: 1448,
    schengen: false
  },
  {
    id: 5931,
    name: 'Colorado Plains Regional Airport',
    city: 'Akron',
    country: 'United States',
    iata: 'AKO',
    icao: 'KAKO',
    latitude: 40.1755981445,
    longitude: -103.222000122,
    altitude: 4714,
    schengen: false
  },
  {
    id: 5932,
    name: 'Akron Fulton International Airport',
    city: 'Akron',
    country: 'United States',
    iata: 'AKC',
    icao: 'KAKR',
    latitude: 41.0374984741,
    longitude: -81.4669036865,
    altitude: 1067,
    schengen: false
  },
  {
    id: 5933,
    name: 'Albany International Airport',
    city: 'Albany',
    country: 'United States',
    iata: 'ALB',
    icao: 'KALB',
    latitude: 42.7482986450195,
    longitude: -73.8016967773438,
    altitude: 285,
    schengen: false
  },
  {
    id: 5934,
    name: 'Alice International Airport',
    city: 'Alice',
    country: 'United States',
    iata: 'ALI',
    icao: 'KALI',
    latitude: 27.7409000397,
    longitude: -98.0269012451,
    altitude: 178,
    schengen: false
  },
  {
    id: 5935,
    name: 'Alamogordo White Sands Regional Airport',
    city: 'Alamogordo',
    country: 'United States',
    iata: 'ALM',
    icao: 'KALM',
    latitude: 32.8399009705,
    longitude: -105.990997314,
    altitude: 4200,
    schengen: false
  },
  {
    id: 5936,
    name: 'St Louis Regional Airport',
    city: 'Alton/St Louis',
    country: 'United States',
    iata: 'ALN',
    icao: 'KALN',
    latitude: 38.8903007507,
    longitude: -90.0459976196,
    altitude: 544,
    schengen: false
  },
  {
    id: 5937,
    name: 'Waterloo Regional Airport',
    city: 'Waterloo',
    country: 'United States',
    iata: 'ALO',
    icao: 'KALO',
    latitude: 42.5570983886719,
    longitude: -92.4002990722656,
    altitude: 873,
    schengen: false
  },
  {
    id: 5938,
    name: 'San Luis Valley Regional Bergman Field',
    city: 'Alamosa',
    country: 'United States',
    iata: 'ALS',
    icao: 'KALS',
    latitude: 37.434898,
    longitude: -105.866997,
    altitude: 7539,
    schengen: false
  },
  {
    id: 5939,
    name: 'Walla Walla Regional Airport',
    city: 'Walla Walla',
    country: 'United States',
    iata: 'ALW',
    icao: 'KALW',
    latitude: 46.09489822,
    longitude: -118.288002,
    altitude: 1194,
    schengen: false
  },
  {
    id: 5940,
    name: 'Thomas C Russell Field',
    city: 'Alexander City',
    country: 'United States',
    iata: 'ALX',
    icao: 'KALX',
    latitude: 32.9146995544,
    longitude: -85.9629974365,
    altitude: 686,
    schengen: false
  },
  {
    id: 5941,
    name: 'Rick Husband Amarillo International Airport',
    city: 'Amarillo',
    country: 'United States',
    iata: 'AMA',
    icao: 'KAMA',
    latitude: 35.219398,
    longitude: -101.706001,
    altitude: 3607,
    schengen: false
  },
  {
    id: 5942,
    name: 'Anniston Metropolitan Airport',
    city: 'Anniston',
    country: 'United States',
    iata: 'ANB',
    icao: 'KANB',
    latitude: 33.58819962,
    longitude: -85.85810089,
    altitude: 612,
    schengen: false
  },
  {
    id: 5943,
    name: 'Anderson Regional Airport',
    city: 'Andersen',
    country: 'United States',
    iata: 'AND',
    icao: 'KAND',
    latitude: 34.4945983887,
    longitude: -82.7093963623,
    altitude: 782,
    schengen: false
  },
  {
    id: 5944,
    name: 'Anoka County-Blaine (Janes Field) Airport',
    city: 'Anoka',
    country: 'United States',
    iata: '',
    icao: 'KANE',
    latitude: 45.145,
    longitude: -93.211401,
    altitude: 912,
    schengen: false
  },
  {
    id: 5945,
    name: 'Lee Airport',
    city: 'Annapolis',
    country: 'United States',
    iata: 'ANP',
    icao: 'KANP',
    latitude: 38.942902,
    longitude: -76.568398,
    altitude: 34,
    schengen: false
  },
  {
    id: 5946,
    name: 'Tri State Steuben County Airport',
    city: 'Angola',
    country: 'United States',
    iata: 'ANQ',
    icao: 'KANQ',
    latitude: 41.639702,
    longitude: -85.083504,
    altitude: 995,
    schengen: false
  },
  {
    id: 5947,
    name: 'Lima Allen County Airport',
    city: 'Lima',
    country: 'United States',
    iata: 'AOH',
    icao: 'KAOH',
    latitude: 40.706902,
    longitude: -84.026703,
    altitude: 975,
    schengen: false
  },
  {
    id: 5948,
    name: 'Altoona Blair County Airport',
    city: 'Altoona',
    country: 'United States',
    iata: 'AOO',
    icao: 'KAOO',
    latitude: 40.29639816,
    longitude: -78.31999969,
    altitude: 1503,
    schengen: false
  },
  {
    id: 5949,
    name: 'Centennial Airport',
    city: 'Denver',
    country: 'United States',
    iata: 'APA',
    icao: 'KAPA',
    latitude: 39.57009888,
    longitude: -104.848999,
    altitude: 5885,
    schengen: false
  },
  {
    id: 5950,
    name: 'Napa County Airport',
    city: 'Napa',
    country: 'United States',
    iata: 'APC',
    icao: 'KAPC',
    latitude: 38.2132,
    longitude: -122.280998,
    altitude: 35,
    schengen: false
  },
  {
    id: 5951,
    name: 'Naples Municipal Airport',
    city: 'Naples',
    country: 'United States',
    iata: 'APF',
    icao: 'KAPF',
    latitude: 26.1525993347,
    longitude: -81.7752990723,
    altitude: 8,
    schengen: false
  },
  {
    id: 5952,
    name: 'Phillips Army Air Field',
    city: 'Aberdeen',
    country: 'United States',
    iata: 'APG',
    icao: 'KAPG',
    latitude: 39.466202,
    longitude: -76.1688,
    altitude: 57,
    schengen: false
  },
  {
    id: 5953,
    name: 'Alpena County Regional Airport',
    city: 'Alpena',
    country: 'United States',
    iata: 'APN',
    icao: 'KAPN',
    latitude: 45.0780983,
    longitude: -83.56030273,
    altitude: 690,
    schengen: false
  },
  {
    id: 5954,
    name: 'Marion County Brown Field',
    city: 'Jasper',
    country: 'United States',
    iata: 'APT',
    icao: 'KAPT',
    latitude: 35.060699,
    longitude: -85.585297,
    altitude: 641,
    schengen: false
  },
  {
    id: 5955,
    name: 'Acadiana Regional Airport',
    city: 'Louisiana',
    country: 'United States',
    iata: 'ARA',
    icao: 'KARA',
    latitude: 30.0378,
    longitude: -91.883904,
    altitude: 24,
    schengen: false
  },
  {
    id: 5956,
    name: 'Ann Arbor Municipal Airport',
    city: 'Ann Arbor',
    country: 'United States',
    iata: 'ARB',
    icao: 'KARB',
    latitude: 42.2229995728,
    longitude: -83.7455978394,
    altitude: 839,
    schengen: false
  },
  {
    id: 5957,
    name: 'Watertown International Airport',
    city: 'Watertown',
    country: 'United States',
    iata: 'ART',
    icao: 'KART',
    latitude: 43.9919013977051,
    longitude: -76.0216979980469,
    altitude: 325,
    schengen: false
  },
  {
    id: 5958,
    name: 'Lakeland-Noble F. Lee Memorial field',
    city: 'Minocqua - Woodruff',
    country: 'United States',
    iata: 'ARV',
    icao: 'KARV',
    latitude: 45.92789841,
    longitude: -89.73090363,
    altitude: 1629,
    schengen: false
  },
  {
    id: 5959,
    name: 'Beaufort County Airport',
    city: 'Beaufort',
    country: 'United States',
    iata: 'BFT',
    icao: 'KARW',
    latitude: 32.4122009277,
    longitude: -80.6343994141,
    altitude: 10,
    schengen: false
  },
  {
    id: 5960,
    name: 'Aspen-Pitkin Co/Sardy Field',
    city: 'Aspen',
    country: 'United States',
    iata: 'ASE',
    icao: 'KASE',
    latitude: 39.22320175,
    longitude: -106.8690033,
    altitude: 7820,
    schengen: false
  },
  {
    id: 5961,
    name: 'Boire Field',
    city: 'Nashua',
    country: 'United States',
    iata: 'ASH',
    icao: 'KASH',
    latitude: 42.7817001343,
    longitude: -71.5148010254,
    altitude: 199,
    schengen: false
  },
  {
    id: 5962,
    name: 'Talladega Municipal Airport',
    city: 'Talladega',
    country: 'United States',
    iata: 'ASN',
    icao: 'KASN',
    latitude: 33.5699005127,
    longitude: -86.0509033203,
    altitude: 529,
    schengen: false
  },
  {
    id: 5963,
    name: 'Astoria Regional Airport',
    city: 'Astoria',
    country: 'United States',
    iata: 'AST',
    icao: 'KAST',
    latitude: 46.158000946,
    longitude: -123.878997803,
    altitude: 15,
    schengen: false
  },
  {
    id: 5964,
    name: 'Warsaw Municipal Airport',
    city: 'Warsaw',
    country: 'United States',
    iata: '',
    icao: 'KASW',
    latitude: 41.2747001647949,
    longitude: -85.8401031494141,
    altitude: 850,
    schengen: false
  },
  {
    id: 5965,
    name: 'Hartsfield Jackson Atlanta International Airport',
    city: 'Atlanta',
    country: 'United States',
    iata: 'ATL',
    icao: 'KATL',
    latitude: 33.6367,
    longitude: -84.428101,
    altitude: 1026,
    schengen: false
  },
  {
    id: 5966,
    name: 'Appleton International Airport',
    city: 'Appleton',
    country: 'United States',
    iata: 'ATW',
    icao: 'KATW',
    latitude: 44.2580986023,
    longitude: -88.5190963745,
    altitude: 918,
    schengen: false
  },
  {
    id: 5967,
    name: 'Watertown Regional Airport',
    city: 'Watertown',
    country: 'United States',
    iata: 'ATY',
    icao: 'KATY',
    latitude: 44.91400146,
    longitude: -97.15470123,
    altitude: 1749,
    schengen: false
  },
  {
    id: 5968,
    name: 'Augusta State Airport',
    city: 'Augusta',
    country: 'United States',
    iata: 'AUG',
    icao: 'KAUG',
    latitude: 44.3205986023,
    longitude: -69.7973022461,
    altitude: 352,
    schengen: false
  },
  {
    id: 5969,
    name: 'Auburn Opelika Robert G. Pitts Airport',
    city: 'Auburn',
    country: 'United States',
    iata: 'AUO',
    icao: 'KAUO',
    latitude: 32.61510086,
    longitude: -85.43399811,
    altitude: 777,
    schengen: false
  },
  {
    id: 5970,
    name: 'Austin Bergstrom International Airport',
    city: 'Austin',
    country: 'United States',
    iata: 'AUS',
    icao: 'KAUS',
    latitude: 30.1944999694824,
    longitude: -97.6698989868164,
    altitude: 542,
    schengen: false
  },
  {
    id: 5971,
    name: 'Wausau Downtown Airport',
    city: 'Wausau',
    country: 'United States',
    iata: 'AUW',
    icao: 'KAUW',
    latitude: 44.9262008667,
    longitude: -89.6266021729,
    altitude: 1201,
    schengen: false
  },
  {
    id: 5972,
    name: 'Asheville Regional Airport',
    city: 'Asheville',
    country: 'United States',
    iata: 'AVL',
    icao: 'KAVL',
    latitude: 35.4361991882324,
    longitude: -82.5418014526367,
    altitude: 2165,
    schengen: false
  },
  {
    id: 5973,
    name: 'Avon Park Executive Airport',
    city: 'Avon Park',
    country: 'United States',
    iata: 'AVO',
    icao: 'KAVO',
    latitude: 27.59119987,
    longitude: -81.52780151,
    altitude: 160,
    schengen: false
  },
  {
    id: 5974,
    name: 'Wilkes Barre Scranton International Airport',
    city: 'Scranton',
    country: 'United States',
    iata: 'AVP',
    icao: 'KAVP',
    latitude: 41.3385009766,
    longitude: -75.7233963013,
    altitude: 962,
    schengen: false
  },
  {
    id: 5975,
    name: 'Marana Regional Airport',
    city: 'Tucson',
    country: 'United States',
    iata: 'AVW',
    icao: 'KAVQ',
    latitude: 32.4095993042,
    longitude: -111.218002319,
    altitude: 2031,
    schengen: false
  },
  {
    id: 5976,
    name: 'Catalina Airport',
    city: 'Catalina Island',
    country: 'United States',
    iata: 'AVX',
    icao: 'KAVX',
    latitude: 33.4049,
    longitude: -118.416,
    altitude: 1602,
    schengen: false
  },
  {
    id: 5977,
    name: 'Arlington Municipal Airport',
    city: 'Arlington',
    country: 'United States',
    iata: '',
    icao: 'KAWO',
    latitude: 48.16070175,
    longitude: -122.1589966,
    altitude: 142,
    schengen: false
  },
  {
    id: 5978,
    name: 'Chandler Field',
    city: 'Alexandria',
    country: 'United States',
    iata: 'AXN',
    icao: 'KAXN',
    latitude: 45.8662986755,
    longitude: -95.3946990967,
    altitude: 1425,
    schengen: false
  },
  {
    id: 5979,
    name: 'Waycross Ware County Airport',
    city: 'Waycross',
    country: 'United States',
    iata: 'AYS',
    icao: 'KAYS',
    latitude: 31.2490997314,
    longitude: -82.3955001831,
    altitude: 142,
    schengen: false
  },
  {
    id: 5980,
    name: 'Kalamazoo Battle Creek International Airport',
    city: 'Kalamazoo',
    country: 'United States',
    iata: 'AZO',
    icao: 'KAZO',
    latitude: 42.2349014282227,
    longitude: -85.5521011352539,
    altitude: 874,
    schengen: false
  },
  {
    id: 5981,
    name: 'Beale Air Force Base',
    city: 'Marysville',
    country: 'United States',
    iata: 'BAB',
    icao: 'KBAB',
    latitude: 39.136100769,
    longitude: -121.43699646,
    altitude: 113,
    schengen: false
  },
  {
    id: 5982,
    name: 'Barksdale Air Force Base',
    city: 'Shreveport',
    country: 'United States',
    iata: 'BAD',
    icao: 'KBAD',
    latitude: 32.5018005371,
    longitude: -93.6626968384,
    altitude: 166,
    schengen: false
  },
  {
    id: 5983,
    name: 'Westfield-Barnes Regional Airport',
    city: 'Westfield',
    country: 'United States',
    iata: 'BAF',
    icao: 'KBAF',
    latitude: 42.157799,
    longitude: -72.715599,
    altitude: 271,
    schengen: false
  },
  {
    id: 5984,
    name: 'Columbus Municipal Airport',
    city: 'Columbus',
    country: 'United States',
    iata: 'CLU',
    icao: 'KBAK',
    latitude: 39.2619018555,
    longitude: -85.8963012695,
    altitude: 656,
    schengen: false
  },
  {
    id: 5985,
    name: 'Curtis Field',
    city: 'Brady',
    country: 'United States',
    iata: 'BBD',
    icao: 'KBBD',
    latitude: 31.1793003082,
    longitude: -99.3238983154,
    altitude: 1827,
    schengen: false
  },
  {
    id: 5986,
    name: 'Bryce Canyon Airport',
    city: 'Bryce Canyon',
    country: 'United States',
    iata: 'BCE',
    icao: 'KBCE',
    latitude: 37.706401825,
    longitude: -112.144996643,
    altitude: 7590,
    schengen: false
  },
  {
    id: 5987,
    name: 'Boca Raton Airport',
    city: 'Boca Raton',
    country: 'United States',
    iata: 'BCT',
    icao: 'KBCT',
    latitude: 26.3784999847,
    longitude: -80.1076965332,
    altitude: 13,
    schengen: false
  },
  {
    id: 5988,
    name: 'Baudette International Airport',
    city: 'Baudette',
    country: 'United States',
    iata: 'BDE',
    icao: 'KBDE',
    latitude: 48.7284011841,
    longitude: -94.612197876,
    altitude: 1086,
    schengen: false
  },
  {
    id: 5989,
    name: 'Bradley International Airport',
    city: 'Windsor Locks',
    country: 'United States',
    iata: 'BDL',
    icao: 'KBDL',
    latitude: 41.9388999939,
    longitude: -72.6831970215,
    altitude: 173,
    schengen: false
  },
  {
    id: 5990,
    name: 'Bend Municipal Airport',
    city: 'Bend',
    country: 'United States',
    iata: '',
    icao: 'KBDN',
    latitude: 44.09479904,
    longitude: -121.2009964,
    altitude: 3460,
    schengen: false
  },
  {
    id: 5991,
    name: 'Igor I Sikorsky Memorial Airport',
    city: 'Stratford',
    country: 'United States',
    iata: 'BDR',
    icao: 'KBDR',
    latitude: 41.163501739502,
    longitude: -73.1261978149414,
    altitude: 9,
    schengen: false
  },
  {
    id: 5992,
    name: 'Boulder Municipal Airport',
    city: 'Boulder',
    country: 'United States',
    iata: 'WBU',
    icao: 'KBDU',
    latitude: 40.0393981934,
    longitude: -105.225997925,
    altitude: 5288,
    schengen: false
  },
  {
    id: 5993,
    name: 'Beech Factory Airport',
    city: 'Wichita',
    country: 'United States',
    iata: 'BEC',
    icao: 'KBEC',
    latitude: 37.6944999695,
    longitude: -97.2149963379,
    altitude: 1408,
    schengen: false
  },
  {
    id: 5994,
    name: 'Laurence G Hanscom Field',
    city: 'Bedford',
    country: 'United States',
    iata: 'BED',
    icao: 'KBED',
    latitude: 42.47000122,
    longitude: -71.28900146,
    altitude: 133,
    schengen: false
  },
  {
    id: 5995,
    name: 'Bradford Regional Airport',
    city: 'Bradford',
    country: 'United States',
    iata: 'BFD',
    icao: 'KBFD',
    latitude: 41.8031005859375,
    longitude: -78.6400985717773,
    altitude: 2143,
    schengen: false
  },
  {
    id: 5996,
    name: 'Western Neb. Rgnl/William B. Heilig Airport',
    city: 'Scottsbluff',
    country: 'United States',
    iata: 'BFF',
    icao: 'KBFF',
    latitude: 41.87400055,
    longitude: -103.5960007,
    altitude: 3967,
    schengen: false
  },
  {
    id: 5997,
    name: 'Boeing Field King County International Airport',
    city: 'Seattle',
    country: 'United States',
    iata: 'BFI',
    icao: 'KBFI',
    latitude: 47.5299987792969,
    longitude: -122.302001953125,
    altitude: 21,
    schengen: false
  },
  {
    id: 5998,
    name: 'Meadows Field',
    city: 'Bakersfield',
    country: 'United States',
    iata: 'BFL',
    icao: 'KBFL',
    latitude: 35.43360138,
    longitude: -119.0569992,
    altitude: 510,
    schengen: false
  },
  {
    id: 5999,
    name: 'Mobile Downtown Airport',
    city: 'Mobile',
    country: 'United States',
    iata: 'BFM',
    icao: 'KBFM',
    latitude: 30.6268005371,
    longitude: -88.0680999756,
    altitude: 26,
    schengen: false
  },
  {
    id: 6000,
    name: 'Hutchinson County Airport',
    city: 'Borger',
    country: 'United States',
    iata: 'BGD',
    icao: 'KBGD',
    latitude: 35.7009010315,
    longitude: -101.393997192,
    altitude: 3055,
    schengen: false
  },
  {
    id: 6001,
    name: 'Decatur County Industrial Air Park',
    city: 'Bainbridge',
    country: 'United States',
    iata: 'BGE',
    icao: 'KBGE',
    latitude: 30.9715004,
    longitude: -84.63739777,
    altitude: 141,
    schengen: false
  },
  {
    id: 6002,
    name: 'Greater Binghamton/Edwin A Link field',
    city: 'Binghamton',
    country: 'United States',
    iata: 'BGM',
    icao: 'KBGM',
    latitude: 42.20869827,
    longitude: -75.97979736,
    altitude: 1636,
    schengen: false
  },
  {
    id: 6003,
    name: 'Bangor International Airport',
    city: 'Bangor',
    country: 'United States',
    iata: 'BGR',
    icao: 'KBGR',
    latitude: 44.8073997497559,
    longitude: -68.8281021118164,
    altitude: 192,
    schengen: false
  },
  {
    id: 6004,
    name: 'Hancock County-Bar Harbor Airport',
    city: 'Bar Harbor',
    country: 'United States',
    iata: 'BHB',
    icao: 'KBHB',
    latitude: 44.45000076,
    longitude: -68.3615036,
    altitude: 83,
    schengen: false
  },
  {
    id: 6005,
    name: 'Birmingham-Shuttlesworth International Airport',
    city: 'Birmingham',
    country: 'United States',
    iata: 'BHM',
    icao: 'KBHM',
    latitude: 33.56290054,
    longitude: -86.75350189,
    altitude: 650,
    schengen: false
  },
  {
    id: 6006,
    name: 'Block Island State Airport',
    city: 'Block Island',
    country: 'United States',
    iata: 'BID',
    icao: 'KBID',
    latitude: 41.1680984497,
    longitude: -71.577796936,
    altitude: 108,
    schengen: false
  },
  {
    id: 6007,
    name: 'Biggs Army Air Field (Fort Bliss)',
    city: 'El Paso',
    country: 'United States',
    iata: 'BIF',
    icao: 'KBIF',
    latitude: 31.84950066,
    longitude: -106.3799973,
    altitude: 3946,
    schengen: false
  },
  {
    id: 6008,
    name: 'Eastern Sierra Regional Airport',
    city: 'Bishop',
    country: 'United States',
    iata: 'BIH',
    icao: 'KBIH',
    latitude: 37.3731002808,
    longitude: -118.363998413,
    altitude: 4124,
    schengen: false
  },
  {
    id: 6009,
    name: 'Billings Logan International Airport',
    city: 'Billings',
    country: 'United States',
    iata: 'BIL',
    icao: 'KBIL',
    latitude: 45.8077011108398,
    longitude: -108.542999267578,
    altitude: 3652,
    schengen: false
  },
  {
    id: 6010,
    name: 'Bismarck Municipal Airport',
    city: 'Bismarck',
    country: 'United States',
    iata: 'BIS',
    icao: 'KBIS',
    latitude: 46.7727012634277,
    longitude: -100.746002197266,
    altitude: 1661,
    schengen: false
  },
  {
    id: 6011,
    name: 'Tulip City Airport',
    city: 'Holland',
    country: 'United States',
    iata: '',
    icao: 'KBIV',
    latitude: 42.742900848389,
    longitude: -86.107398986816,
    altitude: 698,
    schengen: false
  },
  {
    id: 6012,
    name: 'Keesler Air Force Base',
    city: 'Biloxi',
    country: 'United States',
    iata: 'BIX',
    icao: 'KBIX',
    latitude: 30.4104003906,
    longitude: -88.9244003296,
    altitude: 33,
    schengen: false
  },
  {
    id: 6013,
    name: 'Rocky Mountain Metropolitan Airport',
    city: 'Broomfield-CO',
    country: 'United States',
    iata: 'BJC',
    icao: 'KBJC',
    latitude: 39.90879822,
    longitude: -105.1169968,
    altitude: 5673,
    schengen: false
  },
  {
    id: 6014,
    name: 'Bemidji Regional Airport',
    city: 'Bemidji',
    country: 'United States',
    iata: 'BJI',
    icao: 'KBJI',
    latitude: 47.50939941,
    longitude: -94.93370056,
    altitude: 1391,
    schengen: false
  },
  {
    id: 6015,
    name: 'Stephens County Airport',
    city: 'Breckenridge',
    country: 'United States',
    iata: 'BKD',
    icao: 'KBKD',
    latitude: 32.71900177,
    longitude: -98.8909988403,
    altitude: 1284,
    schengen: false
  },
  {
    id: 6016,
    name: 'Baker City Municipal Airport',
    city: 'Baker City',
    country: 'United States',
    iata: 'BKE',
    icao: 'KBKE',
    latitude: 44.8372993469,
    longitude: -117.808998108,
    altitude: 3373,
    schengen: false
  },
  {
    id: 6017,
    name: 'Buckley Air Force Base',
    city: 'Buckley',
    country: 'United States',
    iata: 'BFK',
    icao: 'KBKF',
    latitude: 39.7016983032,
    longitude: -104.751998901,
    altitude: 5662,
    schengen: false
  },
  {
    id: 6018,
    name: 'Burke Lakefront Airport',
    city: 'Cleveland',
    country: 'United States',
    iata: 'BKL',
    icao: 'KBKL',
    latitude: 41.5175018310547,
    longitude: -81.6832962036133,
    altitude: 583,
    schengen: false
  },
  {
    id: 6019,
    name: 'Raleigh County Memorial Airport',
    city: 'Beckley',
    country: 'United States',
    iata: 'BKW',
    icao: 'KBKW',
    latitude: 37.7873001099,
    longitude: -81.1241989136,
    altitude: 2504,
    schengen: false
  },
  {
    id: 6020,
    name: 'Mercer County Airport',
    city: 'Bluefield',
    country: 'United States',
    iata: 'BLF',
    icao: 'KBLF',
    latitude: 37.2957992553711,
    longitude: -81.2077026367188,
    altitude: 2857,
    schengen: false
  },
  {
    id: 6021,
    name: 'Blythe Airport',
    city: 'Blythe',
    country: 'United States',
    iata: 'BLH',
    icao: 'KBLH',
    latitude: 33.6192016602,
    longitude: -114.717002869,
    altitude: 399,
    schengen: false
  },
  {
    id: 6022,
    name: 'Bellingham International Airport',
    city: 'Bellingham',
    country: 'United States',
    iata: 'BLI',
    icao: 'KBLI',
    latitude: 48.7928009033203,
    longitude: -122.53800201416,
    altitude: 170,
    schengen: false
  },
  {
    id: 6023,
    name: 'Scott AFB/Midamerica Airport',
    city: 'Belleville',
    country: 'United States',
    iata: 'BLV',
    icao: 'KBLV',
    latitude: 38.5452,
    longitude: -89.835197,
    altitude: 459,
    schengen: false
  },
  {
    id: 6024,
    name: 'Brigham City Regional Airport',
    city: 'Brigham City',
    country: 'United States',
    iata: 'BMC',
    icao: 'KBMC',
    latitude: 41.552399,
    longitude: -112.061996,
    altitude: 4229,
    schengen: false
  },
  {
    id: 6025,
    name: 'Monroe County Airport',
    city: 'Bloomington',
    country: 'United States',
    iata: 'BMG',
    icao: 'KBMG',
    latitude: 39.1459999084473,
    longitude: -86.61669921875,
    altitude: 846,
    schengen: false
  },
  {
    id: 6026,
    name: 'Central Illinois Regional Airport at Bloomington-Normal',
    city: 'Bloomington',
    country: 'United States',
    iata: 'BMI',
    icao: 'KBMI',
    latitude: 40.47710037,
    longitude: -88.91590118,
    altitude: 871,
    schengen: false
  },
  {
    id: 6027,
    name: 'Beaumont Municipal Airport',
    city: 'Beaumont',
    country: 'United States',
    iata: 'BMT',
    icao: 'KBMT',
    latitude: 30.0706996918,
    longitude: -94.2157974243,
    altitude: 32,
    schengen: false
  },
  {
    id: 6028,
    name: 'Nashville International Airport',
    city: 'Nashville',
    country: 'United States',
    iata: 'BNA',
    icao: 'KBNA',
    latitude: 36.1245002746582,
    longitude: -86.6781997680664,
    altitude: 599,
    schengen: false
  },
  {
    id: 6029,
    name: 'Banning Municipal Airport',
    city: 'Banning',
    country: 'United States',
    iata: 'BNG',
    icao: 'KBNG',
    latitude: 33.9230995178,
    longitude: -116.850997925,
    altitude: 2219,
    schengen: false
  },
  {
    id: 6030,
    name: 'Burns Municipal Airport',
    city: 'Burns',
    country: 'United States',
    iata: 'BNO',
    icao: 'KBNO',
    latitude: 43.5918998718,
    longitude: -118.955001831,
    altitude: 4148,
    schengen: false
  },
  {
    id: 6031,
    name: 'Boise Air Terminal/Gowen Field',
    city: 'Boise',
    country: 'United States',
    iata: 'BOI',
    icao: 'KBOI',
    latitude: 43.5644,
    longitude: -116.223,
    altitude: 2871,
    schengen: false
  },
  {
    id: 6032,
    name: 'General Edward Lawrence Logan International Airport',
    city: 'Boston',
    country: 'United States',
    iata: 'BOS',
    icao: 'KBOS',
    latitude: 42.36429977,
    longitude: -71.00520325,
    altitude: 20,
    schengen: false
  },
  {
    id: 6033,
    name: 'Bartow Municipal Airport',
    city: 'Bartow',
    country: 'United States',
    iata: 'BOW',
    icao: 'KBOW',
    latitude: 27.9433994293,
    longitude: -81.7834014893,
    altitude: 125,
    schengen: false
  },
  {
    id: 6034,
    name: 'Miley Memorial Field',
    city: 'Big Piney',
    country: 'United States',
    iata: 'BPI',
    icao: 'KBPI',
    latitude: 42.58509827,
    longitude: -110.1110001,
    altitude: 6990,
    schengen: false
  },
  {
    id: 6035,
    name: 'Ozark Regional Airport',
    city: 'Mountain Home',
    country: 'United States',
    iata: 'WMH',
    icao: 'KBPK',
    latitude: 36.3689002991,
    longitude: -92.4704971313,
    altitude: 928,
    schengen: false
  },
  {
    id: 6036,
    name: 'Southeast Texas Regional Airport',
    city: 'Beaumont',
    country: 'United States',
    iata: 'BPT',
    icao: 'KBPT',
    latitude: 29.9507999420166,
    longitude: -94.0206985473633,
    altitude: 15,
    schengen: false
  },
  {
    id: 6037,
    name: 'Brunswick Golden Isles Airport',
    city: 'Brunswick',
    country: 'United States',
    iata: 'BQK',
    icao: 'KBQK',
    latitude: 31.2588005065918,
    longitude: -81.4664993286133,
    altitude: 26,
    schengen: false
  },
  {
    id: 6038,
    name: 'Brainerd Lakes Regional Airport',
    city: 'Brainerd',
    country: 'United States',
    iata: 'BRD',
    icao: 'KBRD',
    latitude: 46.39830017,
    longitude: -94.13809967,
    altitude: 1232,
    schengen: false
  },
  {
    id: 6039,
    name: 'Southeast Iowa Regional Airport',
    city: 'Burlington',
    country: 'United States',
    iata: 'BRL',
    icao: 'KBRL',
    latitude: 40.7831993103027,
    longitude: -91.1255035400391,
    altitude: 698,
    schengen: false
  },
  {
    id: 6040,
    name: 'Brownsville South Padre Island International Airport',
    city: 'Brownsville',
    country: 'United States',
    iata: 'BRO',
    icao: 'KBRO',
    latitude: 25.9067993164062,
    longitude: -97.4259033203125,
    altitude: 22,
    schengen: false
  },
  {
    id: 6041,
    name: 'W K Kellogg Airport',
    city: 'Battle Creek',
    country: 'United States',
    iata: 'BTL',
    icao: 'KBTL',
    latitude: 42.3073005676,
    longitude: -85.2515029907,
    altitude: 952,
    schengen: false
  },
  {
    id: 6042,
    name: 'Bert Mooney Airport',
    city: 'Butte',
    country: 'United States',
    iata: 'BTM',
    icao: 'KBTM',
    latitude: 45.9547996520996,
    longitude: -112.497001647949,
    altitude: 5550,
    schengen: false
  },
  {
    id: 6043,
    name: 'Baton Rouge Metropolitan Airport',
    city: 'Baton Rouge',
    country: 'United States',
    iata: 'BTR',
    icao: 'KBTR',
    latitude: 30.533199,
    longitude: -91.149597,
    altitude: 70,
    schengen: false
  },
  {
    id: 6044,
    name: 'Burlington International Airport',
    city: 'Burlington',
    country: 'United States',
    iata: 'BTV',
    icao: 'KBTV',
    latitude: 44.4719009399,
    longitude: -73.1532974243,
    altitude: 335,
    schengen: false
  },
  {
    id: 6045,
    name: 'Buffalo Niagara International Airport',
    city: 'Buffalo',
    country: 'United States',
    iata: 'BUF',
    icao: 'KBUF',
    latitude: 42.94049835,
    longitude: -78.73220062,
    altitude: 728,
    schengen: false
  },
  {
    id: 6046,
    name: 'Bob Hope Airport',
    city: 'Burbank',
    country: 'United States',
    iata: 'BUR',
    icao: 'KBUR',
    latitude: 34.2006988525391,
    longitude: -118.359001159668,
    altitude: 778,
    schengen: false
  },
  {
    id: 6047,
    name: 'Burlington Municipal Airport',
    city: 'Burlington',
    country: 'United States',
    iata: '',
    icao: 'KBUU',
    latitude: 42.6907005310059,
    longitude: -88.3046035766602,
    altitude: 779,
    schengen: false
  },
  {
    id: 6048,
    name: 'Burlington Alamance Regional Airport',
    city: 'Burlington',
    country: 'United States',
    iata: '',
    icao: 'KBUY',
    latitude: 36.0485000610352,
    longitude: -79.4748992919922,
    altitude: 617,
    schengen: false
  },
  {
    id: 6049,
    name: 'Beaver County Airport',
    city: 'Beaver Falls',
    country: 'United States',
    iata: 'BFP',
    icao: 'KBVI',
    latitude: 40.7724990845,
    longitude: -80.3914031982,
    altitude: 1253,
    schengen: false
  },
  {
    id: 6050,
    name: 'Skagit Regional Airport',
    city: 'Skagit',
    country: 'United States',
    iata: 'MVW',
    icao: 'KBVS',
    latitude: 48.4709014893,
    longitude: -122.42099762,
    altitude: 144,
    schengen: false
  },
  {
    id: 6051,
    name: 'Beverly Municipal Airport',
    city: 'Beverly',
    country: 'United States',
    iata: 'BVY',
    icao: 'KBVY',
    latitude: 42.5842018127,
    longitude: -70.9164962769,
    altitude: 107,
    schengen: false
  },
  {
    id: 6052,
    name: 'Bowling Green Warren County Regional Airport',
    city: 'Bowling Green',
    country: 'United States',
    iata: 'BWG',
    icao: 'KBWG',
    latitude: 36.9645004272,
    longitude: -86.4197006226,
    altitude: 547,
    schengen: false
  },
  {
    id: 6053,
    name: 'Baltimore/Washington International Thurgood Marshall Airport',
    city: 'Baltimore',
    country: 'United States',
    iata: 'BWI',
    icao: 'KBWI',
    latitude: 39.1754,
    longitude: -76.668297,
    altitude: 146,
    schengen: false
  },
  {
    id: 6054,
    name: 'Buckeye Municipal Airport',
    city: 'Buckeye',
    country: 'United States',
    iata: 'BXK',
    icao: 'KBXK',
    latitude: 33.42039871,
    longitude: -112.685997,
    altitude: 1033,
    schengen: false
  },
  {
    id: 6055,
    name: 'Arkansas International Airport',
    city: 'Blytheville',
    country: 'United States',
    iata: 'BYH',
    icao: 'KBYH',
    latitude: 35.9642982483,
    longitude: -89.9440002441,
    altitude: 254,
    schengen: false
  },
  {
    id: 6056,
    name: 'Burley Municipal Airport',
    city: 'Burley',
    country: 'United States',
    iata: 'BYI',
    icao: 'KBYI',
    latitude: 42.5425987244,
    longitude: -113.772003174,
    altitude: 4150,
    schengen: false
  },
  {
    id: 6057,
    name: 'Bicycle Lake Army Air Field',
    city: 'Fort Irwin',
    country: 'United States',
    iata: 'BYS',
    icao: 'KBYS',
    latitude: 35.2804985046,
    longitude: -116.629997253,
    altitude: 2350,
    schengen: false
  },
  {
    id: 6058,
    name: 'Bay City Municipal Airport',
    city: 'Bay City',
    country: 'United States',
    iata: 'BBC',
    icao: 'KBYY',
    latitude: 28.9733009338,
    longitude: -95.8635025024,
    altitude: 45,
    schengen: false
  },
  {
    id: 6059,
    name: 'Gallatin Field',
    city: 'Bozeman',
    country: 'United States',
    iata: 'BZN',
    icao: 'KBZN',
    latitude: 45.77750015,
    longitude: -111.1529999,
    altitude: 4473,
    schengen: false
  },
  {
    id: 6060,
    name: 'Nappanee Municipal Airport',
    city: 'Nappanee ',
    country: 'United States',
    iata: '',
    icao: 'KC03',
    latitude: 41.4462013244629,
    longitude: -85.9347991943359,
    altitude: 860,
    schengen: false
  },
  {
    id: 6061,
    name: 'Columbia Metropolitan Airport',
    city: 'Columbia',
    country: 'United States',
    iata: 'CAE',
    icao: 'KCAE',
    latitude: 33.9388008117676,
    longitude: -81.119499206543,
    altitude: 236,
    schengen: false
  },
  {
    id: 6062,
    name: 'Akron Canton Regional Airport',
    city: 'Akron',
    country: 'United States',
    iata: 'CAK',
    icao: 'KCAK',
    latitude: 40.9160995483398,
    longitude: -81.4421997070312,
    altitude: 1228,
    schengen: false
  },
  {
    id: 6063,
    name: 'Caribou Municipal Airport',
    city: 'Caribou',
    country: 'United States',
    iata: 'CAR',
    icao: 'KCAR',
    latitude: 46.8714981079,
    longitude: -68.0178985596,
    altitude: 626,
    schengen: false
  },
  {
    id: 6064,
    name: 'Greater Cumberland Regional Airport',
    city: 'Cumberland',
    country: 'United States',
    iata: 'CBE',
    icao: 'KCBE',
    latitude: 39.615398407,
    longitude: -78.7609024048,
    altitude: 775,
    schengen: false
  },
  {
    id: 6065,
    name: 'Council Bluffs Municipal Airport',
    city: 'Council Bluffs',
    country: 'United States',
    iata: 'CBF',
    icao: 'KCBF',
    latitude: 41.2592010498,
    longitude: -95.760597229,
    altitude: 1253,
    schengen: false
  },
  {
    id: 6066,
    name: 'Columbus Air Force Base',
    city: 'Colombus',
    country: 'United States',
    iata: 'CBM',
    icao: 'KCBM',
    latitude: 33.6437988281,
    longitude: -88.4438018799,
    altitude: 219,
    schengen: false
  },
  {
    id: 6067,
    name: 'Cable Airport',
    city: 'Upland',
    country: 'United States',
    iata: 'CCB',
    icao: 'KCCB',
    latitude: 34.1115989685,
    longitude: -117.68800354,
    altitude: 1444,
    schengen: false
  },
  {
    id: 6068,
    name: 'Newnan Coweta County Airport',
    city: 'Newnan',
    country: 'United States',
    iata: '',
    icao: 'KCCO',
    latitude: 33.3115997314453,
    longitude: -84.7697982788086,
    altitude: 970,
    schengen: false
  },
  {
    id: 6069,
    name: 'Buchanan Field',
    city: 'Concord',
    country: 'United States',
    iata: 'CCR',
    icao: 'KCCR',
    latitude: 37.9897003174,
    longitude: -122.056999207,
    altitude: 26,
    schengen: false
  },
  {
    id: 6070,
    name: 'Northeast Iowa Regional Airport',
    city: 'Charles City',
    country: 'United States',
    iata: 'CCY',
    icao: 'KCCY',
    latitude: 43.0726013184,
    longitude: -92.6108016968,
    altitude: 1125,
    schengen: false
  },
  {
    id: 6071,
    name: 'Cedar City Regional Airport',
    city: 'Cedar City',
    country: 'United States',
    iata: 'CDC',
    icao: 'KCDC',
    latitude: 37.701000213623,
    longitude: -113.098999023438,
    altitude: 5622,
    schengen: false
  },
  {
    id: 6072,
    name: 'Woodward Field',
    city: 'Camden',
    country: 'United States',
    iata: 'CDN',
    icao: 'KCDN',
    latitude: 34.2835998535,
    longitude: -80.5649032593,
    altitude: 302,
    schengen: false
  },
  {
    id: 6073,
    name: 'Chadron Municipal Airport',
    city: 'Chadron',
    country: 'United States',
    iata: 'CDR',
    icao: 'KCDR',
    latitude: 42.837600708,
    longitude: -103.095001221,
    altitude: 3297,
    schengen: false
  },
  {
    id: 6074,
    name: 'Childress Municipal Airport',
    city: 'Childress',
    country: 'United States',
    iata: 'CDS',
    icao: 'KCDS',
    latitude: 34.4337997437,
    longitude: -100.288002014,
    altitude: 1954,
    schengen: false
  },
  {
    id: 6075,
    name: 'Essex County Airport',
    city: 'Caldwell',
    country: 'United States',
    iata: 'CDW',
    icao: 'KCDW',
    latitude: 40.8751983643,
    longitude: -74.2814025879,
    altitude: 173,
    schengen: false
  },
  {
    id: 6076,
    name: 'Jack Mc Namara Field Airport',
    city: 'Crescent City',
    country: 'United States',
    iata: 'CEC',
    icao: 'KCEC',
    latitude: 41.78020096,
    longitude: -124.2369995,
    altitude: 61,
    schengen: false
  },
  {
    id: 6077,
    name: 'Westover ARB/Metropolitan Airport',
    city: 'Chicopee Falls',
    country: 'United States',
    iata: 'CEF',
    icao: 'KCEF',
    latitude: 42.19400024,
    longitude: -72.53479767,
    altitude: 241,
    schengen: false
  },
  {
    id: 6078,
    name: 'Oconee County Regional Airport',
    city: 'Clemson',
    country: 'United States',
    iata: 'CEU',
    icao: 'KCEU',
    latitude: 34.6719017,
    longitude: -82.8864975,
    altitude: 892,
    schengen: false
  },
  {
    id: 6079,
    name: 'Bob Sikes Airport',
    city: 'Crestview',
    country: 'United States',
    iata: 'CEW',
    icao: 'KCEW',
    latitude: 30.778799057,
    longitude: -86.522102356,
    altitude: 213,
    schengen: false
  },
  {
    id: 6080,
    name: 'Cortez Municipal Airport',
    city: 'Cortez',
    country: 'United States',
    iata: 'CEZ',
    icao: 'KCEZ',
    latitude: 37.3030014038,
    longitude: -108.627998352,
    altitude: 5918,
    schengen: false
  },
  {
    id: 6081,
    name: 'Coulter Field',
    city: 'Bryan',
    country: 'United States',
    iata: 'CFD',
    icao: 'KCFD',
    latitude: 30.7157001495,
    longitude: -96.3313980103,
    altitude: 367,
    schengen: false
  },
  {
    id: 6082,
    name: 'Coffeyville Municipal Airport',
    city: 'Coffeyville',
    country: 'United States',
    iata: 'CFV',
    icao: 'KCFV',
    latitude: 37.09400177,
    longitude: -95.5718994141,
    altitude: 754,
    schengen: false
  },
  {
    id: 6083,
    name: 'Crystal River Airport',
    city: 'Crystal River',
    country: 'United States',
    iata: '',
    icao: 'KCGC',
    latitude: 28.8673000335693,
    longitude: -82.5712966918945,
    altitude: 9,
    schengen: false
  },
  {
    id: 6084,
    name: 'Cuyahoga County Airport',
    city: 'Richmond Heights',
    country: 'United States',
    iata: 'CGF',
    icao: 'KCGF',
    latitude: 41.5651016235,
    longitude: -81.4863967896,
    altitude: 879,
    schengen: false
  },
  {
    id: 6085,
    name: 'Cape Girardeau Regional Airport',
    city: 'Cape Girardeau',
    country: 'United States',
    iata: 'CGI',
    icao: 'KCGI',
    latitude: 37.2252998352051,
    longitude: -89.57080078125,
    altitude: 342,
    schengen: false
  },
  {
    id: 6086,
    name: 'Chicago Meigs Airport',
    city: 'Chicago',
    country: 'United States',
    iata: 'CGX',
    icao: 'KCGX',
    latitude: 41.8587989807129,
    longitude: -87.6079025268555,
    altitude: 593,
    schengen: false
  },
  {
    id: 6087,
    name: 'Casa Grande Municipal Airport',
    city: 'Casa Grande',
    country: 'United States',
    iata: 'CGZ',
    icao: 'KCGZ',
    latitude: 32.954899,
    longitude: -111.766998,
    altitude: 1464,
    schengen: false
  },
  {
    id: 6088,
    name: 'Lovell Field',
    city: 'Chattanooga',
    country: 'United States',
    iata: 'CHA',
    icao: 'KCHA',
    latitude: 35.0353012084961,
    longitude: -85.2037963867187,
    altitude: 683,
    schengen: false
  },
  {
    id: 6089,
    name: 'Chandler Municipal Airport',
    city: 'Chandler',
    country: 'United States',
    iata: '',
    icao: 'KCHD',
    latitude: 33.2691,
    longitude: -111.810997,
    altitude: 1243,
    schengen: false
  },
  {
    id: 6090,
    name: 'Wauchula Municipal Airport',
    city: 'Wuchula',
    country: 'United States',
    iata: '',
    icao: 'KCHN',
    latitude: 27.51490020752,
    longitude: -81.880500793457,
    altitude: 106,
    schengen: false
  },
  {
    id: 6091,
    name: 'Charlottesville Albemarle Airport',
    city: 'Charlottesville VA',
    country: 'United States',
    iata: 'CHO',
    icao: 'KCHO',
    latitude: 38.138599395752,
    longitude: -78.4529037475586,
    altitude: 639,
    schengen: false
  },
  {
    id: 6092,
    name: 'Charleston Air Force Base-International Airport',
    city: 'Charleston',
    country: 'United States',
    iata: 'CHS',
    icao: 'KCHS',
    latitude: 32.89860153,
    longitude: -80.04049683,
    altitude: 46,
    schengen: false
  },
  {
    id: 6093,
    name: 'Chico Municipal Airport',
    city: 'Chico',
    country: 'United States',
    iata: 'CIC',
    icao: 'KCIC',
    latitude: 39.79539871,
    longitude: -121.8580017,
    altitude: 240,
    schengen: false
  },
  {
    id: 6094,
    name: 'The Eastern Iowa Airport',
    city: 'Cedar Rapids',
    country: 'United States',
    iata: 'CID',
    icao: 'KCID',
    latitude: 41.8847007751465,
    longitude: -91.7108001708984,
    altitude: 869,
    schengen: false
  },
  {
    id: 6095,
    name: 'Chippewa County International Airport',
    city: 'Sault Ste Marie',
    country: 'United States',
    iata: 'CIU',
    icao: 'KCIU',
    latitude: 46.2508010864258,
    longitude: -84.4723968505859,
    altitude: 800,
    schengen: false
  },
  {
    id: 6096,
    name: 'North Central West Virginia Airport',
    city: 'Clarksburg',
    country: 'United States',
    iata: 'CKB',
    icao: 'KCKB',
    latitude: 39.2966003418,
    longitude: -80.2281036377,
    altitude: 1217,
    schengen: false
  },
  {
    id: 6097,
    name: 'Grand Marais Cook County Airport',
    city: 'Grand Marais',
    country: 'United States',
    iata: 'GRM',
    icao: 'KCKC',
    latitude: 47.8382987976,
    longitude: -90.3828964233,
    altitude: 1799,
    schengen: false
  },
  {
    id: 6098,
    name: 'Crisp County Cordele Airport',
    city: 'Cordele',
    country: 'United States',
    iata: '',
    icao: 'KCKF',
    latitude: 31.98880005,
    longitude: -83.77390289,
    altitude: 310,
    schengen: false
  },
  {
    id: 6099,
    name: 'Clarksville–Montgomery County Regional Airport',
    city: 'Clarksville',
    country: 'United States',
    iata: 'CKV',
    icao: 'KCKV',
    latitude: 36.6218986511,
    longitude: -87.4150009155,
    altitude: 550,
    schengen: false
  },
  {
    id: 6100,
    name: 'Cleveland Hopkins International Airport',
    city: 'Cleveland',
    country: 'United States',
    iata: 'CLE',
    icao: 'KCLE',
    latitude: 41.4117012024,
    longitude: -81.8498001099,
    altitude: 791,
    schengen: false
  },
  {
    id: 6101,
    name: 'Easterwood Field',
    city: 'College Station',
    country: 'United States',
    iata: 'CLL',
    icao: 'KCLL',
    latitude: 30.58860016,
    longitude: -96.36380005,
    altitude: 320,
    schengen: false
  },
  {
    id: 6102,
    name: 'William R Fairchild International Airport',
    city: 'Port Angeles',
    country: 'United States',
    iata: 'CLM',
    icao: 'KCLM',
    latitude: 48.1202011108398,
    longitude: -123.5,
    altitude: 291,
    schengen: false
  },
  {
    id: 6103,
    name: 'Chehalis Centralia Airport',
    city: 'Chehalis',
    country: 'United States',
    iata: 'CLS',
    icao: 'KCLS',
    latitude: 46.6769981384,
    longitude: -122.983001709,
    altitude: 176,
    schengen: false
  },
  {
    id: 6104,
    name: 'Charlotte Douglas International Airport',
    city: 'Charlotte',
    country: 'United States',
    iata: 'CLT',
    icao: 'KCLT',
    latitude: 35.2140007019043,
    longitude: -80.9430999755859,
    altitude: 748,
    schengen: false
  },
  {
    id: 6105,
    name: 'Clearwater Air Park',
    city: 'Clearwater',
    country: 'United States',
    iata: 'CLW',
    icao: 'KCLW',
    latitude: 27.9766998291,
    longitude: -82.7586975098,
    altitude: 71,
    schengen: false
  },
  {
    id: 6106,
    name: 'Camarillo Airport',
    city: 'Camarillo - CA',
    country: 'United States',
    iata: '',
    icao: 'KCMA',
    latitude: 34.2136993408203,
    longitude: -119.09400177002,
    altitude: 77,
    schengen: false
  },
  {
    id: 6107,
    name: 'John Glenn Columbus International Airport',
    city: 'Columbus',
    country: 'United States',
    iata: 'CMH',
    icao: 'KCMH',
    latitude: 39.998001,
    longitude: -82.891899,
    altitude: 815,
    schengen: false
  },
  {
    id: 6108,
    name: 'University of Illinois Willard Airport',
    city: 'Champaign',
    country: 'United States',
    iata: 'CMI',
    icao: 'KCMI',
    latitude: 40.03919983,
    longitude: -88.27809906,
    altitude: 755,
    schengen: false
  },
  {
    id: 6109,
    name: 'Houghton County Memorial Airport',
    city: 'Hancock',
    country: 'United States',
    iata: 'CMX',
    icao: 'KCMX',
    latitude: 47.168399810791,
    longitude: -88.4890975952149,
    altitude: 1095,
    schengen: false
  },
  {
    id: 6110,
    name: 'Cavern City Air Terminal',
    city: 'Carlsbad',
    country: 'United States',
    iata: 'CNM',
    icao: 'KCNM',
    latitude: 32.3375015258789,
    longitude: -104.263000488281,
    altitude: 3295,
    schengen: false
  },
  {
    id: 6111,
    name: 'Chino Airport',
    city: 'Chino',
    country: 'United States',
    iata: 'CNO',
    icao: 'KCNO',
    latitude: 33.97470093,
    longitude: -117.637001,
    altitude: 650,
    schengen: false
  },
  {
    id: 6112,
    name: 'Chanute Martin Johnson Airport',
    city: 'Chanute',
    country: 'United States',
    iata: 'CNU',
    icao: 'KCNU',
    latitude: 37.668800354,
    longitude: -95.4850997925,
    altitude: 1002,
    schengen: false
  },
  {
    id: 6113,
    name: 'TSTC Waco Airport',
    city: 'Waco',
    country: 'United States',
    iata: 'CNW',
    icao: 'KCNW',
    latitude: 31.6378002167,
    longitude: -97.0740966797,
    altitude: 470,
    schengen: false
  },
  {
    id: 6114,
    name: 'Canyonlands Field',
    city: 'Moab',
    country: 'United States',
    iata: 'CNY',
    icao: 'KCNY',
    latitude: 38.75500107,
    longitude: -109.7549973,
    altitude: 4557,
    schengen: false
  },
  {
    id: 6115,
    name: 'Yellowstone Regional Airport',
    city: 'Cody',
    country: 'United States',
    iata: 'COD',
    icao: 'KCOD',
    latitude: 44.520198822,
    longitude: -109.024002075,
    altitude: 5102,
    schengen: false
  },
  {
    id: 6116,
    name: "Coeur D'Alene - Pappy Boyington Field",
    city: "Coeur d'Alene",
    country: 'United States',
    iata: 'COE',
    icao: 'KCOE',
    latitude: 47.77429962,
    longitude: -116.8199997,
    altitude: 2320,
    schengen: false
  },
  {
    id: 6117,
    name: 'Patrick Air Force Base',
    city: 'Coco Beach',
    country: 'United States',
    iata: 'COF',
    icao: 'KCOF',
    latitude: 28.2348995209,
    longitude: -80.6100997925,
    altitude: 8,
    schengen: false
  },
  {
    id: 6118,
    name: 'Concord Municipal Airport',
    city: 'Concord NH',
    country: 'United States',
    iata: 'CON',
    icao: 'KCON',
    latitude: 43.20270157,
    longitude: -71.50229645,
    altitude: 342,
    schengen: false
  },
  {
    id: 6119,
    name: 'City of Colorado Springs Municipal Airport',
    city: 'Colorado Springs',
    country: 'United States',
    iata: 'COS',
    icao: 'KCOS',
    latitude: 38.805801,
    longitude: -104.700996,
    altitude: 6187,
    schengen: false
  },
  {
    id: 6120,
    name: 'Cotulla-La Salle County Airport',
    city: 'Cotulla',
    country: 'United States',
    iata: 'COT',
    icao: 'KCOT',
    latitude: 28.45669937,
    longitude: -99.22029877,
    altitude: 474,
    schengen: false
  },
  {
    id: 6121,
    name: 'Columbia Regional Airport',
    city: 'Columbia',
    country: 'United States',
    iata: 'COU',
    icao: 'KCOU',
    latitude: 38.8180999755859,
    longitude: -92.219596862793,
    altitude: 889,
    schengen: false
  },
  {
    id: 6122,
    name: 'Casper-Natrona County International Airport',
    city: 'Casper',
    country: 'United States',
    iata: 'CPR',
    icao: 'KCPR',
    latitude: 42.90800095,
    longitude: -106.4639969,
    altitude: 5350,
    schengen: false
  },
  {
    id: 6123,
    name: 'Cheraw Municipal Airport/Lynch Bellinger Field',
    city: 'Cheraw',
    country: 'United States',
    iata: 'HCW',
    icao: 'KCQW',
    latitude: 34.71289825,
    longitude: -79.95700073,
    altitude: 239,
    schengen: false
  },
  {
    id: 6124,
    name: 'Grand Strand Airport',
    city: 'North Myrtle Beach',
    country: 'United States',
    iata: 'CRE',
    icao: 'KCRE',
    latitude: 33.8116989136,
    longitude: -78.7238998413,
    altitude: 32,
    schengen: false
  },
  {
    id: 6125,
    name: 'Jacksonville Executive at Craig Airport',
    city: 'Jacksonville',
    country: 'United States',
    iata: 'CRG',
    icao: 'KCRG',
    latitude: 30.3362998962,
    longitude: -81.5143966675,
    altitude: 41,
    schengen: false
  },
  {
    id: 6126,
    name: 'Corpus Christi International Airport',
    city: 'Corpus Christi',
    country: 'United States',
    iata: 'CRP',
    icao: 'KCRP',
    latitude: 27.7703990936279,
    longitude: -97.5011978149414,
    altitude: 44,
    schengen: false
  },
  {
    id: 6127,
    name: 'Mc Clellan-Palomar Airport',
    city: 'Carlsbad',
    country: 'United States',
    iata: 'CLD',
    icao: 'KCRQ',
    latitude: 33.12829971,
    longitude: -117.2799988,
    altitude: 331,
    schengen: false
  },
  {
    id: 6128,
    name: 'Yeager Airport',
    city: 'Charleston',
    country: 'United States',
    iata: 'CRW',
    icao: 'KCRW',
    latitude: 38.3731002807617,
    longitude: -81.5932006835937,
    altitude: 981,
    schengen: false
  },
  {
    id: 6129,
    name: 'Columbus Metropolitan Airport',
    city: 'Columbus',
    country: 'United States',
    iata: 'CSG',
    icao: 'KCSG',
    latitude: 32.516300201416,
    longitude: -84.9389038085937,
    altitude: 397,
    schengen: false
  },
  {
    id: 6130,
    name: 'Clinton Sherman Airport',
    city: 'Clinton',
    country: 'United States',
    iata: 'CSM',
    icao: 'KCSM',
    latitude: 35.3398017883,
    longitude: -99.2005004883,
    altitude: 1922,
    schengen: false
  },
  {
    id: 6131,
    name: 'Crossville Memorial Whitson Field',
    city: 'Crossville',
    country: 'United States',
    iata: 'CSV',
    icao: 'KCSV',
    latitude: 35.9513015747,
    longitude: -85.0849990845,
    altitude: 1881,
    schengen: false
  },
  {
    id: 6132,
    name: 'Cut Bank International Airport',
    city: 'Cutbank',
    country: 'United States',
    iata: 'CTB',
    icao: 'KCTB',
    latitude: 48.6083984375,
    longitude: -112.375999451,
    altitude: 3854,
    schengen: false
  },
  {
    id: 6133,
    name: 'West Georgia Regional O V Gray Field',
    city: 'Carrollton',
    country: 'United States',
    iata: '',
    icao: 'KCTJ',
    latitude: 33.6310005187988,
    longitude: -85.1520004272461,
    altitude: 1161,
    schengen: false
  },
  {
    id: 6134,
    name: 'Cross City Airport',
    city: 'Cross City',
    country: 'United States',
    iata: 'CTY',
    icao: 'KCTY',
    latitude: 29.6354999542,
    longitude: -83.1047973633,
    altitude: 42,
    schengen: false
  },
  {
    id: 6135,
    name: 'Jim Hamilton L.B. Owens Airport',
    city: 'Columbia',
    country: 'United States',
    iata: 'CUB',
    icao: 'KCUB',
    latitude: 33.970500946,
    longitude: -80.9952011108,
    altitude: 193,
    schengen: false
  },
  {
    id: 6136,
    name: 'Cushing Municipal Airport',
    city: 'Cushing',
    country: 'United States',
    iata: 'CUH',
    icao: 'KCUH',
    latitude: 35.9499015808,
    longitude: -96.7731018066,
    altitude: 916,
    schengen: false
  },
  {
    id: 6137,
    name: 'Cincinnati Northern Kentucky International Airport',
    city: 'Cincinnati',
    country: 'United States',
    iata: 'CVG',
    icao: 'KCVG',
    latitude: 39.0488014221,
    longitude: -84.6678009033,
    altitude: 896,
    schengen: false
  },
  {
    id: 6138,
    name: 'Clovis Municipal Airport',
    city: 'Clovis',
    country: 'United States',
    iata: 'CVN',
    icao: 'KCVN',
    latitude: 34.4250984192,
    longitude: -103.07900238,
    altitude: 4216,
    schengen: false
  },
  {
    id: 6139,
    name: 'Corvallis Municipal Airport',
    city: 'Corvallis',
    country: 'United States',
    iata: 'CVO',
    icao: 'KCVO',
    latitude: 44.49720001,
    longitude: -123.2900009,
    altitude: 250,
    schengen: false
  },
  {
    id: 6140,
    name: 'Cannon Air Force Base',
    city: 'Clovis',
    country: 'United States',
    iata: 'CVS',
    icao: 'KCVS',
    latitude: 34.3828010559,
    longitude: -103.321998596,
    altitude: 4295,
    schengen: false
  },
  {
    id: 6141,
    name: 'Charlevoix Municipal Airport',
    city: 'Charelvoix',
    country: 'United States',
    iata: '',
    icao: 'KCVX',
    latitude: 45.3047981262207,
    longitude: -85.2748031616211,
    altitude: 669,
    schengen: false
  },
  {
    id: 6142,
    name: 'Central Wisconsin Airport',
    city: 'Wassau',
    country: 'United States',
    iata: 'CWA',
    icao: 'KCWA',
    latitude: 44.7775993347,
    longitude: -89.6668014526,
    altitude: 1277,
    schengen: false
  },
  {
    id: 6143,
    name: 'Clinton Municipal Airport',
    city: 'Clinton',
    country: 'United States',
    iata: 'CWI',
    icao: 'KCWI',
    latitude: 41.8311004639,
    longitude: -90.3291015625,
    altitude: 708,
    schengen: false
  },
  {
    id: 6144,
    name: 'Calexico International Airport',
    city: 'Calexico',
    country: 'United States',
    iata: 'CXL',
    icao: 'KCXL',
    latitude: 32.6694984436,
    longitude: -115.513000488,
    altitude: 4,
    schengen: false
  },
  {
    id: 6145,
    name: 'Conroe-North Houston Regional Airport',
    city: 'Conroe',
    country: 'United States',
    iata: 'CXO',
    icao: 'KCXO',
    latitude: 30.351801,
    longitude: -95.414497,
    altitude: 245,
    schengen: false
  },
  {
    id: 6146,
    name: 'Camilla Mitchell County Airport',
    city: 'Camilla',
    country: 'United States',
    iata: '',
    icao: 'KCXU',
    latitude: 31.2129001617432,
    longitude: -84.2368011474609,
    altitude: 175,
    schengen: false
  },
  {
    id: 6147,
    name: 'Capital City Airport',
    city: 'Harrisburg',
    country: 'United States',
    iata: 'HAR',
    icao: 'KCXY',
    latitude: 40.2170982361,
    longitude: -76.8515014648,
    altitude: 347,
    schengen: false
  },
  {
    id: 6148,
    name: 'Cheyenne Regional Jerry Olson Field',
    city: 'Cheyenne',
    country: 'United States',
    iata: 'CYS',
    icao: 'KCYS',
    latitude: 41.15570068,
    longitude: -104.8119965,
    altitude: 6159,
    schengen: false
  },
  {
    id: 6149,
    name: 'Tri Cities Airport',
    city: 'Endicott',
    country: 'United States',
    iata: '',
    icao: 'KCZG',
    latitude: 42.078499,
    longitude: -76.096296,
    altitude: 833,
    schengen: false
  },
  {
    id: 6150,
    name: 'Tom B. David Field',
    city: 'Calhoun',
    country: 'United States',
    iata: '',
    icao: 'KCZL',
    latitude: 34.45539856,
    longitude: -84.93920135,
    altitude: 647,
    schengen: false
  },
  {
    id: 6151,
    name: 'Davison Army Air Field',
    city: 'Fort Belvoir',
    country: 'United States',
    iata: 'DAA',
    icao: 'KDAA',
    latitude: 38.7150001526,
    longitude: -77.1809997559,
    altitude: 73,
    schengen: false
  },
  {
    id: 6152,
    name: 'Daytona Beach International Airport',
    city: 'Daytona Beach',
    country: 'United States',
    iata: 'DAB',
    icao: 'KDAB',
    latitude: 29.179899,
    longitude: -81.058098,
    altitude: 34,
    schengen: false
  },
  {
    id: 6153,
    name: 'Barstow Daggett Airport',
    city: 'Daggett',
    country: 'United States',
    iata: 'DAG',
    icao: 'KDAG',
    latitude: 34.85369873,
    longitude: -116.7870026,
    altitude: 1930,
    schengen: false
  },
  {
    id: 6154,
    name: 'Dallas Love Field',
    city: 'Dallas',
    country: 'United States',
    iata: 'DAL',
    icao: 'KDAL',
    latitude: 32.847099,
    longitude: -96.851799,
    altitude: 487,
    schengen: false
  },
  {
    id: 6155,
    name: 'Danville Regional Airport',
    city: 'Danville',
    country: 'United States',
    iata: 'DAN',
    icao: 'KDAN',
    latitude: 36.5728988647461,
    longitude: -79.3360977172852,
    altitude: 571,
    schengen: false
  },
  {
    id: 6156,
    name: 'Skyhaven Airport',
    city: 'Rochester',
    country: 'United States',
    iata: '',
    icao: 'KDAW',
    latitude: 43.2840995788574,
    longitude: -70.9292984008789,
    altitude: 322,
    schengen: false
  },
  {
    id: 6157,
    name: 'James M Cox Dayton International Airport',
    city: 'Dayton',
    country: 'United States',
    iata: 'DAY',
    icao: 'KDAY',
    latitude: 39.902400970459,
    longitude: -84.2193984985352,
    altitude: 1009,
    schengen: false
  },
  {
    id: 6158,
    name: "W H 'Bud' Barron Airport",
    city: 'Dublin',
    country: 'United States',
    iata: 'DBN',
    icao: 'KDBN',
    latitude: 32.56439972,
    longitude: -82.98529816,
    altitude: 309,
    schengen: false
  },
  {
    id: 6159,
    name: 'Dubuque Regional Airport',
    city: 'Dubuque IA',
    country: 'United States',
    iata: 'DBQ',
    icao: 'KDBQ',
    latitude: 42.40200043,
    longitude: -90.70950317,
    altitude: 1077,
    schengen: false
  },
  {
    id: 6160,
    name: 'Ronald Reagan Washington National Airport',
    city: 'Washington',
    country: 'United States',
    iata: 'DCA',
    icao: 'KDCA',
    latitude: 38.8521,
    longitude: -77.037697,
    altitude: 15,
    schengen: false
  },
  {
    id: 6161,
    name: 'Pryor Field Regional Airport',
    city: 'Decatur',
    country: 'United States',
    iata: 'DCU',
    icao: 'KDCU',
    latitude: 34.6526985168,
    longitude: -86.9453964233,
    altitude: 592,
    schengen: false
  },
  {
    id: 6162,
    name: 'Dodge City Regional Airport',
    city: 'Dodge City',
    country: 'United States',
    iata: 'DDC',
    icao: 'KDDC',
    latitude: 37.7634010314941,
    longitude: -99.9655990600586,
    altitude: 2594,
    schengen: false
  },
  {
    id: 6163,
    name: 'Decatur Airport',
    city: 'Decatur',
    country: 'United States',
    iata: 'DEC',
    icao: 'KDEC',
    latitude: 39.8345985412598,
    longitude: -88.8656997680664,
    altitude: 682,
    schengen: false
  },
  {
    id: 6164,
    name: 'Deland Municipal Sidney H Taylor Field',
    city: 'DeLand',
    country: 'United States',
    iata: '',
    icao: 'KDED',
    latitude: 29.06699944,
    longitude: -81.28379822,
    altitude: 79,
    schengen: false
  },
  {
    id: 6165,
    name: 'Denver International Airport',
    city: 'Denver',
    country: 'United States',
    iata: 'DEN',
    icao: 'KDEN',
    latitude: 39.861698150635,
    longitude: -104.672996521,
    altitude: 5431,
    schengen: false
  },
  {
    id: 6166,
    name: 'Coleman A. Young Municipal Airport',
    city: 'Detroit',
    country: 'United States',
    iata: 'DET',
    icao: 'KDET',
    latitude: 42.40919876,
    longitude: -83.00990295,
    altitude: 626,
    schengen: false
  },
  {
    id: 6167,
    name: 'Dallas Fort Worth International Airport',
    city: 'Dallas-Fort Worth',
    country: 'United States',
    iata: 'DFW',
    icao: 'KDFW',
    latitude: 32.896801,
    longitude: -97.038002,
    altitude: 607,
    schengen: false
  },
  {
    id: 6168,
    name: 'Douglas Municipal Airport',
    city: 'Douglas',
    country: 'United States',
    iata: 'DGL',
    icao: 'KDGL',
    latitude: 31.3425998688,
    longitude: -109.505996704,
    altitude: 4173,
    schengen: false
  },
  {
    id: 6169,
    name: 'Dothan Regional Airport',
    city: 'Dothan',
    country: 'United States',
    iata: 'DHN',
    icao: 'KDHN',
    latitude: 31.3213005065918,
    longitude: -85.4496002197266,
    altitude: 401,
    schengen: false
  },
  {
    id: 6170,
    name: 'Dalhart Municipal Airport',
    city: 'Dalhart',
    country: 'United States',
    iata: 'DHT',
    icao: 'KDHT',
    latitude: 36.0225982666,
    longitude: -102.54699707,
    altitude: 3991,
    schengen: false
  },
  {
    id: 6171,
    name: 'Dickinson Theodore Roosevelt Regional Airport',
    city: 'Dickinson',
    country: 'United States',
    iata: 'DIK',
    icao: 'KDIK',
    latitude: 46.7974014282,
    longitude: -102.802001953,
    altitude: 2592,
    schengen: false
  },
  {
    id: 6172,
    name: 'Chautauqua County-Dunkirk Airport',
    city: 'Dunkirk',
    country: 'United States',
    iata: 'DKK',
    icao: 'KDKK',
    latitude: 42.49330139,
    longitude: -79.27200317,
    altitude: 693,
    schengen: false
  },
  {
    id: 6173,
    name: 'Knoxville Downtown Island Airport',
    city: 'Knoxville',
    country: 'United States',
    iata: '',
    icao: 'KDKX',
    latitude: 35.9639015197754,
    longitude: -83.8739013671875,
    altitude: 833,
    schengen: false
  },
  {
    id: 6174,
    name: 'DLF Airport',
    city: 'Del Rio',
    country: 'United States',
    iata: 'DLF',
    icao: 'KDLF',
    latitude: 29.359501,
    longitude: -100.778002,
    altitude: 1082,
    schengen: false
  },
  {
    id: 6175,
    name: 'Duluth International Airport',
    city: 'Duluth',
    country: 'United States',
    iata: 'DLH',
    icao: 'KDLH',
    latitude: 46.8420982361,
    longitude: -92.1936035156,
    altitude: 1428,
    schengen: false
  },
  {
    id: 6176,
    name: 'Baraboo Wisconsin Dells Airport',
    city: 'Baraboo',
    country: 'United States',
    iata: '',
    icao: 'KDLL',
    latitude: 43.52270126,
    longitude: -89.77020264,
    altitude: 979,
    schengen: false
  },
  {
    id: 6177,
    name: 'Columbia Gorge Regional the Dalles Municipal Airport',
    city: 'The Dalles',
    country: 'United States',
    iata: 'DLS',
    icao: 'KDLS',
    latitude: 45.6184997559,
    longitude: -121.166999817,
    altitude: 247,
    schengen: false
  },
  {
    id: 6178,
    name: 'Davis Monthan Air Force Base',
    city: 'Tucson',
    country: 'United States',
    iata: 'DMA',
    icao: 'KDMA',
    latitude: 32.1665000916,
    longitude: -110.883003235,
    altitude: 2704,
    schengen: false
  },
  {
    id: 6179,
    name: 'Deming Municipal Airport',
    city: 'Deming',
    country: 'United States',
    iata: 'DMN',
    icao: 'KDMN',
    latitude: 32.262298584,
    longitude: -107.721000671,
    altitude: 4314,
    schengen: false
  },
  {
    id: 6180,
    name: 'Daniel Field',
    city: 'Augusta',
    country: 'United States',
    iata: 'DNL',
    icao: 'KDNL',
    latitude: 33.4664993286,
    longitude: -82.0393981934,
    altitude: 423,
    schengen: false
  },
  {
    id: 6181,
    name: 'Dalton Municipal Airport',
    city: 'Dalton',
    country: 'United States',
    iata: 'DNN',
    icao: 'KDNN',
    latitude: 34.72290039,
    longitude: -84.87020111,
    altitude: 709,
    schengen: false
  },
  {
    id: 6182,
    name: 'Vermilion Regional Airport',
    city: 'Danville',
    country: 'United States',
    iata: 'DNV',
    icao: 'KDNV',
    latitude: 40.19919968,
    longitude: -87.59590149,
    altitude: 697,
    schengen: false
  },
  {
    id: 6183,
    name: 'Dover Air Force Base',
    city: 'Dover',
    country: 'United States',
    iata: 'DOV',
    icao: 'KDOV',
    latitude: 39.12950134,
    longitude: -75.46600342,
    altitude: 24,
    schengen: false
  },
  {
    id: 6184,
    name: 'Dupage Airport',
    city: 'West Chicago',
    country: 'United States',
    iata: 'DPA',
    icao: 'KDPA',
    latitude: 41.90779877,
    longitude: -88.24859619,
    altitude: 759,
    schengen: false
  },
  {
    id: 6185,
    name: 'Douglas Municipal Airport',
    city: 'Douglas',
    country: 'United States',
    iata: '',
    icao: 'KDQH',
    latitude: 31.4766998291016,
    longitude: -82.8604965209961,
    altitude: 257,
    schengen: false
  },
  {
    id: 6186,
    name: 'Desert Rock Airport',
    city: 'Mercury',
    country: 'United States',
    iata: 'DRA',
    icao: 'KDRA',
    latitude: 36.6194,
    longitude: -116.032997,
    altitude: 3314,
    schengen: false
  },
  {
    id: 6187,
    name: 'Beauregard Regional Airport',
    city: 'Deridder',
    country: 'United States',
    iata: 'DRI',
    icao: 'KDRI',
    latitude: 30.8316993713,
    longitude: -93.3398971558,
    altitude: 202,
    schengen: false
  },
  {
    id: 6188,
    name: 'Drummond Island Airport',
    city: 'Drummond Island',
    country: 'United States',
    iata: 'DRE',
    icao: 'KDRM',
    latitude: 46.0093002319,
    longitude: -83.7438964844,
    altitude: 668,
    schengen: false
  },
  {
    id: 6189,
    name: 'Durango La Plata County Airport',
    city: 'Durango',
    country: 'United States',
    iata: 'DRO',
    icao: 'KDRO',
    latitude: 37.1515007019,
    longitude: -107.753997803,
    altitude: 6685,
    schengen: false
  },
  {
    id: 6190,
    name: 'Del Rio International Airport',
    city: 'Del Rio',
    country: 'United States',
    iata: 'DRT',
    icao: 'KDRT',
    latitude: 29.3742008209,
    longitude: -100.927001953,
    altitude: 1002,
    schengen: false
  },
  {
    id: 6191,
    name: 'Des Moines International Airport',
    city: 'Des Moines',
    country: 'United States',
    iata: 'DSM',
    icao: 'KDSM',
    latitude: 41.5340003967285,
    longitude: -93.6631011962891,
    altitude: 958,
    schengen: false
  },
  {
    id: 6192,
    name: 'Delta Municipal Airport',
    city: 'Delta',
    country: 'United States',
    iata: 'DTA',
    icao: 'KDTA',
    latitude: 39.3805999756,
    longitude: -112.508003235,
    altitude: 4759,
    schengen: false
  },
  {
    id: 6193,
    name: 'Shreveport Downtown Airport',
    city: 'Shreveport',
    country: 'United States',
    iata: 'DTN',
    icao: 'KDTN',
    latitude: 32.5401992798,
    longitude: -93.7450027466,
    altitude: 179,
    schengen: false
  },
  {
    id: 6194,
    name: 'Denton Municipal Airport',
    city: 'Denton',
    country: 'United States',
    iata: '',
    icao: 'KDTO',
    latitude: 33.2006988525,
    longitude: -97.1979980469,
    altitude: 642,
    schengen: false
  },
  {
    id: 6195,
    name: 'Destin Executive Airport',
    city: 'Destin',
    country: 'United States',
    iata: 'DSI',
    icao: 'KDTS',
    latitude: 30.40010071,
    longitude: -86.47149658,
    altitude: 23,
    schengen: false
  },
  {
    id: 6196,
    name: 'Detroit Metropolitan Wayne County Airport',
    city: 'Detroit',
    country: 'United States',
    iata: 'DTW',
    icao: 'KDTW',
    latitude: 42.2123985290527,
    longitude: -83.353401184082,
    altitude: 645,
    schengen: false
  },
  {
    id: 6197,
    name: 'Halliburton Field',
    city: 'Duncan',
    country: 'United States',
    iata: 'DUC',
    icao: 'KDUC',
    latitude: 34.47090149,
    longitude: -97.9598999,
    altitude: 1114,
    schengen: false
  },
  {
    id: 6198,
    name: 'Bisbee Douglas International Airport',
    city: 'Douglas',
    country: 'United States',
    iata: 'DUG',
    icao: 'KDUG',
    latitude: 31.4689998627,
    longitude: -109.603996277,
    altitude: 4154,
    schengen: false
  },
  {
    id: 6199,
    name: 'DuBois Regional Airport',
    city: 'Du Bois',
    country: 'United States',
    iata: 'DUJ',
    icao: 'KDUJ',
    latitude: 41.17829895,
    longitude: -78.8986969,
    altitude: 1817,
    schengen: false
  },
  {
    id: 6200,
    name: 'Devils Lake Regional Airport',
    city: 'Devils Lake',
    country: 'United States',
    iata: 'DVL',
    icao: 'KDVL',
    latitude: 48.11420059,
    longitude: -98.90879822,
    altitude: 1456,
    schengen: false
  },
  {
    id: 6201,
    name: 'Marin County Airport - Gnoss Field',
    city: 'Novato',
    country: 'United States',
    iata: 'NOT',
    icao: 'KDVO',
    latitude: 38.143600463867,
    longitude: -122.55599975586,
    altitude: 2,
    schengen: false
  },
  {
    id: 6202,
    name: 'Phoenix Deer Valley Airport',
    city: 'Phoenix ',
    country: 'United States',
    iata: 'DVT',
    icao: 'KDVT',
    latitude: 33.6883010864,
    longitude: -112.083000183,
    altitude: 1478,
    schengen: false
  },
  {
    id: 6203,
    name: 'Yolo County Davis Woodland Winters Airport',
    city: 'Davis-Woodland-Winters',
    country: 'United States',
    iata: '',
    icao: 'KDWA',
    latitude: 38.57910156,
    longitude: -121.8570023,
    altitude: 100,
    schengen: false
  },
  {
    id: 6204,
    name: 'David Wayne Hooks Memorial Airport',
    city: 'Houston',
    country: 'United States',
    iata: 'DWH',
    icao: 'KDWH',
    latitude: 30.0618000031,
    longitude: -95.5528030396,
    altitude: 152,
    schengen: false
  },
  {
    id: 6205,
    name: 'Danbury Municipal Airport',
    city: 'Danbury',
    country: 'United States',
    iata: 'DXR',
    icao: 'KDXR',
    latitude: 41.3714981079,
    longitude: -73.4822006226,
    altitude: 458,
    schengen: false
  },
  {
    id: 6206,
    name: 'Doylestown Airport',
    city: 'Doylestown',
    country: 'United States',
    iata: 'DYL',
    icao: 'KDYL',
    latitude: 40.3330001831,
    longitude: -75.1222991943,
    altitude: 394,
    schengen: false
  },
  {
    id: 6207,
    name: 'Dyess Air Force Base',
    city: 'Abilene',
    country: 'United States',
    iata: 'DYS',
    icao: 'KDYS',
    latitude: 32.4207992554,
    longitude: -99.854598999,
    altitude: 1789,
    schengen: false
  },
  {
    id: 6208,
    name: 'Wickenburg Municipal Airport',
    city: 'Wickenburg',
    country: 'United States',
    iata: '',
    icao: 'KE25',
    latitude: 33.96889877,
    longitude: -112.7990036,
    altitude: 2377,
    schengen: false
  },
  {
    id: 6209,
    name: 'Alpine Casparis Municipal Airport',
    city: 'Alpine',
    country: 'United States',
    iata: 'ALE',
    icao: 'KE38',
    latitude: 30.3841991425,
    longitude: -103.683998108,
    altitude: 4515,
    schengen: false
  },
  {
    id: 6210,
    name: 'Gila Bend Municipal Airport',
    city: 'Gila Bend',
    country: 'United States',
    iata: '',
    icao: 'KE63',
    latitude: 32.95809937,
    longitude: -112.6780014,
    altitude: 789,
    schengen: false
  },
  {
    id: 6211,
    name: 'Pangborn Memorial Airport',
    city: 'Wenatchee',
    country: 'United States',
    iata: 'EAT',
    icao: 'KEAT',
    latitude: 47.3988990784,
    longitude: -120.207000732,
    altitude: 1249,
    schengen: false
  },
  {
    id: 6212,
    name: 'Chippewa Valley Regional Airport',
    city: 'Eau Claire',
    country: 'United States',
    iata: 'EAU',
    icao: 'KEAU',
    latitude: 44.8657989501953,
    longitude: -91.4842987060547,
    altitude: 913,
    schengen: false
  },
  {
    id: 6213,
    name: 'Elizabeth City Regional Airport & Coast Guard Air Station',
    city: 'Elizabeth City',
    country: 'United States',
    iata: 'ECG',
    icao: 'KECG',
    latitude: 36.26060104,
    longitude: -76.17459869,
    altitude: 12,
    schengen: false
  },
  {
    id: 6214,
    name: 'Northwest Florida Beaches International Airport',
    city: 'Panama City',
    country: 'United States',
    iata: 'ECP',
    icao: 'KECP',
    latitude: 30.357106,
    longitude: -85.795414,
    altitude: 69,
    schengen: false
  },
  {
    id: 6215,
    name: 'Austin Executive Airport',
    city: 'Austin',
    country: 'United States',
    iata: '',
    icao: 'KEDC',
    latitude: 30.3974931,
    longitude: -97.5663935,
    altitude: 620,
    schengen: false
  },
  {
    id: 6216,
    name: 'Enterprise Municipal Airport',
    city: 'Enterprise',
    country: 'United States',
    iata: 'ETS',
    icao: 'KEDN',
    latitude: 31.29969978,
    longitude: -85.89990234,
    altitude: 361,
    schengen: false
  },
  {
    id: 6217,
    name: 'Edwards Air Force Base',
    city: 'Edwards Afb',
    country: 'United States',
    iata: 'EDW',
    icao: 'KEDW',
    latitude: 34.905399,
    longitude: -117.884003,
    altitude: 2312,
    schengen: false
  },
  {
    id: 6218,
    name: 'Needles Airport',
    city: 'Needles',
    country: 'United States',
    iata: 'EED',
    icao: 'KEED',
    latitude: 34.7663002014,
    longitude: -114.623001099,
    altitude: 983,
    schengen: false
  },
  {
    id: 6219,
    name: 'Dillant Hopkins Airport',
    city: 'Keene',
    country: 'United States',
    iata: 'EEN',
    icao: 'KEEN',
    latitude: 42.8983993530273,
    longitude: -72.2707977294922,
    altitude: 488,
    schengen: false
  },
  {
    id: 6220,
    name: 'Shelby County Airport',
    city: 'Alabaster',
    country: 'United States',
    iata: '',
    icao: 'KEET',
    latitude: 33.17699814,
    longitude: -86.78279877,
    altitude: 586,
    schengen: false
  },
  {
    id: 6221,
    name: 'Ellington Airport',
    city: 'Houston',
    country: 'United States',
    iata: 'EFD',
    icao: 'KEFD',
    latitude: 29.6072998047,
    longitude: -95.1587982178,
    altitude: 32,
    schengen: false
  },
  {
    id: 6222,
    name: 'Monroe Municipal Airport',
    city: 'Monroe',
    country: 'United States',
    iata: '',
    icao: 'KEFT',
    latitude: 42.6148986816406,
    longitude: -89.5904006958008,
    altitude: 1086,
    schengen: false
  },
  {
    id: 6223,
    name: 'Eagle County Regional Airport',
    city: 'Vail',
    country: 'United States',
    iata: 'EGE',
    icao: 'KEGE',
    latitude: 39.64260101,
    longitude: -106.9179993,
    altitude: 6548,
    schengen: false
  },
  {
    id: 6224,
    name: 'Duke Field',
    city: 'Crestview',
    country: 'United States',
    iata: 'EGI',
    icao: 'KEGI',
    latitude: 30.65040016,
    longitude: -86.52290344,
    altitude: 191,
    schengen: false
  },
  {
    id: 6225,
    name: 'Wellington Municipal Airport',
    city: 'Wellington',
    country: 'United States',
    iata: '',
    icao: 'KEGT',
    latitude: 37.323600769043,
    longitude: -97.388298034668,
    altitude: 1277,
    schengen: false
  },
  {
    id: 6226,
    name: 'Eagle River Union Airport',
    city: 'Eagle River',
    country: 'United States',
    iata: 'EGV',
    icao: 'KEGV',
    latitude: 45.9323005676,
    longitude: -89.2683029175,
    altitude: 1642,
    schengen: false
  },
  {
    id: 6227,
    name: 'Elkhart Morton County Airport',
    city: 'Elkhart',
    country: 'United States',
    iata: '',
    icao: 'KEHA',
    latitude: 37.000702,
    longitude: -101.879997,
    altitude: 3622,
    schengen: false
  },
  {
    id: 6228,
    name: 'Murray Field',
    city: 'Eureka',
    country: 'United States',
    iata: 'EKA',
    icao: 'KEKA',
    latitude: 40.8033981323,
    longitude: -124.112998962,
    altitude: 7,
    schengen: false
  },
  {
    id: 6229,
    name: 'Elkhart Municipal Airport',
    city: 'Elkhart',
    country: 'United States',
    iata: 'EKI',
    icao: 'KEKM',
    latitude: 41.7193984985,
    longitude: -86.0031967163,
    altitude: 778,
    schengen: false
  },
  {
    id: 6230,
    name: 'Elkins-Randolph Co-Jennings Randolph Field',
    city: 'Elkins',
    country: 'United States',
    iata: 'EKN',
    icao: 'KEKN',
    latitude: 38.88940048,
    longitude: -79.85710144,
    altitude: 1987,
    schengen: false
  },
  {
    id: 6231,
    name: 'Elko Regional Airport',
    city: 'Elko',
    country: 'United States',
    iata: 'EKO',
    icao: 'KEKO',
    latitude: 40.8249015808106,
    longitude: -115.791999816895,
    altitude: 5140,
    schengen: false
  },
  {
    id: 6232,
    name: 'Bessemer Airport',
    city: 'Bessemer',
    country: 'United States',
    iata: '',
    icao: 'KEKY',
    latitude: 33.31290054,
    longitude: -86.92590332,
    altitude: 700,
    schengen: false
  },
  {
    id: 6233,
    name: 'South Arkansas Regional At Goodwin Field',
    city: 'El Dorado',
    country: 'United States',
    iata: 'ELD',
    icao: 'KELD',
    latitude: 33.2210006713867,
    longitude: -92.8133010864258,
    altitude: 277,
    schengen: false
  },
  {
    id: 6234,
    name: 'Elmira Corning Regional Airport',
    city: 'Elmira',
    country: 'United States',
    iata: 'ELM',
    icao: 'KELM',
    latitude: 42.1599006652832,
    longitude: -76.8916015625,
    altitude: 954,
    schengen: false
  },
  {
    id: 6235,
    name: 'Ely Municipal Airport',
    city: 'Ely',
    country: 'United States',
    iata: 'LYU',
    icao: 'KELO',
    latitude: 47.82450104,
    longitude: -91.83070374,
    altitude: 1456,
    schengen: false
  },
  {
    id: 6236,
    name: 'El Paso International Airport',
    city: 'El Paso',
    country: 'United States',
    iata: 'ELP',
    icao: 'KELP',
    latitude: 31.80719948,
    longitude: -106.3779984,
    altitude: 3959,
    schengen: false
  },
  {
    id: 6237,
    name: 'Ely Airport Yelland Field',
    city: 'Ely',
    country: 'United States',
    iata: 'ELY',
    icao: 'KELY',
    latitude: 39.29970169,
    longitude: -114.8420029,
    altitude: 6259,
    schengen: false
  },
  {
    id: 6238,
    name: 'San Gabriel Valley Airport',
    city: 'El Monte',
    country: 'United States',
    iata: 'EMT',
    icao: 'KEMT',
    latitude: 34.086102,
    longitude: -118.035004,
    altitude: 296,
    schengen: false
  },
  {
    id: 6239,
    name: 'Emporia Greensville Regional Airport',
    city: 'Emporia',
    country: 'United States',
    iata: '',
    icao: 'KEMV',
    latitude: 36.6869010925293,
    longitude: -77.4828033447266,
    altitude: 127,
    schengen: false
  },
  {
    id: 6240,
    name: 'Vance Air Force Base',
    city: 'Enid',
    country: 'United States',
    iata: 'END',
    icao: 'KEND',
    latitude: 36.3391990662,
    longitude: -97.9164962769,
    altitude: 1307,
    schengen: false
  },
  {
    id: 6241,
    name: 'Wendover Airport',
    city: 'Wendover',
    country: 'United States',
    iata: 'ENV',
    icao: 'KENV',
    latitude: 40.7187004089,
    longitude: -114.03099823,
    altitude: 4237,
    schengen: false
  },
  {
    id: 6242,
    name: 'Kenosha Regional Airport',
    city: 'Kenosha',
    country: 'United States',
    iata: 'ENW',
    icao: 'KENW',
    latitude: 42.59569931,
    longitude: -87.92780304,
    altitude: 742,
    schengen: false
  },
  {
    id: 6243,
    name: 'Keokuk Municipal Airport',
    city: 'Keokuk',
    country: 'United States',
    iata: 'EOK',
    icao: 'KEOK',
    latitude: 40.4598999023,
    longitude: -91.4284973145,
    altitude: 671,
    schengen: false
  },
  {
    id: 6244,
    name: 'Eastport Municipal Airport',
    city: 'Eastport',
    country: 'United States',
    iata: '',
    icao: 'KEPM',
    latitude: 44.910099029541,
    longitude: -67.0127029418945,
    altitude: 45,
    schengen: false
  },
  {
    id: 6245,
    name: 'Erie International Tom Ridge Field',
    city: 'Erie',
    country: 'United States',
    iata: 'ERI',
    icao: 'KERI',
    latitude: 42.0831270134,
    longitude: -80.1738667488,
    altitude: 732,
    schengen: false
  },
  {
    id: 6246,
    name: 'Kerrville Municipal Louis Schreiner Field',
    city: 'Kerrville',
    country: 'United States',
    iata: 'ERV',
    icao: 'KERV',
    latitude: 29.9766998291,
    longitude: -99.0857009888,
    altitude: 1617,
    schengen: false
  },
  {
    id: 6247,
    name: 'Luce County Airport',
    city: 'Newberry',
    country: 'United States',
    iata: '',
    icao: 'KERY',
    latitude: 46.3111991882324,
    longitude: -85.4572982788086,
    altitude: 869,
    schengen: false
  },
  {
    id: 6248,
    name: 'Delta County Airport',
    city: 'Escanaba',
    country: 'United States',
    iata: 'ESC',
    icao: 'KESC',
    latitude: 45.7226982117,
    longitude: -87.0936965942,
    altitude: 609,
    schengen: false
  },
  {
    id: 6249,
    name: 'Esler Regional Airport',
    city: 'Alexandria',
    country: 'United States',
    iata: 'ESF',
    icao: 'KESF',
    latitude: 31.3948993683,
    longitude: -92.2957992554,
    altitude: 112,
    schengen: false
  },
  {
    id: 6250,
    name: 'Easton Newnam Field',
    city: 'Easton',
    country: 'United States',
    iata: 'ESN',
    icao: 'KESN',
    latitude: 38.8041992188,
    longitude: -76.0690002441,
    altitude: 72,
    schengen: false
  },
  {
    id: 6251,
    name: 'West Bend Municipal Airport',
    city: 'WEST BEND',
    country: 'United States',
    iata: 'ETB',
    icao: 'KETB',
    latitude: 43.4221992493,
    longitude: -88.1278991699,
    altitude: 887,
    schengen: false
  },
  {
    id: 6252,
    name: 'Weedon Field',
    city: 'Eufala',
    country: 'United States',
    iata: 'EUF',
    icao: 'KEUF',
    latitude: 31.9512996674,
    longitude: -85.1288986206,
    altitude: 285,
    schengen: false
  },
  {
    id: 6253,
    name: 'Mahlon Sweet Field',
    city: 'Eugene',
    country: 'United States',
    iata: 'EUG',
    icao: 'KEUG',
    latitude: 44.1245994567871,
    longitude: -123.21199798584,
    altitude: 374,
    schengen: false
  },
  {
    id: 6254,
    name: 'New Smyrna Beach Municipal Airport',
    city: 'New Smyrna Beach',
    country: 'United States',
    iata: '',
    icao: 'KEVB',
    latitude: 29.055700302124,
    longitude: -80.9488983154297,
    altitude: 10,
    schengen: false
  },
  {
    id: 6255,
    name: 'Evansville Regional Airport',
    city: 'Evansville',
    country: 'United States',
    iata: 'EVV',
    icao: 'KEVV',
    latitude: 38.0369987488,
    longitude: -87.5324020386,
    altitude: 418,
    schengen: false
  },
  {
    id: 6256,
    name: 'Evanston-Uinta County Airport-Burns Field',
    city: 'Evanston',
    country: 'United States',
    iata: 'EVW',
    icao: 'KEVW',
    latitude: 41.27479935,
    longitude: -111.0350037,
    altitude: 7143,
    schengen: false
  },
  {
    id: 6257,
    name: 'New Bedford Regional Airport',
    city: 'New Bedford',
    country: 'United States',
    iata: 'EWB',
    icao: 'KEWB',
    latitude: 41.6761016845703,
    longitude: -70.956901550293,
    altitude: 80,
    schengen: false
  },
  {
    id: 6258,
    name: 'Newton City-County Airport',
    city: 'Newton',
    country: 'United States',
    iata: 'EWK',
    icao: 'KEWK',
    latitude: 38.0582008362,
    longitude: -97.2744979858,
    altitude: 1533,
    schengen: false
  },
  {
    id: 6259,
    name: 'Coastal Carolina Regional Airport',
    city: 'New Bern',
    country: 'United States',
    iata: 'EWN',
    icao: 'KEWN',
    latitude: 35.0730018616,
    longitude: -77.0429000854,
    altitude: 18,
    schengen: false
  },
  {
    id: 6260,
    name: 'Newark Liberty International Airport',
    city: 'Newark',
    country: 'United States',
    iata: 'EWR',
    icao: 'KEWR',
    latitude: 40.6925010681152,
    longitude: -74.168701171875,
    altitude: 18,
    schengen: false
  },
  {
    id: 6261,
    name: 'Davidson County Airport',
    city: 'Lexington',
    country: 'United States',
    iata: '',
    icao: 'KEXX',
    latitude: 35.7811012268066,
    longitude: -80.3038024902344,
    altitude: 733,
    schengen: false
  },
  {
    id: 6262,
    name: 'Key West International Airport',
    city: 'Key West',
    country: 'United States',
    iata: 'EYW',
    icao: 'KEYW',
    latitude: 24.5561008453369,
    longitude: -81.7595977783203,
    altitude: 3,
    schengen: false
  },
  {
    id: 6263,
    name: 'Perry Municipal Airport',
    city: 'Perry',
    country: 'United States',
    iata: '',
    icao: 'KF22',
    latitude: 36.3856010437012,
    longitude: -97.2771987915039,
    altitude: 1002,
    schengen: false
  },
  {
    id: 6264,
    name: 'French Valley Airport',
    city: 'Murrieta-Temecula',
    country: 'United States',
    iata: 'RBK',
    icao: 'KF70',
    latitude: 33.5741996765,
    longitude: -117.127998352,
    altitude: 1350,
    schengen: false
  },
  {
    id: 6265,
    name: 'Felker Army Air Field',
    city: 'Fort Eustis',
    country: 'United States',
    iata: 'FAF',
    icao: 'KFAF',
    latitude: 37.1324996948,
    longitude: -76.6088027954,
    altitude: 12,
    schengen: false
  },
  {
    id: 6266,
    name: 'Hector International Airport',
    city: 'Fargo',
    country: 'United States',
    iata: 'FAR',
    icao: 'KFAR',
    latitude: 46.9207000732422,
    longitude: -96.815803527832,
    altitude: 902,
    schengen: false
  },
  {
    id: 6267,
    name: 'Fresno Yosemite International Airport',
    city: 'Fresno',
    country: 'United States',
    iata: 'FAT',
    icao: 'KFAT',
    latitude: 36.7761993408203,
    longitude: -119.718002319336,
    altitude: 336,
    schengen: false
  },
  {
    id: 6268,
    name: 'Fayetteville Regional Grannis Field',
    city: 'Fayetteville',
    country: 'United States',
    iata: 'FAY',
    icao: 'KFAY',
    latitude: 34.9911994934082,
    longitude: -78.8803024291992,
    altitude: 189,
    schengen: false
  },
  {
    id: 6269,
    name: 'Simmons Army Air Field',
    city: 'Fredericksburg',
    country: 'United States',
    iata: 'FBG',
    icao: 'KFBG',
    latitude: 35.13180161,
    longitude: -78.93669891,
    altitude: 244,
    schengen: false
  },
  {
    id: 6270,
    name: 'Fort Bridger Airport',
    city: 'Fort Bridger',
    country: 'United States',
    iata: 'FBR',
    icao: 'KFBR',
    latitude: 41.3918991089,
    longitude: -110.406997681,
    altitude: 7034,
    schengen: false
  },
  {
    id: 6271,
    name: 'Chesterfield County Airport',
    city: 'Richmond',
    country: 'United States',
    iata: '',
    icao: 'KFCI',
    latitude: 37.40650177,
    longitude: -77.52500153,
    altitude: 236,
    schengen: false
  },
  {
    id: 6272,
    name: 'Flying Cloud Airport',
    city: 'Eden Prairie',
    country: 'United States',
    iata: 'FCM',
    icao: 'KFCM',
    latitude: 44.8272018433,
    longitude: -93.4570999146,
    altitude: 906,
    schengen: false
  },
  {
    id: 6273,
    name: 'Butts AAF (Fort Carson) Air Field',
    city: 'Fort Carson',
    country: 'United States',
    iata: 'FCS',
    icao: 'KFCS',
    latitude: 38.67839813,
    longitude: -104.7570038,
    altitude: 5838,
    schengen: false
  },
  {
    id: 6274,
    name: 'Fairfield County Airport',
    city: 'Winnsboro',
    country: 'United States',
    iata: '',
    icao: 'KFDW',
    latitude: 34.3154983520508,
    longitude: -81.1088027954102,
    altitude: 577,
    schengen: false
  },
  {
    id: 6275,
    name: 'Findlay Airport',
    city: 'Findley',
    country: 'United States',
    iata: 'FDY',
    icao: 'KFDY',
    latitude: 41.0135002136,
    longitude: -83.6687011719,
    altitude: 813,
    schengen: false
  },
  {
    id: 6276,
    name: 'Fremont Municipal Airport',
    city: 'Fremont',
    country: 'United States',
    iata: 'FET',
    icao: 'KFET',
    latitude: 41.44910049,
    longitude: -96.52020264,
    altitude: 1204,
    schengen: false
  },
  {
    id: 6277,
    name: 'First Flight Airport',
    city: 'Kill Devil Hills',
    country: 'United States',
    iata: 'FFA',
    icao: 'KFFA',
    latitude: 36.0181999207,
    longitude: -75.6713027954,
    altitude: 13,
    schengen: false
  },
  {
    id: 6278,
    name: 'Peachtree City Falcon Field',
    city: 'Atlanta',
    country: 'United States',
    iata: '',
    icao: 'KFFC',
    latitude: 33.3572998046875,
    longitude: -84.5718002319336,
    altitude: 808,
    schengen: false
  },
  {
    id: 6279,
    name: 'Wright-Patterson Air Force Base',
    city: 'Dayton',
    country: 'United States',
    iata: 'FFO',
    icao: 'KFFO',
    latitude: 39.8260993958,
    longitude: -84.0483016968,
    altitude: 823,
    schengen: false
  },
  {
    id: 6280,
    name: 'Capital City Airport',
    city: 'Frankfort',
    country: 'United States',
    iata: 'FFT',
    icao: 'KFFT',
    latitude: 38.18249893,
    longitude: -84.90470123,
    altitude: 806,
    schengen: false
  },
  {
    id: 6281,
    name: 'Falcon Field',
    city: 'Mesa',
    country: 'United States',
    iata: 'MSC',
    icao: 'KFFZ',
    latitude: 33.4608001709,
    longitude: -111.727996826,
    altitude: 1394,
    schengen: false
  },
  {
    id: 6282,
    name: 'Friday Harbor Airport',
    city: 'Friday Harbor',
    country: 'United States',
    iata: 'FRD',
    icao: 'KFHR',
    latitude: 48.5219993591,
    longitude: -123.024002075,
    altitude: 113,
    schengen: false
  },
  {
    id: 6283,
    name: 'Sierra Vista Municipal Libby Army Air Field',
    city: 'Fort Huachuca',
    country: 'United States',
    iata: 'FHU',
    icao: 'KFHU',
    latitude: 31.5884990692139,
    longitude: -110.34400177002,
    altitude: 4719,
    schengen: false
  },
  {
    id: 6284,
    name: 'Clearfield Lawrence Airport',
    city: 'Clearfield',
    country: 'United States',
    iata: '',
    icao: 'KFIG',
    latitude: 41.0485992431641,
    longitude: -78.4131011962891,
    altitude: 1516,
    schengen: false
  },
  {
    id: 6285,
    name: 'Fitchburg Municipal Airport',
    city: 'Fitchburg',
    country: 'United States',
    iata: '',
    icao: 'KFIT',
    latitude: 42.5541000366211,
    longitude: -71.7590026855469,
    altitude: 348,
    schengen: false
  },
  {
    id: 6286,
    name: 'Venango Regional Airport',
    city: 'Franklin',
    country: 'United States',
    iata: 'FKL',
    icao: 'KFKL',
    latitude: 41.3778991699,
    longitude: -79.8603973389,
    altitude: 1540,
    schengen: false
  },
  {
    id: 6287,
    name: 'Frankfort Dow Memorial Field',
    city: 'Frankfort',
    country: 'United States',
    iata: '',
    icao: 'KFKS',
    latitude: 44.6251983642578,
    longitude: -86.200798034668,
    altitude: 633,
    schengen: false
  },
  {
    id: 6288,
    name: 'Fond du Lac County Airport',
    city: 'Fond du Lac',
    country: 'United States',
    iata: 'FLD',
    icao: 'KFLD',
    latitude: 43.7711982727,
    longitude: -88.4884033203,
    altitude: 808,
    schengen: false
  },
  {
    id: 6289,
    name: 'Flagstaff Pulliam Airport',
    city: 'Flagstaff',
    country: 'United States',
    iata: 'FLG',
    icao: 'KFLG',
    latitude: 35.13850021,
    longitude: -111.6709976,
    altitude: 7014,
    schengen: false
  },
  {
    id: 6290,
    name: 'Fort Lauderdale Hollywood International Airport',
    city: 'Fort Lauderdale',
    country: 'United States',
    iata: 'FLL',
    icao: 'KFLL',
    latitude: 26.072599,
    longitude: -80.152702,
    altitude: 9,
    schengen: false
  },
  {
    id: 6291,
    name: 'Florence Regional Airport',
    city: 'Florence',
    country: 'United States',
    iata: 'FLO',
    icao: 'KFLO',
    latitude: 34.1853981018066,
    longitude: -79.7238998413086,
    altitude: 146,
    schengen: false
  },
  {
    id: 6292,
    name: 'Marion County Regional Airport',
    city: 'Flippin',
    country: 'United States',
    iata: 'FLP',
    icao: 'KFLP',
    latitude: 36.29090118,
    longitude: -92.59030151,
    altitude: 719,
    schengen: false
  },
  {
    id: 6293,
    name: 'Sherman Army Air Field',
    city: 'Fort Leavenworth',
    country: 'United States',
    iata: 'FLV',
    icao: 'KFLV',
    latitude: 39.3683013916,
    longitude: -94.9147033691,
    altitude: 772,
    schengen: false
  },
  {
    id: 6294,
    name: 'Tipton Airport',
    city: 'Fort Meade',
    country: 'United States',
    iata: 'FME',
    icao: 'KFME',
    latitude: 39.0853996277,
    longitude: -76.7593994141,
    altitude: 150,
    schengen: false
  },
  {
    id: 6295,
    name: 'Cape Cod Coast Guard Air Station',
    city: 'Falmouth',
    country: 'United States',
    iata: 'FMH',
    icao: 'KFMH',
    latitude: 41.6584014893,
    longitude: -70.5214004517,
    altitude: 131,
    schengen: false
  },
  {
    id: 6296,
    name: 'Four Corners Regional Airport',
    city: 'Farmington',
    country: 'United States',
    iata: 'FMN',
    icao: 'KFMN',
    latitude: 36.7411994934,
    longitude: -108.230003357,
    altitude: 5506,
    schengen: false
  },
  {
    id: 6297,
    name: 'Page Field',
    city: 'Fort Myers',
    country: 'United States',
    iata: 'FMY',
    icao: 'KFMY',
    latitude: 26.58659935,
    longitude: -81.8632965088,
    altitude: 17,
    schengen: false
  },
  {
    id: 6298,
    name: 'Northern Colorado Regional Airport',
    city: 'Fort Collins',
    country: 'United States',
    iata: 'FNL',
    icao: 'KFNL',
    latitude: 40.451804,
    longitude: -105.011,
    altitude: 5016,
    schengen: false
  },
  {
    id: 6299,
    name: 'Bishop International Airport',
    city: 'Flint',
    country: 'United States',
    iata: 'FNT',
    icao: 'KFNT',
    latitude: 42.9654006958008,
    longitude: -83.7435989379883,
    altitude: 782,
    schengen: false
  },
  {
    id: 6300,
    name: 'Fort Dodge Regional Airport',
    city: 'Fort Dodge',
    country: 'United States',
    iata: 'FOD',
    icao: 'KFOD',
    latitude: 42.55149841,
    longitude: -94.19259644,
    altitude: 1156,
    schengen: false
  },
  {
    id: 6301,
    name: 'Topeka Regional Airport - Forbes Field',
    city: 'Topeka',
    country: 'United States',
    iata: 'FOE',
    icao: 'KFOE',
    latitude: 38.9509010315,
    longitude: -95.6635971069,
    altitude: 1078,
    schengen: false
  },
  {
    id: 6302,
    name: 'Francis S Gabreski Airport',
    city: 'West Hampton Beach',
    country: 'United States',
    iata: 'FOK',
    icao: 'KFOK',
    latitude: 40.8437004089,
    longitude: -72.6317977905,
    altitude: 67,
    schengen: false
  },
  {
    id: 6303,
    name: 'St Lucie County International Airport',
    city: 'Fort Pierce',
    country: 'United States',
    iata: 'FPR',
    icao: 'KFPR',
    latitude: 27.49510002,
    longitude: -80.36830139,
    altitude: 24,
    schengen: false
  },
  {
    id: 6304,
    name: 'Republic Airport',
    city: 'Farmingdale',
    country: 'United States',
    iata: 'FRG',
    icao: 'KFRG',
    latitude: 40.7288017273,
    longitude: -73.4133987427,
    altitude: 82,
    schengen: false
  },
  {
    id: 6305,
    name: 'Marshall Army Air Field',
    city: 'Fort Riley',
    country: 'United States',
    iata: 'FRI',
    icao: 'KFRI',
    latitude: 39.05530167,
    longitude: -96.76450348,
    altitude: 1065,
    schengen: false
  },
  {
    id: 6306,
    name: 'Joe Foss Field Airport',
    city: 'Sioux Falls',
    country: 'United States',
    iata: 'FSD',
    icao: 'KFSD',
    latitude: 43.5820007324,
    longitude: -96.741897583,
    altitude: 1429,
    schengen: false
  },
  {
    id: 6307,
    name: 'Henry Post Army Air Field (Fort Sill)',
    city: 'Fort Sill',
    country: 'United States',
    iata: 'FSI',
    icao: 'KFSI',
    latitude: 34.64979935,
    longitude: -98.40219879,
    altitude: 1189,
    schengen: false
  },
  {
    id: 6308,
    name: 'Fort Smith Regional Airport',
    city: 'Fort Smith',
    country: 'United States',
    iata: 'FSM',
    icao: 'KFSM',
    latitude: 35.3366012573242,
    longitude: -94.3674011230469,
    altitude: 469,
    schengen: false
  },
  {
    id: 6309,
    name: 'Fort Stockton Pecos County Airport',
    city: 'Fort Stockton',
    country: 'United States',
    iata: 'FST',
    icao: 'KFST',
    latitude: 30.9157009125,
    longitude: -102.916000366,
    altitude: 3011,
    schengen: false
  },
  {
    id: 6310,
    name: 'Front Range Airport',
    city: 'Denver',
    country: 'United States',
    iata: '',
    icao: 'KFTG',
    latitude: 39.7853012084961,
    longitude: -104.542999267578,
    altitude: 5512,
    schengen: false
  },
  {
    id: 6311,
    name: 'Godman Army Air Field',
    city: 'Fort Knox',
    country: 'United States',
    iata: 'FTK',
    icao: 'KFTK',
    latitude: 37.9071006775,
    longitude: -85.9720993042,
    altitude: 756,
    schengen: false
  },
  {
    id: 6312,
    name: 'Fort Worth Meacham International Airport',
    city: 'Fort Worth',
    country: 'United States',
    iata: 'FTW',
    icao: 'KFTW',
    latitude: 32.819801,
    longitude: -97.362396,
    altitude: 710,
    schengen: false
  },
  {
    id: 6313,
    name: 'Fulton County Airport Brown Field',
    city: 'Atlanta',
    country: 'United States',
    iata: 'FTY',
    icao: 'KFTY',
    latitude: 33.7790985107,
    longitude: -84.5214004517,
    altitude: 841,
    schengen: false
  },
  {
    id: 6314,
    name: 'Fullerton Municipal Airport',
    city: 'Fullerton',
    country: 'United States',
    iata: 'FUL',
    icao: 'KFUL',
    latitude: 33.8720016479,
    longitude: -117.980003357,
    altitude: 96,
    schengen: false
  },
  {
    id: 6315,
    name: 'Northern Aroostook Regional Airport',
    city: 'Frenchville',
    country: 'United States',
    iata: 'WFK',
    icao: 'KFVE',
    latitude: 47.2854995728,
    longitude: -68.3127975464,
    altitude: 988,
    schengen: false
  },
  {
    id: 6316,
    name: 'Fort Wayne International Airport',
    city: 'Fort Wayne',
    country: 'United States',
    iata: 'FWA',
    icao: 'KFWA',
    latitude: 40.97850037,
    longitude: -85.19509888,
    altitude: 814,
    schengen: false
  },
  {
    id: 6317,
    name: 'Fort Lauderdale Executive Airport',
    city: 'Fort Lauderdale',
    country: 'United States',
    iata: 'FXE',
    icao: 'KFXE',
    latitude: 26.1972999573,
    longitude: -80.1707000732,
    altitude: 13,
    schengen: false
  },
  {
    id: 6318,
    name: 'Drake Field',
    city: 'Fayetteville',
    country: 'United States',
    iata: 'FYV',
    icao: 'KFYV',
    latitude: 36.0051002502441,
    longitude: -94.1700973510742,
    altitude: 1251,
    schengen: false
  },
  {
    id: 6319,
    name: 'Fitzgerald Municipal Airport',
    city: 'Fitzgerald',
    country: 'United States',
    iata: '',
    icao: 'KFZG',
    latitude: 31.6837005615234,
    longitude: -83.2705001831055,
    altitude: 365,
    schengen: false
  },
  {
    id: 6320,
    name: 'Fostoria Metropolitan Airport',
    city: 'Fostoria',
    country: 'United States',
    iata: '',
    icao: 'KFZI',
    latitude: 41.19079971,
    longitude: -83.39450073,
    altitude: 752,
    schengen: false
  },
  {
    id: 6321,
    name: 'Northeast Alabama Regional Airport',
    city: 'Gadsden',
    country: 'United States',
    iata: 'GAD',
    icao: 'KGAD',
    latitude: 33.972599,
    longitude: -86.088996,
    altitude: 569,
    schengen: false
  },
  {
    id: 6322,
    name: 'Montgomery County Airpark',
    city: 'Gaithersburg',
    country: 'United States',
    iata: 'GAI',
    icao: 'KGAI',
    latitude: 39.1683006287,
    longitude: -77.1660003662,
    altitude: 539,
    schengen: false
  },
  {
    id: 6323,
    name: 'Great Bend Municipal Airport',
    city: 'Great Bend',
    country: 'United States',
    iata: 'GBD',
    icao: 'KGBD',
    latitude: 38.3442993164,
    longitude: -98.8591995239,
    altitude: 1887,
    schengen: false
  },
  {
    id: 6324,
    name: 'Galesburg Municipal Airport',
    city: 'Galesburg',
    country: 'United States',
    iata: 'GBG',
    icao: 'KGBG',
    latitude: 40.9379997253,
    longitude: -90.431098938,
    altitude: 764,
    schengen: false
  },
  {
    id: 6325,
    name: 'Gillette Campbell County Airport',
    city: 'Gillette',
    country: 'United States',
    iata: 'GCC',
    icao: 'KGCC',
    latitude: 44.3488998413,
    longitude: -105.539001465,
    altitude: 4365,
    schengen: false
  },
  {
    id: 6326,
    name: 'Garden City Regional Airport',
    city: 'Garden City',
    country: 'United States',
    iata: 'GCK',
    icao: 'KGCK',
    latitude: 37.9275016785,
    longitude: -100.723999023,
    altitude: 2891,
    schengen: false
  },
  {
    id: 6327,
    name: 'Grand Canyon National Park Airport',
    city: 'Grand Canyon',
    country: 'United States',
    iata: 'GCN',
    icao: 'KGCN',
    latitude: 35.9524002075195,
    longitude: -112.147003173828,
    altitude: 6609,
    schengen: false
  },
  {
    id: 6328,
    name: 'Dawson Community Airport',
    city: 'Glendive',
    country: 'United States',
    iata: 'GDV',
    icao: 'KGDV',
    latitude: 47.13869858,
    longitude: -104.8069992,
    altitude: 2458,
    schengen: false
  },
  {
    id: 6329,
    name: 'Gladwin Zettel Memorial Airport',
    city: 'Gladwin',
    country: 'United States',
    iata: 'GDW',
    icao: 'KGDW',
    latitude: 43.9706001282,
    longitude: -84.4749984741,
    altitude: 776,
    schengen: false
  },
  {
    id: 6330,
    name: 'Sussex County Airport',
    city: 'Georgetown',
    country: 'United States',
    iata: 'GED',
    icao: 'KGED',
    latitude: 38.68920135,
    longitude: -75.35890198,
    altitude: 53,
    schengen: false
  },
  {
    id: 6331,
    name: 'Spokane International Airport',
    city: 'Spokane',
    country: 'United States',
    iata: 'GEG',
    icao: 'KGEG',
    latitude: 47.6198997497559,
    longitude: -117.533996582031,
    altitude: 2376,
    schengen: false
  },
  {
    id: 6332,
    name: 'Brown County Airport',
    city: 'Georgetown',
    country: 'United States',
    iata: '',
    icao: 'KGEO',
    latitude: 38.8819007873535,
    longitude: -83.882698059082,
    altitude: 958,
    schengen: false
  },
  {
    id: 6333,
    name: 'Glendale Municipal Airport',
    city: 'Glendale',
    country: 'United States',
    iata: '',
    icao: 'KGEU',
    latitude: 33.5269012451172,
    longitude: -112.294998168945,
    altitude: 1071,
    schengen: false
  },
  {
    id: 6334,
    name: 'Grand Forks International Airport',
    city: 'Grand Forks',
    country: 'United States',
    iata: 'GFK',
    icao: 'KGFK',
    latitude: 47.949299,
    longitude: -97.176102,
    altitude: 845,
    schengen: false
  },
  {
    id: 6335,
    name: 'Floyd Bennett Memorial Airport',
    city: 'Queensbury',
    country: 'United States',
    iata: 'GFL',
    icao: 'KGFL',
    latitude: 43.3412017822,
    longitude: -73.6102981567,
    altitude: 328,
    schengen: false
  },
  {
    id: 6336,
    name: 'Georgetown County Airport',
    city: 'Georgetown',
    country: 'United States',
    iata: 'GGE',
    icao: 'KGGE',
    latitude: 33.3116989136,
    longitude: -79.3196029663,
    altitude: 39,
    schengen: false
  },
  {
    id: 6337,
    name: 'East Texas Regional Airport',
    city: 'Longview',
    country: 'United States',
    iata: 'GGG',
    icao: 'KGGG',
    latitude: 32.3839988708496,
    longitude: -94.7115020751953,
    altitude: 365,
    schengen: false
  },
  {
    id: 6338,
    name: 'Wokal Field Glasgow International Airport',
    city: 'Glasgow',
    country: 'United States',
    iata: 'GGW',
    icao: 'KGGW',
    latitude: 48.212502,
    longitude: -106.614998,
    altitude: 2296,
    schengen: false
  },
  {
    id: 6339,
    name: 'Marshfield Municipal George Harlow Field',
    city: 'Marshfield',
    country: 'United States',
    iata: '',
    icao: 'KGHG',
    latitude: 42.0983009338379,
    longitude: -70.6722030639648,
    altitude: 11,
    schengen: false
  },
  {
    id: 6340,
    name: 'Winter Haven Municipal Airport - Gilbert Field',
    city: 'Winter Haven',
    country: 'United States',
    iata: 'GIF',
    icao: 'KGIF',
    latitude: 28.06290054,
    longitude: -81.75330353,
    altitude: 145,
    schengen: false
  },
  {
    id: 6341,
    name: 'Grand Junction Regional Airport',
    city: 'Grand Junction',
    country: 'United States',
    iata: 'GJT',
    icao: 'KGJT',
    latitude: 39.1223983765,
    longitude: -108.527000427,
    altitude: 4858,
    schengen: false
  },
  {
    id: 6342,
    name: 'Arlington Municipal Airport',
    city: 'Arlington',
    country: 'United States',
    iata: '',
    icao: 'KGKY',
    latitude: 32.6638984680176,
    longitude: -97.0942993164062,
    altitude: 628,
    schengen: false
  },
  {
    id: 6343,
    name: 'Renner Field-Goodland Municipal Airport',
    city: 'Goodland',
    country: 'United States',
    iata: 'GLD',
    icao: 'KGLD',
    latitude: 39.37060165,
    longitude: -101.6989975,
    altitude: 3656,
    schengen: false
  },
  {
    id: 6344,
    name: 'Mid Delta Regional Airport',
    city: 'Greenville',
    country: 'United States',
    iata: 'GLH',
    icao: 'KGLH',
    latitude: 33.4828987121582,
    longitude: -90.9856033325195,
    altitude: 131,
    schengen: false
  },
  {
    id: 6345,
    name: 'Gaylord Regional Airport',
    city: 'GAYLORD',
    country: 'United States',
    iata: 'GLR',
    icao: 'KGLR',
    latitude: 45.0135002136,
    longitude: -84.7035980225,
    altitude: 1328,
    schengen: false
  },
  {
    id: 6346,
    name: 'Scholes International At Galveston Airport',
    city: 'Galveston',
    country: 'United States',
    iata: 'GLS',
    icao: 'KGLS',
    latitude: 29.2653007507324,
    longitude: -94.8603973388672,
    altitude: 6,
    schengen: false
  },
  {
    id: 6347,
    name: 'Glasgow Municipal Airport',
    city: 'Glasgow',
    country: 'United States',
    iata: 'GLW',
    icao: 'KGLW',
    latitude: 37.03179932,
    longitude: -85.9536972,
    altitude: 716,
    schengen: false
  },
  {
    id: 6348,
    name: 'Grove Municipal Airport',
    city: 'Grove',
    country: 'United States',
    iata: '',
    icao: 'KGMJ',
    latitude: 36.60680008,
    longitude: -94.73860168,
    altitude: 831,
    schengen: false
  },
  {
    id: 6349,
    name: 'Greenville Downtown Airport',
    city: 'Greenville',
    country: 'United States',
    iata: 'GMU',
    icao: 'KGMU',
    latitude: 34.8479003906,
    longitude: -82.3499984741,
    altitude: 1048,
    schengen: false
  },
  {
    id: 6350,
    name: 'Grants-Milan Municipal Airport',
    city: 'Grants',
    country: 'United States',
    iata: 'GNT',
    icao: 'KGNT',
    latitude: 35.167301178,
    longitude: -107.902000427,
    altitude: 6537,
    schengen: false
  },
  {
    id: 6351,
    name: 'Gainesville Regional Airport',
    city: 'Gainesville',
    country: 'United States',
    iata: 'GNV',
    icao: 'KGNV',
    latitude: 29.6900997162,
    longitude: -82.2717971802,
    altitude: 152,
    schengen: false
  },
  {
    id: 6352,
    name: 'Groton New London Airport',
    city: 'Groton CT',
    country: 'United States',
    iata: 'GON',
    icao: 'KGON',
    latitude: 41.3301010131836,
    longitude: -72.0450973510742,
    altitude: 9,
    schengen: false
  },
  {
    id: 6353,
    name: 'Glacier Park International Airport',
    city: 'Kalispell',
    country: 'United States',
    iata: 'FCA',
    icao: 'KGPI',
    latitude: 48.3105010986328,
    longitude: -114.255996704102,
    altitude: 2977,
    schengen: false
  },
  {
    id: 6354,
    name: 'Gulfport Biloxi International Airport',
    city: 'Gulfport',
    country: 'United States',
    iata: 'GPT',
    icao: 'KGPT',
    latitude: 30.4073009490967,
    longitude: -89.0700988769531,
    altitude: 28,
    schengen: false
  },
  {
    id: 6355,
    name: 'Grand Rapids Itasca Co-Gordon Newstrom field',
    city: 'Grand Rapids MN',
    country: 'United States',
    iata: 'GPZ',
    icao: 'KGPZ',
    latitude: 47.21110153,
    longitude: -93.50980377,
    altitude: 1355,
    schengen: false
  },
  {
    id: 6356,
    name: 'Galion Municipal Airport',
    city: 'Galion',
    country: 'United States',
    iata: 'GQQ',
    icao: 'KGQQ',
    latitude: 40.7533988953,
    longitude: -82.7238006592,
    altitude: 1224,
    schengen: false
  },
  {
    id: 6357,
    name: 'Austin Straubel International Airport',
    city: 'Green Bay',
    country: 'United States',
    iata: 'GRB',
    icao: 'KGRB',
    latitude: 44.4850997924805,
    longitude: -88.1296005249024,
    altitude: 695,
    schengen: false
  },
  {
    id: 6358,
    name: 'Gray Army Air Field',
    city: 'Fort Lewis',
    country: 'United States',
    iata: 'GRF',
    icao: 'KGRF',
    latitude: 47.07920074,
    longitude: -122.5810013,
    altitude: 300,
    schengen: false
  },
  {
    id: 6359,
    name: 'Central Nebraska Regional Airport',
    city: 'Grand Island',
    country: 'United States',
    iata: 'GRI',
    icao: 'KGRI',
    latitude: 40.9674987792969,
    longitude: -98.3096008300781,
    altitude: 1847,
    schengen: false
  },
  {
    id: 6360,
    name: 'Robert Gray  Army Air Field Airport',
    city: 'Killeen',
    country: 'United States',
    iata: 'GRK',
    icao: 'KGRK',
    latitude: 31.067199707,
    longitude: -97.8289031982,
    altitude: 1015,
    schengen: false
  },
  {
    id: 6361,
    name: 'Gerald R. Ford International Airport',
    city: 'Grand Rapids',
    country: 'United States',
    iata: 'GRR',
    icao: 'KGRR',
    latitude: 42.88079834,
    longitude: -85.52279663,
    altitude: 794,
    schengen: false
  },
  {
    id: 6362,
    name: 'Seymour Johnson Air Force Base',
    city: 'Goldsboro',
    country: 'United States',
    iata: 'GSB',
    icao: 'KGSB',
    latitude: 35.33940125,
    longitude: -77.96060181,
    altitude: 109,
    schengen: false
  },
  {
    id: 6363,
    name: 'Piedmont Triad International Airport',
    city: 'Greensboro',
    country: 'United States',
    iata: 'GSO',
    icao: 'KGSO',
    latitude: 36.0978012084961,
    longitude: -79.9373016357422,
    altitude: 925,
    schengen: false
  },
  {
    id: 6364,
    name: 'Greenville Spartanburg International Airport',
    city: 'Greenville',
    country: 'United States',
    iata: 'GSP',
    icao: 'KGSP',
    latitude: 34.8956985474,
    longitude: -82.2189025879,
    altitude: 964,
    schengen: false
  },
  {
    id: 6365,
    name: 'Wheeler Sack Army Air Field',
    city: 'Fort Drum',
    country: 'United States',
    iata: '',
    icao: 'KGTB',
    latitude: 44.05559921,
    longitude: -75.71949768,
    altitude: 688,
    schengen: false
  },
  {
    id: 6366,
    name: 'Great Falls International Airport',
    city: 'Great Falls',
    country: 'United States',
    iata: 'GTF',
    icao: 'KGTF',
    latitude: 47.48199844,
    longitude: -111.3710022,
    altitude: 3680,
    schengen: false
  },
  {
    id: 6367,
    name: 'Golden Triangle Regional Airport',
    city: 'Columbus Mississippi',
    country: 'United States',
    iata: 'GTR',
    icao: 'KGTR',
    latitude: 33.4502983093,
    longitude: -88.5914001465,
    altitude: 264,
    schengen: false
  },
  {
    id: 6368,
    name: 'Georgetown Municipal Airport',
    city: 'Georgetown',
    country: 'United States',
    iata: '',
    icao: 'KGTU',
    latitude: 30.6788005828857,
    longitude: -97.6793975830078,
    altitude: 790,
    schengen: false
  },
  {
    id: 6369,
    name: 'Gunnison Crested Butte Regional Airport',
    city: 'Gunnison',
    country: 'United States',
    iata: 'GUC',
    icao: 'KGUC',
    latitude: 38.53390121,
    longitude: -106.9329987,
    altitude: 7680,
    schengen: false
  },
  {
    id: 6370,
    name: 'Gallup Municipal Airport',
    city: 'Gallup',
    country: 'United States',
    iata: 'GUP',
    icao: 'KGUP',
    latitude: 35.511100769,
    longitude: -108.789001465,
    altitude: 6472,
    schengen: false
  },
  {
    id: 6371,
    name: 'Grissom Air Reserve Base',
    city: 'Peru',
    country: 'United States',
    iata: 'GUS',
    icao: 'KGUS',
    latitude: 40.6481018066,
    longitude: -86.1520996094,
    altitude: 812,
    schengen: false
  },
  {
    id: 6372,
    name: 'Guymon Municipal Airport',
    city: 'Guymon',
    country: 'United States',
    iata: 'GUY',
    icao: 'KGUY',
    latitude: 36.6851005554,
    longitude: -101.508003235,
    altitude: 3123,
    schengen: false
  },
  {
    id: 6373,
    name: 'Lee Gilmer Memorial Airport',
    city: 'Gainesville',
    country: 'United States',
    iata: 'GVL',
    icao: 'KGVL',
    latitude: 34.27259827,
    longitude: -83.8302002,
    altitude: 1276,
    schengen: false
  },
  {
    id: 6374,
    name: 'Genesee County Airport',
    city: 'Batavia',
    country: 'United States',
    iata: '',
    icao: 'KGVQ',
    latitude: 43.03170013,
    longitude: -78.16760254,
    altitude: 914,
    schengen: false
  },
  {
    id: 6375,
    name: 'Majors Airport',
    city: 'Greenvile',
    country: 'United States',
    iata: 'GVT',
    icao: 'KGVT',
    latitude: 33.0677986145,
    longitude: -96.0652999878,
    altitude: 535,
    schengen: false
  },
  {
    id: 6376,
    name: 'Greenwood–Leflore Airport',
    city: 'Greenwood',
    country: 'United States',
    iata: 'GWO',
    icao: 'KGWO',
    latitude: 33.4943008423,
    longitude: -90.0847015381,
    altitude: 162,
    schengen: false
  },
  {
    id: 6377,
    name: 'Greeley–Weld County Airport',
    city: 'Greeley',
    country: 'United States',
    iata: 'GXY',
    icao: 'KGXY',
    latitude: 40.4374008179,
    longitude: -104.633003235,
    altitude: 4697,
    schengen: false
  },
  {
    id: 6378,
    name: 'Donaldson Center Airport',
    city: 'Greenville',
    country: 'United States',
    iata: 'GDC',
    icao: 'KGYH',
    latitude: 34.7583007812,
    longitude: -82.3764038086,
    altitude: 955,
    schengen: false
  },
  {
    id: 6379,
    name: 'Phoenix Goodyear Airport',
    city: 'Goodyear',
    country: 'United States',
    iata: 'GYR',
    icao: 'KGYR',
    latitude: 33.4225006104,
    longitude: -112.375999451,
    altitude: 968,
    schengen: false
  },
  {
    id: 6380,
    name: 'Gary Chicago International Airport',
    city: 'Gary',
    country: 'United States',
    iata: 'GYY',
    icao: 'KGYY',
    latitude: 41.6162986755371,
    longitude: -87.4128036499024,
    altitude: 591,
    schengen: false
  },
  {
    id: 6381,
    name: 'Evergreen Regional Airport/Middleton Field',
    city: 'Evergreen',
    country: 'United States',
    iata: '',
    icao: 'KGZH',
    latitude: 31.4158,
    longitude: -87.043999,
    altitude: 259,
    schengen: false
  },
  {
    id: 6382,
    name: 'Half Moon Bay Airport',
    city: 'Half Moon Bay',
    country: 'United States',
    iata: 'HAF',
    icao: 'KHAF',
    latitude: 37.5134010315,
    longitude: -122.500999451,
    altitude: 66,
    schengen: false
  },
  {
    id: 6383,
    name: 'Butler Co Regional Airport - Hogan Field',
    city: 'Hamilton',
    country: 'United States',
    iata: 'HAO',
    icao: 'KHAO',
    latitude: 39.3638000488,
    longitude: -84.5220031738,
    altitude: 633,
    schengen: false
  },
  {
    id: 6384,
    name: 'Hattiesburg Bobby L Chain Municipal Airport',
    city: 'Hattiesburg',
    country: 'United States',
    iata: 'HBG',
    icao: 'KHBG',
    latitude: 31.26479912,
    longitude: -89.25279999,
    altitude: 151,
    schengen: false
  },
  {
    id: 6385,
    name: 'Asheboro Regional Airport',
    city: 'Asheboro',
    country: 'United States',
    iata: '',
    icao: 'KHBI',
    latitude: 35.65449905,
    longitude: -79.8946991,
    altitude: 671,
    schengen: false
  },
  {
    id: 6386,
    name: 'Hobart Regional Airport',
    city: 'Hobart',
    country: 'United States',
    iata: 'HBR',
    icao: 'KHBR',
    latitude: 34.991317,
    longitude: -99.051313,
    altitude: 1563,
    schengen: false
  },
  {
    id: 6387,
    name: 'Brewster Field',
    city: 'Holdredge',
    country: 'United States',
    iata: 'HDE',
    icao: 'KHDE',
    latitude: 40.452099,
    longitude: -99.336502,
    altitude: 2313,
    schengen: false
  },
  {
    id: 6388,
    name: 'Hardwick Field',
    city: 'Cleveland',
    country: 'United States',
    iata: 'HDI',
    icao: 'KHDI',
    latitude: 35.220100402832,
    longitude: -84.8323974609375,
    altitude: 874,
    schengen: false
  },
  {
    id: 6389,
    name: 'Yampa Valley Airport',
    city: 'Hayden',
    country: 'United States',
    iata: 'HDN',
    icao: 'KHDN',
    latitude: 40.48120117,
    longitude: -107.2180023,
    altitude: 6606,
    schengen: false
  },
  {
    id: 6390,
    name: 'South Texas Regional Airport at Hondo',
    city: 'Hondo',
    country: 'United States',
    iata: '',
    icao: 'KHDO',
    latitude: 29.35950088501,
    longitude: -99.176696777344,
    altitude: 930,
    schengen: false
  },
  {
    id: 6391,
    name: 'Manassas Regional Airport/Harry P. Davis Field',
    city: 'Manassas',
    country: 'United States',
    iata: 'MNZ',
    icao: 'KHEF',
    latitude: 38.72140121,
    longitude: -77.51540375,
    altitude: 192,
    schengen: false
  },
  {
    id: 6392,
    name: 'Hartford Brainard Airport',
    city: 'Hartford',
    country: 'United States',
    iata: 'HFD',
    icao: 'KHFD',
    latitude: 41.736698150635,
    longitude: -72.649398803711,
    altitude: 18,
    schengen: false
  },
  {
    id: 6393,
    name: 'Hagerstown Regional Richard A Henson Field',
    city: 'Hagerstown',
    country: 'United States',
    iata: 'HGR',
    icao: 'KHGR',
    latitude: 39.707901,
    longitude: -77.72949982,
    altitude: 703,
    schengen: false
  },
  {
    id: 6394,
    name: 'Jack Northrop Field Hawthorne Municipal Airport',
    city: 'Hawthorne',
    country: 'United States',
    iata: 'HHR',
    icao: 'KHHR',
    latitude: 33.922798,
    longitude: -118.334999,
    altitude: 66,
    schengen: false
  },
  {
    id: 6395,
    name: 'Range Regional Airport',
    city: 'Hibbing',
    country: 'United States',
    iata: 'HIB',
    icao: 'KHIB',
    latitude: 47.38660049,
    longitude: -92.83899689,
    altitude: 1354,
    schengen: false
  },
  {
    id: 6396,
    name: 'Hill Air Force Base',
    city: 'Ogden',
    country: 'United States',
    iata: 'HIF',
    icao: 'KHIF',
    latitude: 41.12403,
    longitude: -111.973086,
    altitude: 4789,
    schengen: false
  },
  {
    id: 6397,
    name: 'Lake Havasu City Airport',
    city: 'Lake Havasu City',
    country: 'United States',
    iata: 'HII',
    icao: 'KHII',
    latitude: 34.571098,
    longitude: -114.358002,
    altitude: 783,
    schengen: false
  },
  {
    id: 6398,
    name: 'Portland Hillsboro Airport',
    city: 'Hillsboro',
    country: 'United States',
    iata: 'HIO',
    icao: 'KHIO',
    latitude: 45.540401,
    longitude: -122.949997,
    altitude: 208,
    schengen: false
  },
  {
    id: 6399,
    name: 'Hickory Regional Airport',
    city: 'Hickory',
    country: 'United States',
    iata: 'HKY',
    icao: 'KHKY',
    latitude: 35.74110031,
    longitude: -81.38950348,
    altitude: 1190,
    schengen: false
  },
  {
    id: 6400,
    name: 'Wheeling Ohio County Airport',
    city: 'Wheeling',
    country: 'United States',
    iata: 'HLG',
    icao: 'KHLG',
    latitude: 40.1749992371,
    longitude: -80.6463012695,
    altitude: 1195,
    schengen: false
  },
  {
    id: 6401,
    name: 'Helena Regional Airport',
    city: 'Helena',
    country: 'United States',
    iata: 'HLN',
    icao: 'KHLN',
    latitude: 46.6068000793457,
    longitude: -111.983001708984,
    altitude: 3877,
    schengen: false
  },
  {
    id: 6402,
    name: 'Hood Army Air Field',
    city: 'Fort Hood',
    country: 'United States',
    iata: 'HLR',
    icao: 'KHLR',
    latitude: 31.1387004852,
    longitude: -97.7145004272,
    altitude: 924,
    schengen: false
  },
  {
    id: 6403,
    name: 'Holloman Air Force Base',
    city: 'Alamogordo',
    country: 'United States',
    iata: 'HMN',
    icao: 'KHMN',
    latitude: 32.8525009155,
    longitude: -106.107002258,
    altitude: 4093,
    schengen: false
  },
  {
    id: 6404,
    name: 'Bedford County Airport',
    city: 'Bedford',
    country: 'United States',
    iata: '',
    icao: 'KHMZ',
    latitude: 40.08530045,
    longitude: -78.5121994,
    altitude: 1162,
    schengen: false
  },
  {
    id: 6405,
    name: 'Henderson Executive Airport',
    city: 'Henderson',
    country: 'United States',
    iata: 'HSH',
    icao: 'KHND',
    latitude: 35.9728012085,
    longitude: -115.134002686,
    altitude: 2492,
    schengen: false
  },
  {
    id: 6406,
    name: 'Henderson Oxford Airport',
    city: 'Oxford',
    country: 'United States',
    iata: '',
    icao: 'KHNZ',
    latitude: 36.36159897,
    longitude: -78.52919769,
    altitude: 526,
    schengen: false
  },
  {
    id: 6407,
    name: 'Lea County Regional Airport',
    city: 'Hobbs',
    country: 'United States',
    iata: 'HOB',
    icao: 'KHOB',
    latitude: 32.6875,
    longitude: -103.2170029,
    altitude: 3661,
    schengen: false
  },
  {
    id: 6408,
    name: 'Huron Regional Airport',
    city: 'Huron',
    country: 'United States',
    iata: 'HON',
    icao: 'KHON',
    latitude: 44.3852005004883,
    longitude: -98.2285003662109,
    altitude: 1289,
    schengen: false
  },
  {
    id: 6409,
    name: 'Campbell AAF (Fort Campbell) Air Field',
    city: 'Hopkinsville',
    country: 'United States',
    iata: 'HOP',
    icao: 'KHOP',
    latitude: 36.668598175,
    longitude: -87.4962005615,
    altitude: 573,
    schengen: false
  },
  {
    id: 6410,
    name: 'Memorial Field',
    city: 'Hot Springs',
    country: 'United States',
    iata: 'HOT',
    icao: 'KHOT',
    latitude: 34.4780006408691,
    longitude: -93.0961990356445,
    altitude: 540,
    schengen: false
  },
  {
    id: 6411,
    name: 'William P Hobby Airport',
    city: 'Houston',
    country: 'United States',
    iata: 'HOU',
    icao: 'KHOU',
    latitude: 29.64539909,
    longitude: -95.27890015,
    altitude: 46,
    schengen: false
  },
  {
    id: 6412,
    name: 'Westchester County Airport',
    city: 'White Plains',
    country: 'United States',
    iata: 'HPN',
    icao: 'KHPN',
    latitude: 41.0670013427734,
    longitude: -73.7076034545899,
    altitude: 439,
    schengen: false
  },
  {
    id: 6413,
    name: 'Bowerman Airport',
    city: 'Hoquiam',
    country: 'United States',
    iata: 'HQM',
    icao: 'KHQM',
    latitude: 46.9711990356,
    longitude: -123.93699646,
    altitude: 18,
    schengen: false
  },
  {
    id: 6414,
    name: 'Thomson-McDuffie County Airport',
    city: 'Thomson',
    country: 'United States',
    iata: '',
    icao: 'KHQU',
    latitude: 33.52970123,
    longitude: -82.51650238,
    altitude: 501,
    schengen: false
  },
  {
    id: 6415,
    name: 'Mesquite Metro Airport',
    city: 'Misquite',
    country: 'United States',
    iata: '',
    icao: 'KHQZ',
    latitude: 32.7470016479492,
    longitude: -96.530403137207,
    altitude: 447,
    schengen: false
  },
  {
    id: 6416,
    name: 'Valley International Airport',
    city: 'Harlingen',
    country: 'United States',
    iata: 'HRL',
    icao: 'KHRL',
    latitude: 26.2285003662109,
    longitude: -97.6544036865234,
    altitude: 36,
    schengen: false
  },
  {
    id: 6417,
    name: 'Boone County Airport',
    city: 'Harrison',
    country: 'United States',
    iata: 'HRO',
    icao: 'KHRO',
    latitude: 36.2615013122559,
    longitude: -93.1547012329102,
    altitude: 1365,
    schengen: false
  },
  {
    id: 6418,
    name: 'Hurlburt Field',
    city: 'Mary Esther',
    country: 'United States',
    iata: '',
    icao: 'KHRT',
    latitude: 30.4277992248535,
    longitude: -86.6893005371094,
    altitude: 38,
    schengen: false
  },
  {
    id: 6419,
    name: 'Stennis International Airport',
    city: 'Bay St. Louis',
    country: 'United States',
    iata: '',
    icao: 'KHSA',
    latitude: 30.3677997589111,
    longitude: -89.4545974731445,
    altitude: 23,
    schengen: false
  },
  {
    id: 6420,
    name: 'Homestead ARB Airport',
    city: 'Homestead',
    country: 'United States',
    iata: 'HST',
    icao: 'KHST',
    latitude: 25.48859978,
    longitude: -80.38359833,
    altitude: 5,
    schengen: false
  },
  {
    id: 6421,
    name: 'Huntsville International Carl T Jones Field',
    city: 'Huntsville',
    country: 'United States',
    iata: 'HSV',
    icao: 'KHSV',
    latitude: 34.637199401855,
    longitude: -86.775100708008,
    altitude: 629,
    schengen: false
  },
  {
    id: 6422,
    name: 'Roscommon County - Blodgett Memorial Airport',
    city: 'Houghton Lake',
    country: 'United States',
    iata: 'HTL',
    icao: 'KHTL',
    latitude: 44.359798,
    longitude: -84.671095,
    altitude: 1150,
    schengen: false
  },
  {
    id: 6423,
    name: 'Tri-State/Milton J. Ferguson Field',
    city: 'Huntington',
    country: 'United States',
    iata: 'HTS',
    icao: 'KHTS',
    latitude: 38.36669922,
    longitude: -82.55799866,
    altitude: 828,
    schengen: false
  },
  {
    id: 6424,
    name: 'Redstone Army Air Field',
    city: 'Redstone',
    country: 'United States',
    iata: 'HUA',
    icao: 'KHUA',
    latitude: 34.67869949,
    longitude: -86.68479919,
    altitude: 684,
    schengen: false
  },
  {
    id: 6425,
    name: 'Terre Haute International Hulman Field',
    city: 'Terre Haute',
    country: 'United States',
    iata: 'HUF',
    icao: 'KHUF',
    latitude: 39.4514999389648,
    longitude: -87.3076019287109,
    altitude: 589,
    schengen: false
  },
  {
    id: 6426,
    name: 'Houlton International Airport',
    city: 'Houlton',
    country: 'United States',
    iata: 'HUL',
    icao: 'KHUL',
    latitude: 46.1231002808,
    longitude: -67.792098999,
    altitude: 489,
    schengen: false
  },
  {
    id: 6427,
    name: 'Hutchinson Municipal Airport',
    city: 'Hutchinson',
    country: 'United States',
    iata: 'HUT',
    icao: 'KHUT',
    latitude: 38.0654983521,
    longitude: -97.8606033325,
    altitude: 1543,
    schengen: false
  },
  {
    id: 6428,
    name: 'Tweed New Haven Airport',
    city: 'New Haven',
    country: 'United States',
    iata: 'HVN',
    icao: 'KHVN',
    latitude: 41.26369858,
    longitude: -72.88680267,
    altitude: 12,
    schengen: false
  },
  {
    id: 6429,
    name: 'Havre City County Airport',
    city: 'Havre',
    country: 'United States',
    iata: 'HVR',
    icao: 'KHVR',
    latitude: 48.54299927,
    longitude: -109.762001,
    altitude: 2591,
    schengen: false
  },
  {
    id: 6430,
    name: 'Hartsville Regional Airport',
    city: 'Hartsville',
    country: 'United States',
    iata: 'HVS',
    icao: 'KHVS',
    latitude: 34.4030990601,
    longitude: -80.1192016602,
    altitude: 364,
    schengen: false
  },
  {
    id: 6431,
    name: 'Hayward Executive Airport',
    city: 'Hayward',
    country: 'United States',
    iata: 'HWD',
    icao: 'KHWD',
    latitude: 37.659198761,
    longitude: -122.122001648,
    altitude: 52,
    schengen: false
  },
  {
    id: 6432,
    name: 'North Perry Airport',
    city: 'Hollywood',
    country: 'United States',
    iata: 'HWO',
    icao: 'KHWO',
    latitude: 26.0012,
    longitude: -80.2407,
    altitude: 8,
    schengen: false
  },
  {
    id: 6433,
    name: 'Hilton Head Airport',
    city: 'Hilton Head Island',
    country: 'United States',
    iata: 'HHH',
    icao: 'KHXD',
    latitude: 32.2243995667,
    longitude: -80.6975021362,
    altitude: 19,
    schengen: false
  },
  {
    id: 6434,
    name: 'Barnstable Municipal Boardman Polando Field',
    city: 'Barnstable',
    country: 'United States',
    iata: 'HYA',
    icao: 'KHYA',
    latitude: 41.66930008,
    longitude: -70.28040314,
    altitude: 54,
    schengen: false
  },
  {
    id: 6435,
    name: 'San Marcos Regional Airport',
    city: 'San Marcos',
    country: 'United States',
    iata: '',
    icao: 'KHYI',
    latitude: 29.8927,
    longitude: -97.862999,
    altitude: 597,
    schengen: false
  },
  {
    id: 6436,
    name: 'Sawyer County Airport',
    city: 'Hayward',
    country: 'United States',
    iata: 'HYR',
    icao: 'KHYR',
    latitude: 46.0251998901,
    longitude: -91.4442977905,
    altitude: 1216,
    schengen: false
  },
  {
    id: 6437,
    name: 'Hays Regional Airport',
    city: 'Hays',
    country: 'United States',
    iata: 'HYS',
    icao: 'KHYS',
    latitude: 38.84220123,
    longitude: -99.27320099,
    altitude: 1999,
    schengen: false
  },
  {
    id: 6438,
    name: 'Conway Horry County Airport',
    city: 'Conway',
    country: 'United States',
    iata: '',
    icao: 'KHYW',
    latitude: 33.82849884,
    longitude: -79.12220001,
    altitude: 35,
    schengen: false
  },
  {
    id: 6439,
    name: 'Hazleton Municipal Airport',
    city: 'Hazleton',
    country: 'United States',
    iata: 'HZL',
    icao: 'KHZL',
    latitude: 40.9868011475,
    longitude: -75.9949035645,
    altitude: 1603,
    schengen: false
  },
  {
    id: 6440,
    name: 'Kee Field',
    city: 'Pineville',
    country: 'United States',
    iata: '',
    icao: 'KI16',
    latitude: 37.600399017334,
    longitude: -81.5593032836914,
    altitude: 1783,
    schengen: false
  },
  {
    id: 6441,
    name: 'Mc Connell Air Force Base',
    city: 'Wichita',
    country: 'United States',
    iata: 'IAB',
    icao: 'KIAB',
    latitude: 37.62189865,
    longitude: -97.26820374,
    altitude: 1371,
    schengen: false
  },
  {
    id: 6442,
    name: 'Washington Dulles International Airport',
    city: 'Washington',
    country: 'United States',
    iata: 'IAD',
    icao: 'KIAD',
    latitude: 38.94449997,
    longitude: -77.45580292,
    altitude: 312,
    schengen: false
  },
  {
    id: 6443,
    name: 'Niagara Falls International Airport',
    city: 'Niagara Falls',
    country: 'United States',
    iata: 'IAG',
    icao: 'KIAG',
    latitude: 43.1072998046875,
    longitude: -78.9461975097656,
    altitude: 589,
    schengen: false
  },
  {
    id: 6444,
    name: 'George Bush Intercontinental Houston Airport',
    city: 'Houston',
    country: 'United States',
    iata: 'IAH',
    icao: 'KIAH',
    latitude: 29.9843997955322,
    longitude: -95.3414001464844,
    altitude: 97,
    schengen: false
  },
  {
    id: 6445,
    name: 'Wichita Eisenhower National Airport',
    city: 'Wichita',
    country: 'United States',
    iata: 'ICT',
    icao: 'KICT',
    latitude: 37.649899,
    longitude: -97.433098,
    altitude: 1333,
    schengen: false
  },
  {
    id: 6446,
    name: 'Idaho Falls Regional Airport',
    city: 'Idaho Falls',
    country: 'United States',
    iata: 'IDA',
    icao: 'KIDA',
    latitude: 43.514599,
    longitude: -112.070999,
    altitude: 4744,
    schengen: false
  },
  {
    id: 6447,
    name: 'Indianola Municipal Airport',
    city: 'New York',
    country: 'United States',
    iata: '',
    icao: 'KIDL',
    latitude: 33.485699,
    longitude: -90.678902,
    altitude: 126,
    schengen: false
  },
  {
    id: 6448,
    name: 'Independence Municipal Airport',
    city: 'Independence',
    country: 'United States',
    iata: 'IDP',
    icao: 'KIDP',
    latitude: 37.1584014893,
    longitude: -95.7783966064,
    altitude: 825,
    schengen: false
  },
  {
    id: 6449,
    name: 'Lansing Municipal Airport',
    city: 'Lansing',
    country: 'United States',
    iata: '',
    icao: 'KIGQ',
    latitude: 41.5349006652832,
    longitude: -87.5295028686523,
    altitude: 620,
    schengen: false
  },
  {
    id: 6450,
    name: 'Horace Williams Airport',
    city: 'Chapel Hill',
    country: 'United States',
    iata: '',
    icao: 'KIGX',
    latitude: 35.935001,
    longitude: -79.065902,
    altitude: 512,
    schengen: false
  },
  {
    id: 6451,
    name: 'Windham Airport',
    city: 'Willimantic',
    country: 'United States',
    iata: '',
    icao: 'KIJD',
    latitude: 41.7439994812012,
    longitude: -72.1802978515625,
    altitude: 247,
    schengen: false
  },
  {
    id: 6452,
    name: 'Greater Kankakee Airport',
    city: 'Kankakee',
    country: 'United States',
    iata: 'IKK',
    icao: 'KIKK',
    latitude: 41.0713996887,
    longitude: -87.8462982178,
    altitude: 630,
    schengen: false
  },
  {
    id: 6453,
    name: 'Ankeny Regional Airport',
    city: 'Ankeny',
    country: 'United States',
    iata: '',
    icao: 'KIKV',
    latitude: 41.6913986206055,
    longitude: -93.5663986206055,
    altitude: 910,
    schengen: false
  },
  {
    id: 6454,
    name: 'New Castle Airport',
    city: 'Wilmington',
    country: 'United States',
    iata: 'ILG',
    icao: 'KILG',
    latitude: 39.67869949,
    longitude: -75.60649872,
    altitude: 80,
    schengen: false
  },
  {
    id: 6455,
    name: 'Wilmington International Airport',
    city: 'Wilmington',
    country: 'United States',
    iata: 'ILM',
    icao: 'KILM',
    latitude: 34.2705993652344,
    longitude: -77.9026031494141,
    altitude: 32,
    schengen: false
  },
  {
    id: 6456,
    name: 'Wilmington Airpark',
    city: 'Wilmington',
    country: 'United States',
    iata: 'ILN',
    icao: 'KILN',
    latitude: 39.427898407,
    longitude: -83.792098999,
    altitude: 1077,
    schengen: false
  },
  {
    id: 6457,
    name: 'Immokalee Regional Airport',
    city: 'Immokalee ',
    country: 'United States',
    iata: 'IMM',
    icao: 'KIMM',
    latitude: 26.43320084,
    longitude: -81.40100098,
    altitude: 37,
    schengen: false
  },
  {
    id: 6458,
    name: 'Ford Airport',
    city: 'Iron Mountain',
    country: 'United States',
    iata: 'IMT',
    icao: 'KIMT',
    latitude: 45.8184013367,
    longitude: -88.1145019531,
    altitude: 1182,
    schengen: false
  },
  {
    id: 6459,
    name: 'Indianapolis International Airport',
    city: 'Indianapolis',
    country: 'United States',
    iata: 'IND',
    icao: 'KIND',
    latitude: 39.7173,
    longitude: -86.294403,
    altitude: 797,
    schengen: false
  },
  {
    id: 6460,
    name: 'Hillsboro Municipal Airport',
    city: 'Hillsboro',
    country: 'United States',
    iata: '',
    icao: 'KINJ',
    latitude: 32.08349991,
    longitude: -97.09719849,
    altitude: 686,
    schengen: false
  },
  {
    id: 6461,
    name: 'Winkler County Airport',
    city: 'Wink',
    country: 'United States',
    iata: 'INK',
    icao: 'KINK',
    latitude: 31.7796001434,
    longitude: -103.200996399,
    altitude: 2822,
    schengen: false
  },
  {
    id: 6462,
    name: 'Falls International Airport',
    city: 'International Falls',
    country: 'United States',
    iata: 'INL',
    icao: 'KINL',
    latitude: 48.5662002563477,
    longitude: -93.4030990600586,
    altitude: 1185,
    schengen: false
  },
  {
    id: 6463,
    name: 'Creech Air Force Base',
    city: 'Indian Springs',
    country: 'United States',
    iata: 'INS',
    icao: 'KINS',
    latitude: 36.5872001648,
    longitude: -115.672996521,
    altitude: 3133,
    schengen: false
  },
  {
    id: 6464,
    name: 'Smith Reynolds Airport',
    city: 'Winston-salem',
    country: 'United States',
    iata: 'INT',
    icao: 'KINT',
    latitude: 36.1337013244629,
    longitude: -80.2220001220703,
    altitude: 969,
    schengen: false
  },
  {
    id: 6465,
    name: 'Winslow Lindbergh Regional Airport',
    city: 'Winslow',
    country: 'United States',
    iata: 'INW',
    icao: 'KINW',
    latitude: 35.021900177,
    longitude: -110.722999573,
    altitude: 4941,
    schengen: false
  },
  {
    id: 6466,
    name: 'Mount Sterling Montgomery County Airport',
    city: 'Mount Sterling',
    country: 'United States',
    iata: '',
    icao: 'KIOB',
    latitude: 38.05810165,
    longitude: -83.979599,
    altitude: 1019,
    schengen: false
  },
  {
    id: 6467,
    name: 'Iowa City Municipal Airport',
    city: 'Iowa City',
    country: 'United States',
    iata: 'IOW',
    icao: 'KIOW',
    latitude: 41.6391983032,
    longitude: -91.5465011597,
    altitude: 668,
    schengen: false
  },
  {
    id: 6468,
    name: 'Imperial County Airport',
    city: 'Imperial',
    country: 'United States',
    iata: 'IPL',
    icao: 'KIPL',
    latitude: 32.8342018127,
    longitude: -115.57900238,
    altitude: -54,
    schengen: false
  },
  {
    id: 6469,
    name: 'Williamsport Regional Airport',
    city: 'Williamsport',
    country: 'United States',
    iata: 'IPT',
    icao: 'KIPT',
    latitude: 41.2417984008789,
    longitude: -76.9210968017578,
    altitude: 529,
    schengen: false
  },
  {
    id: 6470,
    name: 'Kirksville Regional Airport',
    city: 'Kirksville',
    country: 'United States',
    iata: 'IRK',
    icao: 'KIRK',
    latitude: 40.0934982299805,
    longitude: -92.5448989868164,
    altitude: 966,
    schengen: false
  },
  {
    id: 6471,
    name: 'Kissimmee Gateway Airport',
    city: 'Kissimmee',
    country: 'United States',
    iata: 'ISM',
    icao: 'KISM',
    latitude: 28.2898006439,
    longitude: -81.4371032715,
    altitude: 82,
    schengen: false
  },
  {
    id: 6472,
    name: 'Sloulin Field International Airport',
    city: 'Williston',
    country: 'United States',
    iata: 'ISN',
    icao: 'KISN',
    latitude: 48.177898407,
    longitude: -103.641998291,
    altitude: 1982,
    schengen: false
  },
  {
    id: 6473,
    name: 'Kinston Regional Jetport At Stallings Field',
    city: 'Kinston',
    country: 'United States',
    iata: 'ISO',
    icao: 'KISO',
    latitude: 35.331401825,
    longitude: -77.6088027954,
    altitude: 93,
    schengen: false
  },
  {
    id: 6474,
    name: 'Long Island Mac Arthur Airport',
    city: 'Islip',
    country: 'United States',
    iata: 'ISP',
    icao: 'KISP',
    latitude: 40.79520035,
    longitude: -73.10019684,
    altitude: 99,
    schengen: false
  },
  {
    id: 6475,
    name: 'Alexander Field South Wood County Airport',
    city: 'Wisconsin Rapids',
    country: 'United States',
    iata: 'ISW',
    icao: 'KISW',
    latitude: 44.3602981567,
    longitude: -89.8389968872,
    altitude: 1021,
    schengen: false
  },
  {
    id: 6476,
    name: 'Ithaca Tompkins Regional Airport',
    city: 'Ithaca',
    country: 'United States',
    iata: 'ITH',
    icao: 'KITH',
    latitude: 42.4910011291504,
    longitude: -76.4583969116211,
    altitude: 1099,
    schengen: false
  },
  {
    id: 6477,
    name: 'Phoenix-Mesa-Gateway Airport',
    city: 'Mesa',
    country: 'United States',
    iata: 'AZA',
    icao: 'KIWA',
    latitude: 33.30780029,
    longitude: -111.6549988,
    altitude: 1382,
    schengen: false
  },
  {
    id: 6478,
    name: 'West Houston Airport',
    city: 'Houston',
    country: 'United States',
    iata: 'IWS',
    icao: 'KIWS',
    latitude: 29.8181991577,
    longitude: -95.6725997925,
    altitude: 111,
    schengen: false
  },
  {
    id: 6479,
    name: 'New Century Aircenter Airport',
    city: 'Olathe',
    country: 'United States',
    iata: 'JCI',
    icao: 'KIXD',
    latitude: 38.8308982849,
    longitude: -94.890296936,
    altitude: 1087,
    schengen: false
  },
  {
    id: 6480,
    name: 'Inyokern Airport',
    city: 'Inyokern',
    country: 'United States',
    iata: 'IYK',
    icao: 'KIYK',
    latitude: 35.65879822,
    longitude: -117.8300018,
    altitude: 2457,
    schengen: false
  },
  {
    id: 6481,
    name: 'Santa Ynez Airport',
    city: 'Santa Ynez',
    country: 'United States',
    iata: 'SQA',
    icao: 'KIZA',
    latitude: 34.60680008,
    longitude: -120.0759964,
    altitude: 674,
    schengen: false
  },
  {
    id: 6482,
    name: 'Eastern Slopes Regional Airport',
    city: 'Fryeburg',
    country: 'United States',
    iata: 'FRY',
    icao: 'KIZG',
    latitude: 43.9911003113,
    longitude: -70.9478988647,
    altitude: 454,
    schengen: false
  },
  {
    id: 6483,
    name: 'Jackson Hole Airport',
    city: 'Jacksn Hole',
    country: 'United States',
    iata: 'JAC',
    icao: 'KJAC',
    latitude: 43.6072998046875,
    longitude: -110.737998962402,
    altitude: 6451,
    schengen: false
  },
  {
    id: 6484,
    name: 'Jackson-Medgar Wiley Evers International Airport',
    city: 'Jackson',
    country: 'United States',
    iata: 'JAN',
    icao: 'KJAN',
    latitude: 32.3111991882,
    longitude: -90.0758972168,
    altitude: 346,
    schengen: false
  },
  {
    id: 6485,
    name: 'Jasper County Airport-Bell Field',
    city: 'Jasper',
    country: 'United States',
    iata: 'JAS',
    icao: 'KJAS',
    latitude: 30.88570023,
    longitude: -94.03489685,
    altitude: 213,
    schengen: false
  },
  {
    id: 6486,
    name: 'Jacksonville International Airport',
    city: 'Jacksonville',
    country: 'United States',
    iata: 'JAX',
    icao: 'KJAX',
    latitude: 30.4941005706787,
    longitude: -81.6878967285156,
    altitude: 30,
    schengen: false
  },
  {
    id: 6487,
    name: 'Jonesboro Municipal Airport',
    city: 'Jonesboro',
    country: 'United States',
    iata: 'JBR',
    icao: 'KJBR',
    latitude: 35.8316993713379,
    longitude: -90.6464004516602,
    altitude: 262,
    schengen: false
  },
  {
    id: 6488,
    name: 'Kimble County Airport',
    city: 'Junction',
    country: 'United States',
    iata: 'JCT',
    icao: 'KJCT',
    latitude: 30.5112991333,
    longitude: -99.7634963989,
    altitude: 1749,
    schengen: false
  },
  {
    id: 6489,
    name: 'Jefferson City Memorial Airport',
    city: 'Jefferson City',
    country: 'United States',
    iata: 'JEF',
    icao: 'KJEF',
    latitude: 38.5912017822,
    longitude: -92.1560974121,
    altitude: 549,
    schengen: false
  },
  {
    id: 6490,
    name: 'Jesup Wayne County Airport',
    city: 'Jesup',
    country: 'United States',
    iata: '',
    icao: 'KJES',
    latitude: 31.55400085,
    longitude: -81.88249969,
    altitude: 107,
    schengen: false
  },
  {
    id: 6491,
    name: 'John F Kennedy International Airport',
    city: 'New York',
    country: 'United States',
    iata: 'JFK',
    icao: 'KJFK',
    latitude: 40.63980103,
    longitude: -73.77890015,
    altitude: 13,
    schengen: false
  },
  {
    id: 6492,
    name: 'Williamsburg Jamestown Airport',
    city: 'Williamsburg',
    country: 'United States',
    iata: '',
    icao: 'KJGG',
    latitude: 37.239200592041,
    longitude: -76.7161026000977,
    altitude: 49,
    schengen: false
  },
  {
    id: 6493,
    name: 'Chautauqua County-Jamestown Airport',
    city: 'Jamestown',
    country: 'United States',
    iata: 'JHW',
    icao: 'KJHW',
    latitude: 42.15340042,
    longitude: -79.25800323,
    altitude: 1723,
    schengen: false
  },
  {
    id: 6494,
    name: 'Jack Edwards Airport',
    city: 'Gulf Shores',
    country: 'United States',
    iata: 'GUF',
    icao: 'KJKA',
    latitude: 30.29050064,
    longitude: -87.67179871,
    altitude: 17,
    schengen: false
  },
  {
    id: 6495,
    name: 'Julian Carroll Airport',
    city: 'Jackson',
    country: 'United States',
    iata: '',
    icao: 'KJKL',
    latitude: 37.5938987731934,
    longitude: -83.3172988891602,
    altitude: 1381,
    schengen: false
  },
  {
    id: 6496,
    name: 'Joplin Regional Airport',
    city: 'Joplin',
    country: 'United States',
    iata: 'JLN',
    icao: 'KJLN',
    latitude: 37.151798248291,
    longitude: -94.4982986450195,
    altitude: 981,
    schengen: false
  },
  {
    id: 6497,
    name: 'Jamestown Regional Airport',
    city: 'Jamestown',
    country: 'United States',
    iata: 'JMS',
    icao: 'KJMS',
    latitude: 46.92969894,
    longitude: -98.67819977,
    altitude: 1500,
    schengen: false
  },
  {
    id: 6498,
    name: 'Joliet Regional Airport',
    city: 'Joliet',
    country: 'United States',
    iata: 'JOT',
    icao: 'KJOT',
    latitude: 41.51779938,
    longitude: -88.17549896,
    altitude: 582,
    schengen: false
  },
  {
    id: 6499,
    name: 'Concord Regional Airport',
    city: 'Concord',
    country: 'United States',
    iata: 'USA',
    icao: 'KJQF',
    latitude: 35.387798,
    longitude: -80.709099,
    altitude: 705,
    schengen: false
  },
  {
    id: 6500,
    name: 'John Murtha Johnstown Cambria County Airport',
    city: 'Johnstown',
    country: 'United States',
    iata: 'JST',
    icao: 'KJST',
    latitude: 40.3161010742187,
    longitude: -78.8339004516602,
    altitude: 2284,
    schengen: false
  },
  {
    id: 6501,
    name: 'Sallisaw Municipal Airport',
    city: 'Sallisaw',
    country: 'United States',
    iata: '',
    icao: 'KJSV',
    latitude: 35.4382019,
    longitude: -94.80280304,
    altitude: 527,
    schengen: false
  },
  {
    id: 6502,
    name: 'Southern Wisconsin Regional Airport',
    city: 'Janesville',
    country: 'United States',
    iata: 'JVL',
    icao: 'KJVL',
    latitude: 42.620300293,
    longitude: -89.0416030884,
    altitude: 808,
    schengen: false
  },
  {
    id: 6503,
    name: 'John C Tune Airport',
    city: 'Nashville',
    country: 'United States',
    iata: '',
    icao: 'KJWN',
    latitude: 36.1823997497559,
    longitude: -86.8867034912109,
    altitude: 495,
    schengen: false
  },
  {
    id: 6504,
    name: 'Jackson County Reynolds Field',
    city: 'Jackson',
    country: 'United States',
    iata: 'JXN',
    icao: 'KJXN',
    latitude: 42.2597999573,
    longitude: -84.4593963623,
    altitude: 1001,
    schengen: false
  },
  {
    id: 6505,
    name: 'Plantation Airpark',
    city: 'Sylvania',
    country: 'United States',
    iata: '',
    icao: 'KJYL',
    latitude: 32.6453018188477,
    longitude: -81.5970993041992,
    altitude: 188,
    schengen: false
  },
  {
    id: 6506,
    name: 'Leesburg Executive Airport',
    city: 'Leesburg',
    country: 'United States',
    iata: '',
    icao: 'KJYO',
    latitude: 39.077999,
    longitude: -77.557503,
    altitude: 389,
    schengen: false
  },
  {
    id: 6507,
    name: 'Charleston Executive Airport',
    city: 'Charleston',
    country: 'United States',
    iata: '',
    icao: 'KJZI',
    latitude: 32.7009010314941,
    longitude: -80.0028991699219,
    altitude: 17,
    schengen: false
  },
  {
    id: 6508,
    name: 'Pickens County Airport',
    city: 'Jasper',
    country: 'United States',
    iata: '',
    icao: 'KJZP',
    latitude: 34.4533996582031,
    longitude: -84.4573974609375,
    altitude: 1535,
    schengen: false
  },
  {
    id: 6509,
    name: 'Sabetha Municipal Airport',
    city: 'Sabetha',
    country: 'United States',
    iata: '',
    icao: 'KK83',
    latitude: 39.9042015075684,
    longitude: -95.7794036865234,
    altitude: 1330,
    schengen: false
  },
  {
    id: 6510,
    name: 'Southwest Washington Regional Airport',
    city: 'Kelso',
    country: 'United States',
    iata: 'KLS',
    icao: 'KKLS',
    latitude: 46.1180000305,
    longitude: -122.898002625,
    altitude: 20,
    schengen: false
  },
  {
    id: 6511,
    name: 'Lamar Municipal Airport',
    city: 'Lamar',
    country: 'United States',
    iata: 'LAA',
    icao: 'KLAA',
    latitude: 38.0696983337,
    longitude: -102.68800354,
    altitude: 3706,
    schengen: false
  },
  {
    id: 6512,
    name: 'Purdue University Airport',
    city: 'Lafayette',
    country: 'United States',
    iata: 'LAF',
    icao: 'KLAF',
    latitude: 40.4123001098633,
    longitude: -86.936897277832,
    altitude: 606,
    schengen: false
  },
  {
    id: 6513,
    name: 'Lakeland Linder Regional Airport',
    city: 'Lakeland',
    country: 'United States',
    iata: 'LAL',
    icao: 'KLAL',
    latitude: 27.988899231,
    longitude: -82.0186004639,
    altitude: 142,
    schengen: false
  },
  {
    id: 6514,
    name: 'Los Alamos Airport',
    city: 'Los Alamos',
    country: 'United States',
    iata: 'LAM',
    icao: 'KLAM',
    latitude: 35.8797988892,
    longitude: -106.268997192,
    altitude: 7171,
    schengen: false
  },
  {
    id: 6515,
    name: 'Capital City Airport',
    city: 'Lansing',
    country: 'United States',
    iata: 'LAN',
    icao: 'KLAN',
    latitude: 42.7787017822266,
    longitude: -84.58740234375,
    altitude: 861,
    schengen: false
  },
  {
    id: 6516,
    name: 'Laramie Regional Airport',
    city: 'Laramie',
    country: 'United States',
    iata: 'LAR',
    icao: 'KLAR',
    latitude: 41.3120994567871,
    longitude: -105.675003051758,
    altitude: 7284,
    schengen: false
  },
  {
    id: 6517,
    name: 'McCarran International Airport',
    city: 'Las Vegas',
    country: 'United States',
    iata: 'LAS',
    icao: 'KLAS',
    latitude: 36.08010101,
    longitude: -115.1520004,
    altitude: 2181,
    schengen: false
  },
  {
    id: 6518,
    name: 'Lawton Fort Sill Regional Airport',
    city: 'Lawton',
    country: 'United States',
    iata: 'LAW',
    icao: 'KLAW',
    latitude: 34.5676994324,
    longitude: -98.4166030884,
    altitude: 1110,
    schengen: false
  },
  {
    id: 6519,
    name: 'Los Angeles International Airport',
    city: 'Los Angeles',
    country: 'United States',
    iata: 'LAX',
    icao: 'KLAX',
    latitude: 33.94250107,
    longitude: -118.4079971,
    altitude: 125,
    schengen: false
  },
  {
    id: 6520,
    name: 'Lubbock Preston Smith International Airport',
    city: 'Lubbock',
    country: 'United States',
    iata: 'LBB',
    icao: 'KLBB',
    latitude: 33.663601,
    longitude: -101.822998,
    altitude: 3282,
    schengen: false
  },
  {
    id: 6521,
    name: 'Arnold Palmer Regional Airport',
    city: 'Latrobe',
    country: 'United States',
    iata: 'LBE',
    icao: 'KLBE',
    latitude: 40.27590179,
    longitude: -79.40480042,
    altitude: 1199,
    schengen: false
  },
  {
    id: 6522,
    name: 'North Platte Regional Airport Lee Bird Field',
    city: 'North Platte',
    country: 'United States',
    iata: 'LBF',
    icao: 'KLBF',
    latitude: 41.12620163,
    longitude: -100.6839981,
    altitude: 2777,
    schengen: false
  },
  {
    id: 6523,
    name: 'Liberal Mid-America Regional Airport',
    city: 'Liberal',
    country: 'United States',
    iata: 'LBL',
    icao: 'KLBL',
    latitude: 37.0442009,
    longitude: -100.9599991,
    altitude: 2885,
    schengen: false
  },
  {
    id: 6524,
    name: 'Lumberton Regional Airport',
    city: 'Lumberton',
    country: 'United States',
    iata: 'LBT',
    icao: 'KLBT',
    latitude: 34.6099014282,
    longitude: -79.0594024658,
    altitude: 126,
    schengen: false
  },
  {
    id: 6525,
    name: 'Texas Gulf Coast Regional Airport',
    city: 'Angleton',
    country: 'United States',
    iata: 'LJN',
    icao: 'KLBX',
    latitude: 29.1086006165,
    longitude: -95.462097168,
    altitude: 25,
    schengen: false
  },
  {
    id: 6526,
    name: 'Lake Charles Regional Airport',
    city: 'Lake Charles',
    country: 'United States',
    iata: 'LCH',
    icao: 'KLCH',
    latitude: 30.1261005401611,
    longitude: -93.2232971191406,
    altitude: 15,
    schengen: false
  },
  {
    id: 6527,
    name: 'Rickenbacker International Airport',
    city: 'Columbus',
    country: 'United States',
    iata: 'LCK',
    icao: 'KLCK',
    latitude: 39.813801,
    longitude: -82.927803,
    altitude: 744,
    schengen: false
  },
  {
    id: 6528,
    name: 'Lake City Gateway Airport',
    city: 'Lake City',
    country: 'United States',
    iata: 'LCQ',
    icao: 'KLCQ',
    latitude: 30.1819992065,
    longitude: -82.5768966675,
    altitude: 201,
    schengen: false
  },
  {
    id: 6529,
    name: 'Linden Airport',
    city: 'Linden',
    country: 'United States',
    iata: 'LDJ',
    icao: 'KLDJ',
    latitude: 40.617401123,
    longitude: -74.2445983887,
    altitude: 23,
    schengen: false
  },
  {
    id: 6530,
    name: 'Mason County Airport',
    city: 'Ludington',
    country: 'United States',
    iata: 'LDM',
    icao: 'KLDM',
    latitude: 43.96250153,
    longitude: -86.40789795,
    altitude: 646,
    schengen: false
  },
  {
    id: 6531,
    name: 'Lebanon Municipal Airport',
    city: 'Lebanon',
    country: 'United States',
    iata: 'LEB',
    icao: 'KLEB',
    latitude: 43.6260986328,
    longitude: -72.3041992188,
    altitude: 603,
    schengen: false
  },
  {
    id: 6532,
    name: 'Leesburg International Airport',
    city: 'Leesburg',
    country: 'United States',
    iata: 'LEE',
    icao: 'KLEE',
    latitude: 28.82309914,
    longitude: -81.80870056,
    altitude: 76,
    schengen: false
  },
  {
    id: 6533,
    name: 'Auburn Lewiston Municipal Airport',
    city: 'Lewiston',
    country: 'United States',
    iata: 'LEW',
    icao: 'KLEW',
    latitude: 44.048500061,
    longitude: -70.2835006714,
    altitude: 288,
    schengen: false
  },
  {
    id: 6534,
    name: 'Blue Grass Airport',
    city: 'Lexington KY',
    country: 'United States',
    iata: 'LEX',
    icao: 'KLEX',
    latitude: 38.0364990234375,
    longitude: -84.6059036254883,
    altitude: 979,
    schengen: false
  },
  {
    id: 6535,
    name: 'Langley Air Force Base',
    city: 'Hampton',
    country: 'United States',
    iata: 'LFI',
    icao: 'KLFI',
    latitude: 37.082901001,
    longitude: -76.360496521,
    altitude: 11,
    schengen: false
  },
  {
    id: 6536,
    name: 'Angelina County Airport',
    city: 'Lufkin',
    country: 'United States',
    iata: 'LFK',
    icao: 'KLFK',
    latitude: 31.2339992523,
    longitude: -94.75,
    altitude: 296,
    schengen: false
  },
  {
    id: 6537,
    name: 'Lafayette Regional Airport',
    city: 'Lafayette',
    country: 'United States',
    iata: 'LFT',
    icao: 'KLFT',
    latitude: 30.20529938,
    longitude: -91.98760223,
    altitude: 42,
    schengen: false
  },
  {
    id: 6538,
    name: 'La Guardia Airport',
    city: 'New York',
    country: 'United States',
    iata: 'LGA',
    icao: 'KLGA',
    latitude: 40.77719879,
    longitude: -73.87259674,
    altitude: 21,
    schengen: false
  },
  {
    id: 6539,
    name: 'Long Beach /Daugherty Field/ Airport',
    city: 'Long Beach',
    country: 'United States',
    iata: 'LGB',
    icao: 'KLGB',
    latitude: 33.81769943,
    longitude: -118.1520004,
    altitude: 60,
    schengen: false
  },
  {
    id: 6540,
    name: 'Lagrange Callaway Airport',
    city: 'LaGrange',
    country: 'United States',
    iata: 'LGC',
    icao: 'KLGC',
    latitude: 33.0088996887,
    longitude: -85.0726013184,
    altitude: 693,
    schengen: false
  },
  {
    id: 6541,
    name: 'La Grande/Union County Airport',
    city: 'La Grande',
    country: 'United States',
    iata: 'LGD',
    icao: 'KLGD',
    latitude: 45.2901992798,
    longitude: -118.007003784,
    altitude: 2717,
    schengen: false
  },
  {
    id: 6542,
    name: 'Logan-Cache Airport',
    city: 'Logan',
    country: 'United States',
    iata: 'LGU',
    icao: 'KLGU',
    latitude: 41.7911987305,
    longitude: -111.851997375,
    altitude: 4457,
    schengen: false
  },
  {
    id: 6543,
    name: 'Lincoln Regional Karl Harder Field',
    city: 'Lincoln',
    country: 'United States',
    iata: '',
    icao: 'KLHM',
    latitude: 38.9091987609863,
    longitude: -121.350997924805,
    altitude: 121,
    schengen: false
  },
  {
    id: 6544,
    name: 'William T. Piper Memorial Airport',
    city: 'Lock Haven',
    country: 'United States',
    iata: 'LHV',
    icao: 'KLHV',
    latitude: 41.13560104,
    longitude: -77.42230225,
    altitude: 556,
    schengen: false
  },
  {
    id: 6545,
    name: 'Wright AAF (Fort Stewart)/Midcoast Regional Airport',
    city: 'Wright',
    country: 'United States',
    iata: 'LIY',
    icao: 'KLHW',
    latitude: 31.889099,
    longitude: -81.562303,
    altitude: 45,
    schengen: false
  },
  {
    id: 6546,
    name: 'La Junta Municipal Airport',
    city: 'La Junta',
    country: 'United States',
    iata: '',
    icao: 'KLHX',
    latitude: 38.04970169,
    longitude: -103.5090027,
    altitude: 4229,
    schengen: false
  },
  {
    id: 6547,
    name: 'Bill & Hillary Clinton National Airport/Adams Field',
    city: 'Little Rock',
    country: 'United States',
    iata: 'LIT',
    icao: 'KLIT',
    latitude: 34.7294006348,
    longitude: -92.2242965698,
    altitude: 262,
    schengen: false
  },
  {
    id: 6548,
    name: 'Lake Placid Airport',
    city: 'Lake Placid',
    country: 'United States',
    iata: 'LKP',
    icao: 'KLKP',
    latitude: 44.2644996643,
    longitude: -73.9618988037,
    altitude: 1747,
    schengen: false
  },
  {
    id: 6549,
    name: 'Lake County Airport',
    city: 'Lakeview',
    country: 'United States',
    iata: 'LKV',
    icao: 'KLKV',
    latitude: 42.1610984802,
    longitude: -120.399002075,
    altitude: 4733,
    schengen: false
  },
  {
    id: 6550,
    name: 'Monticello Municipal Ellis Field',
    city: 'Monticello',
    country: 'United States',
    iata: '',
    icao: 'KLLQ',
    latitude: 33.6385994,
    longitude: -91.75099945,
    altitude: 270,
    schengen: false
  },
  {
    id: 6551,
    name: 'Crater Lake-Klamath Regional Airport',
    city: 'Klamath Falls',
    country: 'United States',
    iata: 'LMT',
    icao: 'KLMT',
    latitude: 42.156101,
    longitude: -121.733002,
    altitude: 4095,
    schengen: false
  },
  {
    id: 6552,
    name: 'Palm Beach County Park Airport',
    city: 'West Palm Beach',
    country: 'United States',
    iata: 'LNA',
    icao: 'KLNA',
    latitude: 26.59300041,
    longitude: -80.08509827,
    altitude: 14,
    schengen: false
  },
  {
    id: 6553,
    name: 'Lincoln Airport',
    city: 'Lincoln',
    country: 'United States',
    iata: 'LNK',
    icao: 'KLNK',
    latitude: 40.851001739502,
    longitude: -96.7592010498047,
    altitude: 1219,
    schengen: false
  },
  {
    id: 6554,
    name: 'Willoughby Lost Nation Municipal Airport',
    city: 'Willoughby',
    country: 'United States',
    iata: 'LNN',
    icao: 'KLNN',
    latitude: 41.6839981079,
    longitude: -81.3897018433,
    altitude: 626,
    schengen: false
  },
  {
    id: 6555,
    name: 'Tri-County Regional Airport',
    city: 'Lone Rock',
    country: 'United States',
    iata: 'LNR',
    icao: 'KLNR',
    latitude: 43.2117004395,
    longitude: -90.181602478,
    altitude: 717,
    schengen: false
  },
  {
    id: 6556,
    name: 'Lancaster Airport',
    city: 'Lancaster',
    country: 'United States',
    iata: 'LNS',
    icao: 'KLNS',
    latitude: 40.1217002868652,
    longitude: -76.2960968017578,
    altitude: 403,
    schengen: false
  },
  {
    id: 6557,
    name: 'Derby Field',
    city: 'Lovelock',
    country: 'United States',
    iata: 'LOL',
    icao: 'KLOL',
    latitude: 40.0663986206,
    longitude: -118.565002441,
    altitude: 3904,
    schengen: false
  },
  {
    id: 6558,
    name: 'Wings Field',
    city: 'Philadelphia',
    country: 'United States',
    iata: 'BBX',
    icao: 'KLOM',
    latitude: 40.1375007629,
    longitude: -75.2650985718,
    altitude: 302,
    schengen: false
  },
  {
    id: 6559,
    name: 'Lewis University Airport',
    city: 'Lockport',
    country: 'United States',
    iata: 'LOT',
    icao: 'KLOT',
    latitude: 41.6072998,
    longitude: -88.09619904,
    altitude: 679,
    schengen: false
  },
  {
    id: 6560,
    name: 'Bowman Field',
    city: 'Louisville',
    country: 'United States',
    iata: 'LOU',
    icao: 'KLOU',
    latitude: 38.2280006409,
    longitude: -85.6636962891,
    altitude: 546,
    schengen: false
  },
  {
    id: 6561,
    name: 'London-Corbin Airport/Magee Field',
    city: 'London',
    country: 'United States',
    iata: 'LOZ',
    icao: 'KLOZ',
    latitude: 37.0821990967,
    longitude: -84.0848999023,
    altitude: 1212,
    schengen: false
  },
  {
    id: 6562,
    name: 'Lompoc Airport',
    city: 'Lompoc',
    country: 'United States',
    iata: 'LPC',
    icao: 'KLPC',
    latitude: 34.665599823,
    longitude: -120.468002319,
    altitude: 88,
    schengen: false
  },
  {
    id: 6563,
    name: 'Lorain County Regional Airport',
    city: 'Lorain-Elyria',
    country: 'United States',
    iata: '',
    icao: 'KLPR',
    latitude: 41.34429932,
    longitude: -82.17759705,
    altitude: 793,
    schengen: false
  },
  {
    id: 6564,
    name: 'Larned Pawnee County Airport',
    city: 'Larned',
    country: 'United States',
    iata: '',
    icao: 'KLQR',
    latitude: 38.20859909,
    longitude: -99.08599854,
    altitude: 2012,
    schengen: false
  },
  {
    id: 6565,
    name: 'Laredo International Airport',
    city: 'Laredo',
    country: 'United States',
    iata: 'LRD',
    icao: 'KLRD',
    latitude: 27.5438003540039,
    longitude: -99.4616012573242,
    altitude: 508,
    schengen: false
  },
  {
    id: 6566,
    name: 'Little Rock Air Force Base',
    city: 'Jacksonville',
    country: 'United States',
    iata: 'LRF',
    icao: 'KLRF',
    latitude: 34.9169006348,
    longitude: -92.1496963501,
    altitude: 311,
    schengen: false
  },
  {
    id: 6567,
    name: 'Mt Pleasant Regional-Faison field',
    city: 'Mount Pleasant',
    country: 'United States',
    iata: '',
    icao: 'KLRO',
    latitude: 32.89780045,
    longitude: -79.78289795,
    altitude: 12,
    schengen: false
  },
  {
    id: 6568,
    name: 'Las Cruces International Airport',
    city: 'Las Cruces',
    country: 'United States',
    iata: 'LRU',
    icao: 'KLRU',
    latitude: 32.2893981933594,
    longitude: -106.921997070313,
    altitude: 4456,
    schengen: false
  },
  {
    id: 6569,
    name: 'La Crosse Municipal Airport',
    city: 'La Crosse',
    country: 'United States',
    iata: 'LSE',
    icao: 'KLSE',
    latitude: 43.879002,
    longitude: -91.256699,
    altitude: 655,
    schengen: false
  },
  {
    id: 6570,
    name: 'Lawson Army Air Field (Fort Benning)',
    city: 'Fort Benning',
    country: 'United States',
    iata: 'LSF',
    icao: 'KLSF',
    latitude: 32.3372993469,
    longitude: -84.9913024902,
    altitude: 232,
    schengen: false
  },
  {
    id: 6571,
    name: 'Nellis Air Force Base',
    city: 'Las Vegas',
    country: 'United States',
    iata: 'LSV',
    icao: 'KLSV',
    latitude: 36.2361984253,
    longitude: -115.033996582,
    altitude: 1870,
    schengen: false
  },
  {
    id: 6572,
    name: 'Altus Air Force Base',
    city: 'Altus',
    country: 'United States',
    iata: 'LTS',
    icao: 'KLTS',
    latitude: 34.667098999,
    longitude: -99.2667007446,
    altitude: 1382,
    schengen: false
  },
  {
    id: 6573,
    name: 'Luke Air Force Base',
    city: 'Phoenix',
    country: 'United States',
    iata: 'LUF',
    icao: 'KLUF',
    latitude: 33.5349998474,
    longitude: -112.383003235,
    altitude: 1085,
    schengen: false
  },
  {
    id: 6574,
    name: 'Cincinnati Municipal Airport Lunken Field',
    city: 'Cincinnati',
    country: 'United States',
    iata: 'LUK',
    icao: 'KLUK',
    latitude: 39.10329819,
    longitude: -84.41860199,
    altitude: 483,
    schengen: false
  },
  {
    id: 6575,
    name: 'Livermore Municipal Airport',
    city: 'Livermore',
    country: 'United States',
    iata: 'LVK',
    icao: 'KLVK',
    latitude: 37.6934013367,
    longitude: -121.819999695,
    altitude: 400,
    schengen: false
  },
  {
    id: 6576,
    name: 'Mission Field',
    city: 'Livingston-Montana',
    country: 'United States',
    iata: 'LVM',
    icao: 'KLVM',
    latitude: 45.6994018555,
    longitude: -110.447998047,
    altitude: 4660,
    schengen: false
  },
  {
    id: 6577,
    name: 'Las Vegas Municipal Airport',
    city: 'Las Vegas',
    country: 'United States',
    iata: 'LVS',
    icao: 'KLVS',
    latitude: 35.6542015076,
    longitude: -105.141998291,
    altitude: 6877,
    schengen: false
  },
  {
    id: 6578,
    name: 'South Haven Area Regional Airport',
    city: 'South Haven',
    country: 'United States',
    iata: '',
    icao: 'KLWA',
    latitude: 42.3512001037598,
    longitude: -86.2556991577148,
    altitude: 666,
    schengen: false
  },
  {
    id: 6579,
    name: 'Greenbrier Valley Airport',
    city: 'Lewisburg',
    country: 'United States',
    iata: 'LWB',
    icao: 'KLWB',
    latitude: 37.8582992554,
    longitude: -80.3994979858,
    altitude: 2302,
    schengen: false
  },
  {
    id: 6580,
    name: 'Lawrence Municipal Airport',
    city: 'Lawrence',
    country: 'United States',
    iata: 'LWC',
    icao: 'KLWC',
    latitude: 39.01119995,
    longitude: -95.21659851,
    altitude: 833,
    schengen: false
  },
  {
    id: 6581,
    name: 'Lawrence Municipal Airport',
    city: 'Lawrence',
    country: 'United States',
    iata: 'LWM',
    icao: 'KLWM',
    latitude: 42.7172012329,
    longitude: -71.1233978271,
    altitude: 148,
    schengen: false
  },
  {
    id: 6582,
    name: 'Lewiston Nez Perce County Airport',
    city: 'Lewiston',
    country: 'United States',
    iata: 'LWS',
    icao: 'KLWS',
    latitude: 46.3745002746582,
    longitude: -117.014999389648,
    altitude: 1442,
    schengen: false
  },
  {
    id: 6583,
    name: 'Lewistown Municipal Airport',
    city: 'Lewistown',
    country: 'United States',
    iata: 'LWT',
    icao: 'KLWT',
    latitude: 47.0493011474609,
    longitude: -109.467002868652,
    altitude: 4170,
    schengen: false
  },
  {
    id: 6584,
    name: 'Jim Kelly Field',
    city: 'Lexington',
    country: 'United States',
    iata: 'LXN',
    icao: 'KLXN',
    latitude: 40.7910003662,
    longitude: -99.7772979736,
    altitude: 2413,
    schengen: false
  },
  {
    id: 6585,
    name: 'Lynchburg Regional Preston Glenn Field',
    city: 'Lynchburg',
    country: 'United States',
    iata: 'LYH',
    icao: 'KLYH',
    latitude: 37.3266983032227,
    longitude: -79.2004013061523,
    altitude: 938,
    schengen: false
  },
  {
    id: 6586,
    name: 'Danielson Airport',
    city: 'Danielson',
    country: 'United States',
    iata: '',
    icao: 'KLZD',
    latitude: 41.8196983337402,
    longitude: -71.9010009765625,
    altitude: 238,
    schengen: false
  },
  {
    id: 6587,
    name: 'Gwinnett County Briscoe Field',
    city: 'Lawrenceville',
    country: 'United States',
    iata: 'LZU',
    icao: 'KLZU',
    latitude: 33.97809982,
    longitude: -83.96240234,
    altitude: 1061,
    schengen: false
  },
  {
    id: 6588,
    name: 'Lampasas Airport',
    city: 'Lampasas',
    country: 'United States',
    iata: '',
    icao: 'KLZZ',
    latitude: 31.1061992645264,
    longitude: -98.1958999633789,
    altitude: 1215,
    schengen: false
  },
  {
    id: 6589,
    name: 'General Dewitt Spain Airport',
    city: 'Memphis',
    country: 'United States',
    iata: '',
    icao: 'KM01',
    latitude: 35.20069885,
    longitude: -90.05400085,
    altitude: 225,
    schengen: false
  },
  {
    id: 6590,
    name: 'Desert Aire Regional Airport',
    city: 'Mattawa',
    country: 'United States',
    iata: '',
    icao: 'KM94',
    latitude: 46.687401,
    longitude: -119.920998,
    altitude: 586,
    schengen: false
  },
  {
    id: 6591,
    name: 'Madera Municipal Airport',
    city: 'Madera',
    country: 'United States',
    iata: 'MAE',
    icao: 'KMAE',
    latitude: 36.9886016846,
    longitude: -120.111999512,
    altitude: 255,
    schengen: false
  },
  {
    id: 6592,
    name: 'Midland International Airport',
    city: 'Midland',
    country: 'United States',
    iata: 'MAF',
    icao: 'KMAF',
    latitude: 31.9424991607666,
    longitude: -102.202003479004,
    altitude: 2871,
    schengen: false
  },
  {
    id: 6593,
    name: 'Mobridge Municipal Airport',
    city: 'Mobridge',
    country: 'United States',
    iata: 'MBG',
    icao: 'KMBG',
    latitude: 45.54650116,
    longitude: -100.4079971,
    altitude: 1716,
    schengen: false
  },
  {
    id: 6594,
    name: 'Manistee Co Blacker Airport',
    city: 'Manistee',
    country: 'United States',
    iata: 'MBL',
    icao: 'KMBL',
    latitude: 44.2723999,
    longitude: -86.24690247,
    altitude: 621,
    schengen: false
  },
  {
    id: 6595,
    name: 'MBS International Airport',
    city: 'Saginaw',
    country: 'United States',
    iata: 'MBS',
    icao: 'KMBS',
    latitude: 43.532901763916,
    longitude: -84.0795974731445,
    altitude: 668,
    schengen: false
  },
  {
    id: 6596,
    name: 'Mc Comb/Pike County Airport/John E Lewis Field',
    city: 'Mc Comb',
    country: 'United States',
    iata: 'MCB',
    icao: 'KMCB',
    latitude: 31.17849922,
    longitude: -90.47190094,
    altitude: 413,
    schengen: false
  },
  {
    id: 6597,
    name: 'Mc Clellan Airfield',
    city: 'Sacramento',
    country: 'United States',
    iata: 'MCC',
    icao: 'KMCC',
    latitude: 38.66759872,
    longitude: -121.401001,
    altitude: 77,
    schengen: false
  },
  {
    id: 6598,
    name: 'Merced Regional Macready Field',
    city: 'Merced',
    country: 'United States',
    iata: 'MCE',
    icao: 'KMCE',
    latitude: 37.28469849,
    longitude: -120.5139999,
    altitude: 155,
    schengen: false
  },
  {
    id: 6599,
    name: 'Mac Dill Air Force Base',
    city: 'Tampa',
    country: 'United States',
    iata: 'MCF',
    icao: 'KMCF',
    latitude: 27.84930038,
    longitude: -82.52120209,
    altitude: 14,
    schengen: false
  },
  {
    id: 6600,
    name: 'Kansas City International Airport',
    city: 'Kansas City',
    country: 'United States',
    iata: 'MCI',
    icao: 'KMCI',
    latitude: 39.2976,
    longitude: -94.713898,
    altitude: 1026,
    schengen: false
  },
  {
    id: 6601,
    name: 'Mc Cook Ben Nelson Regional Airport',
    city: 'McCook',
    country: 'United States',
    iata: 'MCK',
    icao: 'KMCK',
    latitude: 40.20629883,
    longitude: -100.5920029,
    altitude: 2583,
    schengen: false
  },
  {
    id: 6602,
    name: 'Middle Georgia Regional Airport',
    city: 'Macon',
    country: 'United States',
    iata: 'MCN',
    icao: 'KMCN',
    latitude: 32.692798614502,
    longitude: -83.6492004394531,
    altitude: 354,
    schengen: false
  },
  {
    id: 6603,
    name: 'Orlando International Airport',
    city: 'Orlando',
    country: 'United States',
    iata: 'MCO',
    icao: 'KMCO',
    latitude: 28.4293994903564,
    longitude: -81.3089981079102,
    altitude: 96,
    schengen: false
  },
  {
    id: 6604,
    name: 'Mason City Municipal Airport',
    city: 'Mason City',
    country: 'United States',
    iata: 'MCW',
    icao: 'KMCW',
    latitude: 43.1577987671,
    longitude: -93.3312988281,
    altitude: 1213,
    schengen: false
  },
  {
    id: 6605,
    name: 'Southern Illinois Airport',
    city: 'Carbondale/Murphysboro',
    country: 'United States',
    iata: 'MDH',
    icao: 'KMDH',
    latitude: 37.7780990600586,
    longitude: -89.2519989013672,
    altitude: 411,
    schengen: false
  },
  {
    id: 6606,
    name: 'Madison County Executive Airport-Tom Sharp Jr Field',
    city: 'Huntsville',
    country: 'United States',
    iata: '',
    icao: 'KMDQ',
    latitude: 34.8614006,
    longitude: -86.55750275,
    altitude: 756,
    schengen: false
  },
  {
    id: 6607,
    name: 'Harrisburg International Airport',
    city: 'Harrisburg',
    country: 'United States',
    iata: 'MDT',
    icao: 'KMDT',
    latitude: 40.1935005188,
    longitude: -76.7633972168,
    altitude: 310,
    schengen: false
  },
  {
    id: 6608,
    name: 'Chicago Midway International Airport',
    city: 'Chicago',
    country: 'United States',
    iata: 'MDW',
    icao: 'KMDW',
    latitude: 41.785999,
    longitude: -87.752403,
    altitude: 620,
    schengen: false
  },
  {
    id: 6609,
    name: 'Key Field',
    city: 'Meridian',
    country: 'United States',
    iata: 'MEI',
    icao: 'KMEI',
    latitude: 32.3325996398926,
    longitude: -88.7518997192383,
    altitude: 297,
    schengen: false
  },
  {
    id: 6610,
    name: 'Meade Municipal Airport',
    city: 'Meade',
    country: 'United States',
    iata: '',
    icao: 'KMEJ',
    latitude: 37.2769012451172,
    longitude: -100.356002807617,
    altitude: 2529,
    schengen: false
  },
  {
    id: 6611,
    name: 'Memphis International Airport',
    city: 'Memphis',
    country: 'United States',
    iata: 'MEM',
    icao: 'KMEM',
    latitude: 35.0424003601074,
    longitude: -89.9766998291016,
    altitude: 341,
    schengen: false
  },
  {
    id: 6612,
    name: 'Castle Airport',
    city: 'Merced',
    country: 'United States',
    iata: 'MER',
    icao: 'KMER',
    latitude: 37.38050079,
    longitude: -120.5680008,
    altitude: 191,
    schengen: false
  },
  {
    id: 6613,
    name: 'Mansfield Lahm Regional Airport',
    city: 'Mansfield',
    country: 'United States',
    iata: 'MFD',
    icao: 'KMFD',
    latitude: 40.8213996887,
    longitude: -82.5166015625,
    altitude: 1297,
    schengen: false
  },
  {
    id: 6614,
    name: 'Mc Allen Miller International Airport',
    city: 'Mcallen',
    country: 'United States',
    iata: 'MFE',
    icao: 'KMFE',
    latitude: 26.17580032,
    longitude: -98.23860168,
    altitude: 107,
    schengen: false
  },
  {
    id: 6615,
    name: 'Marshfield Municipal Airport',
    city: 'Marshfield',
    country: 'United States',
    iata: 'MFI',
    icao: 'KMFI',
    latitude: 44.6369018555,
    longitude: -90.1893005371,
    altitude: 1277,
    schengen: false
  },
  {
    id: 6616,
    name: 'Rogue Valley International Medford Airport',
    city: 'Medford',
    country: 'United States',
    iata: 'MFR',
    icao: 'KMFR',
    latitude: 42.3741989135742,
    longitude: -122.873001098633,
    altitude: 1335,
    schengen: false
  },
  {
    id: 6617,
    name: 'Michigan City Municipal Airport',
    city: 'Michigan City',
    country: 'United States',
    iata: 'MGC',
    icao: 'KMGC',
    latitude: 41.7033004761,
    longitude: -86.8211975098,
    altitude: 655,
    schengen: false
  },
  {
    id: 6618,
    name: 'Dobbins Air Reserve Base',
    city: 'Marietta',
    country: 'United States',
    iata: 'MGE',
    icao: 'KMGE',
    latitude: 33.91540146,
    longitude: -84.51629639,
    altitude: 1068,
    schengen: false
  },
  {
    id: 6619,
    name: 'Orange County Airport',
    city: 'Montgomery',
    country: 'United States',
    iata: 'MGJ',
    icao: 'KMGJ',
    latitude: 41.50999832,
    longitude: -74.26460266,
    altitude: 364,
    schengen: false
  },
  {
    id: 6620,
    name: 'Montgomery Regional (Dannelly Field) Airport',
    city: 'MONTGOMERY',
    country: 'United States',
    iata: 'MGM',
    icao: 'KMGM',
    latitude: 32.30059814,
    longitude: -86.39399719,
    altitude: 221,
    schengen: false
  },
  {
    id: 6621,
    name: 'Morgantown Municipal Walter L. Bill Hart Field',
    city: 'Morgantown',
    country: 'United States',
    iata: 'MGW',
    icao: 'KMGW',
    latitude: 39.64289856,
    longitude: -79.91629791,
    altitude: 1248,
    schengen: false
  },
  {
    id: 6622,
    name: 'Dayton-Wright Brothers Airport',
    city: 'Dayton',
    country: 'United States',
    iata: 'MGY',
    icao: 'KMGY',
    latitude: 39.5890007019,
    longitude: -84.224899292,
    altitude: 957,
    schengen: false
  },
  {
    id: 6623,
    name: 'Manhattan Regional Airport',
    city: 'Manhattan',
    country: 'United States',
    iata: 'MHK',
    icao: 'KMHK',
    latitude: 39.140998840332,
    longitude: -96.6707992553711,
    altitude: 1057,
    schengen: false
  },
  {
    id: 6624,
    name: 'Sacramento Mather Airport',
    city: 'Sacramento',
    country: 'United States',
    iata: 'MHR',
    icao: 'KMHR',
    latitude: 38.55390167,
    longitude: -121.2979965,
    altitude: 98,
    schengen: false
  },
  {
    id: 6625,
    name: 'Manchester-Boston Regional Airport',
    city: 'Manchester NH',
    country: 'United States',
    iata: 'MHT',
    icao: 'KMHT',
    latitude: 42.932598,
    longitude: -71.435699,
    altitude: 266,
    schengen: false
  },
  {
    id: 6626,
    name: 'Mojave Airport',
    city: 'Mojave',
    country: 'United States',
    iata: 'MHV',
    icao: 'KMHV',
    latitude: 35.05939865,
    longitude: -118.1520004,
    altitude: 2801,
    schengen: false
  },
  {
    id: 6627,
    name: 'Miami International Airport',
    city: 'Miami',
    country: 'United States',
    iata: 'MIA',
    icao: 'KMIA',
    latitude: 25.7931995391846,
    longitude: -80.2906036376953,
    altitude: 8,
    schengen: false
  },
  {
    id: 6628,
    name: 'Minot Air Force Base',
    city: 'Minot',
    country: 'United States',
    iata: 'MIB',
    icao: 'KMIB',
    latitude: 48.4156,
    longitude: -101.358002,
    altitude: 1667,
    schengen: false
  },
  {
    id: 6629,
    name: 'Crystal Airport',
    city: 'Crystal',
    country: 'United States',
    iata: '',
    icao: 'KMIC',
    latitude: 45.0620002746582,
    longitude: -93.3538970947266,
    altitude: 869,
    schengen: false
  },
  {
    id: 6630,
    name: 'Delaware County Johnson Field',
    city: 'Muncie',
    country: 'United States',
    iata: 'MIE',
    icao: 'KMIE',
    latitude: 40.2422981262207,
    longitude: -85.3958969116211,
    altitude: 937,
    schengen: false
  },
  {
    id: 6631,
    name: 'Millville Municipal Airport',
    city: 'Millville',
    country: 'United States',
    iata: 'MIV',
    icao: 'KMIV',
    latitude: 39.367802,
    longitude: -75.072197,
    altitude: 85,
    schengen: false
  },
  {
    id: 6632,
    name: 'Charles B. Wheeler Downtown Airport',
    city: 'Kansas City',
    country: 'United States',
    iata: 'MKC',
    icao: 'KMKC',
    latitude: 39.1231994628906,
    longitude: -94.5927963256836,
    altitude: 759,
    schengen: false
  },
  {
    id: 6633,
    name: 'General Mitchell International Airport',
    city: 'Milwaukee',
    country: 'United States',
    iata: 'MKE',
    icao: 'KMKE',
    latitude: 42.9472007751465,
    longitude: -87.896598815918,
    altitude: 723,
    schengen: false
  },
  {
    id: 6634,
    name: 'Muskegon County Airport',
    city: 'Muskegon',
    country: 'United States',
    iata: 'MKG',
    icao: 'KMKG',
    latitude: 43.16949844,
    longitude: -86.23819733,
    altitude: 629,
    schengen: false
  },
  {
    id: 6635,
    name: 'Mc Kellar Sipes Regional Airport',
    city: 'Jackson',
    country: 'United States',
    iata: 'MKL',
    icao: 'KMKL',
    latitude: 35.59989929,
    longitude: -88.91560364,
    altitude: 434,
    schengen: false
  },
  {
    id: 6636,
    name: 'Muskogee-Davis Regional Airport',
    city: 'Muskogee',
    country: 'United States',
    iata: '',
    icao: 'KMKO',
    latitude: 35.656502,
    longitude: -95.366699,
    altitude: 611,
    schengen: false
  },
  {
    id: 6637,
    name: 'Mankato Regional Airport',
    city: 'Mankato',
    country: 'United States',
    iata: '',
    icao: 'KMKT',
    latitude: 44.22159958,
    longitude: -93.91870117,
    altitude: 1021,
    schengen: false
  },
  {
    id: 6638,
    name: 'Marco Island Airport',
    city: 'Marco Island Airport',
    country: 'United States',
    iata: 'MRK',
    icao: 'KMKY',
    latitude: 25.9950008392,
    longitude: -81.6725006104,
    altitude: 5,
    schengen: false
  },
  {
    id: 6639,
    name: 'Melbourne International Airport',
    city: 'Melbourne',
    country: 'United States',
    iata: 'MLB',
    icao: 'KMLB',
    latitude: 28.1028003692627,
    longitude: -80.6453018188477,
    altitude: 33,
    schengen: false
  },
  {
    id: 6640,
    name: 'Mc Alester Regional Airport',
    city: 'Mcalester',
    country: 'United States',
    iata: 'MLC',
    icao: 'KMLC',
    latitude: 34.882401,
    longitude: -95.783501,
    altitude: 770,
    schengen: false
  },
  {
    id: 6641,
    name: 'Malad City Airport',
    city: 'Malad City',
    country: 'United States',
    iata: '',
    icao: 'KMLD',
    latitude: 42.1665992736816,
    longitude: -112.296997070313,
    altitude: 4503,
    schengen: false
  },
  {
    id: 6642,
    name: 'Quad City International Airport',
    city: 'Moline',
    country: 'United States',
    iata: 'MLI',
    icao: 'KMLI',
    latitude: 41.4485015869141,
    longitude: -90.5074996948242,
    altitude: 590,
    schengen: false
  },
  {
    id: 6643,
    name: 'Baldwin County Regional Airport',
    city: 'Milledgeville',
    country: 'United States',
    iata: '',
    icao: 'KMLJ',
    latitude: 33.154202,
    longitude: -83.240701,
    altitude: 385,
    schengen: false
  },
  {
    id: 6644,
    name: 'Frank Wiley Field',
    city: 'Miles City',
    country: 'United States',
    iata: 'MLS',
    icao: 'KMLS',
    latitude: 46.4280014038086,
    longitude: -105.886001586914,
    altitude: 2630,
    schengen: false
  },
  {
    id: 6645,
    name: 'Millinocket Municipal Airport',
    city: 'Millinocket',
    country: 'United States',
    iata: '',
    icao: 'KMLT',
    latitude: 45.6478004455566,
    longitude: -68.6856002807617,
    altitude: 408,
    schengen: false
  },
  {
    id: 6646,
    name: 'Monroe Regional Airport',
    city: 'Monroe',
    country: 'United States',
    iata: 'MLU',
    icao: 'KMLU',
    latitude: 32.5108985900879,
    longitude: -92.0376968383789,
    altitude: 79,
    schengen: false
  },
  {
    id: 6647,
    name: 'Mammoth Yosemite Airport',
    city: 'Mammoth Lakes',
    country: 'United States',
    iata: 'MMH',
    icao: 'KMMH',
    latitude: 37.62409973,
    longitude: -118.8379974,
    altitude: 7135,
    schengen: false
  },
  {
    id: 6648,
    name: 'McMinn County Airport',
    city: 'Athens',
    country: 'United States',
    iata: 'MMI',
    icao: 'KMMI',
    latitude: 35.39730072,
    longitude: -84.56259918,
    altitude: 875,
    schengen: false
  },
  {
    id: 6649,
    name: 'Meriden Markham Municipal Airport',
    city: 'Meriden',
    country: 'United States',
    iata: '',
    icao: 'KMMK',
    latitude: 41.5087013244629,
    longitude: -72.8294982910156,
    altitude: 103,
    schengen: false
  },
  {
    id: 6650,
    name: 'Mc Entire Joint National Guard Base',
    city: 'Eastover',
    country: 'United States',
    iata: 'MMT',
    icao: 'KMMT',
    latitude: 33.92079926,
    longitude: -80.80130005,
    altitude: 254,
    schengen: false
  },
  {
    id: 6651,
    name: 'Morristown Municipal Airport',
    city: 'Morristown',
    country: 'United States',
    iata: 'MMU',
    icao: 'KMMU',
    latitude: 40.7994003295898,
    longitude: -74.4149017333984,
    altitude: 187,
    schengen: false
  },
  {
    id: 6652,
    name: 'Mc Minnville Municipal Airport',
    city: 'Mackminnville',
    country: 'United States',
    iata: '',
    icao: 'KMMV',
    latitude: 45.19440079,
    longitude: -123.1360016,
    altitude: 163,
    schengen: false
  },
  {
    id: 6653,
    name: 'Menominee Regional Airport',
    city: 'Macon',
    country: 'United States',
    iata: 'MNM',
    icao: 'KMNM',
    latitude: 45.126701,
    longitude: -87.638397,
    altitude: 625,
    schengen: false
  },
  {
    id: 6654,
    name: 'Hamilton Municipal Airport',
    city: 'Hamilton',
    country: 'United States',
    iata: '',
    icao: 'KMNZ',
    latitude: 31.6658992767334,
    longitude: -98.1485977172852,
    altitude: 1299,
    schengen: false
  },
  {
    id: 6655,
    name: 'Mobile Regional Airport',
    city: 'Mobile',
    country: 'United States',
    iata: 'MOB',
    icao: 'KMOB',
    latitude: 30.691200256348,
    longitude: -88.242797851562,
    altitude: 219,
    schengen: false
  },
  {
    id: 6656,
    name: 'Modesto City Co-Harry Sham Field',
    city: 'Modesto',
    country: 'United States',
    iata: 'MOD',
    icao: 'KMOD',
    latitude: 37.62580109,
    longitude: -120.9540024,
    altitude: 97,
    schengen: false
  },
  {
    id: 6657,
    name: 'Minot International Airport',
    city: 'Minot',
    country: 'United States',
    iata: 'MOT',
    icao: 'KMOT',
    latitude: 48.2593994140625,
    longitude: -101.279998779297,
    altitude: 1716,
    schengen: false
  },
  {
    id: 6658,
    name: 'Mariposa Yosemite Airport',
    city: 'Mariposa',
    country: 'United States',
    iata: 'RMY',
    icao: 'KMPI',
    latitude: 37.5108985901,
    longitude: -120.040000916,
    altitude: 2254,
    schengen: false
  },
  {
    id: 6659,
    name: 'Pocono Mountains Municipal Airport',
    city: 'Mount Pocono',
    country: 'United States',
    iata: '',
    icao: 'KMPO',
    latitude: 41.13750076,
    longitude: -75.37889862,
    altitude: 1915,
    schengen: false
  },
  {
    id: 6660,
    name: 'Mc Pherson Airport',
    city: 'Mc Pherson',
    country: 'United States',
    iata: '',
    icao: 'KMPR',
    latitude: 38.35240173,
    longitude: -97.69129944,
    altitude: 1498,
    schengen: false
  },
  {
    id: 6661,
    name: 'Edward F Knapp State Airport',
    city: 'Montpelier',
    country: 'United States',
    iata: 'MPV',
    icao: 'KMPV',
    latitude: 44.20349884,
    longitude: -72.56230164,
    altitude: 1166,
    schengen: false
  },
  {
    id: 6662,
    name: 'Dare County Regional Airport',
    city: 'Manteo',
    country: 'United States',
    iata: 'MEO',
    icao: 'KMQI',
    latitude: 35.91899872,
    longitude: -75.69550323,
    altitude: 13,
    schengen: false
  },
  {
    id: 6663,
    name: 'Chester County G O Carlson Airport',
    city: 'Coatesville',
    country: 'United States',
    iata: 'CTH',
    icao: 'KMQS',
    latitude: 39.97900009,
    longitude: -75.8655014,
    altitude: 660,
    schengen: false
  },
  {
    id: 6664,
    name: 'Marquette Airport',
    city: 'Marquette',
    country: 'United States',
    iata: '',
    icao: 'KMQT',
    latitude: 46.5339012145996,
    longitude: -87.5614013671875,
    altitude: 1424,
    schengen: false
  },
  {
    id: 6665,
    name: 'Smyrna Airport',
    city: 'Smyrna',
    country: 'United States',
    iata: 'MQY',
    icao: 'KMQY',
    latitude: 36.0089988708,
    longitude: -86.5201034546,
    altitude: 543,
    schengen: false
  },
  {
    id: 6666,
    name: 'Eastern WV Regional Airport/Shepherd Field',
    city: 'Martinsburg',
    country: 'United States',
    iata: 'MRB',
    icao: 'KMRB',
    latitude: 39.40190125,
    longitude: -77.98459625,
    altitude: 565,
    schengen: false
  },
  {
    id: 6667,
    name: 'Marfa Municipal Airport',
    city: 'Marfa',
    country: 'United States',
    iata: 'MRF',
    icao: 'KMRF',
    latitude: 30.371099,
    longitude: -104.017997,
    altitude: 4849,
    schengen: false
  },
  {
    id: 6668,
    name: 'Iowa County Airport',
    city: 'MINERAL POINT',
    country: 'United States',
    iata: '',
    icao: 'KMRJ',
    latitude: 42.886799,
    longitude: -90.236198,
    altitude: 1171,
    schengen: false
  },
  {
    id: 6669,
    name: 'Foothills Regional Airport',
    city: 'Morganton',
    country: 'United States',
    iata: '',
    icao: 'KMRN',
    latitude: 35.820202,
    longitude: -81.611397,
    altitude: 1270,
    schengen: false
  },
  {
    id: 6670,
    name: 'Union County Airport',
    city: 'Marysville',
    country: 'United States',
    iata: '',
    icao: 'KMRT',
    latitude: 40.2247009277344,
    longitude: -83.3516006469727,
    altitude: 1021,
    schengen: false
  },
  {
    id: 6671,
    name: 'Monterey Peninsula Airport',
    city: 'Monterey',
    country: 'United States',
    iata: 'MRY',
    icao: 'KMRY',
    latitude: 36.5870018005371,
    longitude: -121.843002319336,
    altitude: 257,
    schengen: false
  },
  {
    id: 6672,
    name: 'Northwest Alabama Regional Airport',
    city: 'Muscle Shoals',
    country: 'United States',
    iata: 'MSL',
    icao: 'KMSL',
    latitude: 34.74530029,
    longitude: -87.61019897,
    altitude: 551,
    schengen: false
  },
  {
    id: 6673,
    name: 'Dane County Regional Truax Field',
    city: 'Madison',
    country: 'United States',
    iata: 'MSN',
    icao: 'KMSN',
    latitude: 43.1399002075195,
    longitude: -89.3375015258789,
    altitude: 887,
    schengen: false
  },
  {
    id: 6674,
    name: 'Missoula International Airport',
    city: 'Missoula',
    country: 'United States',
    iata: 'MSO',
    icao: 'KMSO',
    latitude: 46.91630173,
    longitude: -114.0910034,
    altitude: 3206,
    schengen: false
  },
  {
    id: 6675,
    name: 'Minneapolis-St Paul International/Wold-Chamberlain Airport',
    city: 'Minneapolis',
    country: 'United States',
    iata: 'MSP',
    icao: 'KMSP',
    latitude: 44.882,
    longitude: -93.221802,
    altitude: 841,
    schengen: false
  },
  {
    id: 6676,
    name: 'Massena International Richards Field',
    city: 'Massena',
    country: 'United States',
    iata: 'MSS',
    icao: 'KMSS',
    latitude: 44.9357986450195,
    longitude: -74.8455963134766,
    altitude: 215,
    schengen: false
  },
  {
    id: 6677,
    name: 'Louis Armstrong New Orleans International Airport',
    city: 'New Orleans',
    country: 'United States',
    iata: 'MSY',
    icao: 'KMSY',
    latitude: 29.9934005737305,
    longitude: -90.2580032348633,
    altitude: 4,
    schengen: false
  },
  {
    id: 6678,
    name: 'Selfridge Air National Guard Base Airport',
    city: 'Mount Clemens',
    country: 'United States',
    iata: 'MTC',
    icao: 'KMTC',
    latitude: 42.613463,
    longitude: -82.836919,
    altitude: 580,
    schengen: false
  },
  {
    id: 6679,
    name: 'The Florida Keys Marathon Airport',
    city: 'Marathon',
    country: 'United States',
    iata: 'MTH',
    icao: 'KMTH',
    latitude: 24.726101,
    longitude: -81.051399,
    altitude: 5,
    schengen: false
  },
  {
    id: 6680,
    name: 'Montrose Regional Airport',
    city: 'Montrose CO',
    country: 'United States',
    iata: 'MTJ',
    icao: 'KMTJ',
    latitude: 38.5097999573,
    longitude: -107.893997192,
    altitude: 5759,
    schengen: false
  },
  {
    id: 6681,
    name: 'Martin State Airport',
    city: 'Baltimore',
    country: 'United States',
    iata: 'MTN',
    icao: 'KMTN',
    latitude: 39.325699,
    longitude: -76.413803,
    altitude: 21,
    schengen: false
  },
  {
    id: 6682,
    name: 'Montauk Airport',
    city: 'Montauk',
    country: 'United States',
    iata: '',
    icao: 'KMTP',
    latitude: 41.0764999389648,
    longitude: -71.9207992553711,
    altitude: 6,
    schengen: false
  },
  {
    id: 6683,
    name: 'Blue Ridge Airport',
    city: 'Martinsville',
    country: 'United States',
    iata: '',
    icao: 'KMTV',
    latitude: 36.6306991577148,
    longitude: -80.0183029174805,
    altitude: 941,
    schengen: false
  },
  {
    id: 6684,
    name: 'Muir Army Air Field (Fort Indiantown Gap) Airport',
    city: 'Muir',
    country: 'United States',
    iata: '',
    icao: 'KMUI',
    latitude: 40.43479919,
    longitude: -76.56939697,
    altitude: 488,
    schengen: false
  },
  {
    id: 6685,
    name: 'Mountain Home Air Force Base',
    city: 'Mountain Home',
    country: 'United States',
    iata: 'MUO',
    icao: 'KMUO',
    latitude: 43.043598,
    longitude: -115.872002,
    altitude: 2996,
    schengen: false
  },
  {
    id: 6686,
    name: 'Morrisville Stowe State Airport',
    city: 'Morrisville',
    country: 'United States',
    iata: 'MVL',
    icao: 'KMVL',
    latitude: 44.5345993042,
    longitude: -72.6139984131,
    altitude: 732,
    schengen: false
  },
  {
    id: 6687,
    name: "Martha's Vineyard Airport",
    city: 'Vineyard Haven MA',
    country: 'United States',
    iata: 'MVY',
    icao: 'KMVY',
    latitude: 41.3931007385,
    longitude: -70.6143035889,
    altitude: 67,
    schengen: false
  },
  {
    id: 6688,
    name: 'Williamson County Regional Airport',
    city: 'Marion',
    country: 'United States',
    iata: 'MWA',
    icao: 'KMWA',
    latitude: 37.75500107,
    longitude: -89.01110077,
    altitude: 472,
    schengen: false
  },
  {
    id: 6689,
    name: 'Lawrence J Timmerman Airport',
    city: 'Milwaukee',
    country: 'United States',
    iata: 'MWC',
    icao: 'KMWC',
    latitude: 43.110401,
    longitude: -88.034401,
    altitude: 745,
    schengen: false
  },
  {
    id: 6690,
    name: 'Grant County International Airport',
    city: 'Grant County Airport',
    country: 'United States',
    iata: 'MWH',
    icao: 'KMWH',
    latitude: 47.20769882,
    longitude: -119.3199997,
    altitude: 1189,
    schengen: false
  },
  {
    id: 6691,
    name: 'Mount Airy Surry County Airport',
    city: 'Mount Airy',
    country: 'United States',
    iata: '',
    icao: 'KMWK',
    latitude: 36.45970154,
    longitude: -80.5530014,
    altitude: 1249,
    schengen: false
  },
  {
    id: 6692,
    name: 'Mineral Wells Airport',
    city: 'Mineral Wells',
    country: 'United States',
    iata: 'MWL',
    icao: 'KMWL',
    latitude: 32.7816009521,
    longitude: -98.0602035522,
    altitude: 974,
    schengen: false
  },
  {
    id: 6693,
    name: 'Windom Municipal Airport',
    city: 'Windom',
    country: 'United States',
    iata: '',
    icao: 'KMWM',
    latitude: 43.9133987426758,
    longitude: -95.1093978881836,
    altitude: 1410,
    schengen: false
  },
  {
    id: 6694,
    name: 'Maxwell Air Force Base',
    city: 'Montgomery',
    country: 'United States',
    iata: 'MXF',
    icao: 'KMXF',
    latitude: 32.3829,
    longitude: -86.365799,
    altitude: 171,
    schengen: false
  },
  {
    id: 6695,
    name: 'McCall Municipal Airport',
    city: 'McCall',
    country: 'United States',
    iata: 'MYL',
    icao: 'KMYL',
    latitude: 44.88970184,
    longitude: -116.1009979,
    altitude: 5024,
    schengen: false
  },
  {
    id: 6696,
    name: 'Myrtle Beach International Airport',
    city: 'Myrtle Beach',
    country: 'United States',
    iata: 'MYR',
    icao: 'KMYR',
    latitude: 33.6796989441,
    longitude: -78.9282989502,
    altitude: 25,
    schengen: false
  },
  {
    id: 6697,
    name: 'Yuba County Airport',
    city: 'Yuba City',
    country: 'United States',
    iata: 'MYV',
    icao: 'KMYV',
    latitude: 39.09780121,
    longitude: -121.5699997,
    altitude: 64,
    schengen: false
  },
  {
    id: 6698,
    name: 'Pinal Airpark',
    city: 'Marana',
    country: 'United States',
    iata: 'MZJ',
    icao: 'KMZJ',
    latitude: 32.5106010437,
    longitude: -111.32800293,
    altitude: 1893,
    schengen: false
  },
  {
    id: 6699,
    name: 'Beaufort MCAS - Merritt Field',
    city: 'Beaufort',
    country: 'United States',
    iata: '',
    icao: 'KNBC',
    latitude: 32.4774017334,
    longitude: -80.723197937,
    altitude: 37,
    schengen: false
  },
  {
    id: 6700,
    name: 'New Orleans NAS JRB/Alvin Callender Field',
    city: 'New Orleans',
    country: 'United States',
    iata: 'NBG',
    icao: 'KNBG',
    latitude: 29.82530022,
    longitude: -90.03500366,
    altitude: 2,
    schengen: false
  },
  {
    id: 6701,
    name: 'New River MCAS /H/ /Mccutcheon Fld/ Airport',
    city: 'Jacksonville',
    country: 'United States',
    iata: '',
    icao: 'KNCA',
    latitude: 34.70840073,
    longitude: -77.43969727,
    altitude: 26,
    schengen: false
  },
  {
    id: 6702,
    name: 'Lakehurst Maxfield Field Airport',
    city: 'Lakehurst',
    country: 'United States',
    iata: 'NEL',
    icao: 'KNEL',
    latitude: 40.03329849,
    longitude: -74.353302,
    altitude: 101,
    schengen: false
  },
  {
    id: 6703,
    name: 'Lakefront Airport',
    city: 'New Orleans',
    country: 'United States',
    iata: 'NEW',
    icao: 'KNEW',
    latitude: 30.042400360107,
    longitude: -90.028297424316,
    altitude: 8,
    schengen: false
  },
  {
    id: 6704,
    name: 'Camp Pendleton MCAS (Munn Field) Airport',
    city: 'Oceanside',
    country: 'United States',
    iata: '',
    icao: 'KNFG',
    latitude: 33.30130005,
    longitude: -117.3550034,
    altitude: 78,
    schengen: false
  },
  {
    id: 6705,
    name: 'Fallon Naval Air Station',
    city: 'Fallon',
    country: 'United States',
    iata: 'NFL',
    icao: 'KNFL',
    latitude: 39.41659927,
    longitude: -118.7009964,
    altitude: 3934,
    schengen: false
  },
  {
    id: 6706,
    name: 'NAS Fort Worth JRB/Carswell Field',
    city: 'Dallas',
    country: 'United States',
    iata: 'FWH',
    icao: 'KNFW',
    latitude: 32.76919937,
    longitude: -97.4414978,
    altitude: 650,
    schengen: false
  },
  {
    id: 6707,
    name: 'Corpus Christi Naval Air Station/Truax Field',
    city: 'Corpus Christi',
    country: 'United States',
    iata: '',
    icao: 'KNGP',
    latitude: 27.69260025,
    longitude: -97.29109955,
    altitude: 18,
    schengen: false
  },
  {
    id: 6708,
    name: 'Norfolk Naval Station (Chambers Field)',
    city: 'Norfolk',
    country: 'United States',
    iata: 'NGU',
    icao: 'KNGU',
    latitude: 36.937599,
    longitude: -76.289299,
    altitude: 17,
    schengen: false
  },
  {
    id: 6709,
    name: 'Alameda Naval Air Station',
    city: 'Alameda',
    country: 'United States',
    iata: 'NGZ',
    icao: 'KNGZ',
    latitude: 37.7888984680176,
    longitude: -122.319999694824,
    altitude: 10,
    schengen: false
  },
  {
    id: 6710,
    name: 'Patuxent River Naval Air Station (Trapnell Field)',
    city: 'Patuxent River',
    country: 'United States',
    iata: 'NHK',
    icao: 'KNHK',
    latitude: 38.285999,
    longitude: -76.411797,
    altitude: 39,
    schengen: false
  },
  {
    id: 6711,
    name: 'Brunswick Executive Airport',
    city: 'Brunswick',
    country: 'United States',
    iata: 'NHZ',
    icao: 'KNHZ',
    latitude: 43.89220047,
    longitude: -69.93859863,
    altitude: 72,
    schengen: false
  },
  {
    id: 6712,
    name: 'China Lake Naws (Armitage Field) Airport',
    city: 'China Lake',
    country: 'United States',
    iata: '',
    icao: 'KNID',
    latitude: 35.6853981,
    longitude: -117.6920013,
    altitude: 2283,
    schengen: false
  },
  {
    id: 6713,
    name: 'Jacksonville Naval Air Station (Towers Field)',
    city: 'Jacksonville',
    country: 'United States',
    iata: 'NIP',
    icao: 'KNIP',
    latitude: 30.2358,
    longitude: -81.680603,
    altitude: 21,
    schengen: false
  },
  {
    id: 6714,
    name: 'El Centro Naval Air Facility',
    city: 'El Centro',
    country: 'United States',
    iata: 'NJK',
    icao: 'KNJK',
    latitude: 32.829201,
    longitude: -115.671997,
    altitude: -42,
    schengen: false
  },
  {
    id: 6715,
    name: 'Cherry Point MCAS /Cunningham Field/',
    city: 'Cherry Point',
    country: 'United States',
    iata: '',
    icao: 'KNKT',
    latitude: 34.90090179,
    longitude: -76.88069916,
    altitude: 29,
    schengen: false
  },
  {
    id: 6716,
    name: 'Miramar Marine Corps Air Station - Mitscher Field',
    city: 'Miramar',
    country: 'United States',
    iata: 'NKX',
    icao: 'KNKX',
    latitude: 32.86840057,
    longitude: -117.1429977,
    altitude: 477,
    schengen: false
  },
  {
    id: 6717,
    name: 'Lemoore Naval Air Station (Reeves Field) Airport',
    city: 'Lemoore',
    country: 'United States',
    iata: 'NLC',
    icao: 'KNLC',
    latitude: 36.33300018,
    longitude: -119.9520035,
    altitude: 232,
    schengen: false
  },
  {
    id: 6718,
    name: 'Meridian Naval Air Station',
    city: 'Meridian',
    country: 'United States',
    iata: '',
    icao: 'KNMM',
    latitude: 32.55210114,
    longitude: -88.55560303,
    altitude: 316,
    schengen: false
  },
  {
    id: 6719,
    name: 'Port Angeles Cgas Airport',
    city: 'Port Angeles',
    country: 'United States',
    iata: '',
    icao: 'KNOW',
    latitude: 48.1414985656738,
    longitude: -123.414001464844,
    altitude: 13,
    schengen: false
  },
  {
    id: 6720,
    name: 'Pensacola Naval Air Station/Forrest Sherman Field',
    city: 'Pensacola',
    country: 'United States',
    iata: 'NPA',
    icao: 'KNPA',
    latitude: 30.35269928,
    longitude: -87.31860352,
    altitude: 28,
    schengen: false
  },
  {
    id: 6721,
    name: 'Millington-Memphis Airport',
    city: 'Millington',
    country: 'United States',
    iata: 'NQA',
    icao: 'KNQA',
    latitude: 35.356701,
    longitude: -89.8703,
    altitude: 320,
    schengen: false
  },
  {
    id: 6722,
    name: 'Kingsville Naval Air Station',
    city: 'Kingsville',
    country: 'United States',
    iata: 'NQI',
    icao: 'KNQI',
    latitude: 27.5072002411,
    longitude: -97.8097000122,
    altitude: 50,
    schengen: false
  },
  {
    id: 6723,
    name: 'Naval Air Station Key West/Boca Chica Field',
    city: 'Key West',
    country: 'United States',
    iata: 'NQX',
    icao: 'KNQX',
    latitude: 24.57579994,
    longitude: -81.68890381,
    altitude: 6,
    schengen: false
  },
  {
    id: 6724,
    name: 'Naval Station Mayport (Admiral David L. Mcdonald Field)',
    city: 'Mayport',
    country: 'United States',
    iata: 'NRB',
    icao: 'KNRB',
    latitude: 30.39109993,
    longitude: -81.42469788,
    altitude: 15,
    schengen: false
  },
  {
    id: 6725,
    name: 'Whiting Field Naval Air Station - North',
    city: 'Milton',
    country: 'United States',
    iata: 'NSE',
    icao: 'KNSE',
    latitude: 30.7241993,
    longitude: -87.02189636,
    altitude: 199,
    schengen: false
  },
  {
    id: 6726,
    name: 'San Nicolas Island Nolf Airport',
    city: 'San Nicolas Island',
    country: 'United States',
    iata: '',
    icao: 'KNSI',
    latitude: 33.2397994995117,
    longitude: -119.458000183105,
    altitude: 506,
    schengen: false
  },
  {
    id: 6727,
    name: 'Point Mugu Naval Air Station (Naval Base Ventura Co)',
    city: 'Point Mugu',
    country: 'United States',
    iata: 'NTD',
    icao: 'KNTD',
    latitude: 34.120300293,
    longitude: -119.121002197,
    altitude: 13,
    schengen: false
  },
  {
    id: 6728,
    name: 'Oceana Naval Air Station',
    city: 'Oceana',
    country: 'United States',
    iata: 'NTU',
    icao: 'KNTU',
    latitude: 36.820702,
    longitude: -76.033501,
    altitude: 23,
    schengen: false
  },
  {
    id: 6729,
    name: 'San Clemente Island Naval Auxiliary Landing Field',
    city: 'San Clemente Island',
    country: 'United States',
    iata: '',
    icao: 'KNUC',
    latitude: 33.02270126,
    longitude: -118.5879974,
    altitude: 184,
    schengen: false
  },
  {
    id: 6730,
    name: 'Moffett Federal Airfield',
    city: 'Mountain View',
    country: 'United States',
    iata: 'NUQ',
    icao: 'KNUQ',
    latitude: 37.4161,
    longitude: -122.049004,
    altitude: 32,
    schengen: false
  },
  {
    id: 6731,
    name: 'Whidbey Island Naval Air Station (Ault Field)',
    city: 'Whidbey Island',
    country: 'United States',
    iata: 'NUW',
    icao: 'KNUW',
    latitude: 48.351799,
    longitude: -122.655998,
    altitude: 47,
    schengen: false
  },
  {
    id: 6732,
    name: 'Twentynine Palms (Self) Airport',
    city: 'Twenty Nine Palms',
    country: 'United States',
    iata: '',
    icao: 'KNXP',
    latitude: 34.2961998,
    longitude: -116.1620026,
    altitude: 2051,
    schengen: false
  },
  {
    id: 6733,
    name: 'Willow Grove Naval Air Station/Joint Reserve Base',
    city: 'Willow Grove',
    country: 'United States',
    iata: 'NXX',
    icao: 'KNXX',
    latitude: 40.19979858,
    longitude: -75.14820099,
    altitude: 358,
    schengen: false
  },
  {
    id: 6734,
    name: 'Quantico MCAF /Turner field',
    city: 'Quantico',
    country: 'United States',
    iata: '',
    icao: 'KNYG',
    latitude: 38.50170135,
    longitude: -77.30529785,
    altitude: 10,
    schengen: false
  },
  {
    id: 6735,
    name: 'Yuma MCAS/Yuma International Airport',
    city: 'Yuma',
    country: 'United States',
    iata: 'YUM',
    icao: 'KNYL',
    latitude: 32.65660095,
    longitude: -114.6060028,
    altitude: 213,
    schengen: false
  },
  {
    id: 6736,
    name: 'El Toro Marine Corps Air Station',
    city: 'Santa Ana',
    country: 'United States',
    iata: 'NZJ',
    icao: 'KNZJ',
    latitude: 33.6761016845703,
    longitude: -117.731002807617,
    altitude: 383,
    schengen: false
  },
  {
    id: 6737,
    name: 'North Island Naval Air Station-Halsey Field',
    city: 'San Diego',
    country: 'United States',
    iata: 'NZY',
    icao: 'KNZY',
    latitude: 32.69919968,
    longitude: -117.2149963,
    altitude: 26,
    schengen: false
  },
  {
    id: 6738,
    name: 'Albert J Ellis Airport',
    city: 'Jacksonville NC',
    country: 'United States',
    iata: 'OAJ',
    icao: 'KOAJ',
    latitude: 34.8292007446,
    longitude: -77.6120986938,
    altitude: 94,
    schengen: false
  },
  {
    id: 6739,
    name: 'Metropolitan Oakland International Airport',
    city: 'Oakland',
    country: 'United States',
    iata: 'OAK',
    icao: 'KOAK',
    latitude: 37.721298,
    longitude: -122.221001,
    altitude: 9,
    schengen: false
  },
  {
    id: 6740,
    name: 'Marina Municipal Airport',
    city: 'Fort Ord',
    country: 'United States',
    iata: 'OAR',
    icao: 'KOAR',
    latitude: 36.68190002,
    longitude: -121.762001,
    altitude: 137,
    schengen: false
  },
  {
    id: 6741,
    name: 'Okeechobee County Airport',
    city: 'Okeechobee',
    country: 'United States',
    iata: 'OBE',
    icao: 'KOBE',
    latitude: 27.2628002167,
    longitude: -80.8498001099,
    altitude: 34,
    schengen: false
  },
  {
    id: 6742,
    name: 'Ocala International Airport - Jim Taylor Field',
    city: 'Ocala',
    country: 'United States',
    iata: 'OCF',
    icao: 'KOCF',
    latitude: 29.17259979,
    longitude: -82.22419739,
    altitude: 90,
    schengen: false
  },
  {
    id: 6743,
    name: 'Warren Field',
    city: 'Washington',
    country: 'United States',
    iata: 'OCW',
    icao: 'KOCW',
    latitude: 35.570499420166,
    longitude: -77.049797058105,
    altitude: 38,
    schengen: false
  },
  {
    id: 6744,
    name: 'Branch County Memorial Airport',
    city: 'Coldwater',
    country: 'United States',
    iata: '',
    icao: 'KOEB',
    latitude: 41.9333992,
    longitude: -85.05259705,
    altitude: 959,
    schengen: false
  },
  {
    id: 6745,
    name: 'Offutt Air Force Base',
    city: 'Omaha',
    country: 'United States',
    iata: 'OFF',
    icao: 'KOFF',
    latitude: 41.118301391602,
    longitude: -95.912498474121,
    altitude: 1052,
    schengen: false
  },
  {
    id: 6746,
    name: 'Karl Stefan Memorial Airport',
    city: 'Norfolk  Nebraska',
    country: 'United States',
    iata: 'OFK',
    icao: 'KOFK',
    latitude: 41.985500335693,
    longitude: -97.435096740723,
    altitude: 1573,
    schengen: false
  },
  {
    id: 6747,
    name: 'Orangeburg Municipal Airport',
    city: 'Orangeburg',
    country: 'United States',
    iata: 'OGB',
    icao: 'KOGB',
    latitude: 33.456798553467,
    longitude: -80.859497070312,
    altitude: 195,
    schengen: false
  },
  {
    id: 6748,
    name: 'Ogden Hinckley Airport',
    city: 'Ogden',
    country: 'United States',
    iata: 'OGD',
    icao: 'KOGD',
    latitude: 41.195899963379,
    longitude: -112.0120010376,
    altitude: 4473,
    schengen: false
  },
  {
    id: 6749,
    name: 'Ogdensburg International Airport',
    city: 'Ogdensburg',
    country: 'United States',
    iata: 'OGS',
    icao: 'KOGS',
    latitude: 44.6819000244,
    longitude: -75.4654998779,
    altitude: 297,
    schengen: false
  },
  {
    id: 6750,
    name: 'Johnson County Executive Airport',
    city: 'Olathe',
    country: 'United States',
    iata: 'OJC',
    icao: 'KOJC',
    latitude: 38.84759903,
    longitude: -94.73760223,
    altitude: 1096,
    schengen: false
  },
  {
    id: 6751,
    name: 'Will Rogers World Airport',
    city: 'Oklahoma City',
    country: 'United States',
    iata: 'OKC',
    icao: 'KOKC',
    latitude: 35.3931007385254,
    longitude: -97.600700378418,
    altitude: 1295,
    schengen: false
  },
  {
    id: 6752,
    name: 'Kokomo Municipal Airport',
    city: 'Kokomo',
    country: 'United States',
    iata: 'OKK',
    icao: 'KOKK',
    latitude: 40.528198242188,
    longitude: -86.05899810791,
    altitude: 830,
    schengen: false
  },
  {
    id: 6753,
    name: 'Okmulgee Regional Airport',
    city: 'Okmulgee',
    country: 'United States',
    iata: 'OKM',
    icao: 'KOKM',
    latitude: 35.668098449707,
    longitude: -95.948699951172,
    altitude: 720,
    schengen: false
  },
  {
    id: 6754,
    name: 'L M Clayton Airport',
    city: 'Wolf Point',
    country: 'United States',
    iata: 'OLF',
    icao: 'KOLF',
    latitude: 48.0945014954,
    longitude: -105.574996948,
    altitude: 1986,
    schengen: false
  },
  {
    id: 6755,
    name: 'Olympia Regional Airport',
    city: 'Olympia',
    country: 'United States',
    iata: 'OLM',
    icao: 'KOLM',
    latitude: 46.9693985,
    longitude: -122.9029999,
    altitude: 209,
    schengen: false
  },
  {
    id: 6756,
    name: 'Nogales International Airport',
    city: 'Nogales',
    country: 'United States',
    iata: 'OLS',
    icao: 'KOLS',
    latitude: 31.4177,
    longitude: -110.848,
    altitude: 3955,
    schengen: false
  },
  {
    id: 6757,
    name: 'Columbus Municipal Airport',
    city: 'Columbus',
    country: 'United States',
    iata: '',
    icao: 'KOLU',
    latitude: 41.44800186,
    longitude: -97.34259796,
    altitude: 1447,
    schengen: false
  },
  {
    id: 6758,
    name: 'Olive Branch Airport',
    city: 'Olive Branch',
    country: 'United States',
    iata: 'OLV',
    icao: 'KOLV',
    latitude: 34.9786987305,
    longitude: -89.7869033813,
    altitude: 402,
    schengen: false
  },
  {
    id: 6759,
    name: 'Eppley Airfield',
    city: 'Omaha',
    country: 'United States',
    iata: 'OMA',
    icao: 'KOMA',
    latitude: 41.3032,
    longitude: -95.894096,
    altitude: 984,
    schengen: false
  },
  {
    id: 6760,
    name: 'Ormond Beach Municipal Airport',
    city: 'Ormond Beach',
    country: 'United States',
    iata: '',
    icao: 'KOMN',
    latitude: 29.3006000518799,
    longitude: -81.1136016845703,
    altitude: 29,
    schengen: false
  },
  {
    id: 6761,
    name: 'Socorro Municipal Airport',
    city: 'Socorro',
    country: 'United States',
    iata: '',
    icao: 'KONM',
    latitude: 34.0224990844727,
    longitude: -106.90299987793,
    altitude: 4875,
    schengen: false
  },
  {
    id: 6762,
    name: 'Ontario Municipal Airport',
    city: 'Ontario',
    country: 'United States',
    iata: 'ONO',
    icao: 'KONO',
    latitude: 44.020500183105,
    longitude: -117.01399993896,
    altitude: 2193,
    schengen: false
  },
  {
    id: 6763,
    name: 'Newport Municipal Airport',
    city: 'Newport',
    country: 'United States',
    iata: 'ONP',
    icao: 'KONP',
    latitude: 44.580399,
    longitude: -124.057999,
    altitude: 160,
    schengen: false
  },
  {
    id: 6764,
    name: 'Ontario International Airport',
    city: 'Ontario',
    country: 'United States',
    iata: 'ONT',
    icao: 'KONT',
    latitude: 34.0559997558594,
    longitude: -117.600997924805,
    altitude: 944,
    schengen: false
  },
  {
    id: 6765,
    name: 'Opa-locka Executive Airport',
    city: 'Miami',
    country: 'United States',
    iata: 'OPF',
    icao: 'KOPF',
    latitude: 25.907,
    longitude: -80.278397,
    altitude: 8,
    schengen: false
  },
  {
    id: 6766,
    name: 'Brandywine Airport',
    city: 'West Goshen Township',
    country: 'United States',
    iata: '',
    icao: 'KOQN',
    latitude: 39.9901008605957,
    longitude: -75.581901550293,
    altitude: 466,
    schengen: false
  },
  {
    id: 6767,
    name: 'Quonset State Airport',
    city: 'North Kingstown',
    country: 'United States',
    iata: 'NCO',
    icao: 'KOQU',
    latitude: 41.597099304199,
    longitude: -71.412101745605,
    altitude: 18,
    schengen: false
  },
  {
    id: 6768,
    name: "Chicago O'Hare International Airport",
    city: 'Chicago',
    country: 'United States',
    iata: 'ORD',
    icao: 'KORD',
    latitude: 41.9786,
    longitude: -87.9048,
    altitude: 672,
    schengen: false
  },
  {
    id: 6769,
    name: 'Norfolk International Airport',
    city: 'Norfolk',
    country: 'United States',
    iata: 'ORF',
    icao: 'KORF',
    latitude: 36.8945999145508,
    longitude: -76.2012023925781,
    altitude: 26,
    schengen: false
  },
  {
    id: 6770,
    name: 'Worcester Regional Airport',
    city: 'Worcester',
    country: 'United States',
    iata: 'ORH',
    icao: 'KORH',
    latitude: 42.2672996520996,
    longitude: -71.8757019042969,
    altitude: 1009,
    schengen: false
  },
  {
    id: 6771,
    name: 'Orlando Executive Airport',
    city: 'Orlando',
    country: 'United States',
    iata: 'ORL',
    icao: 'KORL',
    latitude: 28.5455,
    longitude: -81.332901,
    altitude: 113,
    schengen: false
  },
  {
    id: 6772,
    name: 'Orcas Island Airport',
    city: 'Eastsound',
    country: 'United States',
    iata: 'ESD',
    icao: 'KORS',
    latitude: 48.7081985474,
    longitude: -122.910003662,
    altitude: 31,
    schengen: false
  },
  {
    id: 6773,
    name: 'Oscoda Wurtsmith Airport',
    city: 'Oscoda',
    country: 'United States',
    iata: 'OSC',
    icao: 'KOSC',
    latitude: 44.451599,
    longitude: -83.394096,
    altitude: 633,
    schengen: false
  },
  {
    id: 6774,
    name: 'Wittman Regional Airport',
    city: 'Oshkosh',
    country: 'United States',
    iata: 'OSH',
    icao: 'KOSH',
    latitude: 43.9844017028809,
    longitude: -88.556999206543,
    altitude: 808,
    schengen: false
  },
  {
    id: 6775,
    name: 'The Ohio State University Airport - Don Scott Field',
    city: 'Columbus',
    country: 'United States',
    iata: 'OSU',
    icao: 'KOSU',
    latitude: 40.0798,
    longitude: -83.072998,
    altitude: 905,
    schengen: false
  },
  {
    id: 6776,
    name: 'Southwest Oregon Regional Airport',
    city: 'North Bend',
    country: 'United States',
    iata: 'OTH',
    icao: 'KOTH',
    latitude: 43.4170989990234,
    longitude: -124.246002197266,
    altitude: 17,
    schengen: false
  },
  {
    id: 6777,
    name: 'Ottumwa Regional Airport',
    city: 'Ottumwa',
    country: 'United States',
    iata: 'OTM',
    icao: 'KOTM',
    latitude: 41.10660172,
    longitude: -92.44789886,
    altitude: 845,
    schengen: false
  },
  {
    id: 6778,
    name: 'Owensboro Daviess County Airport',
    city: 'Owensboro',
    country: 'United States',
    iata: 'OWB',
    icao: 'KOWB',
    latitude: 37.74010086,
    longitude: -87.16680145,
    altitude: 407,
    schengen: false
  },
  {
    id: 6779,
    name: 'Norwood Memorial Airport',
    city: 'Norwood',
    country: 'United States',
    iata: 'OWD',
    icao: 'KOWD',
    latitude: 42.1904983521,
    longitude: -71.1728973389,
    altitude: 49,
    schengen: false
  },
  {
    id: 6780,
    name: 'William R. Pogue Municipal Airport',
    city: 'Sand Springs',
    country: 'United States',
    iata: '',
    icao: 'KOWP',
    latitude: 36.1753006,
    longitude: -96.15180206,
    altitude: 892,
    schengen: false
  },
  {
    id: 6781,
    name: 'Waterbury Oxford Airport',
    city: 'Oxford',
    country: 'United States',
    iata: 'OXC',
    icao: 'KOXC',
    latitude: 41.47859954834,
    longitude: -73.135200500488,
    altitude: 726,
    schengen: false
  },
  {
    id: 6782,
    name: 'Oxnard Airport',
    city: 'Oxnard',
    country: 'United States',
    iata: 'OXR',
    icao: 'KOXR',
    latitude: 34.200801849365,
    longitude: -119.20700073242,
    altitude: 45,
    schengen: false
  },
  {
    id: 6783,
    name: 'Ozona Municipal Airport',
    city: 'Ozona',
    country: 'United States',
    iata: 'OZA',
    icao: 'KOZA',
    latitude: 30.735300064087,
    longitude: -101.20300292969,
    altitude: 2381,
    schengen: false
  },
  {
    id: 6784,
    name: 'Cairns AAF (Fort Rucker) Air Field',
    city: 'Fort Rucker/Ozark',
    country: 'United States',
    iata: 'OZR',
    icao: 'KOZR',
    latitude: 31.27569962,
    longitude: -85.71340179,
    altitude: 301,
    schengen: false
  },
  {
    id: 6785,
    name: 'Coolidge Municipal Airport',
    city: 'Cooldige',
    country: 'United States',
    iata: '',
    icao: 'KP08',
    latitude: 32.9359016418457,
    longitude: -111.427001953125,
    altitude: 1574,
    schengen: false
  },
  {
    id: 6786,
    name: 'Cottonwood Airport',
    city: 'Cottonwood',
    country: 'United States',
    iata: '',
    icao: 'KP52',
    latitude: 34.7299995422,
    longitude: -112.035003662,
    altitude: 3550,
    schengen: false
  },
  {
    id: 6787,
    name: 'Snohomish County (Paine Field) Airport',
    city: 'Everett',
    country: 'United States',
    iata: 'PAE',
    icao: 'KPAE',
    latitude: 47.90629959,
    longitude: -122.2819977,
    altitude: 606,
    schengen: false
  },
  {
    id: 6788,
    name: 'Barkley Regional Airport',
    city: 'PADUCAH',
    country: 'United States',
    iata: 'PAH',
    icao: 'KPAH',
    latitude: 37.0607986450195,
    longitude: -88.7738037109375,
    altitude: 410,
    schengen: false
  },
  {
    id: 6789,
    name: 'Tyndall Air Force Base',
    city: 'Panama City',
    country: 'United States',
    iata: 'PAM',
    icao: 'KPAM',
    latitude: 30.0695991516,
    longitude: -85.5754013062,
    altitude: 17,
    schengen: false
  },
  {
    id: 6790,
    name: 'Palo Alto Airport of Santa Clara County',
    city: 'Palo Alto',
    country: 'United States',
    iata: 'PAO',
    icao: 'KPAO',
    latitude: 37.461101532,
    longitude: -122.114997864,
    altitude: 4,
    schengen: false
  },
  {
    id: 6791,
    name: 'Grider Field',
    city: 'Pine Bluff',
    country: 'United States',
    iata: 'PBF',
    icao: 'KPBF',
    latitude: 34.1730995178,
    longitude: -91.9356002808,
    altitude: 206,
    schengen: false
  },
  {
    id: 6792,
    name: 'Plattsburgh International Airport',
    city: 'Plattsburgh',
    country: 'United States',
    iata: 'PBG',
    icao: 'KPBG',
    latitude: 44.6509017944336,
    longitude: -73.4681015014648,
    altitude: 234,
    schengen: false
  },
  {
    id: 6793,
    name: 'Price County Airport',
    city: 'Phillips',
    country: 'United States',
    iata: '',
    icao: 'KPBH',
    latitude: 45.7089996337891,
    longitude: -90.4024963378906,
    altitude: 1497,
    schengen: false
  },
  {
    id: 6794,
    name: 'Palm Beach International Airport',
    city: 'West Palm Beach',
    country: 'United States',
    iata: 'PBI',
    icao: 'KPBI',
    latitude: 26.6832008361816,
    longitude: -80.0955963134766,
    altitude: 19,
    schengen: false
  },
  {
    id: 6795,
    name: 'Pike County-Hatcher Field',
    city: 'Pikeville',
    country: 'United States',
    iata: 'PVL',
    icao: 'KPBX',
    latitude: 37.5617981,
    longitude: -82.56639862,
    altitude: 1473,
    schengen: false
  },
  {
    id: 6796,
    name: 'Carl R Keller Field',
    city: 'Port Clinton',
    country: 'United States',
    iata: '',
    icao: 'KPCW',
    latitude: 41.516300201416,
    longitude: -82.8686981201172,
    altitude: 590,
    schengen: false
  },
  {
    id: 6797,
    name: 'Waupaca Municipal Airport',
    city: 'Waupaca',
    country: 'United States',
    iata: '',
    icao: 'KPCZ',
    latitude: 44.33330154,
    longitude: -89.01979828,
    altitude: 840,
    schengen: false
  },
  {
    id: 6798,
    name: 'Prairie Du Chien Municipal Airport',
    city: 'Prairie du Chien',
    country: 'United States',
    iata: 'PCD',
    icao: 'KPDC',
    latitude: 43.019298553467,
    longitude: -91.12370300293,
    altitude: 661,
    schengen: false
  },
  {
    id: 6799,
    name: 'DeKalb Peachtree Airport',
    city: 'Atlanta',
    country: 'United States',
    iata: 'PDK',
    icao: 'KPDK',
    latitude: 33.8755989075,
    longitude: -84.3020019531,
    altitude: 1003,
    schengen: false
  },
  {
    id: 6800,
    name: 'Eastern Oregon Regional At Pendleton Airport',
    city: 'Pendleton',
    country: 'United States',
    iata: 'PDT',
    icao: 'KPDT',
    latitude: 45.695098877,
    longitude: -118.841003418,
    altitude: 1497,
    schengen: false
  },
  {
    id: 6801,
    name: 'Portland International Airport',
    city: 'Portland',
    country: 'United States',
    iata: 'PDX',
    icao: 'KPDX',
    latitude: 45.58869934,
    longitude: -122.5979996,
    altitude: 31,
    schengen: false
  },
  {
    id: 6802,
    name: 'Pecos Municipal Airport',
    city: 'Pecos',
    country: 'United States',
    iata: 'PEQ',
    icao: 'KPEQ',
    latitude: 31.382400512695,
    longitude: -103.51100158691,
    altitude: 2613,
    schengen: false
  },
  {
    id: 6803,
    name: 'Panama City-Bay Co International Airport',
    city: 'Panama City',
    country: 'United States',
    iata: 'PFN',
    icao: 'KPFN',
    latitude: 30.212099,
    longitude: -85.6828,
    altitude: 20,
    schengen: false
  },
  {
    id: 6804,
    name: 'Page Municipal Airport',
    city: 'Page',
    country: 'United States',
    iata: 'PGA',
    icao: 'KPGA',
    latitude: 36.92610168,
    longitude: -111.447998,
    altitude: 4316,
    schengen: false
  },
  {
    id: 6805,
    name: 'Charlotte County Airport',
    city: 'Punta Gorda',
    country: 'United States',
    iata: 'PGD',
    icao: 'KPGD',
    latitude: 26.92020035,
    longitude: -81.9905014,
    altitude: 26,
    schengen: false
  },
  {
    id: 6806,
    name: 'Pitt Greenville Airport',
    city: 'Greenville',
    country: 'United States',
    iata: 'PGV',
    icao: 'KPGV',
    latitude: 35.6352005,
    longitude: -77.38529968,
    altitude: 26,
    schengen: false
  },
  {
    id: 6807,
    name: 'Harry Clever Field',
    city: 'New Philadelpha',
    country: 'United States',
    iata: 'PHD',
    icao: 'KPHD',
    latitude: 40.470901489258,
    longitude: -81.419700622559,
    altitude: 894,
    schengen: false
  },
  {
    id: 6808,
    name: 'Newport News Williamsburg International Airport',
    city: 'Newport News',
    country: 'United States',
    iata: 'PHF',
    icao: 'KPHF',
    latitude: 37.13190079,
    longitude: -76.49299622,
    altitude: 42,
    schengen: false
  },
  {
    id: 6809,
    name: 'Palm Beach County Glades Airport',
    city: 'Pahokee',
    country: 'United States',
    iata: 'PHK',
    icao: 'KPHK',
    latitude: 26.78499985,
    longitude: -80.69339752,
    altitude: 16,
    schengen: false
  },
  {
    id: 6810,
    name: 'Philadelphia International Airport',
    city: 'Philadelphia',
    country: 'United States',
    iata: 'PHL',
    icao: 'KPHL',
    latitude: 39.871898651123,
    longitude: -75.241096496582,
    altitude: 36,
    schengen: false
  },
  {
    id: 6811,
    name: 'St Clair County International Airport',
    city: 'Port Huron',
    country: 'United States',
    iata: 'PHN',
    icao: 'KPHN',
    latitude: 42.9109993,
    longitude: -82.52890015,
    altitude: 650,
    schengen: false
  },
  {
    id: 6812,
    name: 'Phoenix Sky Harbor International Airport',
    city: 'Phoenix',
    country: 'United States',
    iata: 'PHX',
    icao: 'KPHX',
    latitude: 33.4342994689941,
    longitude: -112.012001037598,
    altitude: 1135,
    schengen: false
  },
  {
    id: 6813,
    name: 'General Wayne A. Downing Peoria International Airport',
    city: 'Peoria',
    country: 'United States',
    iata: 'PIA',
    icao: 'KPIA',
    latitude: 40.6641998291,
    longitude: -89.6932983398,
    altitude: 660,
    schengen: false
  },
  {
    id: 6814,
    name: 'Hattiesburg Laurel Regional Airport',
    city: 'Hattiesburg/Laurel',
    country: 'United States',
    iata: 'PIB',
    icao: 'KPIB',
    latitude: 31.4671001434326,
    longitude: -89.3370971679687,
    altitude: 298,
    schengen: false
  },
  {
    id: 6815,
    name: 'St Petersburg Clearwater International Airport',
    city: 'St. Petersburg',
    country: 'United States',
    iata: 'PIE',
    icao: 'KPIE',
    latitude: 27.91020012,
    longitude: -82.68740082,
    altitude: 11,
    schengen: false
  },
  {
    id: 6816,
    name: 'Pocatello Regional Airport',
    city: 'Pocatello',
    country: 'United States',
    iata: 'PIH',
    icao: 'KPIH',
    latitude: 42.9098014831543,
    longitude: -112.596000671387,
    altitude: 4452,
    schengen: false
  },
  {
    id: 6817,
    name: 'Harris County Airport',
    city: 'Pine Mountain',
    country: 'United States',
    iata: 'PIM',
    icao: 'KPIM',
    latitude: 32.8406982422,
    longitude: -84.8824005127,
    altitude: 902,
    schengen: false
  },
  {
    id: 6818,
    name: 'Pierre Regional Airport',
    city: 'Pierre',
    country: 'United States',
    iata: 'PIR',
    icao: 'KPIR',
    latitude: 44.38270187,
    longitude: -100.2860031,
    altitude: 1744,
    schengen: false
  },
  {
    id: 6819,
    name: 'Pittsburgh International Airport',
    city: 'Pittsburgh',
    country: 'United States',
    iata: 'PIT',
    icao: 'KPIT',
    latitude: 40.49150085,
    longitude: -80.23290253,
    altitude: 1203,
    schengen: false
  },
  {
    id: 6820,
    name: 'Zelienople Municipal Airport',
    city: 'Zelienople',
    country: 'United States',
    iata: '',
    icao: 'KPJC',
    latitude: 40.80160141,
    longitude: -80.16069794,
    altitude: 898,
    schengen: false
  },
  {
    id: 6821,
    name: 'Mid Ohio Valley Regional Airport',
    city: 'PARKERSBURG',
    country: 'United States',
    iata: 'PKB',
    icao: 'KPKB',
    latitude: 39.345100402832,
    longitude: -81.4392013549805,
    altitude: 858,
    schengen: false
  },
  {
    id: 6822,
    name: 'Pellston Regional Airport of Emmet County Airport',
    city: 'Pellston',
    country: 'United States',
    iata: 'PLN',
    icao: 'KPLN',
    latitude: 45.57089996,
    longitude: -84.79669952,
    altitude: 721,
    schengen: false
  },
  {
    id: 6823,
    name: 'Pembina Municipal Airport',
    city: 'Pembina',
    country: 'United States',
    iata: 'PMB',
    icao: 'KPMB',
    latitude: 48.9425010681,
    longitude: -97.2407989502,
    altitude: 795,
    schengen: false
  },
  {
    id: 6824,
    name: 'Palmdale Regional/USAF Plant 42 Airport',
    city: 'Palmdale',
    country: 'United States',
    iata: 'PMD',
    icao: 'KPMD',
    latitude: 34.62939835,
    longitude: -118.0849991,
    altitude: 2543,
    schengen: false
  },
  {
    id: 6825,
    name: 'Greater Portsmouth Regional Airport',
    city: 'Portsmouth',
    country: 'United States',
    iata: 'PMH',
    icao: 'KPMH',
    latitude: 38.8404998779,
    longitude: -82.8472976685,
    altitude: 663,
    schengen: false
  },
  {
    id: 6826,
    name: 'Pompano Beach Airpark',
    city: 'Pompano Beach',
    country: 'United States',
    iata: 'PPM',
    icao: 'KPMP',
    latitude: 26.247100830078,
    longitude: -80.111099243164,
    altitude: 19,
    schengen: false
  },
  {
    id: 6827,
    name: 'Ralph Wenz Field',
    city: 'Pinedale',
    country: 'United States',
    iata: 'PWY',
    icao: 'KPNA',
    latitude: 42.79550171,
    longitude: -109.8069992,
    altitude: 7102,
    schengen: false
  },
  {
    id: 6828,
    name: 'Ponca City Regional Airport',
    city: 'Ponca City',
    country: 'United States',
    iata: 'PNC',
    icao: 'KPNC',
    latitude: 36.73199844,
    longitude: -97.09980011,
    altitude: 1008,
    schengen: false
  },
  {
    id: 6829,
    name: 'Northeast Philadelphia Airport',
    city: 'Philadelphia',
    country: 'United States',
    iata: 'PNE',
    icao: 'KPNE',
    latitude: 40.081902,
    longitude: -75.010597,
    altitude: 120,
    schengen: false
  },
  {
    id: 6830,
    name: 'Princeton Municipal Airport',
    city: 'Princeton',
    country: 'United States',
    iata: '',
    icao: 'KPNM',
    latitude: 45.55989838,
    longitude: -93.60820007,
    altitude: 980,
    schengen: false
  },
  {
    id: 6831,
    name: 'Pensacola Regional Airport',
    city: 'Pensacola',
    country: 'United States',
    iata: 'PNS',
    icao: 'KPNS',
    latitude: 30.473400115967,
    longitude: -87.186599731445,
    altitude: 121,
    schengen: false
  },
  {
    id: 6832,
    name: 'Pope Field',
    city: 'Fort Bragg',
    country: 'United States',
    iata: 'POB',
    icao: 'KPOB',
    latitude: 35.1708984375,
    longitude: -79.014503479004,
    altitude: 217,
    schengen: false
  },
  {
    id: 6833,
    name: 'Brackett Field',
    city: 'La Verne',
    country: 'United States',
    iata: 'POC',
    icao: 'KPOC',
    latitude: 34.091598510742,
    longitude: -117.78199768066,
    altitude: 1011,
    schengen: false
  },
  {
    id: 6834,
    name: 'Polk Army Air Field',
    city: 'Fort Polk',
    country: 'United States',
    iata: 'POE',
    icao: 'KPOE',
    latitude: 31.0447998,
    longitude: -93.1917038,
    altitude: 330,
    schengen: false
  },
  {
    id: 6835,
    name: 'Poplar Bluff Municipal Airport',
    city: 'Poplar Bluff',
    country: 'United States',
    iata: 'POF',
    icao: 'KPOF',
    latitude: 36.773899078369,
    longitude: -90.324897766113,
    altitude: 331,
    schengen: false
  },
  {
    id: 6836,
    name: 'Dutchess County Airport',
    city: 'Poughkeepsie',
    country: 'United States',
    iata: 'POU',
    icao: 'KPOU',
    latitude: 41.6265983581543,
    longitude: -73.8842010498047,
    altitude: 165,
    schengen: false
  },
  {
    id: 6837,
    name: 'Perry Lefors Field',
    city: 'Pampa',
    country: 'United States',
    iata: 'PPA',
    icao: 'KPPA',
    latitude: 35.612998962402,
    longitude: -100.99600219727,
    altitude: 3245,
    schengen: false
  },
  {
    id: 6838,
    name: 'Tri-City Airport',
    city: 'Parsons',
    country: 'United States',
    iata: 'PPF',
    icao: 'KPPF',
    latitude: 37.32989883,
    longitude: -95.5062027,
    altitude: 900,
    schengen: false
  },
  {
    id: 6839,
    name: 'Northern Maine Regional Airport at Presque Isle',
    city: 'Presque Isle',
    country: 'United States',
    iata: 'PQI',
    icao: 'KPQI',
    latitude: 46.68899918,
    longitude: -68.0447998,
    altitude: 534,
    schengen: false
  },
  {
    id: 6840,
    name: 'Paso Robles Municipal Airport',
    city: 'Paso Robles',
    country: 'United States',
    iata: 'PRB',
    icao: 'KPRB',
    latitude: 35.67290115,
    longitude: -120.6269989,
    altitude: 840,
    schengen: false
  },
  {
    id: 6841,
    name: 'Ernest A. Love Field',
    city: 'Prescott',
    country: 'United States',
    iata: 'PRC',
    icao: 'KPRC',
    latitude: 34.65449905,
    longitude: -112.4199982,
    altitude: 5045,
    schengen: false
  },
  {
    id: 6842,
    name: 'Cox Field',
    city: 'Paris',
    country: 'United States',
    iata: 'PRX',
    icao: 'KPRX',
    latitude: 33.636600494385,
    longitude: -95.450798034668,
    altitude: 547,
    schengen: false
  },
  {
    id: 6843,
    name: 'Mid-State Regional Airport',
    city: 'Philipsburg',
    country: 'United States',
    iata: '',
    icao: 'KPSB',
    latitude: 40.884399414062,
    longitude: -78.087303161621,
    altitude: 1948,
    schengen: false
  },
  {
    id: 6844,
    name: 'Tri Cities Airport',
    city: 'Pasco',
    country: 'United States',
    iata: 'PSC',
    icao: 'KPSC',
    latitude: 46.2647018432617,
    longitude: -119.119003295898,
    altitude: 410,
    schengen: false
  },
  {
    id: 6845,
    name: 'Portsmouth International at Pease Airport',
    city: 'Portsmouth',
    country: 'United States',
    iata: 'PSM',
    icao: 'KPSM',
    latitude: 43.0778999329,
    longitude: -70.8233032227,
    altitude: 100,
    schengen: false
  },
  {
    id: 6846,
    name: 'Palm Springs International Airport',
    city: 'Palm Springs',
    country: 'United States',
    iata: 'PSP',
    icao: 'KPSP',
    latitude: 33.8297004699707,
    longitude: -116.50700378418,
    altitude: 477,
    schengen: false
  },
  {
    id: 6847,
    name: 'Palacios Municipal Airport',
    city: 'Palacios',
    country: 'United States',
    iata: 'PSX',
    icao: 'KPSX',
    latitude: 28.727500915527,
    longitude: -96.250999450684,
    altitude: 14,
    schengen: false
  },
  {
    id: 6848,
    name: 'Dinwiddie County Airport',
    city: 'Petersburg',
    country: 'United States',
    iata: 'PTB',
    icao: 'KPTB',
    latitude: 37.183799743652,
    longitude: -77.507400512695,
    altitude: 193,
    schengen: false
  },
  {
    id: 6849,
    name: 'Oakland County International Airport',
    city: 'Pontiac',
    country: 'United States',
    iata: 'PTK',
    icao: 'KPTK',
    latitude: 42.665500640869,
    longitude: -83.420097351074,
    altitude: 980,
    schengen: false
  },
  {
    id: 6850,
    name: 'Pratt Regional Airport',
    city: 'Pratt',
    country: 'United States',
    iata: 'PTT',
    icao: 'KPTT',
    latitude: 37.70159912,
    longitude: -98.74690247,
    altitude: 1953,
    schengen: false
  },
  {
    id: 6851,
    name: 'Pueblo Memorial Airport',
    city: 'Pueblo',
    country: 'United States',
    iata: 'PUB',
    icao: 'KPUB',
    latitude: 38.2891006469727,
    longitude: -104.497001647949,
    altitude: 4726,
    schengen: false
  },
  {
    id: 6852,
    name: 'Carbon County Regional/Buck Davis Field',
    city: 'Price',
    country: 'United States',
    iata: 'PUC',
    icao: 'KPUC',
    latitude: 39.61389923,
    longitude: -110.7509995,
    altitude: 5957,
    schengen: false
  },
  {
    id: 6853,
    name: 'Pullman Moscow Regional Airport',
    city: 'Pullman',
    country: 'United States',
    iata: 'PUW',
    icao: 'KPUW',
    latitude: 46.7439,
    longitude: -117.110001,
    altitude: 2556,
    schengen: false
  },
  {
    id: 6854,
    name: 'Provincetown Municipal Airport',
    city: 'Provincetown',
    country: 'United States',
    iata: 'PVC',
    icao: 'KPVC',
    latitude: 42.0718994141,
    longitude: -70.2213973999,
    altitude: 9,
    schengen: false
  },
  {
    id: 6855,
    name: 'Theodore Francis Green State Airport',
    city: 'Providence',
    country: 'United States',
    iata: 'PVD',
    icao: 'KPVD',
    latitude: 41.7326011657715,
    longitude: -71.4204025268555,
    altitude: 55,
    schengen: false
  },
  {
    id: 6856,
    name: 'Provo Municipal Airport',
    city: 'Provo',
    country: 'United States',
    iata: 'PVU',
    icao: 'KPVU',
    latitude: 40.219200134277,
    longitude: -111.72299957275,
    altitude: 4497,
    schengen: false
  },
  {
    id: 6857,
    name: 'Wiley Post Airport',
    city: 'Oklahoma City',
    country: 'United States',
    iata: 'PWA',
    icao: 'KPWA',
    latitude: 35.53419876,
    longitude: -97.64710236,
    altitude: 1300,
    schengen: false
  },
  {
    id: 6858,
    name: 'Chicago Executive Airport',
    city: 'Chicago-Wheeling',
    country: 'United States',
    iata: 'PWK',
    icao: 'KPWK',
    latitude: 42.114222,
    longitude: -87.901494,
    altitude: 647,
    schengen: false
  },
  {
    id: 6859,
    name: 'Portland International Jetport Airport',
    city: 'Portland',
    country: 'United States',
    iata: 'PWM',
    icao: 'KPWM',
    latitude: 43.64619827,
    longitude: -70.30930328,
    altitude: 76,
    schengen: false
  },
  {
    id: 6860,
    name: 'Bremerton National Airport',
    city: 'Bremerton',
    country: 'United States',
    iata: 'PWT',
    icao: 'KPWT',
    latitude: 47.490200042725,
    longitude: -122.76499938965,
    altitude: 444,
    schengen: false
  },
  {
    id: 6861,
    name: 'Perry Houston County Airport',
    city: 'Perry',
    country: 'United States',
    iata: '',
    icao: 'KPXE',
    latitude: 32.5106010437012,
    longitude: -83.7673034667969,
    altitude: 418,
    schengen: false
  },
  {
    id: 6862,
    name: 'Plymouth Municipal Airport',
    city: 'Plymouth',
    country: 'United States',
    iata: 'PYM',
    icao: 'KPYM',
    latitude: 41.909,
    longitude: -70.728798,
    altitude: 148,
    schengen: false
  },
  {
    id: 6863,
    name: 'Centre-Piedmont-Cherokee County Regional Airport',
    city: 'Centre',
    country: 'United States',
    iata: '',
    icao: 'KPYP',
    latitude: 34.089977,
    longitude: -85.610069,
    altitude: 596,
    schengen: false
  },
  {
    id: 6864,
    name: 'Akutan Seaplane Base',
    city: 'Akutan',
    country: 'United States',
    iata: 'KQA',
    icao: 'KQA',
    latitude: 54.1337704415,
    longitude: -165.778895617,
    altitude: 0,
    schengen: false
  },
  {
    id: 6865,
    name: 'John H Batten Airport',
    city: 'Racine',
    country: 'United States',
    iata: 'RAC',
    icao: 'KRAC',
    latitude: 42.7606010437,
    longitude: -87.8152008057,
    altitude: 674,
    schengen: false
  },
  {
    id: 6866,
    name: 'Riverside Municipal Airport',
    city: 'Riverside',
    country: 'United States',
    iata: 'RAL',
    icao: 'KRAL',
    latitude: 33.95190048,
    longitude: -117.4449997,
    altitude: 819,
    schengen: false
  },
  {
    id: 6867,
    name: 'Rapid City Regional Airport',
    city: 'Rapid City',
    country: 'United States',
    iata: 'RAP',
    icao: 'KRAP',
    latitude: 44.0452995300293,
    longitude: -103.056999206543,
    altitude: 3204,
    schengen: false
  },
  {
    id: 6868,
    name: 'Dallas Executive Airport',
    city: 'Dallas',
    country: 'United States',
    iata: 'RBD',
    icao: 'KRBD',
    latitude: 32.6809005737,
    longitude: -96.8682022095,
    altitude: 660,
    schengen: false
  },
  {
    id: 6869,
    name: 'Red Bluff Municipal Airport',
    city: 'Red Bluff',
    country: 'United States',
    iata: 'RBL',
    icao: 'KRBL',
    latitude: 40.1506996155,
    longitude: -122.251998901,
    altitude: 352,
    schengen: false
  },
  {
    id: 6870,
    name: 'Robinson Army Air Field',
    city: 'Robinson',
    country: 'United States',
    iata: '',
    icao: 'KRBM',
    latitude: 34.85010147,
    longitude: -92.30020142,
    altitude: 587,
    schengen: false
  },
  {
    id: 6871,
    name: 'Ellsworth Air Force Base',
    city: 'Rapid City',
    country: 'United States',
    iata: 'RCA',
    icao: 'KRCA',
    latitude: 44.14500046,
    longitude: -103.1039963,
    altitude: 3276,
    schengen: false
  },
  {
    id: 6872,
    name: 'Rusk County Airport',
    city: 'Ladysmith',
    country: 'United States',
    iata: '',
    icao: 'KRCX',
    latitude: 45.4967994689941,
    longitude: -91.0005035400391,
    altitude: 1238,
    schengen: false
  },
  {
    id: 6873,
    name: 'Richmond County Airport',
    city: 'Rockingham',
    country: 'United States',
    iata: '',
    icao: 'KRCZ',
    latitude: 34.8913,
    longitude: -79.759598,
    altitude: 358,
    schengen: false
  },
  {
    id: 6874,
    name: 'Redding Municipal Airport',
    city: 'Redding',
    country: 'United States',
    iata: 'RDD',
    icao: 'KRDD',
    latitude: 40.50899887,
    longitude: -122.2929993,
    altitude: 505,
    schengen: false
  },
  {
    id: 6875,
    name: 'Reading Regional Carl A Spaatz Field',
    city: 'Reading',
    country: 'United States',
    iata: 'RDG',
    icao: 'KRDG',
    latitude: 40.3785018920898,
    longitude: -75.965202331543,
    altitude: 344,
    schengen: false
  },
  {
    id: 6876,
    name: 'Roberts Field',
    city: 'Redmond-Bend',
    country: 'United States',
    iata: 'RDM',
    icao: 'KRDM',
    latitude: 44.2541008,
    longitude: -121.1500015,
    altitude: 3080,
    schengen: false
  },
  {
    id: 6877,
    name: 'Grand Forks Air Force Base',
    city: 'Red River',
    country: 'United States',
    iata: 'RDR',
    icao: 'KRDR',
    latitude: 47.961101532,
    longitude: -97.4011993408,
    altitude: 913,
    schengen: false
  },
  {
    id: 6878,
    name: 'Raleigh Durham International Airport',
    city: 'Raleigh-durham',
    country: 'United States',
    iata: 'RDU',
    icao: 'KRDU',
    latitude: 35.8776016235352,
    longitude: -78.7874984741211,
    altitude: 435,
    schengen: false
  },
  {
    id: 6879,
    name: 'Redlands Municipal Airport',
    city: 'Redlands',
    country: 'United States',
    iata: '',
    icao: 'KREI',
    latitude: 34.0853004455566,
    longitude: -117.146003723145,
    altitude: 1571,
    schengen: false
  },
  {
    id: 6880,
    name: 'Chicago Rockford International Airport',
    city: 'Rockford',
    country: 'United States',
    iata: 'RFD',
    icao: 'KRFD',
    latitude: 42.1954002380371,
    longitude: -89.0971984863281,
    altitude: 742,
    schengen: false
  },
  {
    id: 6881,
    name: 'Rhinelander Oneida County Airport',
    city: 'Rhinelander',
    country: 'United States',
    iata: 'RHI',
    icao: 'KRHI',
    latitude: 45.6311988830566,
    longitude: -89.4674987792969,
    altitude: 1624,
    schengen: false
  },
  {
    id: 6882,
    name: 'Reid-Hillview Airport of Santa Clara County',
    city: 'San Jose',
    country: 'United States',
    iata: 'RHV',
    icao: 'KRHV',
    latitude: 37.332901001,
    longitude: -121.819000244,
    altitude: 135,
    schengen: false
  },
  {
    id: 6883,
    name: 'Richmond International Airport',
    city: 'Richmond',
    country: 'United States',
    iata: 'RIC',
    icao: 'KRIC',
    latitude: 37.505199432373,
    longitude: -77.3197021484375,
    altitude: 167,
    schengen: false
  },
  {
    id: 6884,
    name: 'Richmond Municipal Airport',
    city: 'Richmond',
    country: 'United States',
    iata: '',
    icao: 'KRID',
    latitude: 39.7571983337402,
    longitude: -84.8427963256836,
    altitude: 1140,
    schengen: false
  },
  {
    id: 6885,
    name: 'Richfield Municipal Airport',
    city: 'Richfield',
    country: 'United States',
    iata: '',
    icao: 'KRIF',
    latitude: 38.736400604248,
    longitude: -112.098999023438,
    altitude: 5301,
    schengen: false
  },
  {
    id: 6886,
    name: 'Garfield County Regional Airport',
    city: 'Rifle',
    country: 'United States',
    iata: 'RIL',
    icao: 'KRIL',
    latitude: 39.526299,
    longitude: -107.726997,
    altitude: 5548,
    schengen: false
  },
  {
    id: 6887,
    name: 'Flabob Airport',
    city: 'Riverside',
    country: 'United States',
    iata: '',
    icao: 'KRIR',
    latitude: 33.9897003173828,
    longitude: -117.411003112793,
    altitude: 764,
    schengen: false
  },
  {
    id: 6888,
    name: 'Rancho Murieta Airport',
    city: 'Rancho Murieta',
    country: 'United States',
    iata: '',
    icao: 'KRIU',
    latitude: 38.4868011474609,
    longitude: -121.102996826172,
    altitude: 141,
    schengen: false
  },
  {
    id: 6889,
    name: 'March ARB Airport',
    city: 'Riverside',
    country: 'United States',
    iata: 'RIV',
    icao: 'KRIV',
    latitude: 33.880699,
    longitude: -117.259003,
    altitude: 1536,
    schengen: false
  },
  {
    id: 6890,
    name: 'Riverton Regional Airport',
    city: 'Riverton WY',
    country: 'United States',
    iata: 'RIW',
    icao: 'KRIW',
    latitude: 43.064201355,
    longitude: -108.459999084,
    altitude: 5525,
    schengen: false
  },
  {
    id: 6891,
    name: 'Ridgely Airpark',
    city: 'Ridgely',
    country: 'United States',
    iata: '',
    icao: 'KRJD',
    latitude: 38.970100402832,
    longitude: -75.8663024902344,
    altitude: 64,
    schengen: false
  },
  {
    id: 6892,
    name: 'Knox County Regional Airport',
    city: 'Rockland',
    country: 'United States',
    iata: 'RKD',
    icao: 'KRKD',
    latitude: 44.06010056,
    longitude: -69.09919739,
    altitude: 56,
    schengen: false
  },
  {
    id: 6893,
    name: 'Aransas County Airport',
    city: 'Rockport',
    country: 'United States',
    iata: 'RKP',
    icao: 'KRKP',
    latitude: 28.0867996216,
    longitude: -97.0446014404,
    altitude: 24,
    schengen: false
  },
  {
    id: 6894,
    name: 'Robert S Kerr Airport',
    city: 'Poteau',
    country: 'United States',
    iata: '',
    icao: 'KRKR',
    latitude: 35.021598815918,
    longitude: -94.6212997436524,
    altitude: 451,
    schengen: false
  },
  {
    id: 6895,
    name: 'Southwest Wyoming Regional Airport',
    city: 'Rock Springs',
    country: 'United States',
    iata: 'RKS',
    icao: 'KRKS',
    latitude: 41.5942,
    longitude: -109.065001,
    altitude: 6764,
    schengen: false
  },
  {
    id: 6896,
    name: 'Griffiss International Airport',
    city: 'Rome',
    country: 'United States',
    iata: 'RME',
    icao: 'KRME',
    latitude: 43.23379898,
    longitude: -75.40699768,
    altitude: 504,
    schengen: false
  },
  {
    id: 6897,
    name: 'Richard B Russell Airport',
    city: 'Rome',
    country: 'United States',
    iata: 'RMG',
    icao: 'KRMG',
    latitude: 34.3506011963,
    longitude: -85.1579971313,
    altitude: 644,
    schengen: false
  },
  {
    id: 6898,
    name: 'Brooks Field',
    city: 'Marshall',
    country: 'United States',
    iata: '',
    icao: 'KRMY',
    latitude: 42.2512016296387,
    longitude: -84.9554977416992,
    altitude: 941,
    schengen: false
  },
  {
    id: 6899,
    name: 'Randolph Air Force Base',
    city: 'San Antonio',
    country: 'United States',
    iata: 'RND',
    icao: 'KRND',
    latitude: 29.52969933,
    longitude: -98.27890015,
    altitude: 761,
    schengen: false
  },
  {
    id: 6900,
    name: 'New Richmond Regional Airport',
    city: 'New Richmond',
    country: 'United States',
    iata: '',
    icao: 'KRNH',
    latitude: 45.14830017,
    longitude: -92.5381012,
    altitude: 998,
    schengen: false
  },
  {
    id: 6901,
    name: 'Ramona Airport',
    city: 'Ramona',
    country: 'United States',
    iata: '',
    icao: 'KRNM',
    latitude: 33.0392,
    longitude: -116.915001,
    altitude: 1395,
    schengen: false
  },
  {
    id: 6902,
    name: 'Reno Tahoe International Airport',
    city: 'Reno',
    country: 'United States',
    iata: 'RNO',
    icao: 'KRNO',
    latitude: 39.4990997314453,
    longitude: -119.767997741699,
    altitude: 4415,
    schengen: false
  },
  {
    id: 6903,
    name: 'Renton Municipal Airport',
    city: 'Renton',
    country: 'United States',
    iata: 'RNT',
    icao: 'KRNT',
    latitude: 47.4930992126,
    longitude: -122.216003418,
    altitude: 32,
    schengen: false
  },
  {
    id: 6904,
    name: 'Roanoke–Blacksburg Regional Airport',
    city: 'Roanoke VA',
    country: 'United States',
    iata: 'ROA',
    icao: 'KROA',
    latitude: 37.3255,
    longitude: -79.975403,
    altitude: 1175,
    schengen: false
  },
  {
    id: 6905,
    name: 'Greater Rochester International Airport',
    city: 'Rochester',
    country: 'United States',
    iata: 'ROC',
    icao: 'KROC',
    latitude: 43.1189002990723,
    longitude: -77.6724014282227,
    altitude: 559,
    schengen: false
  },
  {
    id: 6906,
    name: 'Roswell International Air Center Airport',
    city: 'Roswell',
    country: 'United States',
    iata: 'ROW',
    icao: 'KROW',
    latitude: 33.3016014099121,
    longitude: -104.53099822998,
    altitude: 3671,
    schengen: false
  },
  {
    id: 6907,
    name: 'Russell Municipal Airport',
    city: 'Russell',
    country: 'United States',
    iata: 'RSL',
    icao: 'KRSL',
    latitude: 38.872100830078,
    longitude: -98.811798095703,
    altitude: 1862,
    schengen: false
  },
  {
    id: 6908,
    name: 'Rochester International Airport',
    city: 'Rochester',
    country: 'United States',
    iata: 'RST',
    icao: 'KRST',
    latitude: 43.9082984924316,
    longitude: -92.5,
    altitude: 1317,
    schengen: false
  },
  {
    id: 6909,
    name: 'Southwest Florida International Airport',
    city: 'Fort Myers',
    country: 'United States',
    iata: 'RSW',
    icao: 'KRSW',
    latitude: 26.5361995697021,
    longitude: -81.7552032470703,
    altitude: 30,
    schengen: false
  },
  {
    id: 6910,
    name: 'Raton Municipal-Crews Field',
    city: 'Raton',
    country: 'United States',
    iata: 'RTN',
    icao: 'KRTN',
    latitude: 36.74150085,
    longitude: -104.5019989,
    altitude: 6352,
    schengen: false
  },
  {
    id: 6911,
    name: 'Rutland - Southern Vermont Regional Airport',
    city: 'Rutland',
    country: 'United States',
    iata: 'RUT',
    icao: 'KRUT',
    latitude: 43.52939987,
    longitude: -72.94960022,
    altitude: 787,
    schengen: false
  },
  {
    id: 6912,
    name: 'Richard Lloyd Jones Jr Airport',
    city: 'Tulsa',
    country: 'United States',
    iata: 'RVS',
    icao: 'KRVS',
    latitude: 36.039600372314,
    longitude: -95.984596252441,
    altitude: 638,
    schengen: false
  },
  {
    id: 6913,
    name: 'Redwood Falls Municipal Airport',
    city: 'Redwood Falls',
    country: 'United States',
    iata: 'RWF',
    icao: 'KRWF',
    latitude: 44.54719925,
    longitude: -95.08229828,
    altitude: 1024,
    schengen: false
  },
  {
    id: 6914,
    name: 'Rocky Mount Wilson Regional Airport',
    city: 'Rocky Mount',
    country: 'United States',
    iata: 'RWI',
    icao: 'KRWI',
    latitude: 35.8563003540039,
    longitude: -77.8918991088867,
    altitude: 159,
    schengen: false
  },
  {
    id: 6915,
    name: 'Rawlins Municipal Airport/Harvey Field',
    city: 'Rawlins',
    country: 'United States',
    iata: 'RWL',
    icao: 'KRWL',
    latitude: 41.80559921,
    longitude: -107.1999969,
    altitude: 6813,
    schengen: false
  },
  {
    id: 6916,
    name: 'Ryan Field',
    city: 'Tucson',
    country: 'United States',
    iata: '',
    icao: 'KRYN',
    latitude: 32.1422004699707,
    longitude: -111.175003051758,
    altitude: 2417,
    schengen: false
  },
  {
    id: 6917,
    name: 'Lago Vista Tx Rusty Allen Airport',
    city: 'Lago Vista',
    country: 'United States',
    iata: '',
    icao: 'KRYW',
    latitude: 30.4986000061035,
    longitude: -97.9694976806641,
    altitude: 1231,
    schengen: false
  },
  {
    id: 6918,
    name: 'Cobb County-Mc Collum Field',
    city: 'Atlanta',
    country: 'United States',
    iata: '',
    icao: 'KRYY',
    latitude: 34.01319885,
    longitude: -84.59860229,
    altitude: 1040,
    schengen: false
  },
  {
    id: 6919,
    name: 'Jasper County Airport',
    city: 'Rensselaer',
    country: 'United States',
    iata: 'RNZ',
    icao: 'KRZL',
    latitude: 40.9478988647,
    longitude: -87.1826019287,
    altitude: 698,
    schengen: false
  },
  {
    id: 6920,
    name: 'Prineville Airport',
    city: 'Prineville',
    country: 'United States',
    iata: 'PRZ',
    icao: 'KS39',
    latitude: 44.286998748779,
    longitude: -120.90399932861,
    altitude: 3250,
    schengen: false
  },
  {
    id: 6921,
    name: 'Prosser Airport',
    city: 'Prosser',
    country: 'United States',
    iata: '',
    icao: 'KS40',
    latitude: 46.21340179,
    longitude: -119.7910004,
    altitude: 697,
    schengen: false
  },
  {
    id: 6922,
    name: 'Shively Field',
    city: 'SARATOGA',
    country: 'United States',
    iata: 'SAA',
    icao: 'KSAA',
    latitude: 41.444901,
    longitude: -106.823997,
    altitude: 7012,
    schengen: false
  },
  {
    id: 6923,
    name: 'Sacramento Executive Airport',
    city: 'Sacramento',
    country: 'United States',
    iata: 'SAC',
    icao: 'KSAC',
    latitude: 38.5125007629,
    longitude: -121.492996216,
    altitude: 24,
    schengen: false
  },
  {
    id: 6924,
    name: 'Safford Regional Airport',
    city: 'Safford',
    country: 'United States',
    iata: 'SAD',
    icao: 'KSAD',
    latitude: 32.85480118,
    longitude: -109.6350021,
    altitude: 3179,
    schengen: false
  },
  {
    id: 6925,
    name: 'Santa Fe Municipal Airport',
    city: 'Santa Fe',
    country: 'United States',
    iata: 'SAF',
    icao: 'KSAF',
    latitude: 35.617099762,
    longitude: -106.088996887,
    altitude: 6348,
    schengen: false
  },
  {
    id: 6926,
    name: 'San Diego International Airport',
    city: 'San Diego',
    country: 'United States',
    iata: 'SAN',
    icao: 'KSAN',
    latitude: 32.7336006165,
    longitude: -117.190002441,
    altitude: 17,
    schengen: false
  },
  {
    id: 6927,
    name: 'San Antonio International Airport',
    city: 'San Antonio',
    country: 'United States',
    iata: 'SAT',
    icao: 'KSAT',
    latitude: 29.5337009429932,
    longitude: -98.4698028564453,
    altitude: 809,
    schengen: false
  },
  {
    id: 6928,
    name: 'Savannah Hilton Head International Airport',
    city: 'Savannah',
    country: 'United States',
    iata: 'SAV',
    icao: 'KSAV',
    latitude: 32.12760162,
    longitude: -81.20210266,
    altitude: 50,
    schengen: false
  },
  {
    id: 6929,
    name: 'Sawyer International Airport',
    city: 'Gwinn',
    country: 'United States',
    iata: 'MQT',
    icao: 'KSAW',
    latitude: 46.3535995483,
    longitude: -87.395401001,
    altitude: 1221,
    schengen: false
  },
  {
    id: 6930,
    name: 'Santa Barbara Municipal Airport',
    city: 'Santa Barbara',
    country: 'United States',
    iata: 'SBA',
    icao: 'KSBA',
    latitude: 34.42620087,
    longitude: -119.8399963,
    altitude: 13,
    schengen: false
  },
  {
    id: 6931,
    name: 'San Bernardino International Airport',
    city: 'San Bernardino',
    country: 'United States',
    iata: 'SBD',
    icao: 'KSBD',
    latitude: 34.0954017639,
    longitude: -117.23500061,
    altitude: 1159,
    schengen: false
  },
  {
    id: 6932,
    name: 'Sheboygan County Memorial Airport',
    city: 'Sheboygan',
    country: 'United States',
    iata: 'SBM',
    icao: 'KSBM',
    latitude: 43.76959991,
    longitude: -87.85140228,
    altitude: 755,
    schengen: false
  },
  {
    id: 6933,
    name: 'South Bend Regional Airport',
    city: 'South Bend',
    country: 'United States',
    iata: 'SBN',
    icao: 'KSBN',
    latitude: 41.7086982727051,
    longitude: -86.3172988891602,
    altitude: 799,
    schengen: false
  },
  {
    id: 6934,
    name: 'Emanuel County Airport',
    city: 'Santa Barbara',
    country: 'United States',
    iata: '',
    icao: 'KSBO',
    latitude: 32.6091003417969,
    longitude: -82.3699035644531,
    altitude: 327,
    schengen: false
  },
  {
    id: 6935,
    name: 'San Luis County Regional Airport',
    city: 'San Luis Obispo',
    country: 'United States',
    iata: 'SBP',
    icao: 'KSBP',
    latitude: 35.2368011475,
    longitude: -120.641998291,
    altitude: 212,
    schengen: false
  },
  {
    id: 6936,
    name: 'Steamboat Springs Bob Adams Field',
    city: 'Steamboat Springs',
    country: 'United States',
    iata: 'SBS',
    icao: 'KSBS',
    latitude: 40.5163002,
    longitude: -106.8659973,
    altitude: 6882,
    schengen: false
  },
  {
    id: 6937,
    name: 'Salisbury Ocean City Wicomico Regional Airport',
    city: 'Salisbury',
    country: 'United States',
    iata: 'SBY',
    icao: 'KSBY',
    latitude: 38.3404998779297,
    longitude: -75.5102996826172,
    altitude: 52,
    schengen: false
  },
  {
    id: 6938,
    name: 'Merkel Field Sylacauga Municipal Airport',
    city: 'Sylacauga',
    country: 'United States',
    iata: '',
    icao: 'KSCD',
    latitude: 33.1717987060547,
    longitude: -86.3054962158203,
    altitude: 569,
    schengen: false
  },
  {
    id: 6939,
    name: 'Schenectady County Airport',
    city: 'Scotia NY',
    country: 'United States',
    iata: 'SCH',
    icao: 'KSCH',
    latitude: 42.852500915527,
    longitude: -73.928901672363,
    altitude: 378,
    schengen: false
  },
  {
    id: 6940,
    name: 'Stockton Metropolitan Airport',
    city: 'Stockton',
    country: 'United States',
    iata: 'SCK',
    icao: 'KSCK',
    latitude: 37.894199371338,
    longitude: -121.2379989624,
    altitude: 33,
    schengen: false
  },
  {
    id: 6941,
    name: 'Williamson Sodus Airport',
    city: 'Williamson',
    country: 'United States',
    iata: '',
    icao: 'KSDC',
    latitude: 43.23469925,
    longitude: -77.1210022,
    altitude: 424,
    schengen: false
  },
  {
    id: 6942,
    name: 'Louisville International Standiford Field',
    city: 'Louisville',
    country: 'United States',
    iata: 'SDF',
    icao: 'KSDF',
    latitude: 38.1744,
    longitude: -85.736,
    altitude: 501,
    schengen: false
  },
  {
    id: 6943,
    name: 'Scottsdale Airport',
    city: 'Scottsdale',
    country: 'United States',
    iata: 'SCF',
    icao: 'KSDL',
    latitude: 33.622898101807,
    longitude: -111.91100311279,
    altitude: 1510,
    schengen: false
  },
  {
    id: 6944,
    name: 'Brown Field Municipal Airport',
    city: 'San Diego',
    country: 'United States',
    iata: 'SDM',
    icao: 'KSDM',
    latitude: 32.572299957275,
    longitude: -116.98000335693,
    altitude: 526,
    schengen: false
  },
  {
    id: 6945,
    name: 'Sidney - Richland Regional Airport',
    city: 'Sidney',
    country: 'United States',
    iata: 'SDY',
    icao: 'KSDY',
    latitude: 47.706902,
    longitude: -104.193001,
    altitude: 1985,
    schengen: false
  },
  {
    id: 6946,
    name: 'Seattle Tacoma International Airport',
    city: 'Seattle',
    country: 'United States',
    iata: 'SEA',
    icao: 'KSEA',
    latitude: 47.449001,
    longitude: -122.308998,
    altitude: 433,
    schengen: false
  },
  {
    id: 6947,
    name: 'Gillespie Field',
    city: 'El Cajon',
    country: 'United States',
    iata: 'SEE',
    icao: 'KSEE',
    latitude: 32.826198577881,
    longitude: -116.97200012207,
    altitude: 388,
    schengen: false
  },
  {
    id: 6948,
    name: 'Sebring Regional Airport',
    city: 'Sebring',
    country: 'United States',
    iata: 'SEF',
    icao: 'KSEF',
    latitude: 27.45639992,
    longitude: -81.3423996,
    altitude: 62,
    schengen: false
  },
  {
    id: 6949,
    name: 'Craig Field',
    city: 'Selma',
    country: 'United States',
    iata: 'SEM',
    icao: 'KSEM',
    latitude: 32.343898773193,
    longitude: -86.987800598145,
    altitude: 166,
    schengen: false
  },
  {
    id: 6950,
    name: 'Stephenville Clark Regional Airport',
    city: 'Stephenville',
    country: 'United States',
    iata: 'SEP',
    icao: 'KSEP',
    latitude: 32.215301513672,
    longitude: -98.177696228027,
    altitude: 1321,
    schengen: false
  },
  {
    id: 6951,
    name: 'Sedona Airport',
    city: 'Sedona',
    country: 'United States',
    iata: 'SDX',
    icao: 'KSEZ',
    latitude: 34.848598480225,
    longitude: -111.78800201416,
    altitude: 4830,
    schengen: false
  },
  {
    id: 6952,
    name: 'Orlando Sanford International Airport',
    city: 'Sanford',
    country: 'United States',
    iata: 'SFB',
    icao: 'KSFB',
    latitude: 28.7775993347168,
    longitude: -81.2375030517578,
    altitude: 55,
    schengen: false
  },
  {
    id: 6953,
    name: 'Felts Field',
    city: 'Spokane',
    country: 'United States',
    iata: 'SFF',
    icao: 'KSFF',
    latitude: 47.682800292969,
    longitude: -117.32299804688,
    altitude: 1953,
    schengen: false
  },
  {
    id: 6954,
    name: 'San Francisco International Airport',
    city: 'San Francisco',
    country: 'United States',
    iata: 'SFO',
    icao: 'KSFO',
    latitude: 37.6189994812012,
    longitude: -122.375,
    altitude: 13,
    schengen: false
  },
  {
    id: 6955,
    name: 'North Central State Airport',
    city: 'Smithfield',
    country: 'United States',
    iata: 'SFZ',
    icao: 'KSFZ',
    latitude: 41.9207992554,
    longitude: -71.4914016724,
    altitude: 441,
    schengen: false
  },
  {
    id: 6956,
    name: 'Springfield Branson National Airport',
    city: 'Springfield',
    country: 'United States',
    iata: 'SGF',
    icao: 'KSGF',
    latitude: 37.24570084,
    longitude: -93.38860321,
    altitude: 1268,
    schengen: false
  },
  {
    id: 6957,
    name: 'Springfield-Beckley Municipal Airport',
    city: 'Springfield',
    country: 'United States',
    iata: 'SGH',
    icao: 'KSGH',
    latitude: 39.840301513672,
    longitude: -83.840202331543,
    altitude: 1051,
    schengen: false
  },
  {
    id: 6958,
    name: 'Northeast Florida Regional Airport',
    city: 'St. Augustine Airport',
    country: 'United States',
    iata: 'UST',
    icao: 'KSGJ',
    latitude: 29.9592,
    longitude: -81.339798,
    altitude: 10,
    schengen: false
  },
  {
    id: 6959,
    name: 'Sugar Land Regional Airport',
    city: 'Sugar Land',
    country: 'United States',
    iata: 'SGR',
    icao: 'KSGR',
    latitude: 29.622299194336,
    longitude: -95.65650177002,
    altitude: 82,
    schengen: false
  },
  {
    id: 6960,
    name: 'St George Municipal Airport',
    city: 'Saint George',
    country: 'United States',
    iata: 'SGU',
    icao: 'KSGU',
    latitude: 37.036389,
    longitude: -113.510306,
    altitude: 2941,
    schengen: false
  },
  {
    id: 6961,
    name: 'Shenandoah Valley Regional Airport',
    city: 'Weyers Cave',
    country: 'United States',
    iata: 'SHD',
    icao: 'KSHD',
    latitude: 38.2638015747,
    longitude: -78.8964004517,
    altitude: 1201,
    schengen: false
  },
  {
    id: 6962,
    name: 'Sanderson Field',
    city: 'Shelton',
    country: 'United States',
    iata: 'SHN',
    icao: 'KSHN',
    latitude: 47.233600616455,
    longitude: -123.14800262451,
    altitude: 273,
    schengen: false
  },
  {
    id: 6963,
    name: 'Sheridan County Airport',
    city: 'Sheridan',
    country: 'United States',
    iata: 'SHR',
    icao: 'KSHR',
    latitude: 44.7691993713379,
    longitude: -106.980003356934,
    altitude: 4021,
    schengen: false
  },
  {
    id: 6964,
    name: 'Shreveport Regional Airport',
    city: 'Shreveport',
    country: 'United States',
    iata: 'SHV',
    icao: 'KSHV',
    latitude: 32.446602,
    longitude: -93.8256,
    altitude: 258,
    schengen: false
  },
  {
    id: 6965,
    name: 'Sikeston Memorial Municipal Airport',
    city: 'Sikeston',
    country: 'United States',
    iata: 'SIK',
    icao: 'KSIK',
    latitude: 36.898899078369,
    longitude: -89.561798095703,
    altitude: 315,
    schengen: false
  },
  {
    id: 6966,
    name: 'Norman Y. Mineta San Jose International Airport',
    city: 'San Jose',
    country: 'United States',
    iata: 'SJC',
    icao: 'KSJC',
    latitude: 37.362598,
    longitude: -121.929001,
    altitude: 62,
    schengen: false
  },
  {
    id: 6967,
    name: 'San Angelo Regional Mathis Field',
    city: 'San Angelo',
    country: 'United States',
    iata: 'SJT',
    icao: 'KSJT',
    latitude: 31.3577003479004,
    longitude: -100.496002197266,
    altitude: 1919,
    schengen: false
  },
  {
    id: 6968,
    name: 'Fairchild Air Force Base',
    city: 'Spokane',
    country: 'United States',
    iata: 'SKA',
    icao: 'KSKA',
    latitude: 47.6151008606,
    longitude: -117.65599823,
    altitude: 2461,
    schengen: false
  },
  {
    id: 6969,
    name: 'Lackland Air Force Base',
    city: 'San Antonio',
    country: 'United States',
    iata: 'SKF',
    icao: 'KSKF',
    latitude: 29.38419914,
    longitude: -98.58110046,
    altitude: 691,
    schengen: false
  },
  {
    id: 6970,
    name: 'Taos Regional Airport',
    city: 'Taos',
    country: 'United States',
    iata: 'TSM',
    icao: 'KSKX',
    latitude: 36.45819855,
    longitude: -105.6719971,
    altitude: 7095,
    schengen: false
  },
  {
    id: 6971,
    name: 'Griffing Sandusky Airport',
    city: 'Sandusky',
    country: 'United States',
    iata: 'SKY',
    icao: 'KSKY',
    latitude: 41.4333992004,
    longitude: -82.6522979736,
    altitude: 580,
    schengen: false
  },
  {
    id: 6972,
    name: 'Salt Lake City International Airport',
    city: 'Salt Lake City',
    country: 'United States',
    iata: 'SLC',
    icao: 'KSLC',
    latitude: 40.7883987426758,
    longitude: -111.977996826172,
    altitude: 4227,
    schengen: false
  },
  {
    id: 6973,
    name: 'Salem Municipal Airport/McNary Field',
    city: 'Salem',
    country: 'United States',
    iata: 'SLE',
    icao: 'KSLE',
    latitude: 44.90950012,
    longitude: -123.0029984,
    altitude: 214,
    schengen: false
  },
  {
    id: 6974,
    name: 'Cheboygan County Airport',
    city: 'Cheboygan',
    country: 'United States',
    iata: '',
    icao: 'KSLH',
    latitude: 45.65370178,
    longitude: -84.51930237,
    altitude: 639,
    schengen: false
  },
  {
    id: 6975,
    name: 'Adirondack Regional Airport',
    city: 'Saranac Lake',
    country: 'United States',
    iata: 'SLK',
    icao: 'KSLK',
    latitude: 44.3852996826172,
    longitude: -74.2061996459961,
    altitude: 1663,
    schengen: false
  },
  {
    id: 6976,
    name: 'Salina Municipal Airport',
    city: 'Salina',
    country: 'United States',
    iata: 'SLN',
    icao: 'KSLN',
    latitude: 38.7910003662109,
    longitude: -97.6521987915039,
    altitude: 1288,
    schengen: false
  },
  {
    id: 6977,
    name: 'Smith Field',
    city: 'Fort Wayne IN',
    country: 'United States',
    iata: 'SMD',
    icao: 'KSMD',
    latitude: 41.14339828,
    longitude: -85.15280151,
    altitude: 835,
    schengen: false
  },
  {
    id: 6978,
    name: 'Lake Cumberland Regional Airport',
    city: 'Somerset',
    country: 'United States',
    iata: 'SME',
    icao: 'KSME',
    latitude: 37.0533981323,
    longitude: -84.6158981323,
    altitude: 927,
    schengen: false
  },
  {
    id: 6979,
    name: 'Sacramento International Airport',
    city: 'Sacramento',
    country: 'United States',
    iata: 'SMF',
    icao: 'KSMF',
    latitude: 38.6954002380371,
    longitude: -121.591003417969,
    altitude: 27,
    schengen: false
  },
  {
    id: 6980,
    name: 'Lemhi County Airport',
    city: 'Salmon',
    country: 'United States',
    iata: 'SMN',
    icao: 'KSMN',
    latitude: 45.1237983704,
    longitude: -113.880996704,
    altitude: 4043,
    schengen: false
  },
  {
    id: 6981,
    name: 'Santa Monica Municipal Airport',
    city: 'Santa Monica',
    country: 'United States',
    iata: 'SMO',
    icao: 'KSMO',
    latitude: 34.0158004761,
    longitude: -118.450996399,
    altitude: 177,
    schengen: false
  },
  {
    id: 6982,
    name: 'Somerset Airport',
    city: 'Somerville',
    country: 'United States',
    iata: '',
    icao: 'KSMQ',
    latitude: 40.6259994506836,
    longitude: -74.6701965332031,
    altitude: 105,
    schengen: false
  },
  {
    id: 6983,
    name: 'Santa Maria Pub/Capt G Allan Hancock Field',
    city: 'Santa Maria',
    country: 'United States',
    iata: 'SMX',
    icao: 'KSMX',
    latitude: 34.89889908,
    longitude: -120.4570007,
    altitude: 261,
    schengen: false
  },
  {
    id: 6984,
    name: 'John Wayne Airport-Orange County Airport',
    city: 'Santa Ana',
    country: 'United States',
    iata: 'SNA',
    icao: 'KSNA',
    latitude: 33.67570114,
    longitude: -117.8679962,
    altitude: 56,
    schengen: false
  },
  {
    id: 6985,
    name: 'Salinas Municipal Airport',
    city: 'Salinas',
    country: 'United States',
    iata: 'SNS',
    icao: 'KSNS',
    latitude: 36.662799835205,
    longitude: -121.60600280762,
    altitude: 85,
    schengen: false
  },
  {
    id: 6986,
    name: 'Sidney Municipal-Lloyd W Carr Field',
    city: 'Sidney',
    country: 'United States',
    iata: 'SNY',
    icao: 'KSNY',
    latitude: 41.10129929,
    longitude: -102.9850006,
    altitude: 4313,
    schengen: false
  },
  {
    id: 6987,
    name: 'Sonora Municipal Airport',
    city: 'Sonora',
    country: 'United States',
    iata: '',
    icao: 'KSOA',
    latitude: 30.585699081421,
    longitude: -100.6490020752,
    altitude: 2140,
    schengen: false
  },
  {
    id: 6988,
    name: 'Moore County Airport',
    city: 'Pinehurst-Southern Pines',
    country: 'United States',
    iata: 'SOP',
    icao: 'KSOP',
    latitude: 35.23740005,
    longitude: -79.3911972,
    altitude: 455,
    schengen: false
  },
  {
    id: 6989,
    name: 'Show Low Regional Airport',
    city: 'Show Low',
    country: 'United States',
    iata: 'SOW',
    icao: 'KSOW',
    latitude: 34.265499115,
    longitude: -110.005996704,
    altitude: 6415,
    schengen: false
  },
  {
    id: 6990,
    name: 'Spartanburg Downtown Memorial Airport',
    city: 'Spartangurg',
    country: 'United States',
    iata: 'SPA',
    icao: 'KSPA',
    latitude: 34.915699005127,
    longitude: -81.956497192383,
    altitude: 801,
    schengen: false
  },
  {
    id: 6991,
    name: 'Scappoose Industrial Airpark',
    city: 'San Luis',
    country: 'United States',
    iata: '',
    icao: 'KSPB',
    latitude: 45.7709999084473,
    longitude: -122.861999511719,
    altitude: 58,
    schengen: false
  },
  {
    id: 6992,
    name: 'Black Hills Airport-Clyde Ice Field',
    city: 'Spearfish-South Dakota',
    country: 'United States',
    iata: 'SPF',
    icao: 'KSPF',
    latitude: 44.48030090332,
    longitude: -103.78299713135,
    altitude: 3931,
    schengen: false
  },
  {
    id: 6993,
    name: 'Albert Whitted Airport',
    city: 'St. Petersburg',
    country: 'United States',
    iata: 'SPG',
    icao: 'KSPG',
    latitude: 27.7651,
    longitude: -82.626999,
    altitude: 7,
    schengen: false
  },
  {
    id: 6994,
    name: 'Abraham Lincoln Capital Airport',
    city: 'Springfield',
    country: 'United States',
    iata: 'SPI',
    icao: 'KSPI',
    latitude: 39.84410095,
    longitude: -89.67790222,
    altitude: 598,
    schengen: false
  },
  {
    id: 6995,
    name: 'Sheppard Air Force Base-Wichita Falls Municipal Airport',
    city: 'Wichita Falls',
    country: 'United States',
    iata: 'SPS',
    icao: 'KSPS',
    latitude: 33.9888,
    longitude: -98.491898,
    altitude: 1019,
    schengen: false
  },
  {
    id: 6996,
    name: 'Spencer Municipal Airport',
    city: 'Spencer',
    country: 'United States',
    iata: 'SPW',
    icao: 'KSPW',
    latitude: 43.165500640869,
    longitude: -95.202796936035,
    altitude: 1339,
    schengen: false
  },
  {
    id: 6997,
    name: 'Silver Springs Airport',
    city: 'Silver Springs',
    country: 'United States',
    iata: '',
    icao: 'KSPZ',
    latitude: 39.4029998779297,
    longitude: -119.250999450684,
    altitude: 4269,
    schengen: false
  },
  {
    id: 6998,
    name: 'San Carlos Airport',
    city: 'San Carlos',
    country: 'United States',
    iata: 'SQL',
    icao: 'KSQL',
    latitude: 37.511901855469,
    longitude: -122.25,
    altitude: 5,
    schengen: false
  },
  {
    id: 6999,
    name: 'Sarasota Bradenton International Airport',
    city: 'Sarasota',
    country: 'United States',
    iata: 'SRQ',
    icao: 'KSRQ',
    latitude: 27.3953990936279,
    longitude: -82.5543975830078,
    altitude: 30,
    schengen: false
  },
  {
    id: 7000,
    name: 'Sierra Blanca Regional Airport',
    city: 'Ruidoso',
    country: 'United States',
    iata: 'RUI',
    icao: 'KSRR',
    latitude: 33.462799072266,
    longitude: -105.53500366211,
    altitude: 6814,
    schengen: false
  },
  {
    id: 7001,
    name: 'Shaw Air Force Base',
    city: 'Sumter',
    country: 'United States',
    iata: 'SSC',
    icao: 'KSSC',
    latitude: 33.97269821,
    longitude: -80.47059631,
    altitude: 241,
    schengen: false
  },
  {
    id: 7002,
    name: 'Stinson Municipal Airport',
    city: 'Stinson',
    country: 'United States',
    iata: 'SSF',
    icao: 'KSSF',
    latitude: 29.336999893188,
    longitude: -98.471099853516,
    altitude: 577,
    schengen: false
  },
  {
    id: 7003,
    name: 'Malcolm McKinnon Airport',
    city: 'Brunswick',
    country: 'United States',
    iata: 'SSI',
    icao: 'KSSI',
    latitude: 31.15180016,
    longitude: -81.39129639,
    altitude: 19,
    schengen: false
  },
  {
    id: 7004,
    name: 'St Cloud Regional Airport',
    city: 'Saint Cloud',
    country: 'United States',
    iata: 'STC',
    icao: 'KSTC',
    latitude: 45.5466003417969,
    longitude: -94.0598983764648,
    altitude: 1031,
    schengen: false
  },
  {
    id: 7005,
    name: 'Stevens Point Municipal Airport',
    city: 'Stevens Point',
    country: 'United States',
    iata: 'STE',
    icao: 'KSTE',
    latitude: 44.5452003479,
    longitude: -89.530296325684,
    altitude: 1110,
    schengen: false
  },
  {
    id: 7006,
    name: 'Rosecrans Memorial Airport',
    city: 'Rosecrans',
    country: 'United States',
    iata: 'STJ',
    icao: 'KSTJ',
    latitude: 39.771900177002,
    longitude: -94.909698486328,
    altitude: 826,
    schengen: false
  },
  {
    id: 7007,
    name: 'Sterling Municipal Airport',
    city: 'Sterling',
    country: 'United States',
    iata: 'STK',
    icao: 'KSTK',
    latitude: 40.61529922,
    longitude: -103.2649994,
    altitude: 4040,
    schengen: false
  },
  {
    id: 7008,
    name: 'St Louis Lambert International Airport',
    city: 'St. Louis',
    country: 'United States',
    iata: 'STL',
    icao: 'KSTL',
    latitude: 38.748697,
    longitude: -90.370003,
    altitude: 618,
    schengen: false
  },
  {
    id: 7009,
    name: 'St Paul Downtown Holman Field',
    city: 'St. Paul',
    country: 'United States',
    iata: 'STP',
    icao: 'KSTP',
    latitude: 44.9345016479492,
    longitude: -93.0599975585938,
    altitude: 705,
    schengen: false
  },
  {
    id: 7010,
    name: 'Charles M. Schulz Sonoma County Airport',
    city: 'Santa Rosa',
    country: 'United States',
    iata: 'STS',
    icao: 'KSTS',
    latitude: 38.50899887,
    longitude: -122.8130035,
    altitude: 128,
    schengen: false
  },
  {
    id: 7011,
    name: 'Witham Field',
    city: 'Stuart',
    country: 'United States',
    iata: 'SUA',
    icao: 'KSUA',
    latitude: 27.18169975,
    longitude: -80.22109985,
    altitude: 16,
    schengen: false
  },
  {
    id: 7012,
    name: 'Friedman Memorial Airport',
    city: 'Hailey',
    country: 'United States',
    iata: 'SUN',
    icao: 'KSUN',
    latitude: 43.50439835,
    longitude: -114.2959976,
    altitude: 5318,
    schengen: false
  },
  {
    id: 7013,
    name: 'Spirit of St. Louis Airport',
    city: 'St. Louis',
    country: 'United States',
    iata: 'SUS',
    icao: 'KSUS',
    latitude: 38.662101745605,
    longitude: -90.652000427246,
    altitude: 463,
    schengen: false
  },
  {
    id: 7014,
    name: 'Brunswick County Airport',
    city: 'Oak Island',
    country: 'United States',
    iata: '',
    icao: 'KSUT',
    latitude: 33.9292984,
    longitude: -78.07499695,
    altitude: 24,
    schengen: false
  },
  {
    id: 7015,
    name: 'Travis Air Force Base',
    city: 'Fairfield',
    country: 'United States',
    iata: 'SUU',
    icao: 'KSUU',
    latitude: 38.262699127197,
    longitude: -121.92700195312,
    altitude: 62,
    schengen: false
  },
  {
    id: 7016,
    name: 'Sioux Gateway Col. Bud Day Field',
    city: 'Sioux City',
    country: 'United States',
    iata: 'SUX',
    icao: 'KSUX',
    latitude: 42.40259933,
    longitude: -96.38439941,
    altitude: 1098,
    schengen: false
  },
  {
    id: 7017,
    name: 'Saline County Regional Airport',
    city: 'Benton',
    country: 'United States',
    iata: '',
    icao: 'KSUZ',
    latitude: 34.59059906,
    longitude: -92.47940063,
    altitude: 390,
    schengen: false
  },
  {
    id: 7018,
    name: 'Statesville Regional Airport',
    city: 'Statesville',
    country: 'United States',
    iata: 'SVH',
    icao: 'KSVH',
    latitude: 35.765300750732,
    longitude: -80.953903198242,
    altitude: 968,
    schengen: false
  },
  {
    id: 7019,
    name: 'Hunter Army Air Field',
    city: 'Hunter Aaf',
    country: 'United States',
    iata: 'SVN',
    icao: 'KSVN',
    latitude: 32.00999832,
    longitude: -81.14569855,
    altitude: 41,
    schengen: false
  },
  {
    id: 7020,
    name: 'Stewart International Airport',
    city: 'Newburgh',
    country: 'United States',
    iata: 'SWF',
    icao: 'KSWF',
    latitude: 41.5041007995606,
    longitude: -74.1047973632813,
    altitude: 491,
    schengen: false
  },
  {
    id: 7021,
    name: 'Stillwater Regional Airport',
    city: 'Stillwater',
    country: 'United States',
    iata: 'SWO',
    icao: 'KSWO',
    latitude: 36.161201477051,
    longitude: -97.08570098877,
    altitude: 1000,
    schengen: false
  },
  {
    id: 7022,
    name: 'Syracuse Hancock International Airport',
    city: 'Syracuse',
    country: 'United States',
    iata: 'SYR',
    icao: 'KSYR',
    latitude: 43.111198425293,
    longitude: -76.1063003540039,
    altitude: 421,
    schengen: false
  },
  {
    id: 7023,
    name: 'Whiteman Air Force Base',
    city: 'Knobnoster',
    country: 'United States',
    iata: 'SZL',
    icao: 'KSZL',
    latitude: 38.73030090332,
    longitude: -93.547897338867,
    altitude: 870,
    schengen: false
  },
  {
    id: 7024,
    name: 'Sandpoint Airport',
    city: 'Sandpoint',
    country: 'United States',
    iata: '',
    icao: 'KSZT',
    latitude: 48.299499511719,
    longitude: -116.55999755859,
    altitude: 2131,
    schengen: false
  },
  {
    id: 7025,
    name: 'Taunton Municipal King Field',
    city: 'Taunton',
    country: 'United States',
    iata: '',
    icao: 'KTAN',
    latitude: 41.8744010925293,
    longitude: -71.0166015625,
    altitude: 43,
    schengen: false
  },
  {
    id: 7026,
    name: 'Waynesville-St. Robert Regional Forney field',
    city: 'Fort Leonardwood',
    country: 'United States',
    iata: 'TBN',
    icao: 'KTBN',
    latitude: 37.74160004,
    longitude: -92.14070129,
    altitude: 1159,
    schengen: false
  },
  {
    id: 7027,
    name: 'Statesboro Bulloch County Airport',
    city: 'Statesboro',
    country: 'United States',
    iata: 'TBR',
    icao: 'KTBR',
    latitude: 32.4827003479,
    longitude: -81.7369003296,
    altitude: 187,
    schengen: false
  },
  {
    id: 7028,
    name: 'Tucumcari Municipal Airport',
    city: 'Tucumcari',
    country: 'United States',
    iata: 'TCC',
    icao: 'KTCC',
    latitude: 35.182800293,
    longitude: -103.602996826,
    altitude: 4065,
    schengen: false
  },
  {
    id: 7029,
    name: 'Tuscaloosa Regional Airport',
    city: 'Tuscaloosa AL',
    country: 'United States',
    iata: 'TCL',
    icao: 'KTCL',
    latitude: 33.220600128174,
    longitude: -87.611396789551,
    altitude: 170,
    schengen: false
  },
  {
    id: 7030,
    name: 'McChord Air Force Base',
    city: 'Tacoma',
    country: 'United States',
    iata: 'TCM',
    icao: 'KTCM',
    latitude: 47.1376991272,
    longitude: -122.475997925,
    altitude: 322,
    schengen: false
  },
  {
    id: 7031,
    name: 'Truth Or Consequences Municipal Airport',
    city: 'Truth Or Consequences',
    country: 'United States',
    iata: 'TCS',
    icao: 'KTCS',
    latitude: 33.2369003296,
    longitude: -107.272003174,
    altitude: 4853,
    schengen: false
  },
  {
    id: 7032,
    name: 'Tracy Municipal Airport',
    city: 'Tracy',
    country: 'United States',
    iata: '',
    icao: 'KTCY',
    latitude: 37.6889991760254,
    longitude: -121.442001342773,
    altitude: 193,
    schengen: false
  },
  {
    id: 7033,
    name: 'Person County Airport',
    city: 'Roxboro',
    country: 'United States',
    iata: '',
    icao: 'KTDF',
    latitude: 36.28490067,
    longitude: -78.98419952,
    altitude: 609,
    schengen: false
  },
  {
    id: 7034,
    name: 'Teterboro Airport',
    city: 'Teterboro',
    country: 'United States',
    iata: 'TEB',
    icao: 'KTEB',
    latitude: 40.8501014709,
    longitude: -74.060798645,
    altitude: 9,
    schengen: false
  },
  {
    id: 7035,
    name: 'Telluride Regional Airport',
    city: 'Telluride',
    country: 'United States',
    iata: 'TEX',
    icao: 'KTEX',
    latitude: 37.9538002,
    longitude: -107.9079971,
    altitude: 9070,
    schengen: false
  },
  {
    id: 7036,
    name: 'Tinker Air Force Base',
    city: 'Oklahoma City',
    country: 'United States',
    iata: 'TIK',
    icao: 'KTIK',
    latitude: 35.414699554443,
    longitude: -97.386596679688,
    altitude: 1291,
    schengen: false
  },
  {
    id: 7037,
    name: 'Tacoma Narrows Airport',
    city: 'Tacoma',
    country: 'United States',
    iata: 'TIW',
    icao: 'KTIW',
    latitude: 47.26789856,
    longitude: -122.5780029,
    altitude: 294,
    schengen: false
  },
  {
    id: 7038,
    name: 'Space Coast Regional Airport',
    city: 'Titusville',
    country: 'United States',
    iata: 'TIX',
    icao: 'KTIX',
    latitude: 28.514799118042,
    longitude: -80.799201965332,
    altitude: 34,
    schengen: false
  },
  {
    id: 7039,
    name: 'Collin County Regional At Mc Kinney Airport',
    city: 'DALLAS',
    country: 'United States',
    iata: '',
    icao: 'KTKI',
    latitude: 33.17789841,
    longitude: -96.59049988,
    altitude: 585,
    schengen: false
  },
  {
    id: 7040,
    name: 'Tallahassee Regional Airport',
    city: 'Tallahassee',
    country: 'United States',
    iata: 'TLH',
    icao: 'KTLH',
    latitude: 30.3964996337891,
    longitude: -84.3503036499024,
    altitude: 81,
    schengen: false
  },
  {
    id: 7041,
    name: 'Henry Tift Myers Airport',
    city: 'Tifton',
    country: 'United States',
    iata: 'TMA',
    icao: 'KTMA',
    latitude: 31.4290008545,
    longitude: -83.4885025024,
    altitude: 355,
    schengen: false
  },
  {
    id: 7042,
    name: 'Kendall-Tamiami Executive Airport',
    city: 'Kendall-tamiami',
    country: 'United States',
    iata: 'TMB',
    icao: 'KTMB',
    latitude: 25.6478996277,
    longitude: -80.432800293,
    altitude: 8,
    schengen: false
  },
  {
    id: 7043,
    name: 'Tillamook Airport',
    city: 'Tillamook',
    country: 'United States',
    iata: 'OTK',
    icao: 'KTMK',
    latitude: 45.4182014465,
    longitude: -123.814002991,
    altitude: 36,
    schengen: false
  },
  {
    id: 7044,
    name: 'Dade Collier Training and Transition Airport',
    city: 'Miami',
    country: 'United States',
    iata: 'TNT',
    icao: 'KTNT',
    latitude: 25.861799240112,
    longitude: -80.897003173828,
    altitude: 13,
    schengen: false
  },
  {
    id: 7045,
    name: 'Tonopah Test Range Airport',
    city: 'Tonopah',
    country: 'United States',
    iata: 'XSD',
    icao: 'KTNX',
    latitude: 37.7988014221,
    longitude: -116.78099823,
    altitude: 5549,
    schengen: false
  },
  {
    id: 7046,
    name: 'Zamperini Field',
    city: 'Torrance',
    country: 'United States',
    iata: 'TOA',
    icao: 'KTOA',
    latitude: 33.803398132324,
    longitude: -118.33999633789,
    altitude: 103,
    schengen: false
  },
  {
    id: 7047,
    name: 'Toccoa Airport - R.G. Letourneau Field',
    city: 'Toccoa',
    country: 'United States',
    iata: 'TOC',
    icao: 'KTOC',
    latitude: 34.59379959,
    longitude: -83.29579926,
    altitude: 996,
    schengen: false
  },
  {
    id: 7048,
    name: 'Troy Municipal Airport at N Kenneth Campbell Field',
    city: 'Troy',
    country: 'United States',
    iata: 'TOI',
    icao: 'KTOI',
    latitude: 31.860399,
    longitude: -86.012101,
    altitude: 398,
    schengen: false
  },
  {
    id: 7049,
    name: 'Toledo Express Airport',
    city: 'Toledo',
    country: 'United States',
    iata: 'TOL',
    icao: 'KTOL',
    latitude: 41.58679962,
    longitude: -83.80780029,
    altitude: 683,
    schengen: false
  },
  {
    id: 7050,
    name: 'Philip Billard Municipal Airport',
    city: 'Topeka',
    country: 'United States',
    iata: 'TOP',
    icao: 'KTOP',
    latitude: 39.068698883057,
    longitude: -95.622497558594,
    altitude: 881,
    schengen: false
  },
  {
    id: 7051,
    name: 'Tampa International Airport',
    city: 'Tampa',
    country: 'United States',
    iata: 'TPA',
    icao: 'KTPA',
    latitude: 27.9755001068115,
    longitude: -82.533203125,
    altitude: 26,
    schengen: false
  },
  {
    id: 7052,
    name: 'Peter O Knight Airport',
    city: 'Tampa',
    country: 'United States',
    iata: 'TPF',
    icao: 'KTPF',
    latitude: 27.915599822998,
    longitude: -82.44930267334,
    altitude: 8,
    schengen: false
  },
  {
    id: 7053,
    name: 'Tonopah Airport',
    city: 'Tonopah',
    country: 'United States',
    iata: 'TPH',
    icao: 'KTPH',
    latitude: 38.06019974,
    longitude: -117.086998,
    altitude: 5430,
    schengen: false
  },
  {
    id: 7054,
    name: 'Draughon Miller Central Texas Regional Airport',
    city: 'Temple',
    country: 'United States',
    iata: 'TPL',
    icao: 'KTPL',
    latitude: 31.1525001525879,
    longitude: -97.4077987670898,
    altitude: 682,
    schengen: false
  },
  {
    id: 7055,
    name: 'Scott City Municipal Airport',
    city: 'Scott City',
    country: 'United States',
    iata: '',
    icao: 'KTQK',
    latitude: 38.4743003845215,
    longitude: -100.88500213623,
    altitude: 2963,
    schengen: false
  },
  {
    id: 7056,
    name: 'Tri-Cities Regional TN/VA Airport',
    city: 'BRISTOL',
    country: 'United States',
    iata: 'TRI',
    icao: 'KTRI',
    latitude: 36.475201,
    longitude: -82.407401,
    altitude: 1519,
    schengen: false
  },
  {
    id: 7057,
    name: 'Truckee Tahoe Airport',
    city: 'Truckee',
    country: 'United States',
    iata: 'TKF',
    icao: 'KTRK',
    latitude: 39.3199996948,
    longitude: -120.13999939,
    altitude: 5900,
    schengen: false
  },
  {
    id: 7058,
    name: 'Jacqueline Cochran Regional Airport',
    city: 'Palm Springs',
    country: 'United States',
    iata: 'TRM',
    icao: 'KTRM',
    latitude: 33.62670135498,
    longitude: -116.16000366211,
    altitude: -115,
    schengen: false
  },
  {
    id: 7059,
    name: 'Portland Troutdale Airport',
    city: 'Troutdale',
    country: 'United States',
    iata: 'TTD',
    icao: 'KTTD',
    latitude: 45.54940032959,
    longitude: -122.40100097656,
    altitude: 39,
    schengen: false
  },
  {
    id: 7060,
    name: 'Trenton Mercer Airport',
    city: 'Trenton',
    country: 'United States',
    iata: 'TTN',
    icao: 'KTTN',
    latitude: 40.2766990661621,
    longitude: -74.8134994506836,
    altitude: 213,
    schengen: false
  },
  {
    id: 7061,
    name: 'Tulsa International Airport',
    city: 'Tulsa',
    country: 'United States',
    iata: 'TUL',
    icao: 'KTUL',
    latitude: 36.1983985900879,
    longitude: -95.8880996704102,
    altitude: 677,
    schengen: false
  },
  {
    id: 7062,
    name: 'Tupelo Regional Airport',
    city: 'Tupelo',
    country: 'United States',
    iata: 'TUP',
    icao: 'KTUP',
    latitude: 34.2681007385254,
    longitude: -88.7698974609375,
    altitude: 346,
    schengen: false
  },
  {
    id: 7063,
    name: 'Tucson International Airport',
    city: 'Tucson',
    country: 'United States',
    iata: 'TUS',
    icao: 'KTUS',
    latitude: 32.1161003112793,
    longitude: -110.94100189209,
    altitude: 2643,
    schengen: false
  },
  {
    id: 7064,
    name: 'Cherry Capital Airport',
    city: 'Traverse City',
    country: 'United States',
    iata: 'TVC',
    icao: 'KTVC',
    latitude: 44.7414016723633,
    longitude: -85.5821990966797,
    altitude: 624,
    schengen: false
  },
  {
    id: 7065,
    name: 'Thief River Falls Regional Airport',
    city: 'Thief River Falls',
    country: 'United States',
    iata: 'TVF',
    icao: 'KTVF',
    latitude: 48.06570053,
    longitude: -96.18499756,
    altitude: 1119,
    schengen: false
  },
  {
    id: 7066,
    name: 'Thomasville Regional Airport',
    city: 'Thomasville',
    country: 'United States',
    iata: 'TVI',
    icao: 'KTVI',
    latitude: 30.901599884033,
    longitude: -83.881301879883,
    altitude: 264,
    schengen: false
  },
  {
    id: 7067,
    name: 'Lake Tahoe Airport',
    city: 'South Lake Tahoe',
    country: 'United States',
    iata: 'TVL',
    icao: 'KTVL',
    latitude: 38.8939018249512,
    longitude: -119.995002746582,
    altitude: 6264,
    schengen: false
  },
  {
    id: 7068,
    name: 'Joslin Field Magic Valley Regional Airport',
    city: 'Twin Falls',
    country: 'United States',
    iata: 'TWF',
    icao: 'KTWF',
    latitude: 42.4818,
    longitude: -114.487999,
    altitude: 4154,
    schengen: false
  },
  {
    id: 7069,
    name: 'Texarkana Regional Webb Field',
    city: 'Texarkana',
    country: 'United States',
    iata: 'TXK',
    icao: 'KTXK',
    latitude: 33.4537010192871,
    longitude: -93.9909973144531,
    altitude: 390,
    schengen: false
  },
  {
    id: 7070,
    name: 'Tyler Pounds Regional Airport',
    city: 'Tyler',
    country: 'United States',
    iata: 'TYR',
    icao: 'KTYR',
    latitude: 32.3540992736816,
    longitude: -95.4023971557617,
    altitude: 544,
    schengen: false
  },
  {
    id: 7071,
    name: 'McGhee Tyson Airport',
    city: 'Knoxville',
    country: 'United States',
    iata: 'TYS',
    icao: 'KTYS',
    latitude: 35.81100082,
    longitude: -83.9940033,
    altitude: 981,
    schengen: false
  },
  {
    id: 7072,
    name: 'Bolton Field',
    city: 'Columbus',
    country: 'United States',
    iata: '',
    icao: 'KTZR',
    latitude: 39.9011993408203,
    longitude: -83.1369018554688,
    altitude: 905,
    schengen: false
  },
  {
    id: 7073,
    name: 'Aurora State Airport',
    city: 'Aurora',
    country: 'United States',
    iata: '',
    icao: 'KUAO',
    latitude: 45.2471008300781,
    longitude: -122.769996643066,
    altitude: 200,
    schengen: false
  },
  {
    id: 7074,
    name: 'Bermuda Dunes Airport',
    city: 'Palm Springs',
    country: 'United States',
    iata: 'UDD',
    icao: 'KUDD',
    latitude: 33.748401641846,
    longitude: -116.27500152588,
    altitude: 73,
    schengen: false
  },
  {
    id: 7075,
    name: 'Darlington County Airport',
    city: 'Darlington',
    country: 'United States',
    iata: '',
    icao: 'KUDG',
    latitude: 34.449401,
    longitude: -79.890098,
    altitude: 192,
    schengen: false
  },
  {
    id: 7076,
    name: 'Waukegan National Airport',
    city: 'Chicago',
    country: 'United States',
    iata: 'UGN',
    icao: 'KUGN',
    latitude: 42.422199249268,
    longitude: -87.867897033691,
    altitude: 727,
    schengen: false
  },
  {
    id: 7077,
    name: 'Quincy Regional Baldwin Field',
    city: 'Quincy',
    country: 'United States',
    iata: 'UIN',
    icao: 'KUIN',
    latitude: 39.94269943,
    longitude: -91.19460297,
    altitude: 768,
    schengen: false
  },
  {
    id: 7078,
    name: 'Wilkes County Airport',
    city: 'North Wilkesboro',
    country: 'United States',
    iata: 'IKB',
    icao: 'KUKF',
    latitude: 36.2228012085,
    longitude: -81.0982971191,
    altitude: 1301,
    schengen: false
  },
  {
    id: 7079,
    name: 'Ukiah Municipal Airport',
    city: 'Ukiah',
    country: 'United States',
    iata: 'UKI',
    icao: 'KUKI',
    latitude: 39.125999450684,
    longitude: -123.20099639893,
    altitude: 614,
    schengen: false
  },
  {
    id: 7080,
    name: 'Quakertown Airport',
    city: 'Quakertown',
    country: 'United States',
    iata: 'UKT',
    icao: 'KUKT',
    latitude: 40.435199737549,
    longitude: -75.381896972656,
    altitude: 526,
    schengen: false
  },
  {
    id: 7081,
    name: 'Ulysses Airport',
    city: 'Ulysses',
    country: 'United States',
    iata: '',
    icao: 'KULS',
    latitude: 37.60400009,
    longitude: -101.3740005,
    altitude: 3071,
    schengen: false
  },
  {
    id: 7082,
    name: 'Indianapolis Metropolitan Airport',
    city: 'Indianapolis',
    country: 'United States',
    iata: '',
    icao: 'KUMP',
    latitude: 39.93519974,
    longitude: -86.04499817,
    altitude: 811,
    schengen: false
  },
  {
    id: 7083,
    name: 'Ohio University Snyder Field',
    city: 'Athens',
    country: 'United States',
    iata: 'ATO',
    icao: 'KUNI',
    latitude: 39.2109985352,
    longitude: -82.2313995361,
    altitude: 766,
    schengen: false
  },
  {
    id: 7084,
    name: 'University Park Airport',
    city: 'State College Pennsylvania',
    country: 'United States',
    iata: 'SCE',
    icao: 'KUNV',
    latitude: 40.8493003845,
    longitude: -77.8487014771,
    altitude: 1239,
    schengen: false
  },
  {
    id: 7085,
    name: 'Franklin County Airport',
    city: 'Sewanee',
    country: 'United States',
    iata: 'UOS',
    icao: 'KUOS',
    latitude: 35.205101013184,
    longitude: -85.898101806641,
    altitude: 1953,
    schengen: false
  },
  {
    id: 7086,
    name: 'University Oxford Airport',
    city: 'Oxford',
    country: 'United States',
    iata: 'UOX',
    icao: 'KUOX',
    latitude: 34.384300231934,
    longitude: -89.536796569824,
    altitude: 452,
    schengen: false
  },
  {
    id: 7087,
    name: 'Tunica Municipal Airport',
    city: 'Tunica',
    country: 'United States',
    iata: 'UTM',
    icao: 'KUTA',
    latitude: 34.680999755859,
    longitude: -90.346702575684,
    altitude: 194,
    schengen: false
  },
  {
    id: 7088,
    name: 'Huntsville Regional Airport',
    city: 'Huntsville',
    country: 'United States',
    iata: 'HTV',
    icao: 'KUTS',
    latitude: 30.7469005585,
    longitude: -95.5871963501,
    altitude: 363,
    schengen: false
  },
  {
    id: 7089,
    name: 'Garner Field',
    city: 'Uvalde',
    country: 'United States',
    iata: 'UVA',
    icao: 'KUVA',
    latitude: 29.2112998962,
    longitude: -99.743598938,
    altitude: 942,
    schengen: false
  },
  {
    id: 7090,
    name: 'New Castle Henry Co. Municipal Airport',
    city: 'New Castle',
    country: 'United States',
    iata: '',
    icao: 'KUWL',
    latitude: 39.87590027,
    longitude: -85.32649994,
    altitude: 1088,
    schengen: false
  },
  {
    id: 7091,
    name: 'Madison County Airport',
    city: 'London',
    country: 'United States',
    iata: '',
    icao: 'KUYF',
    latitude: 39.93270111,
    longitude: -83.46199799,
    altitude: 1082,
    schengen: false
  },
  {
    id: 7092,
    name: 'Rock Hill - York County Airport',
    city: 'Rock Hill',
    country: 'United States',
    iata: 'RKH',
    icao: 'KUZA',
    latitude: 34.9878006,
    longitude: -81.05719757,
    altitude: 666,
    schengen: false
  },
  {
    id: 7093,
    name: 'Moody Air Force Base',
    city: 'Valdosta',
    country: 'United States',
    iata: 'VAD',
    icao: 'KVAD',
    latitude: 30.9678001404,
    longitude: -83.1930007935,
    altitude: 233,
    schengen: false
  },
  {
    id: 7094,
    name: 'South Jersey Regional Airport',
    city: 'Mount Holly',
    country: 'United States',
    iata: 'LLY',
    icao: 'KVAY',
    latitude: 39.9429016113,
    longitude: -74.845703125,
    altitude: 53,
    schengen: false
  },
  {
    id: 7095,
    name: 'Vandenberg Air Force Base',
    city: 'Lompoc',
    country: 'United States',
    iata: 'VBG',
    icao: 'KVBG',
    latitude: 34.7373008728,
    longitude: -120.583999634,
    altitude: 369,
    schengen: false
  },
  {
    id: 7096,
    name: 'Bentonville Municipal-Louise M Thaden Field',
    city: 'Bentonville',
    country: 'United States',
    iata: '',
    icao: 'KVBT',
    latitude: 36.34569931,
    longitude: -94.21929932,
    altitude: 1296,
    schengen: false
  },
  {
    id: 7097,
    name: 'Victoria Regional Airport',
    city: 'Victoria',
    country: 'United States',
    iata: 'VCT',
    icao: 'KVCT',
    latitude: 28.8526000976562,
    longitude: -96.9185028076172,
    altitude: 115,
    schengen: false
  },
  {
    id: 7098,
    name: 'Southern California Logistics Airport',
    city: 'Victorville',
    country: 'United States',
    iata: 'VCV',
    icao: 'KVCV',
    latitude: 34.5974998474,
    longitude: -117.383003235,
    altitude: 2885,
    schengen: false
  },
  {
    id: 7099,
    name: 'Vernal Regional Airport',
    city: 'Vernal',
    country: 'United States',
    iata: 'VEL',
    icao: 'KVEL',
    latitude: 40.4408989,
    longitude: -109.5100021,
    altitude: 5278,
    schengen: false
  },
  {
    id: 7100,
    name: 'North Las Vegas Airport',
    city: 'Las Vegas',
    country: 'United States',
    iata: 'VGT',
    icao: 'KVGT',
    latitude: 36.21070098877,
    longitude: -115.19400024414,
    altitude: 2205,
    schengen: false
  },
  {
    id: 7101,
    name: 'Visalia Municipal Airport',
    city: 'Visalia',
    country: 'United States',
    iata: 'VIS',
    icao: 'KVIS',
    latitude: 36.3186988831,
    longitude: -119.392997742,
    altitude: 295,
    schengen: false
  },
  {
    id: 7102,
    name: 'Valdosta Regional Airport',
    city: 'Valdosta',
    country: 'United States',
    iata: 'VLD',
    icao: 'KVLD',
    latitude: 30.7824993133545,
    longitude: -83.2767028808594,
    altitude: 203,
    schengen: false
  },
  {
    id: 7103,
    name: 'Oakland Troy Airport',
    city: 'Troy',
    country: 'United States',
    iata: '',
    icao: 'KVLL',
    latitude: 42.54290009,
    longitude: -83.17790222,
    altitude: 727,
    schengen: false
  },
  {
    id: 7104,
    name: 'Venice Municipal Airport',
    city: 'Venice',
    country: 'United States',
    iata: 'VNC',
    icao: 'KVNC',
    latitude: 27.071599960327,
    longitude: -82.440299987793,
    altitude: 18,
    schengen: false
  },
  {
    id: 7105,
    name: 'Van Wert County Airport',
    city: 'Van Wert',
    country: 'United States',
    iata: '',
    icao: 'KVNW',
    latitude: 40.8647003173828,
    longitude: -84.6093978881836,
    altitude: 785,
    schengen: false
  },
  {
    id: 7106,
    name: 'Van Nuys Airport',
    city: 'Van Nuys',
    country: 'United States',
    iata: 'VNY',
    icao: 'KVNY',
    latitude: 34.209800720215,
    longitude: -118.48999786377,
    altitude: 802,
    schengen: false
  },
  {
    id: 7107,
    name: 'Volk Field',
    city: 'Camp Douglas',
    country: 'United States',
    iata: 'VOK',
    icao: 'KVOK',
    latitude: 43.938999176025,
    longitude: -90.253402709961,
    altitude: 912,
    schengen: false
  },
  {
    id: 7108,
    name: 'Cartersville Airport',
    city: 'Cartersville',
    country: 'United States',
    iata: '',
    icao: 'KVPC',
    latitude: 34.1231002807617,
    longitude: -84.8487014770508,
    altitude: 759,
    schengen: false
  },
  {
    id: 7109,
    name: 'Destin-Ft Walton Beach Airport',
    city: 'Valparaiso',
    country: 'United States',
    iata: 'VPS',
    icao: 'KVPS',
    latitude: 30.4832,
    longitude: -86.525398,
    altitude: 87,
    schengen: false
  },
  {
    id: 7110,
    name: 'Porter County Municipal Airport',
    city: 'Valparaiso IN',
    country: 'United States',
    iata: 'VPZ',
    icao: 'KVPZ',
    latitude: 41.45399857,
    longitude: -87.00710297,
    altitude: 770,
    schengen: false
  },
  {
    id: 7111,
    name: 'Cecil Airport',
    city: 'Jacksonville',
    country: 'United States',
    iata: 'VQQ',
    icao: 'KVQQ',
    latitude: 30.2187004089,
    longitude: -81.876701355,
    altitude: 81,
    schengen: false
  },
  {
    id: 7112,
    name: 'Vero Beach Regional Airport',
    city: 'Vero Beach',
    country: 'United States',
    iata: 'VRB',
    icao: 'KVRB',
    latitude: 27.6556,
    longitude: -80.417901,
    altitude: 24,
    schengen: false
  },
  {
    id: 7113,
    name: 'Miller Field',
    city: 'Valentine',
    country: 'United States',
    iata: 'VTN',
    icao: 'KVTN',
    latitude: 42.85779953,
    longitude: -100.5479965,
    altitude: 2596,
    schengen: false
  },
  {
    id: 7114,
    name: 'Stanly County Airport',
    city: 'Albemarle',
    country: 'United States',
    iata: '',
    icao: 'KVUJ',
    latitude: 35.416698455811,
    longitude: -80.150802612305,
    altitude: 609,
    schengen: false
  },
  {
    id: 7115,
    name: 'Joseph A. Hardy Connellsville Airport',
    city: 'Connellsville',
    country: 'United States',
    iata: '',
    icao: 'KVVS',
    latitude: 39.95920181,
    longitude: -79.65709686,
    altitude: 1267,
    schengen: false
  },
  {
    id: 7116,
    name: 'Illinois Valley Regional Airport-Walter A Duncan Field',
    city: 'Peru',
    country: 'United States',
    iata: 'VYS',
    icao: 'KVYS',
    latitude: 41.351898,
    longitude: -89.153099,
    altitude: 654,
    schengen: false
  },
  {
    id: 7117,
    name: 'Potomac Airpark',
    city: 'Berkeley Springs',
    country: 'United States',
    iata: '',
    icao: 'KW35',
    latitude: 39.6926002502441,
    longitude: -78.1660995483398,
    altitude: 412,
    schengen: false
  },
  {
    id: 7118,
    name: 'Wallops Flight Facility Airport',
    city: 'Wallops Island',
    country: 'United States',
    iata: 'WAL',
    icao: 'KWAL',
    latitude: 37.9402008057,
    longitude: -75.4664001465,
    altitude: 40,
    schengen: false
  },
  {
    id: 7119,
    name: 'Wilkes Barre Wyoming Valley Airport',
    city: 'Wilkes-Barre',
    country: 'United States',
    iata: 'WBW',
    icao: 'KWBW',
    latitude: 41.2971992493,
    longitude: -75.8511962891,
    altitude: 545,
    schengen: false
  },
  {
    id: 7120,
    name: 'Barrow County Airport',
    city: 'Winder',
    country: 'United States',
    iata: 'WDR',
    icao: 'KWDR',
    latitude: 33.98289871,
    longitude: -83.66739655,
    altitude: 943,
    schengen: false
  },
  {
    id: 7121,
    name: 'Whiteman Airport',
    city: 'Los Angeles',
    country: 'United States',
    iata: 'WHP',
    icao: 'KWHP',
    latitude: 34.2593002319,
    longitude: -118.413002014,
    altitude: 1003,
    schengen: false
  },
  {
    id: 7122,
    name: 'General WM J Fox Airfield',
    city: 'Lancaster',
    country: 'United States',
    iata: 'WJF',
    icao: 'KWJF',
    latitude: 34.74110031,
    longitude: -118.2190018,
    altitude: 2351,
    schengen: false
  },
  {
    id: 7123,
    name: 'Strother Field',
    city: 'Winfield',
    country: 'United States',
    iata: 'WLD',
    icao: 'KWLD',
    latitude: 37.168598175,
    longitude: -97.0375976562,
    altitude: 1160,
    schengen: false
  },
  {
    id: 7124,
    name: 'Winnemucca Municipal Airport',
    city: 'Winnemucca',
    country: 'United States',
    iata: 'WMC',
    icao: 'KWMC',
    latitude: 40.8965988159,
    longitude: -117.805999756,
    altitude: 4308,
    schengen: false
  },
  {
    id: 7125,
    name: 'Robins Air Force Base',
    city: 'Macon',
    country: 'United States',
    iata: 'WRB',
    icao: 'KWRB',
    latitude: 32.6400985718,
    longitude: -83.5919036865,
    altitude: 294,
    schengen: false
  },
  {
    id: 7126,
    name: 'Mc Guire Air Force Base',
    city: 'Wrightstown',
    country: 'United States',
    iata: 'WRI',
    icao: 'KWRI',
    latitude: 40.0155983,
    longitude: -74.59169769,
    altitude: 131,
    schengen: false
  },
  {
    id: 7127,
    name: 'Worland Municipal Airport',
    city: 'Worland',
    country: 'United States',
    iata: 'WRL',
    icao: 'KWRL',
    latitude: 43.9656982421875,
    longitude: -107.950996398926,
    altitude: 4227,
    schengen: false
  },
  {
    id: 7128,
    name: 'Condron Army Air Field',
    city: 'White Sands',
    country: 'United States',
    iata: 'WSD',
    icao: 'KWSD',
    latitude: 32.34149933,
    longitude: -106.4029999,
    altitude: 3934,
    schengen: false
  },
  {
    id: 7129,
    name: 'Westerly State Airport',
    city: 'Washington County',
    country: 'United States',
    iata: 'WST',
    icao: 'KWST',
    latitude: 41.3496017456,
    longitude: -71.8033981323,
    altitude: 81,
    schengen: false
  },
  {
    id: 7130,
    name: 'Watsonville Municipal Airport',
    city: 'Watsonville',
    country: 'United States',
    iata: 'WVI',
    icao: 'KWVI',
    latitude: 36.9356994629,
    longitude: -121.790000916,
    altitude: 163,
    schengen: false
  },
  {
    id: 7131,
    name: 'Cape May County Airport',
    city: 'Wildwood',
    country: 'United States',
    iata: 'WWD',
    icao: 'KWWD',
    latitude: 39.0084991455,
    longitude: -74.9083023071,
    altitude: 23,
    schengen: false
  },
  {
    id: 7132,
    name: 'West Woodward Airport',
    city: 'Woodward',
    country: 'United States',
    iata: 'WWR',
    icao: 'KWWR',
    latitude: 36.438,
    longitude: -99.5226667,
    altitude: 2189,
    schengen: false
  },
  {
    id: 7133,
    name: 'Yellowstone Airport',
    city: 'West Yellowstone',
    country: 'United States',
    iata: 'WYS',
    icao: 'KWYS',
    latitude: 44.68840027,
    longitude: -111.1179962,
    altitude: 6649,
    schengen: false
  },
  {
    id: 7134,
    name: 'Flagler County Airport',
    city: 'Flagler',
    country: 'United States',
    iata: '',
    icao: 'KXFL',
    latitude: 29.4673996,
    longitude: -81.20629883,
    altitude: 33,
    schengen: false
  },
  {
    id: 7135,
    name: 'Cape Canaveral AFS Skid Strip',
    city: 'Cocoa Beach',
    country: 'United States',
    iata: '',
    icao: 'KXMR',
    latitude: 28.4675998688,
    longitude: -80.5665969849,
    altitude: 10,
    schengen: false
  },
  {
    id: 7136,
    name: 'Northwest Arkansas Regional Airport',
    city: 'Bentonville',
    country: 'United States',
    iata: 'XNA',
    icao: 'KXNA',
    latitude: 36.281898,
    longitude: -94.306801,
    altitude: 1287,
    schengen: false
  },
  {
    id: 7137,
    name: 'Homey (Area 51) Airport',
    city: 'Groom Lake',
    country: 'United States',
    iata: '',
    icao: 'KXTA',
    latitude: 37.2350006103516,
    longitude: -115.810997009277,
    altitude: 4462,
    schengen: false
  },
  {
    id: 7138,
    name: 'Bloyer Field',
    city: 'Tomah',
    country: 'United States',
    iata: '',
    icao: 'KY72',
    latitude: 43.97499847,
    longitude: -90.48349762,
    altitude: 966,
    schengen: false
  },
  {
    id: 7139,
    name: 'Willow Run Airport',
    city: 'Detroit',
    country: 'United States',
    iata: 'YIP',
    icao: 'KYIP',
    latitude: 42.23789978,
    longitude: -83.53040314,
    altitude: 716,
    schengen: false
  },
  {
    id: 7140,
    name: 'Yakima Air Terminal McAllister Field',
    city: 'Yakima',
    country: 'United States',
    iata: 'YKM',
    icao: 'KYKM',
    latitude: 46.56819916,
    longitude: -120.5439987,
    altitude: 1099,
    schengen: false
  },
  {
    id: 7141,
    name: 'Chan Gurney Municipal Airport',
    city: 'Yankton',
    country: 'United States',
    iata: 'YKN',
    icao: 'KYKN',
    latitude: 42.916698455811,
    longitude: -97.385902404785,
    altitude: 1306,
    schengen: false
  },
  {
    id: 7142,
    name: 'Youngstown Warren Regional Airport',
    city: 'Youngstown',
    country: 'United States',
    iata: 'YNG',
    icao: 'KYNG',
    latitude: 41.26070023,
    longitude: -80.67910004,
    altitude: 1192,
    schengen: false
  },
  {
    id: 7143,
    name: 'Zephyrhills Municipal Airport',
    city: 'Zephyrhills',
    country: 'United States',
    iata: 'ZPH',
    icao: 'KZPH',
    latitude: 28.2282009125,
    longitude: -82.1558990479,
    altitude: 90,
    schengen: false
  },
  {
    id: 7144,
    name: 'Black Rock Airport',
    city: 'Zuni Pueblo',
    country: 'United States',
    iata: '',
    icao: 'KZUN',
    latitude: 35.0831985473633,
    longitude: -108.791999816895,
    altitude: 6454,
    schengen: false
  },
  {
    id: 7145,
    name: 'Zanesville Municipal Airport',
    city: 'Zanesville',
    country: 'United States',
    iata: 'ZZV',
    icao: 'KZZV',
    latitude: 39.9444007874,
    longitude: -81.8920974731,
    altitude: 900,
    schengen: false
  },
  {
    id: 7146,
    name: 'Taszár Air Base',
    city: 'Columbus',
    country: 'United States',
    iata: 'TZR',
    icao: 'LHTA',
    latitude: 46.3931007385254,
    longitude: 17.9174995422363,
    altitude: 531,
    schengen: false
  },
  {
    id: 7147,
    name: 'Turkey Mountain Estates Airport',
    city: 'Shell Knob',
    country: 'United States',
    iata: '',
    icao: 'MO00',
    latitude: 36.5917015075684,
    longitude: -93.6669006347656,
    altitude: 1000,
    schengen: false
  },
  {
    id: 7148,
    name: 'San Agustin Airport',
    city: 'Morristown',
    country: 'United States',
    iata: '',
    icao: 'MRST',
    latitude: 10.0666666030884,
    longitude: -84.8833312988281,
    altitude: 66,
    schengen: false
  },
  {
    id: 7149,
    name: 'One Police Plaza Heliport',
    city: 'New York',
    country: 'United States',
    iata: '',
    icao: 'NK39',
    latitude: 40.7126007080078,
    longitude: -73.9996032714844,
    altitude: 244,
    schengen: false
  },
  {
    id: 7150,
    name: 'Las Vegas Helicopters Heliport',
    city: 'Las vegas',
    country: 'United States',
    iata: '',
    icao: 'NV03',
    latitude: 36.1081008911133,
    longitude: -115.174003601074,
    altitude: 2133,
    schengen: false
  },
  {
    id: 7151,
    name: 'Old Rhinebeck Airport',
    city: 'Rhinebeck',
    country: 'United States',
    iata: '',
    icao: 'NY94',
    latitude: 41.9715004,
    longitude: -73.86289978,
    altitude: 323,
    schengen: false
  },
  {
    id: 7152,
    name: 'Longview Ranch Airport',
    city: 'Longview',
    country: 'United States',
    iata: '',
    icao: 'OG39',
    latitude: 44.6617012023926,
    longitude: -119.652000427246,
    altitude: 2080,
    schengen: false
  },
  {
    id: 7153,
    name: 'Atka Airport',
    city: 'Atka',
    country: 'United States',
    iata: 'AKB',
    icao: 'PAAK',
    latitude: 52.22029877,
    longitude: -174.2059937,
    altitude: 57,
    schengen: false
  },
  {
    id: 7154,
    name: 'Port Moller Airport',
    city: 'Cold Bay',
    country: 'United States',
    iata: 'PML',
    icao: 'PAAL',
    latitude: 56.0060005188,
    longitude: -160.561004639,
    altitude: 20,
    schengen: false
  },
  {
    id: 7155,
    name: 'Warren "Bud" Woods Palmer Municipal Airport',
    city: 'Palmer',
    country: 'United States',
    iata: 'PAQ',
    icao: 'PAAQ',
    latitude: 61.594898,
    longitude: -149.08901,
    altitude: 242,
    schengen: false
  },
  {
    id: 7156,
    name: 'Barter Island LRRS Airport',
    city: 'Barter Island',
    country: 'United States',
    iata: 'BTI',
    icao: 'PABA',
    latitude: 70.1340026855,
    longitude: -143.582000732,
    altitude: 2,
    schengen: false
  },
  {
    id: 7157,
    name: 'Bethel Airport',
    city: 'Bethel',
    country: 'United States',
    iata: 'BET',
    icao: 'PABE',
    latitude: 60.77980042,
    longitude: -161.8379974,
    altitude: 126,
    schengen: false
  },
  {
    id: 7158,
    name: 'Allen Army Airfield',
    city: 'Delta Junction',
    country: 'United States',
    iata: 'BIG',
    icao: 'PABI',
    latitude: 63.9944992065,
    longitude: -145.722000122,
    altitude: 1291,
    schengen: false
  },
  {
    id: 7159,
    name: 'Buckland Airport',
    city: 'Buckland',
    country: 'United States',
    iata: 'BKC',
    icao: 'PABL',
    latitude: 65.9815979004,
    longitude: -161.149002075,
    altitude: 31,
    schengen: false
  },
  {
    id: 7160,
    name: 'Big Mountain Airport',
    city: 'Big Mountain',
    country: 'United States',
    iata: 'BMX',
    icao: 'PABM',
    latitude: 59.3611984253,
    longitude: -155.259002686,
    altitude: 663,
    schengen: false
  },
  {
    id: 7161,
    name: 'Wiley Post Will Rogers Memorial Airport',
    city: 'Barrow',
    country: 'United States',
    iata: 'BRW',
    icao: 'PABR',
    latitude: 71.285402,
    longitude: -156.766008,
    altitude: 44,
    schengen: false
  },
  {
    id: 7162,
    name: 'Bettles Airport',
    city: 'Bettles',
    country: 'United States',
    iata: 'BTT',
    icao: 'PABT',
    latitude: 66.91390228,
    longitude: -151.529007,
    altitude: 647,
    schengen: false
  },
  {
    id: 7163,
    name: 'Cold Bay Airport',
    city: 'Cold Bay',
    country: 'United States',
    iata: 'CDB',
    icao: 'PACD',
    latitude: 55.2061004638672,
    longitude: -162.725006103516,
    altitude: 96,
    schengen: false
  },
  {
    id: 7164,
    name: 'Central Airport',
    city: 'Central',
    country: 'United States',
    iata: 'CEM',
    icao: 'PACE',
    latitude: 65.57379913,
    longitude: -144.7830048,
    altitude: 937,
    schengen: false
  },
  {
    id: 7165,
    name: 'Chalkyitsik Airport',
    city: 'Chalkyitsik',
    country: 'United States',
    iata: 'CIK',
    icao: 'PACI',
    latitude: 66.6449966431,
    longitude: -143.740005493,
    altitude: 544,
    schengen: false
  },
  {
    id: 7166,
    name: 'Chefornak Airport',
    city: 'Chefornak',
    country: 'United States',
    iata: 'CYF',
    icao: 'PACK',
    latitude: 60.1492004395,
    longitude: -164.285995483,
    altitude: 40,
    schengen: false
  },
  {
    id: 7167,
    name: 'Clear Airport',
    city: 'Clear Mews',
    country: 'United States',
    iata: '',
    icao: 'PACL',
    latitude: 64.301201,
    longitude: -149.119995,
    altitude: 552,
    schengen: false
  },
  {
    id: 7168,
    name: 'Scammon Bay Airport',
    city: 'Scammon Bay',
    country: 'United States',
    iata: 'SCM',
    icao: 'PACM',
    latitude: 61.8452987671,
    longitude: -165.570999146,
    altitude: 14,
    schengen: false
  },
  {
    id: 7169,
    name: 'Circle City /New/ Airport',
    city: 'Circle',
    country: 'United States',
    iata: 'IRC',
    icao: 'PACR',
    latitude: 65.830498,
    longitude: -144.076008,
    altitude: 613,
    schengen: false
  },
  {
    id: 7170,
    name: 'Merle K (Mudhole) Smith Airport',
    city: 'Cordova',
    country: 'United States',
    iata: 'CDV',
    icao: 'PACV',
    latitude: 60.4917984,
    longitude: -145.4779968,
    altitude: 54,
    schengen: false
  },
  {
    id: 7171,
    name: 'Yakataga Airport',
    city: 'Yakataga',
    country: 'United States',
    iata: 'CYT',
    icao: 'PACY',
    latitude: 60.080974,
    longitude: -142.494541,
    altitude: 12,
    schengen: false
  },
  {
    id: 7172,
    name: 'Cape Romanzof LRRS Airport',
    city: 'Cape Romanzof',
    country: 'United States',
    iata: 'CZF',
    icao: 'PACZ',
    latitude: 61.78030014,
    longitude: -166.0390015,
    altitude: 464,
    schengen: false
  },
  {
    id: 7173,
    name: 'Deering Airport',
    city: 'Deering',
    country: 'United States',
    iata: 'DRG',
    icao: 'PADE',
    latitude: 66.0696029663,
    longitude: -162.76600647,
    altitude: 21,
    schengen: false
  },
  {
    id: 7174,
    name: 'Red Dog Airport',
    city: 'Red Dog',
    country: 'United States',
    iata: 'RDB',
    icao: 'PADG',
    latitude: 68.0320968628,
    longitude: -162.899002075,
    altitude: 969,
    schengen: false
  },
  {
    id: 7175,
    name: 'Adak Airport',
    city: 'Adak Island',
    country: 'United States',
    iata: 'ADK',
    icao: 'PADK',
    latitude: 51.8779983520508,
    longitude: -176.64599609375,
    altitude: 18,
    schengen: false
  },
  {
    id: 7176,
    name: 'Dillingham Airport',
    city: 'Dillingham',
    country: 'United States',
    iata: 'DLG',
    icao: 'PADL',
    latitude: 59.04470062,
    longitude: -158.5050049,
    altitude: 81,
    schengen: false
  },
  {
    id: 7177,
    name: 'Marshall Don Hunter Sr Airport',
    city: 'Marshall',
    country: 'United States',
    iata: 'MLL',
    icao: 'PADM',
    latitude: 61.8642997742,
    longitude: -162.026000977,
    altitude: 103,
    schengen: false
  },
  {
    id: 7178,
    name: 'Kodiak Airport',
    city: 'Kodiak',
    country: 'United States',
    iata: 'ADQ',
    icao: 'PADQ',
    latitude: 57.75,
    longitude: -152.4940033,
    altitude: 78,
    schengen: false
  },
  {
    id: 7179,
    name: 'Unalaska Airport',
    city: 'Unalaska',
    country: 'United States',
    iata: 'DUT',
    icao: 'PADU',
    latitude: 53.900100708,
    longitude: -166.544006348,
    altitude: 22,
    schengen: false
  },
  {
    id: 7180,
    name: 'Kongiganak Airport',
    city: 'Kongiganak',
    country: 'United States',
    iata: 'KKH',
    icao: 'PADY',
    latitude: 59.9608001709,
    longitude: -162.880996704,
    altitude: 30,
    schengen: false
  },
  {
    id: 7181,
    name: 'Elmendorf Air Force Base',
    city: 'Anchorage',
    country: 'United States',
    iata: 'EDF',
    icao: 'PAED',
    latitude: 61.2509994506836,
    longitude: -149.807006835938,
    altitude: 212,
    schengen: false
  },
  {
    id: 7182,
    name: 'Eek Airport',
    city: 'Eek',
    country: 'United States',
    iata: 'EEK',
    icao: 'PAEE',
    latitude: 60.21367264,
    longitude: -162.0438843,
    altitude: 12,
    schengen: false
  },
  {
    id: 7183,
    name: 'Eagle Airport',
    city: 'Eagle',
    country: 'United States',
    iata: 'EAA',
    icao: 'PAEG',
    latitude: 64.77639771,
    longitude: -141.151001,
    altitude: 908,
    schengen: false
  },
  {
    id: 7184,
    name: 'Cape Newenham LRRS Airport',
    city: 'Cape Newenham',
    country: 'United States',
    iata: 'EHM',
    icao: 'PAEH',
    latitude: 58.6464004517,
    longitude: -162.06300354,
    altitude: 541,
    schengen: false
  },
  {
    id: 7185,
    name: 'Eielson Air Force Base',
    city: 'Fairbanks',
    country: 'United States',
    iata: 'EIL',
    icao: 'PAEI',
    latitude: 64.66570282,
    longitude: -147.102005,
    altitude: 547,
    schengen: false
  },
  {
    id: 7186,
    name: 'Elfin Cove Seaplane Base',
    city: 'Elfin Cove',
    country: 'United States',
    iata: 'ELV',
    icao: 'PAEL',
    latitude: 58.1952018738,
    longitude: -136.347000122,
    altitude: 0,
    schengen: false
  },
  {
    id: 7187,
    name: 'Emmonak Airport',
    city: 'Emmonak',
    country: 'United States',
    iata: 'EMK',
    icao: 'PAEM',
    latitude: 62.78609848,
    longitude: -164.4909973,
    altitude: 13,
    schengen: false
  },
  {
    id: 7188,
    name: 'Kenai Municipal Airport',
    city: 'Kenai',
    country: 'United States',
    iata: 'ENA',
    icao: 'PAEN',
    latitude: 60.5731010437012,
    longitude: -151.244995117188,
    altitude: 99,
    schengen: false
  },
  {
    id: 7189,
    name: 'Fairbanks International Airport',
    city: 'Fairbanks',
    country: 'United States',
    iata: 'FAI',
    icao: 'PAFA',
    latitude: 64.81510162,
    longitude: -147.8560028,
    altitude: 439,
    schengen: false
  },
  {
    id: 7190,
    name: 'Ladd AAF Airfield',
    city: 'Fort Wainwright',
    country: 'United States',
    iata: 'FBK',
    icao: 'PAFB',
    latitude: 64.83750153,
    longitude: -147.6139984,
    altitude: 454,
    schengen: false
  },
  {
    id: 7191,
    name: 'Kake Airport',
    city: 'Kake',
    country: 'United States',
    iata: '',
    icao: 'PAFE',
    latitude: 56.9613990784,
    longitude: -133.910003662,
    altitude: 172,
    schengen: false
  },
  {
    id: 7192,
    name: 'Ambler Airport',
    city: 'Ambler',
    country: 'United States',
    iata: 'ABL',
    icao: 'PAFM',
    latitude: 67.1063,
    longitude: -157.856989,
    altitude: 334,
    schengen: false
  },
  {
    id: 7193,
    name: 'Bryant Army Heliport',
    city: 'Fort Richardson',
    country: 'United States',
    iata: 'FRN',
    icao: 'PAFR',
    latitude: 61.26639938,
    longitude: -149.6529999,
    altitude: 378,
    schengen: false
  },
  {
    id: 7194,
    name: 'Nikolai Airport',
    city: 'Nikolai',
    country: 'United States',
    iata: 'NIB',
    icao: 'PAFS',
    latitude: 63.0186,
    longitude: -154.358002,
    altitude: 441,
    schengen: false
  },
  {
    id: 7195,
    name: 'Edward G. Pitka Sr Airport',
    city: 'Galena',
    country: 'United States',
    iata: 'GAL',
    icao: 'PAGA',
    latitude: 64.73619843,
    longitude: -156.9369965,
    altitude: 153,
    schengen: false
  },
  {
    id: 7196,
    name: 'Kwigillingok Airport',
    city: 'Kwigillingok',
    country: 'United States',
    iata: 'KWK',
    icao: 'PAGG',
    latitude: 59.876499,
    longitude: -163.169005,
    altitude: 18,
    schengen: false
  },
  {
    id: 7197,
    name: 'Shungnak Airport',
    city: 'Shungnak',
    country: 'United States',
    iata: 'SHG',
    icao: 'PAGH',
    latitude: 66.88809967041,
    longitude: -157.16200256348,
    altitude: 197,
    schengen: false
  },
  {
    id: 7198,
    name: 'Gulkana Airport',
    city: 'Gulkana',
    country: 'United States',
    iata: 'GKN',
    icao: 'PAGK',
    latitude: 62.1548996,
    longitude: -145.4570007,
    altitude: 1586,
    schengen: false
  },
  {
    id: 7199,
    name: 'Golovin Airport',
    city: 'Golovin',
    country: 'United States',
    iata: 'GLV',
    icao: 'PAGL',
    latitude: 64.5504989624,
    longitude: -163.007003784,
    altitude: 59,
    schengen: false
  },
  {
    id: 7200,
    name: 'Gambell Airport',
    city: 'Gambell',
    country: 'United States',
    iata: 'GAM',
    icao: 'PAGM',
    latitude: 63.7667999267578,
    longitude: -171.733001708984,
    altitude: 27,
    schengen: false
  },
  {
    id: 7201,
    name: 'Angoon Seaplane Base',
    city: 'Angoon',
    country: 'United States',
    iata: 'AGN',
    icao: 'PAGN',
    latitude: 57.503601,
    longitude: -134.585007,
    altitude: 0,
    schengen: false
  },
  {
    id: 7202,
    name: 'Gustavus Airport',
    city: 'Gustavus',
    country: 'United States',
    iata: 'GST',
    icao: 'PAGS',
    latitude: 58.4253006,
    longitude: -135.7070007,
    altitude: 35,
    schengen: false
  },
  {
    id: 7203,
    name: 'Nightmute Airport',
    city: 'Nightmute',
    country: 'United States',
    iata: 'NME',
    icao: 'PAGT',
    latitude: 60.471000671387,
    longitude: -164.70100402832,
    altitude: 4,
    schengen: false
  },
  {
    id: 7204,
    name: 'Skagway Airport',
    city: 'Skagway',
    country: 'United States',
    iata: 'SGY',
    icao: 'PAGY',
    latitude: 59.4600982666016,
    longitude: -135.315994262695,
    altitude: 44,
    schengen: false
  },
  {
    id: 7205,
    name: 'Holy Cross Airport',
    city: 'Holy Cross',
    country: 'United States',
    iata: 'HCR',
    icao: 'PAHC',
    latitude: 62.1883010864258,
    longitude: -159.774993896484,
    altitude: 70,
    schengen: false
  },
  {
    id: 7206,
    name: 'Huslia Airport',
    city: 'Huslia',
    country: 'United States',
    iata: 'HSL',
    icao: 'PAHL',
    latitude: 65.69789886,
    longitude: -156.3509979,
    altitude: 220,
    schengen: false
  },
  {
    id: 7207,
    name: 'Haines Airport',
    city: 'Haines',
    country: 'United States',
    iata: 'HNS',
    icao: 'PAHN',
    latitude: 59.2438011169434,
    longitude: -135.524002075195,
    altitude: 15,
    schengen: false
  },
  {
    id: 7208,
    name: 'Homer Airport',
    city: 'Homer',
    country: 'United States',
    iata: 'HOM',
    icao: 'PAHO',
    latitude: 59.6455993652344,
    longitude: -151.477005004883,
    altitude: 84,
    schengen: false
  },
  {
    id: 7209,
    name: 'Hooper Bay Airport',
    city: 'Hooper Bay',
    country: 'United States',
    iata: 'HPB',
    icao: 'PAHP',
    latitude: 61.52389908,
    longitude: -166.1470032,
    altitude: 13,
    schengen: false
  },
  {
    id: 7210,
    name: 'Hughes Airport',
    city: 'Hughes',
    country: 'United States',
    iata: 'HUS',
    icao: 'PAHU',
    latitude: 66.04109955,
    longitude: -154.2630005,
    altitude: 299,
    schengen: false
  },
  {
    id: 7211,
    name: 'Healy River Airport',
    city: 'Healy',
    country: 'United States',
    iata: '',
    icao: 'PAHV',
    latitude: 63.8661994934082,
    longitude: -148.968994140625,
    altitude: 1263,
    schengen: false
  },
  {
    id: 7212,
    name: 'Shageluk Airport',
    city: 'Shageluk',
    country: 'United States',
    iata: 'SHX',
    icao: 'PAHX',
    latitude: 62.6922988892,
    longitude: -159.569000244,
    altitude: 79,
    schengen: false
  },
  {
    id: 7213,
    name: 'Hydaburg Seaplane Base',
    city: 'Hydaburg',
    country: 'United States',
    iata: 'HYG',
    icao: 'PAHY',
    latitude: 55.206298828125,
    longitude: -132.828002929688,
    altitude: 0,
    schengen: false
  },
  {
    id: 7214,
    name: 'Igiugig Airport',
    city: 'Igiugig',
    country: 'United States',
    iata: 'IGG',
    icao: 'PAIG',
    latitude: 59.3240013122559,
    longitude: -155.901992797852,
    altitude: 90,
    schengen: false
  },
  {
    id: 7215,
    name: 'Egegik Airport',
    city: 'Egegik',
    country: 'United States',
    iata: 'EGX',
    icao: 'PAII',
    latitude: 58.1855010986,
    longitude: -157.375,
    altitude: 92,
    schengen: false
  },
  {
    id: 7216,
    name: 'Bob Baker Memorial Airport',
    city: 'Kiana',
    country: 'United States',
    iata: 'IAN',
    icao: 'PAIK',
    latitude: 66.9759979248,
    longitude: -160.43699646,
    altitude: 166,
    schengen: false
  },
  {
    id: 7217,
    name: 'Iliamna Airport',
    city: 'Iliamna',
    country: 'United States',
    iata: 'ILI',
    icao: 'PAIL',
    latitude: 59.75439835,
    longitude: -154.9109955,
    altitude: 192,
    schengen: false
  },
  {
    id: 7218,
    name: 'Indian Mountain LRRS Airport',
    city: 'Indian Mountains',
    country: 'United States',
    iata: 'UTO',
    icao: 'PAIM',
    latitude: 65.99279785,
    longitude: -153.7039948,
    altitude: 1273,
    schengen: false
  },
  {
    id: 7219,
    name: 'McKinley National Park Airport',
    city: 'McKinley Park',
    country: 'United States',
    iata: 'MCL',
    icao: 'PAIN',
    latitude: 63.7326011658,
    longitude: -148.910995483,
    altitude: 1720,
    schengen: false
  },
  {
    id: 7220,
    name: 'Wales Airport',
    city: 'Wales',
    country: 'United States',
    iata: 'WAA',
    icao: 'PAIW',
    latitude: 65.622593,
    longitude: -168.095,
    altitude: 22,
    schengen: false
  },
  {
    id: 7221,
    name: 'Juneau International Airport',
    city: 'Juneau',
    country: 'United States',
    iata: 'JNU',
    icao: 'PAJN',
    latitude: 58.3549995422363,
    longitude: -134.57600402832,
    altitude: 21,
    schengen: false
  },
  {
    id: 7222,
    name: 'Koliganek Airport',
    city: 'Koliganek',
    country: 'United States',
    iata: 'KGK',
    icao: 'PAJZ',
    latitude: 59.726600647,
    longitude: -157.259002686,
    altitude: 269,
    schengen: false
  },
  {
    id: 7223,
    name: 'False Pass Airport',
    city: 'False Pass',
    country: 'United States',
    iata: 'KFP',
    icao: 'PAKF',
    latitude: 54.8474006652832,
    longitude: -163.410003662109,
    altitude: 20,
    schengen: false
  },
  {
    id: 7224,
    name: 'Akhiok Airport',
    city: 'Akhiok',
    country: 'United States',
    iata: 'AKK',
    icao: 'PAKH',
    latitude: 56.9387016296,
    longitude: -154.182998657,
    altitude: 44,
    schengen: false
  },
  {
    id: 7225,
    name: 'Kipnuk Airport',
    city: 'Kipnuk',
    country: 'United States',
    iata: 'KPN',
    icao: 'PAKI',
    latitude: 59.9329986572,
    longitude: -164.031005859,
    altitude: 11,
    schengen: false
  },
  {
    id: 7226,
    name: 'Koyuk Alfred Adams Airport',
    city: 'Koyuk',
    country: 'United States',
    iata: 'KKA',
    icao: 'PAKK',
    latitude: 64.9394989014,
    longitude: -161.154006958,
    altitude: 154,
    schengen: false
  },
  {
    id: 7227,
    name: 'King Salmon Airport',
    city: 'King Salmon',
    country: 'United States',
    iata: 'AKN',
    icao: 'PAKN',
    latitude: 58.67679977,
    longitude: -156.6490021,
    altitude: 73,
    schengen: false
  },
  {
    id: 7228,
    name: 'Nikolski Air Station',
    city: 'Nikolski',
    country: 'United States',
    iata: 'IKO',
    icao: 'PAKO',
    latitude: 52.9416007995606,
    longitude: -168.848999023438,
    altitude: 77,
    schengen: false
  },
  {
    id: 7229,
    name: 'Anaktuvuk Pass Airport',
    city: 'Anaktuvuk Pass',
    country: 'United States',
    iata: 'AKP',
    icao: 'PAKP',
    latitude: 68.13359833,
    longitude: -151.7429962,
    altitude: 2102,
    schengen: false
  },
  {
    id: 7230,
    name: 'Ketchikan International Airport',
    city: 'Ketchikan',
    country: 'United States',
    iata: 'KTN',
    icao: 'PAKT',
    latitude: 55.35559845,
    longitude: -131.7140045,
    altitude: 89,
    schengen: false
  },
  {
    id: 7231,
    name: 'Ugnu-Kuparuk Airport',
    city: 'Kuparuk',
    country: 'United States',
    iata: 'UUK',
    icao: 'PAKU',
    latitude: 70.3308029175,
    longitude: -149.598007202,
    altitude: 67,
    schengen: false
  },
  {
    id: 7232,
    name: 'Kaltag Airport',
    city: 'Kaltag',
    country: 'United States',
    iata: 'KAL',
    icao: 'PAKV',
    latitude: 64.31909943,
    longitude: -158.7409973,
    altitude: 181,
    schengen: false
  },
  {
    id: 7233,
    name: 'Klawock Airport',
    city: 'Klawock',
    country: 'United States',
    iata: 'KLW',
    icao: 'PAKW',
    latitude: 55.5792007446,
    longitude: -133.076004028,
    altitude: 80,
    schengen: false
  },
  {
    id: 7234,
    name: 'Karluk Airport',
    city: 'Karluk',
    country: 'United States',
    iata: 'KYK',
    icao: 'PAKY',
    latitude: 57.5671005249,
    longitude: -154.449996948,
    altitude: 137,
    schengen: false
  },
  {
    id: 7235,
    name: 'Larsen Bay Airport',
    city: 'Larsen Bay',
    country: 'United States',
    iata: 'KLN',
    icao: 'PALB',
    latitude: 57.5350990295,
    longitude: -153.977996826,
    altitude: 87,
    schengen: false
  },
  {
    id: 7236,
    name: 'Kalskag Airport',
    city: 'Kalskag',
    country: 'United States',
    iata: 'KLG',
    icao: 'PALG',
    latitude: 61.5363006591797,
    longitude: -160.341003417969,
    altitude: 55,
    schengen: false
  },
  {
    id: 7237,
    name: 'Lake Hood Seaplane Base',
    city: 'Anchorage',
    country: 'United States',
    iata: '',
    icao: 'PALH',
    latitude: 61.1800003051758,
    longitude: -149.97200012207,
    altitude: 71,
    schengen: false
  },
  {
    id: 7238,
    name: 'Cape Lisburne LRRS Airport',
    city: 'Cape Lisburne',
    country: 'United States',
    iata: 'LUR',
    icao: 'PALU',
    latitude: 68.87509918,
    longitude: -166.1100006,
    altitude: 16,
    schengen: false
  },
  {
    id: 7239,
    name: 'Manokotak Airport',
    city: 'Manokotak',
    country: 'United States',
    iata: 'KMO',
    icao: 'PAMB',
    latitude: 58.9902000427,
    longitude: -159.050003052,
    altitude: 100,
    schengen: false
  },
  {
    id: 7240,
    name: 'McGrath Airport',
    city: 'Mcgrath',
    country: 'United States',
    iata: 'MCG',
    icao: 'PAMC',
    latitude: 62.95289993,
    longitude: -155.6060028,
    altitude: 341,
    schengen: false
  },
  {
    id: 7241,
    name: 'Minchumina Airport',
    city: 'Lake Minchumina',
    country: 'United States',
    iata: 'LMA',
    icao: 'PAMH',
    latitude: 63.886002,
    longitude: -152.302002,
    altitude: 678,
    schengen: false
  },
  {
    id: 7242,
    name: 'St Michael Airport',
    city: 'St. Michael',
    country: 'United States',
    iata: 'SMK',
    icao: 'PAMK',
    latitude: 63.49010086,
    longitude: -162.1100006,
    altitude: 98,
    schengen: false
  },
  {
    id: 7243,
    name: 'Manley Hot Springs Airport',
    city: 'Manley Hot Springs',
    country: 'United States',
    iata: 'MLY',
    icao: 'PAML',
    latitude: 64.9975967407,
    longitude: -150.643997192,
    altitude: 270,
    schengen: false
  },
  {
    id: 7244,
    name: 'Metlakatla Seaplane Base',
    city: 'Metakatla',
    country: 'United States',
    iata: '',
    icao: 'PAMM',
    latitude: 55.1310005187988,
    longitude: -131.578002929688,
    altitude: 0,
    schengen: false
  },
  {
    id: 7245,
    name: 'Mountain Village Airport',
    city: 'Mountain Village',
    country: 'United States',
    iata: 'MOU',
    icao: 'PAMO',
    latitude: 62.095401763916,
    longitude: -163.682006835938,
    altitude: 337,
    schengen: false
  },
  {
    id: 7246,
    name: 'Merrill Field',
    city: 'Anchorage',
    country: 'United States',
    iata: 'MRI',
    icao: 'PAMR',
    latitude: 61.2135009765625,
    longitude: -149.843994140625,
    altitude: 137,
    schengen: false
  },
  {
    id: 7247,
    name: 'Mekoryuk Airport',
    city: 'Mekoryuk',
    country: 'United States',
    iata: 'MYU',
    icao: 'PAMY',
    latitude: 60.3713989257813,
    longitude: -166.27099609375,
    altitude: 48,
    schengen: false
  },
  {
    id: 7248,
    name: 'Napakiak Airport',
    city: 'Napakiak',
    country: 'United States',
    iata: 'WNA',
    icao: 'PANA',
    latitude: 60.690299987793,
    longitude: -161.97900390625,
    altitude: 17,
    schengen: false
  },
  {
    id: 7249,
    name: 'Ted Stevens Anchorage International Airport',
    city: 'Anchorage',
    country: 'United States',
    iata: 'ANC',
    icao: 'PANC',
    latitude: 61.1744003295898,
    longitude: -149.996002197266,
    altitude: 152,
    schengen: false
  },
  {
    id: 7250,
    name: 'Aniak Airport',
    city: 'Aniak',
    country: 'United States',
    iata: 'ANI',
    icao: 'PANI',
    latitude: 61.581600189209,
    longitude: -159.542999267578,
    altitude: 88,
    schengen: false
  },
  {
    id: 7251,
    name: 'Nenana Municipal Airport',
    city: 'Nenana',
    country: 'United States',
    iata: 'ENN',
    icao: 'PANN',
    latitude: 64.5473022460938,
    longitude: -149.074005126953,
    altitude: 362,
    schengen: false
  },
  {
    id: 7252,
    name: 'Nondalton Airport',
    city: 'Nondalton',
    country: 'United States',
    iata: 'NNL',
    icao: 'PANO',
    latitude: 59.980201721191,
    longitude: -154.8390045166,
    altitude: 314,
    schengen: false
  },
  {
    id: 7253,
    name: 'Funter Bay Seaplane Base',
    city: 'Funter Bay',
    country: 'United States',
    iata: 'FNR',
    icao: 'PANR',
    latitude: 58.2543983459,
    longitude: -134.897994995,
    altitude: 0,
    schengen: false
  },
  {
    id: 7254,
    name: 'Annette Island Airport',
    city: 'Annette Island',
    country: 'United States',
    iata: 'ANN',
    icao: 'PANT',
    latitude: 55.0424003601074,
    longitude: -131.572006225586,
    altitude: 119,
    schengen: false
  },
  {
    id: 7255,
    name: 'Nulato Airport',
    city: 'Nulato',
    country: 'United States',
    iata: 'NUL',
    icao: 'PANU',
    latitude: 64.729301,
    longitude: -158.074005,
    altitude: 399,
    schengen: false
  },
  {
    id: 7256,
    name: 'Anvik Airport',
    city: 'Anvik',
    country: 'United States',
    iata: 'ANV',
    icao: 'PANV',
    latitude: 62.646702,
    longitude: -160.190994,
    altitude: 291,
    schengen: false
  },
  {
    id: 7257,
    name: 'New Stuyahok Airport',
    city: 'New Stuyahok',
    country: 'United States',
    iata: 'KNW',
    icao: 'PANW',
    latitude: 59.4499015808,
    longitude: -157.32800293,
    altitude: 364,
    schengen: false
  },
  {
    id: 7258,
    name: 'Kobuk Airport',
    city: 'Kobuk',
    country: 'United States',
    iata: 'OBU',
    icao: 'PAOB',
    latitude: 66.9123001099,
    longitude: -156.897003174,
    altitude: 137,
    schengen: false
  },
  {
    id: 7259,
    name: 'Hoonah Airport',
    city: 'Hoonah',
    country: 'United States',
    iata: 'HNH',
    icao: 'PAOH',
    latitude: 58.0961,
    longitude: -135.410111,
    altitude: 19,
    schengen: false
  },
  {
    id: 7260,
    name: 'Nome Airport',
    city: 'Nome',
    country: 'United States',
    iata: 'OME',
    icao: 'PAOM',
    latitude: 64.5121994018555,
    longitude: -165.445007324219,
    altitude: 37,
    schengen: false
  },
  {
    id: 7261,
    name: 'Toksook Bay Airport',
    city: 'Toksook Bay',
    country: 'United States',
    iata: 'OOK',
    icao: 'PAOO',
    latitude: 60.54140091,
    longitude: -165.0870056,
    altitude: 59,
    schengen: false
  },
  {
    id: 7262,
    name: 'Northway Airport',
    city: 'Northway',
    country: 'United States',
    iata: 'ORT',
    icao: 'PAOR',
    latitude: 62.9612999,
    longitude: -141.9290009,
    altitude: 1715,
    schengen: false
  },
  {
    id: 7263,
    name: 'Ralph Wien Memorial Airport',
    city: 'Kotzebue',
    country: 'United States',
    iata: 'OTZ',
    icao: 'PAOT',
    latitude: 66.88469696,
    longitude: -162.598999,
    altitude: 14,
    schengen: false
  },
  {
    id: 7264,
    name: 'Nelson Lagoon Airport',
    city: 'Nelson Lagoon',
    country: 'United States',
    iata: 'NLG',
    icao: 'PAOU',
    latitude: 56.007499694824,
    longitude: -161.16000366211,
    altitude: 14,
    schengen: false
  },
  {
    id: 7265,
    name: 'St George Airport',
    city: 'Point Barrow',
    country: 'United States',
    iata: 'STG',
    icao: 'PAPB',
    latitude: 56.5783004761,
    longitude: -169.662002563,
    altitude: 125,
    schengen: false
  },
  {
    id: 7266,
    name: 'Port Clarence Coast Guard Station',
    city: 'Port Clarence',
    country: 'United States',
    iata: 'KPC',
    icao: 'PAPC',
    latitude: 65.2537002563,
    longitude: -166.85899353,
    altitude: 10,
    schengen: false
  },
  {
    id: 7267,
    name: 'Perryville Airport',
    city: 'Perryville',
    country: 'United States',
    iata: 'KPV',
    icao: 'PAPE',
    latitude: 55.905998,
    longitude: -159.162993,
    altitude: 29,
    schengen: false
  },
  {
    id: 7268,
    name: 'Petersburg James A Johnson Airport',
    city: 'Petersburg',
    country: 'United States',
    iata: 'PSG',
    icao: 'PAPG',
    latitude: 56.80170059,
    longitude: -132.9450073,
    altitude: 111,
    schengen: false
  },
  {
    id: 7269,
    name: 'Port Heiden Airport',
    city: 'Port Heiden',
    country: 'United States',
    iata: 'PTH',
    icao: 'PAPH',
    latitude: 56.959098815918,
    longitude: -158.632995605469,
    altitude: 95,
    schengen: false
  },
  {
    id: 7270,
    name: 'Napaskiak Airport',
    city: 'Napaskiak',
    country: 'United States',
    iata: 'PKA',
    icao: 'PAPK',
    latitude: 60.70289993,
    longitude: -161.7779999,
    altitude: 24,
    schengen: false
  },
  {
    id: 7271,
    name: 'Platinum Airport',
    city: 'Port Moller',
    country: 'United States',
    iata: 'PTU',
    icao: 'PAPM',
    latitude: 59.0113983154297,
    longitude: -161.820007324219,
    altitude: 15,
    schengen: false
  },
  {
    id: 7272,
    name: 'Pilot Point Airport',
    city: 'Pilot Point',
    country: 'United States',
    iata: 'PIP',
    icao: 'PAPN',
    latitude: 57.5803985596,
    longitude: -157.572006226,
    altitude: 57,
    schengen: false
  },
  {
    id: 7273,
    name: 'Prospect Creek Airport',
    city: 'Prospect Creek',
    country: 'United States',
    iata: 'PPC',
    icao: 'PAPR',
    latitude: 66.814102172852,
    longitude: -150.64399719238,
    altitude: 1095,
    schengen: false
  },
  {
    id: 7274,
    name: 'Klawock Seaplane Base',
    city: 'Klawock',
    country: 'United States',
    iata: '',
    icao: 'PAQC',
    latitude: 55.5546989440918,
    longitude: -133.102005004883,
    altitude: 0,
    schengen: false
  },
  {
    id: 7275,
    name: 'Quinhagak Airport',
    city: 'Quinhagak',
    country: 'United States',
    iata: 'KWN',
    icao: 'PAQH',
    latitude: 59.7551,
    longitude: -161.845,
    altitude: 42,
    schengen: false
  },
  {
    id: 7276,
    name: 'Nuiqsut Airport',
    city: 'Nuiqsut',
    country: 'United States',
    iata: 'NUI',
    icao: 'PAQT',
    latitude: 70.2099990845,
    longitude: -151.005996704,
    altitude: 38,
    schengen: false
  },
  {
    id: 7277,
    name: 'Arctic Village Airport',
    city: 'Arctic Village',
    country: 'United States',
    iata: 'ARC',
    icao: 'PARC',
    latitude: 68.1147,
    longitude: -145.578995,
    altitude: 2092,
    schengen: false
  },
  {
    id: 7278,
    name: 'Russian Mission Airport',
    city: 'Russian Mission',
    country: 'United States',
    iata: 'RSH',
    icao: 'PARS',
    latitude: 61.7788848877,
    longitude: -161.319458008,
    altitude: 51,
    schengen: false
  },
  {
    id: 7279,
    name: 'Ruby Airport',
    city: 'Ruby',
    country: 'United States',
    iata: 'RBY',
    icao: 'PARY',
    latitude: 64.72720337,
    longitude: -155.4700012,
    altitude: 658,
    schengen: false
  },
  {
    id: 7280,
    name: 'Savoonga Airport',
    city: 'Savoonga',
    country: 'United States',
    iata: 'SVA',
    icao: 'PASA',
    latitude: 63.6864013671875,
    longitude: -170.49299621582,
    altitude: 53,
    schengen: false
  },
  {
    id: 7281,
    name: 'Deadhorse Airport',
    city: 'Deadhorse',
    country: 'United States',
    iata: 'SCC',
    icao: 'PASC',
    latitude: 70.19470215,
    longitude: -148.4649963,
    altitude: 65,
    schengen: false
  },
  {
    id: 7282,
    name: 'Sand Point Airport',
    city: 'Sand Point',
    country: 'United States',
    iata: 'SDP',
    icao: 'PASD',
    latitude: 55.314998626709,
    longitude: -160.522994995117,
    altitude: 21,
    schengen: false
  },
  {
    id: 7283,
    name: 'Shishmaref Airport',
    city: 'Shishmaref',
    country: 'United States',
    iata: 'SHH',
    icao: 'PASH',
    latitude: 66.249604,
    longitude: -166.089112,
    altitude: 12,
    schengen: false
  },
  {
    id: 7284,
    name: 'Sitka Rocky Gutierrez Airport',
    city: 'Sitka',
    country: 'United States',
    iata: 'SIT',
    icao: 'PASI',
    latitude: 57.0471000671387,
    longitude: -135.361999511719,
    altitude: 21,
    schengen: false
  },
  {
    id: 7285,
    name: 'Selawik Airport',
    city: 'Selawik',
    country: 'United States',
    iata: 'WLK',
    icao: 'PASK',
    latitude: 66.60009766,
    longitude: -159.9859924,
    altitude: 17,
    schengen: false
  },
  {
    id: 7286,
    name: 'Sleetmute Airport',
    city: 'Sleetmute',
    country: 'United States',
    iata: 'SLQ',
    icao: 'PASL',
    latitude: 61.7005004883,
    longitude: -157.166000366,
    altitude: 190,
    schengen: false
  },
  {
    id: 7287,
    name: "St Mary's Airport",
    city: "St Mary's",
    country: 'United States',
    iata: 'KSM',
    icao: 'PASM',
    latitude: 62.0605011,
    longitude: -163.302002,
    altitude: 312,
    schengen: false
  },
  {
    id: 7288,
    name: 'St Paul Island Airport',
    city: 'St. Paul Island',
    country: 'United States',
    iata: 'SNP',
    icao: 'PASN',
    latitude: 57.1673011779785,
    longitude: -170.220001220703,
    altitude: 63,
    schengen: false
  },
  {
    id: 7289,
    name: 'Seldovia Airport',
    city: 'Seldovia',
    country: 'United States',
    iata: 'SOV',
    icao: 'PASO',
    latitude: 59.442401885986,
    longitude: -151.70399475098,
    altitude: 29,
    schengen: false
  },
  {
    id: 7290,
    name: 'Sparrevohn LRRS Airport',
    city: 'Sparrevohn',
    country: 'United States',
    iata: 'SVW',
    icao: 'PASV',
    latitude: 61.09740067,
    longitude: -155.5740051,
    altitude: 1585,
    schengen: false
  },
  {
    id: 7291,
    name: 'Soldotna Airport',
    city: 'Soldotna',
    country: 'United States',
    iata: 'SXQ',
    icao: 'PASX',
    latitude: 60.475700378418,
    longitude: -151.033996582031,
    altitude: 113,
    schengen: false
  },
  {
    id: 7292,
    name: 'Eareckson Air Station',
    city: 'Shemya',
    country: 'United States',
    iata: 'SYA',
    icao: 'PASY',
    latitude: 52.71229935,
    longitude: 174.1139984,
    altitude: 95,
    schengen: false
  },
  {
    id: 7293,
    name: 'Tin City Long Range Radar Station Airport',
    city: 'Tin City',
    country: 'United States',
    iata: 'TNC',
    icao: 'PATC',
    latitude: 65.56310272,
    longitude: -167.9219971,
    altitude: 271,
    schengen: false
  },
  {
    id: 7294,
    name: 'Teller Airport',
    city: 'Teller',
    country: 'United States',
    iata: 'TLA',
    icao: 'PATE',
    latitude: 65.2404022217,
    longitude: -166.339004517,
    altitude: 294,
    schengen: false
  },
  {
    id: 7295,
    name: 'Togiak Airport',
    city: 'Togiak Village',
    country: 'United States',
    iata: 'TOG',
    icao: 'PATG',
    latitude: 59.0527992248535,
    longitude: -160.397003173828,
    altitude: 21,
    schengen: false
  },
  {
    id: 7296,
    name: 'Tok Airport',
    city: 'Tok',
    country: 'United States',
    iata: '',
    icao: 'PATJ',
    latitude: 63.303333,
    longitude: -143.001111,
    altitude: 1670,
    schengen: false
  },
  {
    id: 7297,
    name: 'Talkeetna Airport',
    city: 'Talkeetna',
    country: 'United States',
    iata: 'TKA',
    icao: 'PATK',
    latitude: 62.320499420166,
    longitude: -150.09399414062,
    altitude: 358,
    schengen: false
  },
  {
    id: 7298,
    name: 'Tatalina LRRS Airport',
    city: 'Tatalina',
    country: 'United States',
    iata: 'TLJ',
    icao: 'PATL',
    latitude: 62.8944015503,
    longitude: -155.977005005,
    altitude: 964,
    schengen: false
  },
  {
    id: 7299,
    name: 'Atqasuk Edward Burnell Sr Memorial Airport',
    city: 'Atqasuk',
    country: 'United States',
    iata: 'ATK',
    icao: 'PATQ',
    latitude: 70.4673004150391,
    longitude: -157.436004638672,
    altitude: 96,
    schengen: false
  },
  {
    id: 7300,
    name: 'Alakanuk Airport',
    city: 'Alakanuk',
    country: 'United States',
    iata: 'AUK',
    icao: 'PAUK',
    latitude: 62.6800422668,
    longitude: -164.659927368,
    altitude: 10,
    schengen: false
  },
  {
    id: 7301,
    name: 'Unalakleet Airport',
    city: 'Unalakleet',
    country: 'United States',
    iata: 'UNK',
    icao: 'PAUN',
    latitude: 63.88840103,
    longitude: -160.798996,
    altitude: 27,
    schengen: false
  },
  {
    id: 7302,
    name: 'Chevak Airport',
    city: 'Chevak',
    country: 'United States',
    iata: 'VAK',
    icao: 'PAVA',
    latitude: 61.5409,
    longitude: -165.6005,
    altitude: 75,
    schengen: false
  },
  {
    id: 7303,
    name: 'King Cove Airport',
    city: 'King Cove',
    country: 'United States',
    iata: 'KVC',
    icao: 'PAVC',
    latitude: 55.1162986755371,
    longitude: -162.266006469727,
    altitude: 155,
    schengen: false
  },
  {
    id: 7304,
    name: 'Valdez Pioneer Field',
    city: 'Valdez',
    country: 'United States',
    iata: 'VDZ',
    icao: 'PAVD',
    latitude: 61.13389969,
    longitude: -146.2480011,
    altitude: 121,
    schengen: false
  },
  {
    id: 7305,
    name: 'Venetie Airport',
    city: 'Venetie',
    country: 'United States',
    iata: 'VEE',
    icao: 'PAVE',
    latitude: 67.0086975098,
    longitude: -146.365997314,
    altitude: 574,
    schengen: false
  },
  {
    id: 7306,
    name: 'Kivalina Airport',
    city: 'Kivalina',
    country: 'United States',
    iata: 'KVL',
    icao: 'PAVL',
    latitude: 67.736198425293,
    longitude: -164.563003540039,
    altitude: 13,
    schengen: false
  },
  {
    id: 7307,
    name: 'Beaver Airport',
    city: 'Beaver',
    country: 'United States',
    iata: 'WBQ',
    icao: 'PAWB',
    latitude: 66.362197876,
    longitude: -147.406997681,
    altitude: 359,
    schengen: false
  },
  {
    id: 7308,
    name: 'Seward Airport',
    city: 'Seward',
    country: 'United States',
    iata: 'SWD',
    icao: 'PAWD',
    latitude: 60.1268997192383,
    longitude: -149.419006347656,
    altitude: 22,
    schengen: false
  },
  {
    id: 7309,
    name: 'Wrangell Airport',
    city: 'Wrangell',
    country: 'United States',
    iata: 'WRG',
    icao: 'PAWG',
    latitude: 56.48429871,
    longitude: -132.3699951,
    altitude: 49,
    schengen: false
  },
  {
    id: 7310,
    name: 'Wainwright Airport',
    city: 'Wainwright',
    country: 'United States',
    iata: 'AIN',
    icao: 'PAWI',
    latitude: 70.6380004883,
    longitude: -159.994995117,
    altitude: 41,
    schengen: false
  },
  {
    id: 7311,
    name: 'White Mountain Airport',
    city: 'White Mountain',
    country: 'United States',
    iata: 'WMO',
    icao: 'PAWM',
    latitude: 64.689201355,
    longitude: -163.412994385,
    altitude: 267,
    schengen: false
  },
  {
    id: 7312,
    name: 'Noatak Airport',
    city: 'Noatak',
    country: 'United States',
    iata: 'WTK',
    icao: 'PAWN',
    latitude: 67.5661010742188,
    longitude: -162.975006103516,
    altitude: 88,
    schengen: false
  },
  {
    id: 7313,
    name: 'Whittier Airport',
    city: 'Whittier',
    country: 'United States',
    iata: '',
    icao: 'PAWR',
    latitude: 60.7771987915039,
    longitude: -148.72200012207,
    altitude: 30,
    schengen: false
  },
  {
    id: 7314,
    name: 'Wasilla Airport',
    city: 'Wasilla',
    country: 'United States',
    iata: 'WWA',
    icao: 'PAWS',
    latitude: 61.5717010498,
    longitude: -149.539993286,
    altitude: 354,
    schengen: false
  },
  {
    id: 7315,
    name: 'Wainwright Air Station',
    city: 'Fort Wainwright',
    country: 'United States',
    iata: '',
    icao: 'PAWT',
    latitude: 70.61340332,
    longitude: -159.8600006,
    altitude: 35,
    schengen: false
  },
  {
    id: 7316,
    name: 'Yakutat Airport',
    city: 'Yakutat',
    country: 'United States',
    iata: 'YAK',
    icao: 'PAYA',
    latitude: 59.5032997131,
    longitude: -139.660003662,
    altitude: 33,
    schengen: false
  },
  {
    id: 7317,
    name: 'Akiak Airport',
    city: 'Akiak',
    country: 'United States',
    iata: 'AKI',
    icao: 'PFAK',
    latitude: 60.9029006958,
    longitude: -161.231002808,
    altitude: 30,
    schengen: false
  },
  {
    id: 7318,
    name: 'Allakaket Airport',
    city: 'Allakaket',
    country: 'United States',
    iata: 'AET',
    icao: 'PFAL',
    latitude: 66.5518035889,
    longitude: -152.621994019,
    altitude: 441,
    schengen: false
  },
  {
    id: 7319,
    name: 'Chenega Bay Airport',
    city: 'Chenega',
    country: 'United States',
    iata: 'NCN',
    icao: 'PFCB',
    latitude: 60.0773010254,
    longitude: -147.992004395,
    altitude: 72,
    schengen: false
  },
  {
    id: 7320,
    name: 'Clarks Point Airport',
    city: 'Clarks Point',
    country: 'United States',
    iata: 'CLP',
    icao: 'PFCL',
    latitude: 58.83369827,
    longitude: -158.529007,
    altitude: 80,
    schengen: false
  },
  {
    id: 7321,
    name: 'Elim Airport',
    city: 'Elim',
    country: 'United States',
    iata: 'ELI',
    icao: 'PFEL',
    latitude: 64.61470032,
    longitude: -162.2720032,
    altitude: 162,
    schengen: false
  },
  {
    id: 7322,
    name: 'Kasigluk Airport',
    city: 'Kasigluk',
    country: 'United States',
    iata: 'KUK',
    icao: 'PFKA',
    latitude: 60.87440109,
    longitude: -162.5240021,
    altitude: 48,
    schengen: false
  },
  {
    id: 7323,
    name: 'Kotlik Airport',
    city: 'Kotlik',
    country: 'United States',
    iata: 'KOT',
    icao: 'PFKO',
    latitude: 63.0306015015,
    longitude: -163.533004761,
    altitude: 15,
    schengen: false
  },
  {
    id: 7324,
    name: 'Brevig Mission Airport',
    city: 'Brevig Mission',
    country: 'United States',
    iata: 'KTS',
    icao: 'PFKT',
    latitude: 65.3312988281,
    longitude: -166.466003418,
    altitude: 38,
    schengen: false
  },
  {
    id: 7325,
    name: 'Koyukuk Airport',
    city: 'Koyukuk',
    country: 'United States',
    iata: 'KYU',
    icao: 'PFKU',
    latitude: 64.8760986328,
    longitude: -157.727005005,
    altitude: 149,
    schengen: false
  },
  {
    id: 7326,
    name: 'Kwethluk Airport',
    city: 'Kwethluk',
    country: 'United States',
    iata: 'KWT',
    icao: 'PFKW',
    latitude: 60.7902984619,
    longitude: -161.444000244,
    altitude: 25,
    schengen: false
  },
  {
    id: 7327,
    name: 'Robert (Bob) Curtis Memorial Airport',
    city: 'Noorvik',
    country: 'United States',
    iata: 'ORV',
    icao: 'PFNO',
    latitude: 66.81790161,
    longitude: -161.0189972,
    altitude: 55,
    schengen: false
  },
  {
    id: 7328,
    name: 'Shaktoolik Airport',
    city: 'Shaktoolik',
    country: 'United States',
    iata: 'SKK',
    icao: 'PFSH',
    latitude: 64.37110138,
    longitude: -161.223999,
    altitude: 24,
    schengen: false
  },
  {
    id: 7329,
    name: 'Tok Junction Airport',
    city: 'Tok',
    country: 'United States',
    iata: 'TKJ',
    icao: 'PFTO',
    latitude: 63.32949829,
    longitude: -142.9539948,
    altitude: 1639,
    schengen: false
  },
  {
    id: 7330,
    name: 'South Naknek Nr 2 Airport',
    city: 'South Naknek',
    country: 'United States',
    iata: 'WSN',
    icao: 'PFWS',
    latitude: 58.7033996582,
    longitude: -157.007995605,
    altitude: 162,
    schengen: false
  },
  {
    id: 7331,
    name: 'Fort Yukon Airport',
    city: 'Fort Yukon',
    country: 'United States',
    iata: 'FYU',
    icao: 'PFYU',
    latitude: 66.5715026855469,
    longitude: -145.25,
    altitude: 433,
    schengen: false
  },
  {
    id: 7332,
    name: 'Barking Sands Airport',
    city: 'Barking Sands',
    country: 'United States',
    iata: 'BKH',
    icao: 'PHBK',
    latitude: 22.0228004456,
    longitude: -159.785003662,
    altitude: 23,
    schengen: false
  },
  {
    id: 7333,
    name: 'Dillingham Airfield',
    city: 'Dillingham',
    country: 'United States',
    iata: 'HDH',
    icao: 'PHDH',
    latitude: 21.5795001984,
    longitude: -158.197006226,
    altitude: 14,
    schengen: false
  },
  {
    id: 7334,
    name: 'Wheeler Army Airfield',
    city: 'Wahiawa',
    country: 'United States',
    iata: 'HHI',
    icao: 'PHHI',
    latitude: 21.48349953,
    longitude: -158.0399933,
    altitude: 837,
    schengen: false
  },
  {
    id: 7335,
    name: 'Hana Airport',
    city: 'Hana',
    country: 'United States',
    iata: 'HNM',
    icao: 'PHHN',
    latitude: 20.7956008911133,
    longitude: -156.014007568359,
    altitude: 78,
    schengen: false
  },
  {
    id: 7336,
    name: 'Kapalua Airport',
    city: 'Lahania-kapalua',
    country: 'United States',
    iata: 'JHM',
    icao: 'PHJH',
    latitude: 20.9629001617432,
    longitude: -156.673004150391,
    altitude: 256,
    schengen: false
  },
  {
    id: 7337,
    name: 'Kalaeloa Airport',
    city: 'Kapolei',
    country: 'United States',
    iata: 'JRF',
    icao: 'PHJR',
    latitude: 21.3074,
    longitude: -158.070009,
    altitude: 30,
    schengen: false
  },
  {
    id: 7338,
    name: 'Ellison Onizuka Kona International At Keahole Airport',
    city: 'Kona',
    country: 'United States',
    iata: 'KOA',
    icao: 'PHKO',
    latitude: 19.738783,
    longitude: -156.045603,
    altitude: 47,
    schengen: false
  },
  {
    id: 7339,
    name: 'Lihue Airport',
    city: 'Lihue',
    country: 'United States',
    iata: 'LIH',
    icao: 'PHLI',
    latitude: 21.9759998321533,
    longitude: -159.339004516602,
    altitude: 153,
    schengen: false
  },
  {
    id: 7340,
    name: 'Kalaupapa Airport',
    city: 'Molokai',
    country: 'United States',
    iata: 'LUP',
    icao: 'PHLU',
    latitude: 21.21100044,
    longitude: -156.973999,
    altitude: 24,
    schengen: false
  },
  {
    id: 7341,
    name: 'Molokai Airport',
    city: 'Molokai',
    country: 'United States',
    iata: 'MKK',
    icao: 'PHMK',
    latitude: 21.1529006958008,
    longitude: -157.095993041992,
    altitude: 454,
    schengen: false
  },
  {
    id: 7342,
    name: 'Waimea Kohala Airport',
    city: 'Kamuela',
    country: 'United States',
    iata: 'MUE',
    icao: 'PHMU',
    latitude: 20.0013008117676,
    longitude: -155.667999267578,
    altitude: 2671,
    schengen: false
  },
  {
    id: 7343,
    name: 'Kaneohe Bay MCAS (Marion E. Carl Field) Airport',
    city: 'Kaneohe Bay',
    country: 'United States',
    iata: 'NGF',
    icao: 'PHNG',
    latitude: 21.4505004883,
    longitude: -157.768005371,
    altitude: 24,
    schengen: false
  },
  {
    id: 7344,
    name: 'Daniel K Inouye International Airport',
    city: 'Honolulu',
    country: 'United States',
    iata: 'HNL',
    icao: 'PHNL',
    latitude: 21.32062,
    longitude: -157.924228,
    altitude: 13,
    schengen: false
  },
  {
    id: 7345,
    name: 'Lanai Airport',
    city: 'Lanai',
    country: 'United States',
    iata: 'LNY',
    icao: 'PHNY',
    latitude: 20.7856006622314,
    longitude: -156.95100402832,
    altitude: 1308,
    schengen: false
  },
  {
    id: 7346,
    name: 'Kahului Airport',
    city: 'Kahului',
    country: 'United States',
    iata: 'OGG',
    icao: 'PHOG',
    latitude: 20.8986,
    longitude: -156.429993,
    altitude: 54,
    schengen: false
  },
  {
    id: 7347,
    name: 'Bradshaw Army Airfield',
    city: 'Bradshaw Field',
    country: 'United States',
    iata: 'BSF',
    icao: 'PHSF',
    latitude: 19.760099411,
    longitude: -155.554000854,
    altitude: 6190,
    schengen: false
  },
  {
    id: 7348,
    name: 'Hilo International Airport',
    city: 'Hilo',
    country: 'United States',
    iata: 'ITO',
    icao: 'PHTO',
    latitude: 19.721399307251,
    longitude: -155.048004150391,
    altitude: 38,
    schengen: false
  },
  {
    id: 7349,
    name: 'Upolu Airport',
    city: 'Opolu',
    country: 'United States',
    iata: 'UPP',
    icao: 'PHUP',
    latitude: 20.2653007507324,
    longitude: -155.860000610352,
    altitude: 96,
    schengen: false
  },
  {
    id: 7350,
    name: 'Point Lay LRRS Airport',
    city: 'Point Lay',
    country: 'United States',
    iata: 'PIZ',
    icao: 'PPIZ',
    latitude: 69.73290253,
    longitude: -163.0050049,
    altitude: 22,
    schengen: false
  },
  {
    id: 7351,
    name: 'Lopez Island Airport',
    city: 'Lopez',
    country: 'United States',
    iata: 'LPS',
    icao: 'S31',
    latitude: 48.4838981628418,
    longitude: -122.938003540039,
    altitude: 209,
    schengen: false
  },
  {
    id: 7352,
    name: 'El Almendro Airport',
    city: 'Scranton',
    country: 'United States',
    iata: '',
    icao: 'SCRT',
    latitude: -35.9605560302734,
    longitude: -71.7933349609375,
    altitude: 486,
    schengen: false
  },
  {
    id: 7353,
    name: 'Comarapa Airport',
    city: 'Salt Lake City',
    country: 'United States',
    iata: '',
    icao: 'SLCR',
    latitude: -17.9136009216309,
    longitude: -64.5177993774414,
    altitude: 6186,
    schengen: false
  },
  {
    id: 7354,
    name: 'Monument Valley Airport',
    city: 'Monument Valley',
    country: 'United States',
    iata: 'GMV',
    icao: 'UT25',
    latitude: 37.0167007446,
    longitude: -110.200996399,
    altitude: 5192,
    schengen: false
  },
  {
    id: 7355,
    name: 'Oryol Yuzhny Airport',
    city: 'Oakley',
    country: 'United States',
    iata: 'OEL',
    icao: 'UUOR',
    latitude: 52.9347000122,
    longitude: 36.0022010803,
    altitude: 656,
    schengen: false
  },
  {
    id: 7356,
    name: 'Enumclaw Airport',
    city: 'Enumclaw',
    country: 'United States',
    iata: '',
    icao: 'WA77',
    latitude: 47.1957015991211,
    longitude: -122.022003173828,
    altitude: 738,
    schengen: false
  },
  {
    id: 7357,
    name: 'Windwood Fly-In Resort Airport',
    city: 'Davis',
    country: 'United States',
    iata: '',
    icao: 'WV62',
    latitude: 39.0550994873,
    longitude: -79.4313964844,
    altitude: 3210,
    schengen: false
  },
  {
    id: 7358,
    name: "Port O'Connor Private Heliport",
    city: "Port O\\'Connor",
    country: 'United States',
    iata: '',
    icao: 'XS46',
    latitude: 28.429725,
    longitude: -96.444419,
    altitude: 8,
    schengen: false
  },
  {
    id: 7359,
    name: 'Cowra Airport',
    city: 'Chatsworth',
    country: 'United States',
    iata: 'CWT',
    icao: 'YCWR',
    latitude: -33.8446998596191,
    longitude: 148.649002075195,
    altitude: 966,
    schengen: false
  },
  {
    id: 7360,
    name: 'Angel S Adami Airport',
    city: 'Montevideo',
    country: 'Uruguay',
    iata: '',
    icao: 'SUAA',
    latitude: -34.7892,
    longitude: -56.264702,
    altitude: 174,
    schengen: false
  },
  {
    id: 7361,
    name: 'Artigas International Airport',
    city: 'Artigas ',
    country: 'Uruguay',
    iata: 'ATI',
    icao: 'SUAG',
    latitude: -30.4006996154785,
    longitude: -56.5079002380371,
    altitude: 410,
    schengen: false
  },
  {
    id: 7362,
    name: 'Laguna de Los Patos International Airport',
    city: 'Colonia',
    country: 'Uruguay',
    iata: 'CYR',
    icao: 'SUCA',
    latitude: -34.456401824951,
    longitude: -57.770599365234,
    altitude: 66,
    schengen: false
  },
  {
    id: 7363,
    name: 'Santa Bernardina International Airport',
    city: 'Durazno',
    country: 'Uruguay',
    iata: 'DZO',
    icao: 'SUDU',
    latitude: -33.3588981628418,
    longitude: -56.4991989135742,
    altitude: 305,
    schengen: false
  },
  {
    id: 7364,
    name: 'Capitan Corbeta CA Curbelo International Airport',
    city: 'Punta del Este',
    country: 'Uruguay',
    iata: 'PDP',
    icao: 'SULS',
    latitude: -34.8550987243652,
    longitude: -55.0942993164063,
    altitude: 95,
    schengen: false
  },
  {
    id: 7365,
    name: 'Cerro Largo International Airport',
    city: 'Melo',
    country: 'Uruguay',
    iata: 'MLZ',
    icao: 'SUMO',
    latitude: -32.3378982543945,
    longitude: -54.2167015075684,
    altitude: 364,
    schengen: false
  },
  {
    id: 7366,
    name: 'Carrasco International /General C L Berisso Airport',
    city: 'Montevideo',
    country: 'Uruguay',
    iata: 'MVD',
    icao: 'SUMU',
    latitude: -34.838402,
    longitude: -56.0308,
    altitude: 105,
    schengen: false
  },
  {
    id: 7367,
    name: 'El Jagüel / Punta del Este Airport',
    city: 'Maldonado',
    country: 'Uruguay',
    iata: '',
    icao: 'SUPE',
    latitude: -34.9169998169,
    longitude: -54.9169998169,
    altitude: 66,
    schengen: false
  },
  {
    id: 7368,
    name: 'Tydeo Larre Borges Airport',
    city: 'Paysandu',
    country: 'Uruguay',
    iata: 'PDU',
    icao: 'SUPU',
    latitude: -32.3633003234863,
    longitude: -58.0619010925293,
    altitude: 138,
    schengen: false
  },
  {
    id: 7369,
    name: 'Presidente General Don Oscar D. Gestido International Airport',
    city: 'Rivera',
    country: 'Uruguay',
    iata: 'RVY',
    icao: 'SURV',
    latitude: -30.9745998382568,
    longitude: -55.4762001037598,
    altitude: 712,
    schengen: false
  },
  {
    id: 7370,
    name: 'Nueva Hesperides International Airport',
    city: 'Salto',
    country: 'Uruguay',
    iata: 'STY',
    icao: 'SUSO',
    latitude: -31.4384994506836,
    longitude: -57.9852981567383,
    altitude: 187,
    schengen: false
  },
  {
    id: 7371,
    name: 'Andizhan Airport',
    city: 'Andizhan',
    country: 'Uzbekistan',
    iata: 'AZN',
    icao: 'UTKA',
    latitude: 40.7276992798,
    longitude: 72.2939987183,
    altitude: 1515,
    schengen: false
  },
  {
    id: 7372,
    name: 'Fergana International Airport',
    city: 'Fergana',
    country: 'Uzbekistan',
    iata: 'FEG',
    icao: 'UTKF',
    latitude: 40.3587989807,
    longitude: 71.7450027466,
    altitude: 1980,
    schengen: false
  },
  {
    id: 7373,
    name: 'Namangan Airport',
    city: 'Namangan',
    country: 'Uzbekistan',
    iata: 'NMA',
    icao: 'UTKN',
    latitude: 40.9846000671,
    longitude: 71.5567016602,
    altitude: 1555,
    schengen: false
  },
  {
    id: 7374,
    name: 'Nukus Airport',
    city: 'Nukus',
    country: 'Uzbekistan',
    iata: 'NCU',
    icao: 'UTNN',
    latitude: 42.4883995056152,
    longitude: 59.6232986450195,
    altitude: 246,
    schengen: false
  },
  {
    id: 7375,
    name: 'Urgench Airport',
    city: 'Urgench',
    country: 'Uzbekistan',
    iata: 'UGC',
    icao: 'UTNU',
    latitude: 41.5843009948731,
    longitude: 60.6417007446289,
    altitude: 320,
    schengen: false
  },
  {
    id: 7376,
    name: 'Navoi Airport',
    city: 'Navoi',
    country: 'Uzbekistan',
    iata: 'NVI',
    icao: 'UTSA',
    latitude: 40.1171989440918,
    longitude: 65.1707992553711,
    altitude: 0,
    schengen: false
  },
  {
    id: 7377,
    name: 'Bukhara Airport',
    city: 'Bukhara',
    country: 'Uzbekistan',
    iata: 'BHK',
    icao: 'UTSB',
    latitude: 39.7750015258789,
    longitude: 64.4832992553711,
    altitude: 751,
    schengen: false
  },
  {
    id: 7378,
    name: 'Karshi Khanabad Airport',
    city: 'Khanabad',
    country: 'Uzbekistan',
    iata: 'KSQ',
    icao: 'UTSL',
    latitude: 38.8335990906,
    longitude: 65.9215011597,
    altitude: 1365,
    schengen: false
  },
  {
    id: 7379,
    name: 'Sugraly Airport',
    city: 'Zarafshan',
    country: 'Uzbekistan',
    iata: 'AFS',
    icao: 'UTSN',
    latitude: 41.613899230957,
    longitude: 64.2332000732422,
    altitude: 1396,
    schengen: false
  },
  {
    id: 7380,
    name: 'Samarkand Airport',
    city: 'Samarkand',
    country: 'Uzbekistan',
    iata: 'SKD',
    icao: 'UTSS',
    latitude: 39.7005004882812,
    longitude: 66.9838027954102,
    altitude: 2224,
    schengen: false
  },
  {
    id: 7381,
    name: 'Termez Airport',
    city: 'Termez',
    country: 'Uzbekistan',
    iata: 'TMJ',
    icao: 'UTST',
    latitude: 37.2867012023926,
    longitude: 67.3099975585938,
    altitude: 1027,
    schengen: false
  },
  {
    id: 7382,
    name: 'Tashkent East Airport',
    city: 'Tashkent',
    country: 'Uzbekistan',
    iata: '',
    icao: 'UTTP',
    latitude: 41.3126983642578,
    longitude: 69.3914031982422,
    altitude: 1574,
    schengen: false
  },
  {
    id: 7383,
    name: 'Tashkent International Airport',
    city: 'Tashkent',
    country: 'Uzbekistan',
    iata: 'TAS',
    icao: 'UTTT',
    latitude: 41.257900238,
    longitude: 69.2811965942,
    altitude: 1417,
    schengen: false
  },
  {
    id: 7384,
    name: 'Mota Lava Airport',
    city: 'Ablow',
    country: 'Vanuatu',
    iata: 'MTV',
    icao: 'NVSA',
    latitude: -13.6660003662,
    longitude: 167.712005615,
    altitude: 63,
    schengen: false
  },
  {
    id: 7385,
    name: 'Sola Airport',
    city: 'Sola',
    country: 'Vanuatu',
    iata: 'SLH',
    icao: 'NVSC',
    latitude: -13.8516998291,
    longitude: 167.537002563,
    altitude: 7,
    schengen: false
  },
  {
    id: 7386,
    name: 'Torres Airstrip',
    city: 'Loh/Linua',
    country: 'Vanuatu',
    iata: 'TOH',
    icao: 'NVSD',
    latitude: -13.3280000687,
    longitude: 166.638000488,
    altitude: 75,
    schengen: false
  },
  {
    id: 7387,
    name: 'Siwo Airport',
    city: 'Sangafa',
    country: 'Vanuatu',
    iata: 'EAE',
    icao: 'NVSE',
    latitude: -17.0902996063,
    longitude: 168.343002319,
    altitude: 7,
    schengen: false
  },
  {
    id: 7388,
    name: 'Craig Cove Airport',
    city: 'Craig Cove',
    country: 'Vanuatu',
    iata: 'CCV',
    icao: 'NVSF',
    latitude: -16.264999,
    longitude: 167.923996,
    altitude: 69,
    schengen: false
  },
  {
    id: 7389,
    name: 'Longana Airport',
    city: 'Longana',
    country: 'Vanuatu',
    iata: 'LOD',
    icao: 'NVSG',
    latitude: -15.3066997528,
    longitude: 167.966995239,
    altitude: 167,
    schengen: false
  },
  {
    id: 7390,
    name: 'Sara Airport',
    city: 'Pentecost Island',
    country: 'Vanuatu',
    iata: 'SSR',
    icao: 'NVSH',
    latitude: -15.4708003998,
    longitude: 168.151992798,
    altitude: 493,
    schengen: false
  },
  {
    id: 7391,
    name: 'Tavie Airport',
    city: 'Paama Island',
    country: 'Vanuatu',
    iata: 'PBJ',
    icao: 'NVSI',
    latitude: -16.438999176,
    longitude: 168.257003784,
    altitude: 160,
    schengen: false
  },
  {
    id: 7392,
    name: 'Lamap Airport',
    city: 'Lamap',
    country: 'Vanuatu',
    iata: 'LPM',
    icao: 'NVSL',
    latitude: -16.4611228,
    longitude: 167.829253,
    altitude: 7,
    schengen: false
  },
  {
    id: 7393,
    name: 'Lamen Bay Airport',
    city: 'Lamen Bay',
    country: 'Vanuatu',
    iata: 'LNB',
    icao: 'NVSM',
    latitude: -16.5841999054,
    longitude: 168.158996582,
    altitude: 7,
    schengen: false
  },
  {
    id: 7394,
    name: 'Maewo-Naone Airport',
    city: 'Maewo Island',
    country: 'Vanuatu',
    iata: 'MWF',
    icao: 'NVSN',
    latitude: -15,
    longitude: 168.082992554,
    altitude: 509,
    schengen: false
  },
  {
    id: 7395,
    name: 'Lonorore Airport',
    city: 'Lonorore',
    country: 'Vanuatu',
    iata: 'LNE',
    icao: 'NVSO',
    latitude: -15.8655996323,
    longitude: 168.17199707,
    altitude: 43,
    schengen: false
  },
  {
    id: 7396,
    name: 'Norsup Airport',
    city: 'Norsup',
    country: 'Vanuatu',
    iata: 'NUS',
    icao: 'NVSP',
    latitude: -16.0797,
    longitude: 167.401001,
    altitude: 23,
    schengen: false
  },
  {
    id: 7397,
    name: 'Gaua Island Airport',
    city: 'Gaua Island',
    country: 'Vanuatu',
    iata: 'ZGU',
    icao: 'NVSQ',
    latitude: -14.2180995941,
    longitude: 167.587005615,
    altitude: 100,
    schengen: false
  },
  {
    id: 7398,
    name: 'Redcliffe Airport',
    city: 'Redcliffe',
    country: 'Vanuatu',
    iata: 'RCL',
    icao: 'NVSR',
    latitude: -15.4720001221,
    longitude: 167.835006714,
    altitude: 36,
    schengen: false
  },
  {
    id: 7399,
    name: 'Santo Pekoa International Airport',
    city: 'Santo',
    country: 'Vanuatu',
    iata: 'SON',
    icao: 'NVSS',
    latitude: -15.5050001144,
    longitude: 167.220001221,
    altitude: 184,
    schengen: false
  },
  {
    id: 7400,
    name: 'Tongoa Airport',
    city: 'Tongoa Island',
    country: 'Vanuatu',
    iata: 'TGH',
    icao: 'NVST',
    latitude: -16.8910999298,
    longitude: 168.550994873,
    altitude: 443,
    schengen: false
  },
  {
    id: 7401,
    name: 'Uléi Airport',
    city: 'Ambryn Island',
    country: 'Vanuatu',
    iata: 'ULB',
    icao: 'NVSU',
    latitude: -16.3297,
    longitude: 168.3011,
    altitude: 170,
    schengen: false
  },
  {
    id: 7402,
    name: 'Valesdir Airport',
    city: 'Valesdir',
    country: 'Vanuatu',
    iata: 'VLS',
    icao: 'NVSV',
    latitude: -16.7961006165,
    longitude: 168.177001953,
    altitude: 10,
    schengen: false
  },
  {
    id: 7403,
    name: 'Walaha Airport',
    city: 'Walaha',
    country: 'Vanuatu',
    iata: 'WLH',
    icao: 'NVSW',
    latitude: -15.4119997025,
    longitude: 167.690994263,
    altitude: 151,
    schengen: false
  },
  {
    id: 7404,
    name: 'Southwest Bay Airport',
    city: 'Malekula Island',
    country: 'Vanuatu',
    iata: 'SWJ',
    icao: 'NVSX',
    latitude: -16.4864,
    longitude: 167.4472,
    altitude: 68,
    schengen: false
  },
  {
    id: 7405,
    name: 'North West Santo Airport',
    city: 'Olpoi',
    country: 'Vanuatu',
    iata: '',
    icao: 'NVSZ',
    latitude: -14.8816995621,
    longitude: 166.557998657,
    altitude: 50,
    schengen: false
  },
  {
    id: 7406,
    name: 'Aneityum Airport',
    city: 'Anelghowhat',
    country: 'Vanuatu',
    iata: 'AUY',
    icao: 'NVVA',
    latitude: -20.2492008209,
    longitude: 169.770996094,
    altitude: 7,
    schengen: false
  },
  {
    id: 7407,
    name: 'Aniwa Airport',
    city: 'Aniwa',
    country: 'Vanuatu',
    iata: 'AWD',
    icao: 'NVVB',
    latitude: -19.2346,
    longitude: 169.6009,
    altitude: 69,
    schengen: false
  },
  {
    id: 7408,
    name: "Dillon's Bay Airport",
    city: "Dillon's Bay",
    country: 'Vanuatu',
    iata: 'DLY',
    icao: 'NVVD',
    latitude: -18.7693996429,
    longitude: 169.00100708,
    altitude: 630,
    schengen: false
  },
  {
    id: 7409,
    name: 'Futuna Airport',
    city: 'Futuna Island',
    country: 'Vanuatu',
    iata: 'FTA',
    icao: 'NVVF',
    latitude: -19.5163993835,
    longitude: 170.231994629,
    altitude: 95,
    schengen: false
  },
  {
    id: 7410,
    name: 'Ipota Airport',
    city: 'Ipota',
    country: 'Vanuatu',
    iata: 'IPA',
    icao: 'NVVI',
    latitude: -18.856389,
    longitude: 169.283333,
    altitude: 23,
    schengen: false
  },
  {
    id: 7411,
    name: 'Bauerfield International Airport',
    city: 'Port-vila',
    country: 'Vanuatu',
    iata: 'VLI',
    icao: 'NVVV',
    latitude: -17.699300765991,
    longitude: 168.32000732422,
    altitude: 70,
    schengen: false
  },
  {
    id: 7412,
    name: 'Tanna Airport',
    city: 'Tanna',
    country: 'Vanuatu',
    iata: 'TAH',
    icao: 'NVVW',
    latitude: -19.455099105835,
    longitude: 169.223999023438,
    altitude: 19,
    schengen: false
  },
  {
    id: 7413,
    name: 'Oswaldo Guevara Mujica Airport',
    city: 'Acarigua',
    country: 'Venezuela',
    iata: 'AGV',
    icao: 'SVAC',
    latitude: 9.55337524414063,
    longitude: -69.2378692626953,
    altitude: 640,
    schengen: false
  },
  {
    id: 7414,
    name: 'Anaco Airport',
    city: 'Anaco',
    country: 'Venezuela',
    iata: 'AAO',
    icao: 'SVAN',
    latitude: 9.43022537231445,
    longitude: -64.4707260131836,
    altitude: 721,
    schengen: false
  },
  {
    id: 7415,
    name: 'San Fernando de Atabapo Airport',
    city: 'San Fernando Deatabapo',
    country: 'Venezuela',
    iata: '',
    icao: 'SVAT',
    latitude: 4.05104017258,
    longitude: -67.6996994019,
    altitude: 301,
    schengen: false
  },
  {
    id: 7416,
    name: 'General José Antonio Anzoategui International Airport',
    city: 'Barcelona',
    country: 'Venezuela',
    iata: 'BLA',
    icao: 'SVBC',
    latitude: 10.111111,
    longitude: -64.692222,
    altitude: 30,
    schengen: false
  },
  {
    id: 7417,
    name: 'Barinas Airport',
    city: 'Barinas',
    country: 'Venezuela',
    iata: 'BNS',
    icao: 'SVBI',
    latitude: 8.615,
    longitude: -70.21416667,
    altitude: 615,
    schengen: false
  },
  {
    id: 7418,
    name: 'El Libertador Airbase',
    city: 'Maracaibo',
    country: 'Venezuela',
    iata: '',
    icao: 'SVBL',
    latitude: 10.1833753586,
    longitude: -67.5573196411,
    altitude: 1450,
    schengen: false
  },
  {
    id: 7419,
    name: 'Barquisimeto International Airport',
    city: 'Barquisimeto',
    country: 'Venezuela',
    iata: 'BRM',
    icao: 'SVBM',
    latitude: 10.0427465438843,
    longitude: -69.3586196899414,
    altitude: 2042,
    schengen: false
  },
  {
    id: 7420,
    name: 'Escuela Mariscal Sucre Airport',
    city: 'Maracay',
    country: 'Venezuela',
    iata: 'MYC',
    icao: 'SVBS',
    latitude: 10.2499780654907,
    longitude: -67.6494216918945,
    altitude: 1338,
    schengen: false
  },
  {
    id: 7421,
    name: 'Aeropuerto "General Tomas de Heres". Ciudad Bolivar',
    city: 'Ciudad Bolivar',
    country: 'Venezuela',
    iata: 'CBL',
    icao: 'SVCB',
    latitude: 8.12216091156,
    longitude: -63.5369567871,
    altitude: 197,
    schengen: false
  },
  {
    id: 7422,
    name: 'Caicara del Orinoco Airport',
    city: 'Caicara De Orinoco',
    country: 'Venezuela',
    iata: 'CXA',
    icao: 'SVCD',
    latitude: 7.62551021575928,
    longitude: -66.1628036499023,
    altitude: 141,
    schengen: false
  },
  {
    id: 7423,
    name: 'San Carlos Airport',
    city: 'San Carlos',
    country: 'Venezuela',
    iata: '',
    icao: 'SVCJ',
    latitude: 9.64772033691406,
    longitude: -68.5746994018555,
    altitude: 512,
    schengen: false
  },
  {
    id: 7424,
    name: 'Calabozo Airport',
    city: 'Calabozo',
    country: 'Venezuela',
    iata: 'CLZ',
    icao: 'SVCL',
    latitude: 8.92465591430664,
    longitude: -67.4170913696289,
    altitude: 328,
    schengen: false
  },
  {
    id: 7425,
    name: 'Canaima Airport',
    city: 'Canaima',
    country: 'Venezuela',
    iata: 'CAJ',
    icao: 'SVCN',
    latitude: 6.23198890686035,
    longitude: -62.8544311523438,
    altitude: 1450,
    schengen: false
  },
  {
    id: 7426,
    name: 'Carora Airport',
    city: 'Carora',
    country: 'Venezuela',
    iata: 'VCR',
    icao: 'SVCO',
    latitude: 10.1756029129028,
    longitude: -70.0652160644531,
    altitude: 1437,
    schengen: false
  },
  {
    id: 7427,
    name: 'General Francisco Bermúdez Airport',
    city: 'Carupano',
    country: 'Venezuela',
    iata: 'CUP',
    icao: 'SVCP',
    latitude: 10.6600141525269,
    longitude: -63.2616806030273,
    altitude: 33,
    schengen: false
  },
  {
    id: 7428,
    name: 'José Leonardo Chirinos Airport',
    city: 'Coro',
    country: 'Venezuela',
    iata: 'CZE',
    icao: 'SVCR',
    latitude: 11.4149436950684,
    longitude: -69.6809005737305,
    altitude: 52,
    schengen: false
  },
  {
    id: 7429,
    name: 'Oscar Machado Zuluaga Airport',
    city: 'Caracas',
    country: 'Venezuela',
    iata: '',
    icao: 'SVCS',
    latitude: 10.2861108779907,
    longitude: -66.8161087036133,
    altitude: 2145,
    schengen: false
  },
  {
    id: 7430,
    name: 'Cumaná (Antonio José de Sucre) Airport',
    city: 'Cumana',
    country: 'Venezuela',
    iata: 'CUM',
    icao: 'SVCU',
    latitude: 10.4503326416016,
    longitude: -64.1304702758789,
    altitude: 14,
    schengen: false
  },
  {
    id: 7431,
    name: 'Capitán Manuel Ríos Airbase',
    city: 'Carrizal',
    country: 'Venezuela',
    iata: '',
    icao: 'SVCZ',
    latitude: 9.37265014648438,
    longitude: -66.9227981567383,
    altitude: 595,
    schengen: false
  },
  {
    id: 7432,
    name: 'El Dorado Airport',
    city: 'El Dorado',
    country: 'Venezuela',
    iata: 'EOR',
    icao: 'SVED',
    latitude: 6.73333311080933,
    longitude: -61.5833320617676,
    altitude: 318,
    schengen: false
  },
  {
    id: 7433,
    name: 'Elorza Airport',
    city: 'Elorza',
    country: 'Venezuela',
    iata: 'EOZ',
    icao: 'SVEZ',
    latitude: 7.08333301544189,
    longitude: -69.533332824707,
    altitude: 295,
    schengen: false
  },
  {
    id: 7434,
    name: 'Francisco de Miranda Airport',
    city: 'Caracas',
    country: 'Venezuela',
    iata: '',
    icao: 'SVFM',
    latitude: 10.4850330353,
    longitude: -66.8435134888,
    altitude: 2739,
    schengen: false
  },
  {
    id: 7435,
    name: 'Guasdalito Airport',
    city: 'Guasdualito',
    country: 'Venezuela',
    iata: 'GDO',
    icao: 'SVGD',
    latitude: 7.23333311080933,
    longitude: -70.8000030517578,
    altitude: 426,
    schengen: false
  },
  {
    id: 7436,
    name: 'Guiria Airport',
    city: 'Guiria',
    country: 'Venezuela',
    iata: 'GUI',
    icao: 'SVGI',
    latitude: 10.5740776062,
    longitude: -62.3126678467,
    altitude: 42,
    schengen: false
  },
  {
    id: 7437,
    name: 'Guanare Airport',
    city: 'Guanare',
    country: 'Venezuela',
    iata: 'GUQ',
    icao: 'SVGU',
    latitude: 9.02694416046143,
    longitude: -69.7551498413086,
    altitude: 606,
    schengen: false
  },
  {
    id: 7438,
    name: 'Higuerote Airport',
    city: 'Higuerote',
    country: 'Venezuela',
    iata: 'HGE',
    icao: 'SVHG',
    latitude: 10.462474,
    longitude: -66.092779,
    altitude: 12,
    schengen: false
  },
  {
    id: 7439,
    name: 'Andrés Miguel Salazar Marcano Airport',
    city: 'Isla De Coche',
    country: 'Venezuela',
    iata: 'ICC',
    icao: 'SVIE',
    latitude: 10.794432,
    longitude: -63.98159,
    altitude: 10,
    schengen: false
  },
  {
    id: 7440,
    name: 'Josefa Camejo International Airport',
    city: 'Paraguana',
    country: 'Venezuela',
    iata: 'LSP',
    icao: 'SVJC',
    latitude: 11.7807750701904,
    longitude: -70.151496887207,
    altitude: 75,
    schengen: false
  },
  {
    id: 7441,
    name: 'San Juan de Los Morros Airport',
    city: 'San Juan De Los Morros',
    country: 'Venezuela',
    iata: '',
    icao: 'SVJM',
    latitude: 9.90695285797119,
    longitude: -67.379638671875,
    altitude: 1404,
    schengen: false
  },
  {
    id: 7442,
    name: 'La Fria Airport',
    city: 'La Fria',
    country: 'Venezuela',
    iata: 'LFR',
    icao: 'SVLF',
    latitude: 8.23916721343994,
    longitude: -72.2710266113281,
    altitude: 305,
    schengen: false
  },
  {
    id: 7443,
    name: 'La Orchila Airport',
    city: 'La Orchila',
    country: 'Venezuela',
    iata: '',
    icao: 'SVLO',
    latitude: 11.8072004318237,
    longitude: -66.1796035766602,
    altitude: 5,
    schengen: false
  },
  {
    id: 7444,
    name: 'La Chinita International Airport',
    city: 'Maracaibo',
    country: 'Venezuela',
    iata: 'MAR',
    icao: 'SVMC',
    latitude: 10.5582084656,
    longitude: -71.7278594971,
    altitude: 239,
    schengen: false
  },
  {
    id: 7445,
    name: 'Alberto Carnevalli Airport',
    city: 'Merida',
    country: 'Venezuela',
    iata: 'MRD',
    icao: 'SVMD',
    latitude: 8.582078,
    longitude: -71.161041,
    altitude: 5007,
    schengen: false
  },
  {
    id: 7446,
    name: 'Del Caribe Santiago Mariño International Airport',
    city: 'Porlamar',
    country: 'Venezuela',
    iata: 'PMV',
    icao: 'SVMG',
    latitude: 10.9126033782959,
    longitude: -63.9665985107422,
    altitude: 74,
    schengen: false
  },
  {
    id: 7447,
    name: 'Simón Bolívar International Airport',
    city: 'Caracas',
    country: 'Venezuela',
    iata: 'CCS',
    icao: 'SVMI',
    latitude: 10.601194,
    longitude: -66.991222,
    altitude: 234,
    schengen: false
  },
  {
    id: 7448,
    name: 'Metropolitano Airport',
    city: '',
    country: 'Venezuela',
    iata: '',
    icao: 'SVMP',
    latitude: 10.1331691741943,
    longitude: -66.7878265380859,
    altitude: 574,
    schengen: false
  },
  {
    id: 7449,
    name: 'Maturín Airport',
    city: 'Maturin',
    country: 'Venezuela',
    iata: 'MUN',
    icao: 'SVMT',
    latitude: 9.75452995300293,
    longitude: -63.1473999023438,
    altitude: 224,
    schengen: false
  },
  {
    id: 7450,
    name: 'Cacique Aramare Airport',
    city: 'Puerto Ayacucho',
    country: 'Venezuela',
    iata: 'PYH',
    icao: 'SVPA',
    latitude: 5.6199898719788,
    longitude: -67.606101989746,
    altitude: 245,
    schengen: false
  },
  {
    id: 7451,
    name: 'General Bartolome Salom International Airport',
    city: 'Puerto Cabello',
    country: 'Venezuela',
    iata: 'PBL',
    icao: 'SVPC',
    latitude: 10.4805002212524,
    longitude: -68.072998046875,
    altitude: 32,
    schengen: false
  },
  {
    id: 7452,
    name: 'Paramillo Airport',
    city: 'San Cristobal',
    country: 'Venezuela',
    iata: 'SCI',
    icao: 'SVPM',
    latitude: 7.80132007598877,
    longitude: -72.2029037475586,
    altitude: 3314,
    schengen: false
  },
  {
    id: 7453,
    name: 'General Manuel Carlos Piar International Airport',
    city: 'Guayana',
    country: 'Venezuela',
    iata: 'PZO',
    icao: 'SVPR',
    latitude: 8.28853034973145,
    longitude: -62.7603988647461,
    altitude: 472,
    schengen: false
  },
  {
    id: 7454,
    name: 'Palmarito Airport',
    city: 'Palmarito',
    country: 'Venezuela',
    iata: 'PTM',
    icao: 'SVPT',
    latitude: 7.56666994094849,
    longitude: -70.1832962036133,
    altitude: 347,
    schengen: false
  },
  {
    id: 7455,
    name: 'Los Roques Airport',
    city: 'Los Roques',
    country: 'Venezuela',
    iata: 'LRV',
    icao: 'SVRS',
    latitude: 11.9499998093,
    longitude: -66.6699981689,
    altitude: 17,
    schengen: false
  },
  {
    id: 7456,
    name: 'San Antonio Del Tachira Airport',
    city: 'San Antonio',
    country: 'Venezuela',
    iata: 'SVZ',
    icao: 'SVSA',
    latitude: 7.84082984924316,
    longitude: -72.439697265625,
    altitude: 1312,
    schengen: false
  },
  {
    id: 7457,
    name: 'Santa Bárbara de Barinas Airport',
    city: 'Santa Barbara',
    country: 'Venezuela',
    iata: 'SBB',
    icao: 'SVSB',
    latitude: 7.80351400375366,
    longitude: -71.1657180786133,
    altitude: 590,
    schengen: false
  },
  {
    id: 7458,
    name: 'Santa Elena de Uairen Airport',
    city: 'Santa Ana De Uairen',
    country: 'Venezuela',
    iata: 'SNV',
    icao: 'SVSE',
    latitude: 4.55499982833862,
    longitude: -61.1500015258789,
    altitude: 2938,
    schengen: false
  },
  {
    id: 7459,
    name: 'Mayor Buenaventura Vivas International Airport',
    city: 'Santo Domingo',
    country: 'Venezuela',
    iata: 'STD',
    icao: 'SVSO',
    latitude: 7.56538,
    longitude: -72.035103,
    altitude: 1083,
    schengen: false
  },
  {
    id: 7460,
    name: 'Sub Teniente Nestor Arias Airport',
    city: 'San Felipe',
    country: 'Venezuela',
    iata: 'SNF',
    icao: 'SVSP',
    latitude: 10.2787,
    longitude: -68.755203,
    altitude: 761,
    schengen: false
  },
  {
    id: 7461,
    name: 'San Fernando De Apure Airport',
    city: 'San Fernando De Apure',
    country: 'Venezuela',
    iata: 'SFD',
    icao: 'SVSR',
    latitude: 7.88331985473633,
    longitude: -67.4440002441406,
    altitude: 154,
    schengen: false
  },
  {
    id: 7462,
    name: 'San Tomé Airport',
    city: 'San Tome',
    country: 'Venezuela',
    iata: 'SOM',
    icao: 'SVST',
    latitude: 8.9451465606689,
    longitude: -64.151084899902,
    altitude: 861,
    schengen: false
  },
  {
    id: 7463,
    name: 'Santa Bárbara del Zulia Airport',
    city: 'Santa Barbara',
    country: 'Venezuela',
    iata: 'STB',
    icao: 'SVSZ',
    latitude: 8.97455024719238,
    longitude: -71.9432525634766,
    altitude: 32,
    schengen: false
  },
  {
    id: 7464,
    name: 'Tucupita Airport',
    city: 'Tucupita',
    country: 'Venezuela',
    iata: 'TUV',
    icao: 'SVTC',
    latitude: 9.08899402618408,
    longitude: -62.0941734313965,
    altitude: 16,
    schengen: false
  },
  {
    id: 7465,
    name: 'Tumeremo Airport',
    city: 'Tumeremo',
    country: 'Venezuela',
    iata: 'TMO',
    icao: 'SVTM',
    latitude: 7.24938,
    longitude: -61.52893,
    altitude: 345,
    schengen: false
  },
  {
    id: 7466,
    name: 'Arturo Michelena International Airport',
    city: 'Valencia',
    country: 'Venezuela',
    iata: 'VLN',
    icao: 'SVVA',
    latitude: 10.1497325897217,
    longitude: -67.9283981323242,
    altitude: 1411,
    schengen: false
  },
  {
    id: 7467,
    name: 'Juan Pablo Pérez Alfonso Airport',
    city: 'El Vigía',
    country: 'Venezuela',
    iata: 'VIG',
    icao: 'SVVG',
    latitude: 8.624139,
    longitude: -71.672668,
    altitude: 250,
    schengen: false
  },
  {
    id: 7468,
    name: 'Dr. Antonio Nicolás Briceño Airport',
    city: 'Valera',
    country: 'Venezuela',
    iata: 'VLV',
    icao: 'SVVL',
    latitude: 9.34047794342041,
    longitude: -70.5840606689453,
    altitude: 2060,
    schengen: false
  },
  {
    id: 7469,
    name: 'Valle de La Pascua Airport',
    city: 'Valle De La Pascua',
    country: 'Venezuela',
    iata: 'VDP',
    icao: 'SVVP',
    latitude: 9.22202777863,
    longitude: -65.9935836792,
    altitude: 410,
    schengen: false
  },
  {
    id: 7470,
    name: 'Buon Ma Thuot Airport',
    city: 'Buonmethuot',
    country: 'Vietnam',
    iata: 'BMV',
    icao: 'VVBM',
    latitude: 12.668299675,
    longitude: 108.120002747,
    altitude: 1729,
    schengen: false
  },
  {
    id: 7471,
    name: 'Chu Lai International Airport',
    city: 'Chu Lai',
    country: 'Vietnam',
    iata: 'VCL',
    icao: 'VVCA',
    latitude: 15.4033002853,
    longitude: 108.706001282,
    altitude: 10,
    schengen: false
  },
  {
    id: 7472,
    name: 'Cat Bi International Airport',
    city: 'Haiphong',
    country: 'Vietnam',
    iata: 'HPH',
    icao: 'VVCI',
    latitude: 20.8194007873535,
    longitude: 106.724998474121,
    altitude: 6,
    schengen: false
  },
  {
    id: 7473,
    name: 'Cà Mau Airport',
    city: 'Ca Mau',
    country: 'Vietnam',
    iata: 'CAH',
    icao: 'VVCM',
    latitude: 9.177667,
    longitude: 105.177778,
    altitude: 6,
    schengen: false
  },
  {
    id: 7474,
    name: 'Cam Ranh Airport',
    city: 'Nha Trang',
    country: 'Vietnam',
    iata: 'CXR',
    icao: 'VVCR',
    latitude: 11.9982004165649,
    longitude: 109.21900177002,
    altitude: 40,
    schengen: false
  },
  {
    id: 7475,
    name: 'Co Ong Airport',
    city: 'Conson',
    country: 'Vietnam',
    iata: 'VCS',
    icao: 'VVCS',
    latitude: 8.73183,
    longitude: 106.633003,
    altitude: 20,
    schengen: false
  },
  {
    id: 7476,
    name: 'Can Tho International Airport',
    city: 'Can Tho',
    country: 'Vietnam',
    iata: 'VCA',
    icao: 'VVCT',
    latitude: 10.085100174,
    longitude: 105.711997986,
    altitude: 9,
    schengen: false
  },
  {
    id: 7477,
    name: 'Dien Bien Phu Airport',
    city: 'Dienbienphu',
    country: 'Vietnam',
    iata: 'DIN',
    icao: 'VVDB',
    latitude: 21.3974990845,
    longitude: 103.008003235,
    altitude: 1611,
    schengen: false
  },
  {
    id: 7478,
    name: 'Lien Khuong Airport',
    city: 'Dalat',
    country: 'Vietnam',
    iata: 'DLI',
    icao: 'VVDL',
    latitude: 11.75,
    longitude: 108.366997,
    altitude: 3156,
    schengen: false
  },
  {
    id: 7479,
    name: 'Da Nang International Airport',
    city: 'Danang',
    country: 'Vietnam',
    iata: 'DAD',
    icao: 'VVDN',
    latitude: 16.0438995361328,
    longitude: 108.198997497559,
    altitude: 33,
    schengen: false
  },
  {
    id: 7480,
    name: 'Gia Lam Air Base',
    city: 'Hanoi',
    country: 'Vietnam',
    iata: '',
    icao: 'VVGL',
    latitude: 21.0405006408691,
    longitude: 105.886001586914,
    altitude: 50,
    schengen: false
  },
  {
    id: 7481,
    name: 'Kep Air Base',
    city: 'Kep',
    country: 'Vietnam',
    iata: '',
    icao: 'VVKP',
    latitude: 21.3945999146,
    longitude: 106.261001587,
    altitude: 55,
    schengen: false
  },
  {
    id: 7482,
    name: 'Noi Bai International Airport',
    city: 'Hanoi',
    country: 'Vietnam',
    iata: 'HAN',
    icao: 'VVNB',
    latitude: 21.2212009429932,
    longitude: 105.806999206543,
    altitude: 39,
    schengen: false
  },
  {
    id: 7483,
    name: 'Na-San Airport',
    city: 'Son-La',
    country: 'Vietnam',
    iata: 'SQH',
    icao: 'VVNS',
    latitude: 21.2169990539551,
    longitude: 104.032997131348,
    altitude: 2133,
    schengen: false
  },
  {
    id: 7484,
    name: 'Nha Trang Air Base',
    city: 'Nhatrang',
    country: 'Vietnam',
    iata: 'NHA',
    icao: 'VVNT',
    latitude: 12.2275,
    longitude: 109.192001,
    altitude: 20,
    schengen: false
  },
  {
    id: 7485,
    name: 'Phu Bai Airport',
    city: 'Hue',
    country: 'Vietnam',
    iata: 'HUI',
    icao: 'VVPB',
    latitude: 16.4015007019,
    longitude: 107.70300293,
    altitude: 48,
    schengen: false
  },
  {
    id: 7486,
    name: 'Phu Cat Airport',
    city: 'Phucat',
    country: 'Vietnam',
    iata: 'UIH',
    icao: 'VVPC',
    latitude: 13.955,
    longitude: 109.042,
    altitude: 80,
    schengen: false
  },
  {
    id: 7487,
    name: 'Pleiku Airport',
    city: 'Pleiku',
    country: 'Vietnam',
    iata: 'PXU',
    icao: 'VVPK',
    latitude: 14.0045003890991,
    longitude: 108.016998291016,
    altitude: 2434,
    schengen: false
  },
  {
    id: 7488,
    name: 'Phu Quoc International Airport',
    city: 'Phuquoc',
    country: 'Vietnam',
    iata: 'PQC',
    icao: 'VVPQ',
    latitude: 10.1698,
    longitude: 103.9931,
    altitude: 37,
    schengen: false
  },
  {
    id: 7489,
    name: 'Phan Rang Airport',
    city: 'Phan Rang',
    country: 'Vietnam',
    iata: 'PHA',
    icao: 'VVPR',
    latitude: 11.6335000992,
    longitude: 108.952003479,
    altitude: 101,
    schengen: false
  },
  {
    id: 7490,
    name: 'Rach Gia Airport',
    city: 'Rach Gia',
    country: 'Vietnam',
    iata: 'VKG',
    icao: 'VVRG',
    latitude: 9.95802997234,
    longitude: 105.132379532,
    altitude: 7,
    schengen: false
  },
  {
    id: 7491,
    name: 'Dong Tac Airport',
    city: 'Tuy Hoa',
    country: 'Vietnam',
    iata: 'TBB',
    icao: 'VVTH',
    latitude: 13.0495996475,
    longitude: 109.333999634,
    altitude: 20,
    schengen: false
  },
  {
    id: 7492,
    name: 'Tan Son Nhat International Airport',
    city: 'Ho Chi Minh City',
    country: 'Vietnam',
    iata: 'SGN',
    icao: 'VVTS',
    latitude: 10.8187999725,
    longitude: 106.652000427,
    altitude: 33,
    schengen: false
  },
  {
    id: 7493,
    name: 'Vinh Airport',
    city: 'Vinh',
    country: 'Vietnam',
    iata: 'VII',
    icao: 'VVVH',
    latitude: 18.7376003265,
    longitude: 105.67099762,
    altitude: 23,
    schengen: false
  },
  {
    id: 7494,
    name: 'Cyril E. King Airport',
    city: 'St. Thomas',
    country: 'Virgin Islands',
    iata: 'STT',
    icao: 'TIST',
    latitude: 18.3372993469238,
    longitude: -64.9733963012695,
    altitude: 23,
    schengen: false
  },
  {
    id: 7495,
    name: 'Henry E Rohlsen Airport',
    city: 'St. Croix Island',
    country: 'Virgin Islands',
    iata: 'STX',
    icao: 'TISX',
    latitude: 17.7019004821777,
    longitude: -64.7985992431641,
    altitude: 74,
    schengen: false
  },
  {
    id: 7496,
    name: 'Charlotte Amalie Harbor Seaplane Base',
    city: 'Charlotte Amalie',
    country: 'Virgin Islands',
    iata: 'SPB',
    icao: 'VI22',
    latitude: 18.3386001586914,
    longitude: -64.9406967163086,
    altitude: 0,
    schengen: false
  },
  {
    id: 7497,
    name: 'Wake Island Airfield',
    city: 'Wake island',
    country: 'Wake Island',
    iata: 'AWK',
    icao: 'PWAK',
    latitude: 19.2821006774902,
    longitude: 166.636001586914,
    altitude: 14,
    schengen: false
  },
  {
    id: 7498,
    name: 'Pointe Vele Airport',
    city: 'Futuna Island',
    country: 'Wallis and Futuna',
    iata: 'FUT',
    icao: 'NLWF',
    latitude: -14.3114004135,
    longitude: -178.065994263,
    altitude: 20,
    schengen: false
  },
  {
    id: 7499,
    name: 'Hihifo Airport',
    city: 'Wallis',
    country: 'Wallis and Futuna',
    iata: 'WLS',
    icao: 'NLWW',
    latitude: -13.2383003235,
    longitude: -176.199005127,
    altitude: 79,
    schengen: false
  },
  {
    id: 7500,
    name: 'Jerusalem Airport',
    city: 'Jerusalem',
    country: 'West Bank',
    iata: '',
    icao: 'OJJR',
    latitude: 31.864700317383,
    longitude: 35.219200134277,
    altitude: 2485,
    schengen: false
  },
  {
    id: 7501,
    name: 'Smara Airport',
    city: 'Smara',
    country: 'Western Sahara',
    iata: 'SMW',
    icao: 'GMMA',
    latitude: 26.7318,
    longitude: -11.6847,
    altitude: 350,
    schengen: false
  },
  {
    id: 7502,
    name: 'Dakhla Airport',
    city: 'Dakhla',
    country: 'Western Sahara',
    iata: 'VIL',
    icao: 'GMMH',
    latitude: 23.7183,
    longitude: -15.932,
    altitude: 36,
    schengen: false
  },
  {
    id: 7503,
    name: 'Hassan I Airport',
    city: 'El Aaiún',
    country: 'Western Sahara',
    iata: 'EUN',
    icao: 'GMML',
    latitude: 27.151699,
    longitude: -13.2192,
    altitude: 207,
    schengen: false
  },
  {
    id: 7504,
    name: 'Aden International Airport',
    city: 'Aden',
    country: 'Yemen',
    iata: 'ADE',
    icao: 'OYAA',
    latitude: 12.8295001983643,
    longitude: 45.0288009643555,
    altitude: 7,
    schengen: false
  },
  {
    id: 7505,
    name: 'Ataq Airport',
    city: 'Ataq',
    country: 'Yemen',
    iata: 'AXK',
    icao: 'OYAT',
    latitude: 14.5513000488281,
    longitude: 46.8261985778809,
    altitude: 3735,
    schengen: false
  },
  {
    id: 7506,
    name: 'Al Badie Airport',
    city: 'Al Badie',
    country: 'Yemen',
    iata: '',
    icao: 'OYBA',
    latitude: 18.7192993164063,
    longitude: 50.8368988037109,
    altitude: 908,
    schengen: false
  },
  {
    id: 7507,
    name: 'Beihan Airport',
    city: 'Beihan',
    country: 'Yemen',
    iata: 'BHN',
    icao: 'OYBN',
    latitude: 14.7819995880127,
    longitude: 45.720100402832,
    altitude: 3800,
    schengen: false
  },
  {
    id: 7508,
    name: 'Al Ghaidah International Airport',
    city: 'Al Ghaidah Intl',
    country: 'Yemen',
    iata: 'AAY',
    icao: 'OYGD',
    latitude: 16.1916999816895,
    longitude: 52.1749992370606,
    altitude: 134,
    schengen: false
  },
  {
    id: 7509,
    name: 'Hodeidah International Airport',
    city: 'Hodeidah',
    country: 'Yemen',
    iata: 'HOD',
    icao: 'OYHD',
    latitude: 14.7530002593994,
    longitude: 42.9762992858887,
    altitude: 41,
    schengen: false
  },
  {
    id: 7510,
    name: 'Mukalla International Airport',
    city: 'Mukalla',
    country: 'Yemen',
    iata: 'RIY',
    icao: 'OYRN',
    latitude: 14.6625995635986,
    longitude: 49.375,
    altitude: 54,
    schengen: false
  },
  {
    id: 7511,
    name: "Sana'a International Airport",
    city: 'Sanaa',
    country: 'Yemen',
    iata: 'SAH',
    icao: 'OYSN',
    latitude: 15.476300239563,
    longitude: 44.2196998596191,
    altitude: 7216,
    schengen: false
  },
  {
    id: 7512,
    name: 'Socotra International Airport',
    city: 'Socotra',
    country: 'Yemen',
    iata: 'SCT',
    icao: 'OYSQ',
    latitude: 12.6307001113892,
    longitude: 53.9057998657227,
    altitude: 146,
    schengen: false
  },
  {
    id: 7513,
    name: 'Sayun International Airport',
    city: 'Sayun Intl',
    country: 'Yemen',
    iata: 'GXF',
    icao: 'OYSY',
    latitude: 15.9660997391,
    longitude: 48.7882995605,
    altitude: 2097,
    schengen: false
  },
  {
    id: 7514,
    name: "Ta'izz International Airport",
    city: 'Taiz',
    country: 'Yemen',
    iata: 'TAI',
    icao: 'OYTZ',
    latitude: 13.6859998703,
    longitude: 44.1390991211,
    altitude: 4838,
    schengen: false
  },
  {
    id: 7515,
    name: 'Chipata Airport',
    city: 'Chipata',
    country: 'Zambia',
    iata: 'CIP',
    icao: 'FLCP',
    latitude: -13.5583000183105,
    longitude: 32.5872001647949,
    altitude: 3360,
    schengen: false
  },
  {
    id: 7516,
    name: 'Kasompe Airport',
    city: 'Kasompe',
    country: 'Zambia',
    iata: 'CGJ',
    icao: 'FLKE',
    latitude: -12.572799682617,
    longitude: 27.893899917603,
    altitude: 4636,
    schengen: false
  },
  {
    id: 7517,
    name: 'Kasama Airport',
    city: 'Kasama',
    country: 'Zambia',
    iata: 'KAA',
    icao: 'FLKS',
    latitude: -10.2166996002197,
    longitude: 31.13330078125,
    altitude: 4541,
    schengen: false
  },
  {
    id: 7518,
    name: 'Kasaba Bay Airport',
    city: 'Kasaba Bay',
    country: 'Zambia',
    iata: 'ZKB',
    icao: 'FLKY',
    latitude: -8.52499961853027,
    longitude: 30.6630001068115,
    altitude: 2780,
    schengen: false
  },
  {
    id: 7519,
    name: 'Lusaka City Airport',
    city: 'Lusaka',
    country: 'Zambia',
    iata: '',
    icao: 'FLLC',
    latitude: -15.4138002396,
    longitude: 28.3306999207,
    altitude: 4200,
    schengen: false
  },
  {
    id: 7520,
    name: 'Livingstone Airport',
    city: 'Livingstone',
    country: 'Zambia',
    iata: 'LVI',
    icao: 'FLLI',
    latitude: -17.8218002319336,
    longitude: 25.8227005004883,
    altitude: 3302,
    schengen: false
  },
  {
    id: 7521,
    name: 'Kenneth Kaunda International Airport Lusaka',
    city: 'Lusaka',
    country: 'Zambia',
    iata: 'LUN',
    icao: 'FLLS',
    latitude: -15.3308000565,
    longitude: 28.4526004791,
    altitude: 3779,
    schengen: false
  },
  {
    id: 7522,
    name: 'Mansa Airport',
    city: 'Mansa',
    country: 'Zambia',
    iata: 'MNS',
    icao: 'FLMA',
    latitude: -11.1370000839233,
    longitude: 28.8726005554199,
    altitude: 4101,
    schengen: false
  },
  {
    id: 7523,
    name: 'Mfuwe Airport',
    city: 'Mfuwe',
    country: 'Zambia',
    iata: 'MFU',
    icao: 'FLMF',
    latitude: -13.2588996887207,
    longitude: 31.9365997314453,
    altitude: 1853,
    schengen: false
  },
  {
    id: 7524,
    name: 'Mongu Airport',
    city: 'Mongu',
    country: 'Zambia',
    iata: 'MNR',
    icao: 'FLMG',
    latitude: -15.2545003890991,
    longitude: 23.1623001098633,
    altitude: 3488,
    schengen: false
  },
  {
    id: 7525,
    name: 'Simon Mwansa Kapwepwe International Airport',
    city: 'Ndola',
    country: 'Zambia',
    iata: 'NLA',
    icao: 'FLND',
    latitude: -12.998100280762,
    longitude: 28.66489982605,
    altitude: 4167,
    schengen: false
  },
  {
    id: 7526,
    name: 'Southdowns Airport',
    city: 'Southdowns',
    country: 'Zambia',
    iata: 'KIW',
    icao: 'FLSO',
    latitude: -12.9005002975464,
    longitude: 28.1499004364014,
    altitude: 4145,
    schengen: false
  },
  {
    id: 7527,
    name: 'Los Alamitos Army Air Field',
    city: 'Solwesi',
    country: 'Zambia',
    iata: '',
    icao: 'KSLI',
    latitude: 33.79000092,
    longitude: -118.052002,
    altitude: 32,
    schengen: false
  },
  {
    id: 7528,
    name: 'Bumi Airport',
    city: 'Bumi Hills',
    country: 'Zimbabwe',
    iata: 'BZH',
    icao: 'FVBM',
    latitude: -16.8169994354,
    longitude: 28.3500003815,
    altitude: 1650,
    schengen: false
  },
  {
    id: 7529,
    name: 'Joshua Mqabuko Nkomo International Airport',
    city: 'Bulawayo',
    country: 'Zimbabwe',
    iata: 'BUQ',
    icao: 'FVBU',
    latitude: -20.0174007415771,
    longitude: 28.6179008483887,
    altitude: 4359,
    schengen: false
  },
  {
    id: 7530,
    name: 'Charles Prince Airport',
    city: 'Harare',
    country: 'Zimbabwe',
    iata: '',
    icao: 'FVCP',
    latitude: -17.7516002655029,
    longitude: 30.9246997833252,
    altitude: 4845,
    schengen: false
  },
  {
    id: 7531,
    name: 'Buffalo Range Airport',
    city: 'Chiredzi',
    country: 'Zimbabwe',
    iata: 'BFO',
    icao: 'FVCZ',
    latitude: -21.008101,
    longitude: 31.5786,
    altitude: 1421,
    schengen: false
  },
  {
    id: 7532,
    name: 'Victoria Falls International Airport',
    city: 'Victoria Falls',
    country: 'Zimbabwe',
    iata: 'VFA',
    icao: 'FVFA',
    latitude: -18.0958995819092,
    longitude: 25.8390007019043,
    altitude: 3490,
    schengen: false
  },
  {
    id: 7533,
    name: 'Harare International Airport',
    city: 'Harare',
    country: 'Zimbabwe',
    iata: 'HRE',
    icao: 'FVHA',
    latitude: -17.9318008422852,
    longitude: 31.0928001403809,
    altitude: 4887,
    schengen: false
  },
  {
    id: 7534,
    name: 'Kariba International Airport',
    city: 'Kariba',
    country: 'Zimbabwe',
    iata: 'KAB',
    icao: 'FVKB',
    latitude: -16.5198001861572,
    longitude: 28.8850002288818,
    altitude: 1706,
    schengen: false
  },
  {
    id: 7535,
    name: 'Mana Pools Airport',
    city: 'Mana Pools',
    country: 'Zimbabwe',
    iata: '',
    icao: 'FVMN',
    latitude: -15.768735,
    longitude: 29.385617,
    altitude: 1300,
    schengen: false
  },
  {
    id: 7536,
    name: 'Mutoko Airport',
    city: 'Mutoko',
    country: 'Zimbabwe',
    iata: '',
    icao: 'FVMT',
    latitude: -17.4319000244141,
    longitude: 32.1845016479492,
    altitude: 3950,
    schengen: false
  },
  {
    id: 7537,
    name: 'Mutare Airport',
    city: 'Mutare',
    country: 'Zimbabwe',
    iata: 'UTA',
    icao: 'FVMU',
    latitude: -18.997499465942,
    longitude: 32.627201080322,
    altitude: 3410,
    schengen: false
  },
  {
    id: 7538,
    name: 'Masvingo International Airport',
    city: 'Masvingo',
    country: 'Zimbabwe',
    iata: 'MVZ',
    icao: 'FVMV',
    latitude: -20.0552997589111,
    longitude: 30.8591003417969,
    altitude: 3595,
    schengen: false
  },
  {
    id: 7539,
    name: 'Zvishavane Airport',
    city: 'Zvishavane',
    country: 'Zimbabwe',
    iata: '',
    icao: 'FVSH',
    latitude: -20.2894992828369,
    longitude: 30.0881996154785,
    altitude: 3012,
    schengen: false
  },
  {
    id: 7540,
    name: 'Spray View Airport',
    city: 'Spray View',
    country: 'Zimbabwe',
    iata: '',
    icao: 'FVSV',
    latitude: -17.9169998168945,
    longitude: 25.8169994354248,
    altitude: 3210,
    schengen: false
  },
  {
    id: 7541,
    name: 'Thornhill Air Base',
    city: 'Gwert',
    country: 'Zimbabwe',
    iata: 'GWE',
    icao: 'FVTL',
    latitude: -19.4363994598389,
    longitude: 29.8619003295898,
    altitude: 4680,
    schengen: false
  },
  {
    id: 7542,
    name: 'Hwange National Park Airport',
    city: 'Hwange National Park',
    country: 'Zimbabwe',
    iata: 'HWN',
    icao: 'FVWN',
    latitude: -18.6298999786377,
    longitude: 27.0209999084473,
    altitude: 3543,
    schengen: false
  },
  {
    id: 7543,
    name: 'Hwange (Town) Airport',
    city: 'Hwange',
    country: 'Zimbabwe',
    iata: 'WKI',
    icao: 'FVWT',
    latitude: -18.362967,
    longitude: 26.519791,
    altitude: 2500,
    schengen: false
  },
  {
    id: 7544,
    name: 'Oostende Heliport',
    city: 'Ostend',
    country: 'Belgium',
    iata: '',
    icao: 'EBNH',
    latitude: 51.1944,
    longitude: 2.8547,
    altitude: 13,
    schengen: true
  }
]);
