/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { StickyContainer, Sticky } from "react-sticky";
import ReactDataGrid from "react-data-grid";
import { Modal, ButtonToolbar, Button } from "reactstrap";
import { Editors } from "react-data-grid-addons";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RotateLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import { FaSync, FaPlus } from "react-icons/fa";
import axios from "axios";
import _ from "lodash";
import { getAllUsers, postUsers, putUsers } from "../../api/usersApi";
import roles from "../../enums/roles";
import status from "../../enums/userStatus";

const uuidv1 = require("uuid/v1");

const { DropDownEditor } = Editors;

class Users extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = axios.CancelToken.source();
    this.state = {
      isLoading: true,
      didLoad: false,
      isCreating: false,
      showModal: false,
      newUser: {},
      table: {
        key: uuidv1(),
        columns: [],
        rows: []
      }
    };
  }

  componentDidMount() {
    this.refreshAsync();
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel("Users will unmount.");
  }

  async refreshAsync() {
    try {
      this.cancelTokenSource = axios.CancelToken.source();
      const users = await getAllUsers(this.cancelTokenSource.token);
      const { table } = this.state;
      table.key = uuidv1();
      table.columns = this.createColumns();
      table.rows = _.sortBy(users, "id");
      this.setState({
        isLoading: false,
        didLoad: true,
        table
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while loading users!");
        console.log(err);
        this.setState({ isLoading: false, didLoad: false });
      }
    }
  }

  handleRefreshButtonClick = event => {
    event.preventDefault();
    this.refreshAsync();
  };

  getDisplayNameForRole = role => {
    switch (role) {
      case roles.ADMINISTRATOR:
        return "Administrator";
      case roles.NAVIGATION_OFFICE:
        return "Navigation Office";
      case roles.OBSERVER:
        return "Observer";
      case roles.NONE:
        return "None";
      default:
        return role;
    }
  };

  createColumns() {
    let roleOptions = [];
    _.mapKeys(roles, (value, key) => {
      const name = this.getDisplayNameForRole(value);
      const option = {
        id: value,
        value,
        title: name,
        text: name
      };
      roleOptions = [...roleOptions, option];
    });
    const RoleEditor = <DropDownEditor options={roleOptions} />;
    const RoleFormatter = ({ value }) => {
      const name = this.getDisplayNameForRole(value);
      return <div title={name}>{name}</div>;
    };
    const statusOptions = [
      { id: "active", value: "active" },
      { id: "inactive", value: "inactive" }
    ];
    const StatusEditor = <DropDownEditor options={statusOptions} />;
    const StatusFormatter = ({ value }) => {
      const name = value.charAt(0).toUpperCase() + value.slice(1);;
      return <div title={name}>{name}</div>;
    };
    return [
      {
        key: "id",
        name: "#",
        width: 80
      },
      {
        key: "display_name",
        name: "Display Name",
        // width: 200,
        editable: false,
        filterable: false,
        sortable: false
      },
      {
        key: "username",
        name: "Username",
        // width: 200,
        editable: false,
        filterable: false,
        sortable: false
      },
      {
        key: "email",
        name: "Email",
        // width: 200,
        editable: false,
        filterable: false,
        sortable: false
      },
      {
        key: "role",
        name: "Role",
        // width: 200,
        editable: false,
        filterable: false,
        sortable: false,
        // editor: RoleEditor,
        formatter: RoleFormatter
      },
      {
        key: "status",
        name: "Status",
        // width: 200,
        editable: false,
        filterable: false,
        sortable: false,
        // editor: StatusEditor,
        formatter: StatusFormatter
      }
    ];
  }

  handleUserUpdate = async updatedUsers => {
    
    this.cancelTokenSource = axios.CancelToken.source();

    try {
      this.setState({ isUpdating: true });
      const updatedUser = await putUsers(
        this.state.editUser,
        this.cancelTokenSource.token
      );
      const users = [
        ..._.filter(this.state.users, u => {
          return u.id !== updatedUser.id;
        }),
        updatedUser
      ];
      this.setState({
        isUpdating: false,
        users: _.sortBy(users, "id")
      });
      this.hideUserEditModal();
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while editing a user!");
        console.log(err);
      }
      this.setState({ isUpdating: false });
    }

    try {
      const updatedServiceGroupsResponse = await putUsers(
        updatedUsers,
        this.cancelTokenSource.token
      );
      if (
        updatedServiceGroupsResponse &&
        Array.isArray(updatedServiceGroupsResponse)
      ) {
        const { serviceTable, serviceGroupTable } = this.state;
        const { rows } = serviceGroupTable;
        for (let i = 0; i < updatedServiceGroupsResponse.length; i += 1) {
          const serviceGroup = updatedServiceGroupsResponse[i];
          const index = _.findIndex(rows, { id: serviceGroup.id });
          rows[index] = { ...rows[index], ...serviceGroup };
        }
        serviceTable.columns = this.getServiceTableColumns(
          serviceGroupTable.rows
        );
        serviceTable.key = uuidv1();
        serviceGroupTable.key = uuidv1();
        this.setState({ serviceTable, serviceGroupTable });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while updating service groups!");
        console.log(err);
      }
    }
  };

  toggleModal = event => {
    if (event) event.preventDefault();
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  cancelNewUser = event => {
    event.preventDefault();
    this.setState({ showModal: false, newUser: {} });
  };

  createNewUser = async event => {
    if (event) event.preventDefault();
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      const { newUser, table } = this.state;
      const { rows } = table;
      const user = await postUsers(newUser, this.cancelTokenSource.token);
      if (user) {
        table.rows = [...rows, user];
        table.rows = _.sortBy(table.rows, "id");
        table.key = uuidv1();
        this.setState({
          isCreating: false,
          showServiceModal: false,
          table
        });
      } else {
        this.setState({
          isCreating: false,
          showServiceGroupModal: false
        });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while creating a new service!");
        console.log(err);
      }
      this.setState({ isCreating: false });
    }
  };

  handleFormChange = event => {
    if (event) event.preventDefault();
    const field = event.target.id;
    const { newService } = this.state;
    newService[field] = event.target.value;
    this.setState({ newService });
  };

  // Modals
  renderCreationModals = showModal => {
    const { table, newUser } = this.state;
    const RoleOptions = (
      <React.Fragment>
        {_.map(roles, role => (
          <option key={role} value={role}>
            {this.getDisplayNameForRole(role)}
          </option>
        ))}
      </React.Fragment>
    );
    return (
      <Modal
        zIndex={9000}
        isOpen={showModal}
        toggle={this.toggleServiceModal}
        className="modal-dialog--header modal-dialog--success"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={this.toggleModal}
          />
          <h4 className="bold-text  modal__title">New user</h4>
        </div>
        <div className="modal__body">
          <Form>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control id="username" onChange={this.handleFormChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control id="email" onChange={this.handleFormChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                id="role"
                onChange={this.handleFormChange}
              >
                {RoleOptions}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                id="display_name"
                onChange={this.handleFormChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                id="status"
                onChange={this.handleFormChange}
              >
                <option value="active">active</option>
                <option value="inactive">inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button onClick={this.cancelNewUser}>Cancel</Button>
          <Button outline color="success" onClick={this.createNewUser}>
            Create
          </Button>
        </ButtonToolbar>
      </Modal>
    );
  };

  getTableRow = i => {
    const { table } = this.state;
    return table.rows[i];
  };

  onTableGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const { table } = this.state;
    const { rows } = table;
    let users = [];
    for (let i = fromRow; i <= toRow; i += 1) {
      users = [...users, { ...rows[i], ...updated }];
    }
    this.handleUserUpdate(users);
  };

  renderActions() {
    return (
      <div className="flex mr-3">
        {/* <button
          id="btnAddService"
          type="button"
          className="btn-sm btn-gold ml-3"
          onClick={event => this.toggleModal(event)}
        >
          <FaPlus>Add</FaPlus>
        </button> */}
        <button
          id="btnRefreshService"
          type="button"
          className="btn-sm btn-gold ml-3"
          onClick={event => this.handleRefreshButtonClick(event)}
        >
          <FaSync>Refresh</FaSync>
        </button>
      </div>
    );
  }

  // Content
  renderContent = (table, isLoading) => {
    if (isLoading) {
      return (
        <React.Fragment>
          <div className="container">
            <div className="row">
              <div className="mt-5 ml-auto mr-auto">
                <RotateLoader
                  sizeUnit="px"
                  size={44}
                  color="#85714d"
                  loading={this.state.loading}
                />
              </div>
            </div>
            <div className="row">
              <h3 className="mt-5 ml-auto mr-auto">Loading...</h3>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <StickyContainer>
        <Sticky>
          {({ style, isSticky }) => (
            <div
              style={
                isSticky
                  ? {
                      ...style,
                      backgroundColor: "#fff",
                      zIndex: 8000,
                      borderBottom: "solid 0.1rem #85714d"
                    }
                  : { ...style, backgroundColor: "#fff", zIndex: 8000 }
              }
            >
              <div className="container my-1">
                <div className="row mx-0">
                  <h2 className="my-auto mr-auto">Users</h2>
                  {this.renderActions()}
                </div>
              </div>
            </div>
          )}
        </Sticky>
        <div className="container">
          <div className="col-12 px-0 mb-3">
            <ReactDataGrid
              enableCellSelect
              key={table.key}
              columns={table.columns}
              rowsCount={table.rows.length}
              rowGetter={this.getTableRow}
              onGridRowsUpdated={this.onTableGridRowsUpdated}
              rowHeight={44}
              minHeight={88}
            />
          </div>
          <div id="service_group_table_bottom" />
        </div>
      </StickyContainer>
    );
  };

  render() {
    const { isLoading, table, showModal } = this.state;
    const Modals = this.renderCreationModals(showModal);
    const Content = this.renderContent(table, isLoading);
    return (
      <React.Fragment>
        <div className="container mt-1 mb-3">
          <div className="row mx-0">
            <h1 className="mr-auto">Manage Users</h1>
          </div>
        </div>
        {Content}
        {Modals}
      </React.Fragment>
    );
  }
}

export default withRouter(Users);
