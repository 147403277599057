import React from "react";
import { withRouter } from "react-router-dom";
import Image404 from "../../../shared/img/404/404.png";

const NotFound404 = withRouter(({ history }) => (
  <div className="not-found">
    <div className="not-found__content">
      <img className="not-found__image" src={Image404} alt="404" />
      <h3 className="not-found__info">Page not found!</h3>
      <button type="button" className="btn btn-primary mt-3" onClick={() => history.goBack()}>
        Go Back
      </button>
    </div>
  </div>
));

export default NotFound404;
