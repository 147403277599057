/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { StickyContainer, Sticky } from "react-sticky";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  InputGroup,
  Row,
  Col,
  Tabs,
  Tab,
  Button
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FaPlus } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
import { RotateLoader } from "react-spinners";
import NotificationSystem from "rc-notification";
import { FullWideNotification } from "../../shared/components/Notification";

import { getAllServices } from "../../api/servicesApi";
import { getAllServiceGroups } from "../../api/serviceGroupsApi";
import { postHandlingRequestNoFix } from "../../api/handlingRequestApi";

import { FLIGHTS_ADD_NEW } from "../../actions/actionTypes";
import HandlingRequestTypes from "../../enums/handlingRequestTypes";
import Airports from "../../data/airports_openflights";

import HandlingRequestForm from "./HandlingRequestForm";
import Checkbox from "../Checkbox";

const uuidv1 = require("uuid/v1");

let notificationSystem = null;

class HandlingRequestInternalV2 extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = axios.CancelToken.source();
    this.state = {
      isLoading: true,
      didLoad: false,
      isCreating: false,
      addAnother: false,
      isValid: false,
      key: 0,
      serviceGroups: [],
      services: [],
      flight: {},
      documents: {
        generalDeclaration: [],
        noiseCertificate: []
      }
    };

    this.onDropAccepted = this.onDropAccepted.bind(this);
  }

  async componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationSystem = n));

    try {
      this.cancelTokenSource = axios.CancelToken.source();
      const serviceGroups = await getAllServiceGroups(
        this.cancelTokenSource.token
      );
      const services = await getAllServices(this.cancelTokenSource.token);
      this.setState({
        isLoading: false,
        didLoad: true,
        serviceGroups,
        services
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while loading the AddFlight form!");
        console.log(err);
        this.setState({ isLoading: false, didLoad: false });
      }
    }
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel("FlightsAdd will unmount");
  }

  showNotification = (message, color, duration) => {
    const notification = (
      <FullWideNotification color={color} message={message} />
    );
    const { previousNotice } = this.state;
    notificationSystem.removeNotice(previousNotice);
    const key = uuidv1();
    notificationSystem.notice({
      key,
      content: notification,
      duration: duration || 10,
      closable: true,
      style: { bottom: 0, left: 0 },
      className: "full handling-request-wizard__notification"
    });
    this.setState({ previousNotice: key });
  };

  onDropAccepted(droppedFiles, key) {
    // if (droppedFiles && droppedFiles.length > 1) {
    //   const message = (
    //     <div>
    //       <p>Only one file is allowed.</p>
    //       <p>Please combine your files into a single pdf-file.</p>
    //     </div>
    //   );
    //   this.showNotification(message, "danger");
    // } else {
    const { documents } = this.state;
    documents[key] = droppedFiles;
    this.setState({ documents });
    // }
  }

  onDropRejected = rejectedFiles => {
    if (rejectedFiles && Array.isArray(rejectedFiles)) {
      let message;
      const color = "danger";
      if (rejectedFiles.length > 1) {
        message = (
          <div>
            <p>Some files were rejected:</p>
            <ul>
              {_.map(rejectedFiles, rf => {
                return <li>{rf.name}</li>;
              })}
            </ul>
            <p>Please make sure individual file sizes do not exceed 3Mb.</p>
          </div>
        );
      } else {
        message = (
          <div>
            <p>File {rejectedFiles[0].name} was rejected.</p>
            <p>Please make sure file size does not exceed 3Mb.</p>
          </div>
        );
      }
      this.showNotification(message, color);
    }
  };

  removeFile = (fileName, key, e) => {
    e.preventDefault();
    const { documents } = this.state;
    documents[key] = _.filter(documents[key], f => f.name !== fileName);
    this.setState({ documents });
  };

  renderFilePreview = (file, key) => {
    const { type } = file || "";
    let filePreview;
    switch (type) {
      case "application/pdf":
        filePreview = (
          <div className="dropzone__pdf" key={file.name}>
            <span className="dropzone__pdf-title">{file.name}</span>
            <p className="dropzone__pdf-name">{file.name}</p>
            <button
              className="dropzone__pdf-delete"
              type="button"
              onClick={e => this.removeFile(file.name, key, e)}
            >
              Remove
            </button>
          </div>
        );
        break;
      case "image/png":
      case "image/jpeg":
        filePreview = (
          <div
            className="dropzone__img"
            key={file.name}
            style={{ backgroundImage: `url(${file.preview})` }}
          >
            <p className="dropzone__img-name">{file.name}</p>
            <button
              className="dropzone__img-delete"
              type="button"
              onClick={e => this.removeFile(file.name, key, e)}
            >
              Remove
            </button>
          </div>
        );
        break;
      default:
        filePreview = null;
        break;
    }
    return filePreview;
  };

  removeGenDec = fileName => {
    const { editedFlight } = this.state;
    editedFlight.documents.generalDeclaration = _.filter(
      editedFlight.documents.generalDeclaration,
      f => f.fileName !== fileName
    );
    this.setState({ editedFlight });
  };

  removeNoiseCertificate = fileName => {
    const { editedFlight } = this.state;
    editedFlight.documents.noiseCertificate = _.filter(
      editedFlight.documents.noiseCertificate,
      f => f.fileName !== fileName
    );
    this.setState({ editedFlight });
  };

  cancel() {
    this.props.history.replace("/flights");
  }

  addFlight = async (event, reset) => {
    if (event) event.preventDefault();
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      this.setState({ isCreating: true });
      const { flight, addAnother, key, documents } = this.state;
      const newlyCreatedFlight = await postHandlingRequestNoFix(
        flight,
        documents,
        this.cancelTokenSource.token
      );
      // await this.props.dispatch({
      //   type: FLIGHTS_ADD_NEW,
      //   payload: newlyCreatedFlight
      // });
      // console.log("newlyCreatedFlight", newlyCreatedFlight);
      if (addAnother) {
        this.setState({
          isCreating: false,
          isValid: false,
          key: key + 1,
          flight: {},
          documents: {
            generalDeclaration: [],
            noiseCertificate: []
          }
        });
        const color = "success";
        const message = (
          <div>
            <p>Handling request created successfully.</p>
          </div>
        );
        this.showNotification(message, color, 3);
      } else {
        this.setState({
          isCreating: false
        });
        this.props.history.replace("/flights");
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while creating a new flight!");
        console.log(err);
      }
      this.setState({ isCreating: false });
    }
  };

  handleHandlingRequestChange = handlingRequest => {
    const isValid = this.validateHandlingRequest(handlingRequest);
    this.setState({ flight: handlingRequest, isValid });
  };

  validateHandlingRequest = handlingRequest => {
    return (
      handlingRequest.registration_number &&
      ((handlingRequest.airport_of_origin && handlingRequest.eta) ||
        (handlingRequest.airport_of_destination && handlingRequest.etd))
    );
  };

  handleAddAnotherChange = e => {
    const isChecked = e.target.checked;
    this.setState({ addAnother: isChecked });
  };

  renderDocumentsEditView = (flight, documents) => {
    const { generalDeclaration, noiseCertificate } = documents;
    return (
      <Row className="mx-0">
        <div className="col-12 px-0">
          <h3>General Declaration</h3>
          <hr />
        </div>
        <FormGroup className="form-group handling-request-wizard__form-group col-12 px-0">
          <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
            <Dropzone
              key="dz-gd"
              className="dropzone__input"
              accept="application/pdf"
              name="generalDeclaration"
              maxSize={3000000}
              onDropAccepted={droppedFiles =>
                this.onDropAccepted(droppedFiles, "generalDeclaration")
              }
              onDropRejected={this.onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone__input">
                  {(!generalDeclaration || generalDeclaration.length === 0) && (
                    <div className="dropzone__drop-here">
                      <span className="lnr lnr-upload" />
                      <span className="message">
                        {/* <br />
                        Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
                        <br />
                      </span>
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            {generalDeclaration && (
              <div className="dropzone__imgs-wrapper">
                {_.map(generalDeclaration, f =>
                  this.renderFilePreview(f, "generalDeclaration")
                )}
              </div>
            )}
          </div>
        </FormGroup>
        <div className="col-12 px-0">
          <h3>Noise Certificate</h3>
          <hr />
        </div>
        <FormGroup className="form-group handling-request-wizard__form-group col-12 px-0">
          <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
            <Dropzone
              key="dz-nc"
              className="dropzone__input"
              accept="application/pdf"
              name="noiseCertificate"
              maxSize={3000000}
              onDropAccepted={droppedFiles =>
                this.onDropAccepted(droppedFiles, "noiseCertificate")
              }
              onDropRejected={this.onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone__input">
                  {(!noiseCertificate || noiseCertificate.length === 0) && (
                    <div className="dropzone__drop-here">
                      <span className="lnr lnr-upload" />
                      <span className="message">
                        {/* <br />
                Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
                        <br />
                      </span>
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            {noiseCertificate && (
              <div className="dropzone__imgs-wrapper">
                {_.map(noiseCertificate, f =>
                  this.renderFilePreview(f, "noiseCertificate")
                )}
              </div>
            )}
          </div>
        </FormGroup>
      </Row>
    );
  };

  renderDocuments() {
    const { flight } = this.state;
    const { documents } = this.state;
    return (
      <React.Fragment>
        <h2>Documents</h2>
        <div className="mt-3">
          {this.renderDocumentsEditView(flight, documents)}
        </div>
      </React.Fragment>
    );
  }

  renderActions() {
    const { isLoading, isCreating, isValid, addAnother, key } = this.state;
    return (
      <React.Fragment>
        {isCreating ? (
          <Button className="btn-lg btn-gold ml-auto" disabled>
            <span className="spinner-border spinner-border-sm mr-1" />
            Creating...
          </Button>
        ) : (
          <div className="flex">
            <Checkbox
              id="add_another"
              group="add_another"
              label="Add another"
              checked={addAnother || false}
              onChange={this.handleAddAnotherChange}
              containerClassNames="ml-auto mr-3 my-auto"
            />
            {/* <button
              className="btn-sm btn-gold"
              onClick={this.addFlight}
              disabled={!isValid}
            >
              <FaPlus>Add</FaPlus>
            </button> */}
            <Button
              className="btn-lg btn-gold mr-3"
              onClick={this.addFlight}
              disabled={!isValid}
            >
              Add
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, isCreating, isValid, addAnother, key } = this.state;
    if (isLoading) {
      return (
        <div className="container">
          <div className="row">
            <div className="mt-5 ml-auto mr-auto">
              <RotateLoader
                sizeUnit="px"
                size={44}
                color="#85714d"
                loading={this.state.loading}
              />
            </div>
          </div>
          <div className="row">
            <h3 className="mt-5 ml-auto mr-auto">Loading...</h3>
          </div>
        </div>
      );
    }
    return (
      <StickyContainer>
        <Sticky>
          {({ style, isSticky }) => (
            <div
              style={
                isSticky
                  ? {
                      ...style,
                      backgroundColor: "#fff",
                      zIndex: 9999,
                      borderBottom: "solid 0.1rem #85714d"
                    }
                  : { ...style, backgroundColor: "#fff", zIndex: 9999 }
              }
            >
              <div className="container my-1">
                <div className="row mx-0">
                  <h1 className="mr-auto">Add Flight</h1>
                  {this.renderActions()}
                </div>
              </div>
            </div>
          )}
        </Sticky>
        <div className="container mb-3">
          {this.renderDocuments()}
          <h2>Handling Request</h2>
          <HandlingRequestForm
            key={key}
            airports={Airports}
            serviceGroups={this.state.serviceGroups}
            services={this.state.services}
            onChange={this.handleHandlingRequestChange}
            handlingRequestType={HandlingRequestTypes.ADD_INTERNAL}
          />
        </div>
      </StickyContainer>
    );
  }
}

export default connect(null)(withRouter(HandlingRequestInternalV2));
