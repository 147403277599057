/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  id,
  group,
  label,
  checked = false,
  onChange,
  disabled,
  containerClassNames,
  inputClassNames,
  labelClassNames
}) => (
  <div className={containerClassNames || "form-check"}>
    <input
      className={inputClassNames || "form-check-input"}
      type="checkbox"
      id={id}
      group={group}
      value=""
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <label className={labelClassNames || "form-check-label"} htmlFor={id}>
      {label}
    </label>
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  containerClassNames: PropTypes.string,
  inputClassNames: PropTypes.string,
  labelClassNames: PropTypes.string
};

export default Checkbox;
