/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
import moment from "moment";

import { Button, ButtonGroup, ButtonToolbar, Label, Badge } from "reactstrap";
import Calendar from "react-calendar";
import MaterialTable from "material-table";
import {
  FaPlaneArrival,
  FaPlaneDeparture,
  FaSync,
  FaEuroSign
} from "react-icons/fa";
import {
  FLIGHTS_UPDATE_CURRENT,
  FLIGHTS_UPDATE_DATE,
  FLIGHTS_UPDATE_ALL
} from "../../actions/actionTypes";
import { getAllFlights } from "../../api/flightsApi";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";
import FlightStatus from "../../enums/flightStatus";
import Airports from "../../data/airports_openflights";

class FlightsDashboard extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = null;
    this.state = {
      isLoading: true,
      didLoad: false,
      visibleFlights: [],
      flightType: 0,
      schengen: 0
    };
  }

  async componentDidMount() {
    try {
      this.cancelTokenSource = axios.CancelToken.source();
      const flights = await getAllFlights(this.cancelTokenSource.token);
      this.props.dispatch({ type: FLIGHTS_UPDATE_ALL, payload: flights });
      const { minDate, maxDate } = this.props.flights;
      if (minDate && maxDate) {
        this.updateVisibleflights({ minDate, maxDate });
      }
      this.setState({ isLoading: false, didLoad: true });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(
          "Something went wrong while loading the flights dashboard!"
        );
        console.log(err);
        this.setState({ isLoading: false, didLoad: false });
      }
    }
  }

  componentWillUnmount() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Services will unmount.");
    }
  }

  refreshDashboard = async () => {
    this.setState({ isLoading: true, didLoad: false });
    this.cancelTokenSource = axios.CancelToken.source();
    const flights = await getAllFlights(this.cancelTokenSource.token);
    this.props.dispatch({ type: FLIGHTS_UPDATE_ALL, payload: flights });
    const { minDate, maxDate } = this.props.flights;
    this.updateVisibleflights({ minDate, maxDate });
    this.setState({ isLoading: false, didLoad: true });
  };

  filterFlightType = flightType => {
    this.setState({ flightType }, () => {
      const { minDate, maxDate } = this.props.flights;
      this.updateVisibleflights({ minDate, maxDate });
    });
  };

  filterSchengen = schengen => {
    this.setState({ schengen }, () => {
      const { minDate, maxDate } = this.props.flights;
      this.updateVisibleflights({ minDate, maxDate });
    });
  };

  dateToUnixTimestamp = date => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return moment.utc([year, month, day]).unix();
  };

  updateDateRange = dates => {
    let minDate;
    let maxDate;
    if (dates && dates.length === 2) {
      minDate = dates[0];
      maxDate = dates[1];
    } else {
      minDate = dates[0];
      maxDate = dates[0];
    }
    minDate.setHours(0, 0, 0, 0);
    maxDate.setHours(0, 0, 0, 0);
    this.updateVisibleflights({ minDate, maxDate });
    this.props.dispatch({
      type: FLIGHTS_UPDATE_DATE,
      payload: { minDate, maxDate }
    });
  };

  updateVisibleflights = dates => {
    const minDate = new Date(dates.minDate.getTime());
    const maxDate = new Date(dates.maxDate.getTime());
    const startDate = this.dateToUnixTimestamp(minDate);
    maxDate.setDate(maxDate.getDate() + 1);
    const endDate = this.dateToUnixTimestamp(maxDate);
    const detailedFlights = this.props.flights.all;
    if (detailedFlights) {
      const incomingFlights = _.map(detailedFlights, detailedFlight => {
        if (detailedFlight.handling_request.airport_of_origin) {
          const airportOfOriginIcao = detailedFlight.handling_request.airport_of_origin.toUpperCase();
          const airportOfOrigin = _.find(Airports, airport => {
            return airport.icao === airportOfOriginIcao;
          });
          // console.log(airportOfOrigin);
          const schengen = airportOfOrigin ? airportOfOrigin.schengen : false;
          return {
            id: detailedFlight.id,
            registration_number:
              detailedFlight.handling_request.registration_number,
            status: (detailedFlight.status || "").toLowerCase(),
            from: airportOfOriginIcao,
            to: "EBOS",
            et: detailedFlight.handling_request.eta,
            incoming: true,
            passenger_count: detailedFlight.handling_request.passenger_count,
            mtow: detailedFlight.handling_request.mtow,
            mtowUnit: detailedFlight.handling_request.mtowUnit,
            schengen
          };
        }
      });
      const outgoingFlights = _.map(detailedFlights, detailedFlight => {
        if (detailedFlight.handling_request.airport_of_destination) {
          const airportOfDestinationIcao = detailedFlight.handling_request.airport_of_destination.toUpperCase();
          const airportOfDestination = _.find(Airports, airport => {
            return airport.icao === airportOfDestinationIcao;
          });
          // console.log(airportOfDestination);
          const schengen = airportOfDestination
            ? airportOfDestination.schengen
            : false;
          return {
            id: detailedFlight.id,
            registration_number:
              detailedFlight.handling_request.registration_number,
            status: (detailedFlight.status || "").toLowerCase(),
            from: "EBOS",
            to: airportOfDestinationIcao,
            et: detailedFlight.handling_request.etd,
            incoming: false,
            passenger_count:
              detailedFlight.handling_request.passenger_count_departure,
            mtow: detailedFlight.handling_request.mtow,
            mtowUnit: detailedFlight.handling_request.mtowUnit,
            schengen
          };
        }
      });
      let flights = [];
      if (incomingFlights) {
        flights = [...flights, ...incomingFlights];
      }
      if (outgoingFlights) {
        flights = [...flights, ...outgoingFlights];
      }
      let visibleFlights = flights.filter(
        flight =>
          flight !== undefined &&
          flight.et !== undefined &&
          this.isBetweenDates(startDate, endDate, flight.et)
      );
      const { flightType, schengen } = this.state;
      switch (flightType) {
        case 1:
          visibleFlights = visibleFlights.filter(flight => flight.incoming);
          break;
        case 2:
          visibleFlights = visibleFlights.filter(flight => !flight.incoming);
          break;
        default:
          break;
      }
      switch (schengen) {
        case 1:
          visibleFlights = visibleFlights.filter(flight => flight.schengen);
          break;
        case 2:
          visibleFlights = visibleFlights.filter(flight => !flight.schengen);
          break;
        default:
          break;
      }
      this.setState({ visibleFlights });
    }
  };

  isBetweenDates = (startDate, endDate, date) => {
    const flag = date < endDate && date >= startDate;
    return flag;
  };

  showFlightDetails = (event, rowData) => {
    event.preventDefault();
    const currentFlight = this.state.visibleFlights.find(
      flight => flight.id === rowData.id
    );
    this.props.dispatch({
      type: FLIGHTS_UPDATE_CURRENT,
      payload: currentFlight
    });
    this.props.history.push(`/flights/${rowData.id}`);
  };

  getTitleForDates = (minDate, maxDate) => {
    const minDateString = minDate.toDateString();
    const maxDateString = maxDate.toDateString();
    if (minDateString === maxDateString) {
      return moment(minDate).format("dddd Do MMMM YYYY");
    }
    return `${moment(minDate).format("DD/MM/YYYY")} - ${moment(maxDate).format(
      "DD/MM/YYYY"
    )}`;
  };

  renderBadges = (rowData, type) => {
    const flightTypeIcon = rowData.incoming ? (
      <FaPlaneArrival>0</FaPlaneArrival>
    ) : (
      <FaPlaneDeparture>1</FaPlaneDeparture>
    );
    const schengenIcon = rowData.schengen ? <FaEuroSign /> : <FaEuroSign visibility="hidden" />;
    switch (type) {
      case 1:
        return <span style={{ fontSize: "1rem" }}>{flightTypeIcon}</span>;
      case 2:
        return <span style={{ fontSize: "1rem" }}>{schengenIcon}</span>;
      case 3:
        return <Badge className="ml-1">{rowData.passenger_count}</Badge>;
      default:
        return (
          <div>
            <span className="mr-1" style={{ fontSize: "1rem" }}>{flightTypeIcon}</span>
            <span className="mr-1" style={{ fontSize: "1rem" }}>{schengenIcon}</span>
            <Badge className="ml-1">{rowData.passenger_count}</Badge>
          </div>
        );
    }
  };

  convertMtowToTonnes = (mtow, mtowUnit) => {
    // console.log(mtowUnit);
    // console.log(mtow);
    let mtowTonne = 0;
    switch (mtowUnit) {
      case "kg":
        mtowTonne = mtow * 0.001;
        break;
      case "lb":
        mtowTonne = mtow * 0.000453592;
        break;
      case "t":
        mtowTonne = mtow;
        break;
      default:
        mtowTonne = mtow * 0.001;
        break;
    }
    // console.log("t");
    return mtowTonne;
  };

  renderFlightsTable() {
    const { visibleFlights } = this.state;
    const { minDate, maxDate } = this.props.flights;
    const tableOptions = {
      // pageSize: 10,
      // pageSizeOptions: [10, 15, 20, 25],
      paging: false,
      // maxBodyHeight: "calc(70vh - 105px)"
      rowStyle: rowData => {
        let backgroundColor = "white";
        if (
          (rowData.incoming && rowData.status === FlightStatus.ARRIVED) ||
          (!rowData.incoming && rowData.status === FlightStatus.DEPARTED) ||
          rowData.status === FlightStatus.SETTLED
        ) {
          backgroundColor = "#e1e1e1";
        } else if (rowData.status === FlightStatus.CANCELLED) {
          backgroundColor = "#eccfca";
        } else if (
          (rowData.status === FlightStatus.EXPECTED ||
            (!rowData.incoming && rowData.status === FlightStatus.ARRIVED)) &&
          this.convertMtowToTonnes(rowData.mtow, rowData.mtowUnit) > 2.0
        ) {
          backgroundColor = "#caeccf";
        }
        return { backgroundColor };
      }
    };
    const cellStyle = { borderColor: "#000" };
    const headerStyle = {
      color: "#85714d",
      borderColor: "#85714d",
      fontWeight: "bold"
    };
    return (
      <div className="flights-table border-gold-1 mb-3">
        <MaterialTable
          columns={[
            // {
            //   title: "",
            //   sorting: false,
            //   cellStyle,
            //   headerStyle,
            //   render: rowData => {
            //     return this.renderBadges(rowData, 1);
            //   }
            // },
            // {
            //   title: "",
            //   sorting: false,
            //   cellStyle,
            //   headerStyle,
            //   render: rowData => {
            //     return this.renderBadges(rowData, 2);
            //   }
            // },
            // {
            //   title: "",
            //   sorting: false,
            //   cellStyle,
            //   headerStyle,
            //   render: rowData => {
            //     return this.renderBadges(rowData, 3);
            //   }
            // },
            {
              title: "",
              sorting: false,
              cellStyle,
              headerStyle,
              render: rowData => {
                return this.renderBadges(rowData);
              }
            },
            { title: "ID", cellStyle, headerStyle, field: "id" },
            { title: "From", cellStyle, headerStyle, field: "from" },
            { title: "To  ", cellStyle, headerStyle, field: "to" },
            {
              title: "UTC",
              field: "et",
              type: "datetime",
              defaultSort: "asc",
              cellStyle: { ...cellStyle, fontWeight: "bold" },
              headerStyle,
              render: rowData => {
                const unixTimeUTC = moment.unix(rowData.et).utc();
                return `${unixTimeUTC.format("DD/MM/YYYY HH:mm")}`;
              }
            },
            {
              title: "Local Time",
              field: "et",
              type: "datetime",
              defaultSort: "asc",
              cellStyle,
              headerStyle,
              render: rowData => {
                const unixTimeLocal = moment.unix(rowData.et).local();
                return `${unixTimeLocal.format("DD/MM/YYYY HH:mm")}`;
              }
            },
            {
              cellStyle,
              headerStyle,
              title: "Registration Number",
              field: "registration_number"
            },
            {
              cellStyle,
              headerStyle,
              title: "Status",
              field: "status",
              render: rowData => {
                return capitalizeFirstLetter(rowData.status);
              }
            }
          ]}
          options={tableOptions}
          data={visibleFlights}
          title={this.getTitleForDates(minDate, maxDate)}
          onRowClick={this.showFlightDetails}
        />
      </div>
    );
  }

  renderActions() {
    // const { user, flightId } = this.state;
    return (
      <div className="flex mr-3">
        <button
          id="btnRefresh"
          className="btn-sm btn-gold ml-3"
          onClick={() => this.refreshDashboard()}
          type="button"
        >
          <FaSync>Refresh</FaSync>
        </button>
      </div>
    );
  }

  render() {
    const { minDate, maxDate } = this.props.flights;
    const { flightType, schengen } = this.state;
    // const minDate = moment.unix(minDateUnix).toDate();
    // const maxDate = moment.unix(maxDateUnix).toDate();
    // minDate.setHours(0, 0, 0, 0);
    // maxDate.setHours(0, 0, 0, 0);
    return (
      <div className="container">
        <div className="row mb-3">
          <h1 className="ml-3 mr-auto">Flights Dashboard</h1>
          {this.renderActions()}
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-auto">
            <Calendar
              className="border-gold-1"
              value={[minDate, maxDate]}
              selectRange
              onChange={this.updateDateRange}
            />
          </div>
          <div className="col-auto mt-3 mt-md-0">
            <div className="row flex-column">
              <div className="col-12">
                <h4>Flight Type</h4>
                <ButtonToolbar className="segmented-control">
                  <ButtonGroup>
                    <Button
                      outline
                      className={`${flightType === 0 ? "selected" : ""}`}
                      onClick={() => this.filterFlightType(0)}
                    >
                      All
                    </Button>
                    <Button
                      outline
                      className={`${flightType === 1 ? "selected" : ""}`}
                      onClick={() => this.filterFlightType(1)}
                    >
                      Arrivals
                    </Button>
                    <Button
                      outline
                      className={`${flightType === 2 ? "selected" : ""}`}
                      onClick={() => this.filterFlightType(2)}
                    >
                      Departures
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            <div className="row flex-column my-3">
              <div className="col-12">
                <h4>Schengen</h4>
                <ButtonToolbar className="segmented-control">
                  <ButtonGroup>
                    <Button
                      outline
                      className={`${schengen === 0 ? "selected" : ""}`}
                      onClick={() => this.filterSchengen(0)}
                    >
                      All
                    </Button>
                    <Button
                      outline
                      className={`${schengen === 1 ? "selected" : ""}`}
                      onClick={() => this.filterSchengen(1)}
                    >
                      Intra
                    </Button>
                    <Button
                      outline
                      className={`${schengen === 2 ? "selected" : ""}`}
                      onClick={() => this.filterSchengen(2)}
                    >
                      Extra
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{this.renderFlightsTable()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { flights: state.flights };
}

export default connect(mapStateToProps)(withRouter(FlightsDashboard));
