// reducers/flightsReducer.js

import {
  FLIGHTS_UPDATE_CURRENT,
  FLIGHTS_EDIT_CURRENT,
  FLIGHTS_UPDATE_ALL,
  FLIGHTS_UPDATE_DATE,
  FLIGHTS_DELETE_CURRENT,
  FLIGHTS_ADD_NEW,
  LOG_OUT
} from "../actions/actionTypes";
import initialState from "./initialState";

function updateObjectInArray(array, action) {
  return array.map(item => {
    if (item.id !== action.payload.id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.payload
    };
  });
}

function deleteObjectInArray(array, id) {
  return array.filter(item => item.id !== id);
}

function insertObjectInArray(array, newItem) {
  return [...array, newItem];
}

export default function(state = initialState.flights, action) {
  let newState;
  switch (action.type) {
    case FLIGHTS_UPDATE_ALL:
      newState = {
        ...state,
        all: action.payload
      };
      return newState;
    case FLIGHTS_UPDATE_CURRENT:
      newState = {
        ...state,
        current: action.payload
      };
      return newState;
    case FLIGHTS_UPDATE_DATE:
      newState = {
        ...state,
        minDate: action.payload.minDate,
        maxDate: action.payload.maxDate
      };
      return newState;
    case FLIGHTS_EDIT_CURRENT:
      newState = {
        minDate: state.minDate,
        maxDate: state.maxDate,
        current: action.payload,
        all: updateObjectInArray(state.all, action)
      };
      return newState;
    case FLIGHTS_DELETE_CURRENT:
      newState = {
        minDate: state.minDate,
        maxDate: state.maxDate,
        current: null,
        all: deleteObjectInArray(state.all, state.current.id)
      };
      return newState;
    case FLIGHTS_ADD_NEW:
      newState = {
        ...state,
        all: insertObjectInArray(state.all, action.payload)
      };
      return newState;
    case LOG_OUT:
      return initialState.flights;
    default:
      return state;
  }
}
