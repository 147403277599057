export const LOG_IN = 'log_in';
export const LOG_OUT = 'log_out';
export const FLIGHTS_UPDATE_ALL = 'flights_update_all';
export const FLIGHTS_UPDATE_CURRENT = 'flights_update_current';
export const FLIGHTS_UPDATE_DATE = 'flights_update_date';
export const FLIGHTS_EDIT_CURRENT = 'flights_edit_current';
export const FLIGHTS_DELETE_CURRENT = 'flights_delete_current';
export const FLIGHTS_ADD_NEW = 'flights_add_new';
export const USERS_UPDATE_ME = 'users_update_me';
export const USERS_ADD_NEW = 'users_add_me';
export const SERVICES_UPDATE_ALL = 'services_update_all';
export const SERVICES_ADD = 'serviceps_add';
export const SERVICE_GROUPS_UPDATE_ALL = 'service_groups_update_all';
export const SERVICE_GROUPS_ADD = 'service_groups_add';
