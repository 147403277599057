// api/usersApi.js

import axios from "axios";
import _ from "lodash";
import userSchema from "../schemas/userSchema";

export const getMyUser = async cancelToken => {
  const res = await axios.get("/api/users/me", { cancelToken });
  if (res) {
    return userSchema.parse(res.data);
  }
  return null;
};

export const getAllUsers = async cancelToken => {
  const res = await axios.get("/api/users/all", { cancelToken });
  if (res) {
    return _.map(res.data, data => userSchema.parse(data));
  }
  return null;
};

export const postUsers = async (user, cancelToken) => {
  const res = await axios.post("/api/users", user, {
    cancelToken
  });
  if (res && res.status === 200) {
    return userSchema.parse(res.data);
  }
  return null;
};

export const putUsers = async (user, cancelToken) => {
  const res = await axios.put(`/api/users/${user.id}`, user, {
    cancelToken
  });
  if (res && res.status === 200) {
    return userSchema.parse(res.data);
  }
  return null;
};
