/* eslint-disable func-names */
import axios from "axios";
// import handlingRequestSchema from "../schemas/handlingRequestSchema";

const fixHandlingRequestPropNames = handlingRequest => {
  return {
    captain: handlingRequest.captain,
    crew_email: handlingRequest.crewEmail,
    crew_phone: handlingRequest.crewPhone,
    passenger_count: handlingRequest.passengerCount,
    passenger_count_departure: handlingRequest.passengerCountDeparture,
    pob_count: handlingRequest.pobCount,
    pob_count_departure: handlingRequest.pobCountDeparture,
    prm_count: handlingRequest.prmCount,
    registration_number: handlingRequest.registrationNumber,
    aircraft_type: handlingRequest.aircraftType,
    cargo: handlingRequest.cargo,
    mtow: handlingRequest.mtow,
    mtowUnit: handlingRequest.mtowUnit,
    services: handlingRequest.services,
    other: handlingRequest.other,
    airport_of_origin: handlingRequest.airportOfOrigin,
    eta: handlingRequest.eta,
    airport_of_destination: handlingRequest.airportOfDestination,
    etd: handlingRequest.etd,
    billing: handlingRequest.billing
  };
};

export const postHandlingRequest = async function(handlingRequest, documents, cancelToken) {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      cancelToken
    };

    const handlingRequestCorrected = fixHandlingRequestPropNames(handlingRequest);
    const formData = new FormData();
    // JSON content
    formData.append("handlingRequest", JSON.stringify(handlingRequestCorrected));
    // Documents
    if (documents) {
      // console.log("documents");
      // console.log(documents);
      const { generalDeclaration, noiseCertificate } = documents;
      if (generalDeclaration && Array.isArray(generalDeclaration) && generalDeclaration.length > 0) {
        // console.log("generalDeclaration");
        // console.log(generalDeclaration);
        let i;
        for (i = 0; i < generalDeclaration.length; i += 1) {
          formData.append(`generalDeclaration_${i}`, generalDeclaration[i]);
        }
      }
      if (noiseCertificate && Array.isArray(noiseCertificate) && noiseCertificate.length > 0) {
        // console.log("noiseCertificate");
        // console.log(noiseCertificate);
        let i;
        for (i = 0; i < noiseCertificate.length; i += 1) {
          formData.append(`noiseCertificate_${i}`, noiseCertificate[i]);
        }
      }
    }
    // console.log("handlingRequest");
    // console.log(handlingRequest);
    // console.log("documents");
    // console.log(documents);
    // console.log("formData");
    // console.log(formData);
    return await axios.post("/api/handling-requests", formData, config);
  } catch (err) {
    console.log(err);
  }
  return null;
};


export const postHandlingRequestNoFix = async function(handlingRequest, documents, cancelToken) {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      cancelToken
    };

    const handlingRequestCorrected = handlingRequest;
    const formData = new FormData();
    // JSON content
    formData.append("handlingRequest", JSON.stringify(handlingRequestCorrected));
    // Documents
    if (documents) {
      // console.log("documents");
      // console.log(documents);
      const { generalDeclaration, noiseCertificate } = documents;
      if (generalDeclaration && Array.isArray(generalDeclaration) && generalDeclaration.length > 0) {
        // console.log("generalDeclaration");
        // console.log(generalDeclaration);
        let i;
        for (i = 0; i < generalDeclaration.length; i += 1) {
          formData.append(`generalDeclaration_${i}`, generalDeclaration[i]);
        }
      }
      if (noiseCertificate && Array.isArray(noiseCertificate) && noiseCertificate.length > 0) {
        // console.log("noiseCertificate");
        // console.log(noiseCertificate);
        let i;
        for (i = 0; i < noiseCertificate.length; i += 1) {
          formData.append(`noiseCertificate_${i}`, noiseCertificate[i]);
        }
      }
    }
    // console.log("handlingRequest");
    // console.log(handlingRequest);
    // console.log("documents");
    // console.log(documents);
    // console.log("formData");
    // console.log(formData);
    return await axios.post("/api/handling-requests/internal", formData, config);
  } catch (err) {
    console.log(err);
  }
  return null;
};
