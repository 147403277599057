/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
// import moment from "moment";
import axios from "axios";
import _ from "lodash";
import NotificationSystem from "rc-notification";
import { getAllServices } from "../../../api/servicesApi";
import { getAllServiceGroups } from "../../../api/serviceGroupsApi";
import { FullWideNotification } from "../../../shared/components/Notification";
import ServiceStatus from "../../../enums/serviceStatus";
import GroundHandling from "./HandlingRequestFormGroundHandling";
import FlightScheduling from "./HandlingRequestFormFlightScheduling";
import HandlingRequestDocuments from "./HandlingRequestFormDocuments";
import Services from "./HandlingRequestFormServices";
import HandlinRequestReview from "./HandlingRequestFormReview";

const uuidv1 = require("uuid/v1");

let notificationSystem = null;
const initialHandlingRequest = undefined;
// const initialHandlingRequest = {
//   captain: "Thomas De Wilde",
//   crewEmail: "thomas.dewilde@outlook.com",
//   crewPhone: "+320472591687",
//   passengerCount: "5",
//   pobCount: "6",
//   prmCount: "",
//   registrationNumber: "XXYYZZ",
//   aircraftType: "Zork R3",
//   cargo: "",
//   mtow: "500",
//   mtowUnit: "kg",
//   services: {},
//   other: "",
//   documents: [],
//   airportOfOrigin: "EBAW",
//   eta: {
//     day: "5",
//     month: "5",
//     year: "2019",
//     hour: "15",
//     minute: "8",
//     date: moment().utc()
//   },
//   airportOfDestination: "",
//   etd: {
//     day: "",
//     month: "",
//     year: "",
//     hour: "",
//     minute: "",
//     date: undefined
//   }
// };

class HandlingRequestForm extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    t: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.cancelTokenSource = axios.CancelToken.source();
    this.state = {
      allowToggle: false,
      isLoading: true,
      didLoad: false,
      isCreating: false,
      isValid: false,
      key: 0,
      tab: 1,
      previousNotice: "",
      serviceGroups: [],
      services: [],
      flight: {},
      handlingRequest: initialHandlingRequest || {}
    };
    this.updateHandlingRequest = this.updateHandlingRequest.bind(this);
    this.scrollToTab = this.scrollToTab.bind(this);
  }

  async componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationSystem = n));
    try {
      this.cancelTokenSource = axios.CancelToken.source();
      const serviceGroups = await getAllServiceGroups(this.cancelTokenSource.token);
      const services = await getAllServices(this.cancelTokenSource.token);
      this.setState({
        isLoading: false,
        didLoad: true,
        serviceGroups: _.filter(serviceGroups, serviceGroup => serviceGroup.status === ServiceStatus.ACTIVE),
        services: _.filter(services, service => service.status === ServiceStatus.ACTIVE)
      });
      // const prevState = this.state;
      // console.log(prevState);
      // console.log(prevState.serviceGroups);
      // console.log(prevState.services);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while loading the AddFlight form!");
        console.log(err);
        this.setState({ isLoading: false, didLoad: false });
      }
    }
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel("FlightsAdd will unmount");
    notificationSystem.destroy();
  }

  showNotification = (message, color, duration) => {
    // console.log(message);
    const notification = <FullWideNotification color={color} message={message} />;
    const { previousNotice } = this.state;
    notificationSystem.removeNotice(previousNotice);
    const key = uuidv1();
    notificationSystem.notice({
      key,
      content: notification,
      duration: duration || 10,
      closable: true,
      style: { top: 0, left: 0 },
      className: "full handling-request-wizard__notification"
    });
    this.setState({ previousNotice: key });
  };

  nexttab = () => {
    this.setState(
      prevState => ({ tab: prevState.tab + 1 }),
      () => {
        this.scrollToTab();
      }
    );
  };

  previoustab = () => {
    this.setState(
      prevState => ({ tab: prevState.tab - 1 }),
      () => {
        this.scrollToTab();
      }
    );
  };

  toggle = newTab => {
    const { allowToggle, tab } = this.state;
    if (allowToggle) {
      if (tab !== newTab) {
        this.setState(
          {
            tab: newTab
          },
          () => {
            this.scrollToTab();
          }
        );
      }
    }
  };

  scrollToTab() {
    const { tab } = this.state;
    window.scrollTo(0, 0);
    this.tabsScrollbar.scrollLeft = Number(tab - 1) * 100;
  }

  updateHandlingRequest(property, value) {
    const { handlingRequest } = this.state;
    handlingRequest[property] = value;
    this.setState({ handlingRequest });
  }

  render() {
    const { didLoad, allowToggle, tab, serviceGroups, services, handlingRequest } = this.state;

    return (
      didLoad && (
        <div className="handling-request-wizard">
          <div className="handling-request-wizard__wrapper">
            <div className="handling-request-wizard__header">
              <h1 className="title">Handling Request</h1>
              <span className="logo" />
            </div>
            <div className="tabs tabs--bordered-bottom">
              <div
                id="tabs-scrollbar"
                className="tabs__wrap"
                ref={tabsScrollbar => (this.tabsScrollbar = tabsScrollbar)}
              >
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 1, disabled: !allowToggle })}
                      onClick={() => {
                        this.toggle(1);
                      }}
                    >
                      Ground Handling
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 2, disabled: !allowToggle })}
                      onClick={() => {
                        this.toggle(2);
                      }}
                    >
                      Services &amp; Arrangements
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 3, disabled: !allowToggle })}
                      onClick={() => {
                        this.toggle(3);
                      }}
                    >
                      Flight Scheduling
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 4, disabled: !allowToggle })}
                      onClick={() => {
                        this.toggle(4);
                      }}
                    >
                      Request Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 5, disabled: !allowToggle })}
                      onClick={() => {
                        this.toggle(5);
                      }}
                    >
                      Request Overview
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <TabContent activeTab={`${tab}`}>
              <TabPane tabId="1">
                <GroundHandling
                  showNext={this.nexttab}
                  showNotification={this.showNotification}
                  updateHandlingRequest={this.updateHandlingRequest}
                />
              </TabPane>
              <TabPane tabId="2">
                <Services
                  serviceGroups={serviceGroups}
                  services={services}
                  showPrevious={this.previoustab}
                  showNext={this.nexttab}
                  showNotification={this.showNotification}
                  updateHandlingRequest={this.updateHandlingRequest}
                />
              </TabPane>
              <TabPane tabId="3">
                <FlightScheduling
                  showPrevious={this.previoustab}
                  showNext={this.nexttab}
                  showNotification={this.showNotification}
                  updateHandlingRequest={this.updateHandlingRequest}
                />
              </TabPane>
              <TabPane tabId="4">
                <HandlingRequestDocuments
                  showPrevious={this.previoustab}
                  showNext={this.nexttab}
                  showNotification={this.showNotification}
                  updateHandlingRequest={this.updateHandlingRequest}
                />
              </TabPane>
              <TabPane tabId="5">
                <HandlinRequestReview
                  handlingRequest={handlingRequest}
                  serviceGroups={serviceGroups}
                  services={services}
                  showPrevious={this.previoustab}
                  showNotification={this.showNotification}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      )
    );
  }
}

export default withTranslation("common")(HandlingRequestForm);
