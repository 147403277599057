/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import axios from "axios";
import _ from "lodash";
import moment from "moment";

// import { Button, ButtonGroup, ButtonToolbar, Label, Badge } from "reactstrap";
// import Calendar from "react-calendar";
// import MaterialTable from "material-table";
// import { FaPlaneArrival, FaPlaneDeparture, FaSync, FaEuroSign } from "react-icons/fa";
// import { FLIGHTS_UPDATE_CURRENT, FLIGHTS_UPDATE_DATE, FLIGHTS_UPDATE_ALL } from "../../actions/actionTypes";
// import { getAllFlights } from "../../api/flightsApi";
// import { capitalizeFirstLetter } from "../../helpers/stringHelper";
// import FlightStatus from "../../enums/flightStatus";
// import Airports from "../../data/airports_openflights";
// import Calendar from '../../../../shared/components/Calendar';
import { Card, CardHeader, CardBody, Col } from "reactstrap";
import Calendar from "../../shared/components/Calendar";
import Airports from "../../data/airports_openflights";

class FlightsCalendar extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = null;
    this.state = {
      events: []
    };
  }

  componentDidMount() {
    const events = this.createEvents(this.props.flights.all);
    this.setState({ events });
  }

  componentWillUnmount() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Services will unmount.");
    }
  }

  createEvents = detailedFlights => {
    // const events = [
    //   {
    //     id: 0,
    //     title: "All Day Event very long title",
    //     allDay: true,
    //     start: new Date(2020, 1, 0),
    //     end: new Date(2020, 1, 1),
    //     priority: "high"
    //   },
    //   {
    //     id: 1,
    //     title: "Escape from the Dragon",
    //     start: new Date(2020, 1, 7),
    //     end: new Date(2020, 1, 10),
    //     priority: "high"
    //   }
    // ];

    const incomingFlights = _.map(detailedFlights, detailedFlight => {
      if (detailedFlight.handling_request.airport_of_origin) {
        const airportOfOriginIcao = detailedFlight.handling_request.airport_of_origin.toUpperCase();
        const airportOfOrigin = _.find(Airports, airport => {
          return airport.icao === airportOfOriginIcao;
        });
        // console.log(airportOfOrigin);
        const schengen = airportOfOrigin ? airportOfOrigin.schengen : false;
        // const unixTimeUTC = moment.unix(rowData.et).utc();
        // return `${unixTimeUTC.format("DD/MM/YYYY HH:mm")}`;
        return {
          title: `IN - ${detailedFlight.handling_request.registration_number}`,
          start: new Date(
            moment
              .unix(detailedFlight.handling_request.eta)
              .utc()
              .format()
          ),
          end: new Date(
            moment
              .unix(detailedFlight.handling_request.eta + 1)
              .utc()
              .format()
          ),
          priority: "high",
          allDay: false,

          uid: detailedFlight.id,
          registration_number:
            detailedFlight.handling_request.registration_number,
          status: (detailedFlight.status || "").toLowerCase(),
          from: airportOfOriginIcao,
          to: "EBOS",
          et: detailedFlight.handling_request.eta,
          incoming: true,
          passenger_count: detailedFlight.handling_request.passenger_count,
          mtow: detailedFlight.handling_request.mtow,
          mtowUnit: detailedFlight.handling_request.mtowUnit,
          schengen
        };
      }
    });
    const outgoingFlights = _.map(detailedFlights, detailedFlight => {
      if (detailedFlight.handling_request.airport_of_destination) {
        const airportOfDestinationIcao = detailedFlight.handling_request.airport_of_destination.toUpperCase();
        const airportOfDestination = _.find(Airports, airport => {
          return airport.icao === airportOfDestinationIcao;
        });
        // console.log(airportOfDestination);
        const schengen = airportOfDestination
          ? airportOfDestination.schengen
          : false;
        return {
          title: `OUT - ${detailedFlight.handling_request.registration_number}`,
          start: new Date(
            moment
              .unix(detailedFlight.handling_request.etd)
              .utc()
              .format()
          ),
          end: new Date(
            moment
              .unix(detailedFlight.handling_request.etd + 1)
              .utc()
              .format()
          ),
          priority: "high",
          allDay: false,

          uid: detailedFlight.id,
          registration_number:
            detailedFlight.handling_request.registration_number,
          status: (detailedFlight.status || "").toLowerCase(),
          from: "EBOS",
          to: airportOfDestinationIcao,
          et: detailedFlight.handling_request.etd,
          incoming: false,
          passenger_count:
            detailedFlight.handling_request.passenger_count_departure,
          mtow: detailedFlight.handling_request.mtow,
          mtowUnit: detailedFlight.handling_request.mtowUnit,
          schengen
        };
      }
    });
    const events = [...incomingFlights, ...outgoingFlights];
    // events = [...events, ...events];
    // console.log("events ", events);
    return events;
  };

  render() {
    const { events } = this.state;
    return (
      <div className="container">
        <div className="row mb-3">
          <h1 className="ml-3 mr-auto">Flights Calendar</h1>
        </div>
        <Col md={12} lg={12} xl={{ size: 10, offset: 1 }}>
          <Card>
            <CardHeader hidden />
            <CardBody>
              <Calendar
                events={events}
                onSelectEvent={event => {
                  // console.log("event ", event);
                  // alert(event.title);
                  this.props.history.push(`/flights/${event.uid}`);
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { flights: state.flights };
}

export default connect(mapStateToProps)(withRouter(FlightsCalendar));
