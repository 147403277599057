/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  id,
  group,
  label,
  checked = false,
  onChange,
  disabled,
  containerClassNames,
  inputClassNames,
  labelClassNames
}) => (
  <div className={`form-check${containerClassNames ? ` ${containerClassNames}` : ""}`}>
    <label htmlFor={id} className={`custom-control overflow-checkbox${labelClassNames ? ` ${labelClassNames}` : ""}`}>
      <input
        className={`overflow-control-input${inputClassNames ? ` ${inputClassNames}` : ""}`}
        type="checkbox"
        id={id}
        group={group}
        value=""
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="overflow-control-indicator" />
      <span className="overflow-control-description">{label}</span>
    </label>
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  containerClassNames: PropTypes.string,
  inputClassNames: PropTypes.string,
  labelClassNames: PropTypes.string
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  containerClassNames: "",
  inputClassNames: "",
  labelClassNames: ""
};

export default Checkbox;
