/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

import { Form, Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import Textarea from "react-textarea-autosize";
import Flatpickr from "react-flatpickr";
import Autosuggest from "react-autosuggest";
import { FaTrashAlt } from "react-icons/fa";
import HandlingRequestTypes from "../../enums/handlingRequestTypes";
import ServiceCheckboxContainer from "./ServiceCheckboxContainer";
import UserRoles from "../../enums/roles";

class HandlingRequestForm extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = axios.CancelToken.source();
    this.state = {
      today: moment()
        .startOf("day")
        .toDate(),
      checked: false,
      airportSuggestions: [],
      handlingRequest: this.props.handlingRequest || {
        airport_of_origin: "",
        eta: "",
        airport_of_destination: "",
        etd: "",
        registration_number: "",
        aircraft_type: "",
        mtow: "",
        captain: "",
        first_officer: "",
        passenger_count: "",
        services: {},
        other: "",
        pax: [{ id: 1, name: "" }]
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.handlingRequest !== this.props.handlingRequest) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ handlingRequest: this.props.handlingRequest });
    }
  }

  handleGroundHandlingChange = event => {
    if (event) event.preventDefault();
    const field = event.target.id;
    const { handlingRequest } = this.state;
    // console.log(handlingRequest);
    handlingRequest[field] = event.target.value;
    this.setState({ handlingRequest });
    this.props.onChange(this.state.handlingRequest);
  };

  handleMtowChange = event => {
    if (event) event.preventDefault();
    const field = event.target.id;
    const { handlingRequest } = this.state;
    handlingRequest.mtow = event.target.value;
    handlingRequest.mtowUnit = "t";
    // console.log(handlingRequest);
    this.setState({ handlingRequest });
    this.props.onChange(this.state.handlingRequest);
  };

  handleBillingChangeEvent = event => {
    if (event) event.preventDefault();
    const field = event.target.id;
    // console.log(field);
    const { handlingRequest } = this.state;
    // console.log(handlingRequest);
    const billing = handlingRequest.billing || {};
    billing[field] = event.target.value;
    handlingRequest.billing = billing;
    this.setState({ handlingRequest });
    this.props.onChange(this.state.handlingRequest);
    // console.log(this.state.handlingRequest);
  };

  handleEstimatedTimeChange(name, estimated_time) {
    const { handlingRequest } = this.state;
    handlingRequest[name] = estimated_time;
    this.setState({ handlingRequest });
    this.props.onChange(this.state.handlingRequest);
  }

  handleServiceChange = (serviceGroupId, serviceIds) => {
    const { handlingRequest } = this.state;
    handlingRequest.services[serviceGroupId] = serviceIds;
    this.setState({ handlingRequest });
    this.props.onChange(this.state.handlingRequest);
  };

  onAirportOfOriginChange = (event, { newValue }) => {
    const { handlingRequest } = this.state;
    handlingRequest.airport_of_origin = newValue;
    this.setState({ handlingRequest });
    this.props.onChange(this.state.handlingRequest);
  };

  onAirportOfDestinationChange = (event, { newValue }) => {
    const { handlingRequest } = this.state;
    handlingRequest.airport_of_destination = newValue;
    this.setState({ handlingRequest });
    this.props.onChange(this.state.handlingRequest);
  };

  updateAirportSuggestions = searchString => {
    const filteredAirports = this.filterAirportSuggestions(searchString);
    this.setState({
      airportSuggestions: filteredAirports,
      demo: "success"
    });
  };

  filterAirportSuggestions = value => {
    if (!value || value === undefined) return [];
    const inputValue = value.trim().toLowerCase() || "";
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : _.filter(
          this.props.airports,
          airport =>
            airport.icao.toLowerCase().slice(0, inputLength) === inputValue ||
            airport.country.toLowerCase().slice(0, inputLength) === inputValue ||
            airport.city.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      airportSuggestions: this.filterAirportSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      airportSuggestions: []
    });
  };

  renderSuggestion = (suggestion, name) => {
    return (
      <div name={name} className="suggestion">
        {suggestion.icao}{" "}
        <small className="suggestion-additional">
          {suggestion.city}
          {", "}
          {suggestion.country}
        </small>
      </div>
    );
  };

  getSuggestionValue = suggestion => suggestion.icao;

  renderGroundHandlingHeader(minDate) {
    const { handlingRequest, airportSuggestions } = this.state;
    const { preventEditing } = this.props;
    const inputPropsFrom = {
      placeholder: "Enter the airport of origin",
      value: handlingRequest.airport_of_origin || "",
      onChange: this.onAirportOfOriginChange,
      name: "airport_of_origin",
      disabled: preventEditing
    };
    const inputPropsTo = {
      placeholder: "Enter the airport of destination",
      value: handlingRequest.airport_of_destination || "",
      onChange: this.onAirportOfDestinationChange,
      name: "airport_of_destination",
      disabled: preventEditing
    };
    const flatpickrOptions = {
      minDate,
      enableTime: true,
      dateFormat: "d/m/Y H:i",
      timeFormat: "H:i",
      time_24hr: true,
      locale: { firstDayOfWeek: 1 },
      disableMobile: true
    };
    return (
      <div>
        <h3>Ground handling</h3>
        <hr />
        <h4 className="mb-3">Arrival</h4>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6 airport-input">
            <Form.Label>Airport of Origin</Form.Label>
            <Autosuggest
              suggestions={airportSuggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={suggestion => this.renderSuggestion(suggestion, inputPropsFrom.name)}
              inputProps={inputPropsFrom}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="eta" className="col-12 col-md-6">
            <Form.Label>Estimated Time of Arrival</Form.Label>
            <div className="form-row ml-0 mr-0">
              <Flatpickr
                className="w-100 form-control"
                value={handlingRequest.eta ? this.unixTimestampToDate(handlingRequest.eta) : ""}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates !== undefined) {
                    const unix = this.dateToUnixTimestamp(selectedDates[0]);
                    this.handleEstimatedTimeChange("eta", unix);
                  }
                }}
                options={flatpickrOptions}
                placeholder="Enter the ETA"
                disabled={preventEditing}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Flight Number</Form.Label>
            <Form.Control
              type="text"
              id="flight_number_arrival"
              placeholder="Enter the arrival flight number"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.flight_number_arrival || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Passengers on Arrival</Form.Label>
            <Form.Control
              type="number"
              id="passenger_count"
              placeholder="Enter the number of passengers on arrival"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.passenger_count}
              disabled={preventEditing}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Persons on Board on Arrival</Form.Label>
            <Form.Control
              type="number"
              id="pob_count"
              placeholder="Enter the number of POB on arrival"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.pob_count || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <hr />
        <h4 className="mb-3">Departure</h4>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6 airport-input">
            <Form.Label>Airport of Destination</Form.Label>
            <Autosuggest
              suggestions={airportSuggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={suggestion => this.renderSuggestion(suggestion, inputPropsTo.name)}
              inputProps={inputPropsTo}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="eta" className="col-12 col-md-6">
            <Form.Label>Estimated Time of Departure</Form.Label>
            <div className="form-row ml-0 mr-0">
              <Flatpickr
                className="w-100 form-control"
                value={handlingRequest.etd ? this.unixTimestampToDate(handlingRequest.etd) : ""}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates !== undefined) {
                    const unix = this.dateToUnixTimestamp(selectedDates[0]);
                    this.handleEstimatedTimeChange("etd", unix);
                  }
                }}
                options={flatpickrOptions}
                placeholder="Enter the ETD"
                disabled={preventEditing}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Flight Number</Form.Label>
            <Form.Control
              type="text"
              id="flight_number_departure"
              placeholder="Enter the departure flight number"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.flight_number_departure || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Passengers on Departure</Form.Label>
            <Form.Control
              type="number"
              id="passenger_count_departure"
              placeholder="Enter the number of passengers on departure"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.passenger_count_departure}
              disabled={preventEditing}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Persons on Board on Departure</Form.Label>
            <Form.Control
              type="number"
              id="pob_count_departure"
              placeholder="Enter the number of POB on departure"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.pob_count_departure || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <hr />
      </div>
    );
  }

  dateToUnixTimestamp = date => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    // console.log(`dateToUnixTimestamp: ${year}/${month}/${day}  ${hour}:${minute}`);
    return moment.utc([year, month, day, hour, minute]).unix();
  };

  unixTimestampToDate = timestamp => {
    // console.log(timestamp);
    const utc = moment.unix(timestamp).utc();
    const year = utc.year();
    const month = utc.month();
    const day = utc.date();
    const hour = utc.hour();
    const minute = utc.minute();
    // console.log(`unixTimestampToDate: ${year}/${month}/${day}  ${hour}:${minute}`);
    return new Date(year, month, day, hour, minute, 0, 0);
  };

  renderGroundHandlingHeaderForEdit(minDate) {
    const { handlingRequest, airportSuggestions } = this.state;
    const { preventEditing } = this.props;
    const inputPropsFrom = {
      placeholder: "Enter the airport of origin",
      value: handlingRequest.airport_of_origin || "",
      onChange: this.onAirportOfOriginChange,
      name: "airport_of_origin",
      disabled: preventEditing
    };
    const inputPropsTo = {
      placeholder: "Enter the airport of destination",
      value: handlingRequest.airport_of_destination || "",
      onChange: this.onAirportOfDestinationChange,
      name: "airport_of_destination",
      disabled: preventEditing
    };
    const flatpickrOptions = {
      minDate,
      enableTime: true,
      dateFormat: "d/m/Y H:i",
      timeFormat: "H:i",
      time_24hr: true,
      locale: { firstDayOfWeek: 1 },
      disableMobile: true
    };
    return (
      <div>
        <h3>Ground handling</h3>
        <hr />
        <h5 className="mb-3">Arrival</h5>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6 airport-input">
            <Form.Label>Airport of Origin</Form.Label>
            <Autosuggest
              suggestions={airportSuggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={suggestion => this.renderSuggestion(suggestion, inputPropsFrom.name)}
              inputProps={inputPropsFrom}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Flight Number</Form.Label>
            <Form.Control
              type="text"
              id="flight_number_arrival"
              placeholder="Enter the arrival flight number"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.flight_number_arrival || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="eta" className="col-12 col-md-6">
            <Form.Label>Estimated Time of Arrival</Form.Label>
            <div className="form-row ml-0 mr-0">
              <Flatpickr
                className="w-100 form-control"
                value={handlingRequest.eta ? this.unixTimestampToDate(handlingRequest.eta) : ""}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates !== undefined) {
                    const unix = this.dateToUnixTimestamp(selectedDates[0]);
                    this.handleEstimatedTimeChange("eta", unix);
                  }
                }}
                options={flatpickrOptions}
                placeholder="Enter the ETA"
                disabled={preventEditing}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} controlId="ata" className="col-12 col-md-6">
            <Form.Label>Actual Time of Arrival</Form.Label>
            <div className="form-row ml-0 mr-0">
              <Flatpickr
                className="w-100 form-control"
                value={handlingRequest.ata ? this.unixTimestampToDate(handlingRequest.ata) : ""}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates !== undefined) {
                    const unix = this.dateToUnixTimestamp(selectedDates[0]);
                    this.handleEstimatedTimeChange("ata", unix);
                  }
                }}
                options={flatpickrOptions}
                placeholder="Enter the ATA"
                disabled={preventEditing}
              />
            </div>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Passengers on Arrival</Form.Label>
            <Form.Control
              type="number"
              id="passenger_count"
              placeholder="Enter the number of passengers on arrival"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.passenger_count}
              disabled={preventEditing}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Persons on Board on Arrival</Form.Label>
            <Form.Control
              type="number"
              id="pob_count"
              placeholder="Enter the number of POB on arrival"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.pob_count || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <hr />
        <h5 className="mb-3">Departure</h5>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6 airport-input">
            <Form.Label>Airport of Destination</Form.Label>
            <Autosuggest
              suggestions={airportSuggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={suggestion => this.renderSuggestion(suggestion, inputPropsTo.name)}
              inputProps={inputPropsTo}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Flight Number</Form.Label>
            <Form.Control
              type="text"
              id="flight_number_departure"
              placeholder="Enter the departure flight number"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.flight_number_departure || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="etd" className="col-12 col-md-6">
            <Form.Label>Estimated Time of Departure</Form.Label>
            <div className="form-row ml-0 mr-0">
              <Flatpickr
                className="w-100 form-control"
                value={handlingRequest.etd ? this.unixTimestampToDate(handlingRequest.etd) : ""}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates !== undefined) {
                    const unix = this.dateToUnixTimestamp(selectedDates[0]);
                    this.handleEstimatedTimeChange("etd", unix);
                  }
                }}
                options={flatpickrOptions}
                placeholder="Enter the ETD"
                disabled={preventEditing}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} controlId="atd" className="col-12 col-md-6">
            <Form.Label>Actual Time of Departure</Form.Label>
            <div className="form-row ml-0 mr-0">
              <Flatpickr
                className="w-100 form-control"
                value={handlingRequest.atd ? this.unixTimestampToDate(handlingRequest.atd) : ""}
                onChange={(selectedDates, dateStr, instance) => {
                  if (selectedDates !== undefined) {
                    const unix = this.dateToUnixTimestamp(selectedDates[0]);
                    this.handleEstimatedTimeChange("atd", unix);
                  }
                }}
                options={flatpickrOptions}
                placeholder="Enter the ATD"
                disabled={preventEditing}
              />
            </div>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Passengers on Departure</Form.Label>
            <Form.Control
              type="number"
              id="passenger_count_departure"
              placeholder="Enter the number of passengers on departure"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.passenger_count_departure}
              disabled={preventEditing}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Persons on Board on Departure</Form.Label>
            <Form.Control
              type="number"
              id="pob_count_departure"
              placeholder="Enter the number of POB on departure"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.pob_count_departure || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <hr />
      </div>
    );
  }

  calculateMtowInTonne = handlingRequest => {
    const { mtow, mtowUnit } = handlingRequest;
    // console.log(mtowUnit);
    // console.log(mtow);
    let mtowTonne = 0;
    switch (mtowUnit) {
      case "kg":
        mtowTonne = mtow * 0.001;
        break;
      case "lb":
        mtowTonne = mtow * 0.000453592;
        break;
      case "t":
        mtowTonne = mtow;
        break;
      default:
        mtowTonne = mtow * 0.001;
        break;
    }
    // console.log("t");
    // console.log(mtowTonne);
    return mtowTonne;
  };

  renderGroundHandling() {
    const { handlingRequest, today } = this.state;
    const { handlingRequestType, preventEditing } = this.props;
    let groundHandlingHeader = null;
    // console.log("handlingRequestType");
    // console.log(handlingRequestType);
    switch (handlingRequestType) {
      case HandlingRequestTypes.EDIT:
        groundHandlingHeader = this.renderGroundHandlingHeaderForEdit();
        break;
      case HandlingRequestTypes.ADD_INTERNAL:
        groundHandlingHeader = this.renderGroundHandlingHeader();
        break;
      case HandlingRequestTypes.ADD_EXTERNAL:
        groundHandlingHeader = this.renderGroundHandlingHeader(today);
        break;
      default:
        console.log(`Unknown handling request type :: ${handlingRequestType}`);
        break;
    }
    const mtow = this.calculateMtowInTonne(handlingRequest);
    return (
      <div className="mt-3">
        {groundHandlingHeader}
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Registration Number</Form.Label>
            <Form.Control
              type="text"
              id="registration_number"
              placeholder="Enter the aircraft's registration number"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.registration_number}
              disabled={preventEditing}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Aircraft Type</Form.Label>
            <Form.Control
              type="text"
              id="aircraft_type"
              placeholder="Enter the aircraft's type"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.aircraft_type}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Maximum Take-Off Weight</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                id="mtow"
                min="0"
                step="any"
                placeholder="Enter the aircraft's MTOW"
                onChange={this.handleMtowChange}
                value={mtow}
                disabled={preventEditing}
              />
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon2">t</InputGroup.Text>
                {/* {handlingRequest.mtowUnit && handlingRequest.mtowUnit === "lb" ? (
                  <InputGroup.Text id="basic-addon2">Lb</InputGroup.Text>
                ) : (
                  <InputGroup.Text id="basic-addon2">Kg</InputGroup.Text>
                )} */}
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Number of Persons with Reduced Mobility</Form.Label>
            <Form.Control
              type="number"
              id="prm_count"
              placeholder="Enter the number of PRM"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.prm_count || ""}
              disabled={preventEditing}
            />
          </Form.Group>
          {/* <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Name of the First Officer</Form.Label>
            <Form.Control
              type="text"
              id="first_officer"
              placeholder="Enter the first officer's name"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.first_officer}
            />
          </Form.Group> */}
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12">
            <Form.Label>Cargo</Form.Label>
            <Textarea
              key="cargo"
              name="cargo"
              id="cargo"
              minRows={3}
              maxLength={1500}
              value={handlingRequest.cargo || ""}
              onChange={this.handleGroundHandlingChange}
              className="form-control form-control-textarea"
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
      </div>
    );
  }

  renderServiceGroups() {
    const { serviceGroups } = this.props;
    const filteredServiceGroups = _.filter(serviceGroups, serviceGroup => serviceGroup.status === "active");
    if (filteredServiceGroups && filteredServiceGroups.length > 0) {
      return _.map(filteredServiceGroups, serviceGroup => {
        return (
          <div key={`sg-${serviceGroup.id}`} className="mt-3">
            <h3>{serviceGroup.name}</h3>
            <hr />
            {this.renderServices(serviceGroup)}
          </div>
        );
      });
    }
    return null;
  }

  renderServices(serviceGroup) {
    const { services, preventEditing } = this.props;
    const { handlingRequest } = this.state;
    const checkedServices = handlingRequest.services ? handlingRequest.services[serviceGroup.id] : [];
    const filteredServices = _.filter(
      services,
      service => service.service_group_id === serviceGroup.id && service.status === "active"
    );
    const orderedFilteredServices = _.sortBy(filteredServices, "name");
    return (
      <ServiceCheckboxContainer
        serviceGroup={serviceGroup}
        services={orderedFilteredServices}
        checkedServices={checkedServices}
        onChange={this.handleServiceChange}
        disabled={preventEditing}
      />
    );
  }

  renderOtherServices() {
    const { preventEditing } = this.props;
    const { handlingRequest } = this.state;
    return (
      <div className="mt-3">
        <h3>Other info or requests</h3>
        <hr />
        <Form.Group>
          <Textarea
            key="other"
            name="other"
            id="other"
            minRows={3}
            maxLength={1500}
            value={handlingRequest.other}
            onChange={this.handleGroundHandlingChange}
            className="form-control form-control-textarea"
            disabled={preventEditing}
          />
        </Form.Group>
      </div>
    );
  }

  renderContact() {
    const { preventEditing } = this.props;
    const { handlingRequest } = this.state;
    return (
      <div className="mt-3">
        <h3>Contact</h3>
        <hr />
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Name of the Captain</Form.Label>
            <Form.Control
              type="text"
              id="captain"
              placeholder="Enter the captain's name"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.captain || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Crew Email</Form.Label>
            <Form.Control
              type="email"
              id="crew_email"
              placeholder="Enter the crew's email"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.crew_email || ""}
              disabled={preventEditing}
            />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Crew Phone</Form.Label>
            <Form.Control
              type="tel"
              pattern="^[0-9-+s()]*$"
              id="crew_phone"
              placeholder="Enter the crew's phone number"
              onChange={this.handleGroundHandlingChange}
              value={handlingRequest.crew_phone || ""}
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
      </div>
    );
  }

  renderBillingAddress() {
    const { preventEditing } = this.props;
    const { handlingRequest } = this.state;
    const { billing } = handlingRequest;
    const { billingName, billingAddressLine1, billingAddressLine2, billingVatNumber, billingCountry } = billing || {};
    const billingAddressFragment = (
      <div className="mt-3">
        <h3>Billing Address</h3>
        <hr />
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Name</Form.Label>
            <Form.Control
              id="billingName"
              name="billingName"
              type="text"
              value={billingName}
              onChange={this.handleBillingChangeEvent}
              placeholder="Name (e.g. NSAC Bvba)"
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control
              name="billingAddressLine1"
              id="billingAddressLine1"
              type="text"
              value={billingAddressLine1}
              onChange={this.handleBillingChangeEvent}
              placeholder="Address Line 1 (e.g. Nieuwpoortsesteenweg 943)"
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              name="billingAddressLine2"
              id="billingAddressLine2"
              type="text"
              value={billingAddressLine2}
              onChange={this.handleBillingChangeEvent}
              placeholder="Address Line 2 (e.g. 8400 Ostend)"
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>Country</Form.Label>
            <Form.Control
              name="billingCountry"
              id="billingCountry"
              type="text"
              value={billingCountry}
              onChange={this.handleBillingChangeEvent}
              placeholder="Country (e.g. Belgium)"
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="col-12 col-md-6">
            <Form.Label>VAT Number</Form.Label>
            <Form.Control
              name="billingVatNumber"
              id="billingVatNumber"
              type="text"
              value={billingVatNumber}
              onChange={this.handleBillingChangeEvent}
              placeholder="VAT Number"
              disabled={preventEditing}
            />
          </Form.Group>
        </Row>
      </div>
    );
    return billingAddressFragment;
  }

  render() {
    return (
      <div>
        {this.renderContact()}
        {this.renderBillingAddress()}
        {this.renderGroundHandling()}
        {this.renderServiceGroups()}
        {this.renderOtherServices()}
      </div>
    );
  }
}

HandlingRequestForm.propTypes = {
  handlingRequestType: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    cachedFlight: state.flights.current,
    user: state.authentication.user
  };
}

export default connect(mapStateToProps)(withRouter(HandlingRequestForm));
