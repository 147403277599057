// reducers/initialState.js

// import moment from "moment";

export default {
  authentication: {
    user: null
  },
  flights: {
    all: [],
    current: null,
    minDate: new Date(),
    maxDate: new Date()
  },
  services: [],
  service_groups: []
};
