/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable no-case-declarations */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { StickyContainer, Sticky } from "react-sticky";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import NotificationSystem from "rc-notification";

import {
  FaPencilAlt,
  FaSave,
  FaTimesCircle,
  FaFileInvoice,
  FaDownload,
  FaCheckDouble,
  FaTrashAlt
} from "react-icons/fa";
import { RotateLoader } from "react-spinners";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  InputGroup,
  Row,
  Col
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { getAllServices } from "../../api/servicesApi";
import { getAllServiceGroups } from "../../api/serviceGroupsApi";
import { getFlightById, updateFlight } from "../../api/flightsApi";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";

import Airports from "../../data/airports_openflights";
import HandlingRequestTypes from "../../enums/handlingRequestTypes";
import FlightStatus from "../../enums/flightStatus";
import UserRoles from "../../enums/roles";
import { FLIGHTS_EDIT_CURRENT } from "../../actions/actionTypes";
import { FullWideNotification } from "../../shared/components/Notification";

import HandlingRequestForm from "./HandlingRequestForm";

const uuidv1 = require("uuid/v1");

let notificationSystem = null;

class Flight extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = axios.CancelToken.source();
    this.state = {
      isEditing: false,
      isLoading: true,
      didLoad: false,
      isUpdating: false,
      serviceGroups: [],
      services: [],
      user: this.props.user,
      flightId: this.props.match.params.id,
      cachedFlight: {},
      originalFlight: {},
      editedFlight: {},
      flight: {},
      droppedDocuments: {
        droppedGeneralDeclaration: [],
        droppedNoiseCertificate: []
      }
    };

    this.onDropAccepted = this.onDropAccepted.bind(this);
  }

  async componentDidMount() {
    NotificationSystem.newInstance(
      { },
      n => (notificationSystem = n)
    );
    try {
      this.cancelTokenSource = axios.CancelToken.source();
      const { flightId } = this.state;
      const serviceGroups = await getAllServiceGroups(
        this.cancelTokenSource.token
      );
      const services = await getAllServices(this.cancelTokenSource.token);
      const flightDTO = await getFlightById(
        flightId,
        this.cancelTokenSource.token
      );
      const originalFlightCopy = JSON.parse(JSON.stringify(flightDTO));
      const editedFlightCopy = JSON.parse(JSON.stringify(flightDTO));
      const flightCopy = JSON.parse(JSON.stringify(flightDTO));
      this.setState({
        isLoading: false,
        didLoad: true,
        serviceGroups,
        services,
        originalFlight: originalFlightCopy,
        editedFlight: editedFlightCopy,
        flight: flightCopy
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while loading the AddFlight form!");
        console.log(err);
        this.setState({ isLoading: false, didLoad: false });
      }
    }
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel("FlightsAdd will unmount");
    notificationSystem.destroy();
  }

  showNotification = (message, color, duration) => {
    const notification = (
      <FullWideNotification color={color} message={message} />
    );
    const { previousNotice } = this.state;
    notificationSystem.removeNotice(previousNotice);
    const key = uuidv1();
    notificationSystem.notice({
      key,
      content: notification,
      duration: duration || 10,
      closable: true,
      style: { bottom: 0, left: 0 },
      className: "full handling-request-wizard__notification"
    });
    this.setState({ previousNotice: key });
  };

  onDropAccepted(droppedFiles, key) {
    if (droppedFiles && droppedFiles.length > 1) {
      const message = (
        <div>
          <p>Only one file is allowed.</p>
          <p>Please combine your files into a single pdf-file.</p>
        </div>
      );
      this.showNotification(message, "danger");
    } else {
      const { droppedDocuments } = this.state;
      droppedDocuments[key] = droppedFiles;
      this.setState({ droppedDocuments });
    }
  }

  onDropRejected = rejectedFiles => {
    if (rejectedFiles && Array.isArray(rejectedFiles)) {
      let message;
      const color = "danger";
      if (rejectedFiles.length > 1) {
        message = (
          <div>
            <p>Some files were rejected:</p>
            <ul>
              {_.map(rejectedFiles, rf => {
                return <li>{rf.name}</li>;
              })}
            </ul>
            <p>Please make sure individual file sizes do not exceed 3Mb.</p>
          </div>
        );
      } else {
        message = (
          <div>
            <p>File {rejectedFiles[0].name} was rejected.</p>
            <p>Please make sure file size does not exceed 3Mb.</p>
          </div>
        );
      }
      this.showNotification(message, color);
    }
  };

  removeFile = (fileName, key, e) => {
    e.preventDefault();
    const { droppedDocuments } = this.state;
    droppedDocuments[key] = _.filter(
      droppedDocuments[key],
      f => f.name !== fileName
    );
    this.setState({ droppedDocuments });
  };

  renderFilePreview = (file, key) => {
    const { type } = file || "";
    let filePreview;
    switch (type) {
      case "application/pdf":
        filePreview = (
          <div className="dropzone__pdf" key={file.name}>
            <span className="dropzone__pdf-title">{file.name}</span>
            <p className="dropzone__pdf-name">{file.name}</p>
            <button
              className="dropzone__pdf-delete"
              type="button"
              onClick={e => this.removeFile(file.name, key, e)}
            >
              Remove
            </button>
          </div>
        );
        break;
      case "image/png":
      case "image/jpeg":
        filePreview = (
          <div
            className="dropzone__img"
            key={file.name}
            style={{ backgroundImage: `url(${file.preview})` }}
          >
            <p className="dropzone__img-name">{file.name}</p>
            <button
              className="dropzone__img-delete"
              type="button"
              onClick={e => this.removeFile(file.name, key, e)}
            >
              Remove
            </button>
          </div>
        );
        break;
      default:
        filePreview = null;
        break;
    }
    return filePreview;
  };

  removeGenDec = fileName => {
    const { editedFlight } = this.state;
    editedFlight.documents.generalDeclaration = _.filter(
      editedFlight.documents.generalDeclaration,
      f => f.fileName !== fileName
    );
    this.setState({ editedFlight });
  };

  removeNoiseCertificate = fileName => {
    const { editedFlight } = this.state;
    editedFlight.documents.noiseCertificate = _.filter(
      editedFlight.documents.noiseCertificate,
      f => f.fileName !== fileName
    );
    this.setState({ editedFlight });
  };

  renderDocumentsEditView = (flight, droppedDocuments) => {
    const { generalDeclaration, noiseCertificate } = flight.documents || {};
    const {
      droppedGeneralDeclaration,
      droppedNoiseCertificate
    } = droppedDocuments;
    let genDecDocs = (
      <div className="list-group-item clearfix">No documents</div>
    );
    if (Array.isArray(generalDeclaration) && generalDeclaration.length > 0) {
      genDecDocs = generalDeclaration.map((doc, index) => {
        return (
          <li
            key={`gd-${index}-edit`}
            className="list-group-item list-group-item-action clearfix"
          >
            <span>{doc.fileName}</span>
            <button
              className="float-right btn-download"
              onClick={() => this.removeGenDec(doc.fileName)}
            >
              <FaTrashAlt />
            </button>
          </li>
        );
      });
    }
    let noiseCertDocs = (
      <div className="list-group-item clearfix">No documents</div>
    );
    if (Array.isArray(noiseCertificate) && noiseCertificate.length > 0) {
      noiseCertDocs = noiseCertificate.map((doc, index) => {
        return (
          <li
            key={`nc-${index}-edit`}
            className="list-group-item list-group-item-action clearfix"
          >
            <span>{doc.fileName}</span>
            <button
              className="float-right btn-download"
              onClick={() => this.removeNoiseCertificate(doc.fileName)}
            >
              <FaTrashAlt />
            </button>
          </li>
        );
      });
    }
    return (
      <Row className="mx-0">
        <div className="col-12 px-0 mt-3">
          <h3>General Declaration</h3>
          <hr />
        </div>
        <div className="list-group col-12 px-0">{genDecDocs}</div>
        <FormGroup className="form-group handling-request-wizard__form-group col-12">
          <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
            <Dropzone
              key="dz-gd"
              className="dropzone__input"
              accept="application/pdf"
              name="droppedGeneralDeclaration"
              maxSize={3000000}
              onDropAccepted={droppedFiles =>
                this.onDropAccepted(droppedFiles, "droppedGeneralDeclaration")
              }
              onDropRejected={this.onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone__input">
                  {(!droppedGeneralDeclaration ||
                    droppedGeneralDeclaration.length === 0) && (
                    <div className="dropzone__drop-here">
                      <span className="lnr lnr-upload" />
                      <span className="message">
                        {/* <br />
                        Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
                        <br />
                      </span>
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            {droppedGeneralDeclaration && (
              <div className="dropzone__imgs-wrapper">
                {_.map(droppedGeneralDeclaration, f =>
                  this.renderFilePreview(f, "droppedGeneralDeclaration")
                )}
              </div>
            )}
          </div>
        </FormGroup>
        <div className="col-12 px-0 mt-3">
          <h3>Noise Certificate</h3>
          <hr />
        </div>
        <div className="list-group col-12 px-0">{noiseCertDocs}</div>
        <FormGroup className="form-group handling-request-wizard__form-group col-12">
          <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
            <Dropzone
              key="dz-nc"
              className="dropzone__input"
              accept="application/pdf"
              name="droppedNoiseCertificate"
              maxSize={3000000}
              onDropAccepted={droppedFiles =>
                this.onDropAccepted(droppedFiles, "droppedNoiseCertificate")
              }
              onDropRejected={this.onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone__input">
                  {(!droppedNoiseCertificate ||
                    droppedNoiseCertificate.length === 0) && (
                    <div className="dropzone__drop-here">
                      <span className="lnr lnr-upload" />
                      <span className="message">
                        {/* <br />
                Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
                        <br />
                      </span>
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            {droppedNoiseCertificate && (
              <div className="dropzone__imgs-wrapper">
                {_.map(droppedNoiseCertificate, f =>
                  this.renderFilePreview(f, "droppedNoiseCertificate")
                )}
              </div>
            )}
          </div>
        </FormGroup>
      </Row>
    );

    // return (
    //   <Row>
    //     <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
    //       <h4 className="font-weight-bold mb-1">General Declaration</h4>
    //       <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
    //         <Dropzone
    //           className="dropzone__input"
    //           accept="application/pdf"
    //           name="generalDeclaration"
    //           maxSize={3000000}
    //           onDropAccepted={droppedFiles => this.onDropAccepted(droppedFiles, "generalDeclaration")}
    //           onDropRejected={this.onDropRejected}
    //         >
    //           {({ getRootProps, getInputProps }) => (
    //             <div {...getRootProps()} className="dropzone__input">
    //               {(!generalDeclaration || generalDeclaration.length === 0) && (
    //                 <div className="dropzone__drop-here">
    //                   <span className="lnr lnr-upload" />
    //                   <span className="message">
    //                     {/* <br />
    //                     Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
    //                     <br />
    //                   </span>
    //                 </div>
    //               )}
    //               <input {...getInputProps()} />
    //             </div>
    //           )}
    //         </Dropzone>
    //         {generalDeclaration && (
    //           <div className="dropzone__imgs-wrapper">
    //             {_.map(generalDeclaration, f => this.renderFilePreview(f, "generalDeclaration"))}
    //           </div>
    //         )}
    //       </div>
    //     </FormGroup>
    //     <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
    //       <h4 className="font-weight-bold mb-1">Noise Certificate</h4>
    //       <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
    //         <Dropzone
    //           className="dropzone__input"
    //           accept="application/pdf"
    //           name="noiseCertificate"
    //           maxSize={3000000}
    //           onDropAccepted={droppedFiles => this.onDropAccepted(droppedFiles, "noiseCertificate")}
    //           onDropRejected={this.onDropRejected}
    //         >
    //           {({ getRootProps, getInputProps }) => (
    //             <div {...getRootProps()} className="dropzone__input">
    //               {(!noiseCertificate || noiseCertificate.length === 0) && (
    //                 <div className="dropzone__drop-here">
    //                   <span className="lnr lnr-upload" />
    //                   <span className="message">
    //                     {/* <br />
    //             Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
    //                     <br />
    //                   </span>
    //                 </div>
    //               )}
    //               <input {...getInputProps()} />
    //             </div>
    //           )}
    //         </Dropzone>
    //         {noiseCertificate && (
    //           <div className="dropzone__imgs-wrapper">
    //             {_.map(noiseCertificate, f => this.renderFilePreview(f, "noiseCertificate"))}
    //           </div>
    //         )}
    //       </div>
    //     </FormGroup>
    //   </Row>
    // );
  };

  toggleEditing = () => {
    // event.preventDefault();
    const { isEditing, originalFlight, editedFlight } = this.state;
    const flightCopy = !isEditing
      ? JSON.parse(JSON.stringify(editedFlight))
      : JSON.parse(JSON.stringify(originalFlight));
    this.setState({
      isEditing: !isEditing,
      flight: flightCopy
    });
  };

  settle = async () => {
    // event.preventDefault();
    const { editedFlight } = this.state;
    if (editedFlight.status !== FlightStatus.SETTLED) {
      editedFlight.status = FlightStatus.SETTLED;
      const flightCopy = JSON.parse(JSON.stringify(editedFlight));
      this.setState({ editedFlight, flight: flightCopy });
      this.saveEdits();
    }
  };

  cancelEdits = () => {
    // event.preventDefault();
    this.toggleEditing();
  };

  notifyUpdateSuccess = () => {
    const color = "success";
    const message = (
      <div>
        <p>Handling request successfully updated.</p>
      </div>
    );
    this.showNotification(message, color, 3);
  };

  saveEdits = async () => {
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      this.setState({ isUpdating: true });
      const { editedFlight, droppedDocuments } = this.state;
      const updatedFlight = await updateFlight(
        editedFlight,
        droppedDocuments,
        this.cancelTokenSource.token
      );
      this.toggleEditing();
      const originalFlightCopy = JSON.parse(JSON.stringify(updatedFlight));
      const editedFlightCopy = JSON.parse(JSON.stringify(updatedFlight));
      const flightCopy = JSON.parse(JSON.stringify(updatedFlight));
      this.setState({
        isEditing: false,
        isLoading: false,
        didLoad: true,
        originalFlight: originalFlightCopy,
        editedFlight: editedFlightCopy,
        flight: flightCopy,
        droppedDocuments: {
          droppedGeneralDeclaration: [],
          droppedNoiseCertificate: []
        }
      });
      this.notifyUpdateSuccess();
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while editing a flight!");
        console.log(err);
      }
      this.setState({
        isUpdating: false,
        documents: {
          generalDeclaration: [],
          noiseCertificate: []
        }
      });
    }
  };

  handleHandlingRequestChange = handlingRequest => {
    const { editedFlight, flight } = this.state;
    editedFlight.handling_request = JSON.parse(JSON.stringify(handlingRequest));
    flight.handling_request = JSON.parse(JSON.stringify(handlingRequest));
    this.setState({ editedFlight, flight });
  };

  renderActions() {
    const { user, flightId } = this.state;
    switch (user.role) {
      case UserRoles.NAVIGATION_OFFICE:
        return this.state.isEditing ? (
          <div className="flex">
            <button
              className="btn-sm btn-gold ml-3"
              onClick={() => this.saveEdits()}
            >
              <FaSave>Save</FaSave>
            </button>
            <button
              className="btn-sm btn-gold ml-3"
              onClick={() => this.cancelEdits()}
            >
              <FaTimesCircle>Cancel</FaTimesCircle>
            </button>
          </div>
        ) : (
          <div className="flex">
            <button
              className="btn-sm btn-gold ml-3"
              onClick={() => this.toggleEditing()}
            >
              <FaPencilAlt>Edit</FaPencilAlt>
            </button>
          </div>
        );
      case UserRoles.ADMINISTRATOR:
        const invoiceUrl = `/api/handling-requests/${flightId}/invoice`;
        return this.state.isEditing ? (
          <div className="flex">
            <button
              className="btn-sm btn-gold ml-3"
              onClick={() => this.saveEdits()}
            >
              <FaSave>Save</FaSave>
            </button>
            <button
              className="btn-sm btn-gold ml-3"
              onClick={() => this.cancelEdits()}
            >
              <FaTimesCircle>Cancel</FaTimesCircle>
            </button>
          </div>
        ) : (
          <div className="flex">
            <a
              className="btn-sm btn-gold mr-3"
              href={invoiceUrl}
              target="_blank"
              download
            >
              <FaFileInvoice>Invoice</FaFileInvoice>
            </a>
            <button
              className="btn-sm btn-gold ml-3"
              onClick={() => this.settle()}
            >
              <FaCheckDouble>Settle</FaCheckDouble>
            </button>
            <button
              className="btn-sm btn-gold ml-3"
              onClick={() => this.toggleEditing()}
            >
              <FaPencilAlt>Edit</FaPencilAlt>
            </button>
          </div>
        );
      default:
        return null;
    }
  }

  handleStatusChange = event => {
    if (event) event.preventDefault();
    const { editedFlight } = this.state;
    editedFlight.status = event.target.value;
    const flightCopy = JSON.parse(JSON.stringify(editedFlight));
    this.setState({ editedFlight, flight: flightCopy });
  };

  renderFlightDetails() {
    const { isEditing, flight } = this.state;
    return (
      <React.Fragment>
        <h2 className="mt-3">Flight Details</h2>
        <Row>
          <FormGroup as={Col} className="col-12 col-md-6" controlId="flight-id">
            <FormLabel>ID</FormLabel>
            <FormControl value={flight.id} disabled />
          </FormGroup>
          <FormGroup
            as={Col}
            className="col-12 col-md-6"
            controlId="flight-status"
          >
            <FormLabel>Status</FormLabel>
            <Form.Control
              as="select"
              value={flight.status}
              disabled={!isEditing}
              onChange={this.handleStatusChange}
            >
              {_.sortBy(FlightStatus).map(status => (
                <option key={status} value={status}>
                  {capitalizeFirstLetter(status)}
                </option>
              ))}
            </Form.Control>
          </FormGroup>
        </Row>
      </React.Fragment>
    );
  }

  handleFeeChange = event => {
    if (event) event.preventDefault();
    const { id, value } = event.target;
    const { editedFlight } = this.state;
    if (!editedFlight.fees) {
      editedFlight.fees = {};
    }
    const fee = parseFloat(value);
    // console.log(`value ${value}`);
    // console.log(`fee ${fee}`);
    editedFlight.fees[id] = fee;
    const flightCopy = JSON.parse(JSON.stringify(editedFlight));
    this.setState({ editedFlight, flight: flightCopy });
  };

  renderFees() {
    const { user } = this.state;
    switch (user.role) {
      case UserRoles.NAVIGATION_OFFICE:
      case UserRoles.ADMINISTRATOR:
        const { isEditing, flight } = this.state;
        const fees = flight.fees || {};
        const { landing, departure, pax, apron, prm } = fees;
        return (
          <div className="mt-3">
            <h2>Fees</h2>
            <Row>
              <FormGroup as={Col} className="col-12 col-md-6">
                <FormLabel>Landing fees</FormLabel>
                <InputGroup>
                  <FormControl
                    id="landing"
                    type="number"
                    min="0.0"
                    step="0.5"
                    value={landing}
                    disabled={!isEditing}
                    onChange={this.handleFeeChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">EUR</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <FormGroup as={Col} className="col-12 col-md-6">
                <FormLabel>Departure fees</FormLabel>
                <InputGroup>
                  <FormControl
                    id="departure"
                    type="number"
                    min="0.0"
                    step="0.5"
                    value={departure}
                    disabled={!isEditing}
                    onChange={this.handleFeeChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">EUR</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup as={Col} className="col-12 col-md-6">
                <FormLabel>Pax fees</FormLabel>
                <InputGroup>
                  <FormControl
                    id="pax"
                    type="number"
                    min="0.0"
                    step="0.5"
                    value={pax}
                    disabled={!isEditing}
                    onChange={this.handleFeeChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">EUR</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <FormGroup as={Col} className="col-12 col-md-6">
                <FormLabel>Apron fees</FormLabel>
                <InputGroup>
                  <FormControl
                    id="apron"
                    type="number"
                    min="0.0"
                    step="0.5"
                    value={apron}
                    disabled={!isEditing}
                    onChange={this.handleFeeChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">EUR</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup as={Col} className="col-12 col-md-6">
                <FormLabel>PRM fees</FormLabel>
                <InputGroup>
                  <FormControl
                    id="prm"
                    type="number"
                    min="0.0"
                    step="0.5"
                    value={prm}
                    disabled={!isEditing}
                    onChange={this.handleFeeChange}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">EUR</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
            </Row>
          </div>
        );
      default:
        return null;
    }
  }

  renderDocumentsDownloadView = flight => {
    const documents = flight.documents || {};
    const { generalDeclaration, noiseCertificate } = documents;
    let genDecDocs = (
      <div className="list-group-item clearfix">No documents</div>
    );
    if (Array.isArray(generalDeclaration) && generalDeclaration.length > 0) {
      genDecDocs = generalDeclaration.map((doc, index) => {
        const location = `/api/flights/${flight.id}/document/${doc.fileName}`;
        return (
          <li
            key={`gd-${index}`}
            className="list-group-item list-group-item-action clearfix"
          >
            <span>{doc.fileName}</span>
            <a
              className="float-right btn-download"
              href={location}
              target="_blank"
              role="button"
            >
              <FaDownload />
            </a>
          </li>
        );
      });
    }
    let noiseCertDocs = (
      <div className="list-group-item clearfix">No documents</div>
    );
    if (Array.isArray(noiseCertificate) && noiseCertificate.length > 0) {
      noiseCertDocs = noiseCertificate.map((doc, index) => {
        const location = `/api/flights/${flight.id}/document/${doc.fileName}`;
        return (
          <li
            key={`nc-${index}`}
            className="list-group-item list-group-item-action clearfix"
          >
            <span>{doc.fileName}</span>
            <a
              className="float-right btn-download"
              href={location}
              target="_blank"
              role="button"
            >
              <FaDownload />
            </a>
          </li>
          // <a
          //   key={`nc-${index}`}
          //   className="list-group-item list-group-item-action clearfix"
          //   href={location}
          //   target="_blank"
          //   role="button"
          // >
          //   {doc.fileName}
          // </a>
        );
      });
    }
    return (
      <Row className="mx-0">
        <div className="col-12 px-0 mt-3">
          <h3>General Declaration</h3>
          <hr />
        </div>
        <ul className="list-group col-12 px-0">{genDecDocs}</ul>
        <div className="col-12 px-0 mt-3">
          <h3>Noise Certificate</h3>
          <hr />
        </div>
        <ul className="list-group col-12 px-0">{noiseCertDocs}</ul>
      </Row>
    );
  };

  renderDocuments() {
    const { isEditing } = this.state;

    if (isEditing) {
      const { editedFlight } = this.state;
      const { droppedDocuments } = this.state;
      return (
        <div className="mt-3">
          <h2>Documents</h2>
          {this.renderDocumentsEditView(editedFlight, droppedDocuments)}
        </div>
      );
    }

    const { flight } = this.state;
    return (
      <div className="mt-3">
        <h2>Documents</h2>
        {this.renderDocumentsDownloadView(flight)}
      </div>
    );
  }

  render() {
    const { flight, isLoading, didLoad, isEditing, flightId } = this.state;
    const key = isEditing ? "edited" : "original";
    if (isLoading) {
      return (
        <div className="container">
          <div className="row">
            <div className="mt-5 ml-auto mr-auto">
              <RotateLoader
                sizeUnit="px"
                size={44}
                color="#85714d"
                loading={this.state.loading}
              />
            </div>
          </div>
          <div className="row">
            <h3 className="mt-5 ml-auto mr-auto">Loading...</h3>
          </div>
        </div>
      );
    }
    if (didLoad) {
      return (
        <StickyContainer>
          <Sticky>
            {({ style, isSticky }) => (
              <div
                style={
                  isSticky
                    ? {
                        ...style,
                        backgroundColor: "#fff",
                        zIndex: 9999,
                        borderBottom: "solid 0.1rem #85714d"
                      }
                    : { ...style, backgroundColor: "#fff", zIndex: 9999 }
                }
              >
                <div className="container my-1" key={key}>
                  <div className="row mx-0">
                    <h1 className="mr-auto">Flight {flightId}</h1>
                    {this.renderActions()}
                  </div>
                </div>
              </div>
            )}
          </Sticky>
          <div className="container mb-3">
            {this.renderFlightDetails()}
            {this.renderDocuments()}
            {this.renderFees()}
            <h2 className="mt-3">Handling Request</h2>
            <HandlingRequestForm
              airports={Airports}
              serviceGroups={this.state.serviceGroups}
              services={this.state.services}
              onChange={this.handleHandlingRequestChange}
              handlingRequestType={HandlingRequestTypes.EDIT}
              handlingRequest={flight.handling_request}
              preventEditing={!isEditing}
            />
          </div>
        </StickyContainer>
      );
    }
    return (
      <div className="container mb-3">
        <div className="d-flex mb-3">
          <h1 className="ml-3 mr-auto">Flight {flightId}</h1>
        </div>
        <div className="px-3 mt-3 mb-3">
          <h2>Flight with id {flightId} no longer exists.</h2>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cachedFlight: state.flights.current,
    user: state.authentication.user
  };
}

export default connect(mapStateToProps)(withRouter(Flight));
