// api/serviceGroupsApi.js

import axios from 'axios';

export const getAllServiceGroups = async function(cancelToken) {
  const res = await axios.get('/api/service-groups/all', { cancelToken });
  if (res) {
    return res.data;
  }
  return null;
};

export const createServiceGroup = async function(serviceGroup, cancelToken) {
  const res = await axios.post('/api/service-groups', serviceGroup, {
    cancelToken
  });
  if (res && res.status === 200) {
    return res.data;
  }
  return null;
};

export const putServiceGroups = async function(serviceGroups, cancelToken) {
  const res = await axios.put("/api/service-groups", serviceGroups, {
    cancelToken
  });
  if (res && res.status === 200) {
    return res.data;
  }
  return null;
};
