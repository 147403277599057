// reducers/servicesReducer.js

import { SERVICES_ADD, SERVICES_UPDATE_ALL, LOG_OUT } from "../actions/actionTypes";
import initialState from "./initialState";

export default function(state = initialState.services, action) {
  let newState;
  switch (action.type) {
    case SERVICES_ADD:
      newState = [...state, action.payload];
      return newState;
    case SERVICES_UPDATE_ALL:
      newState = action.payload;
      return newState;
    case LOG_OUT:
      return initialState.services;
    default:
      return state;
  }
}
