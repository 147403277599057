import schema from "schm";

export default schema({
  captain: String,
  crewEmail: String,
  crewPhone: String,
  passengerCount: String,
  passengerCountDeparture: String,
  pobCount: String,
  pobCountDeparture: String,
  prmCount: String,
  registrationNumber: String,
  aircraftType: String,
  cargo: String,
  mtow: String,
  mtowUnit: String,
  services: Object,
  other: String,
  airportOfOrigin: String,
  eta: Number,
  airportOfDestination: String,
  etd: Number
});
