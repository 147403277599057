import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';
import authReducer from './authenticationReducer';
import flightsReducer from './flightsReducer';
import servicesReducer from './servicesReducer';
import serviceGroupsReducer from './serviceGroupsReducer';

const appReducer = combineReducers({
  authentication: authReducer,
  flights: flightsReducer,
  services: servicesReducer,
  service_groups: serviceGroupsReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
