import schema from 'schm';

export default schema({
  id: Number,
  display_name: String,
  email: String,
  role: String,
  username: String,
  status: String
});
