/* eslint-disable no-useless-escape */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Component } from "react";
import { Button, ButtonToolbar, Row, Form, FormGroup, Label, InputGroup, InputGroupAddon } from "reactstrap";
import Textarea from "react-textarea-autosize";
import PhoneInput from "react-phone-number-input"; // isValidPhoneNumber // formatPhoneNumber,
import PropTypes from "prop-types";
import _ from "lodash";

class HandlingRequestFormGroundHandling extends Component {
  static propTypes = {
    showNext: PropTypes.func.isRequired,
    updateHandlingRequest: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      captain: "",
      crewEmail: "",
      crewPhone: "",
      passengerCount: "",
      passengerCountDeparture: "",
      pobCount: "",
      pobCountDeparture: "",
      prmCount: "",
      registrationNumber: "",
      aircraftType: "",
      cargo: "",
      mtow: "",
      mtowUnit: "kg",
      mtowMinKg: 500,
      mtowMaxKg: 640000,
      mtowMinLb: 1000,
      mtowMaxLb: 1411000,
      preventContinue: true
    };
  }

  componentDidMount() {
    const { mtowUnit } = this.state;
    const { updateHandlingRequest } = this.props;
    updateHandlingRequest("mtowUnit", mtowUnit);
  }

  handleChange = (name, value) => {
    // console.log(`name: ${name}`);
    // console.log(`value: ${value}`);
    this.setState({ [name]: value, preventContinue: false }, () => {
      const { updateHandlingRequest } = this.props;
      updateHandlingRequest(name, value);
    });
  };

  handleChangeEvent = event => {
    event.preventDefault();
    const { target } = event;
    const { name, value } = target;
    // console.log(`name: ${name}`);
    // console.log(`value: ${value}`);
    this.setState({ [name]: value, preventContinue: false }, () => {
      const { updateHandlingRequest } = this.props;
      updateHandlingRequest(name, value);
    });
  };

  validate = () => {
    const {
      registrationNumber,
      aircraftType,
      captain,
      crewPhone,
      crewEmail,
      passengerCount,
      pobCount,
      mtow
    } = this.state;

    const invalidProperties = [];
    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!registrationNumber || registrationNumber.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Registration Number", "Can't be empty"));
    }
    if (!aircraftType || registrationNumber.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Aircraft Type", "Can't be empty"));
    }
    if (!mtow || mtow.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Maximum Take-Off Weight", "Can't be empty"));
    }
    if (!captain || captain.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Name of the Captain", "Can't be empty"));
    }
    if (!crewEmail || crewEmail.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Crew Email", "Can't be empty"));
    } else if (!crewEmail.match(email)) {
      invalidProperties.push(this.createInvalidPropertyObject("Crew Email", "Invalid email"));
    }
    if (!crewPhone || crewPhone.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Crew Phone Number", "Can't be empty"));
    }
    if (!passengerCount || passengerCount.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Number of Passengers", "Can't be empty"));
    }
    if (!pobCount || pobCount.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Number of Persons On Board", "Can't be empty"));
    }

    const isValid = invalidProperties.length === 0;
    if (!isValid) {
      this.notifyAboutInvalidProperties(invalidProperties);
    }
    this.setState({ preventContinue: !isValid });
    return isValid;
  };

  createInvalidPropertyObject = (name, reason, priority) => {
    return { name, reason, priority: priority || 1 };
  };

  notifyAboutInvalidProperties = invalidProperties => {
    const color = "danger";
    const message = (
      <div>
        <p>The following data is invalid :</p>
        <ul>
          {_.map(invalidProperties, prop => {
            return (
              <li key={prop.name}>
                <strong>{prop.name} </strong>
                <br />
                <small>{prop.reason}</small>
              </li>
            );
          })}
        </ul>
      </div>
    );
    const { showNotification } = this.props;
    showNotification(message, color);
  };

  continue = () => {
    const isValid = this.validate();
    if (isValid) {
      const { showNext } = this.props;
      showNext();
    }
  };

  render() {
    const {
      registrationNumber,
      aircraftType,
      captain,
      crewPhone,
      crewEmail,
      passengerCount,
      passengerCountDeparture,
      pobCount,
      pobCountDeparture,
      prmCount,
      cargo,
      mtow,
      mtowUnit,
      mtowMinKg,
      mtowMaxKg,
      mtowMinLb,
      mtowMaxLb,
      preventContinue
    } = this.state;

    return (
      <Form className="handling-request-wizard__form">
        <div className="handling-request-wizard__form-section__title">Aircraft</div>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${registrationNumber ? "" : " hide"}`}>
              Registration Number
            </Label>
            <input
              key="registrationNumber"
              name="registrationNumber"
              className="form-control handling-request-wizard__form-control"
              type="text"
              value={registrationNumber}
              onChange={this.handleChangeEvent}
              placeholder="Registration Number"
            />
          </FormGroup>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${aircraftType ? "" : " hide"}`}>Aircraft Type</Label>
            <input
              key="aircraftType"
              name="aircraftType"
              className="form-control handling-request-wizard__form-control"
              type="text"
              value={aircraftType}
              onChange={this.handleChangeEvent}
              placeholder="Aircraft Type"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${mtow ? "" : " hide"}`}>
              Maximum Take-Off Weight
            </Label>
            <InputGroup>
              <input
                key="mtow"
                name="mtow"
                value={mtow}
                type="number"
                min={mtowUnit === "kg" ? mtowMinKg : mtowMinLb}
                max={mtowUnit === "kg" ? mtowMaxKg : mtowMaxLb}
                step={100}
                onChange={this.handleChangeEvent}
                placeholder="Maximum Take-Off Weight"
                className="form-control handling-request-wizard__form-control"
              />
              <InputGroupAddon addonType="append" className="segmented">
                <button
                  type="button"
                  className={mtowUnit === "kg" ? "segment-button segment-selection" : "segment-button"}
                  id="kg"
                  onClick={() => this.handleChange("mtowUnit", "kg")}
                >
                  kg
                </button>
                <button
                  type="button"
                  className={mtowUnit === "lb" ? "segment-button segment-selection" : "segment-button"}
                  id="lb"
                  onClick={() => this.handleChange("mtowUnit", "lb")}
                >
                  lb
                </button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Row>
        <div className="handling-request-wizard__form-section__title">Crew</div>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${captain ? "" : " hide"}`}>
              Name of the Captain
            </Label>
            <input
              key="captain"
              name="captain"
              type="text"
              value={captain}
              onChange={this.handleChangeEvent}
              placeholder="Name of the Captain"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${crewEmail ? "" : " hide"}`}>Crew Email</Label>
            <input
              key="crewEmail"
              name="crewEmail"
              type="email"
              value={crewEmail}
              onChange={this.handleChangeEvent}
              placeholder="Crew Email"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${crewPhone ? "" : " hide"}`}>
              Crew Phone Number
            </Label>
            <PhoneInput
              key="crewPhone"
              name="crewPhone"
              value={crewPhone}
              onChange={value => this.handleChange("crewPhone", value)}
              placeholder="Crew Phone Number"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <div className="handling-request-wizard__form-section__title">Passengers</div>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label
              className={`handling-request-wizard__form-label${passengerCount && passengerCount >= 0 ? "" : " hide"}`}
            >
              Number of Passengers on Arrival
            </Label>
            <input
              key="passengerCount"
              name="passengerCount"
              type="number"
              min={0}
              step={1}
              value={passengerCount}
              onChange={this.handleChangeEvent}
              placeholder="Number of Passengers on Arrival"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label
              className={`handling-request-wizard__form-label${
                passengerCountDeparture && passengerCountDeparture >= 0 ? "" : " hide"
              }`}
            >
              Number of Passengers on Departure
            </Label>
            <input
              key="passengerCountDeparture"
              name="passengerCountDeparture"
              type="number"
              min={0}
              step={1}
              value={passengerCountDeparture}
              onChange={this.handleChangeEvent}
              placeholder="Number of Passengers on Departure"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${pobCount && pobCount >= 0 ? "" : " hide"}`}>
              Number of Persons on Board on Arrival
            </Label>
            <input
              key="pobCount"
              name="pobCount"
              type="number"
              min={0}
              step={1}
              value={pobCount}
              onChange={this.handleChangeEvent}
              placeholder="Number of Persons on Board on Arrival"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label
              className={`handling-request-wizard__form-label${
                pobCountDeparture && pobCountDeparture >= 0 ? "" : " hide"
              }`}
            >
              Number of Persons on Board on Departure
            </Label>
            <input
              key="pobCountDeparture"
              name="pobCountDeparture"
              type="number"
              min={0}
              step={1}
              value={pobCountDeparture}
              onChange={this.handleChangeEvent}
              placeholder="Number of Persons on Board on Departure"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${prmCount && prmCount >= 0 ? "" : " hide"}`}>
              Number of Persons with Reduced Mobility
            </Label>
            <input
              key="prmCount"
              name="prmCount"
              type="number"
              min={0}
              step={1}
              value={prmCount}
              onChange={this.handleChangeEvent}
              placeholder="Number of Persons with Reduced Mobility"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <div className="handling-request-wizard__form-section__title">Cargo</div>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12">
            <Textarea
              key="cargo"
              name="cargo"
              // maxRows={5}
              maxLength={300}
              value={cargo}
              onChange={this.handleChangeEvent}
              placeholder="Tell us about your cargo"
              className="form-control handling-request-wizard__form-control handling-request-wizard__form-control-textarea"
            />
          </FormGroup>
        </Row>
        <ButtonToolbar className="form__button-toolbar handling-request-wizard__toolbar">
          <Button
            color="primary"
            type="button"
            className="next ml-auto"
            onClick={() => this.continue()}
            disabled={preventContinue}
          >
            Next
          </Button>
        </ButtonToolbar>
      </Form>
    );
  }
}

export default HandlingRequestFormGroundHandling;
