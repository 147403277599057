/* eslint-disable react/forbid-prop-types */
import React from "react";
import { PropTypes } from "prop-types";
import _ from "lodash";
import { Row, Col } from "reactstrap";
import Checkbox from "./Checkbox";

class ServiceCheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedState: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { checkedServices } = this.props;
    this.setState({
      checkedState: this.createCheckedStatesFromServiceIds(checkedServices)
    });
  }

  handleChange = e => {
    const { id, checked } = e.target;
    const { checkedState } = this.state;
    checkedState[id] = checked;
    this.setState({ checkedState });
    const checkedcheckboxIds = _.keys(_.pickBy(checkedState));
    const { onChange, serviceGroup } = this.props;
    onChange(serviceGroup.id, checkedcheckboxIds);
  };

  createCheckedStatesFromServiceIds = serviceIds => {
    const checkedState = {};
    if (serviceIds) {
      _.forEach(serviceIds, id => {
        checkedState[id] = true;
      });
    }
    return checkedState;
  };

  createCheckboxesFromServices = (group, services) => {
    return _.map(services, service => {
      const key = `${group}-${service.id}`;
      const label = service.name;
      const containerClassNames = "px-0";
      const inputClassNames = "form-check-checkbox";
      const labelClassNames = "form-check-label ml-2";
      return {
        id: service.id,
        group,
        key,
        label,
        containerClassNames,
        inputClassNames,
        labelClassNames
      };
    });
  };

  renderCheckboxes(checkboxes) {
    const { checkedState } = this.state;
    const { disabled } = this.props;
    return _.map(checkboxes, checkbox => (
      <Checkbox
        key={checkbox.key}
        id={checkbox.id}
        group={checkbox.group}
        label={checkbox.label}
        checked={checkedState[checkbox.id] || false}
        onChange={this.handleChange}
        disabled={disabled}
        containerClassNames={checkbox.containerClassNames}
        inputClassNames={checkbox.inputClassNames}
        labelClassNames={checkbox.labelClassNames}
      />
    ));
  }

  render() {
    const { serviceGroup, services, className } = this.props;
    const halfWay = Math.ceil(services.length / 2);
    const servicesCol1 = services.slice(0, halfWay);
    const servicesCol2 = services.slice(halfWay, services.length);
    const checkboxesCol1 = this.createCheckboxesFromServices(serviceGroup.name, servicesCol1);
    const checkboxesCol2 = this.createCheckboxesFromServices(serviceGroup.name, servicesCol2);
    return (
      <Row className={`${className || ""} mt-2 mb-2`}>
        <Col className="col-12 col-md-6">{this.renderCheckboxes(checkboxesCol1)}</Col>
        <Col className="col-12 col-md-6">{this.renderCheckboxes(checkboxesCol2)}</Col>
      </Row>
    );
  }
}

ServiceCheckboxContainer.propTypes = {
  // key: PropTypes.string.isRequired,
  serviceGroup: PropTypes.object.isRequired,
  services: PropTypes.arrayOf(Checkbox).isRequired,
  checkedServices: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string
};

ServiceCheckboxContainer.defaultProps = {
  className: "",
  checkedServices: []
};

export default ServiceCheckboxContainer;
