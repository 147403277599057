// api/servicesApi.js

import axios from "axios";

export const getAllServices = async function(cancelToken) {
  const res = await axios.get("/api/services/all", { cancelToken });
  if (res) {
    return res.data;
  }
  return null;
};

export const createService = async function(service, cancelToken) {
  const res = await axios.post("/api/services", service, {
    cancelToken
  });
  if (res && res.status === 200) {
    return res.data;
  }
  return null;
};

export const putServices = async function(services, cancelToken) {
  const res = await axios.put("/api/services", services, {
    cancelToken
  });
  if (res && res.status === 200) {
    return res.data;
  }
  return null;
};
