import React, { PureComponent } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import MinusIcon from "mdi-react/MinusIcon";
import PlusIcon from "mdi-react/PlusIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";

export default class CollapseComponent extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
    onToggle: PropTypes.func,
    isOpen: PropTypes.bool
  };

  static defaultProps = {
    title: "",
    className: "",
    onToggle: id => {
      console.log(id);
    },
    isOpen: false
  };

  constructor() {
    super();
    this.state = {
      status: "closed",
      icon: <PlusIcon />
    };
  }

  onEntering = () => {
    this.setState({ status: "opening", icon: <MinusIcon /> });
  };

  onEntered = () => {
    this.setState({ status: "opened", icon: <MinusIcon /> });
  };

  onExiting = () => {
    this.setState({ status: "closing", icon: <PlusIcon /> });
  };

  onExited = () => {
    this.setState({ status: "closed", icon: <PlusIcon /> });
  };

  render() {
    const { id, className, title, children, isOpen, onToggle } = this.props;
    const { icon, status } = this.state;

    return (
      <div className={`collapse__wrapper ${status} ${className}`}>
        <button
          onClick={() => onToggle(id)}
          className="collapse__title"
          type="button"
        >
          {icon}
          <p>
            {title}
            <ChevronDownIcon />
          </p>
        </button>
        <Collapse
          isOpen={isOpen}
          className="collapse__content"
          onEntering={this.onEntering}
          onEntered={this.onEntered}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <div>{children}</div>
        </Collapse>
      </div>
    );
  }
}
