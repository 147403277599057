/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React, { Component } from "react";
// import { Button, ButtonToolbar } from "reactstrap";
import { Button, ButtonToolbar, Row, Form, FormGroup, Label, InputGroup, InputGroupAddon } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import { convertMtowToTonnes } from "../../../helpers/conversion";
import CollapseMinimal from "../../../shared/components/Collapse";
import { postHandlingRequest } from "../../../api/handlingRequestApi";
import handlingRequestDTOSchema from "../../../schemas/handlingRequestDTOSchema";
import CheckboxContainer from "./CheckboxContainer";

class HandlingRequestFormReview extends Component {
  static propTypes = {
    showPrevious: PropTypes.func.isRequired,
    handlingRequest: PropTypes.object.isRequired,
    serviceGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    services: PropTypes.arrayOf(PropTypes.object).isRequired,
    showNotification: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.cancelTokenSource = axios.CancelToken.source();
    this.state = {
      collapsable: 0,
      isUploading: false,
      didCreateHandlingRequest: false,
      acceptLegal: false,
      billing: { billingName: "", billingAddressLine1: "", billingAddressLine2: "", billingCountry: "", billingVatNumber: "" }
    };
    this.submitHandlingRequest = this.submitHandlingRequest.bind(this);
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel("FlightsAdd will unmount");
  }

  handleLegalChangeEvent = checked => {
    this.setState({ acceptLegal: checked });
  };

  handleBillingChangeEvent = event => {
    event.preventDefault();
    const { target } = event;
    const { name, value } = target;
    // console.log(`name: ${name}`);
    // console.log(`value: ${value}`);
    const { billing } = this.state;
    billing[name] = value;
    this.setState({ billing });
  };

  onToggle = id => {
    // console.log(id);
    this.setState(prevState => ({
      collapsable: prevState.collapsable === id ? 0 : id
    }));
    // console.log(this.state);
  };

  submitHandlingRequest = async () => {
    const isBillingAddressValid = this.validateBillingAddress();
    if (!isBillingAddressValid) return;
    const { isUploading } = this.state;
    if (isUploading) {
      return;
    }
    this.setState({ isUploading: true });
    const { handlingRequest } = this.props;
    const { documents } = handlingRequest;
    const handlingRequestDTO = this.createHandlingRequestDTO(handlingRequest);
    const { billing } = this.state;
    handlingRequestDTO.billing = billing;
    try {
      this.cancelTokenSource = axios.CancelToken.source();
      const res = await postHandlingRequest(handlingRequestDTO, documents, this.cancelTokenSource.token);
      if (res && res.status === 200) {
        this.setState({ didCreateHandlingRequest: true, isUploading: false });
      } else {
        this.setState({ didCreateHandlingRequest: false, isUploading: false });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while uploading the handling request!");
        console.log(err);
        this.setState({ didCreateHandlingRequest: false, isUploading: false });
      }
    }
  };

  validateBillingAddress = () => {
    const { billing } = this.state;
    // const { billingName, billingAddressLine1, billingAddressLine2, billingVatNumber } = billing;
    const { billingName, billingAddressLine1, billingAddressLine2, billingCountry } = billing;

    const invalidProperties = [];
    // const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!billingName || billingName.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Name", "Can't be empty"));
    }
    if (!billingAddressLine1 || billingAddressLine1.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Address Line 1", "Can't be empty"));
    }
    if (!billingAddressLine2 || billingAddressLine2.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Address Line 2", "Can't be empty"));
    }
    if (!billingCountry || billingCountry.length === 0) {
      invalidProperties.push(this.createInvalidPropertyObject("Country", "Can't be empty"));
    }
    // if (!billingVatNumber || billingVatNumber.length === 0) {
    //   invalidProperties.push(this.createInvalidPropertyObject("VAT Number", "Can't be empty"));
    // }

    const isValid = invalidProperties.length === 0;
    if (!isValid) {
      this.notifyAboutInvalidProperties(invalidProperties);
    }
    return isValid;
  };

  createInvalidPropertyObject = (name, reason, priority) => {
    return { name, reason, priority: priority || 1 };
  };

  notifyAboutInvalidProperties = invalidProperties => {
    const color = "danger";
    const message = (
      <div>
        <p>The following data is invalid :</p>
        <ul>
          {_.map(invalidProperties, prop => {
            return (
              <li key={prop.name}>
                <strong>{prop.name} </strong>
                <br />
                <small>{prop.reason}</small>
              </li>
            );
          })}
        </ul>
      </div>
    );
    const { showNotification } = this.props;
    showNotification(message, color);
  };

  createHandlingRequestDTO = handlingRequest => {
    // console.log(handlingRequest);
    const handlingRequestDTO = handlingRequestDTOSchema.parse(handlingRequest);
    // console.log(handlingRequestDTO);
    if (!handlingRequest.airportOfOrigin) {
      handlingRequestDTO.eta = 0;
    }
    if (!handlingRequest.airportOfDestination) {
      handlingRequestDTO.etd = 0;
    }
    // handlingRequestDTO.mtowUnit = "t";
    // handlingRequestDTO.mtow = convertMtowToTonnes(handlingRequest.mtow, handlingRequest.mtowUnit);
    return handlingRequestDTO;
  };

  renderCollapsable = (id, title, content) => {
    const { collapsable } = this.state;
    return (
      <CollapseMinimal
        title={title}
        id={id}
        isOpen={collapsable === id}
        onToggle={this.onToggle}
        className="with-shadow"
      >
        {content}
      </CollapseMinimal>
    );
  };

  renderKeyValueTable = (keyTitlePairs, handlingRequest) => {
    return (
      <table className="table table-sm handling-request-wizard__form-review__table">
        <tbody>
          {_.map(keyTitlePairs, pair => {
            let value;
            if (Array.isArray(pair.key)) {
              value = handlingRequest;
              pair.key.forEach(key => {
                if (value) {
                  value = value[key];
                }
              });
            } else {
              value = handlingRequest[pair.key];
            }

            switch (pair.type || "") {
              case "et":
                {
                  const unix = moment.unix(value).utc();
                  value =
                    unix && unix.isValid()
                      ? `${unix.format("dddd D MMMM YYYY")} at ${unix.format("HH:mm")}`
                      : undefined;
                }
                break;
              default:
                break;
            }

            return (
              <tr key={pair.title}>
                <td className="title">{pair.title}</td>
                <td className="value">{value || "/"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  renderGroundHandlingOverview = (id, handlingRequest) => {
    let content = <div>No handling request details available.</div>;
    if (handlingRequest) {
      const keyTitlePairs = [
        { key: "registrationNumber", title: "Registration Number" },
        { key: "aircraftType", title: "Aircraft Type" },
        { key: "mtow", title: "Maximum Take-Off Weight" },
        { key: "mtowUnit", title: "MTOW unit of measure" },
        { key: "captain", title: "Name of the Captain" },
        { key: "crewEmail", title: "Crew Email" },
        { key: "crewPhone", title: "Crew Phone" },
        { key: "passengerCount", title: "Number of Passenger on Arrival" },
        { key: "passengerCountDeparture", title: "Number of Passenger on Departure" },
        { key: "pobCount", title: "Number of Persons on Board on Arrival" },
        { key: "pobCountDeparture", title: "Number of Persons on Board on Departure" },
        { key: "prmCount", title: "Number of Persons with Reduced Mobility" },
        { key: "cargo", title: "Cargo" }
      ];
      content = this.renderKeyValueTable(keyTitlePairs, handlingRequest);
    }
    return this.renderCollapsable(id, "Ground Handling", content);
  };

  renderServicesOverview = (id, handlingRequest) => {
    const { serviceGroups, services } = this.props;
    let content = <div>No services or arrangements requested.</div>;
    let renderServicesArrangementsRequests = false;
    let servicesArrangementsRequests;
    let otherServicesArrangementsRequests;
    if (handlingRequest) {
      if (handlingRequest.services) {
        const selectedServiceGroupIds = _.keys(handlingRequest.services);
        if (Array.isArray(selectedServiceGroupIds) && selectedServiceGroupIds.length > 0) {
          servicesArrangementsRequests = _.map(selectedServiceGroupIds, serviceGroupId => {
            const serviceGroup = _.find(serviceGroups, ["id", serviceGroupId]);
            const selectedServiceIds = handlingRequest.services[serviceGroupId];
            if (selectedServiceIds && Array.isArray(selectedServiceIds) && selectedServiceIds.length > 0) {
              renderServicesArrangementsRequests = true;
              return (
                <React.Fragment key={serviceGroupId}>
                  <tr>
                    <td className="title">{serviceGroup.name}</td>
                  </tr>
                  {_.map(selectedServiceIds, serviceId => {
                    const service = _.find(services, ["id", serviceId]);
                    return (
                      <tr key={serviceId}>
                        <td className="value">{service.name}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            }
            return undefined;
          });
        }
      }
      if (handlingRequest.other) {
        otherServicesArrangementsRequests = (
          <React.Fragment>
            <tr>
              <td className="title">Other services, arrangements or requests</td>
            </tr>
            <tr>
              <td className="value">{handlingRequest.other}</td>
            </tr>
          </React.Fragment>
        );
        renderServicesArrangementsRequests = true;
      }
    }

    if (renderServicesArrangementsRequests) {
      // console.log("renderServicesArrangementsRequests");
      // console.log(renderServicesArrangementsRequests);
      content = (
        <table className="table table-sm handling-request-wizard__form-review__table">
          <tbody>
            {servicesArrangementsRequests}
            {otherServicesArrangementsRequests}
          </tbody>
        </table>
      );
    }

    return this.renderCollapsable(id, "Services & Arrangements", content);
  };

  renderDocumentsOverview = (id, handlingRequest) => {
    let genDecOverview = (
      <div>
        <h3>General Declaration</h3>
        <p>No documents included.</p>
      </div>
    );
    let noiseCertOverview = (
      <div>
        <h3>Noise Certificate</h3>
        <p>No documents included.</p>
      </div>
    );
    if (handlingRequest && handlingRequest.documents) {
      const { generalDeclaration, noiseCertificate } = handlingRequest.documents;
      if (Array.isArray(generalDeclaration) && generalDeclaration.length > 0) {
        genDecOverview = (
          <React.Fragment>
            <h5>General Declaration</h5>
            <table className="table table-sm handling-request-wizard__form-review__table">
              <tbody>
                {_.map(generalDeclaration, document => {
                  return (
                    <tr key={document.name}>
                      <td className="value">{document.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </React.Fragment>
        );
      }
      if (Array.isArray(noiseCertificate) && noiseCertificate.length > 0) {
        noiseCertOverview = (
          <React.Fragment>
            <h5>Noise Certificate</h5>
            <table className="table table-sm handling-request-wizard__form-review__table">
              <tbody>
                {_.map(noiseCertificate, document => {
                  return (
                    <tr key={document.name}>
                      <td className="value">{document.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </React.Fragment>
        );
      }
    }
    const content = (
      <div>
        {genDecOverview}
        {noiseCertOverview}
      </div>
    );
    return this.renderCollapsable(id, "Request Documents", content);
  };

  renderFlightSchedulingOverview = (id, handlingRequest) => {
    let content = <div>No flight scheduling available.</div>;
    if (handlingRequest) {
      const keyTitlePairs = [
        { key: "airportOfOrigin", title: "Airport of Origin" },
        { key: "eta", title: "Estimated Time of Arrival [UTC]", type: "et" },
        { key: "airportOfDestination", title: "Airport of Destination" },
        { key: "etd", title: "Estimated Time of Departure [UTC]", type: "et" }
      ];
      content = this.renderKeyValueTable(keyTitlePairs, handlingRequest);
    }
    return this.renderCollapsable(id, "Flight Scheduling", content);
  };

  renderBilling = () => {
    // const billingName = "";
    // const billingAddressLine1 = "";
    // const billingAddressLine2 = "";
    // const billingVatNumber = "";
    const { billing } = this.state;
    const { billingName, billingAddressLine1, billingAddressLine2, billingCountry, billingVatNumber } = billing;
    return (
      <React.Fragment key="billing">
        <div className="handling-request-wizard__form-section__title">Billing Address</div>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12">
            <Label className={`handling-request-wizard__form-label${billingName ? "" : " hide"}`}>Name</Label>
            <input
              key="billingName"
              name="billingName"
              type="text"
              value={billingName}
              onChange={this.handleBillingChangeEvent}
              placeholder="Name (e.g. NSAC Bvba)"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12">
            <Label className={`handling-request-wizard__form-label${billingAddressLine1 ? "" : " hide"}`}>
              Address Line 1
            </Label>
            <input
              key="billingAddressLine1"
              name="billingAddressLine1"
              type="text"
              value={billingAddressLine1}
              onChange={this.handleBillingChangeEvent}
              placeholder="Address Line 1 (e.g. Nieuwpoortsesteenweg 943)"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12">
            <Label className={`handling-request-wizard__form-label${billingAddressLine2 ? "" : " hide"}`}>
              Address Line 2
            </Label>
            <input
              key="billingAddressLine2"
              name="billingAddressLine2"
              type="text"
              value={billingAddressLine2}
              onChange={this.handleBillingChangeEvent}
              placeholder="Address Line 2 (e.g. 8400 Ostend)"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12">
            <Label className={`handling-request-wizard__form-label${billingCountry ? "" : " hide"}`}>
              Country
            </Label>
            <input
              key="billingCountry"
              name="billingCountry"
              type="text"
              value={billingCountry}
              onChange={this.handleBillingChangeEvent}
              placeholder="Country (e.g. Belgium)"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12">
            <Label className={`handling-request-wizard__form-label${billingVatNumber ? "" : " hide"}`}>
              VAT Number
            </Label>
            <input
              key="billingVatNumber"
              name="billingVatNumber"
              type="text"
              value={billingVatNumber}
              onChange={this.handleBillingChangeEvent}
              placeholder="VAT Number"
              className="form-control handling-request-wizard__form-control"
            />
          </FormGroup>
        </Row>
      </React.Fragment>
    );
  };

  renderLegal = () => {
    return (
      <CheckboxContainer
        key="legal"
        label={(
          <React.Fragment>
            I accept to the{" "}
            <a href="https://s3.eu-central-1.amazonaws.com/files.nsac.aero/general_terms_and_conditions_nsac.pdf">
              general terms &amp; conditions
            </a>{" "}
            and to the{" "}
            <a
              href="https://s3.eu-central-1.amazonaws.com/files.nsac.aero/privacy_policy_nsac.pdf"
              target="_blank"
              download="privacy_policy_nsac.pdf"
            >
              privacy policy
            </a>
            .
          </React.Fragment>
)}
        onChange={this.handleLegalChangeEvent}
      />
    );
  };

  render() {
    const { showPrevious, handlingRequest } = this.props;
    const { isUploading, didCreateHandlingRequest, acceptLegal } = this.state;

    return (
      <div className="handling-request-wizard__form handling-request-wizard__form-review">
        <div className="handling-request-wizard__form-review__container">
          {this.renderGroundHandlingOverview(1, handlingRequest)}
          {this.renderServicesOverview(2, handlingRequest)}
          {this.renderFlightSchedulingOverview(3, handlingRequest)}
          {this.renderDocumentsOverview(4, handlingRequest)}
        </div>

        {didCreateHandlingRequest ? (
          <div className="handling-request-wizard__form-success__container">
            <div className="handling-request-wizard__form-success__container-content">
              <span className="headline">Handling Request well received.</span>
              <span className="tagline">A confirmation email will be sent to the crew email shortly.</span>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="handling-request-wizard__form-billing">{this.renderBilling()}</div>
            <div className="handling-request-wizard__form-review__legal">{this.renderLegal()}</div>

            <ButtonToolbar className="form__button-toolbar handling-request-wizard__toolbar">
              <Button color="primary" type="button" className="previous" onClick={() => showPrevious()}>
                Previous
              </Button>

              <Button
                color="primary"
                type="button"
                className="next"
                onClick={() => this.submitHandlingRequest()}
                disabled={didCreateHandlingRequest || isUploading || !acceptLegal}
              >
                {isUploading ? (
                  <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
                ) : null}
                Submit Handling Request
              </Button>
            </ButtonToolbar>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default HandlingRequestFormReview;
