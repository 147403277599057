// api/authenticationApi.js

import axios from "axios";

export const createToken = async credentials => {
  try {
    const res = await axios.post("/api/auth/tokens", credentials);
    return res.status === 200;
  } catch (err) {
    console.log(err);
  }
  return false;
};

export const destroyToken = async () => {
  try {
    const res = await axios.delete("/api/auth/tokens");
    return res.status === 200 || res.status === 401;
  } catch (err) {
    console.log(err);
  }
  return false;
};
