import { LOG_IN, LOG_OUT } from './actionTypes';
import { createToken, destroyToken } from '../api/authenticationApi';
import { getMyUser } from '../api/usersApi';

export const logIn = (credentials, history) => async dispatch => {
  try {
    const didCreateToken = await createToken(credentials);
    if (didCreateToken) {
      const user = await getMyUser();
      await dispatch({ type: LOG_IN, payload: user });
      history.push('/flights');
    }
  } catch (err) {
    console.log(err);
  }
};

export const logOut = history => async dispatch => {
  const didDestroyToken = await destroyToken();
  if (didDestroyToken) {
    await dispatch({ type: LOG_OUT });
    history.push('/');
  }
};
