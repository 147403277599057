import React from "react";
// import { Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import showResults from "./Show";
import HandlingRequestForm from "./components/HandlingRequestForm";

const HandlingRequest = ({ t }) => (
  <HandlingRequestForm onSubmit={showResults} t={t} />
);

HandlingRequest.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation("common")(HandlingRequest);
