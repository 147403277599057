/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-children-prop */
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { StickyContainer, Sticky } from "react-sticky";
import ReactDataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";
import { Button, ButtonToolbar, Modal } from "reactstrap";
import { FaSync, FaPlus } from "react-icons/fa";
import { RotateLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
import {
  getAllServices,
  createService,
  putServices
} from "../../api/servicesApi";
import {
  getAllServiceGroups,
  createServiceGroup,
  putServiceGroups
} from "../../api/serviceGroupsApi";
import {
  SERVICES_ADD,
  SERVICES_UPDATE_ALL,
  SERVICE_GROUPS_ADD,
  SERVICE_GROUPS_UPDATE_ALL
} from "../../actions/actionTypes";
import status from "../../enums/status";

const uuidv1 = require("uuid/v1");

const { DropDownEditor } = Editors;
const statusOptions = [
  { id: "active", value: "active", title: "Active", text: "Active" },
  { id: "inactive", value: "inactive", title: "Inactive", text: "Inactive" }
];
const StatusEditor = <DropDownEditor options={statusOptions} />;

class Services extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = axios.CancelToken.source();
    this.state = {
      isLoading: true,
      didLoad: false,
      defaultServiceGroupId: undefined,
      showServiceGroupModal: false,
      newServiceGroup: {},
      showServiceModal: false,
      newService: {},
      serviceGroupTable: {
        key: uuidv1(),
        columns: [],
        rows: []
      },
      serviceTable: {
        key: uuidv1(),
        columns: [],
        rows: []
      }
    };
  }

  componentDidMount() {
    this.refreshAsync();
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel("Services will unmount.");
  }

  async refreshAsync() {
    try {
      const { serviceGroupTable, serviceTable } = this.state;
      this.cancelTokenSource = axios.CancelToken.source();
      // Groups
      const serviceGroups = await getAllServiceGroups(
        this.cancelTokenSource.token
      );
      serviceGroupTable.rows = serviceGroups.sort(function(a, b) {
        return a.id - b.id;
      });
      serviceGroupTable.columns = this.getServiceGroupTableColumns();
      // Services
      const services = await getAllServices(this.cancelTokenSource.token);
      serviceTable.rows = services.sort(function(a, b) {
        return a.id - b.id;
      });
      serviceTable.columns = this.getServiceTableColumns(
        serviceGroupTable.rows
      );
      serviceTable.key = uuidv1();
      serviceGroupTable.key = uuidv1();
      const defaultServiceGroupId = serviceTable.rows[0];
      // State update
      this.setState({
        isLoading: false,
        didLoad: true,
        defaultServiceGroupId,
        newServiceGroup: this.getDefaultNewServiceGroup(),
        newService: this.getDefaultNewService(defaultServiceGroupId),
        serviceGroupTable,
        serviceTable
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while loading services!");
        console.log(err);
        this.setState({ isLoading: false, didLoad: false });
      }
    }
  }

  handleRefreshButtonClick = event => {
    event.preventDefault();
    this.refreshAsync();
  };

  getDefaultNewServiceGroup = () => {
    return { status: status.INACTIVE };
  };

  createNewServiceGroup = async event => {
    if (event) event.preventDefault();
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      this.setState({ isCreating: true });
      const { newServiceGroup, serviceGroupTable, serviceTable } = this.state;
      const { rows } = serviceGroupTable;
      const serviceGroup = await createServiceGroup(
        newServiceGroup,
        this.cancelTokenSource.token
      );
      if (serviceGroup) {
        serviceGroupTable.rows = [...rows, serviceGroup];
        serviceTable.columns = this.getServiceTableColumns(
          serviceGroupTable.rows
        );
        serviceTable.key = uuidv1();
        serviceGroupTable.key = uuidv1();
        this.setState({
          isCreating: false,
          showServiceGroupModal: false,
          serviceGroupTable,
          serviceTable
        });
      } else {
        this.setState({
          isCreating: false,
          showServiceGroupModal: false
        });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while creating a new service group!");
        console.log(err);
      }
      this.setState({ isCreating: false });
    }
  };

  cancelNewServiceGroup = event => {
    event.preventDefault();
    this.setState({
      showServiceGroupModal: false,
      newServiceGroup: this.getDefaultNewServiceGroup()
    });
  };

  getDefaultNewService = service_group_id => {
    return { service_group_id, status: status.INACTIVE };
  };

  createNewService = async event => {
    if (event) event.preventDefault();
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      const { newService, serviceTable } = this.state;
      const { rows } = serviceTable;
      const service = await createService(
        newService,
        this.cancelTokenSource.token
      );
      if (service) {
        serviceTable.rows = [...rows, service];
        serviceTable.key = uuidv1();
        this.setState({
          isCreating: false,
          showServiceModal: false,
          serviceTable
        });
      } else {
        this.setState({
          isCreating: false,
          showServiceGroupModal: false
        });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while creating a new service!");
        console.log(err);
      }
      this.setState({ isCreating: false });
    }
  };

  cancelNewService = event => {
    event.preventDefault();
    const { defaultServiceGroupId } = this.state;
    this.setState({
      showServiceModal: false,
      newService: this.getDefaultNewService(defaultServiceGroupId)
    });
  };

  toggleServiceGroupModal = event => {
    event.preventDefault();
    const { showServiceGroupModal } = this.state;
    this.setState({ showServiceGroupModal: !showServiceGroupModal });
  };

  toggleServiceModal = event => {
    event.preventDefault();
    const { showServiceModal } = this.state;
    this.setState({ showServiceModal: !showServiceModal });
  };

  handleServiceUpdate = async updatedServices => {
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      const updatedServiceResponse = await putServices(
        updatedServices,
        this.cancelTokenSource.token
      );
      if (updatedServiceResponse && Array.isArray(updatedServiceResponse)) {
        const { serviceTable } = this.state;
        const { rows } = serviceTable;
        for (let i = 0; i < updatedServiceResponse.length; i += 1) {
          const service = updatedServiceResponse[i];
          const index = _.findIndex(rows, { id: service.id });
          rows[index] = { ...rows[index], ...service };
        }
        this.setState({ serviceTable });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while updating services!");
        console.log(err);
      }
    }
  };

  handleServiceGroupUpdate = async updatedServiceGroups => {
    this.cancelTokenSource = axios.CancelToken.source();
    try {
      const updatedServiceGroupsResponse = await putServiceGroups(
        updatedServiceGroups,
        this.cancelTokenSource.token
      );
      if (
        updatedServiceGroupsResponse &&
        Array.isArray(updatedServiceGroupsResponse)
      ) {
        const { serviceTable, serviceGroupTable } = this.state;
        const { rows } = serviceGroupTable;
        for (let i = 0; i < updatedServiceGroupsResponse.length; i += 1) {
          const serviceGroup = updatedServiceGroupsResponse[i];
          const index = _.findIndex(rows, { id: serviceGroup.id });
          rows[index] = { ...rows[index], ...serviceGroup };
        }
        serviceTable.columns = this.getServiceTableColumns(
          serviceGroupTable.rows
        );
        serviceTable.key = uuidv1();
        serviceGroupTable.key = uuidv1();
        this.setState({ serviceTable, serviceGroupTable });
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while updating service groups!");
        console.log(err);
      }
    }
  };

  // Service Group
  getServiceGroupTableColumns = () => {
    return [
      {
        key: "id",
        name: "#",
        width: 80
      },
      {
        key: "name",
        name: "Name",
        editable: true,
        filterable: false,
        sortable: false
      },
      // {
      //   key: "description",
      //   name: "Description",
      //   editable: true
      // },
      // {
      //   key: "price",
      //   name: "Price",
      //   width: 100,
      //   editable: true
      // },
      {
        key: "status",
        name: "Status",
        width: 100,
        filterable: false,
        editable: true,
        editor: StatusEditor
      }
    ];
  };

  getServiceGroupTableRow = i => {
    const { serviceGroupTable } = this.state;
    return serviceGroupTable.rows[i];
  };

  onServiceGroupTableGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const { serviceGroupTable } = this.state;
    const { rows } = serviceGroupTable;
    let serviceGroups = [];
    for (let i = fromRow; i <= toRow; i += 1) {
      const updatedRow = { ...rows[i], ...updated };
      if (!_.isEqual(rows[i], updatedRow)) {
        serviceGroups = [...serviceGroups, updatedRow];
      }
    }
    if (serviceGroups.length > 0) {
      this.handleServiceGroupUpdate(serviceGroups);
    }
  };

  // Service
  getServiceTableColumns = ({ serviceGroupsIn }) => {
    let serviceGroups = serviceGroupsIn;
    if (!serviceGroups) {
      const { serviceGroupTable } = this.state;
      serviceGroups = serviceGroupTable.rows;
    }
    const serviceGroupOptions = _.map(serviceGroups, serviceGroup => {
      return {
        id: serviceGroup.id,
        value: serviceGroup.id,
        title: serviceGroup.name,
        text: serviceGroup.name
      };
    });
    const serviceGroupHashMap = _.reduce(
      serviceGroups,
      function(hashMap, serviceGroup) {
        hashMap[serviceGroup.id] = serviceGroup.name;
        return hashMap;
      },
      {}
    );
    const ServiceGroupEditor = <DropDownEditor options={serviceGroupOptions} />;
    const ServiceGroupFormatter = ({ value }) => {
      return (
        <div title={serviceGroupHashMap[value]}>
          {serviceGroupHashMap[value]}
        </div>
      );
    };
    return [
      {
        key: "id",
        name: "#",
        width: 80
      },
      {
        key: "name",
        name: "Name",
        editable: true,
        filterable: false,
        sortable: false
      },
      // {
      //   key: "description",
      //   name: "Description",
      //   editable: true
      // },
      {
        key: "service_group_id",
        name: "Service Group",
        width: 200,
        editable: true,
        filterable: false,
        editor: ServiceGroupEditor,
        formatter: ServiceGroupFormatter
        // ({ value }) => {
        //   ServiceGroupFormatter(value, serviceGroupHashMap);
        // }
      },
      {
        key: "price",
        name: "Price",
        width: 150,
        editable: true
      },
      {
        key: "status",
        name: "Status",
        width: 100,
        // editable: true,
        filterable: false,
        editor: StatusEditor
      }
    ];
  };

  getServiceTableRow = i => {
    const { serviceTable } = this.state;
    return serviceTable.rows[i];
  };

  onServiceTableGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const { serviceTable } = this.state;
    const { rows } = serviceTable;
    let services = [];
    const { price } = updated;
    const rowUpdate = updated;
    if (price) {
      rowUpdate.price = price.replace(",", ".");
    }
    for (let i = fromRow; i <= toRow; i += 1) {
      const updatedRow = { ...rows[i], ...rowUpdate };
      if (!_.isEqual(rows[i], updatedRow)) {
        services = [...services, updatedRow];
      }
    }
    if (services.length > 0) {
      this.handleServiceUpdate(services);
    }
  };

  // Actions
  renderServiceGroupActions() {
    return (
      <div className="flex mr-3">
        <button
          id="btnAddServiceGroup"
          type="button"
          className="btn-sm btn-gold ml-3"
          onClick={event => this.toggleServiceGroupModal(event)}
        >
          <FaPlus>Add</FaPlus>
        </button>
        <button
          id="btnRefreshServiceGroup"
          type="button"
          className="btn-sm btn-gold ml-3"
          onClick={event => this.handleRefreshButtonClick(event)}
        >
          <FaSync>Refresh</FaSync>
        </button>
      </div>
    );
  }

  renderServiceActions() {
    return (
      <div className="flex mr-3">
        <button
          id="btnAddService"
          type="button"
          className="btn-sm btn-gold ml-3"
          onClick={event => this.toggleServiceModal(event)}
        >
          <FaPlus>Add</FaPlus>
        </button>
        <button
          id="btnRefreshService"
          type="button"
          className="btn-sm btn-gold ml-3"
          onClick={event => this.handleRefreshButtonClick(event)}
        >
          <FaSync>Refresh</FaSync>
        </button>
      </div>
    );
  }

  handleServiceGroupFormChange = event => {
    if (event) event.preventDefault();
    const field = event.target.id;
    const { newServiceGroup } = this.state;
    newServiceGroup[field] = event.target.value;
    this.setState({ newServiceGroup });
  };

  handleServiceFormChange = event => {
    if (event) event.preventDefault();
    const field = event.target.id;
    const { newService } = this.state;
    newService[field] =
      field === "price"
        ? event.target.value.replace(",", ".")
        : event.target.value;
    this.setState({ newService });
  };

  // Modals
  renderCreationModals = (showServiceGroupModal, showServiceModal) => {
    const { serviceGroupTable, newService, newServiceGroup } = this.state;
    const ServiceGroupOptions = (
      <React.Fragment>
        {_.map(serviceGroupTable.rows, serviceGroup => (
          <option
            key={`service_group_${serviceGroup.id}`}
            value={serviceGroup.id}
          >
            {serviceGroup.name}
          </option>
        ))}
      </React.Fragment>
    );
    const StatusOptions = (
      <React.Fragment>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Modal
          zIndex={9000}
          isOpen={showServiceGroupModal}
          toggle={this.toggleServiceGroupModal}
          className="modal-dialog--header modal-dialog--success"
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.toggleServiceGroupModal}
            />
            <h4 className="bold-text  modal__title">New service group</h4>
          </div>
          <div className="modal__body">
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  id="name"
                  value={newServiceGroup.name}
                  onChange={this.handleServiceGroupFormChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  id="status"
                  value={newServiceGroup.status}
                  onChange={this.handleServiceGroupFormChange}
                >
                  {StatusOptions}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button onClick={this.cancelNewServiceGroup}>Cancel</Button>
            <Button
              outline
              color="success"
              onClick={this.createNewServiceGroup}
            >
              Create
            </Button>
          </ButtonToolbar>
        </Modal>
        <Modal
          zIndex={9000}
          isOpen={showServiceModal}
          toggle={this.toggleServiceModal}
          className="modal-dialog--header modal-dialog--success"
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.toggleServiceModal}
            />
            <h4 className="bold-text  modal__title">New service</h4>
          </div>
          <div className="modal__body">
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  id="name"
                  value={newService.name}
                  onChange={this.handleServiceFormChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Service Group</Form.Label>
                <Form.Control
                  as="select"
                  id="service_group_id"
                  value={newService.service_group_id}
                  onChange={this.handleServiceFormChange}
                >
                  {ServiceGroupOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  id="price"
                  value={newService.price}
                  min="0"
                  step="any"
                  onChange={this.handleServiceFormChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  id="status"
                  value={newService.status}
                  onChange={this.handleServiceFormChange}
                >
                  {StatusOptions}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <ButtonToolbar className="modal__footer">
            <Button onClick={this.cancelNewService}>Cancel</Button>
            <Button outline color="success" onClick={this.createNewService}>
              Create
            </Button>
          </ButtonToolbar>
        </Modal>
      </React.Fragment>
    );
  };

  // Content
  renderContent = (serviceGroupTable, serviceTable, isLoading) => {
    if (isLoading) {
      return (
        <React.Fragment>
          <div className="container">
            <div className="row">
              <div className="mt-5 ml-auto mr-auto">
                <RotateLoader
                  sizeUnit="px"
                  size={44}
                  color="#85714d"
                  loading={this.state.loading}
                />
              </div>
            </div>
            <div className="row">
              <h3 className="mt-5 ml-auto mr-auto">Loading...</h3>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <StickyContainer>
          <Sticky>
            {({ style, isSticky }) => (
              <div
                style={
                  isSticky
                    ? {
                        ...style,
                        backgroundColor: "#fff",
                        zIndex: 8000,
                        borderBottom: "solid 0.1rem #85714d"
                      }
                    : { ...style, backgroundColor: "#fff", zIndex: 8000 }
                }
              >
                <div className="container my-1">
                  <div className="row mx-0">
                    <h2 className="my-auto mr-auto">Service Groups</h2>
                    {this.renderServiceGroupActions()}
                  </div>
                </div>
              </div>
            )}
          </Sticky>
          <div className="container">
            <div className="col-12 px-0 mb-3">
              <ReactDataGrid
                key={serviceGroupTable.key}
                enableCellSelect
                columns={serviceGroupTable.columns}
                rowsCount={serviceGroupTable.rows.length}
                rowGetter={this.getServiceGroupTableRow}
                onGridRowsUpdated={this.onServiceGroupTableGridRowsUpdated}
                rowHeight={44}
                minHeight={88}
              />
            </div>
            <div id="service_group_table_bottom" />
          </div>
        </StickyContainer>
        <StickyContainer>
          <Sticky>
            {({ style, isSticky }) => (
              <div
                style={
                  isSticky
                    ? {
                        ...style,
                        backgroundColor: "#fff",
                        zIndex: 8000,
                        borderBottom: "solid 0.1rem #85714d"
                      }
                    : { ...style, backgroundColor: "#fff", zIndex: 8000 }
                }
              >
                <div className="container my-1">
                  <div className="row mx-0">
                    <h2 className="my-auto mr-auto">Services</h2>
                    {this.renderServiceActions()}
                  </div>
                </div>
              </div>
            )}
          </Sticky>
          <div className="container">
            <div className="col-12 px-0 mb-3">
              <ReactDataGrid
                key={serviceTable.key}
                enableCellSelect
                columns={serviceTable.columns}
                rowsCount={serviceTable.rows.length}
                rowGetter={this.getServiceTableRow}
                onGridRowsUpdated={this.onServiceTableGridRowsUpdated}
                rowHeight={44}
                minHeight={88}
              />
            </div>
            <div id="service_table_bottom" />
          </div>
        </StickyContainer>
      </React.Fragment>
    );
  };

  render() {
    const {
      isLoading,
      serviceGroupTable,
      serviceTable,
      showServiceGroupModal,
      showServiceModal
    } = this.state;
    const Modals = this.renderCreationModals(
      showServiceGroupModal,
      showServiceModal
    );
    const Content = this.renderContent(
      serviceGroupTable,
      serviceTable,
      isLoading
    );
    return (
      <React.Fragment>
        <div className="container mt-1 mb-3">
          <div className="row mx-0">
            <h1 className="mr-auto">Manage Service Groups & Services</h1>
          </div>
        </div>
        {Content}
        {Modals}
      </React.Fragment>
    );
  }
}

export default withRouter(Services);
