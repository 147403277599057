/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import * as authenticationActions from "../actions/authenticationActions";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = { credentials: { username: "", password: "" } };
  }

  validateForm() {
    return this.state.credentials.username.length > 0 && this.state.credentials.password.length > 0;
  }

  handleChange = event => {
    const field = event.target.id;
    const { credentials } = this.state;
    credentials[field] = event.target.value;
    this.setState({ credentials });
  };

  handleSubmit = async event => {
    event.preventDefault();
    await this.props.actions.logIn(this.state.credentials, this.props.history);
  };

  render() {
    return (
      <div className="container align-self-center">
        <div className="d-flex flex-row justify-content-center mt-2">
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="username">
              <FormControl
                autoFocus
                type="text"
                value={this.state.credentials.username}
                onChange={this.handleChange}
                placeholder="Username"
              />
            </FormGroup>
            <FormGroup controlId="password">
              <FormControl
                type="password"
                value={this.state.credentials.password}
                onChange={this.handleChange}
                placeholder="Password"
              />
            </FormGroup>
            <div className="text-center">
              <Button className="btn btn-primary btn-gold mx-auto" disabled={!this.validateForm()} type="submit">
                Log in
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authenticationActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(Landing));
