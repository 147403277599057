/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "react-phone-number-input/style.css";
import "../scss/app.scss";
import "../style";

import NotFound from "../containers/DefaultPage/404/index";
import Header from "./Header";
import Landing from "./Landing";
import FlightsDetail from "./flights/FlightsDetail";
import FlightsDashboard from "./flights/FlightsDashboard";
import FlightsCalendar from "./flights/FlightsCalendar";
// import HandlingRequestInternal from "./flights/HandlingRequestInternal";
import HandlingRequestInternalV2 from "./flights/HandlingRequestInternalV2";
// import HandlingRequestExternal from "./flights/HandlingRequestExternal";
// import HandlingRequestAvailableSoon from "./flights/HandlingRequestAvailableSoon";
import HandlingRequest from "../containers/HandlingRequest/index";
// import HandlingRequestSoon from "../containers/HandlingRequest/soon";
import UserProfile from "./UserProfile";
import TechnicalSupport from "./TechnicalSupport";
import ManagementUsers from "./management/Users";
import ManagementServices from "./management/Services";
import { getMyUser } from "../api/usersApi";
import { USERS_UPDATE_ME } from "../actions/actionTypes";
import Roles from "../enums/roles";
import { changeThemeToLight } from "../redux/actions/themeActions";

require("jquery");
require("bootstrap");

class App extends Component {
  constructor(props) {
    super(props);
    this.cancelTokenSource = null;
    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      this.changeToLight();
      if (!this.props.user) {
        this.cancelTokenSource = axios.CancelToken.source();
        const user = await getMyUser(this.cancelTokenSource.token);
        if (user) {
          await this.props.dispatch({ type: USERS_UPDATE_ME, payload: user });
          this.setState({ isLoading: false });
        }
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log("Something went wrong while loading user!");
        console.log(err);
        this.setState({ isLoading: false });
      }
    }
  }

  componentWillUnmount() {
    this.cancelTokenSource.cancel("App will unmount.");
  }

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  isAuthenticated() {
    const { user } = this.props;
    return !(user === null || user === undefined);
  }

  hasRole(role) {
    const { user } = this.props;
    return user.role === role;
  }

  renderContent() {
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading && (
          <div className={`load${isLoading ? "" : " loaded"}`}>
            <div className="load__icon-wrap">
              <svg
                className="load__icon animate"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1500 308.178"
              >
                <path
                  className="name"
                  d="M877.254,238.747L849.993,63.041l-27.688,175.706l-10.861,67.088h-48.134L819.111,4.686h62.828l56.865,301.149h-50.688L877.254,238.747z"
                />
                <path
                  className="stroke"
                  d="M979.483,172.299H167.826l59.421,12.565H1276.8l55.374-12.565H979.483z"
                />
                <path
                  className="stroke"
                  d="M979.483,123.313v-0.213H0l62.189,13.205h917.294v-0.213l458.327,0.213L1500,123.101L979.483,123.313z"
                />
                <path
                  className="stroke"
                  d="M917.72,147.806H83.913l62.189,12.992h1207.795l62.189-12.992H917.72z"
                />
                <path
                  className="name"
                  d="M819.111,4.473h62.828l56.865,301.362h-50.901l-10.649-67.088L849.993,62.828 M384.424,304.771V3.407h49.837l63.255,186.355V3.407h45.577v301.363h-47.281l-65.81-195.939v195.939H384.424z M688.556,110.961v-42.17c0-18.954-10.648-25.77-25.344-25.77s-25.345,6.815-25.345,25.77V89.45c0,15.547,5.111,22.362,19.381,30.456l35.354,19.807c28.752,16.399,46.003,29.604,46.003,61.977v43.447c0,46.429-28.752,62.828-74.542,62.828h-0.852c-45.577,0-74.542-15.973-74.542-62.828v-52.393h48.984v45.79c0,18.529,10.649,26.622,25.771,26.622s25.771-8.093,25.771-26.622v-23.854c0-15.547-4.686-23.214-19.807-31.521l-34.716-19.594c-29.178-16.825-46.003-30.456-46.003-61.977V61.977C588.67,17.251,621.468,0,662.785,0h0.853c41.317,0,74.116,17.251,74.116,61.977v48.984H688.556L688.556,110.961z M1121.113,244.498c0,46.429-30.243,63.68-75.82,63.68h-0.852c-45.577,0-75.82-16.825-75.82-63.68V63.68C968.834,18.955,998.864,0,1044.654,0h0.852c45.577,0,75.82,18.955,75.82,63.68v42.596h-50.05V71.347c0-21.084-10.436-27.474-26.196-27.474c-15.76,0-26.196,6.39-26.196,27.474v165.271c0,21.085,10.437,27.475,26.196,27.475c15.761,0,26.196-6.39,26.196-27.475v-38.336h49.837V244.498z"
                />
              </svg>
              <svg
                className="load__icon-flat"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1500 308.178"
              >
                <path
                  className="name"
                  d="M877.254,238.747L849.993,63.041l-27.688,175.706l-10.861,67.088h-48.134L819.111,4.686h62.828l56.865,301.149h-50.688L877.254,238.747z"
                />
                <path
                  className="stroke"
                  d="M979.483,172.299H167.826l59.421,12.565H1276.8l55.374-12.565H979.483z"
                />
                <path
                  className="stroke"
                  d="M979.483,123.313v-0.213H0l62.189,13.205h917.294v-0.213l458.327,0.213L1500,123.101L979.483,123.313z"
                />
                <path
                  className="stroke"
                  d="M917.72,147.806H83.913l62.189,12.992h1207.795l62.189-12.992H917.72z"
                />
                <path
                  className="name"
                  d="M819.111,4.473h62.828l56.865,301.362h-50.901l-10.649-67.088L849.993,62.828 M384.424,304.771V3.407h49.837l63.255,186.355V3.407h45.577v301.363h-47.281l-65.81-195.939v195.939H384.424z M688.556,110.961v-42.17c0-18.954-10.648-25.77-25.344-25.77s-25.345,6.815-25.345,25.77V89.45c0,15.547,5.111,22.362,19.381,30.456l35.354,19.807c28.752,16.399,46.003,29.604,46.003,61.977v43.447c0,46.429-28.752,62.828-74.542,62.828h-0.852c-45.577,0-74.542-15.973-74.542-62.828v-52.393h48.984v45.79c0,18.529,10.649,26.622,25.771,26.622s25.771-8.093,25.771-26.622v-23.854c0-15.547-4.686-23.214-19.807-31.521l-34.716-19.594c-29.178-16.825-46.003-30.456-46.003-61.977V61.977C588.67,17.251,621.468,0,662.785,0h0.853c41.317,0,74.116,17.251,74.116,61.977v48.984H688.556L688.556,110.961z M1121.113,244.498c0,46.429-30.243,63.68-75.82,63.68h-0.852c-45.577,0-75.82-16.825-75.82-63.68V63.68C968.834,18.955,998.864,0,1044.654,0h0.852c45.577,0,75.82,18.955,75.82,63.68v42.596h-50.05V71.347c0-21.084-10.436-27.474-26.196-27.474c-15.76,0-26.196,6.39-26.196,27.474v165.271c0,21.085,10.437,27.475,26.196,27.475c15.761,0,26.196-6.39,26.196-27.475v-38.336h49.837V244.498z"
                />
              </svg>
            </div>
          </div>
        )}
        <Switch>
          <Route exact path="/handling-request" component={HandlingRequest} />
          <Route
            exact
            path="/handling-request-form"
            component={HandlingRequest}
          />
          <Route
            exact
            path="/"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? (
                  <Redirect to="/flights/dashboard" />
                ) : (
                  <Landing />
                )}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/user"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? <UserProfile /> : <Landing />}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/technical-support"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? <TechnicalSupport /> : <Landing />}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/flights/calendar"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? (
                  <FlightsCalendar />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/flights/dashboard"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? (
                  <FlightsDashboard />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/flights/add"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? (
                  <HandlingRequestInternalV2 />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          />
          {/* <Route
            exact
            path="/flights/add/v2"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? (
                  <HandlingRequestInternalV2 />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          /> */}
          <Route
            exact
            path="/flights/:id([0-9]+)"
            render={props => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? (
                  <FlightsDetail {...props} />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/flights"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() ? (
                  <Redirect to="/flights/dashboard" />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/management/users"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() && this.hasRole(Roles.ADMINISTRATOR) ? (
                  <ManagementUsers />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/management/services"
            render={() => (
              <React.Fragment>
                <Header />
                {this.isAuthenticated() && this.hasRole(Roles.ADMINISTRATOR) ? (
                  <ManagementServices />
                ) : (
                  <Redirect to="/" />
                )}
              </React.Fragment>
            )}
          />
          <Route path="/" component={NotFound} />
        </Switch>
      </React.Fragment>
    );
  }

  render() {
    return (
      <BrowserRouter>
        <div className="flex flex-column vh-100">{this.renderContent()}</div>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.authentication.user };
}

export default connect(mapStateToProps)(App);
