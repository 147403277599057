/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
import React, { Component } from "react";
import { Button, ButtonToolbar, Form, FormGroup, Row } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import Textarea from "react-textarea-autosize";
import ServiceCheckboxContainer from "../../../shared/components/ServiceCheckboxContainer";

class HandlingRequestFormsServices extends Component {
  static propTypes = {
    serviceGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    services: PropTypes.arrayOf(PropTypes.object).isRequired,
    showNext: PropTypes.func.isRequired,
    showPrevious: PropTypes.func.isRequired,
    updateHandlingRequest: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      checkedServices: {},
      other: "",
      preventContinue: false
    };
  }

  handleChangeEvent = event => {
    event.preventDefault();
    const { target } = event;
    const { name, value } = target;
    // console.log(`name: ${name}`);
    // console.log(`value: ${value}`);
    this.setState({ [name]: value, preventContinue: false }, () => {
      const { updateHandlingRequest } = this.props;
      updateHandlingRequest(name, value);
    });
  };

  handleServiceChange = (serviceGroupId, serviceIds) => {
    const { checkedServices } = this.state;
    checkedServices[serviceGroupId] = serviceIds;
    this.setState({ checkedServices, preventContinue: false }, () => {
      const { updateHandlingRequest } = this.props;
      updateHandlingRequest("services", checkedServices);
    });
  };

  validate = () => {
    const { checkedServices } = this.state;

    let isValid = false;
    const message = "Please select your desired services and arrangements.";

    if (checkedServices) {
      const values = _.values(checkedServices);
      const flattenValues = _.flattenDeep(values);
      if (flattenValues && Array.isArray(flattenValues)) {
        // console.log("flattenValues is array");
        isValid = flattenValues.length > 0;
        // console.log(isValid);
      }
    }

    if (!isValid) {
      const color = "danger";
      const { showNotification } = this.props;
      showNotification(message, color);
    }
    this.setState({ preventContinue: !isValid });
    return isValid;
  };

  continue = () => {
    // const isValid = this.validate();
    const isValid = true;
    if (isValid) {
      const { showNext } = this.props;
      showNext();
    }
  };

  renderServiceComponent() {
    const { serviceGroups, services } = this.props;
    const filteredServiceGroups = _.filter(serviceGroups, serviceGroup => serviceGroup.status === "active");
    if (filteredServiceGroups && filteredServiceGroups.length > 0) {
      return _.map(filteredServiceGroups, serviceGroup => {
        // const checkedServices = handlingRequest
        //   ? handlingRequest.services[serviceGroup.id]
        //   : [];
        const filteredServices = _.filter(services, service => service.service_group_id === serviceGroup.id);
        const orderedFilteredServices = _.sortBy(filteredServices, "name");
        return (
          <div key={`sg-${serviceGroup.id}`} className="mb-4">
            <div className="handling-request-wizard__form-section__title">{serviceGroup.name}</div>
            <ServiceCheckboxContainer
              serviceGroup={serviceGroup}
              services={orderedFilteredServices}
              onChange={this.handleServiceChange}
              disabled={false}
            />
          </div>
        );
      });
    }
    return null;
  }

  render() {
    const { showPrevious, serviceGroups, services } = this.props;
    const { other, preventContinue } = this.state;

    return (
      <Form className="handling-request-wizard__form">
        {/* <div className="handling-request-wizard__form-section__header">Flight Scheduling</div> */}

        {this.renderServiceComponent(serviceGroups, services)}

        <div className="handling-request-wizard__form-section__title">Other services, arrangements, or requests</div>

        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12">
            <Textarea
              key="other"
              name="other"
              // maxRows={5}
              maxLength={1500}
              value={other}
              onChange={this.handleChangeEvent}
              placeholder="Enter any other requests you may have"
              className="form-control handling-request-wizard__form-control handling-request-wizard__form-control-textarea"
            />
          </FormGroup>
        </Row>

        <ButtonToolbar className="form__button-toolbar handling-request-wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={() => showPrevious()}>
            Previous
          </Button>

          <Button
            color="primary"
            type="button"
            className="next ml-auto"
            onClick={() => this.continue()}
            disabled={preventContinue}
          >
            Next
          </Button>
        </ButtonToolbar>
      </Form>
    );
  }
}

export default HandlingRequestFormsServices;
