import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class TechnicalSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="container mt-1">
          <div className="row mx-0">
            <h1 className="mr-auto">Technical Support</h1>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row mb-2">
            <h3 className="col-3">Contact Name</h3>
            <h3>De Wilde, Thomas</h3>
          </div>
          <div className="row mb-2">
            <h3 className="col-3">Email Address</h3>
            <h3>
              <a href="mailto:thomas@ictor.be">thomas@ictor.be</a>
            </h3>
          </div>
          <div className="row mb-2">
            <h3 className="col-3">Phone</h3>
            <h3>
              <a className="gold" href="tel:+32472591687">
                +32 472 59 16 87
              </a>
            </h3>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TechnicalSupport);
