/* eslint-disable react/forbid-prop-types */
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Checkbox from "../../../shared/components/Checkbox";

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = e => {
    const { checked } = e.target;
    this.setState({ checked });
    const { onChange } = this.props;
    onChange(checked);
  };

  render() {
    const { label } = this.props;
    const { checked } = this.state;
    const uniqueId = moment.unix();
    return (
      <Checkbox
        key={`check-${uniqueId}`}
        id={`id-${uniqueId}`}
        group={`group-${uniqueId}`}
        label={label}
        checked={checked}
        onChange={this.handleChange}
        disabled={false}
        containerClassNames="px-0"
        inputClassNames="form-check-checkbox"
        labelClassNames="form-check-label ml-2"
      />
    );
  }
}

CheckboxContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};

export default CheckboxContainer;
