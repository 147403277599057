// reducers/serviceGroupsReducer.js

import { SERVICE_GROUPS_ADD, SERVICE_GROUPS_UPDATE_ALL, LOG_OUT } from "../actions/actionTypes";
import initialState from "./initialState";

// function updateObjectInArray(array, action) {
//   return array.map((item, index) => {
//     if (item.id !== action.payload.id) {
//       // This isn't the item we care about - keep it as-is
//       return item;
//     }

//     // Otherwise, this is the one we want - return an updated value
//     return {
//       ...item,
//       ...action.payload
//     };
//   });
// }

// function deleteObjectInArray(array, id) {
//   return array.filter(item => item.id !== id);
// }

// function insertObjectInArray(array, newItem) {
//   return [...array, newItem];
// }

export default function(state = initialState.service_groups, action) {
  let newState;
  switch (action.type) {
    case SERVICE_GROUPS_ADD:
      newState = [...state, action.payload];
      return newState;
    case SERVICE_GROUPS_UPDATE_ALL:
      newState = action.payload;
      return newState;
    case LOG_OUT:
      return initialState.service_groups;
    default:
      return state;
  }
}
