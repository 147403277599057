/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import Clock from "react-live-clock";
import * as authenticationActions from "../actions/authenticationActions";
import Roles from "../enums/roles";

class Header extends Component {
  logOut = event => {
    event.preventDefault();
    this.props.actions.logOut(this.props.history);
  };

  renderClock() {
    // eslint-disable-next-line max-len
    return (
      <Clock
        className="m-auto mr-md-0 my-none clock"
        format="dddd Do MMMM YYYY, HH:mm:ss"
        ticking
        timezone="Europe/Brussels"
      />
    );
  }

  renderUserMenu(user) {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="userMenu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {user.display_name}
        </a>
        <div className="dropdown-menu" aria-labelledby="userMenu">
          <Link className="dropdown-item" to="/user">
            User Profile
          </Link>
          <div className="dropdown-divider" />
          <Link className="dropdown-item" onClick={this.logOut} to="/">
            Log Out
          </Link>
        </div>
      </li>
    );
  }

  renderUserMenuForInternal(user) {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="userMenu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {user.display_name}
        </a>
        <div className="dropdown-menu ml-n5" aria-labelledby="userMenu">
          <Link className="dropdown-item" to="/user">
            User Profile
          </Link>
          <Link className="dropdown-item" to="/technical-support">
            Technical Support
          </Link>
          <div className="dropdown-divider" />
          <Link className="dropdown-item" onClick={this.logOut} to="/">
            Log Out
          </Link>
        </div>
      </li>
    );
  }

  renderNavigationMenu() {
    const { user } = this.props.authentication;
    let navigationMenu = this.renderNavigationMenuForNone();
    let userMenu = null;
    if (user) {
      switch (user.role) {
        case Roles.ADMINISTRATOR:
          userMenu = this.renderUserMenuForInternal(user);
          navigationMenu = this.renderNavigationMenuForInternal(userMenu);
          break;
        // case Roles.ADMINISTRATOR:
        // case Roles.NAVIGATION_OFFICE:
        // case Roles.NONE:
        //   navigationMenu = this.renderNavigationMenuForExternal(userMenu, user.role);
        //   break;
        default:
          userMenu = this.renderUserMenu(user);
          navigationMenu = this.renderNavigationMenuForExternal(userMenu, user.role);
          break;
      }
    }
    return navigationMenu;
  }

  renderNavigationMenuForInternal(userMenu) {
    return (
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="flightsMenu"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Flights
            </a>
            <div className="dropdown-menu" aria-labelledby="flightsMenu">
              <Link className="dropdown-item" to="/flights/dashboard">
                Dashboard
              </Link>
              <Link className="dropdown-item" to="/flights/calendar">
                Calendar
              </Link>
              <Link className="dropdown-item" to="/flights/add">
                Add flight
              </Link>
              {/* <Link className="dropdown-item" to="/flights/add/v2">
                Add flight V2
              </Link> */}
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="managementMenu"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Management
            </a>
            <div className="dropdown-menu" aria-labelledby="managementMenu">
              <Link className="dropdown-item" to="/management/services">
                Services
              </Link>
              <Link className="dropdown-item" to="/management/users">
                Users
              </Link>
            </div>
          </li>
          <span className="border-top border-right my-3 my-md-2 mx-0 mx-md-2 border-gold" />
          {userMenu}
        </ul>
      </div>
    );
  }

  renderNavigationMenuForExternal(userMenu, userRole) {
    return (
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="flightsMenu"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Flights
            </a>
            <div className="dropdown-menu" aria-labelledby="flightsMenu">
              <Link className="dropdown-item" to="/flights/dashboard">
                Dashboard
              </Link>
              {userRole === Roles.ADMINISTRATOR || userRole === Roles.NAVIGATION_OFFICE ? (
                <Link className="dropdown-item" to="/flights/add">
                  Add flight
                </Link>
              ) : null}
            </div>
          </li>
          {userMenu}
        </ul>
      </div>
    );
  }

  renderNavigationMenuForNone() {
    return <h3 className="m-auto mr-md-0 my-none">NSAC Webportal</h3>;
  }

  renderBrand() {
    const { user } = this.props.authentication;
    let brand = (
      <div className="col-auto mx-auto navbar-expand-md">
        <a className="navbar-brand" href="/">
          <img src="/assets/brand/nsac.svg" height="60px" alt="" />
        </a>
      </div>
    );
    if (user) {
      brand = (
        <div className="col-auto navbar-expand-md">
          <div className="row">
            <div className="col-auto mx-auto">
              <a className="navbar-brand mx-auto" href="/">
                <img src="/assets/brand/nsac.svg" height="60px" alt="" />
              </a>
            </div>
            <div className="col-auto mx-auto my-auto py-1">
              <button
                className="navbar-toggler mb-0 mx-auto mt-auto"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
            </div>
          </div>
        </div>
      );
    }
    return brand;
  }

  render() {
    const { pathname } = this.props.location;
    if (pathname === "/handling-request" || pathname === "/handling-request/form") {
      return null;
    }
    return (
      <div className="container border-bottom border-gold p-3 mb-3">
        <div className="row">
          {this.renderBrand()}
          <div className="col">
            <div className="row">
              <div className="col-auto mx-auto mr-md-0 my-1">{this.renderClock()}</div>
            </div>
            <div className="row">
              <div className="col mx-auto mr-md-0">
                <nav className="header navbar navbar-expand-md navbar-light">{this.renderNavigationMenu()}</nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { authentication: state.authentication };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authenticationActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
