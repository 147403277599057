/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import PropTypes from "prop-types";
import _ from "lodash";
import React, { Component } from "react";
import { Button, ButtonToolbar, Row, FormGroup } from "reactstrap";
import Dropzone from "react-dropzone";

class HandlingRequestFormDocuments extends Component {
  static propTypes = {
    showNext: PropTypes.func.isRequired,
    showPrevious: PropTypes.func.isRequired,
    updateHandlingRequest: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      documents: {
        generalDeclaration: [],
        noiseCertificate: []
      }
    };
  }

  onDropAccepted(droppedFiles, key) {
    if (droppedFiles && droppedFiles.length > 1) {
      const message = (
        <div>
          <p>Only one file is allowed.</p>
          <p>Please combine your files into a single pdf-file.</p>
        </div>
      );
      const { showNotification } = this.props;
      showNotification(message, "danger");
    } else {
      const { documents } = this.state;
      documents[key] = droppedFiles;
      this.setState({ documents });
      const { updateHandlingRequest } = this.props;
      updateHandlingRequest("documents", documents);
    }
  }

  onDropRejected = rejectedFiles => {
    if (rejectedFiles && Array.isArray(rejectedFiles)) {
      let message;
      const color = "danger";
      if (rejectedFiles.length > 1) {
        message = (
          <div>
            <p>Some files were rejected:</p>
            <ul>
              {_.map(rejectedFiles, rf => {
                return <li>{rf.name}</li>;
              })}
            </ul>
            <p>Please make sure individual file sizes do not exceed 3Mb.</p>
          </div>
        );
      } else {
        message = (
          <div>
            <p>File {rejectedFiles[0].name} was rejected.</p>
            <p>Please make sure file size does not exceed 3Mb.</p>
          </div>
        );
      }
      const { showNotification } = this.props;
      showNotification(message, color);
    }
  };

  removeFile = (fileName, key, e) => {
    e.preventDefault();
    const { documents } = this.state;
    documents[key] = _.filter(documents[key], f => f.name !== fileName);
    this.setState({ documents });
    const { updateHandlingRequest } = this.props;
    updateHandlingRequest("documents", documents);
  };

  renderFilePreview = (file, key) => {
    const { type } = file || "";
    let filePreview;
    switch (type) {
      case "application/pdf":
        filePreview = (
          <div className="dropzone__pdf" key={file.name}>
            <span className="dropzone__pdf-title">{file.name}</span>
            <p className="dropzone__pdf-name">{file.name}</p>
            <button className="dropzone__pdf-delete" type="button" onClick={e => this.removeFile(file.name, key, e)}>
              Remove
            </button>
          </div>
        );
        break;
      case "image/png":
      case "image/jpeg":
        filePreview = (
          <div className="dropzone__img" key={file.name} style={{ backgroundImage: `url(${file.preview})` }}>
            <p className="dropzone__img-name">{file.name}</p>
            <button className="dropzone__img-delete" type="button" onClick={e => this.removeFile(file.name, key, e)}>
              Remove
            </button>
          </div>
        );
        break;
      default:
        filePreview = null;
        break;
    }
    return filePreview;
  };

  render() {
    const { showPrevious, showNext } = this.props;
    const { documents } = this.state;
    const { generalDeclaration, noiseCertificate } = documents;

    return (
      <div className="handling-request-wizard__form handling-request-wizard__form-review">
        <div className="handling-request-wizard__form-section__title">
          Handling Request Documents
          <span className="description">
            Please include a{" "}
            <a href="/assets/documents/general_declaration_nsac.pdf" target="_blank" download="general_declaration.pdf">
              <u>general declaration</u>
            </a>
            , and a <u>noise certificate</u> in your request.
          </span>
        </div>

        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <h4 className="font-weight-bold mb-1">General Declaration</h4>
            <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
              <Dropzone
                className="dropzone__input"
                accept="application/pdf"
                name="generalDeclaration"
                maxSize={3000000}
                onDropAccepted={droppedFiles => this.onDropAccepted(droppedFiles, "generalDeclaration")}
                onDropRejected={this.onDropRejected}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone__input">
                    {(!generalDeclaration || generalDeclaration.length === 0) && (
                      <div className="dropzone__drop-here">
                        <span className="lnr lnr-upload" />
                        <span className="message">
                          {/* <br />
                          Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
                          <br />
                        </span>
                      </div>
                    )}
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
              {generalDeclaration && (
                <div className="dropzone__imgs-wrapper">
                  {_.map(generalDeclaration, f => this.renderFilePreview(f, "generalDeclaration"))}
                </div>
              )}
            </div>
          </FormGroup>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <h4 className="font-weight-bold mb-1">Noise Certificate</h4>
            <div className="dropzone dropzone--multiple handling-request-wizard__dropzone">
              <Dropzone
                className="dropzone__input"
                accept="application/pdf"
                name="noiseCertificate"
                maxSize={3000000}
                onDropAccepted={droppedFiles => this.onDropAccepted(droppedFiles, "noiseCertificate")}
                onDropRejected={this.onDropRejected}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone__input">
                    {(!noiseCertificate || noiseCertificate.length === 0) && (
                      <div className="dropzone__drop-here">
                        <span className="lnr lnr-upload" />
                        <span className="message">
                          {/* <br />
                  Make sure the file name is equal to 'general_declaration' or 'noise_certificate'. */}
                          <br />
                        </span>
                      </div>
                    )}
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
              {noiseCertificate && (
                <div className="dropzone__imgs-wrapper">
                  {_.map(noiseCertificate, f => this.renderFilePreview(f, "noiseCertificate"))}
                </div>
              )}
            </div>
          </FormGroup>
        </Row>

        <ButtonToolbar className="form__button-toolbar handling-request-wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={() => showPrevious()}>
            Previous
          </Button>

          <Button color="primary" type="button" className="next ml-auto" onClick={() => showNext()}>
            Next
          </Button>
        </ButtonToolbar>
      </div>
    );
  }
}

export default HandlingRequestFormDocuments;
