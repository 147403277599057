/* eslint-disable func-names */
import axios from "axios";
import _ from "lodash";
// import flightSchema from "../schemas/flightSchema";

export const getAllFlights = async cancelToken => {
  const res = await axios.get("/api/flights/all", { cancelToken });
  if (res) {
    return _.map(res.data, data => {
      // const flight = flightSchema.parse(data);
      return data;
    });
  }
  return null;
};

export const createFlight = async function(flight, cancelToken) {
  const res = await axios.post("/api/flights", flight, {
    cancelToken
  });
  if (res && res.status === 200) {
    return res.data;
  }
  return null;
};

export const updateFlight = async function(flight, droppedDocuments, cancelToken) {
  try {
    // console.log("updateFlight");
    // console.log(flight);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      cancelToken
    };
    const formData = new FormData();
    // JSON content
    formData.append("handlingRequest", JSON.stringify(flight.handling_request));
    formData.append("fees", JSON.stringify(flight.fees || {}));
    formData.append("status", flight.status);
    formData.append("documents", JSON.stringify(flight.documents || {}));
    // Documents
    if (droppedDocuments) {
      // console.log("droppedDocuments");
      // console.log(droppedDocuments);
      const { droppedGeneralDeclaration, droppedNoiseCertificate } = droppedDocuments;
      if (
        droppedGeneralDeclaration &&
        Array.isArray(droppedGeneralDeclaration) &&
        droppedGeneralDeclaration.length > 0
      ) {
        // console.log("generalDeclaration");
        // console.log(generalDeclaration);
        let i;
        for (i = 0; i < droppedGeneralDeclaration.length; i += 1) {
          formData.append(`generalDeclaration_${i}`, droppedGeneralDeclaration[i]);
        }
      }
      if (droppedNoiseCertificate && Array.isArray(droppedNoiseCertificate) && droppedNoiseCertificate.length > 0) {
        // console.log("noiseCertificate");
        // console.log(noiseCertificate);
        let i;
        for (i = 0; i < droppedNoiseCertificate.length; i += 1) {
          formData.append(`noiseCertificate_${i}`, droppedNoiseCertificate[i]);
        }
      }
    }
    // console.log("formData");
    // console.log(formData);
    const res = await axios.put(`/api/flights/${flight.id}`, formData, config);
    if (res && res.status === 200) {
      return res.data;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getFlightById = async function(flightId, cancelToken) {
  const res = await axios.get(`/api/flights/${flightId}`, {
    cancelToken
  });
  if (res && res.status === 200) {
    // console.log("getById");
    // console.log(res.data);
    return res.data;
  }
  return null;
};
