/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React, { Component } from "react";
import { Button, ButtonToolbar, Row, Form, FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import moment from "moment";
import _ from "lodash";
import Airports from "../../../data/airports_openflights";

class HandlingRequestFormFlightScheduling extends Component {
  static propTypes = {
    showNext: PropTypes.func.isRequired,
    showPrevious: PropTypes.func.isRequired,
    updateHandlingRequest: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      today: moment()
        .utc()
        .startOf("day"),
      thisYear: moment().year(),
      thisMonth: moment().month(),
      thisDay: moment().date(),
      checked: false,
      airportSuggestions: [],
      airportOfOrigin: "",
      eta: {
        day: "",
        month: "",
        year: "",
        hour: "",
        minute: ""
      },
      airportOfDestination: "",
      etd: {
        day: "",
        month: "",
        year: "",
        hour: "",
        minute: ""
      },
      preventContinue: true
    };

    this.lastSuggestionRequestId = null;
  }

  isValidIcao = icao => {
    if (icao) {
      const upperIcao = icao.toUpperCase();
      return (
        _.findIndex(Airports, airport => {
          return airport.icao === upperIcao;
        }) >= 0
      );
    }
    return false;
  };

  validate = () => {
    const { today, airportOfOrigin, eta, airportOfDestination, etd } = this.state;

    const invalidProperties = [];

    const airportOfOriginIsNotEmpty = airportOfOrigin;
    const airportOfOriginIsValid = this.isValidIcao(airportOfOrigin);
    const etaMoment = this.parseEstimatedTime(eta);
    const etaIsValid = etaMoment && etaMoment.isValid();
    const etaIsNotInThePast = etaIsValid ? etaMoment.startOf("day").isSameOrAfter(today) : true;

    if (airportOfOriginIsNotEmpty && !airportOfOriginIsValid) {
      invalidProperties.push(this.createInvalidPropertyObject("Airport of Origin", "Invalid ICAO code."));
    }
    if (airportOfOriginIsNotEmpty && airportOfOriginIsValid) {
      if (!etaIsValid) {
        invalidProperties.push(this.createInvalidPropertyObject("Estimated Time of Arrival", "Invalid date."));
      } else if (!etaIsNotInThePast) {
        invalidProperties.push(this.createInvalidPropertyObject("Estimated Time of Arrival", "Date is in the past."));
      }
    }

    const airportOfDestinationIsNotEmpty = airportOfDestination;
    const airportOfDestinationIsValid = this.isValidIcao(airportOfDestination);
    const etdMoment = this.parseEstimatedTime(etd);
    const etdIsValid = etdMoment && etdMoment.isValid();
    const etdIsNotInThePast = etdIsValid ? etdMoment.startOf("day").isSameOrAfter(today) : true;

    if (airportOfDestinationIsNotEmpty && !airportOfDestinationIsValid) {
      invalidProperties.push(this.createInvalidPropertyObject("Airport of Destination", "Invalid ICAO code."));
    }
    if (airportOfDestinationIsNotEmpty && airportOfDestinationIsValid) {
      if (!etdIsValid) {
        invalidProperties.push(this.createInvalidPropertyObject("Estimated Time of Departure", "Invalid date."));
      } else if (!etdIsNotInThePast) {
        invalidProperties.push(this.createInvalidPropertyObject("Estimated Time of Departure", "Date is in the past."));
      }
    }

    let isValid = false;
    if (!airportOfDestinationIsNotEmpty && !airportOfOriginIsNotEmpty) {
      const { showNotification } = this.props;
      showNotification("Please fill in the arrival and/or departure fields", "danger");
    } else {
      isValid = invalidProperties.length === 0;
      if (!isValid) {
        this.notifyAboutInvalidProperties(invalidProperties);
      }
    }

    this.setState({ preventContinue: !isValid });
    return isValid;
  };

  createInvalidPropertyObject = (name, reason, priority) => {
    return { name, reason, priority: priority || 1 };
  };

  notifyAboutInvalidProperties = invalidProperties => {
    const color = "danger";
    const message = (
      <div>
        <p>The following data is invalid :</p>
        <ul>
          {_.map(invalidProperties, prop => {
            return (
              <li>
                <strong>{prop.name} </strong>
                <br />
                <small>{prop.reason}</small>
              </li>
            );
          })}
        </ul>
      </div>
    );
    const { showNotification } = this.props;
    showNotification(message, color);
  };

  continue = () => {
    const isValid = this.validate();
    if (isValid) {
      const { showNext } = this.props;
      showNext();
    }
  };

  handleChange = (name, value) => {
    // console.log(`name: ${name}`);
    // console.log(`value: ${value}`);
    this.setState({ [name]: value.trimLeft(), preventContinue: false }, () => {
      const { updateHandlingRequest } = this.props;
      updateHandlingRequest(name, value);
    });
  };

  handleChangeEvent = event => {
    event.preventDefault();
    const { target } = event;
    const { name, value } = target;
    // console.log(`name: ${name}`);
    // console.log(`value: ${value}`);
    this.setState({ [name]: value.trimLeft(), preventContinue: false }, () => {
      const { updateHandlingRequest } = this.props;
      updateHandlingRequest(name, value);
    });
  };

  handleChangeEventForDate = event => {
    event.preventDefault();
    const { target } = event;
    const { id, name, value } = target;
    const isEta = id.startsWith("eta");
    const { eta, etd } = this.state;
    const et = isEta ? eta : etd;
    et[name] = value;

    const date = this.parseEstimatedTime(et);
    const unix = date.isValid ? date.unix() : 0;
    const { updateHandlingRequest } = this.props;

    if (isEta) {
      // console.log(`updating eta ${unix}`);
      this.setState({ eta: et, preventContinue: false }, () => {
        updateHandlingRequest("eta", unix);
      });
    } else {
      // console.log(`updating etd ${unix}`);
      this.setState({ etd: et, preventContinue: false }, () => {
        updateHandlingRequest("etd", unix);
      });
    }
  };

  parseEstimatedTime = et => {
    return et
      ? moment.utc([
          et.year,
          // cfr. https://momentjs.com/docs/#/parsing/array/
          // `Note: Because this mirrors the native Date parameters, months, hours, minutes, seconds, and milliseconds are all zero indexed. Years and days of the month are 1 indexed.`
          parseInt(et.month, 10) - 1,
          et.day,
          et.hour,
          et.minute
        ])
      : undefined;
  };

  updateAirportSuggestions = searchString => {
    const filteredAirports = this.filterAirportSuggestions(searchString);
    this.setState({
      airportSuggestions: filteredAirports
    });
  };

  filterAirportSuggestions = value => {
    if (value === undefined) return [];
    const inputValue = value.trim().toLowerCase() || "";
    const inputLength = inputValue.length;
    let suggestions = [];
    if (inputLength >= 0) {
      suggestions = _.filter(
        Airports,
        airport =>
          airport.icao.toLowerCase().slice(0, inputLength) === inputValue ||
          airport.country.toLowerCase().slice(0, inputLength) === inputValue ||
          airport.city.toLowerCase().slice(0, inputLength) === inputValue
      );
      if (suggestions.length === 0) {
        suggestions = [{ noSuggestions: true }];
      }
    }
    return suggestions;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.lastSuggestionRequestId !== null) {
      clearTimeout(this.lastSuggestionRequestId);
    }
    this.lastSuggestionRequestId = setTimeout(() => {
      this.setState({
        airportSuggestions: this.filterAirportSuggestions(value)
      });
    }, 500);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      airportSuggestions: []
    });
  };

  renderSuggestion = (suggestion, name) => {
    const { noSuggestions } = suggestion;
    return noSuggestions ? (
      <div>No suggestions</div>
    ) : (
      <div name={name} className="suggestion">
        {suggestion.icao}{" "}
        <small className="suggestion-additional">
          {suggestion.city}
          {", "}
          {suggestion.country}
        </small>
      </div>
    );
  };

  getSuggestionValue = suggestion => {
    const { noSuggestions } = suggestion;
    return noSuggestions ? "" : suggestion.icao;
  };

  render() {
    const { showPrevious } = this.props;
    const { airportSuggestions, today, airportOfOrigin, eta, airportOfDestination, etd, preventContinue } = this.state;

    const etaDate = this.parseEstimatedTime(eta);
    const etdDate = this.parseEstimatedTime(etd);

    const inputPropsOrigin = {
      name: "airportOfOrigin",
      type: "search",
      value: airportOfOrigin,
      onChange: (event, { newValue }) => {
        this.handleChange("airportOfOrigin", newValue);
      },
      placeholder: "Airport of Origin",
      className: "form-control handling-request-wizard__form-control"
    };

    const inputPropsDestination = {
      name: "airportOfDestination",
      type: "search",
      value: airportOfDestination,
      onChange: (event, { newValue }) => {
        this.handleChange("airportOfDestination", newValue);
      },
      placeholder: "Airport of Destination",
      className: "form-control handling-request-wizard__form-control"
    };

    return (
      <Form className="handling-request-wizard__form">
        {/* <div className="handling-request-wizard__form-section__header">Flight Scheduling</div> */}
        <div className="handling-request-wizard__form-section__title">Arrival</div>

        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${airportOfOrigin ? "" : " hide"}`}>
              Airport of Origin
            </Label>
            <Autosuggest
              suggestions={airportSuggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={suggestion => this.renderSuggestion(suggestion, inputPropsOrigin.name)}
              inputProps={inputPropsOrigin}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 row mx-0 px-0">
            <div className="col-12">
              <span>Estimated Time of Arrival [UTC]</span>
            </div>
            <div className="handling-request-wizard_form-control_time col-12 row mx-0">
              <div className="col-1 col-md-1 mt-4 pl-0 pr-2 pl-md-1 pr-md-1">
                <span className="handling-request-wizard_form-control_time"> on </span>
              </div>
              <div className="col-3 col-md-2 mt-4 pl-2 pr-0 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${eta.year ? "" : " hide"}`}>year</Label>
                <input
                  key="etaYear"
                  id="etaYear"
                  name="year"
                  type="number"
                  value={eta.year}
                  min={today.year()}
                  max={today.year() + 1}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfOrigin)}
                  placeholder="year"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-3 col-md-2 mt-4 pl-1 pr-1 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${eta.month ? "" : " hide"}`}>month</Label>
                <input
                  key="etaMonth"
                  id="etaMonth"
                  name="month"
                  type="number"
                  value={eta.month}
                  min={1}
                  max={12}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfOrigin)}
                  placeholder="month"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-3 col-md-2 mt-4 pl-0 pr-2 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${eta.day ? "" : " hide"}`}>day</Label>
                <input
                  key="etaDay"
                  id="etaDay"
                  name="day"
                  type="number"
                  value={eta.day}
                  min={1}
                  max={31}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfOrigin)}
                  placeholder="day"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-1 col-md-1 mt-4 pl-0 pr-2 pl-md-1 pr-md-1 text-md-center">
                <span className="handling-request-wizard_form-control_time"> at </span>
              </div>
              <div className="col-3 col-md-2 mt-4 pl-2 pr-0 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${eta.hour ? "" : " hide"}`}>hour</Label>
                <input
                  key="etaHour"
                  id="etaHour"
                  name="hour"
                  type="number"
                  value={eta.hour}
                  min={0}
                  max={23}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfOrigin)}
                  placeholder="hour"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-3 col-md-2 mt-4 pl-1 pr-1 pl-md-2 pr-md-0">
                <Label className={`handling-request-wizard__form-label${eta.minute ? "" : " hide"}`}>minute</Label>
                <input
                  key="etaMinute"
                  id="etaMinute"
                  name="minute"
                  type="number"
                  value={eta.minute}
                  min={0}
                  max={59}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfOrigin)}
                  placeholder="minute"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 row mx-0">
            <div className="col-12 col-md-auto pl-0 pr-md-1">
              <span>Local Estimated Time of Arrival [UTC+2]</span>
            </div>
            {etaDate && etaDate.isValid() ? (
              <div className="col-12 col-md-6 pl-0">
                <span>on </span>
                <strong>{etaDate.utcOffset(2).format("dddd D MMMM YYYY")}</strong>
                <span> at </span>
                <strong>{etaDate.utcOffset(2).format("HH:mm")}</strong>
              </div>
            ) : (
              <div className="col-12 col-md-auto pl-0 ml-md-auto">
                <strong> Please enter a valid date and time to see the local ETA.</strong>
              </div>
            )}
          </FormGroup>
        </Row>

        <div className="handling-request-wizard__form-section__title md-row">
          <span>Departure </span>
          {/* <small>Your departure can be shared at a later time.</small> */}
        </div>

        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 col-md-6">
            <Label className={`handling-request-wizard__form-label${airportOfDestination ? "" : " hide"}`}>
              Airport of Destination
            </Label>
            <Autosuggest
              suggestions={airportSuggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={suggestion => this.renderSuggestion(suggestion, inputPropsDestination.name)}
              inputProps={inputPropsDestination}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 row mx-0 px-0">
            <div className="col-12">
              <span>Estimated Time of Departure [UTC]</span>
            </div>
            <div className="handling-request-wizard_form-control_time col-12 row mx-0">
              <div className="col-1 col-md-1 mt-4 pl-0 pr-2 pl-md-1 pr-md-1">
                <span className="handling-request-wizard_form-control_time"> on </span>
              </div>
              <div className="col-3 col-md-2 mt-4 pl-2 pr-0 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${etd.year ? "" : " hide"}`}>year</Label>
                <input
                  key="etdYear"
                  id="etdYear"
                  name="year"
                  type="number"
                  value={etd.year}
                  min={today.year()}
                  max={today.year() + 1}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfDestination)}
                  placeholder="year"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-3 col-md-2 mt-4 pl-1 pr-1 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${etd.month ? "" : " hide"}`}>month</Label>
                <input
                  key="etdMonth"
                  id="etdMonth"
                  name="month"
                  type="number"
                  value={etd.month}
                  min={1}
                  max={12}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfDestination)}
                  placeholder="month"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-3 col-md-2 mt-4 pl-0 pr-2 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${etd.day ? "" : " hide"}`}>day</Label>
                <input
                  key="etdDay"
                  id="etdDay"
                  name="day"
                  type="number"
                  value={etd.day}
                  min={1}
                  max={31}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfDestination)}
                  placeholder="day"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-1 col-md-1 mt-4 pl-0 pr-2 pl-md-1 pr-md-1 text-md-center">
                <span className="handling-request-wizard_form-control_time"> at </span>
              </div>
              <div className="col-3 col-md-2 mt-4 pl-2 pr-0 pl-md-1 pr-md-1">
                <Label className={`handling-request-wizard__form-label${etd.hour ? "" : " hide"}`}>hour</Label>
                <input
                  key="etdHour"
                  id="etdHour"
                  name="hour"
                  type="number"
                  value={etd.hour}
                  min={0}
                  max={23}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfDestination)}
                  placeholder="hour"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
              <div className="col-3 col-md-2 mt-4 pl-1 pr-1 pl-md-2 pr-md-0">
                <Label className={`handling-request-wizard__form-label${etd.minute ? "" : " hide"}`}>minute</Label>
                <input
                  key="etdMinute"
                  id="etdMinute"
                  name="minute"
                  type="number"
                  value={etd.minute}
                  min={0}
                  max={59}
                  step={1}
                  onChange={this.handleChangeEventForDate}
                  disabled={!this.isValidIcao(airportOfDestination)}
                  placeholder="minute"
                  className="form-control handling-request-wizard__form-control"
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="form-group handling-request-wizard__form-group col-12 row mx-0">
            <div className="col-12 col-md-auto pl-0 pr-md-1">
              <span>Local Estimated Time of Departure [UTC+2]</span>
            </div>
            {etdDate && etdDate.isValid() ? (
              <div className="col-12 col-md-6 pl-0">
                <span>on </span>
                <strong>{etdDate.utcOffset(2).format("dddd D MMMM YYYY")}</strong>
                <span> at </span>
                <strong>{etdDate.utcOffset(2).format("HH:mm")}</strong>
              </div>
            ) : (
              <div className="col-12 col-md-auto pl-0 ml-md-auto">
                <strong> Please enter a valid date and time to see the local ETD.</strong>
              </div>
            )}
          </FormGroup>
        </Row>

        <ButtonToolbar className="form__button-toolbar handling-request-wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={() => showPrevious()}>
            Previous
          </Button>

          <Button
            color="primary"
            type="button"
            className="next ml-auto"
            onClick={() => this.continue()}
            disabled={preventContinue}
          >
            Next
          </Button>
        </ButtonToolbar>
      </Form>
    );
  }
}

export default HandlingRequestFormFlightScheduling;
